import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  ScreenRoot,
  DetailedFormContainer,
  SideContainer,
  LargeText,
  StyledSpan,
  MediumText,
} from "../styles";
import { AlertPopup } from "components/alertPopup/alertPopup";
import { CQSList } from "./CQSList";
import { CCDCRecentCard } from "./RecentCard";
import { SearchBar } from "./SearchBar";
import { FunctionalButton } from "../../../components/functionalButton/index";
import {
  DetailContent,
  DetailArea,
  HeaderArea,
  TouchScrollDiv,
  SubHeading,
} from "./styles";
import { AddPersonnelPopup } from "../../../components/addPersonnelPopup";
import { savePersonnel } from "../../../api/dashboard";
import { getCCDCs, deleteSingleCCDC } from "../../../api/prequalify";
import { useHistory, useLocation } from "react-router";
import AddNewButtonIcon from "../../../assets/dashboardIcons/addNewButtonIcon";
import { IconButton } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  clearContractorCcdcList,
  getContractorCcdcsList,
} from "data/actions/serviceProvider";
import { ContractorCcdcListSelector } from "data/selectors/serviceProvider";

function SeeAllCQS({ noWidthChange }) {
  const [query, setQuery] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [filteredData, setFilteredData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [individual, setIndividual] = useState("");
  const history = useHistory();
  const location = useLocation();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const institution_id = location.state?.institution_id || "";
  const dispatch = useDispatch();
  const [internalSearchResults, setInternalSearchResults] =
    useState(filteredData);

  const contractorCcdcList = useSelector(ContractorCcdcListSelector);

  const currentDashboardUrl = localStorage.getItem("current_dashboard_url");

  const handleFilter = async (filterQuery) => {
    if (currentDashboardUrl === "/service-provider-dashboard") {
      dispatch(
        getContractorCcdcsList(
          { institution_id: institution_id },
          () => { },
          () => { }
        )
      );
    } else {
      await getCCDCs(filterQuery)
        .then((data) => {
          setFilteredData(data);
        })
        .catch((err) => { });
    }
  };

  useEffect(() => {
    handleFilter(query);
    return () => {
      dispatch(clearContractorCcdcList());
    };
  }, []);

  useEffect(() => {
    if (contractorCcdcList?.length > 0) {
      setFilteredData(contractorCcdcList);
    }
  }, [contractorCcdcList]);

  // add button
  const handleAdd = () => {
    history.push("/prequalify/qualifying", {
      institution_id: institution_id,
    });
  };

  const addPersonnel = (data) => {
    savePersonnel(data, onSuccess, onFailure);
  };

  const onSuccess = (res) => {
    setIndividual("");
    setQuery("");
    handleFilter("");
  };

  const onFailure = (error) => {
    if (error?.status == 409) {
      setHasError(true);
      setErrorMessage(error?.data?.message);
    }
  };

  useEffect(() => {
    if (!openPopup) {
      setIndividual("");
    }
  }, [openPopup]);

  //three dots button

  const editCCDC = (id) => {
    history.push("/prequalify/qualifying", {
      action: "edit",
      ccdcId: id,
      institution_id: institution_id,
    });
  };

  const onSuccessEdit = (res) => {
    setIndividual(res);
    setOpenPopup(true);
  };

  const deleteCCDC = (id) => {
    deleteSingleCCDC(id, onSuccessDelete, () => { });
  };
  const onSuccessDelete = (res) => {
    setQuery("");
    handleFilter("");
  };

  return (
    <ScreenRoot isMobile={isMobile}>
      <DetailedFormContainer isMobile={isMobile} noWidthChange={noWidthChange}>
        <HeaderArea>
          <LargeText isMobile={isMobile}>
            {isMobile
              ? "All CQS"
              : "All Contractor's Qualifications Statements"}
          </LargeText>
          {isMobile && (
            <IconButton onClick={handleAdd}>
              <AddNewButtonIcon />
            </IconButton>
          )}
          {!isMobile && (
            <HeaderArea width="50%">
              <FunctionalButton
                buttonTitle="Start New"
                handleButton={handleAdd}
                width="40%"
              />
              <SearchBar
                filteredData={filteredData}
                setInternalSearchResults={setInternalSearchResults}
                query={query}
                setQuery={setQuery}
                internalSearch={true}
              />
            </HeaderArea>
          )}
        </HeaderArea>
        {/* {isMobile && (
          <TouchScrollDiv>
            {filteredData?.length > 0 &&
              filteredData?.slice(0, 4).map((item, i) => {
                return (
                  <CCDCRecentCard
                    item={{ ...item, id: item.ccdcId }}
                    key={i}
                    editFunc={editCCDC}
                    deleteFunc={deleteCCDC}
                    isMobile={isMobile}
                  />
                );
              })}
          </TouchScrollDiv>
        )}
        {!isMobile && (
          <DetailArea>
            <MediumText>Recently Added</MediumText>
            <DetailContent>
              {filteredData?.length > 0 &&
                filteredData?.slice(0, 4).map((item, i) => {
                  return (
                    <CCDCRecentCard
                      item={{ ...item, id: item.ccdcId }}
                      key={i}
                      editFunc={editCCDC}
                      deleteFunc={deleteCCDC}
                      isMobile={isMobile}
                    />
                  );
                })}
            </DetailContent>
          </DetailArea>
        )} */}

        <DetailArea>
          {isMobile && (
            <SearchBar
              filteredData={filteredData}
              setInternalSearchResults={setInternalSearchResults}
              query={query}
              setQuery={setQuery}
              searchWidth="95%"
              internalSearch={true}
            />
          )}
          {/*!isMobile &&(
            <MediumText>List of Contractor Qualification Statements</MediumText>
          )*/}
          {/* <SubHeading isMobile={isMobile} fontSize="14px">Only 5 most recent statements are shown</SubHeading> */}
          <CQSList
            filteredData={
              query && query !== "" ? internalSearchResults : filteredData
            }
            editFunc={editCCDC}
            deleteFunc={deleteCCDC}
            isMobile={isMobile}
          />
        </DetailArea>
        <AddPersonnelPopup
          setOpenDialog={setOpenPopup}
          openDialog={openPopup}
          data={individual}
          setData={setIndividual}
          handleButtonFunc={addPersonnel}
        />

        <AlertPopup
          openPopup={hasError}
          setOpenPopup={setHasError}
          isMobile={isMobile}
          headerText="Personnel Add/Edit Alert"
          subHeaderText={`${errorMessage}`}
          showButton={false}
        />

      </DetailedFormContainer>
    </ScreenRoot>
  );
}

export default SeeAllCQS;
