import React from "react";
import { Page, Document, StyleSheet, View, Line } from "@react-pdf/renderer";
import ProjectDetails from "./pageProjectDetails";
import CompanyDetails from "./pageCompanyDetails";
import BondDetails from "./pageBondDetails";
import ReportDetails from "./pageReportDetails";
import ProgressIsSatisfactory from "./pageProgressIsSatisfactory"
import ProgressIsSatisfactoryNoRemarks from "./pageProgressIsSatisfactoryNoRemarks"
import PageDiscliamer from "./pageDiscliamer"
import PageReportRequestedBy from "./pageReportRequestedBy"
import PageReportIssuedBy from "./pageReportIssuedBy"
import PdfHeaderWithoutLogo from "./pdfHeaderWithoutLogo";
import PdfHeaderWithLogo from "./pdfHeaderWithLogo";
import PdfFooter from "./pdfFooter";


const styles = StyleSheet.create({
    page: {
        backgroundColor: '#FFFFFF',
        fontFamily: 'Helvetica',
        fontSize: 10,
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        lineHeight: 1.5,
        flexDirection: 'column',
        size: 'letter',
    },
    logo: {
        width: 84,
        height: 70,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    lineDivider: {
        marginTop: 20,
        height: 1,
        backgroundColor: '#EBE9F1',
        marginLeft: 30,
        marginRight: 30,
        marginBottom: 20,
    },
    lineHeader: {
        marginTop: 0,
        marginLeft: 0,
        marginRight: 0,
        height: 79,
        backgroundColor: '#EBE9F1',
    },
    pageNumbers: {
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: 'center',
        paddingTop: 10,
        marginTop: 0,
    },
    spanText: {
        color: '#888888',
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 30,
        marginRight: 30,
    },
    remarks: {
        color: '#888888',
        marginTop: 6,
        marginBottom: 5,
        marginLeft: 30,
        marginRight: 30,
    },
    spanText2: {
        color: '#000000',
        marginTop: 5,
        marginLeft: 30,
        marginRight: 30,
    },
    remarksText: {
        color: '#000000',
        marginTop: 0,
        marginLeft: 30,
        marginRight: 30,
    },
    lineDividerBeforeFooter: {
        height: 1,
        width: 542,
        backgroundColor: '#F66616',
        marginLeft: 30,
        marginRight: 30,
    },
    imageContainer1: {
        justifyContent: 'flex-start',
        width: '100%',
        flex: 1,
    },
    imageContainer2: {
        justifyContent: 'flex-start',
        width: '100%',
        flex: 1,
    },
    lineDividerRed: {
        marginTop: 20,
        height: 1,
        backgroundColor: '#F66616',
        marginLeft: 30,
        marginRight: 30,
        marginBottom: 20,
    },


});


const PdfDocument = ({ csrData }) => {


    return (
        <Document>
            <Page style={styles.page} >
                {csrData?.data?.companyLogo !== "" ? <PdfHeaderWithLogo csrData={csrData} /> : <PdfHeaderWithoutLogo csrData={csrData} />}
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <CompanyDetails csrData={csrData} />
                    <ProjectDetails csrData={csrData} />
                </View>

                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <BondDetails csrData={csrData} />
                    <ReportDetails csrData={csrData} />
                </View>

                <Line style={styles.lineDivider} vertical={false} />

                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <ProgressIsSatisfactory csrData={csrData} />
                    <ProgressIsSatisfactoryNoRemarks csrData={csrData} />
                </View>

                <Line style={styles.lineDivider} vertical={false} />

                <PageDiscliamer />

                <Line style={styles.lineDividerRed} vertical={false} />

                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <PageReportRequestedBy csrData={csrData} />
                    <PageReportIssuedBy csrData={csrData} />
                </View>

                <PdfFooter />
            </Page>

        </Document>
    );
}

export default PdfDocument;
