import React, { useState, useEffect, useRef } from 'react';

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper,
  StyledMenuItem,
  StyledButton,
  StyledButtonDownload,
  StyledButtonContainer
} from './styles';

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import PledgxLogo from '../../assets/common/pledgxLogo';
import { downloadSingleCCDC } from '../../api/prequalify';
import { AlertPopup } from 'components/alertPopup/alertPopup';

export const AddDownloadPopUp = ({
  setOpenDialog,
  openDialog,
  prequalifyData,
  downloadFile,
  isMobile,
  data
}) => {
  const [openPopup, setOpenPopup] = useState(false);
  const popOpen = useRef(false);

  const downloadCCDC = (id) => {
    downloadSingleCCDC(id, onSuccessDownload, () => { });
  }

  const onSuccessDownload = (res) => {
    if (res?.status === "success") {
      downloadFile();
      handleClose();
    }
  }

  useEffect(() => {
    if (!openDialog) {
      setOpenDialog(false);
    }
  }, [openDialog]);

  useEffect(() => {
    if (!openPopup && popOpen.current) {
      popOpen.current=false;
      setOpenDialog(false);
    } else if (openPopup) {
      popOpen.current=true;
    }
  }, [openPopup]);
  

  const handleClose = () => {
    setOpenDialog(false);
    setOpenPopup(false);
  };

  // add handle Go Back Button
  const handleGoBackClick = () => {
    handleClose();
  };


  // add handle Download Button
  const handleDownloadClick = () => {
    const checkCcdcStatus = obj => obj.status === null && prequalifyData.ccdcId === obj.ccdcId;
    const checkCcdcStatusValue = data ? data.some(checkCcdcStatus) : '';

    if(checkCcdcStatusValue == true) {
      setOpenPopup(true);    
    } else {
      downloadCCDC(prequalifyData.ccdcId);
      setOpenPopup(false); 
    }   
       
  };

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper
        elevation={0}
        isMobile={isMobile}
      >
        <CloseButton
          aria-label="close"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </CloseButton>

        <PledgxLogo width={isMobile ? 45 : ''} height={isMobile ? 45 : ''} />
        <InfoArea isMobile={isMobile} marginTop="0px">
          <FormTitle style={{ marginTop: 20, marginBottom: 20 }}>{'You will not be able to edit the document once it is downloaded'}</FormTitle>
        </InfoArea>

        <StyledButtonContainer>
          <StyledMenuItem>
            <StyledButton title="Go Back" width="150px" onClick={handleGoBackClick}
            > Go Back
            </StyledButton>
          </StyledMenuItem>

          <StyledMenuItem>
            <StyledButtonDownload title="Download" width="150px" onClick={handleDownloadClick}
            > Download
            </StyledButtonDownload>
          </StyledMenuItem>

          <AlertPopup
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            headerText={'You cannot download the document unless it has been reviewed.'}
            subHeaderText={''}
          />

        </StyledButtonContainer>
      </StyledPaper>
    </Dialog>
  )
}