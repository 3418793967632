import React, { useState, useEffect } from "react";
import { Stepper } from "./Stepper";
import {
  Content,
  PreQualifyRoot,
  FormContainer,
  StyledTempMobileContainer,
  StyledPylonTemp,
  StyledMobileTempText,
} from "./styles";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import updateObjectByPath from "../../common/updateObjectByPath";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { qualificationSelector } from "../../data/selectors/user";
import {
  getPreQualificationInfo,
  preQualifyUser,
} from "../../data/actions/user";
import { checkAuth } from "../../data/actions/user";
import { useHistory } from "react-router-dom";

//logos
import { TemplateSelection } from "./components/TemplateSelection";
import { OwnerPartners } from "./components/OwnerPartners";
import { FinancialForm } from "./components/FinancialForm";
import { AdditionalDocuments } from "./components/additionalDocuments/additionalDocuments";
import { PrincipalProjectsForm } from "./components/principalProjectsForm";
import { AnnualRecords } from "./components/AnnualRecords";
import { PersonnelSelection } from "./components/personnelSelection";
import { FillValueConstruction } from "./components/valueOfConstruction/fillValueConstruction";
import { ReviewForm } from "./components/reviewForm";
import { getSelectedCCDCInfo } from "../../data/actions/user";
import PylonIcon from "assets/common/pylonIcon.png";
import { getInsuranceTypes } from "api/external_data";
import { getInstitutionProfileInfo } from "data/actions/serviceProvider";

function NewPreQualify() {
  const isMobile = useMediaQuery({ maxWidth: 960 });

  let history = useHistory();
  const dispatch = useDispatch();
  const qualification = useSelector(qualificationSelector);
  const [prequalifyData, setPrequalifyData] = useState({ ...qualification });
  const location = useLocation();
  const ccdcId = location.state?.ccdcId || "";
  const [institutionId, setInstitutionId] = useState(
    location.state?.institution_id || ""
  );
  const currentDashboardUrl = localStorage.getItem("current_dashboard_url");
  const [action, setAction] = useState(location.state?.action || "new");
  const section = location.state?.section || "";
  const [step, setStep] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [fileType, setFileType] = useState("");
  const [pdfFileSequence, setPdfFileSequence] = useState([]);
  const [insuranceType, setInsuranceType] = useState([]);
  const [currNavLocation, setCurrNavLocation] = useState({ step: 0, panel: 0 });
  const [navigationSequence, setNavigationSequence] = useState({
    0: { path: ["/qualifying"], visited: true, name: "Project Selection" },
    1: {
      path: ["/info/owner"],
      visited: false,
      name: "Owner Info",
    },
    2: {
      path: [
        "/financial/bank",
        "/financial/bond",
        "/financial/insurance",
        // "/financial/general",
        // "/financial/automobile",
        // "/financial/equipment",
      ],
      visited: false,
      name: "Financial Institutions",
    },
    3: { path: ["/safety"], visited: false, name: "Health & Safety" },
    4: { path: ["/value"], visited: false, name: "Financial Records" },
    5: { path: ["/projects"], visited: false, name: "Showcase Projects" },
    6: { path: ["/personnels"], visited: false, name: "Personnel" },
    7: {
      path: ["/additional/documents"],
      visited: false,
      name: "Additional Documents",
    },
    8: {
      path: ["/preview/ecqs", "/preview/format", "/preview/download"],
      visited: false,
      name: "Review and Download",
    },
  });

  useEffect(() => {
    if (action === "edit") {
      dispatch(
        getSelectedCCDCInfo(ccdcId, action, { institution_id: institutionId })
      );
    } else {
      dispatch(getPreQualificationInfo({ institution_id: institutionId }));
    }
  }, [dispatch]);
  useEffect(() => {
    getInsuranceTypes()
      .then((res) => {
        setInsuranceType(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
    if (section === "ecqs") {
      let newCurrLocation = {};
      newCurrLocation = { step: 8, panel: 0 };

      let visitedSequence = { ...navigationSequence };

      visitedSequence[newCurrLocation.step].visited = true;

      setNavigationSequence(visitedSequence);
      const newPath =
        navigationSequence?.[newCurrLocation.step]?.path?.[
          newCurrLocation.panel
        ];
      history.push("/prequalify" + newPath);
      setCurrNavLocation(newCurrLocation);
    }

    if (
      currentDashboardUrl === "/service-provider-dashboard" &&
      institutionId
    ) {
      dispatch(
        getInstitutionProfileInfo(
          { institution_id: institutionId },
          () => {},
          () => {}
        )
      );
    }
  }, []);

  useEffect(() => {
    setPrequalifyData({ ...qualification });
  }, [qualification]);

  const onChangeData = (attribute, val) => {
    const updatedData = { ...prequalifyData };
    updateObjectByPath(updatedData, attribute, val);
    setPrequalifyData({ ...updatedData });
  };

  const iconList = [];

  const stepLabels = [
    "Step 1",
    "Step 2",
    "Step 3",
    "Step 4",
    "Step 5",
    "Step 6",
    "Step 7",
    "Step 8",
    "Step 9",
  ];

  const [list, setList] = useState([iconList[0]]);
  const [labels, setLabels] = useState([stepLabels[0]]);
  const [panel, setPanel] = useState(1);
  const [isBranchPanel, setIsBranchPanel] = useState(false);
  const [isLastPanel, setIsLastPanel] = useState(false);
  const [previousStepPanel, setPreviousStepPanel] = useState("");
  const [disabledSelect, setDisabledSelect] = useState(true);
  const [popupSkip, setPopupSkip] = useState(false);
  const [rfpq, setRfpq] = useState("");

  const handleCCDCGenerate = () => {
    let formData = new FormData();

    // add additional documents
    let cacheDataCopy = { institution_id: institutionId, ...prequalifyData };

    let data = cacheDataCopy?.additional_document;
    let arrayKey = `additionalFiles`;
    try {
      if (data) {
        arrayKey = `additionalFiles`;

        data.forEach((v) => {
          if (!(v instanceof File)) {
            v = new File([v.file.split("base64,")[1]], v.name, {
              type: v.content_type,
            });
          }
          formData.append(arrayKey, v);
        });
      }
    } catch {}

    // add legal structure personnel resumes
    data = cacheDataCopy?.legal_structure?.personnel;

    try {
      if (data) {
        arrayKey = `personnel`;

        data.forEach((v, i) => {
          if (v.resume) {
            if (v.id) {
              formData.append(`${arrayKey}[${v.id}]`, v.resume);
            } else {
              formData.append(`${arrayKey}-new-[${i}]`, v.resume);
            }
          }

          // try {
          //   delete cacheDataCopy["legal_structure"]["personnel"][i]["resume"];
          // } catch {}
        });
      }
    } catch {}

    // add personnel resumes
    data = cacheDataCopy?.key_personnel;
    try {
      if (data) {
        arrayKey = `key_personnel`;

        data.forEach((v, i) => {
          if (v.resume) {
            if (v.id) {
              formData.append(`${arrayKey}[${v.id}]`, v.resume);
            } else {
              formData.append(`${arrayKey}-new-[${i}]`, v.resume);
            }
          }
          // try {
          //   delete cacheDataCopy["key_personnel"][i]["resume"];
          // } catch {}
        });
      }
    } catch {}

    // add CORS file
    if (cacheDataCopy?.years?.CORfileOrEquivalent) {
      formData.append(
        "CORfileOrEquivalent",
        cacheDataCopy?.years?.CORfileOrEquivalent
      );
    }

    try {
      delete cacheDataCopy["additional_document"];
    } catch {}
    try {
      delete cacheDataCopy["years"]["CORfileOrEquivalent"];
    } catch {}
    formData.append("data", JSON.stringify(cacheDataCopy));

    return formData;
  };

  const handleNext = (handleGenericGenerate, getPDF) => {
    dispatch(
      checkAuth(
        () => {},
        () => {
          history.push("/account/login");
        }
      )
    );

    let data = handleCCDCGenerate();

    if (handleGenericGenerate || getPDF) {
      if (fileType === "generic") {
        handleGenericGenerate(prequalifyData);
        dispatch(
          preQualifyUser(data, action, (res) => {
            // handleGenericGenerate(res);
          })
        );
      } else {
        getPDF(prequalifyData);
        dispatch(
          preQualifyUser(data, action, (res) => {
            // getPDF(res);
          })
        );
      }
    } else {
      dispatch(
        preQualifyUser(
          data,
          action,
          () => {},
          () => {}
        )
      );
    }

    if (action === "new") {
      setAction("edit");
    }

    let newCurrLocation = {};

    if (
      currNavLocation.panel <
      navigationSequence?.[currNavLocation.step]?.path?.length - 1
    ) {
      newCurrLocation = {
        ...currNavLocation,
        panel: currNavLocation.panel + 1,
      };
    } else {
      newCurrLocation = { step: currNavLocation.step + 1, panel: 0 };
      let visitedSequence = { ...navigationSequence };
      visitedSequence[newCurrLocation.step].visited = true;
      setNavigationSequence(visitedSequence);
    }

    const newPath =
      navigationSequence?.[newCurrLocation.step]?.path?.[newCurrLocation.panel];
    history.push("/prequalify" + newPath);

    setCurrNavLocation(newCurrLocation);
  };
  return (
    <>
      {!isMobile && (
        <PreQualifyRoot>
          <Stepper
            step={step}
            setStep={setStep}
            iconList={iconList}
            list={list}
            setList={setList}
            setLabels={setLabels}
            panel={panel}
            setPanel={setPanel}
            isLastPanel={isLastPanel}
            setIsLastPanel={setIsLastPanel}
            isBranchPanel={isBranchPanel}
            setIsBranchPanel={setIsBranchPanel}
            previousStepPanel={previousStepPanel}
            onboardingData={prequalifyData}
            setOnboardingData={onChangeData}
            setAction={setAction}
            setNavigationSequence={setNavigationSequence}
            navigationSequence={navigationSequence}
            currNavLocation={currNavLocation}
            setCurrNavLocation={setCurrNavLocation}
            history={history}
          />
          <FormContainer isMobile={isMobile}>
            <Content>
              <Switch>
                <Route exact path="/prequalify/qualifying">
                  <TemplateSelection
                    step={0}
                    handleNext={handleNext}
                    panel={panel}
                    setPanel={setPanel}
                    setIsLastPanel={setIsLastPanel}
                    setPreviousStepPanel={setPreviousStepPanel}
                    prequalifyData={prequalifyData}
                    setPrequalifyData={onChangeData}
                    setDownloading={setDownloading}
                    setPopupSkip={setPopupSkip}
                    setIsBranchPanel={setIsBranchPanel}
                    rfpq={rfpq}
                    setRfpq={setRfpq}
                    institutionId={institutionId}
                  />
                </Route>
                <Route path="/prequalify/info">
                  <OwnerPartners
                    step={1}
                    handleNext={handleNext}
                    panel={panel}
                    setPanel={setPanel}
                    setIsLastPanel={setIsLastPanel}
                    setPreviousStepPanel={setPreviousStepPanel}
                    prequalifyData={prequalifyData}
                    setPrequalifyData={onChangeData}
                    popupSkip={popupSkip}
                    setIsBranchPanel={setIsBranchPanel}
                  />
                </Route>
                <Route path="/prequalify/financial">
                  <FinancialForm
                    step={2}
                    handleNext={handleNext}
                    panel={panel}
                    setPanel={setPanel}
                    setIsLastPanel={setIsLastPanel}
                    setPreviousStepPanel={setPreviousStepPanel}
                    prequalifyData={prequalifyData}
                    setPrequalifyData={onChangeData}
                    setIsBranchPanel={setIsBranchPanel}
                    insuranceTypeList={insuranceType}
                    institutionId={institutionId}
                  />
                </Route>
                <Route exact path="/prequalify/safety">
                  <AnnualRecords
                    step={3}
                    handleNext={handleNext}
                    panel={panel}
                    setPanel={setPanel}
                    setIsLastPanel={setIsLastPanel}
                    setPreviousStepPanel={setPreviousStepPanel}
                    prequalifyData={prequalifyData}
                    setPrequalifyData={onChangeData}
                    institutionId={institutionId}
                  />
                </Route>
                <Route exact path="/prequalify/value">
                  <FillValueConstruction
                    step={4}
                    handleNext={handleNext}
                    panel={panel}
                    setPanel={setPanel}
                    setIsLastPanel={setIsLastPanel}
                    setPreviousStepPanel={setPreviousStepPanel}
                    prequalifyData={prequalifyData}
                    setPrequalifyData={onChangeData}
                    institutionId={institutionId}
                  />
                </Route>

                <Route exact path="/prequalify/projects">
                  <PrincipalProjectsForm
                    step={5}
                    handleNext={handleNext}
                    panel={panel}
                    setPanel={setPanel}
                    setIsLastPanel={setIsLastPanel}
                    setPreviousStepPanel={setPreviousStepPanel}
                    prequalifyData={prequalifyData}
                    setPrequalifyData={onChangeData}
                    institutionId={institutionId}
                  />
                </Route>
                <Route exact path="/prequalify/personnels">
                  <PersonnelSelection
                    step={6}
                    handleNext={handleNext}
                    panel={panel}
                    setPanel={setPanel}
                    setIsLastPanel={setIsLastPanel}
                    setPreviousStepPanel={setPreviousStepPanel}
                    prequalifyData={prequalifyData}
                    setPrequalifyData={onChangeData}
                    institutionId={institutionId}
                  />
                </Route>
                <Route path="/prequalify/additional">
                  <AdditionalDocuments
                    step={7}
                    handleNext={handleNext}
                    panel={panel}
                    setPanel={setPanel}
                    setIsLastPanel={setIsLastPanel}
                    setPreviousStepPanel={setPreviousStepPanel}
                    prequalifyData={prequalifyData}
                    setPrequalifyData={onChangeData}
                    action={action}
                    setAction={setAction}
                    fileType={fileType}
                    setFileType={setFileType}
                    updateCachedData={setPrequalifyData}
                    setPdfFileSequence={setPdfFileSequence}
                    rfpq={rfpq}
                    institutionId={institutionId}
                  />
                </Route>
                <Route path="/prequalify/preview">
                  <ReviewForm
                    step={8}
                    setIsLastPanel={setIsLastPanel}
                    handleNext={handleNext}
                    setPreviousStepPanel={setPreviousStepPanel}
                    pdfFileSequence={pdfFileSequence}
                    setPrequalifyData={onChangeData}
                    prequalifyData={prequalifyData}
                    ccdcId={ccdcId}
                    action={action}
                    setAction={setAction}
                    setFileType={setFileType}
                    updateCachedData={setPrequalifyData}
                    setPdfFileSequence={setPdfFileSequence}
                    fileType={fileType}
                    insuranceTypeList={insuranceType}
                    institutionId={institutionId}
                  />
                </Route>
              </Switch>
            </Content>
          </FormContainer>
        </PreQualifyRoot>
      )}
      {isMobile && (
        <StyledTempMobileContainer>
          <StyledPylonTemp src={PylonIcon} />
          <StyledMobileTempText>
            Please switch to <br />
            desktop for best experience
          </StyledMobileTempText>
        </StyledTempMobileContainer>
      )}
    </>
  );
}

export default NewPreQualify;
