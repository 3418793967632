import React, { useEffect, useState } from "react";
import { StyledTableCell } from '../../../components/filesListTable/styles';
import ConciseTable from '../../../components/filesListTable/conciseTable';
import { FunctionalButton } from '../../../components/functionalButton/index';
import { AddInsurancePopup } from "../../../components/addInsurancePopup";
import { getInsurances } from "../../../api/external_data";
import { StyledProjectSelectionContainer, HeaderArea } from '../styles';
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";


export default function DataMaintananceInsurance(editFunc,
  deleteFunc,
  isMobile) {

  const headers = [
    { title: 'Id' },
    { title: 'Name' },
    { title: 'Address' },
    { title: 'Suite Number' },
    { title: 'City' },
    { title: 'State/Province' },
    { title: 'Country' },
    { title: 'Postal/Zip Code' },
    // { title: 'Insurance Type', align: 'center' },
    { title: 'phone' },
    { title: 'Website' },
    { title: 'Is Bonding?' },
  ];

  const [openPopup, setOpenPopup] = useState(false);
  const [insurances, setInsurances] = useState([]);
  const [internalSearchResults, setInternalSearchResults] = useState(insurances);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getInsurances()
      .then((res) => {
        setInsurances(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  const handleAdd = () => {
    setOpenPopup(true);
  };

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCell component={'tr'} width="15%">
          {bodyData.id}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="15%">
          {bodyData.name}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="15%">
          {bodyData.address}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="15%">
          {bodyData.suite_number}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="15%">
          {bodyData.city}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="15%">
          {bodyData.state_province}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="15%">
          {bodyData.country}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="25%">
          {bodyData.postal_zip_code}
        </StyledTableCell>
        {/* <StyledTableCell component={'tr'} width="25%">
          {bodyData.insurance_type}
        </StyledTableCell> */}
        <StyledTableCell component={'tr'} width="20%">
          {bodyData.phone}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="20%">
          {bodyData.website}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="20%">
          {bodyData.is_bonding}
        </StyledTableCell>
      </>
    );
  };


  return (
    <StyledProjectSelectionContainer isMobile={isMobile}>

      <HeaderArea width="100%">
        <SearchBar
          filteredData={insurances}
          setInternalSearchResults={setInternalSearchResults}
          query={query}
          setQuery={setQuery}
          internalSearch={true}
        />
        <FunctionalButton
          buttonTitle="Add Insurance"
          handleButton={handleAdd}
          width="60%"
        />
      </HeaderArea>

      <ConciseTable
        headers={isMobile ? "" : headers}
        length={isMobile ? 8 : 8.5}
        body={body}
        data={
          query && query !== "" ? internalSearchResults : insurances
        }
      />

      <AddInsurancePopup
        setOpenDialog={setOpenPopup}
        openDialog={openPopup}
      />

    </StyledProjectSelectionContainer>
  )
}


