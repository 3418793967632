import styled from 'styled-components';

export const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 25px;
  margin-left: 0;
  margin-right: auto;
  font-style: normal;
  font-weight:  ${props => props.labelWeight || 'normal'};
  font-size: ${props => props.labelSize || '12px'};
  line-height: 20px;
  color: #000000;
`;
