import React, { useState } from "react";

import { MediumText, StyledBox, SmallText } from "../styles";

import { FunctionalButton } from "../../../components/functionalButton";
import criteriaComingSoon from "assets/dashboardIcons/criteriaComingSoon.svg";
import {
  FunctionArea,
  IntroArea,
  StyledTitle,
  ButtonContainer,
  ComingSoonImg,
  ImgDiv,
} from "./styles";

import { useHistory } from "react-router";
import PPCIcon from "assets/serviceProvider/PPCIcon";

export const ProgressPayment = ({ isMobile, selectedTab }) => {
  const [comingSoon, setComingSoon] = useState(false);
  const history = useHistory();
  const seeAllPpc = () => {
    history.push("/service-provider-dashboard/allPPCs");
  };
  return (
    <FunctionArea isMobile={isMobile}>
      {isMobile && (
        <StyledBox
          boxHeight="110px"
          borderRadius="15px"
          isMobile={isMobile}
          // onClick={}
          style={{ cursor: "pointer" }}
        >
          {comingSoon && (
            <ImgDiv>
              <ComingSoonImg src={criteriaComingSoon} />
            </ImgDiv>
          )}
          {!comingSoon && (
            <>
              <PPCIcon />
              <SmallText>Coming Soon</SmallText>
            </>
          )}
        </StyledBox>
      )}
      {!isMobile && (
        <>
          <MediumText>Progress Payments</MediumText>
          <StyledBox boxHeight="216px">
            {comingSoon && (
              <ImgDiv style={{ width: "100%" }}>
                <ComingSoonImg src={criteriaComingSoon} />
              </ImgDiv>
            )}
            {!comingSoon && (
              <>
                {/* <ImgDiv style={{ width: "100%" }}>
                  <ComingSoonImg src={criteriaComingSoon} />
                </ImgDiv> */}
                <IntroArea>
                  <PPCIcon />
                </IntroArea>
                <ButtonContainer>
                  <FunctionalButton
                    buttonTitle="View Progress Payments"
                    handleButton={seeAllPpc}
                    width="90%"
                    marginRight="0px"
                    isReversedColor={true}
                    hasBoxShadow={true}
                    disabled={selectedTab === "2" ? false : true}
                  />
                </ButtonContainer>
              </>
            )}
          </StyledBox>
        </>
      )}
    </FunctionArea>
  );
};
