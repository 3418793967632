import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import FlagIcon from "@mui/icons-material/Flag";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { COLORS } from "../../../constants";

const RedFleg = ({ text = "" }) => {
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `${COLORS.primaryOrange} !important`, // Custom background color with !important
      color: "white !important", // Text color with !important
      fontSize: "11px",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#f50057 !important", // Arrow color to match background with !important
    },
  });

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <CustomTooltip title={text}>
        <FlagIcon style={{ color: "red", cursor: "pointer" }} />
      </CustomTooltip>
    </div>
  );
};

export default RedFleg;
