import React, { useEffect, useRef, useState } from "react";
import { 
  LargeText,
 } from '../styles';

import logoPlacement from 'assets/reference/logoPlacement.svg';
import editBadge from 'assets/reference/editBadge.svg';
import { Badge } from '@mui/material';
import { saveCompanyLogo } from 'api/profile';

import { FunctionalButton } from "../../../components/functionalButton";
import { CommonCategories } from "../../../components/commonCategories";
import updateObjectByPath from "../../../common/updateObjectByPath";
import { AddressAutoFields } from './AddressAutoFields';
import Select from "../../../components/selectField";
import { getCompanyInfo, saveCompanyInfo } from '../../../api/profile';
import Autocomplete from "react-google-autocomplete";
import { IconButton } from "@material-ui/core";
import MobileSaveButtonIcon from "../../../assets/dashboardIcons/mobileSaveButtonIcon";
import Input from 'components/inputField';
import { formatPhoneNumber } from 'common/formatPhoneNumber';
import { phoneRegex } from "common/regex";

import { 
  FieldArea,
  StyledRow,
  SectionText,
  SetupContainer,
  StyledAutoComplete,
  StyledAuto,
  CompanyLogo,
  EditBadge,
  LogoDiv,
  LogoPlacementContainer,
} from './styles';
import PersonnelSelection from "./personnelSelection";

const EditBadgeContent = ({
  inputFile,
  isMobile,
  setLogo
}) => {

  const clickUploadFile = () => {
    inputFile.current.click();
  };

  const savePhoto = (file) => {
    let formData = new FormData();
    formData.append('logo', file);
    saveCompanyLogo(formData, (res) => setLogo(res.logo), () => {});
  }

  return (
    <>
    <label htmlFor="contained-button-file">
      <input
        type="file"
        id="contained-button-file"
        style={{ display: "none" }}
        ref={inputFile}
        accept= ".jpg,.jpeg,.png, image/jpeg,image/png"
        onClick={(e) => {
          e.target.value = null;
          clickUploadFile();
        }}
        onChange={(e) => {
          if (
            e.target.files[0].type === "image/jpeg" ||
            e.target.files[0].type === "image/png" 
          ){ savePhoto(e.target.files[0]);}
        }}
      />
          <EditBadge src={editBadge} isMobile={isMobile}/>
      </label>
      
    </>
  )
}

export const UserAccessManagement = ({
  isMobile,
  setOpenSuccessPopup
}) => {
  const [data, setData] = useState({});
  const inputFile = useRef(null);
  const [logo, setLogo] = useState(data?.company_info?.logo)

  useEffect(() => {
    getCompanyInfo((res) => { setData({...res}); setLogo(res?.company_info?.logo)}, () => {});
  },[])

  

  const ventureOptions = [
    "Joint Venture",
    "Corporation",
    "Partnership",
    "Registered",
    "Sole Proprietor",
    "Other",
  ];

  const handleSave = () => {
    saveCompanyInfo(data, () => {setOpenSuccessPopup(true)}, () => {})
  };

  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };

  const currentYear = new Date().getFullYear();

  return (
    <SetupContainer  isMobile={isMobile}>
      
      <StyledRow justifyContent="space-between">
        <LargeText isMobile={isMobile}>{isMobile ? "User Management" : "User Management"}</LargeText>
        {isMobile && (
          <IconButton onClick={handleSave}>
            <MobileSaveButtonIcon />
          </IconButton>
        )}
        {!isMobile && (
          <FunctionalButton
            buttonTitle="Save"
            handleButton={handleSave}
            width="150px"
            height="38px"
            marginRight="0px"
          />
        )}
      </StyledRow>
      <FieldArea isMobile={isMobile} width={isMobile ? "100%": "66.67%"} >
        <SectionText>User Management</SectionText>
        <LogoDiv>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={<EditBadgeContent inputFile={inputFile} isMobile={isMobile} setLogo={setLogo} />}
          >
            {logo && (
              <CompanyLogo src={`data:image/png;base64,${logo}`} isMobile={isMobile} variant="square" />
            )}
            {!logo && (
              <LogoPlacementContainer src={logoPlacement} isMobile={isMobile}/>
            )}
            </Badge>
        </LogoDiv>
       <PersonnelSelection></PersonnelSelection>
      
      </FieldArea>
    </SetupContainer>
  );
}
