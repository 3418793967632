import React, { useState, useEffect } from "react";
import { Popup } from '../../../../components/popup';
import { Steps } from './steps';


export const AddProjectPopup = ({
  setOpenPopup,
  openPopup,
 }) => {
  return(
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width="716px"
      height="550px"
      dialogContent={(
        <Steps
        />
      )}
    />
  )
 }
