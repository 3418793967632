export const getBondingInfo = () => (dispatch) => {
  dispatch({
    type: "GET_BONDING_INFO",
    payload: {
      endpoint: "/bonding",
      method: "GET",
    },
  });
};

export const getUserCategories = () => (dispatch) => {
  dispatch({
    type: "GET_USER_CATEGORIES",
    payload: {
      endpoint: "/categories",
      method: "GET",
    },
  });
};
