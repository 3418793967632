import { HorizontalContainer, ScreenRoot, VerticalContainer } from "../styles";
import {
  ProceedButtonContainer,
  SearchBarContainer,
  TitleContainer,
} from "../uploader/styles";
import { LargeText, MediumText } from "../../styles";
import React, { useEffect, useState } from "react";
import { StageList } from "../uploader";
import { useMediaQuery } from "react-responsive";
import {
  ClickablePageThumbnail,
  ClickablePageThumbnailSkeleton,
  ConfidenceLabel,
  Line,
  LineSkeleton,
  SplitContainer,
  SplitSkeletonContainer,
  ToggleButton,
  ToggleButtonSkeleton,
} from "./styles";
import { FunctionalButton } from "../../../../components/functionalButton";
import { useHistory, useLocation } from "react-router";
import {
  editSplitData,
  getFinancialDocumentsFromBucket,
  getSplitterData,
} from "../../../../api/external_data";
import { Document, Page } from "react-pdf";
import {
  getUnderwriterIdpActiveDocuments,
  getUnderwriterIdpArchivedDocuments,
} from "../../../../data/actions/underwriters";
import { useDispatch } from "react-redux";
// import { DocumentViewer } from "../components/documentViewer";

export default function Splitter() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();

  const { selectedAccount, clientDocumentsId, bucketName, filePath } =
    location.state || null;

  console.log("state being from to uploader ", location.state);

  const [splitLocations, setSplitLocations] = useState([]);
  const [splitterData, setSplitterData] = useState();

  const [documents, setDocuments] = useState([]);
  const [activeDocument, setActiveDocument] = useState();

  const [pageCounts, setPageCounts] = useState({});

  const [proceedDisabled, setProceedDisabled] = useState(false);

  const [loading, setLoading] = useState(false);

  const [activePageNumber, setActivePageNumber] = useState(1);

  useEffect(() => {
    const fetchFinancialDocuments = async () => {
      try {
        const data = {
          bucketName,
          filePath,
        };

        await getFinancialDocumentsFromBucket(data, setDocuments, (error) => {
          if (error) {
            throw new Error("Error retrieving documents");
          }
        });

        if (documents && documents.length > 0) {
          console.log("called setActiveDocuments");
          setActiveDocument(documents[0]);
        }
      } catch (error) {
        console.log(error.message || "An unexpected error occurred");
      }
    };

    fetchFinancialDocuments();
  }, []);

  useEffect(() => {
    if (documents && documents.length > 0) {
      setActiveDocument(documents[0]);
    }
  }, [documents]);

  useEffect(() => {
    const accountDetails = {
      account_id: selectedAccount.AccountId,
      client_documents_id: clientDocumentsId,
    };

    const fetchSplitterData = async () => {
      setProceedDisabled(true);
      try {
        await getSplitterData(
          accountDetails,
          (response) => {
            if (response && response.splitter_data) {
              setSplitterData(response);
              setSplitLocations(
                extractSplitLocations(response.splitter_data.files),
              );
              clearInterval(pollingInterval);
              setProceedDisabled(false);
              setLoading(false);
            }
          },
          (error) => {
            if (
              error.error ===
              "splitter_data_response is None, cannot parse JSON"
            ) {
              setLoading(true);
            }
            console.log(`Failed to get splitter data: ${error}`);
          },
        );
      } catch (error) {
        console.error("Error fetching splitter data:", error);
      } finally {
        dispatch(getUnderwriterIdpActiveDocuments());
        dispatch(getUnderwriterIdpArchivedDocuments());
      }
    };

    const pollingInterval = setInterval(fetchSplitterData, 10000);

    fetchSplitterData();

    return () => clearInterval(pollingInterval);
  }, []);

  /**
   * Generates the edit split data for the given files and split locations.
   *
   * This function processes a list of files and their corresponding split locations to produce
   * the required data structure for editing split data API. It groups split locations by file ID,
   * sorts them, and creates `splitData` arrays for each file, which include ranges of pages
   * between the split locations.
   *
   * @param {Array<Object>} files - An array of file objects. Each file object must have at least:
   *   - `id` (string|number): The unique identifier of the file.
   *   - `numPages` (number): The total number of pages in the file.
   *   - Other properties as needed.
   * @param {Array<Object>} splitLocations - An array of split location objects. Each object must have:
   *   - `fileId` (string|number): The ID of the file to which the split location belongs.
   *   - `location` (number): The page number where the split occurs.
   *   - Other properties like `confidence` can be included as needed.
   * @returns {Object} An object containing:
   *   - `client_documents_id` (number|string): The provided client documents ID.
   *   - `account_id` (number|string): The provided account ID.
   *   - `edit_split_data` (Object): An object with a `files` property, which is an array of file objects with updated `splitData`.
   */
  const generateEditSplitData = (edit_split_data, splitLocations) => {
    const splitLocationsByFileId = splitLocations.reduce((acc, loc) => {
      if (!acc[loc.fileId]) {
        acc[loc.fileId] = [];
      }
      acc[loc.fileId].push(loc.location);
      return acc;
    }, {});

    edit_split_data.files.forEach((file) => {
      const fileId = file.id;

      const fileSplitLocations = splitLocationsByFileId[fileId] || [];
      const sortedSplitLocations = fileSplitLocations.sort((a, b) => a - b);

      if (!sortedSplitLocations.includes(0)) {
        sortedSplitLocations.unshift(0);
      }

      let splitData = [];
      const totalPages = file.numPages;

      if (!sortedSplitLocations.includes(totalPages)) {
        sortedSplitLocations.push(totalPages);
      }

      for (let i = 0; i < sortedSplitLocations.length - 1; i++) {
        const startPage = sortedSplitLocations[i];
        const endPage = sortedSplitLocations[i + 1];

        const pages = [];
        for (let page = startPage; page < endPage; page++) {
          pages.push(page);
        }

        if (pages.length > 0) {
          splitData.push(pages);
        }
      }

      file.splitData = splitData;
    });
    console.log("edit split data", edit_split_data);
    return edit_split_data;
  };

  /**
   * Extracts split locations from the given files based on their Document AI output.
   *
   * This function processes an array of files, extracting split location information from each file's
   * `documentAIOutput.entities`. It generates an array of split location objects, each containing:
   * - `location`: The page number where a split should occur.
   * - `fileId`: The identifier of the file.
   * - `confidence`: The confidence score associated with the split location.
   *
   * @param {Array<Object>} files - An array of file objects. Each file object should have:
   *   - `id` (string|number): The unique identifier of the file.
   *   - `documentAIOutput` (Object): The output from Document AI, which includes:
   *     - `entities` (Array<Object>): An array of entity objects, each potentially containing a `pageAnchor`.
   * @returns {Array<Object>} An array of split location objects with properties:
   *   - `location` (number): The page number for the split.
   *   - `fileId` (string|number): The ID of the file the split belongs to.
   *   - `confidence` (number): The confidence score of the split location.
   */
  const extractSplitLocations = (files) => {
    let splitLocations = [];

    files.forEach((file) => {
      file.documentAIOutput.entities.forEach((entity) => {
        if (entity.pageAnchor && entity.pageAnchor.pageRefs) {
          if (!entity.pageAnchor.pageRefs[0].page) {
            splitLocations.push({
              location: 1,
              fileId: file.id,
              confidence: entity.confidence,
            });
          } else {
            const lastElement =
              entity.pageAnchor.pageRefs[entity.pageAnchor.pageRefs.length - 1];
            splitLocations.push({
              location: Number(lastElement.page) + 1,
              fileId: file.id,
              confidence: entity.confidence,
            });
          }
        }
      });
    });

    return splitLocations;
  };

  // Called when documents are loaded and is used to set total page count for each file
  const onDocumentLoadSuccess = (fileName, { numPages }) => {
    setPageCounts((prevPageCounts) => ({
      ...prevPageCounts,
      [fileName]: numPages,
    }));
  };

  /**
   * Toggles a split location in the `splitLocations` array based on the provided index and file ID.
   *
   * This function checks if a split location matching the given `index` and `fileId` exists in the
   * `splitLocations` array:
   * - If it exists, the function removes it (untoggles the split).
   * - If it doesn't exist, the function adds it with a default confidence of `1.0` (toggles the split).
   *
   * After updating, it sets the new `splitLocations` array using `setSplitLocations`.
   *
   * @param {number} index - The page index (or location) where the split is toggled.
   * @param {string|number} fileId - The identifier of the file to which the split location belongs.
   */
  const toggleSplitLocation = (index, fileId) => {
    const toggledSplit = splitLocations.findIndex(
      (splitLocation) =>
        splitLocation.location === index && splitLocation.fileId === fileId,
    );

    let updatedSplitLocations;

    if (toggledSplit !== -1) {
      updatedSplitLocations = splitLocations.filter(
        (splitLocation, i) => i !== toggledSplit,
      );
    } else {
      updatedSplitLocations = [
        ...splitLocations,
        {
          location: index,
          fileId,
          confidence: 1.0,
        },
      ];
    }

    setSplitLocations(updatedSplitLocations);
  };

  /**
   * Handles the proceed action for editing split data, disables the proceed button,
   * generates and sends the edit split data to the API, and navigates to the classifier route
   * with the updated data.
   *
   * @async
   * @function handleProceed
   * @returns {Promise<void>} - Executes the edit splits API call and navigates upon success.
   *
   * @description
   * This function performs the following:
   * - Disables the proceed button to prevent duplicate submissions.
   * - Generates the required data structure for editing splits using the provided `splitterData` and `splitLocations`.
   * - Calls the `editSplitData` API function, which:
   *    - On success, redirects the user to the classifier route with the updated data (`splitterFiles`).
   *    - On failure, logs an error and re-enables the proceed button.
   *
   * @example
   * // Triggered on proceed button click
   * handleProceed();
   */
  const handleProceed = async () => {
    setProceedDisabled(true);

    const data = generateEditSplitData(
      splitterData.edit_split_data,
      splitLocations,
    );

    // edit splits API, returns with
    await editSplitData(
      {
        account_id: selectedAccount.AccountId,
        client_documents_id: clientDocumentsId,
        edit_split_data: data,
      },
      (response) => {
        history.push({
          pathname: "/underwriter/idpdocuments/classifier",
          state: {
            selectedAccount,
            clientDocumentsId,
            splitterFiles: response.splitterFilesArray,
          },
        });
        setProceedDisabled(false);
      },
      (err) => {
        console.log("error ediitng split data ", err);
        setProceedDisabled(false);
      },
    );
  };

  return (
    <>
      <ScreenRoot isMobile={isMobile}>
        <VerticalContainer>
          <HorizontalContainer>
            <TitleContainer>
              <LargeText>{selectedAccount.CompanyName}</LargeText>
              <MediumText>{selectedAccount.AccountId}</MediumText>
            </TitleContainer>
            <StageList
              stages={["Uploader", "Splitter", "Classifier", "Parser"]}
              currentStage="Splitter"
            />
            <ProceedButtonContainer>
              <FunctionalButton
                buttonTitle="Proceed"
                handleButton={handleProceed}
                width="100%"
                height="48px"
                isReversedColor={false}
                marginRight={"48px"}
                marginLeft={"48px"}
                marginTop={"48px"}
                marginBottom={"48px"}
                fontSize={"16px"}
                disabled={proceedDisabled}
                icon={null}
                hasBoxShadow={true}
                frontIcon={null}
                weight={600}
              />
            </ProceedButtonContainer>
          </HorizontalContainer>
          <HorizontalContainer>
            <VerticalContainer>
              <SearchBarContainer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      overflowY: "scroll",
                      maxHeight: "700px",
                      width: "60%",
                      boxShadow:
                        "inset 0px -20px 10px -10px rgba(0, 0, 0, 0.3)",
                    }}
                  >
                    {documents && splitterData ? (
                      splitterData.splitter_data.files.map((file) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "34px",
                              alignItems: "flex-end",
                            }}
                          >
                            <Document
                              loading="Loading your split document"
                              key={file.id}
                              file={
                                documents.find(
                                  (doc) => doc.fileName === file.fileName,
                                )?.blob || null
                              }
                              onLoadSuccess={(pdf) =>
                                onDocumentLoadSuccess(file.fileName, pdf)
                              }
                              renderAnnotationLayer={false}
                              renderTextLayer={false}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "34px",
                                  alignItems: "flex-end",
                                  position: "relative",
                                }}
                              >
                                <MediumText
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    const foundDocument = documents.find(
                                      (doc) => doc.fileName === file.fileName,
                                    );
                                    if (foundDocument) {
                                      setActiveDocument(foundDocument);
                                    }
                                  }}
                                >
                                  {file.fileName}
                                </MediumText>
                                {pageCounts[file.fileName] &&
                                  Array.from(
                                    new Array(pageCounts[file.fileName] || 0),
                                    (el, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "35px",
                                          alignItems: "flex-end",
                                        }}
                                      >
                                        <ClickablePageThumbnail
                                          onClick={() => {
                                            setActivePageNumber(index + 1);
                                            const foundDocument =
                                              documents.find(
                                                (doc) =>
                                                  doc.fileName ===
                                                  file.fileName,
                                              );
                                            if (foundDocument) {
                                              setActiveDocument(foundDocument);
                                            }
                                          }}
                                        >
                                          <Page
                                            renderAnnotationLayer={false}
                                            renderTextLayer={false}
                                            pageNumber={index + 1}
                                            width={120}
                                            height={168}
                                          />
                                        </ClickablePageThumbnail>
                                        {/* Split */}
                                        <Split
                                          index={index + 1}
                                          fileId={file.id}
                                          toggled={
                                            !!splitLocations.find(
                                              (splitLocation) =>
                                                splitLocation.location ==
                                                  index + 1 &&
                                                splitLocation.fileId ===
                                                  file.id,
                                            )
                                          }
                                          confidence={
                                            splitLocations.find(
                                              (splitLocation) =>
                                                splitLocation.location ==
                                                  index + 1 &&
                                                splitLocation.fileId ===
                                                  file.id,
                                            )?.confidence || 0
                                          }
                                          onSplitToggle={toggleSplitLocation}
                                        />
                                      </div>
                                    ),
                                  )}
                              </div>
                            </Document>
                          </div>
                        );
                      })
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "34px",
                          alignItems: "flex-end",
                        }}
                      >
                        {Array.from({ length: 12 }).map((_, index) => (
                          <React.Fragment key={index}>
                            <ClickablePageThumbnailSkeleton />
                            <SplitSkeleton />
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                  </div>
                  <div style={{ width: "500px" }}>
                    {" "}
                    {/* Ensure the parent div takes up the full available width */}
                    {activeDocument && (
                      <iframe
                        src={URL.createObjectURL(
                          new Blob([activeDocument.blob], {
                            type: "application/pdf",
                          }),
                        )}
                        width="100%" // Use 100% to occupy the full width of the parent div
                        height="698px"
                        type="application/pdf"
                        title="PDF Viewer"
                        style={{
                          overflow: "auto", // Allow scrolling inside iframe
                          whiteSpace: "nowrap",
                          border: "none",
                        }}
                      ></iframe>
                    )}
                  </div>
                  {/*{activeDocument && (*/}
                  {/*  <DocumentViewer*/}
                  {/*    src={activeDocument?.blob}*/}
                  {/*    defaultPageNumber={activePageNumber}*/}
                  {/*    width="600px"*/}
                  {/*    height="700px"*/}
                  {/*  />*/}
                  {/*)}*/}
                </div>
              </SearchBarContainer>
            </VerticalContainer>
          </HorizontalContainer>
        </VerticalContainer>
      </ScreenRoot>
    </>
  );
}

const ExpandIcon = () => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.17422 2.5C3.31987 2.5 2.85896 3.50212 3.41496 4.15079L7.24074 8.6142C7.63984 9.07981 8.36016 9.07981 8.75926 8.6142L12.585 4.15079C13.141 3.50212 12.6801 2.5 11.8258 2.5H4.17422Z"
      fill="#1A051D"
    />
  </svg>
);

/**
 * Split component representing a toggleable split indicator for document pages.
 * This component allows users to toggle split locations between pages within a document and displays
 * the confidence level when a split is selected.
 *
 * @param {Object} props - The component props.
 * @param {number} props.index - The page index where the split is applied.
 * @param {string|number} props.fileId - The unique identifier of the file.
 * @param {boolean} props.toggled - Indicates whether the split is currently toggled on.
 * @param {number} props.confidence - The confidence score associated with the split (between 0 and 1).
 * @param {function} props.onSplitToggle - Callback function invoked when the split is toggled.
 *
 * @returns {JSX.Element} The rendered Split component.
 */
const Split = ({ index, fileId, toggled, confidence, onSplitToggle }) => {
  return (
    <SplitContainer>
      <Line selected={toggled} />
      <ToggleButton
        selected={toggled}
        onClick={() => onSplitToggle(index, fileId)}
      >
        {toggled ? (
          <svg
            width="6"
            height="1"
            viewBox="0 0 6 1"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 0.5L5 0.5" stroke="white" strokeLinecap="round" />
          </svg>
        ) : (
          <svg
            width="6"
            height="5"
            viewBox="0 0 6 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 2.5L5 2.5" stroke="white" strokeLinecap="round" />
            <path d="M3 0.5L3 4.5" stroke="white" strokeLinecap="round" />
          </svg>
        )}
      </ToggleButton>
      {toggled && (
        <ConfidenceLabel>{(confidence * 100).toFixed(2)}%</ConfidenceLabel>
      )}
    </SplitContainer>
  );
};

const SplitSkeleton = () => (
  <SplitSkeletonContainer>
    <LineSkeleton />
    <ToggleButtonSkeleton></ToggleButtonSkeleton>
  </SplitSkeletonContainer>
);
