import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  background: #f5f5f5;
  min-height: calc(100vh - 172px);

  padding: 20px 35px;
`;

export const BaseInfo = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InfoBox = styled.div`
  width: 180px;
  height: 65px;

  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;

  margin-right: 20px;

  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 8px 17px -1px rgba(0, 0, 0, 0.05);

  &:hover {
    background: #dddddd;
  }
`;

export const Circle = styled.div`
  width: 35px;
  height: 35px;
  margin-left: 12px;

  border-radius: 100%;

  background: ${(props) => props.color};
`;

export const InfoSubTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;

  color: #6e6b7b;
`;
export const InfoTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;

  color: ${(props) => props.color};
`;

export const Title = styled.div`
  flex-grow: 2;

  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 41px;

  color: #1a051d;
`;

export const ContentControls = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: row;
`;

export const SelectLabel = styled.div`
  margin-right: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 41px;

  color: #1a051d;
`;

export const ChangeMode = styled.div`
  width: 160px;
  height: 38px;

  background: white;

  display: flex;
  flex-direction: row;
`;

export const ChangeModeItem = styled.div`
  width: 80px;
  height: 38px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;

  color: ${(props) => (props.active ? "#000000" : "#c4c4c4")};

  ${(props) => (props.active ? "border-bottom: 3px solid #F26934;" : "")}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContractorRow = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  height: 50px;

  border-top: 1px solid #d3d3d3;
`;

export const ContractorName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;

  color: #6e6b7b;
`;

export const ContractorWinnerTag = styled.div`
  display: flex;
  align-items: center;

  margin-left: 30px;

  height: 18px;
  border-radius: 5px;
  padding: 0 5px;
  margin-right: 10px;

  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  white-space: nowrap;

  background: #02814e;
  color: white;
`;
