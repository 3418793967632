import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PageThreeInsuranceTableRow from './pageThreeInsuranceTableRow';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 5,
        justifyContent: 'flex-start',
        width: '65%',
        flex: 1,
        paddingLeft: 30,
        paddingRight: 0,
    },
    insurance: {
        marginTop: 5,
        paddingBottom: 0,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
    },
    spanText: {
        color: '#888888',
    },
});

const PageThreeInsurance = ({ prequalifyData }) => (
    <View style={{ display: 'flex', flexDirection: 'row' }}>
        <View style={styles.headerContainer}>
            <Text style={styles.insurance}>Insurance</Text>
            <View>
                <PageThreeInsuranceTableRow items={prequalifyData?.insuranceData} />
            </View>
        </View>
    </View>
);

export default PageThreeInsurance;
