import React from "react";
import ErrorAlertIcon from '../../../assets/common/ErrorAlertIcon';
import { FunctionalButton } from '../../../components/functionalButton';
import {
  StyledPopupTitle,
  StyledPopupText,
  StyledBottomButtonContainer,
  StyledTextContainer,
  StyledIconContainer
} from './styles';


export const UploadFailurePopup = ({
  clickUploadFile,
  setOpenPopup,
  setCCDCFile
}) => {

  const handleCancel = () => {
    setOpenPopup(false);
    setCCDCFile(null);
  };

  const handleRetry = () => {
    setOpenPopup(false);
    setCCDCFile(null);
    clickUploadFile();
  };

  return (
    <>
      <StyledTextContainer>
        <StyledIconContainer>
          <ErrorAlertIcon />
        </StyledIconContainer>
        <div>
          <StyledPopupTitle>Oh No! Something Went Wrong!</StyledPopupTitle>
          <StyledPopupText>
            There was an error and your file could not be uploaded.<br/>
            Would you like to try again?
          </StyledPopupText>
        </div>
      </StyledTextContainer>
      <StyledBottomButtonContainer justifyContent="center" marginTop="16px">
      <FunctionalButton
          buttonTitle="Retry"
          width="138px"
          height="40px"
          marginRight="16px"
          handleButton={handleRetry}
        />
        <FunctionalButton
          buttonTitle="Cancel"
          width="138px"
          height="40px"
          isReversedColor={true}
          handleButton={handleCancel}
        />
      </StyledBottomButtonContainer>
    </>
  )
}
