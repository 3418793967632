import React, { useState, useEffect } from 'react';

import { PROVINCES } from '../../../constants';
import { emailRE } from "../../../common/regex";
import {
  Row,
  StyledMediumTitle,
} from "../styles";
import Input from "../../../components/inputField";
import Select from "../../../components/selectField";


export const Panel2 = ({
  qualificationData,
  setQualificationData,
}) => {

  const [disabledThird, setDisabledThird] = useState(true);

  useEffect(() => {
    const projectInfo = qualificationData?.project_info;
    if (
      projectInfo?.gc_name?.length > 0 &&
      emailRE.test(projectInfo?.gc_email) &&
      projectInfo?.gc_phone?.length > 0 &&
      projectInfo?.gc_address?.length > 0 &&
      projectInfo?.gc_postal_code?.length > 0 &&
      projectInfo?.gc_city?.length > 0 &&
      projectInfo?.gc_province?.length > 0 &&
      projectInfo?.gc_hst > 0
    ) {
      setDisabledThird(false);
    } else {
      setDisabledThird(true);
    }
  });

  return (
    <>
      <StyledMediumTitle marginBottom="-8px">Submitted by</StyledMediumTitle>
      <Row>
      <Input
        style={{  marginRight: "16px", width: "50%" }}
        label="Name"
        type="text"
        placeholder="Name"
        value={qualificationData.project_info?.gc_name}
        setValue={(val) => setQualificationData('project_info.gc_name', val)}
        validateFunc={(item) => item.length > 0}
      ></Input>
      <Input
        style={{  marginRight: "16px", width: "25%" }}
        label="Email"
        type="text"
        placeholder="Company Email"
        value={qualificationData.project_info?.gc_email}
        setValue={(val) => setQualificationData('project_info.gc_email', val)}
        validateFunc={(item) => {
          return emailRE.test(item);
        }}
      ></Input>
      <Input
        style={{  marginRight: "16px", width: "25%" }}
        label="Phone"
        type="text"
        placeholder="Phone"
        value={qualificationData.project_info?.gc_phone}
        setValue={(val) => setQualificationData('project_info.gc_phone', val)}
        validateFunc={(item) => item.length > 0}
      ></Input>
      </Row>
      <Row>
      </Row>
      <Row>
        <Input
          style={{  marginRight: "16px", width: "50%" }}
          label="Address"
          type="text"
          placeholder="Address"
          value={qualificationData.project_info?.gc_address}
          setValue={(val) => setQualificationData('project_info.gc_address', val)}
          validateFunc={(item) => item.length > 0}
        />
        <Input
          style={{  marginRight: "16px", width: "25%" }}
          label="Suite number, etc."
          type="text"
          placeholder="Suite number, etc."
          value={qualificationData.project_info?.gc_suite_number}
          setValue={(val) => setQualificationData('project_info.gc_suite_number', val)}
          validateFunc={(item) => item.length > 0}
        />
        <Input
         style={{  marginRight: "16px", width: "25%" }}
          label="ZIP/ Postal Code"
          type="text"
          placeholder="ZIP/ Postal Code"
          value={qualificationData.project_info?.gc_postal_code}
          setValue={(val) => setQualificationData('project_info.gc_postal_code', val)}
          validateFunc={(item) => item.length > 0}
        />
      </Row>
      <Row marginBottom="80px">
        <Input
          style={{  marginRight: "16px" }}
          label="HST Number"
          type="text"
          placeholder="HST Number"
          value={qualificationData.project_info?.gc_hst}
          setValue={(val) => setQualificationData('project_info.gc_hst', val)}
          validateFunc={(item) => String(item).length > 0}
        />
        <Input
          style={{  marginRight: "16px", }}
          label="City"
          type="text"
          placeholder="City"
          value={qualificationData.project_info?.gc_city}
          setValue={(val) => setQualificationData('project_info.gc_city', val)}
          validateFunc={(item) => item.length > 0}
        />
        <Select
          style={{  marginRight: "16px"}}
          // iconMarginRight="16px"
          label="Province"
          options={PROVINCES.map((item) => ({ value: item, label: item }))}
          value={qualificationData.project_info?.gc_province}
          setValue={(val) => setQualificationData('project_info.gc_province', val)}
          validateFunc={(item) => Boolean(item)}
        />
      </Row>
    </>
  );
};
