import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { COLORS, TYPOGRAPHY } from '../../../../constants';
import { StyledHeader } from '../../../../components/slider/styles';
import { StyledTableCell } from '../../../../components/filesListTable/styles';
import DownloadIcon from "../../../../assets/buyers/downloadIcon";

export const StyledInput = styled.div`
  margin-top: ${props => props.marginTop};
`;

export const StyledSmallText = styled(Typography)`
  && {
    width: 100%;
    font-size: 12px;
    font-family: ${TYPOGRAPHY.primaryFont};
    margin-top: 8px;
    color: ${COLORS.darkGrey2};
  }
`;

export const StyledNarrowHeader = styled(StyledHeader)`
  &&{
    margin-bottom: 8px;
  }
`;

export const StyledWrap = styled.div`
  margin-top: 16px;
  padding-left: ${props => props.paddingLeft};
`;

export const StyledTableCellFirst = styled(StyledTableCell)`
  width: 85%;
`;

export const StyledUploadedFilesContainer = styled.div`
  width: 80%;
`;

export const StyledDownloadText = styled.p`
  && {
    display: inline;
    color: ${COLORS.primaryOrange};
    font-weight: 600;
    font-size: 15px;
    margin-right: 5px;
  }
`;

export const StyledIcon = styled(DownloadIcon)`
  display: inline;
`;
