import React from "react";
import classes from './InputArea.module.css';
import { COLORS } from '../../constants';

function InputArea({
  id,
  backgroundColor,
  borderColor,
  disabled,
  fontSize,
  height,
  label,
  labelSize,
  labelWeight,
  placeholder,
  readOnly,
  value,
  width,
  setValue
}) {

  const handleOnChange = (e) => {
    if (setValue) {
      setValue(e.target.value);
    };
  };

  return (
    <div className={ classes.container }>
      <label
        className={ classes.label }
        htmlFor={ id }
        style={{
          fontSize: labelSize ? labelSize : '12px',
          fontWeight: labelWeight ? labelWeight : 'normal'
        }}
      >
        { label }
      </label>
      {
        readOnly ?
          <span
            className={ classes['read-only-span'] }
            style={{
              backgroundColor: COLORS.backgroundGrey,
              border: `1px solid ${ COLORS.greyBorder }`,
              color: COLORS.darkGrey1,
              fontSize: fontSize || '15px',
              minHeight: height || '100px',
              width: width || '100%'
            }}
          >{ value }</span>
        :
          <textarea
            className={ classes.input }
            id={ id }
            disabled={ disabled }
            onChange={ (e) => handleOnChange(e) }
            placeholder={ placeholder }
            style={{
              backgroundColor: readOnly ? COLORS.backgroundGrey : backgroundColor || 'white',
              border: `1px solid ${ readOnly ? COLORS.greyBorder : borderColor || '#CCC' }`,
              fontSize: fontSize || '15px',
              height: height || '100px',
              width: width || '100%'
            }}
            value={ value }
          />
      }
    </div>
  );
}

export default InputArea;
