import React from 'react';
import lift from './man.png';

const LiftPerson = ({isMobile}) => {
  return (
    <img width={isMobile ? "119" : "508"} height={isMobile ? "124" : "329"} src={lift} />
  )
};

export default LiftPerson;


