import React, { useState } from 'react';
import { StyledTableRow } from "./styles";


export const FileRow = (props) => {
  const {
    ccdcId,
    actions,
    actionType,
    icons,
    body,
    selectedIndex,
    setSelectedIndex,
    handleSelected,
    hover,
    handleClickRow,
    borderRadius,
    boxShadow,
    noActions,
    pickedArray
  } = props;

  const bodyProps = { ...props };
  delete bodyProps['actions'];
  delete bodyProps['icons'];
  delete bodyProps['body'];
  const [selectedRow, setSelectedRow] = useState();
  let skipCell = false;
  
  const isItemSelected = (row) => {
    return (selectedRow && row && selectedRow.index === row.index && selectedRow.index === selectedIndex );
  }

  const handleClick = (event, row) => {
    if(handleSelected) {
      if(selectedRow && selectedRow?.index === row.index && selectedRow.index === selectedIndex){
        setSelectedRow('');
      } else {
        setSelectedIndex(row.index);
        setSelectedRow(row);
        handleSelected(row);
      }
    }
    if(handleClickRow) {
      handleClickRow(row);
    }
  };
// console.log(pickedArray);
// console.log(bodyProps.project_number);

  
    if(pickedArray && pickedArray.includes(bodyProps.institution_id)) {
      skipCell = true;
    } else if (pickedArray && pickedArray.includes(bodyProps.id)){
      skipCell = true;
    } else if (pickedArray && pickedArray.includes(bodyProps.project_number)){
      skipCell = true;
    } 


  return (
    <>
      { !skipCell && (
        <StyledTableRow
          borderRadius={borderRadius}
          boxShadow={boxShadow}
          hover={hover}
          onClick={(event) => handleClick(event, bodyProps)}
          selected={isItemSelected(bodyProps)}
        >
          {icons && icons()}
          {body && body(bodyProps)}
          {actions && !actionType && actions(ccdcId)}
          {actions && actionType && !noActions && actions(bodyProps)}
        </StyledTableRow>
      )}
    </>

  );
};
