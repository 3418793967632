import React, { useState, useEffect } from "react";
import SelectDropdown from "../selectDropdown";

export const PersonnelDropdown = ({
  margin,
  value,
  setData,
  attribute,
  filterApi,
  label,
  className,
  institution_id,
}) => {
  const [options, setOptions] = useState([]);
  const [main, setMain] = useState(value || "");
  const currentDashboardUrl = localStorage.getItem("current_dashboard_url");

  const handleFilter = async () => {
    let data = {};
    if (
      currentDashboardUrl === "/service-provider-dashboard" &&
      institution_id
    ) {
      data = { institution_id: institution_id };
    }
    await filterApi("", data)
      .then((data) => {
        if (data) {
          generateOptions(data);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (filterApi) {
      handleFilter();
    }
  }, []);

  const generateOptions = (data) => {
    const tmp = [];
    data.map((val, i) => {
      const name = `${val.firstName}${
        val.firstName && val.lastName ? " " : ""
      }${val.lastName}`;
      tmp.push({
        key: i,
        text: name,
        value: name,
        email: val.email,
        id: val.id,
      });
    });
    setOptions(tmp);
  };

  const handleSelect = (props) => {
    setMain(props.value);

    if (props.value.length > 0) {
      setData(attribute || "personnel", props.value);
    } else {
      setData(attribute || "personnel", "");
    }
  };

  return (
    <SelectDropdown
      className={className || ""}
      placeholder={"Search & select one"}
      value={main}
      label={label}
      options={options}
      handleSelect={handleSelect}
      search={true}
      multiple={false}
      width="100%"
      height="50px"
      hasLabel={true}
      hasData={main?.length > 0}
      clearable={true}
    />
  );
};
