import axios from "axios";
import { API_HOST } from "../constants";

let headers = {};

const getAuth = () => {
  const authData = localStorage.getItem("Authorization");
  if (authData) {
    headers = { Authorization: authData };
  }
};

export const getSubmissionDetails = async (id, action, data) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/submissions/details/${id}?action=${action}`, data, {
      headers,
    })
    .then((res) => res.data)
    .catch((err) => {});
};

export const getAllSubmissions = async (id, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .get(`${API_HOST}/submissions/${id}`, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => onFailure(err.response));
};

export const getAllProjects = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/buyers/projects/all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const getProjectInfo = async (id) => {
  getAuth();
  return await axios
    .get(`${API_HOST}/buyers/projects/${id}`, { headers })
    .then((res) => res.data)
    .catch((err) => {});
};

export const postProjectInfo = async (action, data) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/buyers/projects/${action}`, data, { headers })
    .then((res) => res.data)
    .catch((err) => {});
};

export const modifyProject = async (id, action) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/buyers/projects/${action}/${id}`, {}, { headers })
    .then((res) => res.data)
    .catch((err) => {});
};

export const checkUserAvailable = async (data) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/rfpq/check_user_available`, data, { headers })
    .then((res) => res.data)
    .catch(
      (err) => err.response.data
      // console.log(err.response.data)
    );
};
export const filterPrequaliedContractors = async (
  data,
  filter,
  onSuccess,
  onFailure
) => {
  getAuth();
  return await axios
    .post(
      `${API_HOST}/buyers/prequalified/contractors?filter=${filter}`,
      data,
      { headers }
    )
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};
