import get from "lodash/get";
import { createSelector } from "reselect";

export const baseOverviewSelector = (state) => get(state, "overview", {});

export const bondingSelector = createSelector(baseOverviewSelector, (items) =>
  get(items, "bonding", {})
);

export const categoriesSelector = createSelector(
  baseOverviewSelector,
  (items) => get(items, "categories", [])
);
