import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { COLORS, TYPOGRAPHY } from '../../../constants';
import { FormContainer } from '../styles';

export const LargeText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.secondaryFont};
    font-weight: 600;
    font-size: ${props => props.isMobile? "24px" : "36px"};
    text-align: center;
    width: ${props => props.isMobile? "100%" : props.width};
    margin-top: ${props => props.isMobile? "0px" : "60px"};
  }
`;

export const SmallText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.secondaryFont};
    font-weight: 600;
    font-size: ${props => props.isMobile? "16px" : "24px"};;
    text-align: center;
    width: ${props => props.isMobile? "100%" : props.width};
    margin-top: ${props => props.isMobile? "16px" : "36px"};
  }
`;
