import styled from "styled-components";
import StepLabel from "@material-ui/core/StepLabel";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import Stepper from "@material-ui/core/Stepper";
import TextField from "@material-ui/core/TextField";
import { TYPOGRAPHY, COLORS } from "../../../constants";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Avatar } from "@material-ui/core";
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import Autocomplete from "react-google-autocomplete";
import FormControlLabel from '@material-ui/core/FormControlLabel';




export const StyledHealthSafetyPersonnelNone = styled(FormControlLabel)`
  &&{
    margin-left : -8px;
    margin-top: 16px;
    margin-bottom: 20px;

    .MuiTypography-body1{
      font-size: 13px;
      font-weight: 500;
      font-family: ${TYPOGRAPHY.primaryFont};
    }

    .MuiIconButton-label{
      margin-left: 0px;
      margin-right : 0px;
    }
  }
`;


export const StyledAddressContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  gap: 16px;
`;


export const StyledCenter = styled.div`
  display: flex;
  justify-content:center;
  margin-top: 16px;
`;


export const StyledSafetyRecordsContainer = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: row;
  width: 100%;
  gap: 16px;
`;


export const FormContainer = styled.div`
  width: ${(props) => (props.isMobile ? "85%" : "85%")};
  ${(props) => (props.isMobile ? "align-self: center;" : "")}
  padding-bottom: 20px;
  margin-left: 20px;
  margin-top: ${(props) => (props.isMobile ? "16px" : "24px")};
  display: flex;
  flex-direction: column;
  // align-items: center;
  // ${(props) => (props.leftBorder ? "margin-top: 50px;" : "")};
  border-left: ${(props) => (props.leftBorder ? "3px solid #EBE9F1" : "none")};
`;


export const StyledTab = styled(Tab)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 18px;
    font-weight: 500;
    text-transform: none;
    color: ${COLORS.blurOutText};
    padding-right: 8px;
    padding-left: 8px;
    border: 1px solid #DAE0E7;
	  background: #F9F9F9;
    border-radius:  10px 10px 0px 0px;
    transition: all .1s;
    cursor: pointer;
    user-select: none;
  }
  &:hover{
    color: ${COLORS.primaryOrange};
  }
  &&.Mui-selected{
    color: ${COLORS.primaryOrange};
    font-size: 22px;
    background: #F9FBFC;
    border-bottom-color: transparent;
   }
`;

export const StyledTabList = styled(TabList)`
&&{
  color: #DAEDD7;
  variant: fullWidth;
  indicator-color: #DAEDD7;
}  
`;

export const StyledTabListHealthSafety = styled(TabList)`
&&{
  color: #DAEDD7;
  variant: fullWidth;
  indicator-color: #DAEDD7;
  width: 400px;
  margin-left: 130px;
}  
`;

export const ErrorText = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: ${COLORS.primaryRed};
    margin-top: 4px;
  }
`;

export const SafetyText = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 4px;
  }
`;


export const StyledStepConnector = styled(StepConnector)`
  && {
    &&.MuiStepConnector-vertical {
      padding: 0px;
      height: 0px;
    }

    .MuiStepConnector-line {
      border-color: white;
    }
  }

  &&.MuiStepConnector-active {
    .MuiStepConnector-line {
      border-color: white;
    }
  }

  &&.MuiStepConnector-completed {
    .MuiStepConnector-line {
      border-color: white;
    }
  }
`;

export const StyledStepLabel = styled(StepLabel)`
  && {
    .MuiStepLabel-labelContainer {
      font-family: ${TYPOGRAPHY.primaryFont};
      font-weight: 400;
      color: ${COLORS.lightGrey2};
    }

    .MuiStepLabel-iconContainer {
      padding: 0;
    }

    .MuiStepLabel-label.MuiStepLabel-active {
      color: ${COLORS.textGrey};
      font-weight: 600;
    }

    .MuiStepLabel-label.MuiStepLabel-completed {
      color: ${COLORS.lightGrey2};
      font-weight: 400;
    }

    .MuiStepLabel-label {
      font-size: 18px;
      color: ${COLORS.lightGrey2};
    }

    cursor: pointer;
  }

  &&.MuiStepLabel-root.Mui-disabled {
    cursor: pointer;
  }
`;

export const StyledStepper = styled(Stepper)`
  && {
    background: transparent;
    align-self: center;
    ${(props) => (props.isMobile ? "" : "margin-right: -16px;")}
    ${(props) => (props.isMobile ? "" : "padding-left: 0px;")}
    padding-top: 30px;
    padding-bottom: 0px;
    min-width: 270px;
  }
`;

export const SideBarContainer = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  margin-left: -80px;
`;

export const SetupContainer = styled.div`
  ${(props) => (!props.isMobile ? "margin-left: 110px" : "")};
  padding-bottom: 32px;
  position: relative;
  z-index=0;
`;

export const SetupContainerPassword = styled.div`
  margin-left: 110px;
  padding-bottom: 32px;
  width: 50%;
`;


export const SetupUserManagementContainer = styled.div`
  ${(props) => (!props.isMobile ? "margin-left: 20px" : "")};
  padding-bottom: 32px;
  position: relative;
  z-index=0;
`;

export const FieldArea = styled.div`
  ${(props) =>
    props.marginLeft && !props.isMobile
      ? `margin-left: ${props.marginLeft};`
      : ""}
  margin-top: ${(props) => props.marginTop || "24px"};
  display: flex;
  flex-direction: ${(props) => (props.isColumn ? "column" : "row")};
  align-items: center;
  flex-wrap: wrap;
  ${(props) => (props.noJustifyContent ? "" : "justify-content: left;")}
  ${(props) =>
    props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}

  .last {
    padding-right: 0px !important;
  }

  .inputWrap {
    padding-right: ${(props) => (props.isMobile ? "0px" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "50%")};
  }

  .fullWidthInputWrap {
    width: 100%;
  }

  .oneFifthInputWrap {
   padding-right: ${(props) => (props.isMobile ? "0px" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "20%")};
  }

  .thirtyInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "30%")};
  }

  .oneThirdInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "33.33%")};
  }

  .twoThirdInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "66.66%")};
  }

  .oneFifthMobile {
    padding-right: 16px;
    width: ${(props) => (props.isMobile ? "50%" : "20%")};
  }

  .thirtyMobile {
    width: ${(props) => (props.isMobile ? "50%" : "30%")};
  }
`;

export const StyledEditRecordButton = styled(FieldArea)`
  margin-top: 15px;
  margin-left: -10px;
`;

export const StyledCard = styled(Card)`
  && {
    width: ${(props) => (props.isMobile ? "155px" : "200px")};
    height: ${(props) => (props.isMobile ? "125px" : "162px")};
    margin-right: 20px;
    box-shadow: none;
    background: ${(props) => (props.isChecked ? COLORS.lighOrange : "white")};
    border: 1px solid
      ${(props) => (props.isChecked ? COLORS.primaryOrange : "#ECEBED")};
    box-sizing: border-box;
    border-radius: 10px;
    ${(props) => (props.isMobile ? "margin-top: 32px;" : "")};
  }
`;

export const StyledCardContent = styled(CardContent)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    ${(props) => (!props.isMobile ? "margin-top: 12px;" : "")};
    ${(props) => (!props.isMobile ? "margin-bottom: 16px;" : "")};
  }
`;

export const StyledCardActionArea = styled(CardActionArea)`
  && {
    // height: 64px;
  }
`;

export const StyledCardContainer = styled.div`
  margin-top: 16px;
`;

export const StyledFlag = styled.img`
  ${(props) => (props.isMobile ? "width: 100px;" : "width: 130px;")};
  ${(props) => (props.isMobile ? "height: 80px;" : "height: 85px;")};
`;

export const StyledCardTypography = styled(Typography)`
  && {
    font-size: 18px;
    font-family: ${TYPOGRAPHY.primaryFont};
    font-weight: 500;
    ${(props) => (!props.isMobile ? "margin-top: 12px;" : "")};
    color: #888888;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  width: 100%;
  ${(props) => (props.margin ? `margin: ${props.margin};` : "")}
  ${(props) =>
    props.justifyContent ? `justify-content: ${props.justifyContent};` : ""}
  flex-direction: ${(props) =>
    props.isMobile || props.isColumn ? "column" : "row"};
`;

export const FieldTitle = styled(Typography)`
  && {
    margin-bottom: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    width: 100%;
    margin-top: 24px;
  }
`;

export const ProfilePicture = styled(Avatar)`
  && {
    width: ${(props) => (props.isMobile ? "120px" : "200px")};
    height: ${(props) => (props.isMobile ? "120px" : "200px")};
  }
`;

export const EditBadge = styled(Avatar)`
  && {
    width: ${(props) => (props.isMobile ? "29px" : "49px")};
    height: ${(props) => (props.isMobile ? "29px" : "49px")};
    ${(props) =>
      props.isMobile ? "border: 1px solid white;" : "border: 2px solid white;"}
    cursor: pointer;
  }
`;

export const SectionText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    width: 100%;
    margin-top: ${(props) => props.marginTop || "16px"};
    color: ${COLORS.darkGrey1};
  }
`;

export const StyledAddText = styled.div`
  && {
    color: ${COLORS.primaryOrange};
    cursor: pointer;
    font-family: ${TYPOGRAPHY.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    font-size: 18px;
    margin-top: 3px;
    margin-left: 10px;
  }
`;

export const TableArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(props) => (props.isMobile ? "margin-top: -8px;" : "")}
`;

export const AddSignArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const StyledMainPanel = styled.div`
  width: 100%;
  margin-top: -20px;
  ${(props) => (props.isMobile ? "" : "margin-left: 40px;")}
`;

export const StyledAutoComplete = styled(Autocomplete)`
  &&.pac-container {
    position: relative !important;
    margin-top: -450px;
  }
`;

export const StyledAuto = styled.div``;

export const StyledAddFinancialText = styled.div`
  color: ${COLORS.primaryOrange};
  cursor: pointer;
  font-family: ${TYPOGRAPHY.primaryFont};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-right: 16px;
`;

export const ImagePopup = styled.div`
  margin-top: 16px;
`;

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isMobile ? "90%" : "100%")};
  margin-top: 0px;
  justify-content: center;
  align-items: center;
`;

export const ButtonArea = styled.div`
  margin-top: 40px;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;

export const LargeText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${(props) => (props.isMobile ? "22px" : "28px")};
    font-style: normal;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 24px;
  }
`;

export const SmallText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.secondaryFont};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 12px;
  }
`;

export const CompanyLogo = styled(Avatar)`
  && {
    width: ${(props) => (props.isMobile ? "121px" : "182px")};
    height: ${(props) => (props.isMobile ? "114px" : "182px")};
  }
`;

export const LogoPlacementContainer = styled(Avatar)`
  && {
    width: ${(props) => (props.isMobile ? "121px" : "182px")};
    height: ${(props) => (props.isMobile ? "114px" : "182px")};
  }
`;

export const LogoDiv = styled.div`
  width: 100%;
  text-align: left;
  margin: 24px 0px;
  display: flex;
  justify-content: center;
`;

export const SecondRadioContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SecondInnerRadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 85px;
`;

export const StyledTextField = styled(TextField)`
  && {
    width: ${(props) => (props.width ? props.width : "100%")};
    border-color: ${(props) => (props.noBorderColor ? "" : COLORS.lightGrey1)};
    background-color: white;

    .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) =>
        props.noBorderColor ? "" : COLORS.lightGrey1};
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${COLORS.primaryOrange};
      border-width: thin;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${COLORS.primaryOrange};
    }
  }
`;
