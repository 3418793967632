import axios from "axios";
import get from "lodash/get";
// import redirectToLoginPage from "../../helpers/redirectToLogin";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (next) => async (action) => {
  if (get(action, "disableApiMiddleWare")) return next(action);
  const endpoint = get(action, "payload.endpoint");
  const method = get(action, "payload.method");
  const body = get(action, "payload.body");
  const params = get(action, "payload.params", {});
  const onSuccess = get(action, "onSuccess", null);
  const onFailed = get(action, "onFailed", null);
  const baseUrl = get(action, "payload.baseUrl");
  const skipAuth = get(action, "payload.skipAuth", false);
  const file = get(action, "payload.file", false);
  const header = get(action, "payload.headers", false);
  const responseType = get(action, "payload.responseType", false);

  // const API_HOST = baseUrl ? baseUrl : `https://api.pledgx.com/api/v1`;
  // const API_HOST = baseUrl ? baseUrl : `http://127.0.0.1:5000/api/v1`;
  const API_HOST = baseUrl ? baseUrl : `https://sit.api.pledgx.com/api/v1`;

  if (get(action, "data")) return next(action);
  let headers = {};

  const authData = localStorage.getItem("Authorization");
  if (authData) {
    headers = { Authorization: authData };
  }

  if (file) {
    headers = { ...headers, "Content-Type": "multipart/form-data" };
  }

  if (header) {
    headers = { ...headers, ...header };
  }

  let info = {
    url: `${API_HOST}${endpoint}`,
    method,
    data: body,
    headers,
    params,
  };

  if (responseType) {
    info = {
      url: `${API_HOST}${endpoint}`,
      method,
      data: body,
      headers,
      params,
      responseType,
    };
  }

  //   if (!skipAuth) {
  //     try {
  //       const authData = localStorage.getItem("authorization");
  //       const data = JSON.parse(authData);
  //       if (!data.id_token || !data.access_token) redirectToLoginPage();
  //       else {
  //         headers = {
  //           "Id-Token": data.id_token,
  //           "Access-Token": data.access_token,
  //         };
  //       }
  //     } catch (e) {
  //       redirectToLoginPage();
  //     }
  //   }

  axios(info)
    .then((res) => {
      const data = get(res, "data");
      if (onSuccess) onSuccess(data);
      return next({
        ...action,
        payload: data,
      });
    })
    .catch((err) => {
      if (
        err &&
        err.response &&
        err.response.status === 401 &&
        err.response.message === "SESSION_ID_INVALID"
      ) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      if (onFailed) onFailed(err);
      return next({
        ...action,
        error: get(err, "response.status"),
      });
    });

  return false;
};
