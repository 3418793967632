import React, { useEffect } from "react";
import {
    StyledIncrementUpButton,
    StyledIncrementDownButton,
    StyledPageScrollPaper,
    StyledPageInfo
} from './styles';


export const PageScroll = ({ pageNumber, numPages, nextPage, prevPage, setPageNumber }) => {
    useEffect(() => {
        if(pageNumber > numPages) {
            setPageNumber(1);
        }
    })

    return(
        <StyledPageScrollPaper elevation={0}>
            <StyledIncrementDownButton
                pageNumber={pageNumber}
                numPages={numPages}
                onClick={prevPage}
            >
                {"<"}
            </StyledIncrementDownButton>
            <StyledPageInfo>
                Page {pageNumber} of {numPages}
            </StyledPageInfo>
            <StyledIncrementUpButton
                onClick={nextPage}
                pageNumber={pageNumber}
                numPages={numPages}
            >
                {">"}
            </StyledIncrementUpButton>
        </StyledPageScrollPaper>
    );
}
