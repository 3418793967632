import React from 'react';
import {
  StyledTextField,
  StyledLabelText,
  StyledMulti,
} from './styles';


export const MultilineTextField = ({
  label,
  placeholder,
  row,
  width,
  noBorderColor,
  value,
  setValue,
  margin
 }) => {
  return (
    <StyledMulti width={width} margin={margin} >
      <StyledLabelText>{label}</StyledLabelText>
        <StyledTextField
          multiline
          rows={row}
          width={width}
          placeholder={placeholder}
          noBorderColor={noBorderColor}
          variant="outlined"
          value={value}
          onChange={(e) => setValue(e)}
        />
    </StyledMulti>
  )
};
