export const getNews = (categories = "") => (dispatch) => {
  dispatch({
    type: "GET_NEWS",
    payload: {
      endpoint: `/news?categories=${categories}`,
      method: "GET",
    },
  });
};

export const saveArticle = (article) => (dispatch) => {
  dispatch({
    type: "SAVE_ARTICLE",
    payload: {
      endpoint: "/news/saved",
      method: "POST",
      body: {
        title: article.title,
        link: article.link,
        category: article.category,
        minutes: article.minutes,
        image: article.image,
      },
    },
    article,
  });
};

export const deleteArticle = (article) => (dispatch) => {
  dispatch({
    type: "DELETE_ARTICLE",
    payload: {
      endpoint: "/news/saved",
      method: "DELETE",
      body: {
        link: article.link,
      },
    },
    article,
  });
};

export const getSavedNews = () => (dispatch) => {
  dispatch({
    type: "GET_SAVED_NEWS",
    payload: {
      endpoint: "/news/saved",
      method: "GET",
    },
  });
};
