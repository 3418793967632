import React from 'react';
import Input from '../../../../components/inputField';


export const MobileYearsNumber = ({
  data,
  setData,
  year,
}) => {

  return (
    <>
      <Input
        id="fullWidthInputWrap"
        label='Independent Classification Code'
        type="text"
        value={(data[`${year}`] && data[`${year}`]["emr_worksheet_independent_classification_code"]) ? data[`${year}`]["emr_worksheet_independent_classification_code"] : ''}
        marginTop="0px"
        labelSize="10px"
        setValue={(val) => setData(`safety_records_usa.${year}.emr_worksheet_independent_classification_code`, val)}
        validateFunc={(item) => String(item).length > 0}
        noCheckIcon={true}
        noErrorBorder={true}
      />
      <Input
        id="fullWidthInputWrap"
        label='Experience Modification Rating'
        type="text"
        value={(data[`${year}`] && data[`${year}`]["emr_worksheet_electronic_medical_record"]) ? data[`${year}`][`emr_worksheet_electronic_medical_record`] : ''}
        marginTop="0px"
        labelSize="10px"
        setValue={(val) => setData(`safety_records_usa.${year}.emr_worksheet_electronic_medical_record`, val)}
        validateFunc={(item) => item > 0}
        noCheckIcon={true}
        noErrorBorder={true}
      />
      <Input
        id="fullWidthInputWrap"
        label={'Total Hours for All Employees'}
        type="text"
        value={(data[`${year}`] && data[`${year}`][`osha_300a_total_hours_for_all_employees`]) ? data[`${year}`][`osha_300a_total_hours_for_all_employees`] : ''}
        marginTop="0px"
        labelSize="10px"
        setValue={(val) => setData(`safety_records_usa.${year}.osha_300a_total_hours_for_all_employees`, val)}
        validateFunc={(item) => item > 0}
        noCheckIcon={true}
        noErrorBorder={true}
      />

      <Input
        id="fullWidthInputWrap"
        label={'Total Number of Deaths'}
        type="text"
        value={(data[`${year}`] && data[`${year}`][`osha_300a_total_number_of_deaths`]) ? data[`${year}`][`osha_300a_total_number_of_deaths`] : ''}
        marginTop="0px"
        labelSize="10px"
        setValue={(val) => setData(`safety_records_usa.${year}.osha_300a_total_number_of_deaths`, val)}
        validateFunc={(item) => item > 0}
        noCheckIcon={true}
        noErrorBorder={true}
      />

      <Input
        id="fullWidthInputWrap"
        label='Total Number of Other Cases'
        type="text"
        value={(data[`${year}`] && data[`${year}`][`osha_300a_total_number_of_other_cases`]) ? data[`${year}`][`osha_300a_total_number_of_other_cases`] : ''}
        marginTop="0px"
        labelSize="10px"
        setValue={(val) => setData(`safety_records_usa.${year}.osha_300a_total_number_of_other_cases`, val)}
        validateFunc={(item) => item > 0}
        noCheckIcon={true}
        noErrorBorder={true}
      />
      <Input
        id="fullWidthInputWrap"
        label='Total Number of Absent Days'
        type="text"
        value={(data[`${year}`] && data[`${year}`][`osha_300a_total_number_of_absent_days`]) ? data[`${year}`][`osha_300a_total_number_of_absent_days`] : ''}
        marginTop="0px"
        labelSize="10px"
        setValue={(val) => setData(`safety_records_usa.${year}.osha_300a_total_number_of_absent_days`, val)}
        validateFunc={(item) => item > 0}
        noCheckIcon={true}
        noErrorBorder={true}
      />
    </>
  )
};
