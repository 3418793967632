import React, { useState } from "react";
import { CloseButton, FormTitle, InfoArea, StyledPaper } from "./styles";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Input from "../inputField";
import PledgxLogo from "../../assets/common/pledgxLogo";
import { FunctionalButton } from "../functionalButton";
import { addPackage } from "../../data/actions/dataMaintenance"
import { useDispatch } from "react-redux";
import updateObjectByPath from "../../common/updateObjectByPath";
import { formatPhoneNumber } from "../../common/formatPhoneNumber";
import { SuccessPopup } from "../../pages/adminToolsDashboard/SuccessPopup";

export const AddPackagePopup = ({
  setOpenDialog,
  openDialog,
  isMobile,
  label,
  buttonLabel
}) => {


  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);


  const handleSave = () => {
    dispatch(
      addPackage(
        data,
        () => {
          setOpenSuccessPopup(true);
          setData({});
        },
        () => { }
      )
    );
  };

  const handleClose = () => {
    setOpenDialog(false);
  };


  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };



  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper elevation={0} isMobile={isMobile}>
        <CloseButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
        <FormTitle style={{ marginTop: 10 }}>
          {label || "Add Plan"}
        </FormTitle>
        <InfoArea isMobile={isMobile} marginTop="0px">
          <Input
            id="fullWidthInputWrap"
            label="Plan Name"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.package_name}
            setValue={(val) => onChangeData("package_name", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noErrorBorder={true}
          />

          <Input
            id="inputWrap"
            label="Number of references"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.number_of_references}
            setValue={(val) => onChangeData("number_of_references", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noErrorBorder={true}
          />

          <Input
            id="inputWrap"
            label="Number of Projects"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={formatPhoneNumber(data?.number_of_projects)}
            setValue={(val) => onChangeData("number_of_projects", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noCancelIcon={true}
            noErrorBorder={true}
          />

          <Input
            id="oneFourthInputWrap"
            label="# of users"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.number_of_users}
            setValue={(val) => onChangeData("number_of_users", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noErrorBorder={true}
          />


          <Input
            id="oneFourthInputWrap"
            label="# of Personnel"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.number_of_personnel}
            setValue={(val) => onChangeData("number_of_personnel", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noCancelIcon={true}
            noErrorBorder={true}
          />


          <Input
            id="oneFourthInputWrap"
            label="File Limit Count"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.file_limit_count}
            setValue={(val) => onChangeData("file_limit_count", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noCancelIcon={true}
            noErrorBorder={true}
          />


          <Input
            id="oneFourthInputWrap"
            label="File Limit Size"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.file_limit_size}
            setValue={(val) => onChangeData("file_limit_size", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noCancelIcon={true}
            noErrorBorder={true}
          />

          <Input
            id="inputWrap"
            label="Number of CQS-CCDC(Monthly)"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.number_of_cqs_ccdc_monthly}
            setValue={(val) => onChangeData("number_of_cqs_ccdc_monthly", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noCancelIcon={true}
            noErrorBorder={true}
          />

          <Input
            id="inputWrap last"
            label="Number of CQS-PledgX(Monthly)"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.number_of_cqs_pledgx_monthly}
            setValue={(val) => onChangeData("number_of_cqs_pledgx_monthly", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noCancelIcon={true}
            noErrorBorder={true}
          />

          <Input
            id="inputWrap"
            label="Number of CQS-CCDC(Yearly)"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.number_of_cqs_ccdc_yearly}
            setValue={(val) => onChangeData("number_of_cqs_ccdc_yearly", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noCancelIcon={true}
            noErrorBorder={true}
          />

          <Input
            id="inputWrap last"
            label="Number of CQS-PledgX(Yearly)"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.number_of_cqs_pledgx_yearly}
            setValue={(val) => onChangeData("number_of_cqs_pledgx_yearly", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noCancelIcon={true}
            noErrorBorder={true}
          />

        </InfoArea>

        <FunctionalButton
          width="90%"
          marginTop="16px"
          marginRight="10px"
          handleButton={() => {
            handleSave();
          }}
          buttonTitle={buttonLabel || "ADD"}
        />
        <SuccessPopup
          setOpenPopup={setOpenSuccessPopup}
          openPopup={openSuccessPopup}
          isMobile={isMobile}
        />
      </StyledPaper>
    </Dialog>
  );
};
