import React from "react";
import { FunctionalButton } from 'components/functionalButton';
import { Popup } from 'components/popup';
import { CloseButton } from "components/closeButton";
import PylonIcon from "assets/common/pylonIcon.png";

import {
  TitleDiv,
  TitleText,
  PopupContainer
} from "pages/referenceModule/rightComponents/styles.js";

export const AlertMessage = ({
  openPopup,
  setOpenPopup,
  message, // Custom message for unsaved changes
  confirmAction, // Action to perform on confirmation
  cancelAction, // Action to perform on cancel
  isMobile
}) => {
  const icon = <img src={PylonIcon} alt="Alert Icon" />;

  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width={isMobile ? "100%" : "680px"}
      height={isMobile ? "100vh" : "434px"}
      dialogContent={(
        <>
          <CloseButton handleClose={cancelAction} />
          <PopupContainer>
            <TitleDiv>
              {icon}
            </TitleDiv>

            <TitleText fontSize="18px" fontWeight="600" color="black" textAlign="center" margin="30px 16px 16px">
              {message || "Are you sure you want to leave without saving changes?"}
            </TitleText>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {/* Cancel Button */}
              <FunctionalButton
                buttonTitle="CANCEL"
                handleButton={cancelAction}
                isReversedColor={true}
                height="48px"
                width="150px"
                marginTop="40px"
                marginRight="10px"
              />
              {/* Confirm Leave Button */}
              <FunctionalButton
                buttonTitle="LEAVE"
                handleButton={confirmAction}
                isReversedColor={false}
                height="48px"
                width="150px"
                marginTop="40px"
              />
            </div>
          </PopupContainer>
        </>
      )}
    />
  );
};
