import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from "../../../constants";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@material-ui/lab/TabPanel";
import Typography from "@material-ui/core/Typography";

export const TabBox = styled(Box)`
  && {
    width: 100%;
    margin-top: 36px;
    display: flex;
    padding-left: 16px;
  }
`;

export const StyledTab = styled(Tab)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 18px;
    font-weight: 500;
    text-transform: none;
    color: ${COLORS.blurOutText};
    padding-right: 8px;
    padding-left: 8px;
  }
  &.css-1q2h7u5.Mui-selected {
    color: black;
  }
`;

export const StyledTabsContainer = styled(Tabs)``;

export const DividerLine = styled.div`
  width: 0.5px;
  height: 16px;
  left: 1243px;
  top: 158px;
  border: 1px solid ${COLORS.secondaryOrange};
  margin-top: 14px;
`;

export const StyledTabPanel = styled(TabPanel)`
  && {
    padding-top: 10px;
    padding-right: 10px;
  }
`;

export const PersonnelContent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px;
  align-items: center;
`;

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-left: 16px;
`;

export const IconContainer = styled.span`
  display: flex;
  align-items: center;
  margin-right: 9px;
`;

export const SeeAllText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: ${COLORS.blurOutText};
    cursor: pointer;
    margin-top: 16px;

    &:hover {
      color: ${COLORS.primaryOrange};
    }
  }
`;
export const StyledDownloadedContainer = styled.div`
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  background-color: ${COLORS.lightGreen};
  border-radius: 16px;
  color: ${COLORS.primaryGreen};
  align-items: center;
  margin-right: 10px;
  font-size: 10px;
  width: 72px;
`;

export const MobileColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MobileColumnText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${(props) => props.fontSize || "13px"};
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || "500"} !important;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: ${COLORS.columnGreyText};
    width: 100%;
  }
`;
export const DetailArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;
export const HeaderArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${(props) => props.width || "100%"};
`;
