import styled from 'styled-components';
import { StyledAddText } from "../../../userOnBoardingPage/components/styles";

export const StyledCategoriesArea = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => !props.isMobile && props.width ? props.width : "100%"};
`;

export const SmallAddText = styled(StyledAddText)`
  &&{
    font-size: 14px;
  }
`;  