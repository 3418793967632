import React from "react";

export const SandClockIcon = () => {
  return (
    <svg
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="24.5676"
        cy="24.3333"
        rx="23.7108"
        ry="23.7647"
        fill="#FFAA7B"
      />
      <ellipse
        cx="24.5676"
        cy="24.3334"
        rx="19.265"
        ry="19.3088"
        fill="#FF6D1D"
      />
      <path
        d="M27.5223 14.5686H21.0423C17.2823 14.5686 16.9923 17.9486 19.0223 19.7886L29.5423 29.3486C31.5723 31.1886 31.2823 34.5686 27.5223 34.5686H21.0423C17.2823 34.5686 16.9923 31.1886 19.0223 29.3486L29.5423 19.7886C31.5723 17.9486 31.2823 14.5686 27.5223 14.5686Z"
        stroke="#FFAA7B"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
