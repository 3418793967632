import React, { useState, useEffect } from "react";
import { FunctionalButton } from "../../../../components/functionalButton";
import { StyledYesNoRow } from './styles';


export const ButtonYesNo = ({ value, setQualificationData, attribute }) => {
  const [disabledYesButton, setDisabledYesButton] = useState((typeof value == "boolean") ? (value? false : true) : true);
  const [disabledNoButton, setDisabledNoButton] = useState((typeof value == "boolean") ? (value? true : false) : true);

  const handleYes = () => {
    setQualificationData(attribute, true)
    if (disabledYesButton){
      setDisabledYesButton(!disabledYesButton);
    }
    if (!disabledNoButton) {
      setDisabledNoButton(true);
    }
  }

  const handleNo = () => {
    setQualificationData(attribute, false)
    if (disabledNoButton){
      setDisabledNoButton(!disabledNoButton);
    }
    if (!disabledYesButton) {
      setDisabledYesButton(true);
    }
  }

  return (
    <StyledYesNoRow>
      <FunctionalButton
        buttonTitle="Yes"
        width="138px"
        height="40px"
        marginRight="16px"
        isReversedColor={disabledYesButton}
        handleButton={handleYes}
      />
      <FunctionalButton
        buttonTitle="No"
        width="138px"
        height="40px"
        isReversedColor={disabledNoButton}
        handleButton={handleNo}
      />
    </StyledYesNoRow>
  )
}
