import React, {useState} from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FunctionalButton } from 'components/functionalButton';

import {
  Title,
  PopupContainer
} from './styles';

export const Panel1 = ({
  setPanel,
  isMobile
}) => {
  const [isProject, setIsProject] = useState('')
  const handleChoice = (confirmed) => {
    if(confirmed) {
      setIsProject(true);
    } else {
      setIsProject(false);
    }
  }

  return (
    <>
    <Title fontWeight={500} margin="40px 0 0">How would you like to choose your referee?</Title>
    <FunctionalButton
      buttonTitle="PROJECT SPECIFIC"
      width={isMobile ? "90%" : "368px"}
      height="48px"
      marginRight="0px"
      marginTop={isMobile ? "16px" : "64px"}
      isReversedColor={(typeof isProject == "boolean") ? !isProject : true}
      handleButton={() => handleChoice(true)}
    />
    <FunctionalButton
      buttonTitle="GENERIC"
      width={isMobile ? "90%" : "368px"}
      height="48px"
      marginRight="0px"
      marginTop="24px"
      isReversedColor={(typeof isProject == "boolean") ? isProject : true}
      handleButton={() => handleChoice(false)}
    />

    <FunctionalButton
      buttonTitle={"NEXT"}
      handleButton={(typeof isProject== "boolean" && isProject) ? 
                    () => setPanel(2) : () => setPanel(4)}
      width={isMobile ? "90%" : "368px"}
      marginRight="0px"
      marginTop={"72px"}
      disabled={!(typeof isProject == "boolean")}
    />
    </>

  )
}
