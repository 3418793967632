import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import {
  OnBoardRoot,
  FormContainer,
  Content,
} from "../styles";

import { InfoArea } from '../components/styles';

import { LargeText, SmallText } from '../verificationPage/styles';
import { ErrorText, ErrorDiv } from './styles';
import { FunctionalButton } from "../../../components/functionalButton";
import { resetPassword } from '../../../api/onboarding';
import { checkResetToken } from "../../../api/onboarding";
import Input from '../../../components/inputField';

const uppercaseRE = /(?=.*?[A-Z])/;
const lowercaseRE = /(?=.*?[a-z])/;
const numberRE = /(?=.*?[0-9])/;
const eightMoreRE = /^[\s\S]{8,}$/;
const passwordRE = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/

function ResetPasswordPage(props) {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();

  const [panel, setPanel] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    checkToken(token);
  }, []);

  useEffect(() => {
    setErrorText("");
  }, [password, password2]);

  const onSuccess = () => {
    setPanel(1);
  }

  const onFailure = () => {
    setPanel(2);
  }
  
  const successReset = () => {
    setPanel(3);
  }

  const failedReset = (error) => {
    setErrorText(error?.data?.message);
  }

  const handleReset = () => {
    resetPassword(token, {password}, successReset, failedReset)
  };

  const checkToken = (token) => {
    checkResetToken(token, onSuccess, onFailure)
  }

  return (
    <OnBoardRoot isMobile={isMobile}>
      <FormContainer isMobile={isMobile}>
        <Content>
        {panel == 1 && (
          <InfoArea isMobile={isMobile} width="650px" isColumn={true}>
           <LargeText isMobile={isMobile}>Reset password</LargeText>
           {errorText && (
              <ErrorDiv marginTop="16px" marginBottom="-10px">
                <ErrorText>{errorText}</ErrorText>
              </ErrorDiv>
            )}
            <Input
            autoComplete="new-password"
            label="Password"
            type="password"
            placeholder="Password"
            labelSize="12px"
            textAreaHeight="64px"
            fontSize="18px"
            value={password}
            setValue={(val) => setPassword(val)}
            validateFunc={(item) => passwordRE.test(item)}
            hasError={password.length > 0 && !passwordRE.test(password)}
            noCheckIcon={true}
            // noErrorBorder={true}
          />
          <ErrorDiv>
            {password.length > 0 && !uppercaseRE.test(password) && (
              <ErrorText>At least 1 uppercase letter should exists.</ErrorText>
            )}
            {password.length > 0 && !lowercaseRE.test(password) && (
              <ErrorText>At least 1 lowercase letter should exists.</ErrorText>
            )}
            {password.length > 0 && !numberRE.test(password) && (
              <ErrorText>At least 1 digit should exists.</ErrorText>
            )}
            {password.length > 0 && !eightMoreRE.test(password) && (
              <ErrorText>Should contain at least 8 characters.</ErrorText>
            )}
          </ErrorDiv>
          <Input
            autoComplete="new-password"
            label="Confirm Password"
            type="password"
            placeholder="Confirm Password"
            labelSize="12px"
            textAreaHeight="64px"
            fontSize="18px"
            value={password2}
            setValue={(val) => setPassword2(val)}
            validateFunc={(item) => passwordRE.test(item)}
            hasError={password2.length > 0 && !passwordRE.test(password)}
            noCheckIcon={true}
            // noErrorBorder={true}
          />
          {password !== password2 && (
            <ErrorDiv>
              <ErrorText>Password does not match!</ErrorText>
            </ErrorDiv>
          )}
           <FunctionalButton
             buttonTitle="Reset"
             handleButton={handleReset}
             width="200px"
             marginRight="0px"
             marginTop="40px"
             disabled={(password !== password2) || (!passwordRE.test(password))}
           />
          </InfoArea>
        )}
        {panel == 2 && (
          <InfoArea isMobile={isMobile} width="650px">
           <LargeText isMobile={isMobile} width="650px">The reset password link is invalid or has expired.</LargeText>
          </InfoArea>
        )}
        {panel == 3 && (
          <InfoArea isMobile={isMobile} width="650px" isColumn={true}>
           <LargeText isMobile={isMobile} width="650px">Your password has been changed.</LargeText>
           <SmallText isMobile={isMobile}>Log in to use PledgXQualify</SmallText>
           <FunctionalButton
             buttonTitle="Login"
             handleButton={() => history.push("/account/login")}
             width="200px"
             marginRight="0px"
             marginTop="40px"
             isReversedColor={true}
           />
          </InfoArea>
        )}
        </Content>
      </FormContainer>
    </OnBoardRoot>
  )
}

export default ResetPasswordPage;