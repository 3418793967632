import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { COLORS } from "../../constants";
export const StyledDatePicker = styled(DatePicker)``;
export const StyledMainDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  width: ${(props) => (props.width ? props.width : "50%")};
  margin: 0px 10px 0px 0;
  height: ${(props) => (props.height ? props.height : "48px")};
  background-color: ${(props) => (props.disabled ? "#EBE9F1" : "transparent")};
`;

export const StyledInput = styled.input`
  flex: 1;
  width: 70%;
  border: none;
  outline: none;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
  color: black;
  background-color: transparent;

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #d0c9d6;
  }
`;

export const StyledReadonlyInput = styled.input`
  flex: 1;
  width: ${(props) => (props.width ? props.width : "60%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  border: ${(props) => (props.showBorder ? props.showBorder : "none")};
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : "0px")};
  padding-right: ${(props) =>
    props.paddingRight ? props.paddingRight : "15px"};
  border-radius: 4px;
  outline: none;
  font-weight: 500;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  color: ${(props) =>
    props.fontColor ? props.fontColor : `${COLORS.darkGrey1}`};
  box-sizing: border-box;
  background-color: ${(props) =>
    props.backgroundColor ? COLORS.backgroundGrey : "transparent"};
  overflow-wrap: anywhere;
`;
export const StyledInnerDiv = styled.div`
  padding-right: ${(props) =>
    props.paddingRight ? props.paddingRight : "0px"};
  width: 100%;
  position: relative;
  background-color: ${(props) =>
    props.disabled ? COLORS.backgroundGrey : "transparent"};
`;
export const InputLabel = styled.div`
  margin-bottom: 5px;
  display: flex;
  font-style: normal;
  font-weight: ${(props) => props.labelWeight || "normal"};
  font-size: ${(props) => props.labelSize || "12px"};
  line-height: 20px;
  color: #000000;
`;
export const Error = styled.div`
  font-size: 12px;
  color: #dc3545;
  margin-top: 4px;
  position: absolute;
  bottom: -18px;
`;

export const StyledSelect = styled.select`
  margin: 0px 5px 0px 5px;
  overflow: auto !important;

  max-height: 100px;
  &:not(:-internal-list-box) {
    overflow: auto !important;
  }
`;
