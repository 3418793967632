import React from 'react';
import quality from './quality.svg';
import { StyledImg } from './styles';
const QualityIcon = ({ width, height, noGreyFilter }) => {
  return (
    <StyledImg width={width} heigh={height} src={quality} noGreyFilter={noGreyFilter} />
  )
};

export default QualityIcon;
