import React from "react";
import { FunctionalButton } from '../../../components/functionalButton';
import PylonIcon from "assets/common/pylonIcon.png";

import {
  ImagePopup,
  LargeTextSuccess,
  SmallText,
  ButtonArea,
  PopupContainer,
} from './styles';
import { Popup } from '../../../components/popup';
import { CloseButton } from "components/closeButton";

export const PendingAlert = ({
  openDialog,
  setOpenDialog,
  setOpenPopup,
  buttonText = 'CONFIRM',
  descriptionText = 'This Referee has a pending reference. Would you like to replace this info?',
  titleText = 'Pending!!!',
  isMobile
}) => {

  const handleDone = () => {
    setOpenDialog(false);
    setOpenPopup(true);
  };


  const handleClose = () => {
    setOpenDialog(false);
    setOpenPopup(false);
  };


  return (
    <Popup
      open={openDialog}
      setOpen={setOpenDialog}
      width={isMobile ? "50%" : "550px"}
      height={isMobile ? "420px" : "520px"}
      dialogContent={(
        <>
          <PopupContainer>
            <CloseButton handleClose={handleClose} />
            <ImagePopup>
              <img src={PylonIcon} />
            </ImagePopup>

            <LargeTextSuccess isMobile={isMobile}>{titleText}</LargeTextSuccess>
            <SmallText>
              {descriptionText}
            </SmallText>
          </PopupContainer>
          <ButtonArea isMobile={isMobile}>
            <FunctionalButton
              buttonTitle={buttonText}
              width="50%"
              height={isMobile ? "48px" : "53px"}
              marginRight="16px"
              handleButton={handleDone}
            />

            <FunctionalButton
              buttonTitle="GO BACK"
              width="50%"
              height={isMobile ? "48px" : "53px"}
              marginRight="0px"
              handleButton={handleClose}
            />
          </ButtonArea>
        </>
      )}
    />

  )
}
