import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { SideNav } from './SideNav';
import { ScreenRoot, FormContainer } from '../styles';
import { SideBarContainer } from './styles';
import { Dashboard } from './Dashboard';
import { DataMaintenance } from '../dataMaintenance/DataMaintenance';
import { UserOnboarding } from '../clientOnboarding/UserOnboarding';
import { Reports } from '../../adminToolsDashboard/reports/Reports';

import DrawerSideNav from "./DrawerSideNav";


function SwitchComponents({ active, children }) {
  return children.filter((child) => child.props.step === active);
};

export default function AdminToolsSetup() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [activeStep, setActiveStep] = useState(0);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);

  return (
    <ScreenRoot isMobile={isMobile}>
      {isMobile && (
        <DrawerSideNav
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          isMobile={isMobile}
        />
      )}
      {!isMobile && (
        <SideBarContainer isMobile={isMobile}>
          <SideNav
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isMobile={isMobile}
          />
        </SideBarContainer>
      )}

      <FormContainer isMobile={isMobile} leftBorder={true}>
        <SwitchComponents active={activeStep}>
          <Dashboard
            step={0}
            isMobile={isMobile}
            setOpenSuccessPopup={setOpenSuccessPopup}
          />
          <DataMaintenance
            step={1}
            isMobile={isMobile}
            setOpenSuccessPopup={setOpenSuccessPopup}
          />
          <UserOnboarding
            step={2}
            isMobile={isMobile}
            setOpenSuccessPopup={setOpenSuccessPopup}
          />
          <Reports
            step={3}
            isMobile={isMobile}
            setOpenSuccessPopup={setOpenSuccessPopup}
          />
        </SwitchComponents>
      </FormContainer>
    </ScreenRoot>
  );
}
