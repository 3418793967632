import { RatingSlider } from "components/ratingSlider";
import React, { useEffect, useState } from "react";


import {
  ScaleDiv,
  InsideDisplay,
  DarkTitle
} from './styles';

export const Scale = ({
  scaleData,
  onChangeAnswer
}) => {
  const [scale, setScale] = useState([...scaleData])
 

  useEffect(() => {
    onChangeAnswer('assessment.scale', scale)
  }, [scale])

  const setPoint = (val, i) => {
    const tmp = [...scale];
    tmp[i]["point"] = val;
    setScale(tmp);
  }

  return (
    <ScaleDiv>
      <InsideDisplay>
        <DarkTitle fontWeight="500" fontSize="18px" margin="0px 0px 32px -8px">Rate your experience with the contractor on a scale of 1 (worst) - 10 (best) on the following...</DarkTitle>
        {scale?.length > 0 &&
          scale.map((each, i) => {
            return (
              <>
               
                <RatingSlider
                  value={each?.point}
                  setValue={(val) => setPoint(val, i)}
                  header={each?.question}
                />
              </>
            )
          })}
      </InsideDisplay>
    </ScaleDiv>
  )
}