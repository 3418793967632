import React, { useEffect, useState } from "react";

import { StyledBox, SmallText, GreySmallText } from "../styles";
import { truncateString } from "../../../common/truncateString";
import { PersonnelContent, TitleDiv } from "./styles";

export const ReportsCard = ({ name, location }) => {
  return (
    <>
      <StyledBox boxHeight="80px" borderRadius="10px" margin="0 0 20px 0">
        <TitleDiv>
          <SmallText>{truncateString(name, 31)}</SmallText>
          <GreySmallText>{truncateString(location, 31)}</GreySmallText>
        </TitleDiv>
      </StyledBox>
    </>
  );
};
