import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PageFiveUnderwayProjectsDetails from './pageFiveUnderwayProjectsDetails';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 0,
        justifyContent: 'flex-start',
        width: '100%',
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30,
    },
    underwayProjects: {
        marginTop: -40,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
    },
    underwayProjectsWithoutLogo: {
        marginTop: -7,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
        position:'relative',
    },
});

const PageFiveUnderwayProjects = ({ prequalifyData, companyLogo }) => (
    <View style={styles.headerContainer}>
        {companyLogo !== ""  ? <Text style={styles.underwayProjects}>Underway Projects</Text> : <Text style={styles.underwayProjectsWithoutLogo}>Underway Projects</Text> }
        <View>
            <PageFiveUnderwayProjectsDetails items={prequalifyData} />
        </View>
    </View>
);

export default PageFiveUnderwayProjects;
