import styled from "styled-components";
import { TYPOGRAPHY, COLORS } from "../../../constants";

export const PersonnelCardRoot = styled.div`
  position: relative;
  margin-right: 24px;

  width: 245px;
  min-height: 128px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  border: 1px solid ${COLORS.darkGrey3};
`;

export const PersonnelAdding = styled(PersonnelCardRoot)`
  && {
    border: 2px dashed ${COLORS.darkGrey3};
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export const PersonnelCardName = styled.div`
  font-family: ${TYPOGRAPHY.primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
`;

export const PersonnelCardPosition = styled.div`
  font-family: ${TYPOGRAPHY.primaryFont};
  margin-top: 4px;
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
`;

export const ControlButtons = styled.div`
  margin-left: 20px;
  font-family: ${TYPOGRAPHY.primaryFont};
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  cursor: pointer;
  color: #ff6d1d;
`;

export const AddText = styled.div`
  font-family: ${TYPOGRAPHY.primaryFont};
  margin-top: 4px;
  margin-left: 8px;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #000000;
`;

export const PlusIcon = styled.img`
  width: 28px;
  height: 28px;
`;

export const StyledCardContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-contents: center;
  padding: 10px 16px 10px 16px;
  min-height: 60px;
  flex-grow: 1px;
`;

export const StyledAddContent = styled(StyledCardContent)`
  &&{
    align-items: center;
  }
`;

export const StyledName = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 16px;
`;

export const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 25px;
  margin-left: 0;
  margin-right: auto;
  font-style: normal;
  font-weight:  ${props => props.labelWeight || 'normal'};
  font-size: ${props => props.labelSize || '12px'};
  line-height: 20px;
  color: #000000;
`;
