import React from "react";
import { StyledCheckBox } from "./styles";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CropSquareIcon from '@mui/icons-material/CropSquare';

export default function RoundedCheckbox({
  attributeTypes,
  onChange,
  readOnly,
  value,
  disabled,
  name
}) {
  return (
    <React.Fragment>
      <StyledCheckBox
        classes={{ root: "custom-checkbox-root" }}
        icon={<CircleUnchecked />}
        checkedIcon={<CircleCheckedFilled />}
        value={value}
        name="N/A"
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        checked={attributeTypes && attributeTypes.includes(value)}
      />
    </React.Fragment>
  );
}



export { RoundedCheckbox };
