import React from "react";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { COLORS } from "../../../constants";
import { StyledBox, StyledBoxContent, DataText } from "../styles";

import { StyledAwardsArea } from "./styles";
import QualityIcon from "assets/reference/quality";
import EfficientIcon from "assets/reference/efficient";
import OrganizedIcon from "assets/reference/organized";
import ServiceIcon from "assets/reference/service";
import AdministrationIcon from "assets/reference/administration";

export const Awards = ({ isMobile, points }) => {
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      border: "1px solid black",
      background: "white",
    },
    "&:hover": {
      cursor: "pointer",
    },
  }));

  return (
    <StyledBox boxHeight="144px">
      <DataText fontSize="18px" fontWeight="500" margin="24px 0px 0px 20px">
        Awards
      </DataText>
      <StyledAwardsArea>
        <StyledBoxContent margin="16px 5px 24px">
          {points?.quality > 0 && (
            <Tooltip
              title="Quality"
              placement="bottom-end"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: 11,
                    bgcolor: `${COLORS.primaryOrange}`,
                    "& .MuiTooltip-arrow": {
                      color: `${COLORS.primaryOrange}`,
                    },
                  },
                },
              }}
            >
              <StyledBadge badgeContent={points?.quality} overlap="circular">
                <QualityIcon width={65} height={65} noGreyFilter={true} />
              </StyledBadge>
            </Tooltip>
          )}
          {points?.efficiency > 0 && (
            <Tooltip
              title="Efficiency"
              placement="bottom-end"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: 11,
                    bgcolor: `${COLORS.primaryOrange}`,
                    "& .MuiTooltip-arrow": {
                      color: `${COLORS.primaryOrange}`,
                    },
                  },
                },
              }}
            >
              <StyledBadge badgeContent={points?.efficiency} overlap="circular">
                <EfficientIcon width={65} height={65} noGreyFilter={true} />
              </StyledBadge>
            </Tooltip>
          )}
          {points?.organization > 0 && (
            <Tooltip
              title="Organization"
              placement="bottom-end"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: 11,
                    bgcolor: `${COLORS.primaryOrange}`,
                    "& .MuiTooltip-arrow": {
                      color: `${COLORS.primaryOrange}`,
                    },
                  },
                },
              }}
            >
              <StyledBadge
                badgeContent={points?.organization}
                overlap="circular"
              >
                <OrganizedIcon width={65} height={65} noGreyFilter={true} />
              </StyledBadge>
            </Tooltip>
          )}
          {points?.service > 0 && (
            <Tooltip
              title="Service"
              placement="bottom-end"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: 11,
                    bgcolor: `${COLORS.primaryOrange}`,
                    "& .MuiTooltip-arrow": {
                      color: `${COLORS.primaryOrange}`,
                    },
                  },
                },
              }}
            >
              <StyledBadge badgeContent={points?.service} overlap="circular">
                <ServiceIcon width={65} height={65} noGreyFilter={true} />
              </StyledBadge>
            </Tooltip>
          )}
          {points?.administration > 0 && (
            <Tooltip
              title="Administration"
              placement="bottom-end"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: 11,
                    bgcolor: `${COLORS.primaryOrange}`,
                    "& .MuiTooltip-arrow": {
                      color: `${COLORS.primaryOrange}`,
                    },
                  },
                },
              }}
            >
              <StyledBadge
                badgeContent={points?.administration}
                overlap="circular"
              >
                <AdministrationIcon
                  width={65}
                  height={65}
                  noGreyFilter={true}
                />
              </StyledBadge>
            </Tooltip>
          )}
        </StyledBoxContent>
      </StyledAwardsArea>
    </StyledBox>
  );
};
