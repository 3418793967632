import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PageFiveComparableProjectsDetails from './pageFiveComparableProjectsDetails';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 0,
        justifyContent: 'flex-start',
        width: '100%',
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30,
    },
    comparableProjects: {
        marginTop: -40,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
    },
    comparableProjectsWithoutLogo: {
        marginTop: -7,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
        position:'relative',
    },
});

const PageFiveComparableProjects = ({ prequalifyData, companyLogo }) => (
    <View style={styles.headerContainer}>
        {companyLogo !== ""  ? <Text style={styles.comparableProjects}>Comparable Projects</Text> : <Text style={styles.comparableProjectsWithoutLogo}>Comparable Projects</Text> }
        <View>
            <PageFiveComparableProjectsDetails items={prequalifyData} />
        </View>
    </View>
);

export default PageFiveComparableProjects;
