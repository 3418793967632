import React, { useState, useEffect } from "react";
import { filterPersonnels } from "api/prequalify";
import IncrementalSearchPickList from "components/incrementalSearchPickList";
import { StyledImgIcon } from "./styles";
import { StyledTableCell } from "components/filesListTable/styles";
import PlusSignIcon from "assets/buyers/plusSignIcon";
import { checkPersonnelLimit } from "api/dashboard";
import { AlertPopup } from "components/alertPopup/alertPopup";
import { useSelector } from "react-redux";
import { contactSelector } from "../../../../../data/selectors/contact";

export const SelectingProjects = ({
  add,
  projects,
  setProjects,
  setOpenPopup,
  setPrequalifyData,
  prequalifyData,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [personnelList, setPersonnelList] = useState("");
  const [selected, setSelected] = useState(false);
  const [openExceedPopup, setOpenExceedPopup] = useState(false);
  const contactData = useSelector(contactSelector);
  const personnelLimit = contactData?.user_access_control?.number_of_personnel;
  // const headers = [
  //   { title: '' },
  //   { title: 'Project #' },
  //   { title: 'Project Title' },
  //   { title: 'Project Location' },
  // ];

  // const handleProjectSelection = (bodyData) => {

  //   const filteredTemp = [...filteredData];
  //   const projectsTemp = [...projects];

  //   projectsTemp.unshift({
  //     id: bodyData.id,
  //     firstName: bodyData.firstName,
  //     position: bodyData.position,
  //     location: bodyData.project_location,
  //     type: ['key'],
  //   });

  //   setProjects(projectsTemp);
  //   filteredTemp.splice(bodyData.index, 1);
  //   setFilteredData(filteredTemp);
  // };
  const handleProjectSelection = (bodyData) => {
    const filteredTemp = [...filteredData];
    const personnelsTemp = [...projects];

    personnelsTemp.unshift({
      id: bodyData.id,
      firstName: bodyData.firstName,
      lastName: bodyData.lastName,
      position: bodyData.position,
      type: ["key_site"],
    });

    setProjects(personnelsTemp);
    filteredTemp.splice(bodyData.index, 1);
    setFilteredData(filteredTemp);
  };

  useEffect(() => {
    handleFilterPersonnel();
  }, []);

  const handleFilterPersonnel = async () => {
    await filterPersonnels("")
      .then((data) => {
        setPersonnelList(data);
      })
      .catch((err) => {});
  };

  const handleAdd = () => {
    if (personnelList && personnelList.length >= personnelLimit) {
      setOpenExceedPopup(true);
    } else {
      setOpenPopup(true);
    }
    // checkPersonnelLimit(() => setOpenPopup(true), () => setOpenExceedPopup(true))
  };

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCell
          component={"tr"}
          onClick={() => handleProjectSelection(bodyData)}
        >
          <StyledImgIcon src={PlusSignIcon} />
        </StyledTableCell>
        <StyledTableCell
          component={"tr"}
          onClick={() => handleProjectSelection(bodyData)}
        >
          {bodyData.id}
        </StyledTableCell>
        <StyledTableCell
          component={"tr"}
          onClick={() => handleProjectSelection(bodyData)}
        >
          {bodyData.firstName} {bodyData.lastName}
        </StyledTableCell>
        <StyledTableCell
          component={"tr"}
          onClick={() => handleProjectSelection(bodyData)}
        >
          {bodyData.position}
        </StyledTableCell>
      </>
    );
  };

  return (
    <>
      <IncrementalSearchPickList
        add={handleAdd}
        // headers={headers}
        body={body}
        conciseLength={3.5}
        filterApi={filterPersonnels}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        addButtonTitle="Add Personnel"
        handleSelected={() => setSelected(true)}
        hover={true}
        marginTop={"24px"}
        placeholder={"ID, Name, Position..."}
      />
      <AlertPopup
        openPopup={openExceedPopup}
        setOpenPopup={setOpenExceedPopup}
        headerText="You have exceeded the personnel limit."
        subHeaderText={`You can add a maximum of ${personnelLimit} personnel.`}
        showButton={true}
      />
    </>
  );
};
