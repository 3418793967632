import React, { useEffect } from "react";

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper,
  StyledMenuItem,
  StyledButton,
  StyledButtonReview,
  StyledButtonContainer,
  HighLightText,
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import PledgxLogo from "../../assets/common/pledgxLogo";

export const ConfirmInvitationPopUp = ({
  setOpenDialog,
  openDialog,
  isMobile,
  handleFinalizeClick,
  item,
  addedItem,
}) => {
  useEffect(() => {
    if (!openDialog) {
      setOpenDialog(false);
    }
  }, [openDialog]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  // add handle Go Back Button
  const handleGoBackClick = () => {
    handleClose();
  };

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper elevation={0} isMobile={isMobile}>
        <CloseButton aria-label="close" onClick={() => handleClose()}>
          <CloseIcon />
        </CloseButton>

        <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
        <InfoArea isMobile={isMobile} marginTop="0px">
          <FormTitle style={{ marginTop: 20, marginBottom: 0 }}>
            <HighLightText>{item?.email}</HighLightText>
            {" belongs to "} <HighLightText>{addedItem?.name}</HighLightText>
          </FormTitle>
          <FormTitle style={{ marginTop: 0, marginBottom: 20 }}>
            {"Do you want to add this company?"}
          </FormTitle>
        </InfoArea>

        <StyledButtonContainer>
          <StyledMenuItem>
            <StyledButton
              title="Go Back"
              width="150px"
              onClick={handleGoBackClick}
            >
              GO BACK
            </StyledButton>
          </StyledMenuItem>

          <StyledMenuItem>
            <StyledButtonReview
              title="Review"
              width="150px"
              onClick={() => handleFinalizeClick(addedItem)}
            >
              CONFIRM
            </StyledButtonReview>
          </StyledMenuItem>
        </StyledButtonContainer>
      </StyledPaper>
    </Dialog>
  );
};
