import React, { useState } from "react";
import { InfoArea, StyledRow, SectionText } from "./styles";
import { CommonBusinessType } from "components/commonBusinessType/index.js";
import RadioGroupButtonForProjectCategory from "components/radioGroupButtonForProjectCategory";

export const Step4 = ({
  setDisable,
  addData,
  setAddData,
  isMobile
}) => {

  const [containsUNSPC, setContainsUNSPC] = useState(addData?.is_unspc_category_type);
 
  const confirmRadioChange = (val) => {
    if (val === 1) {
      setContainsUNSPC(false);
      setAddData("is_unspc_category_type", false);
    } else {
      setContainsUNSPC(true);
      setAddData("is_unspc_category_type", true);
    }
  };



  return (
    <InfoArea isMobile={isMobile} marginTop="0px" marginLeft="8px" isColumn={true} >
      {addData && (addData?.user_type === "Contractor & Buyer" || addData?.user_type === "Contractor") && (
        <>
          <SectionText>Business Categories</SectionText>
          <RadioGroupButtonForProjectCategory
            containsUNSPC={containsUNSPC}
            confirmRadioChange={confirmRadioChange}
          />

          <StyledRow isMobile={isMobile}>
            <CommonBusinessType
              data={addData}
              setData={setAddData}
              isMobile={isMobile}
              notPopup={true}
              containsUNSPC={containsUNSPC}
            />
          </StyledRow>
        </>
      )}
    </InfoArea>

  )
}