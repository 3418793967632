import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import HeaderImageLogo from "pages/pledgxOutputPDF/images/444444.png"
import LogoImage from "./logoImage"

const styles = StyleSheet.create({
    view: {
        width: 612,
        height: 121,
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        position: "fixed",
    },
    companyName: {
        marginTop: 20,
        marginLeft: 30,
        fontSize: 16,
        position: 'absolute',
    },
    spanText: {
        marginLeft: 30,
        marginTop: 45,
        position: 'absolute',
        fontSize: 12,
    },
});

const PdfHeaderWithLogo = ({ prequalifyData}) => (
    <View style={styles.view}>
        <Image src={HeaderImageLogo} />
        <Text style={styles.companyName}>
            <span>{prequalifyData?.data?.companyName}</span>
        </Text>
        <Text style={styles.spanText}>
            <span>Contractor's Qualifications Showcase </span>
        </Text>  
        {/* // calculate the scale factor to maintain aspect ratio
  const scaleFactor = Math.min(imageWidth / image.width, imageHeight / image.height); */}

{/* <Image src={imageSrc} style={{ width: image.width * scaleFactor, height: image.height * scaleFactor }} /> */}

  
                
        <Image src={prequalifyData?.data?.companyLogoImage} style={{ border:0, borderRadius: 48, marginTop: 35, height: 84, position: 'absolute', left: 467, width: 84, marginRight: 61 }} />
    </View>
);

export default PdfHeaderWithLogo;
