import get from "lodash/get";

const initialState = {
  status: 'idle',
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONTACT": {
      const payload = get(action, "payload");

      return {
        ...state,
        contactInfo: payload
      };
    }

    case "SAVE_AVATAR": {
      const payload = get(action, "payload");
      const updatedContact = { ...state.contactInfo, avatar: payload.avatar };

      return {
        ...state,
        contactInfo: updatedContact
      };
    }

    case"SAVE_CONTACT": {
      const payload = get(action, "payload");

      return {
        ...state,
        contactInfo: payload
      };
    }

    case "GET_VIEW": {
      const data = get(action, "data");
      return {
        ...state,
        view: data
      }
    }
    
    default:
      return state;
    }
}