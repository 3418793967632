import { Popup } from "components/popup";
import React, { useState } from "react";
import {
  PopupBodyText,
  PopupContent,
  PopupTitle,
  StyledCloseButton,
} from "./style";
import CloseIcon from "@material-ui/icons/Close";
import PledgxLogo from "assets/common/pledgxLogo";
import { FormBuilder } from "components/formBuilder";
import formSetup from "./forms/formSetUp.json";
import formFields from "./forms/formFields.json";
import formDetails from "./forms/formDetails.json";
import { numberWithCommas } from "common/numberFormat";
import { sendProjectReferenceRequests, sendProjectReferenceRequestsFromThirdParty } from "api/reference";
import { ConfirmationMessage } from "pages/referencesVieweCQS/ConfirmationMessage";
import { useMediaQuery } from "react-responsive";
import { convertPhoneNumberCanadaUSA } from "common/regex";

const ProjectPreviewPopUp = ({ data, openPopup, setOpenPopup }) => {
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const tempData = {
    ...data,
    dateCompleted: data?.completed_date,
    projectValueAtCompletion: numberWithCommas(data?.value),
    scheduledCompletionDate: data?.completed_date,
    projectValueAtAward: numberWithCommas(data?.value),
    consultant_phone: data?.consultant_phone ? convertPhoneNumberCanadaUSA(data?.consultant_phone) : "",
    owner_phone: data?.owner_phone ? convertPhoneNumberCanadaUSA(data?.owner_phone) : "",
    other_phone: data?.other_phone ? convertPhoneNumberCanadaUSA(data?.other_phone) : "",
  };

  const closeButton = (
    <StyledCloseButton aria-label="close" onClick={() => setOpenPopup(false)}>
      <CloseIcon />
    </StyledCloseButton>
  );

  const icon = <PledgxLogo />;

  const content = () => {
    return (
      <>
        {closeButton}
        <PopupContent>
          {icon}
          <PopupTitle>Project Information</PopupTitle>

          <div
            style={{
              padding: "0 30px 30px 30px",
              position: "relative",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <FormBuilder
              formFields={formFields}
              formSetup={formSetup}
              formDetails={formDetails}
              readOnly={true}
              data={tempData}
              navigationType="TABS"
              onInteractionItemClicked={(e) => {
                let refData = {
                  'id': data?.id,
                  'project_location': data?.project_location,
                  'project_name': data?.project_name,
                  'project_number': data?.project_number
                }

                let referee = []

                if (e.id === "consultant_ask_reference") {
                  var words = data?.consultant_contact.split(" ");
                  referee.push({
                    'email': data?.consultant_email,
                    'first_name': words[0],
                    'id': data?.consultant_contact_person_id,
                    'last_name': words[1],
                    'relationship': 'Project Consultant',
                    'type': 'consultant',
                    'contact_person': data?.consultant_contact
                  })

                  const updatedRefData = {
                    ...refData,
                    'referees': referee,
                    'contractor_insitituion_id': data?.contractor_insitituion_id,
                    'contractor_email': data?.contractor_email,
                    'third_party_name': data?.third_party_name,
                    'user_type': data?.user_type
                  };

                  if (data?.third_party_name === '' && (data?.user_type === 1 || data?.user_type === 3)) {
                    sendProjectReferenceRequests(updatedRefData, () => { setOpenSuccessPopup(true) }, () => { })
                  } else if (data?.third_party_name !== '' && (data?.user_type === 2 || data?.user_type === 4)) {
                    sendProjectReferenceRequestsFromThirdParty(updatedRefData, () => { setOpenSuccessPopup(true) }, () => { })
                  }

                }

                if (e.id === "owner_ask_reference") {
                  var words = data?.owner_contact.split(" ");
                  referee.push({
                    'email': data?.owner_email,
                    'first_name': words[0],
                    'id': data?.owner_contact_person_id,
                    'last_name': words[1],
                    'relationship': 'Project Owner',
                    'type': 'owner',
                    'contact_person': data?.owner_contact
                  })

                  const updatedRefData = {
                    ...refData,
                    'referees': referee,
                    'contractor_insitituion_id': data?.contractor_insitituion_id,
                    'contractor_email': data?.contractor_email,
                    'third_party_name': data?.third_party_name,
                    'user_type': data?.user_type
                  };

                  if (data?.third_party_name === '' && (data?.user_type === 1 || data?.user_type === 3)) {
                    sendProjectReferenceRequests(updatedRefData, () => { setOpenSuccessPopup(true) }, () => { })
                  } else if (data?.third_party_name !== '' && (data?.user_type === 2 || data?.user_type === 4)) {
                    sendProjectReferenceRequestsFromThirdParty(updatedRefData, () => { setOpenSuccessPopup(true) }, () => { })
                  }

                }
              }}
            />
          </div>
        </PopupContent>

        <ConfirmationMessage
          openPopup={openSuccessPopup}
          setOpenPopup={setOpenSuccessPopup}
          isMobile={isMobile}
          boldText="Reference request sent!"
          text="Check Your Email for Verification."
        />
      </>
    );
  };

  return (
    <Popup
      open={openPopup}
      dialogContent={content()}
      width={"650px"}
      height={"725px"}
      innerPadding={"0 24px 0 24px"}
    />
  );
};

export default ProjectPreviewPopUp;
