import React, { useEffect, useState, useRef } from "react";
import { LargeText } from "../styles";
import { FloatingButton } from "../../../components/floatingButton";
import updateObjectByPath from "../../../common/updateObjectByPath";
import { AnnualValues } from "./annualValues";
import { IconButton } from "@material-ui/core";
import MobileSaveButtonIcon from "../../../assets/dashboardIcons/mobileSaveButtonIcon";
import FilledAccordion from "../../../components/filledAccordion";
import { FunctionalButton } from "components/functionalButton";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";
import DocumentPoolPopUp from "components/documentPoolPopUp";
import { WrapperForUploadedHealthSafetyFileDisplay } from "pages/preQualifyForm/components/uploadedHealthSafetyFileDisplay";
import ConciseTable from "components/filesListTable/conciseTable";
import { COLORS } from "../../../constants";
import { ScrollableDiv } from "pages/documentPool/styles";
import { StyledSubtext } from "pages/loginPage/styles";

import {
  FieldArea,
  StyledRow,
  SectionText,
  SetupContainer,
  StyledTextField,
  StyledCenter,
} from "./styles";
import { LabelText } from "./safetyRecords/styles";

export const AnnualRecords = ({
  isMobile,
  setOpenSuccessPopup,
  saveAnnualInfo,
  data,
  setData,
  institution_id,
  currentDashboardUrl,
}) => {
  const [constructionRemark, setConstructionRemark] = useState(
    data?.annual_record_remarks_and_cor?.annual_construction_value_remark || ""
  );

  const [internalSearchResults, setInternalSearchResults] = useState(
    data?.financial_records_documents || []
  );
  const [query, setQuery] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [openDocumentPool, setOpenDocumentPool] = useState(false);
  const headers = [];
  const [selectedFiles, setSelectedFiles] = useState([]);

  const deleteHealthSafetyFile = (index) => {
    let temp = [...data?.financial_records_documents];
    temp.splice(index, 1);
    setData({
      ...data,
      financial_records_documents: temp,
    });
  };

  const addUploadedFile = () => {
    let temp = [...data?.financial_records_documents];

    for (let key in selectedFiles) {
      if (!isNaN(key)) {
        temp.push(selectedFiles[key]);
      }
    }

    setData({
      ...data,
      financial_records_documents: temp,
    });

    setOpenDocumentPool(false);
  };

  const handleDocumentPoolPopUp = () => {
    setOpenDocumentPool(true);
  };

  const handleConstructionRemarkChange = (event) => {
    setConstructionRemark(event.target.value);
  };

  if (data && !data.annual_record_remarks_and_cor) {
    data["annual_record_remarks_and_cor"] = {};
  }

  useEffect(() => {
    const updatedData = { ...data };
    updatedData["annual_record_remarks_and_cor"][
      "annual_construction_value_remark"
    ] = constructionRemark;
    setData(updatedData);
  }, [constructionRemark]);

  const handleSave = () => {
    let institutionData = {};
    if (
      currentDashboardUrl === "/service-provider-dashboard" &&
      institution_id
    ) {
      institutionData = { institution_id: institution_id };
    }

    const newData = { ...data, ...institutionData };
    saveAnnualInfo(
      newData,
      () => {
        setOpenSuccessPopup(true);
      },
      () => {}
    );
  };

  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };

  const saveButtonRef = useRef();

  return (
    <SetupContainer isMobile={isMobile}>
      {!isMobile && (
        <FloatingButton
          buttonTitle="Save"
          handleButton={handleSave}
          width="150px"
          height="38px"
          marginRight="0px"
        />
      )}
      <StyledRow justifyContent="space-between" ref={saveButtonRef}>
        <LargeText isMobile={isMobile}>
          {isMobile ? "Edit your Records" : "Edit your Financial Records"}
        </LargeText>
        {isMobile && (
          <IconButton onClick={handleSave}>
            <MobileSaveButtonIcon />
          </IconButton>
        )}
      </StyledRow>
      <FieldArea noJustifyContent={true} isMobile={isMobile}>
        {!isMobile && (
          <>
            <SectionText marginTop="60px">
              Annual Construction Value
            </SectionText>
            <FieldArea width="80%" noJustifyContent={true}>
              <AnnualValues
                isMobile={isMobile}
                data={data}
                setData={onChangeData}
              />
            </FieldArea>
            <FieldArea width="78%" noJustifyContent={true}>
              <LabelText> Remarks </LabelText>
              <StyledTextField
                multiline
                rows={5}
                placeholder={"Remark"}
                variant="outlined"
                value={constructionRemark}
                onChange={handleConstructionRemarkChange}
              />
            </FieldArea>

            <StyledSubtext style={{ marginTop: "28px" }}>
              {" "}
              Attach all relevant Financial documents:{" "}
            </StyledSubtext>

            <div style={{ display: "flex", width: "80%" }}>
              <div
                style={{
                  marginLeft: "0px",
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "70%",
                }}
              >
                <SearchBar
                  filteredData={data?.financial_records_documents}
                  setInternalSearchResults={setInternalSearchResults}
                  internalSearch={true}
                  query={query}
                  setQuery={setQuery}
                  searchWidth="100%"
                  searchPlaceHolder="File Name..."
                />
              </div>
              <FunctionalButton
                buttonTitle="Document Pool"
                marginLeft="20px"
                handleButton={() => handleDocumentPoolPopUp()}
                width="250px"
                isReversedColor={true}
              />
            </div>

            {data?.financial_records_documents?.length < 1 && (
              <div
                style={{
                  alignItems: "center",
                  backgroundColor: "white",
                  border: `dashed 2px ${COLORS.lightGrey1}`,
                  display: "flex",
                  height: "186px",
                  color: `${COLORS.textGrey}`,
                  justifyContent: "center",
                  width: "78%",
                  marginTop: "20px",
                }}
              >
                Selected Documents will appear here
              </div>
            )}
            {data?.financial_records_documents?.length > 0 && (
              <div style={{ marginTop: "20px", width: "80%" }}>
                <ConciseTable headers={headers} />

                <ScrollableDiv style={{ maxHeight: "190px" }}>
                  <div style={{ marginRight: "15px" }}>
                    {data?.financial_records_documents?.length > 0 &&
                      (query && query !== ""
                        ? internalSearchResults
                        : data?.financial_records_documents
                      )?.map((item, i) => (
                        <StyledCenter style={{ marginTop: 0 }}>
                          <WrapperForUploadedHealthSafetyFileDisplay
                            key={`file-${i}`}
                            file={item}
                            displayPercentage="100%"
                            deleteFile={deleteHealthSafetyFile}
                            index={i}
                            setPrequalifyData={setData}
                            prequalifyData={data}
                            setOpenPopup={setOpenPopup}
                            openPopup={openPopup}
                          />
                        </StyledCenter>
                      ))}
                  </div>
                </ScrollableDiv>
              </div>
            )}

            <DocumentPoolPopUp
              openPopup={openDocumentPool}
              setOpenPopup={setOpenDocumentPool}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              attachFile={addUploadedFile}
              institutionId={institution_id || ""}
            />
          </>
        )}
        {isMobile && (
          <>
            <FilledAccordion
              header="Annual Construction Revenue"
              details={
                <FieldArea noJustifyContent={true} isMobile={isMobile}>
                  <AnnualValues
                    isMobile={isMobile}
                    data={data}
                    setData={onChangeData}
                  />
                </FieldArea>
              }
            />
          </>
        )}
      </FieldArea>
    </SetupContainer>
  );
};
