import styled from 'styled-components';
import TableCell from "@material-ui/core/TableCell";
import { Button } from '@material-ui/core';
import submission from "../../../../assets/buyers/submissionIcon.svg";
import qualified from "../../../../assets/buyers/qualifiedIcon.svg";
import disqualified from "../../../../assets/buyers/disqualifiedIcon.svg";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { COLORS } from '../../../../constants';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';

export const RespondButton = styled(Button)`
  && {
    width: 100px;
    height: 32.58px;
    background-color: white;
    border: 2px solid #43B868;
    box-sizing: border-box;
    border-radius: 6px;
    color: #43B868;

    font-family: SF Pro Text;
    font-size: 13px;
    font-weight: 600;
    transition: all 0.5s ease;
    text-transform: uppercase;

    &:hover {
      background-color: #43B868;
      color: white;
    }
`;

export const MoreButton = styled(Button)`
  && {
    width: 82px;
    height: 32.58px;
    background-color: white;
    border: 2px solid #FF6D1D;
    box-sizing: border-box;
    border-radius: 6px;
    color: #FF6D1D;

    font-family: SF Pro Text;
    font-size: 13px;
    font-weight: 600;
    transition: all 0.5s ease;
    text-transform: uppercase;

    &:hover {
      background-color: #FF6D1D;
      color: white;
    }
  }
`;

export const SubmissionIcon = styled.img`
  margin-top: 4px;
  margin-left: 16px;
  padding: 0px
  background: white;
  border-radius: 3px;
  content: url(${props => ((props.isQualified == null) ?
               `${submission}` : (((typeof props.isQualified == "boolean") &&
               props.isQualified)? `${qualified}` : `${disqualified}`))});
`;

export const StyledTableCell = styled(TableCell)`
  && {
    font-family: SF Pro Text;
    font-weight: ${(props) => (props.header ? '500' : '400')};
    color: ${(props) => (props.header ? '#B9B9C3' : '#6E6B7B')};
    font-size: ${(props) => (props.header ? '18px' : '13px')};

    &.MuiTableCell-root{
      padding: 0 16px;

      &:first-child {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border-left: 1px ${COLORS.greyBorder} solid;
      }

      &:last-child {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        border-right: 1px ${COLORS.greyBorder} solid;
      }
    }
  }
`;

export const StyledTableCellIcon = styled(StyledTableCell)`
  width: 10%;
`;

export const StyledTableCellIndex = styled(StyledTableCell)`
  width: 20%;
`;

export const StyledTableCellCompanyName = styled(StyledTableCell)`
  width: 10%;
`;

export const StyledTableCellActions = styled(StyledTableCell)`
  width: 10%;
`;

export const StyledTableCellCompanyInfo = styled(StyledTableCell)`
  width: 10%;
`;


export const StyledDialogTitle = styled(DialogTitle)`
  .MuiTypography-root{
    font-family: SF Pro Text;
    font-size: 24px;
    font-weight: 600;
    margin-top: 16px;
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper{
    width: 800px;
    height: 325px;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  &&.MuiDialogContent-root{
    flex: none;
  }
`;
