import get from "lodash/get";

const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_NEWS": {
      const payload = get(action, "payload", []);
      return {
        ...state,
        news: payload,
      };
    }
    case "SAVE_ARTICLE": {
      const article = get(action, "article", {});
      const updSavedNews = [...state.savedNews];
      const updNews = [...state.news];

      article.saved = true;
      updSavedNews.push(article);

      const updNewsIndex = updNews.findIndex(
        (item) => item.link === article.link
      );
      updNews[updNewsIndex].saved = true;

      return {
        ...state,
        savedNews: updSavedNews,
        news: updNews,
      };
    }
    case "DELETE_ARTICLE": {
      const article = get(action, "article", {});
      const updSavedNews = [...state.savedNews];
      const updNews = [...state.news];

      const updSavedNewsIndex = updSavedNews.findIndex(
        (item) => item.link === article.link
      );
      updSavedNews.splice(updSavedNewsIndex, 1);

      const updNewsIndex = updNews.findIndex(
        (item) => item.link === article.link
      );
      if (updNewsIndex !== -1) updNews[updNewsIndex].saved = false;

      return {
        ...state,
        savedNews: updSavedNews,
        news: updNews,
      };
    }
    case "GET_SAVED_NEWS": {
      const payload = get(action, "payload", []);
      return {
        ...state,
        savedNews: payload,
      };
    }
    default:
      return state;
  }
};
