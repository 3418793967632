import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { getPreQualificationInfo, getSelectedCCDCInfo } from "../../../data/actions/user";
import {
  FormTitle,
  StyledOrText,
  StyledLargeTitle,
} from "../styles";

import { StyledTextContainer } from './styles';
import { StyledTableCell } from '../../../components/filesListTable/styles';
import ConciseTable from "../../../components/filesListTable/conciseTable";

import { getCCDCs } from '../../../api/prequalify';
import { StyledConciseContainer } from '../styles';
import { NextArrowButton } from '../components/nextArrowButton';
import { StyledTableCellFirst } from '../components/searchProject/styles';
import { UploadButton } from './UploadButton';
import { UploadArea } from '../components/uploadArea';
import { UploadedFileDisplay } from '../components/uploadedFileDisplay';

export const Panel1 = ({
  setDownloading,
  listPanel,
  setListPanel,
  setIsLastPanel,
  setAction
 }) => {

  setIsLastPanel(false);

  const dispatch = useDispatch();
  const [CCDCFile, setCCDCFile] = React.useState(null);
  const inputFile = useRef(null)
  const [data, setData] = useState('');

  const ccdcList = async () => {
    await getCCDCs()
          .then(data => { setData(data);})
          .catch(err => {});
  };

  useEffect(() => {
    if(listPanel === 1) {
      ccdcList();
      setCCDCFile(null);
    }

    dispatch({ type: "RESET_CCDC", disableApiMiddleWare: true });
  }, [listPanel]);


  const body = (bodyData) => { return (
    <>
        <StyledTableCellFirst component={'tr'}>
            {bodyData.project_title}
        </StyledTableCellFirst>
        <StyledTableCell component={'tr'} scope="row">
            {bodyData.project_number}
        </StyledTableCell>
        <StyledTableCell component={'tr'} scope="row">
            {bodyData.project_location}
        </StyledTableCell>
    </>
    );
  };

  const handlePickCCDC = (props) => {
    dispatch(getSelectedCCDCInfo(props.ccdcId));
    setAction("new");
    setListPanel(2);
  }

  const actions = (ccdcId) => {
    return (
    <>
      <StyledTableCell component={'tr'}  align="center">
        <NextArrowButton />
      </StyledTableCell>
    </>
    )
  }

  const clickUploadFile = () => {
    inputFile.current.click();
  };

  const deleteFile = () => {
    setCCDCFile(null);
  }

  return (
        <>
        <StyledLargeTitle>Upload</StyledLargeTitle>
        <FormTitle>Select one previously submitted qualification statement file</FormTitle>
        { data && (
          <StyledConciseContainer>
          <ConciseTable
            length={3}
            headers={''}
            data={data}
            body={body}
            actions={actions}
            hover={true}
            handleClickRow={handlePickCCDC}
          />
         </StyledConciseContainer>
        )}
        <StyledOrText>Or</StyledOrText>
        <FormTitle>Upload an existing qualification statement file in ccdc-11 (2019) format</FormTitle>
        <UploadArea
            setFile={setCCDCFile}
            width="100%"
            height="186px"
            margin="16px 0 16px 0"
            inputFile={inputFile}
          />
        {CCDCFile && (
          <>
            <FormTitle>Uploaded file</FormTitle>
            <StyledTextContainer>
              <UploadedFileDisplay
                file={CCDCFile}
                deleteFile={deleteFile}
              />
              <UploadButton
                setDownloading={setDownloading}
                setListPanel={setListPanel}
                CCDCFile={CCDCFile}
                setCCDCFile={setCCDCFile}
                clickUploadFile={clickUploadFile}
                setAction={setAction}
              />
            </StyledTextContainer>

          </>
        )}
        </>
  );
};
