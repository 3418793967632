import React, { useEffect, useState } from "react";

import { MediumText, RegularText, StyledBox, SmallText } from "../styles";

import { FunctionalButton } from "../../../components/functionalButton";
import {
  FunctionArea,
  IntroArea,
  StyledTitle,
  ButtonContainer,
  ComingSoonImg,
  ImgDiv,
} from "./styles";

import DocumentsIcon from "../../../assets/dashboardIcons/documentsIcon";
import docComingSoon from "assets/dashboardIcons/docComingSoon.svg";
import { useHistory } from "react-router";
import { getAllFiles } from "api/file_managment";
import bytesToMegaBytes from "common/convertFileSize";
import { useSelector } from "react-redux";
import { contactSelector } from "data/selectors/contact";

export const Documents = ({ isMobile }) => {
  let history = useHistory();
  const handleAddDoc = () => {
    history.push("/documents");
  };
  const [comingSoon, setComingSoon] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [totalFilesSize, setTotalFilesSize] = useState(null);
  const contactData = useSelector(contactSelector);
  const fileLimitCount = contactData?.user_access_control?.file_limit_count;
  const fileLimitSize = contactData?.user_access_control?.file_limit_size;

  const fileLimitSizeMB = Math.round(fileLimitSize / (1024 * 1024));
  // const getInstitutionFiles = async (e) => {
  //   await getAllFiles()
  //     .then((data) => setFilteredData(data.files))
  //     .catch((err) => {
  //       console.log("Something went wrong");
  //     });
  // };

  // useEffect(() => {
  //   getInstitutionFiles();
  // }, []);

  // useEffect(() => {
  //   let totalSize = 0;
  //   filteredData?.map((item) => (totalSize += item.size));
  //   setTotalFilesSize(bytesToMegaBytes(totalSize, 1));
  // }, [filteredData]);
  return (
    <FunctionArea isMobile={isMobile}>
      {isMobile && (
        <StyledBox
          boxHeight="110px"
          borderRadius="15px"
          isMobile={isMobile}
          onClick={handleAddDoc}
          style={{ cursor: "pointer" }}
        >
          {comingSoon && (
            <ImgDiv>
              <ComingSoonImg src={docComingSoon} />
            </ImgDiv>
          )}
          {!comingSoon && (
            <>
              <DocumentsIcon width={55} height={59} isMobile={isMobile} />
              {/* <SmallText>
                {totalFilesSize}/{fileLimitSizeMB}mb used
              </SmallText> */}
            </>
          )}
        </StyledBox>
      )}
      {!isMobile && (
        <>
          <MediumText>Documents</MediumText>
          <StyledBox boxHeight="216px">
            {comingSoon && (
              <ImgDiv style={{ width: "100%" }}>
                <ComingSoonImg src={docComingSoon} />
              </ImgDiv>
            )}
            {!comingSoon && (
              <>
                <IntroArea>
                  <DocumentsIcon />
                  {/* <StyledTitle>
                    <SmallText>
                      {totalFilesSize}/{fileLimitSizeMB}mb used
                    </SmallText>
                  </StyledTitle> */}
                </IntroArea>
                <ButtonContainer>
                  <FunctionalButton
                    buttonTitle="Add Documents"
                    handleButton={handleAddDoc}
                    width="80%"
                    marginRight="0px"
                    isReversedColor={true}
                    hasBoxShadow={true}
                  />
                </ButtonContainer>
              </>
            )}
          </StyledBox>
        </>
      )}
    </FunctionArea>
  );
};
