import React from 'react';

const Submit = ({ active, completed }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1545:3151)">
        <g filter="url(#filter0_d_1545:3151)">
          <circle cx="14" cy="14" r="14" fill="white"/>
          <circle cx="14" cy="14" r="13.5"fill={active || completed ? "#FF6D1D" : "white"} stroke="#ECEBED" />
        </g>
        <path d="M18.7982 9.20118C18.6105 9.01419 18.3307 8.95025 18.0812 9.04031L8.45636 12.4777C8.19718 12.5699 8.01775 12.8084 8.00125 13.0827C7.98475 13.3577 8.13324 13.6155 8.38005 13.7393L11.5329 15.315L15.5622 12.4372L12.6837 16.4672L14.2594 19.62C14.377 19.8531 14.6156 19.9995 14.8747 19.9995C14.8892 19.9995 14.9029 19.9988 14.9167 19.9982C15.1917 19.9817 15.4302 19.8029 15.523 19.543L18.9605 9.91823C19.0491 9.66867 18.9859 9.38887 18.7982 9.20118Z" fill={active || completed ? "white" : "#D0C9D6"} />
      </g>
      <defs>
      <filter id="filter0_d_1545:3151" x="-64" y="-57" width="156" height="156" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="7"/>
      <feGaussianBlur stdDeviation="32"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1545:3151"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1545:3151" result="shape"/>
      </filter>
      <clipPath id="clip0_1545:3151">
      <rect width="28" height="28" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
};

export default Submit;
