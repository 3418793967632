import styled from "styled-components";

export const BuyerBody = styled.div`
  // width: 1200px;
  min-height: 110px;
  background: white;
  margin: 5px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BuyerRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BuyerColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BuyerLogo = styled.img`
  width: 40px;
  height: 40px;

  padding: 10px;

  margin-left: 25px;
  background: #c4c4c4;
  border-radius: 3px;
`;

export const BuyerName = styled.div`
  display: flex;
  margin-left: 20px;

  font-weight: 600;
  font-size: 18px;
  line-height: 21px;

  color: #6e6b7b;
`;
