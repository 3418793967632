import React, { useEffect, useRef, useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { stripeDataSelector } from '../../data/selectors/subscriptions';

import Spinner from '../../components/spinner';
import { FunctionalButton } from '../../components/functionalButton';
import { PackageItemMini } from '../../components/packageItemMini';

import { APP_URL, COLORS, FONT_SIZES } from '../../constants';
import classes from './style/Payment.module.css';
import contractor_packages from '../../assets/payment/contractor_packages.json';

export const Payment = () => {
  const [state, setState] = useState({
    loading: true,
    error: false,
    message: null,
    paymentCompleted: false,
    submitLoading: false
  });
  const [widgetDimensions, setWidgetDimensions] = useState();
  const stripe = useStripe();
  const elements = useElements();
  const stripeData = useSelector(stripeDataSelector);
  const history = useHistory();
  const userSubmit = useRef(false);
  const widgetRef = useRef();

  useEffect(() => {
    if (widgetRef.current && !widgetDimensions) {
      setWidgetDimensions({
        height: widgetRef.current.getBoundingClientRect().height,
        width: widgetRef.current.getBoundingClientRect().width
      });
    };
  }, [widgetRef.current]);

  useEffect(() => {
    if (!stripe || !stripeData || !stripeData.stripe_client_secret || !stripeData.stripe_client_secret.token || !stripeData.stripe_client_secret.type) {
      return;
    };
    if (stripeData.stripe_client_secret.type === 'PAYMENT_INTENT') {
      stripe.retrievePaymentIntent(stripeData.stripe_client_secret.token).then(({ paymentIntent }) => handleIntent(paymentIntent));
    } else if (stripeData.stripe_client_secret.type === 'SETUP_INTENT') {
      stripe.retrieveSetupIntent(stripeData.stripe_client_secret.token).then(({ setupIntent }) => handleIntent(setupIntent));
    };
  }, [stripe, stripeData]);

  function handleIntent(intent) {
    switch (intent.status) {
      case "succeeded":
        setState({
          ...state,
          loading: false,
          paymentCompleted: true,
          message: 'Payment succeeded!'
        });
        break;
      case "processing":
        setState({
          ...state,
          loading: false,
          paymentCompleted: false,
          message: 'Your payment is processing.'
        });
        break;
      case "requires_payment_method":
        setState({
          ...state,
          loading: false,
          paymentCompleted: false,
          message: 'Your payment was not successful, please try again.'
        });
        break;
      default:
        setState({
          ...state,
          loading: false,
          paymentCompleted: false,
          error: true,
          message: 'Something went wrong.'
        });
        break;
    };
  };

  async function handleInteraction({ action, payload }) {
    switch(action) {
      case 'SUBMIT_PAYMENT':
        setState({
          ...state,
          error: null,
          message: null,
          submitLoading: true
        });
        userSubmit.current = true;
        payload.preventDefault();


        if (stripeData.stripe_client_secret.type === 'PAYMENT_INTENT') {
          const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: `${ APP_URL }/subscribe/subscribed`
            }
          });
          if(error) {
            // show error and collect new card details.
            if (error.type === "card_error" || error.type === "validation_error") {
              setState({
                ...state,
                error: error.message,
                submitLoading: false
              });
            } else {
              setState({
                ...state,
                error: 'We apologize. An unexpected error occurred',
                submitLoading: false
              });
            };
            return;
          };
          setState({
            ...state,
            submitLoading: false
          });
        } else if (stripeData.stripe_client_secret.type === 'SETUP_INTENT') {
          stripe.confirmSetup({
            elements,
            confirmParams: {
              // Return URL where the customer should be redirected after the SetupIntent is confirmed.
              return_url: `${ APP_URL }/subscribe/subscribed`
            },
            //redirect: 'if_required'
          })
          .then(function(result) {
            if (result.error) {
              // Inform the customer that there was an error.
              if (result.error.type === "card_error" || result.error.type === "validation_error") {
                setState({
                  ...state,
                  error: result.error.message,
                  submitLoading: false
                });
              } else {
                setState({
                  ...state,
                  error: 'We apologize. An unexpected error occurred',
                  submitLoading: false
                });
              };
              return;
            };
            setState({
              ...state,
              submitLoading: false
            });
          });
        };
        break;
      case 'SEE_PACKAGES':
        history.push('/subscribe/packages');
        break;
    };
  };

  var selectedPackage;
  if (stripeData && stripeData.package && contractor_packages.packages_hash[stripeData.package.title] && (contractor_packages.packages_hash[stripeData.package.title].index !== null && contractor_packages.packages_hash[stripeData.package.title].index !== undefined)) {
    selectedPackage = contractor_packages.packages[contractor_packages.packages_hash[stripeData.package.title].index]
  };

  var redirectType;
  if (history && history.location && history.location.search) {
    var redirectStringArray = history.location.search.split('?');
    if (redirectStringArray && redirectStringArray.length > 1) {
      redirectType = redirectStringArray[1];
    };
  };
  var elementsLoading = false;
  if (!stripe || !elements || !stripeData || (Object.keys(stripeData).length < 1)) {
    elementsLoading = true;
  };
  return (
    <div className={ classes.container }>
      <div className={ classes['left-container'] }>
        <div className={ [classes['pop-up-box'], classes['package-box']].join(' ') }>
          <PackageItemMini redirectType={ redirectType } selectedPackage={ selectedPackage } />
        </div>
        <div className={ classes['other-packages-container'] }>
          <span className={ classes['other-packages-text'] } onClick={ () => handleInteraction({ action: 'SEE_PACKAGES' }) }>See other packages</span>
        </div>
      </div>
      <div className={ [classes['pop-up-box'], classes['payment-box']].join(' ') } ref={ widgetRef } style={ (widgetDimensions && widgetDimensions.width) ? { maxWidth: widgetDimensions.width } : {}}>
        {
          elementsLoading ?
            <Spinner />
          :
            <form className={ classes['payment-form'] } id='payment-form' onSubmit={ (e) => handleInteraction({ action: 'SUBMIT_PAYMENT', payload: e }) }>
              {
                (state.error && userSubmit.current) &&
                  <div className={ classes['message-container'] } style={{ backgroundColor: COLORS.primaryRed }}>
                    <ErrorOutlineIcon
                      sx={{ fontSize: '17px', color: 'white', marginRight: '10px' }}
                    />
                    <span id='payment-message' className={ classes['message-text'] } style={{ fontSize: FONT_SIZES.body_3 }}>{ state.error }</span>
                  </div>
              }
              <PaymentElement id="payment-element" />
              <div className={ classes['interaction-container'] }>
                <FunctionalButton
                  disabled={ (state.loading || state.submitLoading || !stripe || !elements) }
                  buttonTitle='Pay Now'
                  handleButton={ (e) => handleInteraction({ action: 'SUBMIT_PAYMENT', payload: e }) }
                  width='100%'
                  height='50px'
                  isReversedColor={ false }
                />
                {
                  (state.loading || state.submitLoading) &&
                    <div className={ classes['loading-interaction-container'] }>
                      <Spinner size={ 20 } />
                    </div>
                }
              </div>
            </form>
        }
      </div>
    </div>
  );
}