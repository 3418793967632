import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const StyledIconButton = styled(IconButton)`
  &&{
    width: fit-content;
    color: white;
    padding: 0px;
    border-radius: 0%;
    cursor: pointer;
    margin:  ${props => props.margin ? props.margin : '0px'};

    &:hover{
      background-color: white;
    }
  }
`;
