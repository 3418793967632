import release_notes_public from '../../assets/system_messages/release_notes_public.json';

export const updateLastSeenRelease = () => (dispatch) => {
  var latest_release_version = release_notes_public[0]
  localStorage.setItem('pledgxqualify_release_version_seen', latest_release_version.id);
  dispatch({
    type: 'CLEAR_MESSAGE',
    payload: 'release_notes'
  });
};

export const getSystemMessages = () => (dispatch) => {
  // TODO: Add endpoint to check for messages in the back end
  var messages = {};

  // Check last seen release
  var latest_release_version = release_notes_public[0]
  if (latest_release_version && latest_release_version.notification) {
    var last_seen_release_version = localStorage.getItem('pledgxqualify_release_version_seen');
    if (!last_seen_release_version || (last_seen_release_version !== latest_release_version.id)) {
      messages.release_notes = latest_release_version.id;
    };
  };
  dispatch({
    type: 'GET_SYSTEM_MESSAGES',
    payload: messages
  });
};