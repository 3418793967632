import get from "lodash/get";

const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_BONDING_INFO": {
      const payload = get(action, "payload", []);
      return {
        ...state,
        bonding: payload,
      };
    }
    case "GET_USER_CATEGORIES": {
      const payload = get(action, "payload.categories", []);
      return {
        ...state,
        categories: payload,
      };
    }
    default:
      return state;
  }
};
