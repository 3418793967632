import React, { useState } from "react";

import { 
  ContractInfo,
  CommentCard,
  TitleText,
  AwardDiv, 

} from './styles';

import { MultilineTextField } from 'components/multilineTextField';
import QualityIcon from "assets/reference/quality";
import EfficientIcon from "assets/reference/efficient";
import OrganizedIcon from "assets/reference/organized";
import ServiceIcon from "assets/reference/service";
import AdministrationIcon from "assets/reference/administration";

export const GiveAwards = ({
  data,
  onChangeAnswer
}) => {

  const awardsIcon = [
    {
      text: "Best Quality Work",
      category: "quality",
      icon: <QualityIcon width={116} height={116} noGreyFilter={data?.awards?.quality}/>
    },
    {
      text: "Efficient Work",
      category: "efficiency",
      icon: <EfficientIcon width={116} height={116} noGreyFilter={data?.awards?.efficiency}/>
    },
    {
      text: "Organized",
      category: "organization",
      icon: <OrganizedIcon width={116} height={116} noGreyFilter={data?.awards?.organization}/>
    },
    {
      text: "Great Service",
      category: "service",
      icon: <ServiceIcon width={116} height={116} noGreyFilter={data?.awards?.service} />
    },
    {
      text: "Great Administration",
      category: "administration",
      icon: <AdministrationIcon width={116} height={116} noGreyFilter={data?.awards?.administration} />
    }
  ]
  
  const [pickedCommentIndex, setPickedCommentIndex] = useState('')

  const handlePick = (val, i) => {
    const tmp = data?.awards || {}
    tmp['quality'] = 0
    tmp['efficiency'] = 0
    tmp['organization'] = 0
    tmp['service'] = 0
    tmp['administration'] = 0

    if (i === pickedCommentIndex){
      setPickedCommentIndex('');
      tmp[val.category] = 0
      // onChangeAnswer(`awards.${val.category}`, 0)
    } else {
      setPickedCommentIndex(i)
      tmp[val.category] = 1
      // onChangeAnswer(`awards.${val.category}`, 1)
    }
    onChangeAnswer(`awards`, tmp)
  }

  return (
    <>
      <ContractInfo flexDirection="row" justifyContent="flex-start">
      {awardsIcon.map((val,i) => {
        return(
          <AwardDiv 
            onClick={() => handlePick(val,i)} 
            isPicked={i === pickedCommentIndex} >
            {val.icon}
            {val.text}
          </AwardDiv>
        )
      })}
      </ContractInfo>
    </>
  
  )
}