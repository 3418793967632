import styled from "styled-components";
import { COLORS } from "../../../constants";

export const StyledDownloadButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
`;

export const StyledView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledPdfViewerCanvas = styled.div`
  width: 832px;
  height: 832px;
  border: 2px solid ${COLORS.primaryOrange};
  border-radius: 8px;
  background: ${COLORS.lighOrange};
`;

export const StyledPdfViewer = styled.div`
  width: 800px;
  height: 800px;
  margin-left: 16px;
  margin-top: 16px;
`;

export const StyledFilePreviewContainer = styled.div`
  display: flex;
`;

export const StyledTitle = styled.div`
  display: inline-block;
  color: ${COLORS.lightGrey2};
  font-size: 18px;
  margin-right: 96px;
  margin-bottom: 16px;
`;

export const StyledTitleBold = styled(StyledTitle)`
  display: inline-block;
  font-weight: 700;
  margin-right: 6px;
`;