import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { filterBank } from "api/prequalify";

import { StyledQuestion, StyledBox, PlaceholderText } from "../styles";

import { ResultPanel } from "./resultPanel";
import { FunctionalButton } from "../../../../components/functionalButton";
import { EmptyPanel } from "./emptyPanel";
import SelectedProjects from "./components/selectedProjects";
import { AddFinancialPopup } from "../../../../components/addFinancialPopup";

export const BankPicklist = ({
  setActiveStep,
  prequalifyData,
  setPrequalifyData,
  setIsLastPanel,
  setPreviousStepPanel,
  handleNext,
  setPanel,
  popupTitle,
  setRerender,
  institutionId,
}) => {
  setIsLastPanel(true);
  setPreviousStepPanel(1);

  const isMobile = useMediaQuery({ maxWidth: 960 });
  const [list, setList] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [pickedArray, setPickedArray] = useState([]);

  const [object, setObject] = useState(
    prequalifyData?.bank?.company_name ? prequalifyData?.bank : ""
  );

  useEffect(() => {
    setPrequalifyData("bank", object);
  }, [object]);

  const handleFilter = async () => {
    await filterBank("", { institution_id: institutionId })
      .then((data) => {
        setList(data);
        setFilteredData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    handleFilter();
  }, []);

  const addFinancial = (data) => {
    setObject(data);
  };

  return (
    <>
      <StyledQuestion marginBottom="32px">
        Which Bank do you currently work with?
      </StyledQuestion>
      {!object && (
        <StyledBox boxWidth="844px">
          <PlaceholderText>Selected Bank will appear here</PlaceholderText>
        </StyledBox>
      )}
      {object && (
        <SelectedProjects
          object={object}
          setObject={setObject}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          pickedArray={pickedArray}
          setPickedArray={setPickedArray}
          setRerender={setRerender}
        />
      )}
      {list?.length < 1 && (
        <EmptyPanel
          object={object}
          setObject={setObject}
          setOpenPopup={setOpenPopup}
        />
      )}
      {list?.length > 0 && (
        <ResultPanel
          object={object}
          setObject={setObject}
          setOpenPopup={setOpenPopup}
          openPopup={openPopup}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          filterApi={filterBank}
          pickedArray={pickedArray}
          disabled={object}
        />
      )}
      <FunctionalButton
        buttonTitle="Next"
        handleButton={() => handleNext()}
        width="200px"
        marginRight="auto"
        marginLeft="auto"
        marginTop="16px"
        disabled={!object}
      />
      <AddFinancialPopup
        setOpenPopup={setOpenPopup}
        openPopup={openPopup}
        handleButtonFunc={addFinancial}
        popupTitle={popupTitle}
        data={{ type: "bank" }}
      />
    </>
  );
};

export default BankPicklist;
