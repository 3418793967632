import React from "react";
import { useHistory } from "react-router-dom";
import { saveOnboarding } from '../../../api/onboarding';

import { FunctionalButton } from '../../../components/functionalButton';
import {
  ImagePopup,
  LargeText,
  SmallText,
  ButtonArea,
  PopupContainer,
} from './styles';
import { Popup } from '../../../components/popup';
import Cone from "../../../assets/onBoarding/Cone";

export const ExitPopup = ({
  openPopup,
  setOpenPopup,
  onboardingData
}) => {
  let history = useHistory();

  const handleContinue = () => {
    setOpenPopup(false);
  };
  const handleExit = async () => {
    history.push(`/`);
  };

  return (
    <Popup
        open={openPopup}
        setOpen={setOpenPopup}
        width="640px"
        height="498px"
        dialogContent={(
          <>
            <PopupContainer>
              <ImagePopup>
                <Cone />
              </ImagePopup>
              <LargeText>Are you sure you want to exit?</LargeText>
              <SmallText>
                Warning: unless you are at the PDF preview, your progress will be lost! 
              </SmallText>
            </PopupContainer>
            <ButtonArea>
              <FunctionalButton
                buttonTitle="CONTINUE"
                width="250px"
                height="53px"
                marginRight="16px"
                isReversedColor={true}
                handleButton={handleContinue}
              />
              <FunctionalButton
                buttonTitle={"EXIT"}
                width="250px"
                height="53px"
                marginRight="0px"
                handleButton={handleExit}
              />
            </ButtonArea>
        </>
        )}
      />

  )
}
