import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '../../constants';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';


export const StyledTextField = styled(TextField)`
  && {
    width:  ${props => props.width ? props.width : '100%'};
    border-color:  ${props => props.noBorderColor ? '' : COLORS.primaryOrange};

    .MuiOutlinedInput-notchedOutline{
      border-color: ${props => props.noBorderColor ? '' : COLORS.primaryOrange};
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${COLORS.primaryOrange};
      border-width: thin;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${COLORS.primaryOrange};
    }
  }
`;

export const StyledLabelText = styled(Typography)`
  && {
    font-size: 14px;
    font-family: ${TYPOGRAPHY.primaryFont};
    font-weight: 400;
    margin-bottom: 4px;
    color: black;
  }
`;

export const StyledMulti = styled.div`
  ${props => props.margin ? `margin: ${props.margin};` : ""}
  width:  ${props => props.width ? props.width : '100%'};
`;

