import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 10,
    justifyContent: 'flex-start',
    width: '100%',
    paddingLeft: 30,
    paddingRight: 30,
    fontSize: 10,
  }
});

const PageDiscliamer = () => (
  <View style={styles.headerContainer}>
    <Text>
      It is understood that the information contained herein is furnished as a matter of courtesy for the confidential use of the surety and is merely an expression of opinion. It is also agreed that in furnishing this information, no guaranty or warranty of accuracy or correctness is made and no responsibility is assumed as a result of reliance by the surety, whether such information is furnished by the owner or obligee or by an architect or engineer on behalf of the owner.
    </Text>
  </View>
);

export default PageDiscliamer;
