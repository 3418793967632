import React from 'react';
import pledgxeCQS from './PledgXeCQS.svg'

const PledgXeCQS = ({ isMobile }) => {
  return (
    <img width={isMobile ? "100" : "200"} height={isMobile ? "20" : "38"} src={pledgxeCQS} />
  )
};

export default PledgXeCQS;
