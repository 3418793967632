import React from 'react';
import { useMediaQuery } from "react-responsive";
import Input from '../../../components/inputField';
import { StyledInputContainer } from '../styles';
import { StyledYear, StyledRemarkContainer } from './styles';
import TextField from "../../../components/textField";


export const ValueOfConstruction = ({ data, setCollectedData }) => {
  const isMobile = useMediaQuery({ maxWidth: 1100 });

  return (
    <StyledInputContainer isMobile={isMobile}>
      <Input
        id='oneFifthInputWrap'
        label="Current Year (2021)"
        type="text"
        value={data ? data.years?.currentPrice : ''}
        marginTop={16}
        labelSize="15px"
        labelWeight="600"
        setValue={(val) => setCollectedData('years.currentPrice', val)}
        validateFunc={(item) => String(item).length > 0}
      />
      <Input
        id='oneFifthInputWrap'
        label="2020"
        type="text"
        value={data ? data.years?.price2020 : ''}
        marginTop={16}
        labelSize="15px"
        labelWeight="600"
        setValue={(val) => setCollectedData('years.price2020', val)}
        validateFunc={(item) => String(item).length > 0}
      />
      <Input
        id='oneFifthInputWrap'
        label="2019"
        type="text"
        value={data ? data.years?.price2019 : ''}
        marginTop={16}
        labelSize="15px"
        labelWeight="600"
        setValue={(val) => setCollectedData('years.price2019', val)}
        validateFunc={(item) => String(item).length > 0}
      />
      <Input
        id='oneFifthInputWrap'
        label="2018"
        type="text"
        value={data ? data.years?.price2018 : ''}
        marginTop={16}
        labelSize="15px"
        labelWeight="600"
        setValue={(val) => setCollectedData('years.price2018', val)}
        validateFunc={(item) => String(item).length > 0}
      />
      <Input
        id='oneFifthInputWrap'
        label="2017"
        type="text"
        value={data ? data.years?.price2017 : ''}
        marginTop={16}
        labelSize="15px"
        labelWeight="600"
        setValue={(val) => setCollectedData('years.price2017', val)}
        validateFunc={(item) => String(item).length > 0}
      />
      <StyledRemarkContainer isMobile={isMobile}>
        <StyledYear>Remarks</StyledYear>
        <TextField
          width="100%"
          placeholder="Description"
          withoutMargin={true}
          value={data ? data.years?.valueOfConstructionRemarks : ''}
          setValue={(val) => setCollectedData('years.valueOfConstructionRemarks', val)}
          validateFunc={(item) => true}
        />
      </StyledRemarkContainer>
    </StyledInputContainer>
  );
};
