import React, { useEffect } from "react";
import {
  MediumText,
  Content,
  StyledArea
} from '../styles';
import MobilePersonnelIcon from "../../../assets/dashboardIcons/mobilePersonnelIcon";
import MobileProjectsIcon from "../../../assets/dashboardIcons/mobileProjectsIcon";
import { useHistory } from "react-router";
import { Recommendations } from './Recommendations';
import { References } from "./References";
import { Criteria } from "./Criteria";
import {
  FunctionArea,
  MobileBoxText
} from './styles';
import { 
  StyledBox
} from '../styles';

export const FunctionSection = ({
  isMobile,
  userData
}) => {
  const history = useHistory()

  return (
    <Content isMobile={isMobile} >
      <StyledArea marginTop="24px" isMobile={isMobile}>
        <Recommendations isMobile={isMobile} userData={userData}/>
        <References isMobile={isMobile}/>
        <Criteria isMobile={isMobile}/>
        {isMobile && (
          <FunctionArea  isMobile={isMobile}>
            <StyledBox 
              boxHeight="110px" 
              borderRadius="15px" 
              isMobile={isMobile} 
              onClick={() => {history.push("/buyers/prequalified/contractors");}} 
              style={{cursor: "pointer"}}
              margin="0 0 16px 0"
              flexDirection="row"
            >
              <MobileProjectsIcon />
              <MobileBoxText>Contractors</MobileBoxText>
            </StyledBox>
          </FunctionArea>
        )}
      </StyledArea>
    </Content>
  );
}
