import countriesInArray from "../assets/locale/countriesInArray"

export const getCountryName = (value) => {
    if(value){
      let country =  countriesInArray.find(
        (item) => item.countryCode === value)
      if(country){
        return country.countryName
      }
    }
    return ""
  }