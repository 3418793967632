import React from 'react';
import { StyledInput } from './styles';


export const InputWithLabel = ({ value, label, setValue, placeholder, width, }) => {
  const handleOnChange = (e) => {
    const val = e.target.value;
    
    if(val <= 100 && val >= 0) {
      setValue(e.target.value);
    }
  };

  return (
    <StyledInput 
      value={value}
      onChange={handleOnChange}
      label={label? { content: label } : false}
      width={width} 
      labelPosition='right'
      placeholder={placeholder}
    />
  );
};
