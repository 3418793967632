import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { FunctionalButton } from "components/functionalButton";

import {
  StyledQuestion,
  InfoArea,
  StyledAddressContainer,
  // StyledSkipText,
  // TextContainer
 } from './styles';
 
 import Input from '../../../../components/inputField';
export const ProjectDetails = ({
  handleNext,
  data,
  setData,
  panel,
  setPanel,
  setIsLastPanel,
  setPreviousStepPanel
}) => {
  const isMobile = useMediaQuery({ maxWidth: 960 });
  setIsLastPanel(true);
  return (
    <>
      <StyledQuestion isMobile={isMobile} width="650px">What are the project details?</StyledQuestion>
      <InfoArea isMobile={isMobile} width="650px" isColumn={true} marginTop="0px">
        <Input
          label="Project title"
          type="text"
          labelSize="12px"
          fontSize="18px"
          marginTop="16px"
          // value={prequalifyData?.project_info?.owner_name}
          setValue={(e) => setData('project_title', e)}
        />
      </InfoArea>
      <InfoArea isMobile={isMobile} width="650px" isColumn={true} marginTop="0px">
      <StyledAddressContainer>
        <Input
          label="Project Number"
          type="text"
          labelSize="12px"
          fontSize="18px"
          // value={prequalifyData?.project_info?.owner_email}
          marginTop="16px"
          // setValue={(e) => setPrequalifyData('project_location', e)}
        />
        <Input
          label="Closing Date & Time for Submission"
          type="text"
          labelSize="12px"
          fontSize="18px"
          marginTop="16px"
          // value={prequalifyData?.project_info?.owner_phone}
          // setValue={(e) => setPrequalifyData('project_info.owner_phone', e)}
        />
       
      </StyledAddressContainer>
      </InfoArea>
      <FunctionalButton
          buttonTitle="Next"
          handleButton={() => handleNext()}
          width="200px"
          marginRight="0px"
          marginTop="48px"
        />
    </>
  );
}
