import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  ScreenRoot,
  HeaderArea,
  LargeText,
  DetailedFormContainer,
} from "./styles";

import { filterReferenceForThirdParty } from "api/reference";
import { LeftContainer } from "./LeftContainer";
import { RightContainer } from "./RightContainer";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";

export const ReferencesVieweCQS = ({ institutionId, readOnly }) => {
  const [query, setQuery] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [filteredData, setFilteredData] = useState([]);
  const [internalSearchResults, setInternalSearchResults] =
    useState(filteredData);

  const handleFilter = async (filterQuery) => {
    filterReferenceForThirdParty(
      filterQuery,
      (data) => setFilteredData(data),
      () => {}
    );
  };

  useEffect(() => {
    handleFilter(institutionId);
  }, []);

  return (
    <ScreenRoot>
      <DetailedFormContainer isMobile={isMobile} style={{ marginTop: "90px" }}>
        <HeaderArea>
          <LargeText isMobile={isMobile}></LargeText>
          {!isMobile && (
            <HeaderArea width="50%" justifyContent="flex-end">
              <SearchBar
                filterApi={filterReferenceForThirdParty}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                setInternalSearchResults={setInternalSearchResults}
                query={query}
                setQuery={setQuery}
                searchWidth="90%"
                internalSearch={true}
                searchPlaceHolder="Project Title, Location, Referee Name..."
                marginRight="10px"
              />
            </HeaderArea>
          )}
        </HeaderArea>
      </DetailedFormContainer>
      <DetailedFormContainer isMobile={isMobile}>
        <LeftContainer isMobile={isMobile} institutionId={institutionId} />
        <RightContainer
          isMobile={isMobile}
          data={query && query !== "" ? internalSearchResults : filteredData}
          setFilteredData={setFilteredData}
        />
      </DetailedFormContainer>
    </ScreenRoot>
  );
};
