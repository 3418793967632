import React, { useState } from "react";
import { useHistory } from "react-router";
import { MediumText, StyledBox, SmallText } from "../styles";

import { FunctionalButton } from "../../../components/functionalButton";
import criteriaComingSoon from "assets/dashboardIcons/criteriaComingSoon.svg";
import {
  FunctionArea,
  IntroArea,
  StyledTitle,
  ButtonContainer,
  ComingSoonImg,
  ImgDiv,
} from "./styles";

import ObligeeIcon from "assets/dashboardIcons/ObligeeIcon";

export const ObligeeBehaviours = ({ isMobile }) => {
  const [comingSoon, setComingSoon] = useState(false);
  let history = useHistory();

  const handleAccountInsights = () => {
    history.push("/underwriter/accountinsights");
  };

  return (
    <FunctionArea isMobile={isMobile}>
      {isMobile && (
        <StyledBox
          boxHeight="110px"
          borderRadius="15px"
          isMobile={isMobile}
          // onClick={}
          style={{ cursor: "pointer" }}
        >
          {comingSoon && (
            <ImgDiv>
              <ComingSoonImg src={criteriaComingSoon} />
            </ImgDiv>
          )}
          {!comingSoon && (
            <>
              <ObligeeIcon width={55} height={59} isMobile={isMobile} />
              <SmallText>Coming Soon</SmallText>
            </>
          )}
        </StyledBox>
      )}
      {!isMobile && (
        <>
          <StyledBox boxHeight="216px">
            {comingSoon && (
              <ImgDiv style={{ width: "100%" }}>
                <ComingSoonImg src={criteriaComingSoon} />
              </ImgDiv>
            )}
            {!comingSoon && (
              <>
                <IntroArea>
                  <ObligeeIcon />
                  {/* <StyledTitle>
                    <SmallText>Coming Soon</SmallText>
                  </StyledTitle> */}
                </IntroArea>
                <ButtonContainer>
                  <FunctionalButton
                    buttonTitle="Account Insights"
                    handleButton={handleAccountInsights}
                    width="80%"
                    marginRight="0px"
                    isReversedColor={true}
                    hasBoxShadow={true}
                  />
                </ButtonContainer>
              </>
            )}
          </StyledBox>
        </>
      )}
    </FunctionArea>
  );
};
