import axios from "axios";
import { API_HOST } from "../constants";
import JSZip from "jszip";

let headers = {};

const getAuth = () => {
  const authData = localStorage.getItem("Authorization");
  if (authData) {
    headers = { Authorization: authData };
  }
};
export const getAssociations = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/associations/get_association_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const sendContractStatusReport = async (token, onSuccess, onFailure) => {
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/contract/status/report/${token}`, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => onFailure(err.response));
};

export const viewContractStatusReport = async (token, onSuccess, onFailure) => {
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/underwriter/csr/view/${token}`, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => onFailure(err.response));
};

export const getUnions = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/unions/get_union_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const getBanks = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/banks/get_bank_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const getInsurances = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/insurances/get_insurance_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const getCorporates = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/corporate/get_corporate_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const getAgents = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/agents/get_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const getPackages = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/packages/get_package_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const getInsuranceTypes = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/insurance_type/get_insurance_type_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const getDocumentTypes = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/document_type/get_document_type_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const getConciergePaidAll = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/concierge/get_paid_all`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const getActiveUsers = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/reports/active_users`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const getAdminUsers = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/reports/admin/users`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const getUsers = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/reports/users`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const getAllIssueStatusReport = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/reports/all_issue_status_report`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const getAllPartnerReferralReport = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/reports/all_partner_referral_report`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const getAllInstitutionReport = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/reports/all_institution_report`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const getAllProspectUsersReport = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/reports/all_prospect_users_report`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const getFinancialInitialData = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/financial/initial/data/analysis`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

export const saveMarkFigureData = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/financial/mark/figure/save`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};


export const updateIdpDocumentCounter = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/idp/document/counter/update`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};


export const getFinancialAsGivenData = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/financial/initial/as/given/data/analysis`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

export const getFinancialEventStatusData = async (
  data,
  onSuccess,
  onFailure,
) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/financial/event/status/data`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

export const getFinancialDocuments = async (
  data,
  setDocumentsData,
  onFailure,
) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/financial/documents/data`,
      data,
      {
        headers,
        responseType: "blob",
      },
    );

    const zipBlob = new Blob([response.data], { type: "application/zip" });

    // Use JSZip to extract the files
    const zip = await JSZip.loadAsync(zipBlob);
    const pdfFiles = [];

    // Iterate over each file in the zip
    zip.forEach(async (relativePath, file) => {
      if (file.name.endsWith(".pdf")) {
        const pdfBlob = await file.async("blob");
        pdfFiles.push({ fileName: file.name, blob: pdfBlob });
      }
    });

    setTimeout(() => setDocumentsData(pdfFiles), 1000);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

export const postFinancialCSVData = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const formData = new FormData();
    formData.append("account_id", data.account_id);
    formData.append("company_name", data.company_name);
    formData.append(
      "csvFile",
      new Blob([data.csvData], { type: "text/csv" }),
      data.fileName,
    );
    const response = await axios.post(
      `${API_HOST}/underwriter/financial/csv/data/analysis`,
      formData,
      {
        headers: {
          ...headers,
          "Content-Type": "multipart/form-data",
        },
      },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

export const saveFinancialData = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/financial/data/analysis/save`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

export const singlePeerGroupAnalysis = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/peer/group/analysis/single`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

export const postImportWipCSVData = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const formData = new FormData();
    formData.append("account_id", data.account_id);
    formData.append("company_name", data.company_name);
    formData.append("company_id", data.company_id);
    formData.append(
      "csvFile",
      new Blob([data.csvData], { type: "text/csv" }),
      data.fileName,
    );
    const response = await axios.post(
      `${API_HOST}/wip/import/csv/data`,
      formData,
      {
        headers: {
          ...headers,
          "Content-Type": "multipart/form-data",
        },
      },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

export const postImportWipCSVDataUnderwriter = async (
  data,
  onSuccess,
  onFailure,
) => {
  try {
    getAuth();
    const formData = new FormData();
    formData.append("account_id", data.account_id);
    formData.append("company_name", data.company_name);
    formData.append("company_id", data.company_id);
    formData.append(
      "csvFile",
      new Blob([data.csvData], { type: "text/csv" }),
      data.fileName,
    );
    const response = await axios.post(
      `${API_HOST}/underwriter/wip/import/csv/data`,
      formData,
      {
        headers: {
          ...headers,
          "Content-Type": "multipart/form-data",
        },
      },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

export const getAuditTrailData = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/idp/audit/trail/events`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};



export const testRiskAndPdData = async (
  data,
  onSuccess,
  onFailure,
) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/ai/risk/and/pd/single/data`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

export const deleteFileFromIdpClientDocuments = async (
  data,
  onSuccess,
  onFailure,
) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/idp/delete/file`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

export const getSplitterData = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/idp/splitter/data`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

// edit split data API
export const editSplitData = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/idp/splitter/edit/data`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

export const getClassifierData = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/idp/classifier/data`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

export const getClassifierResponseData = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/idp/classifier/response/data`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

//
export const editClassifierData = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/idp/labeller/data`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

export const getLabellerData = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/idp/labeller/response/data`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

export const getPdfDocsFromBucket = async (data) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/idp/financial/documents`,
      data,
      {
        headers,
        responseType: "blob",
      },
    );

    const zipBlob = new Blob([response.data], { type: "application/zip" });

    const zip = await JSZip.loadAsync(zipBlob);
    const pdfFiles = [];

    const promises = [];
    zip.forEach((relativePath, file) => {
      if (file.name.endsWith(".pdf")) {
        const promise = file.async("blob").then((pdfBlob) => {
          pdfFiles.push({
            fileName: file.name,
            blob: pdfBlob,
          });
        });
        promises.push(promise);
      }
    });

    await Promise.all(promises);

    return pdfFiles;
  } catch (error) {
    console.error("Error fetching and unzipping PDF documents:", error);
    throw error;
  }
};

export const getFinancialDocumentsFromBucket = async (
  data,
  setDocumentsData,
  onFailure,
) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/idp/financial/documents`,
      data,
      {
        headers,
        responseType: "blob",
      },
    );

    const zipBlob = new Blob([response.data], { type: "application/zip" });

    // Use JSZip to extract the files
    const zip = await JSZip.loadAsync(zipBlob);
    const pdfFiles = [];

    // Iterate over each file in the zip
    zip.forEach(async (relativePath, file) => {
      if (file.name.endsWith(".pdf")) {
        const pdfBlob = await file.async("blob");
        pdfFiles.push({ fileName: file.name, blob: pdfBlob });
      }
    });

    setTimeout(() => setDocumentsData(pdfFiles), 1000);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

export const postTransferData = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/idp/labeller/transfer/data`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};


export const getMappingLabellerData = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/mapping/labeller/data`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};



export const saveTransferData = async (data, onSuccess, onFailure) => {
  try {
    getAuth();
    const response = await axios.post(
      `${API_HOST}/underwriter/idp/transfer/data/update`,
      data,
      { headers },
    );
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    onFailure(err.response);
  }
};

