import React, { useEffect, useState } from "react";

import { useMediaQuery } from "react-responsive";

import { PageHeader } from "../pageHeader";
import {
  StyledContainer,
  StyledInnerContainer,
  StyledMainContainer,
  StyledTitleContainer,
  StyledValueContainer,
} from "pages/workInProgress/styles";
import { useLocation } from "react-router";
import { GoBackButton } from "pages/buyers/components/goBackButton";
import BottomComponent from "./bottomComponent";
import { numberWithCommas } from "common/numberFormat";
import MiddleComponent from "./middleComponent";
import { StyledBoxContainer } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { clientProjectsWipSelector } from "data/selectors/underwriters";
import { getClientProjectsInfoForWip } from "data/actions/underwriters";
import { COLORS } from "../../../../constants";

const WipForProject = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });

  const location = useLocation();
  const [data, setData] = useState(location.state?.projectData || {});
  const projectId = location?.state?.projectId || "";
  const clientProjects = useSelector(clientProjectsWipSelector);
  const companyId = location.state?.companyId || "";
  const [changeOrder, setChangeOrder] = useState(0);
  const dispatch = useDispatch();
  const [eventData, seteventData] = useState(
    data?.wip_data ? data?.wip_data[data?.wip_data?.length - 1] : {}
  );

  useEffect(() => {
    if (clientProjects) {
      const projectDetails = clientProjects?.find(
        (obj) => obj.id === projectId
      );
      if (projectDetails) {
        setData(projectDetails);
      }
    }
  }, [clientProjects]);
  useEffect(() => {
    if (clientProjects?.length <= 0) {
      dispatch(
        getClientProjectsInfoForWip({ account_id: companyId }, "underway")
      );
    }
  }, []);

  useEffect(() => {
    const totalChangeOrderValue = data?.wip_data?.reduce((total, item) => {
      // Convert the values to numbers and add them to the total
      let changeInContractValue = item?.co_data?.change_in_contract_value
        ? parseFloat(item?.co_data.change_in_contract_value)
        : 0;
      return total + changeInContractValue;
    }, 0);

    setChangeOrder(totalChangeOrderValue);

    seteventData( data?.wip_data ? data?.wip_data[data?.wip_data?.length - 1] : {});

    const dataArray = data && data.wip_data && Array.isArray(data.wip_data) ? [...data.wip_data] : [];


    // Iterate over the array to find and update objects with co_data but without ppc_data.
    if (Array.isArray(dataArray) && dataArray.length > 0) {
    dataArray?.forEach((obj, index, originalArray) => {
      // Check if the current object has co_data and either no ppc_data or empty ppc_data.
      if (
        Object.keys(obj.co_data).length > 0 &&
        Object.keys(obj.ppc_data || {}).length === 0
      ) {
        // Find the most recent object with ppc_data after the current object.
        for (let i = index + 1; i < originalArray.length; i++) {
          if (Object.keys(originalArray[i].ppc_data || {}).length > 0) {
            // Once found, assign its ppc_data to the current object and break the loop.
            obj.ppc_data = originalArray[i].ppc_data;
            break;
          }
        }
      }
    });
  }
  }, [data]);

  const handleCircleClickEvents = (eventData) => {
    seteventData(eventData);
  };

  return (
    <StyledContainer isMobile={isMobile}>
      {data && Object.keys(data).length > 0 && (
        <>
          <GoBackButton
            buttonText={
              data?.project_status === "Completed"
                ? "Go Back"
                : "See Consolidated WIP"
            }
          />
          <PageHeader
            projectStatus={data?.project_status}
            subTitle={data?.project_number}
            companyName={data?.project_name}
            project={true}
            hasButton={true}
            marginBottom="8px"
            data={data}
            companyId={companyId}
            // handleButton={handleStartNew}
          />
          <StyledInnerContainer style={{ flexDirection: "row" }}>
            <StyledBoxContainer>
              <div>
                <StyledValueContainer color="#6E6B7B">$</StyledValueContainer>
                <StyledValueContainer color="#1A051D">
                  {numberWithCommas(data?.value)}
                </StyledValueContainer>
              </div>
              <StyledTitleContainer
                color="#888888"
                fontSize="11px"
                fontWeight="400"
              >
                Original Contract Price
              </StyledTitleContainer>
            </StyledBoxContainer>
            <StyledBoxContainer>
              <div>
                <StyledValueContainer color="#6E6B7B">$</StyledValueContainer>
                <StyledValueContainer color="#1A051D">
                  {numberWithCommas(changeOrder)}
                </StyledValueContainer>
              </div>
              <StyledTitleContainer
                color="#888888"
                fontSize="11px"
                fontWeight="400"
              >
                Change Orders
              </StyledTitleContainer>
            </StyledBoxContainer>
          </StyledInnerContainer>
          <StyledMainContainer style={{ height: "204px" }}>
            <MiddleComponent
              data={data}
              onCircleClick={handleCircleClickEvents}
            />
          </StyledMainContainer>
          <StyledMainContainer style={{ height: "530px" }}>
            <BottomComponent data={data} eventData={eventData} />
          </StyledMainContainer>
        </>
      )}
      {Object.keys(data).length <= 0 && (
        <StyledMainContainer
          style={{
            fontSize: "15px",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          No Progress Details Available
        </StyledMainContainer>
      )}
    </StyledContainer>
  );
};

export default WipForProject;
