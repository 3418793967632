import React from 'react';


const VerticalThreeDots = () => {
  return (
    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13 21.9375C14.3462 21.9375 15.4375 23.0708 15.4375 24.4688C15.4375 25.8667 14.3462 27 13 27C11.6538 27 10.5625 25.8667 10.5625 24.4688C10.5625 23.0708 11.6538 21.9375 13 21.9375ZM13 10.9688C14.3462 10.9688 15.4375 12.102 15.4375 13.5C15.4375 14.898 14.3462 16.0312 13 16.0312C11.6538 16.0312 10.5625 14.898 10.5625 13.5C10.5625 12.102 11.6538 10.9688 13 10.9688ZM15.4375 2.53125C15.4375 1.13328 14.3462 0 13 0C11.6538 0 10.5625 1.13328 10.5625 2.53125C10.5625 3.92922 11.6538 5.0625 13 5.0625C14.3462 5.0625 15.4375 3.92922 15.4375 2.53125Z" fill="#D0C9D6"/>
    </svg>
  )
};

export default VerticalThreeDots;
