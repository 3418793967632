import React from "react";
import { FunctionalButton } from 'components/functionalButton';
import { Popup } from 'components/popup';
import { CloseButton } from "components/closeButton";
import PledgxLogo from "assets/common/pledgxLogo";
import { statusReportTransaction } from "../../../../data/actions/dataMaintenance.js";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  TitleDiv,
  TitleText,
  PopupContainer,
  ButtonDiv
} from "./styles";


export const SubmitStatusReportRequest = ({
  openPopup,
  setOpenPopup,
  isMobile,
  data
}) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const handleDone = () => {
    setOpenPopup(false);
  };


  const saveSendingStatus = () => {
    dispatch(
      statusReportTransaction(
        data,
        () => {
          setOpenPopup(false);
          history.goBack();
        },
        () => { }
      )
    );
  };


  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width={isMobile ? "100%" : "577px"}
      height={isMobile ? "100vh" : "338px"}
      dialogContent={(
        <>
          <CloseButton handleClose={handleDone} />
          <PopupContainer>
            <TitleDiv>
              <PledgxLogo width={isMobile ? 75 : ""} height={isMobile ? 74 : ""} />
            </TitleDiv>

            <TitleText fontSize="28px" fontWeight="700" color="#1A051D" margin="41px 0px 39px" lineHeight="41px">Please confirm requesting Status</TitleText>
            <TitleText fontSize="28px" fontWeight="700" color="#1A051D" margin="-20px 0px 30px" lineHeight="41px" textAlign="center">Report from <span style={{fontWeight:"400"}}>{data?.underwriter}</span></TitleText>
            <ButtonDiv isMobile={isMobile}>
              <FunctionalButton
                buttonTitle="GO BACK"
                handleButton={handleDone}
                isReversedColor={true}
                height="48px"
                width="233px"
                marginTop="0px"
                marginRight="16px"
              />
              <FunctionalButton
                buttonTitle={"CONFIRM"}
                height="48px"
                width="233px"
                marginTop="0px"
                marginRight="0px"
                handleButton={() => saveSendingStatus()}
              />
            </ButtonDiv>
          </PopupContainer>

        </>
      )}
    />

  )
}
