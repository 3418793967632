import React from "react";
import { useMediaQuery } from "react-responsive";
import { StyledCard } from "./styles";
import { HeaderText } from "./HeaderText";
import displayDate from "../../../utilities/displayDate";

export const ProjectHeader = ({ data }) => {
  const isMobile = useMediaQuery({ maxWidth: 453 });

  return (
    <HeaderText
      isMobile={isMobile}
      projectTitle={data?.project?.title}
      projectNumber={data?.project?.number}
      projectLocation={data?.project?.location}
      projectClosingDate={data?.expiry && displayDate({ date: data?.expiry })}
      projectOriginalStatus={data?.original_status}
      projectStatus={data?.status}
      projectSubmissions={data?.submission_count}
      projectInvitationCount={data?.invited_institutions?.length}
    />
  );
};
