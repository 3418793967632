import React from "react";
import Input from "../inputField";
import { InfoArea, SectionText } from "./styles";
import { StyledRow } from "./styles";
import CustomSelect from "components/selectField";
import { formatPhoneNumber } from "../../common/formatPhoneNumber";
import { phoneRegex } from "../../common/regex";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

export const Step1 = ({ setDisable, addData, setAddData, isMobile }) => {
  const userType = ["Contractor", "Contractor & Buyer", "Buyer", "Underwriter", "Agent/Broker",];

  return (
    <InfoArea
      isMobile={isMobile}
      marginTop="0px"
      marginLeft="8px"
      isColumn={true}
    >
      <CustomSelect
        style={{ paddingRight: "16px" }}
        label="User Type*"
        options={userType.map((item) => ({ value: item, label: item }))}
        value={addData?.user_type || ""}
        setValue={(val) => {
          setAddData("user_type", val);
        }}
        validateFunc={(item) => Boolean(item)}
        noCheckIcon={true}
        showDropdownIndicator={true}
      />

      <SectionText>Admin User  Information</SectionText>
      <StyledRow isMobile={isMobile}>
        <Input
          id="inputWrap"
          label="First Name"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.first_name || ""}
          setValue={(val) => setAddData("first_name", val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />

        <Input
          id="inputWrap"
          label="Last Name"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.last_name || ""}
          setValue={(val) => setAddData("last_name", val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
      </StyledRow>


      <StyledRow isMobile={isMobile}>

        <div style={{ marginTop: "24px", width: "100%", marginLeft: '0px', marginRight: '0px' }}>
          <label htmlFor="phone" style={{ fontSize: "12px" }}>
            Phone Number
          </label>
          <div style={{ height: "48px", width: "100%", marginTop: "-10px" }}>
            <PhoneInput
              id="phone"
              country={"ca"}
              value={addData?.phone}
              onChange={(value) => setAddData("phone", value)}
              style={{ marginTop: "16px", width: "100%", height: "100%" }}
              inputStyle={{
                fontSize: "18px",
                padding: "8px",
                lineHeight: "24px",
                marginLeft: "20px",
                paddingLeft: "28px",
                height: "100%",
                width: "89%",
                marginRight: "20px",
              }}
              dropdownStyle={{ fontSize: "18px", lineHeight: "24px" }}
            />
          </div>
        </div>

        <Input
          id="fullWidthInputWrap"
          label="User's Email"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.email || ""}
          setValue={(val) => setAddData("email", val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
          disabled
          style={{ marginLeft: "5px" }}
        />
      </StyledRow>



    </InfoArea>
  );
};
