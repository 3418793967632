import React from 'react';
import additional from './companyInfo_grey.svg'

const CompanyInfo_grey = ({ isMobile }) => {
  return (
    <img width={isMobile ? "36" : "56"} height={isMobile ? "36" : "56"} src={additional} />
  )
};

export default CompanyInfo_grey;
