import React, { useEffect } from "react";
import { FloatingContainer, StyledButton } from "./styles";
import { useElementVisibility } from "../../utilities/useElementVisibility";

export const FloatingButton = ({
  buttonTitle,
  handleButton,
  width,
  height,
  isReversedColor,
  marginRight,
  marginLeft,
  marginTop,
  marginBottom,
  fontSize,
  disabled,
  icon,
  hasBoxShadow,
  frontIcon,
  weight,
  borderColor,
  selected,
  controlElementRef,
}) => {
  return (
    <FloatingContainer>
      <StyledButton
        onClick={handleButton}
        width={width}
        height={height}
        isReversedColor={isReversedColor}
        marginRight={marginRight}
        marginLeft={marginLeft}
        marginTop={marginTop}
        marginBottom={marginBottom}
        fontSize={fontSize}
        disabled={disabled}
        hasBoxShadow={hasBoxShadow}
        weight={weight}
        borderColor={borderColor}
        selected={selected}
      >
        {frontIcon && <>{icon}</>}
        {buttonTitle}
        {!frontIcon && <>{icon}</>}
      </StyledButton>
    </FloatingContainer>
  );
};
