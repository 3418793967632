import React, { useEffect, useState } from "react";
import { ColumnContainer } from './styles';
import { CompanySummary } from "./leftComponents/CompanySummary";
import { getAwardsPoint, getCompanyReferenceInfoForThirdParty, getScores } from '../../api/reference';
import { Awards } from './leftComponents/Awards';
import { Scores } from './leftComponents/Scores';

export const LeftContainer = ({
  isMobile,
  institutionId
}) => { 
  const [companyData, setCompanyData] = useState({})
  const [logo, setLogo] = useState('')
  const [points, setPoints] = useState('')
  const [scores, setScores] = useState('')

  useEffect(() => {
    getCompanyReferenceInfoForThirdParty(institutionId,
      (res) => {
        setCompanyData(res); 
        setLogo(res.logo);
        getAwardsPoint(res?.id, (data) => setPoints(data), () => {});
        getScores(res?.id, (data) => setScores(data), () => {});
      },
      () => {}
    )
  },[])


  return (
    <ColumnContainer isMobile={isMobile} margin="0 16px 0 0px" width="40%">
      <CompanySummary isMobile={isMobile} data={companyData} logo={logo} setLogo={setLogo}/>
      {points && (
        <Awards isMobile={isMobile} points={points} />
      )}
      {scores && (
        <Scores isMobile={isMobile} scores={scores} />
      )}
    </ColumnContainer>
  )
};
