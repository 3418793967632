import React from 'react';

const Company = ({ active, completed }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1579:3220)">
        <g filter="url(#filter0_d_1579:3220)">
          <circle cx="14" cy="14" r="14" fill="white"/>
          <circle cx="14" cy="14" r="13.5" fill={active || completed ? "#FF6D1D" : "white"} stroke="#ECEBED"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5625 9H8.6875C8.308 9 8 9.308 8 9.6875V14.5C8 14.8795 8.308 15.1875 8.6875 15.1875H10.0625V17.25L12.125 15.1875H15.5625C15.942 15.1875 16.25 14.8795 16.25 14.5V9.6875C16.25 9.308 15.942 9 15.5625 9ZM17.625 11.75H18.3125C18.692 11.75 19 12.058 19 12.4375V17.25C19 17.6295 18.692 17.9375 18.3125 17.9375H16.9375V20L14.875 17.9375H11.4375L12.6943 16.5625H16.9375C17.317 16.5625 17.625 16.2545 17.625 15.875V11.75Z" fill={active || completed ? "white" : "#D0C9D6"} />
      </g>
      <defs>
        <filter id="filter0_d_1579:3220" x="-64" y="-57" width="156" height="156" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="7"/>
          <feGaussianBlur stdDeviation="32"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1579:3220"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1579:3220" result="shape"/>
        </filter>
        <clipPath id="clip0_1579:3220">
          <rect width="28" height="28" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  )
};

export default Company;

