import React from 'react';

const MobilePersonnelIcon = () => {
  return (
    <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.04662 6.9113C4.03165 6.9113 0 7.91921 0 9.93418V12.0925H12.0924V9.93418C12.0924 7.91921 8.06244 6.9113 6.04662 6.9113ZM12.9566 6.9113C12.7052 6.9113 12.4237 6.92685 12.1222 6.95794C13.1224 7.6817 13.8194 8.65334 13.8194 9.93418V12.0925H19.0006V9.93418C19.0006 7.91921 14.9715 6.9113 12.9566 6.9113ZM6.05095 0C4.6207 0 3.45992 1.16165 3.45992 2.5919C3.45992 4.02215 4.6207 5.18294 6.05095 5.18294C7.48207 5.18294 8.63422 4.02215 8.63422 2.5919C8.63422 1.16165 7.48207 0 6.05095 0ZM12.9594 0C11.5274 0 10.3675 1.16165 10.3675 2.5919C10.3675 4.02215 11.5274 5.18294 12.9594 5.18294C14.3897 5.18294 15.5409 4.02215 15.5409 2.5919C15.5409 1.16165 14.3897 0 12.9594 0Z" fill="#888888"/>
    </svg>

  )
};

export default MobilePersonnelIcon;


