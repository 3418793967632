
import React from 'react';

const PoorFace = ({ width, height }) => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.9359 32.2376C25.1619 32.2376 31.8303 25.557 31.8303 17.3161C31.8303 9.07513 25.1619 2.39453 16.9359 2.39453C8.70996 2.39453 2.0415 9.07513 2.0415 17.3161C2.0415 25.557 8.70996 32.2376 16.9359 32.2376Z" fill="white"/>
    <path d="M11.6683 15.6356C12.5896 15.6356 13.3366 14.8873 13.3366 13.9643C13.3366 13.0412 12.5896 12.293 11.6683 12.293C10.7469 12.293 10 13.0412 10 13.9643C10 14.8873 10.7469 15.6356 11.6683 15.6356Z" fill="#FF6D1D"/>
    <path d="M22.2493 15.6356C23.1707 15.6356 23.9176 14.8873 23.9176 13.9643C23.9176 13.0412 23.1707 12.293 22.2493 12.293C21.328 12.293 20.5811 13.0412 20.5811 13.9643C20.5811 14.8873 21.328 15.6356 22.2493 15.6356Z" fill="#FF6D1D"/>
    <path d="M9 23C9.23005 22.7583 11.8224 20.1226 16.676 20.0037C21.9335 19.8809 24.8541 22.8465 25 23" stroke="#FF6D1D" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
    </svg>
  )
};

export default PoorFace;
