import React, { useState } from 'react';

import { filterProjects } from '../../../../api/prequalify';

import IncrementalSearchPickList from '../../../../components/incrementalSearchPickList';
import { StyledTableCell } from '../../../../components/filesListTable/styles';

import { StyledImgIcon } from './styles';
import plusIcon from '../../../../assets/common/plusIcon.svg';

export const SelectingProjects = ({ add, projects, setProjects }) => {
  const [filteredData, setFilteredData] = useState([]);

  const headers = [
    { title: '' },
    { title: 'Project #' },
    { title: 'Project Title' },
    { title: 'Project Location' },
  ];

  const handleProjectSelection = (bodyData) => {
    const filteredTemp = [...filteredData];
    const projectsTemp = [...projects];

    projectsTemp.unshift({
      id: bodyData.id,
      name: bodyData.project_name,
      project_number: bodyData.project_number,
      location: bodyData.project_location,
      type: ['key'],
    });

    setProjects(projectsTemp);
    filteredTemp.splice(bodyData.index, 1);
    setFilteredData(filteredTemp);
  };

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCell component={'tr'}>
          <StyledImgIcon src={plusIcon} onClick={() => handleProjectSelection(bodyData)} />
        </StyledTableCell>
        <StyledTableCell component={'tr'}>
          {bodyData.project_number}
        </StyledTableCell>
        <StyledTableCell component={'tr'}>
          {bodyData.project_name}
        </StyledTableCell>
        <StyledTableCell component={'tr'}>
          {bodyData.project_location}
        </StyledTableCell>
      </>
    );
  };

  return (
    <IncrementalSearchPickList
      add={add}
      headers={headers}
      body={body}
      conciseLength={5.5}
      filterApi={filterProjects}
      filteredData={filteredData}
      setFilteredData={setFilteredData}
      addButtonTitle="Add Project"
    />
  );
};
