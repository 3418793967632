import React, { useEffect, useRef, useState } from "react";
import { COLORS, FONT_SIZES } from "../../constants";
import classes from "./style/FormBuilder.module.css";
import { ItemBuilder } from "./ItemBuilder";
import calculatePosition from "./utils/calculatePosition";
import { FormBuilder } from "../formBuilder";

export const PageBuilder = ({
  conditionalState,
  currentLocation,
  data,
  formFields,
  formSetup,
  formSubmitted,
  handleInteraction,
  id,
  interactedFields,
  itemStates,
  onSubmitItem,
  readOnly,
  disabled,
  setConditionalState,
  supplementaryData,
}) => {
  const [state, setState] = useState({
    currentPosition: null,
  });
  const stateRef = useRef({
    currentPosition: null,
  });

  stateRef.current = state;

  useEffect(() => {
    setCurrentPosition();
  }, [currentLocation, formSetup]);

  function setCurrentPosition() {
    var currentPosition = calculatePosition({
      currentLocation: currentLocation,
      formSetup: formSetup,
    });
    setState({
      ...stateRef.current,
      currentPosition: currentPosition.currentPage,
    });
  }

  if (
    stateRef.current.currentPosition &&
    (stateRef.current.currentPosition.itemType === "page" ||
      stateRef.current.currentPosition.itemType === "reviewPage")
  ) {
    return (
      <div
        className={[
          classes["page-container"],
          "pledgxform-page-container",
        ].join(" ")}
      >
        {stateRef.current.currentPosition.pageTitleLong && (
          <div
            className={[
              classes["page-title-container"],
              "pledgxform-page-title-container",
            ].join(" ")}
            style={
              stateRef.current.currentPosition.pageTitleVerticalMargins
                ? {
                    margin: `${stateRef.current.currentPosition.pageTitleVerticalMargins} 0 ${stateRef.current.currentPosition.pageTitleVerticalMargins} 0`,
                  }
                : { margin: "36px 0 36px 0" }
            }
          >
            <span
              className={[
                classes["page-title-text"],
                "pledgxform-page-title-text",
              ].join(" ")}
              style={{ fontSize: FONT_SIZES.heading_6 }}
            >
              {stateRef.current.currentPosition.pageTitleLong}
            </span>
          </div>
        )}
        {stateRef.current.currentPosition.content && (
          <div
            className={[
              classes["page-content-container"],
              "pledgxform-page-content-container",
            ].join(" ")}
            style={
              stateRef.current.currentPosition.pageTitleLong
                ? {}
                : { marginTop: "70px" }
            }
          >
            {stateRef.current.currentPosition.content.map(
              (pageDetails, pageDetailsIndex) => (
                <>
                  {pageDetails.itemType === "field" &&
                    formFields?.[pageDetails.id] && (
                      <div
                        className={[
                          classes["item-container"],
                          "pledgxform-item-container",
                        ].join(" ")}
                      >
                        <ItemBuilder
                          alternateDataSourceField={
                            formFields[pageDetails.id].dataSource &&
                            formFields[formFields[pageDetails.id].dataSource]
                          }
                          conditionalFieldValue={
                            formFields[pageDetails.id].condition?.field &&
                            data?.[formFields[pageDetails.id].condition.field]
                          }
                          conditionalState={conditionalState}
                          formId={id}
                          formSubmitted={formSubmitted}
                          handleInteraction={handleInteraction}
                          id={pageDetails.id}
                          interactedFields={interactedFields}
                          item={formFields[pageDetails.id]}
                          itemStates={itemStates}
                          disabled={disabled}
                          readOnly={
                            stateRef.current.currentPosition.itemType ===
                            "reviewPage"
                              ? true
                              : readOnly
                          }
                          setConditionalState={setConditionalState}
                          onSubmitItem={onSubmitItem}
                          institutionId={data?.institutionId}
                          data={data}
                          supplementaryData={supplementaryData}
                          value={
                            data?.[formFields[pageDetails.id].dataSource] ||
                            data?.[pageDetails.id] ||
                            ""
                          }
                          width={pageDetails.width || "100%"}
                        />
                      </div>
                    )}
                  {pageDetails.itemType === "form" &&
                    (stateRef.current.currentPosition.itemType ===
                      "reviewPage" ||
                      formFields[pageDetails.id]) && (
                      <div
                        className={[
                          classes["inner-form-container"],
                          "pledgxform-inner-form-container",
                        ].join(" ")}
                      >
                        {stateRef.current.currentPosition.itemType ===
                        "reviewPage" ? (
                          <FormBuilder
                            data={data}
                            formDetails={pageDetails.formDetails}
                            formFields={formFields}
                            formSetup={pageDetails.formSetup}
                            id={pageDetails.id}
                            navigationType="TABS"
                            readOnly={true}
                          />
                        ) : (
                          <FormBuilder
                            data={data}
                            changeData={handleInteraction}
                            formDetails={formFields[pageDetails.id].formDetails}
                            formFields={formFields}
                            formSetup={formFields[pageDetails.id].formSetup}
                            id={pageDetails.id}
                            navigationType={
                              formFields[pageDetails.id].navigationType ||
                              "TABS"
                            }
                            readOnly={
                              formFields[pageDetails.id].readOnly || false
                            }
                          />
                        )}
                      </div>
                    )}
                  {pageDetails.itemType === "group" ? (
                    pageDetails.setup ? (
                      <>
                        {(pageDetails.groupName || pageDetails.marginTop) && (
                          <div
                            style={{
                              marginTop:
                                pageDetails.marginTop ||
                                (pageDetailsIndex === 0 ? "0" : "50px"),
                            }}
                          >
                            <span
                              style={{
                                fontSize: FONT_SIZES.body_3,
                                fontWeight: "bold",
                              }}
                            >
                              {pageDetails.groupName
                                ? pageDetails.groupName
                                : ""}
                            </span>
                          </div>
                        )}
                        <div
                          className={[
                            classes["interaction-inner-container-split"],
                            "pledgxform-interaction-inner-container-split",
                          ].join(" ")}
                        >
                          {["left", "center", "right"].map((placement) => (
                            <div
                              className={[
                                classes[
                                  `interaction-inner-container-${[placement]}`
                                ],
                                `pledgxform-interaction-inner-container-${[
                                  placement,
                                ]}`,
                              ].join(" ")}
                            >
                              {Object.keys(pageDetails.setup).includes(
                                placement
                              ) &&
                                pageDetails.setup[placement].map(
                                  (recognizedItem) =>
                                    pageDetails.content[recognizedItem] &&
                                    pageDetails.content[recognizedItem]
                                      .display !== false && (
                                      <ItemBuilder
                                        alternateDataSourceField={
                                          formFields[recognizedItem]
                                            .dataSource &&
                                          formFields[
                                            formFields[recognizedItem]
                                              .dataSource
                                          ]
                                        }
                                        conditionalFieldValue={
                                          formFields[recognizedItem].condition
                                            ?.field &&
                                          data?.[
                                            formFields[recognizedItem].condition
                                              .field
                                          ]
                                        }
                                        conditionalState={conditionalState}
                                        flex={
                                          pageDetails.content[recognizedItem]
                                            .flex || 1
                                        }
                                        formId={id}
                                        formSubmitted={formSubmitted}
                                        handleInteraction={handleInteraction}
                                        id={recognizedItem}
                                        interactedFields={interactedFields}
                                        item={formFields[recognizedItem]}
                                        itemStates={itemStates}
                                        institutionId={data?.institutionId}
                                        data={data}
                                        readOnly={
                                          stateRef.current.currentPosition
                                            .itemType === "reviewPage"
                                            ? true
                                            : readOnly
                                        }
                                        setConditionalState={
                                          setConditionalState
                                        }
                                        onSubmitItem={onSubmitItem}
                                        disabled={disabled}
                                        supplementaryData={supplementaryData}
                                        value={
                                          data?.[
                                            formFields[recognizedItem]
                                              .dataSource
                                          ] ||
                                          data?.[recognizedItem] ||
                                          ""
                                        }
                                        width={
                                          pageDetails.content[recognizedItem]
                                            .width || "100%"
                                        }
                                      />
                                    )
                                )}
                            </div>
                          ))}
                        </div>
                        {pageDetails.navigationMessage && (
                          <div
                            className={classes["navigation-message"]}
                            style={{
                              color: COLORS.textGrey,
                              fontSize: FONT_SIZES.body_5,
                            }}
                          >
                            {pageDetails.navigationMessage}
                          </div>
                        )}
                      </>
                    ) : (
                      <div>
                        {(pageDetails.groupName || pageDetails.marginTop) && (
                          <div
                            style={{
                              marginTop:
                                pageDetails.marginTop ||
                                (pageDetailsIndex === 0 ? "0" : "50px"),
                            }}
                          >
                            <span
                              style={{
                                fontSize: FONT_SIZES.body_3,
                                fontWeight: "bold",
                              }}
                            >
                              {pageDetails.groupName
                                ? pageDetails.groupName
                                : ""}
                            </span>
                          </div>
                        )}
                        <div
                          className={[
                            classes["item-group-container"],
                            "pledgxform-item-container",
                          ].join(" ")}
                        >
                          {pageDetails.content.map((pageInnerDetails) => (
                            <>
                              {pageInnerDetails.itemType === "field" &&
                                formFields?.[pageInnerDetails.id] && (
                                  <ItemBuilder
                                    alternateDataSourceField={
                                      formFields[pageInnerDetails.id]
                                        .dataSource &&
                                      formFields[
                                        formFields[pageInnerDetails.id]
                                          .dataSource
                                      ]
                                    }
                                    conditionalFieldValue={
                                      formFields[pageInnerDetails.id].condition
                                        ?.field &&
                                      data?.[
                                        formFields[pageInnerDetails.id]
                                          .condition.field
                                      ]
                                    }
                                    conditionalState={conditionalState}
                                    flex={pageInnerDetails.flex || 1}
                                    formId={id}
                                    disabled={disabled}
                                    formSubmitted={formSubmitted}
                                    handleInteraction={handleInteraction}
                                    id={pageInnerDetails.id}
                                    interactedFields={interactedFields}
                                    item={formFields[pageInnerDetails.id]}
                                    institutionId={data?.institutionId}
                                    data={data}
                                    itemStates={itemStates}
                                    readOnly={
                                      stateRef.current.currentPosition
                                        .itemType === "reviewPage"
                                        ? true
                                        : readOnly
                                    }
                                    setConditionalState={setConditionalState}
                                    onSubmitItem={onSubmitItem}
                                    supplementaryData={supplementaryData}
                                    value={
                                      data?.[
                                        formFields[pageInnerDetails.id]
                                          .dataSource
                                      ] ||
                                      data?.[pageInnerDetails.id] ||
                                      ""
                                    }
                                    width={pageInnerDetails.width || "100%"}
                                  />
                                )}
                            </>
                          ))}
                        </div>
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                  {pageDetails.itemType === "label" && (
                    <div
                      className={[
                        classes["inner-form-container"],
                        "pledgxform-inner-form-container",
                      ].join(" ")}
                    >
                      <div
                        className={classes["item-label"]}
                        style={{
                          fontSize: FONT_SIZES.body_3,
                          fontWeight: "bold",
                          marginBottom: pageDetails.noBottomMargin
                            ? "0"
                            : "5px",
                          marginTop: pageDetails.topMargin || "50px",
                        }}
                      >
                        {pageDetails.label}
                      </div>
                    </div>
                  )}
                </>
              )
            )}
          </div>
        )}
      </div>
    );
  } else {
    return <div>Empty</div>
  }
};
