import { useMediaQuery } from "react-responsive";
import { StyledQuestion, InfoArea } from "./styles";
import { FunctionalButton } from "../../../components/functionalButton";
import Select from "../../../components/selectField";
import countriesInArray from "../../../assets/locale/countriesInArray";
import { getCountryName } from "../../../common/countryName";


export const Country = ({
  handleNext,
  onboardingData,
  setOnboardingData,
  setIsLastPanel,
  setPreviousStepPanel,
}) => {
  setIsLastPanel(true);
  setPreviousStepPanel(1);

  const isMobile = useMediaQuery({ maxWidth: 960 });

  const selectCountry = (value) => {
    if (value) {
      setOnboardingData("country", value);
    }
  };

  return (
    <>
      <StyledQuestion isMobile={isMobile}>
        What country are you in?
      </StyledQuestion>
      <InfoArea
        marginTop={isMobile ? "36px" : "56px"}
        isMobile={isMobile}
        width="100%"
      >
        <div className="inputWrap">
          <Select
            style={{ marginRight: "16px" }}
            label="Country"
            options={countriesInArray.map((country) => ({
              value: country.countryCode,
              label: country.countryName,
            }))}
            value={getCountryName(onboardingData?.country)}
          
            setValue={(value) => {
              selectCountry(value);
            }}
            validateFunc={(item) => Boolean(item)}
            noCheckIcon={true}
            showDropdownIndicator={true}
          />
        </div>
      </InfoArea>
      {/* <ButtonAtBottom isMobile={isMobile}> */}
      <FunctionalButton
        buttonTitle="Next"
        handleButton={handleNext}
        width={isMobile ? "90%" : "200px"}
        marginRight="0px"
        marginTop="72px"
        disabled={!(onboardingData?.country?.length > 0)}
      />
      {/* </ButtonAtBottom> */}
    </>
  );
};
