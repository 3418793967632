import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Switch, Redirect, Route } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { useSelector } from "react-redux";
import { stripeDataSelector } from "../../data/selectors/subscriptions";
import PrivateRoute from "../../containers/PrivateRoute";

import Spinner from "../../components/spinner";

import { Packages } from "./Packages";
import { Payment } from "./Payment";
import { Confirmation } from "./Confirmation";

import { LoadingContainer, SubscribeRoot } from "./styles";
import { STRIPE_PUBLISHABLE_KEY } from "../../constants";

function SubscribePage() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isMedium = useMediaQuery({ maxWidth: 720 });
  const [state, setState] = useState({
    loading: false,
  });

  const stripeData = useSelector(stripeDataSelector);
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret:
      stripeData &&
      stripeData.stripe_client_secret &&
      stripeData.stripe_client_secret.token
        ? stripeData.stripe_client_secret.token
        : null,
    appearance: appearance,
  };

  return (
    <SubscribeRoot isMobile={isMedium}>
      {state.loading ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : (
        <Switch>
          <Route
            exact
            path="/subscribe"
            render={() => <Redirect to="/subscribe/packages" />}
          />
          <PrivateRoute
            type="LOGGED_IN"
            path="/subscribe/packages"
            component={Packages}
            componentProps={{ isMobile: isMobile }}
          />
          <PrivateRoute
            type="ALL_USERS"
            path="/subscribe/subscribed"
            component={Confirmation}
            componentProps={{
              isMobile: isMobile,
              subscriptionStatus: "SUBSCRIBED",
            }}
          />
          <PrivateRoute
            type="ALL_USERS"
            path="/subscribe/unsubscribed"
            component={Confirmation}
            componentProps={{
              isMobile: isMobile,
              subscriptionStatus: "UNSUBSCRIBED",
            }}
          />
          <PrivateRoute
            type="LOGGED_IN"
            path="/subscribe/pay"
            prerequisiteLoading={
              !stripeData ||
              Object.keys(stripeData).length < 1 ||
              !stripeData.stripe_client_secret ||
              !stripeData.stripe_client_secret.token
            }
          >
            <Elements options={options} stripe={stripePromise}>
              <Payment />
            </Elements>
          </PrivateRoute>
        </Switch>
      )}
    </SubscribeRoot>
  );
}

export default SubscribePage;
