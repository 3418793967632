import React from "react";

export const ClockIcon = () => {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="24.0468" cy="24" rx="24.0184" ry="24" fill="white" />
      <ellipse cx="24.0466" cy="24" rx="19.515" ry="19.5" fill="#FF6D1D" />
      <path
        d="M24.0464 20V25M24.0464 34C19.2127 34 15.2897 30.08 15.2897 25.25C15.2897 20.42 19.2127 16.5 24.0464 16.5C28.8801 16.5 32.8031 20.42 32.8031 25.25"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.0442 13.5H24.0465H26.9487M26.9487 30.5V29.34C26.9487 27.91 27.9695 27.32 29.2105 28.04L30.2112 28.62L31.212 29.2C32.453 29.92 32.453 31.09 31.212 31.81L30.2112 32.39L29.2105 32.97C27.9695 33.69 26.9487 33.1 26.9487 31.67V30.5Z"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
