import React from "react";
import admin from "./shovelIcon.png";
import styled from "styled-components";

const AdminIcon = ({ width, height, isMobile }) => {
  return (
    <StyledImg
      // width={width || "400"}
      height={height || "250"}
      src={admin}
      isMobile={isMobile}
    />
  );
};

export default AdminIcon;

const StyledImg = styled.img`
  ${(props) => (props.isMobile ? "margin-bottom: 8px; margin-top: 12px;" : "")}
`;
