import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from '../../../constants';
import Typography from '@material-ui/core/Typography';




export const ImagePopup = styled.div`
  margin-top: 16px;
`;


export const ButtonArea = styled.div`
  margin-top: 40px;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;



export const LargeTextSuccess = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${(props) => (props.isMobile ? "22px" : "28px")};
    font-style: normal;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 24px;
  }
`;

export const SmallText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.secondaryFont};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 12px;
  }
`;

export const StyledAddressContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  gap: 16px;
`;



export const Title = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 28px;
    font-style: normal;
    font-weight: ${props => props.fontWeight || 700};
    line-height: 41px;
    letter-spacing: 0px;
    text-align: center;
    ${props => props.margin ? `margin: ${props.margin};` : "" }
  }
`;

export const PopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.isMobile ? "90%" : "100%")};
    margin-top: 28px;
    justify-content: center;
    align-items: center;
`;

export const TableDiv = styled.div`
  margin-top: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InfoText = styled(Typography)`
  &&{
    font-family:  ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.fontSize || "17px"};
    font-style: normal;
    font-weight:  ${props => props.fontWeight || "600"};
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
  }
`;

export const InfoDiv = styled.div `
  width: 80%;
  display: flex; 
  flex-direction: column;
  margin-top: 32px;
`;

export const ElementDiv = styled.div`
  margin-top: ${props => props.marginTop || "16px"};
  width: 80%;
`;

export const ImgButton = styled.img`
  cursor: pointer;
  width: 196px;
  height: 20px;
`;

export const FormArea = styled.div`
  ${props => props.marginLeft && !props.isMobile ? `margin-left: ${props.marginLeft};` : ''}
  margin-top: ${(props) => props.marginTop || "24px"};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  ${props => props.noJustifyContent ? "" : "justify-content: center;"}
  ${props => props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}
  
  .last {
    padding-right: 0px !important;
  }
  
  .inputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '50%'};
  }

  .fullWidthInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: 100%;
  }

`;