import React, { useState } from "react";
import {
  StyledContainerTitle,
  StyledHeaderContent,
  StyledButtonArea,
  StyledContainerInside,
} from "./styles";
import { FunctionalButton } from "../../../components/functionalButton";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";

export const AllClientHeader = ({
  title,
  hasButton,
  handleButton,
  marginBottom,
  subTitle,
  data,
  setData,
  query,
  setQuery,
  internalSearchResults,
  setInternalSearchResults,
}) => {
 
  return (
    <StyledContainerTitle>
      <StyledContainerInside>
        <StyledHeaderContent marginBottom={marginBottom}>
          {title}
        </StyledHeaderContent>
        {subTitle && (
          <div style={{ marginBottom: "16px", fontSize: "18px" }}>
            {subTitle}
          </div>
        )}
      </StyledContainerInside>
      {hasButton && (
        <StyledButtonArea>
          <FunctionalButton
            buttonTitle="Add New"
            // handleButton={handleButton}
            width="180px"
            height="48px"
            isReversedColor={true}
            disabled
          />
          <SearchBar
            searchWidth="60%"
            internalSearch={true}
            query={query}
            setQuery={setQuery}
            filteredData={data}
            setInternalSearchResults={setInternalSearchResults}
          />
        </StyledButtonArea>
      )}
    </StyledContainerTitle>
  );
};
