import React, { useState, useEffect } from "react";

import ConvertToDate from '../../../common/convertToDate';

import {
  Row,
  FormTitle,
  StyledLargeTitle,
} from "../styles";

import Input from "../../../components/inputField";
import TextField from "../../../components/textField";
import Select from "../../../components/selectField";

import { businessOptions } from "../../../data/consts";
import { LooksGoodButton } from "../components/looksGoodButton";
import { StyleButtonDiv } from './styles';

export const AddPanel = ({
  addData,
  setAddData,
  setPanel,
  setProjects,
  editIndex,
  setEditIndex,
  setOpenEdit

}) => {
  const [manualSubmission, setManualSubmission] = useState(true);

  useEffect(() => {
    if (addData.name &&
        addData.completed &&
        addData.price) {
      setManualSubmission(false);
    } else {
      setManualSubmission(true);
    }
  }, [addData]);

  return (
    <>
    <StyledLargeTitle>Add a Project</StyledLargeTitle>
      <FormTitle>Add a project</FormTitle>
      <Row>
        <Input
          style={{ paddingRight: "16px" }}
          label="Project Title*"
          type="text"
          placeholder="Project Title"
          value={addData.name}
          setValue={(item) =>
            setAddData((prev) => {
              return { ...prev, name: item };
            })
          }
          validateFunc={(item) => item.length > 0}
        />
        <Select
          style={{ paddingRight: "16px"}}
          label="Project Category"
          options={businessOptions}
          value={addData.category}
          setValue={(item) =>
            setAddData((prev) => {
              return { ...prev, category: item };
            })
          }
          validateFunc={(item) => Boolean(item)}
        />
      </Row>
      <Row>
        <Input
          style={{ paddingRight: "16px" }}
          label="Date Completed*"
          type="date"
          value={addData.completed}
          setValue={(item) =>
            setAddData((prev) => {
              return { ...prev, completed: ConvertToDate(item) };
            })
          }
          validateFunc={(item) =>  item.length > 0}
        />
        <Input
          style={{ paddingRight: "16px" }}
          label="Project Value ($)*"
          type="text"
          placeholder="Project Value"
          value={addData.price}
          setValue={(item) =>
            setAddData((prev) => {
              return { ...prev, price: item };
            })
          }
          validateFunc={(item) => String(item).length > 0}
        />
      </Row>
      <TextField
        style={{ paddingRight: "16px" }}
        label="Add a brief description"
        placeholder="Description"
        value={addData.description}
        setValue={(item) =>
          setAddData((prev) => {
            return { ...prev, description: item };
          })
        }
        validateFunc={(item) => item.length > 0}
      />

      <FormTitle>Who was the Owner?*</FormTitle>
      <Row>
        <Input
          withoutMargin
          style={{ paddingRight: "16px" }}
          type="text"
          placeholder="Owner"
          value={addData.owner}
          setValue={(item) =>
            setAddData((prev) => {
              return { ...prev, owner: item };
            })
          }
          validateFunc={(item) => item.length > 0}
        />
      </Row>

      <FormTitle>Who was the Consultant?</FormTitle>
      <Row>
        <Input
          withoutMargin
          style={{ paddingRight: "16px" }}
          type="text"
          placeholder="Owner"
          value={addData.consultant}
          setValue={(item) =>
            setAddData((prev) => {
              return { ...prev, consultant: item };
            })
          }
          validateFunc={(item) => item.length > 0}
        />
      </Row>
      <StyleButtonDiv>
        <LooksGoodButton
          style={{ maxWidth: 250, marginBottom:"32px" }}
          disabled={manualSubmission}
          onClick={() => {
            setProjects((prev) => {
              if(editIndex === -1) {
                return [addData, ...prev];
              }

              let temp = [...prev];
              temp[editIndex] = addData;
              return temp;
            });
            setOpenEdit(false);
            setEditIndex(-1);
            setAddData({ type: ['key'] });
            setPanel("result");
          }}
        >
          {'Add Project >'}
        </LooksGoodButton>
      </StyleButtonDiv>

    </>
  );
};
