import React from "react";
import { FunctionalButton } from 'components/functionalButton';
import { Popup } from 'components/popup';
import { CloseButton } from "components/closeButton";
import { filterReference, referenceComment, changeReferenceStatus } from "api/reference";
import { MultilineTextField } from "components/multilineTextField";
import PledgxLogo from "assets/common/pledgxLogo";

import {
  FormTitle,
  TitleDiv,
  PopupContainer,
  ButtonDiv
} from "./styles";


export const ContestPost = ({
  openPopup,
  setOpenPopup,
  isMobile,
  data,
  comment,
  setComment,
  setHasComment,
  setFilteredData
}) => {



  const handleFilter = async (filterQuery) => {
    await filterReference(filterQuery)
      .then((data) => {
        if (data) {
          setFilteredData(data);
        }
      })
      .catch((err) => { });
  };


  const handleDone = () => {
    setOpenPopup(false);
  };

  const handleContestStatus = () => {
    changeReferenceStatus({
      id: data?.id,
      status: "contested"
    }, () => {
      // handleFilter("");
      setOpenPopup(false);
      window.location.reload();
    }, () => { })
  };


  const postComment = () => {
    if (comment) {
      referenceComment({
        id: data?.id,
        comment: comment
      }, (res) => {
        setHasComment(true);
        setComment(res?.comment);
        handleContestStatus();
      }, () => { })
    }
  }


  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width={isMobile ? "100%" : "595px"}
      height={isMobile ? "100vh" : "634px"}
      dialogContent={(
        <>
          <CloseButton handleClose={handleDone} />
          <PopupContainer>
            <TitleDiv>
              <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
              <FormTitle style={{ marginTop: 10 }}>
                {"Add a Comment"}
              </FormTitle>
            </TitleDiv>

            <MultilineTextField
              label=""
              placeholder="Response"
              row={16}
              width="100%"
              value={comment}
              setValue={(e) => setComment(e.target.value)}
              margin="10px 0px"
              noErrorBorder={true}
            />

            <ButtonDiv isMobile={isMobile}>
              <FunctionalButton
                buttonTitle="GO BACK"
                handleButton={handleDone}
                isReversedColor={true}
                height="48px"
                width="233px"
                marginTop="0px"
                marginRight="0px"
              />
              <FunctionalButton
                buttonTitle={"CONFIRM"}
                height="48px"
                width="233px"
                marginTop="0px"
                marginRight="0px"
                handleButton={() => postComment()}
                disabled={!comment}
              />
            </ButtonDiv>
          </PopupContainer>

        </>
      )}
    />

  )
}
