import React, { useEffect, useState } from "react";

import {
  MediumText,
  Content,
  StyledArea,
  StyledBox,
  PlaceholderText,
  StyledTooltipButton,
  StyledTooltip,
  TooltipText,
  ButtonPlacement,
} from "../styles";

import {
  StyledTableCellFirst,
  StyledTableCellSecond,
  ListContainer,
  ProgressColumn,
  StyledTableCellStatus,
  StyledDownloadedContainer,
  StyledReviewedContainer,
  StyledDraftContainer,
} from "./styles";

import { useHistory } from "react-router-dom";
import { FunctionalButton } from "../../../components/functionalButton";
import { ArrowIcon } from "./asset/ArrowIcon";
import { StyledTableCell } from "../../../components/filesListTable/styles";
import FilesListTable from "../../../components/filesListTable/index";
import { deleteSingleCCDC, getCCDCs } from "../../../api/prequalify";
import VerticalThreeDots from "../../../assets/dashboardIcons/verticalThreeDots";
import CompletedProgress from "../../../assets/dashboardIcons/completedProgress";
import { COLORS } from "../../../constants";
import { truncateString } from "../../../common/truncateString";
import { ThreeDotsPopupMenu } from "components/threeDotsPopupMenu";

export const QualificationSection = ({ isMobile, userData }) => {
  let history = useHistory();
  const [data, setData] = useState("");

  const ccdcList = async () => {
    await getCCDCs()
      .then((data) => {
        setData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    ccdcList();
  }, []);

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCellFirst
          isMobile={isMobile}
          component={"tr"}
          borderRadius="20px"
        >
          {isMobile
            ? truncateString(bodyData.project_title, 18)
            : bodyData.project_title}
        </StyledTableCellFirst>
        {!isMobile && (
          <StyledTableCellSecond
            component={"tr"}
            scope="row"
            borderRadius="20px"
          >
            {bodyData.project_location}
          </StyledTableCellSecond>
        )}

        <StyledTableCellStatus
          isMobile={isMobile}
          component={"tr"}
          scope="row"
          borderRadius="20px"
        >
          {bodyData.status === null ? (
            <StyledDraftContainer>Draft</StyledDraftContainer>
          ) : bodyData.status === "Reviewed" ? (
            <StyledReviewedContainer>Reviewed</StyledReviewedContainer>
          ) : bodyData.status === "Submitted" ? (
            <StyledDownloadedContainer>Submitted </StyledDownloadedContainer>
          ) : bodyData.status === "Downloaded" ? (
            <StyledDownloadedContainer>Downloaded </StyledDownloadedContainer>
          ) : bodyData.status === "Prequalified" ? (
            <StyledDownloadedContainer>Prequalified </StyledDownloadedContainer>
          ) : (
            ""
          )}
        </StyledTableCellStatus>
      </>
    );
  };

  const handlePickCCDC = (id) => {
    // console.log(id);
    history.push("/prequalify/qualifying", {
      action: "edit",
      ccdcId: id,
    });
  };
  const handleReviewEcqs = (id) => {
    history.push("/prequalify/preview/", {
      section: "ecqs",
      action: "edit",
      ccdcId: id,
    });
  };

  const deleteCCDC = (id) => {
    deleteSingleCCDC(
      id,
      () => ccdcList(),
      () => {}
    );
  };

  const actions = (ccdcId) => {
    return (
      <>
        <StyledTableCell component={"tr"} align="center" borderRadius="20px">
          <ThreeDotsPopupMenu
            item={{ id: ccdcId }}
            removeEdit={"cloneCcdc"}
            data={data}
            handleSeeAll={handleOnboarding}
            handleModify={handlePickCCDC}
            handleDelete={deleteCCDC}
            handleReviewEcqs={handleReviewEcqs}
          />
        </StyledTableCell>
      </>
    );
  };

  const createHeader = (title, align) => {
    return {
      title,
      align: align || "left",
    };
  };

  const allHeaders = [
    createHeader("Project Title"),
    createHeader("Location"),
    createHeader("Status", "center"),
    createHeader(""),
  ];

  const handleSeeAll = () => {
    history.push("/all/CQS");
  };

  const handleOnboarding = () => {
    history.push("/contractor-dashboard");
    ccdcList();
  };

  const handleNew = () => {
    history.push("/prequalify/qualifying");
  };

  // const [open, setOpen] = useState(userData?.status == "active" ? false : true);

  // useEffect(() => {
  //   setOpen(userData?.status == "active" ? false : true)
  // },[userData])

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleOpen = () => {
  //     setOpen(true);
  // };

  return (
    <Content isMobile={isMobile}>
      <MediumText isMobile={isMobile}>
        Your Qualifications Statements
      </MediumText>
      <StyledBox
        hasData={data?.length > 0}
        boxHeight="270px"
        noBackground={data?.length > 0 ? true : false}
      >
        {data?.length > 0 && (
          <ListContainer>
            <FilesListTable
              boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;"
              borderRadius="20px"
              headers={isMobile ? "" : allHeaders}
              data={data.slice(0, 3)}
              body={body}
              actions={actions}
              // hover={true}
              // handleClickRow={handlePickCCDC}
            />
          </ListContainer>
        )}
        {!(data?.length > 0) && (
          <PlaceholderText>
            Your Qualifications Statements will appear here
          </PlaceholderText>
        )}
      </StyledBox>
      <StyledArea
        marginTop={isMobile && data?.length > 0 ? "-38px" : "12px"}
        isMobile={isMobile}
      >
        <ButtonPlacement isMobile={isMobile}>
          {data?.length > 0 && (
            <FunctionalButton
              buttonTitle={
                isMobile ? "See All" : "See All Qualification Statements"
              }
              handleButton={handleSeeAll}
              width="100%"
              marginRight="0px"
              isReversedColor={true}
              icon={<ArrowIcon fill="#FF6D1D" />}
              hasBoxShadow={true}
            />
          )}
        </ButtonPlacement>
        {/* { userData?.status != "active" && (
          <StyledTooltip
           open={true}
           onClose={handleClose}
           onOpen={handleOpen}
           title={
               <TooltipText>Complete your profile to start using Qualify!</TooltipText>
           }
           placement="left-start"
           arrow
           componentsProps={{
             tooltip: {
               sx: {
                 minHeight: 50,
                 maxWidth: `${isMobile ? "120px" : "250px"}`,
                 borderTopLeftRadius: 10,
                 borderTopRightRadius: 10,
                 borderBottomLeftRadius: 10,
                 borderBottomRightRadius: 10,
                 bgcolor: `${COLORS.primaryOrange}`,
                 '& .MuiTooltip-arrow': {
                   color: `${COLORS.primaryOrange}`,
                 },
               },
             },
           }}
           >
           <StyledTooltipButton isMobile={isMobile}>
             <FunctionalButton
               buttonTitle={isMobile ? "Start New" : "Start a New Qualification Statement"}
               handleButton={() => {}}
               width="100%"
               marginRight="0px"
               icon={<ArrowIcon fill="#FFAA7B"/>}
               hasBoxShadow={true}
               disabled={true}
             />
           </StyledTooltipButton>
         </StyledTooltip>
        )} */}
        {userData?.status == "active" && (
          <StyledTooltipButton>
            <FunctionalButton
              buttonTitle={
                isMobile ? "Start New" : "Start a New Qualification Statement"
              }
              handleButton={userData?.status == "active" ? handleNew : () => {}}
              width="100%"
              marginRight="0px"
              icon={<ArrowIcon fill="white" />}
              hasBoxShadow={true}
              disabled={!(userData?.status == "active")}
            />
          </StyledTooltipButton>
        )}
      </StyledArea>
    </Content>
  );
};
