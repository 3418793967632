import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PageTwoCompanyInfoTableRow from './pageTwoCompanyInfoTableRow';
import PageTwoCompanyInfoTableHeader from './pageTwoCompanyInfoTableHeader';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 10,
        justifyContent: 'flex-start',
        width: '80%',
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30,
    },
    companyInfo: {
        marginTop: -40,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
    },
    companyInfoWithoutLogo: {
        marginTop: -7,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
    },
    spanText: {
        color: '#888888',
    },
    spanTable: {
        marginTop: 16,
        color: '#888888',
    },
    viewContainer: {
        borderRadius: 6,
        backgroundColor: '#F8F8F8',
        width: 537,
        paddingTop: 2,
        paddingBottom: 3,
    },
});

const PageTwoCompanyInformation = ({ prequalifyData, companyLogo }) => (
    <View style={styles.headerContainer}>
        {companyLogo !== ""  ? <Text style={styles.companyInfo}>Company Information</Text> : <Text style={styles.companyInfoWithoutLogo}>Company Information</Text> } 
        <Text>
            <span style={styles.spanText}>Year Established: </span>
            {prequalifyData?.data?.yearEstablished}
        </Text>
        <Text>
            <span style={styles.spanText}>Venture Type: </span>
            {prequalifyData?.data?.ventureType}
        </Text>
        <Text><span style={styles.spanText}>Operational Region: </span>
            {prequalifyData?.data?.operationalRegion} {","}
            {prequalifyData?.data?.operationalProvince} {","}
            {prequalifyData?.data?.operationalCountry}
        </Text>

        <Text style={styles.spanTable}>Officers, Partners and Principals: </Text>
        <View style={styles.viewContainer}>
            <PageTwoCompanyInfoTableHeader />
            <PageTwoCompanyInfoTableRow items={prequalifyData?.data?.legalStructurePersonnels?.selectedData} />
        </View>

        <Text style={{ marginTop: 10 }}>
            <span style={styles.spanText}>Construction Association: </span>
            {prequalifyData?.data?.companyAssociation}
        </Text>
        <Text>
            <span style={styles.spanText}>Construction Union: </span>
            {prequalifyData?.data?.companyUnion}
        </Text>

    </View>
);

export default PageTwoCompanyInformation;
