import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#EBE9F1'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#EBE9F1',
        backgroundColor: '#F8F8F8',
        color: '#888888',
        alignItems: 'center',
        height: 24,
        textAlign: 'left',
        fontStyle: 'bold',
        paddingLeft: 15,
        marginTop: 5,
    },
    name: {
        width: '40%',
        borderRightColor: borderColor,
        color: '#888888',
    },
    position: {
        width: '30%',
        borderRightColor: borderColor,
        color: '#888888',
    },
    linkedIn: {
        width: '15%',
        borderRightColor: borderColor,
        color: '#888888',
    },
    resume: {
        width: '15%',
        borderRightColor: borderColor,
    },
});

const PageTwoCompanyInfoTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.name}>Name</Text>
        <Text style={styles.position}>Position</Text>
        <Text style={styles.linkedIn}>LinkedIn</Text>
        <Text style={styles.resume}>Resume</Text>
    </View>
);

export default PageTwoCompanyInfoTableHeader;
