import React from 'react';
import { StyledDropdown, StyledLabel } from './styles';


const SelectDropdown = ({
  label,
  placeholder,
  options,
  handleSelect,
  multiple,
  search,
  value,
  defaultValue,
  width,
  minWidth,
  height,
  position,
  disabled,
  hasLabel,
  hasData,
  clearable,
  className,
  readOnly
}) => {
  return(
    <div className={className}>
      { hasLabel && (<StyledLabel>{label}</StyledLabel>)}
      {/* <StyledMenu width={width} hasData={hasData}> */}
        <StyledDropdown
          value={value}
          selection
          fluid
          multiple={multiple}
          search={search}
          placeholder={placeholder}
          options={options}
          onChange={(e, data) => handleSelect(data)}
          defaultValue={defaultValue}
          width={width}
          minWidth={minWidth}
          item
          height={height}
          hasData={hasData}
          position={position}
          clearable={clearable}
          disabled={readOnly || disabled}
          readOnly={ readOnly }
        />
      {/* </StyledMenu> */}
    </div>
  );
}

export default SelectDropdown;
