import React, { useState, useEffect } from "react";
import { SelectingProjects } from "./components/selectingProjects";
import { SelectedProjects } from "./components/selectedProjects";
import { AddPersonnelPopup } from "components/addPersonnelPopup";
import {
  StyledPicklistSmallHeader,
  StyledPicklistSmallHeaderContainer,
} from "../styles";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { contactSelector } from "../../../../data/selectors/contact";
import { savePersonnel } from "../../../../api/dashboard";
import { institutionProfileInfoSelector } from "data/selectors/serviceProvider";
import { AlertPopup } from "components/alertPopup/alertPopup";
export const ResultPanel = ({
  projects,
  setPanel,
  setAddData,
  setProjects,
  setEditIndex,
  setOpenEdit,
  setOpenPopup,
  openPopup,
  paddingRight,
  height,
  label,
  institution_id,
  currentDashboardUrl,
}) => {
  const [individual, setIndividual] = useState(null);
  const [pickedArray, setPickedArray] = useState([]);
  const [rerender, setRerender] = useState(false);
  let contactData = useSelector(contactSelector);
  const institutionContactData = useSelector(institutionProfileInfoSelector);
  const isMobile = useMediaQuery({ maxWidth: 480 });
  if (currentDashboardUrl === "/service-provider-dashboard") {
    contactData = institutionContactData;
  }
  const fileLimitCount = contactData?.user_access_control?.file_limit_count;
  const fileLimitSize = contactData?.user_access_control?.file_limit_size;
  const fileLimitSizeMB = (fileLimitSize / (1024 * 1024)).toFixed(2);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const personnelLimitCount =
    contactData?.user_access_control?.number_of_personnel;

  const addPersonnel = (data) => {
    if (
      currentDashboardUrl === "/service-provider-dashboard" &&
      institution_id
    ) {
      data.append(
        "institution_id",
        JSON.stringify({
          institution_id: institution_id,
        })
      );
    }
    savePersonnel(
      data,
      () => {
        setIndividual("");
      },
      onFailure
    );
  };

  const onFailure = (error) => {
    if (error?.status == 409) {
      setHasError(true);
      setErrorMessage(error?.data?.message);
    }
  };

  useEffect(() => {
    if (individual) {
      const individualTemp = individual[0];
      const temp = [individualTemp, ...projects];
      setProjects(temp);
    }
  }, [individual]);

  return (
    <>
      <StyledPicklistSmallHeaderContainer>
        <StyledPicklistSmallHeader>
          {label === false ? "" : "Officers, Partners and Principals"}
        </StyledPicklistSmallHeader>
      </StyledPicklistSmallHeaderContainer>
      <SelectedProjects
        projects={projects}
        setProjects={setProjects}
        setOpenPopup={setOpenPopup}
        openPopup={openPopup}
        individual={individual}
        setPickedArray={setPickedArray}
        pickedArray={pickedArray}
        rerender={rerender}
        setRerender={setRerender}
      />
      <SelectingProjects
        add={() => setPanel("add")}
        projects={projects}
        setProjects={setProjects}
        setOpenPopup={setOpenPopup}
        pickedArray={pickedArray}
        setPickedArray={setPickedArray}
        rerender={rerender}
        setRerender={setRerender}
        paddingRight={paddingRight}
        height={height}
        institution_id={institution_id}
        currentDashboardUrl={currentDashboardUrl}
      />
      <AddPersonnelPopup
        setOpenDialog={setOpenPopup}
        openDialog={openPopup}
        data={individual}
        setData={setIndividual}
        handleButtonFunc={addPersonnel}
        fileLimitCount={fileLimitCount}
        fileLimitSizeMB={fileLimitSizeMB}
        personnelLimitCount={personnelLimitCount}
        institution_id={institution_id}
      />
      <AlertPopup
        openPopup={hasError}
        setOpenPopup={setHasError}
        isMobile={isMobile}
        headerText="Personnel Add/Edit Alert"
        subHeaderText={`${errorMessage}`}
        showButton={false}
      />
    </>
  );
};
