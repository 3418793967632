import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  height: 68px;
  background: #ffffff;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const Label = styled.div`
  color: black;
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid grey;
  width: 100%;
`;

export const Item = styled(Link)`
  color: ${(props) => (props.active ? "black" : "#c4c4c4")};
  ${(props) => (props.active ? "border-bottom: 3px solid #F26934" : "")};
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 25px;
  cursor: pointer;
  display: block;
  margin: 0 20px;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    opacity: ${(props) => (props.active ? 1 : 0.7)};
  }
`;
