import React, { useState } from 'react';
import { StyledTableRow } from "./styles";


export const MultiSelectedRow = (props) => {
  const {
    actions,
    actionType,
    icons,
    body,
    selectedIndexList,
    setSelectedIndexList,
    handleSelected,
    hover,
    handleClickRow,
    borderRadius,
    boxShadow,
    noActions,
  } = props;

  const bodyProps = { ...props };
  delete bodyProps['actions'];
  delete bodyProps['icons'];
  delete bodyProps['body'];

  const isItemSelected = (row) => {
    return (selectedIndexList.includes(row.index));
  }

  const handleClick = (event, row) => {
    if(handleSelected) {
      let temp = [...selectedIndexList]
      if (!temp.includes(row.index)) {
        temp.push(row.index)
      } else {
        temp = temp.filter(item => item !== row.index)
      }
      setSelectedIndexList(temp)
      handleSelected(temp);
    }
  };

  return (
    <>
      <StyledTableRow
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        hover={hover}
        onClick={(event) => handleClick(event, bodyProps)}
        selected={isItemSelected(bodyProps)}
      >
        {icons && icons()}
        {body && body(bodyProps)}
        {actions && actionType && !noActions && actions(bodyProps)}
      </StyledTableRow>
    </>
  );
};
