import React, { useEffect, useState } from "react";
import { StyledTableCell } from '../../../components/filesListTable/styles';
import ConciseTable from '../../../components/filesListTable/conciseTable';
import { StyledProjectSelectionContainer } from '../styles';
import { getConciergePaidAll } from "../../../api/external_data";
import { StyledExpiredContainer, StyledLiveContainer} from '../styles'


export default function ConciergePaid(filteredData,
  editFunc,
  deleteFunc,
  isMobile) {

  const headers = [
    { title: 'Company Name',width:"25%" },
    { title: 'Admin Name',width:"20%" },
    { title: 'Admin Contacts',width:"35%" },
    { title: 'Service Expiry Date / Time',width:"30%" },
    { title: 'Status', align: 'center',width:"5%" },
    { title: '' }
  ];

  const [conciergePaidAll, setConciergePaidAll] = useState([]);

  useEffect(() => {
    getConciergePaidAll()
      .then((res) => {
        setConciergePaidAll(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);



  const body = (bodyData) => {
    return (
      <>
        <StyledTableCell component={'tr'} width="20%">
          {bodyData.company_name}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="20%">
          {bodyData.admin_name}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="30%">
          {bodyData.admin_contact}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="20%">
          {bodyData?.user_access?.service_expiry}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="10%">
          {bodyData?.user_access?.is_concierge_service_active === "Expired" ? (
                <StyledExpiredContainer>Expired</StyledExpiredContainer>
              ) : bodyData?.user_access?.is_concierge_service_active === "Live" ? (
                <StyledLiveContainer>Live</StyledLiveContainer>
              ) : (
                ""
              )}
        </StyledTableCell>
      </>

    );
  };


  return (
    <StyledProjectSelectionContainer isMobile={isMobile}>

      <ConciseTable
        headers={isMobile ? "" : headers}
      />
      <ConciseTable
        length={isMobile ? 5 : 5.5}
        body={body}
        data={conciergePaidAll}
      />
    </StyledProjectSelectionContainer>
  )
}
