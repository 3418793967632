import React, { useEffect, useState } from "react";

import { StyledBox, SmallText, GreySmallText } from "../styles";
import { truncateString } from "../../../common/truncateString";
import {
  PersonnelContent,
  StyledDownloadedContainer,
  TitleDiv,
} from "./styles";

export const ReportsCard = ({ cqs, institutionName }) => {
  return (
    <>
      {cqs?.map((item, i) => (
        <StyledBox
          boxHeight="80px"
          borderRadius="10px"
          margin="0 0 20px 0"
          key={i}
        >
          <TitleDiv>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <SmallText>{truncateString(institutionName, 31)}</SmallText>
              {item.status === "Submitted" && (
                <StyledDownloadedContainer>Submitted</StyledDownloadedContainer>
              )}
              {item.status === "Downloaded" && (
                <StyledDownloadedContainer>
                  Downloaded
                </StyledDownloadedContainer>
              )}
              {item.status === "Prequalified" && (
                <StyledDownloadedContainer>
                  Prequalified
                </StyledDownloadedContainer>
              )}
            </div>
            <GreySmallText>
              {truncateString(item?.project_title, 31)}
            </GreySmallText>
          </TitleDiv>
        </StyledBox>
      ))}
    </>
  );
};
