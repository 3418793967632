
import React from 'react';

const Rating = ({width, height}) => {
  return (
    <svg width={width || "128"} height={height || "23"} viewBox="0 0 128 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.0675 9.1607L11.654 2L9.24048 9.1607H2L7.90099 13.9328L5.58163 21.308L11.654 16.7502L17.7263 21.308L15.407 13.9328L21.308 9.1607H14.0675Z" fill="white" stroke="#FF6D1D"/>
      <path d="M40.2404 9.1607L37.827 2L35.4135 9.1607H28.173L34.074 13.9328L31.7546 21.308L37.827 16.7502L43.8993 21.308L41.5799 13.9328L47.4809 9.1607H40.2404Z" fill="white" stroke="#FF6D1D"/>
      <path d="M66.4135 9.1607L64 2L61.5866 9.1607H54.3461L60.2471 13.9328L57.9277 21.308L64 16.7502L70.0724 21.308L67.753 13.9328L73.654 9.1607H66.4135Z" fill="white" stroke="#FF6D1D"/>
      <path d="M92.5865 9.1607L90.173 2L87.7595 9.1607H80.519L86.42 13.9328L84.1007 21.308L90.173 16.7502L96.2454 21.308L93.926 13.9328L99.827 9.1607H92.5865Z" fill="white" stroke="#FF6D1D"/>
      <path d="M118.759 9.1607L116.346 2L113.933 9.1607H106.692L112.593 13.9328L110.274 21.308L116.346 16.7502L122.418 21.308L120.099 13.9328L126 9.1607H118.759Z" fill="white" stroke="#FF6D1D"/>
    </svg>
  )
};

export default Rating;
