import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { convertPhoneNumberCanadaUSA } from "common/regex";
import { getCountryName } from "common/countryName";
import {
  StyledQuestion,
  InfoArea,
  StyledSkipText,
  TextContainer,
  StyledInput,
  StyledAddressContainer,
  StyledInputLabelContainer,
  StyledInputLabel,
  StyledOrangeHighlight,
} from "../styles";

import Input from "../../../../components/inputField";
import { FunctionalButton } from "../../../../components/functionalButton";
import { LastSkip } from "../lastSkip";
import { StyledRow, SubRow } from "pages/userOnBoardingPage/components/styles";
import { formatPhoneNumber } from "common/formatPhoneNumber";

export const FillAddress = ({
  handleNext,
  prequalifyData,
  setPanel,
  setIsLastPanel,
  setPreviousStepPanel,
  setPrequalifyData,
  readOnly,
}) => {
  // setIsLastPanel(false);
  // setPreviousStepPanel(2);
  const isMobile = useMediaQuery({ maxWidth: 960 });
  const [phoneNumber, setPhoneNumber] = React.useState(prequalifyData?.project_info?.owner_phone);

  const handlePhoneNumberChange = (value) => {
    setPrequalifyData("project_info.owner_phone", value)
  };


  let autocomplete;
  let address1Field;
  let address2Field;
  let postalField;
  let cityField;
  let provinceField;
  let countryField;

  // setIsLastPanel(true);

  function initAutocomplete() {
    address1Field = document.querySelector("#ship-address");
    address2Field = document.querySelector("#address2");
    postalField = document.querySelector("#postcode");
    cityField = document.querySelector("#locality");
    provinceField = document.querySelector("#state");
    countryField = document.querySelector("#country");
    autocomplete = new window.google.maps.places.Autocomplete(address1Field, {
      componentRestrictions: { country: ["ca", "us"] },
      fields: ["address_components", "geometry"],
      types: ["address"],
    });
    // address1Field.focus();
    autocomplete.addListener("place_changed", fillInAddress);
  }

  function fillInAddress() {
    const place = autocomplete.getPlace();
    let address1 = "";
    let postcode = "";
    let city = "";
    let province = "";
    let country = "";


    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case "route": {
          address1 += component.short_name;
          break;
        }

        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }
        case "locality":
          city = component.long_name;
          break;
        case "country":
          country = component.short_name;
          break;
        case "administrative_area_level_1": {
          province = component.short_name;
          break;
        }
      }
    }

    address1Field.value = address1;
    setPrequalifyData("project_info.owner_address", address1Field.value);
    postalField.value = postcode;
    setPrequalifyData("project_info.owner_postal_code", postalField.value);
    cityField.value = city;
    setPrequalifyData("project_info.owner_city", cityField.value);
    provinceField.value = province;
    setPrequalifyData("project_info.owner_province", provinceField.value);
    countryField.value = country;
    setPrequalifyData("project_info.owner_country",countryField.value)

    address2Field.focus();
  }

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyCwLidsAgErglXnD9dT6qnaQF7u_DYU3Pc&libraries=places`,
      () => initAutocomplete()
    );
  });

  let setNumber;
  function format(phone) {
    setNumber = formatPhoneNumber(prequalifyData?.project_info?.owner_phone);
    // console.log(setNumber);
    return setNumber;
  }

  const postalCode = prequalifyData?.project_info?.owner_postal_code;
  const canadianPostalCodePattern = /^[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d$/;
  const usaZipCodePattern = /^\d{5}(?:[-\s]\d{4})?$/;

  //prequalifyData?.project_info?.owner_phone
  return (
    <>
      <StyledQuestion isMobile={isMobile} width="650px">
        Who is the Project Owner?
      </StyledQuestion>
      <form id="address-form" action="" method="get" autocomplete="off">
        <InfoArea
          isMobile={isMobile}
          width="100%"
          isColumn={true}
          marginTop="0px"
        >
          <InfoArea
            isMobile={isMobile}
            width="100%"
            isColumn={true}
            marginTop="0px"
          >
            <Input
              label="Company Name"
              type="text"
              labelSize="12px"
              fontSize="18px"
              marginTop="16px"
              value={prequalifyData?.project_info?.owner_name}
              setValue={(e) => setPrequalifyData("project_info.owner_name", e)}
              readOnly={readOnly}
            />
          </InfoArea>
          <StyledAddressContainer>
            <Input
              label="Email"
              type="text"
              labelSize="12px"
              fontSize="18px"
              value={prequalifyData?.project_info?.owner_email}
              marginTop="16px"
              width="100%"
              setValue={(e) => setPrequalifyData("project_info.owner_email", e)}
              readOnly={readOnly}
            />
            {readOnly ? (
              <Input
                label="Phone Number"
                type="text"
                labelSize="12px"
                fontSize="18px"
                marginTop="16px"
                value={convertPhoneNumberCanadaUSA(prequalifyData?.project_info?.owner_phone)}
                setValue={(e) =>
                  setPrequalifyData(
                    "project_info.owner_phone",
                    formatPhoneNumber(e)
                  )
                }
                readOnly={readOnly}
              />
            ) : (
              <div style={{ marginTop: '16px', width: "100%" }}>
                <label htmlFor="phone" style={{ fontSize: '12px' }}>Phone Number</label>
                <div style={{ height: '46px', width: "100%", marginTop: '-10px' }}>
                  <PhoneInput
                    id="phone"
                    country={'ca'}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    style={{ marginTop: '16px', width: '100%', height: '100%' }}
                    inputStyle={{ fontSize: '18px', padding: '8px', lineHeight: '24px', marginLeft: '20px', paddingLeft: '28px', height: '100%' }}
                    dropdownStyle={{ fontSize: '18px', lineHeight: '24px' }}
                  />
                </div>
              </div>

            )}

          </StyledAddressContainer>

          <StyledAddressContainer>
            <StyledInputLabelContainer>
              <StyledInputLabel for="locality">Owner Location</StyledInputLabel>
              {readOnly ? (
                <Input
                  type="text"
                  labelSize="12px"
                  fontSize="18px"
                  marginTop="16px"
                  width="340px"
                  value={prequalifyData?.project_info?.owner_address}
                  readOnly={readOnly}
                  textAreaHeight="48px"
                />
              ) : (
                <StyledInput
                  id="ship-address"
                  required
                  width="317"
                  value={prequalifyData?.project_info?.owner_address}
                  placeholder=""
                  autocomplete="off"
                  onChange={(event) =>
                    setPrequalifyData(
                      "project_info.owner_address",
                      event.target.value
                    )
                  }
                />
              )}
            </StyledInputLabelContainer>
            <StyledInputLabelContainer>
              <StyledInputLabel for="locality">Suite</StyledInputLabel>
              {readOnly ? (
                <Input
                  type="text"
                  labelSize="12px"
                  fontSize="18px"
                  marginTop="16px"
                  width="190px"
                  value={prequalifyData?.project_info?.owner_suite}
                  readOnly={readOnly}
                  textAreaHeight="48px"
                />
              ) : (
                <StyledInput
                  id="address2"
                  aria-label="suite"
                  width="90"
                  value={prequalifyData?.project_info?.owner_suite}
                  onChange={(event) =>
                    setPrequalifyData(
                      "project_info.owner_suite",
                      event.target.value
                    )
                  }
                  readOnly={readOnly}
                />
              )}
            </StyledInputLabelContainer>
            <StyledInputLabelContainer>
              <StyledInputLabel for="locality">
                {
                  canadianPostalCodePattern.test(postalCode) ? "Postal Code" : usaZipCodePattern.test(postalCode) ? "Zip Code" : "Postal/Zip Code"
                }
              </StyledInputLabel>
              {readOnly ? (
                <Input
                  type="text"
                  labelSize="12px"
                  fontSize="18px"
                  marginTop="16px"
                  width="160px"
                  value={prequalifyData?.project_info?.owner_postal_code}
                  readOnly={readOnly}
                  textAreaHeight="48px"
                />
              ) : (
                <StyledInput
                  id="postcode"
                  required
                  width="210"
                  value={prequalifyData?.project_info?.owner_postal_code}
                  onChange={(event) =>
                    setPrequalifyData(
                      "project_info.owner_postal_code",
                      event.target.value
                    )
                  }
                  readOnly={readOnly}
                />
              )}
            </StyledInputLabelContainer>
          </StyledAddressContainer>
          <InfoArea width="100%" isColumn={true} marginTop="0px">
            <StyledAddressContainer>
              <StyledInputLabelContainer>
                <StyledInputLabel for="locality">City</StyledInputLabel>
                {readOnly ? (
                  <Input
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    width="340px"
                    value={prequalifyData?.project_info?.owner_city}
                    readOnly={readOnly}
                    textAreaHeight="48px"
                  />
                ) : (
                  <StyledInput
                    id="locality"
                    required
                    width="318"
                    value={prequalifyData?.project_info?.owner_city}
                    onChange={(event) =>
                      setPrequalifyData(
                        "project_info.owner_city",
                        event.target.value
                      )
                    }
                    readOnly={readOnly}
                  />
                )}
              </StyledInputLabelContainer>
              <StyledInputLabelContainer>
                <StyledInputLabel for="state">
                  {
                    canadianPostalCodePattern.test(postalCode) ? "Province" : usaZipCodePattern.test(postalCode) ? "State" : " Province/State"
                  }
                </StyledInputLabel>
                {readOnly ? (
                  <Input
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    width="190px"
                    value={prequalifyData?.project_info?.owner_province}
                    readOnly={readOnly}
                    textAreaHeight="48px"
                  />
                ) : (
                  <StyledInput
                    id="state"
                    required
                    width="90"
                    value={prequalifyData?.project_info?.owner_province}
                    onChange={(event) =>
                      setPrequalifyData(
                        "project_info.owner_province",
                        event.target.value
                      )
                    }
                    readOnly={readOnly}
                  />
                )}

                
              </StyledInputLabelContainer>

              <StyledInputLabelContainer>
                <StyledInputLabel for="country">Country</StyledInputLabel>
                {readOnly ? (
                  <Input
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    width="160px"
                    value={getCountryName(prequalifyData?.project_info?.owner_country)}
                    readOnly={readOnly}
                    textAreaHeight="48px"
                  />
                ) : (
                  <StyledInput
                    id="country"
                    required
                    width="210"
                    value={getCountryName(prequalifyData?.project_info?.owner_country)}
                    onChange={(event) =>
                      setPrequalifyData(
                        "project_info.owner_country",
                        event.target.value
                      )
                    }
                    readOnly={readOnly}
                  />
                )}

                
              </StyledInputLabelContainer>
            </StyledAddressContainer>
          </InfoArea>
        </InfoArea>
      </form>

      <FunctionalButton
        buttonTitle="Next"
        handleButton={() => handleNext()}
        width="200px"
        marginRight="0px"
        marginTop="48px"
      />
    </>
  );
};

{
  /* <form id="address-form" action="" method="get" autocomplete="off">
        <StyledAddressContainer>
          <StyledInputLabelContainer>
            <StyledInputLabel for="locality">Bank Location</StyledInputLabel>
            <StyledInput
              id="ship-address"
              required
              width='310'
              placeholder=""
              autocomplete="off"
              onChange={(event) => setPrequalifyData('bank.address', event.target.value)}
            />
          </StyledInputLabelContainer>
          <StyledInputLabelContainer>
            <StyledInputLabel for="locality">Suite</StyledInputLabel>
            <StyledInput id="address2" aria-label="suite" width='91'
              value={prequalifyData?.company?.suite}
              onChange={(event) => setPrequalifyData('bank.suite', event.target.name)}
            />
          </StyledInputLabelContainer>
          <StyledInputLabelContainer>
            <StyledInputLabel for="locality">Postal/Zip Code</StyledInputLabel>
            <StyledInput id="postcode" required width='201'
            value={prequalifyData?.company?.postal}
            onChange={(event) => setPrequalifyData('bank.suite', event.target.name)}
            />
          </StyledInputLabelContainer>

        </StyledAddressContainer>
      </form> */
}

//   <StyledAddressContainer>
//   <StyledInputLabelContainer>
//     <StyledInputLabel for="locality">City</StyledInputLabel>
//     <StyledInput id="locality" required width='310'
//     value={prequalifyData?.company?.city}
//     onChange={(event) => setPrequalifyData('bank.city', event.target.value)}
//     />
//   </StyledInputLabelContainer>
//   <StyledInputLabelContainer>
//     <StyledInputLabel for="state">Province/State</StyledInputLabel>
//     <StyledInput id="state" required width='310'
//     value={prequalifyData?.company?.province}
//     onChange={(event) => setPrequalifyData('bank.province', event.target.value)}
//     />
//   </StyledInputLabelContainer>
// </StyledAddressContainer>

export default FillAddress;
