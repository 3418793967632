import React, { useState, useEffect } from "react";

import Input from "../inputField";
import { InfoArea } from "./styles";
import { StyledRow } from "./styles";
import { dateRegex } from "../../common/regex";
import CustomSelect from "components/selectField";
import { handleCommaFormat, numberWithCommas } from "../../common/numberFormat";
import {
  NumberFormatOnly,
  NumberFormatWithCurrency,
} from "components/locale/numberFormatOnly.js";
import DateTimePickerField from "components/dateTimePickerField";

export const Step1 = ({ setDisable, addData, setAddData, isMobile }) => {
  const projectType = ["Underway", "Completed"];

  const [temporaryStorageOfType, setTemporaryStorageOfType] = useState("");

  useEffect(() => {
    if (
      addData?.project_name?.length > 0 &&
      addData?.project_number?.length > 0 &&
      addData?.project_location?.length > 0 &&
      (addData?.completed_date?.length > 0
        ? dateRegex.test(addData?.completed_date)
        : true) &&
      (addData?.substantial_date?.length > 0
        ? dateRegex.test(addData?.substantial_date)
        : true) &&
      (addData?.percent_completed?.length > 0 ? addData?.percent_completed : 0)
    ) {
      setDisable(false);
    } else {
      setDisable(false);
    }
  }, [addData]);
  const [valueCons, setValueCons] = useState(
    addData["value"] ? NumberFormatOnly(addData["value"]) : ""
  );

  const removeComma = (e) => {
    var num = e;
    if (typeof num === "string") {
      var str = num.replaceAll(",", "");
      str = str.replaceAll(".", "");
      num = Number(str);
    }

    if (isNaN(num)) {
      return 0;
    } else {
      return num;
    }
  };

  return (
    <InfoArea
      isMobile={isMobile}
      marginTop="0px"
      marginLeft="8px"
      isColumn={true}
    >
      <CustomSelect
        style={{ paddingRight: "16px" }}
        label="Project Status*"
        options={projectType.map((item) => ({ value: item, label: item }))}
        value={
          addData?.project_status
            ? addData.project_status
            : temporaryStorageOfType
        }
        setValue={(val) => {
          setTemporaryStorageOfType(val);
          setAddData("project_status", val);
        }}
        validateFunc={(item) => Boolean(item)}
        noCheckIcon={true}
        showDropdownIndicator={true}
      />
      <StyledRow isMobile={isMobile}>
        <Input
          id="fullWidthInputWrap"
          label="Project Title"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.project_name || ""}
          setValue={(val) => setAddData("project_name", val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
      </StyledRow>
      <StyledRow isMobile={isMobile}>
        <Input
          id="oneThirdInputWrap"
          label="Project Number"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.project_number || ""}
          setValue={(val) => setAddData("project_number", val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
        <Input
          id="twoThirdInputWrap"
          label="Location"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.project_location || ""}
          setValue={(val) => setAddData("project_location", val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
      </StyledRow>
      <StyledRow isMobile={isMobile}>
        {addData && addData?.project_status === "Completed" && (
          <>
            {/* <Input
              id="oneThirdInputWrap"
              label="Date Substantially Completed"
              type="text"
              placeholder="MM/DD/YYYY"
              labelSize="12px"
              fontSize="18px"
              value={addData?.substantial_date || ""}
              setValue={(val) => setAddData("substantial_date", val)}
              validateFunc={(item) => dateRegex.test(item)}
              noCheckIcon={true}
              noErrorBorder={true}
            />
            <Input
              id="oneThirdInputWrap"
              label="Date Completed"
              type="text"
              placeholder="MM/DD/YYYY"
              labelSize="12px"
              fontSize="18px"
              value={addData?.completed_date || ""}
              setValue={(val) => {
                console.log(val);
                setAddData("completed_date", val);
              }}
              validateFunc={(item) => dateRegex.test(item)}
              noCheckIcon={true}
              noErrorBorder={true}
            /> */}

            <DateTimePickerField
              showDateField={true}
              dateWidth="50%"
              paddingRight="16px"
              dateLabel="Date Substantially Completed"
              dateValue={
                addData?.substantial_date ? addData?.substantial_date : ""
              }
              setDateValue={(val) => {
                setAddData("substantial_date", val);
              }}
            />
            <DateTimePickerField
              showDateField={true}
              dateWidth="50%"
              paddingRight="16px"
              dateLabel="Date Completed"
              dateValue={addData?.completed_date ? addData?.completed_date : ""}
              setDateValue={(val) => {
                setAddData("completed_date", val);
              }}
            />
            <Input
              id="oneThirdInputWrap"
              label="Value at Completion"
              type="text"
              placeholder="$"
              labelSize="12px"
              fontSize="18px"
              value={valueCons}
              setValue={(val) => {
                setValueCons(val);
                setAddData("value", val);
              }}
              validateFunc={(item) => item.length > 0}
              onBlur={(e) => setValueCons(NumberFormatOnly(e.target.value))}
              noCheckIcon={true}
              noErrorBorder={true}
            />
          </>
        )}
        {addData && addData?.project_status === "Underway" && (
          <>
            {/* <Input
              id="oneThirdInputWrap"
              label="Scheduled Completion Date"
              type="text"
              placeholder="MM/DD/YYYY"
              labelSize="12px"
              fontSize="18px"
              value={addData?.completed_date || ""}
              setValue={(val) => setAddData("completed_date", val)}
              validateFunc={(item) => dateRegex.test(item)}
              noCheckIcon={true}
              noErrorBorder={true}
            /> */}
            <DateTimePickerField
              showDateField={true}
              dateWidth="50%"
              paddingRight="16px"
              dateLabel="Scheduled Completion Date"
              dateValue={addData?.completed_date ? addData?.completed_date : ""}
              setDateValue={(val) => {
                setAddData("completed_date", val);
              }}
            />
            <Input
              id="oneThirdInputWrap"
              label="Percent Completed"
              type="text"
              placeholder="%"
              labelSize="12px"
              fontSize="18px"
              value={addData?.percent_completed || ""}
              setValue={(val) => setAddData("percent_completed", val)}
              onBlur={(e) => {
                let inputValue = e.target.value.trim();
                if (inputValue !== "") {
                  let parsedValue = parseFloat(inputValue);
                  parsedValue = isNaN(parsedValue) ? 0 : parsedValue;
                  parsedValue = Math.min(100, Math.max(0, parsedValue));
                  inputValue = `${parsedValue}%`;
                }
                setAddData("percent_completed", inputValue);
              }}
              noCheckIcon={true}
              noErrorBorder={true}
            />
            <Input
              id="oneThirdInputWrap"
              label="Project Value At Award*"
              type="text"
              placeholder="$"
              labelSize="12px"
              fontSize="18px"
              value={valueCons}
              setValue={(val) => {
                setValueCons(val);
                setAddData("value", val);
              }}
              onBlur={(e) => setValueCons(NumberFormatOnly(e.target.value))}
              validateFunc={(item) => String(item).length > 0}
              noCheckIcon={true}
              noErrorBorder={true}
            />
          </>
        )}
      </StyledRow>
    </InfoArea>
  );
};
