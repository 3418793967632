import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FunctionalButton } from "components/functionalButton";
import IncrementalSearchPickList from "components/incrementalSearchPickList";
import { StyledTableCell } from "components/filesListTable/styles";
import { Title, PopupContainer } from "./styles";
import { filterProjects } from "api/prequalify";

export const Panel2 = ({ setPanel, isMobile, setProjectId, projectId }) => {
  const [filteredData, setFilteredData] = useState([]);

  const handlePickProject = (props) => {
    setProjectId(props.id);
  };
  const handleFilter = async () => {
    await filterProjects("")
      .then((data) => {
        setFilteredData(data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    handleFilter();
  }, []);

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCell component={"tr"} width="55%">
          {bodyData.project_name}
        </StyledTableCell>
        <StyledTableCell component={"tr"} scope="row" width="15%">
          {bodyData.project_number}
        </StyledTableCell>
        <StyledTableCell component={"tr"} scope="row" width="30%">
          {bodyData.project_location}
        </StyledTableCell>
      </>
    );
  };

  return (
    <>
      <Title fontWeight={500} margin="0px 0 0">
        Which project would you like a reference for?
      </Title>
      <IncrementalSearchPickList
        body={body}
        conciseLength={4}
        // filterApi={filterProjects}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        noAddButton={true}
        handleSelected={handlePickProject}
        hover={true}
        width="80%"
        searchWidth="100%"
        placeholder="Project Title, Number, Location..."
        marginTop="16px"
        internalSearch={true}
      />
      <FunctionalButton
        buttonTitle={"NEXT"}
        handleButton={() => setPanel(3)}
        width={isMobile ? "90%" : "368px"}
        marginRight="0px"
        marginTop="32px"
        disabled={!(projectId > 0)}
      />
    </>
  );
};
