import VerticalLine from "components/verticalLine";
import { contactSelector } from "data/selectors/contact";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import { ClientSection } from "./clientSection";
import { FunctionSection } from "./functionSection";
import { SideSection } from "./sideSection";
import {
  FormContainer,
  LargeText,
  ScreenRoot,
  SideContainer,
  StyledArea,
  StyledBox,
  StyledSpan,
} from "./styles";
import BuyerIcon from "assets/landingToolsBarIcons/buyerIcon";
import ContractorIcon from "assets/landingToolsBarIcons/contractorIcon";
import {
  serviceProviderBuyerListSelector,
  serviceProviderContractorsListSelector,
} from "data/selectors/serviceProvider";
import {
  getServiceProviderBuyersList,
  getServiceProviderContractorsList,
} from "data/actions/serviceProvider";

function BuyerAgentDashboard() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [userData, setUserData] = useState("");
  const serviceProviderBuyerData = useSelector(
    serviceProviderBuyerListSelector
  );

  const contactData = useSelector(contactSelector);
  let history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (serviceProviderBuyerData?.length < 1) {
      dispatch(getServiceProviderBuyersList());
    }
  }, []);

  return (
    <ScreenRoot isMobile={isMobile}>
      <FormContainer isMobile={isMobile}>
        <LargeText isMobile={isMobile}>
          Welcome Back,
          <StyledSpan>
            {contactData?.first_name
              ? contactData?.first_name
              : contactData?.last_name}
          </StyledSpan>
          !
        </LargeText>
        <ClientSection
          isMobile={isMobile}
          data={serviceProviderBuyerData?.data}
          selectedTab="1"
        />
        <FunctionSection
          isMobile={isMobile}
          userData={userData}
          selectedTab="1"
        />
      </FormContainer>
      {!isMobile && (
        <>
          <VerticalLine />
          <SideContainer isMobile={isMobile}>
            <SideSection isMobile={isMobile} selectedTab="1" />
          </SideContainer>
        </>
      )}
    </ScreenRoot>
  );
}

export default BuyerAgentDashboard;
