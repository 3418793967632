export const SelectingOptions = (mainState, mainOptions) => {
  let selectedTypes = [];

  Object.entries(mainState).map(([key, value]) => {
    if (key === "state") {
      Object.entries(value).map(([key, value]) => {
        selectedTypes.push(value["type"]);
      });
    }
  });

  const notSelectedTypes = mainOptions.filter(
    (x) => !selectedTypes.includes(x.type)
  );

  return notSelectedTypes;
};

export const SelectingTypeOptions = (mainState, mainOptions) => {
  let selectedTypes = [];

  Object.entries(mainState).map(([key, value]) => {
    if (key === "state") {
      Object.entries(value).map(([key, value]) => {
        selectedTypes.push(value["type"]);
      });
    }
  });

  const notSelectedTypes = mainOptions.filter(
    (x) => !selectedTypes.includes(x.short_form)
  );

  return notSelectedTypes;
};
