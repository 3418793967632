
import React from 'react';

const AddNewButtonIcon = () => {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="31" height="31" rx="6" fill="#FF6D1D"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 6C15.068 6 14.3125 6.68897 14.3125 7.53886V13.3125H8.53886C7.68897 13.3125 7 14.068 7 15C7 15.932 7.68897 16.6875 8.53886 16.6875H14.3125V22.4611C14.3125 23.311 15.068 24 16 24C16.932 24 17.6875 23.311 17.6875 22.4611V16.6875H23.4611C24.311 16.6875 25 15.932 25 15C25 14.068 24.311 13.3125 23.4611 13.3125H17.6875V7.53886C17.6875 6.68897 16.932 6 16 6Z" fill="white"/>
    </svg>
  )
};

export default AddNewButtonIcon;
