import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { FunctionalButton } from "components/functionalButton";
import { GraphicContainer } from "./GraphicContainer";
import { LoginRoot } from "./styles";
import Input from "components/inputField";
import { createPledgxUserFromResearchDatabase } from "api/users.js"
import { viewPledgxUserFromResearchDatabase } from "api/users.js"
import { emailRE } from "common/regex";
import { RadioButton } from "components/radioButton";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { useHistory, useParams } from "react-router-dom";

import {
  FormContainer,
  Content,
  FormCard,
  FormTitle,
  StyledRememberMe,
  StyledSubtext,
  StyledSpan,
  StyledCheckArea,
  StyledTermText,
  ErrorText,
} from "./styles";

import { COLORS, FONT_SIZES } from "constants.js";
const uppercaseRE = /(?=.*?[A-Z])/;
const lowercaseRE = /(?=.*?[a-z])/;
const numberRE = /(?=.*?[0-9])/;
const eightMoreRE = /^[\s\S]{8,}$/;
const passwordRE = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

function ConfirmNewUserFromResearchDatabase({ }) {
  let history = useHistory();
  const [data, setData] = useState({});
  const { token1, token2 } = useParams();
  const [name, setName] = useState(data?.first_name || "");
  const [company, setCompany] = useState(data?.contractor_name || "");
  const [phone, setPhone] = useState(data?.phone || "");
  const [password, setPassword] = React.useState(data?.password || "");
  const [agree, setAgree] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [email, setEmail] = useState(data?.email || "");
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isMedium = useMediaQuery({ maxWidth: 720 });
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (
      passwordRE.test(password) &&
      agree
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  });

  useEffect(() => {
    const updatedData = { ...data };
    updatedData["full_name"] = name;
    setName(name);
    setData(updatedData);
  }, [name]);

  useEffect(() => {
    const updatedData = { ...data };
    updatedData["contractor_name"] = company;
    setData(updatedData);
  }, [company]);


  useEffect(() => {
    const updatedData = { ...data };
    updatedData["phone"] = phone;
    setData(updatedData);
  }, [phone]);

  useEffect(() => {
    const updatedData = { ...data };
    updatedData["email"] = email;
    setData(updatedData);
  }, [email]);


  useEffect(() => {
    const updatedData = { ...data };
    updatedData["password"] = password;
    setData(updatedData);
  }, [password]);

  const handleCreatePledgxUserFromResearchDatabase = () => {
    const newData = { ...data, is_confirmed: 1 };
    setData(newData);
    createPledgxUserFromResearchDatabase(newData, () => {
      window.location.replace("https://qualify.pledgx.com/account/login");
      // history.push("/account/emailed");
    }, () => { setHasError(true); });
  };

  useEffect(() => {
    viewPledgxUserFromResearchDatabase(
      token1,
      token2,
      (res) => {
        setData(res);
        setValid(true);
      },
      () => { setValid(false); }
    )
  }, []);

  const handleChange = () => {
    setAgree(!agree);
  };


  useEffect(() => {
    setHasError(false);
  }, [email]);


  return (
    <>
      {!valid && (
        <div
          style={{
            marginTop: "150px",
            color: "black",
            fontSize: FONT_SIZES.body_4,
            fontWeight: "400",
          }}
        >
          <div
            style={{
              color: COLORS.textGrey,
              fontSize: FONT_SIZES.body_4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No PledgX's Sign-Up Invitaion Form To View
          </div>
        </div>
      )}
      {valid && (
        <LoginRoot isMobile={isMedium}>
          <FormContainer isMobile={isMobile}>
            <Content isMobile={isMobile}>
              <FormCard>
                <FormTitle>Sign Up</FormTitle>
                <StyledSubtext>
                  Fill in the fields below to create your account
                </StyledSubtext>
                <Input
                  label="Full Name"
                  type="text"
                  placeholder="Full Name"
                  labelSize="12px"
                  textAreaHeight="48px"
                  fontSize="18px"
                  marginTop="18px"
                  value={data?.full_name ? data?.full_name : ""}
                  setValue={setName}
                  validateFunc={(item) => item.length > 0}
                  noCheckIcon={true}
                />
                <Input
                  label="Company Name"
                  type="text"
                  placeholder="ABC Company"
                  labelSize="12px"
                  textAreaHeight="48px"
                  fontSize="18px"
                  marginTop="18px"
                  value={data?.contractor_name ? data?.contractor_name : ""}
                  setValue={setCompany}
                  validateFunc={(item) => item.length > 0}
                  noCheckIcon={true}
                />
                <div style={{ marginTop: "20px", width: "100%", marginLeft: '0px', marginRight: '0px' }}>
                  <label htmlFor="phone" style={{ fontSize: "12px" }}>
                    Phone Number
                  </label>
                  <div style={{ height: "48px", width: "100%", marginTop: "-10px" }}>
                    <PhoneInput
                      id="phone"
                      country={"ca"}
                      value={data?.phone ? data?.phone : ""}
                      onChange={(value) => setPhone(value)}
                      style={{ marginTop: "16px", width: "100%", height: "100%" }}
                      inputStyle={{
                        fontSize: "18px",
                        padding: "8px",
                        lineHeight: "24px",
                        marginLeft: "20px",
                        paddingLeft: "28px",
                        height: "100%",
                        width: "96%",
                        marginRight: "0px",
                      }}
                      dropdownStyle={{ fontSize: "18px", lineHeight: "24px" }}
                    />
                  </div>
                </div>
                <Input
                  label="Your Email"
                  type="text"
                  placeholder="name@company.com"
                  labelSize="12px"
                  textAreaHeight="48px"
                  fontSize="18px"
                  marginTop="18px"
                  value={data?.email ? data?.email : ""}
                  setValue={setEmail}
                  validateFunc={(item) => emailRE.test(item)}
                  hasError={hasError}
                  noCheckIcon={true}
                  disabled={false}
                />
                {hasError && (
                  <ErrorText>
                    Oops! It looks like this username has already been used.
                  </ErrorText>
                )}
                <Input
                  autoComplete="new-password"
                  label="Password"
                  type="password"
                  placeholder="Password"
                  labelSize="12px"
                  textAreaHeight="48px"
                  fontSize="18px"
                  marginTop="18px"
                  value={data?.password ? data?.password : ""}
                  setValue={setPassword}
                  validateFunc={(item) => passwordRE.test(item)}
                  hasError={password.length > 0 && !passwordRE.test(password)}
                  noCheckIcon={true}
                />
                {password.length > 0 && !uppercaseRE.test(password) && (
                  <ErrorText>At least 1 uppercase letter should exist.</ErrorText>
                )}
                {password.length > 0 && !lowercaseRE.test(password) && (
                  <ErrorText>At least 1 lowercase letter should exist.</ErrorText>
                )}
                {password.length > 0 && !numberRE.test(password) && (
                  <ErrorText>At least 1 digit should exist.</ErrorText>
                )}
                {password.length > 0 && !eightMoreRE.test(password) && (
                  <ErrorText>Should contain at least 8 characters.</ErrorText>
                )}
                <StyledCheckArea>
                  <StyledRememberMe
                    control={
                      <RadioButton isChecked={agree} handleChange={handleChange} />
                    }
                  />
                  <StyledTermText>
                    I agree to the
                    <a href="https://pledgx.com/terms-of-use.html" target="_blank">
                      <StyledSpan> Terms of Use </StyledSpan>
                    </a>
                    and
                    <a href="https://pledgx.com/privacy-policy.html" target="_blank">
                      <StyledSpan> Privacy Policy </StyledSpan>
                    </a>
                  </StyledTermText>
                </StyledCheckArea>
                <FunctionalButton
                  buttonTitle="Sign Up"
                  handleButton={handleCreatePledgxUserFromResearchDatabase}
                  width="100%"
                  marginRight="0px"
                  disabled={disabled && !hasError}
                />
                <StyledSubtext>
                  Already have an account?
                  <StyledSpan onClick={() => history.push("/account/login")}>
                    {" "}
                    Login
                  </StyledSpan>
                </StyledSubtext>
              </FormCard>
            </Content>
          </FormContainer>
          {!isMedium && <GraphicContainer />}
        </LoginRoot>
      )}
    </>
  );
}

export default ConfirmNewUserFromResearchDatabase;
