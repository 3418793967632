import React, { useState, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { UploadArea } from '../components/uploadArea';
import { UploadedFileDisplay } from '../components/uploadedFileDisplay';

import {
  FormContainer,
  StyledLargeTitle,
  StyledMediumTitle,
} from "../styles";

import { StyledFormTitle } from './styles';

export const AdditionalDocuments = ({
  setIsLastPanel,
  setPreviousStepPanel,
  setSaveCCDC,
  qualificationData,
  setQualificationData,
}) => {
  setIsLastPanel(true);
  setPreviousStepPanel(1);
  setSaveCCDC(true);

  const inputFile = useRef(null);

  const addUploadedFile = (files) => {
    let temp = [...qualificationData.additional_document];

    for (let i = 0; i < files.length; i++)  {
      temp.push(files[i]);
    }

    setQualificationData('additional_document', temp);
  };

  const deleteFile = (index) => {
    let temp = [...qualificationData.additional_document];
    temp.splice(index, 1);
    setQualificationData('additional_document', temp);
  }

  const handleOnDragEnd = (resultEvent) => {
    if(!resultEvent.destination) {
      return;
    }

    const temp = [...qualificationData.additional_document];
    const [reorderedItem] = temp.splice(resultEvent.source.index, 1);
    temp.splice(resultEvent.destination.index, 0, reorderedItem);

    setQualificationData('additional_document', temp);
  };

  return (
    <FormContainer>
      <StyledLargeTitle>Additional Documents</StyledLargeTitle>
      <StyledMediumTitle>Add any additional documents</StyledMediumTitle>
      <StyledFormTitle>Upload your additional files</StyledFormTitle>
      <UploadArea
        setFile={addUploadedFile}
        width="100%"
        height="186px"
        inputFile={inputFile}
        multiple={true}
      />
      <StyledFormTitle >Uploaded Files</StyledFormTitle>
      { qualificationData?.additional_document?.length > 0 &&
        (<DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="additionalFile">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                { qualificationData?.additional_document?.map((item, i) =>  (
                    <Draggable key={`file-${i}`} draggableId={`file-${i}`} index={i}>
                      {(provided) => (
                        <div key={i} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <UploadedFileDisplay
                            key={`file-${i}`}
                            file={item}
                            displayPercentage="90%"
                            deleteFile={deleteFile}
                            index={i}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>)
      }
    </FormContainer>
  );
};
