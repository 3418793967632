import styled from "styled-components";
import { COLORS } from "../../constants";
import { Button } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export const StyledMenu = styled(Menu)`
  &&&& {
    margin-top: 36px;
    margin-left: ${(props) => (props.removeEdit ? "-30px" : "-50px")};
  }

  .MuiMenu-list {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &&.MuiListItem-gutters {
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
    width: 180px;
  }
  &:hover {
    background-color: ${COLORS.primaryOrange};
    color: white;
  }
`;

export const StyledButton = styled(Button)`

  && {
    width: ${(props) => (props.width ? props.width : "100px")};
    height: 25px;
    background-color: white;
    box-sizing: border-box;
    //border-radius: 6px;
    

    font-family: SF Pro Text;
    font-size: 13px;
    font-weight: 500;
    transition: all 0.5s ease;
    text-transform: none;

    &:hover {
      background-color: ${COLORS.primaryOrange};
      color: white;
    }
    .MuiButton-label{
      justify-content:left;
    }
`;
