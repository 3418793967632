import React, { useState, useEffect, useRef } from 'react';
import { Popup } from '../popup';
import { PopupFieldsContent } from './PopupFieldsContent';

import { 
  InputLabel,
  CloseButton,
  Row,
  FormTitle, 
  StyledFileName,
  StyledUploadIcon
} from './styles';


export const AddFinancialPopup = ({
  setOpenPopup,
  openPopup,
  data,
  setData,
  handleButtonFunc,
  isMobile,
  popupTitle
}) => {
  
  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width="600px"
      height="650px"
      dialogContent={(
        <PopupFieldsContent
          isMobile={isMobile}
          setOpenPopup={setOpenPopup}
          data={data}
          setData={setData}
          handleButtonFunc={handleButtonFunc}
          popupTitleType={popupTitle}
        />
      )}
    />   
  )
}