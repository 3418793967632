import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';


const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 10,
    justifyContent: 'flex-start',
    width: '50%',
    flex: 1,
    paddingLeft: 10,
    paddingRight: 30,
  },
  reportDetails: {
    marginTop: 10,
    paddingBottom: 3,
    fontFamily: 'Helvetica',
    fontSize: 14,
    color: '#B9B9B9',
    fontWeight: 600,
  },
  spanText: {
    color: '#B9B9B9',
  },
});

const PageReportDetails = ({ csrData }) => (
  <View style={styles.headerContainer}>
    <Text style={styles.reportDetails}>Report Details</Text>
    {csrData && csrData?.data?.project_status === "Underway" && (
      <>
        <Text>
          <span style={styles.spanText}>Anticipated Completion Date: </span>
          {csrData?.data?.anticipated_completion_date}
        </Text>
        <Text>
          <span style={styles.spanText}>Reporting Date: </span>
          {csrData?.data?.reporting_date}
        </Text>
        <Text>
          <span style={styles.spanText}>Current Contract Price: </span>
          ${csrData?.data?.current_contract_price}({csrData?.data?.current_contract_price_percent}%)
        </Text>
        <Text>
          <span style={styles.spanText}>Work Approved Amount: </span>
          ${csrData?.data?.work_approved_amount}({csrData?.data?.work_approved_amount_percent}%)
        </Text>
        <Text>
          <span style={styles.spanText}>Paid to Contractor: </span>
          ${csrData?.data?.paid_to_contractor_amount}({csrData?.data?.paid_to_contractor_amount_percent}%)
        </Text>
        <Text>
          <span style={styles.spanText}>Holdback Amount: </span>
          ${csrData?.data?.holdback_amount}({csrData?.data?.holdback_amount_percent}%)
        </Text>
      </>
    )}

    {csrData && csrData?.data?.project_status !== "Underway" && (
      <>
        <Text>
          <span style={styles.spanText}>Anticipated Completion Date: </span>
          {csrData?.data?.anticipated_completion_date}
        </Text>
        <Text>
          <span style={styles.spanText}>Final Contract Price: </span>
          ${csrData?.data?.current_contract_price}({csrData?.data?.current_contract_price_percent}%)
        </Text>
      </>
    )}


  </View>
);

export default PageReportDetails;
