import React, { useState } from "react";

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper,
  StyledSmallAddText,
  StyledRow
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Input from "../inputField";
import PledgxLogo from "../../assets/common/pledgxLogo";
import { FunctionalButton } from "../functionalButton";
import { SuccessPopup } from "../../pages/adminToolsDashboard/SuccessPopup";
import updateObjectByPath from "../../common/updateObjectByPath";

export const AddPendingReferencePopup = ({
  setOpenDialog,
  openDialog,
  isMobile,
  label,
  buttonLabel,
  data,
  setData,
  setPickReferees,
  objectData,
  setObjectData
}) => {
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);

  function updateReferee(refereesArray, updatedObject) {
    for (let i = 0; i < refereesArray.length; i++) {
      if (refereesArray[i].id === updatedObject.id) {
        refereesArray[i] = { ...refereesArray[i], ...updatedObject };
        break;
      }
    }
    return refereesArray;
  }

  const handleSave = () => {
    const updatedObjectData = {...objectData , project_status: ""}
    data.referees = updateReferee(data.referees, updatedObjectData);
    const updatedData = { ...data, referees: data.referees }
    setData(updatedData);
    setPickReferees(data.referees);
    setOpenDialog(false);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const onChangeData = (attribute, val) => {
    const updatedData = { ...objectData };
    updateObjectByPath(updatedData, attribute, val);
    setObjectData({ ...updatedData });
  };


  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper elevation={0} isMobile={isMobile}>
        <CloseButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
        <FormTitle style={{ marginTop: 10 }}>
          {label || "Edit Referee Info"}
        </FormTitle>
        <InfoArea isMobile={isMobile} marginTop="0px">

          <Input
            id="fullWidthInputWrap"
            label="Email"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={objectData?.email}
            setValue={(val) => onChangeData("email", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
          />

          <Input
            id="inputWrap"
            label="First Name"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={objectData?.first_name}
            setValue={(val) => onChangeData("first_name", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
          />

          <Input
            id="inputWrap last"
            label="Last Name"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={objectData?.last_name}
            setValue={(val) => onChangeData("last_name", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
          />

          <Input
            id="inputWrap"
            label="Company Name"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={objectData?.institution_name}
            setValue={(val) => onChangeData("institution_name", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noCancelIcon={true}
            noErrorBorder={true}
          />

          <Input
            id="inputWrap last"
            label="Role"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={objectData?.relationship}
            noCheckIcon={true}
            disabled
          />

        </InfoArea>

        <FunctionalButton
          width="30%"
          marginTop="16px"
          marginRight="10px"
          handleButton={() => {
            handleSave();
          }}
          buttonTitle={buttonLabel || "SUBMIT"}
        />
        <SuccessPopup
          setOpenPopup={setOpenSuccessPopup}
          openPopup={openSuccessPopup}
          isMobile={isMobile}
        />
      </StyledPaper>
    </Dialog>
  );
};
