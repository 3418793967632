import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getSystemMessages, updateLastSeenRelease } from '../../data/actions/systemMessages';
import { baseSystemMessagesSelector } from '../../data/selectors/systemMessages';
import CloseIcon from '@mui/icons-material/Close';
import classes from './style/SystemMessages.module.css';
import { FONT_SIZES, COLORS, DASHBOARDHEADER_HEIGHT, SYSTEM_MESSAGES_HEIGHT } from "../../constants";

function SystemMessages() {
  const [state, setState] = useState({
    visible: false,
    currentMessage: {
      messageType: null,
      content: null
    }
  });
  const dispatch = useDispatch();
  const systemMessages = useSelector(baseSystemMessagesSelector);
  const history = useHistory();

  useEffect(() => {
    dispatch(getSystemMessages());
  }, []);

  useEffect(() => {
    if (systemMessages && systemMessages.release_notes) {
      setState({
        ...state,
        visible: true,
        currentMessage: {
          messageType: 'RELEASE_NOTES',
          content: <div className={ classes['message-container'] } style={{ fontSize: FONT_SIZES.body_2 }}>
            <span>
              We’ve updated our application to serve you better.
            </span>&nbsp;
            <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={ () => handleInteraction({ action: 'NAVIGATE', payload: 'RELEASE_NOTES' }) }>
              See all the changes here.
            </span>
          </div>
        }
      });
    } else {
      setState({
        ...state,
        visible: false
      });
    };
  }, [systemMessages]);

  function handleInteraction({ action, payload }) {
    switch(action) {
      case 'CLOSE':
        switch(payload) {
          case 'RELEASE_NOTES':
            dispatch(updateLastSeenRelease());
            break;
        }
        break;
      case 'NAVIGATE':
        switch(payload) {
          case 'RELEASE_NOTES':
            dispatch(updateLastSeenRelease());
            history.push('/release_notes');
            break;
        };
        break;
    };
  };

  
  return (
    <div
      className={ classes.container }
      style={{ height: state.visible ? `${ DASHBOARDHEADER_HEIGHT + SYSTEM_MESSAGES_HEIGHT }px` : '0', marginBottom: state.visible ? `-${ DASHBOARDHEADER_HEIGHT }px` : '0' }}
    >
      <div style={{ height: `${ DASHBOARDHEADER_HEIGHT }px` }}></div>
      <div
        className={ classes['inner-container'] }
        style={{ backgroundColor: COLORS.primaryOrange, padding: `10px 28px`, height: `${ SYSTEM_MESSAGES_HEIGHT }px` }}
      >
        <div className={ classes.content }>
          { state.currentMessage.content }
        </div>
        <div className={ classes['close-button'] } onClick={() => handleInteraction({ action: 'CLOSE', payload: state.currentMessage.messageType })}>
          <CloseIcon
            sx={{ fontSize: 32, color: 'white' }}
          />
        </div>
      </div>
    </div>
  );
}

export default SystemMessages;