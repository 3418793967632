import React from "react";
import { StyledIconButton } from './styles';
import ExitIcon from "../../assets/common/exitIcon"; 

export const ExitIconButton = ({ handleClick, margin }) => {

  return (
    <>
      <StyledIconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        margin={margin}
      >
        <ExitIcon />
      </StyledIconButton>
    </>
  )
}
