import React, { useEffect } from "react";
import { PROVINCES } from "../../../constants";
import { emailRE } from "../../../common/regex";

import {
  Row,
  FormTitle,
  StyledMediumTitle
} from "../styles";

import Input from "../../../components/inputField";
import Select from "../../../components/selectField";


export const Panel1 = ({
  setPanel,
  qualificationData,
  setQualificationData,
  disabledBC,
  setDisabledBC,
}) => {
  const bonding = qualificationData.bonding;

  const companyOptions = [
    "HQ Bonding B.V.",
    "Tweha B.V.",
    "Rouge One Ltd",
  ];

  useEffect(() => {
    if (
      bonding?.bc?.length > 0 &&
      bonding?.addressBC?.length > 0 &&
      bonding?.postalCodeBC?.length > 0 &&
      bonding?.cityBC?.length > 0 &&
      bonding?.provinceBC?.length > 0 &&
      bonding?.firstNameBC?.length > 0 &&
      bonding?.lastNameBC?.length > 0 &&
      bonding?.phoneBC?.length > 0 &&
      emailRE.test(bonding?.emailBC)
    ) {
      setDisabledBC(false);
    } else {
      setDisabledBC(true);
    }
  });

  return (
    <>
      <StyledMediumTitle>Which bonding company do you currently work with?</StyledMediumTitle>
      <Select
        label="Name"
        iconMarginRight="16px"
        style={{ paddingRight:"16px", width: "50%"}}
        options={companyOptions.map((item) => ({ value: item, label: item }))}
        value={bonding?.bc}
        setValue={(val) => setQualificationData('bonding.bc', val)}
        validateFunc={(item) => Boolean(item)}
      />
      <Row>
        <Input
          style={{  marginRight: "16px", width: "50%" }}
          label="Address"
          type="text"
          placeholder="Address"
          value={bonding?.addressBC}
          setValue={(val) => setQualificationData('bonding.addressBC', val)}
          validateFunc={(item) => item.length > 0}
        />
        <Input
          style={{  marginRight: "16px", width: "25%" }}
          label="Suite number, etc."
          type="text"
          placeholder="Suite number, etc."
          value={bonding?.suiteNumberBC}
          setValue={(val) => setQualificationData('bonding.suiteNumberBC', val)}
          validateFunc={(item) => item.length > 0}
        />
        <Input
          style={{  marginRight: "16px", width: "25%" }}
          label="ZIP/ Postal Code"
          type="text"
          placeholder="ZIP/ Postal Code"
          value={bonding?.postalCodeBC}
          setValue={(val) => setQualificationData('bonding.postalCodeBC', val)}
          validateFunc={(item) => item.length > 0}
        />
      </Row>
      <Row marginBottom="16px">
        <Input
          style={{  marginRight: "16px", width: "49%" }}
          label="City"
          type="text"
          placeholder="City"
          value={bonding?.cityBC}
          setValue={(val) => setQualificationData('bonding.cityBC', val)}
          validateFunc={(item) => item.length > 0}
        />
        <Select
          style={{ paddingRight:"16px", width: "51%" }}
          iconMarginRight="16px"
          label="Province"
          options={PROVINCES.map((item) => ({ value: item, label: item }))}
          value={bonding?.provinceBC}
          setValue={(val) => setQualificationData('bonding.provinceBC', val)}
          validateFunc={(item) => Boolean(item)}
        />
      </Row>

      <StyledMediumTitle>Who is the main contact? </StyledMediumTitle>
      <Row>
        <Input
          style={{  marginRight: "16px", width: "50%" }}
          label="First Name"
          type="text"
          placeholder="First Name"
          value={bonding?.firstNameBC}
          setValue={(val) => setQualificationData('bonding.firstNameBC', val)}
          validateFunc={(item) => item.length > 0}
        />
        <Input
          style={{  marginRight: "16px", width: "50%" }}
          label="Last Name"
          type="text"
          placeholder="Last Name"
          value={bonding?.lastNameBC}
          setValue={(val) => setQualificationData('bonding.lastNameBC', val)}
          validateFunc={(item) => item.length > 0}
        />
      </Row>
      <Row marginBottom="16px">
        <Input
          style={{  marginRight: "16px", width: "50%" }}
          label="Phone"
          type="text"
          placeholder="Company Phone"
          value={bonding?.phoneBC}
          setValue={(val) => setQualificationData('bonding.phoneBC', val)}
          validateFunc={(item) => item.length > 0}
        />
        <Input
          style={{  marginRight: "16px", width: "50%" }}
          label="Email"
          type="text"
          placeholder="Company Email"
          value={bonding?.emailBC}
          setValue={(val) => setQualificationData('bonding.emailBC', val)}
          validateFunc={(item) => {
            return emailRE.test(item);
          }}
        />
      </Row>
    </>
  );
};
