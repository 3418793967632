import React, { useEffect, useState } from "react";
import PdfDocument from './pdfDocument';
import { getInsuranceTypes } from "api/external_data";
import { convertPhoneNumberCanadaUSA } from "common/regex";
import { getProjectFiles } from "api/file_managment";
import { getCountryName } from "common/countryName";

function PledgxOutput(prequalifyData) {
  const [insuranceType, setInsuranceType] = useState([]);
  const companyLogoImage = prequalifyData?.prequalifyData?.project_info?.gc_company_logo?.file;
  const projectImageData=prequalifyData?.prequalifyData?.project_files?.files;  

  useEffect(() => {
    getInsuranceTypes()
      .then((res) => {
        setInsuranceType(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  //separate selected projects according to type
  const projects = prequalifyData?.prequalifyData?.projects;
  const underwayProjects = [];
  const keyProjects = [];
  const comparableProjects = [];
  if (projects) {
    projects.map((project) => {
      if (project) {
        project["type"]?.map((type) => {
          if (type === "underway") underwayProjects.push(project);
          if (type === "key") keyProjects.push(project);
          if (type === "comparable") comparableProjects.push(project);
        });
      }
    });
  }


  const insuranceListObject = { ...prequalifyData?.prequalifyData?.insuranceList };
  const selectedInsuranceData = insuranceListObject?.order?.map((key) => ({
    ...insuranceListObject?.state[key],
    type: insuranceType?.find(
      (value) => value.short_form === insuranceListObject?.state[key].type
    )
      ? insuranceType?.find(
        (value) => value.short_form === insuranceListObject?.state[key].type
      ).type
      : insuranceListObject?.state[key].type,
  }));
  insuranceListObject.state = selectedInsuranceData;


  const insuranceData = insuranceListObject?.state;
  const filteredInsuranceData = insuranceData.map(a => a.data);

  const groupedInsuranceData = filteredInsuranceData?.reduce((accumulator, currentValue) => {
    const { suite_number, address, city, province, postal, company_name, contact_first_name, contact_last_name, contact_email } = currentValue;
    const key = `${suite_number}-${address}-${city}-${province}-${postal}-${company_name}-${contact_first_name}-${contact_last_name}-${contact_email}`;

    if (!accumulator[key]) {
      accumulator[key] = { suite_number, address, city, province, postal, company_name, contact_first_name, contact_last_name, contact_email, data: [currentValue] };
    } else {
      accumulator[key].data.push(currentValue);
    }

    return accumulator;
  }, {});


  const groupedInsuranceArray = Object.values(groupedInsuranceData);

  const data = {
    projectTitle: prequalifyData?.prequalifyData?.project_info?.project_title,
    projectNumber: prequalifyData?.prequalifyData?.project_info?.project_number,
    projectCategory: prequalifyData?.prequalifyData?.project_info?.categories,
    projectOwner: prequalifyData?.prequalifyData?.project_info?.owner_name,
    projectOwnerEmail: prequalifyData?.prequalifyData?.project_info?.owner_email,
    projectOwnerPhone: convertPhoneNumberCanadaUSA(prequalifyData?.prequalifyData?.project_info?.owner_phone),
    ProjectLocation: prequalifyData?.prequalifyData?.project_info?.project_city,
    projectOwnerAddress:
      (prequalifyData?.prequalifyData?.project_info?.owner_suite
        ? prequalifyData?.prequalifyData?.project_info?.owner_suite + "-"
        : "") +
      (prequalifyData?.prequalifyData?.project_info?.owner_address
        ? prequalifyData?.prequalifyData?.project_info?.owner_address + ", "
        : "") +
      (prequalifyData?.prequalifyData?.project_info?.owner_city
        ? prequalifyData?.prequalifyData?.project_info?.owner_city + ", "
        : "") +
      (prequalifyData?.prequalifyData?.project_info?.owner_province
        ? prequalifyData?.prequalifyData?.project_info?.owner_province + ", "
        : "") +
      prequalifyData?.prequalifyData?.project_info?.owner_postal_code,
    companyName: prequalifyData?.prequalifyData?.project_info?.gc_name,
    companyEmail: prequalifyData?.prequalifyData?.project_info?.gc_email,
    companyContactNumber: convertPhoneNumberCanadaUSA(prequalifyData?.prequalifyData?.project_info?.gc_phone),
    companyWebsite: prequalifyData?.prequalifyData?.project_info?.gc_website,
    companyLogo: prequalifyData?.prequalifyData?.project_info?.gc_company_logo,
    companyLogoImage: companyLogoImage,
    projectImageData: prequalifyData?.prequalifyData?.project_files?.files,
    operationalCountry: prequalifyData?.prequalifyData?.project_info?.gc_operational_country,
    operationalProvince: prequalifyData?.prequalifyData?.project_info?.gc_operational_province,
    operationalRegion: prequalifyData?.prequalifyData?.project_info?.gc_operational_region,
    yearEstablished: prequalifyData?.prequalifyData?.legal_structure?.establishment_year,
    ventureType: prequalifyData?.prequalifyData?.legal_structure?.venture_type,
    companyUnion: prequalifyData?.prequalifyData?.project_info?.gc_union,
    companyAssociation: prequalifyData?.prequalifyData?.project_info?.gc_association,
    companyAddress:
      (prequalifyData?.prequalifyData?.project_info?.gc_suite_number
        ? prequalifyData?.prequalifyData?.project_info?.gc_suite_number + "-"
        : "") +
      (prequalifyData?.prequalifyData?.project_info?.gc_address
        ? prequalifyData?.prequalifyData?.project_info?.gc_address + ", "
        : "") +
      (prequalifyData?.prequalifyData?.project_info?.gc_city
        ? prequalifyData?.prequalifyData?.project_info?.gc_city + ", "
        : "") +
      (prequalifyData?.prequalifyData?.project_info?.gc_province
        ? prequalifyData?.prequalifyData?.project_info?.gc_province + ", "
        : "") +
      prequalifyData?.prequalifyData?.project_info?.gc_postal_code,

    businessCategory: prequalifyData?.prequalifyData?.project_info?.gc_categories,

    legalStructurePersonnels: {
      selectedData: prequalifyData?.prequalifyData?.legal_structure?.personnel,
    },
    bankName: prequalifyData?.prequalifyData?.bank?.company_name,

    bankAddress:
      (prequalifyData?.prequalifyData?.bank?.suite_number &&
        prequalifyData?.prequalifyData?.bank?.suite_number !== "None"
        ? prequalifyData?.prequalifyData?.bank?.suite_number + "-"
        : "") +
      (prequalifyData?.prequalifyData?.bank?.address
        ? prequalifyData?.prequalifyData?.bank?.address + ","
        : "") +
      (prequalifyData?.prequalifyData?.bank?.city ? prequalifyData?.prequalifyData?.bank?.city + "," : "") +
      (prequalifyData?.prequalifyData?.bank?.province
        ? prequalifyData?.prequalifyData?.bank?.province + " "
        : "") +
      prequalifyData?.prequalifyData?.bank?.postal,

    bankContactName:
      prequalifyData?.prequalifyData?.bank?.contact_first_name +
      " " +
      prequalifyData?.prequalifyData?.bank?.contact_last_name,

    bankContactEmail: prequalifyData?.prequalifyData?.bank?.contact_email,

    bondingCompanyName: prequalifyData?.prequalifyData?.bonding?.company_name,
    bondingCompanyAddress:
      (prequalifyData?.prequalifyData?.bonding?.suite_number &&
        prequalifyData?.prequalifyData?.bonding?.suite_number !== "None"
        ? prequalifyData?.prequalifyData?.bonding?.suite_number + "-"
        : "") +
      (prequalifyData?.prequalifyData?.bonding?.address
        ? prequalifyData?.prequalifyData?.bonding?.address + ","
        : "") +
      (prequalifyData?.prequalifyData?.bonding?.city
        ? prequalifyData?.prequalifyData?.bonding?.city + ","
        : "") +
      (prequalifyData?.prequalifyData?.bonding?.province
        ? prequalifyData?.prequalifyData?.bonding?.province + " "
        : "") +
      prequalifyData?.prequalifyData?.bonding?.postal,
    bondingCompanyContactName:
      prequalifyData?.prequalifyData?.bonding?.contact_first_name +
      " " +
      prequalifyData?.prequalifyData?.bonding?.contact_last_name,
    bondingCompanyContactEmail: prequalifyData?.prequalifyData?.bonding?.contact_email,
    bondAggregateLimit: prequalifyData?.prequalifyData?.bonding?.bond_aggregate_limit,
    bondSingleProjectLimit: prequalifyData?.prequalifyData?.bonding?.bond_single_project_limit,
    insuranceDetails: { selectedData: insuranceListObject },

    keyPersonnels: {
      selectedData: prequalifyData?.prequalifyData?.key_personnel,
    },
    safetyRecords: prequalifyData?.prequalifyData?.annual?.safety_records,
    safetyRecordsForPdf: prequalifyData?.prequalifyData?.annual?.safety_records_for_pdf,
    safetyRecordsUSA: prequalifyData?.prequalifyData?.annual?.safety_records_usa,
    safetyRecordsForPdfUSA: prequalifyData?.prequalifyData?.annual?.safety_records_usa_for_pdf,
    selectedCountryForHealthSafety:  getCountryName(prequalifyData?.prequalifyData?.project_info?.owner_country),
    constructionRecords: prequalifyData?.prequalifyData?.annual?.annual_values,
    constructionRecordsForPdf: prequalifyData?.prequalifyData?.annual?.annual_values_for_pdf,
    safetyRecordsRemark:
      prequalifyData?.prequalifyData?.annual?.remark_records?.annual_safety_records_remark,
    safetyRecordsRemarkUSA:
      prequalifyData?.prequalifyData?.annual?.remark_records?.annual_safety_records_remark_usa,
    constructionRecordsRemark:
      prequalifyData?.prequalifyData?.annual?.remark_records?.annual_construction_value_remark,
    corsRadioButtonYes:
      prequalifyData?.prequalifyData?.annual?.remark_records?.certificate_of_recognition,
    corsRadioButtonNo:
      prequalifyData?.prequalifyData?.annual?.remark_records?.no_certificate_of_recognition,
    corsRadioButtonEquivalentToCor:
      prequalifyData?.prequalifyData?.annual?.remark_records
        ?.certificate_of_recognition_equivalent,
    corsRadioButtonCoveredByOther:
      prequalifyData?.prequalifyData?.annual?.remark_records
        ?.no_certificate_of_recognition_equivalent,
    healthSafetyEmployeeOrientationProgram: prequalifyData?.prequalifyData?.annual?.remark_records?.health_safety_employee_orientation_program,
    healthSafetyEmployeeOrientationProgramList: prequalifyData?.prequalifyData?.annual?.remark_records?.health_safety_employee_orientation_program_list,
    healthSafetyProgram: prequalifyData?.prequalifyData?.annual?.remark_records?.health_safety_program,
    healthSafetyProgramList: prequalifyData?.prequalifyData?.annual?.remark_records?.health_safety_program_list,
    noHealthSafetyEmployeeOrientationProgram: prequalifyData?.prequalifyData?.annual?.remark_records?.no_health_safety_employee_orientation_program,
    noHealthSafetyEmployeeOrientationProgramRemark: prequalifyData?.prequalifyData?.annual?.remark_records?.no_health_safety_employee_orientation_program_remark,
    noHealthSafetyProgram: prequalifyData?.prequalifyData?.annual?.remark_records?.no_health_safety_program,
    noHealthSafetyProgramRemark: prequalifyData?.prequalifyData?.annual?.remark_records?.no_health_safety_program_remark,
    health_safety_personnel: prequalifyData?.prequalifyData?.annual?.health_safety_personnel,  
    corsRemark: prequalifyData?.prequalifyData?.annual?.remark_records?.cors_remark,
    keyProjectsList: keyProjects,
    comparableProjectsList: comparableProjects,
    underwayProjectsList: underwayProjects,
    additionalDocument: prequalifyData?.prequalifyData?.additional_document,
    institutionId: prequalifyData?.prequalifyData?.institution_id,
  };

  const updateData = { "data": data, "insuranceData": groupedInsuranceArray, "projectImageData": projectImageData };


  return (
    <PdfDocument prequalifyData={updateData} />
  );
}

export default PledgxOutput;
