import React from "react";
import { numberWithCommas, removeComma } from "common/numberFormat.js";

const Insights = ({ data }) => {

  return (
    <div style={{ backgroundColor: '#FFFFFF' }}>
      <div style={{ backgroundColor: '#FFFFFF', padding: '5px' }}>
        <div><b>Profitability:</b></div>
        <br />
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <React.Fragment key={index}>
              {item.Insight1 && (
                <p> 1. {item.Insight1}</p>
              )}
              {item.Insight2 && (
                <p> 2. {item.Insight2}</p>
              )}
              {item.Insight3 && (
                <p> 3. {item.Insight3}</p>
              )}
              {item.Insight4 && (
                <p> 4. {item.Insight4}</p>
              )}
              {item.Insight5 && (
                <p> 5. {item.Insight5}</p>
              )}
            </React.Fragment>
          ))}

        <div><b>Liquidity:</b></div>
        <br />
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <React.Fragment key={index}>
              {item.Insight6 && (
                <p> 6. {item.Insight6}</p>
              )}
              {item.Insight7 && (
                <p> 7. {item.Insight7}</p>
              )}
            </React.Fragment>
          ))}

        <div><b>Financial Strength:</b></div>
        <br />
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <React.Fragment key={index}>
              {item.Insight8 && (
                <p> 8. {item.Insight8}</p>
              )}
              {item.Insight9 && (
                <p> 9. {item.Insight9}</p>
              )}
            </React.Fragment>
          ))}
      </div>

    </div>
  );
};

export default Insights;
