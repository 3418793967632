import React, { Fragment } from "react";
import { Text, View, StyleSheet, Line } from "@react-pdf/renderer";
import { numberWithCommas } from "common/numberFormat";
import { formatDate } from "common/dateFormat";

const styles = StyleSheet.create({
  row: {
    borderBottomColor: "#EBE9F1",
    backgroundColor: "#F8F8F8",
    alignItems: "left",
    fontStyle: "normal",
    paddingLeft: 15,
    color: "#000000",
    fontWeight: 400,
    fontSize: 10,
    lineHeight: 1.5,
    paddingTop: 5,
    paddingBottom: 5,
  },
  spanText: {
    color: "#888888",
  },
  spanText2: {
    color: "#888888",
    marginLeft: 5,
  },
  lineDivider: {
    marginTop: 10,
    height: 1,
    backgroundColor: "#EBE9F1",
    marginLeft: 0,
    marginRight: 15,
  },
  headerContainer3: {
    marginTop: 5,
    justifyContent: "flex-start",
    width: "57%",
    paddingLeft: 0,
    paddingRight: 0,
  },
  headerContainer4: {
    marginTop: 5,
    justifyContent: "flex-end",
    width: "43%",
    flex: 1,
    paddingLeft: 28,
    paddingRight: 0,
  },
});

const PageThreeInsuranceTableRowChild = ({ items }) => {
  const lastIndex = items.length - 1;

  const rows = items?.map((item, i) => (
    <View style={styles.row}>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View style={styles.headerContainer3}>
          <Text>
            <span style={styles.spanText}>Type: </span>
            {item?.insurance_type}
          </Text>
          <Text>
            <span style={styles.spanText}>Liability Limit: </span>$
            {numberWithCommas(item?.limit_value)}
          </Text>
          <Text>
            <span style={styles.spanText2}>Per Occurrence Limit: </span>$
            {numberWithCommas(item?.per_occurance_limit)}
          </Text>
        </View>

        <View style={styles.headerContainer4}>
          <Text>
            <span style={styles.spanText}>Effective Date: </span>
            {formatDate(item?.effective_date)}
          </Text>

          <Text>
            <span style={styles.spanText}>Expiry Date: </span>
            {formatDate(item?.expiry_date)}
          </Text>
        </View>
      </View>

      {i !== lastIndex && <Line style={styles.lineDivider} vertical={false} />}
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default PageThreeInsuranceTableRowChild;
