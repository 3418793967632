/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import { StyledCellTitle, StyledCellCommon, StyledCellAction } from "./styles";

import { ActionsMenu } from "./ActionsMenu";
import ConciseTable from "components/filesListTable/conciseTable";
import { numberWithCommas } from "common/numberFormat";
import { useHistory, useLocation } from "react-router-dom";
import { StyledTableCell } from "components/filesListTable/styles.js";
import { ThreeDotsPopupMenu } from "components/threeDotsPopupMenu";
import {
  getFirstPpcObject,
  getestimatedCostOfRevenue,
  getestimatedRevenue,
} from "../commonfunction";

export const AllProjectsTable = ({
  data,
  setData,
  isMobile,
  borderRadius,
  boxShadow,
  isShortened,
  clientCompanyName,
  editFunc,
  companyId,
  editProject,
}) => {
  const history = useHistory();
  const location = useLocation();
  const handleProjectSelection = (project_id) => {
    const projectDetails = data?.find((obj) => obj.id === project_id);
    const isUnderwriterWip = location.pathname.includes("underwriter/client/wip");
    ;
    if(isUnderwriterWip){
    history.push("/underwriter/client/wip/project", {
      projectId: project_id,
      projectData: projectDetails,
        companyId: companyId,
      });
    } else {
      history.push("/client/wip/project", {
        projectId: project_id,
        projectData: projectDetails,
        companyId: companyId,
      });
    }
  };

  const body = (bodyData) => {
    let totalChangeOrderValue = bodyData?.co_data?.reduce((total, item) => {
      // Convert the values to numbers and add them to the total
      let changeInContractValue = item.change_in_contract_value
        ? parseFloat(item.change_in_contract_value)
        : 0;
      return total + changeInContractValue;
    }, 0);
    const totalEstimatedRevenue = getestimatedRevenue(bodyData);

    let changeInContractCost = bodyData?.co_data.reduce(
      (totalChange, coData) => {
        // Sum up all estimated_cost_for_the_change in co_data array
        return totalChange + parseFloat(coData.estimated_cost_for_the_change);
      },
      0
    );
    const totalEstimatedCostOfRevenue = getestimatedCostOfRevenue(bodyData);
    const firstPpcData = getFirstPpcObject(bodyData);
    const cost_incurred_to_date = parseFloat(
      firstPpcData?.ppc_data?.cost_incurred_to_date
    );

    const percentCompleted =
      totalEstimatedCostOfRevenue > 0 && parseFloat(cost_incurred_to_date) > 0
        ? ((cost_incurred_to_date / totalEstimatedCostOfRevenue) * 100).toFixed(
            0
          )
        : 0;
    return (
      <>
        <StyledCellTitle
          component={"tr"}
          borderRadius={borderRadius}
          onClick={() => handleProjectSelection(bodyData?.id)}
        >
          {bodyData?.project_name}
        </StyledCellTitle>
        <StyledCellCommon
          component={"tr"}
          width="13%"
          borderRadius={borderRadius}
          onClick={() => handleProjectSelection(bodyData?.id)}
        >
          {bodyData?.project_number}
        </StyledCellCommon>
        <StyledCellCommon
          component={"tr"}
          width="14.5%"
          borderRadius={borderRadius}
          onClick={() => handleProjectSelection(bodyData?.id)}
        >
          ${numberWithCommas(Math.round(totalEstimatedRevenue))}
        </StyledCellCommon>
        <StyledCellCommon
          component={"tr"}
          borderRadius={borderRadius}
          width="15%"
          onClick={() => handleProjectSelection(bodyData?.id)}
        >
          ${numberWithCommas(cost_incurred_to_date) || 0}
        </StyledCellCommon>
        <StyledCellCommon
          component={"tr"}
          borderRadius={borderRadius}
          onClick={() => handleProjectSelection(bodyData?.id)}
        >
          $
          {numberWithCommas(
            Math.round(totalEstimatedCostOfRevenue - cost_incurred_to_date)
          ) || 0}
        </StyledCellCommon>

        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
          onClick={() => handleProjectSelection(bodyData?.id)}
        >
          {percentCompleted}%
        </StyledCellCommon>

        {/* <StyledTableCell component={"tr"} align="right" width="5%">
          <ThreeDotsPopupMenu
            item={{ ...bodyData, id: bodyData?.id }}
            // removeEdit={"disableEnableClient"}
            handleModify={editFunc}
          />
        </StyledTableCell> */}
      </>
    );
  };

  const actions = (props) => {
    return (
      <>
        <StyledCellAction
          component={"tr"}
          scope="row"
          align="center"
          borderRadius={borderRadius}
        >
          <ActionsMenu
            clientCompanyName={clientCompanyName}
            data={data.find((obj) => obj.id === props.id)}
            companyId={companyId}
            handleGoToWip={handleProjectSelection}
            editProject={editProject}
          />
        </StyledCellAction>
      </>
    );
  };

  const createHeader = (title, width, align) => {
    return {
      title,
      width: width || "",
      align: align || "left",
    };
  };

  const headers = [
    createHeader("Project Title", "33%"),
    createHeader("Number", "12%"),
    createHeader("Estimated Revenue", "14%"),
    createHeader("Cost to Date ", "15%"),
    createHeader("Cost to Complete", "13%"),
    createHeader("% Complete", "15%"),
    createHeader("", "5%"),
    // createHeader("Last Status Report", "30%"),
    // createHeader(""),
  ];
  const shortenedHeaders = [
    createHeader("Project Title"),
    createHeader("Status"),
    createHeader(""),
    createHeader(""),
  ];

  return (
    <div>
      <ConciseTable
        headers={isMobile ? "" : isShortened ? shortenedHeaders : headers}
        largefont={false}
        borderSpacing="0 4px !important"
      />
      <ConciseTable
        actionType="client"
        data={data}
        body={body}
        actions={actions}
        length={3}
        boxShadow={boxShadow}
        borderRadius={borderRadius}
        hover={true}
        // headers={isMobile ? "" : isShortened ? shortenedHeaders : headers}
      />
    </div>
  );
};
