import React, { useState, useEffect } from 'react';

import { PROVINCES } from '../../../constants';
import { emailRE } from "../../../common/regex";

import {
  Row,
  StyledMediumTitle,
} from "../styles";
import Input from "../../../components/inputField";
import Select from "../../../components/selectField";
import { CategoriesSetup } from './Categories';
import { CommonCategories } from 'components/commonCategories';

export const Panel1 = ({
  qualificationData,
  setQualificationData,
  setHasOwnerData,
  hasOwnerData
}) => {
  const [hasCategories, setHasCategories] = useState('');

  useEffect(() => {
    const projectInfo = qualificationData?.project_info;
    setHasCategories(( projectInfo && projectInfo?.categories?.length > 0)  ? true : false);

    if (
      projectInfo?.project_title?.length > 0 &&
      projectInfo?.project_number?.length > 0 &&
      projectInfo?.project_city?.length > 0 &&
      projectInfo?.owner_city?.length > 0 &&
      emailRE.test(projectInfo?.owner_email) &&
      projectInfo?.owner_address?.length > 0 &&
      projectInfo?.owner_phone?.length > 0 &&
      projectInfo?.owner_postal_code?.length > 0 &&
      projectInfo?.owner_province?.length > 0 &&
      projectInfo?.owner_city?.length > 0 &&
      projectInfo?.owner_name?.length > 0 &&
      hasCategories
    ) {
      setHasOwnerData(true);
    } else {
      setHasOwnerData(false);
    }
  });

  return (
    <>
      <StyledMediumTitle marginBottom="-8px">Which project are you qualifying for?</StyledMediumTitle>
      <Row>
        <Input
          style={{ marginRight: "16px", width: "60%" }}
          label="Project title"
          type="text"
          placeholder="Title"
          value={qualificationData?.project_info?.project_title}
          setValue={(val) => setQualificationData('project_info.project_title', val)}
          validateFunc={(item) => item.length > 0}
        />
        <Input
          style={{  marginRight: "16px", width: "40%" }}
          label="Project number"
          type="text"
          placeholder="Number"
          value={qualificationData?.project_info?.project_number}
          setValue={(val) => setQualificationData('project_info.project_number', val)}
          validateFunc={(item) => item.length > 0}
        ></Input>
      </Row>
      <Row alignItems="flex-end" marginBottom="16px" style={{  paddingRight: "18px", width: "60%" }}>
        <Input
          label="City"
          type="text"
          placeholder="City name"
          value={qualificationData?.project_info?.project_city}
          setValue={(val) => setQualificationData('project_info.project_city', val)}
          validateFunc={(item) => item.length > 0}
        ></Input>
      </Row>
      <StyledMediumTitle>What is the project Category?</StyledMediumTitle>
      <CategoriesSetup
        data={qualificationData}
        setData={setQualificationData}
        setHasData={setHasCategories}
      />
      {/* { hasCategories && ( */}
        <>
          <StyledMediumTitle marginBottom="-8px">Who is the owner?</StyledMediumTitle>
          <Row>
          <Input
              style={{  marginRight: "16px", width: "50%" }}
              label="Name"
              type="text"
              placeholder="Name"
              value={qualificationData?.project_info?.owner_name}
              setValue={(val) => setQualificationData('project_info.owner_name', val)}
              validateFunc={(item) => item.length > 0}
            />
            <Input
              style={{ marginRight: "16px", width: "25%" }}
              label="Email"
              type="text"
              placeholder="Company Email"
              value={qualificationData.project_info?.owner_email}
              setValue={(val) => setQualificationData('project_info.owner_email', val)}
              validateFunc={(item) => {
                return emailRE.test(item);
              }}
            />
            <Input
              style={{  marginRight: "16px", width: "25%" }}
              label="Phone"
              type="text"
              placeholder="Phone"
              value={qualificationData?.project_info?.owner_phone}
              setValue={(val) => setQualificationData('project_info.owner_phone', val)}
              validateFunc={(item) => item.length > 0}
            />
          </Row>
          <Row>
            <Input
              style={{  marginRight: "16px", width: "50%" }}
              label="Address"
              type="text"
              placeholder="Address"
              value={qualificationData?.project_info?.owner_address}
              setValue={(val) => setQualificationData('project_info.owner_address', val)}
              validateFunc={(item) => item.length > 0}
            ></Input>
            <Input
              style={{  marginRight: "16px", width: "25%" }}
              label="Suite number, etc."
              type="text"
              placeholder="Suite number, etc."
              value={qualificationData?.project_info?.owner_suite}
              setValue={(val) => setQualificationData('project_info.owner_suite', val)}
              validateFunc={(item) => item.length > 0}
            ></Input>
            <Input
              style={{  marginRight: "16px", width: "25%" }}
              label="ZIP/Postal Code"
              type="text"
              placeholder="ZIP/Postal Code"
              value={qualificationData?.project_info?.owner_postal_code}
              setValue={(val) => setQualificationData('project_info.owner_postal_code', val)}
              validateFunc={(item) => item.length > 0}
            ></Input>
          </Row>
          <Row marginBottom="16px">
            <Input
              style={{ marginRight: "16px", width: "49%" }}
              label="City"
              type="text"
              placeholder="City"
              value={qualificationData?.project_info?.owner_city}
              setValue={(val) => setQualificationData('project_info.owner_city', val)}
              validateFunc={(item) => item.length > 0}
            ></Input>
            <Select
              style={{ paddingRight: "16px",  width: "51%" }}
              iconMarginRight="16px"
              label="Province"
              options={PROVINCES.map((item) => ({ value: item, label: item }))}
              value={qualificationData?.project_info?.owner_province}
              setValue={(val) => setQualificationData('project_info.owner_province', val)}
              validateFunc={(item) => Boolean(item)}
            ></Select>
          </Row>
        </>
      {/* )} */}
    </>
  );
};
