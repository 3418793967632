import React, { useState, useEffect } from "react";
import { pdf } from "@react-pdf/renderer";
import { useDispatch } from "react-redux";
import { getCCDC } from "data/actions/user";
import { FormContainer } from "pages/newPreQualify/styles";
import { FunctionalButton } from "components/functionalButton";
import { FunctionalButtonImage } from "components/functionalButtonImage"
import { StyledQuestion } from "../styles";
import { AddCcdc11PopUp } from "../../../../components/addCcdc11PopUp";
import PledgxOutput from 'pages/pledgxOutputPDF/indexFileType.js'
import ButtonImageNormal from 'assets/pledgx_CQS_orange.png';
import ButtonImageHover from 'assets/pledgx_CQS_white.png';
import { AlertPopup } from "components/alertPopup/alertPopup";

export const FileType = ({
  handleNext,
  action,
  setAction,
  prequalifyData,
  setIsLastPanel,
  fileType,
  setFileType,
  setPrequalifyData,
  setPdfFileSequence,
  handleFinalizeButtonClick,
  pdfDownloadCounter
}) => {
  const dispatch = useDispatch();
  setIsLastPanel(true);
  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openExceedPopup, setOpenExceedPopup] = useState(false);
  const [headText, setHeadText] = useState("");

  const getPDF = (res) => {
    const ccdcId = res.ccdcId;
    function onSuccess(data) {
      const val = new File([data], `ccdc_${Date.now()}.pdf`, {
        type: "pdf",
      });

      // key personnel
      const filtered_personnel = res?.key_personnel?.filter((personnel) => {
        if (!personnel.resume) {
          return false;
        }

        return true;
      });

      const filtered_resumes = filtered_personnel.map((personnel) => {
        return personnel.resume;
      });

      // legal structure personnel
      const filteredLegalPersonnel = res?.legal_structure?.personnel?.filter(
        (personnel) => {
          if (!personnel.resume) {
            return false;
          }

          return true;
        }
      );

      const filteredLegalResumes = filteredLegalPersonnel.map((personnel) => {
        return personnel.resume;
      });

      let temp = [
        ...res?.additional_document,
        ...res?.annual?.financial_records_files,
        ...res?.annual?.health_safety_files,
        ...filteredLegalResumes,
        ...filtered_resumes,
      ];

      // additional files
      Promise.all(
        temp?.map((file) => {
          if (file.content_type) {
            return fetch(file.file)
              .then((res) => res.arrayBuffer())
              .then((buf) => new File([buf], file.name, { type: file.type }));
          }
          // file.content_type = "application/pdf";
          return file;
        })
      )
        .then((data) => {
          setPdfFileSequence([val, ...data]);
        })
        .then(() => handleNext());
    }

    dispatch(getCCDC(ccdcId, (data) => onSuccess(data)));

  };

  const handleGenericGenerate = async (res) => {
    const genericDoc = (
      <PledgxOutput prequalifyData={res} />
    );

    const currentDateTime = new Date().toLocaleString().replace(/[\s,:]+/g, '_');
    const newDateTime = currentDateTime.replace(/\//g, '_');

    const blob = pdf(genericDoc).toBlob();
    blob
      .then((val) => {
        return new File([val], `eCQS_${newDateTime}.pdf`, {
          type: "application/pdf",
        });
      })
      .then((val) => {
        // key personnel
        const filtered_personnel = res?.key_personnel?.filter((personnel) => {
          if (!personnel.resume) {
            return false;
          }

          return true;
        });

        const filtered_resumes = filtered_personnel.map((personnel) => {
          return personnel.resume;
        });

        // legal structure personnel
        const filteredLegalPersonnel = res?.legal_structure?.personnel?.filter(
          (personnel) => {
            if (!personnel.resume) {
              return false;
            }

            return true;
          }
        );

        const filteredLegalResumes = filteredLegalPersonnel.map((personnel) => {
          return personnel.resume;
        });

        let temp = [
          ...res?.additional_document,
          ...res?.annual?.financial_records_files,
          ...res?.annual?.health_safety_files,
          ...filteredLegalResumes,
          ...filtered_resumes,
        ];

        // additional files
        Promise.all(
          temp?.map((file) =>
            fetch(file.file)
              .then((res) => res.arrayBuffer())
              .then((buf) => new File([buf], file.name, { type: file.type }))
          )
        ).then((data) => setPdfFileSequence([val, ...data]));
      })
      .then(() => setPrequalifyData(res));


  };

  const handleCcdcButtonClick = () => {
    setOpenPopup(true);
  };

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [prequalifyData]);

  const handleFileGeneration = () => {

    if (pdfDownloadCounter?.pledgxLimit > 0 && fileType === "generic") {
      setLoading(true);
      handleFinalizeButtonClick();
      handleNext(handleGenericGenerate, getPDF);
    } else {
      setOpenExceedPopup(true);
    }

    if (pdfDownloadCounter?.ccdcLimit > 0 && fileType === "ccdc-11") {
      setLoading(true);
      handleFinalizeButtonClick();
      handleNext(handleGenericGenerate, getPDF);
    } else {
      setOpenExceedPopup(true);
    }

  };

  return (
    <FormContainer>
      <StyledQuestion marginBottom="120" weight="500">
        What format would you like to use for your Qualifications Showcase?
      </StyledQuestion>
      <FunctionalButton
        buttonTitle="CCDC 11 - 2019"
        width="420px"
        height="48px"
        marginRight="16px"
        marginTop="48px"
        isReversedColor={fileType !== "ccdc-11" ? true : false}
        selected={fileType !== "ccdc-11" ? false : true}
        handleButton={() => {
          setFileType("ccdc-11");
          setHeadText("CCDC 11");
        }}
      />

      <FunctionalButtonImage
        normalImage={ButtonImageNormal}
        hoverImage={ButtonImageHover}
        width="420px"
        height="48px"
        marginTop="24px"
        backgroundColor="#FF6D1D"
        isReversedColor={fileType !== "generic" ? true : false}
        selected={fileType !== "generic" ? false : true}
        handleButton={() => {
          setFileType("generic");
          setHeadText("PledgX CQS");
        }}
      />

      <FunctionalButton
        buttonTitle="Next"
        handleButton={() => {
          if (fileType === "ccdc-11") {
            handleCcdcButtonClick();
          } else {
            handleFileGeneration();
          }
        }}
        width="200px"
        marginRight="0px"
        marginTop="48px"
        disabled={!fileType || loading}
      />
      <AddCcdc11PopUp
        setOpenDialog={(e) => setOpenPopup(e)}
        openDialog={openPopup}
        handleButton={() => handleFileGeneration()}
      />

      <AlertPopup
        openPopup={openExceedPopup}
        setOpenPopup={setOpenExceedPopup}
        headerText={`You have exceeded the ${headText} limit.`}
        subHeaderText={`You have only 0 CQS. Please upgrade your package to increase your limit.`}
      />
    </FormContainer>
  );
};

export default FileType;
