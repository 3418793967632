import React, { useEffect, useRef, useState } from "react";
import { COLORS, FONT_SIZES } from "../../constants";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useDispatch, useSelector } from "react-redux";
import Input from "../inputField";

import {
  stripeDataSelector,
  subscriptionSelector,
} from "../../data/selectors/subscriptions";

import displayPrice from "../../utilities/displayPrice";
import Spinner from "../spinner";
import classes from "./PackageItemMini.module.css";

import contractor_packages from "../../assets/payment/contractor_packages.json";
import { FunctionalButton } from "components/functionalButton";
import { apply_promo_code } from "data/actions/subscriptions";
import { useHistory } from "react-router";
import { CenterRow } from "pages/subscribePage/styles";
import { PackageItemBoxFreeTrialText } from "components/packageItem/styles";
import moment from "moment";

export const PackageItemMini = ({ loading, redirectType, selectedPackage }) => {
  const [state, setState] = useState({
    widgetDimensions: null,
  });
  const activeSubscription = useSelector(subscriptionSelector);
  const [errorMessage, setErrorMessage] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const widgetRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  const stripeData = useSelector(stripeDataSelector);

  useEffect(() => {
    if (widgetRef.current && !state.widgetDimensions) {
      setState({
        ...state,
        widgetDimensions: {
          height: widgetRef.current.getBoundingClientRect().height,
          width: widgetRef.current.getBoundingClientRect().width,
        },
      });
    }
  }, [widgetRef]);

  function handleError(e) {
    // Handle API Erorrs
    if (
      e.response &&
      e.response.status === 401 &&
      e.response.data &&
      e.response.data.message === "SESSION_ID_INVALID"
    ) {
      history.push("/account");
    } else if (e.response && e.response?.data && e.response?.data?.message) {
      setErrorMessage(e.response?.data?.message);
    }
  }

  function handleInteraction({ action, payload }) {
    switch (action) {
      case "MORE_INFO":
        break;
      case "APPLY_PROMO_CODE":
        dispatch(
          apply_promo_code(
            { promo_code: promoCode, package_id: selectedPackage.id },
            () => {
              setErrorMessage("");
              setState({
                ...state,
                loading: false,
              });
            },
            (e) => {
              handleError(e);
            }
          )
        );
        break;
    }
  }

  if (loading || !selectedPackage) {
    return (
      <div className={classes["loading-container"]}>
        <Spinner />
      </div>
    );
  } else {
    var billing_date =
      stripeData &&
      stripeData.invoice &&
      stripeData.invoice.billing &&
      stripeData.invoice.billing.date
        ? new Date(Date.parse(stripeData.invoice.billing.date))
        : new Date();
    return (
      <div className={classes.container} ref={widgetRef}>
        <div className={classes["title-container"]}>
          <span
            className={classes["title-text"]}
            style={{ fontSize: FONT_SIZES.body_2, color: COLORS.primaryOrange }}
          >
            PledgXqualify {selectedPackage.title}
          </span>
        </div>
        {state.widgetDimensions && (
          <div
            className={classes["description-container"]}
            style={{ maxWidth: `${state.widgetDimensions.width}px` }}
          >
            <span
              className={classes["description-text"]}
              style={{ fontSize: FONT_SIZES.body_5 }}
            >
              {selectedPackage.description}
            </span>
          </div>
        )}
        {selectedPackage.details &&
          Array.isArray(selectedPackage.details) &&
          selectedPackage.details.length > 0 && (
            <div className={classes["details-section"]}>
              {selectedPackage.details.map((packageDetail) => (
                <div
                  className={classes["detail-item-container"]}
                  style={{
                    fontSize: FONT_SIZES.body_5,
                    borderBottom: `0.25px solid ${COLORS.lightGrey1}`,
                    borderTop: `0.25px solid ${COLORS.lightGrey1}`,
                  }}
                >
                  <span
                    style={{ fontSize: FONT_SIZES.body_5, marginRight: "5px" }}
                  >
                    {packageDetail.title}
                  </span>
                  {packageDetail.tooltip && (
                    <Tooltip
                      title={packageDetail.tooltip}
                      placement="top"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontSize: FONT_SIZES.body_5,
                            color: "white",
                            bgcolor: COLORS.primaryOrange,
                            border: `1.5px solid ${COLORS.borderOrange}`,
                            maxWidth: "250px",
                            "& .MuiTooltip-arrow": {
                              color: COLORS.primaryOrange,
                            },
                          },
                        },
                      }}
                    >
                      <HelpOutlineIcon
                        sx={{
                          fontSize: 14,
                          color: COLORS.primaryOrange,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleInteraction({
                            action: "MORE_INFO",
                            payload: {
                              package: packageDetail.title,
                              detail: packageDetail.id,
                            },
                          })
                        }
                      />
                    </Tooltip>
                  )}
                </div>
              ))}
            </div>
          )}
        <>
          <div className={classes["promo-code-container"]}>
            <Input
              width="100%"
              textAreaHeight="40px"
              placeholder="Promo Code"
              paddingRight="10px"
              marginTop="0px"
              value={promoCode}
              setValue={setPromoCode}
            />
            <FunctionalButton
              buttonTitle="Apply Now"
              width="40%"
              height="40px"
              marginRight="0"
              marginLeft="15px"
              disabled={promoCode ? false : true}
              handleButton={(e) =>
                handleInteraction({ action: "APPLY_PROMO_CODE", payload: e })
              }
            />
          </div>
          {errorMessage && (
            <>
              <CenterRow style={{ justifyContent: "left", marginTop: "5px" }}>
                <div className={classes["error-container"]}>{errorMessage}</div>
              </CenterRow>
            </>
          )}
        </>
        {stripeData && stripeData.invoice && (
          <>
            {stripeData.invoice.amount_details ? (
              <div className={classes["price-subtotal-container"]}>
                {stripeData.invoice.amount_details.map((amountDetail) => (
                  <span
                    className={classes["price-subtotal-text"]}
                    style={
                      amountDetail.type === "CREDIT"
                        ? { color: COLORS.lightGreen4 }
                        : {}
                    }
                  >
                    {amountDetail.title} $
                    {displayPrice({
                      amount: amountDetail.amount,
                      options: { addDecimal: true },
                    })}
                  </span>
                ))}
              </div>
            ) : (
              <></>
            )}
            <span className={classes["expiry-date-container"]}>
              your plan will automatically renew on{" "}
              {stripeData.invoice.billing.interval === "YR"
                ? moment().add(1, "years").format("MM-DD-YYYY")
                : moment().add(1, "months").format("MM-DD-YYYY")}{" "}
              unless canceled.
            </span>
            <div className={classes["price-container"]}>
              {!stripeData.invoice.typical_amount ||
              stripeData.invoice.typical_amount ===
                stripeData.invoice.amount ? (
                <span
                  className={classes["price-text"]}
                  style={{
                    fontSize: FONT_SIZES.body_2,
                    color: COLORS.primaryOrange,
                  }}
                >
                  {`$${displayPrice({
                    amount: stripeData.invoice.amount,
                    options: { addDecimal: true },
                  })} ${stripeData.invoice.currency}`}
                  {stripeData.invoice.billing &&
                  stripeData.invoice.billing.type === "recurring"
                    ? ` per ${
                        stripeData.invoice.billing.interval === "YR"
                          ? "year"
                          : stripeData.invoice.billing.interval === "MO"
                          ? "month"
                          : ""
                      }`
                    : ""}
                </span>
              ) : (
                <span
                  className={classes["price-text"]}
                  style={{
                    fontSize: FONT_SIZES.body_2,
                    color: COLORS.primaryOrange,
                  }}
                >{`Pay $${displayPrice({
                  amount: stripeData.invoice.amount,
                  options: { addDecimal: true },
                })} ${stripeData.invoice.currency} now`}</span>
              )}
            </div>
            {stripeData.invoice.billing && stripeData.invoice.billing.date && (
              <div className={classes["secondary-price-container"]}>
                <span
                  className={classes["secondary-price-text"]}
                  style={{ fontSize: FONT_SIZES.body_4 }}
                >{`Billed on ${billing_date.getFullYear()}-${
                  billing_date.getMonth() + 1
                }-${billing_date.getDate()}`}</span>
              </div>
            )}
            {stripeData.invoice.typical_amount &&
              stripeData.invoice.typical_amount !==
                stripeData.invoice.amount && (
                <div className={classes["secondary-price-container"]}>
                  <span
                    className={classes["secondary-price-text"]}
                    style={{ fontSize: FONT_SIZES.body_4 }}
                  >{`Typical recurring payment: $${displayPrice({
                    amount: stripeData.invoice.typical_amount,
                    options: { addDecimal: true },
                  })}${
                    stripeData.invoice.billing &&
                    stripeData.invoice.billing.type === "recurring"
                      ? ` per ${
                          stripeData.invoice.billing.interval === "YR"
                            ? "year"
                            : stripeData.invoice.billing.interval === "MO"
                            ? "month"
                            : ""
                        }`
                      : ""
                  }`}</span>
                </div>
              )}
            {redirectType &&
              redirectType === "UPGRADE" &&
              activeSubscription?.title !== "Free" && (
                <div className={classes["upgrade-container"]}>
                  <span
                    className={classes["upgrade-text"]}
                    style={{ fontSize: FONT_SIZES.body_5 }}
                  >
                    We will automatically apply any prorated remainder from your
                    previous subscription to your next bill
                  </span>
                </div>
              )}
          </>
        )}
      </div>
    );
  }
};
