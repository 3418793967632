import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  clearInstitutionRfpq,
  getInstitutionRfpq,
  prequalifyContractors,
} from "../../data/actions/buyers";
import { institutionRfpqSelector } from "../../data/selectors/buyers";
import { Container } from "../../components/common";
import { ProjectHeader } from "./components/ProjectHeader";
import { SubmissionTable } from "./components/submissionTable";
import { AdditionalBox } from "./components/additionalBox";
import { getAllSubmissions } from "../../api/buyers";
import { useParams } from "react-router-dom";
import { GoBackButton } from "./components/goBackButton";
import { AlertPopup } from "components/alertPopup/alertPopup";
import SubmissionPreview from "../submissionPreview";
import { getSubmissionDetails } from "../../api/buyers";
import Spinner from "../../components/spinner";

import SubmissionsTableForm from "./SubmissionsTableForm";
import formSetup from "./forms/formSetup.json";
import { getInsuranceTypes } from "api/external_data";

const ProjectSubmissions = () => {
  const history = useHistory();
  const { id } = useParams();
  const [state, setState] = useState({
    submissionDetails: null,
    submissionDetailsError: null,
    submissionDetailsPopup: {
      height: "100%",
      showLogo: true,
      visible: false,
      width: "100%",
    },
    formLoading: false,
    popup: { height: "350px", showLogo: true, visible: false, width: "470px" },
    prequalifiedContractorCount: 0,
    readOnly: false,
    disabled: false,
    successPopup: { visible: false },
  });
  const [formSetupState, setFormSetupState] = useState(formSetup);
  const [data, setData] = useState("");
  const dispatch = useDispatch();
  const [insuranceType, setInsuranceType] = useState([]);
  const rfpqSelector = useSelector(institutionRfpqSelector);
  const location = useLocation();
  const institution_id = location.state?.institutionId || "";

  useEffect(() => {
    //getAllSubmissions(id, (res) => setData(res), () => {})
    dispatch(getInstitutionRfpq({ rfpq_id: id }));
    return () => {
      dispatch(clearInstitutionRfpq());
    };
  }, []);

  useEffect(() => {
    getInsuranceTypes()
      .then((res) => {
        setInsuranceType(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  useEffect(() => {
    if (rfpqSelector) {
      setData(rfpqSelector);
    }

    if (rfpqSelector?.original_status === "invited") {
      var newSubmissions = rfpqSelector?.submissions;
      var invitations = rfpqSelector?.invited_institutions;

      const temp = invitations?.filter(
        (invitation) =>
          !newSubmissions?.find(
            (submission) =>
              submission.institution_id === invitation.institution_id
          )
      );
      const newTemp = temp?.map((item) => ({
        ...item,
        submitted_date: "Not Submitted",
      }));

      let resultArray = [];
      if (rfpqSelector?.submissions && newTemp.length > 0) {
        resultArray = newSubmissions?.concat(newTemp);
        setData({ ...rfpqSelector, submissions: resultArray });
      } else if (!("submissions" in rfpqSelector) && newTemp?.length > 0) {
        resultArray = [...newTemp];
        setData({ ...rfpqSelector, submissions: resultArray });
      }
    }
  }, [rfpqSelector]);

  useEffect(() => {
    let formPage = formSetupState[0];
    if (formPage) {
      if (data) {
        if (
          data?.status === "closed" &&
          (data?.is_prequalified === false || data?.is_prequalified === null)
        ) {
          formPage.navigation.navigationMessage =
            "You can now select contractors";
        } else if (data?.status === "open" || data?.status === "invited") {
          formPage.navigation.navigationMessage =
            "You can Select the Contractors Once RFP/Q Status is closed";
        } else if (
          data?.is_prequalified === true &&
          data?.status === "closed"
        ) {
          formPage.navigation.navigationMessage =
            "You already selected the Contractors";
        }
        setFormSetupState([formPage]);
      }
    }

    if (
      data?.status === "invited" ||
      data?.status === "open" ||
      data?.is_prequalified === true
    ) {
      setState({
        ...state,
        disabled: true,
      });
    }
  }, [data]);

  function handleInteraction({ action, payload }) {
    switch (action) {
      case "CLOSE_SUBMISSIONS_POPUP":
        setState({
          ...state,
          submissionDetails: null,
          submissionDetailsError: null,
          submissionDetailsPopup: {
            ...state.submissionDetailsPopup,
            visible: false,
          },
        });
        break;
      case "CONFIRM_POPUP":
        if (payload === "prequalifyPopup") {
          if (data.submissions && data.submissions.length > 0) {
            var prequalifiedContractors = [];
            data.submissions.map((contractor) => {
              if (contractor.prequalified) {
                prequalifiedContractors.push({
                  contractor_id: contractor.institution_id,
                  id: contractor.id,
                });
              }
            });
            setState({
              ...state,
              formLoading: true,
              prequalifiedContractorCount: prequalifiedContractors.length,
              readOnly: true,
            });
            dispatch(
              prequalifyContractors(
                { rfpq_id: id, contractors: prequalifiedContractors },
                () => {
                  setState({
                    ...state,
                    formLoading: false,
                    readOnly: false,
                    popup: { visible: false },
                    successPopup: {
                      visible: true,
                      content:
                        state.prequalifiedContractorCount > 0
                          ? `The Contractor${
                              state.prequalifiedContractorCount > 1
                                ? "s are"
                                : " is"
                            } now Selected`
                          : "You have removed Selected contractor(s)",
                    },
                  });
                },
                () => {
                  setState({
                    ...state,
                    formLoading: false,
                    readOnly: false,
                    popup: { ...state.popup, visible: false },
                  });
                  // DISPLAY ERROR
                }
              )
            );
          }
        } else if (payload === "successPopup") {
          var formPage = formSetupState[0];
          formPage.navigation.submit.disabled = true;
          formPage.navigation.navigationMessage =
            "You have Selected the Contractors";
          setFormSetupState([formPage]);
          setState({
            ...state,
            disabled: true,
            successPopup: { ...state.successPopup, visible: false },
          });
        }
        break;
      case "CONTRACTOR_CHANGE":
        var tempSubmissions = data.submissions;
        tempSubmissions[payload.index][payload.selectedId] = payload.selected;

        setData({
          ...data,
          submissions: tempSubmissions,
        });

        var prequalifiedContractorsList = [];
        data.submissions.map((contractor) => {
          if (contractor.prequalified) {
            prequalifiedContractorsList.push({
              contractor_id: contractor.institution_id,
              id: contractor.id,
            });
          }
        });

        formPage = formSetupState[0];
        if (prequalifiedContractorsList.length > 0) {
          if (data?.status === "closed") {
            if (
              data?.is_prequalified === false ||
              data?.is_prequalified === null
            ) {
              formPage.navigation.submit.disabled = false;
            }
          }
        } else {
          formPage.navigation.submit.disabled = true;
        }
        setFormSetupState([formPage]);
        break;
      case "INTERACTION_CLICKED":
        //history.push(`/buyers/submissions/${ id }/${ payload }`);
        getSubmission(payload);
        break;
      case "SUBMIT_FORM":
        prequalifiedContractors = [];
        payload.submissionsList.map((contractor) => {
          if (contractor.prequalified) {
            prequalifiedContractors.push(contractor.institution_id);
          }
        });
        setState({
          ...state,
          popup: {
            ...state.popup,
            height: "350px",
            id: "prequalifyPopup",
            interaction: {
              cancelButton: { title: "CANCEL", width: "225px" },
              confirmButton: { title: "CONFIRM", width: "225px" },
            },
            title:
              prequalifiedContractors.length > 0
                ? `Please confirm that your selection is final`
                : "Do you want to remove this Contractor(s)?",
            visible: true,
            width: "550px",
          },
          prequalifiedContractorCount: prequalifiedContractors.length,
        });
        break;
      default:
        break;
    }
  }

  const getSubmission = async (id) => {
    setState({
      ...state,
      submissionDetailsPopup: {
        ...state.submissionDetailsPopup,
        visible: true,
      },
    });
    await getSubmissionDetails(id, "edit", { institution_id: institution_id })
      .then((data) => {
        setState({
          ...state,
          submissionDetailsPopup: {
            ...state.submissionDetailsPopup,
            visible: true,
          },
          submissionDetails: data,
        });
      })
      .catch((err) => {
        setState({
          ...state,
          submissionDetailsPopup: {
            ...state.submissionDetailsPopup,
            visible: true,
          },
          submissionDetailsError:
            "We're sorry. An error occured loading this submission's details. Please try again later.",
        });
      });
  };

  return (
    <Container>
      <GoBackButton buttonText="Back to RFP/Qs" />
      <AlertPopup
        openPopup={state.submissionDetailsPopup.visible}
        height={state.submissionDetailsPopup.height || "434px"}
        width={state.submissionDetailsPopup.width || "640px"}
        setOpenPopup={() =>
          handleInteraction({ action: "CLOSE_SUBMISSIONS_POPUP" })
        }
        showIcon={false}
        bodyContent={
          <div style={{ height: "100%", maxWidth: "850px" }}>
            {state.submissionDetailsError ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {state.submissionDetailsError}
              </div>
            ) : state.submissionDetails ? (
              <SubmissionPreview
                setIsLastPanel={true}
                handleNext={() => console.log("NEXT")}
                prequalifyData={state.submissionDetails}
                readOnly={true}
                submitCqs={false}
                insuranceTypeList={insuranceType}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spinner />
              </div>
            )}
          </div>
        }
      />
      {rfpqSelector && (
        <>
          <ProjectHeader data={rfpqSelector} />
          {rfpqSelector?.submission_count > 0 &&
            rfpqSelector.original_status === "open" && (
              <>
                <AdditionalBox
                  submissionNumber={rfpqSelector?.submission_count}
                  data={rfpqSelector}
                  buttonTitle="Analyze"
                />
                <SubmissionsTableForm
                  contractorChange={(e) =>
                    handleInteraction({
                      action: "CONTRACTOR_CHANGE",
                      payload: e,
                    })
                  }
                  formLoading={state.formLoading}
                  interactionClicked={(e) =>
                    handleInteraction({
                      action: "INTERACTION_CLICKED",
                      payload: e.rowId,
                    })
                  }
                  popup={state.popup}
                  popupClicked={(e) =>
                    handleInteraction({ action: e.action, payload: e.id })
                  }
                  prequalifiedContractorCount={
                    state.prequalifiedContractorCount
                  }
                  readOnly={state.readOnly}
                  disabled={state.disabled}
                  successPopup={state.successPopup}
                  submissions={data?.submissions}
                  submitForm={(e) =>
                    handleInteraction({ action: "SUBMIT_FORM", payload: e })
                  }
                />
              </>
            )}
          {rfpqSelector.original_status === "invited" && (
            <>
              <AdditionalBox
                submissionNumber={rfpqSelector?.submission_count}
                data={rfpqSelector}
                buttonTitle="Analyze"
              />
              <SubmissionsTableForm
                contractorChange={(e) =>
                  handleInteraction({
                    action: "CONTRACTOR_CHANGE",
                    payload: e,
                  })
                }
                formLoading={state.formLoading}
                interactionClicked={(e) => {
                  if (e.rowId) {
                    handleInteraction({
                      action: "INTERACTION_CLICKED",
                      payload: e.rowId,
                    });
                  }
                }}
                popup={state.popup}
                popupClicked={(e) =>
                  handleInteraction({ action: e.action, payload: e.id })
                }
                prequalifiedContractorCount={state.prequalifiedContractorCount}
                readOnly={state.readOnly}
                disabled={state.disabled}
                successPopup={state.successPopup}
                submissions={data?.submissions}
                submitForm={(e) =>
                  handleInteraction({ action: "SUBMIT_FORM", payload: e })
                }
              />
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default ProjectSubmissions;

/*

    if (formData.submissionsList && (formData.submissionsList.length > 0)) {
      var prequalifiedContractors = [];
      setState({ ...state, formLoading: true, readOnly: true });
      dispatch(prequalifyContractors({ rfpq_id: id, contractor_id: payload.contractorId, selected: payload.selected }, () => {
        setState({ ...state, readOnly: false });
      }, () => {
        setState({ ...state, readOnly: false });
        // DISPLAY ERROR
      }));
    };

  */
