import React from 'react';

const PlusSignIcon = ({ fill, height }) => {
  return (
    <svg width={height || '8'} height={height || '8'} viewBox="0 0 8 8" fill={fill ? fill : 'none'} xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 0C3.58579 0 3.25 0.306209 3.25 0.683938V3.25H0.683938C0.306209 3.25 0 3.58579 0 4C0 4.41421 0.306209 4.75 0.683938 4.75H3.25V7.31606C3.25 7.69379 3.58579 8 4 8C4.41421 8 4.75 7.69379 4.75 7.31606V4.75H7.31606C7.69379 4.75 8 4.41421 8 4C8 3.58579 7.69379 3.25 7.31606 3.25H4.75V0.683938C4.75 0.306209 4.41421 0 4 0Z" fill={fill || "#1A051D"} />
    </svg>
  );
};

export default PlusSignIcon;
