import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more'; // Import highcharts-more for gauge
import SolidGauge from 'highcharts/modules/solid-gauge'; // Import solid-gauge module for gauge

// Initialize the modules
HighchartsMore(Highcharts);
SolidGauge(Highcharts);

const SemiCircleGauge = ({ data }) => {
  const score = data?.score ? data?.score : 0
  const AccountId = data?.AccountId ? data?.AccountId : 0
  const options = {
    chart: {
      type: 'gauge',
      backgroundColor: '#ffffff',
      plotBackgroundColor: {
        stops: [
          [0, '#FFF4C6'],
          [50, '#FFFFFF'],
          [100, '#FFF4C6']
        ]
      },
      height: 250
    },
    title: {
      text: `Risk Score Calculator for ${AccountId}`,
      verticalAlign: 'bottom'
    },
    pane: [{
      startAngle: -90,
      endAngle: 90,
      background: null,
      center: ['50%', '100%'],
      size: 300
    }, {
      startAngle: -90,
      endAngle: 90,
      background: null,
      center: ['50%', '100%'],
      size: 50
    }],
    exporting: {
      enabled: true
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.series.name}</b><br/>Score: ${this.y.toFixed(2)}`;
      }
    },
    yAxis: [{
      min: 0,
      max: 100,
      minorTickPosition: 'inside',
      tickPosition: 'inside',
      tickColor: '#666',
      labels: {
        rotation: 'auto',
        distance: 10,
        style: {
          color: '#666',
          fontSize: '10px'
        }
      },
      plotBands: [{
        from: 0,
        to: 100,
        color: {
          linearGradient: {
            x1: 0,
            x2: 1,
            y1: 0,
            y2: 0
          },
          stops: [
            [0.00, '#32CD32'], // Green
            [0.05, '#2AAC2A'],
            [0.10, '#228B22'], // Forest Green
            [0.15, '#2F9F49'],
            [0.20, '#3CB371'], // Medium Sea Green
            [0.25, '#66D080'],
            [0.30, '#90EE90'], // Light Green
            [0.35, '#C8F7BF'],
            [0.40, '#FFFFED'], // Light Yellow
            [0.45, '#FFFF77'],
            [0.50, '#FFFF00'], // Yellow
            [0.55, '#FFEB00'],
            [0.60, '#FFD700'], // Gold
            [0.65, '#FFBE00'],
            [0.70, '#FFA500'], // Orange
            [0.75, '#FF9900'],
            [0.80, '#FF8C00'], // Dark Orange
            [0.85, '#FF7824'],
            [0.90, '#FF6347'], // Tomato
            [0.95, '#FF3224'],
            [1.00, '#FF0000'] // Red
          ]
        }
      }],
      title: {
        text: `<span style="font-size:14px">Risk ${score.toFixed(2)}%</span>`,
        y: -20
      }
    }, {
      linkedTo: 0,
      pane: 1,
      min: 0,
      max: 100,
      minorTickPosition: 'inside',
      tickPosition: 'inside',
      labels: {
        enabled: false
      }
    }],
    plotOptions: {
      gauge: {
        dataLabels: {
          enabled: false
        },
        dial: {
          backgroundColor: '#EDEAEA', // Change to off-white color
          radius: '98%',
          baseWidth: 8,
          rearLength: '0',
          topWidth: 1
        }
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Risk in Percent(%)',
      data: [[0, score]],
      yAxis: 0
    }]
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default SemiCircleGauge;
