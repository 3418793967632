export const getServiceProviderBuyersList = () => (dispatch) => {
  dispatch({
    type: "GET_BUYERS_LIST",
    payload: {
      endpoint: "/service_provider/get_buyer_list",
      method: "GET",
    },
  });
};

export const getServiceProviderContractorsList = () => (dispatch) => {
  dispatch({
    type: "GET_AGENT_CONTRACTORS_LIST",
    payload: {
      endpoint: "/service_provider/get_all_contractors_list",
      method: "GET",
    },
  });
};

export const getBuyerRfpqsList = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "GET_BUYER_RFPQS_LIST",
    payload: {
      endpoint: "/service_provider/get_buyer_rpfqs",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};

export const getInstitutionProfileInfo =
  (data, onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "GET_INSTITUTION_PROFILE_INFO",
      payload: {
        endpoint: "/service_provider/institution/profile/get",
        method: "POST",
        body: data,
      },
      onSuccess,
      onFailed,
    });
  };
export const getContractorCcdcsList =
  (data, onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "GET_CONTRACTOR_CCDCS_LIST",
      payload: {
        endpoint: "/service_provider/get_contractor_ccdcs",
        method: "POST",
        body: data,
      },
      onSuccess,
      onFailed,
    });
  };

export const clearContractorCcdcList = () => (dispatch) => {
  dispatch({
    type: "CLEAR_CONTRACTOR_CCDC_LIST",
  });
};

export const getServiceProviderPpcListOfAllClient = () => (dispatch) => {
  dispatch({
    type: "GET_AGENT_PPC_LIST",
    payload: {
      endpoint: "/service_provider/get_contractors_all_ppc",
      method: "GET",
    },
  });
};
export const getServiceProviderCoListOfAllClient = () => (dispatch) => {
  dispatch({
    type: "GET_AGENT_CO_LIST",
    payload: {
      endpoint: "/service_provider/get_contractors_all_change_order",
      method: "GET",
    },
  });
};
export const getServiceProviderCqsListOfAllClient = () => (dispatch) => {
  dispatch({
    type: "GET_AGENT_CQS_LIST",
    payload: {
      endpoint: "/service_provider/get_contractors_all_cqs",
      method: "GET",
    },
  });
};
export const getServiceProviderContractorAlerts = () => (dispatch) => {
  dispatch({
    type: "GET_CONTRACTOR_ALERTS",
    payload: {
      endpoint: "/service_provider/get_contractors_alerts",
      method: "GET",
    },
  });
};
