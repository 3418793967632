import React from 'react';
import cone from './Cone.svg';

const Cone = ({isMobile}) => {
  return (
    <img width={isMobile ? "119" : "194"} height={isMobile ? "124" : "204"} src={cone} />
  )
};

export default Cone;


