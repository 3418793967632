import React, { useEffect, useState } from "react";

import {
  MediumText,
  RegularText,
  StyledBox,
  SmallText,
} from '../styles';

import { useHistory } from "react-router-dom";
import { FunctionalButton } from "../../../components/functionalButton";
import ProfileSetupIcon from "../../../assets/dashboardIcons/profileSetupIcon";
import {
  FunctionArea,
  IntroArea,
  StyledTitle,
  StyledProgress,
  ButtonContainer,
} from './styles';

export const ProfileSetup = ({
  isMobile,
  userData
}) => {
  let progress = userData ? userData.step + 1 : 0;
  if (progress == 3 && userData?.status != "active"){
    progress = progress - 1
  }
  let history = useHistory();
  const handleComplete = () => {
    history.push("/onboarding");
  }

  const handleEdit = () => {
    history.push("/profile");
  }
  return (
    <FunctionArea isMobile={isMobile}>
      {isMobile && (
        <>
        <StyledBox 
          boxHeight="110px" 
          borderRadius="15px" 
          isMobile={isMobile} 
          onClick={userData?.status != "active" ? handleComplete : handleEdit} 
          style={{cursor: "pointer"}}
        >
          <ProfileSetupIcon width={77} height={83} isMobile={isMobile}/>
          {/* <StyledProgress value={progress} total='3'/> */}
        </StyledBox>
        </>
      )}
      {!isMobile && (
        <>
        <MediumText>Profile Setup</MediumText>
        <StyledBox boxHeight="216px">
          <IntroArea>
            <ProfileSetupIcon />
            {/* <StyledTitle marginTop="12px">
              <SmallText>Your Progress</SmallText>
              <RegularText>{Math.round(((progress/3)*100)/10)*10}% Completed</RegularText>
              <StyledProgress value={progress} total='3'/>
            </StyledTitle> */}
          </IntroArea>
          <ButtonContainer>
            <FunctionalButton
              buttonTitle={userData?.status != "active" ? "Complete Profile" : "Edit Profile"}
              handleButton={userData?.status != "active" ? handleComplete : handleEdit}
              width="80%"
              marginRight="0px"
              isReversedColor={true}
              hasBoxShadow={true}
            />
          </ButtonContainer>
      </StyledBox>
        </>
      )}
      
    </FunctionArea>
  );
}


