import styled from "styled-components";

export const NoticeRoot = styled.div`
  width: 310px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  color: #5e5873;
`;

export const NoticeImage = styled.img`
  margin-left: -22px;
  margin-top: 18px;
  width: 310px;
  height: 160px;
`;

export const NoticeTitle = styled.div`
  margin-top: 18px;

  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;

  color: #26303d;
`;

export const NoticeBody = styled.div`
  margin-top: 18px;

  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;

  color: #26303d;
`;
