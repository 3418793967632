import React, { useState, useEffect } from "react";
import { SelectingProjects } from "./components/selectingProjects";
import { AlertPopup } from "components/alertPopup/alertPopup";
import { useMediaQuery } from "react-responsive";

export const ResultPanel = ({
  object,
  setObject,
  setOpenPopup,
  filteredData,
  setFilteredData,
  filterApi,
  type,
  project,
  pickedArray,
  rerender,
  disabled,
}) => {
  const [openExceedPopup, setOpenExceedPopup] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const handleAdd = () => {
    if (filteredData && filteredData.length >= 99) {
      setOpenExceedPopup(true);
    } else {
      setOpenPopup(true);
    }
    // checkPersonnelLimit(() => setOpenPopup(true), () => setOpenExceedPopup(true))
  };

  return (
    <>
      <SelectingProjects
        add={() => {
          handleAdd();
        }}
        object={object}
        setObject={setObject}
        type={type}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        filterApi={filterApi}
        project={project}
        pickedArray={pickedArray}
        rerender={rerender}
        disabled={disabled}
      />
      <AlertPopup
        openPopup={openExceedPopup}
        setOpenPopup={setOpenExceedPopup}
        isMobile={isMobile}
        headerText="You have exceeded the personnel limit."
        subHeaderText="You can add a maximum of 10 personnel."
      />
    </>
  );
};
