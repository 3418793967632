import React, { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import { FunctionalButton } from "components/functionalButton";
import ExportComponent from "./exportComponent";
import { ImportIcon } from "assets/common/ImportIcon";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { GoBackButton } from "pages/buyers/components/goBackButton";
import { StyledContainer } from "pages/underwriters/components/peerGroupAnalysis/styles.js";
import { MediumText, HeadingText } from "../../../underwriterDashboard/styles";
import { StyledConciseTableContainer, DataTable } from "./styles.js";
import "./styles.css";
import PylonIcon from "assets/common/pylonIcon.png";
import {
  getFinancialAsGivenData,
  getFinancialInitialData,
  getFinancialEventStatusData,
  getFinancialDocuments,
  postFinancialCSVData,
  saveFinancialData,
  saveMarkFigureData
} from "api/external_data.js";
import {
  PlaceholderText,
  StyledBox,
} from "pages/newPreQualify/components/styles.js";
import { DeleteRow } from "./deleteRow";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import {
  StyledTab,
  SetupContainer,
  StyledTabList,
  TitleDiv,
} from "./styles.js";
import { NumberFormatOnly } from "components/locale/numberFormatOnly.js";
import { SuccessPopup } from "pages/contractorDashboard/profileSetup/SuccessPopup.js";
import { AlertMessage } from "./alertMessage";
import { PdfViewer } from "components/pdfViewer";

const FinancialAnalysis = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  const location = useLocation();
  const [data, setData] = useState([]);
  const [asGivendata, setAsGivendata] = useState([]);
  const fileReader = new FileReader();
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [editAnalysisRowIndex, setEditAnalysisRowIndex] = useState(null);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [selectedNotAnalysisRow, setSelectedNotAnalysisRow] = useState(null);
  const [selectedAnalysisRow, setSelectedAnalysisRow] = useState(null);
  const [notAnalysisRowId, setNotAnalysisRowId] = useState(null);
  const [analysisRowId, setAnalysisRowId] = useState(null);
  const [desiredColumns, setDesiredColumns] = useState([]);
  const [desiredColumnsAsGiven, setDesiredColumnsAsGiven] = useState([]);
  const formattedCompanyName = location.state?.company_name?.replace(
    /\s+/g,
    "_",
  );
  const fName =
    location.state?.account_id +
    "_" +
    formattedCompanyName +
    "_Financial_Statement";
  const [sheetName, setSheetName] = useState("Financial Statement");
  const [fileName, setFileName] = useState(fName);
  const [openDeleteRowNotAnalysisPopup, setOpenDeleteRowNotAnalysisPopup] =
    useState(false);
  const [openDeleteRowAnalysisPopup, setOpenDeleteRowAnalysisPopup] =
    useState(false);
  const [analysisData, setAnalysisData] = useState([]);
  const [notAnalysisData, setNotAnalysisData] = useState([]);
  const [selectTab, setSelectTab] = React.useState("1");
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [eventStatusData, setEventStatusData] = useState([]);
  const [documentsData, setDocumentsData] = useState([]);
  const [openAlertMessagePopup, setOpenAlertMessagePopup] = useState(false);
  const [totalAssets, setTotalAssets] = useState(0);
  const [totalLiabilitiesNetworth, setTotalLiabilitiesNetworth] = useState(0);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [notCorrectFormat, setNotCorrectFormat] = useState(false);
  const [openSuccessSavePopup, setOpenSuccessSavePopup] = useState(false);
  const icon = <img src={PylonIcon} />;
  const initialDataRef = useRef([]);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [multiplier, setMultiplier] = useState(1);
  const [showAlert, setShowAlert] = useState(false);
  const [markFigure, setMarkFigure] = useState("");
  const [showFigure, setShowFigure] = useState("");
  const [initialMarkFigure, setInitialMarkFigure] = useState("");

  const multiplierItems = [
    'Tangible Net Worth',
    'Working Capital',
    'Acid Test',
    'Net Quick',
    'Net Holdback',
    'Cashflow (Less) CPLTD',
    'Margin Cover',
    'Net Cash',
    'Max Project Capacity (MPC)'
  ];

  const parseNumber = (value) => {
    if (typeof value === "string") {
      value = value.replace(/,/g, "");
    }
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? value : parsedValue;
  };

  const handleMultiplierChange = (event) => {
    const value = event.target.value;
    if (value === 'Thousands') {
      setMultiplier(0.001);
      setShowFigure("Thousands");
      const updatedData = data.map(row => ({
        ...row,
        ...Object.fromEntries(
          Object.entries(row).map(([key, value], index) =>
            typeof parseNumber(value) === "number" && index >= 2 ? [key, parseNumber(value) / 1000] : [key, value]
          )
        )
      }));
      setData(updatedData);
    } else if (value === 'Millions') {
      setMultiplier(0.000001);
      setShowFigure("Millions");
      const updatedData = data.map(row => ({
        ...row,
        ...Object.fromEntries(
          Object.entries(row).map(([key, value], index) =>
            typeof parseNumber(value) === "number" && index >= 2 ? [key, parseNumber(value) / 1000000] : [key, value]
          )
        )
      }));
      setData(updatedData);
    } else {
      setMultiplier(1);
      setShowFigure("Actuals");
      const updatedData = data.map(row => ({
        ...row,
        ...Object.fromEntries(
          Object.entries(row).map(([key, value], index) =>
            typeof parseNumber(value) === "number" && index >= 2 ? [key, parseNumber(value)] : [key, value]
          )
        )
      }));
      setData(updatedData);
    }
  };



  const handleMarkFigure = (event) => {
    const value = event.target.value;
    if (value === 'Thousands') {
      setMarkFigure("Thousands");
    } else if (value === 'Millions') {
      setMarkFigure("Millions");
    } else {
      setMarkFigure("Actuals");
    }

    if (initialMarkFigure !== value) {
      const data = {
        account_id: location.state?.account_id,
        mark_figure: value,
      };
      saveMarkFigureData(data, () => { }, () => { });
    }

  };


  useEffect(() => {
    if (documentsData.length > 0) {
      const fullDocumentIndex = documentsData.findIndex(
        (doc) => !doc.fileName.startsWith("page"),
      );

      setSelectedPdf(documentsData[fullDocumentIndex]);
    }
  }, [documentsData]);

  const handleChange = (event, newValue) => {
    setSelectTab(newValue);
    if (newValue === "3") {
      const fName =
        location.state?.account_id + "_" + formattedCompanyName + "_Analysis";
      setFileName(fName);
      setSheetName("Analysis");
    } else if (newValue === "2") {
      const fName =
        location.state?.account_id +
        "_" +
        formattedCompanyName +
        "_Financial_Statement";
      setFileName(fName);
      setSheetName("Financial Statement");
    } else if (newValue === "4") {
      const fName =
        location.state?.account_id +
        "_" +
        formattedCompanyName +
        "_Financial_Event_Status_Report";
      setFileName(fName);
      setSheetName("Financial Event Status Report");
    }
  };

  useEffect(() => {
    if (!Array.isArray(data)) {
      console.error("Data is not an array.");
      return;
    }

    const analysisGroup = data.filter(
      (item) => item.Class.toLowerCase() === "analysis",
    );
    const otherGroup = data.filter(
      (item) => item.Class.toLowerCase() !== "analysis",
    );

    const specificItem = analysisGroup.find(
      (item) => item["Item Description"] === "Max Project Capacity (MPC)",
    );
    if (specificItem) {
      otherGroup.push({ ...specificItem });
    }

    setAnalysisData(analysisGroup);
    setNotAnalysisData(otherGroup);
  }, [data]);

  const getValue = (itemName, columnKey, editItemValue, itemDescription) => {
    const item = data.find((item) => item["Item Description"] === itemName);

    if (item && item[columnKey] !== undefined) {
      const value = item[columnKey].toString();
      if (
        (editItemValue === "" || editItemValue !== value) &&
        itemDescription === item["Item Description"]
      ) {
        const stringValue = editItemValue.toString();
        return parseInt(stringValue.replace(/,/g, ""), 10);
      } else {
        const integerValue = parseInt(value.replace(/,/g, ""), 10);
        return integerValue;
      }
    }

    return null;
  };

  useEffect(() => {
    const data = {
      account_id: location.state?.account_id,
      company_name: location.state?.company_name,
    };
    getFinancialInitialData(data, onSuccessFinancialInitialData, () => { });
  }, []);


  useEffect(() => {
    const data = {
      account_id: location.state?.account_id,
      company_name: location.state?.company_name,
    };
    getFinancialAsGivenData(
      data,
      onSuccessFinancialInitialAsGivenData,
      () => { },
    );
  }, []);

  useEffect(() => {
    const data = {
      account_id: location.state?.account_id,
      company_name: location.state?.company_name,
    };
    getFinancialEventStatusData(
      data,
      onSuccessFinancialEventStatusData,
      () => { },
    );
  }, []);

  const onSuccessFinancialEventStatusData = (res) => {
    setEventStatusData(res);
  };

  useEffect(() => {
    const data = {
      account_id: location.state?.account_id,
      company_name: location.state?.company_name,
    };
    getFinancialDocuments(data, setDocumentsData, () => { });
  }, []);

  const onSuccessFinancialInitialAsGivenData = (res) => {
    const filteredItem = res.find(item => item["Item Description"] === "amounts_reported_in");
    if (filteredItem) {
      const firstValue = Object.values(filteredItem)[0];
      setMarkFigure(firstValue);
      setInitialMarkFigure(firstValue);
    }
    setAsGivendata(res);
    const keysArray = Object.keys(res[0]);
    const rearrangedKeys = [
      keysArray[keysArray.length - 2],
      keysArray[keysArray.length - 1],
      ...keysArray
        .slice(0, keysArray.length - 2)
        .sort()
        .reverse(),
    ];
    setDesiredColumnsAsGiven(rearrangedKeys);
  };

  const onSuccessFinancialInitialData = (res) => {
    setData(res);
    initialDataRef.current = res;
    const keysArray = Object.keys(res[0]);
    const rearrangedKeys = [
      keysArray[keysArray.length - 2],
      keysArray[keysArray.length - 1],
      ...keysArray
        .slice(0, keysArray.length - 2)
        .sort()
        .reverse(),
    ];
    setDesiredColumns(rearrangedKeys);
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
    }
  };

  useEffect(() => {
    if (file) {
      convertAndUpload(file);
    }
  }, [file]);

  const convertAndUpload = (file) => {
    const fileReader = new FileReader();
    const fileName = file.name;

    if (fileName.endsWith(".xls") || fileName.endsWith(".xlsx")) {
      fileReader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        uploadCsvData(fileName, csv);
      };
      fileReader.readAsArrayBuffer(file);
    } else if (fileName.endsWith(".csv")) {
      fileReader.onload = (event) => {
        const text = event.target.result;
        uploadCsvData(fileName, text);
      };
      fileReader.readAsText(file);
    } else {
      setNotCorrectFormat(true);
    }
  };

  const uploadCsvData = (fileName, csvData) => {
    const data = {
      account_id: location.state?.account_id,
      company_name: location.state?.company_name,
      csvData: csvData,
      fileName: fileName,
    };
    postFinancialCSVData(data, onSuccessFinancialCSVData, () => {
      setData([]);
      setDesiredColumns([]);
    });
  };

  const onSuccessFinancialCSVData = (res) => {
    setData(res);
    initialDataRef.current = res;
    const keysArray = Object.keys(res[0]);
    const rearrangedKeys = [
      keysArray[keysArray.length - 2],
      keysArray[keysArray.length - 1],
      ...keysArray
        .slice(0, keysArray.length - 2)
        .sort()
        .reverse(),
    ];
    setDesiredColumns(rearrangedKeys);
    setOpenSuccessSavePopup(true);
  };

  const editRow = (rowIndex) => {
    setEditRowIndex(rowIndex);
  };

  const handleRowClick = (rowIndex) => {
    editRow(rowIndex);
  };

  const editAnalysisRow = (rowIndex) => {
    setEditRowIndex(rowIndex);
    setEditAnalysisRowIndex(null);
    if (
      analysisData[rowIndex]["Item Description"] ===
      "Max Project Capacity (MPC)"
    ) {
      setEditAnalysisRowIndex(rowIndex);
    }
  };

  const handleAnalysisRowClick = (rowIndex) => {
    editAnalysisRow(rowIndex);
  };

  const handleClickOutsideTable = (event) => {
    if (!event.target.closest("tr")) {
      setEditRowIndex(null);
    }
  };

  const handleContextMenu = (event, rowIndex) => {
    event.preventDefault();
    setContextMenuVisible(true);
    setContextMenuPosition({ x: event.clientX, y: event.clientY });
    if (selectTab === "2") {
      setSelectedNotAnalysisRow(rowIndex);
    } else {
      setSelectedAnalysisRow(rowIndex);
    }
  };

  const handleContextMenuClose = () => {
    setContextMenuVisible(false);
    if (selectTab === "2") {
      setSelectedNotAnalysisRow(null);
    } else {
      setSelectedAnalysisRow(null);
    }
  };

  const handleDeleteRow = () => {
    if (selectTab === "2") {
      const id = selectedNotAnalysisRow;
      setNotAnalysisRowId(id);
      if (
        notAnalysisData[id] &&
        ![
          "Total Current Assets",
          "Total Assets",
          "Total Current Liabilities",
          "Total Liabilities",
          "Net Worth",
          "Total Liability and Net Worth",
          "Total Operating Revenue",
          "Total Direct Expense",
          "Gross Profit",
          "Total Overhead Expense",
          "Operating Profit",
          "Income Before Income Taxes",
          "Net Income After Tax",
          "Net cash provided (used) by Operating activities",
          "Net cash provided (used) by Financing activities",
          "Net increase (decrease) in cash and cash equivalents",
        ].includes(notAnalysisData[id]["Item Description"])
      ) {
        setOpenDeleteRowNotAnalysisPopup(true);
      }
    } else {
      const id = selectedAnalysisRow;
      setAnalysisRowId(id);
      if (
        analysisData[id] &&
        analysisData[id]["Item Description"] === "Max Project Capacity (MPC)"
      ) {
        setOpenDeleteRowAnalysisPopup(true);
      }
    }
    handleContextMenuClose();
  };

  const handleRightClick = (event, rowIndex) => {
    event.preventDefault();
    handleContextMenu(event, rowIndex);
  };

  useEffect(() => {
    document.body.addEventListener("click", handleClickOutsideTable);
    return () => {
      document.body.removeEventListener("click", handleClickOutsideTable);
    };
  }, []);

  useEffect(() => {
    const handleClickOutsideContextMenu = (event) => {
      if (!event.target.closest(".context-menu")) {
        handleContextMenuClose();
      }
    };

    document.body.addEventListener("click", handleClickOutsideContextMenu);
    return () => {
      document.body.removeEventListener("click", handleClickOutsideContextMenu);
    };
  }, []);

  const updateCalculation = (columnKey, newData, rowIndex) => {
    const editItemValue = newData[columnKey] || "";
    const NumMonths = 12;
    const itemDescription = newData["Item Description"];
    const MPC = getValue(
      "Max Project Capacity (MPC)",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const Cash = getValue("Cash", columnKey, editItemValue, itemDescription);
    const MarketableSecurities = getValue(
      "Marketable Securities",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const ARProgress = getValue(
      "Accounts Receivable (PPCs)",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const ARHoldback = getValue(
      "Accounts Receivable (Retention)",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const WorkInProgress = getValue(
      "Contract Assets",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const Inventory = getValue(
      "Inventory",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const PrepaidExpenseCurrent = getValue(
      "Prepaid Expense Current",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const OtherCurrentAssets = getValue(
      "Other Current Assets",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const TotalCurrentAssets =
      Cash +
      MarketableSecurities +
      ARProgress +
      ARHoldback +
      WorkInProgress +
      Inventory +
      PrepaidExpenseCurrent +
      OtherCurrentAssets;
    const PrepaidExpenseNonCurrent = getValue(
      "Prepaid Expense Non Current",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const Over90DayAR = getValue(
      "Accounts Receivable (over 90 Days)",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const PlantandEquipment = getValue(
      "Plant & Equipment",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const MachineryandVehicles = getValue(
      "Machinery & Vehicles",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const FurnitureandFixture = getValue(
      "Furniture & Fixture",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const RealEstate = getValue(
      "Real Estate",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const AccumulatedDepreciation = getValue(
      "(Less) Accumulated Depreciation",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const OtherNonCurrentAssets = getValue(
      "Other Non-Current Assets",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const ShareholderAdvance = getValue(
      "Shareholder Advance",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const IntangibleAssets = getValue(
      "Intangible Assets",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const AssetsWrittenOff = getValue(
      "Assets Written Off",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const TotalAssets =
      TotalCurrentAssets +
      PrepaidExpenseNonCurrent +
      Over90DayAR +
      PlantandEquipment +
      MachineryandVehicles +
      FurnitureandFixture +
      RealEstate +
      AccumulatedDepreciation +
      OtherNonCurrentAssets +
      ShareholderAdvance +
      IntangibleAssets +
      AssetsWrittenOff;
    const BankLoan = getValue(
      "Bank Loan",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const ChequesInFloat = getValue(
      "Cheques In Float",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const APProgress = getValue(
      "Accounts Payable (Progress Payments)",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const APHoldback = getValue(
      "Accounts Payable (Retention)",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const APOthers = getValue(
      "Accounts Payable (Others)",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const DeferredRevenue = getValue(
      "Deferred Revenue",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const CurrentPortionLTD = getValue(
      "Current Portion of LTD",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const EmployeeAdvances = getValue(
      "Employee Advances",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const CurrentTaxes = getValue(
      "Current Taxes Payable",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const CurrentDeferredTaxes = getValue(
      "Current Taxes Deferred",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const LoansFromShareholder = getValue(
      "Loans From Shareholder",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const OtherPayables = getValue(
      "Accrued Expenses",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const TotalCurrentLiabilities =
      BankLoan +
      ChequesInFloat +
      APProgress +
      APHoldback +
      APOthers +
      DeferredRevenue +
      CurrentPortionLTD +
      EmployeeAdvances +
      CurrentTaxes +
      CurrentDeferredTaxes +
      LoansFromShareholder +
      OtherPayables;
    const LTDEquipment = getValue(
      "LTD Equipment",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const LTDOther = getValue(
      "LTD Other",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const LTDeferredTax = getValue(
      "LT Deferred Tax",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const OtherNonCurrentDebt = getValue(
      "Other Non-Current Debt",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const MinorityInterest = getValue(
      "Minority Interest",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const TotalLiabilities =
      TotalCurrentLiabilities +
      LTDEquipment +
      LTDOther +
      LTDeferredTax +
      OtherNonCurrentDebt +
      MinorityInterest;
    const SubordinatedDebt = getValue(
      "Subordinated Debt",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const ShareCapital = getValue(
      "Share Capital",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const RetainedEarnings = getValue(
      "Retained Earnings",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const OtherEquity = getValue(
      "Other Equity",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const NetWorth = SubordinatedDebt + ShareCapital + RetainedEarnings + OtherEquity;
    const TotalLiabilityNetWorth = TotalLiabilities + NetWorth;
    const GrossContractIncome = getValue(
      "Gross Contract Income",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const ManagementFeeIncome = getValue(
      "Management Fee Income",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const OtherOperatingIncome = getValue(
      "Other Operating Income",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const IntercompanyRevenue = getValue(
      "Intercompany Revenue",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const TotalOperatingRevenue =
      GrossContractIncome +
      ManagementFeeIncome +
      OtherOperatingIncome +
      IntercompanyRevenue;
    const MaterialExpense = getValue(
      "Material Expense",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const SubcontractExpense = getValue(
      "Subcontract Expense",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const WageExpense = getValue(
      "Wage Expense",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const EquipmentExpense = getValue(
      "Equipment Expense",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const OtherDirectExpenses = getValue(
      "Other Direct Expenses",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const IntercompanyDirectExpense = getValue(
      "Intercompany Direct Expense",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const TotalDirectExpense =
      MaterialExpense +
      SubcontractExpense +
      WageExpense +
      EquipmentExpense +
      OtherDirectExpenses +
      IntercompanyDirectExpense;
    const GrossProfit = TotalOperatingRevenue - TotalDirectExpense;
    const AdminExpense = getValue(
      "Admin Expense",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const SalaryAndBonus = getValue(
      "Salary And Bonus",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const InterestExpense = getValue(
      "Interest Expense",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const Depreciation = getValue(
      "Depreciation IS",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const IntercompanyOverheadExpense = getValue(
      "Intercompany Overhead Expense",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const TotalOverheadExpense =
      AdminExpense +
      SalaryAndBonus +
      Depreciation +
      IntercompanyOverheadExpense;
    const OperatingProfit = GrossProfit - TotalOverheadExpense;
    const OtherIncome = getValue(
      "Other Income",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const DiscretionaryBonuses = getValue(
      "Discretionary Bonuses",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const IncomeTaxCurrent = getValue(
      "Income Tax Current",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const IncomeTaxDeferred = getValue(
      "Deferred Tax IS",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const IncomeBeforeIncomeTaxes =
      OperatingProfit + OtherIncome + DiscretionaryBonuses + InterestExpense;
    const NetIncomeAfterTax =
      IncomeBeforeIncomeTaxes - IncomeTaxCurrent - IncomeTaxDeferred;
    const DepreciationCF = getValue(
      "Depreciation",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const BadDebtExpense = getValue(
      "Bad Debt Expense",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const OtherNonCash = getValue(
      "Other Non Cash",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const IncomeTaxDeferredCF = getValue(
      "Deferred Tax",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const ChangeInAssets = getValue(
      "(Increase)/Decrease in assets",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const ChangeInLiabilities = getValue(
      "Increase/(Decrease) in liabilities",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const NetCashOperating =
      NetIncomeAfterTax +
      DepreciationCF +
      BadDebtExpense +
      OtherNonCash +
      IncomeTaxDeferredCF +
      ChangeInAssets +
      ChangeInLiabilities;
    const NetCashInvesting = getValue(
      "Net cash provided (used) by Investing activities",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const LoanProceeds = getValue(
      "Proceeds from loans/borrowings",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const LoanRepayment = getValue(
      "Repayment of loans/borrowings",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const Dividends = getValue(
      "Dividends/distributions to shareholders",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const OtherFinancing = getValue(
      "Other Financing",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const NetCashFinancing = LoanProceeds + LoanRepayment + Dividends + OtherFinancing;
    const NetCashFlow = NetCashOperating + NetCashInvesting + NetCashFinancing;
    const TangibleNetWorth =
      NetWorth - ShareholderAdvance - IntangibleAssets - AssetsWrittenOff;
    const WorkingCapital = TotalCurrentAssets - TotalCurrentLiabilities;
    const WorkingCapitalRatio = TotalCurrentAssets / TotalCurrentLiabilities;
    const AcidTest =
      Cash + MarketableSecurities + ARProgress - TotalCurrentLiabilities;
    const NetQuick =
      Cash +
      MarketableSecurities +
      ARProgress +
      ARHoldback +
      WorkInProgress -
      TotalCurrentLiabilities;
    const NetHoldback = ARHoldback - APHoldback;
    const WorkingCapitalVsOverhead = WorkingCapital / TotalOverheadExpense;
    const CashflowLessCPLTD = NetCashFlow - CurrentPortionLTD;
    const MarginCover =
      Cash +
      MarketableSecurities +
      ARProgress +
      ARHoldback -
      TotalCurrentLiabilities;
    const ReceivableTurnover =
      (ARProgress / ((GrossContractIncome * 12) / NumMonths)) * 365;
    const PayableTurnover =
      ((APProgress + APHoldback) / ((TotalDirectExpense * 12) / NumMonths)) *
      365;
    const InventoryTurnover =
      (MaterialExpense + SubcontractExpense + OtherDirectExpenses) /
      (ARProgress + ARHoldback + WorkInProgress + Inventory);
    const TotalDebtToEquity = TotalLiabilities / TangibleNetWorth;
    const SalesToEquity =
      (TotalOperatingRevenue * 12) / NumMonths / TangibleNetWorth;
    const SalesToWorkingCapital =
      (TotalOperatingRevenue * 12) / NumMonths / WorkingCapital;
    const GrossProfitMargin = (GrossProfit / TotalOperatingRevenue) * 100;
    const OperatingProfitMargin =
      (OperatingProfit / TotalOperatingRevenue) * 100;
    const NetCash = Cash - BankLoan - ChequesInFloat - CurrentPortionLTD;
    const AssetTurnover = GrossContractIncome / TotalAssets;
    const ReturnOnAssets = (GrossProfit / TotalAssets) * 100;
    const ClientAggLimit = getValue(
      "Max Project Capacity (MPC)",
      columnKey,
      editItemValue,
      itemDescription,
    );
    const AggLimittoTNW = ClientAggLimit / TangibleNetWorth;
    const AggLimittoWC = ClientAggLimit / WorkingCapital;

    const updatedData = [...data];
    const totalCurrentAssetsIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Total Current Assets",
    );

    if (totalCurrentAssetsIndex !== -1) {
      updatedData[totalCurrentAssetsIndex][columnKey] =
        TotalCurrentAssets.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const totalAssetsIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Total Assets",
    );
    if (totalAssetsIndex !== -1) {
      updatedData[totalAssetsIndex][columnKey] = TotalAssets.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const totalCurrentLiabilitiesIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Total Current Liabilities",
    );
    if (totalCurrentLiabilitiesIndex !== -1) {
      updatedData[totalCurrentLiabilitiesIndex][columnKey] =
        TotalCurrentLiabilities.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const totalLiabilitiesIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Total Liabilities",
    );
    if (totalLiabilitiesIndex !== -1) {
      updatedData[totalLiabilitiesIndex][columnKey] =
        TotalLiabilities.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const netWorthIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Net Worth",
    );
    if (netWorthIndex !== -1) {
      updatedData[netWorthIndex][columnKey] = NetWorth.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const totalLiabilityNetWorthIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Total Liability and Net Worth",
    );
    if (totalLiabilityNetWorthIndex !== -1) {
      updatedData[totalLiabilityNetWorthIndex][columnKey] =
        TotalLiabilityNetWorth.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const totalOperatingRevenueIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Total Operating Revenue",
    );
    if (totalOperatingRevenueIndex !== -1) {
      updatedData[totalOperatingRevenueIndex][columnKey] =
        TotalOperatingRevenue.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const totalDirectExpenseIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Total Direct Expense",
    );
    if (totalDirectExpenseIndex !== -1) {
      updatedData[totalDirectExpenseIndex][columnKey] =
        TotalDirectExpense.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const grossProfitIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Gross Profit",
    );
    if (grossProfitIndex !== -1) {
      updatedData[grossProfitIndex][columnKey] = GrossProfit.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const totalOverheadExpenseIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Total Overhead Expense",
    );
    if (totalOverheadExpenseIndex !== -1) {
      updatedData[totalOverheadExpenseIndex][columnKey] =
        TotalOverheadExpense.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const operatingProfitIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Operating Profit",
    );
    if (operatingProfitIndex !== -1) {
      updatedData[operatingProfitIndex][columnKey] = OperatingProfit.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const incomeBeforeIncomeTaxesIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Income Before Income Taxes",
    );
    if (incomeBeforeIncomeTaxesIndex !== -1) {
      updatedData[incomeBeforeIncomeTaxesIndex][columnKey] =
        IncomeBeforeIncomeTaxes.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const netIncomeAfterTaxIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Net Income After Tax",
    );
    if (netIncomeAfterTaxIndex !== -1) {
      updatedData[netIncomeAfterTaxIndex][columnKey] =
        NetIncomeAfterTax.toString();
      updatedData[netIncomeAfterTaxIndex + 1][columnKey] =
        NetIncomeAfterTax.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
      const updatedRowData2 = {
        ...updatedData[rowIndex + 1],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex + 1] = updatedRowData2;
    }

    const netCashOperatingIndex = updatedData.findIndex(
      (item) =>
        item["Item Description"] ===
        "Net cash provided (used) by Operating activities",
    );
    if (netCashOperatingIndex !== -1) {
      updatedData[netCashOperatingIndex][columnKey] =
        NetCashOperating.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const netCashFinancingIndex = updatedData.findIndex(
      (item) =>
        item["Item Description"] ===
        "Net cash provided (used) by Financing activities",
    );
    if (netCashFinancingIndex !== -1) {
      updatedData[netCashFinancingIndex][columnKey] =
        NetCashFinancing.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const netCashFlowIndex = updatedData.findIndex(
      (item) =>
        item["Item Description"] ===
        "Net increase (decrease) in cash and cash equivalents",
    );
    if (netCashFlowIndex !== -1) {
      updatedData[netCashFlowIndex][columnKey] = NetCashFlow.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const tangibleNetWorthIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Tangible Net Worth",
    );
    if (tangibleNetWorthIndex !== -1) {
      updatedData[tangibleNetWorthIndex][columnKey] =
        TangibleNetWorth.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const workingCapitalIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Working Capital",
    );
    if (workingCapitalIndex !== -1) {
      updatedData[workingCapitalIndex][columnKey] = WorkingCapital.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const workingCapitalRatioIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Working Capital Ratio",
    );
    if (workingCapitalRatioIndex !== -1) {
      updatedData[workingCapitalRatioIndex][columnKey] =
        WorkingCapitalRatio.toFixed(2).toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const acidTestIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Acid Test",
    );
    if (acidTestIndex !== -1) {
      updatedData[acidTestIndex][columnKey] = AcidTest.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const netQuickIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Net Quick",
    );
    if (netQuickIndex !== -1) {
      updatedData[netQuickIndex][columnKey] = NetQuick.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const netHoldbackIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Net Holdback",
    );
    if (netHoldbackIndex !== -1) {
      updatedData[netHoldbackIndex][columnKey] = NetHoldback.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const workingCapitalVsOverheadIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Working Capital Vs Overhead",
    );
    if (workingCapitalVsOverheadIndex !== -1) {
      updatedData[workingCapitalVsOverheadIndex][columnKey] =
        WorkingCapitalVsOverhead.toFixed(2).toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const cashflowLessCPLTDIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Cashflow (Less) CPLTD",
    );
    if (cashflowLessCPLTDIndex !== -1) {
      updatedData[cashflowLessCPLTDIndex][columnKey] =
        CashflowLessCPLTD.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const marginCoverIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Margin Cover",
    );
    if (marginCoverIndex !== -1) {
      updatedData[marginCoverIndex][columnKey] = MarginCover.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const receivableTurnoverIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Receivable Turnover",
    );
    if (receivableTurnoverIndex !== -1) {
      updatedData[receivableTurnoverIndex][columnKey] =
        ReceivableTurnover.toFixed(2).toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const payableTurnoverIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Payable Turnover",
    );
    if (payableTurnoverIndex !== -1) {
      updatedData[payableTurnoverIndex][columnKey] =
        PayableTurnover.toFixed(2).toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const inventoryTurnoverIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Inventory Turnover",
    );
    if (inventoryTurnoverIndex !== -1) {
      updatedData[inventoryTurnoverIndex][columnKey] =
        InventoryTurnover.toFixed(2).toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const totalDebtToEquityIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Total Debt To Equity",
    );
    if (totalDebtToEquityIndex !== -1) {
      updatedData[totalDebtToEquityIndex][columnKey] =
        TotalDebtToEquity.toFixed(2).toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const salesToEquityIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Sales To Equity",
    );
    if (salesToEquityIndex !== -1) {
      updatedData[salesToEquityIndex][columnKey] =
        SalesToEquity.toFixed(2).toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const salesToWorkingCapitalIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Sales To Working Capital",
    );
    if (salesToWorkingCapitalIndex !== -1) {
      updatedData[salesToWorkingCapitalIndex][columnKey] =
        SalesToWorkingCapital.toFixed(2).toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const grossProfitMarginIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Gross Profit Margin",
    );
    if (grossProfitMarginIndex !== -1) {
      updatedData[grossProfitMarginIndex][columnKey] =
        GrossProfitMargin.toFixed(2).toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const operatingProfitMarginIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Operating Profit Margin",
    );
    if (operatingProfitMarginIndex !== -1) {
      updatedData[operatingProfitMarginIndex][columnKey] =
        OperatingProfitMargin.toFixed(2).toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const netCashIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Net Cash",
    );
    if (netCashIndex !== -1) {
      updatedData[netCashIndex][columnKey] = NetCash.toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const assetTurnoverIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Asset Turnover",
    );
    if (assetTurnoverIndex !== -1) {
      updatedData[assetTurnoverIndex][columnKey] =
        AssetTurnover.toFixed(2).toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const returnOnAssetsIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "Return On Assets",
    );
    if (returnOnAssetsIndex !== -1) {
      updatedData[returnOnAssetsIndex][columnKey] =
        ReturnOnAssets.toFixed(2).toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const aggLimittoTNWIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "MPC to TNW",
    );
    if (aggLimittoTNWIndex !== -1) {
      updatedData[aggLimittoTNWIndex][columnKey] =
        AggLimittoTNW.toFixed(2).toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }

    const aggLimittoWCIndex = updatedData.findIndex(
      (item) => item["Item Description"] === "MPC to WC",
    );
    if (aggLimittoWCIndex !== -1) {
      updatedData[aggLimittoWCIndex][columnKey] =
        AggLimittoWC.toFixed(2).toString();
      const updatedRowData = {
        ...updatedData[rowIndex],
        [columnKey]: editItemValue,
      };
      updatedData[rowIndex] = updatedRowData;
    }
    setTotalAssets(TotalAssets);
    setTotalLiabilitiesNetworth(TotalLiabilityNetWorth);
    setData(updatedData);
  };

  const handleSave = () => {
    const saveData = {
      account_id: location.state?.account_id,
      company_name: location.state?.company_name,
      updateData: data,
    };

    if (totalLiabilitiesNetworth === totalAssets) {
      saveFinancialData(saveData, onSuccessSaveFinancialData, () => { });
    } else {
      setOpenAlertMessagePopup(true);
    }
  };

  const onSuccessSaveFinancialData = (res) => {
    setOpenSuccessPopup(true);
    initialDataRef.current = data;
    setEventStatusData(res);
  };

  const isDataChanged = () => {
    return JSON.stringify(data) !== JSON.stringify(initialDataRef.current);
  };

  return (
    <StyledContainer isMobile={isMobile}>
      <GoBackButton hasUnsavedChanges={isDataChanged} />
      <br />

      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", width: "50%" }}>
          <MediumText
            isMobile={isMobile}
            style={{
              fontSize: "28px",
              lineHeight: "20px",
              fontFamily: "SF Pro Text",
              color: "#1A051D",
              marginBottom: "0px",
              fontWeight: "600",
            }}
          >
            Financial Analysis
          </MediumText>
        </div>
        <div style={{ display: "flex", width: "50%" }}>
          <FunctionalButton
            buttonTitle="IMPORT"
            handleButton={() => fileInputRef.current.click()}
            width="200px"
            height="48px"
            marginRight="18px"
            isReversedColor={true}
            frontIcon={true}
            disabled={false}
            icon={<ImportIcon marginRight="16px" />}
          />
          <input
            type="file"
            style={{ display: "none" }}
            ref={fileInputRef}
            accept=".xls,.csv,.xlsx,application/vnd.ms-excel,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onClick={(e) => {
              e.target.value = null;
              fileInputRef.current.click();
            }}
            onChange={handleFileUpload}
          />

          <ExportComponent
            fileName={fileName}
            sheetName={sheetName}
            tableId="table-to-xls"
          />

          <FunctionalButton
            buttonTitle="SAVE"
            handleButton={handleSave}
            width="200px"
            height="48px"
            marginRight="0px"
            marginLeft="0px"
          />
        </div>
      </div>

      <div style={{ marginTop: "-15px" }}>
        <HeadingText>
          {location.state?.company_name} | {location.state?.account_id} &nbsp;
        </HeadingText>
      </div>

      <br />

      {data.length > 0 && (
        <SetupContainer>
          <TabContext value={selectTab}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* Left side tabs */}



              <div style={{ display: "flex", width: "100%" }}>
                <StyledTabList
                  onChange={handleChange}
                  TabIndicatorProps={{ hidden: true }} // removed fullWidth
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    gap: "8px",
                  }} // Align tabs to the left and add minimum gap
                >
                  <StyledTab
                    label=" Financial Statement As Given "
                    value="1"
                    style={{ minWidth: "fit-content" }}
                  />
                  <StyledTab
                    label=" Financial Statement As Allowed "
                    value="2"
                    style={{ minWidth: "fit-content", marginLeft: "14px" }}
                  />
                  <StyledTab
                    label=" Analysis "
                    value="3"
                    style={{ minWidth: "fit-content", marginLeft: "14px" }}
                  />
                  <StyledTab
                    label=" Event Status "
                    value="4"
                    style={{ minWidth: "fit-content", marginLeft: "14px" }}
                  />
                  <StyledTab
                    label=" Financial Documents "
                    value="5"
                    style={{ minWidth: "fit-content", marginLeft: "14px" }}
                  />
                </StyledTabList>
              </div>

              {/* Right side Download Template link */}

              {selectTab === "2" && (
                <div>
                  <label htmlFor="multiplier-select">Show Figures:</label>
                  <select id="multiplier-select" onChange={handleMultiplierChange} defaultValue={showFigure} style={{ color: "#FF6D1D", backgroundColor: "white" }}>
                    <option value="Actuals">Actuals</option>
                    <option value="Thousands">Thousands</option>
                    <option value="Millions">Millions</option>
                  </select>
                </div>
              )}

              {/* {selectTab === "1" && markFigure !== "" && (
                <div>
                  <label htmlFor="mark-figures-select">Read Figures as:</label>
                  <select id="mark-figures-select" onChange={handleMarkFigure} defaultValue={markFigure} style={{ color: "#FF6D1D", backgroundColor: "white" }}>
                    <option value="Actuals">Actuals</option>
                    <option value="Thousands">Thousands</option>
                    <option value="Millions">Millions</option>
                  </select>
                </div>
              )} */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "auto",
                  height: "22px",
                  width: "206px",
                  marginTop: "10px",
                }}
              >
                <a
                  href="/financial-statement-template-import.xlsx"
                  download="financial-statement-template-import.xlsx"
                  style={{
                    textDecoration: "none",
                    color: "#FF6D1D",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ImportIcon marginRight="8px" />
                  {"Download Template"}
                </a>
              </div>
            </div>

            <TabPanel value="1">
              {desiredColumnsAsGiven && desiredColumnsAsGiven.length > 4 && (
                <StyledConciseTableContainer length={isMobile ? 12 : 12.5}>
                  <DataTable id="table-to-xls" style={{ width: "100%" }}>
                    <thead>
                      <tr style={{ position: "sticky", top: 0, zIndex: 1 }}>
                        {desiredColumns.map((header, index) => (
                          <th
                            key={index}
                            style={{
                              backgroundColor: "#EBE9F1",
                              opacity: "75%",
                            }}
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {asGivendata &&
                        asGivendata.map((row, rowIndex) =>
                          row["Item Description"] !== 'amounts_reported_in' && (
                            <tr key={rowIndex}>
                              {desiredColumnsAsGiven.map(
                                (columnKey, columnIndex) => {
                                  const isEditable = ![
                                    "Total Current Assets",
                                    "Total Assets",
                                    "Total Current Liabilities",
                                    "Total Liabilities",
                                    "Net Worth",
                                    "Total Liability and Net Worth",
                                    "Total Operating Revenue",
                                    "Total Direct Expense",
                                    "Gross Profit",
                                    "Total Overhead Expense",
                                    "Operating Profit",
                                    "Income Before Income Taxes",
                                    "Net Income After Tax",
                                    "Net cash provided (used) by Operating activities",
                                    "Net cash provided (used) by Financing activities",
                                    "Net increase (decrease) in cash and cash equivalents",
                                  ].includes(row["Item Description"]);

                                  return (
                                    <td
                                      key={columnIndex}
                                      style={{
                                        backgroundColor: `rgba(235, 233, 241, ${rowIndex % 2 === 0 ? "0.25" : "0.50"})`,
                                        textAlign:
                                          columnIndex >= 2 ? "right" : "left",
                                        padding: "4px",
                                        color:
                                          !isEditable && columnIndex < 2
                                            ? "#000000"
                                            : "inherit",
                                        fontWeight:
                                          !isEditable && columnIndex < 2
                                            ? "bold"
                                            : "normal",
                                      }}
                                    >
                                      {!isEditable && columnIndex >= 2 ? (
                                        <strong style={{ color: "#000000" }}>
                                          {NumberFormatOnly(parseNumber(row[columnKey]))}
                                        </strong>
                                      ) : (
                                        columnIndex < 2 ? row[columnKey] : NumberFormatOnly(parseNumber(row[columnKey]))
                                      )}
                                    </td>
                                  );
                                },
                              )}
                            </tr>
                          ))}
                    </tbody>
                  </DataTable>
                </StyledConciseTableContainer>
              )}

              {desiredColumnsAsGiven && desiredColumnsAsGiven.length <= 4 && (
                <DataTable id="table-to-xls" style={{ width: "100%" }}>
                  <thead>
                    <tr style={{ position: "sticky", top: 0, zIndex: 1 }}>
                      {asGivendata.length > 0 &&
                        desiredColumnsAsGiven.map((header, index) => (
                          <th
                            key={index}
                            style={{
                              backgroundColor: "#EBE9F1",
                              opacity: "75%",
                            }}
                          >
                            {header}
                          </th>
                        ))}
                    </tr>
                  </thead>

                  <tbody>
                    {asGivendata &&
                      asGivendata.map((row, rowIndex) =>
                        row["Item Description"] !== 'amounts_reported_in' && (
                          <tr key={rowIndex}>
                            {desiredColumnsAsGiven.map(
                              (columnKey, columnIndex) => {
                                const isEditable = ![
                                  "Total Current Assets",
                                  "Total Assets",
                                  "Total Current Liabilities",
                                  "Total Liabilities",
                                  "Net Worth",
                                  "Total Liability and Net Worth",
                                  "Total Operating Revenue",
                                  "Total Direct Expense",
                                  "Gross Profit",
                                  "Total Overhead Expense",
                                  "Operating Profit",
                                  "Income Before Income Taxes",
                                  "Net Income After Tax",
                                  "Net cash provided (used) by Operating activities",
                                  "Net cash provided (used) by Financing activities",
                                  "Net increase (decrease) in cash and cash equivalents",
                                ].includes(row["Item Description"]);

                                return (
                                  <td
                                    key={columnIndex}
                                    style={{
                                      backgroundColor: `rgba(235, 233, 241, ${rowIndex % 2 === 0 ? "0.25" : "0.50"})`,
                                      textAlign:
                                        columnIndex >= 2 ? "right" : "left",
                                      padding: "4px",
                                      color:
                                        !isEditable && columnIndex < 2
                                          ? "#000000"
                                          : "inherit",
                                      fontWeight:
                                        !isEditable && columnIndex < 2
                                          ? "bold"
                                          : "normal",
                                    }}
                                  >
                                    {!isEditable && columnIndex >= 2 ? (
                                      <strong style={{ color: "#000000" }}>
                                        {NumberFormatOnly(parseNumber(row[columnKey]))}
                                      </strong>
                                    ) : (
                                      columnIndex < 2 ? row[columnKey] : NumberFormatOnly(parseNumber(row[columnKey]))
                                    )}
                                  </td>
                                );
                              },
                            )}
                          </tr>
                        ))}
                  </tbody>
                </DataTable>
              )}
            </TabPanel>

            <TabPanel value="2">
              {desiredColumns && desiredColumns.length > 4 && (
                <StyledConciseTableContainer length={isMobile ? 12 : 12.5}>
                  <DataTable id="table-to-xls" style={{ width: "100%" }}>
                    <thead>
                      <tr style={{ position: "sticky", top: 0, zIndex: 1 }}>
                        {notAnalysisData.length > 0 &&
                          desiredColumns.map((header, index) => (
                            <th
                              key={index}
                              style={{
                                backgroundColor: "#EBE9F1",
                                opacity: "75%",
                              }}
                            >
                              {header}
                            </th>
                          ))}
                      </tr>
                    </thead>

                    <tbody>
                      {notAnalysisData &&
                        notAnalysisData.map((row, rowIndex) => (
                          <tr
                            key={rowIndex}
                            onClick={() => handleRowClick(rowIndex)}
                            onContextMenu={(event) =>
                              handleRightClick(event, rowIndex)
                            }
                          >
                            {desiredColumns.map((columnKey, columnIndex) => {
                              const isEditable = ![
                                "Total Current Assets",
                                "Total Assets",
                                "Total Current Liabilities",
                                "Total Liabilities",
                                "Net Worth",
                                "Total Liability and Net Worth",
                                "Total Operating Revenue",
                                "Total Direct Expense",
                                "Gross Profit",
                                "Total Overhead Expense",
                                "Operating Profit",
                                "Income Before Income Taxes",
                                "Net Income After Tax",
                                "Net cash provided (used) by Operating activities",
                                "Net cash provided (used) by Financing activities",
                                "Net increase (decrease) in cash and cash equivalents",
                              ].includes(row["Item Description"]);

                              return (
                                <td
                                  key={columnIndex}
                                  style={{
                                    backgroundColor: `rgba(235, 233, 241, ${rowIndex % 2 === 0 ? "0.25" : "0.50"})`,
                                    textAlign:
                                      columnIndex >= 2 ? "right" : "left",
                                    padding: "4px",
                                    color:
                                      !isEditable && columnIndex < 2
                                        ? "#0000FF"
                                        : "inherit",
                                    fontWeight:
                                      !isEditable && columnIndex < 2
                                        ? "bold"
                                        : "normal",
                                    position: 'relative',
                                  }}
                                >
                                  {!isEditable && columnIndex >= 2 ? (
                                    <strong style={{ color: "#0000FF" }}>
                                      {NumberFormatOnly(parseNumber(row[columnKey]) * (columnIndex >= 2 ? multiplier : 1))}
                                    </strong>
                                  ) : isEditable &&
                                    editRowIndex === rowIndex &&
                                    columnIndex >= 2 ? (
                                    <>
                                      <input
                                        type="text"
                                        value={row[columnKey]}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          boxSizing: "border-box",
                                          border: "2px solid #FF6D1D",
                                        }}
                                        onChange={(e) => {
                                          const newData = [...data];
                                          newData[rowIndex] = {
                                            ...newData[rowIndex],
                                            [columnKey]: NumberFormatOnly(
                                              e.target.value,
                                            ),
                                          };
                                          updateCalculation(
                                            columnKey,
                                            newData[rowIndex],
                                            editRowIndex,
                                          );
                                          setData(newData);
                                          setShowAlert(true);
                                          setTimeout(() => setShowAlert(false), 3000);
                                        }}
                                      />
                                      {showAlert && (
                                        <div style={{
                                          position: 'absolute',
                                          bottom: '100%', // Place it above the input
                                          left: '0',
                                          backgroundColor: '#fff',
                                          color: '#FF6D1D',
                                          border: '1px solid #ccc',
                                          padding: '4px',
                                          zIndex: 100,
                                          display: 'block',
                                          visibility: 'visible', // Always show for demonstration; you can conditionally render it
                                        }}>
                                          Please write only actual value.
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    columnIndex >= 2
                                      ? NumberFormatOnly(parseNumber(row[columnKey]) * multiplier)
                                      : row[columnKey]
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                    </tbody>
                  </DataTable>
                </StyledConciseTableContainer>
              )}

              {desiredColumns && desiredColumns.length <= 4 && (
                <DataTable id="table-to-xls" style={{ width: "100%" }}>
                  <thead>
                    <tr style={{ position: "sticky", top: 0, zIndex: 1 }}>
                      {notAnalysisData.length > 0 &&
                        desiredColumns.map((header, index) => (
                          <th
                            key={index}
                            style={{
                              backgroundColor: "#EBE9F1",
                              opacity: "75%",
                            }}
                          >
                            {header}
                          </th>
                        ))}
                    </tr>
                  </thead>

                  <tbody>
                    {notAnalysisData &&
                      notAnalysisData.map((row, rowIndex) => (
                        <tr
                          key={rowIndex}
                          onClick={() => handleRowClick(rowIndex)}
                          onContextMenu={(event) =>
                            handleRightClick(event, rowIndex)
                          }
                        >
                          {desiredColumns.map((columnKey, columnIndex) => {
                            const isEditable = ![
                              "Total Current Assets",
                              "Total Assets",
                              "Total Current Liabilities",
                              "Total Liabilities",
                              "Net Worth",
                              "Total Liability and Net Worth",
                              "Total Operating Revenue",
                              "Total Direct Expense",
                              "Gross Profit",
                              "Total Overhead Expense",
                              "Operating Profit",
                              "Income Before Income Taxes",
                              "Net Income After Tax",
                              "Net cash provided (used) by Operating activities",
                              "Net cash provided (used) by Financing activities",
                              "Net increase (decrease) in cash and cash equivalents",
                            ].includes(row["Item Description"]);

                            return (
                              <td
                                key={columnIndex}
                                style={{
                                  backgroundColor: `rgba(235, 233, 241, ${rowIndex % 2 === 0 ? "0.25" : "0.50"})`,
                                  textAlign:
                                    columnIndex >= 2 ? "right" : "left",
                                  padding: "4px",
                                  color:
                                    !isEditable && columnIndex < 2
                                      ? "#0000FF"
                                      : "inherit",
                                  fontWeight:
                                    !isEditable && columnIndex < 2
                                      ? "bold"
                                      : "normal",
                                  position: 'relative',
                                }}
                              >
                                {!isEditable && columnIndex >= 2 ? (
                                  <strong style={{ color: "#0000FF" }}>
                                    {NumberFormatOnly(parseNumber(row[columnKey]) * (columnIndex >= 2 ? multiplier : 1))}
                                  </strong>
                                ) : isEditable &&
                                  editRowIndex === rowIndex &&
                                  columnIndex >= 2 ? (
                                  <>
                                    <input
                                      type="text"
                                      value={row[columnKey]}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        boxSizing: "border-box",
                                        border: "2px solid #FF6D1D",
                                      }}
                                      onChange={(e) => {
                                        const newData = [...data];
                                        newData[rowIndex] = {
                                          ...newData[rowIndex],
                                          [columnKey]: NumberFormatOnly(
                                            e.target.value,
                                          ),
                                        };
                                        updateCalculation(
                                          columnKey,
                                          newData[rowIndex],
                                          editRowIndex,
                                        );
                                        setData(newData);
                                        setShowAlert(true);
                                        setTimeout(() => setShowAlert(false), 3000);
                                      }}
                                    />
                                    {showAlert && (
                                      <div style={{
                                        position: 'absolute',
                                        bottom: '100%', // Place it above the input
                                        left: '0',
                                        backgroundColor: '#fff',
                                        color: '#FF6D1D',
                                        border: '1px solid #ccc',
                                        padding: '4px',
                                        zIndex: 100,
                                        display: 'block',
                                        visibility: 'visible', // Always show for demonstration; you can conditionally render it
                                      }}>
                                        Please write only actual value.
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  columnIndex >= 2
                                    ? NumberFormatOnly(parseNumber(row[columnKey]) * multiplier)
                                    : row[columnKey]
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                  </tbody>
                </DataTable>
              )}
            </TabPanel>

            <TabPanel value="3">
              {desiredColumns && desiredColumns.length > 4 && (
                <StyledConciseTableContainer length={isMobile ? 12 : 12.5}>
                  <DataTable id="table-to-xls" style={{ width: "100%" }}>
                    <thead>
                      <tr style={{ position: "sticky", top: 0, zIndex: 1 }}>
                        {analysisData.length > 0 &&
                          desiredColumns.map((header, index) => (
                            <th
                              key={index}
                              style={{
                                backgroundColor: "#EBE9F1",
                                opacity: "75%",
                              }}
                            >
                              {header}
                            </th>
                          ))}
                      </tr>
                    </thead>

                    <tbody>
                      {analysisData &&
                        analysisData.map((row, rowIndex) => (
                          <tr
                            key={rowIndex}
                            onClick={() => handleAnalysisRowClick(rowIndex)}
                            onContextMenu={(event) =>
                              handleRightClick(event, rowIndex)
                            }
                          >
                            {desiredColumns.map((columnKey, columnIndex) => (
                              <td
                                key={columnIndex}
                                style={{
                                  backgroundColor: `rgba(235, 233, 241, ${rowIndex % 2 === 0 ? "0.25" : "0.50"})`,
                                  textAlign:
                                    columnIndex >= 2 ? "right" : "left",
                                  padding: "4px",
                                }}
                              >
                                {
                                  [
                                    "Gross Profit Margin",
                                    "Operating Profit Margin",
                                    "Return On Assets",
                                  ].includes(row["Item Description"]) &&
                                    columnIndex >= 2 ? (
                                    <span style={{ color: "#0000FF" }}>
                                      {row[columnKey]}%
                                    </span>
                                  ) : columnIndex >= 2 && multiplierItems.includes(row["Item Description"]) ? (
                                    <span style={{ color: "#0000FF" }}>
                                      {NumberFormatOnly(parseNumber(row[columnKey]) * multiplier)}
                                    </span>
                                  ) : (
                                    <span style={{ color: "#0000FF" }}>
                                      {row[columnKey]}
                                    </span>
                                  )
                                }
                              </td>
                            ))}
                          </tr>
                        ))}
                    </tbody>
                  </DataTable>
                </StyledConciseTableContainer>
              )}

              {desiredColumns && desiredColumns.length <= 4 && (
                <DataTable id="table-to-xls" style={{ width: "100%" }}>
                  <thead>
                    <tr style={{ position: "sticky", top: 0, zIndex: 1 }}>
                      {analysisData.length > 0 &&
                        desiredColumns.map((header, index) => (
                          <th
                            key={index}
                            style={{
                              backgroundColor: "#EBE9F1",
                              opacity: "75%",
                            }}
                          >
                            {header}
                          </th>
                        ))}
                    </tr>
                  </thead>

                  <tbody>
                    {analysisData &&
                      analysisData.map((row, rowIndex) => (
                        <tr
                          key={rowIndex}
                          onClick={() => handleAnalysisRowClick(rowIndex)}
                          onContextMenu={(event) => handleRightClick(event, rowIndex)}
                        >
                          {desiredColumns.map((columnKey, columnIndex) => (
                            <td
                              key={columnIndex}
                              style={{
                                backgroundColor: `rgba(235, 233, 241, ${rowIndex % 2 === 0 ? "0.25" : "0.50"})`,
                                textAlign: columnIndex >= 2 ? "right" : "left",
                                padding: "4px",
                              }}
                            >
                              {["Gross Profit Margin", "Operating Profit Margin", "Return On Assets"].includes(row["Item Description"]) && columnIndex >= 2 ? (
                                <span style={{ color: "#0000FF" }}>
                                  {row[columnKey]}%
                                </span>
                              ) : columnIndex >= 2 && multiplierItems.includes(row["Item Description"]) ? (
                                <span style={{ color: "#0000FF" }}>
                                  {NumberFormatOnly(parseNumber(row[columnKey]) * multiplier)}
                                </span>
                              ) : (
                                <span style={{ color: "#0000FF" }}>
                                  {row[columnKey]}
                                </span>
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </DataTable>
              )}
            </TabPanel>

            <TabPanel value="4">
              <StyledConciseTableContainer length={isMobile ? 12 : 12.5}>
                {eventStatusData.length > 0 && (
                  <DataTable id="table-to-xls" style={{ width: "100%" }}>
                    <thead>
                      <tr
                        style={{ backgroundColor: "#EBE9F1", opacity: "75%" }}
                      >
                        <th>User ID</th>
                        <th>Account ID</th>
                        <th>Institution Name</th>
                        <th>Updated At</th>
                        {/* <th>Accountant Or Auditor Name</th> */}
                        <th>Event Type</th>
                        <th>File Name</th>
                      </tr>
                    </thead>

                    <tbody>
                      {eventStatusData &&
                        eventStatusData.map((item, index) => (
                          <tr
                            key={index}
                            style={{
                              backgroundColor: `rgba(235, 233, 241, ${index % 2 === 0 ? "0.25" : "0.50"})`,
                            }}
                          >
                            <td>{item.UserId}</td>
                            <td>{item.AccountID}</td>
                            <td>{item.InstituteName}</td>
                            <td>{new Date(item.UpdatedAt).toLocaleString()}</td>
                            {/* <td>{item.AccountantOrAuditorName}</td> */}
                            <td>{item.EventType}</td>
                            <td>{item.FileName || "-"}</td>
                          </tr>
                        ))}
                    </tbody>
                  </DataTable>
                )}

                {eventStatusData.length === 0 && (
                  <StyledBox boxHeight="340px">
                    <PlaceholderText>
                      No Financial Event Status Data Available.
                    </PlaceholderText>
                  </StyledBox>
                )}
              </StyledConciseTableContainer>
            </TabPanel>

            <TabPanel value="5">
              {documentsData.length !== 0 ? (
                <StyledConciseTableContainer length={isMobile ? 12 : 12.5}>
                  <div style={{ display: "flex", height: "100%" }}>
                    {/* Left side: List of PDFs */}
                    <div
                      style={{
                        width: "19%",
                        overflowY: "auto",
                        padding: "0px",
                      }}
                    >
                      {documentsData
                        .filter((doc) => !doc.fileName.startsWith("page"))
                        .map((pdfFile, index) => (
                          <div
                            key={index}
                            style={{
                              padding: "5px",
                              cursor: "pointer",
                              backgroundColor:
                                selectedPdf === pdfFile
                                  ? "#f0f0f0"
                                  : "transparent",
                              color:
                                selectedPdf === pdfFile ? "orange" : "black",
                              borderRadius: "4px",
                              marginBottom: "5px",
                            }}
                            onClick={() => setSelectedPdf(pdfFile)}
                          >
                            {pdfFile.fileName || `Document ${index + 1}`}
                          </div>
                        ))}
                    </div>

                    <div
                      style={{
                        width: "1%",
                        overflowY: "auto",
                        padding: "5px",
                        borderRight: "2px solid #ccc",
                      }}
                    ></div>

                    {/* Right side: PDF Viewer */}
                    <div style={{ width: "80%", padding: "5px" }}>
                      {selectedPdf ? (
                        <iframe
                          src={URL.createObjectURL(
                            new Blob([selectedPdf.blob], {
                              type: "application/pdf",
                            }),
                          )}
                          title="PDF Viewer"
                          width="800px"
                          height="800px"
                        />
                      ) : (
                        <PlaceholderText>Select a PDF to view.</PlaceholderText>
                      )}
                    </div>
                  </div>
                </StyledConciseTableContainer>
              ) : (
                <StyledBox boxHeight="340px">
                  <PlaceholderText>Loading PDF...</PlaceholderText>
                </StyledBox>
              )}
            </TabPanel>
          </TabContext>
        </SetupContainer>
      )}

      {data.length === 0 && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "auto",
              height: "22px",
              width: "206px",
              marginTop: "0px",
              marginBottom: "20px",
            }}
          >
            <a
              href="/financial-statement-template-import.xlsx"
              download="financial-statement-template-import.xlsx"
              style={{
                textDecoration: "none",
                color: "#FF6D1D",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ImportIcon marginRight="8px" />
              {"Download Template"}
            </a>
          </div>

          <StyledBox boxHeight="480px">
            <TitleDiv>{icon}</TitleDiv>
            <MediumText
              isMobile={isMobile}
              style={{
                fontSize: "20px",
                textAlign: "center",
                lineHeight: "20px",
                fontFamily: "SF Pro Text",
                color: "#1A051D",
                marginTop: "16px",
                marginBottom: "16px",
                fontWeight: "500",
              }}
            >
              No Financial Data Available.
            </MediumText>
            <PlaceholderText>
              Please upload a pdf document through IDP feature or
            </PlaceholderText>
            <PlaceholderText>
              {" "}
              import a csv file using the button above to see data here.
            </PlaceholderText>
          </StyledBox>
        </>
      )}

      {contextMenuVisible && (
        <div
          className="context-menu"
          style={{
            position: "fixed",
            top: contextMenuPosition.y,
            left: contextMenuPosition.x,
            backgroundColor: "#FF6D1D",
            color: "white",
            border: "2px solid #ccc",
            boxShadow: "2px 2px 5px rgba(0,0,0,0.2)",
          }}
        >
          {selectTab === "2" &&
            notAnalysisData[selectedNotAnalysisRow] &&
            ![
              "Total Current Assets",
              "Total Assets",
              "Total Current Liabilities",
              "Total Liabilities",
              "Net Worth",
              "Total Liability and Net Worth",
              "Total Operating Revenue",
              "Total Direct Expense",
              "Gross Profit",
              "Total Overhead Expense",
              "Operating Profit",
              "Income Before Income Taxes",
              "Net Income After Tax",
              "Net cash provided (used) by Operating activities",
              "Net cash provided (used) by Financing activities",
              "Net increase (decrease) in cash and cash equivalents",
            ].includes(
              notAnalysisData[selectedNotAnalysisRow]["Item Description"],
            ) && (
              <div onClick={handleDeleteRow} style={{ margin: "10px" }}>
                Delete Row
              </div>
            )}
          {selectTab === "2" &&
            analysisData[selectedAnalysisRow] &&
            analysisData[selectedAnalysisRow]["Item Description"] ===
            "Max Project Capacity (MPC)" && (
              <div onClick={handleDeleteRow} style={{ margin: "10px" }}>
                Delete Row
              </div>
            )}
        </div>
      )}

      {openDeleteRowNotAnalysisPopup === true && (
        <DeleteRow
          isMobile={isMobile}
          data={notAnalysisData}
          openPopup={openDeleteRowNotAnalysisPopup}
          setOpenPopup={setOpenDeleteRowNotAnalysisPopup}
          setData={setNotAnalysisData}
          selectedRow={notAnalysisRowId}
        />
      )}

      {openDeleteRowAnalysisPopup === true && (
        <DeleteRow
          isMobile={isMobile}
          data={analysisData}
          openPopup={openDeleteRowAnalysisPopup}
          setOpenPopup={setOpenDeleteRowAnalysisPopup}
          setData={setAnalysisData}
          selectedRow={analysisRowId}
        />
      )}

      <SuccessPopup
        setOpenPopup={setOpenSuccessPopup}
        openPopup={openSuccessPopup}
        isMobile={isMobile}
      />

      <SuccessPopup
        setOpenPopup={setOpenSuccessSavePopup}
        openPopup={openSuccessSavePopup}
        descriptionText="Your information has been saved."
        isMobile={isMobile}
      />

      <AlertMessage
        isMobile={isMobile}
        openPopup={openAlertMessagePopup}
        setOpenPopup={setOpenAlertMessagePopup}
      />
    </StyledContainer>
  );
};

export default FinancialAnalysis;
