import React from "react";
import InputMaterial from "@material-ui/core/Input";
import Dragfield from "../../../../assets/preQualifyIcons/temp_card.js";
import { StyledDragfieldArea, StyledLabel } from "./styles";

export const UploadArea = ({
  setFile,
  width,
  height,
  margin,
  inputFile,
  multiple,
}) => {
  const clickUploadFile = () => {
    inputFile.current.click();
  };

  return (
    <StyledLabel margin={margin}>
      <input
        type="file"
        //multiple={multiple}
        style={{ display: "none" }}
        ref={inputFile}
        accept=".pdf,.doc,.docx,.jpg,.jpeg,.png, application/pdf, application/msword,image/jpeg,image/png"
        onClick={(e) => {
          e.target.value = null;
          clickUploadFile();
        }}
        onChange={(e) => {
          if (
            e.target.files[0].type === "application/pdf" ||
            e.target.files[0].type === "application/msword" ||
            e.target.files[0].type === "image/jpeg" ||
            e.target.files[0].type === "image/png" ||
            e.target.files[0].type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            if (multiple) {
              setFile(e.target.files);
            } else {
              setFile(e.target.files[0]);
            }
          }
        }}
      />
      <StyledDragfieldArea width={width} height={height}>
        <Dragfield />
      </StyledDragfieldArea>
    </StyledLabel>
  );
};
