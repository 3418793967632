import React, { useState, useEffect, useRef } from "react";
import {
  FormContainer,
  FormTitle,
  StyledLargeTitle,
  StyledMediumTitle,
} from "../styles";
import { UploadArea } from '../components/uploadArea';
import { FunctionalButton } from "../../../components/functionalButton";
import { SafetyRecords} from './SafetyRecords';
import { UploadedFileDisplay } from '../components/uploadedFileDisplay';

import {
  StyledButtonRow,
  StyledUploadText,
  StyledUploadArea,
  StyledFileDisplay,
  StyledSpan,
  StyledTitle,
} from './styles';

export const HeathAndSafetyForm = ({
  setActiveStep,
  qualificationData,
  setQualificationData,
  setIsLastPanel,
  setPreviousStepPanel,
 }) => {
  setIsLastPanel(true);
  setPreviousStepPanel(1);

  const [haveCOR, setHaveCOR] = useState(qualificationData ? qualificationData.years?.currentCOR : '');
  const [fileCOR, setFileCOR] = useState (qualificationData ? qualificationData.years?.CORfileOrEquivalent : '');
  const [disabledYesButton, setDisabledYesButton] = useState((typeof haveCOR == "boolean")? (haveCOR? false : true) : true);
  const [disabledNoButton, setDisabledNoButton] = useState((typeof haveCOR == "boolean")? (haveCOR? true : false) : true);
  const [uploadTitle, setUploadTitle] = useState('');
  const [hasAnnualRecords, setHasAnnualRecords] = useState ('');
  const inputFile = useRef(null)

  useEffect (() => {
    if (fileCOR) {
      setQualificationData('years.CORfileOrEquivalent', fileCOR);
    }
  }, [fileCOR])

  useEffect (() => {
    setQualificationData('years.currentCOR', haveCOR);
    if(haveCOR) {
      setUploadTitle('Please upload a version of your COR\u2122');
    } else {
      setUploadTitle('Please upload Equivalent to COR or attach details that demonstrate the effectiveness of your health and safety plan');
    }
  }, [haveCOR])

  const handleYes = () => {
    setHaveCOR(true)
    if(disabledYesButton){
      setDisabledYesButton(!disabledYesButton);
    }
    if (!disabledNoButton) {
      setDisabledNoButton(true);
    }
  }

  const handleNo = () => {
    setHaveCOR(false)
    if (disabledNoButton){
      setDisabledNoButton(!disabledNoButton);
    }
    if (!disabledYesButton) {
      setDisabledYesButton(true);
    }
  }

  const handleButton = () => {
    setActiveStep(4);
  }

  const deleteFile = () => {
    setFileCOR(null);
  }

  return (
    <FormContainer>
      <StyledLargeTitle>Health &#38; Safety</StyledLargeTitle>
      <StyledMediumTitle>Do you have a Certificate of Recognition program (COR&trade;)?</StyledMediumTitle>
        <StyledButtonRow>
          <FunctionalButton
            buttonTitle="Yes"
            width="138px"
            height="40px"
            marginRight="16px"
            isReversedColor={disabledYesButton}
            handleButton={handleYes}
          />
          <FunctionalButton
            buttonTitle="No"
            width="138px"
            height="40px"
            isReversedColor={disabledNoButton}
            handleButton={handleNo}
          />
        </StyledButtonRow>
      {uploadTitle && !fileCOR && (
        <StyledUploadArea>
          <StyledUploadText>{uploadTitle}</StyledUploadText>
          <UploadArea
            setFile={setFileCOR}
            width="100%"
            height="186px"
            inputFile={inputFile}
          />
        </StyledUploadArea>
      )}
      { fileCOR && (
        <StyledFileDisplay>
          <FormTitle>Uploaded file</FormTitle>
          <UploadedFileDisplay
            file={fileCOR}
            displayPercentage="100%"
            deleteFile={deleteFile}
            marginTop="16px"
          />
        </StyledFileDisplay>
      )}
      { !fileCOR && !disabledNoButton && (
        <StyledSpan
          // onClick={() => { setActiveStep(4); }}
        >
        I dont have an equivalent to COR
        </StyledSpan>
      )}
      <StyledTitle>Annual Safety Records</StyledTitle>
      <SafetyRecords
        data={qualificationData}
        setCollectedData={setQualificationData}
        setHasAnnualRecords={setHasAnnualRecords}
      />
    </FormContainer>
  );
};
