import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { ScreenRoot } from '../styles';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Tooltip from "@mui/material/Tooltip";

import {
  DarkTitle,
  TitleText,
  TitleDiv,
  PopupContainer,
  PointText,
  ErrorText,
  StyledPylon,
  ErrorDiv,
  StyledAwardsArea,
  StyledBoxContent,
  TitleTextRating
} from './styles'
import { CloseButton } from "components/closeButton";
import { FunctionalButton } from 'components/functionalButton';
import { COLORS } from "../../../constants";
import { givenReference } from 'api/reference';
import { useParams } from "react-router";
import PylonIcon from "assets/common/pylonIcon.png";
import QualityIcon from "assets/reference/quality";
import EfficientIcon from "assets/reference/efficient";
import OrganizedIcon from "assets/reference/organized";
import ServiceIcon from "assets/reference/service";
import AdministrationIcon from "assets/reference/administration";



function ReferenceGiverScreen() {
  const { token } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [data, setData] = useState({});
  const [valid, setValid] = useState(false);
  const [error, setError] = useState("");

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      border: "1px solid black",
      background: "white"
    },
    '&:hover': {
      cursor: 'pointer',
    },
    marginRight: 12
  }));

  useEffect(() => {
    givenReference(
      token,
      (res) => { setData(res); setValid(true) },
      (err) => { setValid(false); setError(err.data.message); }
    )
  }, [])


  const changeUrl = () => {
    window.location.replace("https://pledgx.com")
  };


  return (
    <ScreenRoot>
      {valid && (
        <>
          <CloseButton handleClose={changeUrl} />
          <PopupContainer>
            <TitleDiv>
              <DarkTitle>You gave a reference on</DarkTitle>
              <DarkTitle><PointText>{data?.company_name}</PointText> on {data?.updated_at}</DarkTitle>
            </TitleDiv>
            <TitleText fontSize="18px" fontWeight="600" color="black" margin="0px 0px 6px">{data?.project_name}</TitleText>
            <TitleText fontSize="18px">{data?.project_location}</TitleText>
            <TitleTextRating fontSize="24px" fontWeight="500" color={COLORS.blurOutText} margin="-38px 0px 0px 0px"><PointText>{data?.average_rating}</PointText> /10</TitleTextRating>
            <TitleText fontSize="24px" fontWeight="500" color={COLORS.blurOutText} margin="8px 0px 16px">Rating</TitleText>
            {data?.assessment?.scale?.length > 0 &&
              data?.assessment?.scale.map((each) => {
                return (
                  <>
                    <TitleText fontSize="18px" fontWeight="400" color="black">{each.question}</TitleText>

                    {each.point === true &&
                      <TitleText fontSize="18px" fontWeight="500" color={COLORS.blurOutText}>N/A</TitleText>
                    }

                    {each.point !== true &&
                      <TitleText fontSize="24px" fontWeight="500" color={COLORS.blurOutText}><PointText>{each.point}</PointText> /10</TitleText>
                    }

                  </>

                )
              })
            }

            <TitleText fontSize="24px" fontWeight="500" color={COLORS.blurOutText} margin="8px 0px 16px">Award</TitleText>
            <StyledAwardsArea>
              <StyledBoxContent margin="16px 32px 24px">
                {data?.current_award === "quality" && (
                  <Tooltip
                    title="Quality"
                    placement="bottom-end"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: 11,
                          bgcolor: `${COLORS.primaryOrange}`,
                          "& .MuiTooltip-arrow": {
                            color: `${COLORS.primaryOrange}`,
                          },
                        },
                      },
                    }}
                  >
                    <StyledBadge overlap="circular">
                      <QualityIcon width={65} height={65} noGreyFilter={true} />
                    </StyledBadge>
                  </Tooltip>
                )}
                {data?.current_award === "efficiency" && (
                  <Tooltip
                    title="Efficiency"
                    placement="bottom-end"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: 11,
                          bgcolor: `${COLORS.primaryOrange}`,
                          "& .MuiTooltip-arrow": {
                            color: `${COLORS.primaryOrange}`,
                          },
                        },
                      },
                    }}
                  >
                    <StyledBadge overlap="circular">
                      <EfficientIcon width={65} height={65} noGreyFilter={true} />
                    </StyledBadge>
                  </Tooltip>
                )}
                {data?.current_award === "organization" && (
                  <Tooltip
                    title="Organization"
                    placement="bottom-end"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: 11,
                          bgcolor: `${COLORS.primaryOrange}`,
                          "& .MuiTooltip-arrow": {
                            color: `${COLORS.primaryOrange}`,
                          },
                        },
                      },
                    }}
                  >
                    <StyledBadge overlap="circular">
                      <OrganizedIcon width={65} height={65} noGreyFilter={true} />
                    </StyledBadge>
                  </Tooltip>
                )}
                {data?.current_award === "service" && (
                  <Tooltip
                    title="Service"
                    placement="bottom-end"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: 11,
                          bgcolor: `${COLORS.primaryOrange}`,
                          "& .MuiTooltip-arrow": {
                            color: `${COLORS.primaryOrange}`,
                          },
                        },
                      },
                    }}
                  >
                    <StyledBadge overlap="circular">
                      <ServiceIcon width={65} height={65} noGreyFilter={true} />
                    </StyledBadge>
                  </Tooltip>
                )}
                {data?.current_award === "administration" && (
                  <Tooltip
                    title="Administration"
                    placement="bottom-end"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: 11,
                          bgcolor: `${COLORS.primaryOrange}`,
                          "& .MuiTooltip-arrow": {
                            color: `${COLORS.primaryOrange}`,
                          },
                        },
                      },
                    }}
                  >
                    <StyledBadge overlap="circular">
                      <AdministrationIcon width={65} height={65} noGreyFilter={true} />
                    </StyledBadge>
                  </Tooltip>
                )}
              </StyledBoxContent>
            </StyledAwardsArea>

            <TitleText fontSize="24px" fontWeight="500" color={COLORS.blurOutText} margin="16px 0px">Comment</TitleText>
            <TitleText fontSize="14px" color="black" fontWeight="400">{data?.assessment?.comment}</TitleText>
            <br />
            <FunctionalButton
              buttonTitle="Go to PledgX website"
              handleButton={changeUrl}
              isReversedColor={true}
              width="20%"
            />
            <br /> <br />
          </PopupContainer>
        </>
      )}
      {!valid && (
        <ErrorDiv isMobile={isMobile}>
          <StyledPylon src={PylonIcon} isMobile={isMobile} />
          <ErrorText isMobile={isMobile}>{error}</ErrorText>
        </ErrorDiv>
      )}
    </ScreenRoot>
  )
}

export default ReferenceGiverScreen;