import React, { useState, useEffect, useRef } from "react";
// import WebViewer from '@pdftron/webviewer';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  FormContainer,
  StyledLargeTitle,
  StyledPdfViewerContainer,
} from "../styles";

import { UploadedFileDisplay } from '../components/uploadedFileDisplay';
import SystemUpdateAltRoundedIcon from "@material-ui/icons/SystemUpdateAltRounded";
import { PdfViewer } from '../../../components/pdfViewer/index';
import  {
  StyledDownloadButton,
  StyledView,
  StyledPdfViewerCanvas,
  StyledPdfViewer,
  StyledFilePreviewContainer,
  StyledTitle,
  StyledTitleBold,
} from './styles';
import { FunctionalButton } from '../../../components/functionalButton';


export const ReviewForm = ({
  setIsLastPanel,
  setPreviousStepPanel,
  qualificationData,
}) => {
  const [pdfFileSequence, setPdfFileSequence] = useState([{ name: 'Generated RFPQ', generated: true },
                                                          ...qualificationData.additional_document]);

  const [pdfInstance, setPdfInstance] = useState(null);
  const [pdfPackage, setPdfPackage] = useState(null);

  setIsLastPanel(true);
  setPreviousStepPanel(1);

  const downloadReport = () => {
    if(pdfPackage) {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(pdfPackage);
      const fileName = `qualify_${Date.now()}`;
      link.download = fileName;
      link.click();
    }
  };

  const deleteFile = (index) => {
    let temp = [...pdfFileSequence];
    temp.splice(index, 1);
    setPdfFileSequence(temp);
  };

  const handleOnDragEnd = (resultEvent) => {
    if(!resultEvent.destination) {
      return;
    }

    const temp = [...pdfFileSequence];
    const [reorderedItem] = temp.splice(resultEvent.source.index, 1);
    temp.splice(resultEvent.destination.index, 0, reorderedItem);

    setPdfFileSequence(temp);
  };

  const viewerDiv = useRef(null);

  // useEffect(() => {
  //   if(!pdfInstance && qualificationData.ccdc) {
  //     WebViewer({
  //       path: 'lib',
  //       disabledElements: [
  //         'header',
  //       ],
  //       enableReadOnlyMode: true,
  //     }, viewerDiv.current).then(async instance => {
  //       instance.UI.setTheme('dark');

  //       setPdfInstance(instance);
  //     });
  //   }
  // }, [qualificationData]);

  // useEffect(() => {
  //   async function displayMergedPdf() {
  //     if(pdfInstance && qualificationData.ccdc) {
  //       let doc = null;

  //       if(pdfFileSequence.at(0).generated) {
  //         doc = await pdfInstance.Core.createDocument(qualificationData.ccdc);
  //       } else {
  //         doc = await pdfInstance.Core.createDocument(pdfFileSequence.at(0));
  //       }

  //       pdfFileSequence.forEach(async (file, index) => {
  //         if(index > 0) {
  //           let mergingFile = null;
  //           console.log(index);

  //           if(file.generated) {
  //             mergingFile = qualificationData.ccdc;
  //           } else {
  //             mergingFile = file;
  //           }

  //           const docToMerge = await pdfInstance.Core.createDocument(mergingFile);
  //           const pageIndexToInsert = doc.getPageCount() + 1;
  //           const insertMergePages = Array.from({length: docToMerge.getPageCount()}, (_, i) => i + 1);

  //           doc.insertPages(docToMerge, insertMergePages, pageIndexToInsert).then(() => console.log('File merged...'));
  //         }

  //         if(index === (pdfFileSequence.length - 1)) {
  //           const { annotationManager } = pdfInstance.Core;

  //           const xfdfString = await annotationManager.exportAnnotations();
  //           const data = await doc.getFileData({ xfdfString });
  //           const arr = new Uint8Array(data);
  //           const blob = new Blob([arr], { type: 'application/pdf' });
  //           setPdfPackage(blob);
  //         }
  //       });

  //       pdfInstance.UI.loadDocument(doc);
  //     }
  //   }

  //   displayMergedPdf();
  // }, [pdfFileSequence, pdfInstance, qualificationData])

  // const icon = <SystemUpdateAltRoundedIcon style={{ marginLeft: 15 }}/>

  return (
    <FormContainer>
      {/* <StyledLargeTitle>Preview &#38; Download</StyledLargeTitle> */}
{/*
      <StyledFilePreviewContainer>
        <div>
          <StyledTitleBold>Drag and Drop</StyledTitleBold><StyledTitle> to Re-Order your package</StyledTitle>
          { pdfFileSequence.length > 0 &&
            (<DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="fileReorderable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    { pdfFileSequence.map((item, i) =>  (
                        <Draggable key={`file-${i}`} draggableId={`file-${i}`} index={i}>
                          {(provided) => (
                            <div key={i} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                              <UploadedFileDisplay
                                key={`file-${i}`}
                                file={item}
                                displayPercentage="90%"
                                marginTop={'8px'}
                                noActions={true}
                                index={i}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))
                    }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>)
          }
        </div>

        <div>
          <StyledTitle>Your Qualification Statement</StyledTitle>
          <StyledPdfViewerCanvas>
            <StyledPdfViewer ref={viewerDiv} />
          </StyledPdfViewerCanvas>
        </div>
      </StyledFilePreviewContainer>
      <FunctionalButton
              buttonTitle="Download PDF"
              handleButton={downloadReport}
              icon={icon}
              width="220px"
              height="36px"
              marginRight="0"
              font-size="15px"
            /> */}

      {/* <StyledView>
        <StyledPdfViewerContainer>
          <StyledDownloadButton>
            <FunctionalButton
              buttonTitle="Download PDF"
              handleButton={downloadReport}
              icon={icon}
              width="220px"
              height="36px"
              marginRight="0"
              font-size="15px"
            />
          </StyledDownloadButton>
          <PdfViewer pdfFile={qualificationData.ccdc} />
        </StyledPdfViewerContainer>
      </StyledView> */}

    </FormContainer>
  );
};
