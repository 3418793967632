import React, { useState } from "react";

import { FormContainer, StyledLargeTitle } from "../styles";

import { Panel0 } from "./panel0";
import { Panel1 } from "./panel1";
import { Panel2 } from "./panel2";


export function FinancialForm({
  setActiveStep,
  qualificationData,
  setQualificationData,
  sameAI,
  setSameAI,
  sameCI,
  setSameCI,
  setIsLastPanel,
  setPreviousStepPanel,
}) {
  setIsLastPanel(true);
  setPreviousStepPanel(1);

  const [panel, setPanel] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [disabledBC, setDisabledBC] = useState(true);

  return (
    <FormContainer>
        <StyledLargeTitle>Financial Reference</StyledLargeTitle>
        <Panel0
          setPanel={setPanel}
          qualificationData={qualificationData}
          setQualificationData={setQualificationData}
          disabled={disabled}
          setDisabled={setDisabled}
        />
        <Panel1
          setPanel={setPanel}
          qualificationData={qualificationData}
          setQualificationData={setQualificationData}
          disabledBC={disabledBC}
          setDisabledBC={setDisabledBC}
        />
        <Panel2
          setActiveStep={setActiveStep}
          qualificationData={qualificationData}
          setQualificationData={setQualificationData}
          disabled={disabled}
          disabledBC={disabledBC}
          sameAI={sameAI}
          setSameAI={setSameAI}
          sameCI={sameCI}
          setSameCI={setSameCI}
        />
    </FormContainer>
  );
};
