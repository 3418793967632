
import React from 'react';

const Step3 = ({ active, completed }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2222:16271)">
    <g filter="url(#filter0_d_2222:16271)">
    <circle cx="16" cy="16" r="16" fill="white"/>
    </g>
    <circle cx="16" cy="16" r="13" fill={active || completed ? "#FF6D1D" : "white"} stroke="#ECEBED"/>
    </g>
    <path d="M14.2217 16.5977H15.459C16.6758 16.5977 17.4141 17.2061 17.4141 18.1973C17.4141 19.1543 16.6143 19.7969 15.4795 19.7969C14.3379 19.7969 13.5654 19.209 13.4902 18.293H11.8018C11.8838 20.0771 13.3535 21.2461 15.5 21.2461C17.6328 21.2461 19.2188 20.0156 19.2188 18.3203C19.2188 16.9736 18.3779 16.1055 17.0381 15.9004V15.7842C18.084 15.5039 18.8291 14.7178 18.8359 13.5283C18.8428 12.1133 17.6602 10.8896 15.5615 10.8896C13.4219 10.8896 12.082 12.0996 11.9932 13.8291H13.6475C13.7227 12.8447 14.3926 12.2979 15.4658 12.2979C16.5117 12.2979 17.1338 12.9404 17.1338 13.7539C17.1338 14.6494 16.4365 15.2783 15.4248 15.2783H14.2217V16.5977Z" fill={active || completed ? "white" : "#D0C9D6"}/>
    <defs>
    <filter id="filter0_d_2222:16271" x="-64" y="-57" width="160" height="160" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="7"/>
    <feGaussianBlur stdDeviation="32"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2222:16271"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2222:16271" result="shape"/>
    </filter>
    <clipPath id="clip0_2222:16271">
    <rect width="32" height="32" fill="white"/>
    </clipPath>
    </defs>
    </svg>

  )
};

export default Step3;

