import React from 'react';
import { StyledWeightsContainer } from '../weightedAttributes/styles';
import { SectionTitle } from '../SectionTitle';
import { COLORS } from '../../../../constants';
import { InputWithLabel } from '../../../../components/inputWithLabel';
import { MultilineTextField} from '../../../../components/multilineTextField';
import { StyledSubContainer } from '../styles';

import { 
  StyledSmallText,
  StyledInput, 
  StyledNarrowHeader, 
  StyledWrap, 
  StyledTableCellFirst,
  StyledUploadedFilesContainer,
  StyledDownloadText,
  StyledIcon,
} from './styles';

import { UploadFile } from '../../../../components/uploadFile';
import { FunctionalButton } from '../../../../components/functionalButton';
import  ConciseTable from '../../../../components/filesListTable/conciseTable';
import { StyledTableCell } from '../../../../components/filesListTable/styles';


export const AdditionalFilesUpload = () => {
  const handleCreate = () => {};

  const actions = (props) => {
    return (
      <>
        <StyledTableCell component={'tr'}  align="center">
          <StyledDownloadText>Download</StyledDownloadText>
          <StyledIcon />
        </StyledTableCell>
      </>
    )
  };

  const body = (bodyData) => { return (
      <>
        <StyledTableCellFirst component={'tr'}>
          {bodyData.fileName}
        </StyledTableCellFirst>  
      </>
    );
  };

  const bodyData = [
    { fileName: 'RFPQ21-079 Schedule A Reference Check' },
    { fileName: 'RFPQ21-079 Schedule B Insurance Letter Form' },
    { fileName: 'RFPQ21-079 Schedule C Reference Form' },
    { fileName: 'RFPQ21-079 Schedule D Other 1' },
    { fileName: 'RFPQ21-079 Schedule E Other 2' },
    { fileName: 'RFPQ21-079 Schedule F Other 3' },
    { fileName: 'RFPQ21-079 Schedule G Other 4' },
  ];

  return (
    <>
    <SectionTitle title="Additional Documents" color={COLORS.darkGrey2} />
      <StyledWeightsContainer>
        <StyledSubContainer>
          <StyledWrap className="inputWrap">
            <StyledInput>
              <StyledNarrowHeader>Give your New File a Name</StyledNarrowHeader>
              <InputWithLabel width="100%" placeholder="Name" />
            </StyledInput>
            <StyledInput marginTop="24px">
              <StyledNarrowHeader>Add a Description</StyledNarrowHeader>
              <MultilineTextField placeholder="Write a detailed Description" row={5}/>
              <StyledSmallText>You will be able to edit this information later.</StyledSmallText>
            </StyledInput>
            <StyledInput marginTop="24px">
              <FunctionalButton
                buttonTitle="Create"
                handleButton={handleCreate}
                width="419px"
                marginRight="0px"
                disabled={true}
              />
            </StyledInput>
          </StyledWrap>
          <StyledWrap className="inputWrap" paddingLeft="32px">
            <StyledNarrowHeader>Upload your additional files</StyledNarrowHeader>
            <UploadFile />
          </StyledWrap>
        </StyledSubContainer>

        <StyledInput marginTop="24px">
          <StyledNarrowHeader>Uploaded Files</StyledNarrowHeader>
          <StyledUploadedFilesContainer>
            <ConciseTable 
              length={3}
              actions={actions}
              body={body}
              data={bodyData}
            />
          </StyledUploadedFilesContainer>
        </StyledInput>
      </StyledWeightsContainer>
    </>
  );
};
