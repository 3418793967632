import React, { useState, useEffect } from "react";
import PlusSign from '../../assets/onBoarding/plusSign.js';
import { CategoriesRow } from "./CategoriesRow";

import {
  StyledCategoriesArea,
  SmallAddText,
  PlusSignArea,
  StyledAddText
} from './styles';

export const CommonCategories = ({
  data,
  setData,
  attribute,
  usePlusIcon,
  marginTopSecondary,
  isMobile,
  width,
  notPopup,
  readOnly,
  categoriesUNSPCData,
  categoriesStandardData,
  initialSubCategory,
  containsUNSPC,
  onRadioChange
}) => {

  const [enableAdd, setEnableAdd] = useState(true);

  const addInitialList = (data) => {
    const tmp = [];
    if (data?.categories?.length > 0) {
      const list = data?.categories;
      const listOfMain = list.map((item) => item.main_categories);
      list.map((item) => {
        tmp.push(<CategoriesRow
          attribute={attribute}
          setEnableAdd={setEnableAdd}
          data={data}
          setData={setData}
          categoriesUNSPC={categoriesUNSPCData}
          categoriesStandard={categoriesStandardData}
          initialSubCategory={initialSubCategory}
          containsUNSPC={containsUNSPC}
          onRadioChange={onRadioChange} 
          marginTop="0px"
          index={item.index}
          categories={item}
          mainSelected={listOfMain}
          isMobile={isMobile}
          marginTopSecondary={marginTopSecondary}
          readOnly={readOnly}
        />)
      });
    } else {
      tmp.push(<CategoriesRow
        attribute={attribute}
        setEnableAdd={setEnableAdd}
        data={data}
        setData={setData}
        categoriesUNSPC={categoriesUNSPCData}
        categoriesStandard={categoriesStandardData}
        initialSubCategory={initialSubCategory}
        containsUNSPC={containsUNSPC}
        onRadioChange={onRadioChange} 
        index={0}
        isMobile={isMobile}
        marginTopSecondary={marginTopSecondary}
        readOnly={readOnly}
      />)
    }
    return tmp;
  }

  const [addList, setAddList] = useState([]);

  useEffect(() => {
    setAddList([]);
    addInitialList([]);
  }, [containsUNSPC]);

  useEffect(() => {
    if (notPopup) {
      setAddList([])
    }
  }, [data?.categories])

  useEffect(() => {
    if (addList?.length == 0 && notPopup) {
      setAddList(addInitialList(data))
    }
  }, [addList])

  useEffect(() => {
    if (!notPopup) {
      setAddList(addInitialList(data))
    }
  }, [data])

  const onAdd = () => {
    const index = addList.length
    const list = data?.categories;
    const listOfMain = list?.length > 0 ? list.map((item) => item.main_categories) : [];
    setAddList(addList.concat(<CategoriesRow
      data={data}
      setData={setData}
      categoriesUNSPC={categoriesUNSPCData}
      categoriesStandard={categoriesStandardData}
      initialSubCategory={initialSubCategory}
      containsUNSPC={containsUNSPC}
      onRadioChange={onRadioChange} 
      index={index}
      marginTop="0px"
      marginTopSecondary={marginTopSecondary}
      mainSelected={listOfMain}
      isMobile={isMobile}
      attribute={attribute}
      setEnableAdd={setEnableAdd}
      readOnly={readOnly}
    />))
  }

  return (
    <StyledCategoriesArea isMobile={isMobile} width={width}>
      {addList}
      {((!readOnly) && (addList.length < 12) && (data?.categories?.length > 0)) && (
        <PlusSignArea usePlusIcon={usePlusIcon} >
          {usePlusIcon && (
            <>
              <PlusSign enableAdd={enableAdd} onAdd={onAdd} />
              <StyledAddText onClick={enableAdd ? onAdd : () => { }} enableAdd={enableAdd} >Add Category</StyledAddText>
            </>
          )}
          {!usePlusIcon && (
            <SmallAddText onClick={enableAdd ? onAdd : () => { }} enableAdd={enableAdd} >+ Add Category</SmallAddText>
          )}
        </PlusSignArea>
      )}

    </StyledCategoriesArea>
  );
}
