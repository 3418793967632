import { Typography } from "@mui/material";
import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from '../../../../../constants';

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PopupTitle = styled(Typography)`
&&{
  font-family: ${TYPOGRAPHY.primaryFont};
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 16px;
}
`;

export const PopupFields = styled.div`
  display: flex;
  flex-direction: column;
  // margin-top: 16px;
  // width: ${props => props.isMobile ? "100%" : "95%"};
  width: 100%;
  min-height: 330px;
`;

export const ButtonWrap = styled.div`
  min-height: 80px;
  display: flex;
  justify-content: center;
  width: 100%;
`;