import { IconButton, Paper } from "@material-ui/core";
import { COLORS } from "../../../constants";
import { TYPOGRAPHY } from "../../../constants";
import styled from "styled-components";

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f9fbfc;
`;

export const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 48px;
`;

export const TabContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 20px 38px;
`;

export const FunctionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px;
`;

export const TabButtonSpan = styled.span`
  opacity: 50%;
`;

export const TabButton = styled.button`
  background: none;
  cursor: pointer;
  padding: 6px 16px 6px 16px;
  font-size: 16px;
  color: ${(props) => (props.active ? "#FF6D1D" : "#888888")};
  border: none;
  border-bottom: ${(props) => (props.active ? "2px solid #FF6D1D" : "none")};
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 80%; /* 100% of the viewport height */
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const StyledTable = styled.table`
  width: 100%;
  padding: 0 48px;
  border-collapse: collapse;
  border: none;
  table-layout: fixed;
`;

export const StatusSpan = styled.span`
  cursor: pointer;
  padding: 0 24px 0 24px;
  border-radius: 16px;
  color: ${({ status }) => {
    switch (status) {
      case "Splitter":
        return "#C48A1A";
      case "Classifier":
        return "#435EAB";
      case "Processed":
      case "Parser":
        return "#2F9B51";
      case "Pending":
        return "#888888";
      default:
        return "black";
    }
  }};
  background-color: ${({ status }) => {
    switch (status) {
      case "Splitter":
        return "#FFEBC6";
      case "Classifier":
        return "#C1D1FF";
      case "Processed":
      case "Parser":
        return "#C0FAD2";
      case "Pending":
        return "#EBE9F1BF";
      default:
        return "none";
    }
  }};
`;

export const StyledTd = styled.td`
  text-align: left;
  vertical-align: middle;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  padding: 18px;
  white-space: normal;
  word-break: break-word;
  max-height: 56px;
`;

export const StyledHeader = styled.th`
  color: #6e6b7b;
  text-align: left;
  font-size: 16px;
  padding: 18px;
`;

export const DeleteButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  padding: 18px;
`;

export const ScreenRoot = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  ${(props) => (props.isMobile ? "position: relative;" : "")}
  width: 100%;
  max-width: 1440px;
  ${(props) => (props.isMobile ? "" : "align-content: center;")}
  ${(props) => (props.isMobile ? "" : "justify-content: center;")}
  ${(props) =>
    props.isMobile ? "padding: 70px 0 0 0;" : "padding: 60px 0px 0px 0px;"}
  background-color: #f0f0f0;
`;

export const StyledButton = styled.button`
  background-color: #ff6d1d;
  color: white;
  border: none;
  padding: 13px 61px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    background-color: #e65a14;
  }
`;
export const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;
export const FormTitle = styled.div`
  font-family: ${TYPOGRAPHY.primaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-top: 24px;
  color: ${COLORS.lightGrey2};
`;
export const InfoArea = styled.div`
  ${(props) =>
    props.marginLeft && !props.isMobile
      ? `margin-left: ${props.marginLeft};`
      : ""}
  margin-top: ${(props) => props.marginTop || "24px"};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  ${(props) => (props.noJustifyContent ? "" : "justify-content: center;")}
  ${(props) =>
    props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}
  
  .last {
    padding-right: 0px !important;
  }

  .inputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "50%")};
  }

  .fullWidthInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: 100%;
  }

  .oneFifthInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "20%")};
  }

  .thirtyInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "30%")};
  }

  .oneThirdInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "33.33%")};
  }

  .twoThirdInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "66.66%")};
  }

  .oneFifthMobile {
    padding-right: 16px;
    width: ${(props) => (props.isMobile ? "50%" : "20%")};
  }

  .thirtyMobile {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "50%" : "30%")};
  }
`;
export const StyledPaper = styled(Paper)`
  && {
    padding: 25px;
    width: ${(props) => (props.isMobile ? "100%" : "715px")};
    align-self: center;
    overflow: hidden;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`;

export const StyledAddText = styled.div`
  color: #888;
  cursor: pointer;
  font-family: ${TYPOGRAPHY.primaryFont};
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-top: 0px;
  ${(props) => (!props.enableAdd ? `color: #888888` : "")}
`;

export const SmallTitleText = styled(StyledAddText)`
  && {
    font-size: 18px;
  }
`;
