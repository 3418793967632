import React, { useEffect, useRef, useState } from "react";
import classes from "./style/FormBuilder.module.css";

import { AdditionalDocuments } from "../additionalDocuments/additionalDocuments";
import CheckedRadioButton from "assets/dashboardIcons/checkedRadioButton";
import { COLORS } from "../../constants";
import { CommonBusinessType } from "components/commonBusinessType/index.js";
import { FunctionalButton } from "../../components/functionalButton";
import Input from "../inputField";
import InputArea from "../inputArea";
import InteractiveTable from "../interactiveTable";
import { RadioButton } from "../radioButton";
import { RoundedCheckbox } from "../roundedCheckbox";
import Select from "../selectField";
import { SimpleList } from "../simpleList";
import { SimpleTable } from "../simpleTable";
import UncheckedRadioButton from "assets/dashboardIcons/uncheckedRadioButton";
import { numberWithCommas } from "common/numberFormat";
import InsuranceGroup from "components/insuranceGroup";
import { ReferencesVieweCQS } from "pages/referencesVieweCQS";
import DateTimePickerField from "components/dateTimePickerField";


export const ItemBuilder = ({
  alternateDataSourceField,
  conditionalFieldValue,
  conditionalState,
  flex,
  formId,
  formSubmitted,
  handleInteraction,
  id,
  interactedFields,
  item,
  itemStates,
  onSubmitItem,
  readOnly,
  setConditionalState,
  supplementaryData,
  value,
  disabled,
  width,
  institutionId,
  data
}) => {

  if (item?.fieldType) {
    var hasError =
      item.required && !value && value !== false && value !== 0 ? true : false;
    var extraProps = {
      label: !readOnly && item.required ? `${item.label}*` : item.label,
      readOnly: item.readOnly || readOnly,
      paddingLeft: item.paddingLeft || '5px',
      paddingRight: item.paddingRight || '5px'
    };

    if (item.condition?.type) {
      var conditionMet = true;
      switch (item.condition.type) {
        case "any":
          if (
            (!conditionalFieldValue ||
              conditionalFieldValue === {} ||
              conditionalFieldValue === []) &&
            conditionalFieldValue !== false &&
            conditionalFieldValue !== 0
          ) {
            conditionMet = false;
          }
          break;
        case "match":
          if (
            item.condition.value &&
            item.condition.value !== conditionalFieldValue
          ) {
            conditionMet = false;
          }
          break;
        case "of":
          if (
            item.condition.value &&
            Array.isArray(item.condition.value) &&
            !item.condition.value.includes(conditionalFieldValue)
          ) {
            conditionMet = false;
          }
      }
      if (conditionMet) {
        if (conditionalState[id] !== true) {
          setConditionalState({ ...conditionalState, [id]: true });
        }
      } else {
        if (conditionalState[id] !== false) {
          setConditionalState({ ...conditionalState, [id]: false });
        }
        return <></>;
      }
    }

    switch (item.fieldType) {
      case "additionalDocuments":
        return (
          <div
            className={[
              classes["item-inner-container"],
              "pledgxform-item-inner-container",
            ].join(" ")}
            style={{
              flex: flex ? flex : "auto",
              maxWidth: width ? width : "100%",
            }}
          >
            <AdditionalDocuments
              ccdcId={
                item.supplementaryData &&
                supplementaryData?.[item.supplementaryData]?.ccdcId
              }
              institutionId={institutionId}
              files={value?.selectedFiles || []}
              readOnly={readOnly}
              rfpqId={
                item.supplementaryData &&
                supplementaryData?.[item.supplementaryData]?.rfpqId
              }
              setFiles={(e) =>
                handleInteraction({
                  action: "CHANGE_FILES",
                  payload: { id, value: e },
                })
              }
            />
          </div>
        );
      case "button":
        return (
          <div
            className={[
              classes["item-inner-container"],
              "pledgxform-item-inner-container",
            ].join(" ")}
            style={{
              flex: flex ? flex : "auto",
              maxWidth: width ? width : "100%",
            }}
          >
            <FunctionalButton
              buttonTitle={item.label || "Button"}
              disabled={item.disabled}
              handleButton={() =>
                handleInteraction({ action: "BUTTON_CLICKED", payload: { id } })
              }
              isReversedColor={item.isReversedColor}
              width={item.width || "200px"}
              marginRight="0px"
              marginTop="0px"
            />
          </div>
        );
      case "checkBox":
        return (
          <div
            className={[
              classes["item-inner-container"],
              "pledgxform-item-inner-container",
            ].join(" ")}
            style={{
              flex: flex ? flex : "auto",
              maxWidth: width ? width : "100%",
              marginLeft: item.marginLeft ? item.marginLeft : "0",
            }}
          >
            {extraProps.label && (
              <div
                className={classes["item-label"]}
                style={{ marginBottom: "0" }}
              >
                {extraProps.label}
              </div>
            )}
            {item.preText && <span>{item.preText}</span>}
            <RoundedCheckbox
              attributeTypes={[value]}
              onChange={() =>
                handleInteraction({
                  action: "CHANGE_VALUE",
                  payload: {
                    id,
                    value: value || value === false ? !value : true,
                  },
                })
              }
              readOnly={readOnly}
              value={value || null}
            />
            {item.postText && (
              <span
                dangerouslySetInnerHTML={{
                  __html: item.postText,
                }}
              ></span>
            )}
          </div>
        );
        break;
      case "commonCategories":
        return (
          <div
            className={[
              classes["item-inner-container"],
              "pledgxform-item-inner-container",
            ].join(" ")}
            style={{
              flex: flex ? flex : "auto",
              maxWidth: width ? width : "100%",
            }}
          >
            
            <CommonBusinessType
              data={{ categories: value }}
              marginTopSecondary="0px"
              notPopup={true}
              readOnly={readOnly}
              setData={(attrib, val) =>
                handleInteraction({
                  action: "CHANGE_VALUE",
                  payload: { id, value: val },
                })
              }
              containsUNSPC={data?.radioProjectUNSPC}
            />
          </div>
        );
      case "documentPool":
        return (
          <div
            className={[
              classes["item-inner-container"],
              "pledgxform-item-inner-container",
            ].join(" ")}
            style={{
              flex: flex ? flex : "auto",
              maxWidth: width ? width : "100%",
            }}
          >
            COMING SOON
          </div>
        );
      case "dropdown":
        return (
          <div
            className={[
              classes["item-inner-container"],
              "pledgxform-item-inner-container",
            ].join(" ")}
            style={{
              flex: flex ? flex : "auto",
              maxWidth: width ? width : "100%",
            }}
          >
            <Select
              noCheckIcon={true}
              options={
                item.supplementaryData
                  ? supplementaryData?.[item.supplementaryData]?.data
                  : value?.data
              }
              setValue={(e) =>
                handleInteraction({
                  action: "CHANGE_DROPDOWN_VALUE",
                  payload: { id, value: e },
                })
              }
              showDropdownIndicator={true}
              showError={
                readOnly
                  ? false
                  : interactedFields.includes(id) || formSubmitted
                    ? hasError
                    : false
              }
              useValueLabel={true}
              validateFunc={(item) => Boolean(item)}
              value={value?.selectedData}
              withoutMargin={true}
              {...extraProps}
            />
          </div>
        );
      case "input":
        return (
          <div
            className={[
              classes["item-inner-container"],
              "pledgxform-item-inner-container",
            ].join(" ")}
            style={{
              flex: flex ? flex : "auto",
              maxWidth: width ? width : "100%",
            }}
          >
            <Input
              fontSize="18px"
              labelSize="12px"
              marginTop="0px"
              paddingRight={hasError ? "28px" : "15px"}
              placeholder={!readOnly && item.placeholder}
              setValue={(e) =>
                handleInteraction({
                  action: "CHANGE_VALUE",
                  payload: { id, value: e },
                })
              }
              showError={
                readOnly
                  ? false
                  : interactedFields.includes(id) || formSubmitted
                    ? hasError
                    : false
              }
              type={item.fieldSubType || "text"}
              value={value || ""}
              {...extraProps}
            />
          </div>
        );
      case "date":
        return (
          <div
            className={[
              classes["item-inner-container"],
              "pledgxform-item-inner-container",
            ].join(" ")}
            style={{
              flex: flex ? flex : "auto",
              maxWidth: width ? width : "100%",
            }}
          >
            <DateTimePickerField
              showDateField={item.showDateField}
              dateWidth={item.width}
              paddingRight="0px"
              height="50px"
              fontSize="18px"
              textAlign="left"
              dateLabel={item.label || ""}
              dateValue={value ? value : ""}
              readOnly={readOnly}
              showBorder={`1px solid ${COLORS.greyBorder}`}
              backgroundColor={true}
              withoutMargin={true}
              paddingLeft="15px"
              setDateValue={(val) => {
                handleInteraction({
                  action: "CHANGE_VALUE",
                  payload: { id, value: val },
                });
              }}
            />
          </div>
        );
      case "time":
        return (
          <div
            className={[
              classes["item-inner-container"],
              "pledgxform-item-inner-container",
            ].join(" ")}
            style={{
              flex: flex ? flex : "auto",
              maxWidth: width ? width : "100%",
            }}
          >
            <DateTimePickerField
              showTimeField={item.showTimeField}
              width={item.width}
              paddingRight="0px"
              height="50px"
              fontSize="18px"
              textAlign="left"
              timeLabel={item.label || ""}
              timeValue={value ? value : ""}
              readOnly={readOnly}
              showBorder={`1px solid ${COLORS.greyBorder}`}
              backgroundColor={true}
              withoutMargin={true}
              paddingLeft="15px"
              setTimeValue={(val) => {
                handleInteraction({
                  action: "CHANGE_VALUE",
                  payload: { id, value: val },
                });
              }}
            />
          </div>
        );
      case "inputArea":
        return (
          <div
            className={[
              classes["item-inner-container"],
              "pledgxform-item-inner-container",
            ].join(" ")}
            style={{
              flex: flex ? flex : "auto",
              maxWidth: width ? width : "100%",
            }}
          >
            <InputArea
              fontSize="18px"
              labelSize="12px"
              marginTop="0px"
              placeholder={!readOnly && item.placeholder}
              setValue={(e) =>
                handleInteraction({
                  action: "CHANGE_VALUE",
                  payload: { id, value: e },
                })
              }
              type={item.fieldSubType || "text"}
              value={value || ""}
              {...extraProps}
            />
          </div>
        );
      case "interactiveTable":
        return (
          <div
            className={[
              classes["item-inner-container"],
              "pledgxform-item-inner-container",
            ].join(" ")}
            style={{
              flex: flex ? flex : "auto",
              maxWidth: width ? width : "100%",
            }}
          >
            <InteractiveTable
              data={
                item.supplementaryData
                  ? supplementaryData?.[item.supplementaryData]?.data
                  : value?.data
              }
              id={id}
              inheritState={itemStates?.[id] || {}}
              internalSearch={true}
              options={item.options}
              readOnly={readOnly}
              selectedData={value?.selectedData}
              setData={(e) =>
                handleInteraction({
                  action: item.supplementaryData
                    ? "TABLE_ADD_SUPPLEMENTARY_VALUE"
                    : "TABLE_CHANGE_VALUE",
                  payload: { id: id, value: e },
                })
              }
              setSelectedData={(e) =>
                handleInteraction({
                  action: "TABLE_SELECTED_CHANGE_VALUE",
                  payload: { id: id, value: e },
                })
              }
              onInteraction={(e) =>
                handleInteraction({
                  action: "RESUME_BUTTON_CLICKED",
                  payload: {
                    id,
                    fileName: e.fileName,
                    file_id: e.file_id,
                  },
                })
              }
              submitItem={onSubmitItem}
            />
          </div>
        );
      case "radioButton":
        // NOTE: Radio buttons can only be enabled. Disable logic must be done in the parent component, and done via state mutation.
        // To circumvent this, and allow radioButtons to be unchecked, pass the prop allowUncheck=true
        return (
          <div
            className={[
              classes["item-inner-container"],
              "pledgxform-item-inner-container",
            ].join(" ")}
            style={{
              flex: flex ? flex : "auto",
              maxWidth: width ? width : "100%",
            }}
          >
            {extraProps.label && (
              <div
                className={classes["item-label"]}
                style={{ marginBottom: "0" }}
              >
                {/* {extraProps.label} */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: extraProps.label,
                  }}
                ></div>
              </div>
            )}
            {item.preText && (
              <div dangerouslySetInnerHTML={{ __html: item.preText }}></div>
            )}
            <RadioButton
              checkedIcon={
                <CheckedRadioButton color={readOnly ? COLORS.textGrey : null} />
              }
              handleChange={() =>
                handleInteraction({
                  action: "CHANGE_VALUE",
                  payload: {
                    id,
                    value: item.allowUncheck
                      ? value || value === false
                        ? !value
                        : true
                      : true,
                  },
                })
              }
              isChecked={value}
              readOnly={readOnly}
              unCheckedIcon={
                <UncheckedRadioButton
                  color={readOnly ? COLORS.textGrey : null}
                />
              }
              value={id}
            />
            {item.postText && <span>{item.postText}</span>}
          </div>
        );
      case "simpleList":
        var listOptions = item.options || {};
        var columns =
          item.columns || alternateDataSourceField?.options?.list?.columns;
        return (
          <div
            className={[
              classes["item-inner-container"],
              "pledgxform-item-inner-container",
            ].join(" ")}
            style={{
              flex: flex ? flex : "auto",
              maxWidth: width ? width : "100%",
            }}
          >
            <SimpleList
              columns={columns}
              data={value?.selectedData || value || []}
              disabled={disabled}
              onInteraction={(e) =>
                handleInteraction({
                  action: "INTERACTION_ITEM_CLICKED",
                  payload: { id, rowId: e.rowId, name: e.name },
                })
              }
              setData={(e) =>
                handleInteraction({
                  action: "CHANGE_LIST_SELECT_VALUE",
                  payload: { id, ...e },
                })
              }
              {...listOptions}
              {...extraProps}
            />
          </div>
        );
      case "simpleTable":
        var tableOptions = item.options || {};
        return (
          <div
            className={[
              classes["item-inner-container"],
              "pledgxform-item-inner-container",
            ].join(" ")}
            style={{
              flex: flex ? flex : "auto",
              maxWidth: width ? width : "100%"
            }}
          >
            <SimpleTable
              columns={item.columns}
              data={value || {}}
              rows={item.rows}
              setData={(e) =>
                handleInteraction({
                  action: "CHANGE_VALUE",
                  payload: { id, value: e },
                })
              }
              {...tableOptions}
              {...extraProps}
            />
          </div>
        );
      case "yearInputs":
        // Warning: UI is not optimized for any more than 2 boxes per row (individualWidth < 33%).
        const currentYear = new Date().getFullYear();
        var existingYears = value
          ? item.useNumberKeys
            ? Object.keys(value).map(Number)
            : Object.keys(value)
          : [];
        var yearsArray = [];
        if (item.keys) {
          yearsArray = item.keys;
        } else if (item.minRange) {
          yearsArray = Array.from(
            Array.from(
              Array(
                Math.ceil(
                  (currentYear - (item.minRange || 5) - currentYear) / -1
                )
              ).keys()
            ),
            (x) => currentYear + x * -1
          );
        }
        if (!item.useNumberKeys) {
          yearsArray = yearsArray.map(String);
        }
        var combinedYears = [...new Set([...yearsArray, ...existingYears])];
        combinedYears.sort();
        combinedYears.reverse();
        return (
          <div
            className={[
              classes["year-group-container"],
              "pledgxform-year-group-container",
            ].join(" ")}
            style={{ width: width ? width : "100%" }}
          >
            {combinedYears.map((year, index) => (
              <div
                className={[
                  classes["year-inner-container"],
                  "pledgxform-year-inner-container",
                ].join(" ")}
                style={{
                  width: item.individualWidth ? item.individualWidth : "100%",
                  marginTop: index > 1 ? "10px" : "0",
                }}
              >
                <Input
                  fontSize="18px"
                  label={year === currentYear ? "Current Year(forecast)" : year}
                  labelSize="12px"
                  marginTop="0px"
                  readOnly={readOnly}
                  setValue={(e) =>
                    handleInteraction({
                      action: "YEAR_CHANGE_VALUE",
                      payload: { id, value: { [year]: e } },
                    })
                  }
                  value={numberWithCommas(value?.[year]) || ""}
                />
              </div>
            ))}
          </div>
        );
      case "insuranceDetails":
        return (
          <InsuranceGroup
            value={value?.selectedData || value || ""}
            readOnly={readOnly}
          />
        );
      case "referenceReview":
        return (
          <ReferencesVieweCQS
            institutionId={institutionId}
            readOnly={readOnly}
          />
        );
      default:
        return <></>;
    }
  } else {
    return <></>;
  }
};
