import React, { useRef } from "react";
import logoPlacement from 'assets/reference/logoPlacement.svg';
import editBadge from 'assets/reference/editBadge.svg';
import { Badge } from '@mui/material';
import { saveCompanyLogo } from 'api/profile';

import { 
  StyledBox,
  CompanyLogo,
  EditBadge,
  StyledBoxContent,
  LargeTitle,
  SmallTitle
} from '../styles';

import { 
  NameRating,
  RatedNumber,
  TotalNumber,
  RatingDiv
} from './styles';

const EditBadgeContent = ({
  inputFile,
  isMobile,
  setLogo
}) => {

  const clickUploadFile = () => {
    inputFile.current.click();
  };

  const savePhoto = (file) => {
    let formData = new FormData();
    formData.append('logo', file);
    saveCompanyLogo(formData, (res) => setLogo(res.logo), () => {});
  }

  return (
    <>
    <label htmlFor="contained-button-file">
      <input
        type="file"
        id="contained-button-file"
        style={{ display: "none" }}
        ref={inputFile}
        accept= ".jpg,.jpeg,.png, image/jpeg,image/png"
        onClick={(e) => {
          e.target.value = null;
          clickUploadFile();
        }}
        onChange={(e) => {
          if (
            e.target.files[0].type === "image/jpeg" ||
            e.target.files[0].type === "image/png" 
          ){ savePhoto(e.target.files[0]);}
        }}
      />
          <EditBadge src={editBadge} isMobile={isMobile}/>
      </label>
      
    </>
  )
}

export const CompanySummary = ({
  isMobile,
  data,
  logo,
  setLogo
}) => { 
  const inputFile = useRef(null);
  

  return (
      <StyledBox boxHeight="160px">
        <StyledBoxContent>
          <div>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={<EditBadgeContent inputFile={inputFile} isMobile={isMobile} setLogo={setLogo} />}
            >
              {logo && (
                <CompanyLogo src={`data:image/png;base64,${logo}`} isMobile={isMobile} variant="square" />
              )}
              {!logo && (
                <img src={logoPlacement}/>
              )}
              </Badge>
          </div>
            <NameRating>
              <LargeTitle>{data?.name}</LargeTitle>
              <SmallTitle margin="12px 0 8px 0">Rating</SmallTitle>
              <RatingDiv>
                <RatedNumber hasData={data?.rating}>{data?.rating || "__"}</RatedNumber>
                <TotalNumber>/10</TotalNumber>
              </RatingDiv>
            </NameRating>
        </StyledBoxContent>
      </StyledBox>      
  )
};