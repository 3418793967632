import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./data/store";
import App from "./App";
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

ReactDOM.render(
  <GoogleOAuthProvider clientId="458051917325-q1nq2adkd734sb5mr1u71782f6sqh5kn.apps.googleusercontent.com">
    <Provider store={configureStore()}>
      <Router>
        <App />
      </Router>
    </Provider>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
