import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { FunctionalButton } from "../../../components/functionalButton";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import {
  StyledQuestion,
  InfoArea,
  StyledSkipText,
  TextContainer,
} from "./styles";

import { ValidateBankName } from "./financialForm/validBankName";
import { FillBankInfo } from "./financialForm/fillBankInfo";
import { ValidateBondName } from "./financialForm/validBondName";
import { FillBondInfo } from "./financialForm/fillBondInfo";
import { FillBondAddress } from "./financialForm/fillBondAddress";
import { ValidateBondAddress } from "./financialForm/validBondAddress";
import { FillBondContact } from "./financialForm/fillBondContact";
import { ValidInsuranceName } from "./financialForm/validInsuranceName";
import { FillInsuranceName } from "./financialForm/fillInsuranceName";
import { ValidateInsuranceAddress } from "./financialForm/validInsuranceAddress";
import { FillInsuranceAddress } from "./financialForm/fillInsuranceAddress";
import { ValidInsuranceLimit } from "./financialForm/validInsuranceLimit";
import { FillInsuranceContact } from "./financialForm/fillInsuranceContact";
import { ValidateBankAddress } from "./financialForm/validBankAddress";
import { FillBankAddress } from "./financialForm/fillBankAddress";
import { FillBankContact } from "./financialForm/fillBankContact";
import { BankPicklist } from "./bankPicklist";
import { BondingPicklist } from "./bondingPicklist";
import { GIPicklist } from "./GIPicklist";
import { CIPicklist } from "./CIPicklist";
import { AIPicklist } from "./AIPicklist";
import { InsurancePickList } from "./insurancePickList";

export const FinancialForm = ({
  handleNext,
  prequalifyData,
  setPrequalifyData,
  panel,
  setPanel,
  setIsLastPanel,
  setPreviousStepPanel,
  setIsBranchPanel,
  insuranceTypeList,
  institutionId,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 960 });
  const [selected, setSelected] = useState(0);
  // console.log(prequalifyData);
  // States for popup checkboxes
  const [autoInsurance, setAutoInsurance] = useState(false);
  const [equipmentInsurance, setEquipmentInsurance] = useState(false);
  const [acronymInsurance, setAcronymInsurance] = useState("GI");
  const [insuranceType, setInsuranceType] = useState("general liability");
  const [popUpLock, setPopUpLock] = useState(false);

  const [manualBankFill, setManualBankFill] = useState(false);
  const [manualBondFill, setManualBondFill] = useState(false);
  const [manualInsuranceFill, setManualInsuranceFill] = useState(false);
  // console.log(acronymInsurance);
  useEffect(() => {
    if (autoInsurance) {
      setAcronymInsurance("AI");
      setAutoInsurance(false);
    } else if (equipmentInsurance) {
      setAcronymInsurance("CI");
      setEquipmentInsurance(false);
    }
  }, [insuranceType]);

  // useEffect(() => {
  //   if(panel === 1 && prequalifyData?.bank?.name === '') {
  //     setPanel(2);
  //   } else if(panel === 3 && (prequalifyData?.bank?.address === '' ||
  //     prequalifyData?.bank?.city === ''||
  //     prequalifyData?.bank?.zip_code  === ''||
  //     prequalifyData?.bank?.province === '')) {
  //       setPanel(4);
  //   }

  //   if(panel === 6 && prequalifyData?.bonding?.bc === '') {
  //     setPanel(7);
  //   } else if(panel === 8 && (prequalifyData?.bonding?.addressBC === '' ||
  //     prequalifyData?.bonding?.cityBC === ''||
  //     prequalifyData?.bonding?.postalCodeBC  === ''||
  //     prequalifyData?.bonding?.provinceBC === '')) {
  //       setPanel(9);
  //   };

  //   const x = `${acronymInsurance}Company`;
  //   const a = `${acronymInsurance}Address`;
  //   const z = `${acronymInsurance}Postal`;
  //   const c = `${acronymInsurance}City`;
  //   const p = `${acronymInsurance}Province`;

  //   if(panel === 11 && !prequalifyData?.insurance?.[x]) {
  //     setPanel(12);
  //   } else if(panel === 13 && prequalifyData?.insurance?.[a] === '' ||
  //     panel === 13 && prequalifyData?.insurance?.[z] === '' ||
  //     panel === 13 && prequalifyData?.insurance?.[c] === '' ||
  //     panel === 13 && prequalifyData?.insurance?.[p] === '' ) {
  //       setPanel(14);
  //   };

  // },[panel]);

  // useEffect(() => {
  //   if(manualBankFill || manualBondFill || manualInsuranceFill) {
  //     setPanel(panel+1);
  //   }

  //   if(manualBankFill) {
  //     setPrequalifyData('bank.address', '');
  //     setPrequalifyData('bank.suite', '');
  //     setPrequalifyData('bank.zip_code', '');
  //     setPrequalifyData('bank.city', '');
  //     setPrequalifyData('bank.province', '');
  //     setPrequalifyData('bank.contact_person_firstname', '');
  //     setPrequalifyData('bank.contact_person_lastname', '');
  //     setPrequalifyData('bank.email', '');
  //     setPrequalifyData('bank.phone', '');
  //     setManualBankFill(false);
  //   }

  //   if(manualBondFill) {
  //     setPrequalifyData('bonding.addressBC', '');
  //     setPrequalifyData('bonding.suiteNumberBC', '');
  //     setPrequalifyData('bonding.postalCodeBC', '');
  //     setPrequalifyData('bonding.cityBC', '');
  //     setPrequalifyData('bonding.provinceBC', '');
  //     setPrequalifyData('bonding.firstNameBC', '');
  //     setPrequalifyData('bonding.lastNameBC', '');
  //     setPrequalifyData('bonding.emailBC', '');
  //     setPrequalifyData('bonding.phoneBC', '');
  //     setManualBondFill(false);
  //   }

  //   if(manualInsuranceFill) {
  //     setPrequalifyData(`insurance.${acronymInsurance}Address`, '');
  //     setPrequalifyData(`insurance.${acronymInsurance}Suite`, '');
  //     setPrequalifyData(`insurance.${acronymInsurance}Postal`, '');
  //     setPrequalifyData(`insurance.${acronymInsurance}City`, '');
  //     setPrequalifyData(`insurance.${acronymInsurance}Province`, '');
  //     setPrequalifyData(`insurance.${acronymInsurance}FirstName`, '');
  //     setPrequalifyData(`insurance.${acronymInsurance}LastName`, '');
  //     setPrequalifyData(`insurance.${acronymInsurance}Email`, '');
  //     setPrequalifyData(`insurance.${acronymInsurance}Phone`, '');
  //     setManualInsuranceFill(false);
  //   }
  // }, [manualBankFill,manualBondFill, manualInsuranceFill]);

  const [rerender, setRerender] = useState(false);

  return (
    <>
      <Switch>
        <Route exact path="/prequalify/financial/bank">
          <BankPicklist
            prequalifyData={prequalifyData}
            setPrequalifyData={setPrequalifyData}
            handleNext={handleNext}
            setIsLastPanel={setIsLastPanel}
            setPanel={setPanel}
            panel={panel}
            setPreviousStepPanel={setPreviousStepPanel}
            setIsBranchPanel={setIsBranchPanel}
            popupTitle={"Bank"}
            setRerender={setRerender}
            institutionId={institutionId}
          />
        </Route>
        <Route exact path="/prequalify/financial/bond">
          <BondingPicklist
            prequalifyData={prequalifyData}
            setPrequalifyData={setPrequalifyData}
            handleNext={handleNext}
            setIsLastPanel={setIsLastPanel}
            setPanel={setPanel}
            panel={panel}
            setPreviousStepPanel={setPreviousStepPanel}
            setIsBranchPanel={setIsBranchPanel}
            popupTitle={"Bonding"}
            setRerender={setRerender}
            institutionId={institutionId}
          />
        </Route>
        <Route exact path="/prequalify/financial/insurance">
          <InsurancePickList
            prequalifyData={prequalifyData}
            setPrequalifyData={setPrequalifyData}
            handleNext={handleNext}
            popupTitle={"Insurance"}
            setRerender={setRerender}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
            insuranceType={insuranceTypeList}
            institutionId={institutionId}
          />
        </Route>
        {/* <Route exact path="/prequalify/financial/general">
          <GIPicklist
            prequalifyData={prequalifyData}
            setPrequalifyData={setPrequalifyData}
            handleNext={handleNext}
            setIsLastPanel={setIsLastPanel}
            setPanel={setPanel}
            panel={panel}
            setPreviousStepPanel={setPreviousStepPanel}
            setIsBranchPanel={setIsBranchPanel}
            popupTitle={"Insurance"}
            setRerender={setRerender}
          />
        </Route>
        <Route exact path="/prequalify/financial/equipment">
          <CIPicklist
            prequalifyData={prequalifyData}
            setPrequalifyData={setPrequalifyData}
            handleNext={handleNext}
            setIsLastPanel={setIsLastPanel}
            setPanel={setPanel}
            panel={panel}
            setPreviousStepPanel={setPreviousStepPanel}
            setIsBranchPanel={setIsBranchPanel}
            popupTitle={"Insurance"}
            setRerender={setRerender}
          />
        </Route>
        <Route exact path="/prequalify/financial/automobile">
          <AIPicklist
            prequalifyData={prequalifyData}
            setPrequalifyData={setPrequalifyData}
            handleNext={handleNext}
            setIsLastPanel={setIsLastPanel}
            setPanel={setPanel}
            panel={panel}
            setPreviousStepPanel={setPreviousStepPanel}
            setIsBranchPanel={setIsBranchPanel}
            popupTitle={"Insurance"}
            setRerender={setRerender}
          />
        </Route> */}
      </Switch>
      {/* { panel == 2 && (
        <FillBankInfo
          prequalifyData={prequalifyData}
          setPrequalifyData={setPrequalifyData}
          handleNext={handleNext}
          setIsLastPanel={setIsLastPanel}
          setPanel={setPanel}
          panel={panel}
          setPreviousStepPanel={setPreviousStepPanel}
          setManualBankFill={setManualBankFill}
        />
      )}
      { panel == 3 && (
        <ValidateBankAddress
        prequalifyData={prequalifyData}
        setPrequalifyData={setPrequalifyData}
        handleNext={handleNext}
        setIsLastPanel={setIsLastPanel}
        setPanel={setPanel}
        panel={panel}
        setPreviousStepPanel={setPreviousStepPanel}
        setIsBranchPanel={setIsBranchPanel}
        />
      )}
      { panel == 4 && (
        <FillBankAddress
        prequalifyData={prequalifyData}
        setPrequalifyData={setPrequalifyData}
        handleNext={handleNext}
        setIsLastPanel={setIsLastPanel}
        setPanel={setPanel}
        panel={panel}
        setPreviousStepPanel={setPreviousStepPanel}
        setIsBranchPanel={setIsBranchPanel}
        />
      )}
      { panel == 5 && (
        <FillBankContact
        prequalifyData={prequalifyData}
        setPrequalifyData={setPrequalifyData}
        handleNext={handleNext}
        setIsLastPanel={setIsLastPanel}
        setPanel={setPanel}
        panel={panel}
        setPreviousStepPanel={setPreviousStepPanel}
        setIsBranchPanel={setIsBranchPanel}
        />
      )}
      { panel == 6 && (
        <ValidateBondName
          handleNext={handleNext}
          prequalifyData={prequalifyData}
          setPrequalifyData={setPrequalifyData}
          setPanel={setPanel}
          setPrequalifyData={setPrequalifyData}
          setIsLastPanel={setIsLastPanel}
          setPreviousStepPanel={setPreviousStepPanel}
          setIsBranchPanel={setIsBranchPanel}
        />
      )}
      { panel == 7 && (
        <FillBondInfo
          handleNext={handleNext}
          prequalifyData={prequalifyData}
          setPanel={setPanel}
          setPrequalifyData={setPrequalifyData}
          setIsLastPanel={setIsLastPanel}
          setPreviousStepPanel={setPreviousStepPanel}
          setManualBondFill={setManualBondFill}
        />
      )}
      { panel == 8 && (
        <>
          <ValidateBondAddress
            handleNext={handleNext}
            prequalifyData={prequalifyData}
            setPanel={setPanel}
            setPrequalifyData={setPrequalifyData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
            setIsBranchPanel={setIsBranchPanel}
          />
        </>
      )}
      { panel == 9 && (
        <>
          <FillBondAddress
            handleNext={handleNext}
            prequalifyData={prequalifyData}
            setPanel={setPanel}
            setPrequalifyData={setPrequalifyData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
          />
        </>
      )}
      { panel == 10 && (
        <>
          <FillBondContact
            handleNext={handleNext}
            prequalifyData={prequalifyData}
            setPanel={setPanel}
            setPrequalifyData={setPrequalifyData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
          />
        </>
      )}
      { panel == 11 && (
        <>
          <ValidInsuranceName
            handleNext={handleNext}
            prequalifyData={prequalifyData}
            setPanel={setPanel}
            setPrequalifyData={setPrequalifyData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
            insuranceType={insuranceType}
            acronymInsurance={acronymInsurance}
          />
        </>
      )}
      { panel == 12 && (
        <>
          <FillInsuranceName
            handleNext={handleNext}
            prequalifyData={prequalifyData}
            setPanel={setPanel}
            setPrequalifyData={setPrequalifyData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
            insuranceType={insuranceType}
            acronymInsurance={acronymInsurance}
            insuranceType={insuranceType}
            setManualInsuranceFill={setManualInsuranceFill}
          />
        </>
      )}
      { panel == 13 && (
        <>
          <ValidateInsuranceAddress
            handleNext={handleNext}
            prequalifyData={prequalifyData}
            setPanel={setPanel}
            setPrequalifyData={setPrequalifyData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
            insuranceType={insuranceType}
            acronymInsurance={acronymInsurance}
            setIsBranchPanel={setIsBranchPanel}
          />
        </>
      )}
      { panel == 14 && (
        <>
          <FillInsuranceAddress
            handleNext={handleNext}
            prequalifyData={prequalifyData}
            setPanel={setPanel}
            setPrequalifyData={setPrequalifyData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
            insuranceType={insuranceType}
            acronymInsurance={acronymInsurance}
          />
        </>
      )}
      { panel == 15 && (
        <>
          <FillInsuranceContact
            handleNext={handleNext}
            prequalifyData={prequalifyData}
            setPanel={setPanel}
            setPrequalifyData={setPrequalifyData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
            insuranceType={insuranceType}
            acronymInsurance={acronymInsurance}
          />
        </>
      )}
      { panel == 16 && (
        <>
          <ValidInsuranceLimit
            handleNext={handleNext}
            prequalifyData={prequalifyData}
            setPanel={setPanel}
            setPrequalifyData={setPrequalifyData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
            setAutoInsurance={setAutoInsurance}
            setEquipmentInsurance={setEquipmentInsurance}
            equipmentInsurance={equipmentInsurance}
            autoInsurance={autoInsurance}
            setInsuranceType={setInsuranceType}
            insuranceType={insuranceType}
            setPopUpLock={setPopUpLock}
            popUpLock={popUpLock}
            acronymInsurance={acronymInsurance}
            setIsBranchPanel={setIsBranchPanel}
          />
        </>
      )} */}
    </>
  );
};
