import React, { useState, useEffect } from "react";
import { SelectingProjects } from "pages/contractorDashboard/profileSetup/components/selectingProjects";
import { SelectedProjects } from "pages/contractorDashboard/profileSetup/components/selectedProjects.js";
import { AddPersonnelPopup } from "components/addPersonnelPopup";
import { contactSelector } from "data/selectors/contact";
import { useSelector } from "react-redux";
import { filterPersonnels } from "api/prequalify";
import { institutionProfileInfoSelector } from "data/selectors/serviceProvider";

export const ResultPanel = ({
  projects,
  setPanel,
  setAddData,
  setProjects,
  setEditIndex,
  setOpenEdit,
  setOpenPopup,
  openPopup,
  setPrequalifyData,
  prequalifyData,
  institutionId,
}) => {
  const [individual, setIndividual] = useState(null);
  const [pickedArray, setPickedArray] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [personnelList, setPersonnelList] = useState("");
  const currentDashboardUrl = localStorage.getItem("current_dashboard_url");
  let contactData = useSelector(contactSelector);
  const institutionContactData = useSelector(institutionProfileInfoSelector);

  if (currentDashboardUrl === "/service-provider-dashboard") {
    contactData = institutionContactData;
  }
  const fileLimitCount = contactData?.user_access_control?.file_limit_count;
  const fileLimitSize = contactData?.user_access_control?.file_limit_size;

  const fileLimitSizeMB = (fileLimitSize / (1024 * 1024)).toFixed(2);

  const addPersonnel = (data) => {};

  useEffect(() => {
    if (individual) {
      const individualTemp = individual[0];
      const temp = [individualTemp, ...projects];
      setProjects(temp);
    }
  }, [individual]);

  useEffect(() => {
    handleFilterPersonnel();
  }, []);

  const handleFilterPersonnel = async () => {
    await filterPersonnels("", { institution_id: institutionId })
      .then((data) => {
        setPersonnelList(data);
        setFilteredData(data);
      })
      .catch((err) => {});
  };

  return (
    <>
      <SelectedProjects
        projects={projects}
        setProjects={setProjects}
        setOpenPopup={setOpenPopup}
        openPopup={openPopup}
        individual={individual}
        setPrequalifyData={setPrequalifyData}
        prequalifyData={prequalifyData}
        pickedArray={pickedArray}
        setPickedArray={setPickedArray}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
      />
      <SelectingProjects
        add={() => setPanel("add")}
        projects={projects}
        setProjects={setProjects}
        setOpenPopup={setOpenPopup}
        setPrequalifyData={setPrequalifyData}
        prequalifyData={prequalifyData}
        pickedArray={pickedArray}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        personnelList={personnelList}
      />
      <AddPersonnelPopup
        setOpenDialog={setOpenPopup}
        openDialog={openPopup}
        data={individual}
        setData={setIndividual}
        handleButtonFunc={addPersonnel}
        fileLimitCount={fileLimitCount}
        fileLimitSizeMB={fileLimitSizeMB}
        institution_id={institutionId}
      />
    </>
  );
};
