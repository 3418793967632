import React from "react";
import {
  StyledPosition,
  PersonnelCardRoot,
  StyledCardContent,
  StyledName,
  StyledProfileImage,
} from "./styles";
import { StyledXMargin } from "pages/newPreQualify/components/personnelSelection/components/styles.js";
import BorderLessConciseTable from "components/filesListTable/borderLessConciseTable";
import { PlaceholderText, StyledBox } from "pages/newPreQualify/components/styles.js";
import { LetterAvatar } from "components/letterAvatar";
import XIcon from "assets/buyers/xIcon";

export const SelectedProjects = ({
  individual,
  setIndividual,
  projects,
  setProjects,
  openPopup,
  setOpenPopup,
  prequalifyData,
  setPrequalifyData,
  pickedArray,
  setPickedArray,
  filteredData,
  setFilteredData,
}) => {
  const removeProject = (e, index, bodyData) => {
    const temp = [...projects];
    temp.splice(index, 1);

    setProjects(temp);
    
    var pickedArrayIndex = pickedArray.indexOf(bodyData?.id);
    pickedArray.splice(pickedArrayIndex, 1);
    setPickedArray(pickedArray);
    if (bodyData?.id) {
      let allData = [...filteredData];
      if (!allData.find(({ id }) => id === bodyData.id)) {
        allData.push({
          id: bodyData.id,
          firstName: bodyData.firstName,
          lastName: bodyData.lastName,
          position: bodyData.position,
        });
      }

      setFilteredData(allData);
    }
  };


  const body = (bodyData) => {
    if (pickedArray && !pickedArray.includes(bodyData.id)) {
      setPickedArray((pickedArray) => [...pickedArray, bodyData.id]);
    }

    return (
      <PersonnelCardRoot>
        <StyledCardContent>
          <StyledProfileImage>
            <LetterAvatar name={bodyData.firstName} />
            <StyledXMargin
              onClick={() => removeProject(null, bodyData.index, bodyData)}
            >
              <XIcon />
            </StyledXMargin>
          </StyledProfileImage>
          <StyledName>
            {bodyData.firstName} {bodyData.lastName}
          </StyledName>
          <StyledPosition>{bodyData.position}</StyledPosition>
        </StyledCardContent>
      </PersonnelCardRoot>
    );
  };

  return (
    <>
      {projects.length === 0 && (
        <StyledBox boxHeight="140px">
          <PlaceholderText>Selected Personnel will appear here</PlaceholderText>
        </StyledBox>
      )}
      {projects.length > 0 && (
        <BorderLessConciseTable
          length={10}
          body={body}
          data={projects}
          horizontal={true}
          height={"130px"}
          width={"860px"}
          backgroundColor={"transparent"}
        />
      )}
    </>
  );
};

export default SelectedProjects;
