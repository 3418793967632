import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  ScreenRoot,
  DetailedFormContainer,
  SideContainer,
  LargeText,
  StyledSpan,
  MediumText,
  NoPrequalifiedContractors,
} from "../styles";
import { ContractorsList } from "./ContractorsList";
import { filterPrequaliedContractors } from "../../../api/buyers";
import { ContractorRecentCard } from "./RecentCard";

import {
  DetailContent,
  DetailArea,
  HeaderArea,
  TouchScrollDiv,
} from "./styles";
import { removePrequalifiedContractor } from "../../../data/actions/buyers";
import { useLocation } from "react-router";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";

function SeeAllPrequalifiedContractors() {
  const location = useLocation();
  const institution_id = location.state?.institution_id || "";
  const isMobile = useMediaQuery({ maxWidth: 480 });
  return (
    <ScreenRoot isMobile={isMobile}>
      <Contractors institution_id={institution_id} />
    </ScreenRoot>
  );
}

export default SeeAllPrequalifiedContractors;

export const Contractors = ({ noWidthChange, institution_id }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [filteredData, setFilteredData] = useState([]);
  const [internalSearchResults, setInternalSearchResults] =
    useState(filteredData);

  const dataRef = useRef(null);

  const currentDashboardUrl = localStorage.getItem("current_dashboard_url");

  const handleFilter = async (filterQuery) => {
    if (currentDashboardUrl === "/service-provider-dashboard") {
      dataRef.current = { institution_id: institution_id };
    }
    await filterPrequaliedContractors(dataRef.current, filterQuery)
      .then((data) => {
        setFilteredData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    handleFilter(query);
  }, []);

  //three dots button

  const removeContractor = (id) => {
    dispatch(
      removePrequalifiedContractor(
        { institution_id: institution_id, contractor_id: id },
        (e) => {
          handleFilter(query);
        },
        (e) => {
          // TODO: DISPLAY ERROR!
        }
      )
    );
  };

  if (filteredData && filteredData.length > 0) {
    return (
      <DetailedFormContainer isMobile={isMobile} noWidthChange={noWidthChange}>
        <HeaderArea>
          <LargeText isMobile={isMobile}>All Selected Contractors</LargeText>
          {/* {isMobile && (
              <IconButton
                onClick={handleAdd}
              >
                <AddNewButtonIcon />
              </IconButton>
            )} */}
          {!isMobile && (
            <HeaderArea width="50%" justifyContent="flex-end">
              {/* <FunctionalButton
                  buttonTitle="Add Project"
                  handleButton={handleAdd}
                  width="40%"
                /> */}
              {/* <SearchBar
                filterApi={filterPrequaliedContractors}
                setFilteredData={setFilteredData}
              /> */}
              <SearchBar
                filteredData={filteredData}
                setInternalSearchResults={setInternalSearchResults}
                internalSearch={true}
                query={query}
                setQuery={setQuery}
                searchWidth="60%"
                searchPlaceHolder="Search by Name,location,..."
              />
            </HeaderArea>
          )}
        </HeaderArea>
        {isMobile && (
          <TouchScrollDiv>
            {filteredData?.length > 0 &&
              filteredData
                .slice(-4)
                .reverse()
                .map((item, i) => {
                  return (
                    <ContractorRecentCard
                      item={item}
                      key={i}
                      // editFunc={editProject}
                      deleteFunc={removeContractor}
                      isMobile={isMobile}
                    />
                  );
                })}
          </TouchScrollDiv>
        )}
        {!isMobile && (
          <DetailArea>
            <MediumText>Recently Selected</MediumText>
            <DetailContent>
              {filteredData?.length > 0 &&
                filteredData
                  .slice(-4)
                  .reverse()
                  .map((item, i) => {
                    return (
                      <ContractorRecentCard
                        item={item}
                        key={i}
                        // editFunc={editProject}
                        deleteFunc={removeContractor}
                        isMobile={isMobile}
                      />
                    );
                  })}
            </DetailContent>
          </DetailArea>
        )}

        <DetailArea>
          {isMobile && (
            <SearchBar
              filteredData={filteredData}
              setInternalSearchResults={setInternalSearchResults}
              internalSearch={true}
              query={query}
              setQuery={setQuery}
              searchWidth="60%"
              searchPlaceHolder="Search by Name,location,..."
            />
          )}
          {!isMobile && <MediumText>List of Contractors</MediumText>}
          <ContractorsList
            filteredData={
              query && query !== "" ? internalSearchResults : filteredData
            }
            // editFunc={editProject}
            deleteFunc={removeContractor}
            isMobile={isMobile}
          />
        </DetailArea>
      </DetailedFormContainer>
    );
  } else {
    return (
      <DetailedFormContainer isMobile={isMobile} noWidthChange={noWidthChange}>
        <NoPrequalifiedContractors>
          No Selected Contractors
        </NoPrequalifiedContractors>
      </DetailedFormContainer>
    );
  }
};
