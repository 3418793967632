import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from "../../../constants";
import { Progress } from "semantic-ui-react";
import { Typography } from "@material-ui/core";

export const MobileBoxText = styled(Typography)`
  &&{
    color: ${COLORS.textGrey};
    font-family: ${TYPOGRAPHY.primaryFont}
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: center;
    margin-left: 8px;
  }
`;

export const FunctionArea = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isMobile ? "46%" : "24%")};
`;

export const IntroArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 14px;
  margin-right: 14px;
  margin-bottom: 14px;
  align-items: center;
  justify-content: space-evenly;
`;

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  min-width: 119px;
  justify-content: center;
  align-items: center;
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop};` : "")}
  margin-left: -8px;
`;

export const StyledProgress = styled(Progress)`
  &.ui.progress {
    width: 106px;
    height: 8px;
    background: ${COLORS.scrollbarTrack};
  }

  && {
    .bar {
      background: linear-gradient(
        270deg,
        rgba(255, 109, 29, 0.85) 0%,
        rgba(254, 198, 92, 0.85) 100%
      );
      height: 8px;
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`;

export const ComingSoonImg = styled.img`
  width: 85%;
  height: 85%;
`;

export const ImgDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
