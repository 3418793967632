import React from "react";
import { FunctionalButton } from 'components/functionalButton';
import { Popup } from 'components/popup';
import { CloseButton } from "components/closeButton";
import PledgxLogo from "assets/common/pledgxLogo";


import {
  TitleDiv,
  TitleText,
  PopupContainer,
  ButtonDiv
} from "./styles.js";


export const SubmitErrorMessage = ({
  openPopup,
  setOpenPopup,
  isMobile,
  errors
}) => {

  const handleDone = () => {
    setOpenPopup(false);
  };



  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width={isMobile ? "100%" : "650px"}
      height={isMobile ? "100vh" : "330px"}
      dialogContent={(
        <>
          <CloseButton handleClose={handleDone} />
          <PopupContainer>
            <TitleDiv>
              <PledgxLogo width={isMobile ? 75 : ""} height={isMobile ? 74 : ""} />
            </TitleDiv>

            <TitleText fontSize="20px" fontWeight="700" color="#1A051D" margin="20px 0px 39px" lineHeight="41px" textAlign="center">Oops! There is no owner's email or consultant's email. </TitleText>
            <br />
            <TitleText fontSize="18px" fontWeight="400" color="#1A051D" margin="-30px 0px 30px" lineHeight="41px" textAlign="center">Please edit your project information before sending for approval.</TitleText>

            <ButtonDiv isMobile={isMobile}>
              <FunctionalButton
                buttonTitle="GO BACK"
                handleButton={handleDone}
                isReversedColor={true}
                height="48px"
                width="233px"
                marginTop="0px"
                marginRight="16px"
              />
            </ButtonDiv>
          </PopupContainer>

        </>
      )}
    />

  )
}
