import React, { useState, useEffect } from 'react';
import LegalPersonnelPortrait from '../../../assets/preQualifyIcons/legalPersonnel';
import Divider from "@material-ui/core/Divider";
import plus from "../../../assets/preQualifyIcons/plusIcon.svg";
import {
  PersonnelCardName,
  PersonnelCardRoot,
  PersonnelCardPosition,
  ControlButtons,
  AddText,
  PlusIcon,
  StyledCardContent,
  StyledName,
  PersonnelAdding,
  StyledAddContent,
} from "./styles";


export const LegalPersonnel = ({
  firstName,
  lastName,
  position,
  cv,
  variant = "default",
  onClick,
  setPersonnel,
  setDialogFN,
  setDialogLN,
  setDialogPos,
  setDialogCV,
  setOpenDialog,
  setEditIndex,
  index,
  qualificationData,
}) => {
  if (variant === "default")
    return (
      <PersonnelCardRoot>
        <StyledCardContent>
          <LegalPersonnelPortrait />
          <StyledName>
            <PersonnelCardName>
              {firstName} {lastName}
            </PersonnelCardName>
            <PersonnelCardPosition>{position}</PersonnelCardPosition>
          </StyledName>
        </StyledCardContent>
        <Divider></Divider>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            minHeight: 38,
            alignItems: "center",
          }}
        >
          <ControlButtons
            onClick={() => {
              setDialogFN(firstName);
              setDialogLN(lastName);
              setDialogPos(position);
              setDialogCV && setDialogCV(cv);
              setOpenDialog(true);
              setEditIndex(index);
            }}
          >
            Edit
          </ControlButtons>
          <ControlButtons
            onClick={() => setPersonnel(qualificationData, firstName, lastName, position)}
          >
            Remove
          </ControlButtons>
        </div>
      </PersonnelCardRoot>
    );
  if (variant === "add")
    return (
      <PersonnelAdding onClick={onClick}>
        <StyledAddContent>
          <PlusIcon src={plus}></PlusIcon>
          <AddText>Add personnel</AddText>
        </StyledAddContent>
      </PersonnelAdding>
    );
}
