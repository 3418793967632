import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import updateObjectByPath from "../../common/updateObjectByPath";
import { getOnboarding, saveOnboarding } from '../../api/onboarding';

import { useDispatch } from "react-redux";

import { Stepper } from './Stepper';
import { Country } from './components/Country';
import { Contact } from "./components/Contact";
import { Company } from "./components/Company";

import CountryStep from '../../assets/onBoarding/Country';
import GeneralInfoStep from '../../assets/onBoarding/GeneralInfo';
import LegalStep from '../../assets/onBoarding/Legal';
import { useHistory } from "react-router";
import { phoneRegex } from "common/regex";

import {
  OnBoardRoot,
  FormContainer,
  Content,
} from "./styles";

function SwitchComponents({ active, children }) {
  return children.filter((child) => child.props.step === active);
};

function UserOnBoardingPage(props) {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const dispatch = useDispatch();
  const [onboardingData, setOnboardingData] = useState('');
  const [step, setStep] = useState(onboardingData?.step);
  const history = useHistory();

  const iconList = [
    CountryStep, 
    GeneralInfoStep, 
    LegalStep, 
  ];
  const stepLabels = [
    "Step 1",
    "Step 2",
    "Step 3",
  ];
  
  const [list, setList] = useState([iconList[0]])
  const [labels, setLabels] = useState([stepLabels[0]])
  const [panel, setPanel] = React.useState(1);
  const [isBranchPanel, setIsBranchPanel] = React.useState(false);
  const [isLastPanel, setIsLastPanel] = useState(false)
  const [previousStepPanel, setPreviousStepPanel] = useState('');
  
  useEffect(() => {
    getOnboardingData();
  }, []);

  const getOnboardingData =  () => {
   getOnboarding(onSuccess, onFailure);
  };

  const onSuccess = (data) => {
    if(data.status == "active"){
      history.push("/");
    }
    setOnboardingData(data); 
    setStep(data.step);
    if(isMobile) {
      setList([iconList[data.step]]);
      setLabels([stepLabels[data.step]]);
    } else {
    setList(iconList.splice(0, data.step + 1));
    setLabels(stepLabels.splice(0, data.step + 1));
    }
  }

  const onFailure = (data) => {}

  const onChangeData = (attribute, val) => {
    const updatedData = { ...onboardingData };
    updateObjectByPath(updatedData, attribute, val);
    setOnboardingData({ ...updatedData });
  };

  const handleNext = () => {
    if(isLastPanel){
      if (step < 3) {
        if (isMobile) {
          const tempList = [iconList[step+1]];
          setList(tempList)
          const tempLabels = [stepLabels[step+1]];
          setLabels(tempLabels);
        } else{
          const tempList = iconList.splice(0, step + 2);
          setList(tempList)
          const tempLabels = stepLabels.splice(0, step + 2);
          setLabels(tempLabels);
        }
        onChangeData('step', step + 1);
        setStep(step + 1);
        setPanel(1);
        // setIsLastPanel(false);
      }
    } else {
      setPanel(panel + 1);
    }
  }
  const [openPopup, setOpenPopup] = useState(false);
  const [openCongratsPopup, setOpenCongratsPopup] = useState(false)

  const [progress, setProgress] = useState(0)
  useEffect(() => {
    let temp_progress = 3;
    if (!(onboardingData?.country)){
      temp_progress = temp_progress - 1;
    }
    if (!(phoneRegex.test(onboardingData?.user_phone) && onboardingData?.user_title)){
      temp_progress = temp_progress - 1;
    }
    if (!(onboardingData?.company?.categories?.length > 0 &&
        onboardingData?.company?.address &&
        onboardingData?.company?.postal &&
        onboardingData?.company?.city &&
        onboardingData?.company?.province && 
        onboardingData?.company?.addressConfirmed)){
          temp_progress = temp_progress - 1;
    }
    setProgress(temp_progress)
  }, [onboardingData])

  const handleExit = () => {
    if (progress != 2){
      setOpenPopup(true);
    } else {
      saveOnboarding(onboardingData, () => setOpenCongratsPopup(true) , () => {});
    }
  }
 
  return (
    <OnBoardRoot isMobile={isMobile}>
      <FormContainer isMobile={isMobile}>
          <Stepper
            step={step}
            setStep={setStep}
            iconList={iconList}
            stepLabels={stepLabels}
            list={list}
            setList={setList}
            labels={labels}
            setLabels={setLabels}
            panel={panel}
            setPanel={setPanel}
            isLastPanel={isLastPanel}
            setIsLastPanel={setIsLastPanel}
            isBranchPanel={isBranchPanel}
            setIsBranchPanel={setIsBranchPanel}
            previousStepPanel={previousStepPanel}
            onboardingData={onboardingData}
            setOnboardingData={onChangeData}
            openPopup={openPopup}
            handleExit={handleExit}
            setOpenPopup={setOpenPopup}
            isMobile={isMobile}
            openCongratsPopup={openCongratsPopup}
            setOpenCongratsPopup={setOpenCongratsPopup}
            progress={progress}
          />
          <Content isMobile={isMobile}>
            <SwitchComponents active={step}>
              <Country
                step={0}
                handleNext={handleNext}
                onboardingData={onboardingData}
                setOnboardingData={onChangeData}
                setIsLastPanel={setIsLastPanel}
                setPreviousStepPanel={setPreviousStepPanel}
              />
              <Contact
                step={1}
                handleNext={handleNext}
                panel={panel}
                setPanel={setPanel}
                onboardingData={onboardingData}
                setOnboardingData={onChangeData}
                setIsLastPanel={setIsLastPanel}
                setPreviousStepPanel={setPreviousStepPanel}
              />
              <Company
                step={2}
                handleNext={handleNext}
                onboardingData={onboardingData}
                setOnboardingData={onChangeData}
                panel={panel}
                setPanel={setPanel}
                setIsLastPanel={setIsLastPanel}
                setPreviousStepPanel={setPreviousStepPanel}
                isBranchPanel={isBranchPanel}
                setIsBranchPanel={setIsBranchPanel}
                handleExit={handleExit}
              />
              {/* <Personnel 
                step={3}
                handleNext={handleNext}
                onboardingData={onboardingData}
                setOnboardingData={onChangeData}
                panel={panel}
                setPanel={setPanel}
                setIsLastPanel={setIsLastPanel}
                setPreviousStepPanel={setPreviousStepPanel}
              />
              <Projects 
                step={4}
                handleNext={handleNext}
                onboardingData={onboardingData}
                setOnboardingData={onChangeData}
                panel={panel}
                setPanel={setPanel}
                setIsLastPanel={setIsLastPanel}
                setPreviousStepPanel={setPreviousStepPanel}
                handleExit={handleExit}
              /> */}
            </SwitchComponents>
          </Content>

      </FormContainer>
    </OnBoardRoot>
  );
}

export default UserOnBoardingPage;
