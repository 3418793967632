import React from "react";
import Input from "../../../../components/inputField";

export const YearsNumber = ({ data, setData, type, year }) => {
  return (
    <>
      <Input
        label=""
        type="text"
        value={data?.[`${year}`]?.[`${type}`] ?? ""}
        marginTop="10px"
        labelSize="10px"
        setValue={(val) =>
          setData(`annual.safety_records.${year}.${type}`, val)
        }
        validateFunc={(item) => {
          if (String(item).length === 0) {
            return false;
          }

          if (type == "classCode") {
            return String(item).length > 0;
          }

          // console.log(item);

          return item > -1;
        }}
        noCheckIcon={true}
        noErrorBorder={true}
      />
      <Input
        label=""
        type="text"
        value={data?.[`${year - 1}`]?.[`${type}`] ?? ""}
        marginTop="10px"
        labelSize="10px"
        setValue={(val) =>
          setData(`annual.safety_records.${year - 1}.${type}`, val)
        }
        validateFunc={(item) => {
          if (String(item).length === 0) {
            return false;
          }

          if (type == "classCode") {
            return String(item).length > 0;
          }

          return item > -1;
        }}
        noCheckIcon={true}
        noErrorBorder={true}
      />
      <Input
        label=""
        type="text"
        value={data?.[`${year - 2}`]?.[`${type}`] ?? ""}
        marginTop="10px"
        labelSize="10px"
        setValue={(val) =>
          setData(`annual.safety_records.${year - 2}.${type}`, val)
        }
        validateFunc={(item) => {
          if (String(item).length === 0) {
            return false;
          }

          if (type == "classCode") {
            return String(item).length > 0;
          }

          return item > -1;
        }}
        noCheckIcon={true}
        noErrorBorder={true}
      />
    </>
  );
};
