import React from "react";
import { Popup } from '../popup';
import { Steps } from './steps';

export const AddClientPopup = ({
  setOpenPopup,
  openPopup,
  data,
  setData,
  initialSubCategory,
  isMobile,
  handleButtonFunc
 }) => {
  return(
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width="650px"
      height="661px"
      dialogContent={(
        <Steps
          isMobile={isMobile}
          setOpenPopup={setOpenPopup}
          onboardingData={data}
          setOnboardingData={setData}
          initialSubCategory={initialSubCategory}
          handleButtonFunc={handleButtonFunc}
        />
      )}
    />
  )
 }
