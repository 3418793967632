import React, { useState} from "react";
import { 
  BubbleDiv,
  BubbleText,
  ChoiceArea,
  ImgContainer,
  ButtonText 
} from './styles';
import dots from 'assets/tutorialBubble/dot.svg';

export const TutorialBubble = ({
  text,
  handleButton
}) => {

  return (
    <BubbleDiv>
      <BubbleText>{text}</BubbleText>
      <ChoiceArea>
        <ImgContainer src={dots} />
        <ButtonText paddingTop="8px" margin=" 0 8px 0 0" onClick={handleButton}>DONE</ButtonText>
      </ChoiceArea>
    </BubbleDiv>
  )
}