import React, { useState, useEffect } from 'react';

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper,
  StyledMenuItem,
  StyledButton,
  StyledButtonNew,
  StyledButtonContainer
} from './styles';

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import PledgxLogo from '../../assets/common/pledgxLogo';
import { tamplateSingleCCDC } from '../../api/prequalify';

export const AddCcdcTemplatePopUp = ({
  setOpenDialog,
  openDialog,
  item,
  handleSeeAll,
  isMobile
}) => {

  const tamplateCCDC = (id) => {
    tamplateSingleCCDC(id, onSuccessTemplate, () => { });
  }

  const onSuccessTemplate = (res) => {
    if (res?.status === "success") {
      handleSeeAll();
    }
  }

  useEffect(() => {
    if (!openDialog) {
      setOpenDialog(false);
    }
  }, [openDialog]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  // add handle Go Back Button
  const handleGoBackClick = () => {
    handleClose();
  };


  // add handle Download Button
  const handleCreateNewClick = () => {
    tamplateCCDC(item ? item?.id : '');
    handleClose();
  };


  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper
        elevation={0}
        isMobile={isMobile}
      >
        <CloseButton
          aria-label="close"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </CloseButton>

        <PledgxLogo width={isMobile ? 45 : ''} height={isMobile ? 45 : ''} />
        <InfoArea isMobile={isMobile} marginTop="0px">
          <FormTitle style={{ marginTop: 20, marginBottom: 20 }}>{'Editing will create a draft using the existing file and its data'}</FormTitle>
        </InfoArea>

        <StyledButtonContainer>
          <StyledMenuItem>
            <StyledButton title="Go Back" width="150px" onClick={handleGoBackClick}
            > Go Back
            </StyledButton>
          </StyledMenuItem>

          <StyledMenuItem>
            <StyledButtonNew title="Create New" width="150px" onClick={handleCreateNewClick}
            > Create New
            </StyledButtonNew>
          </StyledMenuItem>

        </StyledButtonContainer>
      </StyledPaper>
    </Dialog>
  )

}