// export const yAxisGradient = {
//   tagName: 'linearGradient',
//   id: 'gradient_yAxis',
//   x1: 0,
//   y1: 0,
//   x2: 0,
//   y2: 1,
//   children: [
//     { tagName: 'stop', offset: '0%', style: 'stop-color:#43B868;' },
//     { tagName: 'stop', offset: '51.04%', style: 'stop-color:#F5D632;' },
//     { tagName: 'stop', offset: '100%', style: 'stop-color:#F52D2D;' }
//   ]
// };

// export const xAxisGradient = {
//   tagName: 'linearGradient',
//   id: 'gradient_xAxis',
//   x1: 0,
//   y1: 0,
//   x2: 1,
//   y2: 0,
//   children: [
//     { tagName: 'stop', offset: '0%', style: 'stop-color:#43B868;' },
//     { tagName: 'stop', offset: '51.04%', style: 'stop-color:#F5D632;' },
//     { tagName: 'stop', offset: '100%', style: 'stop-color:#F52D2D;' }
//   ]
// };

import React from 'react';

export const yAxisGradient = (
  <linearGradient id="gradient_yAxis" x1={0} y1={0} x2={0} y2={1}>
    <stop offset="0%" stopColor="#43B868" />
    <stop offset="51.04%" stopColor="#F5D632" />
    <stop offset="100%" stopColor="#F52D2D" />
  </linearGradient>
);

export const xAxisGradient = (
  <linearGradient id="gradient_xAxis" x1={0} y1={0} x2={1} y2={0}>
    <stop offset="0%" stopColor="#43B868" />
    <stop offset="51.04%" stopColor="#F5D632" />
    <stop offset="100%" stopColor="#F52D2D" />
  </linearGradient>
);
