import React from "react";
import Slider from 'components/slider';
import ScoreQuality from "assets/reference/scoreQuality";
import ScoreOrganization from "assets/reference/scoreOrganization";
import ScoreExecution from "assets/reference/scoreExecution";
import ScoreAdministration from "assets/reference/scoreAdministration";
import ScoreSafety from "assets/reference/scoreSafety";

import { 
  StyledBox,
  DataText
} from '../styles';


export const Scores = ({
  isMobile,
  scores
}) => { 
   
  return (
      <StyledBox boxHeight="550px" hasData={true}>
        <DataText fontSize="24px" fontWeight="500" margin="24px 0px 0px 32px">Scores</DataText>
        <Slider 
           value={scores?.quality}
           setValue={() => {}}
           header={"Quality"}
           maxTen={true}
           width="85%"
           margin="24px 32px 0px"
           headerFontSize="18px"
           headerMarginBottom="4px"
           noThumb={true}
           icon={<ScoreQuality />}
           disabled={true}
        />
        <Slider 
           value={scores?.organization}
           setValue={() => {}}
           header={"Organization"}
           maxTen={true}
           width="85%"
           margin="16px 32px 0px"
           headerFontSize="18px"
           headerMarginBottom="4px"
           noThumb={true}
           icon={<ScoreOrganization />}
           disabled={true}
        />
        <Slider 
           value={scores?.execution}
           setValue={() => {}}
           header={"Execution"}
           maxTen={true}
           width="85%"
           margin="16px 32px 0px"
           headerFontSize="18px"
           headerMarginBottom="4px"
           noThumb={true}
           icon={<ScoreExecution />}
           disabled={true}
        />
        <Slider 
           value={scores?.administration}
           setValue={() => {}}
           header={"Administration"}
           maxTen={true}
           width="85%"
           margin="16px 32px 0px"
           headerFontSize="18px"
           headerMarginBottom="4px"
           noThumb={true}
           icon={<ScoreAdministration />}
           disabled={true}
        />
        <Slider 
           value={scores?.safety}
           setValue={() => {}}
           header={"Safety"}
           maxTen={true}
           width="85%"
           margin="16px 32px 0px"
           headerFontSize="18px"
           headerMarginBottom="4px"
           noThumb={true}
           icon={<ScoreSafety />}
           disabled={true}
        />
      </StyledBox>      
  )
};