import React, { useEffect, useRef, useState } from "react";
import { FloatingButton } from "../../../components/floatingButton";
import { CommonBusinessType } from "components/commonBusinessType/index.js";
import RadioGroupButtonForProjectCategory from "components/radioGroupButtonForProjectCategory";
import updateObjectByPath from "../../../common/updateObjectByPath";
import { AddressAutoFields } from "../../contractorDashboard/profileSetup/AddressAutoFields";
import Select from "../../../components/selectField";
import Input from "../../../components/inputField";
import { SuccessPopup } from "./SuccessPopup";
import { getCorporates, getPackages } from "../../../api/external_data";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { useGeneralContractorList } from "components/bidsAndTendersDataAccess";

import {
  LargeText,
  FieldArea,
  StyledRow,
  SectionText,
  SetupContainer,
  ErrorText,
  SectionLabelText,
  StyledAddressContainer,
} from "../styles.js";

import {
  getAssociations,
  getUnions,
  getAgents,
} from "../../../api/external_data";
import { getCountryName } from "../../../common/countryName";
import countriesInArray from "../../../assets/locale/countriesInArray";
import fetchStatesProvinces from "../../../assets/locale/stateProvinceInArray.js";
import { emailRE } from "../../../common/regex";
import { onboardingRegister } from "../../../api/onboarding";

export default function UserOnboardingCreate(isMobile) {
  const [data, setData] = useState({
    user_type: "Contractor",
    first_name: "",
    last_name: "",
    user_phone: "",
    company_phone: "",
    user_email: "",
    user_country: "",
    user_role: "Admin",
    company_name: "",
    company_website_url: "",
    company_email: "",
    company_business_number: "",
    categories: [],
    company: {},
    operational_region: {},
    establishment_year: "",
    venture_type: "",
    user_corporate_account_id: "",
    user_agent_account_id: "",
    user_corporate_name: "",
    user_agent_name: "",
    user_package_name: "",
    number_of_rfpq: "",
    document_pool_size: "",
    association: "",
    union: "",
  });
  const [userType, setUserType] = useState("Contractor");
  const [hasError, setHasError] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const currentYear = new Date().getFullYear();
  const saveButtonRef = useRef();
  const [userPhoneNumber, setPhoneNumber] = React.useState(data?.user_phone);
  const [companyPhoneNumber, setCompanyPhoneNumber] = React.useState(
    data?.company_phone
  );
  const [statesProvinces, setStatesProvinces] = useState([]);
  let stateValue = "State";
  const [stateProvince, setStateProvince] = useState(stateValue);
  const [containsUNSPC, setContainsUNSPC] = useState(
    data?.is_unspc_category_type || false
  );

  // Calling and Testing B&T AI model data
  // console.log("Seleted category:",data?.categories[0]?.main_categories);
  // const returnData = useGeneralContractorList(data?.categories[0]?.main_categories);
  // console.log("Return Data:",returnData);
  // const contractorNames = returnData.map(item => item.contractor_name);
  // console.log("contractorNames:",contractorNames);

  const confirmRadioChange = (val) => {
    if (val === 1) {
      setContainsUNSPC(false);
    } else {
      setContainsUNSPC(true);
    }
  };

  const handleUserPhoneNumberChange = (value) => {
    setData({ ...data, user_phone: value });
  };

  const handleCompanyPhoneNumberChange = (value) => {
    setData({ ...data, company_phone: value });
  };

  const ventureOptions = [
    "Joint Venture",
    "Corporation",
    "Partnership",
    "Registered",
    "Sole Proprietor",
    "Other",
  ];

  const userRoleOptions = [
    "User",
    "Admin",
  ];

  const userOptions = [
    "Contractor",
    "Contractor & Buyer",
    "Buyer",
    "Underwriter",
    "Agent/Broker",
  ];

  const documentPoolSize = ["10MB", "50MB", "100MB", "1024MB(1GB)"];

  const selectCountry = (value) => {
    if (value) {
      onChangeData("user_country", value);
    }
  };

  const selectOperationalRegionCountry = (value) => {
    if (value) {
      onChangeData("operational_region.country", value);
      if (getCountryName(value) === "Canada") {
        setStateProvince("Province");
      } else if (getCountryName(value) === "United States") {
        setStateProvince("State");
      } else {
        setStateProvince("State/Province");
      }
      const fetchedStatesProvinces = fetchStatesProvinces(
        getCountryName(value)
      );
      setStatesProvinces(fetchedStatesProvinces);
      onChangeData("operational_region.province", fetchedStatesProvinces[0]);
    }
  };

  const handleSave = () => {
    onboardingRegister(
      data,
      () => {
        setOpenSuccessPopup(true);
      },
      (err) => {
        setHasError(true);
      }
    );
  };

  const onChangeData = (attribute, val) => {
    const isBusinessCategoryAttribute = attribute === "categories";
    if (isBusinessCategoryAttribute) {
      const updatedData = {
        ...data,
        categories: val,
        is_unspc_category_type: containsUNSPC,
      };
      setData(updatedData);
      return;
    }

    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };

  useEffect(() => {
    setHasError(false);
  }, []);

  return (
    <SetupContainer isMobile={isMobile}>
      <FloatingButton
        buttonTitle="Save"
        handleButton={handleSave}
        width="150px"
        height="38px"
        marginRight="100px"
      />

      <StyledRow justifyContent="space-between" ref={saveButtonRef}>
        <LargeText isMobile={isMobile}>
          {isMobile ? "Add Client Information" : "Add your Client Information"}
        </LargeText>
      </StyledRow>

      {hasError && (
        <ErrorText>
          Oops! It looks like this user's email has already been resgistered.
        </ErrorText>
      )}

      <FieldArea>
        <SectionText>Select User Type</SectionText>
        <div className="fullWidthInputWrap">
          <Select
            style={{ marginRight: "16px" }}
            options={userOptions?.map((item) => ({
              value: item,
              label: item,
            }))}
            value={data?.user_type || userOptions[0]}
            setValue={(val) => {
              onChangeData("user_type", val);
              setUserType(val);
            }}
            validateFunc={(item) => Boolean(item)}
            noCheckIcon={true}
            showDropdownIndicator={true}
          />
        </div>
      </FieldArea>

      <FieldArea>
        <SectionText>Admin User Information</SectionText>
        <Input
          id="inputWrap"
          label="First Name"
          type="text"
          placeholder="First Name"
          labelSize="12px"
          fontSize="18px"
          value={data?.first_name}
          setValue={(val) => onChangeData("first_name", val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
        />
        <Input
          id="inputWrap last"
          label="Last Name"
          type="text"
          placeholder="Last Name"
          labelSize="12px"
          fontSize="18px"
          value={data?.last_name}
          setValue={(val) => onChangeData("last_name", val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
        />

        <StyledAddressContainer>
          <div
            style={{
              marginTop: "24px",
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
            }}
          >
            <label htmlFor="phone" style={{ fontSize: "12px" }}>
              Phone Number
            </label>
            <div style={{ height: "48px", width: "100%", marginTop: "-10px" }}>
              <PhoneInput
                id="phone"
                country={"ca"}
                value={userPhoneNumber}
                onChange={handleUserPhoneNumberChange}
                style={{ marginTop: "16px", width: "100%", height: "100%" }}
                inputStyle={{
                  fontSize: "18px",
                  padding: "8px",
                  lineHeight: "24px",
                  marginLeft: "20px",
                  paddingLeft: "28px",
                  height: "100%",
                  width: "93%",
                  marginRight: "20px",
                }}
                dropdownStyle={{ fontSize: "18px", lineHeight: "24px" }}
              />
            </div>
          </div>

          <Input
            id="fullWidthInputWrap"
            label="User's Email*"
            type="text"
            placeholder="user@company.com"
            labelSize="12px"
            fontSize="18px"
            value={data?.user_email}
            setValue={(val) => onChangeData("user_email", val)}
            validateFunc={(item) => emailRE.test(item)}
            hasError={hasError}
            noCheckIcon={true}
            style={{ marginLeft: "-14px" }}
          />
        </StyledAddressContainer>

        <StyledAddressContainer>
          <div className="fullWidthInputWrap">
            <Select
              style={{ marginRight: "16px" }}
              label="Country"
              options={countriesInArray?.map((country) => ({
                value: country.countryCode,
                label: country.countryName,
              }))}
              value={
                data?.user_country
                  ? getCountryName(data?.user_country)
                  : "United States"
              }
              setValue={(value) => selectCountry(value)}
              validateFunc={(item) => Boolean(item)}
              noCheckIcon={true}
              showDropdownIndicator={true}
            />
          </div>

          <div className="fullWidthInputWrap">
            <Select
              style={{ marginRight: "16px" }}
              label="User Role"
              options={userRoleOptions?.map((item) => ({
                value: item,
                label: item,
              }))}
              value={data?.user_role}
              setValue={(val) => onChangeData("user_role", val)}
              validateFunc={(item) => Boolean(item)}
              noCheckIcon={true}
              showDropdownIndicator={true}
            />
          </div>
        </StyledAddressContainer>
      </FieldArea>

      <FieldArea>
        <SectionText>Business Information</SectionText>

        {userType === "Agent/Broker" && (
          <>
            <StyledAddressContainer>
              <Input
                id="inputWrap"
                label="Agent Company Name*"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={data?.company_name}
                setValue={(val) => onChangeData("company_name", val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                style={{ marginRight: "-16px" }}
              />

              <Input
                id="inputWrap last"
                label="Company Website"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={data?.company_website_url}
                setValue={(val) => onChangeData("company_website_url", val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
              />
            </StyledAddressContainer>
          </>
        )}

        {userType !== "Agent/Broker" && (
          <>
            <StyledAddressContainer>
              <Input
                id="inputWrap"
                label="Company Name"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={data?.company_name}
                setValue={(val) => onChangeData("company_name", val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                style={{ marginRight: "-16px" }}
              />

              <Input
                id="inputWrap last"
                label="Company Website"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={data?.company_website_url}
                setValue={(val) => onChangeData("company_website_url", val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
              />
            </StyledAddressContainer>
          </>
        )}
        <StyledAddressContainer>
          <div
            style={{
              marginTop: "24px",
              width: "100%",
              marginLeft: "0px",
              marginRight: "0px",
            }}
          >
            <label htmlFor="phone" style={{ fontSize: "12px" }}>
              Phone Number
            </label>
            <div style={{ height: "48px", width: "100%", marginTop: "-10px" }}>
              <PhoneInput
                id="phone"
                country={"ca"}
                value={companyPhoneNumber}
                onChange={handleCompanyPhoneNumberChange}
                style={{ marginTop: "16px", width: "100%", height: "100%" }}
                inputStyle={{
                  fontSize: "18px",
                  padding: "8px",
                  lineHeight: "24px",
                  marginLeft: "20px",
                  paddingLeft: "28px",
                  height: "100%",
                  width: "93%",
                  marginRight: "20px",
                }}
                dropdownStyle={{ fontSize: "18px", lineHeight: "24px" }}
              />
            </div>
          </div>
          <Input
            id="fullWidthInputWrap"
            label="Company Email"
            type="text"
            placeholder="abc@company.com"
            labelSize="12px"
            fontSize="18px"
            value={data?.company_email}
            setValue={(val) => onChangeData("company_email", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            style={{ marginLeft: "-14px" }}
          />
        </StyledAddressContainer>
      </FieldArea>

      <FieldArea>
        <SectionText>Company Identification</SectionText>
        <Input
          id="fullWidthInputWrap "
          label="Business Number"
          type="text"
          placeholder="000000"
          labelSize="12px"
          fontSize="18px"
          value={data?.company_business_number}
          setValue={(val) => onChangeData("company_business_number", val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
        />
      </FieldArea>

      <FieldArea isMobile={isMobile} width={isMobile ? "100%" : "66.67%"}>
        {(userType === "Contractor" || userType === "Contractor & Buyer") && (
          <>
            <SectionText>Business Categories</SectionText>
            <RadioGroupButtonForProjectCategory
              containsUNSPC={containsUNSPC}
              confirmRadioChange={confirmRadioChange}
            />

            <StyledRow isMobile={isMobile}>
              <CommonBusinessType
                data={data}
                setData={onChangeData}
                isMobile={isMobile}
                notPopup={true}
                containsUNSPC={containsUNSPC}
              />
            </StyledRow>
          </>
        )}
        <SectionText marginTop="40px">Company Address</SectionText>
        <AddressAutoFields data={data} setData={onChangeData} />
      </FieldArea>
      <FieldArea>
        {(userType === "Contractor" || userType === "Contractor & Buyer") && (
          <>
            <SectionText marginTop="40px">Operational Region</SectionText>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div className="inputWrap">
                <Select
                  style={{ marginRight: "16px" }}
                  label="Country"
                  options={countriesInArray?.map((country) => ({
                    value: country.countryCode,
                    label: country.countryName,
                  }))}
                  value={
                    getCountryName(data?.operational_region?.country)
                      ? getCountryName(data?.operational_region?.country)
                      : ""
                  }
                  setValue={(value) => selectOperationalRegionCountry(value)}
                  validateFunc={(item) => Boolean(item)}
                  noCheckIcon={true}
                  showDropdownIndicator={true}
                />
              </div>

              {statesProvinces && statesProvinces.length > 0 && (
                <div className="inputWrap">
                  <Select
                    style={{ marginRight: "16px" }}
                    label={stateProvince}
                    options={statesProvinces.map((stateProvince) => ({
                      value: stateProvince,
                      label: stateProvince,
                    }))}
                    value={
                      data?.operational_region?.province
                        ? data?.operational_region?.province
                        : ""
                    }
                    setValue={(value) =>
                      onChangeData("operational_region.province", value)
                    }
                    validateFunc={(item) => Boolean(item)}
                    noCheckIcon={true}
                    isMulti={true}
                    showDropdownIndicator={true}
                  />
                </div>
              )}

              {statesProvinces && statesProvinces.length === 0 && (
                <Input
                  id="inputWrap"
                  label={stateProvince}
                  type="text"
                  placeholder=""
                  labelSize="12px"
                  fontSize="18px"
                  value={
                    data?.operational_region?.province
                      ? data?.operational_region?.province
                      : ""
                  }
                  setValue={(val) =>
                    onChangeData("operational_region.province", val)
                  }
                  validateFunc={(item) => item.length > 0}
                  noCheckIcon={true}
                  marginRight="18px"
                />
              )}

              <Input
                id="inputWrap"
                label="Region"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={data?.operational_region?.region}
                setValue={(val) =>
                  onChangeData("operational_region.region", val)
                }
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
              />
            </div>
          </>
        )}
      </FieldArea>
      <FieldArea>
        <SectionText marginTop="40px">General</SectionText>
        <div className="fullWidthInputWrap">
          <Select
            style={{ marginRight: "16px" }}
            label="Year Established"
            options={Array.from(
              { length: currentYear - 1899 },
              (_, i) => currentYear - i
            )?.map((item) => ({ value: item, label: item }))}
            value={data?.establishment_year}
            setValue={(val) => onChangeData("establishment_year", val)}
            validateFunc={(item) => Boolean(item)}
            noCheckIcon={true}
            showDropdownIndicator={true}
          />
        </div>
        <div className="fullWidthInputWrap">
          <Select
            style={{ marginRight: "16px" }}
            label="Venture Type"
            options={ventureOptions?.map((item) => ({
              value: item,
              label: item,
            }))}
            value={data?.venture_type}
            setValue={(val) => onChangeData("venture_type", val)}
            validateFunc={(item) => Boolean(item)}
            noCheckIcon={true}
            showDropdownIndicator={true}
          />
        </div>
      </FieldArea>
      <FieldArea isMobile={isMobile} width={isMobile ? "100%" : "66.67%"}>
        <SectionText>Additional Information</SectionText>

        {userType !== "Buyer" &&
          userType !== "Underwriter" &&
          userType !== "Agent/Broker" && (
            <>
              <StyledAddressContainer>
                <Input
                  id="inputWrap"
                  label="Underwriter Account Id"
                  type="text"
                  placeholder="Underwriter Account Id"
                  labelSize="12px"
                  fontSize="18px"
                  value={data?.user_corporate_account_id}
                  setValue={(val) =>
                    onChangeData("user_corporate_account_id", val)
                  }
                  validateFunc={(item) => item.length > 0}
                  noCheckIcon={true}
                />

                <div className="inputWrap last">
                  <CorporateDropdown data={data} onChangeData={onChangeData} />
                </div>
              </StyledAddressContainer>
            </>
          )}

        {userType === "Contractor" && (
          <>
            <StyledAddressContainer>
              <Input
                id="inputWrap"
                label="Agent Account Id"
                type="text"
                placeholder="Agent Account Id"
                labelSize="12px"
                fontSize="18px"
                value={data?.user_agent_account_id}
                setValue={(val) => onChangeData("user_agent_account_id", val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
              />

              <div className="inputWrap last">
                <AgentDropdown data={data} onChangeData={onChangeData} />
              </div>
            </StyledAddressContainer>

            <StyledAddressContainer>
              <div className="fullWidthInputWrap">
                <PackageDropdown data={data} onChangeData={onChangeData} />
              </div>
            </StyledAddressContainer>
          </>
        )}

        {userType === "Contractor & Buyer" && (
          <>
            <StyledAddressContainer>
              <Input
                id="inputWrap"
                label="Number of RFP/Q"
                type="text"
                placeholder="Number of RFP/Q"
                labelSize="12px"
                fontSize="18px"
                value={data?.number_of_rfpq}
                setValue={(val) => onChangeData("number_of_rfpq", val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
              />

              <div className="inputWrap last">
                <Select
                  style={{ marginRight: "16px" }}
                  label="Document Pool Size"
                  options={documentPoolSize?.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  value={data?.document_pool_size || documentPoolSize[0]}
                  setValue={(val) => {
                    onChangeData("document_pool_size", val);
                  }}
                  validateFunc={(item) => Boolean(item)}
                  noCheckIcon={true}
                  showDropdownIndicator={true}
                />
              </div>
            </StyledAddressContainer>

            <StyledAddressContainer>
              <Input
                id="inputWrap"
                label="Agent Account Id"
                type="text"
                placeholder="Agent Account Id"
                labelSize="12px"
                fontSize="18px"
                value={data?.user_agent_account_id}
                setValue={(val) => onChangeData("user_agent_account_id", val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
              />

              <div className="inputWrap last">
                <AgentDropdown data={data} onChangeData={onChangeData} />
              </div>
            </StyledAddressContainer>

            <StyledAddressContainer>
              <div className="fullWidthInputWrap">
                <PackageDropdown data={data} onChangeData={onChangeData} />
              </div>
            </StyledAddressContainer>
          </>
        )}

        {userType === "Buyer" && (
          <>
            <StyledAddressContainer>
              <Input
                id="inputWrap"
                label="Number of RFP/Q"
                type="text"
                placeholder="Number of RFP/Q"
                labelSize="12px"
                fontSize="18px"
                value={data?.number_of_rfpq}
                setValue={(val) => onChangeData("number_of_rfpq", val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
              />

              <div className="inputWrap last">
                <Select
                  style={{ marginRight: "16px" }}
                  label="Document Pool Size"
                  options={documentPoolSize?.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  value={data?.document_pool_size || documentPoolSize[0]}
                  setValue={(val) => {
                    onChangeData("document_pool_size", val);
                  }}
                  validateFunc={(item) => Boolean(item)}
                  noCheckIcon={true}
                  showDropdownIndicator={true}
                />
              </div>
            </StyledAddressContainer>

            <StyledAddressContainer>
              <div className="inputWrap">
                <AgentDropdown data={data} onChangeData={onChangeData} />
              </div>
            </StyledAddressContainer>
          </>
        )}

        <StyledAddressContainer>
          <div className="fullWidthInputWrap">
            <AssociationDropdown data={data} onChangeData={onChangeData} />
          </div>
          <div className="fullWidthInputWrap">
            <UnionDropdown data={data} onChangeData={onChangeData} />
          </div>
        </StyledAddressContainer>
      </FieldArea>

      <SuccessPopup
        setOpenPopup={setOpenSuccessPopup}
        openPopup={openSuccessPopup}
        isMobile={isMobile}
      />
    </SetupContainer>
  );
}

const AssociationDropdown = ({ data, onChangeData }) => {
  const [associations, setAssociations] = useState([]);

  useEffect(() => {
    getAssociations()
      .then((res) => {
        setAssociations(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  return (
    <Select
      style={{ marginRight: "16px" }}
      label="Choose Your Construction Association"
      options={associations?.map((item) => ({
        value: item.name,
        label: item.name,
      }))}
      value={data?.association}
      setValue={(val) => onChangeData("association", val)}
      validateFunc={(item) => Boolean(item)}
      noCheckIcon={true}
      showDropdownIndicator={true}
    />
  );
};

const UnionDropdown = ({ data, onChangeData }) => {
  const [unions, setUnions] = useState([]);

  useEffect(() => {
    getUnions()
      .then((res) => {
        setUnions(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  return (
    <Select
      style={{ marginRight: "16px" }}
      label="Choose Your Construction Union"
      options={unions?.map((item) => ({
        value: item.name,
        label: item.name,
      }))}
      value={data?.union}
      setValue={(val) => onChangeData("union", val)}
      validateFunc={(item) => Boolean(item)}
      noCheckIcon={true}
      showDropdownIndicator={true}
    />
  );
};

const PackageDropdown = ({ data, onChangeData }) => {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    getPackages()
      .then((res) => {
        setPackages(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  return (
    <Select
      style={{ marginRight: "16px" }}
      label="Choose Your Package"
      options={packages?.map((item) => ({
        value: item.package_name,
        label: item.package_name,
      }))}
      value={data?.user_package_name}
      setValue={(val) => onChangeData("user_package_name", val)}
      validateFunc={(item) => Boolean(item)}
      noCheckIcon={true}
      showDropdownIndicator={true}
    />
  );
};

const CorporateDropdown = ({ data, onChangeData }) => {
  const [corporates, setCorporates] = useState([]);

  useEffect(() => {
    getCorporates()
      .then((res) => {
        setCorporates(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  return (
    <Select
      style={{ marginRight: "16px" }}
      label="Choose Your Underwriter Name"
      options={corporates?.map((item) => ({
        value: item.corporate_name,
        label: item.corporate_name,
      }))}
      value={data?.user_corporate_name}
      setValue={(val) => onChangeData("user_corporate_name", val)}
      validateFunc={(item) => Boolean(item)}
      noCheckIcon={true}
      showDropdownIndicator={true}
    />
  );
};

const AgentDropdown = ({ data, onChangeData }) => {
  const [agent, setAgent] = useState([]);

  useEffect(() => {
    getAgents()
      .then((res) => {
        setAgent(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  return (
    <Select
      style={{ marginRight: "16px" }}
      label="Choose Your Agent Name"
      options={agent?.map((item) => ({
        value: item.agent_name,
        label: item.agent_name,
      }))}
      value={data?.user_agent_name}
      setValue={(val) => onChangeData("user_agent_name", val)}
      validateFunc={(item) => Boolean(item)}
      noCheckIcon={true}
      showDropdownIndicator={true}
    />
  );
};
