import React, { useState } from "react";

import IncrementalSearchPickList from "components/incrementalSearchPickList";
import { StyledTableCell } from "components/filesListTable/styles";
import RfpqReviewPopUp from "../../rfpqReviewPopUp";
import { ReactComponent as ViewIcon } from "../../../../../assets/common/ViewIcon.svg";

export const SelectingProjects = ({
  add,
  object,
  setObject,
  type,
  filteredData,
  setFilteredData,
  filterApi,
  project,
  pickedArray,
  disabled,
}) => {
  const [rfpqReviewPopup, setRfpqReviewPopup] = useState(false);
  const [newRfpqId, setNewRfpqId] = useState(null);
  const handleFinancialSelection = (bodyData) => {
    if (object) return;
    const filteredTemp = [...filteredData];
    const temp = {
      address: bodyData.address,
      city: bodyData.city,
      company_name: bodyData.company_name,
      contact_email: bodyData.contact_email,
      contact_first_name: bodyData.contact_first_name,
      contact_last_name: bodyData.contact_last_name,
      contact_phone: bodyData.contact_phone,
      default: bodyData.default,
      has_ins: bodyData.has_ins,
      institution_id: bodyData.institution_id,
      postal: bodyData.postal,
      province: bodyData.province,
      ref_id: bodyData.ref_id ? bodyData.ref_id : "",
      suite_number: bodyData.suite_number,
      type: type,
      has_ins: bodyData.has_ins,
    };

    setObject(temp);
    filteredTemp.splice(bodyData.index, 1);
    setFilteredData(filteredTemp);
  };

  const handleProjectSelection = (bodyData) => {
    if (object) return;

    const filteredTemp = [...filteredData];
    const temp = {
      rfpq_id: bodyData.id,
      project_title: bodyData?.project?.title,
      project_number: bodyData?.project?.number,
      project_city: bodyData?.project?.location,
      project_closing_date: bodyData?.project?.closing_date,
      // owner_name: bodyData?.institution?.name,
      // owner_email: bodyData?.institution?.owner_email,
      // owner_phone: bodyData?.institution?.owner_phone,
      // owner_postal_code: bodyData?.institution?.owner_postal_code,
      // owner_province: bodyData?.institution?.owner_province,
      // owner_country: bodyData?.institution?.owner_country,
      // owner_suite: bodyData?.institution?.owner_suite,
    };

    setObject(temp);
    filteredTemp.splice(bodyData.index, 1);
    setFilteredData(filteredTemp);
  };

  const handleRfpqPreview = (rfpqId) => {
    setRfpqReviewPopup(true);
    setNewRfpqId(rfpqId);
  };
  const body = (bodyData) => {
    const address = `${bodyData?.address || ""}${bodyData?.address ? ", " : ""}\
    ${bodyData?.suite_number ? `suite ${bodyData?.suite_number}` : ""}${
      bodyData?.suite_number && bodyData?.city ? ", " : ""
    }\
    ${bodyData?.city || ""}${bodyData?.city && bodyData?.province ? ", " : ""}\
    ${bodyData?.province || ""}${bodyData?.postal ? " " : ""}${
      bodyData?.postal || ""
    }`;

    return (
      <>
        <StyledTableCell
          component={"tr"}
          onClick={() => handleFinancialSelection(bodyData)}
        >
          {bodyData.company_name}
        </StyledTableCell>
        <StyledTableCell
          component={"tr"}
          onClick={() => handleFinancialSelection(bodyData)}
        >
          {address}
        </StyledTableCell>
        <StyledTableCell
          component={"tr"}
          onClick={() => handleFinancialSelection(bodyData)}
        >
          {bodyData.lastName}
        </StyledTableCell>
        <StyledTableCell
          component={"tr"}
          onClick={() => handleFinancialSelection(bodyData)}
        >
          {bodyData.position}
        </StyledTableCell>
      </>
    );
  };

  const bodyProject = (bodyData) => {
    return (
      <>
        <>
          <StyledTableCell
            width="30%"
            onClick={() => handleProjectSelection(bodyData)}
          >
            {bodyData?.project?.title}
          </StyledTableCell>
          <StyledTableCell
            width="30%"
            onClick={() => handleProjectSelection(bodyData)}
          >
            {bodyData?.project?.number}
          </StyledTableCell>
          <StyledTableCell
            width="20%"
            onClick={() => handleProjectSelection(bodyData)}
          >
            {bodyData?.project?.location}
          </StyledTableCell>

          <StyledTableCell
            width="10%"
            onClick={() => handleRfpqPreview(bodyData?.id)}
          >
            <div>
              <ViewIcon />
            </div>
          </StyledTableCell>
        </>
      </>
    );
  };

  return (
    <>
      {!project && (
        <IncrementalSearchPickList
          disabledButton={object}
          add={add}
          body={body}
          conciseLength={3.5}
          filterApi={filterApi}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          addButtonTitle={"Add New"}
          placeholder={"Company Name, Address..."}
          marginTop={"24px"}
          hover={true}
          width="100%"
          pickedArray={pickedArray}
          disabled={disabled}
          internalSearch={true}
        />
      )}
      {project && (
        <IncrementalSearchPickList
          disabledButton={object}
          add={add}
          body={bodyProject}
          conciseLength={3.5}
          filterApi={filterApi}
          addButtonTitle={"Add Project"}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          placeholder={"Company Name, Address..."}
          marginTop={"24px"}
          hover={true}
          width="100%"
          pickedArray={pickedArray}
          project={project}
          object={object}
          disabled={disabled}
          internalSearch={true}
        />
      )}
      <RfpqReviewPopUp
        openPopup={rfpqReviewPopup}
        setOpenPopup={setRfpqReviewPopup}
        rfpqId={newRfpqId}
        setRfpqId={setNewRfpqId}
      />
    </>
  );
};
