import React from "react";
import { useDispatch } from "react-redux";
import { StyledButton } from "./styles";
import { useHistory } from "react-router-dom";
import { modifyProject } from "../../../../api/buyers";
import { cancelRfpq, deleteRfpq } from "../../../../data/actions/buyers";

export const StatusButton = ({
  title,
  width,
  contract_id,
  handleClick,
  disabled,
  institutionId,
}) => {
  const dispatch = useDispatch();
  let history = useHistory();

  function clickCancel() {
    dispatch(cancelRfpq({ rfpq_id: contract_id }));
    handleClick();
  }

  function clickDelete() {
    dispatch(deleteRfpq({ rfpq_id: contract_id }));
    handleClick(contract_id);
  }

  const clickEditViewAmend = () => {
    //history.push('/buyers/projects/setup', { feature: title, contract_id: contract_id });
    history.push(`/rfpq?id=${contract_id}`);
  };

  function clickReview() {
    history.push(`/rfpq?id=${contract_id}&section=review`);
  }

  const clickSubmissions = () => {
    history.push(`/buyers/submissions/${contract_id}`, {
      institutionId: institutionId,
    });
  };

  const handleButton = () => {
    if (title == "edit" || title == "amend" || title == "view") {
      clickEditViewAmend();
    } else if (title === "delete") {
      clickDelete();
    } else if (title === "invite/publish") {
      clickReview();
    } else if (title === "review") {
      clickReview();
    } else if (title == "submissions") {
      clickSubmissions();
    } else if (title == "cancel" || title == "archive") {
      clickCancel();
    }
  };

  return (
    <>
      <StyledButton
        title={title}
        width={width}
        onClick={handleButton}
        disabled={disabled}
      >
        {title}
      </StyledButton>
    </>
  );
};
