import React, { useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import { BorderLessTableRows } from "./borderLessTableRows.js";
import {
  StyledTableCell,
  StyledBorderlessTable,
  StyledBorderlessTableRow,
  StyledHeaderCheckBoxCell,
  StyledActionHeaderCell,
  StyledBorderLessBorder,
  StyledRow,
} from "./styles";

const BorderLessTableIndex = ({
  headers,
  data,
  isQualified,
  actions,
  actionType,
  icons,
  body,
  concise,
  handleSelected,
  handleClickRow,
  hover,
  borderSpacing,
  checkBoxHeaders,
  actionsHeaders,
  horizontal,
  backgroundColor,
  singleRow,
  pickedArray,
  setPickedArray,
  selectedNotSkip,
  largeFont = true,
}) => {
  const [selectedIndex, setSelectedIndex] = useState();
  // console.log(pickedArray);

  return (
    <StyledBorderlessTable concise={concise} elevation="0">
      {headers && headers.length > 0 && (
        <TableHead>
          <StyledBorderlessTableRow header={true}>
            {headers.map((header) => (
              <StyledTableCell
                align={header.align}
                header={true}
                largeFont={largeFont}
              >
                {header.title}
              </StyledTableCell>
            ))}
            {checkBoxHeaders?.map((checkBoxHeader) => (
              <StyledHeaderCheckBoxCell
                align={checkBoxHeader.align}
                header={true}
              >
                {checkBoxHeader.title}
              </StyledHeaderCheckBoxCell>
            ))}
            {actionsHeaders?.map((actionsHeader) => (
              <StyledActionHeaderCell align={actionsHeader.align} header={true}>
                {actionsHeader.title}
              </StyledActionHeaderCell>
            ))}
          </StyledBorderlessTableRow>
        </TableHead>
      )}
      {horizontal && (
        <TableBody>
          <StyledRow>
            {data &&
              data.map((row, index) => {
                return (
                  <BorderLessTableRows
                    {...row}
                    index={index}
                    isQualified={isQualified}
                    actions={actions}
                    actionType={actionType}
                    icons={icons}
                    body={body}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    handleSelected={handleSelected}
                    handleClickRow={handleClickRow}
                    hover={hover}
                    backgroundColor={backgroundColor}
                    pickedArray={pickedArray}
                    setPickedArray={setPickedArray}
                  />
                );
              })}
          </StyledRow>
        </TableBody>
      )}
      {!singleRow && !horizontal && (
        <TableBody>
          {data &&
            data.map((row, index) => {
              return (
                <BorderLessTableRows
                  {...row}
                  index={index}
                  isQualified={isQualified}
                  actions={actions}
                  actionType={actionType}
                  icons={icons}
                  body={body}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  handleSelected={handleSelected}
                  handleClickRow={handleClickRow}
                  hover={hover}
                  backgroundColor={backgroundColor}
                  setPickedArray={setPickedArray}
                  pickedArray={pickedArray}
                />
              );
            })}
        </TableBody>
      )}
      {singleRow && (
        <TableBody>
          <BorderLessTableRows
            {...data}
            index={0}
            isQualified={isQualified}
            actions={actions}
            actionType={actionType}
            icons={icons}
            body={body}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            handleSelected={handleSelected}
            handleClickRow={handleClickRow}
            hover={hover}
            backgroundColor={backgroundColor}
            pickedArray={pickedArray}
            setPickedArray={setPickedArray}
            selectedNotSkip={selectedNotSkip}
          />
        </TableBody>
      )}
    </StyledBorderlessTable>
  );
};

export default BorderLessTableIndex;
