import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { preQualifyUser } from "../../../data/actions/user";

import { FormContainer, StyledLargeTitle } from "../styles";
import { StyledTitle } from './styles';
import { ValueOfConstruction } from './ValueOfConstruction';
import { LooksGoodButton } from "../components/looksGoodButton";


export const CalendarYearsForm =({
  setActiveStep,
  qualificationData,
  setQualificationData,
  setIsLastPanel,
  setPreviousStepPanel,

}) => {
  setIsLastPanel(true);
  setPreviousStepPanel(1);


  const dispatch = useDispatch();
  const[ccdcId, setCcdcId] = useState(qualificationData? qualificationData.ccdcId : '')

  const [err1, setErr1] = useState(false);
  const [err2, setErr2] = useState(false);
  const [err3, setErr3] = useState(false);
  const [err4, setErr4] = useState(false);
  const [err5, setErr5] = useState(false);

  useEffect(() => {
    const collectedData = qualificationData.years;
    if (collectedData?.currentPrice && collectedData?.currentPrice >= 0) {
      setErr1(false);
    } else {
      setErr1(true);
    }

    if (
      collectedData?.price2020 &&
      String(collectedData?.price2020).length > 0
    ) {
      setErr2(false);
    } else {
      setErr2(true);
    }

    if (
      collectedData?.price2019 &&
      String(collectedData?.price2019).length > 0
    ) {
      setErr3(false);
    } else {
      setErr3(true);
    }

    if (
      collectedData?.price2018 &&
      String(collectedData?.price2018).length > 0
    ) {
      setErr4(false);
    } else {
      setErr4(true);
    }

    if (
      collectedData?.price2017 &&
      String(collectedData?.price2017).length > 0
    ) {
      setErr5(false);
    } else {
      setErr5(true);
    }

    // if(!err1 && !err2 && !err3 && !err4 && !err5){
    //   setSaveCCDC(true)
    // } else {
    //   setSaveCCDC(false)
    // }
  });

  return (
    <FormContainer>
        <StyledLargeTitle>Value of Construction Work</StyledLargeTitle>
        <StyledTitle>Annual value of construction work projected for current year and past four years</StyledTitle>
        <ValueOfConstruction data={qualificationData} setCollectedData={setQualificationData} />
    </FormContainer>
  )
};
