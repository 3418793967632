import styled from "styled-components";
import TableCell from "@material-ui/core/TableCell";
import { COLORS } from '../../../../../constants';


export const StyledTableCell = styled(TableCell)`
  && {
    font-family: SF Pro Text;
    font-weight: ${(props) => (props.header ? '500' : '400')};
    color: ${(props) => (props.header ? '#B9B9C3' : '#6E6B7B')};
    font-size: ${(props) => (props.header ? '18px' : '13px')};
    ${props => props.width ? `width: ${props.width};` : ""}
    &.MuiTableCell-root{
      padding: 0 10px;

      &:first-child {
        border-top-left-radius: ${props => props.borderRadius || "0.5rem"};
        border-bottom-left-radius: ${props => props.borderRadius || "0.5rem"};
        border-left: ${props => props.header ? '' : `1px ${COLORS.greyBorder} solid`};
      }

      &:last-child {
        border-top-right-radius: ${props => props.borderRadius || "0.5rem"};
        border-bottom-right-radius: ${props => props.borderRadius || "0.5rem"};
        border-right: ${props => props.header ? '' : `1px ${COLORS.greyBorder} solid`};
      }
    }
  }
`;



export const StyledTableCellFirst = styled(StyledTableCell)`
  width: 150px;

  &&.MuiTableCell-root:first-child  {
    border: none;
  }

  &&.MuiTableCell-root {
    border: none;
  }
`;

export const StyledIndexCell = styled(StyledTableCellFirst)`
  width: 50px;
`;

export const StyledTableCellSecond = styled(StyledTableCell)`
  width: 200px;

  &&.MuiTableCell-root:first-child {
    border: none;
  }

  &&.MuiTableCell-root {
    border: none;
  }
`;

export const StyledTableCellThird = styled(StyledTableCell)`
  width: 100px;
  
  

  &&.MuiTableCell-root:first-child  {
    border: none;
    text-align: center;
  }

  &&.MuiTableCell-root {
    border: none;
    // color: #FFB72F;
    // background: #FFE6B6;
    // border-radius: 10px;
    // text-align: center;
  }
`;


export const StyledTableCheckBoxCell = styled(StyledTableCell)`
  width: 10px;

  &&.MuiTableCell-root:last-child {
    border: none;
  }

  &&.MuiTableCell-root{
    border: none;
  }
`;

export const StyledTableCenteringContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledMinusImg = styled.img`
  width: 5px;
  cursor: pointer;
`;

export const StyledImgIcon = styled.img`
  margin-top: 5px;
  cursor: pointer;
`;

export const StyledPickedContainer = styled.div`
  height: 500px;
`;

export const StyledUnderwayContainer = styled.div`
  padding: 0 10px;
  background-color: ${ COLORS.lightYellow };
  border-radius: 20px;
  color: ${ COLORS.primaryYellow };
  text-align: center;
`;

export const StyledCompletedContainer = styled.div`
  padding: 0 10px;
  background-color: ${ COLORS.lightGreen };
  border-radius: 20px;
  color: ${ COLORS.primaryGreen };
  text-align: center;
`;

