import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '../../../constants';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';

export const OnboardContainer = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isMobile ? "90%" : "100%")};
`;

export const PopupContainer = styled(OnboardContainer)`
  &&{
    margin-top: 0px;
    justify-content: center;
    align-items: center;
  }
`;

export const InfoArea = styled.div`
  ${props => props.marginLeft && !props.isMobile ? `margin-left: ${props.marginLeft};` : ''}
  margin-top: ${(props) => props.marginTop || "24px"};
  display: flex;
  flex-direction: ${props => props.isColumn || props.isMobile ? "column" : "row"};
  align-items: center;
  ${props => props.noJustifyContent ? "" : "justify-content: center;"}
  ${props => props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}
  .inputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '50%'};
  }

  .fullWidthInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: 100%;
  }

  .oneFifthInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '20%'};
  }

  .thirtyInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '30%'};
  }

  .oneThirdInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '33.33%'};
  }

  .twoThirdInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '66.66%'};
  }

  .oneFifthMobile {
    padding-right: 16px;
    width: ${props => props.isMobile ? '50%' : '40%'};
  }

  .thirtyMobile {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '50%' : '60%'};
  }
`;

export const PlusSignArea = styled.div`
  width: 100%;
  margin-top: ${props => props.usePlusIcon ? '20px' : "4px"};
  display: flex;
  flex-direction: row;
  
`;

export const ButtonArea = styled(InfoArea)`
  && {
    margin-top: ${props => props.marginTop || "48px"};
    justify-content: center;
    display: flex;
    flex-direction: ${props => props.isMobile ? "column" : "row"};
  }
`;

export const ImagePopup = styled.div`
  margin-top: 16px;
`;

export const LargeText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.displayFont};
    font-size: ${props => props.isMobile ? "18px" : "26px"};
    font-style: normal;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 16px;
  }
`;

export const MediumText = styled(Typography)`
  && {
    font-family:  ${TYPOGRAPHY.primaryFont};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 16px;
    color: ${COLORS.lightGrey2};
  }
`;

export const SmallText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.secondaryFont};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 16px;
  }
`;

export const StyledQuestion = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.displayFont};
    font-weight: 600;
    font-size: ${props => props.isMobile? "24px" : "36px"};
    line-height: 43px;
    text-align: center;
    width: ${props => props.isMobile? "100%" : props.width};
  }
`;


export const StyledSubtext = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.displayFont};
    font-weight: 400;
    font-size: ${props => props.isMobile? "14px" : "16px"};
    line-height: 28px;
    text-align: left;
    width: ${props => props.isMobile? "100%" : props.width};
  }
`;



export const TextContainer = styled(StyledQuestion)`
  && {
    margin-top: ${props => props.isMobile? "8px" : "36px"};
    width: ${props => props.isMobile? "100%" : props.width};
  }
`;

export const StyledCardTypography = styled(Typography)`
  && {
    font-size: 18px;
    font-family: ${TYPOGRAPHY.primaryFont};
    font-weight: 500;
    ${props => !props.isMobile ? "margin-top: 24px;" : ""};
    color: #888888;

  }
`;

export const StyledCard = styled(Card)`
  && {
      width: ${props => props.isMobile ? "155px": "308px"};
      height: ${props => props.isMobile ? "125px": "248px"};
      margin-left: 12px;
      margin-right: 12px;
      box-shadow: none;
      background: ${props => props.isChecked ? COLORS.lighOrange : "white"};
      border: 1px solid ${props => props.isChecked ? COLORS.primaryOrange : "#ECEBED"};
      box-sizing: border-box;
      border-radius: 10px;
      ${props => props.isMobile ? "margin-top: 32px;" : ""};
  }
`;

export const StyledCardContent = styled(CardContent)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    ${props => !props.isMobile ? "margin-top: 24px;" : ""};
    ${props => !props.isMobile ? "margin-bottom: 16px;" : ""};
  }
`;

export const StyledCardActionArea = styled(CardActionArea)`
  && {
    // height: 64px;
  }
`;

export const StyledCardContainer = styled.div`
  margin-top: 16px;
`;

export const StyledFlag = styled.img`
  ${props => props.isMobile ? "width: 100px;" : ""};
  ${props => props.isMobile ? "height: 80px;" : ""};
`;

export const StyleList = styled.div`
  // width: 100%;
  margin-top: 24px;
  // ${props => props.isMobile ? "" : "margin-right: 16px;"}
`;

export const StyledRow = styled.div`
  width: 100%;
  // margin-right: 16px;
  ${props => props.marginTop ? `margin-top: ${props.marginTop};` : ''}
  display: flex;
  flex-direction: ${props => props.isMobile || props.isColumn ? "column" : "row"};
`;

export const SubRow = styled.div`
  // width: 50%;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const StyledSkipText = styled.div`
  color: ${COLORS.primaryOrange};
  cursor: pointer;
  font-family: ${TYPOGRAPHY.primaryFont} ;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin-top: 18px;
  margin-right: 16px;
`;

export const StyledSmallAddText = styled(StyledSkipText)`
  font-size: 15px;
`;

export const StyledAddText = styled(StyledSkipText)`
  &&{
    font-size: 18px;
    margin-top: 3px;
    margin-left: 10px;
    ${props => !props.enableAdd ? `color: ${COLORS.lightGrey2}` : ''}
  }
`;
export const StyledFileDiplay = styled.div`
  width: 100%;
  margin-top: 40px;
`;


export const StyledInput = styled.input`
  && {
    display: flex;
    justify-content: right;
    width: ${props => props.width || '250'}px;
    height: 48px;
    font-size: 18px;
    border: 1px solid #FF6D1D;
    box-sizing: border-box;
    border-radius: 4px;
    
    border: ${props => props.value ?  "1px solid #FF6D1D" : "1px solid #cccccc"};
    text-indent: 14px;
  }
  &&.form input[type="text"] {
    box-sizing: border-box;
    padding-left: 50px;
  }
  :focus {
    outline: none !important;
    border: ${props => props.value ?  "2px solid #FF6D1D" : "1px solid #cccccc"};
  }

`;

export const StyledInputLabel = styled.label`
  font-size: 12px;
  text-align: left;
  margin-top: 36px;
`;

export const StyledAddressContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
`;

export const StyledInputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonAtBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${props => props.isMobile ? "position: absolute; bottom: 0; margin-bottom: 40px;" : ""}
`;