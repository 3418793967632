import React, { useState, useEffect } from 'react';

import { FormContainer, StyledLargeTitle } from "../styles";

import { Panel1 } from './panel1';
import { Panel2 } from './panel2';

export const ProjectInfoForm = ({
  setActiveStep,
  qualificationData,
  setQualificationData,
  setIsLastPanel,
  setPreviousStepPanel,
}) => {
  setIsLastPanel(true)
  setPreviousStepPanel(2)

  const [hasOwnerData, setHasOwnerData] = useState(false);

  return (
    <FormContainer>
      <StyledLargeTitle>Submitter Info</StyledLargeTitle>
        <Panel1
          setActiveStep={setActiveStep}
          qualificationData={qualificationData}
          setQualificationData={setQualificationData}
          setIsLastPanel={setIsLastPanel}
          hasOwnerData={hasOwnerData}
          setHasOwnerData={setHasOwnerData}
        />
      {/* {hasOwnerData && ( */}
        <Panel2
          qualificationData={qualificationData}
          setQualificationData={setQualificationData}
        />
      {/* )} */}
    </FormContainer>
  );
};
