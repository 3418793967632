import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { StyledMediumTitle } from "../styles";
import { StyledInputContainer } from '../styles';

export const StyledYear = styled(Typography)`
  justify-content: center;
  display: flex;
  flex-direction: column;

  && {
    font-size: 15px;
    margin-top: 40px;

    &.MuiTypography-root{
      font-weight: 600;
    }
  }
`;

export const StyledTitle = styled(StyledMediumTitle)`
  && {
    margin-bottom: 24px;
  }
`;

export const StyledRemarkContainer = styled(StyledInputContainer)`
  && {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
  }
`;