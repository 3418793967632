import styled from 'styled-components';
import { Input } from 'semantic-ui-react'
import { COLORS, TYPOGRAPHY } from '../../constants';


export const StyledInput = styled(Input)`
  && {
    input {
      width:  ${props => props.label ? '155px': '100%'};
      height: 48px;
      border: 1px ${COLORS.primaryOrange} solid;
      border-radius: 6px 0px 0px 6px;
      font-weight: 500;
      font-size: 15px;
      font-family: ${TYPOGRAPHY.primaryFont};

      &:focus {
        border-color: ${COLORS.primaryOrange};
      }
    }

    .label {
      width: 45px;
      height: 48px;
      border: 1px ${COLORS.primaryOrange} solid;
      color: ${COLORS.primaryOrange};
      background-color: ${COLORS.secondaryOrange};
      text-align: center;
      vertical-align: center;
      font-weight: 400;
      font-family: ${TYPOGRAPHY.primaryFont};
    }

    &&.ui.input{
     ${props => props.width ? `width: ${props.width};` : ''};
    }

    &&.ui.labeled.input>.label {
      font-size: 24px;
    }

    &&.ui.labeled.input>.label:not(.corner) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &&.ui[class*="right labeled"].input>input:focus {
      border-right-color: white !important;
    }

    &&.ui[class*="right labeled"].input>input {
      ${props => props.label ? '' :
          `border-top-right-radius: 6px !important;
           border-bottom-right-radius: 6px !important;
           border-right-color: ${COLORS.primaryOrange} !important;`};
    }
  }
`;
