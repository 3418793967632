import styled from "styled-components";

import IconButton from "@material-ui/core/IconButton";
import { COLORS, TYPOGRAPHY } from "constants.js";
import { Typography } from "@mui/material";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

export const StyledCloseButton = styled(IconButton)`
  && {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // max-height: 406px;
  margin-top: 24px;
`;

export const PopupTitle = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 32px;
  }
`;

export const PopupBodyText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 8px;
  }
`;
export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledMenuItem = styled(MenuItem)`
  &&.MuiListItem-gutters {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const StyledButton = styled(Button)`
  && {
    width: ${(props) => (props.width ? props.width : "100px")};
    height: 48px;
    background-color: white;
    border: 1px solid ${COLORS.primaryOrange};
    box-sizing: border-box;
    border-radius: 6px;
    color: ${COLORS.primaryOrange};
    font-family:${TYPOGRAPHY.primaryFont};
    font-size: 17px;
    font-weight: 600;
    transition: all 0.5s ease;
    text-transform: uppercase;

    &:hover {
      background-color: ${COLORS.primaryOrange};
      color: white;
    }
`;

export const StyledButtonReview = styled(Button)`
  && {
    width: ${(props) => (props.width ? props.width : "100px")};
    height: 48px;
    background-color: #FF6D1D;
    border: 1px solid ${COLORS.primaryOrange};
    box-sizing: border-box;
    border-radius: 6px;
    color: white;
    font-family:${TYPOGRAPHY.primaryFont};
    font-size: 17px;
    font-weight: 600;
    transition: all 0.5s ease;
    text-transform: uppercase;

    &:hover {
      background-color: ${COLORS.primaryOrange};
      color: white;
    }
`;
