import React, { useEffect, useState } from "react";
import { FunctionalButton } from "components/functionalButton";
import PledgxLogo from "assets/common/pledgxLogo";
import { useHistory } from "react-router-dom";
import Input from "components/inputField";
import { viewChangeOrderApproval } from 'api/work_in_progress.js';
import { useParams } from "react-router";
import { handleCommaFormat, numberWithCommas, removeComma } from "common/numberFormat";
import { changeOrderApprovalBuyer } from "api/work_in_progress";
import DateTimePickerField from "components/dateTimePickerField";
import { RedirectToApproval } from "./redirectToApproval"

import {
  FormTitle,
  TitleDiv,
  ButtonDiv,
  ScreenRoot,
  FormContainer,
  FieldArea,
  SmallTitleText
} from "./styles.js";

import { COLORS, FONT_SIZES } from "constants.js";


function ChangeOrderForApproval({
  isMobile
}) {
  let history = useHistory();
  const [data, setData] = useState({});
  const { token1, token2 } = useParams();
  const [valid, setValid] = useState(false);
  const [error, setError] = useState("");
  const [openRedirectStatusReportPopup, setOpenRedirectStatusReportPopup] = useState(false);

  const [changeInContractValue, setChangeInContractValue] = useState(data?.change_in_contract_value || '');
  const [changeOrderNo, setChangeOrderNo] = useState(data?.new_change_order_id);
  const [changeOrderDate, setChangeOrderDate] = useState("");
  const [dateApproved, setDateApproved] = useState("");
  const [changeInContractTime, setChangeInContractTime] = useState(0);
  const [initialEstimatedRevenue, setInitialEstimatedRevenue] = useState(0);
  const [newEstimatedRevenue, setNewEstimatedRevenue] = useState(initialEstimatedRevenue);
  const [initialContractCompletionDate, setInitialContractCompletionDate] = useState("");
  const [newContractCompletionDate, setNewContractCompletionDate] = useState("");
  const [validSubmit, setValidSubmit] = useState(false);
  const [remarks, setRemarks] = useState("");
  const oldTotalChangeInContractValue = data?.old_project_value_or_estimated_revenue ? removeComma(data?.old_project_value_or_estimated_revenue) : 0;


  useEffect(() => {
    const updatedData = { ...data };
    updatedData["change_order_no"] = changeOrderNo;
    setData(updatedData);
  }, [changeOrderNo]);

  useEffect(() => {
    const updatedData = { ...data };
    updatedData["change_order_date"] = changeOrderDate;
    setData(updatedData);
  }, [changeOrderDate]);


  useEffect(() => {
    const updatedData = { ...data };
    updatedData["date_approved"] = dateApproved;
    setData(updatedData);
  }, [dateApproved]);


  useEffect(() => {
    const updatedData = { ...data };
    updatedData["change_in_contract_value"] = changeInContractValue ? removeComma(changeInContractValue) : 0;
    const totalChangeInContractValue = oldTotalChangeInContractValue + removeComma(changeInContractValue);
    setNewEstimatedRevenue(totalChangeInContractValue);
    setData(updatedData);
  }, [changeInContractValue, newEstimatedRevenue]);


  useEffect(() => {
    const updatedData = { ...data };
    updatedData["change_in_contract_time"] = changeInContractTime ? removeComma(changeInContractTime) : 0;
    setData(updatedData);
  }, [changeInContractTime]);


  useEffect(() => {
    const updatedData = { ...data };
    updatedData["new_estimated_revenue"] = newEstimatedRevenue ? newEstimatedRevenue : 0;
    setData(updatedData);
  }, [newEstimatedRevenue]);

  useEffect(() => {
    const updatedData = { ...data };
    updatedData["new_contract_completion_date"] = newContractCompletionDate ? newContractCompletionDate : 0;
    setData(updatedData);
  }, [newContractCompletionDate]);


  useEffect(() => {
    const updatedData = { ...data };
    updatedData["remarks"] = remarks ? remarks : "";
    setData(updatedData);
  }, [remarks]);



  useEffect(() => {
    const originalCompletionDate = initialContractCompletionDate || data?.old_contract_completion_date;
    const newCompletionDate = newContractCompletionDate;

    try {
      if (!/^\d{4}-\d{2}-\d{2}$/.test(originalCompletionDate)) {
        console.log('Invalid original completion date format. Use YYYY-MM-DD');
      }
      if (!/^\d{4}-\d{2}-\d{2}$/.test(newCompletionDate)) {
        console.log('Invalid new completion date format. Use YYYY-MM-DD');
      }

      const originalDate = new Date(originalCompletionDate);
      const newDate = new Date(newCompletionDate);

      const timeDifference = newDate.getTime() - originalDate.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

      if (isNaN(daysDifference)) {
        setChangeInContractTime(0);
        setNewContractCompletionDate(originalCompletionDate);
      } else {
        setChangeInContractTime(daysDifference);
      }

    } catch (error) {
      console.log("Oops!! An error occurred.");
    }
  }, [newContractCompletionDate, initialContractCompletionDate]);


  useEffect(() => {
    const completionDate = initialContractCompletionDate || data?.old_contract_completion_date;
    const changeInContractTimeNumber = parseInt(changeInContractTime, 10);

    try {
      if (!/^\d{4}-\d{2}-\d{2}$/.test(completionDate)) {
        console.log('Invalid date format. Use YYYY-MM-DD');
      }
      const date = new Date(completionDate);
      date.setDate(date.getDate() + changeInContractTimeNumber);
      const formattedDate = date.toISOString().split('T')[0];
      setNewContractCompletionDate(formattedDate);
    } catch (error) {
      console.log("Opps!!!");
    }
  }, [changeInContractTime, initialContractCompletionDate]);




  const handleChangeOrderApprovalBuyer = () => {
    const co_status = "Approved"
    const new_estimated_revenue = newEstimatedRevenue
    ? removeComma(newEstimatedRevenue)
    : 0;
    const newData = { ...data, co_status: co_status, new_estimated_revenue: new_estimated_revenue };
    setData(newData);
    changeOrderApprovalBuyer(newData, () => {
      window.location.replace("https://pledgx.com")
    }, () => { });
  };


  useEffect(() => {
    viewChangeOrderApproval(
      token1,
      token2,
      (res) => {
        setData(res);
        setNewEstimatedRevenue(res?.new_estimated_revenue);
        setNewContractCompletionDate(res?.contract_completion_date);
        setValid(true);
      },
      (err) => { setValid(false); setError(err.data.message); }
    )
  }, [])


  const handleRedirectStatus = () => {
    setOpenRedirectStatusReportPopup(true);
  };


  return (
    <>
      {!data && (
        <div
          style={{
            marginTop: "150px",
            color: "black",
            fontSize: FONT_SIZES.body_4,
            fontWeight: "400",
          }}
        >
          <div
            style={{
              color: COLORS.textGrey,
              fontSize: FONT_SIZES.body_4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No Change Order for Approval To View
          </div>
        </div>
      )}
      {data && (
        <ScreenRoot isMobile={isMobile}>
          <FormContainer isMobile={isMobile}>
            <TitleDiv>
              <PledgxLogo
                width={isMobile ? 45 : ""}
                height={isMobile ? 45 : ""}
              />
              <FormTitle style={{ marginTop: 10 }}>
                {"Change Order"}
              </FormTitle>

              <SmallTitleText
                style={{
                  marginBottom: 10,
                  marginTop: -30,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                }}>
                {data?.project_name}
              </SmallTitleText>
            </TitleDiv>

            <FieldArea width={isMobile ? "100%" : "100%"}>
              <div style={{ margin: "0px", marginBottom: "-15px", display: "flex", flexDirection: "row", width: "100%" }}>
                {data?.co_status === "Approved" && (
                  <>
                    <Input
                      id="inputWrap"
                      label="Change Order #"
                      type="text"
                      placeholder=""
                      labelSize="12px"
                      fontSize="18px"
                      value={data?.change_order_no ? data?.change_order_no : ""}
                      noCheckIcon={true}
                      noErrorBorder={true}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />
                    <DateTimePickerField
                      showDateField={true}
                      dateWidth="50%"
                      paddingRight="16px"
                      dateLabel="Change Order Date"
                      dateValue={data?.change_order_date ? data?.change_order_date : ""}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />
                  </>
                )}

                {data?.co_status !== "Approved" && (
                  <>
                    <Input
                      id="inputWrap"
                      label="Change Order #"
                      type="text"
                      placeholder=""
                      labelSize="12px"
                      fontSize="18px"
                      value={
                        data?.change_order_no ? data?.change_order_no : ""
                      }
                      setValue={setChangeOrderNo}
                      validateFunc={(item) => item.length > 0}
                      noCheckIcon={true}
                      noErrorBorder={true}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />
                    <DateTimePickerField
                      showDateField={true}
                      dateWidth="50%"
                      paddingRight="16px"
                      dateLabel="Change Order Date*"
                      dateValue={
                        data?.change_order_date ? data?.change_order_date : ""
                      }
                      setDateValue={setChangeOrderDate}
                    />
                  </>
                )}
              </div>

              <div style={{ margin: "0px", marginBottom: "-15px", display: "flex", flexDirection: "row", width: "100%" }}>
                {data?.co_status === "Approved" && (
                  <>
                    <Input
                      id="inputWrap"
                      label="Change in Contract Value*"
                      type="text"
                      placeholder="$"
                      labelSize="12px"
                      fontSize="18px"
                      value={data?.change_in_contract_value ? handleCommaFormat(data?.change_in_contract_value) : ""}
                      setValue={setChangeInContractValue}
                      noCheckIcon={true}
                      noErrorBorder={true}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />

                    <Input
                      id="inputWrap"
                      label="Change in Contract Time(Days)*"
                      type="text"
                      placeholder="0"
                      labelSize="12px"
                      fontSize="18px"
                      value={data?.change_in_contract_time ? data?.change_in_contract_time : ""}
                      setValue={setChangeInContractTime}
                      noCheckIcon={true}
                      noErrorBorder={true}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />
                  </>
                )}

                {data?.co_status !== "Approved" && (
                  <>
                    <Input
                      id="inputWrap"
                      label="Change in Contract Value*"
                      type="text"
                      placeholder="$"
                      labelSize="12px"
                      fontSize="18px"
                      value={data?.change_in_contract_value ? handleCommaFormat(data?.change_in_contract_value) : ""}
                      setValue={setChangeInContractValue}
                      noCheckIcon={true}
                      noErrorBorder={true}
                    />

                    <Input
                      id="inputWrap"
                      label="Change in Contract Time(Days)*"
                      type="text"
                      placeholder="0"
                      labelSize="12px"
                      fontSize="18px"
                      value={changeInContractTime}
                      setValue={setChangeInContractTime}
                      noCheckIcon={true}
                      noErrorBorder={true}
                    />
                  </>
                )}
              </div>

              <div style={{ margin: "0px", marginBottom: "-15px", display: "flex", flexDirection: "row", width: "100%" }}>
                {data?.co_status === "Approved" && (
                  <>
                    <Input
                      id="inputWrap"
                      label="New Estimated Revenue"
                      type="text"
                      placeholder="$"
                      labelSize="12px"
                      fontSize="18px"
                      value={data?.project_value_or_estimated_revenue ? handleCommaFormat(data?.project_value_or_estimated_revenue) : ""}
                      noCheckIcon={true}
                      noErrorBorder={true}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />

                    <DateTimePickerField
                      showDateField={true}
                      dateWidth="50%"
                      paddingRight="16px"
                      dateLabel="New Contract Completion Date*"
                      dateValue={data?.contract_completion_date ? data?.contract_completion_date : ""}
                      setDateValue={setNewContractCompletionDate}
                      disabled
                    />
                  </>
                )}

                {data?.co_status !== "Approved" && (
                  <>
                    <Input
                      id="inputWrap"
                      label="New Estimated Revenue"
                      type="text"
                      placeholder="$"
                      labelSize="12px"
                      fontSize="18px"
                      value={data?.new_estimated_revenue ? handleCommaFormat(data?.new_estimated_revenue) : ""}
                      setValue={setNewEstimatedRevenue}
                      noCheckIcon={true}
                      noErrorBorder={true}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />

                    <DateTimePickerField
                      showDateField={true}
                      dateWidth="50%"
                      paddingRight="16px"
                      dateLabel="New Contract Completion Date*"
                      dateValue={newContractCompletionDate}
                      setDateValue={setNewContractCompletionDate}
                    />
                  </>
                )}
              </div>

              <div style={{ margin: "0px", display: "flex", flexDirection: "row", width: "100%" }}>
                {data?.co_status !== "Approved" && (
                  <>
                    <DateTimePickerField
                      showDateField={true}
                      dateWidth="50%"
                      paddingRight="16px"
                      dateLabel="Date Approved"
                      dateValue={data?.date_approved ? data?.date_approved : ""}
                      setDateValue={setDateApproved}
                    />

                    <Input
                      id="inputWrap"
                      label="Remarks"
                      type="text"
                      placeholder=""
                      labelSize="12px"
                      fontSize="18px"
                      value={data?.remarks ? data?.remarks : ""}
                      setValue={setRemarks}
                      noCheckIcon={true}
                      noCancelIcon={true}
                      noErrorBorder={true}
                    />
                  </>
                )}

                {data?.co_status === "Approved" && (
                  <>
                    <DateTimePickerField
                      showDateField={true}
                      dateWidth="50%"
                      paddingRight="16px"
                      dateLabel="Date Approved"
                      dateValue={data?.date_approved ? data?.date_approved : ""}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />

                    <Input
                      id="inputWrap"
                      label="Remarks"
                      type="text"
                      placeholder=""
                      labelSize="12px"
                      fontSize="18px"
                      value={data?.remarks ? data?.remarks : ""}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                      noCheckIcon={true}
                      noCancelIcon={true}
                      noErrorBorder={true}
                    />
                  </>
                )}
              </div>
            </FieldArea>

            <ButtonDiv isMobile={isMobile}>
              {data?.co_status === "Approved" && (
                <>
                  <FunctionalButton
                    disabled
                    width="30%"
                    marginTop="32px"
                    marginBottom="34x"
                    marginRight="0px"
                    buttonTitle={"APPROVED"}
                  />
                </>
              )}

              {data?.co_status !== "Approved" && (
                <>
                  <FunctionalButton
                    disabled={validSubmit}
                    width="30%"
                    marginTop="32px"
                    marginBottom="34x"
                    marginRight="16px"
                    handleButton={() => {
                      handleChangeOrderApprovalBuyer();
                    }}
                    buttonTitle={"APPROVE"}
                  />
                  {data?.redirect_email === "" && (
                    <>
                      <FunctionalButton
                        buttonTitle={"REDIRECT TO"}
                        width="30%"
                        marginTop="32px"
                        marginBottom="34x"
                        marginRight="0px"
                        isReversedColor={true}
                        handleButton={() => handleRedirectStatus()}
                      />
                    </>
                  )}
                </>
              )}
            </ButtonDiv>

            <RedirectToApproval
              isMobile={isMobile}
              data={data}
              openPopup={openRedirectStatusReportPopup}
              setOpenPopup={setOpenRedirectStatusReportPopup}
            />

          </FormContainer>
        </ScreenRoot>
      )}
    </>
  );
}

export default ChangeOrderForApproval;
