import React from "react";
import { FunctionalButton } from 'components/functionalButton';
import PledgxLogo from "assets/common/pledgxLogo";

import {
  ImagePopup,
  LargeText,
  SmallText,
  ButtonArea,
  PopupContainer,
} from './styles';
import { Popup } from 'components/popup';
import { CloseButton } from "components/closeButton";

export const ConfirmationMessage = ({
  openPopup,
  setOpenPopup,
  isMobile,
  boldText,
  text,
  handleButton
}) => {

  const handleDone = () => {
    setOpenPopup(false);
  };

  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width={isMobile ? "100%" : "480px"}
      height={isMobile ? "390px" : "280px"}
      dialogContent={(
        <>
          <PopupContainer>
            <CloseButton handleClose={handleDone} />
            <ImagePopup>
              <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
            </ImagePopup>
            <LargeText isMobile={isMobile}>{boldText || "Success!"}</LargeText>
            <SmallText>
              {text || "Your change has been saved."}
            </SmallText>
          </PopupContainer>
          <ButtonArea isMobile={isMobile}>
            <FunctionalButton
              buttonTitle="DONE"
              width="90%"
              height={isMobile ? "48px" : "53px"}
              marginRight="0px"
              handleButton={handleButton || handleDone}
            />
          </ButtonArea>
        </>
      )}
    />

  )
}
