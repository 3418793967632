import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getRfpq } from "../../data/actions/buyers";
import { rfpqSelector } from "../../data/selectors/buyers";
import classes from "./style/Rfpq.module.css";
import { DASHBOARDHEADER_HEIGHT } from "../../constants";
import { FormBuilder } from "../../components/formBuilder";
import Spinner from "../../components/spinner";

import formFields from "../rfpq/forms/formFields.json";
import formSetup from "../rfpq/forms/formSetup.json";
import { getCountryName } from "../../common/countryName";

function RfpqPreview({ rfpq, rfpqId }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const retrievedRfpq = useSelector(rfpqSelector);

  useEffect(() => {
    if (!rfpq && rfpqId) {
      dispatch(getRfpq({ rfpq_id: rfpqId }));
    }
  }, []);
  if (retrievedRfpq || rfpq) {
    var rfpqData = retrievedRfpq || rfpq || {};
    var expiryDate = {};
    if (rfpqData.expiry) {
      expiryDate.date = moment.utc(rfpqData.expiry).format("YYYY-MM-DD");
      expiryDate.time = moment.utc(rfpqData.expiry).format("HH:mm");
    }

    var organizedRfpqData = {
      closingDate: expiryDate.date,
      closingTime: expiryDate.time,
      projectCategory: rfpqData.project?.categories,
      projectCity: rfpqData.project?.location,
      ownerName: rfpqData.institution?.name,
      projectCountry: {
        selectedData: getCountryName(rfpqData.project?.country),
      },
      projectDescription: rfpqData.project?.description,
      projectLocationDescription: rfpqData.project?.location_description,
      projectNumber: rfpqData.project?.number,
      projectRequirements: {
        selectedData: rfpqData.requirements,
      },
      additionalDocuments: { selectedFiles: rfpqData.files },
      projectState: rfpqData.project?.state,
      projectTitle: rfpqData.project?.title,
    };
    return (
      <div className={classes.container} style={{ width: "600px" }}>
        <div>
          <FormBuilder
            data={organizedRfpqData}
            formFields={formFields}
            formSetup={[{ ...formSetup[5], pageTitleLong: "" }]}
            navigationType="TABS"
            readOnly={true}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.container}>
        <Spinner />
      </div>
    );
  }
}

export default RfpqPreview;
