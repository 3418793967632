import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { IconButton } from "@material-ui/core";
import SideBarCloseArrow from "../../../assets/dashboardIcons/sideBarCloseArrow";
import SideBarOpenArrow from "../../../assets/dashboardIcons/sideBarOpenArrow";
import { SideNav } from './SideNav';


const useStyles = makeStyles({
  list: {
    width: 320,
  },
  fullList: {
    width: "auto"
  },
  buttonClose: {
    position: "absolute",
    top: 30,
    right: 0,
    border: "0px solid ",
    borderRadius: 0,
    padding: 0
  },

  buttonDrawer: {
    position: "absolute",
    top: 120,
    left: 0,
    border: "0px solid ",
    borderRadius: 0,
    padding: 0
  },
  paper: {
    height: 382,
    top: 90,
    border: "1px solid #FF6D1D",
    borderRadius: 6,
    borderLeft: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
});

export default function DrawerSideNav({
  activeStep,
  setActiveStep,
  isMobile
}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom"
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <SideNav   
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        isMobile={isMobile}
      />
    </div>
  );

  return (
    <div>
      <IconButton
        className={classes.buttonDrawer}
        onClick={toggleDrawer("left", true)}
      >
        <SideBarOpenArrow />
      </IconButton>
      <SwipeableDrawer
        classes={{
          paper: classes.paper,
        }}
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        <IconButton 
          className={classes.buttonClose}
          onClick={toggleDrawer("left", false)}>
          <SideBarCloseArrow />
        </IconButton>
        {list("left")}
      </SwipeableDrawer>
    </div>
  );
}
