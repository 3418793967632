import React from "react";
import SwitchUnstyled from '@mui/base/SwitchUnstyled';

import { Root } from "./styles";

export const CommonToggle = ({
  handleSwitch,
  checked,
  constantTrackColor=false
}) => {

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <SwitchUnstyled 
        component={ Root }
        checked={checked}
        onChange={handleSwitch}
      />
    </div>
  );
}
