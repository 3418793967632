import { useHistory, useLocation } from "react-router-dom";
import React, { useState } from "react";
import { BackButton } from "../../../components/backButton";
import { AlertMessage } from "./AlertMessage.js"

export const GoBackButton = ({ buttonText, handleClick, hasUnsavedChanges }) => {
  let history = useHistory();
  let location = useLocation();
  const path = location.pathname;
  const [openAlertMessagePopup, setOpenAlertMessagePopup] = useState(false);
  const [backUrl, setBackUrl] = useState(""); 


  const getPreviousUrl = (n, char, string) => {
    if (n <= 0) {
      return string.length;
    }
    return getPreviousUrl(
      --n,
      char,
      string.substring(0, string.lastIndexOf(char))
    );
  };

  const handleBackPrevious = () => {
    const backUrl = path.substring(0, getPreviousUrl(2, "/", path) + 1);
    history.push(backUrl);
  };

  const handleConfirmLeave = () => {
    if (backUrl) {
      history.push(backUrl);
    } else {
      history.goBack();
    }
    setOpenAlertMessagePopup(false); 
  };


  const handleBack = () => {
    if (hasUnsavedChanges && hasUnsavedChanges()) {
      setBackUrl(""); 
      setOpenAlertMessagePopup(true);
    } else {
      history.goBack();
    }
  };


  return (
    <>
      <BackButton
        margin="20px 0px 0px 0px"
        handleClick={handleClick ? handleClick : handleBack}
        buttonText={buttonText}
      />

      <AlertMessage
        openPopup={openAlertMessagePopup}
        setOpenPopup={setOpenAlertMessagePopup}
        message="You have unsaved changes. Do you want to exit this screen without saving those changes?"
        confirmAction={handleConfirmLeave}
        cancelAction={() => setOpenAlertMessagePopup(false)}
      />
    </>
  );
};
