import { COLORS } from "../../../../constants";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export const StyledDownloadButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
`;

export const StyledView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledPdfViewerCanvas = styled.div`
  width: 832px;
  height: 832px;
  border: 2px solid ${COLORS.primaryOrange};
  border-radius: 8px;
  background: ${COLORS.lighOrange};
`;

export const StyledPdfViewer = styled.div`
  width: 800px;
  height: 800px;
  margin-left: 16px;
  margin-top: 16px;
`;

export const StyledPdfViewerContainer = styled.div`

`;

export const StyledFilePreviewContainer = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 32px;
`;

export const StyledTitle = styled.div`
  display: inline-block;
  color: ${COLORS.lightGrey2};
  font-size: 18px;
  margin-bottom: 18px;
`;

export const StyledTitle2 = styled(StyledTitle)`
  width: 300px;
  margin-bottom: 0;
  margin-right: 170px;
`;

export const StyledTitleBold = styled(StyledTitle)`
  display: inline-block;
  font-weight: 700;
  margin-right: 6px;
`;

export const FormContainer = styled.div`
  // display: flex;
  // margin: 0 auto;
  flex-direction: column;

  margin-top: 28px;
`;

export const rowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledDownloadSelection = styled.div`
  margin-top: 15px;
  margin-right: 30px;
`;


export const StyledArea = styled.div`
  width: 100%;
  display: flex;
  ${props => props.isMobile ? "flex-wrap: wrap;" : ""}
  justify-content: ${props => props.isMobile ? "space-around" : "space-between"};
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop};` : "")}
`;

export const StyledBox = styled.div`
  width: ${props => props.boxWidth || "100%"};
  ${props => props.hasData ? "" : `border-radius: ${props.borderRadius || `20px`}; margin-bottom: 12px;`}
  ${props => props.hasData ? "" : "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;"}
  height: ${props => props.boxHeight || "248px"};
  display: flex;
  flex-direction: ${props => props.flexDirection || "column"};
  ${props => props.isMobile ? "align-items: center;" : ""}
  ${props => props.margin ?  `margin: ${props.margin};` : ""}
  ${props => props.hasData ? "" : "justify-content: center;"}
  ${props => props.noBackground ? "" : "background-color: white;"}
`;

export const CloseButton = styled(IconButton)`
  &&{
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;


export const StyledMenu = styled(Menu)`
  &&&& {
    margin-top: 36px;
    margin-left: ${props => props.removeEdit ? "-30px" : "-50px"};
  }

  .MuiMenu-list {
    display: flex;
    flex-direction: row;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &&.MuiListItem-gutters {
    padding-left: 4px;
    padding-right: 4px;
  }
`;

export const StyledButton = styled(Button)`
  && {
    width: ${(props) => (props.width ? props.width : '100px')};
    height: 36px;
    background-color: white;
    border: 1px solid ${(props) => ((props.title == "Review")? COLORS.primaryOrange :
                                    (props.title == "download")? COLORS.primaryOrange :
                                    (props.title == "view")? COLORS.primaryOrange : COLORS.primaryOrange)};
    box-sizing: border-box;
    border-radius: 6px;
    color: ${(props) => ((props.title == "Review")? COLORS.primaryOrange :
                         (props.title == "Download")? COLORS.primaryOrange :
                         (props.title == "view")? COLORS.primaryOrange : COLORS.primaryOrange)};
    font-family: SF Pro Text;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.5s ease;
    text-transform: capitalize;

    &:hover {
      background-color: ${(props) => ((props.title == "Review")? COLORS.primaryOrange :
                                      (props.title == "Download")? COLORS.primaryOrange :
                                      (props.title == "view")? COLORS.primaryOrange : COLORS.primaryOrange)};
      color: white;
    }
`;



