export const emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const dateRegex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/\d{4}$/;
export const phoneRegex = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;

export const convertPhoneNumberCanadaUSA = (phoneNumber) => {

  if (!phoneNumber) {
    return ""; 
  }

  phoneNumber = phoneNumber.toString(); 
  phoneNumber = phoneNumber.replace(/\D/g, "");

  if (phoneNumber.length === 10 && !phoneNumber.startsWith("1"))  {
    phoneNumber = "1" + phoneNumber;
  }

  const digits = phoneNumber.match(/\d/g);

  if (!digits) {
    return "";
  }

  if (digits.length === 11 && phoneNumber.startsWith("1")) {
    const convertedPhoneNumber = `+1 (${digits[1]}${digits[2]}${digits[3]}) ${digits[4]}${digits[5]}${digits[6]}-${digits[7]}${digits[8]}${digits[9]}${digits[10]}`;
    return convertedPhoneNumber;
  } else if (digits.length === 10 && phoneNumber.startsWith("1")) {
    const convertedPhoneNumber = `+1 (${digits[1]}${digits[2]}${digits[3]}) ${digits[4]}${digits[5]}${digits[6]}-${digits[7]}${digits[8]}${digits[9]}`;
    return convertedPhoneNumber;
  } else if (digits.length > 11 && !phoneNumber.startsWith("1")) {
    const convertedPhoneNumber = `+${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6)}`;
    return convertedPhoneNumber;
  } else if (digits.length < 10 && !phoneNumber.startsWith("1")) {
    const convertedPhoneNumber = `+${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6)}`;
    return convertedPhoneNumber;
  }
    
};


export const convertNumberToRoman = (num) => {
  const romanNumerals = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };

  let roman = '';

  for (let i in romanNumerals) {
    while (num >= romanNumerals[i]) {
      roman += i;
      num -= romanNumerals[i];
    }
  }

  return roman;
};


