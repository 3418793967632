let files = [];

// Getter function to retrieve the files array
export function getFiles() {
  return files;
}

// Setter function to update the files array
export function setFiles(newFiles) {
  if (Array.isArray(newFiles)) {
    files = newFiles;
  } else {
    console.error('setFiles expects an array as an argument');
  }
}

export default files;