import React from 'react';
import { Text, View, StyleSheet, Link } from '@react-pdf/renderer';
import { convertPhoneNumberCanadaUSA } from "common/regex";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 10,
    justifyContent: 'flex-end',
    width: '50%',
    flex: 1,
    paddingLeft: 10,
    paddingRight: 30,
  },
  issuedBy: {
    marginTop: 10,
    paddingBottom: 3,
    fontFamily: 'Helvetica',
    fontSize: 14,
    color: '#B9B9B9',
    fontWeight: 600,
  },
  spanText: {
    color: '#B9B9B9',
  },
});

const PageReportIssuedBy = ({ csrData }) => (
  <View style={styles.headerContainer}>
    <Text style={styles.issuedBy}>Report Issued by</Text>
    <Text>
      <span style={styles.spanText}>Name: </span>
      {csrData?.data?.issuer_name}
    </Text>
    <Text>
      <span style={styles.spanText}>Role: </span>
      {csrData?.data?.issuer_role}
    </Text>
    <Text>
      <span style={styles.spanText}>Email: </span>
      {csrData?.data?.issuer_email}
    </Text>
    <Text>
      <span style={styles.spanText}>Phone Number: </span>
      {convertPhoneNumberCanadaUSA(csrData?.data?.issuer_phone_number)}
    </Text>
  </View>
);

export default PageReportIssuedBy;
