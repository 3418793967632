import styled, { keyframes } from "styled-components";

export const StyledRadioWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledAutoConfirmButton = styled.button`
  color: ${(props) => (props.enabled ? "white" : "#ff6d1d")};
  background-color: ${(props) => (props.enabled ? "#ff6d1d" : "white")};
  border-radius: 6px;
  border: 1px solid #ff6d1d;
  font-weight: 600;
  padding: 4px 12px;
  cursor: pointer;
  margin-left: 20px;
  &:hover {
    opacity: 0.8;
  }
`;

export const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const StyledClassificationSkeleton = styled.div`
  width: 710px;
  height: 79px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  padding: 16px;
  background: #f0f0f0;
  background-image: linear-gradient(
    to right,
    #f0f0f0 0%,
    #ffffff 20%,
    #f0f0f0 40%,
    #f0f0f0 100%
  );
  background-repeat: no-repeat;
  background-size: 200px 100%;
  animation: ${shimmer} 1.2s infinite linear;
  border-radius: 6px;
`;

export const StyledClassifiedNameSelect = styled.select`
  padding: 12px 19px;
  border-radius: 6px;
  font-family: "SF Pro Text";
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #ebe9f1;
  outline: none;

  &:focus {
    border-color: #ff6d1d;
  }
`;

export const StyledCustomRadio = styled.input.attrs({ type: "radio" })`
  appearance: none;
  border: 1px solid #ff6d1d;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;

  ${({ confirmed }) =>
    confirmed &&
    `
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      background-color: #FF6D1D;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  `}
`;
