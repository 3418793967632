import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - 172px);
  background: #f5f5f5;

  padding: 20px 35px;
`;

export const ChangeMode = styled.div`
  margin-bottom: 10px;
  width: 260px;
  height: 38px;

  background: white;

  display: flex;
  flex-direction: row;
  align-self: flex-end;
`;

export const ChangeModeItem = styled.div`
  width: 130px;
  height: 38px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.5px;

  color: ${(props) => (props.active ? "#000000" : "#c4c4c4")};

  ${(props) => (props.active ? "border-bottom: 3px solid #F26934;" : "")}
`;
