import React from 'react';

const NextArrow = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.01021 8L6.28585 5.17732C6.02591 4.90799 6.02591 4.47132 6.28585 4.202C6.5458 3.93267 6.96725 3.93267 7.2272 4.202L10.4222 7.51234C10.6822 7.78167 10.6822 8.21833 10.4222 8.48766L7.2272 11.798C6.96725 12.0673 6.5458 12.0673 6.28585 11.798C6.02591 11.5287 6.02591 11.092 6.28585 10.8227L9.01021 8Z" fill="#6E6B7B"/>
    </svg>
  )
};

export default NextArrow;


