import React from "react";

const COIcon = () => {
  return (
    <svg
      width="102"
      height="102"
      viewBox="0 0 102 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_317_1389)">
        <rect
          x="8"
          y="4"
          width="86"
          height="86"
          rx="16"
          fill="#FFDB98"
          shape-rendering="crispEdges"
        />
        <g clip-path="url(#clip0_317_1389)">
          <path
            d="M54.6703 70.9701C49.1178 71.6622 43.4952 70.6583 38.4567 68.0753L24.5 71.2425L28.3604 58.8904C21.4592 48.0047 24.1258 33.9581 34.5964 26.0338C45.0669 18.1126 60.1046 18.7619 69.7703 27.554C73.8802 31.2945 76.4815 36.0168 77.5 40.9925M75.9343 51.3295H68.5105C67.3292 51.3295 66.1962 51.8301 65.3609 52.721C64.5255 53.612 64.0563 54.8204 64.0563 56.0804C64.0563 57.3403 64.5255 58.5487 65.3609 59.4397C66.1962 60.3306 67.3292 60.8312 68.5105 60.8312H71.4801C72.6614 60.8312 73.7944 61.3317 74.6297 62.2227C75.465 63.1136 75.9343 64.322 75.9343 65.582C75.9343 66.842 75.465 68.0504 74.6297 68.9413C73.7944 69.8323 72.6614 70.3328 71.4801 70.3328H64.0563M69.9953 70.3328V73.5M69.9953 48.1623V51.3295"
            stroke="white"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_317_1389"
          x="0"
          y="0"
          width="102"
          height="102"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_317_1389"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_317_1389"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_317_1389">
          <rect
            width="56"
            height="56"
            fill="white"
            transform="translate(23 19)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default COIcon;
