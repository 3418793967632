import styled from "styled-components";
import { Typography } from "@material-ui/core";
import {
  FONT_SIZES,
  TYPOGRAPHY,
  COLORS,
  DASHBOARDHEADER_HEIGHT,
} from "../../constants";

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding 10px 20px;
  border-radius: 7px;
  background-color: ${COLORS.primaryRed};
`;

export const ErrorText = styled.div`
  font-size: ${FONT_SIZES.body_2};
  line-height: ${FONT_SIZES.body_1};
  color: white;
  font-weight: 700;
  margin-left: 10px;
`;

export const BillingPromoText = styled.div`
  margin-top: 0;
  font-size: ${FONT_SIZES.body_4};
  color: ${COLORS.primaryOrange};
`;

export const CenterRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PackagesContainer = styled.div`
  width: 100%;
  max-width: 1500px;
  padding: 20px
    ${(props) =>
      props.horizontalMargins
        ? [props.horizontalMargins.toString(), "px"].join("")
        : "200px"};
  margin-bottom: 50px;
`;

export const SubscribeRoot = styled.div`
  margin-top: ${DASHBOARDHEADER_HEIGHT}px;
  /*display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  width: 100vw;
  height: 100vh;
  align-items: center;*/
  ${(props) => (props.isMobile ? "position: relative" : "")};
`;

export const ToggleDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ToggleText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${FONT_SIZES.body_3};
    text-align: left;
    color: ${COLORS.textGrey};
    ${(props) => (props.bold ? "font-weight: 700" : "")};
  }
`;

export const RowBreak = styled.div`
  height: 50px;
`;

export const PackageCarouselContainer = styled.div`
  display: flex;
  align-items: stretch;
  overflow: hidden;
  overflow-x: scroll;

  /* Temporarily hid the scrollbars. Remove this if more packages are added, and scrolling is expected to be required */
  -ms-overflow-style: none;
  scrollbar-width: none;
  &&::-webkit-scrollbar {
    display: none;
  }
`;

export const PackageItemBoxDescription = styled.span`
  text-align: center;
  font-size: ${FONT_SIZES.body_5};
  ${(props) => (props.clickable ? `color: ${COLORS.primaryOrange};` : "")}
  ${(props) => (props.clickable ? "cursor: pointer" : "")};
  ${(props) => (props.minHeight ? `min-height: ${props.minHeight}px;` : "")}
`;

export const InteractionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0px;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
`;

export const ContentArea = styled.div`
  margin-bottom: 20px;
`;

export const ButtonArea = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

export const ButtonAreaVertical = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

export const ImagePopup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

export const TextArea = styled.div``;

export const LargeText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${(props) => (props.isMobile ? "22px" : "28px")};
    font-style: normal;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0px;
    text-align: center;
  }
`;

export const SmallText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.secondaryFont};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 20px;
  }
`;

export const ConfirmationContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SuccessLogo = styled.img`
  width: 300px;
  height: 300px;
`;

export const SuccessText = styled.div`
  text-align: center;
  font-size: ${FONT_SIZES.body_5};
`;

export const PopupLoadingContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoadingImagePopup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
`;

export const PopupLoadingText = styled.div`
  margin-top: 30px;
  text-align: center;
  font-size: ${FONT_SIZES.body_5};
  line-height: normal;
`;
