import React from 'react';
import BorderLessTableIndex from './borderLessTableIndex.js';
import { StyledBorderLessConciseTableContainer, StyledConciseTableContainer } from './styles';


const BorderLessConciseTable = (props) => {
  const { data, length, height, width } = props;
  const show = data && (data.length > length);

  return (
    <StyledBorderLessConciseTableContainer show={show} length={length} height={height} width={width}>
      <BorderLessTableIndex concise {...props} />
    </StyledBorderLessConciseTableContainer>
  );
};

export default BorderLessConciseTable;
