import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Line } from '@react-pdf/renderer';
import PageThreeInsuranceTableRowChild from "./pageThreeInsuranceTableRowChild";


const styles = StyleSheet.create({
    row: {
        borderBottomColor: '#EBE9F1',
        backgroundColor: '#F8F8F8',
        alignItems: 'left',
        fontStyle: 'normal',
        paddingLeft: 15,
        color: '#000000',
        fontWeight: 400,
        fontSize: 10,
        lineHeight: 1.5,
        paddingTop: 6,
        paddingBottom: 6,
    },
    spanText: {
        color: '#888888',
    },
    spanText2: {
        color: '#888888',
        marginLeft: 5,
    },
    headerContainer1: {
        marginTop: 10,
        justifyContent: 'flex-start',
        width: '60%',
    },
    headerContainer2: {
        marginTop: 10,
        justifyContent: 'flex-end',
        width: '40%',
        flex: 1,
    },
    viewContainer: {
        borderRadius: 6,
        backgroundColor: '#F8F8F8',
        width: 537,
        paddingTop: 3,
        paddingBottom: 3,
    },
});

const PageThreeInsuranceTableRow = ({ items }) => {
    const rows = items?.map(item =>
        <View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
                <View style={styles.headerContainer1}>
                    <Text>
                        <span style={styles.spanText}>Institution Name: </span>
                        {item?.company_name}
                    </Text>
                    <Text>
                        <span style={styles.spanText}>Address: </span>
                        {item?.suite_number}{"-"}{item?.address}{","}{item?.city}{","}{item?.province}{" "}{item?.postal}
                    </Text>
                </View>
                <View style={styles.headerContainer2}>
                    <Text>
                        <span style={styles.spanText}>Contact Name: </span>
                        {item?.contact_first_name} {item?.contact_last_name}
                    </Text>

                    <Text>
                        <span style={styles.spanText2}>Email: </span>
                        {item?.contact_email}
                    </Text>
                </View>
            </View>
            <View style={styles.viewContainer}>
                <PageThreeInsuranceTableRowChild items={item?.data} />
            </View>
        </View>
    );
    return (<Fragment>{rows}</Fragment>)
};

export default PageThreeInsuranceTableRow;
