import React from "react";

import {
  StyledCellTitle,
  StyledCellCommon,
  StyledCellAction,
  StyledAlertsCircle,
  StyledOuterContainer,
  StyledTextContainer,
} from "./styles";
import { ReactComponent as ViewIcon } from "../../../../assets/common/ViewIcon.svg";
import { ReactComponent as ProjectAlertIcon } from "../../../../assets/serviceProvider/projectAlertIcon.svg";
import { ReactComponent as InsuranceAlertIcon } from "../../../../assets/serviceProvider/insuranceAlertIcon.svg";
import { ReactComponent as WipAlertIcon } from "../../../../assets/serviceProvider/wipAlertIcon.svg";

import ConciseTable from "components/filesListTable/conciseTable";
import DateTimePickerField from "components/dateTimePickerField";

export const AllAlertsTable = ({
  data,
  isMobile,
  borderRadius,
  boxShadow,
  isShortened,
}) => {
  console.log("intable", data);
  const DateFormatter = ({ dateString }) => {
    // Parse the date string and create a Date object
    const date = new Date(dateString + "T00:00:00");

    // Define an array of month names
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Format the date
    const formattedDate = `${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;

    return <span>{formattedDate}</span>;
  };
  const dateComparison = (compareDate) => {
    // Get today's date (without time)
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Parse the comparison date string
    const comparisonDate = new Date(compareDate + "T00:00:00");

    // Compare the dates
    let result;
    if (comparisonDate < today) {
      result = " is expired";
    } else if (comparisonDate >= today) {
      result = " is expiring";
    }

    return result;
  };
  const body = (bodyData) => {
    console.log("bodyData", bodyData);
    return (
      <>
        {bodyData?.type && bodyData?.institutionId && (
          <StyledCellTitle component={"tr"} borderRadius={borderRadius}>
            <StyledOuterContainer>
              {bodyData.type === "project" ? (
                <>
                  <StyledAlertsCircle>
                    <ProjectAlertIcon />
                  </StyledAlertsCircle>
                  <div style={{ marginLeft: "12px", width: "100%" }}>
                    <StyledTextContainer>
                      {bodyData?.projectName || ""}
                      <span style={{ fontWeight: "400" }}> for </span>
                      <span style={{ fontWeight: "700" }}>
                        {bodyData?.owner_name || ""}
                      </span>
                      <span style={{ fontWeight: "400" }}>
                        {" "}
                        is scheduled for completion on{" "}
                        <DateFormatter dateString={bodyData?.completion_date} />
                        .
                      </span>
                    </StyledTextContainer>
                  </div>
                </>
              ) : bodyData.type === "insurance" ? (
                <>
                  <StyledAlertsCircle style={{ background: "#FFEBC6" }}>
                    <InsuranceAlertIcon />
                  </StyledAlertsCircle>
                  <div style={{ marginLeft: "12px", width: "100%" }}>
                    <StyledTextContainer>
                      <span style={{ fontWeight: "400" }}>
                        {bodyData?.insurance_type || ""} from{" "}
                      </span>
                      <span style={{ fontWeight: "700" }}>
                        {bodyData?.company_name || ""}
                      </span>
                      <span style={{ fontWeight: "400" }}>
                        {" "}
                        {dateComparison(bodyData?.expiry_date)} on{" "}
                        <DateFormatter dateString={bodyData?.expiry_date} />.
                      </span>
                    </StyledTextContainer>
                  </div>
                </>
              ) : bodyData.type === "wip" ? (
                <>
                  <StyledAlertsCircle style={{ background: "#C0FAD2" }}>
                    <WipAlertIcon />
                  </StyledAlertsCircle>
                  <div style={{ marginLeft: "12px", width: "100%" }}>
                    <StyledTextContainer>
                      <span style={{ fontWeight: "400" }}>
                        {" "}
                        WIP has not been updated in the last 90 days{" "}
                      </span>
                    </StyledTextContainer>
                  </div>
                </>
              ) : (
                ""
              )}
            </StyledOuterContainer>
          </StyledCellTitle>
        )}
      </>
    );
  };

  //   const actions = (props) => {
  //     return (
  //       <>
  //         <StyledCellAction
  //           component={"tr"}
  //           scope="row"
  //           align="center"
  //           borderRadius={borderRadius}
  //         >
  //           <ActionsMenu
  //             client_id={props.id}
  //             selectTab={selectTab}
  //             companyName={props.institution_name}
  //           />
  //         </StyledCellAction>
  //       </>
  //     );
  //   };

  const createHeader = (title, width, align) => {
    return {
      title,
      width: width || "",
      align: align || "left",
    };
  };

  const headers = [createHeader("")];

  return (
    <div>
      <ConciseTable
        headers={headers}
        largefont={false}
        borderSpacing="0 5px !important"
      />
      <ConciseTable
        // actions={actions}
        // actionType="client"
        data={data}
        //height="1200px"
        body={body}
        boxShadow={boxShadow}
        borderRadius={borderRadius}
        // headers={isMobile ? "" : isShortened ? shortenedHeaders : headers}
      />
    </div>
  );
};
