import React, { useState, useEffect } from "react";
import { FunctionalButton } from 'components/functionalButton';
import PledgxLogo from "assets/common/pledgxLogo";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Input from "components/inputField";
import { ReactComponent as RedRectanglurLine } from "pages/underwriters/components/contractStatusReportSending/assets/redRectanglurLine.svg";
import { numberWithCommas } from "common/numberFormat";
import { SubmitStatusReportRequest } from "./submitStatusReportRequest"
import { SubmitErrorMessage } from "./submitErrorMessage"
import { AlertPopup } from "components/alertPopup/alertPopup.js"
import { getClientBondsInfo, getStatusReportCheck } from "data/actions/underwriters";
import { clientBondsSelector, clientStatusReportCheckSelector } from "data/selectors/underwriters";
import { contactSelector } from "data/selectors/contact";
import InputArea from "components/inputAreaBG/index.js";
import { RadioButton } from "components/radioButton/index.js";
import CheckedRadioButton from "assets/dashboardIcons/checkedRadioButton.js";
import UncheckedRadioButton from "assets/dashboardIcons/uncheckedRadioButton.js";
import { formatPhoneNumber } from "common/formatPhoneNumber";
import { NumberFormatOnly, NumberFormatWithCurrency } from 'components/locale/numberFormatOnly'
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import './styles.css';


import {
  FormTitle,
  TitleDiv,
  ButtonDiv,
  StyledMainCSR,
  StyledLeftCSR,
  StyledRightCSR,
  TextTitle,
  LabelText,
  ScreenRoot,
  FormContainer,
  FieldArea,
  StyledLeftCSRLeft,
  StyledRightCSRRight,
  StyledLeftCSRLeftLeft,
  StyledRightCSRRightRight,
  StyledMainCSRRight,
  StyledMainCSRLeft,
  StyledEditRecordButton,
  StyledRightCSRRemarks,
  StyledIsProgressSatisfactory
} from "./styles";


function formatDate(convertDate) {
  let formattedDate = "";
  if (convertDate !== "") {
    const dateString = convertDate;
    const dateObject = new Date(dateString);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear().toString();
    formattedDate = `${month}/${day}/${year}`;
  }
  return formattedDate;
}

function ContractStatusReportSending({
  isMobile
}) {
  const [data, setData] = useState({});
  let history = useHistory();
  const dispatch = useDispatch();
  const locationState = history.location.state;
  const { statusReportData, clientCompanyName } = locationState;
  const projectData = statusReportData;
  const [consultant, setConsultant] = useState(projectData?.consultant);
  const [consultantRole, setConsultantRole] = useState(projectData?.consultant ? "Consultant" : "");
  const [consultantEmail, setConsultantEmail] = useState(projectData?.consultant_email || "");
  const [consultantPhoneNumber, setConsultantPhoneNumber] = useState(projectData?.consultant_phone || "");
  const [owner, setOwner] = useState(projectData?.owner);
  const [ownerRole, setOwnerRole] = useState(projectData?.owner ? "Owner" : "");
  const [ownerEmail, setOwnerEmail] = useState(projectData?.owner_email || "");
  const [ownerPhoneNumber, setOwnerPhoneNumber] = useState(projectData?.owner_phone || "");
  const [openSubmitStatusReportPopup, setOpenSubmitStatusReportPopup] = useState(false);
  const accountId = projectData?.account_id;
  const bondId = projectData?.bond_id;
  const clientBondss = useSelector(clientBondsSelector);
  const clientStatusReportCheck = useSelector(clientStatusReportCheckSelector);
  const [bondData, setBondData] = useState([]);
  const [statusReportCheckData, setStatusReportCheckData] = useState([]);
  const contactData = useSelector(contactSelector);
  const underwriter_name = contactData?.first_name + " " + contactData?.last_name
  const underwriter_role = contactData?.title
  const underwriter_phone = contactData?.phone
  const underwriter_email = contactData?.email
  const company_address = contactData?.company?.suite + "-" + contactData?.company?.address + ", " + contactData?.company?.city + " " + contactData?.company?.province + " " + contactData?.company?.postal
  const [underwriter, setUnderwriter] = useState(underwriter_name || "");
  const [underwriterPhoneNumber, setUnderwriterPhoneNumber] = useState(underwriter_phone || "");
  const [underwriterRole, setUnderwriterRole] = useState(underwriter_role || "Underwriter");
  const [underwriterEmail, setUnderwriterEmail] = useState(underwriter_email || "");
  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);
  const [errors, setErrors] = useState([]);
  const [createdDate, setCreatedDate] = useState("");
  const [statusReport, setStatusReport] = useState("");
  let createdAtDate = ""

  useEffect(() => {
    dispatch(getClientBondsInfo({ account_id: accountId, bond_id: bondId }));
  }, []);


  useEffect(() => {
    if (clientBondss) {
      setBondData(clientBondss);
    }
  }, [clientBondss]);


  useEffect(() => {
    dispatch(getStatusReportCheck({ account_id: accountId, bond_id: bondId }));
  }, []);


  useEffect(() => {
    if (clientStatusReportCheck) {
      setStatusReportCheckData(clientStatusReportCheck);
      setCreatedDate(statusReportCheckData[0]?.created_at || "");
      setStatusReport(statusReportCheckData[0]?.status);

    }
  }, [clientStatusReportCheck]);

  const createdFormatDate = formatDate(createdDate);
  const currentDate = new Date();
  const currentFormatDate = formatDate(currentDate);


  useEffect(() => {
    const updatedData = { ...data };
    if (projectData?.consultant !== "") {
      updatedData["consultant"] = consultant;
      updatedData["consultant_role"] = consultantRole;
      updatedData["consultant_email"] = consultantEmail;
      updatedData["consultant_phone"] = consultantPhoneNumber;
      updatedData["owner"] = "";
      updatedData["owner_role"] = "";
      updatedData["owner_email"] = "";
      updatedData["owner_phone"] = "";
    } else if (projectData?.owner && projectData?.consultant === "") {
      updatedData["owner"] = owner;
      updatedData["owner_role"] = ownerRole;
      updatedData["owner_email"] = ownerEmail;
      updatedData["owner_phone"] = ownerPhoneNumber;
      updatedData["consultant"] = "";
      updatedData["consultant_role"] = "";
      updatedData["consultant_email"] = "";
      updatedData["consultant_phone"] = "";
    }
    updatedData["underwriter"] = underwriter;
    updatedData["underwriter_role"] = underwriterRole;
    updatedData["underwriter_email"] = underwriterEmail;
    updatedData["underwriter_phone"] = underwriterPhoneNumber;
    setData(updatedData);
  }, [
    owner,
    ownerRole,
    ownerEmail,
    ownerPhoneNumber,
    consultant,
    consultantRole,
    consultantEmail,
    consultantPhoneNumber,
    underwriter,
    underwriterRole,
    underwriterEmail,
    underwriterPhoneNumber
  ]);

  let formattedEffectiveDate = "";

  if (bondData[0]?.effective_date !== "") {
    const dateString = bondData[0]?.effective_date;
    const dateObject = new Date(dateString);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear().toString();
    formattedEffectiveDate = `${day}/${month}/${year}`;
  }


  const handleGoBack = () => {
    history.goBack();
  };


  const handleSendingStatus = () => {
    const company_name = contactData?.company?.name ? contactData?.company?.name : ""
    const contractor_name = clientCompanyName ? clientCompanyName : ""
    const project_title = projectData?.project_name ? projectData?.project_name : ""
    const project_actual_value = projectData?.value ? projectData?.value : ""
    const newData = { ...data, account_id: projectData?.account_id, bond_id: projectData?.bond_id, project_id: projectData?.project_number, project_name: projectData?.project_name, project_status: projectData?.project_status, contractor_name: contractor_name, company_address: company_address, company_name: company_name, project_title: project_title, project_actual_value: project_actual_value };

    const checkData = data
    const errors = []

    // Check issuer
    if (checkData.consultant === "") {
      errors.push("Issuer field is empty");
    } else if (checkData.consultant === "" && checkData.owner === "") {
      errors.push("Issuer field is empty");
    }

    // Check issuer_role
    if (checkData.consultant_role === "") {
      errors.push("Issuer role field is empty");
    } else if (checkData.consultant_role === "" && checkData.owner_role === "") {
      errors.push("Issuer role field is empty");
    }

    // Check issuer_email
    if (checkData.consultant_email === "") {
      errors.push("Issuer email field is empty");
    } else if (checkData.consultant_email === "" && checkData.owner_email === "") {
      errors.push("Issuer email field is empty");
    }

    // Check issuer_phone
    if (checkData.consultant_phone === "") {
      errors.push("Issuer phone field is empty");
    } else if (checkData.consultant_phone === "" && checkData.owner_phone === "") {
      errors.push("Issuer phone field is empty");
    }

    // Check underwriter
    if (checkData.underwriter === "") {
      errors.push("Underwriter field is empty");
    }

    // Check underwriter_role
    if (checkData.underwriter_role === "") {
      errors.push("Underwriter role field is empty");
    }

    // Check underwriter_email
    if (checkData.underwriter_email === "") {
      errors.push("Underwriter email field is empty");
    }

    // Check underwriter_phone
    if (checkData.underwriter_phone === "") {
      errors.push("Underwriter phone field is empty");
    }


    if (errors.length > 0) {
      setErrors(errors);
      setOpenErrorPopup(true);
    } else if (createdFormatDate === currentFormatDate) {
      setOpenAlertPopup(true);
      setOpenSubmitStatusReportPopup(false);
    } else {
      setData(newData);
      setOpenSubmitStatusReportPopup(true);
    }
  };




  return (
    <ScreenRoot isMobile={isMobile}>
      <FormContainer isMobile={isMobile}>
        <TitleDiv>
          <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
          <FormTitle style={{ marginTop: 10 }}>
            {"Contract Status Report"}
          </FormTitle>
        </TitleDiv>

        <FieldArea width={isMobile ? "100%" : "100%"}>
          <StyledMainCSR>
            <StyledLeftCSR>
              <TextTitle>Bonding Company Details</TextTitle>
              <Input
                label="Company Name"
                type="text"
                labelSize="12px"
                fontSize="18px"
                marginTop="0px"
                backgroundColor="#EBE9F1"
                value={contactData?.company?.name || ""}
                disabled
              />

              <Input
                label="Company Address"
                type="text"
                labelSize="12px"
                fontSize="18px"
                marginTop="16px"
                backgroundColor="#EBE9F1"
                value={company_address || ""}
                disabled
              />

              <Input
                label="Contractor Name"
                type="text"
                labelSize="12px"
                fontSize="18px"
                marginTop="16px"
                backgroundColor="#EBE9F1"
                value={clientCompanyName || ""}
                disabled
              />
              <div style={{ marginTop: "18px" }}></div>
              <TextTitle>Bond Details</TextTitle>

              <Input
                label="Bond Id"
                type="text"
                labelSize="12px"
                fontSize="18px"
                marginTop="0px"
                backgroundColor="#EBE9F1"
                value={projectData?.bond_id || ""}
                disabled
              />

              <div style={{ marginTop: "18px" }}></div>
              <StyledMainCSR>
                <StyledLeftCSRLeft>
                  <Input
                    label="Effective Date"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="0px"
                    backgroundColor="#EBE9F1"
                    value={formattedEffectiveDate || ""}
                    disabled
                  />
                </StyledLeftCSRLeft>

                <StyledRightCSRRight>
                  <Input
                    label="Obligee"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="0px"
                    backgroundColor="#EBE9F1"
                    value={bondData[0]?.Obligee !== "NULL" ? bondData[0]?.obligee : ""}
                    disabled
                  />
                </StyledRightCSRRight>
              </StyledMainCSR>
            </StyledLeftCSR>

            <StyledRightCSR>
              <TextTitle>Project Details</TextTitle>
              <Input
                label="Project Title"
                type="text"
                labelSize="12px"
                fontSize="18px"
                marginTop="0px"
                backgroundColor="#EBE9F1"
                value={projectData?.project_name}
                disabled
              />

              <Input
                label="Project Status"
                type="text"
                labelSize="12px"
                fontSize="18px"
                marginTop="16px"
                backgroundColor="#EBE9F1"
                value={projectData?.project_status || ""}
                disabled
              />

              <Input
                label="Orginal Contract Price($)"
                type="text"
                labelSize="12px"
                fontSize="18px"
                marginTop="16px"
                backgroundColor="#EBE9F1"
                value={NumberFormatOnly(projectData?.value) || ""}
                disabled
              />

              <div style={{ marginTop: "18px" }}></div>
              <TextTitle>Report Details</TextTitle>
              {projectData?.project_status === "Underway" && (
                <>
                  <StyledMainCSR>
                    <StyledLeftCSRLeft>
                      <Input
                        label="Anticipated Completion Date"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="0px"
                        backgroundColor="#EBE9F1"
                        disabled
                      />
                    </StyledLeftCSRLeft>

                    <StyledRightCSRRight>
                      <Input
                        label="Reporting Date"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="0px"
                        backgroundColor="#EBE9F1"
                        disabled
                      />
                    </StyledRightCSRRight>
                  </StyledMainCSR>

                  <StyledMainCSR>
                    <StyledMainCSRLeft>
                      <StyledLeftCSRLeftLeft>
                        <Input
                          label="Current Contract Price($)"
                          type="text"
                          labelSize="12px"
                          fontSize="18px"
                          marginTop="16px"
                          backgroundColor="#EBE9F1"
                          disabled
                        />
                      </StyledLeftCSRLeftLeft>
                      <StyledRightCSRRightRight>
                        <Input
                          label="%"
                          type="text"
                          labelSize="12px"
                          fontSize="18px"
                          marginTop="16px"
                          paddingRight="5px"
                          paddingLeft="5px"
                          backgroundColor="#EBE9F1"
                          disabled
                        />
                      </StyledRightCSRRightRight>
                    </StyledMainCSRLeft>

                    <StyledMainCSRRight>
                      <StyledLeftCSRLeftLeft>
                        <Input
                          label="Work Approved Amount($)"
                          type="text"
                          labelSize="12px"
                          fontSize="18px"
                          marginTop="16px"
                          backgroundColor="#EBE9F1"
                          disabled
                        />

                      </StyledLeftCSRLeftLeft>
                      <StyledRightCSRRightRight>
                        <Input
                          label="%"
                          type="text"
                          labelSize="12px"
                          fontSize="18px"
                          marginTop="16px"
                          paddingRight="5px"
                          paddingLeft="5px"
                          backgroundColor="#EBE9F1"
                          disabled
                        />
                      </StyledRightCSRRightRight>
                    </StyledMainCSRRight>
                  </StyledMainCSR>


                  <StyledMainCSR>
                    <StyledMainCSRLeft>
                      <StyledLeftCSRLeftLeft>
                        <Input
                          label="Paid to Contractor Amount($)"
                          type="text"
                          labelSize="12px"
                          fontSize="18px"
                          marginTop="16px"
                          backgroundColor="#EBE9F1"
                          disabled
                        />
                      </StyledLeftCSRLeftLeft>
                      <StyledRightCSRRightRight>
                        <Input
                          label="%"
                          type="text"
                          labelSize="12px"
                          fontSize="18px"
                          marginTop="16px"
                          paddingRight="5px"
                          paddingLeft="5px"
                          backgroundColor="#EBE9F1"
                          disabled
                        />
                      </StyledRightCSRRightRight>
                    </StyledMainCSRLeft>

                    <StyledMainCSRRight>
                      <StyledLeftCSRLeftLeft>
                        <Input
                          label="Holdback Amount($)"
                          type="text"
                          labelSize="12px"
                          fontSize="18px"
                          marginTop="16px"
                          backgroundColor="#EBE9F1"
                          disabled
                        />
                      </StyledLeftCSRLeftLeft>
                      <StyledRightCSRRightRight>
                        <Input
                          label="%"
                          type="text"
                          labelSize="12px"
                          fontSize="18px"
                          marginTop="16px"
                          paddingRight="5px"
                          paddingLeft="5px"
                          backgroundColor="#EBE9F1"
                          disabled
                        />
                      </StyledRightCSRRightRight>
                    </StyledMainCSRRight>
                  </StyledMainCSR>
                </>
              )}

              {projectData?.project_status === "Completed" && (
                <>

                  <StyledMainCSR>
                    <StyledMainCSRLeft>

                      <Input
                        label="Completion Date"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        backgroundColor="#EBE9F1"
                        disabled
                      />


                    </StyledMainCSRLeft>

                    <StyledMainCSRRight>
                      <StyledLeftCSRLeftLeft>
                        <Input
                          label="Final Contract Price($)"
                          type="text"
                          labelSize="12px"
                          fontSize="18px"
                          marginTop="16px"
                          backgroundColor="#EBE9F1"
                          disabled
                        />

                      </StyledLeftCSRLeftLeft>
                      <StyledRightCSRRightRight>
                        <Input
                          label="%"
                          type="text"
                          labelSize="12px"
                          fontSize="18px"
                          marginTop="16px"
                          paddingRight="5px"
                          paddingLeft="5px"
                          backgroundColor="#EBE9F1"
                          disabled
                        />
                      </StyledRightCSRRightRight>
                    </StyledMainCSRRight>
                  </StyledMainCSR>


                </>
              )}


              <StyledMainCSR>
                <StyledIsProgressSatisfactory>
                  <LabelText style={{ marginTop: "16px" }}>Is Progress Satisfactory?</LabelText>
                  <StyledEditRecordButton style={{ display: 'flex', flexDirection: 'column', marginLeft: '-160px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-60px' }}>
                      <RadioButton
                        checkedIcon={<CheckedRadioButton color="#D0C9D6" />}
                        unCheckedIcon={<UncheckedRadioButton color="#D0C9D6" />}
                        disabled
                      />
                      <span style={{ marginLeft: '5px' }}>Yes</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-62px' }}>
                      <RadioButton
                        checkedIcon={<CheckedRadioButton color="#D0C9D6" />}
                        unCheckedIcon={<UncheckedRadioButton color="#D0C9D6" />}
                        disabled
                      />
                      <span style={{ marginLeft: '5px' }}>No</span>
                    </div>
                  </StyledEditRecordButton>
                </StyledIsProgressSatisfactory>

                <StyledRightCSRRemarks>
                  <LabelText style={{ marginTop: "15px" }}> Remarks </LabelText>
                  <InputArea
                    placeholder={"No Remarks"}
                    readOnly={true}
                  />
                </StyledRightCSRRemarks>

              </StyledMainCSR>
            </StyledRightCSR>
          </StyledMainCSR>

          <StyledMainCSR>
            <StyledLeftCSR>
              <div style={{ marginTop: '48px' }}> </div>
              <RedRectanglurLine />
            </StyledLeftCSR>

            <StyledRightCSR>
              <div style={{ marginTop: '48px' }}> </div>
              <RedRectanglurLine />
            </StyledRightCSR>
          </StyledMainCSR>

          <StyledMainCSR>
            <StyledLeftCSR>
              <TextTitle>Report Requested by</TextTitle>

              <StyledMainCSR>
                <StyledLeftCSRLeft>
                  <Input
                    label="Name"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    paddingRight="5px"
                    paddingLeft="5px"
                    value={underwriter}
                    setValue={(value) => setUnderwriter(value)}
                  />
                </StyledLeftCSRLeft>

                <StyledRightCSRRight>
                  <Input
                    label="Role"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    paddingRight="5px"
                    paddingLeft="5px"
                    value={underwriterRole}
                    setValue={(value) => setUnderwriterRole(value)}
                  />
                </StyledRightCSRRight>
              </StyledMainCSR>

              <StyledMainCSR>
                <StyledLeftCSRLeft>
                  <Input
                    label="Email"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    paddingRight="5px"
                    paddingLeft="5px"
                    value={underwriterEmail}
                    setValue={(value) => setUnderwriterEmail(value)}
                  />
                </StyledLeftCSRLeft>

                <StyledRightCSRRight>
                  <div style={{ marginTop: '16px', width: "100%", marginLeft: '0px', marginRight: '40px' }}>
                    <label htmlFor="phone" style={{ fontSize: '12px', marginLeft: '0px' }}>Phone Number</label>
                    <div style={{ height: '47px', width: "100%", marginTop: '-10px', marginLeft: '0px' }}>
                      <PhoneInput
                        id="phone"
                        country={'ca'}
                        value={underwriterPhoneNumber}
                        onChange={(value) => setUnderwriterPhoneNumber(value)}
                        inputStyle={{ fontSize: '18px', padding: '8px', lineHeight: '24px', marginLeft: '20px', paddingLeft: '28px', height: '100%', width: '91%' }}
                        dropdownStyle={{ fontSize: '18px', lineHeight: '24px' }}
                        className="custom-phone-input"
                      />
                    </div>
                  </div>

                </StyledRightCSRRight>
              </StyledMainCSR>
            </StyledLeftCSR>

            <StyledRightCSR>
              <TextTitle>Report Requested from</TextTitle>
              <StyledMainCSR>
                <StyledLeftCSRLeft>

                  {projectData?.consultant && (
                    <>
                      <Input
                        label="Name"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        paddingRight="5px"
                        paddingLeft="5px"
                        value={consultant}
                        setValue={(value) => setConsultant(value)}
                      />
                    </>

                  )}

                  {projectData?.owner && projectData?.consultant == "" && (
                    <>
                      <Input
                        label="Name"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        paddingRight="5px"
                        paddingLeft="5px"
                        value={owner}
                        setValue={(value) => setOwner(value)}
                      />
                    </>

                  )}

                </StyledLeftCSRLeft>


                <StyledRightCSRRight>

                  {projectData?.consultant && projectData?.consultant !== "" && (
                    <>
                      <Input
                        label="Role"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        paddingRight="5px"
                        paddingLeft="5px"
                        value={consultantRole}
                        setValue={(value) => setConsultantRole(value)}
                      />

                    </>
                  )}

                  {projectData?.owner && projectData?.consultant === "" && (
                    <>
                      <Input
                        label="Role"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        paddingRight="5px"
                        paddingLeft="5px"
                        value={ownerRole}
                        setValue={(value) => setOwnerRole(value)}
                      />

                    </>
                  )}


                </StyledRightCSRRight>
              </StyledMainCSR>

              <StyledMainCSR>
                <StyledLeftCSRLeft>
                  {projectData?.consultant && projectData?.consultant !== "" && (
                    <>
                      <Input
                        label="Email"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        paddingRight="5px"
                        paddingLeft="5px"
                        value={consultantEmail}
                        setValue={(value) => setConsultantEmail(value)}
                      />
                    </>
                  )}

                  {projectData?.owner && projectData?.consultant === "" && (
                    <>
                      <Input
                        label="Email"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        paddingRight="5px"
                        paddingLeft="5px"
                        value={ownerEmail}
                        setValue={(value) => setOwnerEmail(value)}
                      />

                    </>
                  )}

                </StyledLeftCSRLeft>

                <StyledRightCSRRight>
                  {projectData?.consultant && projectData?.consultant !== "" && (
                    <>

                      <div style={{ marginTop: '16px', width: "100%", marginLeft: '0px', marginRight: '40px' }}>
                        <label htmlFor="phone" style={{ fontSize: '12px', marginLeft: '0px' }}>Phone Number</label>
                        <div style={{ height: '47px', width: "100%", marginTop: '-10px', marginLeft: '0px' }}>
                          <PhoneInput
                            id="phone"
                            country={'ca'}
                            value={consultantPhoneNumber}
                            onChange={(value) => setConsultantPhoneNumber(value)}
                            inputStyle={{ fontSize: '18px', padding: '8px', lineHeight: '24px', marginLeft: '20px', paddingLeft: '28px', height: '100%', width: '91%' }}
                            dropdownStyle={{ fontSize: '18px', lineHeight: '24px' }}
                            className="custom-phone-input"
                          />
                        </div>
                      </div>


                    </>
                  )}

                  {projectData?.owner && projectData?.consultant === "" && (
                    <>

                      <div style={{ marginTop: '16px', width: "100%", marginLeft: '0px', marginRight: '40px' }}>
                        <label htmlFor="phone" style={{ fontSize: '12px', marginLeft: '0px' }}>Phone Number</label>
                        <div style={{ height: '47px', width: "100%", marginTop: '-10px', marginLeft: '0px' }}>
                          <PhoneInput
                            id="phone"
                            country={'ca'}
                            value={ownerPhoneNumber}
                            onChange={(value) => setOwnerPhoneNumber(value)}
                            inputStyle={{ fontSize: '18px', padding: '8px', lineHeight: '24px', marginLeft: '20px', paddingLeft: '28px', height: '100%', width: '91%' }}
                            dropdownStyle={{ fontSize: '18px', lineHeight: '24px' }}
                            className="custom-phone-input"
                          />
                        </div>
                      </div>

                    </>
                  )}

                </StyledRightCSRRight>
              </StyledMainCSR>

            </StyledRightCSR>
          </StyledMainCSR>
        </FieldArea>


        <ButtonDiv isMobile={isMobile}>
          <FunctionalButton
            buttonTitle="GO BACK"
            handleButton={handleGoBack}
            isReversedColor={true}
            height="48px"
            width="199px"
            marginTop="64px"
            marginRight="16px"
          />
          <FunctionalButton
            buttonTitle={"SUBMIT"}
            height="48px"
            width="199px"
            marginTop="64px"
            marginRight="0px"
            handleButton={() => handleSendingStatus()}
          />
        </ButtonDiv>
      </FormContainer>

      <SubmitStatusReportRequest
        isMobile={isMobile}
        data={data}
        openPopup={openSubmitStatusReportPopup}
        setOpenPopup={setOpenSubmitStatusReportPopup}
      />

      <SubmitErrorMessage
        isMobile={isMobile}
        errors={errors}
        openPopup={openErrorPopup}
        setOpenPopup={setOpenErrorPopup}
      />
      <AlertPopup
        openPopup={openAlertPopup}
        setOpenPopup={setOpenAlertPopup}
        headerText={'Status Report has already been requested.'}
        subHeaderText={'Sorry, you can not ask multiple status reports on the same day.'}
      />
    </ScreenRoot >

  )
}


export default ContractStatusReportSending;
