import styled from "styled-components";
import { COLORS } from '../../constants';

export const InputWrap = styled.div`
  position: relative;
  width: 100%;
  margin-top: 12px;
`;

export const InputLabel = styled.div`
  margin-bottom: 5px;
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
`;

export const ReadOnlyValue = styled.span`
  width: ${(props) => (props.width ? props.width : "100%")};
  min-height: 50px;

  padding-left: 15px;
  padding-right: ${(props) => props.paddingRight || "40px"};

  background-color: ${ COLORS.backgroundGrey };
  border: 1px solid ${ COLORS.greyBorder };
  box-sizing: border-box;
  border-radius: 4px;
  color: ${ COLORS.darkGrey1 };

  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => props.fontSize || "15px"};
  display: flex;
  align-items: center;
`;