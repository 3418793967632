import React from "react";
import Divider from "@material-ui/core/Divider";

import {
  ProjectCardName,
  ProjectCardRoot,
  ProjectCardPosition,
  ControlButtons,
  AddText,
  PlusIcon,
  AddProjectCard,
  StyleInfoContainer,
  StyledInfoText,
} from "./styles";

import plus from "../../../../assets/preQualifyIcons/plusIcon.svg";
import ConvertToDate from '../../../../common/convertToDate';
import ProjectCardImage from '../../../../assets/preQualifyIcons/projectCardImage';

function ProjectCard({
  data,
  variant = "default",
  onClick,
  setProjects,
  setOpenDialog,
  setEditIndex,
  populateData,
  index,
}) {
  if (variant === "default") {
    return (
      <ProjectCardRoot>
        <StyleInfoContainer>
          <ProjectCardImage style={{height: "40%"}} />
            <ProjectCardName>{data.name}</ProjectCardName>
            <StyledInfoText>
              <ProjectCardPosition>
                Completed Date: {ConvertToDate(data.completed)}
              </ProjectCardPosition>
              <ProjectCardPosition>Owner: {data.owner}</ProjectCardPosition>
              <ProjectCardPosition>Project Value: ${data.price}</ProjectCardPosition>
            </StyledInfoText>
        </StyleInfoContainer>
        <Divider></Divider>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            minHeight: 38,
            alignItems: "center",
          }}
        >
          <ControlButtons
            onClick={() => {
              setOpenDialog(true);
              setEditIndex(index);
              populateData(data);
            }}
          >
            Edit
          </ControlButtons>
          <ControlButtons
            onClick={() =>
              setProjects((prev) =>
                prev.filter(
                  (item) =>
                    !(
                      item.name === data.name &&
                      item.completed === data.completed &&
                      item.buyer === data.buyer &&
                      item.consultant === data.consultant &&
                      item.price === data.price
                    )
            ))}
          >
            Remove
          </ControlButtons>
        </div>
      </ProjectCardRoot>
    );
  }
  if (variant === "add") {
    return (
      <AddProjectCard
        onClick={onClick}
      >
        <PlusIcon src={plus}></PlusIcon>
        <AddText>Add a project</AddText>
      </AddProjectCard>
    );
  }
}

export default ProjectCard;
