import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/spinner";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import {
  MediumText,
  Content,
  StyledArea,
  StyledBox,
  PlaceholderText,
  StyledTooltipButton,
  ButtonPlacement,
} from "../styles";

import { ListContainer, SetupContainer, StyledTab } from "./styles";

import { useHistory } from "react-router-dom";
import { FunctionalButton } from "../../../components/functionalButton";
import { ArrowIcon } from "./asset/ArrowIcon";

import {
  serviceProviderBuyerListSelector,
  serviceProviderContractorsListSelector,
} from "data/selectors/serviceProvider";
import {
  getServiceProviderBuyersList,
  getServiceProviderContractorsList,
} from "data/actions/serviceProvider";
import { AllClientsTable } from "pages/serviceProviders/components/allClientsTable";

export const ClientSection = ({ isMobile, selectedTab, data, alertsData }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: false,
  });

  // const [data, setData] = useState([]);
  // const [selectTab, setSelectTab] = useState("1");
  // const handleChange = (event, newValue) => {
  //   setSelectTab(newValue);
  // };
  // const serviceProviderBuyerData = useSelector(
  //   serviceProviderBuyerListSelector
  // );
  // const serviceProviderContractorData = useSelector(
  //   serviceProviderContractorsListSelector
  // );

  // const actualServiceProviderBuyerData = serviceProviderBuyerData?.data;
  // const actualServiceProviderContratcorsData =
  //   serviceProviderContractorData?.data;
  // useEffect(() => {
  //   if (serviceProviderBuyerData?.length < 1) {
  //     dispatch(getServiceProviderBuyersList());
  //   }

  //   if (serviceProviderContractorData?.length < 1) {
  //     dispatch(getServiceProviderContractorsList());
  //   }
  // }, []);
  // useEffect(() => {
  //   if (selectTab === "1") {
  //     if (actualServiceProviderBuyerData?.length > 0) {
  //       setData(actualServiceProviderBuyerData);
  //     }
  //   }
  //   if (selectTab === "2") {
  //     if (actualServiceProviderContratcorsData?.length > 0) {
  //       setData(actualServiceProviderContratcorsData);
  //     }
  //   }
  // }, [
  //   actualServiceProviderBuyerData,
  //   actualServiceProviderContratcorsData,
  //   selectTab,
  // ]);

  // useEffect(() => {
  //   if (
  //     actualServiceProviderBuyerData?.length > 0 &&
  //     actualServiceProviderContratcorsData?.length > 0
  //   ) {
  //     setSelectTab("1");
  //   }
  //   if (!actualServiceProviderBuyerData?.length > 0) {
  //     setSelectTab("2");
  //   }
  //   if (!actualServiceProviderContratcorsData?.length > 0) {
  //     setSelectTab("1");
  //   }
  // }, [actualServiceProviderBuyerData, actualServiceProviderContratcorsData]);
  const handleSeeAll = () => {
    history.push("/service_provider/clients", {
      section: selectedTab,
    });
  };
  // const handleNew = () => {
  //   history.push("/rfpq");
  // };

  return (
    <Content isMobile={isMobile}>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <MediumText isMobile={isMobile} style={{ paddingBottom: "20px" }}>
          Recently Added Clients
        </MediumText>
        <div
          style={{
            color: "#FF6D1D",
            fontSize: 14,
            width: "20%",
            fontFamily: "SF Pro Text",
            fontWeight: "400",
            textDecoration: "underline",
            wordWrap: "break-word",
            textAlign: "right",
            paddingRight: "24px",
            cursor: "pointer",
          }}
          onClick={handleSeeAll}
        >
          See All Clients
        </div>
      </div>
      {/* <SetupContainer>
        <TabContext value={selectTab}>
          <TabList
            onChange={handleChange}
            variant="halfWidth"
            // centered
            TabIndicatorProps={{ hidden: true }}
            sx={{ "& .MuiTab-root": { minWidth: 200 } }}
          >
            {serviceProviderBuyerData?.data?.length > 0 &&
              serviceProviderBuyerData?.data?.length && (
                <StyledTab label="Buyer Clients" value="1" />
              )}
            {serviceProviderContractorData?.data?.length > 0 &&
              serviceProviderContractorData?.data?.length && (
                <StyledTab label="Contractor Clients" value="2" />
              )}
          </TabList> */}

      {/* <TabPanel value="1">
            <StyledBox
              hasData={data?.length > 0}
              noBackground={data?.length > 0 ? true : false}
              boxHeight="270px"
            >
              {!state.loading && data?.length > 0 && (
                <ListContainer>
                  <AllClientsTable
                    data={data?.slice(0, 3)}
                    setData={setData}
                    isMobile={isMobile}
                    selectTab="1"
                    borderRadius="20px"
                    boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;"
                  />
                </ListContainer>
              )}
              {!state.loading && !(data?.length > 0) && (
                <PlaceholderText>
                  Your clients list will appear here
                </PlaceholderText>
              )}
              {state.loading && (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner />
                  <span style={{ marginTop: "20px" }}>Loading Clients...</span>
                </div>
              )}
            </StyledBox>
          </TabPanel> */}

      {/* <TabPanel value="2"> */}
      <StyledBox
        hasData={data?.length > 0}
        noBackground={data?.length > 0 ? true : false}
        boxHeight="320px"
      >
        {!state.loading && data?.length > 0 && (
          <ListContainer>
            <AllClientsTable
              data={data?.slice(0, 4)}
              isMobile={isMobile}
              alertsData={alertsData}
              selectedTab={selectedTab}
              borderRadius="20px"
              boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;"
            />
          </ListContainer>
        )}
        {!state.loading && !(data?.length > 0) && (
          <PlaceholderText style={{ marginTop: "90px" }}>
            Your clients list will appear here
          </PlaceholderText>
        )}
        {state.loading && (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
            <span style={{ marginTop: "20px" }}>Loading Clients...</span>
          </div>
        )}
      </StyledBox>
      {/* </TabPanel> */}
      {/* </TabContext> */}
      {/* </SetupContainer> */}
      {/* <StyledBox
        hasData={data?.length > 0}
        noBackground={data?.length > 0 ? true : false}
        boxHeight="270px"
      >
        {!state.loading && data?.length > 0 && (
          <ListContainer>
            <AllClientsTable
              data={data?.slice(0, 3)}
              setData={setData}
              isMobile={isMobile}
              borderRadius="20px"
              boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;"
            />
          </ListContainer>
        )}
        {!state.loading && !(data?.length > 0) && (
          <PlaceholderText>Your clients will appear here</PlaceholderText>
        )}
        {state.loading && (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
            <span style={{ marginTop: "20px" }}>Loading Clients...</span>
          </div>
        )}
      </StyledBox> */}
      {/* <StyledArea
        marginTop={isMobile && data?.length > 0 ? "-38px" : "0px"}
        isMobile={isMobile}
      >
        <ButtonPlacement isMobile={isMobile}>
          {data?.length > 0 && (
            <FunctionalButton
              buttonTitle={isMobile ? "See All" : "See All Clients"}
              handleButton={handleSeeAll}
              width="500px"
              marginRight="0px"
              isReversedColor={true}
              icon={<ArrowIcon fill="#FF6D1D" />}
              hasBoxShadow={true}
            />
          )}
        </ButtonPlacement> */}
      {/* {userData?.status === "active" && (
          <StyledTooltipButton>
            <FunctionalButton
              buttonTitle={isMobile ? "Add New" : "Add a New Client"}
              // handleButton={
              //   userData?.status === "active" ? handleNew : () => {}
              // }
              width="500px"
              marginRight="13px"
              icon={<ArrowIcon fill="white" />}
              hasBoxShadow={true}
              disabled={true}
            />
          </StyledTooltipButton>
        )} */}
      {/* </StyledArea> */}
    </Content>
  );
};
