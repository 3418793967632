import { AutoFilledAddress } from "components/autoFilledAddress";
import { InfoArea, SectionText, StyledAddressContainer } from "./styles";
import Input from "../inputField";
import React, { useEffect } from "react";
import { emailRE, phoneRegex } from "../../common/regex";
import { formatPhoneNumber } from "../../common/formatPhoneNumber";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import "./styles.css";
import DateTimePickerField from "components/dateTimePickerField";

export const Step2 = ({ addData, setAddData, isMobile, setDisable }) => {
  useEffect(() => {
    if (addData?.contact_first_name?.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [addData]);

  const [phoneNumber, setPhoneNumber] = React.useState(addData?.contact_phone);
  const handlePhoneNumberChange = (value) => {
    setAddData("contact_phone", value);
  };

  return (
    <InfoArea isMobile={isMobile} marginTop="0px">
      <SectionText>Main Contact</SectionText>
      <Input
        id="inputWrap"
        label="First Name"
        type="text"
        placeholder="First Name"
        labelSize="12px"
        fontSize="18px"
        value={addData?.contact_first_name || ""}
        setValue={(val) => setAddData("contact_first_name", val)}
        validateFunc={(item) => item.length > 0}
        noCheckIcon={true}
        noErrorBorder={true}
      />
      <Input
        id="inputWrap last"
        label="Last Name"
        type="text"
        placeholder="Last Name"
        labelSize="12px"
        fontSize="18px"
        value={addData?.contact_last_name || ""}
        setValue={(val) => setAddData("contact_last_name", val)}
        validateFunc={(item) => item.length > 0}
        noCheckIcon={true}
        noErrorBorder={true}
      />

      <StyledAddressContainer>
        <div
          style={{
            marginTop: "24px",
            width: "100%",
            marginLeft: "-70px",
            marginRight: "55px",
          }}
        >
          <label
            htmlFor="phone"
            style={{ fontSize: "12px", marginLeft: "0px" }}
          >
            Phone Number
          </label>
          <div
            style={{
              height: "48px",
              width: "100%",
              marginTop: "-10px",
              marginLeft: "0px",
            }}
          >
            <PhoneInput
              id="phone"
              country={"ca"}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              inputStyle={{
                fontSize: "18px",
                padding: "8px",
                lineHeight: "24px",
                marginLeft: "20px",
                paddingLeft: "28px",
                height: "100%",
                width: "43%",
              }}
              dropdownStyle={{ fontSize: "18px", lineHeight: "24px" }}
              className="custom-phone-input"
            />
          </div>
        </div>

        <Input
          id="inputWrap"
          label="Email"
          type="text"
          placeholder="name@company.com"
          labelSize="12px"
          fontSize="18px"
          value={addData?.contact_email || ""}
          setValue={(val) => setAddData("contact_email", val)}
          validateFunc={(item) => emailRE.test(item)}
          noCheckIcon={true}
          noErrorBorder={true}
          style={{ marginLeft: "-330px", marginRight: "-90px", width: "53%" }}
        />

        {/* <Input
        id="inputWrap last"
        label="Phone number"
        type="text"
        placeholder="(xxx) xxx-xxxx"
        labelSize="12px"
        fontSize="18px"
        value={addData?.contact_phone || ""}
        setValue={(val) => setAddData("contact_phone", formatPhoneNumber(val))}
        validateFunc={(item) => phoneRegex.test(item)}
        noCheckIcon={true}
        noErrorBorder={true}
      /> */}
      </StyledAddressContainer>

      {addData?.type === "bank" && (
        <>
          {/* <Input
            id="inputWrap"
            label="With Bank Since"
            type="date"
            //placeholder="abcs"
            labelSize="12px"
            fontSize="18px"
            paddingRight="10px"
            value={addData?.with_bank_since ? addData?.with_bank_since : ""}
            setValue={(val) => {
              setAddData("with_bank_since", val);
            }}
            noCheckIcon={true}
            noErrorBorder={true}
          />
          <Input
            id="inputWrap last"
            label="Next Review Date"
            type="date"
            //placeholder="yyyy/mm/dd"
            labelSize="12px"
            fontSize="18px"
            paddingRight="10px"
            value={addData?.next_review_date ? addData?.next_review_date : ""}
            setValue={(val) => setAddData("next_review_date", val)}
            noCheckIcon={true}
            noErrorBorder={true}
          /> */}
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <DateTimePickerField
              showDateField={true}
              dateWidth="100%"
              paddingRight="16px"
              dateLabel="With Bank Since"
              dateValue={
                addData?.with_bank_since ? addData?.with_bank_since : ""
              }
              setDateValue={(val) => {
                setAddData("with_bank_since", val);
              }}
            />
            <DateTimePickerField
              showDateField={true}
              dateWidth="100%"
              dateLabel="Next Review Date"
              dateValue={
                addData?.next_review_date ? addData?.next_review_date : ""
              }
              setDateValue={(val) => {
                setAddData("next_review_date", val);
              }}
            />
          </div>
        </>
      )}
    </InfoArea>
  );
};
