import styled from 'styled-components';
import IconButton from "@material-ui/core/IconButton";

 import { COLORS, TYPOGRAPHY } from '../../../../../constants';

export const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 25px;
  margin-left: 0;
  margin-right: auto;
  font-style: normal;
  font-weight:  ${props => props.labelWeight || 'normal'};
  font-size: ${props => props.labelSize || '12px'};
  line-height: 20px;
  color: #000000;
`;

export const CloseButton = styled(IconButton)`
  &&{
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;


export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${props => props.marginTop || "0"};
  margin-bottom: ${props => props.marginBottom || "0"};
`;

export const FormTitle = styled.div`
  font-family: ${TYPOGRAPHY.primaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-top: 24px;
  // color: ${COLORS.lightgrey2};
  color: grey;
`;


export const StyledFileName = styled.div`
width: 80%;
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;
color: #b8b8b8;
margin-top: 1px;
margin-bottom: 5px;
background: #ffffff;
border: 1px solid #ebe9f1;
border-radius: 6px;
margin-left: 12px;
`;
export const InfoLabel = styled.div`
  margin-bottom: 5px;
  margin-left: 0;
  margin-right: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;

  line-height: 15px;
  color: #938d98;
  padding-right: 16px;
`;


export const StyledUploadIcon = styled.label`
  margin: ${props => props.margin || 0};
  cursor: pointer;
`;