import React, { useState, useEffect, useRef } from "react";

import {
  InputLabel,
  CloseButton,
  Row,
  FormTitle,
  StyledFileName,
  StyledUploadIcon,
  InfoArea,
  StyledPaper,
  InfoLabel,
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@mui/material/CircularProgress";

import CloseIcon from "@material-ui/icons/Close";
import Input from "../inputField";
import PledgxLogo from "../../assets/common/pledgxLogo";
import AddResumeButton from "../../assets/onBoarding/AddResumeButton";
import { emailRE } from "../../common/regex";
import { FunctionalButton } from "../functionalButton";
import { COLORS } from "../../constants";
import { deleteFile, fileUpload } from "api/file_managment";
import { AlertPopup } from "components/alertPopup/alertPopup";
import DocumentPoolPopUp from "components/documentPoolPopUp";

export const AddPersonnelPopup = ({
  setOpenDialog,
  openDialog,
  data,
  setData,
  handleButtonFunc,
  isMobile,
  notShowResume,
  label,
  buttonLabel,
  noEmailEdit,
  fileLimitCount,
  fileLimitSizeMB,
  institution_id,
}) => {
  const [editIndex, setEditIndex] = useState(null);

  const [dialogFN, setDialogFN] = useState("");
  const [dialogLN, setDialogLN] = useState("");
  const [dialogEmail, setDialogEmail] = useState("");
  const [dialogPos, setDialogPos] = useState("");
  const [dialogRes, setDialogRes] = useState("");
  const [validSubmit, setValidSubmit] = useState(false);
  const [fileId, setFileId] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);
  const [dialogLinkedIn, setDialogLinkedIn] = useState("");
  const [openDocumentPool, setOpenDocumentPool] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const fileInput = useRef();

  useEffect(() => {
    setDialogFN(data?.firstName);
    setDialogLN(data?.lastName);
    setDialogEmail(data?.email);
    setDialogPos(data?.position);
    setDialogRes(data?.resume);
    setDialogLinkedIn(data?.linkedInProfile);
    setFileId(data?.resume?.id);
  }, [data]);

  useEffect(() => {
    if (
      dialogFN?.length > 0 &&
      dialogLN?.length > 0 &&
      emailRE.test(dialogEmail)
    ) {
      setValidSubmit(false);
    } else {
      setValidSubmit(true);
    }
  }, [dialogFN, dialogLN, dialogEmail]);

  const handleAddPersonnel = () => {
    let updatedPersonnelData = [];

    if (data?.personnel) {
      updatedPersonnelData = [...data?.personnel];
    }

    updatedPersonnelData.unshift({
      id: data?.id,
      firstName: dialogFN,
      lastName: dialogLN,
      email: dialogEmail,
      position: dialogPos,
      linkedInProfile: dialogLinkedIn,
      // resume: dialogRes,
      resume_cloudfile: fileId,
    });

    if (setData) {
      setData(updatedPersonnelData);
    }
    if (handleButtonFunc) {
      let formData = new FormData();
      if (dialogRes) {
        formData.append("resume", dialogRes);
        //console.log(dialogRes);
      }
      if (notShowResume) {
        formData = {
          id: data?.id,
          firstName: dialogFN,
          lastName: dialogLN,
          email: dialogEmail,
          position: dialogPos,
        };
      } else {
        formData.append(
          "data",
          JSON.stringify({
            id: data?.id,
            firstName: dialogFN,
            lastName: dialogLN,
            email: dialogEmail,
            position: dialogPos,
            linkedInProfile: dialogLinkedIn,
            // resume: dialogRes,
            resume_cloudfile: fileId,
          })
        );
      }

      handleButtonFunc(formData);
    }
  };

  const handleEditPersonnel = () => {
    let updatedPersonnelData = [];

    if (data?.personnel) {
      updatedPersonnelData = [...data?.personnel];
    }

    updatedPersonnelData[editIndex] = {
      id: updatedPersonnelData[editIndex].id,
      firstName: dialogFN,
      lastName: dialogLN,
      email: dialogEmail,
      position: dialogPos,
      linkedInProfile: dialogLinkedIn,
      // resume: dialogRes,
      resume_cloudfile: fileId,
    };
    if (setData) {
      setData(updatedPersonnelData);
    }
    if (handleButtonFunc) {
      handleButtonFunc({
        id: updatedPersonnelData[editIndex].id,
        firstName: dialogFN,
        lastName: dialogLN,
        email: dialogEmail,
        position: dialogPos,
        linkedInProfile: dialogLinkedIn,
        // resume: dialogRes,
        resume_cloudfile: fileId,
      });
    }
  };

  const handleFileSelect = () => {
    fileInput.current.click();
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDialogFN("");
    setDialogLN("");
    setDialogEmail("");
    setDialogPos("");
    setDialogRes(null);
    setEditIndex(null);
    setFileId(null);
    setDialogLinkedIn("");
  };

  const handleDeleteCloudFile = () => {
    setDialogRes(null);
    setFileId(null);
  };

  const handleUploadFile = () => {
    setDialogRes(selectedFiles);
    setFileId(selectedFiles?.file_id);
    setOpenDocumentPool(false);
    // setOpenProgress(true);
    // if (file) {
    //   const returnMessage = await fileUpload(file);
    //   if (returnMessage.error) {
    //     setOpenPopup(true);
    //     setDialogRes(null);
    //   } else {
    //     setFileId(returnMessage.file_id);
    //     setOpenProgress(false);
    //   }
    // }
  };

  return (
    <>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <StyledPaper elevation={0} isMobile={isMobile}>
          <CloseButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
          <FormTitle style={{ marginTop: 10 }}>
            {label || "Add Your Personnel"}
          </FormTitle>
          <InfoArea isMobile={isMobile} marginTop="0px">
            <Input
              id="inputWrap"
              label="First Name"
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={dialogFN}
              setValue={setDialogFN}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noErrorBorder={true}
            />
            <Input
              id="inputWrap"
              label="Last Name"
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={dialogLN}
              setValue={setDialogLN}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noErrorBorder={true}
            />
            <Input
              id="fullWidthInputWrap"
              label="Email*"
              type="text"
              disabled={noEmailEdit}
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={dialogEmail}
              setValue={setDialogEmail}
              validateFunc={(item) => emailRE.test(item)}
              noCheckIcon={true}
              noErrorBorder={true}
            />
            <Input
              id="fullWidthInputWrap"
              label="Position"
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={dialogPos}
              setValue={setDialogPos}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noCancelIcon={true}
              noErrorBorder={true}
            />
            {!notShowResume && (
              <Input
                id="fullWidthInputWrap"
                label="LinkedIn Profile"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={dialogLinkedIn}
                setValue={setDialogLinkedIn}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noCancelIcon={true}
                noErrorBorder={true}
              />
            )}
          </InfoArea>
          {!notShowResume && (
            <>
              <InputLabel>Resume (Optional)</InputLabel>
              <div
                style={{ width: "100%", display: "flex", paddingRight: "16px" }}
              >
                <StyledUploadIcon onClick={() => setOpenDocumentPool(true)}>
                  {/* <input
                  ref={fileInput}
                  id="resume-selector"
                  onClick={(e) => {
                    e.target.value = null;
                    handleFileSelect();
                  }}
                  onChange={(e) => {
                    setDialogRes(e.target.files[0]);
                    handleUploadFile(e.target.files[0]);
                  }}
                  type="file"
                  style={{ display: "none" }}
                /> */}
                  <AddResumeButton />
                </StyledUploadIcon>
                {dialogRes && (
                  <StyledFileName>
                    <span>{dialogRes.name}</span>
                    {openProgress && (
                      <CircularProgress
                        style={{
                          height: "20px",
                          width: "20px",
                          color: COLORS.primaryOrange,
                        }}
                        disableShrink
                      />
                    )}
                    {!openProgress && (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleDeleteCloudFile();
                        }}
                      >
                        <CloseIcon />
                      </span>
                    )}
                  </StyledFileName>
                )}
              </div>
            </>
          )}
          <AlertPopup
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            headerText={"You have exceeded the file limit."}
            subHeaderText={`You can upload a maximum of ${fileLimitCount} documents or ${fileLimitSizeMB}MB.`}
          />
          <FunctionalButton
            disabled={validSubmit}
            width="100%"
            marginTop="16px"
            marginRight="0px"
            handleButton={() => {
              if (editIndex !== null) {
                handleEditPersonnel();
                setEditIndex(null);
              } else {
                handleAddPersonnel();
              }
              setOpenDialog(false);
              setDialogFN("");
              setDialogLN("");
              setDialogEmail("");
              setDialogPos("");
              setDialogLinkedIn("");
              setDialogRes(null);
              setFileId(null);
            }}
            buttonTitle={buttonLabel || "SAVE"}
          />
        </StyledPaper>
      </Dialog>
      <DocumentPoolPopUp
        openPopup={openDocumentPool}
        setOpenPopup={setOpenDocumentPool}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        attachFile={handleUploadFile}
        resumeFile={true}
        institutionId={institution_id}
      />
    </>
  );
};
