import styled from "styled-components";

export const Container = styled.div`
  padding: 64px ${(props) => (props.isMobile ? "0px" : "84px")} 20px ${(props) => (props.isMobile ? "0px" : "84px")};
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  height: 100vh;
`;
