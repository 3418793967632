import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  addRequirement,
  clearInstitutionRfpq,
  createRfpq,
  getContractorsList,
  getInstitutionRfpq,
  getRequirements,
  inviteContractors,
  publishRfpq,
  reviewRfpq,
  saveRfpq,
} from "../../data/actions/buyers";
import {
  contractorsSelector,
  institutionRfpqSelector,
  requirementsSelector,
} from "../../data/selectors/buyers";
import classes from "./style/Rfpq.module.css";
import { DASHBOARDHEADER_HEIGHT } from "../../constants";
import { FormBuilder } from "../../components/formBuilder";
import countries from "../../assets/locale/countries.json";
import formDetails from "./forms/formDetails.json";
import formFields from "./forms/formFields.json";
import formSetup from "./forms/formSetup.json";
import icons from "./style/icons.js";
import mapFields from "./mapFields";
import states from "../../assets/locale/states.json";

function Rfpq(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const currentDashboardUrl = localStorage.getItem("current_dashboard_url");
  const institution_id = location.state?.institution_id || "";
  const [data, setData] = useState({});
  const [formDetailsState, setFormDetailsState] = useState(formDetails);
  const formDetailsStateRef = useRef(formDetails);
  formDetailsStateRef.current = formDetailsState;
  const [formFieldsState, setFormFieldsState] = useState(formFields);
  const [formSetupState, setFormSetupState] = useState(formSetup);
  const formSetupStateRef = useRef(formSetup);
  formSetupStateRef.current = formSetupState;
  const [containsUNSPC, setContainsUNSPC] = useState();

  const [formState, setFormState] = useState({
    alert: null,
    alertReturnsToDashboard: false,
    formItemStates: {},
    goToLocation: null,
    interactionLoading: false,
    loading: true,
    navigationType: "BUBBLES",
    popup: { height: "500px", showLogo: true, visible: false, width: "470px" },
    successPopup: { visible: false },
  });
  const formStateRef = useRef({
    formItemStates: {},
    interactionLoading: false,
  });
  formStateRef.current = formState;

  const [supplementaryData, setSupplementaryData] = useState({
    countries: {
      data: Object.keys(countries).map((value) => ({
        value,
        label: countries[value].countryName,
      })),
    },
    states: {
      data: [],
      country: null,
    },
  });

  const currentRfpqId = useRef();
  const goToLocation = useRef();
  const rfpqUpdated = useRef(false);
  const selectRequirement = useRef(false);
  const currentRfpq = useSelector(institutionRfpqSelector);
  const contractors = useSelector(contractorsSelector);
  const retrievedRequirements = useSelector(requirementsSelector);
  const allSubCategories = [];

  if (Array.isArray(data?.projectCategory)) {
    data?.projectCategory.forEach((category) => {
      const subCategoryInfo = (category.sub_categories || []).map(
        (option, index) => ({
          key: index + 1,
          text: option,
          value: option,
        })
      );

      const categoryList = {
        main_categories: category.main_categories || [],
        sub_categories: subCategoryInfo,
      };

      allSubCategories.push(categoryList);
    });
  }

  const categoriesUNSPCArray = [
    "Building construction and support and maintenance and repair services",
    "Concrete and cement and plaster",
    "General building construction",
    "Heavy construction services",
    "Mining services",
    "Nonresidential building construction services",
    "Oil and gas restoration and reclamation services",
    "Permanent structures",
    "Portable Structures",
    "Prefabricated structures",
    "Roads and landscape",
    "Specialized trade construction and maintenance services",
    "Structural building products",
    "Utilities",
    "Water and wastewater treatment supply and disposal",
    "Well drilling and construction services",
  ];
  if (containsUNSPC === undefined) {
    if (allSubCategories.length > 0) {
      if (categoriesUNSPCArray.includes(allSubCategories[0]?.main_categories)) {
        setContainsUNSPC(true);
        data.radioProjectStandard = false;
        data.radioProjectUNSPC = true;
      } else {
        setContainsUNSPC(false);
        data.radioProjectUNSPC = false;
        data.radioProjectStandard = true;
      }
    }
  }

  useEffect(() => {
    setData({});
    rfpqUpdated.current = false;
    var createNew = true;
    if (props?.location?.search) {
      var queries = props.location.search.split("?")[1].split("&");
      var terms = {};
      if (queries) {
        queries.map((query) => {
          terms[query.split("=")[0]] = query.split("=")[1];
        });
      }
      if (terms.section) {
        if (terms.section === "review") {
          goToLocation.current = [5];
        }
      }
      if (terms.id) {
        createNew = false;
        currentRfpqId.current = parseInt(terms.id);
        dispatch(
          getInstitutionRfpq(
            { rfpq_id: parseInt(terms.id) },
            (e) => {
              // RFPQ RETRIEVED. DON'T CHANGE LOADING STATUS, UNTIL IT IS AVAILABLE IN THE SELECTOR!
            },
            (e) => {
              setFormState({
                ...formStateRef.current,
                alert: {
                  visible: true,
                  headerText: "RFP/Q Not Found!",
                  subHeaderText:
                    "This RFP/Q is no longer accessible. Please try a different RFP/Q",
                },
                alertReturnsToDashboard: true,
                loading: false,
              });
              // TODO: DISPLAY ERROR!
            }
          )
        );
      }
    }
    if (createNew) {
      let data = null;
      if (institution_id) {
        data = { institution_id: institution_id };
      }
      setFormState({
        ...formStateRef.current,
        loading: true,
      });
      dispatch(
        createRfpq(
          data,
          (e) => {
            // SET URL HERE!
            currentRfpqId.current = e.rfpq.id;
            history.push(`/rfpq?id=${e.rfpq.id}`);
          },
          (e) => {
            setFormState({
              ...formStateRef.current,
              loading: false,
            });
            // TODO: DISPLAY ERROR!
          }
        )
      );
    }

    return () => {
      dispatch(clearInstitutionRfpq());
    };
  }, []);

  useEffect(() => {
    if (currentRfpq?.id) {
      if (!rfpqUpdated.current) {
        var mappedRfpq = mapFields({ serverRfpq: currentRfpq });

        setData(mappedRfpq);
        rfpqUpdated.current = true;
      }
      if (currentRfpq.status) {
        updateForm(currentRfpq.status);
      }
    }
  }, [currentRfpq, props.location]);

  useEffect(() => {
    if (selectRequirement.current) {
      var newSelectedRequirements =
        data.projectRequirements?.selectedData || [];
      newSelectedRequirements.unshift(
        retrievedRequirements[retrievedRequirements.length - 1]
      );
      setData({
        ...data,
        projectRequirements: {
          ...data.projectRequirements,
          selectedData: newSelectedRequirements,
        },
      });
      selectRequirement.current = false;
    }
  }, [retrievedRequirements]);

  function updateForm(currentStatus) {
    var newFormState = {
      ...formStateRef.current,
      loading: false,
      goToLocation:
        currentStatus && !["draft", "reviewed"].includes(currentStatus)
          ? [5]
          : goToLocation.current,
      navigationType:
        currentStatus && !["draft", "reviewed"].includes(currentStatus)
          ? null
          : goToLocation.current && goToLocation.current[0] === 5
          ? null
          : "BUBBLES",
    };
    if (currentStatus === "draft") {
      var newFormSetup = [...formSetupStateRef.current];
      newFormSetup[5] = {
        ...formSetupStateRef.current[5],
        navigation: {
          ...formSetupStateRef.current[5].navigation,
          navigationMessage:
            "Click the review button above to certify that you have reviewed the RFP/Q, and enable publishing or inviting contractors",
          edit: {
            ...formSetupStateRef.current[5].navigation.edit,
            disabled: false,
          },
          publishButton: {
            ...formSetupStateRef.current[5].navigation.publishButton,
            disabled: true,
          },
          inviteButton: {
            ...formSetupStateRef.current[5].navigation.inviteButton,
            disabled: true,
          },
          setup: {
            left: ["edit"],
            right: ["publishButton", "inviteButton"],
            center: ["reviewButton"],
          },
        },
      };
      formSetupStateRef.current = newFormSetup;
      formStateRef.current = newFormState;
      setFormSetupState(newFormSetup);
      setFormState(newFormState);
    } else if (currentStatus === "reviewed") {
      var newFormSetup = [...formSetupStateRef.current];
      newFormSetup[5] = {
        ...formSetupStateRef.current[5],
        navigation: {
          ...formSetupStateRef.current[5].navigation,
          navigationMessage:
            "Click 'Publish' to publish a public RFP/Q, or click 'Invite' to invite contractors to a private RFP/Q",
          edit: {
            ...formSetupStateRef.current[5].navigation.edit,
            disabled: false,
          },
          publishButton: {
            ...formSetupStateRef.current[5].navigation.publishButton,
            disabled: false,
          },
          inviteButton: {
            ...formSetupStateRef.current[5].navigation.inviteButton,
            disabled: false,
          },
          reviewButton: {
            ...formSetupStateRef.current[5].navigation.reviewButton,
            disabled: true,
          },

          setup: {
            left: ["edit"],
            right: ["publishButton", "inviteButton"],
            center: ["reviewButton"],
          },
        },
      };
      formSetupStateRef.current = newFormSetup;
      formStateRef.current = { ...newFormState, interactionLoading: false };
      setFormSetupState(newFormSetup);
      setFormState({
        ...newFormState,
        interactionLoading: false,
      });
    } else {
      var newFormSetup = [...formSetupStateRef.current];
      newFormSetup[5] = {
        ...formSetupStateRef.current[5],
        navigation: {
          ...formSetupStateRef.current[5].navigation,
          navigationMessage: null,
          setup: {
            center: ["backToDashboard"],
          },
        },
      };
      formSetupStateRef.current = newFormSetup;
      formStateRef.current = newFormState;
      setFormSetupState(newFormSetup);
      setFormState(newFormState);
    }
  }

  function handleAlert(e) {
    if (e === false && formStateRef.current.alertReturnsToDashboard) {
      history.push("/dashboard");
    }
  }

  function handleButtonClick({ action, id }) {
    switch (id) {
      case "backToDashboard":
        history.push("/buyers/projects");
        break;
      case "completeInviteButton":
        // THIS WILL ALSO LEAD TO THE RFPQ BEING REVIEWED
        // MAKE SURE RFPQ STATUS IS REVIEWED FIRST!
        // if (
        //   data?.inviteContractors?.selectedData &&
        //   data.inviteContractors.selectedData.length > 0
        // ) {
        //   setFormState({ ...formStateRef.current, interactionLoading: true });
        //   setFormDetailsState({
        //     ...formDetailsStateRef.current,
        //     interactionLoadingMessage: "Inviting contractors...",
        //   });
        //   dispatch(
        //     inviteContractors(
        //       {
        //         rfpq_id: currentRfpq.id,
        //         contractors: data.inviteContractors.selectedData,
        //       },
        //       (e) => {
        //         setFormState({
        //           ...formStateRef.current,
        //           interactionLoading: false,
        //         });
        //         setFormDetailsState({
        //           ...formDetailsStateRef.current,
        //           interactionLoadingMessage: null,
        //         });
        //         history.push("/dashboard");
        //       },
        //       (e) => {
        //         setFormState({
        //           ...formStateRef.current,
        //           interactionLoading: false,
        //         });
        //         setFormDetailsState({
        //           ...formDetailsStateRef.current,
        //           interactionLoadingMessage: null,
        //         });
        //         // TODO: DISPLAY ERROR!
        //       }
        //     )
        //   );
        // } else {
        //   // TODO: DISPLAY ERROR!
        // }

        setFormState({
          ...formStateRef.current,
          interactionLoading: false,
          popup: {
            ...formStateRef.current.popup,
            content: "Please confirm that you want to proceed to Invite",
            height: "338px",
            id: "invitePopUp",
            interaction: {
              cancelButton: { title: "GO BACK", width: "175px" },
              confirmButton: { title: "CONFIRM", width: "175px" },
            },
            visible: true,
            width: "470px",
          },
        });
        break;
      // break;
      case "inviteButton":
        if (!currentRfpq.status === "reviewed") {
          // DISPLAY REVIEW POPUP!
          setFormState({ ...formStateRef.current, interactionLoading: true });
          setFormDetailsState({
            ...formDetailsStateRef.current,
            interactionLoadingMessage:
              "Your private RFP/Q is being created. You'll be able to invite contractors shortly...",
          });
          // THIS WILL CHANGE TO REVIEW!
          /*
          dispatch(createRfpq(data, (e) => {
            setFormState({ ...formStateRef.current, interactionLoading: false });
            setFormDetailsState({
              ...formDetailsStateRef.current,
              interactionLoadingMessage: null
            });
            setFormState({ ...formStateRef.current, goToLocation: [6] });
          }, (e) => {
            setFormState({ ...formStateRef.current, interactionLoading: false });
            setFormDetailsState({
              ...formDetailsStateRef.current,
              interactionLoadingMessage: null
            });
            // TODO: DISPLAY ERROR!
          }));
          */
        } else {
          setFormState({ ...formStateRef.current, goToLocation: [5] });
          setFormState({ ...formStateRef.current, goToLocation: [6] });
        }
        break;
      case "publishButton":
        setFormState({
          ...formStateRef.current,
          interactionLoading: false,
          popup: {
            ...formStateRef.current.popup,
            content: "Please confirm that you want to proceed to Publish",
            height: "338px",
            id: "publishPopup",
            interaction: {
              cancelButton: { title: "GO BACK", width: "175px" },
              confirmButton: { title: "CONFIRM", width: "175px" },
            },
            visible: true,
            width: "470px",
          },
        });
        break;
      case "reviewButton":
        setFormState({
          ...formStateRef.current,
          interactionLoading: false,
          popup: {
            ...formStateRef.current.popup,
            content: "Please confirm that you want to proceed to Review",
            height: "338px",
            id: "reviewPopup",
            interaction: {
              cancelButton: { title: "GO BACK", width: "175px" },
              confirmButton: { title: "CONFIRM", width: "175px" },
            },
            visible: true,
            width: "470px",
          },
        });
        break;
      case "publishPopup":
        if (action === "CONFIRM_POPUP") {
          setFormState({
            ...formStateRef.current,
            interactionLoading: true,
            popup: {
              ...formStateRef.current,
              visible: false,
            },
          });
          setFormDetailsState({
            ...formDetailsStateRef.current,
            interactionLoadingMessage:
              "Your public RFP/Q is being published. Please wait...",
          });
          dispatch(
            publishRfpq(
              { rfpq_id: currentRfpq.id },
              (e) => {
                setFormState({
                  ...formStateRef.current,
                  interactionLoading: false,
                  popup: {
                    ...formStateRef.current.popup,
                    visible: false,
                  },
                  successPopup: {
                    ...formStateRef.current.successPopup,
                    visible: true,
                    content: "Your Request for Proposal is now published!",
                  },
                });
              },
              (e) => {
                setFormState({
                  ...formStateRef.current,
                  interactionLoading: false,
                });
                setFormDetailsState({
                  ...formDetailsStateRef.current,
                  interactionLoadingMessage: null,
                });
                // TODO: DISPLAY ERROR!
              }
            )
          );
        }
        break;
      case "invitePopUp":
        if (action === "CONFIRM_POPUP") {
          if (
            data?.inviteContractors?.selectedData &&
            data.inviteContractors.selectedData.length > 0
          ) {
            setFormState({
              ...formStateRef.current,
              interactionLoading: true,
              popup: {
                ...formStateRef.current,
                visible: false,
              },
            });

            setFormDetailsState({
              ...formDetailsStateRef.current,
              interactionLoadingMessage: "Inviting contractors...",
            });
            dispatch(
              inviteContractors(
                {
                  rfpq_id: currentRfpq.id,
                  contractors: data.inviteContractors.selectedData,
                },
                (e) => {
                  setFormState({
                    ...formStateRef.current,
                    interactionLoading: false,
                    popup: {
                      ...formStateRef.current.popup,
                      visible: false,
                    },
                    successPopup: {
                      ...formStateRef.current.successPopup,
                      visible: true,
                      content: "Invitations Have Been Sent",
                    },
                  });
                  setFormDetailsState({
                    ...formDetailsStateRef.current,
                    interactionLoadingMessage: null,
                  });
                  // history.push("/dashboard");
                },
                (e) => {
                  setFormState({
                    ...formStateRef.current,
                    interactionLoading: false,
                  });
                  setFormDetailsState({
                    ...formDetailsStateRef.current,
                    interactionLoadingMessage: null,
                  });
                  // TODO: DISPLAY ERROR!
                }
              )
            );
          } else {
            // TODO: DISPLAY ERROR!
          }
        }
        break;
      case "reviewPopup":
        switch (action) {
          case "CONFIRM_POPUP":
            setFormState({
              ...formStateRef.current,
              interactionLoading: true,
              popup: {
                ...formStateRef.current.popup,
                visible: false,
              },
            });
            dispatch(
              reviewRfpq(
                { rfpq_id: currentRfpq.id },
                () => {
                  //updateForm('reviewed'); This is taken care of by useEffect
                },
                (e) => {
                  setFormState({
                    ...formStateRef.current,
                    interactionLoading: false,
                  });
                  setFormDetailsState({
                    ...formDetailsStateRef.current,
                    interactionLoadingMessage: null,
                  });
                  // TODO: DISPLAY ERROR!
                }
              )
            );
            break;
          default:
            break;
        }
        break;
      case "successPopup":
        if (action === "CONFIRM_POPUP") {
          if (currentDashboardUrl === "/service-provider-dashboard") {
            history.push("/service_provider/projects", {
              institution_id: currentRfpq?.institution?.institution_id,
            });
          } else {
            history.push("/buyer-dashboard");
          }
        }
        break;
      default:
        break;
    }
  }

  function handleDataChange({ action, payload }) {
    switch (action) {
      case "CHANGE_DROPDOWN_VALUE":
        if (payload.id === "projectCountry") {
          if (
            states[payload.value] &&
            (formFieldsState.projectState.fieldType !== "dropdown" ||
              supplementaryData.states?.country !== payload.value)
          ) {
            setFormFieldsState({
              ...formFieldsState,
              projectState: {
                fieldType: "dropdown",
                label: states[payload.value].state_title,
                placeholder: "Ontario",
                required: true,
                supplementaryData: "states",
              },
            });
            setSupplementaryData({
              ...supplementaryData,
              states: {
                data: states[payload.value].states.map((state) => ({
                  value: state.abbreviation,
                  label: state.name,
                })),
              },
            });
          } else if (formFieldsState.projectState.fieldType !== "input") {
            setFormFieldsState({
              ...formFieldsState,
              projectState: {
                fieldType: "input",
                fieldSubType: "text",
                label: "Province/State/Region",
                placeholder: "Ontario",
                required: true,
              },
            });
            setSupplementaryData({
              ...supplementaryData,
              states: { data: [] },
            });
          }
          if (
            data.projectState?.selectedData &&
            data.projectState.selectedData !== payload.value
          ) {
            setData({
              ...data,
              [payload.id]: { selectedData: payload.value },
              projectState: null,
            });
          } else {
            setData({ ...data, [payload.id]: { selectedData: payload.value } });
          }
        } else {
          setData({ ...data, [payload.id]: { selectedData: payload.value } });
        }
        break;
      case "CHANGE_FILES":
        setData({ ...data, [payload.id]: { selectedFiles: payload.value } });
        break;
      case "CHANGE_VALUE":
        const projectCategoryExclusiveRadios = [
          "radioProjectStandard",
          "radioProjectUNSPC",
        ];
        if (projectCategoryExclusiveRadios.includes(payload.id)) {
          var newRadios = {};
          projectCategoryExclusiveRadios.map(
            (radio) => (newRadios[radio] = radio === payload.id ? true : false)
          );
          setData({ ...data, ...newRadios });
        } else {
          setData({ ...data, [payload.id]: payload.value });
        }
        break;
      case "TABLE_CHANGE_VALUE":
        if (payload?.id) {
          setData({
            ...data,
            [payload.id]: data[payload.id]
              ? { ...data[payload.id], data: payload.value }
              : { data: payload.value },
          });
        }
        break;
      case "TABLE_SELECTED_CHANGE_VALUE":
        setData({
          ...data,
          [payload.id]: data[payload.id]
            ? { ...data[payload.id], selectedData: payload.value }
            : { selectedData: payload.value },
        });
        if (payload.id === "inviteContractors") {
          if (
            payload.value &&
            payload.value.length > 0 &&
            formSetupStateRef.current?.[6]?.navigation?.completeInviteButton
              ?.disabled
          ) {
            var newFormSetupState = [...formSetupStateRef.current];
            newFormSetupState[6].navigation.completeInviteButton.disabled = false;
            newFormSetupState[6].navigation.navigationMessage = null;
            formSetupStateRef.current = newFormSetupState;
            setFormSetupState(newFormSetupState);
          } else if (
            (!payload.value || payload.value.length < 1) &&
            !formSetupStateRef.current?.[6]?.navigation?.completeInviteButton
              ?.disabled
          ) {
            var newFormSetupState = [...formSetupStateRef.current];
            newFormSetupState[6].navigation.completeInviteButton.disabled = true;
            newFormSetupState[6].navigation.navigationMessage =
              "Select at least one contractor to publish a private RFP/Q, or click 'Publish' from the Review page to publish a public RFP/Q";
            formSetupStateRef.current = newFormSetupState;
            setFormSetupState(newFormSetupState);
          }
        }
        break;
      default:
        break;
    }
  }

  function handleNavigation({ currentLocation, dataChanged }) {
    if (currentLocation?.[0] === 3) {
      if (!retrievedRequirements || retrievedRequirements.length < 1) {
        setFormState({ ...formStateRef.current, interactionLoading: true });
        setFormDetailsState({
          ...formDetailsStateRef.current,
          interactionLoadingMessage: "Loading requirements...",
        });
        dispatch(
          getRequirements(
            { institution_id: currentRfpq?.institution?.institution_id },
            () => {
              setFormState({
                ...formStateRef.current,
                interactionLoading: false,
              });
              setFormDetailsState({
                ...formDetailsStateRef.current,
                interactionLoadingMessage: null,
              });
            },
            (e) => {
              setFormState({
                ...formStateRef.current,
                interactionLoading: false,
              });
              setFormDetailsState({
                ...formDetailsStateRef.current,
                interactionLoadingMessage: null,
                //navigationMessage: 'An error occurred while loading requirements. We apologize for the inconvenience.'
              });
            }
          )
        );
      }
    } else if (currentLocation?.[0] === 6) {
      if (!contractors || contractors.length < 1) {
        setFormState({ ...formStateRef.current, interactionLoading: true });
        setFormDetailsState({
          ...formDetailsStateRef.current,
          interactionLoadingMessage: "Loading contractors...",
        });
        dispatch(
          getContractorsList(
            () => {
              setFormState({
                ...formStateRef.current,
                interactionLoading: false,
              });
              setFormDetailsState({
                ...formDetailsStateRef.current,
                interactionLoadingMessage: null,
              });
            },
            (e) => {
              setFormState({
                ...formStateRef.current,
                interactionLoading: false,
              });
              setFormDetailsState({
                ...formDetailsStateRef.current,
                interactionLoadingMessage: null,
                //navigationMessage: 'An error occurred while loading requirements. We apologize for the inconvenience.'
              });
            }
          )
        );
      }
    }
    if (
      goToLocation.current &&
      goToLocation.current[0] === 5 &&
      (currentLocation?.[0] === 0 || currentLocation?.[0] === 6)
    ) {
      setFormState({
        ...formStateRef.current,
        navigationType: "BUBBLES",
      });
    }
    if (dataChanged) {
      if (
        currentRfpq.id &&
        ["draft", "reviewed"].includes(currentRfpq.status)
      ) {
        dispatch(
          saveRfpq({ ...data, rfpq_id: currentRfpq.id }, null, (e) =>
            console.log("HANDLE ERROR!")
          )
        );
      }
    }
  }

  function sendItemToApi({ id, value }) {
    switch (id) {
      case "projectRequirements":
        setFormState({
          ...formStateRef.current,
          formItemStates: {
            ...(formStateRef.current.formItemStates || {}),
            projectRequirements: {
              ...(formStateRef.current.formItemStates?.projectRequirements ||
                {}),
              popupForm: {
                ...(formStateRef.current.formItemStates?.projectRequirements
                  ?.popupForm || {}),
                loading: true,
              },
            },
          },
        });
        if (currentRfpq?.institution?.institution_id) {
          value = {
            institution_id: currentRfpq?.institution?.institution_id,
            ...value,
          };
          console.log("in api", value);
        }
        dispatch(
          addRequirement(
            value,
            () => {
              selectRequirement.current = true;
              dispatch(
                getRequirements(
                  { institution_id: currentRfpq?.institution?.institution_id },
                  () => {
                    setFormState({
                      ...formStateRef.current,
                      formItemStates: {
                        ...(formStateRef.current.formItemStates || {}),
                        projectRequirements: {
                          ...(formStateRef.current.formItemStates
                            ?.projectRequirements || {}),
                          popupForm: {
                            ...(formStateRef.current.formItemStates
                              ?.projectRequirements?.popupForm || {}),
                            loading: false,
                          },
                        },
                      },
                    });
                  },
                  (e) => {
                    setFormState({
                      ...formStateRef.current,
                      formItemStates: {
                        ...(formStateRef.current.formItemStates || {}),
                        projectRequirements: {
                          ...(formStateRef.current.formItemStates
                            ?.projectRequirements || {}),
                          popupForm: {
                            ...(formStateRef.current.formItemStates
                              ?.projectRequirements?.popupForm || {}),
                            loading: false,
                          },
                        },
                      },
                    });
                  }
                )
              );
            },
            (e) => {
              // Display error!
              setFormState({
                ...formStateRef.current,
                formItemStates: {
                  ...(formStateRef.current.formItemStates || {}),
                  projectRequirements: {
                    ...(formStateRef.current.formItemStates
                      ?.projectRequirements || {}),
                    popupForm: {
                      ...(formStateRef.current.formItemStates
                        ?.projectRequirements?.popupForm || {}),
                      loading: false,
                    },
                  },
                },
              });
            }
          )
        );
        break;
      default:
        break;
    }
  }

  return (
    <div
      className={classes.container}
      style={{ padding: `${DASHBOARDHEADER_HEIGHT}px 70px 0 70px` }}
    >
      <div className={classes["form-container"]}>
        <FormBuilder
          alert={formStateRef.current.alert}
          changeData={(e) =>
            handleDataChange({ action: e.action, payload: e.payload })
          }
          changeItemState={formStateRef.current.formItemStates}
          data={data}
          formDetails={formDetailsStateRef.current}
          formFields={formFieldsState}
          formSetup={formSetupStateRef.current}
          goToLocation={formStateRef.current.goToLocation}
          icons={icons}
          id="rfpqFormBuilder"
          interactionLoading={formStateRef.current.interactionLoading}
          legacyNavigationUi={true}
          loading={formStateRef.current.loading}
          navigationType={formStateRef.current.navigationType}
          onAlertStateChanged={(e) => handleAlert(e)}
          //onDataChange={ (e) => handleDataChange(e) }
          //onInteractionItemClicked={ (e) => console.log(e) }
          onNavigate={(e) => handleNavigation(e)}
          onNavigationItemClicked={(e) => handleButtonClick({ id: e?.id })}
          onPopupInteraction={(e) => handleButtonClick(e)}
          onSubmitItem={(e) => sendItemToApi(e)}
          //onSubmit={ (formData) => handleSubmit(formData) }
          popup={formStateRef.current.popup}
          //readOnly={ true }
          successPopup={formStateRef.current.successPopup}
          supplementaryData={{
            ...supplementaryData,
            contractors: {
              data: contractors,
            },
            files: {
              rfpqId: currentRfpq?.id,
            },
            requirements: {
              data: retrievedRequirements,
            },
          }}
        />
      </div>
    </div>
  );
}

export default Rfpq;
