import axios from "axios";
import { API_HOST } from "../constants";

let headers = {};

const getAuth = () => {
  const authData = localStorage.getItem("Authorization");
  if (authData) {
    headers = { Authorization: authData };
  }
};

export const createFile = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/file_management/create_file`, data, { headers })
    .then(async (res) => {
      await onSuccess(res.data);
      headers["Content-Type"] = "application/json";
    })
    .catch((err) => {
      if (err.response.status === 401) {
        console.log("Something wrong");
      }
      onFailure(err.response);
    });
};

export const confirmFileUpload = async (
  data,
  onSuccess = () => {},
  onFailure = () => {}
) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/file_management/confirm_file_upload`, data, { headers })
    .then((res) => {
      onSuccess(res.data);
      headers["Content-Type"] = "application/json";
    })
    .catch((err) => {
      if (err.response.status === 401) {
        console.log("Something wrong");
      }
      onFailure(err.response);
    });
};

export const deleteFile = async (
  data,
  onSuccess = () => {},
  onFailure = () => {}
) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/file_management/delete_file`, data, { headers })
    .then((res) => {
      onSuccess(res.data);
      headers["Content-Type"] = "application/json";
    })
    .catch((err) => {
      console.log("Something wrong");

      onFailure(err.response);
    });
};

export const updateCCDC_AdditionalFileBlob = async (
  data,
  onSuccess = () => {},
  onFailure = () => {}
) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/qualification/saveAdditionalFileId`, data, { headers })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      if (err.response.status === 401) {
        console.log(
          "Something wrong while adding additional file blob in ccdc"
        );
      }
      onFailure(err.response);
    });
};

export const uploadFileToGCP = async (data, file, config) => {
  await axios.put(data, file, config);
};

//Resume File Upload
export const fileUpload = async (file) => {
  let message = {
    error: "",
    file_id: null,
    success: "",
  };
  let uploadFileName = file.name;
  let extension = uploadFileName.split(".").pop();
  let fileName = uploadFileName.replace("." + extension, "");

  let dataForCreateApi = {
    file_type: "resume",
    file_extension: extension,
    file_name: fileName,
  };
  await createFile(
    dataForCreateApi,
    async (data) => {
      //#region upload file using upload url
      message["file_id"] = data.id;
      try {
        await uploadFileToGCP(data.upload_url, file, {
          headers: {
            "Content-Type": file.content_type,
            "Access-Control-Allow-Origin": "*",
          },
        });
      } catch (e) {
        console.log("CORS Error");
      }
      //#endregion

      //#region confirm file is uploaded or not
      await confirmFileUpload(
        { file_id: data.id },
        () => {
          //TODO: Toast a message
          message["success"] =
            "File uploaded succesfully verified by calling confirm api";
        },
        () => {
          message["error"] = "File upload failed";
        }
      );

      //#endregion
    },
    () => {
      message["error"] = "File upload failed";
    }
  );

  return message;
};

export const getAllFiles = async (data) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/file_management/get_institution_files`, data, {
      headers,
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log("Something wrong", err);
    });
};

export const getProjectFiles = async () => {
  getAuth();
  return await axios
    .get(`${API_HOST}/file_management/get_project_files`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log("Something wrong", err);
    });
};

export const setShowcasePermission = async (
  data,
  onSuccess = () => {},
  onFailure = () => {}
) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/file_management/set_showcase_permission`, data, {
      headers,
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log("Something went wrong", err);
    });
};
export const downloadFile = async (
  data,
  onSuccess = () => {},
  onFailure = () => {}
) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/file_management/download`, data, {
      headers,
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log("Something went wrong ", err);
    });
};

export const setFileType = async (
  data,
  onSuccess = () => {},
  onFailure = () => {}
) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/file_management/set_file_type`, data, {
      headers,
    })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log("Something went wrong", err);
    });
};
