import React from "react";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from "../../../../assets/preQualifyIcons/deleteIcon";

export const DeleteButton = ({ handleClick }) => {
  return (
    <IconButton
    aria-label="more"
    aria-controls="long-menu"
    aria-haspopup="true"
    onClick={handleClick}
    >
      <DeleteIcon />
    </IconButton>
  )
}