export const cancelSubscription = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "CLEAR_CREATE_SUBSCRIPTION",
    payload: {
      endpoint: "/subscriptions/cancel_subscription",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};

export const clearCreateSubscription = () => (dispatch) => {
  dispatch({
    type: "CLEAR_CREATE_SUBSCRIPTION",
  });
};

export const createSubscription = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "CREATE_SUBSCRIPTION",
    payload: {
      endpoint: "/subscriptions/create_subscription",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};

export const getPrices = () => (dispatch) => {
  dispatch({
    type: "GET_SUBSCRIPTION_PRICES",
    payload: {
      endpoint: "/subscriptions/get_prices",
      method: "GET",
    },
  });
};

export const getLatestSubscriptionReceipt =
  (data, onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "GET_SUBSCRIPTION_RECEIPT",
      payload: {
        endpoint: "/subscriptions/get_latest_receipt",
        method: "GET",
      },
      onSuccess,
      onFailed,
    });
  };

export const getSubscriptionStatus =
  (data, onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "GET_SUBSCRIPTION_STATUS",
      payload: {
        endpoint: "/subscriptions/get_subscription_status",
        method: "GET",
      },
      onSuccess,
      onFailed,
    });
  };

export const modifySubscriptionAutorenewal =
  (data, onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "MODIFY_SUBSCRIPTION_AUTORENEWAL",
      payload: {
        endpoint: "/subscriptions/modify_subscription_autorenewal",
        method: "POST",
        body: data,
      },
      onSuccess,
      onFailed,
    });
  };

export const apply_promo_code = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "APPLY_PROMO_CODE",
    payload: {
      endpoint: "/subscriptions/apply_promo_code",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};
