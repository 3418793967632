import React from "react";
import { Content, StyledArea, StyledBox } from "../styles";
import MobileProjectsIcon from "../../../assets/dashboardIcons/mobileProjectsIcon";
import { useHistory } from "react-router";
import { FunctionArea, MobileBoxText } from "./styles";
import { Insights } from "./Insights";
// import {Bonds} from "./Bonds";
import { ObligeeBehaviours } from "./ObligeeBehaviours";
import { IDP } from "./IDP";

export const FunctionSection = ({ isMobile, userData }) => {
  const history = useHistory();

  return (
    <Content isMobile={isMobile}>
      <StyledArea
        marginTop="24px"
        isMobile={isMobile}
        style={{ display: "flex", flexDirection: "row", gap: "16px" }}
      >
        <ObligeeBehaviours isMobile={isMobile} />
        <Insights isMobile={isMobile} userData={userData} />
        {/*<Bonds isMobile={isMobile}/>*/}
        <IDP isMobile={isMobile} />
        <IDP isMobile={isMobile} automated />
        {isMobile && (
          <FunctionArea isMobile={isMobile}>
            <StyledBox
              boxHeight="110px"
              borderRadius="15px"
              isMobile={isMobile}
              onClick={() => {
                history.push("/buyers/prequalified/contractors");
              }}
              style={{ cursor: "pointer" }}
              margin="0 0 16px 0"
              flexDirection="row"
            >
              <MobileProjectsIcon />
              <MobileBoxText>Contractors</MobileBoxText>
            </StyledBox>
          </FunctionArea>
        )}
      </StyledArea>
    </Content>
  );
};
