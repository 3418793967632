import { DASHBOARDHEADER_HEIGHT } from "../../constants";
import { FormBuilder } from "../../components/formBuilder";
import formDetails from "./forms/formDetails.json";
import formSetup from "./forms/formSetup.json";
import formFields from "./forms/formFields.json";
import classes from "./style/submissionPreview.module.css";
import { FunctionalButton } from "../../components/functionalButton";
import { StyledQuestion } from "pages/newPreQualify/components/styles";
import { AddSubmitPopUp } from "components/addSubmitPopUp";
import { useEffect, useState } from "react";
import { AlertPopup } from "components/alertPopup/alertPopup";
import ProjectPreviewPopUp from "components/projectPreviewPopUp";
import { numberWithCommas } from "../../common/numberFormat";
import { convertPhoneNumberCanadaUSA } from "common/regex";
import { AddFinalizePopUp } from "components/addFinalizePopUp";
import PledgXeCQS from "assets/common/pledgxeCQS";
import handleFileDownload from "common/downloadFile";
import Spinner from "components/spinner";
import { isEmpty } from "lodash";
import { getCountryName } from "common/countryName";
import { getContractorCcdcsList } from "data/actions/serviceProvider";
import { getCCDCs } from "api/prequalify";
import { useDispatch, useSelector } from "react-redux";
import { ContractorCcdcListSelector } from "data/selectors/serviceProvider";

const SubmissionPreview = ({
  handleNext,
  prequalifyData,
  submitCqs = true,
  checkCcdcIdValue,
  checkSubmitStatus,
  ccdcData,
  setCcdcData,
  insuranceTypeList,
  setPrequalifyData,
  handleSavePreQualifyData,
  institutionId,
  currentDashboardUrl,
}) => {
  const [state, setState] = useState({
    showInteractionPopup: { visible: false, data: {} },
  });
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();
  const [openPopUp, setOpenPopup] = useState(false);
  const [openReviwPopup, setOpenReviewPopup] = useState(false);
  const [submitButton, setSubmitButton] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [reviewDisabled, setReviewedDisabled] = useState(false);
  const [ccdcList, setCcdcList] = useState(ccdcData);
  const companyLogoImage = prequalifyData?.project_info?.gc_company_logo?.file;
  let countryForHS = "United States";
  if (
    getCountryName(prequalifyData?.project_info?.owner_country) === "Canada"
  ) {
    countryForHS = "Canada";
  } else {
    countryForHS = "United States";
  }
  const [selectedCountry, setSelectedCountry] = useState(countryForHS);
  const [isSelectedCountry, isSetSelectedCountry] = useState(false);
  const [isCanada, isSetCanada] = useState(false);
  const [isUSA, isSetUSA] = useState(false);
  const healthSafetyProgram =
    prequalifyData?.annual?.remark_records?.health_safety_program_list;
  const healthSafetyEmployeeOrientationProgram =
    prequalifyData?.annual?.remark_records
      ?.health_safety_employee_orientation_program_list;
  const [healthSafetyProgramList, setHealthSafetyProgramList] = useState("");
  const [healthSafetyProgramNo, setHealthSafetyProgramNo] = useState(
    prequalifyData?.annual?.remark_records?.no_health_safety_program || false
  );
  const [healthSafetyProgramYes, setHealthSafetyProgramYes] = useState(
    prequalifyData?.annual?.remark_records?.health_safety_program || false
  );
  const [
    healthSafetyEmployeeOrientationProgramNo,
    setHealthSafetyEmployeeOrientationProgramNo,
  ] = useState(
    prequalifyData?.annual?.remark_records
      ?.no_health_safety_employee_orientation_program || false
  );
  const [
    healthSafetyEmployeeOrientationProgramYes,
    setHealthSafetyEmployeeOrientationProgramYes,
  ] = useState(
    prequalifyData?.annual?.remark_records
      ?.health_safety_employee_orientation_program || false
  );
  const [corsRadioButtonNoYesNo, setCorsRadioButtonNoYesNo] = useState(
    prequalifyData?.annual?.remark_records?.no_certificate_of_recognition ||
      false
  );
  const [healthSafetyProgramRemark, setHealthSafetyProgramRemark] =
    useState("");
  const [
    healthSafetyEmployeeOrientationProgramList,
    setHealthSafetyEmployeeOrientationProgramList,
  ] = useState("");
  const [
    healthSafetyEmployeeOrientationProgramRemark,
    setHealthSafetyEmployeeOrientationProgramRemark,
  ] = useState("");

  const submitData = {
    ccdc_id: prequalifyData?.ccdcId,
    rfpq_id: prequalifyData?.project_info?.rfpq_id,
  };
  const contractorCcdcList = useSelector(ContractorCcdcListSelector);

  const getCcdcList = async () => {
    if (
      currentDashboardUrl === "/service-provider-dashboard" &&
      institutionId
    ) {
      dispatch(
        getContractorCcdcsList(
          { institution_id: institutionId },
          () => {},
          () => {}
        )
      );
    } else {
      await getCCDCs()
        .then((data) => {
          setCcdcList(data);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    if (ccdcList?.length < 1) {
      getCcdcList();
    }
  }, []);
  useEffect(() => {
    if (contractorCcdcList?.length > 0) {
      setCcdcList(contractorCcdcList);
    }
  }, [contractorCcdcList]);

  useEffect(() => {
    if (
      prequalifyData?.ccdc_status === "Reviewed" ||
      prequalifyData?.ccdc_status === "Submitted" ||
      prequalifyData?.ccdc_status === "Downloaded"
    ) {
      setReviewedDisabled(true);
      if (prequalifyData?.ccdc_status === "Reviewed") {
        setSubmitButton(false);
      }
      if (prequalifyData?.ccdc_status === "Submitted") {
        setSubmitButton(true);
      }
      setDisabled(false);
    }
    if (
      prequalifyData?.ccdc_status === "" ||
      prequalifyData?.ccdc_status === "Draft"
    ) {
      setReviewedDisabled(false);
      setDisabled(true);
      setSubmitButton(true);
    }
    if (selectedCountry === "United States") {
      setSelectedCountry("United States");

      isSetSelectedCountry(true);
      isSetUSA(true);
    } else {
      isSetCanada(true);
    }

    if (
      prequalifyData?.annual?.remark_records?.health_safety_program === true
    ) {
      const healthSafetyProgramList = healthSafetyProgram
        ? healthSafetyProgram.map((item) => item.label).join(", ")
        : "";
      setHealthSafetyProgramList(healthSafetyProgramList);
    } else if (
      prequalifyData?.annual?.remark_records?.no_health_safety_program === true
    ) {
      const healthSafetyProgramRemark =
        prequalifyData?.annual?.remark_records?.no_health_safety_program_remark;
      setHealthSafetyProgramRemark(healthSafetyProgramRemark);
    }

    if (
      prequalifyData?.annual?.remark_records
        ?.health_safety_employee_orientation_program === true
    ) {
      const healthSafetyEmployeeOrientationProgramList =
        healthSafetyEmployeeOrientationProgram
          ? healthSafetyEmployeeOrientationProgram
              .map((item) => item.label)
              .join(", ")
          : "";
      setHealthSafetyEmployeeOrientationProgramList(
        healthSafetyEmployeeOrientationProgramList
      );
    } else if (
      prequalifyData?.annual?.remark_records
        ?.no_health_safety_employee_orientation_program === true
    ) {
      const healthSafetyEmployeeOrientationProgramRemark =
        prequalifyData?.annual?.remark_records
          ?.no_health_safety_employee_orientation_program_remark;
      setHealthSafetyEmployeeOrientationProgramRemark(
        healthSafetyEmployeeOrientationProgramRemark
      );
    }

    if (
      prequalifyData?.annual?.remark_records?.no_certificate_of_recognition ===
        true &&
      selectedCountry === "United States"
    ) {
      setCorsRadioButtonNoYesNo(false);
    }

    if (
      prequalifyData?.annual?.remark_records?.health_safety_program === true &&
      selectedCountry === "Canada"
    ) {
      setHealthSafetyProgramYes(false);
    }

    if (
      prequalifyData?.annual?.remark_records?.no_health_safety_program ===
        true &&
      selectedCountry === "Canada"
    ) {
      setHealthSafetyProgramNo(false);
    }

    if (
      prequalifyData?.annual?.remark_records
        ?.health_safety_employee_orientation_program === true &&
      selectedCountry === "Canada"
    ) {
      setHealthSafetyEmployeeOrientationProgramYes(false);
    }

    if (
      prequalifyData?.annual?.remark_records
        ?.no_health_safety_employee_orientation_program === true &&
      selectedCountry === "Canada"
    ) {
      setHealthSafetyEmployeeOrientationProgramNo(false);
    }
  }, [prequalifyData?.ccdc_status, selectedCountry]);
  //separate selected projects according to type
  const projects = prequalifyData?.projects;
  const underwayProjects = [];
  const keyProjects = [];
  const comparableProjects = [];
  if (projects) {
    projects.map((project) => {
      if (project) {
        project["type"]?.map((type) => {
          if (type === "underway") underwayProjects.push(project);
          if (type === "key") keyProjects.push(project);
          if (type === "comparable") comparableProjects.push(project);
        });
      }
    });
  }

  const insuranceListObject = { ...prequalifyData?.insuranceList };
  const selectedInsuranceData = insuranceListObject?.order?.map((key) => ({
    ...insuranceListObject?.state[key],
    type: insuranceTypeList?.find(
      (value) => value.short_form === insuranceListObject?.state[key].type
    )
      ? insuranceTypeList?.find(
          (value) => value.short_form === insuranceListObject?.state[key].type
        ).type
      : insuranceListObject?.state[key].type,
  }));
  insuranceListObject.state = selectedInsuranceData;

  const handleReviewed = () => {
    setOpenReviewPopup(false);
    setDisabled(false);
    setSubmitButton(false);
    setReviewedDisabled(true);

    setPrequalifyData("ccdc_status", "Reviewed");
  };
  const handleReviewClick = () => {
    setOpenReviewPopup(true);
  };

  const data = {
    projectTitle: prequalifyData?.project_info?.project_title,
    projectNumber: prequalifyData?.project_info?.project_number,
    projectCategory: prequalifyData?.project_info?.categories,
    projectOwner: prequalifyData?.project_info?.owner_name,
    projectOwnerEmail: prequalifyData?.project_info?.owner_email,
    projectOwnerPhone: convertPhoneNumberCanadaUSA(
      prequalifyData?.project_info?.owner_phone
    ),
    ProjectLocation: prequalifyData?.project_info?.project_city,
    projectOwnerAddress:
      (prequalifyData?.project_info?.owner_suite
        ? prequalifyData?.project_info?.owner_suite + "-"
        : "") +
      (prequalifyData?.project_info?.owner_address
        ? prequalifyData?.project_info?.owner_address + ", "
        : "") +
      (prequalifyData?.project_info?.owner_city
        ? prequalifyData?.project_info?.owner_city + ", "
        : "") +
      (prequalifyData?.project_info?.owner_province
        ? prequalifyData?.project_info?.owner_province + ", "
        : "") +
      prequalifyData?.project_info?.owner_postal_code,
    companyName: prequalifyData?.project_info?.gc_name,
    companyEmail: prequalifyData?.project_info?.gc_email,
    companyContactNumber: convertPhoneNumberCanadaUSA(
      prequalifyData?.project_info?.gc_phone
    ),
    companyWebsite: prequalifyData?.project_info?.gc_website,
    companyLogo: prequalifyData?.project_info?.gc_company_logo,
    companyLogoImage: companyLogoImage,
    operationalCountry: prequalifyData?.project_info?.gc_operational_country,
    operationalProvince: prequalifyData?.project_info?.gc_operational_province,
    operationalRegion: prequalifyData?.project_info?.gc_operational_region,
    yearEstablished: prequalifyData?.legal_structure?.establishment_year,
    ventureType: prequalifyData?.legal_structure?.venture_type,
    companyUnion: prequalifyData?.project_info?.gc_union,
    companyAssociation: prequalifyData?.project_info?.gc_association,
    companyAddress:
      (prequalifyData?.project_info?.gc_suite_number
        ? prequalifyData?.project_info?.gc_suite_number + "-"
        : "") +
      (prequalifyData?.project_info?.gc_address
        ? prequalifyData?.project_info?.gc_address + ", "
        : "") +
      (prequalifyData?.project_info?.gc_city
        ? prequalifyData?.project_info?.gc_city + ", "
        : "") +
      (prequalifyData?.project_info?.gc_province
        ? prequalifyData?.project_info?.gc_province + ", "
        : "") +
      prequalifyData?.project_info?.gc_postal_code,

    businessCategory: prequalifyData?.project_info?.gc_categories,
    legalStructurePersonnels: {
      selectedData: prequalifyData?.legal_structure?.personnel,
    },
    bankName: prequalifyData?.bank?.company_name,

    bankAddress:
      (prequalifyData?.bank?.suite_number &&
      prequalifyData?.bank?.suite_number !== "None"
        ? prequalifyData?.bank?.suite_number + "-"
        : "") +
      (prequalifyData?.bank?.address
        ? prequalifyData?.bank?.address + ","
        : "") +
      (prequalifyData?.bank?.city ? prequalifyData?.bank?.city + "," : "") +
      (prequalifyData?.bank?.province
        ? prequalifyData?.bank?.province + " "
        : "") +
      prequalifyData?.bank?.postal,

    bankContactName:
      prequalifyData?.bank?.contact_first_name +
      " " +
      prequalifyData?.bank?.contact_last_name,

    bankContactEmail: prequalifyData?.bank?.contact_email,

    bondingCompanyName: prequalifyData?.bonding?.company_name,
    bondingCompanyAddress:
      (prequalifyData?.bonding?.suite_number &&
      prequalifyData?.bonding?.suite_number !== "None"
        ? prequalifyData?.bonding?.suite_number + "-"
        : "") +
      (prequalifyData?.bonding?.address
        ? prequalifyData?.bonding?.address + ","
        : "") +
      (prequalifyData?.bonding?.city
        ? prequalifyData?.bonding?.city + ","
        : "") +
      (prequalifyData?.bonding?.province
        ? prequalifyData?.bonding?.province + " "
        : "") +
      prequalifyData?.bonding?.postal,
    bondingCompanyContactName:
      prequalifyData?.bonding?.contact_first_name +
      " " +
      prequalifyData?.bonding?.contact_last_name,
    bondingCompanyContactEmail: prequalifyData?.bonding?.contact_email,
    bondAggregateLimit: prequalifyData?.bonding?.bond_aggregate_limit,
    bondSingleProjectLimit: prequalifyData?.bonding?.bond_single_project_limit,
    insuranceDetails: { selectedData: insuranceListObject },

    keyPersonnels: {
      selectedData: prequalifyData?.key_personnel,
    },
    healthSafetyPersonnelUSA: {
      selectedData: prequalifyData?.annual?.health_safety_personnel,
    },
    safetyRecords: prequalifyData?.annual?.safety_records,
    safetyRecordsUSA: prequalifyData?.annual?.safety_records_usa,
    safetyRecordsForPdf: prequalifyData?.annual?.safety_records_for_pdf,
    safetyRecordsUSAForPdf: prequalifyData?.annual?.safety_records_usa_for_pdf,
    selectedCountryForHealthSafety: selectedCountry,
    isSelectedCountryForHealthSafety: isSelectedCountry,
    isSelectedCountryCanada: isCanada,
    isSelectedCountryUSA: isUSA,
    constructionRecords: prequalifyData?.annual?.annual_values,
    constructionRecordsForPdf: prequalifyData?.annual?.annual_values_for_pdf,
    safetyRecordsRemark:
      prequalifyData?.annual?.remark_records?.annual_safety_records_remark,
    safetyRecordsRemarkUSA:
      prequalifyData?.annual?.remark_records?.annual_safety_records_remark_usa,
    constructionRecordsRemark:
      prequalifyData?.annual?.remark_records?.annual_construction_value_remark,
    corsRadioButtonYes:
      prequalifyData?.annual?.remark_records?.certificate_of_recognition,
    corsRadioButtonNo: corsRadioButtonNoYesNo,
    corsRadioButtonEquivalentToCor:
      prequalifyData?.annual?.remark_records
        ?.certificate_of_recognition_equivalent,
    corsRadioButtonCoveredByOther:
      prequalifyData?.annual?.remark_records
        ?.no_certificate_of_recognition_equivalent,
    healthSafetyEmployeeOrientationProgramYes:
      healthSafetyEmployeeOrientationProgramYes,
    healthSafetyProgramYes: healthSafetyProgramYes,
    healthSafetyProgramList: healthSafetyProgramList,
    healthSafetyProgramRemark: healthSafetyProgramRemark,
    healthSafetyEmployeeOrientationProgramList:
      healthSafetyEmployeeOrientationProgramList,
    healthSafetyEmployeeOrientationProgramRemark:
      healthSafetyEmployeeOrientationProgramRemark,
    healthSafetyEmployeeOrientationProgramNo:
      healthSafetyEmployeeOrientationProgramNo,
    healthSafetyProgramNo: healthSafetyProgramNo,
    healthSafetyPersonnelCanada: {
      selectedData: prequalifyData?.annual?.health_safety_personnel_canada,
    },
    corsRemark: prequalifyData?.annual?.remark_records?.cors_remark,
    keyProjectsList: keyProjects,
    comparableProjectsList: comparableProjects,
    underwayProjectsList: underwayProjects,
    additionalDocument: prequalifyData?.additional_document,
    financialRecordsDocuments:
      prequalifyData?.annual?.financial_records_documents,
    healthSafetyDocumentsUSA:
      prequalifyData?.annual?.health_safety_documents_usa,
    healthSafetyDocumentsCanada:
      prequalifyData?.annual?.health_safety_documents_canada,
    institutionId: prequalifyData?.institution_id,
  };

  //create an array of safetyRecords keys
  var safetyRecordRows = [];
  if (selectedCountry === "Canada") {
    if (data.safetyRecords) {
      Object.keys(data.safetyRecords).map((safetyRecordRow) =>
        safetyRecordRows.push({ id: safetyRecordRow, title: safetyRecordRow })
      );
      safetyRecordRows.reverse();
    }
  }

  var safetyRecordUSARows = [];
  if (selectedCountry === "United States") {
    if (data.safetyRecordsUSA) {
      Object.keys(data.safetyRecordsUSA).map((safetyRecordUSARow) =>
        safetyRecordUSARows.push({
          id: safetyRecordUSARow,
          title: safetyRecordUSARow,
        })
      );
      safetyRecordUSARows.reverse();
    }
  }

  //adding  "name" field with value of concating "firstname" and "lastName" to each personnel object
  if (data.keyPersonnels.selectedData)
    Object.values(data.keyPersonnels.selectedData).map(
      (value) => (value.name = value.firstName + " " + value.lastName)
    );

  if (data.healthSafetyPersonnelUSA.selectedData)
    Object.values(data.healthSafetyPersonnelUSA.selectedData).map(
      (value) => (value.name = value.firstName + " " + value.lastName)
    );
  if (data.healthSafetyPersonnelCanada.selectedData)
    Object.values(data.healthSafetyPersonnelCanada.selectedData).map(
      (value) => (value.name = value.firstName + " " + value.lastName)
    );
  if (data.legalStructurePersonnels.selectedData)
    Object.values(data.legalStructurePersonnels.selectedData).map(
      (value) => (value.name = value.firstName + " " + value.lastName)
    );
  return (
    <>
      <StyledQuestion>
        <PledgXeCQS />
      </StyledQuestion>
      <div className={classes["form-container"]}>
        {isEmpty(prequalifyData) ? (
          <div
            style={{
              height: "500px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
          </div>
        ) : (
          <FormBuilder
            formFields={{
              ...formFields,
              ...(selectedCountry === "Canada"
                ? {
                    safetyRecords: {
                      ...formFields.safetyRecords,
                      rows: safetyRecordRows,
                    },
                  }
                : {
                    safetyRecordsUSA: {
                      ...formFields.safetyRecordsUSA,
                      rows: safetyRecordUSARows,
                    },
                  }),
            }}
            onInteractionItemClicked={(e) => {
              if (
                e.id === "keyProjectsList" ||
                e.id === "comparableProjectsList" ||
                e.id === "underwayProjectsList"
              ) {
                setState({
                  ...state,
                  showInteractionPopup: {
                    ...state.showInteractionPopup,
                    visible: true,
                    data: projects.find((project) => project.id === e.rowId),
                  },
                });
              }
              if (e.id === "additionalDocument") {
                handleFileDownload(e.rowId, e.name);
              }
            }}
            onResumeButtonClicked={(e) => {
              handleFileDownload(e.file_id, e.fileName);
            }}
            formSetup={formSetup}
            formDetails={formDetails}
            navigationType="TABS"
            readOnly={true}
            data={data}
          />
        )}
      </div>
      <ProjectPreviewPopUp
        openPopup={state.showInteractionPopup.visible}
        setOpenPopup={(e) =>
          setState({
            ...state,
            showInteractionPopup: {
              ...ProjectPreviewPopUp.showInteractionPopup,
              visible: e,
            },
          })
        }
        data={state.showInteractionPopup.data}
      />
      {submitCqs && (
        <>
          <div className={classes["button-container"]}>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-start",
              }}
            >
              <FunctionalButton
                buttonTitle="REVIEW"
                handleButton={() => handleReviewClick()}
                width="170px"
                disabled={reviewDisabled}
              />
              {prequalifyData?.project_info?.rfpq_id && (
                <FunctionalButton
                  buttonTitle="SUBMIT"
                  handleButton={() => {
                    setOpenPopup(true);
                  }}
                  width="170px"
                  marginRight="0"
                  disabled={submitButton}
                />
              )}
            </div>
            <div
              style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}
            >
              <FunctionalButton
                buttonTitle="SHARE ONLINE"
                handleButton={() => console.log("")}
                width="170px"
                marginRight="16px"
                disabled={true}
              />
              <FunctionalButton
                buttonTitle="EXPORT"
                handleButton={() => {
                  handleNext();
                }}
                width="170px"
                marginRight="0"
                disabled={disabled}
              />
            </div>
          </div>

          <AddSubmitPopUp
            openDialog={openPopUp}
            setOpenDialog={setOpenPopup}
            handleNext={handleNext}
            data={submitData}
            ccdcData={ccdcList}
            submitButton={submitButton}
            setPrequalifyData={setPrequalifyData}
            setSubmitButton={setSubmitButton}
          />
          <AddFinalizePopUp
            setOpenDialog={(e) => setOpenReviewPopup(e)}
            openDialog={openReviwPopup}
            prequalifyData={prequalifyData}
            onSuccess={() => handleReviewed()}
          />
        </>
      )}
    </>
  );
};

export default SubmissionPreview;
