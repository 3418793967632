import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from "../../../../constants";
import { StyledTableCell } from "components/filesListTable/styles";
import { Button } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export const StyledCellTitle = styled(StyledTableCell)`
  width: 21%;
`;

export const StyledCellCommon = styled(StyledTableCell)`
  width: ${(props) => (props.width ? props.width : "28%")};
`;

export const StyledCellAction = styled(StyledTableCell)`
  width: 7%;
`;

export const StyledMenu = styled(Menu)`
  &&&& {
    margin-top: 36px;
    margin-left: -50px;
  }

  .MuiMenu-list {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &&.MuiListItem-gutters {
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 3px;
    padding-bottom: 3px;
    width: 180px;
  }
  &:hover {
    background-color: ${COLORS.primaryOrange};
    color: white;
  }
`;

export const StyledButton = styled(Button)`

  && {
    width: ${(props) => (props.width ? props.width : "100px")};
    height: 21px;
    background-color: white;
    box-sizing: border-box;
    //border-radius: 6px;
    

    font-family: SF Pro Text;
    font-size: 13px;
    font-weight: 500;
    transition: all 0.5s ease;
    text-transform: none;

    &:hover {
      background-color: ${COLORS.primaryOrange};
      color: white;
    }
    .MuiButton-label{
      justify-content:left;
    }
`;

export const StyledStatus = styled.div`
  font-family: ${TYPOGRAPHY.primaryFont};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: center;
  color: ${(props) => props.textColor || "black"};
  background-color: ${(props) => props.backgroundColor || "white"};
  border-radius: 10px;
  width: 78px;
  height: 18px;
  padding-top: 2px;

  &::first-letter {
    text-transform: capitalize;
  }
`;

export const StyledAlertCircle = styled.div`
  width: 20px;
  height: 20px;
  padding: 4;
  background: #ff6d1d;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  cursor: pointer;
`;

export const StyledAlertText = styled.div`
  color: white;
  font-size: 15;
  font-family: SF Pro Text;
  font-weight: 500;
  texttransform: uppercase;
  letter-spacing: 0.07;
  word-wrap: break-word;
`;
