import React from 'react';
import { COLORS, FONT_SIZES } from '../../constants';
import Tooltip from '@mui/material/Tooltip';
import Error from "@material-ui/icons/Error";

import classes from './style/FormBuilder.module.css';

export const NavigationBuilder =({ changeLocationDirect, formSetup, icons, ignoreRequired, interactionState, navigationType='BUBBLES', onNavigationItemClicked, readOnly, visitedLocations }) => {
  function handleClick(index) {
    if (changeLocationDirect) {
      changeLocationDirect([index]);
    };
  };

  if (navigationType === 'TABS') {
    return(
      <div className={ [classes['navigation-inner-container'], 'pledgxform-navigation-inner-container'].join(' ') }>
        {
          formSetup.map((pageDetails, index) => (
            <div
              className={
                [
                  classes['navigation-tab-box'],
                  (interactionState?.navigation?.current?.location?.[0] === index) && classes['selected-tab'],
                  (!readOnly && ignoreRequired && interactionState?.requiredPages?.ids?.includes(pageDetails.id)) && classes['tab-error'],
                  'pledgxform-navigation-tab-box'
                ].join(' ')
              }
              onClick={ () => handleClick(index) }
            >
              <span title={ pageDetails.pageTitle }>{ pageDetails.pageTitle }</span>
              {
                (!readOnly && ignoreRequired && interactionState?.requiredPages?.ids?.includes(pageDetails.id)) &&
                  <Error
                    style={{
                      color: "#FF647C",
                      marginLeft: '5px',
                      width: 16,
                      height: 16,
                    }}
                  />
              }
            </div>
          ))
        }
      </div>
    );
  } else {
    return(
      <div className={ [classes['navigation-inner-container'], 'pledgxform-navigation-inner-container'].join(' ') }>
        {
          formSetup.map((pageDetails, index) => (
            <Tooltip
              title={ pageDetails.pageTitle }
              placement='bottom'
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: FONT_SIZES.body_5,
                    color: 'white',
                    bgcolor: ((interactionState?.navigation?.current?.location?.[0] === index) || (visitedLocations?.includes(index))) ? COLORS.primaryOrange : COLORS.darkGrey1,
                    border: `1.5px solid ${ ((interactionState?.navigation?.current?.location?.[0] === index) || (visitedLocations?.includes(index))) ? COLORS.primaryOrange : COLORS.darkGrey1 }`,
                    maxWidth: '250px',
                    '& .MuiTooltip-arrow': {
                      color: ((interactionState?.navigation?.current?.location?.[0] === index) || (visitedLocations?.includes(index))) ? COLORS.primaryOrange : COLORS.darkGrey1
                    },
                  },
                },
              }}
              >
                <div
                  className={ [classes['navigation-element-container'], 'pledgxform-navigation-element-container'].join(' ') }
                  onClick={ () => handleClick(index) }
                >
                  {
                    pageDetails.pageIcons ?
                      <div className={ [classes['navigation-icon-container'], 'pledgxform-navigation-icon-container'].join(' ') }>
                        {
                          ((interactionState?.navigation?.current?.location?.[0] === index) || (visitedLocations?.includes(index))) ? pageDetails.pageIcons.color : pageDetails.pageIcons.grey
                        }
                      </div>
                    :
                      (icons?.[pageDetails.id]) ?
                        <div className={ [classes['navigation-icon-container'], 'pledgxform-navigation-icon-container'].join(' ') }>
                          {
                            ((interactionState?.navigation?.current?.location?.[0] === index) || (visitedLocations?.includes(index))) ? icons[pageDetails.id].color : icons[pageDetails.id].grey
                          }
                        </div>
                      :
                        <div className={ [classes['navigation-text-container'], 'pledgxform-navigation-text-container'].join(' ') } style={{ backgroundColor: ((interactionState?.navigation?.current?.location?.[0] === index) || (visitedLocations?.includes(index))) ? COLORS.primaryOrange : COLORS.greyBorder }}>
                          <span style={{ fontSize: FONT_SIZES.body_6 }}>{ pageDetails.pageTitle || (index + 1) }</span>
                        </div>
                  }
                </div>
            </Tooltip>
          ))
        }
      </div>
    );
  };
};
