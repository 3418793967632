import get from "lodash/get";

const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  const payload = get(action, "payload");
  switch (action.type) {
    case "CLEAR_CREATE_SUBSCRIPTION":
      return {
        ...state,
        permissions: null,
        stripeData: null,
        subscription_status: null,
      };
    case "CREATE_SUBSCRIPTION":
      return {
        ...state,
        stripeData: payload,
      };
    case "MODIFY_SUBSCRIPTION_AUTORENEWAL":
      if (action.error) {
        return state;
      }
      try {
        return {
          ...state,
          subscription: {
            ...state.subscription,
            subscription_renews: payload.autorenewal_status,
          },
        };
      } catch {
        return state;
      }
    case "GET_SUBSCRIPTION_PRICES":
      return {
        ...state,
        currency_options: payload.currency_options,
        permissions: payload.permissions,
        prices: payload.prices,
        stripe_error: payload.stripe_error,
        subscription: payload.subscribed_package,
        new_customer: payload.new_customer,
      };
    case "GET_SUBSCRIPTION_RECEIPT":
      return {
        ...state,
        receipt_data: payload.receipt_data,
      };
    case "GET_SUBSCRIPTION_STATUS":
      return {
        ...state,
        subscription_status: payload.subscription_status,
      };
    case "APPLY_PROMO_CODE":
      if (action.error) {
        return state;
      }
      try {
        return {
          ...state,
          stripeData: payload,
        };
      } catch {
        return state;
      }

    default:
      return state;
  }
};
