import styled from "styled-components";

export const StyledBox = styled.div`
  width: 100%;
  background: #ff6d1d;
  border-radius: 6px;
  border: 1px #ebe9f1 solid;
  display: flex;
  padding: 21px 23px;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const StyledMainBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledStartComponent = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4;
`;

export const StyledDateArea = styled.div`
  text-align: center;
  color: white;
  font-size: 14px;
  font-family: SF Pro Text;
  font-weight: 400;
  word-wrap: break-word;
`;

export const StyledTextArea = styled.span`
  text-align: center;
  color: white;
  font-size: 14px;
  font-family: SF Pro Text;
  font-weight: 400;
  word-wrap: break-word;
`;

export const StyledCircle = styled.div`
  width: 48px;
  height: 48px;
  border: 4.5px solid white;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledDot = styled.div`
  height: 10px;
  width: 15px;
  border-radius: 99px;
  background-color: white;
  border: 4px white solid;
  margin-left: 2px;
`;
export const ScrollButton = styled.button`
  height: auto;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  padding: 0px;
`;
export const StyledBoxContainer = styled.div`
  width: 15%;
  height: 60px;
  background: white;
  border-radius: 6px;
  border: 1px #ebe9f1 solid;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
