import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from '../../../constants';
import Typography from '@material-ui/core/Typography';

export const NameRating = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  padding-top: 12px;
`;

export const RatedNumber = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    ${props => props.hasData ? 'margin-top: 12px;' : ""}
  }
`;

export const TotalNumber = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: ${COLORS.columnGreyText};
    margin-top: 16px;
    margin-left: 4px;
  }
`;

export const RatingDiv = styled.div`
  display: flex;
  flex-direction: row;
`;


export const ThreeDotButtonDiv = styled.div`
    position: absolute;
    top: 0px;
    right: -14px;
`;

export const TopText = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont}
    font-size: ${props => props.fontSize || "15px"};
    font-style: normal;
    font-weight: ${props => props.fontWeight || "400"};
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: ${COLORS.darkGrey1};
    ${props => props.margin ? `margin: ${props.margin};` : ""}
  }
`;

export const StyledAwardsArea = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;