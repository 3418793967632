import InsightsIcon from "../../../assets/dashboardIcons/InsightsIcon";
import { FunctionalButton } from "../../../components/functionalButton";
import React from "react";
import { MediumText, RegularText, StyledBox, SmallText } from "../styles";
import { useHistory } from "react-router";

import {
  FunctionArea,
  StyledTitle,
  IntroArea,
  ButtonContainer,
  ComingSoonImg,
  ImgDiv,
} from "./styles";
import { IDPAutoIcon, IDPGuidedIcon } from "assets/dashboardIcons/idpIcons";

export const IDP = ({ isMobile, automated }) => {
  const history = useHistory();

  const handleIDP = () => {
    if (automated) {
      history.push("/underwriter/idpdocuments?automated=true");
    } else {
      history.push("/underwriter/idpdocuments");
    }
  };

  return (
    <FunctionArea isMobile={isMobile}>
      {isMobile && (
        <StyledBox
          boxHeight="110px"
          borderRadius="15px"
          isMobile={isMobile}
          onClick={handleIDP}
          style={{ cursor: "pointer" }}
        >
          <IntroArea>
            {automated ? <IDPAutoIcon /> : <IDPGuidedIcon />}
          </IntroArea>
          <ButtonContainer>
            <FunctionalButton
              buttonTitle="IDP Documents"
              width="80%"
              marginRight="0px"
              isReversedColor={true}
              hasBoxShadow={true}
              handleButton={handleIDP}
              disabled={false}
            />
          </ButtonContainer>
        </StyledBox>
      )}
      {!isMobile && (
        <>
          <StyledBox boxHeight="216px">
            <IntroArea>
              {automated ? <IDPAutoIcon /> : <IDPGuidedIcon />}
            </IntroArea>
            <ButtonContainer>
              <FunctionalButton
                buttonTitle={`IDP ${automated ? "Auto" : "Guided"}`}
                width="80%"
                marginRight="0px"
                isReversedColor={true}
                hasBoxShadow={true}
                handleButton={handleIDP}
                disabled={automated ? true : false}
              />
            </ButtonContainer>
          </StyledBox>
        </>
      )}
    </FunctionArea>
  );
};
