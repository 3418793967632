import React, { useState } from "react";
import {
  StyledDescription,
  CardRoot,
  StyledCardContent,
  StyledName,
  StyledProfileImage,
  StyledXMargin,
} from "./styles";
import classes from "../style/InteractiveTable.module.css";

import BorderLessConciseTable from "components/filesListTable/borderLessConciseTable";
import { LetterAvatar } from "components/letterAvatar";
import { PlaceholderText, StyledBox } from "../styles";
import XIcon from "assets/buyers/xIcon";
import { COLORS } from "../../../constants";
import { RoundedCheckbox } from "../../roundedCheckbox";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { IconButton } from "@mui/material";
import { color } from "@mui/system";
import { addHTTP } from "common/numberFormat";

export const SelectedItems = ({
  options,
  readOnly,
  selectedItems,
  setSelectedItems,
  setRerender,
  onInteraction,
}) => {
  const [state, setState] = useState({
    cardHeight: 150,
  });

  function modifyItem({ action, index, payload }) {
    switch (action) {
      case "CHECKBOX_CHANGE":
        var newSelectedItems = [...selectedItems];
        var newCheckedObj = options?.list?.keyFields?.checkbox
          ? { [options.list.keyFields.checkbox]: payload.value }
          : { checked: payload.value };
        newSelectedItems[index] = {
          ...newSelectedItems[index],
          ...newCheckedObj,
        };
        setSelectedItems(newSelectedItems);
        break;
    }
  }

  const removeItem = (e, index) => {
    let id = selectedItems[index].id;
    setSelectedItems(selectedItems.filter((arrayItems) => arrayItems !== id));
    const temp = [...selectedItems];
    temp.splice(index, 1);
    setSelectedItems(temp);
    setRerender(true);
  };

  const body = (bodyData) => {
    if (options?.cardType) {
      switch (options.cardType) {
        case "PERSONNEL_CARD":
          if (options?.cardHeight) {
            if (state.cardHeight !== options?.cardHeight) {
              setState({ ...state, cardHeight: options?.cardHeight });
            }
          }
          break;
        // return(
        //   <div>Either add a bespoke card style here, or augment the existing card style below (don't include a return statement here), as I have done with the SINGLE_CHECKBOX cardType</div>
        // )
        case "SINGLE_CHECKBOX":
          if (state.cardHeight !== 200) {
            setState({ ...state, cardHeight: 200 });
          }
          break;
      }
      return (
        <div
          className={classes["card-root"]}
          style={{
            border: `1px solid ${
              readOnly ? COLORS.greyBorder : COLORS.primaryLightOrange
            }`,
            color: readOnly ? COLORS.darkGrey1 : "inherit",
            height: `${state.cardHeight - 10}px`,
          }}
        >
          <div className={classes["card-content"]}>
            {options?.list?.keyFields?.letterAvatar && (
              <div className={classes["profile-image"]}>
                {options?.list?.keyFields?.letterAvatar && (
                  <LetterAvatar name={bodyData[options.list.keyFields.title]} />
                )}
                {!readOnly && (
                  <div
                    className={classes["x-margin"]}
                    onClick={() => removeItem(null, bodyData.index)}
                  >
                    <XIcon />
                  </div>
                )}
              </div>
            )}
            {options?.list?.keyFields?.title && (
              <div className={classes.name}>
                {bodyData[options.list.keyFields.title]}
              </div>
            )}
            <div className={classes.description}>
              {options?.list?.keyFields?.description
                ? bodyData[options.list.keyFields.description]
                : ""}
            </div>
            {["SINGLE_CHECKBOX"].includes(options.cardType) && (
              <div className={classes["single-checkbox-container"]}>
                <span>{options.labels.keyItemsCheckboxText}</span>
                <RoundedCheckbox
                  attributeTypes={
                    options?.list?.keyFields?.checkbox
                      ? [bodyData[options.list.keyFields.checkbox]]
                      : [bodyData.checked]
                  }
                  onChange={() =>
                    modifyItem({
                      action: "CHECKBOX_CHANGE",
                      index: bodyData.index,
                      payload: {
                        value: options?.list?.keyFields?.checkbox
                          ? bodyData[options.list.keyFields.checkbox]
                            ? !bodyData[options.list.keyFields.checkbox]
                            : true
                          : bodyData.checked
                          ? !bodyData.checked
                          : true,
                      },
                    })
                  }
                  readOnly={readOnly}
                  value={
                    options?.list?.keyFields?.checkbox
                      ? bodyData[options.list.keyFields.checkbox] || null
                      : bodyData.checked || null
                  }
                />
              </div>
            )}
            {["PERSONNEL_CARD"].includes(options.cardType) && (
              <>
                {options.list.keyFields.checkbox && (
                  <div className={classes["personnel-checkbox-container"]}>
                    {bodyData[options.list.keyFields.checkbox]?.map((type) => (
                      <>
                        <span>{type === "key_site" ? "Site" : "Office"}</span>
                        <RoundedCheckbox
                          attributeTypes={
                            options?.list?.keyFields?.checkbox
                              ? [bodyData[options.list.keyFields.checkbox]]
                              : [bodyData.checked]
                          }
                          onChange={() =>
                            modifyItem({
                              action: "CHECKBOX_CHANGE",
                              index: bodyData.index,
                              payload: {
                                value: options?.list?.keyFields?.checkbox
                                  ? bodyData[options.list.keyFields.checkbox]
                                    ? !bodyData[options.list.keyFields.checkbox]
                                    : true
                                  : bodyData.checked
                                  ? !bodyData.checked
                                  : true,
                              },
                            })
                          }
                          readOnly={readOnly}
                          value={
                            options?.list?.keyFields?.checkbox
                              ? bodyData[options.list.keyFields.checkbox] ||
                                null
                              : bodyData.checked || null
                          }
                        />
                      </>
                    ))}
                  </div>
                )}
                <div className={classes["resume-button-container"]}>
                  {options.list.keyFields.resume && (
                    <div
                      className={classes["resume-button"]}
                      style={{
                        borderColor: bodyData[options.list.keyFields.resume]
                          ? COLORS.primaryOrange
                          : "#ECEBED",
                        backgroundColor: bodyData[options.list.keyFields.resume]
                          ? "#FFFFFF"
                          : "#ECEBED",
                      }}
                      onClick={() =>
                        bodyData[options.list.keyFields.resume]
                          ? onInteraction &&
                            onInteraction({
                              fileName:
                                bodyData[options.list.keyFields.resume].name,
                              file_id:
                                bodyData[options.list.keyFields.resume].file_id,
                            })
                          : null
                      }
                    >
                      Resume
                    </div>
                  )}
                  {options.list.keyFields.linkedIn && (
                    <IconButton
                      sx={{
                        pointerEvents: bodyData[options.list.keyFields.linkedIn]
                          ? ""
                          : "none",
                      }}
                      onClick={(e) =>
                        options.list.keyFields.linkedIn
                          ? bodyData[options.list.keyFields.linkedIn]
                            ? window.open(
                                addHTTP(
                                  bodyData[options.list.keyFields.linkedIn],
                                  "_blank"
                                )
                              )
                            : null
                          : null
                      }
                    >
                      <LinkedInIcon
                        sx={{
                          color: bodyData[options.list.keyFields.linkedIn]
                            ? COLORS.black
                            : COLORS.disabledGrey,
                        }}
                        fontSize="large"
                      />
                    </IconButton>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <CardRoot readOnly={readOnly}>
          <StyledCardContent>
            {options?.list?.keyFields?.letterAvatar && (
              <StyledProfileImage>
                {options?.list?.keyFields?.letterAvatar && (
                  <LetterAvatar name={bodyData[options.list.keyFields.title]} />
                )}
                {!readOnly && (
                  <StyledXMargin
                    onClick={() => removeItem(null, bodyData.index)}
                  >
                    <XIcon />
                  </StyledXMargin>
                )}
              </StyledProfileImage>
            )}
            {options?.list?.keyFields?.title && (
              <StyledName>{bodyData[options.list.keyFields.title]}</StyledName>
            )}
            {options?.list?.keyFields?.description ? (
              <StyledDescription>
                {bodyData[options.list.keyFields.description]}
              </StyledDescription>
            ) : (
              <StyledDescription></StyledDescription>
            )}
          </StyledCardContent>
        </CardRoot>
      );
    }
  };

  if (!selectedItems || selectedItems.length < 1) {
    return (
      <StyledBox boxHeight={`${state.cardHeight}px`} readOnly={readOnly}>
        <PlaceholderText>
          {readOnly
            ? options?.labels?.keyItemsPlaceholderReadOnly
            : options?.labels?.keyItemsPlaceholder}
        </PlaceholderText>
      </StyledBox>
    );
  } else {
    return (
      <BorderLessConciseTable
        body={body}
        data={selectedItems}
        length={state.cardHeight / 70}
        horizontal={true}
        height={`${state.cardHeight}px`}
        width="100%"
        backgroundColor="transparent"
        setPickedArray={setSelectedItems}
      />
    );
  }
};

export default SelectedItems;
