import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getContact,
  saveContact,
  saveAvatar,
} from "../../../data/actions/contact";
import { contactSelector } from "../../../data/selectors/contact";
import Select from "../../../components/selectField";
import { LargeText } from "../styles";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

import {
  FieldArea,
  StyledRow,
  EditBadge,
  ProfilePicture,
  SetupContainer,
} from "./styles";

import edit from "../../../assets/dashboardIcons/editBadge.svg";
import { formatPhoneNumber } from "../../../common/formatPhoneNumber";
import { phoneRegex } from "../../../common/regex";
import Input from "../../../components/inputField";
import { FloatingButton } from "../../../components/floatingButton";
import { Badge } from "@mui/material";
import { LargeLetterAvatar } from "./LargeLetterAvatar";
import { IconButton } from "@material-ui/core";
import MobileSaveButtonIcon from "../../../assets/dashboardIcons/mobileSaveButtonIcon";
import countriesInArray from "../../../assets/locale/countriesInArray";
import { getCountryName } from "../../../common/countryName";
import { getInstitutionProfileInfo } from "data/actions/serviceProvider";
import { institutionProfileInfoSelector } from "data/selectors/serviceProvider";

const EditBadgeContent = ({ inputFile, isMobile }) => {
  const dispatch = useDispatch();
  const clickUploadFile = () => {
    inputFile.current.click();
  };

  const savePhoto = (file) => {
    let formData = new FormData();
    formData.append("avatar", file);
    dispatch(saveAvatar(formData));
  };

  return (
    <>
      <label htmlFor="contained-button-file">
        <input
          type="file"
          id="contained-button-file"
          style={{ display: "none" }}
          ref={inputFile}
          accept=".jpg,.jpeg,.png, image/jpeg,image/png"
          onClick={(e) => {
            e.target.value = null;
            clickUploadFile();
          }}
          onChange={(e) => {
            if (
              e.target.files[0].type === "image/jpeg" ||
              e.target.files[0].type === "image/png"
            ) {
              savePhoto(e.target.files[0]);
            }
          }}
        />
        <EditBadge src={edit} isMobile={isMobile} />
      </label>
    </>
  );
};
export const ContactInfo = ({
  isMobile,
  setOpenSuccessPopup,
  currentDashboardUrl,
  institution_id,
}) => {
  const dispatch = useDispatch();
  let contactData = useSelector(contactSelector);
  const institutionContactData = useSelector(institutionProfileInfoSelector);

  if (currentDashboardUrl === "/service-provider-dashboard") {
    contactData = institutionContactData;
  }
  const [data, setData] = useState({ ...contactData });
  const inputFile = useRef(null);
  const saveButtonRef = useRef();
  const [phoneNumber, setPhoneNumber] = React.useState(data?.phone);

  const handlePhoneNumberChange = (value) => {
    setData({ ...data, phone: value });
  };

  const selectCountry = (value) => {
    if (value) {
      setData({ ...data, country: value });
    }
  };

  useEffect(() => {
    if (
      currentDashboardUrl === "/service-provider-dashboard" &&
      institution_id
    ) {
      dispatch(
        getInstitutionProfileInfo(
          { institution_id: institution_id },
          () => {},
          () => {}
        )
      );
    } else {
      dispatch(getContact());
    }
  }, []);

  useEffect(() => {
    setData({ ...contactData });
  }, [contactData]);

  const handleSave = () => {
    dispatch(
      saveContact(
        data,
        () => {
          setOpenSuccessPopup(true);
        },
        () => {}
      )
    );
  };
  return (
    <SetupContainer isMobile={isMobile}>
      {!isMobile && (
        <FloatingButton
          buttonTitle="Save"
          handleButton={handleSave}
          width="150px"
          height="38px"
          marginRight="0px"
        />
      )}
      <StyledRow justifyContent="space-between" ref={saveButtonRef}>
        <LargeText isMobile={isMobile}>
          {isMobile
            ? "Edit Contact Information"
            : "Edit your Contact Information"}
        </LargeText>
        {isMobile && (
          <IconButton onClick={handleSave}>
            <MobileSaveButtonIcon />
          </IconButton>
        )}
      </StyledRow>
      <FieldArea width={isMobile ? "100%" : "66.67%"}>
        <StyledRow justifyContent="center" margin="10px 0px 16px -12px">
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <EditBadgeContent inputFile={inputFile} isMobile={isMobile} />
            }
          >
            {data?.avatar && (
              <ProfilePicture
                src={`data:image/png;base64,${data?.avatar}`}
                isMobile={isMobile}
              />
            )}
            {!data?.avatar && (
              <LargeLetterAvatar
                name={`${data?.first_name}${
                  data?.first_name && data?.last_name ? " " : ""
                }${data?.last_name}`}
                size={isMobile ? 120 : 200}
              />
            )}
          </Badge>
        </StyledRow>
        <Input
          id="inputWrap"
          label="First Name"
          type="text"
          placeholder="First Name"
          labelSize="12px"
          fontSize="18px"
          value={data?.first_name}
          setValue={(val) => {
            setData({ ...data, first_name: val });
          }}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
        />
        <Input
          id="inputWrap last"
          label="Last Name"
          type="text"
          placeholder="Last Name"
          labelSize="12px"
          fontSize="18px"
          value={data?.last_name}
          setValue={(val) => {
            setData({ ...data, last_name: val });
          }}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
        />
        {/* <Input
          id="fullWidthInputWrap last"
          label="Phone Number"
          type="text"
          placeholder="(123) 456-7890"
          labelSize="12px"
          fontSize="18px"
          value={formatPhoneNumber(data?.phone)}
          setValue={(val) => {
            setData({ ...data, phone: val });
          }}
          validateFunc={(item) => phoneRegex.test(item)}
          noCheckIcon={true}
        /> */}

        <div style={{ marginTop: "16px", width: "100%" }}>
          <label htmlFor="phone" style={{ fontSize: "12px" }}>
            Phone Number
          </label>
          <div style={{ height: "47px", width: "100%", marginTop: "-10px" }}>
            <PhoneInput
              id="phone"
              country={"ca"}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              style={{ marginTop: "16px", width: "100%", height: "100%" }}
              inputStyle={{
                fontSize: "18px",
                padding: "8px",
                lineHeight: "24px",
                marginLeft: "20px",
                paddingLeft: "28px",
                height: "100%",
                width: "97%",
                marginRight: "20px",
              }}
              dropdownStyle={{ fontSize: "18px", lineHeight: "24px" }}
            />
          </div>
        </div>

        <Input
          id="fullWidthInputWrap last"
          label="Job Title"
          type="text"
          placeholder="i.e.: Chief Financial Officer"
          labelSize="12px"
          fontSize="18px"
          value={data?.title}
          setValue={(val) => {
            setData({ ...data, title: val });
          }}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
        />
        <div className="fullWidthInputWrap">
          <Select
            style={{ marginRight: "16px" }}
            label="Country"
            options={countriesInArray.map((country) => ({
              value: country.countryCode,
              label: country.countryName,
            }))}
            value={getCountryName(data?.country)}
            setValue={(value) => {
              selectCountry(value);
            }}
            validateFunc={(item) => Boolean(item)}
            noCheckIcon={true}
            showDropdownIndicator={true}
          />
        </div>
      </FieldArea>
    </SetupContainer>
  );
};
