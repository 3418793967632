import React from 'react';
import additional from './value_grey.svg'

const Value_grey = ({ isMobile }) => {
  return (
    <img width={isMobile ? "36" : "56"} height={isMobile ? "36" : "56"} src={additional} />
  )
};

export default Value_grey;
