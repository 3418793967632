import axios from 'axios';
import { API_HOST } from '../constants';

let headers = {};
const getAuth = () => {
  const authData = localStorage.getItem("Authorization");
  if (authData) {
    headers = { Authorization: authData };}
};


export const filterReference = async (query) => {
  getAuth();
  return await axios.get(`${API_HOST}/reference/yours?filter=${query}`, {headers})
                    .then((res) => res.data)
                    .catch((err) => {});
};

export const getCompanyReferenceInfo = async (onSuccess, onFailure) => {
  getAuth();
  return await axios.get(`${API_HOST}/reference/company/get`, {headers})
                      .then((res) => onSuccess(res.data))
                      .catch((err) => onFailure(err.response));
};

export const getTopProjects = async (onSuccess, onFailure) => {
  getAuth();
  return await axios.get(`${API_HOST}/reference/top/projects`, {headers})
                      .then((res) => onSuccess(res.data))
                      .catch((err) => onFailure(err.response));
};

export const getTopPersonnel = async (onSuccess, onFailure) => {
  getAuth();
  return await axios.get(`${API_HOST}/reference/top/personnel`, {headers})
                      .then((res) => onSuccess(res.data))
                      .catch((err) => onFailure(err.response));
};

export const getProjectReferees = async (id, onSuccess, onFailure) => {
  getAuth();
  return await axios.get(`${API_HOST}/reference/referees/project?id=${id}`, {headers})
                      .then((res) => onSuccess(res.data))
                      .catch((err) => onFailure(err.response));
};

export const getGenericReferees = async (query, onSuccess, onFailure) => {
  getAuth();
  return await axios.get(`${API_HOST}/reference/referees/generic?filter=${query}`, {headers})
                      .then((res) => onSuccess(res.data))
                      .catch((err) => onFailure(err.response));
};

export const sendProjectReferenceRequests = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios.post(`${API_HOST}/reference/requests/project`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      } else {
        // Handle other error cases or log the error
        console.error("Error:", err);
      }
    });
}

export const sendGenericReferenceRequests = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios.post(`${API_HOST}/reference/requests/generic`, data, {headers})
                          .then((res) => onSuccess(res.data))
                          .catch((err) =>  {
                            if (err.response.status === 401) {
                              localStorage.removeItem("Authorization");
                              dispatchEvent(new Event("storage"));
                            }
                            onFailure(err.response)
                          });
}

export const addAndSendNewGeneric = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios.post(`${API_HOST}/reference/requests/generic/new`, data, {headers})
                          .then((res) => onSuccess(res.data))
                          .catch((err) =>  {
                            if (err?.response?.status === 401) {
                              localStorage.removeItem("Authorization");
                              dispatchEvent(new Event("storage"));
                            }
                            onFailure(err.response)
                          });
  }

export const checkReferenceToken = async (token, onSuccess, onFailure) => {
  headers['Content-Type']="application/json"
  return await axios.post(`${API_HOST}/reference/provide/${token}`, {headers})
                        .then((res) => onSuccess(res.data))
                        .catch((err) => onFailure(err.response));
}


export const referenceRedirectTo = async (data, onSuccess, onFailure) => {
  headers['Content-Type'] = "application/json"
  return await axios.post(`${API_HOST}/reference/redirect/to`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => onFailure(err.response));
}


export const givenReference = async (token, onSuccess, onFailure) => {
  headers['Content-Type']="application/json"
  return await axios.post(`${API_HOST}/reference/given/${token}`, {headers})
                        .then((res) => onSuccess(res.data))
                        .catch((err) => onFailure(err.response));
}


export const sendReference = async (token, data, onSuccess, onFailure) => {
  headers['Content-Type']="application/json"
  return await axios.post(`${API_HOST}/reference/receive/${token}`, data, {headers})
                        .then((res) => onSuccess(res.data))
                        .catch((err) => onFailure(err.response));
}
export const changeReferenceStatus = async (data, onSuccess, onFailure) => {
  headers['Content-Type']="application/json"
  return await axios.post(`${API_HOST}/reference/changestatus`, data, {headers})
                        .then((res) => onSuccess(res.data))
                        .catch((err) => onFailure(err.response));
}

export const getAwardsPoint = async (companyId, onSuccess, onFailure) => {
  getAuth();
  return await axios.get(`${API_HOST}/reference/awards/${companyId}`, {headers})
                      .then((res) => onSuccess(res.data))
                      .catch((err) => onFailure(err.response));
};

export const getScores = async (companyId, onSuccess, onFailure) => {
  getAuth();
  return await axios.get(`${API_HOST}/reference/scores/${companyId}`, {headers})
                      .then((res) => onSuccess(res.data))
                      .catch((err) => onFailure(err.response));
};

export const referenceComment = async (data, onSuccess, onFailure) => {
  headers['Content-Type']="application/json"
  return await axios.post(`${API_HOST}/reference/comment`, data, {headers})
                        .then((res) => onSuccess(res.data))
                        .catch((err) => onFailure(err.response));
}

export const deleteComment = async (id, onSuccess, onFailure) => {
  headers['Content-Type']="application/json"
  return await axios.delete(`${API_HOST}/reference/comment/${id}`, {headers})
                        .then((res) => onSuccess(res.data))
                        .catch((err) => onFailure(err.response));
}

export const sendProjectReferenceRequestsFromThirdParty = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios.post(`${API_HOST}/reference/thirdparty/requests/project`, data, {headers})
                          .then((res) => onSuccess(res.data))
                          .catch((err) =>  {
                            if (err.response.status === 401) {
                              localStorage.removeItem("Authorization");
                              dispatchEvent(new Event("storage"));
                            }
                            onFailure(err.response)
                          });
}

export const filterReferenceForThirdParty = async (institutionId, onSuccess, onFailure) => {
  getAuth();
  return await axios.get(`${API_HOST}/reference/thirdparty/ecqs/${institutionId}`, {headers})
                    .then((res) => onSuccess(res.data))
                    .catch((err) => onFailure(err.response));
};


export const getCompanyReferenceInfoForThirdParty = async (institutionId, onSuccess, onFailure) => {
  getAuth();
  return await axios.get(`${API_HOST}/reference/company/get/${institutionId}`, {headers})
                      .then((res) => onSuccess(res.data))
                      .catch((err) => onFailure(err.response));
};

