import React, { useEffect, useState } from "react";
import PledgxLogo from "../../assets/common/pledgxLogo";
import { FunctionalButton } from "../functionalButton";
import CloseIcon from "@material-ui/icons/Close";
import { CloseButton } from "./styles";
import updateObjectByPath from "../../common/updateObjectByPath";

import { PopupContent, PopupTitle, PopupFields, ButtonWrap } from "./styles";
import { Step1 } from "./step1";
import { Step2 } from "./step2";
import { Step3 } from "./step3";

function SwitchComponents({ active, children }) {
  return children.filter((child) => child.props.step === active);
}

export const PopupFieldsContent = ({
  setOpenPopup,
  data,
  setData,
  handleButtonFunc,
  isMobile,
  popupTitleType,
}) => {
  const [disabled, setDisable] = useState(true);
  const [addData, setAddData] = useState({ ...data });
  const [editIndex, setEditIndex] = useState(null);

  const [step, setStep] = useState(0);

  const onChangeData = (attribute, val) => {
    const updatedData = { ...addData };
    updateObjectByPath(updatedData, attribute, val);
    setAddData({ ...updatedData });
  };

  const handleNext = () => {
    if (step < 2) {
      setStep(step + 1);
    } else {
      // let updatedFinancialData = [];
      // if (data) {
      //   updatedFinancialData = [...data];
      // }
      // updatedFinancialData.unshift({...addData})
      // setAddData({...updatedFinancialData})
      if (handleButtonFunc) {
        handleButtonFunc(addData);
      }

      setOpenPopup(false);
    }
  };

  useEffect(() => {
    if (
      addData?.company_name?.length > 0 &&
      addData?.company?.address?.length > 0 &&
      addData?.company?.city?.length > 0 &&
      addData?.company?.postal?.length > 0 &&
      addData?.company?.province?.length > 0
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [addData]);

  return (
    <>
      <PopupContent>
        <CloseButton
          aria-label="close"
          onClick={() => {
            setOpenPopup(false);
            setEditIndex(null);
          }}
        >
          <CloseIcon />
        </CloseButton>
        <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
        <PopupTitle isMobile={isMobile}>
          {popupTitleType} Information
        </PopupTitle>

        <PopupFields isMobile={isMobile}>
          <SwitchComponents active={step}>
            <Step1
              step={0}
              isMobile={isMobile}
              setDisable={setDisable}
              addData={addData}
              setAddData={onChangeData}
            />
            <Step2
              step={1}
              isMobile={isMobile}
              addData={addData}
              setDisable={setDisable}
              setAddData={onChangeData}
            />
            <Step3
              step={2}
              isMobile={isMobile}
              addData={addData}
              setAddData={onChangeData}
            />
          </SwitchComponents>
        </PopupFields>
        <ButtonWrap>
          <FunctionalButton
            width="300px"
            marginTop="16px"
            marginRight="0px"
            handleButton={handleNext}
            buttonTitle={step < 2 ? "NEXT" : "SAVE"}
            //disabled={disabled}
          />
        </ButtonWrap>
      </PopupContent>
    </>
  );
};
