import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  ScreenRoot,
  DetailedFormContainer,
  LargeText,
  MediumText,
} from "../styles";
import { PersonnelList } from "./PersonnelList";
import { filterPersonnels } from "../../../api/prequalify";
import { PersonnelRecentCard } from "./RecentCard";
import { SearchBar } from "./SearchBar";
import { FunctionalButton } from "../../../components/functionalButton/index";
import {
  DetailContent,
  DetailArea,
  HeaderArea,
  TouchScrollDiv,
} from "./styles";
import { AddPersonnelPopup } from "../../../components/addPersonnelPopup";
import { savePersonnel, checkPersonnelLimit } from "../../../api/dashboard";
import {
  getSinglePersonnel,
  deleteSinglePersonnel,
} from "../../../api/prequalify";
import AddNewButtonIcon from "../../../assets/dashboardIcons/addNewButtonIcon";
import { IconButton } from "@material-ui/core";
import { AlertPopup } from "components/alertPopup/alertPopup";
import { useSelector } from "react-redux";
import { contactSelector } from "../../../data/selectors/contact";
import { useLocation } from "react-router";
import { institutionProfileInfoSelector } from "data/selectors/serviceProvider";

function SeeAllPersonnel() {
  const location = useLocation();
  const institution_id = location?.state?.institution_id || "";
  const currentDashboardUrl = localStorage.getItem("current_dashboard_url");
  const isMobile = useMediaQuery({ maxWidth: 480 });
  return (
    <ScreenRoot isMobile={isMobile}>
      <Personnel
        institution_id={institution_id}
        currentDashboardUrl={currentDashboardUrl}
      />
    </ScreenRoot>
  );
}

export default SeeAllPersonnel;

export const Personnel = ({
  narrowCard,
  noWidthChange,
  currentDashboardUrl,
  institution_id,
}) => {
  const [query, setQuery] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [filteredData, setFilteredData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [individual, setIndividual] = useState("");
  const [internalSearchResults, setInternalSearchResults] =
    useState(filteredData);
  const [openExceedPopup, setOpenExceedPopup] = useState(false);
  let contactData = useSelector(contactSelector);
  const institutionContactData = useSelector(institutionProfileInfoSelector);

  if (currentDashboardUrl === "/service-provider-dashboard") {
    contactData = institutionContactData;
  }

  const personnelLimit = contactData?.user_access_control?.number_of_personnel;
  const fileLimitCount = contactData?.user_access_control?.file_limit_count;
  const fileLimitSize = contactData?.user_access_control?.file_limit_size;

  const fileLimitSizeMB = (fileLimitSize / (1024 * 1024)).toFixed(2);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFilter = async (filterQuery) => {
    let data = {};

    if (
      currentDashboardUrl === "/service-provider-dashboard" &&
      institution_id
    ) {
      data = { institution_id: institution_id };
    }

    await filterPersonnels(filterQuery, data)
      .then((data) => {
        setFilteredData(data);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    handleFilter(query);
  }, []);

  // add button
  const handleAdd = () => {
    if (filteredData && filteredData.length >= personnelLimit) {
      setOpenExceedPopup(true);
    } else {
      setOpenPopup(true);
    }
    // checkPersonnelLimit(() => setOpenPopup(true), () => setOpenExceedPopup(true))
  };

  const addPersonnel = (data) => {
    if (
      currentDashboardUrl === "/service-provider-dashboard" &&
      institution_id
    ) {
      data.append(
        "institution_id",
        JSON.stringify({
          institution_id: institution_id,
        })
      );
    }
    savePersonnel(data, onSuccess, onFailure);
  };

  const onSuccess = (res) => {
    setIndividual("");
    setQuery("");
    handleFilter("");
  };

  const onFailure = (error) => {
    if (error?.status == 409) {
      setHasError(true);
      setErrorMessage(error?.data?.message);
    }
  };

  useEffect(() => {
    if (!openPopup) {
      setIndividual("");
    }
  }, [openPopup]);

  //three dots button
  const editPersonnel = (id) => {
    getSinglePersonnel(id, onSuccessEdit, () => { });
  };

  const onSuccessEdit = (res) => {
    setIndividual(res);
    setOpenPopup(true);
  };

  const deletePersonnel = (id) => {
    deleteSinglePersonnel(id, onSuccessDelete, () => { });
  };

  const onSuccessDelete = (res) => {
    setQuery("");
    handleFilter("");
  };

  return (
    <DetailedFormContainer isMobile={isMobile} noWidthChange={noWidthChange}>
      <HeaderArea>
        <LargeText isMobile={isMobile}>All Company Personnel</LargeText>
        {isMobile && (
          <IconButton onClick={handleAdd}>
            <AddNewButtonIcon />
          </IconButton>
        )}
        {!isMobile && (
          <HeaderArea width="50%">
            <FunctionalButton
              buttonTitle="Add Personnel"
              handleButton={handleAdd}
              width="40%"
            />
            <SearchBar
              filteredData={filteredData}
              setInternalSearchResults={setInternalSearchResults}
              query={query}
              setQuery={setQuery}
              internalSearch={true}
            />
          </HeaderArea>
        )}
      </HeaderArea>
      {/* {isMobile && (
        <TouchScrollDiv>
          {filteredData?.length > 0 &&
            filteredData.slice(0, 4).map((item, i) => {
              return (
                <PersonnelRecentCard
                  item={item}
                  key={i}
                  editFunc={editPersonnel}
                  deleteFunc={deletePersonnel}
                  narrowCard={narrowCard}
                  isMobile={isMobile}
                />
              );
            })}
        </TouchScrollDiv>
      )}
      {!isMobile && (
        <DetailArea>
          <MediumText>Recently Added</MediumText>
          <DetailContent>
            {filteredData?.length > 0 &&
              filteredData.slice(0, 4).map((item, i) => {
                return (
                  <PersonnelRecentCard
                    item={item}
                    key={i}
                    editFunc={editPersonnel}
                    deleteFunc={deletePersonnel}
                    narrowCard={narrowCard}
                    isMobile={isMobile}
                  />
                );
              })}
          </DetailContent>
        </DetailArea>
      )} */}
      <DetailArea>
        {isMobile && (
          <SearchBar
            filteredData={filteredData}
            setInternalSearchResults={setInternalSearchResults}
            query={query}
            setQuery={setQuery}
            searchWidth="95%"
            internalSearch={true}
          />
        )}
        <PersonnelList
          filteredData={
            query && query !== "" ? internalSearchResults : filteredData
          }
          editFunc={editPersonnel}
          deleteFunc={deletePersonnel}
          isMobile={isMobile}
        />
      </DetailArea>
      <AddPersonnelPopup
        setOpenDialog={setOpenPopup}
        openDialog={openPopup}
        data={individual}
        setData={setIndividual}
        handleButtonFunc={addPersonnel}
        isMobile={isMobile}
        fileLimitCount={fileLimitCount}
        fileLimitSizeMB={fileLimitSizeMB}
        institution_id={institution_id}
      />
      <AlertPopup
        openPopup={openExceedPopup}
        setOpenPopup={setOpenExceedPopup}
        isMobile={isMobile}
        headerText="You have exceeded the personnel limit."
        subHeaderText={`You can add a maximum of ${personnelLimit} personnel.`}
        showButton={true}
      />

      <AlertPopup
        openPopup={hasError}
        setOpenPopup={setHasError}
        isMobile={isMobile}
        headerText="Personnel Add/Edit Alert"
        subHeaderText={`${errorMessage}`}
        showButton={false}
      />
    </DetailedFormContainer>
  );
};
