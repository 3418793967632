import React, { useEffect, useState } from "react";
import { StyledTableCell } from "../../../components/filesListTable/styles";
import ConciseTable from "../../../components/filesListTable/conciseTable";
import { ThreeDotsPopupMenu } from "../../../components/threeDotsPopupMenu";
import { getCCDCs } from "../../../api/prequalify";
import { useHistory } from "react-router-dom";

import {
  StyledProjectSelectionContainer,
  MobileColumnContent,
  MobileColumnText,
  StyledDownloadedContainer,
  StyledReviewedContainer,
  StyledDraftContainer,
} from "./styles";

import { truncateString } from "../../../common/truncateString";

export const CQSList = ({ filteredData, editFunc, deleteFunc, isMobile }) => {
  const headers = [
    { title: "ID", width: "5%" },
    { title: "Project Title", width: "35%" },
    { title: "Number", width: "15%" },
    { title: "Location", width: "25%" },
    { title: "Status", align: "center", width: "10%" },
    { title: "" },
  ];

  // const [data, setData] = useState(null);
  let history = useHistory();

  // const ccdcList = async () => {
  //   await getCCDCs()
  //     .then((data) => {
  //       setData(data);
  //     })
  //     .catch((err) => {});
  // };

  // useEffect(() => {
  //   ccdcList();
  // }, []);

  const handleOnboarding = () => {
    history.push("/contractor-dashboard");
  };
  const handleReviewEcqs = (id) => {
    history.push("/prequalify/preview/", {
      section: "ecqs",
      action: "edit",
      ccdcId: id,
    });
  };

  const body = (bodyData) => {
    return (
      <>
        {isMobile && (
          <StyledTableCell component={"tr"} width="90%">
            <MobileColumnContent direction="column">
              <MobileColumnText>
                {truncateString(bodyData.project_title, 28)}
              </MobileColumnText>
              <MobileColumnText fontSize="12px" fontWeight="400">
                {truncateString(bodyData.project_location, 30)}
              </MobileColumnText>
            </MobileColumnContent>
          </StyledTableCell>
        )}
        {!isMobile && (
          <>
            <StyledTableCell component={"tr"} width="5%">
              {bodyData.ccdcId}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="35%">
              {bodyData.project_title}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="15%">
              {bodyData.project_number}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="25%">
              {bodyData.project_location}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="10%">
              {bodyData.status === null ? (
                <StyledDraftContainer>Draft</StyledDraftContainer>
              ) : bodyData.status === "Reviewed" ? (
                <StyledReviewedContainer>Reviewed</StyledReviewedContainer>
              ) : bodyData.status === "Submitted" ? (
                <StyledDownloadedContainer>Submitted</StyledDownloadedContainer>
              ) : bodyData.status === "Downloaded" ? (
                <StyledDownloadedContainer>
                  Downloaded
                </StyledDownloadedContainer>
              ) : bodyData.status === "Prequalified" ? (
                <StyledDownloadedContainer>
                  Prequalified
                </StyledDownloadedContainer>
              ) : (
                ""
              )}
            </StyledTableCell>
          </>
        )}
        <StyledTableCell component={"tr"} align="right">
          <ThreeDotsPopupMenu
            item={{ ...bodyData, id: bodyData.ccdcId }}
            removeEdit={"cloneCcdc"}
            data={filteredData}
            handleSeeAll={handleOnboarding}
            handleModify={editFunc}
            handleDelete={deleteFunc}
            handleReviewEcqs={handleReviewEcqs}
          />
        </StyledTableCell>
      </>
    );
  };

  return (
    <StyledProjectSelectionContainer isMobile={isMobile}>
      <ConciseTable
        headers={isMobile ? "" : headers}
        // length={isMobile ? 5 : 5.5}
      />
      <ConciseTable
        // headers={isMobile ? "" : headers}
        // length={isMobile ? 5 : 5.5}
        body={body}
        data={filteredData}
      />
    </StyledProjectSelectionContainer>
  );
};
