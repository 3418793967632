import React, { useRef } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { FunctionalButton } from "components/functionalButton";
import { ExportIcon } from "assets/common/ExportIcon";
import './styles.css';


const ExportComponent = ({ fileName, sheetName, tableId }) => {
  const excelRef = useRef();
  const exportToCSV = () => {
    excelRef.current.handleDownload();
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <FunctionalButton
        buttonTitle="EXPORT"
        handleButton={exportToCSV}
        width="200px"
        height="48px"
        marginRight="0"
        isReversedColor={true}
        frontIcon={true}
        disabled={false}
        icon={<ExportIcon marginRight="16px" />}
      />

      {/* Hidden ReactHTMLTableToExcel component */}
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        ref={excelRef}
        table={tableId}
        filename={fileName}
        sheet={sheetName}
        buttonText=""
        className="hidden-excel-button"
      />
    </div>
  );
};

export default ExportComponent;
