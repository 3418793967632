
import { MediumText } from "pages/underwriterDashboard/styles.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledContainer } from "pages/underwriters/styles.js";
import { useMediaQuery } from "react-responsive";
import { GoBackButton } from "pages/buyers/components/goBackButton";
import { underwriterPdScoreSelector } from "data/selectors/underwriters";
import { getPdScore } from "data/actions/underwriters";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { StyledTab } from "pages/underwriterDashboard/clientSection/styles.js";
import PositiveAttributes from './positiveAttributes.js'
import NegativeAttributes from './negativeAttributes.js'
import ScatterPlot from './scatterPlot.js';
import SemiCircleGauge from './semiCirclePD.js'
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar.js";

const ProbabilityOfDefault = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const underwriterPdScoreData = useSelector(underwriterPdScoreSelector);
  const [selectTab, setSelectTab] = React.useState('1');
  const [riskValue, setRiskValue] = React.useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [desiredAccountData, setDesiredAccountData] = useState([]);
  let desiredAccountEntry = [];

  const handleChange = (event, newValue) => {
    setSelectTab(newValue);
  };

  useEffect(() => {
    if (underwriterPdScoreData.length < 1) {
      dispatch(getPdScore());
    }
  }, []);


  useEffect(() => {
    if (underwriterPdScoreData) {
      setData(underwriterPdScoreData);
    }
  }, [underwriterPdScoreData]);


  useEffect(() => {
    if (desiredAccountEntry) {

    }
  }, [desiredAccountEntry]);

  useEffect(() => {
    const filteredData = searchQuery ?
      data.filter(item =>
        item.AccountId.toString().toLowerCase().includes(searchQuery.toLowerCase())
      ) : data;
    setSearchResults(filteredData);
  }, [searchQuery, data]);

  const dataCount = searchResults.length;
  const dataTest = searchResults.map(item => ({
    Score: item.Score,
    AnnualGrossContractIncome: item.AnnualGrossContractIncome,
    AccountId: item.AccountId,
    CompanyName: item.CompanyName
  }));

  const handleDataPointClick = (dataPoint) => {
    const desiredAccountId = dataPoint.AccountId;
    desiredAccountEntry = [];
    desiredAccountEntry = data.find(item => item.AccountId === desiredAccountId);
    setDesiredAccountData(desiredAccountEntry);
    setRiskValue(dataPoint);
  };


  return (
    <StyledContainer isMobile={isMobile}>
      <GoBackButton />
      <br />
      <MediumText isMobile={isMobile} style={{ fontSize: '28px', lineHeight: '20px', fontFamily: 'SF Pro Text', color: '#1A051D', marginBottom: '20px', fontWeight: '600' }}>Probability Of Default</MediumText>
      <SearchBar
        filteredData={data}
        setInternalSearchResults={setSearchResults}
        query={searchQuery}
        setQuery={setSearchQuery}
        internalSearch={true}
        searchPlaceHolder={"Search by Account Id"}
      />
      <br />
      <div style={{ display: 'flex' }}>

        <div style={{ flex: '0 0 60%', marginRight: '20px' }}>
          <div>
            <div style={{ backgroundColor: '#FFFFFF', padding: '5px' }}>
              <ScatterPlot data={dataTest} totalAccounts={dataCount} onDataPointClick={handleDataPointClick} />
            </div>
          </div>
        </div>

        <div style={{ flex: '0 0 40%', marginTop: '-30px' }}>

          <div style={{ fontSize: '18px', lineHeight: '20px', fontFamily: 'SF Pro Text', color: '#1A051D', marginBottom: '10px', paddingLeft: '20px' }}>
            Insights & Recommendations
          </div>


          <div style={{ backgroundColor: '#FFFFFF' }}>
            <TabContext value={selectTab}>

              <TabList onChange={handleChange} variant="fullWidth" centered TabIndicatorProps={{ hidden: true }}>
                <StyledTab label="Positive Attributes" value="1" />
                <StyledTab label="Negative Attributes" value="2" />
              </TabList>

              <TabPanel value="1">
                {desiredAccountData &&
                  <PositiveAttributes data={desiredAccountData} />
                }
                {desiredAccountData.length === 0 &&
                  <p>Please select an Account from the graph to see the Positive Attributes</p>
                }
              </TabPanel>
              <TabPanel value="2">
                {desiredAccountData &&
                  <NegativeAttributes data={desiredAccountData} />
                }
                {desiredAccountData.length === 0 &&
                  <p>Please select an Account from the graph to see the Negative Attributes</p>
                }
              </TabPanel>

            </TabContext>
          </div>

          {riskValue &&
            <div style={{ backgroundColor: '#FFFFFF', padding: '5px', marginTop: "20px" }}>
              <SemiCircleGauge data={riskValue} />
            </div>
          }
                    
        </div>
      </div>
    </StyledContainer>
  );
};

export default ProbabilityOfDefault;
