import React from "react";


const PositiveAttributes = ({ data }) => {

  const getPositiveAttributeValue = (id) => {
    const values = {
      1: "This company has been in business for over 10 years",
      2: "They have executed projects at prices as originally awarded, with few change orders",
      3: "Their operations are profitable",
      4: "This company has sufficient liquidity",
      5: "They are adequately capitalised",
      6: "This company provides their financial statements on time (within 90 days)",
      7: "This company reports on the progress of their projects regularly",
      8: "This company typically works with few larger projects which are profitable",
      9: "They are a specialized contractor, operating in a low competition area"
    };
    return values[id] || '';
  };

  const getPositiveValues = () => {
    const dataString = data?.PositiveAttributes;
    if (dataString !== undefined && dataString !== null) {
      try {
        const dataArray = JSON.parse(dataString);
        if (Array.isArray(dataArray)) {
          return dataArray.map((id) => {
            return { key: id, value: getPositiveAttributeValue(id) };
          });
        } else {
          return [];
        }
      } catch (error) {
        return [];
      }
    } else {
      return [];
    }
  };

  const positiveValues = getPositiveValues();

  return (
    <div style={{ backgroundColor: '#FFFFFF', margin: '0px'}}>
      {data && positiveValues.length > 0 && (
        <React.Fragment>
          <ol style={{ fontSize: '15px'}}>
            {positiveValues.map((item) => (
              <li key={item.key}  style={{ marginBottom: '10px' }}>{item.value}.</li> 
            ))}
          </ol>
        </React.Fragment>
      )}
    </div>
  );
};

export default PositiveAttributes;
