import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectDropdown from '../selectDropdown';
import {
  standardCategoriesSubCategoriesListSelector,
  unspcFamilyClassListSelector
} from "data/selectors/user";

import {
  getStandardCategoriesSubCategoriesInfo,
  getUNSPCFamilyClassInfo
} from "data/actions/user";

import {
  StyleList,
  InfoArea,
} from './styles';


export const CategoriesRow = ({
  marginTop,
  data,
  setData,
  index,
  categories,
  mainSelected,
  marginTopSecondary,
  attribute,
  isMobile,
  readOnly,
  initialSubCategory,
  containsUNSPC,
  categoriesStandard,
  categoriesUNSPC
}) => {
  const standardCategoriesSubCategoriesList = useSelector(
    standardCategoriesSubCategoriesListSelector
  );

  const unspcFamilyClassList = useSelector(
    unspcFamilyClassListSelector
  );
  const dispatch = useDispatch();

  const [categoriesStandardData, setCategoriesStandardData] = useState(data?.categories_list || categoriesStandard);
  const [categoriesUNSPCData, setCategoriesUNSPCData] = useState(data?.family_list || categoriesUNSPC);
  let categoriesInfo = containsUNSPC === true ? categoriesUNSPCData : categoriesStandardData;
  const categoriesStandardArray = ['Aggregates', 'Building Services', 'Building Structures & Land', 'Construction', 'Contracted Services', 'Facilities, Redevelopment, Trades, Machinery, Construction, Architectural or Engineering', 'Heavy Construction - Earth Handling Equipment (Industrial)', 'Landscaping & Forestry', 'Oil & Gas Operations', 'Parks', 'Roads - Maintenance, Services and Traffic', 'Trade Services', 'Water and Sewer']

  if (containsUNSPC === undefined) {
    if (categoriesStandardArray.includes(categories?.main_categories)) {
      categoriesInfo = categoriesStandardData;
    } else {
      categoriesInfo = categoriesUNSPCData;
    }
  }

  useEffect(() => {
    if (standardCategoriesSubCategoriesList?.length < 1) {
      dispatch(getStandardCategoriesSubCategoriesInfo());
    }
  }, []);


  useEffect(() => {
    if (unspcFamilyClassList?.length < 1) {
      dispatch(getUNSPCFamilyClassInfo());
    }
  }, []);

  const fetchSubcategoriesOrClass = (selectedMainCategory) => {
    if (containsUNSPC === true) {
      if (unspcFamilyClassList?.length > 0) {
        const selectedFamily = unspcFamilyClassList.find(family => family[selectedMainCategory]);
        if (selectedFamily) {
          setOptions(selectedFamily[selectedMainCategory]);
        } else {
          setOptions([]);
        }
      }
    } else {
      if (standardCategoriesSubCategoriesList?.length > 0) {
        const selectedCategory = standardCategoriesSubCategoriesList.find(category => category[selectedMainCategory]);
        if (selectedCategory) {
          setOptions(selectedCategory[selectedMainCategory]);
        } else {
          setOptions([]);
        }
      }
    }
  };

  const setInitialOption = () => {
    let result = [];
    if (main?.length > 0) {
      if (initialSubCategory?.length > 0) {
        result = initialSubCategory.find(item => item?.main_categories === main);
      }

      if (result) {
        const subCategories = result?.sub_categories;
        return subCategories;
      } else {
        return [];
      }
    }
    return [];
  }

  const setInitialMainOptions = (props) => {
    let temp = props;
    mainSelected?.map((item) => {
      temp = temp.filter(x => x.value !== item || x.value == main);
    })
    return temp;
  }
  const [main, setMain] = useState(categories ? categories.main_categories : '');
  const [sub, setSub] = useState(categories ? categories.sub_categories : '');
  const [options, setOptions] = useState(setInitialOption());
  const [mainOptions, setMainOptions] = useState(setInitialMainOptions(categoriesInfo));
  const [selectedCategories, setSelectedCategories] = useState({ ...categories });
  const [disabled, setDisabled] = useState(options?.length > 0 ? false : true);

  if (!selectedCategories.index) {
    selectedCategories["index"] = index;
  }

  useEffect(() => {
    let tmp = Array.isArray(data?.categories) ? data.categories : [];
    const indexInCategories = tmp.findIndex(x => x.index == index);
    if (indexInCategories !== -1) {
      if (selectedCategories['main_categories']?.length === 0) {
        tmp = tmp.filter(x => x.index !== index);
      } else {
        tmp[indexInCategories] = selectedCategories;
      }
    } else {
      tmp.push(selectedCategories);
    }

    setData(attribute || 'categories', tmp);
  }, [selectedCategories])


  const handleMain = (props) => {
    setMain(props.value);

    if (props.value.length > 0) {
      setSub('');
      setDisabled(false);
      if (props.value) {
        fetchSubcategoriesOrClass(props.value);
      } else {
        setOptions([]);
        setDisabled(true);
        setSub('');
        const tmp = { ...selectedCategories, main_categories: props.value, sub_categories: [] }
        setSelectedCategories(tmp)
        return;
      }
    } else {
      setDisabled(true);
      setOptions([]);
      setSub('');
      setMain('');
      const tmp = { ...selectedCategories, main_categories: props.value, sub_categories: [] }
      setSelectedCategories(tmp);
      return;
    }

    const tmp = { ...selectedCategories, main_categories: props.value }
    setSelectedCategories(tmp)
  }

  const handleSub = (props) => {
    setSub(props.value);
    const tmp = { ...selectedCategories, sub_categories: props.value }
    setSelectedCategories(tmp);
  }

  const handleRemove = () => {
    const updatedCategories = data.categories.filter(category => category.index !== index);
    setData(attribute || 'categories', updatedCategories);
  }

  return (
    <InfoArea isMobile={isMobile} marginTop={marginTop || "-16px"} noJustifyContent={true}>
      <StyleList marginTop={marginTopSecondary} className={"inputWrap"}>
        <SelectDropdown
          placeholder={readOnly ? '' : 'Select one'}
          value={main}
          label={"Categories/Family"}
          options={mainOptions}
          handleSelect={handleMain}
          handleRemove={handleRemove}
          search={true}
          multiple={false}
          width="100%"
          height="50px"
          hasLabel="Category"
          hasData={main?.length > 0}
          clearable={true}
          readOnly={readOnly}
        />
      </StyleList>

      <StyleList marginTop={marginTopSecondary} className={"inputWrap last"}>
        <SelectDropdown
          placeholder={readOnly ? '' : 'Select multiple'}
          value={sub}
          label={containsUNSPC === false ? "Subcategories/Class" : "Subcategories/Class"}
          options={options}
          handleSelect={handleSub}
          search={true}
          multiple={true}
          width="100%"
          height="50px"
          hasLabel="Sub-category"
          hasData={sub?.length > 0}
          disabled={disabled}
          readOnly={readOnly}
        />
      </StyleList>
    </InfoArea>
  )
}
