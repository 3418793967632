import React, { useState } from "react";

import { MediumText, RegularText, StyledBox, SmallText } from "../styles";

import { FunctionalButton } from "../../../components/functionalButton";
import {
  FunctionArea,
  IntroArea,
  StyledTitle,
  ButtonContainer,
  ComingSoonImg,
  ImgDiv,
} from "./styles";

import recommendationComingSoon from "assets/dashboardIcons/recommendationComingSoon.svg";

import EditProfileIcon from "assets/buyers/EditProfileIcon";

export const Recommendations = ({ isMobile, userData }) => {
  const [comingSoon, setComingSoon] = useState(false);
  const handleRec = () => {};

  return (
    <FunctionArea isMobile={isMobile}>
      {isMobile && (
        <StyledBox
          boxHeight="110px"
          borderRadius="15px"
          isMobile={isMobile}
          onClick={handleRec}
          style={{ cursor: "pointer" }}
        >
          {comingSoon && (
            <ImgDiv>
              <ComingSoonImg src={recommendationComingSoon} />
            </ImgDiv>
          )}
          {!comingSoon && <></>}
        </StyledBox>
      )}
      {!isMobile && (
        <>
          <MediumText>Edit Profile</MediumText>
          <StyledBox boxHeight="216px">
            {comingSoon && (
              <ImgDiv style={{ width: "100%" }}>
                <ComingSoonImg src={recommendationComingSoon} />
              </ImgDiv>
            )}
            {!comingSoon && (
              <>
                <IntroArea>
                  <EditProfileIcon />
                </IntroArea>
                <ButtonContainer>
                  <FunctionalButton
                    buttonTitle="Edit Profile"
                    handleButton={handleRec}
                    width="80%"
                    marginRight="0px"
                    isReversedColor={true}
                    hasBoxShadow={true}
                    disabled={true}
                  />
                </ButtonContainer>
              </>
            )}
          </StyledBox>
        </>
      )}
    </FunctionArea>
  );
};
