import React from "react";

import logo_dark from "../../assets/common/full-logo.svg";
import Spinner from "../../components/spinner";

import { Container, Logo } from "./styles";

function Splash() {
  return (
    <Container>
      <Logo src={logo_dark} />
      <Spinner />
    </Container>
  );
};

export default Splash;
