import React, { useState, useEffect } from "react";
import {
  StyledLargeTitle,
  StyledMediumTitle,
  ProjectCardContainer,
} from "../styles";

import { SelectingProjects } from "./components/selectingProjects";

import ProjectCard from "../components/projectCard";
import FilledAccordion from '../../../components/filledAccordion';
import { KeyProjects } from './KeyProjects';
import { NoProjects } from './styles';
import BorderLessConciseTable from "../../../components/filesListTable/borderLessConciseTable";
import { StyledTableCell } from "../../../components/filesListTable/styles";
import { StyledTableCellFirst, StyledUploadedFilesContainer } from "../../buyers/components/additionalFilesUpload/styles";
import { SelectedProjects } from "./components/selectedProjects";
import { AddProjectPopup } from "./addProjectPopup";

export const ResultPanel = ({
  projects,
  setPanel,
  setAddData,
  setProjects,
  setEditIndex,
  setOpenEdit,
}) => {
  const addProject = () => {
    setPanel('add');
  };

  const [openPopup, setOpenPopup] = useState(false);

  return (
    <>
      <StyledLargeTitle>Project Selection</StyledLargeTitle>
      <SelectedProjects
        projects={projects}
        setProjects={setProjects}
      />
      <SelectingProjects
        add={() => setPanel("add")}
        projects={projects}
        setProjects={setProjects}
      />
      <AddProjectPopup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      />
    </>
  )
}
