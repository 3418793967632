export const addRequirement = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "ADD_REQUIREMENT",
    payload: {
      endpoint: "/requirements/add_requirement",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};

export const attachRfpqFile = (data) => (dispatch) => {
  dispatch({
    type: "ATTACH_RFPQ_FILE",
    payload: {
      endpoint: "/rfpq/attach_file",
      method: "POST",
      body: data,
    },
  });
};

export const cancelRfpq = (data) => (dispatch) => {
  dispatch({
    type: "CANCEL_RFPQ",
    payload: {
      endpoint: "/rfpq/cancel_rfpq",
      method: "POST",
      body: data,
    },
  });
};

export const clearInstitutionRfpq = () => (dispatch) => {
  dispatch({
    type: "CLEAR_INSTITUTION_RFPQ",
  });
};

export const createRfpq = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "CREATE_RFPQ",
    payload: {
      endpoint: "/rfpq/create_rfpq",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};

export const deleteRfpq = (data) => (dispatch) => {
  dispatch({
    type: "DELETE_RFPQ",
    payload: {
      endpoint: "/rfpq/delete_rfpq",
      method: "POST",
      body: data,
    },
  });
};

export const getBuyersList = () => (dispatch) => {
  dispatch({
    type: "GET_BUYERS_LIST",
    payload: {
      endpoint: "/buyers",
      method: "GET",
    },
  });
};

export const getContractorsList = (onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "GET_CONTRACTORS_LIST",
    payload: {
      endpoint: "/rfpq/get_contractors_list",
      method: "GET",
    },
    onSuccess,
    onFailed,
  });
};

export const getInstitutionRfpq = (data, onSuccess, onFailed) => (dispatch) => {
  // Gets an RFPQ belonging to the institution
  dispatch({
    type: "GET_INSTITUTION_RFPQ",
    payload: {
      endpoint: "/rfpq/get_institution_rfpq",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};

export const getInstitutionRfpqs = () => (dispatch) => {
  dispatch({
    type: "GET_INSTITUTION_RFPQS",
    payload: {
      endpoint: "/rfpq/get_institution_rfpqs",
      method: "GET",
    },
  });
};

export const getRequirements = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "GET_REQUIREMENTS",
    payload: {
      endpoint: "/requirements/get_requirements",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};

export const getRfpq = (data, onSuccess, onFailed) => (dispatch) => {
  // Gets an RFPQ (if this institution is entitled to view it)
  dispatch({
    type: "GET_RFPQ",
    payload: {
      endpoint: "/rfpq/get_rfpq",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};

export const inviteContractors = (data, onSuccess, onFailed) => (dispatch) => {
  // Invites contractors to an RFPQ, and makes it private
  dispatch({
    type: "INVITE_CONTRACTORS",
    payload: {
      endpoint: "/rfpq/invite_contractors",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};

export const prequalifyContractors =
  (data, onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "PREQUALIFY_CONTRACTORS",
      payload: {
        endpoint: "/rfpq/prequalify_contractors",
        method: "POST",
        body: data,
      },
      onSuccess,
      onFailed,
    });
  };

export const publishRfpq = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "PUBLISH_RFPQ",
    payload: {
      endpoint: "/rfpq/publish_rfpq",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};

export const removePrequalifiedContractor =
  (data, onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "REMOVE_PREQUALIFIED_CONTRACTOR",
      payload: {
        endpoint: "/buyers/prequalified/remove_contractor",
        method: "POST",
        body: data,
      },
      onSuccess,
      onFailed,
    });
  };

export const reviewRfpq = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "REVIEW_RFPQ",
    payload: {
      endpoint: "/rfpq/review_rfpq",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};

export const saveRfpq = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "SAVE_RFPQ",
    payload: {
      endpoint: "/rfpq/save_rfpq",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};
