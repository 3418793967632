import React, { useEffect, useState} from 'react';
import { useMediaQuery } from "react-responsive";
import { StyledSubContainer } from '../styles';
import { SectionTitle } from '../SectionTitle';
import { CheckCard } from './checkCard';
import { COLORS } from '../../../../constants';
import { StyledCardContainer } from './styles';


export const MandatoryRequirement = ({ data, setData }) => {
  const isMobile = useMediaQuery({ maxWidth: 1100 });
  const [pickedData, setPickedData] = useState(data.mandatory_requirements? data.mandatory_requirements : [])

  const requirements = [
    "Submission Form",
    "COR Safety Program – Evidence of Certification",
    "WSIB Certificate or Equivalent",
    "Contractor Qualification Statement (CCDC-11)",
    "Statement of Insurability",
    "Health & Safety Policy and Procedures",
    "Surety’s Letter of Pre-Qualification",
    "Acknowledgement of Receipt of all RFPQ documents and addenda",
    "Letter of Bondability",
  ]

  if(data && !data.mandatory_requirements) {
    data['mandatory_requirements'] = [];
  }

  useEffect (() => {
    data['mandatory_requirements'] = pickedData;
    setData(data);
  }, [pickedData, data]);

  return (
    <StyledSubContainer isMobile={isMobile}>
      <SectionTitle title="Select Mandatory Requirements" color={COLORS.darkGrey2} />
      { data['mandatory_requirements'] && (
        <>
          { requirements.map((req) => { return (
          <StyledCardContainer className="inputWrap">
            <CheckCard text={req} pickedData={pickedData} setPickedData={setPickedData} />
          </StyledCardContainer>
          )})}
        </>
      )}

    </StyledSubContainer>
  )
}
