import React, { useState, useEffect } from "react";
import IncrementalSearchPickList from "components/incrementalSearchPickList";
import { StyledTableCell } from "components/filesListTable/styles";
import { AlertPopup } from "components/alertPopup/alertPopup";

export const SelectingItems = ({
  filterRequest,
  internalSearch,
  items,
  itemLimit,
  selectedIds,
  selectLimit,
  setItems,
  setOpenPopup,
  options,
  rerender,
  selectedItems,
  setRerender,
  setSelectedItems,
}) => {
  const [filteredData, setFilteredData] = useState(items);
  const [openExceedPopup, setOpenExceedPopup] = useState(false);

  useEffect(() => {
    handleFilterItems();
  }, []);

  useEffect(() => {
    updateItems();
  }, [items, selectedIds]);

  /*
  const handleItemSelection = (bodyData) => {
    const filteredTemp = filteredData ? [...filteredData] : [];
    const selectedTemp = selectedItems ? [...selectedItems] : [];

    selectedTemp.unshift({
      id: bodyData.id,
      requirementTitle: bodyData.requirementTitle,
      requirementDescription: bodyData.requirementDescription
    });

    setSelectedItems(selectedTemp);
    filteredTemp.splice(bodyData.index, 1);
    setFilteredData(filteredTemp);
  };
  */

  const handleItemSelection = (bodyData) => {
    if (options?.popupForm?.fields) {
      if (selectLimit && selectedItems && selectedItems.length >= selectLimit) {
        setOpenExceedPopup(true);
      } else {
        setRerender(true);
        const filteredTemp = [...filteredData];
        const selectedTemp = [...selectedItems];
        var newItem = { id: bodyData.id, ...bodyData };
        Object.keys(options.popupForm.fields).map(
          (itemKey) => (newItem[itemKey] = bodyData[itemKey])
        );
        selectedTemp.unshift(newItem);
        setSelectedItems(selectedTemp);
        //setFilteredData(filteredTemp.filter(arrayItem => arrayItem.id !== bodyData.id));
        setRerender(false);
      }
    }
  };

  function updateItems() {
    const filteredTemp = [...items];
    setFilteredData(
      filteredTemp.filter((arrayItem) => !selectedIds[arrayItem.id])
    );
  }

  const handleFilterItems = async () => {
    /*
    FILTER HERE, RETURN DATA!
    await filterPersonnels('')
      .then(data => {
        setItemList(data);
      })
      .catch(err => { });
    */
  };

  const handleAdd = () => {
    if (itemLimit && items && items.length >= itemLimit) {
      setOpenExceedPopup(true);
    } else {
      setOpenPopup(true);
    }
  };

  const body = (bodyData) => {
    if (options?.list?.columns) {
      return (
        <>
          {options.list.columns.map((columnDetails) => (
            <StyledTableCell
              component={"tr"}
              onClick={() => handleItemSelection(bodyData)}
            >
              {bodyData[columnDetails.id]}
            </StyledTableCell>
          ))}
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <IncrementalSearchPickList
        add={handleAdd}
        internalSearch={internalSearch}
        noAddButton={options?.addButton === false}
        noSearch={options?.searchBar === false}
        body={body}
        conciseLength={3.5}
        filterRequest={filterRequest}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        addButtonTitle={options?.labels?.addButtonTitle || "Add"}
        //handleSelected={ () => setSelected(true) }
        placeholder={
          options?.labels?.searchInputPlaceholder || "Search items..."
        }
        marginTop="24px"
        newButtonWidth={options.newButtonWidth}
        hover={true}
        setRerender={setRerender}
        rerender={rerender}
        width="100%"
        paddingRight="0"
        pickedArray={selectedItems}
        disabled={options?.disabled}
      />
      <AlertPopup
        openPopup={openExceedPopup}
        setOpenPopup={setOpenExceedPopup}
        headerText={
          options?.labels?.limitExceededPopupHeader || "Item limit reached"
        }
        subHeaderText={
          options?.labels?.limitExceededPopupText ||
          "Please remove items before adding new ones."
        }
      />
    </>
  );
};
