import React, { useState, useEffect } from "react";
import Input from "../inputField";
import { InfoArea } from "./styles";
import { StyledRow } from "./styles";
import { getCorporates, getPackages, getAgents } from "../../api/external_data";
import Select from "../../components/selectField";
import { getCountryName } from "../../common/countryName";
import countriesInArray from "../../assets/locale/countriesInArray";

export const Step2 = ({ setDisable, addData, setAddData, isMobile }) => {
  const documentPoolSize = ["10MB", "50MB", "100MB", "1024MB(1GB)"];

  return (
    <InfoArea
      isMobile={isMobile}
      marginTop="0px"
      marginLeft="8px"
      isColumn={true}
    >
      {addData && addData?.user_type === "Contractor & Buyer" && (
        <>
          <StyledRow isMobile={isMobile}>
            <Input
              id="inputWrap"
              label="Underwriter Id"
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={addData?.corporate_account_id || ""}
              setValue={(val) => setAddData("corporate_account_id", val)}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noErrorBorder={true}
            />

            <div className="inputWrap">
              <CorporateDropdown data={addData} onChangeData={setAddData} />
            </div>
          </StyledRow>

          <StyledRow isMobile={isMobile}>
            <div className="inputWrap">
              <PackageDropdown data={addData} onChangeData={setAddData} />
            </div>

            <div className="inputWrap">
              <Select
                style={{ marginRight: "16px" }}
                label="Country"
                options={countriesInArray.map((country) => ({
                  value: country.countryCode,
                  label: country.countryName,
                }))}
                value={getCountryName(addData?.country)}
                setValue={(value) => setAddData("country", value)}
                validateFunc={(item) => Boolean(item)}
                noCheckIcon={true}
                showDropdownIndicator={true}
              />
            </div>
          </StyledRow>

          <StyledRow isMobile={isMobile}>
            <Input
              id="inputWrap"
              label="Number of RFP/Q"
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={addData?.user_access_control?.number_of_rfpq || ""}
              setValue={(val) =>
                setAddData("user_access_control.number_of_rfpq", val)
              }
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noErrorBorder={true}
            />

            <div className="inputWrap">
              <Select
                style={{ marginRight: "16px" }}
                label="Document Pool Size"
                options={documentPoolSize?.map((item) => ({
                  value: item,
                  label: item,
                }))}
                value={
                  addData?.user_access_control?.document_pool_size ||
                  documentPoolSize[0]
                }
                setValue={(val) =>
                  setAddData("user_access_control.document_pool_size", val)
                }
                validateFunc={(item) => Boolean(item)}
                noCheckIcon={true}
                showDropdownIndicator={true}
              />
            </div>
          </StyledRow>

          <StyledRow isMobile={isMobile}>
            <Input
              id="inputWrap"
              label="Agent Account Id"
              type="text"
              placeholder="Agent Account Id"
              labelSize="12px"
              fontSize="18px"
              value={addData?.user_agent_account_id || ""}
              setValue={(val) => setAddData("user_agent_account_id", val)}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
            />

            <div className="inputWrap">
              <AgentDropdown data={addData} onChangeData={setAddData} />
            </div>
          </StyledRow>
        </>
      )}

      {addData && addData?.user_type === "Contractor" && (
        <>
          <StyledRow isMobile={isMobile}>
            <Input
              id="inputWrap"
              label="Underwriter Id"
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={addData?.corporate_account_id || ""}
              setValue={(val) => setAddData("corporate_account_id", val)}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noErrorBorder={true}
            />

            <div className="inputWrap">
              <CorporateDropdown data={addData} onChangeData={setAddData} />
            </div>
          </StyledRow>

          <StyledRow isMobile={isMobile}>
            <div className="inputWrap">
              <PackageDropdown data={addData} onChangeData={setAddData} />
            </div>

            <div className="inputWrap">
              <Select
                style={{ marginRight: "16px" }}
                label="Country"
                options={countriesInArray.map((country) => ({
                  value: country.countryCode,
                  label: country.countryName,
                }))}
                value={getCountryName(addData?.country)}
                setValue={(value) => setAddData("country", value)}
                validateFunc={(item) => Boolean(item)}
                noCheckIcon={true}
                showDropdownIndicator={true}
              />
            </div>
          </StyledRow>

          <StyledRow isMobile={isMobile}>
            <Input
              id="inputWrap"
              label="Agent Account Id"
              type="text"
              placeholder="Agent Account Id"
              labelSize="12px"
              fontSize="18px"
              value={addData?.user_agent_account_id || ""}
              setValue={(val) => setAddData("user_agent_account_id", val)}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
            />

            <div className="inputWrap">
              <AgentDropdown data={addData} onChangeData={setAddData} />
            </div>
          </StyledRow>
        </>
      )}

      {addData && addData?.user_type === "Buyer" && (
        <>
          <StyledRow isMobile={isMobile}>
            <Input
              id="inputWrap"
              label="Underwriter Id"
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={addData?.corporate_account_id || ""}
              setValue={(val) => setAddData("corporate_account_id", val)}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noErrorBorder={true}
            />

            <div className="inputWrap">
              <CorporateDropdown data={addData} onChangeData={setAddData} />
            </div>
          </StyledRow>

          <StyledRow isMobile={isMobile}>
            <Input
              id="inputWrap"
              label="Agent Account Id"
              type="text"
              placeholder="Agent Account Id"
              labelSize="12px"
              fontSize="18px"
              value={addData?.user_agent_account_id || ""}
              setValue={(val) => setAddData("user_agent_account_id", val)}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
            />

            <div className="inputWrap">
              <AgentDropdown data={addData} onChangeData={setAddData} />
            </div>
          </StyledRow>

          <StyledRow isMobile={isMobile}>
            <Input
              id="inputWrap"
              label="Number of RFP/Q"
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={addData?.user_access_control?.number_of_rfpq || ""}
              setValue={(val) =>
                setAddData("user_access_control.number_of_rfpq", val)
              }
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noErrorBorder={true}
            />

            <div className="inputWrap">
              <Select
                style={{ marginRight: "16px" }}
                label="Document Pool Size"
                options={documentPoolSize?.map((item) => ({
                  value: item,
                  label: item,
                }))}
                value={
                  addData?.user_access_control?.document_pool_size ||
                  documentPoolSize[0]
                }
                setValue={(val) =>
                  setAddData("user_access_control.document_pool_size", val)
                }
                validateFunc={(item) => Boolean(item)}
                noCheckIcon={true}
                showDropdownIndicator={true}
              />
            </div>
          </StyledRow>

          <StyledRow isMobile={isMobile}>
            <div className="fullWidthInputWrap">
              <Select
                style={{ marginRight: "16px" }}
                label="Country"
                options={countriesInArray.map((country) => ({
                  value: country.countryCode,
                  label: country.countryName,
                }))}
                value={getCountryName(addData?.country)}
                setValue={(value) => setAddData("country", value)}
                validateFunc={(item) => Boolean(item)}
                noCheckIcon={true}
                showDropdownIndicator={true}
              />
            </div>
          </StyledRow>
        </>
      )}

      {addData &&
        (addData?.user_type === "Underwriter" ||
          addData?.user_type === "Agent/Broker") && (
          <>
            <StyledRow isMobile={isMobile}>
              <Input
                id="inputWrap"
                label="Underwriter Id"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={addData?.corporate_account_id || ""}
                setValue={(val) => setAddData("corporate_account_id", val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noErrorBorder={true}
              />

              <div className="inputWrap">
                <CorporateDropdown data={addData} onChangeData={setAddData} />
              </div>
            </StyledRow>

            <StyledRow isMobile={isMobile}>
              <div className="fullWidthInputWrap">
                <Select
                  style={{ marginRight: "16px" }}
                  label="Country"
                  options={countriesInArray.map((country) => ({
                    value: country.countryCode,
                    label: country.countryName,
                  }))}
                  value={getCountryName(addData?.country)}
                  setValue={(value) => setAddData("country", value)}
                  validateFunc={(item) => Boolean(item)}
                  noCheckIcon={true}
                  showDropdownIndicator={true}
                />
              </div>
            </StyledRow>
          </>
        )}
    </InfoArea>
  );
};

const PackageDropdown = ({ data, onChangeData }) => {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    getPackages()
      .then((res) => {
        setPackages(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  return (
    <Select
      style={{ marginRight: "16px" }}
      label="Choose Your Package"
      options={packages.map((item) => ({
        value: item.package_name,
        label: item.package_name,
      }))}
      value={data?.user_access_control?.package_name}
      setValue={(val) => onChangeData("user_access_control.package_name", val)}
      validateFunc={(item) => Boolean(item)}
      noCheckIcon={true}
      showDropdownIndicator={true}
    />
  );
};

const CorporateDropdown = ({ data, onChangeData }) => {
  const [corporates, setCorporates] = useState([]);

  useEffect(() => {
    getCorporates()
      .then((res) => {
        setCorporates(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  return (
    <Select
      style={{ marginRight: "16px" }}
      label="Choose Your Underwriter Name"
      options={corporates.map((item) => ({
        value: item.corporate_name,
        label: item.corporate_name,
      }))}
      value={data?.corporate_name}
      setValue={(val) => onChangeData("corporate_name", val)}
      validateFunc={(item) => Boolean(item)}
      noCheckIcon={true}
      showDropdownIndicator={true}
    />
  );
};

const AgentDropdown = ({ data, onChangeData }) => {
  const [agent, setAgent] = useState([]);

  useEffect(() => {
    getAgents()
      .then((res) => {
        setAgent(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  return (
    <Select
      style={{ marginRight: "16px" }}
      label="Choose Your Agent Name"
      options={agent?.map((item) => ({
        value: item.agent_name,
        label: item.agent_name,
      }))}
      value={data?.user_agent_name}
      setValue={(val) => onChangeData("user_agent_name", val)}
      validateFunc={(item) => Boolean(item)}
      noCheckIcon={true}
      showDropdownIndicator={true}
    />
  );
};
