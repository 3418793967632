import get from "lodash/get";

const initialState = {
  status: 'idle',
  error: null,
  user: null,
  concierge: {}
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case 'ACTIVATE_CONCIERGE_SERVICE': {
      const payload = get(action, 'payload');
      return {
        ...state,
        concierge: {
          ...state.concierge,
          lastActivatedEmail: payload.customer_email,
          code: payload.code,
          ...payload
        }
      };
    };
    case "REGISTRATE_USER": {
      return {
        ...state,
      };
    }
    case "CHECK_AUTH": {
      return {
        ...state,
      };
    }
    case "GET_CURRENT_USER": {
      return {
        ...state.user,
      };
    }
    case "SET_CURRENT_USER": {
      const payload = get(action, "payload");

      return {
        ...state,
        user: payload,
      };
    }
    case "LOGIN_USER": {
      const payload = get(action, "payload");
      localStorage.setItem("Authorization", payload.session_id);

      return {
        ...state,
      };
    }
    case "LOGOUT_USER": {
      return {
        ...state,
        user: {},
      };
    }
    case "PREQUALIFY_USER": {
      const payload = get(action, "payload");

      return {
        ...state,
        qualification: payload,
      };
    };
    case 'GENERATE_CONCIERGE_CODE': {
      const payload = get(action, 'payload');
      return {
        ...state,
        concierge: {
          ...state.concierge,
          ...payload
        }
      };
    };
    case 'GET_CONCIERGE_SERVICE_STATUS': {
      const payload = get(action, 'payload');
      return {
        ...state,
        concierge: payload
      };
    };
    case "GET_STANDARD_CATEGORIES_INFO": {
      const payload = get(action, "payload");
      return {
        ...state,
        standardCategoriesList: payload,
      };
    };
    case "GET_STANDARD_SUB_CATEGORIES_INFO": {
      const payload = get(action, "payload");
      return {
        ...state,
        standardCategoriesSubCategoriesList: payload,
      };
    };
    case "GET_UNSPC_FAMILY_INFO": {
      const payload = get(action, "payload");
      return {
        ...state,
        unspcFamilyList: payload,
      };
    };
    case "GET_UNSPC_FAMILY_CLASS_INFO":  {
      const payload = get(action, "payload");
      return {
        ...state,
        unspcFamilyClassList: payload,
      };
    };
    case "GET_PREQUALIFICATION_INFO": {
      const payload = get(action, "payload");
      return {
        ...state,
        qualification: payload,
      };
    };
    case "GET_SELECTED_CCDC_INFO": {
      const payload = get(action, "payload");
      return {
        ...state,
        qualification: payload,
      };
    };
    case 'INVALIDATE_CONCIERGE_CODE': {
      return {
        ...state,
        concierge: {
          ...state.concierge,
          code: null,
          code_active: false
        }
      };
    };
    case "SEND_CCDC": {
      const payload = get(action, "payload");
      const error = get(action, "error");
      if (error) {
        return {
        ...state,
        status: "failed",
        error: error,
        };
      }

      return {
        ...state,
        status: "succeeded",
        qualification: payload,
      };
    }

    case "RESET_CCDC": {
      return {
        ...state,
        status: "idle",
        error: null,
      };
    }

    case "WAIT_UPLOAD_CCDC": {
      return {
        ...state,
        status: "loading",
      };
    }

    case "GET_CCDC": {
      const payload = get(action, "payload");
      const updatedQualification = { ...state.qualification, ccdc: payload };

      return {
        ...state,
        qualification: updatedQualification,
      }
    }

    case "GET_FILLABLE_CCDC": {
      const payload = get(action, "payload");
      const updatedQualification = { ...state.qualification, fillable_ccdc: payload };

      return {
        ...state,
        qualification: updatedQualification,
      }
    }
    default:
        return state;
    }
};
