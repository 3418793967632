import React, { useState } from "react";

import { MediumText, StyledBox } from "../styles";

import { FunctionalButton } from "../../../components/functionalButton";
import {
  FunctionArea,
  IntroArea,
  ButtonContainer,
  ComingSoonImg,
  ImgDiv,
} from "./styles";

import docComingSoon from "assets/dashboardIcons/docComingSoon.svg";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { contactSelector } from "data/selectors/contact";
import WipIcon from "assets/dashboardIcons/wipIcon";
import { AlertPopup } from "components/alertPopup/alertPopup";

export const Wip = ({ isMobile }) => {
  let history = useHistory();

  const [comingSoon, setComingSoon] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const contactData = useSelector(contactSelector);

  const seeWorkInProgress = () => {
    if (
      contactData?.user_access_control?.package_name === "Basic" ||
      contactData?.user_access_control?.package_name === "Free"
    ) {
      setOpenPopup(true);
    } else {
      history.push("/client/wip", {
        company_name: contactData?.company?.name,
        account_id: contactData?.company?.id,
      });
    }
  };

  return (
    <FunctionArea isMobile={isMobile}>
      {isMobile && (
        <StyledBox
          boxHeight="110px"
          borderRadius="15px"
          isMobile={isMobile}
          onClick={seeWorkInProgress}
          style={{ cursor: "pointer" }}
        >
          {comingSoon && (
            <ImgDiv>
              <ComingSoonImg src={docComingSoon} />
            </ImgDiv>
          )}
          {!comingSoon && (
            <>
              <WipIcon width={55} height={59} isMobile={isMobile} />
            </>
          )}
        </StyledBox>
      )}
      {!isMobile && (
        <>
          <MediumText>WIP</MediumText>
          <StyledBox boxHeight="216px">
            {comingSoon && (
              <ImgDiv style={{ width: "100%" }}>
                <ComingSoonImg src={docComingSoon} />
              </ImgDiv>
            )}
            {!comingSoon && (
              <>
                <IntroArea>
                  <WipIcon />
                </IntroArea>
                <ButtonContainer>
                  <FunctionalButton
                    buttonTitle="Work In Progress"
                    handleButton={seeWorkInProgress}
                    width="80%"
                    marginRight="0px"
                    isReversedColor={true}
                    hasBoxShadow={true}
                  />
                </ButtonContainer>
              </>
            )}
          </StyledBox>
        </>
      )}
      <AlertPopup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        isMobile={isMobile}
        headerText="WIP is available to Premium Subscribers only"
        subHeaderText={`Please upgrade your plan to access WIP`}
        showButton={true}
        height="460px"
      />
    </FunctionArea>
  );
};
