import axios from 'axios';
import { API_HOST } from '../constants';

let headers = {};
const getAuth = () => {
  const authData = localStorage.getItem("Authorization");
  if (authData) {
    headers = { Authorization: authData };
  }
};


export const getSingleClient = async (id, onSuccess, onFailure) => {
  getAuth();
  try {
    const response = await axios.get(`${API_HOST}/onboarding/singleclient/${id}`, { headers });
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));
    }
    if (onFailure) {
      onFailure(err);
    }
  }
};

export const getUserListOfOneClient = async (id, onSuccess, onFailure) => {
  getAuth();
  return await axios.get(`${API_HOST}/onboarding/showuserlistclient/${id}`, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response)
    });
};

export const addUserInTheClient = async (id, data, onSuccess, onFailure) => {
  getAuth();
  return await axios.post(`${API_HOST}/onboarding/adduserintheclient/${id}`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response)
    });
}

export const saveSingleClient = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios.post(`${API_HOST}/onboarding/singleclient/save`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response)
    });
}



export const disableSingleClient = async (id, onSuccess, onFailure) => {
  getAuth();
  return await axios.post(`${API_HOST}/onboarding/singleclient/disable/${id}`, {}, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response)
    });
};


export const enableSingleClient = async (id, onSuccess, onFailure) => {
  getAuth();
  return await axios.post(`${API_HOST}/onboarding/singleclient/enable/${id}`, {}, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response)
    });
};

