import React from 'react';
import admin from './administration.svg';
import { StyledImg } from './styles';

const AdministrationIcon = ({ width, height, noGreyFilter }) => {
  return (
    <StyledImg width={width} heigh={height} src={admin} noGreyFilter={noGreyFilter} />
  )
};

export default AdministrationIcon;
