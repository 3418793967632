function displayPrice(props) {
    var amount = props;
    if (typeof props === 'object') {
        amount = props.amount;
        if (props.options) {
            if (props.options.addDecimal){
                amount = amount/100;
            };
        };
    };
    try {
        var parsedNum = parseFloat(amount);
        if (!isNaN(parsedNum)) {
            if((parsedNum % 1) > 0) {
                // Input is not a whole number. Fix it to 2 decimal places
                return parsedNum.toFixed(2);
            } else {
                // Input is a whole number
                return parsedNum;
            };
        };
    } catch {
    };
    return '??';
};

export default displayPrice;