import React from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import {
  NoticeRoot,
  Title,
  NoticeBody,
  NoticeImage,
  NoticeTitle,
} from "./styles";

import defImage from "../../assets/notices/default.svg";

function Notice(props) {
  return (
    <NoticeRoot>
      <Paper elevation={0} style={{ padding: 22 }}>
        <Title>Notices</Title>
        <NoticeImage src={defImage}></NoticeImage>
        <NoticeTitle>Track the progress of your projects easily</NoticeTitle>
        <NoticeBody>
          Impress your project’s owners by giving them timely reports and
          virtual access to sites and/or dashboards. Perform helps you monitor
          your construction sites and build a performance profile that adds to
          your credit profile.
        </NoticeBody>
        <Button
          variant="contained"
          disableElevation
          disableRipple
          style={{ borderRadius: "6px", marginTop: 22, width: "100%" }}
        >
          Try It Now
        </Button>
      </Paper>
    </NoticeRoot>
  );
}

export default Notice;
