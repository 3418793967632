import React from "react";
import { Popup } from 'components/popup';
import { CloseButton } from "components/closeButton";
import PledgxLogo from "assets/common/pledgxLogo";
import DownloadCSR from 'pages/underwriters/components/downloadCSR/index.js'



import {
  TitleDiv,
  TitleText,
  PopupContainer,
  ButtonDiv
} from "./styles.js";


export const SubmitStatusReportDownload = ({
  openPopup,
  setOpenPopup,
  isMobile,
  data
}) => {

  const handleDone = () => {
    setOpenPopup(false);
  };

  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width={isMobile ? "100%" : "800px"}
      height={isMobile ? "100vh" : "600px"}
      dialogContent={(
        <>
          <CloseButton handleClose={handleDone} />
          <PopupContainer>
            <TitleDiv>
              <PledgxLogo width={isMobile ? 75 : ""} height={isMobile ? 74 : ""} />
            </TitleDiv>

            <DownloadCSR data={data} />

          </PopupContainer>
        </>
      )}
    />

  )
}
