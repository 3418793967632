import React, { useState, useEffect } from "react";

import Input from "../inputField";
import { InfoArea } from "./styles";
import { StyledRow } from "./styles";
import { emailRE } from "../../common/regex";
import { PersonnelDropdown } from "components/personnelDropdown";
import { filterPersonnels } from "api/prequalify";
import { formatPhoneNumber } from "common/formatPhoneNumber";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

export const Step2 = ({
  setDisable,
  addData,
  setAddData,
  isMobile,
  institution_id,
}) => {
  // console.log(addData);

  const [phoneNumber, setPhoneNumber] = React.useState(addData?.owner_phone);
  const handlePhoneNumberChange = (value) => {
    setAddData("owner_phone", value);
  };

  return (
    <InfoArea
      isMobile={isMobile}
      marginTop="0px"
      marginLeft="8px"
      isColumn={true}
    >
      <StyledRow isMobile={isMobile}>
        {/* <Input
        id="inputWrap"
        label="Project Manager"
        type="text"
        placeholder=""
        labelSize="12px"
        fontSize="18px"
        value={addData?.manager || ''}
        setValue={(val) => setAddData('manager', val)}
        validateFunc={(item) => item.length > 0}
      /> */}

        <PersonnelDropdown
          className="inputWrap"
          value={addData?.manager || ""}
          setData={setAddData}
          attribute="manager"
          filterApi={filterPersonnels}
          label="Project Manager"
          noCheckIcon={true}
          noErrorBorder={true}
          institution_id={institution_id}
        />
        {/* <Input
        id="inputWrap"
        label="Project Superintendent"
        type="text"
        placeholder=""
        labelSize="12px"
        fontSize="18px"
        value={addData?.superintendent || ''}
        setValue={(val) => setAddData('superintendent', val)}
        validateFunc={(item) => item.length > 0}
      /> */}
        <PersonnelDropdown
          className="inputWrap"
          value={addData?.superintendent || ""}
          setData={setAddData}
          attribute="superintendent"
          filterApi={filterPersonnels}
          label="Project Superintendent"
          noCheckIcon={true}
          noErrorBorder={true}
          institution_id={institution_id}
        />
      </StyledRow>
      <StyledRow isMobile={isMobile}>
        <Input
          id="inputWrap"
          label="Project Owner"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.owner || ""}
          setValue={(val) => setAddData("owner", val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
        <Input
          id="inputWrap"
          label="Owner Contact Person"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.owner_contact || ""}
          setValue={(val) => setAddData("owner_contact", val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
      </StyledRow>
      <StyledRow isMobile={isMobile}>
        {/* <Input
          id="inputWrap"
          label="Phone Number*"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={formatPhoneNumber(addData?.owner_phone) || ''}
          setValue={(val) => setAddData('owner_phone', val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        /> */}

        <div style={{ marginTop: "24px", width: "100%" }}>
          <label htmlFor="phone" style={{ fontSize: "12px" }}>
            Phone Number
          </label>
          <div style={{ height: "47px", width: "100%", marginTop: "-10px" }}>
            <PhoneInput
              id="phone"
              country={"ca"}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              style={{ marginTop: "16px", width: "100%", height: "100%" }}
              inputStyle={{
                fontSize: "18px",
                padding: "8px",
                lineHeight: "24px",
                marginLeft: "20px",
                paddingLeft: "28px",
                height: "100%",
                width: "45%",
              }}
              dropdownStyle={{ fontSize: "18px", lineHeight: "24px" }}
            />
          </div>
        </div>

        <Input
          id="inputWrap"
          label="Email"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.owner_email || ""}
          setValue={(val) => setAddData("owner_email", val)}
          validateFunc={(item) => emailRE.test(item)}
          noCheckIcon={true}
          noErrorBorder={true}
          style={{ marginLeft: "-290px" }}
        />
      </StyledRow>
    </InfoArea>
  );
};
