import styled from "styled-components";

export const TenderBody = styled.div`
  // width: 1200px;
  min-height: 150px;
  background: white;
  margin: 5px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TenderRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TenderColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TenderLogo = styled.div`
  width: 60px;
  height: 60px;

  margin-left: 20px;
  background: #c4c4c4;
  border-radius: 3px;
`;

export const TenderName = styled.div`
  display: flex;
  margin-left: 20px;

  font-weight: 600;
  font-size: 18px;
  line-height: 21px;

  color: #6e6b7b;
`;

export const TenderCompany = styled.div`
  margin-left: 20px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  color: #b9b9c3;
`;

export const TenderDescText = styled.div`
  margin: 2px;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;

  color: #7a7a7a;
`;

export const TenderDate = styled.div`
  margin: 0 20px;
  width: 60px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  color: #b9b9c3;
`;

export const TenderBusinessCategory = styled.div`
  height: 18px;
  border-radius: 5px;
  padding: 0 5px;
  margin-right: 10px;

  font-weight: normal;
  font-size: 11px;
  line-height: 17px;
  text-transform: capitalize;

  ${(props) =>
    props.color === "green" &&
    "color: #02814e; background: rgba(2, 129, 78, 0.25);"}
  ${(props) =>
    props.color === "yellow" &&
    "color: #FF6D1D; background: rgba(255, 109, 29, 0.25);"}
  ${(props) =>
    props.color === "purple" &&
    "color: #435EAB; background: rgba(67, 94, 171, 0.25);"}
  ${(props) =>
    !props.color && "color: #6e6b7b; background: lightgrey"}
`;

export const NameTag = styled.div`
  display: flex;
  align-items: center;

  margin-left: 10px;

  height: 18px;
  border-radius: 5px;
  padding: 0 5px;
  margin-right: 10px;

  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  white-space: nowrap;

  background: ${(props) =>
    props.red ? "#d60000" : props.green ? "#02814E" : "gray"};
  color: white;
`;
