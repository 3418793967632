import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Step from "@material-ui/core/Step";
import { StyledStepConnector, StyledStepLabel, StyledStepper, SideNavHeading } from './styles';
import clsx from "clsx";
import { ReactComponent as LeftArrow } from "pages/underwriterDashboard/clientSection/asset/leftArrow.svg";
import { ReactComponent as RightArrow } from "pages/underwriterDashboard/clientSection/asset/rightArrow.svg";

const useColorlibStepIconStyles = makeStyles({
  root: {
    cursor: "pointer",
    zIndex: 1,
    color: "#b8b8b8",
    width: 30,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: { color: "#888888" },
  completed: { color: '#B9B9C3' },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
    </div>
  );
}


const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 300,
    backgroundColor: "#FFFFFF",
    color: "#000000",
    marginTop: 64,
  },
  closeBtn: {
    position: "absolute",
    top: 20,
    right: 25,
    fontSize: 36,
    marginLeft: 50,
    color: "#f1f1f1",
  },
}));

export const SideNav = ({
  activeStep,
  setActiveStep,
  isMobile
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    setOpen(open);
  };

  const steps = [
    "Peer Group Analysis",
    "Performance Enhancement",
    "Business Focus",
    "Cash/Asset Utilization",
    "Cashflow Stretch",
    "Bidding Strategy",
    "Competition Landscape",
    "Churn Probability",
    "Next Bond Prediction"
  ];


  return (
    <div>
      <IconButton onClick={toggleDrawer(true)} style={{ fontSize: 30, cursor: "pointer" }}>
        {open === false ? <RightArrow /> : ""}
      </IconButton>
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        classes={{ paper: classes.drawerPaper }}
      >
        <IconButton onClick={toggleDrawer(false)} className={classes.closeBtn}>
          <LeftArrow />
        </IconButton>
        <SideNavHeading>Account Insights</SideNavHeading>

        <StyledStepper
          isMobile={isMobile}
          activeStep={activeStep}
          orientation="vertical"
          connector={<StyledStepConnector />}
        >
          {/* {steps?.map((label, index) => (
            <Step key={label}>
              <StyledStepLabel
                StepIconComponent={ColorlibStepIcon}
                onClick={() => {
                  setActiveStep(index);
                }}
              >
                {label}
              </StyledStepLabel>
            </Step>
          ))} */}

          {steps?.map((label, index) => (
            <Step key={label}>
              {index === 0 ? ( // Render clickable label for the first item
                <StyledStepLabel
                  StepIconComponent={ColorlibStepIcon}
                  onClick={() => {
                    setActiveStep(index);
                  }}
                >
                  {label}
                </StyledStepLabel>
              ) : ( // Render non-clickable labels for other items
                <StyledStepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StyledStepLabel>
              )}
            </Step>
          ))}
        </StyledStepper>

      </Drawer>
    </div>
  );
}
