import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { ArrowsIcon } from "assets/common/arrowsIcon";
import { ScrollButton, StyledDateArea, StyledDot } from "./styles";
import { ApprovedIcon } from "assets/wip/approvedIcon";
import { FlagIcon } from "assets/wip/flagIcon";
import { SandClockIcon } from "assets/wip/sandClockIcon";
import { formatDateInMonthDay } from "common/dateFormat";

const LineWithCircles = ({
  totalCircles,
  ppcData,
  changeOrderData,
  onCircleClick,
}) => {
  const [changeOrderCount, setChangeOrderCount] = useState(0);
  const [ppcCount, setPpcCount] = useState(0);
  const [selectedIdx, setSelectedIdx] = useState(null);

  useEffect(() => {
    setChangeOrderCount(changeOrderData?.length);
  }, [changeOrderData]);
  useEffect(() => {
    setPpcCount(ppcData?.length);
    setSelectedIdx(ppcData?.length - 1);
  }, [ppcData]);
  const containerRef = useRef(null);
  const lineWidth = totalCircles > 0 ? totalCircles * (49 + 125) : " ";

  let animationFrameId;
  const stopScroll = () => {
    cancelAnimationFrame(animationFrameId);
  };

  useEffect(() => {
    // Scroll to the right on component mount
    if (containerRef.current) {
      const maxScrollLeft =
        containerRef.current.scrollWidth - containerRef.current.clientWidth;
      containerRef.current.scrollTo({
        left: maxScrollLeft,
        behavior: "auto",
      });
    }
  }, []);

  const handleClickScrollToRight = () => {
    stopScroll();
    if (containerRef.current) {
      const newScroll = containerRef.current.scrollLeft + 600;
      containerRef.current.scrollTo({
        left: newScroll,
        behavior: "smooth",
      });
    }
  };

  const handleClickScrollToLeft = () => {
    stopScroll();
    if (containerRef.current) {
      const newScroll = containerRef.current.scrollLeft - 600;
      containerRef.current.scrollTo({
        left: newScroll,
        behavior: "smooth",
      });
    }
  };
  const scrollStep = 3;
  const scrollToLeft = () => {
    const performScroll = () => {
      if (containerRef.current) {
        const newScroll = containerRef.current.scrollLeft - scrollStep;
        containerRef.current.scrollTo({
          left: newScroll,
          behavior: "auto",
        });

        if (newScroll > 0) {
          animationFrameId = requestAnimationFrame(performScroll);
        }
      }
    };

    performScroll();
  };

  const scrollToRight = () => {
    const performScroll = () => {
      if (containerRef.current) {
        const newScroll = containerRef.current.scrollLeft + scrollStep;
        containerRef.current.scrollTo({
          left: newScroll,
          behavior: "auto",
        });

        animationFrameId = requestAnimationFrame(performScroll);
      }
    };
    performScroll();
  };

  return (
    <>
      <ScrollButton
        style={{
          justifyContent: "center",
          display: "flex",
        }}
        onClick={handleClickScrollToLeft}
        onMouseEnter={scrollToLeft}
        onMouseLeave={stopScroll}
      >
        <ArrowsIcon backArrow={true} />
      </ScrollButton>
      <StyledDot />
      <StyledDot />
      <div className="container">
        <div ref={containerRef} className="lineContainer">
          <div
            className="line"
            style={{
              width: `${lineWidth}px`,
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          >
            {Array.from({ length: totalCircles }).map((_, idx) => (
              <>
                {/* <div key={idx} className="circle"></div> */}
                <div
                  className="circleContainer"
                  style={{ top: changeOrderCount > 0 ? "-140px" : "-45px" }}
                >
                  {/* <StyledCircle>10%</StyledCircle> */}
                  {/* <div key={idx} className="circle"></div> */}
                  {idx < ppcCount && ppcCount > 0 && (
                    <>
                      <StyledDateArea>
                        {formatDateInMonthDay(ppcData[idx]?.as_of_date)}
                      </StyledDateArea>
                      <ApprovedIcon
                        onCircleClick={() => {
                          setSelectedIdx(idx);
                          onCircleClick(ppcData[idx]);
                        }}
                        isSelected={selectedIdx === idx}
                      />
                      <StyledDateArea>
                        {ppcData[idx]?.event_type}
                      </StyledDateArea>
                    </>
                  )}
                  {idx >= ppcCount && (
                    <>
                      <StyledDateArea>Date</StyledDateArea>
                      <SandClockIcon />
                      <StyledDateArea>PPC#</StyledDateArea>
                    </>
                  )}
                </div>

                {idx < changeOrderCount && idx < totalCircles - 1 && (
                  <div className="svg-container">
                    <FlagIcon />
                    <StyledDateArea>
                      {formatDateInMonthDay(
                        changeOrderData[idx]?.change_order_date
                      )}
                    </StyledDateArea>
                    {/* <StyledDateArea>
                      CO {changeOrderData[idx]?.change_order_no}
                    </StyledDateArea> */}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
      <StyledDot />
      <StyledDot />
      <ScrollButton
        style={{
          justifyContent: "center",
          display: "flex",
        }}
        onClick={handleClickScrollToRight}
        onMouseEnter={scrollToRight}
        onMouseLeave={stopScroll}
      >
        <ArrowsIcon />
      </ScrollButton>
    </>
  );
};

export default LineWithCircles;
