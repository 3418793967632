import React from 'react';
import { StyledSlider, HeaderArea, StyledSliderReverse, StyledSliderRiskScore } from './styles';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { numberWithCommas } from "common/numberFormat.js";


export const RatingSlider = ({ value, startValue, endValue }) => {
  if (startValue === undefined) {
    startValue = 0;
  }
  if (endValue === undefined) {
    endValue = 100;
  }
  let formatStartValue = 0
  let formatEndValue = 0
  let formatCurrentValue = 0

  if (Math.abs(startValue) > 99) {
    formatStartValue = Math.round(startValue)
    formatEndValue = Math.round(endValue)
    formatCurrentValue = Math.round(value)
  } else if (Math.abs(startValue) < 99 && Math.abs(endValue) > 1000) {
    formatStartValue = startValue
    formatEndValue = Math.round(endValue)
    formatCurrentValue = Math.round(value)
  } else {
    formatStartValue = startValue
    formatEndValue = endValue
    formatCurrentValue = value
  }

  const range = endValue - startValue
  const scaleFactor = range / 20
  const newStartValue = Math.round(startValue / scaleFactor)
  const adjustedStepValue = Math.round(value / scaleFactor) + 1
  let newAdjustedStepValue = adjustedStepValue - newStartValue
  if (newAdjustedStepValue > 20){
    newAdjustedStepValue = 20
  }

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    '& .MuiTooltip-arrow': {
      color: '#FF6D1D',
    },
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#FF6D1D',
      color: 'white',
      fontSize: '12px',
      whiteSpace: 'pre-line',
    },
  });

  const ValueTooltip = ({ children, open, value, startValue, endValue }) => {
    return (
      <CustomTooltip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={`Lowest in peer: ${startValue}\nThis Client's Value: ${numberWithCommas(value)}\nHighest in peer: ${numberWithCommas(endValue)}`}
        arrow
      >
        {children}
      </CustomTooltip>
    );
  };

  return (
    <HeaderArea>
      <StyledSlider
        value={newAdjustedStepValue}
        step={1}
        min={1}
        max={20}
        ValueLabelComponent={(props) => (
          <ValueTooltip {...props} startValue={formatStartValue} value={formatCurrentValue} endValue={formatEndValue} />
        )}
        valueLabelDisplay="auto"
      />
    </HeaderArea>
  );
};



export const RatingSliderReverse = ({ value, startValue, endValue }) => {
  if (startValue === undefined) {
    startValue = 0;
  }
  if (endValue === undefined) {
    endValue = 100;
  }
  let formatStartValue = 0
  let formatEndValue = 0
  let formatCurrentValue = 0

  if (Math.abs(startValue) > 99) {
    formatStartValue = Math.round(startValue)
    formatEndValue = Math.round(endValue)
    formatCurrentValue = Math.round(value)
  } else if (Math.abs(startValue) < 99 && Math.abs(endValue) > 1000) {
    formatStartValue = startValue
    formatEndValue = Math.round(endValue)
    formatCurrentValue = Math.round(value)
  } else {
    formatStartValue = startValue
    formatEndValue = endValue
    formatCurrentValue = value
  }

  const range = endValue - startValue
  const scaleFactor = range / 20
  const newStartValue = Math.round(startValue / scaleFactor)
  const adjustedStepValue = Math.round(value / scaleFactor) + 1
  let newAdjustedStepValue = adjustedStepValue - newStartValue
  if (newAdjustedStepValue > 20){
    newAdjustedStepValue = 20
  }

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    '& .MuiTooltip-arrow': {
      color: '#FF6D1D',
    },
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#FF6D1D',
      color: 'white',
      fontSize: '12px',
      whiteSpace: 'pre-line',
    },
  });

  const ValueTooltip = ({ children, open, value, startValue, endValue }) => {
    return (
      <CustomTooltip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={`Lowest in peer:  ${startValue}\nThis Client's Value: ${numberWithCommas(value)}\nHighest in peer: ${numberWithCommas(endValue)}`}
        arrow
      >
        {children}
      </CustomTooltip>
    );
  };

  return (
    <HeaderArea>
      <StyledSliderReverse
        value={newAdjustedStepValue}
        step={1}
        min={1}
        max={20}
        ValueLabelComponent={(props) => (
          <ValueTooltip {...props} startValue={formatStartValue} value={formatCurrentValue} endValue={formatEndValue} />
        )}
        valueLabelDisplay="auto"
      />
    </HeaderArea>
  );
};


export const RatingSliderTrend = ({ value, startValue, endValue }) => {
  if (startValue === undefined) {
    startValue = 0;
  }
  if (endValue === undefined) {
    endValue = 100;
  }
  let formatStartValue = 0
  let formatEndValue = 0
  let formatCurrentValue = 0

  if (Math.abs(startValue) > 99) {
    formatStartValue = Math.round(startValue)
    formatEndValue = Math.round(endValue)
    formatCurrentValue = Math.round(value)
  } else if (Math.abs(startValue) < 99 && Math.abs(endValue) > 1000) {
    formatStartValue = startValue
    formatEndValue = Math.round(endValue)
    formatCurrentValue = Math.round(value)
  } else {
    formatStartValue = startValue
    formatEndValue = endValue
    formatCurrentValue = value
  }

  const range = endValue - startValue
  const scaleFactor = range / 20
  const newStartValue = Math.round(startValue / scaleFactor)
  const adjustedStepValue = Math.round(value / scaleFactor) + 1
  let newAdjustedStepValue = adjustedStepValue - newStartValue
  if (newAdjustedStepValue > 20){
    newAdjustedStepValue = 20
  }

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    '& .MuiTooltip-arrow': {
      color: '#FF6D1D',
    },
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#FF6D1D',
      color: 'white',
      fontSize: '12px',
      whiteSpace: 'pre-line',
    },
  });

  const ValueTooltip = ({ children, open, value, startValue, endValue }) => {
    return (
      <CustomTooltip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={`Trend Quantile: ${numberWithCommas(value)}`}
        arrow
      >
        {children}
      </CustomTooltip>
    );
  };

  return (
    <HeaderArea>
      <StyledSlider
        value={newAdjustedStepValue}
        step={1}
        min={1}
        max={20}
        ValueLabelComponent={(props) => (
          <ValueTooltip {...props} startValue={formatStartValue} value={formatCurrentValue} endValue={formatEndValue} />
        )}
        valueLabelDisplay="auto"
      />
    </HeaderArea>
  );
};


export const RatingSliderForTotalDebtToEquity = ({ value, startValue, endValue }) => {
  if (startValue === undefined) {
    startValue = 0;
  }
  if (endValue === undefined) {
    endValue = 100;
  }
  let formatStartValue = 0
  let formatEndValue = 0
  let formatCurrentValue = 0

  if (Math.abs(startValue) > 99) {
    formatStartValue = Math.round(startValue)
    formatEndValue = Math.round(endValue)
    if (value === undefined) {
      formatCurrentValue = 0
    } else {
      formatCurrentValue = value
    }
  } else if (Math.abs(startValue) < 99 && Math.abs(endValue) > 1000) {
    formatStartValue = startValue
    formatEndValue = Math.round(endValue)
    if (value === undefined) {
      formatCurrentValue = 0
    } else {
      formatCurrentValue = value
    }
  } else {
    formatStartValue = startValue
    formatEndValue = endValue
    if (value === undefined || value <= 0.10) {
      formatCurrentValue = 0
    } else {
      formatCurrentValue = value
    }
  }

  const range = endValue - startValue
  const scaleFactor = range / 20
  const newStartValue = Math.round(startValue / scaleFactor)
  let adjustedStepValue = 0
  let newAdjustedStepValue = 0

  if (value >= 0 && value < 1) {
    newAdjustedStepValue = 1
  } else if (value >= 1 && value < 2) {
    newAdjustedStepValue = 2
  } else if (value >= 2 && value < 3) {
    newAdjustedStepValue = 3
  } else if (value >= 3 && value < 4) {
    newAdjustedStepValue = 4
  } else {
    adjustedStepValue = Math.round(value / scaleFactor) + 5
    newAdjustedStepValue = adjustedStepValue - newStartValue
  }


  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    '& .MuiTooltip-arrow': {
      color: '#FF6D1D',
    },
    '& .MuiTooltip-tooltip': {
      backgroundColor: '#FF6D1D',
      color: 'white',
      fontSize: '12px',
      whiteSpace: 'pre-line',
    },
  });

  const ValueTooltip = ({ children, open, value, startValue, endValue }) => {
    return (
      <CustomTooltip
        open={open}
        enterTouchDelay={0}
        placement="top"
        title={`Lowest in peer: ${numberWithCommas(startValue)}\nThis Client's Value: ${value}\nHighest in peer: ${numberWithCommas(endValue)}`}
        arrow
      >
        {children}
      </CustomTooltip>
    );
  };

  return (
    <HeaderArea>
      <StyledSliderReverse
        value={newAdjustedStepValue}
        step={1}
        min={1}
        max={20}
        ValueLabelComponent={(props) => (
          <ValueTooltip {...props} startValue={formatStartValue} value={formatCurrentValue} endValue={formatEndValue} />
        )}
        valueLabelDisplay="auto"
      />
    </HeaderArea>
  );
};


export const RatingSliderRiskScore = ({ value, startValue, endValue }) => {
  if (startValue === undefined) {
    startValue = 0;
  }
  if (endValue === undefined) {
    endValue = 100;
  }
  let formatStartValue = 0
  let formatEndValue = 0
  let formatCurrentValue = 0

  if (Math.abs(startValue) > 99) {
    formatStartValue = Math.round(startValue)
    formatEndValue = Math.round(endValue)
    if (value === undefined) {
      formatCurrentValue = 0
    } else {
      formatCurrentValue = value
    }
  } else if (Math.abs(startValue) < 99 && Math.abs(endValue) > 1000) {
    formatStartValue = startValue
    formatEndValue = Math.round(endValue)
    if (value === undefined) {
      formatCurrentValue = 0
    } else {
      formatCurrentValue = value
    }
  } else {
    formatStartValue = startValue
    formatEndValue = endValue
    if (value === undefined) {
      formatCurrentValue = 0
    } else {
      formatCurrentValue = value
    }
  }

  const range = endValue - startValue
  const scaleFactor = range / 20
  const newStartValue = Math.round(startValue / scaleFactor)
  const adjustedStepValue = Math.round(value / scaleFactor)
  const newAdjustedStepValue = adjustedStepValue - newStartValue

  const marks = [
    { value: 1, label: `${(formatStartValue * 10).toFixed(1)}`, position: 'start' },
    { value: `${newAdjustedStepValue}`, label: `${(formatCurrentValue * 10).toFixed(1)}`, position: 'middle' },
    { value: 20, label: `${(formatEndValue * 10).toFixed(1)}`, position: 'end' },
  ];

  return (
    <HeaderArea>
      <StyledSliderRiskScore
        value={newAdjustedStepValue}
        step={1}
        min={1}
        max={20}
        marks={marks} 
      />
    </HeaderArea>
  );
};

