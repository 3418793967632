import React, { useEffect, useRef, useState } from "react";
import { LargeText } from "../styles";
import logoPlacement from "assets/reference/logoPlacement.svg";
import editBadge from "assets/reference/editBadge.svg";
import { Badge } from "@mui/material";
import { saveCompanyLogo } from "api/profile";
import { FloatingButton } from "../../../components/floatingButton";
import { CommonCategories } from "../../../components/commonCategories";
import { CommonBusinessType } from "components/commonBusinessType/index.js";
import updateObjectByPath from "../../../common/updateObjectByPath";
import { AddressAutoFields } from "./AddressAutoFields";
import Select from "../../../components/selectField";
import { getCompanyInfo, saveCompanyInfo } from "../../../api/profile";
import { IconButton } from "@material-ui/core";
import MobileSaveButtonIcon from "../../../assets/dashboardIcons/mobileSaveButtonIcon";
import Input from "components/inputField";
import { ResultPanel } from "../../newPreQualify/components/officerPartners/resultPanel";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import RadioGroupButtonForProjectCategory from "components/radioGroupButtonForProjectCategory";

import {
  FieldArea,
  StyledRow,
  SectionText,
  SetupContainer,
  CompanyLogo,
  EditBadge,
  LogoDiv,
  LogoPlacementContainer,
  StyledAddressContainer,
  StyledEditRecordButton
} from "./styles";

import { getAssociations, getUnions } from "api/external_data";
import { getCountryName } from "../../../common/countryName";
import countriesInArray from "../../../assets/locale/countriesInArray";
import fetchStatesProvinces from "../../../assets/locale/stateProvinceInArray.js";

const EditBadgeContent = ({ inputFile, isMobile, setLogo }) => {
  const clickUploadFile = () => {
    inputFile.current.click();
  };

  const savePhoto = (file) => {
    let formData = new FormData();
    formData.append("logo", file);
    saveCompanyLogo(
      formData,
      (res) => setLogo(res.logo),
      () => { }
    );
  };

  return (
    <>
      <label htmlFor="contained-button-file">
        <input
          type="file"
          id="contained-button-file"
          style={{ display: "none" }}
          ref={inputFile}
          accept=".jpg,.jpeg,.png, image/jpeg,image/png"
          onClick={(e) => {
            e.target.value = null;
            clickUploadFile();
          }}
          onChange={(e) => {
            if (
              e.target.files[0].type === "image/jpeg" ||
              e.target.files[0].type === "image/png"
            ) {
              savePhoto(e.target.files[0]);
            }
          }}
        />
        <EditBadge src={editBadge} isMobile={isMobile} />
      </label>
    </>
  );
};

export const CompanyInfo = ({
  isMobile,
  setOpenSuccessPopup,
  institution_id,
  currentDashboardUrl,
}) => {
  const [data, setData] = useState({});
  const inputFile = useRef(null);
  const [logo, setLogo] = useState(data?.company_info?.logo);
  const [filteredData, setFilteredData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState(data?.company_info?.phone);
  const [statesProvinces, setStatesProvinces] = useState([]);
  let stateValue = "State"
  const [stateProvince, setStateProvince] = useState(stateValue);
  const [containsUNSPC, setContainsUNSPC] = useState(data?.is_unspc_category_type || false);

  const confirmRadioChange = (val) => {
    if (val === 1) {
      setContainsUNSPC(false);
    } else {
      setContainsUNSPC(true);
    }
  };

  let institution_data = {};

  if (currentDashboardUrl === "/service-provider-dashboard" && institution_id) {
    institution_data = { institution_id: institution_id };
  }

  const handlePhoneNumberChange = (value) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, "company_info.phone", value);
    setData({ ...updatedData });
  };


  useEffect(() => {
    const updatedData = { ...data };
    if (containsUNSPC === true) {
      updatedData["is_unspc_category_type_new"] = containsUNSPC;
    } else {
      updatedData["is_unspc_category_type_new"] = containsUNSPC;
    }
    setData(updatedData);
  }, [containsUNSPC]);


  useEffect(() => {
    getCompanyInfo(
      institution_data,
      (res) => {
        setData({ ...res });
        setLogo(res?.company_info?.logo);
        setContainsUNSPC(res?.is_unspc_category_type);
        setFilteredData(res?.legal_structure_personnel);
        setPhoneNumber(res?.company_info?.phone);
        if (getCountryName(res?.operational_region?.country) === "Canada") {
          setStateProvince("Province")
        } else if (getCountryName(res?.operational_region?.country) === "United States") {
          setStateProvince("State")
        } else {
          setStateProvince("State/Province")
        }

      },
      () => { }
    );
  }, []);



  const ventureOptions = [
    "Joint Venture",
    "Corporation",
    "Partnership",
    "Registered",
    "Sole Proprietor",
    "Other",
  ];

  const handleSave = () => {
    let newData = { ...data };
    if (currentDashboardUrl === "/service-provider-dashboard") {
      newData = { ...institution_data, ...newData };
    }
    saveCompanyInfo(
      newData,
      () => {
        setOpenSuccessPopup(true);
      },
      () => { }
    );
  };
  const selectCountry = (value) => {
    if (value) {
      onChangeData("operational_region.country", value);
      if (getCountryName(value) === "Canada") {
        setStateProvince("Province");
      } else if (getCountryName(value) === "United States") {
        setStateProvince("State");
      } else {
        setStateProvince("State/Province");
      }
      const fetchedStatesProvinces = fetchStatesProvinces(getCountryName(value));
      setStatesProvinces(fetchedStatesProvinces);
      onChangeData("operational_region.province", fetchedStatesProvinces[0]);
    }
  };

  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };

  const currentYear = new Date().getFullYear();
  const saveButtonRef = useRef();

  useEffect(() => {
    onChangeData("legal_structure_personnel", filteredData);
  }, [filteredData]);

  return (
    <SetupContainer isMobile={isMobile}>
      {!isMobile && (
        <FloatingButton
          buttonTitle="Save"
          handleButton={handleSave}
          width="150px"
          height="38px"
          marginRight="0px"
        />
      )}
      <StyledRow justifyContent="space-between" ref={saveButtonRef}>
        <LargeText isMobile={isMobile}>
          {isMobile
            ? "Edit Company Information"
            : "Edit your Company Information"}
        </LargeText>
        {isMobile && (
          <IconButton onClick={handleSave}>
            <MobileSaveButtonIcon />
          </IconButton>
        )}
      </StyledRow>
      <FieldArea isMobile={isMobile} width={isMobile ? "100%" : "66.67%"}>
        <SectionText>Company Logo</SectionText>
        <LogoDiv>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <EditBadgeContent
                inputFile={inputFile}
                isMobile={isMobile}
                setLogo={setLogo}
              />
            }
          >
            {logo && (
              <CompanyLogo
                src={`data:image/png;base64,${logo}`}
                isMobile={isMobile}
                variant="circular"
              />
            )}
            {!logo && (
              <LogoPlacementContainer
                src={logoPlacement}
                isMobile={isMobile}
                variant="circular"
              />
            )}
          </Badge>
        </LogoDiv>
        <SectionText>Business Information</SectionText>
        <Input
          id="fullWidthInputWrap"
          label="Company Name"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={data?.company_info?.name}
          setValue={(val) => onChangeData("company_info.name", val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
        />
        <StyledAddressContainer>
          <div style={{ marginTop: "24px", width: "100%" }}>
            <label htmlFor="phone" style={{ fontSize: "12px" }}>
              Company Phone
            </label>
            <div style={{ height: "47px", width: "100%", marginTop: "-10px" }}>
              <PhoneInput
                id="phone"
                country={"ca"}
                placeholder="(123) 456-7890"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                style={{ marginTop: "16px", width: "100%", height: "100%" }}
                inputStyle={{
                  fontSize: "18px",
                  padding: "8px",
                  lineHeight: "24px",
                  marginLeft: "20px",
                  paddingLeft: "28px",
                  height: "100%",
                }}
                dropdownStyle={{ fontSize: "18px", lineHeight: "24px" }}
              />
            </div>
          </div>

          <Input
            id="inputWrap last"
            label="Company Email"
            type="text"
            placeholder="abc@company.com"
            labelSize="12px"
            fontSize="18px"
            value={data?.company_info?.email}
            setValue={(val) => onChangeData("company_info.email", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            style={{ marginLeft: "-180px" }}
          />
        </StyledAddressContainer>
        <Input
          id="fullWidthInputWrap"
          label="Company Website"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={data?.company_info?.website_url}
          setValue={(val) => onChangeData("company_info.website_url", val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
        />
        <FieldArea>
          <SectionText>Company Identification</SectionText>
          <Input
            id="fullWidthInputWrap "
            label="Business Number"
            type="text"
            placeholder="000000"
            labelSize="12px"
            fontSize="18px"
            value={data?.company_info?.business_number}
            setValue={(val) => onChangeData("company_info.business_number", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
          />
        </FieldArea>
      </FieldArea>
      <FieldArea isMobile={isMobile} width={isMobile ? "100%" : "66.67%"}>
        <SectionText>Business Categories</SectionText>
        <RadioGroupButtonForProjectCategory
          containsUNSPC={containsUNSPC}
          confirmRadioChange={confirmRadioChange}
        />
        <StyledRow isMobile={isMobile}>
          <CommonBusinessType
            data={data}
            setData={onChangeData}
            isMobile={isMobile}
            notPopup={true}
            containsUNSPC={containsUNSPC}
          />
        </StyledRow>
        <SectionText marginTop="40px">Company Address</SectionText>
        <AddressAutoFields data={data} setData={onChangeData} />

        <SectionText marginTop="40px">Operational Region</SectionText>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="inputWrap">
            <Select
              style={{ marginRight: "16px" }}
              label="Country"
              options={countriesInArray.map((country) => ({
                value: country.countryCode,
                label: country.countryName,
              }))}
              value={data?.operational_region?.country ? getCountryName(data?.operational_region?.country) : "Select a Country"}
              setValue={(value) => selectCountry(value)}
              validateFunc={(item) => Boolean(item)}
              noCheckIcon={true}
              showDropdownIndicator={true}
            />
          </div>

          {statesProvinces && statesProvinces.length > 0 && (
            <div className="inputWrap">
              <Select
                style={{ marginRight: "16px" }}
                label={stateProvince}
                options={statesProvinces.map((stateProvince) => ({
                  value: stateProvince,
                  label: stateProvince,
                }))}
                value={data?.operational_region?.province ? data?.operational_region?.province : ""}
                setValue={(value) => onChangeData("operational_region.province", value)}
                validateFunc={(item) => Boolean(item)}
                noCheckIcon={true}
                showDropdownIndicator={true}
              />
            </div>
          )}

          {statesProvinces && statesProvinces.length === 0 && (
            <Input
              id="inputWrap"
              label={stateProvince}
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={data?.operational_region?.province ? data?.operational_region?.province : ""}
              setValue={(val) => onChangeData("operational_region.province", val)}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              marginRight="18px"
            />
          )}

          <Input
            id="thirtyInputWrap last"
            label="Region"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.operational_region?.region}
            setValue={(val) => onChangeData("operational_region.region", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
          />
        </div>
        <SectionText marginTop="48px">General</SectionText>
        <div className="fullWidthInputWrap">
          <Select
            style={{ marginRight: "16px" }}
            label="Year Established"
            options={Array.from(
              { length: currentYear - 1899 },
              (_, i) => currentYear - i
            ).map((item) => ({ value: item, label: item }))}
            value={data?.establishment_year}
            setValue={(val) => onChangeData("establishment_year", val)}
            validateFunc={(item) => Boolean(item)}
            noCheckIcon={true}
            showDropdownIndicator={true}
          />
        </div>
        <div className="fullWidthInputWrap">
          <Select
            style={{ marginRight: "16px" }}
            label="Venture Type"
            options={ventureOptions.map((item) => ({
              value: item,
              label: item,
            }))}
            value={data?.venture_type}
            setValue={(val) => onChangeData("venture_type", val)}
            validateFunc={(item) => Boolean(item)}
            noCheckIcon={true}
            showDropdownIndicator={true}
          />
        </div>
        <FieldArea>
          <ResultPanel
            projects={filteredData}
            setProjects={setFilteredData}
            setOpenPopup={setOpenPopup}
            openPopup={openPopup}
            paddingRight="0px"
            height="210px"
            institution_id={institution_id}
            currentDashboardUrl={currentDashboardUrl}
          />
        </FieldArea>
      </FieldArea>
      <FieldArea isMobile={isMobile} width={isMobile ? "100%" : "66.67%"}>
        <SectionText>Additional Information</SectionText>

        <div className="fullWidthInputWrap">
          <AssociationDropdown data={data} onChangeData={onChangeData} />
        </div>
        <div className="fullWidthInputWrap">
          <UnionDropdown data={data} onChangeData={onChangeData} />
        </div>
      </FieldArea>
    </SetupContainer>
  );
};

const AssociationDropdown = ({ data, onChangeData }) => {
  const [associations, setAssociations] = useState([{ name: "None" }]);
  useEffect(() => {
    getAssociations()
      .then((res) => {
        let associationList = [...associations, ...res];
        setAssociations(associationList);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  return (
    <Select
      style={{ marginRight: "16px" }}
      label="Choose Your Construction Association"
      options={associations?.map((item) => ({
        value: item.name,
        label: item.name,
      }))}
      value={data?.association}
      setValue={(val) => onChangeData("association", val)}
      validateFunc={(item) => Boolean(item)}
      noCheckIcon={true}
      showDropdownIndicator={true}
    />
  );
};

const UnionDropdown = ({ data, onChangeData }) => {
  const [unions, setUnions] = useState([{ name: "None" }]);

  useEffect(() => {
    getUnions()
      .then((res) => {
        let unionList = [...unions, ...res];
        setUnions(unionList);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  return (
    <Select
      style={{ marginRight: "16px" }}
      label="Choose Your Construction Union"
      options={unions?.map((item) => ({
        value: item.name,
        label: item.name,
      }))}
      value={data?.union}
      setValue={(val) => onChangeData("union", val)}
      validateFunc={(item) => Boolean(item)}
      noCheckIcon={true}
      showDropdownIndicator={true}
    />
  );
};
