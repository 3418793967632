import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { COLORS } from '../../constants';

export const StyledCircularProgress = styled(CircularProgress)`
  && {
    width: 30px !important;
    height: 30px !important;
    color: ${COLORS.primaryOrange};
    margin: ${props => props.margin? props.margin : '0'};
  }
`;
