import React, { useEffect } from "react";
import {
  CloseButton,
  InfoArea,
  StyledPaper,
  StyledMenuItem,
  StyledButton,
  StyledButtonReview,
  StyledButtonContainer,
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import PledgxLogo from "../../assets/common/pledgxLogo";

export const AddCcdc11PopUp = ({ setOpenDialog, openDialog, handleButton }) => {
  useEffect(() => {
    if (!openDialog) {
      setOpenDialog(false);
    }
  }, [openDialog]);
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleGoBack = () => {
    handleClose();
  };

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper elevation={0}>
        <CloseButton aria-label="close" onClick={() => handleClose()}>
          <CloseIcon />
        </CloseButton>
        <div>
          <PledgxLogo />
        </div>

        <InfoArea>
          PledgX has facilitated you to fill in this CCDC 11 – 2019 document
          format with the understanding that you have purchased the form and the
          necessary seals required to use it from an authorized document outlet
          of the Canadian Construction Documents Committee. Please refer to
          <span> </span>
          <span>
            <a
              href="https://www.ccdc.org/document/ccdc11/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#FF6D1D", cursor: "pointer" }}
            >
              CCDC 11 – 2019 Contractor’s Qualification Statement
            </a>
          </span>
          <span> </span>
          for applicable instructions. Please note that use of a CCDC 11
          document not containing a CCDC 11 copyright seal constitutes an
          infringement of copyright. Use of this form with a CCDC 11 copyright
          seal demonstrates that it is intended by the parties to be an accurate
          and unamended version of CCDC 11 – 2019.
        </InfoArea>
        <StyledButtonContainer>
          <StyledMenuItem>
            <StyledButton title="Go Back" width="230px" onClick={handleGoBack}>
              Go Back
            </StyledButton>
          </StyledMenuItem>

          <StyledMenuItem>
            <StyledButtonReview
              title="Generate"
              width="230px"
              onClick={handleButton}
            >
              GENERATE
            </StyledButtonReview>
          </StyledMenuItem>
        </StyledButtonContainer>
      </StyledPaper>
    </Dialog>
  );
};
