import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { StyledCloseButton } from './styles';

export const CloseButton = ({handleClose}) => {
  return (
    <StyledCloseButton
      aria-label="close"
      onClick={handleClose}
    >
      <CloseIcon />
    </StyledCloseButton>
  )
}