import React, { useState, useEffect } from "react";

import Search from "../../assets/common/Search.svg";
import cancelIcon from "../../assets/common/cancelIcon.svg";

import { FunctionalButton } from "../functionalButton";
import ConciseTable from "../filesListTable/conciseTable";

import {
  StyledSearch,
  StyledPicklistContainer,
  StyledCancelIcon,
  StyledSearchIcon,
  StyledSearchContainer,
  StyledProjectSelectionContainer,
  StyledUserManagementSelectionContainer,
} from "./styles";

const IncrementalSearchPickList = ({
  add,
  headers,
  actions,
  body,
  conciseLength,
  filterApi,
  filterRequest,
  filteredData,
  setFilteredData,
  addButtonTitle,
  tableWidth,
  handleSelected,
  hover,
  width,
  noAddButton,
  marginTop,
  newButtonWidth = "180px",
  searchWidth,
  height,
  placeholder,
  rerender,
  setRerender,
  noSearch,
  pickedArray,
  paddingRight,
  project,
  disabled,
  internalSearch
}) => {
  const [query, setQuery] = useState("");
  const [internalSearchResults, setInternalSearchResults] = useState(filteredData);

  useEffect(() => {
    setQuery('');
    setInternalSearchResults(filteredData);
  }, [filteredData]);

  useEffect(() => {
    if (internalSearch) {
        setInternalSearchResults(handleSearch(query)); 
    }
     else {
      handleFilter(query);
      if (rerender && setRerender) {
        setRerender(false);
      }
    }
  }, [query, rerender]);

  const handleFilter = async () => {
    if (filterApi) {
      await filterApi(query)
        .then((data) => {
          setFilteredData(data);
        })
        .catch((err) => {});
    } else if (filterRequest) {
      try {
        const data = filterRequest(query);
        setFilteredData(data);
      } catch {}
    }
  };

  // function handleSearchProjects(searchTerm) {
  //   try {
  //     var modifiedSearchTerm = searchTerm.toString();
  //     try {
  //       modifiedSearchTerm = searchTerm.toLowerCase();
  //     } catch {};
  //     if (searchTerm) {
  //       return filteredData.filter(item => {
          
  //         var itemReturned = false;
  //         var itemKeys = Object.keys(item);
          
  //         itemKeys.map((itemKey) => {
            
  //           try {
  //             var stringTerm = item[itemKey];
              
  //             try {
  //               if(stringTerm && typeof stringTerm === "object"){
  //                 var innerKeys = Object.keys(stringTerm)
  //                 innerKeys.map((innerKey) => {
  //                   try{
  //                     var innerStringterm = stringTerm[innerKey]
  //                     try{
  //                       innerStringterm = stringTerm[innerKey].toString();
  //                     }catch{}
  //                     if (innerStringterm.toLowerCase().includes(modifiedSearchTerm)) {
  //                       itemReturned = true;
  //                     };
  //                   }catch{}
  //                 })
  //               }
  //               stringTerm = item[itemKey].toString();
  //             } catch {};
  //             if (stringTerm.toLowerCase().includes(modifiedSearchTerm)) {
  //               itemReturned = true;
  //             };
  //           } catch {};
  //         });
  //         return itemReturned;
  //       });
  //     } else {
  //       return filteredData;
  //     };
  //   } catch {
  //     return filteredData;
  //   };
  // };

  function handleSearch(searchTerm) {
    try {
      var modifiedSearchTerm = searchTerm.toString();
      try {
        modifiedSearchTerm = searchTerm.toLowerCase();
      } catch {};
      if (searchTerm) {
        return filteredData.filter(item => {
          var itemReturned = false;
          var itemKeys = Object.keys(item);
          
          itemKeys.map((itemKey) => {
            try {
              var stringTerm = item[itemKey];
              try {

                if(stringTerm && typeof stringTerm === "object"){
                  var innerKeys = Object.keys(stringTerm)
                  innerKeys.map((innerKey) => {
                    try{
                      var innerStringterm = stringTerm[innerKey]
                      try{
                        innerStringterm = stringTerm[innerKey].toString();
                      }catch{}
                      if (innerStringterm.toLowerCase().includes(modifiedSearchTerm)) {
                        itemReturned = true;
                      };
                    }catch{}
                  })
                }

                stringTerm = item[itemKey].toString();
              } catch {};
              if (stringTerm.toLowerCase().includes(modifiedSearchTerm)) {
                
                itemReturned = true;

              };
            } catch {};
          });
          return itemReturned;
        });
      } else {
        return filteredData;
      };
    } catch {
      return filteredData;
    };
  };

  return (
    <>
      <StyledPicklistContainer width={width} paddingRight={paddingRight}>
        {!noSearch && (
          <StyledSearchContainer noAddButton={noAddButton}>
            <div class="ui search">
              <div
                class="ui left icon input"
                style={{
                  width: `${noAddButton && searchWidth ? searchWidth : "100%"}`,
                }}
              >
                <StyledSearch
                  searchWidth={searchWidth}
                  class="prompt"
                  type="text"
                  placeholder={placeholder}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <StyledSearchIcon src={Search} />
                {query.length > 0 && (
                  <StyledCancelIcon
                    src={cancelIcon}
                    onClick={() => setQuery("")}
                  />
                )}
              </div>
            </div>
          </StyledSearchContainer>
        )}
        {!noAddButton && (
          <FunctionalButton
            buttonTitle={addButtonTitle}
            handleButton={add}
            width={newButtonWidth}
            isReversedColor={true}
            marginRight="0px"
            marginLeft="24px"
            disabled={disabled}
          />
        )}
      </StyledPicklistContainer>
      <StyledProjectSelectionContainer width={width} marginTop={marginTop}>
        {
          (!internalSearch || internalSearchResults && (internalSearchResults.length > 0)) ?
            <ConciseTable
              headers={headers}
              length={conciseLength}
              actions={actions}
              body={body}
              data={ (internalSearch && query && (query !== '')) ? internalSearchResults : filteredData}
              width={tableWidth}
              handleSelected={handleSelected}
              hover={hover}
              height={height}
              pickedArray={pickedArray}
              paddingRight={paddingRight}
            />
          :
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>No results</div>
        }

      </StyledProjectSelectionContainer>
    </>
  );
};

export default IncrementalSearchPickList;
