import React, { useEffect, useState} from 'react';
import { useMediaQuery } from "react-responsive";
import Input from '../../../../components/inputField';
import { COLORS } from '../../../../constants';
import { StyledSubContainer } from '../styles';
import { SectionTitle } from '../SectionTitle';


export const ProjectSetupInfo = ({ data, setData, setHasData }) => {
  const isMobile = useMediaQuery({ maxWidth: 1100 });
  const dateRegex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/\d{4}$/;

  const [title, setTitle] = useState(data?.project_title || '');
  const [number, setNumber] = useState(data?.project_number || '')
  const [location, setLocation] = useState(data?.project_location ||'')
  const [closingDate, setClosingDate] = useState(data?.closing_date || '')

  if (!data) {
    data= {};
  }

  useEffect(() => {
    if (title?.length > 0 &&
        number?.length > 0 &&
        location?.length > 0 &&
        dateRegex.test(closingDate)) {
          data['project_title'] = title;
          data['project_number'] = number;
          data['project_location'] = location;
          data['closing_date'] = closingDate;
          setHasData(true);
          setData(data);
        } else {
          setHasData(false);
        }
  }, [title, number, location, closingDate]);
  // console.log(data);
  return (
    <StyledSubContainer isMobile={isMobile}>
      <SectionTitle title="Project Details" color={COLORS.darkGrey2} />
      <Input
        id='twoThirdInputWrap'
        label="Project Title"
        type="text"
        placeholder="Project Title"
        value={title}
        setValue={setTitle}
        validateFunc={(item) => item.length > 0}
        marginTop={16}
      />
      <Input
        id='oneThirdInputWrap'
        label="Project Number"
        type="text"
        placeholder="Project Number"
        value={number}
        setValue={setNumber}
        validateFunc={(item) => item.length > 0}
        marginTop={16}
      />
      <Input
        id='twoThirdInputWrap'
        label="Project Location"
        type="text"
        placeholder="Project Location"
        value={location}
        setValue={setLocation}
        validateFunc={(item) => item.length > 0}
        marginTop={16}
      />
      <Input
        id='oneThirdInputWrap'
        label="Closing Date"
        type="text"
        placeholder="MM/DD/YYYY"
        value={closingDate}
        setValue={setClosingDate}
        validateFunc={(item) => {
          return dateRegex.test(item);
        }}
        marginTop={16}
      />
    </StyledSubContainer>
  );
};
