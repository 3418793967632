import React, { useState, useEffect } from 'react';
import { useMediaQuery } from "react-responsive";
import { StyledInputContainer } from '../styles';
import { YearsNumber } from '../components/YearsNumber';
import { StyledYear, StyledSafetyRecords } from './styles';
import TextField from "../../../components/textField";


export const SafetyRecords = ({
  data,
  setCollectedData,
  setHasAnnualRecords,
 }) => {
  const isMobile = useMediaQuery({ maxWidth: 1100 });
  const label = [
    'Industry Classification Code, or Equivalent',
    'Industry Base Rate',
    'Company’s Experience Rate',
    'Fatalities (Actual Number)',
    'Lost Time Incident Frequency (LTIF) Rate'
  ]

  const years = data.years;
  const [err1, setErr1] = useState(false);
  const [err2, setErr2] = useState(false);
  const [err3, setErr3] = useState(false);

  useEffect(() => {
    const collectedData = data.years;
    if (
      collectedData?.classCode2020?.length > 0 &&
      collectedData?.expRate2020 &&
      String(collectedData?.expRate2020).length > 0 &&
      collectedData?.baseRate2020 &&
      String(collectedData?.baseRate2020).length > 0 &&
      collectedData?.lostRate2020 &&
      String(collectedData?.lostRate2020).length > 0  &&
      collectedData?.fatalities2020 &&
      String(collectedData?.fatalities2020).length > 0
    ) {
      setErr1(false);
    } else {
      setErr1(true);
    }

    if (
      collectedData?.classCode2019?.length > 0 &&
      collectedData?.expRate2019 &&
      String(collectedData?.expRate2019).length > 0 &&
      collectedData?.baseRate2019 &&
      String(collectedData?.baseRate2019).length > 0 &&
      collectedData?.lostRate2019 &&
      String(collectedData?.lostRate2019).length > 0  &&
      collectedData?.fatalities2019 &&
      String(collectedData?.fatalities2019).length > 0
    ) {
      setErr2(false);
    } else {
      setErr2(true);
    }

    if (
      collectedData?.classCode2018?.length > 0 &&
      collectedData?.expRate2018 &&
      String(collectedData?.expRate2018).length > 0 &&
      collectedData?.baseRate2018 &&
      String(collectedData?.baseRate2018).length > 0 &&
      collectedData?.lostRate2018 &&
      String(collectedData?.lostRate2018).length > 0  &&
      collectedData?.fatalities2018 &&
      String(collectedData?.fatalities2018).length > 0
    ) {
      setErr3(false);
    } else {
      setErr3(true);
    }

    setHasAnnualRecords(err1 || err2 || err3)
  });


  return (
    <StyledSafetyRecords isMobile={isMobile}>
      <StyledInputContainer>
        <StyledYear className={'narrowInputWrap'}>2020</StyledYear>
        <YearsNumber
          label={label}
          year="2020"
          classificationCode={years?.classCode2020}
          baseRate={years?.baseRate2020}
          experienceRate={years?.expRate2020}
          facilities={years?.fatalities2020}
          incidentRate={years?.lostRate2020}
          setCollectedData={setCollectedData}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledYear className={'narrowInputWrap'}>2019</StyledYear>
        <YearsNumber
          year="2019"
          classificationCode={years?.classCode2019}
          baseRate={years?.baseRate2019}
          experienceRate={years?.expRate2019}
          facilities={years?.fatalities2019}
          incidentRate={years?.lostRate2019}
          setCollectedData={setCollectedData}
        />
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledYear className={'narrowInputWrap'}>2018</StyledYear>
        <YearsNumber
          year="2018"
          classificationCode={years?.classCode2018}
          baseRate={years?.baseRate2018}
          experienceRate={years?.expRate2018}
          facilities={years?.fatalities2018}
          incidentRate={years?.lostRate2018}
          setCollectedData={setCollectedData}
        />
      </StyledInputContainer>
      <StyledYear>Remarks</StyledYear>
      <TextField
        width={isMobile? '100%' : '97%'}
        placeholder="Description"
        withoutMargin={true}
        value={years?.safetyRemarks}
        setValue={(val) => setCollectedData('years.safetyRemarks', val)}
        validateFunc={(item) => true}
        />
    </StyledSafetyRecords>
  );
};
