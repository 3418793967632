import React, { useEffect, useState } from "react";

import { StyledTableCell } from "../../../components/filesListTable/styles";
import { AddFinancialPopup } from "../../../components/addFinancialPopup";
import { ThreeDotsPopupMenu } from "../../../components/threeDotsPopupMenu";
import FilesListTable from "../../../components/filesListTable";
import {
  getSingleFinancial,
  deleteSingleFinancial,
  saveSingleFinancial,
} from "../../../api/profile";
import UncheckedRadioButton from "assets/dashboardIcons/uncheckedRadioButton";
import CheckedRadioButton from "assets/dashboardIcons/checkedRadioButton";
import { PlaceholderText } from "../styles";
import {
  StyledAddText,
  TableArea,
  AddSignArea,
  StyledAddFinancialText,
} from "./styles";
import PlusSign from "../../../assets/onBoarding/plusSign";
import { truncateString } from "../../../common/truncateString";
import { MobileColumnContent, MobileColumnText } from "../detailScreens/styles";
import { RadioButton } from "components/radioButton";
import { setDefaultFinancial } from "api/profile";

export const FinancialList = ({
  data,
  setData,
  attribute,
  handleGetInfo,
  isMobile,
  institution_id,
  currentDashboardUrl,
}) => {
  const headers = [
    { title: "Default" },
    { title: attribute === "bank" ? "Bank Name" : "Company Name" },
    { title: "Address" },
    { title: "Contact Person" },
    { title: "" },
  ];

  const [openPopup, setOpenPopup] = useState(false);
  const [financial, setFinancial] = useState({ type: attribute });
  const [header, setHeader] = useState(headers);
  let popupTitle;

  if (attribute === "bank") {
    popupTitle = "Bank";
  } else if (attribute === "bonding") {
    popupTitle = "Bonding";
  } else if (attribute === "ai") {
    popupTitle = "Automobile Liability Insurance";
  } else if (attribute === "ci") {
    popupTitle = "Contractor Equipment Insurance";
  } else if (attribute === "insurance") {
    popupTitle = "Insurance";
  }

  const onAdd = () => {
    setOpenPopup(true);
  };
  const addFinancial = (props) => {
    let newProps = { ...props };
    if (
      institution_id &&
      currentDashboardUrl === "/service-provider-dashboard"
    ) {
      newProps = { ...newProps, institution_id: institution_id };
    }
    saveSingleFinancial(newProps, onAddSuccess, () => {});
  };

  const onAddSuccess = () => {
    setFinancial({ type: attribute });
    handleGetInfo();
  };

  const editFunc = (id) => {
    getSingleFinancial(id, onEditSuccess, () => {});
  };

  const onEditSuccess = (res) => {
    setFinancial(res);
    setOpenPopup(true);
  };

  const deleteFunc = (id) => {
    deleteSingleFinancial(id, onDeleteSuccess, () => {});
  };

  const onDeleteSuccess = () => {
    handleGetInfo();
  };

  useEffect(() => {
    if (attribute === "insurance") {
      headers.splice(4, 4, { title: "Insurance Type" });
      setHeader(headers);
    }
  }, []);

  useEffect(() => {
    if (!openPopup) {
      setFinancial({ type: attribute });
    }
  }, [openPopup]);

  const handleRadioButton = (bodyData) => {
    const temp = data?.map((e) => {
      e["default"] = e["default"] ? false : e["default"];
      return e;
    });
    temp[bodyData.index]["default"] = true;
    let inputData = {};
    if (
      institution_id &&
      currentDashboardUrl === "/service-provider-dashboard"
    ) {
      inputData = { institution_id: institution_id };
    }
    setData(attribute, temp);
    setDefaultFinancial(
      bodyData.id,
      attribute,
      inputData,
      () => {},
      () => {}
    );
  };

  const getArrayOfValues = (state, key) => {
    let valueArray = [];
    if (state) {
      Object.entries(state).forEach(([key1, value]) =>
        valueArray.push(value[key])
      );
    }

    return valueArray;
  };
  const body = (bodyData) => {
    let insuranceType =
      attribute === "insurance"
        ? getArrayOfValues(bodyData?.insuranceDetails?.state, "type")
        : [];

    return (
      <>
        {isMobile && (
          <>
            <StyledTableCell component={"tr"} width="5%">
              <RadioButton
                isChecked={bodyData.default}
                checkedIcon={<CheckedRadioButton />}
                unCheckedIcon={<UncheckedRadioButton />}
                value={bodyData.default}
                handleChange={() => handleRadioButton(bodyData)}
              />
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="95%">
              <MobileColumnContent direction="column">
                <MobileColumnText>
                  {truncateString(bodyData.company_name, 30)}
                </MobileColumnText>
                <MobileColumnText fontSize="12px" fontWeight="400">
                  {truncateString(bodyData.company_address, 35)}
                </MobileColumnText>
              </MobileColumnContent>
            </StyledTableCell>
          </>
        )}
        {!isMobile && (
          <>
            <StyledTableCell component={"tr"} width="5%" align="center">
              <RadioButton
                isChecked={bodyData.default}
                checkedIcon={<CheckedRadioButton />}
                unCheckedIcon={<UncheckedRadioButton />}
                value={bodyData.default}
                handleChange={() => handleRadioButton(bodyData)}
              />
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="35%">
              {bodyData.company_name}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="30%">
              {bodyData.company_address}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="30%">
              {`${bodyData.contact_first_name}${
                bodyData.contact_first_name && bodyData.contact_last_name
                  ? " "
                  : ""
              }${bodyData.contact_last_name}`}
            </StyledTableCell>
            {attribute === "insurance" && (
              <StyledTableCell component={"tr"} width="30%">
                {` ${insuranceType.toString()}`}
              </StyledTableCell>
            )}
          </>
        )}
        <StyledTableCell component={"tr"} align="right" width="5%">
          <ThreeDotsPopupMenu
            item={bodyData}
            handleModify={editFunc}
            handleDelete={deleteFunc}
          />
        </StyledTableCell>
      </>
    );
  };

  return (
    <>
      <TableArea isMobile={isMobile}>
        <FilesListTable
          headers={isMobile ? "" : header}
          body={body}
          data={data}
        />
        {!(data?.length > 0) && (
          <PlaceholderText hasBorderTop={true}>No Information</PlaceholderText>
        )}
        <StyledAddFinancialText onClick={onAdd}>
          {attribute == "bank" ? "+ Add Bank" : "+ Add Company"}
        </StyledAddFinancialText>
        {/* <AddSignArea>
          <PlusSign enableAdd={true} onAdd={onAdd}/>
          <StyledAddText onClick={onAdd}>Add New</StyledAddText>
        </AddSignArea> */}
      </TableArea>
      <AddFinancialPopup
        openPopup={openPopup}
        data={financial}
        setData={setFinancial}
        setOpenPopup={setOpenPopup}
        handleButtonFunc={addFinancial}
        isMobile={isMobile}
        popupTitle={popupTitle}
      />
    </>
  );
};
