import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { saveOnboarding } from '../../../api/onboarding';
import { logoutUser } from "../../../data/actions/user";
import { FunctionalButton } from '../../../components/functionalButton';

import {
  ImagePopup,
  LargeText,
  SmallText,
  ButtonArea,
  PopupContainer,
} from './styles';
import { Popup } from '../../../components/popup';
import Cone from "../../../assets/onBoarding/Cone";

export const ExitPopup = ({
  openPopup,
  setOpenPopup,
  onboardingData,
  isMobile,
  progress
}) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const handleContinue = () => {
    setOpenPopup(false);
  };

  const handleExit =  () => {
     saveOnboarding(onboardingData, onSuccess, onFailure);
  };

  
  const onSuccess = (data) => {
    dispatch(logoutUser());
    // if(data.status == "active"){
      // history.push("/");
    // } 
    // else {
    //   dispatch(logoutUser());
    // }
  }

  const onFailure = () => {
    history.push("/");
  }
  
  return (
    <Popup
        open={openPopup}
        setOpen={setOpenPopup}
        width="640px"
        height="498px"
        dialogContent={(
          <>
            <PopupContainer>
              <ImagePopup>
                <Cone isMobile={isMobile} />
              </ImagePopup>
              <LargeText isMobile={isMobile}>Are you sure you want to logout?</LargeText>
              <SmallText>
                Your profile is {progress}/3 completed.
              </SmallText>
            </PopupContainer>
            <ButtonArea isMobile={isMobile}>
              <FunctionalButton
                buttonTitle="NO THANKS!"
                width="250px"
                height="53px"
                marginRight={isMobile ? "0px" : "16px"}
                isReversedColor={true}
                handleButton={handleContinue}
              />
              <FunctionalButton
                buttonTitle={"SAVE & LOGOUT"}
                width="250px"
                height="53px"
                marginRight="0px"
                marginTop={isMobile ? "16px" : "0px"}
                handleButton={handleExit}
              />
            </ButtonArea>
        </>
        )}
      />
  )
}
