import React from 'react';

const MinusSignIcon = ({ fill, height }) => {
  return (
    <svg width={height || '8'} height={height || '2'} viewBox="0 0 8 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.31606 0.25C7.69379 0.25 8 0.585786 8 1C8 1.41421 7.69379 1.75 7.31606 1.75H0.683938C0.306209 1.75 0 1.41421 0 1C0 0.585786 0.306209 0.25 0.683938 0.25H7.31606Z" fill={fill || "#1A051D"} />
    </svg>
  )
};

export default MinusSignIcon;
