import React from 'react';

const ScoreExecution = () => {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: 6}}>
    <path d="M16.3756 9.94012C16.3088 9.91241 16.2458 9.87615 16.1883 9.83225C14.7893 8.43749 13.3913 7.04133 11.994 5.64377C11.7467 5.39699 11.6257 5.11758 11.722 4.77187C11.7583 4.63485 11.8289 4.50931 11.9271 4.40709C12.0253 4.30486 12.1479 4.2293 12.2834 4.18752C12.4189 4.14573 12.5627 4.13909 12.7014 4.16823C12.8402 4.19737 12.9692 4.26132 13.0764 4.35407C13.1211 4.39196 13.1622 4.43511 13.2043 4.47668L18.8993 10.1722C19.3634 10.6363 19.3618 11.0888 18.895 11.5555C16.9878 13.4635 15.0803 15.3716 13.1727 17.28C12.9222 17.5304 12.6407 17.6583 12.285 17.5604C12.15 17.5234 12.0264 17.4531 11.9256 17.3559C11.8248 17.2587 11.75 17.1378 11.7081 17.0042C11.6662 16.8706 11.6585 16.7286 11.6858 16.5912C11.713 16.4539 11.7743 16.3256 11.8641 16.2181C11.9099 16.1655 11.963 16.1129 12.014 16.0602C13.4018 14.6722 14.7899 13.2846 16.1783 11.8976C16.2332 11.8502 16.2918 11.8075 16.3535 11.7697L16.3214 11.6965H16.1062C11.3274 11.6965 6.54849 11.6965 1.76962 11.6965C1.645 11.7024 1.52011 11.695 1.39707 11.6744C1.20209 11.6281 1.03028 11.5131 0.912973 11.3506C0.795664 11.1881 0.74067 10.9889 0.758022 10.7892C0.775373 10.5895 0.863913 10.4028 1.0075 10.2629C1.15108 10.1231 1.34014 10.0395 1.5402 10.0275C1.62544 10.0227 1.71068 10.0238 1.79592 10.0238H16.3078L16.3756 9.94012Z" fill="#B9B9C3"/>
    </svg>


  )
};

export default ScoreExecution;
