import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { StyledContainer } from "./styles";
import { AllProjectTable } from "./components/allProjects";
import { getAllProjects } from "../../api/buyers";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { BuyerHeader } from "./components/BuyerHeader";
import { GoBackButton } from "./components/goBackButton";

import { institutionRfpqsSelector } from "../../data/selectors/buyers";

const BuyersAllProjects = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  let history = useHistory();
  const [data, setData] = useState("");
  const institutionRfpqs = useSelector(institutionRfpqsSelector);

  useEffect(() => {
    if (institutionRfpqs) {
      setData(institutionRfpqs);
    }
  }, [institutionRfpqs]);

  const handleStartNew = () => {
    history.push("/rfpq");
  };

  const handleGoBack = () => {
    history.push("/");
  };

  return (
    <StyledContainer isMobile={isMobile}>
      {/* <GoBackButton handleClick={handleGoBack} /> */}
      <BuyerHeader
        title="Requests for Proposal"
        hasButton={true}
        handleButton={handleStartNew}
      />
      {data && <AllProjectTable data={data} setData={setData} />}
    </StyledContainer>
  );
};

export default BuyersAllProjects;
