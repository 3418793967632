import React, { useEffect, useState } from "react";
import { StyledTableCell } from "../../../components/filesListTable/styles";
import ConciseTable from "../../../components/filesListTable/conciseTable";
import { ThreeDotsPopupMenu } from "../../../components/threeDotsPopupMenu";
import { useHistory } from "react-router-dom";
import { StyledExpiredContainer, StyledLiveContainer} from '../styles'

import {
  StyledProjectSelectionContainer,
  MobileColumnContent,
  MobileColumnText
} from "../styles";

import { truncateString } from "../../../common/truncateString";

export const ClientList = ({ filteredData, editFunc, disableFunc, enableFunc, isMobile }) => {
  const headers = [
    { title: "ID" },
    { title: "Email" },
    { title: "Institution Name" },
    { title: "Status", align: "center" },
    { title: "" },
  ];

  const [data, setData] = useState("");
  let history = useHistory();




  const body = (bodyData) => {
    return (
      <>
        {isMobile && (
          <StyledTableCell component={"tr"} width="90%">
            <MobileColumnContent direction="column">
              <MobileColumnText>
                {truncateString(bodyData?.email, 28)}
              </MobileColumnText>
              <MobileColumnText fontSize="12px" fontWeight="400">
                {truncateString(bodyData?.institution, 30)}
              </MobileColumnText>
            </MobileColumnContent>
          </StyledTableCell>
        )}
        {!isMobile && (
          <>
            <StyledTableCell component={"tr"} width="5%">
              {bodyData?.id}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="40%">
              {bodyData?.email}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="45%">
              {bodyData?.institution}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="10%">
             {bodyData?.status === "inactive" ? (
                <StyledExpiredContainer>Inactive</StyledExpiredContainer>
              ) : bodyData?.status === "active" ? (
                <StyledLiveContainer>Active</StyledLiveContainer>
              ) : (
                ""
              )}
            </StyledTableCell>
          </>
        )}
        <StyledTableCell component={"tr"} align="right">
          <ThreeDotsPopupMenu
            item={{ ...bodyData, id: bodyData?.id }}
            removeEdit={"disableEnableClient"}
            data={filteredData}
            handleModify={editFunc}
            handleDisable={disableFunc}
            handleEnable={enableFunc}
          />
        </StyledTableCell>
      </>
    );
  };

  return (
    <StyledProjectSelectionContainer isMobile={isMobile}>
      <ConciseTable
        headers={isMobile ? "" : headers}
        length={isMobile ? 6 : 6.5}
        body={body}
        data={filteredData}
      />
    </StyledProjectSelectionContainer>
  );
};
