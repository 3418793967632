import styled from "styled-components";

export const FileButton = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    // margin-top: 5px;
    width: 100%;
    max-width: 150px;
    height: 40px;
    background-color: ${(props) => (props.inverse ? "#FF6D1D" : "white")};
    border: 1.5px solid #ff6d1d;
    box-sizing: border-box;
    border-radius: 6px;
    color: white;

    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;

    text-align: center;
    text-transform: uppercase;

    color: ${(props) => (props.inverse ? "white" : "#FF6D1D")};
    &:hover {
    background-color: ${(props) => (props.inverse ? "white" : "#FF6D1D")};
    color: ${(props) => (props.inverse ? "#FF6D1D" : "white")};
    },
`;

export const InputLabel = styled.div`
  margin-top: 25px;
  margin-bottom: 5px;

  display: flex;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;

  color: #000000;
`;

export const FileLabel = styled.div`
  margin-left: 25px;
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;

  color: #ff6d1d;
`;
