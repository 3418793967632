import React, { useState } from "react";
import { MediumText, RegularText, StyledBox, SmallText } from "../styles";
import { useHistory } from "react-router";
import { FunctionalButton } from "../../../components/functionalButton";
import {
  FunctionArea,
  IntroArea,
  StyledTitle,
  ButtonContainer,
  ComingSoonImg,
  ImgDiv,
} from "./styles";

import recommendationComingSoon from "assets/dashboardIcons/recommendationComingSoon.svg";
import InsightsIcon from "assets/dashboardIcons/InsightsIcon";

export const Insights = ({ isMobile, userData }) => {
  const [comingSoon, setComingSoon] = useState(true);
  let history = useHistory();
  const handleRec = () => {};

  const handlePortfolioInsights = () => {
    history.push("/underwriter/portfolioinsights");
  };

  return (
    <FunctionArea isMobile={isMobile}>
      {isMobile && (
        <StyledBox
          boxHeight="110px"
          borderRadius="15px"
          isMobile={isMobile}
          onClick={handleRec}
          style={{ cursor: "pointer" }}
        >
          {comingSoon && (
            <>
              <InsightsIcon width={55} height={59} isMobile={isMobile} />
              <StyledTitle>
                <SmallText>Coming Soon</SmallText>
              </StyledTitle>
            </>
          )}
          {!comingSoon && (
            <>
              <InsightsIcon width={55} height={59} isMobile={isMobile} />
              <StyledTitle>
                <SmallText>Coming Soon</SmallText>
              </StyledTitle>
            </>
          )}
        </StyledBox>
      )}
      {!isMobile && (
        <>
          <StyledBox boxHeight="216px">
            {comingSoon && (
              <>
                <IntroArea>
                  <InsightsIcon />
                  {/* <StyledTitle>
                    <SmallText>Coming Soon</SmallText>
                  </StyledTitle> */}
                </IntroArea>
                <ButtonContainer>
                  <FunctionalButton
                    buttonTitle="Portfolio Insights"
                    width="80%"
                    marginRight="0px"
                    isReversedColor={true}
                    hasBoxShadow={true}
                    handleButton={handlePortfolioInsights}
                    disabled={false}
                  />
                </ButtonContainer>
              </>
            )}
            {!comingSoon && (
              <>
                <IntroArea>
                  <InsightsIcon />
                  <StyledTitle>
                    <SmallText>Coming Soon</SmallText>
                  </StyledTitle>
                </IntroArea>
                <ButtonContainer>
                  <FunctionalButton
                    buttonTitle="Add"
                    handleButton={handleRec}
                    width="80%"
                    marginRight="0px"
                    isReversedColor={true}
                    hasBoxShadow={true}
                  />
                </ButtonContainer>
              </>
            )}
          </StyledBox>
        </>
      )}
    </FunctionArea>
  );
};
