import { InfoArea, SectionText } from "./styles";
import React, { useEffect, useState } from "react";
import { BankAdditionalfields } from "./bankAdditionalfields";
import AddIcon from "@mui/icons-material/Add";
import { BondingLimits } from "./bondingLimits";
import { isEmpty } from "lodash";
import { InsuranceTypeAndLimit } from "./insuranceTypeAndLimit";
import { FacilityType, InsuranceType } from "../../constants";
import { getInsuranceTypes } from "api/external_data";

export const Step3 = ({ addData, setAddData, isMobile }) => {
  const [insuranceType, setInsuranceType] = useState([]);
  const [allInsuraceType, setAllInsuraceType] = useState([]);
  useEffect(() => {
    getInsuranceTypes()
      .then((res) => {
        setInsuranceType(res);
        setAllInsuraceType(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);
  const getInitialfacilityValue = () => {
    return {
      order: [0],
      state: {
        0: {
          amount: null,
          currency: null,
          outstanding_balance: null,
          type: null,
        },
      },
    };
  };

  const getInitialInsuranceValue = () => {
    return {
      order: [0],
      state: {
        0: {
          type: null,
          currency: null,
          aggregate_limit: null,
          per_occurence: null,
          effective_date: null,
          expiry_date: null,
        },
      },
    };
  };

  const addOtherfacility = () => {
    try {
      let max = Math.max(...addData.facility?.order);
      let nextElementToAdd = [...addData.facility.order, max + 1];
      setAddData("facility.order", nextElementToAdd);
      setAddData(
        `facility.state.${max + 1}`,
        getInitialfacilityValue().state[0]
      );
    } catch (e) {
      console.log("Not able to add the array");
    }
  };

  useEffect(() => {
    if (addData?.type === "bank") {
      if (isEmpty(addData?.facility?.order)) {
        setAddData("facility", getInitialfacilityValue());
      }
    }
    if (addData?.type !== "bank" && addData?.type !== "bonding") {
      if (isEmpty(addData?.insuranceDetails?.order)) {
        setAddData("insuranceDetails", getInitialInsuranceValue());
      }
    }
  }, []);

  const addOtherInsuranceType = () => {
    try {
      let max = Math.max(...addData.insuranceDetails?.order);
      let nextElementToAdd = [...addData.insuranceDetails.order, max + 1];
      setAddData("insuranceDetails.order", nextElementToAdd);
      setAddData(
        `insuranceDetails.state.${max + 1}`,
        getInitialInsuranceValue().state[0]
      );
    } catch (e) {
      console.log("Not able to add the array");
    }
  };
  return (
    <InfoArea isMobile={isMobile} marginTop="0px">
      {addData?.type !== "bank" && addData?.type !== "bonding" && (
        <>
          <SectionText> Type and Limits</SectionText>
          {addData?.insuranceDetails?.order.map((index) => (
            <InsuranceTypeAndLimit
              key={index}
              index={index}
              setAddData={setAddData}
              addData={addData}
              insuranceType={insuranceType}
              setInsuranceType={setInsuranceType}
              allInsuraceType={allInsuraceType}
            />
          ))}
          {addData?.insuranceDetails?.order?.length <= InsuranceType.length && (
            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                onClick={addOtherInsuranceType}
                // onMouseOver=()
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#FF6D1D",
                  cursor: "pointer",
                }}
              >
                <AddIcon />
                Type and Limits
              </div>
            </div>
          )}
        </>
      )}
      {addData?.type === "bank" && (
        <>
          <SectionText> Facility Details</SectionText>
          {addData?.facility?.order?.map((index) => (
            //<div key={index}>{id}</div>

            <BankAdditionalfields
              key={index}
              index={index}
              setAddData={setAddData}
              addData={addData}
            />
          ))}
          {addData?.facility?.order?.length <= FacilityType.length && (
            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                onClick={addOtherfacility}
                // onMouseOver=()
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#FF6D1D",
                  cursor: "pointer",
                }}
              >
                <AddIcon />
                Facility
              </div>
            </div>
          )}
        </>
      )}

      {addData?.type === "bonding" && (
        <>
          <SectionText> Bonding Limits</SectionText>
          <BondingLimits addData={addData} setAddData={setAddData} />
        </>
      )}
    </InfoArea>
  );
};
