import axios from "axios";
import { API_HOST } from "../constants";

let headers = {};
const getAuth = () => {
  const authData = localStorage.getItem("Authorization");
  if (authData) {
    headers = { Authorization: authData };
  }
};

export const getCCDCs = async (query) => {
  getAuth();
  return await axios
    .get(`${API_HOST}/prequalify/CCDCs?filter=${query}`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const searchProject = async (
  title,
  number,
  location,
  onSuccess,
  onFailure
) => {
  getAuth();
  return await axios
    .get(
      `${API_HOST}/prequalify/search?title=${title}&number=${number}&location=${location}`,
      { headers }
    )
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const filterProjects = async (query, data) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/prequalify/projects?filter=${query}`, data, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const filterPersonnels = async (query, data) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/prequalify/personnel?filter=${query}`, data, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const filterBank = async (query, data) => {
  getAuth();
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/prequalify/bank?filter=${query}`, data, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const filterBonding = async (query, data) => {
  getAuth();
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/prequalify/bonding?filter=${query}`, data, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const filterInsuranceGi = async (query, data) => {
  getAuth();
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/prequalify/insurance/gi?filter=${query}`, data, {
      headers,
    })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const filterInsuranceAi = async (query) => {
  getAuth();
  headers["Content-Type"] = "application/json";
  return await axios
    .get(`${API_HOST}/prequalify/insurance/ai?filter=${query}`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const filterInsuranceCi = async (query) => {
  getAuth();
  headers["Content-Type"] = "application/json";
  return await axios
    .get(`${API_HOST}/prequalify/insurance/ci?filter=${query}`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};

export const openProjects = async (query) => {
  getAuth();
  headers["Content-Type"] = "application/json";
  return await axios
    .get(`${API_HOST}/prequalify/openprojects?filter=${query}`, { headers })
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
    });
};
export const getSingleProject = async (id, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .get(`${API_HOST}/prequalify/singleproject/edit/${id}`, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const deleteSingleProject = async (id, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/prequalify/singleproject/delete/${id}`, {}, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const getSinglePersonnel = async (id, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .get(`${API_HOST}/prequalify/singlepersonnel/edit/${id}`, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const deleteSinglePersonnel = async (id, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(
      `${API_HOST}/prequalify/singlepersonnel/delete/${id}`,
      {},
      { headers }
    )
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const deleteSingleCCDC = async (id, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/prequalify/singleccdc/delete/${id}`, {}, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const reviewSingleCCDC = async (id, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/prequalify/singleccdc/review/${id}`, {}, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const downloadSingleCCDC = async (id, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/prequalify/singleccdc/download/${id}`, {}, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      // onFailure(err.response)
    });
};

export const tamplateSingleCCDC = async (id, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/prequalify/singleccdc/template/${id}`, {}, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const ccdcFormatRemaining = async (
  id,
  data = {},
  onSuccess,
  onFailure
) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/prequalify/ccdcformatremaining/${id}`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const pledgxFormatRemaining = async (
  id,
  data = {},
  onSuccess,
  onFailure
) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/prequalify/pledgxformatremaining/${id}`, data, {
      headers,
    })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const existingCORS = async (id, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .get(`${API_HOST}/cors/exist/${id}`, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};
