import React from "react";
import { Content, StyledArea } from "../styles";
import MobileProjectsIcon from "../../../assets/dashboardIcons/mobileProjectsIcon";
import { useHistory } from "react-router";
import { FunctionArea, MobileBoxText } from "./styles";
import { StyledBox } from "../styles";
import { ProgressPayment } from "./ProgressPayment";
import { ChangeOrder } from "./ChangeOrder";
import { StatusReport } from "./StatusReport";

export const FunctionSection = ({ isMobile, userData, selectedTab }) => {
  const history = useHistory();

  return (
    <Content isMobile={isMobile}>
      <StyledArea marginTop="24px" isMobile={isMobile}>
        <ProgressPayment isMobile={isMobile} selectedTab={selectedTab} />
        <ChangeOrder
          isMobile={isMobile}
          userData={userData}
          selectedTab={selectedTab}
        />
        <StatusReport isMobile={isMobile} selectedTab={selectedTab} />
      </StyledArea>
    </Content>
  );
};
