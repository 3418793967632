import styled from "styled-components";
import { Container } from "components/common/index.js";
import { COLORS, TYPOGRAPHY } from 'constants.js';
import Typography from '@material-ui/core/Typography';

export const MediumText = styled(Typography)`
  && {
    font-family:  ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.isMobile ? "14px" : "18px"};
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
    color:  ${props => props.isMobile ? `${COLORS.textGrey}` : "black"};
  }
`;
export const StyledContainer = styled(Container)`
  && {
    padding-bottom: 100px;
    margin: 0 ${(props) => (props.isMobile ? "0px" : "10px")};
    width: 100%;
  }
`;

// submission deatil page

export const sectionTitle = styled.div``;

export const StyledSectionHeader = styled.div`
  font-family: SF Pro Text;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

export const StyledFinancialContainer = styled.div``;
export const StyledWhiteContainer = styled.div`
  background: #ffffff;
  height: 456px;
  width: 1080px;
`;
