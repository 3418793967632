import React, { useEffect, useState } from "react";
import "./styles.css";
import { LargeText } from "../styles";

import updateObjectByPath from "../../../common/updateObjectByPath";
import { getFinancialInfo } from "../../../api/profile";
import { FinancialList } from "./FinancialList";
import FilledAccordion from "../../../components/filledAccordion";

import { FieldArea, StyledRow, SectionText, SetupContainer } from "./styles";

export const FinancialInfo = ({
  isMobile,
  institution_id,
  currentDashboardUrl,
}) => {
  const [data, setData] = useState({});
  let apiData = {};
  useEffect(() => {
    handleGetInfo();
  }, []);

  const handleGetInfo = () => {
    if (
      institution_id &&
      currentDashboardUrl === "/service-provider-dashboard"
    ) {
      apiData = { institution_id: institution_id };
    }
    getFinancialInfo(
      apiData,
      (res) => setData({ ...res }),
      () => {}
    );
  };

  const ventureOptions = [
    "Joint Venture",
    "Corporation",
    "Partnership",
    "Registered",
    "Sole Proprietor",
    "Other",
  ];

  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };
  console.log(institution_id, "   ", currentDashboardUrl);

  return (
    <SetupContainer isMobile={isMobile}>
      <StyledRow justifyContent="space-between">
        <LargeText isMobile={isMobile}>
          {isMobile
            ? "Edit Financial Information"
            : "Edit your Financial Information"}
        </LargeText>
      </StyledRow>
      <FieldArea>
        {isMobile && (
          <>
            <FilledAccordion
              header="Bank Information"
              details={
                <FinancialList
                  data={data?.bank}
                  attribute="bank"
                  setData={onChangeData}
                  handleGetInfo={handleGetInfo}
                  isMobile={isMobile}
                  institution_id={institution_id}
                  currentDashboardUrl={currentDashboardUrl}
                />
              }
            />
            <FilledAccordion
              header="Bonding Information"
              details={
                <FinancialList
                  data={data?.bonding}
                  attribute="bonding"
                  setData={onChangeData}
                  handleGetInfo={handleGetInfo}
                  isMobile={isMobile}
                  institution_id={institution_id}
                  currentDashboardUrl={currentDashboardUrl}
                />
              }
            />
            <FilledAccordion
              header="Insurance Information"
              details={
                <FinancialList
                  data={data?.insurance}
                  attribute="insurance"
                  setData={onChangeData}
                  handleGetInfo={handleGetInfo}
                  isMobile={isMobile}
                  institution_id={institution_id}
                  currentDashboardUrl={currentDashboardUrl}
                />
              }
            />
            {/* <FilledAccordion
              header="Automobile Insurance Information"
              details={
                <FinancialList
                  data={data?.ai}
                  attribute="ai"
                  setData={onChangeData}
                  handleGetInfo={handleGetInfo}
                  isMobile={isMobile}
                />
              }
            />
            <FilledAccordion
              header={"Broad Form Contractor’s Equipment Insurance Information"}
              details={
                <FinancialList
                  data={data?.ci}
                  attribute="ci"
                  setData={onChangeData}
                  handleGetInfo={handleGetInfo}
                  isMobile={isMobile}
                />
              }
            /> */}
          </>
        )}
        {!isMobile && (
          <>
            <SectionText>Bank Information</SectionText>
            <FinancialList
              data={data?.bank}
              attribute="bank"
              setData={onChangeData}
              handleGetInfo={handleGetInfo}
              institution_id={institution_id}
              currentDashboardUrl={currentDashboardUrl}
            />
            <SectionText marginTop="56px">Bonding Information</SectionText>
            <FinancialList
              data={data?.bonding}
              attribute="bonding"
              setData={onChangeData}
              handleGetInfo={handleGetInfo}
              institution_id={institution_id}
              currentDashboardUrl={currentDashboardUrl}
            />
            <SectionText marginTop="56px">Insurance Information</SectionText>
            <FinancialList
              data={data?.insurance}
              attribute="insurance"
              setData={onChangeData}
              handleGetInfo={handleGetInfo}
              institution_id={institution_id}
              currentDashboardUrl={currentDashboardUrl}
            />
            {/* <SectionText marginTop="56px">Automobile Insurance Information</SectionText>
            <FinancialList 
              data={data?.ai} 
              attribute="ai"
              setData={onChangeData} 
              handleGetInfo={handleGetInfo}
            />
            <SectionText marginTop="56px">{"Broad Form Contractor’s Equipment Insurance Information"}</SectionText>
            <FinancialList 
              data={data?.ci} 
              attribute="ci"
              setData={onChangeData} 
              handleGetInfo={handleGetInfo}
            /> */}
          </>
        )}
      </FieldArea>
    </SetupContainer>
  );
};
