import React, { useEffect, useState } from "react";
import { StyledTableCell } from '../../../components/filesListTable/styles';
import ConciseTable from '../../../components/filesListTable/conciseTable';
import { FunctionalButton } from '../../../components/functionalButton/index';
import { AddInsuranceTypePopup } from "../../../components/addInsuranceTypePopup";
import { getInsuranceTypes } from "../../../api/external_data";
import { StyledProjectSelectionContainer, HeaderArea } from '../styles';
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";


export default function DataMaintananceInsuranceType(editFunc,
  deleteFunc,
  isMobile) {

  const headers = [
    { title: 'Id',width:"20%"},
    { title: 'Type',width:"40%"},
    { title: 'Short Form',width:"40%"}
  ];

  const [openPopup, setOpenPopup] = useState(false);
  const [insuranceTypes, setInsuranceTypes] = useState([]);
  const [internalSearchResults, setInternalSearchResults] = useState(insuranceTypes);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getInsuranceTypes()
      .then((res) => {
        setInsuranceTypes(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  const handleAdd = () => {
    setOpenPopup(true);
  };

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCell component={'tr'} width="20%">
          {bodyData.id}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="40%">
          {bodyData.type}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="40%">
          {bodyData.short_form}
        </StyledTableCell>
      </>

    );
  };


  return (
    <StyledProjectSelectionContainer isMobile={isMobile}>

      <HeaderArea width="100%">
        <SearchBar
          filteredData={insuranceTypes}
          setInternalSearchResults={setInternalSearchResults}
          query={query}
          setQuery={setQuery}
          internalSearch={true}
        />
        <FunctionalButton
          buttonTitle="Add Insurance Type"
          handleButton={handleAdd}
          width="60%"
        />
      </HeaderArea>

      <ConciseTable headers={isMobile ? "" : headers} />
      <ConciseTable
        body={body}
        data={
          query && query !== "" ? internalSearchResults : insuranceTypes
        }
      />

      <AddInsuranceTypePopup
        setOpenDialog={setOpenPopup}
        openDialog={openPopup}
      // data={individual}
      // setData={setIndividual}
      // handleButtonFunc={addPersonnel}
      />

    </StyledProjectSelectionContainer>
  )
}


