import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from '../../../constants';
import { Typography } from "@material-ui/core";


export const TitleTextRating = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.fontSize || "22px"};
    font-style: normal;
    font-weight: ${props => props.fontWeight || "500"};
    line-height: 21px;
    letter-spacing: 0px;
    padding-left:780px;
    color: ${props => props.color || `${COLORS.darkGrey2}`};
    margin: ${props => props.margin || "0 0 16px"};
  }
`;



export const StyledAwardsArea = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;

export const DataText = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.fontSize || "14px"};
    font-style: normal;
    font-weight: ${props => props.fontWeight || "400"};
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    ${props => props.margin ? `margin: ${props.margin};` : ""}
  }
`;

export const StyledBoxContent = styled.div`
  margin: ${props => props.margin || "32px 32px"};
  display: flex;
  flex-direction: ${props => props.flexDirection || "row"};
  ${props => props.width ? `width: ${props.width};` : ""}\
  position: relative;
`;

export const TitleText = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.fontSize || "22px"};
    font-style: normal;
    font-weight: ${props => props.fontWeight || "500"};
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: ${props => props.color || `${COLORS.darkGrey2}`};
    margin: ${props => props.margin || "0 0 16px"};
  }
`;

export const DarkTitle = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.fontSize || "24px"};
    font-style: normal;
    font-weight: ${props => props.fontWeight || "600"};
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: ${props => props.color || "black"};
    margin: ${props => props.margin || "0 0 16px"};
  }
`;

export const ContractInfo = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || "column"};
  width: 100%;
  margin-top: ${props => props.marginTop || "24px"};
  ${props => props.justifyContent ? `justify-content: ${props.justifyContent};` : ""}
`;

export const PageTitle = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.isMobile ? "18px" : "36px"};
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const FormArea = styled.div`
  ${props => props.marginLeft && !props.isMobile ? `margin-left: ${props.marginLeft};` : ''}
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  ${props => props.noJustifyContent ? "" : "justify-content: center;"}
  ${props => props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}
  
  .last {
    padding-right: 0px !important;
  }
  
  .thirdWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '33.33%'};
  }

  .fullWidthInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: 100%;
  }
`;

export const ScaleDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color:  white;
  align-items: center;
  width: 100%;
  min-height: 800px;
  margin-top: 16px;
`;

export const InsideDisplay = styled.div`
  width: 90%;
  margin: 50px 0px 0px;
`;


export const CommentCard = styled.div`
  cursor: pointer;
  background-color: ${props => props.isPicked ? `${COLORS.secondaryLightOrange}` : "white"};
  width: 32%;
  border-radius: 20px;
  border: 1px solid ${COLORS.primaryOrange};
  height: 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;

  font-family: ${TYPOGRAPHY.primaryFont};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  color: #5E5873;
`;

export const TitleDiv = styled.div`
  display: flex; 
  flex-direction: column;
  align-items: center; 
  width: 80%;
  margin: 16px 0 0;
`;

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isMobile ? "90%" : "100%")};
  justify-content: center;
  ${props => props.isMobile ?  "" : "padding: 0px 16px;"}
  // align-items: center;
  margin-left: 100px;
`;

export const PointText = styled.span`
  color: ${COLORS.primaryOrange};
`;

export const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column": "row")};;
  justify-content: space-between;
  margin: 24px 0px;
`;

export const ErrorText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.secondaryFont};
    font-weight: 600;
    font-size: ${props => props.isMobile? "20px" : "36px"};
    text-align: center;
    width: 100%;
    margin-top: ${props => props.isMobile? "16px" : "32px"};
  }
`;

export const StyledPylon = styled.img`
  width: ${props => props.isMobile? "150px" : "200px"};
  height: ${props => props.isMobile? "150px" : "200px"};
  margin-top: ${props => props.isMobile? "8px" : "24px"};
`;  

export const ErrorDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const AwardDiv = styled.div`
  display: flex; 
  flex-direction: column;
  margin: 0 16px 0 32px;
  font-family: ${TYPOGRAPHY.primaryFont};
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  color: ${props => props.isPicked ? "black" : `${COLORS.blurOutText}`};
`;