import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  StyledButtonArea,
  StyledContainerInside,
  StyledContainerTitle,
  StyledHeaderContent,
  StyledSubHeaderContent,
} from "./styles";
import { FunctionalButton } from "components/functionalButton";
import { ExportIcon } from "assets/common/ExportIcon";
import { ImportIcon } from "assets/common/ImportIcon";
import { AddPaymentProgressContractorPopup } from "components/addPaymentProgressContractorPopup";
import { AddChangeOrderContractorPopup } from "components/addChangeOrderContractorPopup";
import { getUnderwriterWorkInProgress, getWorkInProgress } from "api/work_in_progress";
import { postImportWipCSVData, postImportWipCSVDataUnderwriter } from "api/external_data.js";
import * as XLSX from "xlsx";
import { AlertMessage } from "./alertMessage";
import { SuccessPopup } from "pages/contractorDashboard/profileSetup/SuccessPopup.js";
import { getClientProjectsInfoForWip, getClientProjectsInfoForWipUnderwriter } from "data/actions/underwriters";
import { clientProjectsWipSelector } from "data/selectors/underwriters";
import { useLocation } from "react-router-dom";

export const PageHeader = ({
  subTitle,
  hasButton,
  handleButton,
  marginBottom,
  companyName,
  project,
  data,
  companyId,
  projectStatus,
}) => {
  const [
    openPaymentProgressContractorPopup,
    setOpenPaymentProgressContractorPopup,
  ] = useState(false);
  const [openChangeOrderContractorPopup, setOpenChangeOrderContractorPopup] =
    useState(false);
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [paymentProgressContractor, setPaymentProgressContractor] = useState(
    {}
  );
  const [changeOrderContractor, setChangeOrderContractor] = useState({});
  const [changeOrderId, setChangeOrderId] = useState(0);
  const [ppcId, setPpcId] = useState(0);
  const [clientProjectsData, setClientProjectsData] = useState([]);
  const [contractCompletionDate, setContractCompletionDate] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const clientProjects = useSelector(clientProjectsWipSelector);

  useEffect(() => {
    if (clientProjects) {
      setClientProjectsData(clientProjects);
    }
  }, [clientProjects]);

  const handlePaymentProgressContractorAdd = () => {
    setOpenPaymentProgressContractorPopup(true);
    const isUnderwriterWip = location.pathname.includes("underwriter/client/wip");
    if (isUnderwriterWip) {
      getUnderwriterWorkInProgress(data, onSuccessPaymentProgressContractorAdd, () => { });
    } else {
      getWorkInProgress(data, onSuccessPaymentProgressContractorAdd, () => { });
    }
  };

  const onSuccessPaymentProgressContractorAdd = (res) => {
    setPaymentProgressContractor(res);
    setPpcId(res?.new_ppc_id);
    setOpenPaymentProgressContractorPopup(true);
  };

  const handleChangeOrderContractorAdd = () => {
    const isUnderwriterWip = location.pathname.includes("underwriter/client/wip");
    if (isUnderwriterWip) {
      getUnderwriterWorkInProgress(data, onSuccessChangeOrderContractorAdd, () => { });
    } else {
      getWorkInProgress(data, onSuccessChangeOrderContractorAdd, () => { });
    }
    // setOpenChangeOrderContractorPopup(true);
  };

  const onSuccessChangeOrderContractorAdd = (res) => {
    setChangeOrderId(res?.new_change_order_id);
    setContractCompletionDate(res?.contract_completion_date);
    setChangeOrderContractor(res);
    setOpenChangeOrderContractorPopup(true);
  };

  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [notCorrectFormat, setNotCorrectFormat] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [openFailurePopup, setOpenFailurePopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
    }
  };

  useEffect(() => {
    if (file) {
      convertAndUpload(file);
    }
  }, [file]);

  const convertAndUpload = (file) => {
    const fileReader = new FileReader();
    const fileName = file.name;

    if (fileName.endsWith(".xls") || fileName.endsWith(".xlsx")) {
      fileReader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        uploadCsvData(fileName, csv);
      };
      fileReader.readAsArrayBuffer(file);
    } else if (fileName.endsWith(".csv")) {
      fileReader.onload = (event) => {
        const text = event.target.result;
        uploadCsvData(fileName, text);
      };
      fileReader.readAsText(file);
    } else {
      setNotCorrectFormat(true);
    }
  };

  const uploadCsvData = (fileName, csvData) => {
    const data = {
      account_id: companyId,
      company_name: companyName,
      company_id: companyId,
      csvData: csvData,
      fileName: fileName,
    };

    const isUnderwriterWip = location.pathname.includes("underwriter/client/wip");
    if (isUnderwriterWip) {
      postImportWipCSVDataUnderwriter(data, onSuccessImportCSVData, onFailureImportCSVData);
    } else {
      postImportWipCSVData(data, onSuccessImportCSVData, onFailureImportCSVData);
    }
  };

  const onSuccessImportCSVData = (res) => {
    setOpenSuccessPopup(true);
    const isUnderwriterWip = location.pathname.includes("underwriter/client/wip");
    if (isUnderwriterWip) {
      dispatch(
        getClientProjectsInfoForWipUnderwriter({ account_id: companyId }, "underway")
      );
    } else {
      dispatch(
        getClientProjectsInfoForWip({ account_id: companyId }, "underway")
      );
    }
  };

  const onFailureImportCSVData = (res) => {
    setOpenFailurePopup(true);
    setErrorMessage(res.data.message);
  };

  const exportToCSV = () => {
    const headers = [
      "As of Date",
      "Contract Number",
      "Project Name",
      "Original Contract Value",
      "Change Order",
      "Estimated Revenue",
      "Original Estimated Cost of Revenue",
      "Cost Incurred to Date",
      "Total Cost to Complete",
      "Estimated Gross Profit",
      "Percent Complete",
      "Earned Revenue",
      "Cost of Revenue",
      "Gross Profit to Date",
      "Billings to Date",
      "Costs & Estimated Earnings in Excess of Billings",
      "Billings in Excess of Costs & Estimated Earnings",
      "Current Year Earned Revenue",
      "Current Year Contract Costs",
      "Current Year Gross Profit"
    ];

    const rows = clientProjectsData.map((item) => {
      const wipDataArray = item?.wip_data;
      const wipData = wipDataArray?.[wipDataArray.length - 1];
      const ppcData = wipData?.ppc_data;
      const estimated_revenue = Math.round(item?.value || 0)
      let change_order = (wipData?.project_value_or_estimated_revenue ?? 0) - (item?.value ?? 0);
      change_order = Math.round(change_order);

      const estimated_cost_of_revenue = Math.round(wipData?.estimated_cost_of_revenue || 0)
      const cost_incurred_to_date = Math.round(ppcData?.cost_incurred_to_date || 0)
      const total_cost_to_complete = estimated_cost_of_revenue - cost_incurred_to_date
      const estimated_gross_profit = estimated_revenue - estimated_cost_of_revenue
      let percent_complete = 0

      if (estimated_cost_of_revenue > 0) {
        percent_complete = cost_incurred_to_date / estimated_cost_of_revenue;
      }

      const percent_complete_by_100 = (percent_complete * 100).toFixed(0) + "%";
      const earned_revenue = Math.round(estimated_revenue * percent_complete / 1000) * 1000;

      const original_contract_value = estimated_revenue - change_order
      const cost_of_revenue = estimated_cost_of_revenue * percent_complete
      const gross_profit_to_date = earned_revenue - cost_of_revenue
      const billings_to_date = ppcData?.billings_to_date || 0
      let costs_and_estimated_earnings_in_excess_of_billings = 0
      let billings_in_excess_of_costs_and_estimated_earnings = 0


      if (percent_complete < 1 && (earned_revenue > billings_to_date)) {
        costs_and_estimated_earnings_in_excess_of_billings = earned_revenue - billings_to_date
      }

      if (percent_complete < 1 && (earned_revenue < billings_to_date)) {
        billings_in_excess_of_costs_and_estimated_earnings = billings_to_date - earned_revenue
      }

      const current_year_earned_revenue = Math.round(wipData?.current_year_earned_revenue || 0)
      const current_year_contract_costs = Math.round(wipData?.current_year_contract_costs || 0)
      const current_year_gross_profit = current_year_earned_revenue - current_year_contract_costs

      return [
        wipData?.as_of_date || "",
        item?.project_number?.replace(/#/g, "-") || "",
        item?.project_name?.replace(/,/g, "").replace(/#/g, "-") || "",
        original_contract_value,
        change_order,
        estimated_revenue,
        estimated_cost_of_revenue,
        cost_incurred_to_date,
        total_cost_to_complete,
        estimated_gross_profit,
        percent_complete_by_100,
        earned_revenue,
        cost_of_revenue,
        gross_profit_to_date,
        billings_to_date,
        costs_and_estimated_earnings_in_excess_of_billings,
        billings_in_excess_of_costs_and_estimated_earnings,
        current_year_earned_revenue,
        current_year_contract_costs,
        current_year_gross_profit
      ];
    });


    let csvContent =
      "data:text/csv;charset=utf-8," +
      headers.join(",") +
      "\n" +
      rows.map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "export-wip-data.csv");
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  return (
    <StyledContainerTitle>
      <StyledContainerInside>
        <StyledHeaderContent marginBottom={marginBottom}>
          {companyName}
        </StyledHeaderContent>
        {subTitle && (
          <StyledSubHeaderContent>{subTitle}</StyledSubHeaderContent>
        )}
      </StyledContainerInside>
      {hasButton && !project && (
        <>
          <StyledButtonArea>
            <FunctionalButton
              buttonTitle="IMPORT"
              handleButton={() => fileInputRef.current.click()}
              width="200px"
              height="48px"
              isReversedColor={true}
              frontIcon={true}
              disabled={false}
              icon={<ImportIcon marginRight="16px" />}
            />
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              accept=".xls,.csv,.xlsx,application/vnd.ms-excel,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onClick={(e) => {
                e.target.value = null;
                fileInputRef.current.click();
              }}
              onChange={handleFileUpload}
            />

            <FunctionalButton
              buttonTitle="EXPORT"
              handleButton={exportToCSV}
              width="200px"
              height="48px"
              marginRight="0"
              isReversedColor={true}
              frontIcon={true}
              disabled={false}
              icon={<ExportIcon marginRight="16px" />}
            />
          </StyledButtonArea>
        </>
      )}
      {hasButton && project && projectStatus !== "Completed" && (
        <>
          <StyledButtonArea>
            <FunctionalButton
              buttonTitle="PROGRESS PAYMENT "
              handleButton={handlePaymentProgressContractorAdd}
              width="215px"
              height="48px"
              fontSize="16px"
              isReversedColor={true}
            />
            <FunctionalButton
              buttonTitle="CHANGE ORDER"
              handleButton={handleChangeOrderContractorAdd}
              width="170px"
              height="48px"
              isReversedColor={true}
            />
          </StyledButtonArea>
        </>
      )}

      <AddPaymentProgressContractorPopup
        setOpenDialog={setOpenPaymentProgressContractorPopup}
        openDialog={openPaymentProgressContractorPopup}
        data={paymentProgressContractor}
        setData={setPaymentProgressContractor}
        projectData={data}
        ppcId={ppcId}
        isMobile={isMobile}
        companyId={companyId}
      />

      <AddChangeOrderContractorPopup
        setOpenDialog={setOpenChangeOrderContractorPopup}
        openDialog={openChangeOrderContractorPopup}
        data={changeOrderContractor}
        setData={setChangeOrderContractor}
        projectData={data}
        changeOrderId={changeOrderId}
        oldContractCompletionDate={contractCompletionDate}
        isMobile={isMobile}
        companyId={companyId}
      />

      <AlertMessage
        isMobile={isMobile}
        openPopup={notCorrectFormat}
        setOpenPopup={setNotCorrectFormat}
        description="You can only import .xls or .xlsx or .csv format files."
        isFormatIssue={false}
      />

      <SuccessPopup
        setOpenPopup={setOpenSuccessPopup}
        openPopup={openSuccessPopup}
        isMobile={isMobile}
        descriptionText={"Your Work in Progress(WIP) file has been imported."}
      />

      <AlertMessage
        isMobile={isMobile}
        openPopup={openFailurePopup}
        setOpenPopup={setOpenFailurePopup}
        description={errorMessage}
        isFormatIssue={true}
      />
    </StyledContainerTitle>
  );
};
