import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { SideNav } from './SideNav';
import { FormContainer, ScreenRoot } from './styles';
import { SideBarContainer } from './styles';
import ProbabilityOfDefault from 'pages/underwriters/components/probabilityOfDefault/index.js';
import AllClientPage from "pages/underwriters/allClientPage.js";
import PeerGroupAnalysis from "pages/underwriters/components/peerGroupAnalysis/peerGroupAnalysis.js"
import DrawerSideNav from "./DrawerSideNav";


function SwitchComponents({ active, children }) {
  return children.filter((child) => child.props.step === active);
};

export default function AccountInsights() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [activeStep, setActiveStep] = useState(0);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);

  return (
    <ScreenRoot isMobile={isMobile}>
      {isMobile && (
        <DrawerSideNav
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          isMobile={isMobile}
        />
      )}
      {!isMobile && (
        <SideBarContainer isMobile={isMobile}>
          <SideNav
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isMobile={isMobile}
          />
        </SideBarContainer>
      )}

      <FormContainer isMobile={isMobile} leftBorder={false} style={{ marginLeft: "-80px" }}>
        <SwitchComponents active={activeStep}>

          {/* <PeerGroupAnalysis
            step={0}
            isMobile={isMobile}
            setOpenSuccessPopup={setOpenSuccessPopup}
          />
          <ProbabilityOfDefault
            step={1}
            isMobile={isMobile}
            setOpenSuccessPopup={setOpenSuccessPopup}
          /> */}

          {[
            <PeerGroupAnalysis
              step={0}
              isMobile={isMobile}
              setOpenSuccessPopup={setOpenSuccessPopup}
            />
          ]}

        </SwitchComponents>
      </FormContainer>
    </ScreenRoot>
  );
}
