import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

export const LooksGoodButton = withStyles((theme) => ({
  root: {
    maxWidth: 370,
    marginTop: 32,
    width: "100%",
    height: 48,
    marginRight: "12px",
    backgroundColor: "#FF6D1D",
    border: "1.5px solid #FF6D1D",
    boxSizing: "border-box",
    borderRadius: "6px",
    color: "white",

    fontSize: "17px",
    fontWeight: 600,
    transition: "all 0.5s ease",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "white",
      color: "#FF6D1D",
    },
  },
  disabled: {
    backgroundColor: "#FFF3EC",
    color: "#FFDECC !important",
    border: "1.5px solid #FFF3EC",
  },
}))(Button);
