import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from '../../constants';
import { Typography } from "@mui/material";
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const StyledCheckArea = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledSpan = styled.span`
    color: ${COLORS.primaryOrange};
    cursor: pointer;
`;

export const StyledRememberMe = styled(FormControlLabel)`
  &&{
    margin-left : -8px;
    margin-top: 16px;
    margin-bottom: 20px;

    .MuiTypography-body1{
      font-size: 13px;
      font-weight: 500;
      font-family: ${TYPOGRAPHY.primaryFont};
    }

    .MuiIconButton-label{
      margin-left: 0px;
      margin-right : 0px;
    }
  }
`;

export const StyledForgotPassword = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-family: ${TYPOGRAPHY.primaryFont};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${COLORS.primaryOrange};
  margin-top: -4px;
  cursor: pointer;
`;

export const OptionArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const StyledSubtext = styled.div`
  margin-top: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-family: ${TYPOGRAPHY.secondaryFont};
  color: #000000;
`;

export const StyledTermText = styled(StyledSubtext)`
  &&{
    margin-left: -10px;
    margin-top: 26px;
    font-size: 12px;
  }
`;

export const ErrorText = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: ${COLORS.primaryRed};
    margin-top: 4px;
  }
`;

export const EmailSentText = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  margin-top: 40px;
  color: #000000;
`;

export const EmailSentSubText = styled.div`
  margin-top: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #777777;
`;

export const LoginRoot = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  ${(props) => (props.isMobile ? "position: relative;" : "")}
  width: 100vw;
  height: 100vh;
  align-items: center;
`;

export const FormContainer = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "50%")};
  ${(props) => (props.isMobile ? "align-self: center;" : "")}

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  width: ${(props) => (props.isMobile ? "90%" : "60%")};
  align-items: center;
  ${(props) => (props.isMobile && props.marginTop ? `margin-top: ${props.marginTop};` : "")}
`;

export const InfoContainer = styled.div`
  width: 50%;
  height: 100%;
  position:absolute;
  left: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${COLORS.lighOrange};
`;

export const Logo = styled.img`
  width: 135px;
  height: 35px;
`;

export const ImageContainer = styled.div`
  margin-top: 100px;
  position: relative;
`;

export const Dashboard = styled.img`
  height: 610px;
  position:absolute;
  left: -8%;
  box-shadow: none;
`;

export const Dots = styled.img`
  position: absolute;
`;

export const SubTitle = styled.div`
  width: 65%;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  color: #2a3f76;
`;

export const Title = styled.div`
  width: 65%;
  margin-top: 12px;

  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;

  text-align: center;

  color: #000000;
`;

export const Text = styled.div`
  width: 55%;
  margin-top: 20px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  color: #4e4e4e;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;

  align-items: flex-end;
  height: 100%;
  margin-right: 52px;

  & a svg {
    color: white;
    width: 25px;
    height: 25px;
  }

  & a svg:hover {
    color: black;
  }
`;

export const CopyRights = styled.div`
  align-self: flex-end;

  margin-bottom: 30px;
  margin-right: 52px;

  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;

  color: #d9d9d9;
`;

export const FormCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
`;

export const StyledEmailSentCard = styled(FormCard)`
  &&{
    align-items: center;
    text-align: center;
    width: 80%;
    margin-top: 0px;
  }
`;

export const FormTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  font-family: ${TYPOGRAPHY.secondaryFont};
  color: #000000;
`;

export const InputWrap = styled.div`
  position: relative;

  width: 100%;

  margin-top: 12px;
`;

export const InputLabel = styled.div`
  margin-bottom: 5px;

  display: flex;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;

  color: #000000;
`;

export const InputField = styled.input`
  width: 100%;
  height: 40px;

  padding-left: 15px;
  padding-right: 40px;

  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;
  color: white;

  font-style: normal;
  font-weight: normal;
  font-size: 15px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #d0c9d6;
  }

  ${(props) =>
    props.validation === -1 ? "border: 1px solid #FF647C; color: #FF647C;" : ""}

  ${(props) => (props.validation === 1 ? "border: 1px solid #FF6D1D; " : "")}
`;

export const Background = styled.div`
  position: absolute;
  top: 64px;
  right: 0px;

  background-color: ${COLORS.lighOrange};
  border-bottom-left-radius: 50vw;

  width: 50%;
  height: 25%;
`;

export const Divider = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: row;

  color: #777777;

  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 0.25px solid #777777;
    margin: auto;
  }

  &:before {
    margin-right: 20px;
  }
  &:after {
    margin-left: 20px;
  }
`;
