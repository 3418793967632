import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from "../../constants";

export const OnBoardRoot = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};

  ${(props) => (props.isMobile ? "position: relative;" : "")}

  width: 100%;
  height: 100vh;
  ${(props) => (props.isMobile ? "" : "align-content: center;" )}
  ${(props) => (props.isMobile ? "" : "justify-content: center;")}
  margin: 0px;
  background-color: ${COLORS.backgroundGrey};
  padding-top: 72px;
`;

export const StepContainer = styled.div`
  width: 500px;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
`;

export const StyledStepper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  width: 100%
`;

export const FormContainer = styled.div`
  width: 90%;
  ${(props) => (props.isMobile ? "align-self: center;" : "")}
  padding-bottom: 20px;
  margin-top: ${(props) => (props.isMobile ? "16px" : "24px")};
  display: flex;
  flex-direction: column;
  align-items: center;
  // ${(props) => (props.isMobile ? "margin-top: 50px;" : "")}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.isMobile ? "36px" :"56px"};
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const InfoContainer = styled.div`
  width: 55%;
  min-height: 730px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #f26934;
  border-bottom-left-radius: 500px;
`;

export const Logo = styled.img`
  width: 135px;
  height: 35px;
`;

export const FinalLogo = styled.img`
  margin-top: 140px;
  width: 75px;
  height: 75px;
`;

export const ImageContainer = styled.div`
  margin-top: 100px;
  position: relative;

  width: 65%;
`;

export const Dashboard = styled.img`
  width: 100%;
`;

export const Dots = styled.img`
  position: absolute;
`;

export const SubTitle = styled.div`
  width: 65%;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  color: #2a3f76;
`;

export const Title = styled.div`
  width: 65%;
  margin-top: 12px;

  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;

  text-align: center;

  color: #000000;
`;

export const Text = styled.div`
  width: 55%;
  margin-top: 20px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  text-align: center;

  color: #4e4e4e;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;

  align-items: flex-end;
  height: 100%;
  margin-right: 52px;

  & a svg {
    color: white;
    width: 25px;
    height: 25px;
  }

  & a svg:hover {
    color: black;
  }
`;

export const CopyRights = styled.div`
  align-self: flex-end;

  margin-bottom: 30px;
  margin-right: 52px;

  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;

  color: #d9d9d9;
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;

  margin-left: 9px;
  margin-right: 9px;

  cursor: pointer;

  border-radius: 100%;
  background-color: ${(props) => (props.active ? "#D45100" : "#606C84")};
  opacity: ${(props) => (props.active ? "1" : "0.2")};
`;

export const DotContainer = styled.div`
  margin-top: 30px;

  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const CarouselWrap = styled.div`
  margin-top: 80px;
  // margin-bottom: 20px;
`;

export const FormCard = styled.div`
  display: flex;
  flex-direction: column;

  width: 99%;
`;

export const FormTitle = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;

  color: #000000;
`;

export const FormSubTitle = styled.div`
  margin-top: 15px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #777777;
`;

export const InputWrap = styled.div`
  position: relative;

  width: 100%;

  margin-top: 12px;
`;

export const InputLabel = styled.div`
  margin-bottom: 5px;

  display: flex;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;

  color: #000000;
`;

export const InputLabelHint = styled.span`
  margin-left: 15px;
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 20px;

  color: #777777;
`;

export const InputField = styled.input`
  width: 100%;
  height: 40px;

  padding-left: 15px;
  padding-right: 40px;

  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;
  color: #1a051d;

  font-style: normal;
  font-weight: normal;
  font-size: 15px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #d0c9d6;
  }

  ${(props) =>
    props.validation === -1 ? "border: 1px solid #FF647C; color: #FF647C;" : ""}

  ${(props) => (props.validation === 1 ? "border: 1px solid #FF6D1D; " : "")}
`;

export const Background = styled.div`
  position: absolute;
  top: 64px;
  right: 0px;

  background-color: #f26934;
  border-bottom-left-radius: 50vw;

  width: 50%;
  height: 25%;
`;
