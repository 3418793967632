import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import checkEmail from "../../assets/onBoarding/checkEmail.svg";

import { useHistory } from "react-router-dom";

import {
  FormContainer,
  Content,
  StyledSubtext,
  FormTitle,
  FormCard
} from "./styles";

export const CheckEmail = ({ isPasswordChange, isMobile }) => {
  const history = useHistory();

  return (
    <FormContainer isMobile={isMobile}>
      <Content isMobile={isMobile} marginTop="50px">
        <div onClick={() => history.push('/account/login')} style={{cursor:"pointer"}}>
          <img src ={checkEmail}/>
        </div>
        <FormCard>
          <FormTitle>Check your email</FormTitle>
          {isPasswordChange && (
            <StyledSubtext>We have sent password recovery instructions to your email.<br/>If you haven’t received anything within 5 minutes, check your
            Junk/Spam folder!</StyledSubtext>
          )}
          {!isPasswordChange && (
            <StyledSubtext>We have sent a confirmation link to your email. <br/>If you haven’t received anything within 5 minutes, check your
            Junk/Spam folder!</StyledSubtext>
          )}

        </FormCard>
      </Content>
    </FormContainer>
  );
}

