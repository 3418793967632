import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#EBE9F1'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#EBE9F1',
        backgroundColor: '#F8F8F8',
        alignItems: 'center',
        height: 24,
        fontStyle: 'normal',
        paddingLeft: 15,
        color: '#000000',
        fontWeight: 400,
        fontSize: 10,
    },
    name: {
        width: '80%',
        textAlign: 'left',
        color: '#000000',
    },
    contentType: {
        width: '20%',
        textAlign: 'left',
        color: '#000000',
    },
});

const PageSixDocumentsTableRow = ({ items }) => {
    const rows = items?.map(item =>
        <View style={styles.row}>
            <Text style={styles.name}>{item?.name}</Text>
            <Text style={styles.contentType}>{item?.content_type === "Institution_file" ? "Institution File" : item?.content_type}</Text>
        </View>
    );
    return (<Fragment>{rows}</Fragment>)
};

export default PageSixDocumentsTableRow;
