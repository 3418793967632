import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { StepperProgress } from "../../components/stepperProgress";
import { BackButton } from "../../components/backButton";
import { StyledStepper } from "./styles";
import { FunctionalButton } from "../../components/functionalButton";
import { StepContainer } from "./styles";
import { ExitPopup } from "./components/ExitPopup";

import Additional_color from "assets/newPreQualify/Additional_color";
import Additional_grey from "assets/newPreQualify/Additional_grey";
import CompanyInfo_color from "assets/newPreQualify/CompanyInfo_color";
import CompanyInfo_grey from "assets/newPreQualify/CompanyInfo_grey";
import Download_color from "assets/newPreQualify/Download_color";
import Download_grey from "assets/newPreQualify/Download_grey";
import Financial_color from "assets/newPreQualify/Financial_color";
import Financial_grey from "assets/newPreQualify/Financial_grey";
import OpenProject_color from "assets/newPreQualify/OpenProject_color";
import OpenProject_grey from "assets/newPreQualify/OpenProject_grey";
import Personnel_color from "assets/newPreQualify/Personnel_color";
import Personnel_grey from "assets/newPreQualify/Personnel_grey";
import ProjectShow_color from "assets/newPreQualify/ProjectShow_color";
import ProjectShow_grey from "assets/newPreQualify/ProjectShow_grey";
import Safety_color from "assets/newPreQualify/Safety_color";
import Safety_grey from "assets/newPreQualify/Safety_grey";
import Value_color from "assets/newPreQualify/Value_color";
import Value_grey from "assets/newPreQualify/Value_grey";
import Review_color from "assets/newPreQualify/Review_color";
import Review_grey from "assets/newPreQualify/Review_grey";

export const Stepper = ({
  step,
  setStep,
  iconList,
  setLabels,
  panel,
  setPanel,
  isBranchPanel,
  setIsBranchPanel,
  previousStepPanel,
  onboardingData,
  setOnboardingData,
  setAction,
  setNavigationSequence,
  navigationSequence,
  setCurrNavLocation,
  currNavLocation,
}) => {
  const [openPopup, setOpenPopup] = useState(false);

  let history = useHistory();

  const stepLabels = [
    "Step 1",
    "Step 2",
    "Step 3",
    "Step 4",
    "Step 5",
    "Step 6",
    "Step 7",
    "Step 8",
    "Step 9",
  ];

  const colouredList = [
    OpenProject_color,
    CompanyInfo_color,
    Financial_color,
    Safety_color,
    ProjectShow_color,
    Personnel_color,
    Value_color,
    Additional_color,
    Review_color,
  ];

  const unColouredList = [
    OpenProject_grey,
    CompanyInfo_grey,
    Financial_grey,
    Safety_grey,
    ProjectShow_grey,
    Personnel_grey,
    Value_grey,
    Additional_grey,
    Review_grey,
  ];

  const [list, setList] = useState([colouredList[0]]);

  const buildVisitableList = () => {
    const temp = { ...navigationSequence };

    for (let i = 0; i < colouredList.length; i++) {
      temp[i].colouredIcon = colouredList[i];
      temp[i].unColouredIcon = unColouredList[i];
    }

    setNavigationSequence(temp);
  };

  useEffect(() => {
    buildVisitableList();
  }, []);

  const handleBackStep = () => {
    // if(panel === 1){
    //   if (step > 0) {
    //     if (step === 8){
    //       setAction('edit');
    //     }
    //     const tempList = iconList.splice(0, step );
    //     setList(tempList)
    //     const tempLabels = stepLabels.splice(0, step );
    //     setLabels(tempLabels);
    //     setOnboardingData('step', step - 1)
    //     setStep(step - 1);
    //     setPanel(previousStepPanel);
    //   }
    // } else {
    //   if(isBranchPanel){
    //     setPanel(panel - 2);
    //     setIsBranchPanel(false)
    //   } else {
    //     setPanel(panel - 1);
    //   }

    // }

    if (currNavLocation.step - 1 < 1 || currNavLocation.step > 8) {
      return;
    }

    let newCurrLocation = { step: currNavLocation.step - 1, panel: 0 };

    const newPath =
      navigationSequence?.[newCurrLocation.step]?.path?.[newCurrLocation.panel];
    history.push("/prequalify" + newPath);

    setCurrNavLocation(newCurrLocation);
  };

  const handleExit = () => {
    setOpenPopup(true);
  };

  const handleClickIcon = (index) => {
    // const tempList = iconList.splice(0, index);
    // setList(tempList)
    // const tempLabels = stepLabels.splice(0, index);
    // setLabels(tempLabels);
    if (navigationSequence?.[index]?.visited) {
      setCurrNavLocation({ step: index, panel: 0 });
      const newPath = navigationSequence?.[index]?.path?.[0];
      history.push("/prequalify" + newPath);
    }
  };

  return (
    <StyledStepper>
      {/* <div style={{width:"148px"}}>
      <BackButton margin="0px 0px 0px 0px" handleClick={handleBackStep} />
      </div> */}
      <StepContainer>
        <StepperProgress
          activeStep={step}
          setActiveStep={setStep}
          navigationSequence={navigationSequence}
          stepLabels={stepLabels}
          connectorWidth="35px"
          noConnectorColor={true}
          handleClickIcon={handleClickIcon}
        />
      </StepContainer>
      {/* <FunctionalButton
        buttonTitle={"SAVE & EXIT"}
        width="148px"
        height="38px"
        marginLeft="0px"
        marginRight="0px"
        fontSize="12px"
        handleButton={handleExit}
      /> */}
      <ExitPopup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onboardingData={onboardingData}
      />
    </StyledStepper>
  );
};
