import styled from 'styled-components';
import { Dropdown, Menu } from 'semantic-ui-react';
import { TYPOGRAPHY, COLORS } from '../../constants';
export const StyledMenu = styled(Menu)`
  &&.ui.menu {
    width:  ${props => props.width ? props.width : '204px'};
    ${props => props.height ? `height: ${props.height}` : ''};
    box-shadow: none;
    ${props => props.hasData ? `border: 1px solid ${COLORS.primaryOrange};` : ""}

    .item>i.dropdown.icon{
      margin-top: 8px;
      color: #3F3356;
    }

    .item>.label{
      font-size: 14px;
      font-family: ${TYPOGRAPHY.primaryFont};
      text-transform: uppercase;
      background: ${COLORS.secondaryLightOrange};
      color: ${COLORS.primaryLightOrange};
      margin-right: 8px;
    }
  }

  &&.ui.compact.menu {
    .item:last-child{
      border-radius: 0.285714rem .28571429rem .28571429rem 0.285714rem;
    }
  }
`;

export const StyledDropdown = styled(Dropdown)`
  &&&& {
    border-color: ##EBE9F1;
    width:  ${props => props.width ? '100%' : '204px'};
    ${props => props.height ? `height: ${props.height}` : ''};
    border: 1px;

    &.ui.dropdown>.text{
      color: #3F3356;
      ${props => props.textDropdownMarginTop ? `margin-top: ${props.textDropdownMarginTop}` : ''};
    }
  }
  .divider{
    border-bottom:0px;
  }
`;

export const StyledLabel = styled.p`
  && {
    margin-bottom: 4px;
    font-family:  ${TYPOGRAPHY.primaryFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
`;
