import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogContent from '@material-ui/core/DialogContent';
import { COLORS } from '../../../../constants';

export const StyledTypography = styled(Typography)`
    && {
        font-size: 24px;
        font-family: SF Pro Text;
        color: #5E5873;
        font-weight: bold;
        margin-top: 60px;
        width: 16.67%
    }
`;
export const StyledTypographyPagination = styled(Typography)`
    && {
        font-size: 12px;
        font-family: SF Pro Text;
        color: #C4C4C4;
        margin-top: 72px;
        width: 16.67%;
    }
`;

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
`;

export const StyledButtonContainer = styled.div`
        width: 66.66%;
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
`;

export const StyledPicklistContainer = styled.div`
        display: flex;
        justify-content: flex-end;
        margin-top: 22px;
`;

export const StyledDialogTitle = styled(DialogTitle)`
    .MuiTypography-root{
        font-family: SF Pro Text;
        font-size: 48px;
        font-weight: 600;
        margin-top: 16px;
        text-align: center;
    }
`;

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper{
    width: 800px;
    height: 293px;
 }
`;

export const StyledLinearProgress = styled(LinearProgress)`
    &&.MuiLinearProgress-root{
        height: 20px;
        border-radius: 12px;
        margin-top: 32px;

        .MuiLinearProgress-barColorPrimary{
            background-color: ${COLORS.primaryOrange};
        }
    }
    &&.MuiLinearProgress-colorPrimary{
        background-color: ${COLORS.greyBorder};
    }
`;

export const StyledDialogContent = styled(DialogContent)`
    width: 500px;
    margin: 0 auto;
`;
