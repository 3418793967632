import React from 'react';
import { Text, View, StyleSheet, Link } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 10,
    justifyContent: 'flex-start',
    width: '50%',
    flex: 1,
    paddingLeft: 30,
    paddingRight: 10,
  },
  companyDetails: {
    marginTop: 10,
    paddingBottom: 3,
    fontFamily: 'Helvetica',
    fontSize: 14,
    color: '#B9B9B9',
    fontWeight: 600,
  },
  spanText: {
    color: '#B9B9B9',
  },
});

const PageCompanyDetails = ({ csrData }) => (
  <View style={styles.headerContainer}>
    <Text style={styles.companyDetails}>Bonding Company Details</Text>
    <Text>
      <span style={styles.spanText}>Company Name: </span>
      {csrData?.data?.company_name}
    </Text>
    <Text>
      <span style={styles.spanText}>Company Adress: </span>
      {csrData?.data?.company_address}
    </Text>
    <Text>
      <span style={styles.spanText}>Contractor Name: </span>
      {csrData?.data?.contractor_name}
    </Text>
  </View>
);

export default PageCompanyDetails;
