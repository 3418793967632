import React, { useState, useEffect } from "react";
import Search from "../../../assets/common/Search.svg";
import cancelIcon from "../../../assets/common/cancelIcon.svg";

import {
  StyledSearch,
  StyledCancelIcon,
  StyledSearchIcon,
  StyledSearchContainer,
} from "./styles";

export const SearchBar = ({
  filterApi,
  setFilteredData,
  searchWidth,
  filteredData,
  setInternalSearchResults,
  query,
  setQuery,
  internalSearch,
  searchPlaceHolder,
  marginRight,
}) => {
  // const [query, setQuery] = useState("");

  function handleSearch(searchTerm) {
    try {
      var modifiedSearchTerm = searchTerm.toString();
      try {
        modifiedSearchTerm = searchTerm.toLowerCase();
      } catch {}
      if (searchTerm) {
        return filteredData.filter((item) => {
          var itemReturned = false;
          var itemKeys = Object.keys(item);

          itemKeys.map((itemKey) => {
            try {
              var stringTerm = item[itemKey];
              try {
                stringTerm = item[itemKey].toString();
              } catch {}
              if (stringTerm.toLowerCase().includes(modifiedSearchTerm)) {
                itemReturned = true;
              }
            } catch {}
          });
          return itemReturned;
        });
      } else {
        return filteredData;
      }
    } catch {
      return filteredData;
    }
  }
  const handleFilter = async () => {
    if (internalSearch) {
      setInternalSearchResults(handleSearch(query));
    } else {
      await filterApi(query)
        .then((data) => {
          setFilteredData(data);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    handleFilter(query);
  }, [query]);

  return (
    <>
      <StyledSearchContainer width={searchWidth} marginRight={marginRight}>
        <div class="ui search">
          <div class="ui left icon input" style={{ width: "100%" }}>
            <StyledSearch
              class="prompt"
              type="text"
              placeholder={searchPlaceHolder || "Search"}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <StyledSearchIcon src={Search} />
            {/* {query?.length > 0 && (
              <StyledCancelIcon src={cancelIcon} onClick={() => setQuery("")} />
            )} */}
          </div>
        </div>
      </StyledSearchContainer>
    </>
  );
};
