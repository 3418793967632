import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  ScreenRoot,
  DetailedFormContainer,
  LargeText,
  StyledProjectSelectionContainer,
} from "../styles";

import {
  DetailArea,
  HeaderArea,
  MobileColumnContent,
  MobileColumnText,
  StyledDownloadedContainer,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getServiceProviderCqsListOfAllClient } from "data/actions/serviceProvider";
import { serviceProviderCqsListSelector } from "data/selectors/serviceProvider";
import { StyledTableCell } from "components/filesListTable/styles";
import { truncateString } from "common/truncateString";
import ConciseTable from "components/filesListTable/conciseTable";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";
import { GoBackButton } from "pages/buyers/components/goBackButton";
import { AllClientHeader } from "pages/serviceProviders/components/AllClientHeader";

function SeeAllContractorCqs({ noWidthChange }) {
  const [query, setQuery] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [filteredData, setFilteredData] = useState([]);

  const dispatch = useDispatch();
  const [internalSearchResults, setInternalSearchResults] =
    useState(filteredData);

  const allContractorCqsData = useSelector(serviceProviderCqsListSelector);

  useEffect(() => {
    if (allContractorCqsData?.length < 1) {
      dispatch(getServiceProviderCqsListOfAllClient());
    }
  }, []);

  useEffect(() => {
    if (allContractorCqsData?.length > 0) {
      let separatedCQS = allContractorCqsData?.flatMap((item) =>
        item.cqs.map((cqsItem) => ({
          ...cqsItem,
          institutionId: item.institutionId,
          institutionName: item.institutionName,
        }))
      );
      setFilteredData(separatedCQS);
    }
  }, [allContractorCqsData]);

  const headers = [
    { title: "Contractor Name", width: "16%" },
    { title: "Project Title", width: "35%" },
    { title: "Project Number", width: "15%" },
    { title: "Location", width: "21%" },
    { title: "Status", align: "center", width: "15%" },
  ];
  const body = (bodyData) => {
    return (
      <>
        {isMobile && (
          <StyledTableCell component={"tr"} width="90%">
            <MobileColumnContent direction="column">
              <MobileColumnText>
                {truncateString(bodyData.institutionName, 28)}
              </MobileColumnText>
              <MobileColumnText fontSize="12px" fontWeight="400">
                {truncateString(bodyData.project_title, 30)}
              </MobileColumnText>
            </MobileColumnContent>
          </StyledTableCell>
        )}
        {!isMobile && (
          <>
            <StyledTableCell component={"tr"} width="16%">
              {bodyData.institutionName}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="35%">
              {bodyData.project_title}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="15%">
              {bodyData.project_number}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="23%">
              {bodyData.project_location}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="10%">
              {bodyData.status === "Submitted" ? (
                <StyledDownloadedContainer>Submitted</StyledDownloadedContainer>
              ) : bodyData.status === "Downloaded" ? (
                <StyledDownloadedContainer>
                  Downloaded
                </StyledDownloadedContainer>
              ) : bodyData.status === "Prequalified" ? (
                <StyledDownloadedContainer>
                  Prequalified
                </StyledDownloadedContainer>
              ) : (
                ""
              )}
            </StyledTableCell>
          </>
        )}
      </>
    );
  };

  return (
    <ScreenRoot isMobile={isMobile}>
      <DetailedFormContainer
        isMobile={isMobile}
        noWidthChange={noWidthChange}
        style={{ marginTop: "0" }}
      >
        <GoBackButton />
        {/* <HeaderArea>
          <LargeText isMobile={isMobile}>
            {isMobile
              ? "All CQS"
              : "All Contractor's Qualifications Statements"}
          </LargeText>

          {!isMobile && (
            <SearchBar
              filteredData={filteredData}
              setInternalSearchResults={setInternalSearchResults}
              query={query}
              setQuery={setQuery}
              searchWidth="30%"
              internalSearch={true}
            />
          )}
        </HeaderArea> */}
        <AllClientHeader
          title={
            isMobile ? "All CQS" : "All Contractor's Qualifications Statements"
          }
          subTitle="Qualification statements completed by clients in last 30 days "
          marginBottom="0"
          marginTop="0"
          hasButton={false}
          // handleButton={handleStartNew}
          setInternalSearchResults={setInternalSearchResults}
          query={query}
          setQuery={setQuery}
          data={filteredData}
        />

        <DetailArea style={{ marginTop: "0" }}>
          {isMobile && (
            <SearchBar
              filteredData={filteredData}
              setInternalSearchResults={setInternalSearchResults}
              query={query}
              setQuery={setQuery}
              searchWidth="95%"
              internalSearch={true}
            />
          )}

          <StyledProjectSelectionContainer
            isMobile={isMobile}
            style={{ marginTop: "0" }}
          >
            <ConciseTable
              headers={isMobile ? "" : headers}
              // length={isMobile ? 5 : 5.5}
              largefont={false}
              borderSpacing="0 5px !important"
            />
            <ConciseTable
              // headers={isMobile ? "" : headers}
              // length={isMobile ? 5 : 5.5}
              body={body}
              data={
                query && query !== "" ? internalSearchResults : filteredData
              }
            />
          </StyledProjectSelectionContainer>
        </DetailArea>
      </DetailedFormContainer>
    </ScreenRoot>
  );
}

export default SeeAllContractorCqs;
