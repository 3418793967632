import React, { useState } from "react";
import { FunctionalButton } from 'components/functionalButton';
import { Popup } from 'components/popup';
import { CloseButton } from "components/closeButton";
import PledgxLogo from "assets/common/pledgxLogo";
import { paymentProgressCertificateApprovalRedirectTo } from "api/work_in_progress.js";
import { MultilineTextField } from "components/multilineTextField";

import {
  TitleDiv,
  TitleText,
  PopupContainer,
  ButtonDiv
} from "./styles.js";


export const RedirectToApproval = ({
  openPopup,
  setOpenPopup,
  isMobile,
  data
}) => {
  const [redirectEmail, setRedirectEmail] = useState("");

  const handleDone = () => {
    setOpenPopup(false);
  };


  const savePaymentProgressCertificateApprovalRedirectTo = () => {
    const newData = { ...data, redirect_email: redirectEmail }
    paymentProgressCertificateApprovalRedirectTo(newData, () => {
      setOpenPopup(false);
      window.location.replace("https://pledgx.com");
    }, () => { });
  };


  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width={isMobile ? "100%" : "577px"}
      height={isMobile ? "100vh" : "420px"}
      dialogContent={(
        <>
          <CloseButton handleClose={handleDone} />
          <PopupContainer>
            <TitleDiv>
              <PledgxLogo width={isMobile ? 75 : ""} height={isMobile ? 74 : ""} />
            </TitleDiv>

            <TitleText fontSize="28px" fontWeight="700" color="#1A051D" margin="41px 0px 39px" lineHeight="41px" textAlign="center">Progress Payment Approval will be</TitleText>
            <TitleText fontSize="28px" fontWeight="700" color="#1A051D" margin="-20px 0px 30px" lineHeight="41px" textAlign="center">redirected to </TitleText>
            <MultilineTextField
              label=""
              placeholder="write email"
              row={1}
              width="100%"
              value={redirectEmail}
              setValue={(e) => setRedirectEmail(e.target.value)}
              margin="10px 0px"
              noErrorBorder={true}
            />
            <ButtonDiv isMobile={isMobile}>
              <FunctionalButton
                buttonTitle="GO BACK"
                handleButton={handleDone}
                isReversedColor={true}
                height="48px"
                width="233px"
                marginTop="0px"
                marginRight="16px"
              />
              <FunctionalButton
                buttonTitle={"CONFIRM"}
                height="48px"
                width="233px"
                marginTop="0px"
                marginRight="0px"
                handleButton={() => savePaymentProgressCertificateApprovalRedirectTo()}
              />
            </ButtonDiv>
          </PopupContainer>

        </>
      )}
    />

  )
}
