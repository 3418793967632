import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import { StyledMenu, StyledMenuItem, StyledButton } from "./styles";
import DeleteIcon from "../../assets/buyers/deleteIcon";
import VerticalThreeDots from "../../assets/dashboardIcons/verticalThreeDots";
import { useMediaQuery } from "react-responsive";
import { AddProjectRemarkPopup } from "../addProjectRemarkPopup";
import { AddCcdcTemplatePopUp } from "../addCcdcTemplatePopUp";
import { downloadZip } from "client-zip/index.js";
import FileSaver from "file-saver";
import { AlertPopup } from "components/alertPopup/alertPopup";
import { useHistory } from "react-router";

export const ThreeDotsPopupMenu = ({
  item,
  data,
  handleSeeAll,
  projects,
  setProjects,
  icon,
  handleModify,
  handleDelete,
  removeEdit,
  addProjectRemark,
  noEditButton,
  deleteText,
  handleDisable,
  handleEnable,
  handleReviewEcqs,
  handleShowUserList,
  handleAddNewUser,
  wipButton = false,
  goToProjectWipPage,
}) => {
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openClonePopup, setOpenClonePopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const ccdc_status = data?.find((obj) => obj.ccdcId === item?.id)?.status;
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const checkCcdcId = (obj) =>
    (obj.status === "Downloaded" ||
      obj.status === "Submitted" ||
      obj.status === "Prequalified") &&
    item.id === obj.ccdcId;
  const checkCcdcIdValue = data ? data.some(checkCcdcId) : "";
  const [pdfFileSequence, setPdfFileSequence] = useState([]);
  const ckeckEnableDisable = item?.status;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickDownloadedDelete = async () => {
    if (checkCcdcIdValue == true) {
      setOpenDeletePopup(true);
    } else {
      setOpenDeletePopup(false);
    }
    handleClose();
  };

  const clickDelete = async () => {
    handleDelete(item ? item?.id : "");
    handleClose();
  };

  const clickEnable = async () => {
    handleEnable(item ? item?.id : "");
    handleClose();
  };

  const showUserList = async () => {
    handleShowUserList(item ? item?.id : "");
    handleClose();
  };

  const addNewUser = async () => {
    handleAddNewUser(item ? item?.id : "");
    handleClose();
  };

  const clickDisable = async () => {
    handleDisable(item ? item?.id : "");
    handleClose();
  };

  const clickDeleteModern = (e) => {
    handleDelete(e, item.index, item);
    handleClose();
  };

  const clickModify = async (action) => {
    handleModify(item ? item?.id : "");
    handleClose();
  };

  // add button
  const handleRemarkButtonClick = () => {
    if (item) {
      setOpenPopup(true);
      handleClose();
    }
  };

  const handleCloneCcdcButtonClick = () => {
    if (item) {
      setOpenClonePopup(true);
      handleClose();
    }
  };
  const handleReview = () => {
    handleReviewEcqs(item ? item?.id : "");
  };
  const handleDownloadButtonClick = async () => {
    const content = await downloadZip(pdfFileSequence).blob();
    FileSaver.saveAs(content, `qualify_${Date.now()}.zip`);
    handleClose();
  };

  return (
    <>
      {!removeEdit && (
        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ padding: 0 }}
          >
            {icon || <VerticalThreeDots />}
          </IconButton>
          <StyledMenu
            removeEdit={noEditButton}
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            {!noEditButton && (
              <StyledMenuItem>
                <StyledButton title="edit" width="58px" onClick={clickModify}>
                  Edit
                </StyledButton>
              </StyledMenuItem>
            )}
            {handleDelete && (
              <StyledMenuItem>
                <StyledButton
                  title="delete"
                  width={deleteText ? "100%" : "58px"}
                  onClick={clickDelete}
                >
                  {deleteText || "Delete"}
                </StyledButton>
              </StyledMenuItem>
            )}
            {wipButton && item?.project_status === "Completed" && (
              <StyledMenuItem>
                <StyledButton
                  title="view"
                  onClick={() => goToProjectWipPage(item?.id)}
                >
                  WIP History
                </StyledButton>
              </StyledMenuItem>
            )}
          </StyledMenu>
        </>
      )}
      {removeEdit && (
        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ padding: 0 }}
          >
            {icon || <VerticalThreeDots />}
          </IconButton>
          <StyledMenu
            removeEdit={removeEdit}
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <StyledMenuItem onClick={(e) => clickDeleteModern(e)}>
              <DeleteIcon />
            </StyledMenuItem>
          </StyledMenu>
        </>
      )}

      {removeEdit === "na" && addProjectRemark && (
        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ padding: 0, display: "none" }}
          ></IconButton>
          <StyledMenu
            addProjectRemark={addProjectRemark}
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <StyledMenuItem>
              <StyledButton
                title="remark"
                width="100px"
                onClick={handleRemarkButtonClick}
              >
                Add Remark
              </StyledButton>
              <AddProjectRemarkPopup
                setOpenDialog={(e) => setOpenPopup(e)}
                openDialog={openPopup}
                data={item}
                setProjects={setProjects}
                projects={projects}
                isMobile={isMobile}
              />
            </StyledMenuItem>

            <StyledMenuItem onClick={(e) => clickDeleteModern(e)}>
              <StyledButton title="delete" width="140px">
                Remove from list
              </StyledButton>
            </StyledMenuItem>
          </StyledMenu>
        </>
      )}

      {removeEdit === "cloneCcdc" && checkCcdcIdValue === true && (
        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ padding: 0, display: "none" }}
          ></IconButton>
          <StyledMenu
            addProjectRemark={addProjectRemark}
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <StyledMenuItem>
              <StyledButton
                title="edit"
                width="58px"
                onClick={handleCloneCcdcButtonClick}
              >
                Edit
              </StyledButton>
              <AddCcdcTemplatePopUp
                setOpenDialog={(e) => setOpenClonePopup(e)}
                openDialog={openClonePopup}
                item={item}
                handleSeeAll={handleSeeAll}
                isMobile={isMobile}
              />
            </StyledMenuItem>

            <StyledMenuItem onClick={clickDownloadedDelete}>
              <StyledButton
                title="delete"
                width="70px"
                onClick={clickDownloadedDelete}
              >
                Delete
              </StyledButton>
            </StyledMenuItem>
          </StyledMenu>

          <AlertPopup
            openPopup={openDeletePopup}
            setOpenPopup={setOpenDeletePopup}
            headerText={
              "You cannot delete the document once it has been submitted."
            }
            subHeaderText={""}
          />
        </>
      )}

      {removeEdit === "cloneCcdc" && checkCcdcIdValue === false && (
        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ padding: 0, display: "none" }}
          ></IconButton>
          <StyledMenu
            addProjectRemark={addProjectRemark}
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <StyledMenuItem>
              <StyledButton title="edit" width="58px" onClick={clickModify}>
                Edit
              </StyledButton>
            </StyledMenuItem>

            <StyledMenuItem onClick={clickDelete}>
              <StyledButton title="delete" width="70px" onClick={clickDelete}>
                Delete
              </StyledButton>
            </StyledMenuItem>
            <StyledMenuItem onClick={handleReview}>
              <StyledButton
                title="view"
                width={ccdc_status === "Reviewed" ? "115px" : "70px"}
                onClick={handleReview}
              >
                {ccdc_status === "Reviewed" ? "Submit/Export" : " Review"}
              </StyledButton>
            </StyledMenuItem>
          </StyledMenu>
        </>
      )}

      {removeEdit === "disableEnableClient" &&
        ckeckEnableDisable === "active" && (
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
              style={{ padding: 0, display: "none" }}
            ></IconButton>
            <StyledMenu
              removeEdit={removeEdit}
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <StyledMenuItem>
                <StyledButton title="edit" width="58px" onClick={clickModify}>
                  Edit
                </StyledButton>
              </StyledMenuItem>

              <StyledMenuItem onClick={clickDisable}>
                <StyledButton
                  title="disable"
                  width="70px"
                  onClick={clickDisable}
                >
                  Disable
                </StyledButton>
              </StyledMenuItem>
            </StyledMenu>
          </>
        )}

      {removeEdit === "disableEnableClient" &&
        ckeckEnableDisable === "inactive" && (
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
              style={{ padding: 0, display: "none" }}
            ></IconButton>
            <StyledMenu
              removeEdit={removeEdit}
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <StyledMenuItem>
                <StyledButton title="edit" width="58px" onClick={clickModify}>
                  Edit
                </StyledButton>
              </StyledMenuItem>

              <StyledMenuItem onClick={clickEnable}>
                <StyledButton title="enable" width="70px" onClick={clickEnable}>
                  Enable
                </StyledButton>
              </StyledMenuItem>
            </StyledMenu>
          </>
        )}

      {removeEdit === "addNewUserInClient" && (
        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ padding: 0, display: "none" }}
          ></IconButton>
          <StyledMenu
            removeEdit={removeEdit}
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <StyledMenuItem>
              <StyledButton
                title="User List"
                width="80px"
                onClick={showUserList}
              >
                User List
              </StyledButton>
            </StyledMenuItem>

            <StyledMenuItem>
              <StyledButton
                title="Add User"
                width="80px"
                onClick={addNewUser}
              >
                Add User
              </StyledButton>
            </StyledMenuItem>
          </StyledMenu>
        </>
      )}

      {removeEdit === "uploadClientFile" && (
        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ padding: 0, display: "none" }}
          ></IconButton>
          <StyledMenu
            removeEdit={removeEdit}
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <StyledMenuItem>
              <StyledButton title="upload" width="58px" onClick={clickModify}>
                Upload
              </StyledButton>
            </StyledMenuItem>
          </StyledMenu>
        </>
      )}
    </>
  );
};
