import React from "react";

import {
  StyledCellTitle,
  StyledCellCommon,
  StyledCellAction,
  StyledApprovedContainer,
  StyledPendingContainer,
} from "./styles";
import { ReactComponent as ViewIcon } from "../../../../assets/common/ViewIcon.svg";

import ConciseTable from "components/filesListTable/conciseTable";
import DateTimePickerField from "components/dateTimePickerField";

export const AllPendingPpcTable = ({
  data,
  isMobile,
  borderRadius,
  boxShadow,
  isShortened,
  selectTab,
}) => {
  const body = (bodyData) => {
    return (
      <>
        <StyledCellTitle component={"tr"} borderRadius={borderRadius}>
          {bodyData?.institutionName}
        </StyledCellTitle>
        {!isShortened && (
          <StyledCellCommon
            component={"tr"}
            scope="row"
            borderRadius={borderRadius}
            width="29%"
          >
            {bodyData?.projectName}
          </StyledCellCommon>
        )}
        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
          width="15%"
        >
          {bodyData.progress_payment_no}
        </StyledCellCommon>
        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
          width="20%"
        >
          <DateTimePickerField
            showDateField={true}
            withoutMargin={true}
            dateValue={bodyData?.updated_at ? bodyData?.updated_at : ""}
            readOnly={true}
            paddingRight="0px"
          />
        </StyledCellCommon>
        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
          width="18%"
        >
          {bodyData.status === "Approved" ? (
            <StyledApprovedContainer>Approved</StyledApprovedContainer>
          ) : bodyData.status === null ? (
            <StyledPendingContainer>Pending</StyledPendingContainer>
          ) : (
            ""
          )}
        </StyledCellCommon>
        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
        >
          {/* <ViewIcon /> */}
        </StyledCellCommon>
      </>
    );
  };

  //   const actions = (props) => {
  //     return (
  //       <>
  //         <StyledCellAction
  //           component={"tr"}
  //           scope="row"
  //           align="center"
  //           borderRadius={borderRadius}
  //         >
  //           <ActionsMenu
  //             client_id={props.id}
  //             selectTab={selectTab}
  //             companyName={props.institution_name}
  //           />
  //         </StyledCellAction>
  //       </>
  //     );
  //   };

  const createHeader = (title, width, align) => {
    return {
      title,
      width: width || "",
      align: align || "left",
    };
  };

  const headers = [
    createHeader("Company Name", "21.5%"),
    createHeader("Project Name", "28%"),
    createHeader("PPC No", "17%"),
    createHeader("PPC Updated Date", "19%"),
    createHeader("Status", "18%"),
    createHeader(""),
  ];
  const shortenedHeaders = [
    createHeader("Company Name"),
    createHeader("Project Name"),
    createHeader(""),
    createHeader(""),
  ];

  return (
    <div>
      <ConciseTable
        headers={isMobile ? "" : isShortened ? shortenedHeaders : headers}
        largefont={false}
        borderSpacing="0 5px !important"
      />
      <ConciseTable
        // actions={actions}
        // actionType="client"
        data={data}
        //height="1200px"
        body={body}
        boxShadow={boxShadow}
        borderRadius={borderRadius}
        // headers={isMobile ? "" : isShortened ? shortenedHeaders : headers}
      />
    </div>
  );
};
