import React, { useState } from "react";

import { MediumText, RegularText, StyledBox, SmallText } from "../styles";

import { FunctionalButton } from "../../../components/functionalButton";
import {
  FunctionArea,
  IntroArea,
  StyledTitle,
  ButtonContainer,
  ComingSoonImg,
  ImgDiv,
} from "./styles";

import StatusReportIcon from "assets/serviceProvider/StatusReportIcon";

export const StatusReport = ({ isMobile, selectedTab }) => {
  const handleAddRef = () => {};
  const [comingSoon, setComingSoon] = useState(true);

  return (
    <FunctionArea isMobile={isMobile}>
      {isMobile && (
        <StyledBox
          boxHeight="110px"
          borderRadius="15px"
          isMobile={isMobile}
          onClick={handleAddRef}
          style={{ cursor: "pointer" }}
        >
          {comingSoon && (
            <>
              <StatusReportIcon />
              <StyledTitle>
                <SmallText>Coming Soon</SmallText>
              </StyledTitle>
            </>
          )}
          {!comingSoon && (
            <>
              <StatusReportIcon />
              <StyledTitle>
                <SmallText>Coming Soon</SmallText>
              </StyledTitle>
            </>
          )}
        </StyledBox>
      )}
      {!isMobile && (
        <>
          <MediumText>Status Reports</MediumText>
          <StyledBox boxHeight="216px">
            {comingSoon && (
              <>
                {/* <ImgDiv style={{ width: "100%" }}>
                  <ComingSoonImg src={criteriaComingSoon} />
                </ImgDiv> */}
                <IntroArea>
                  <StatusReportIcon />
                  {/* <StyledTitle>
                    <SmallText>Coming Soon</SmallText>
                  </StyledTitle> */}
                </IntroArea>
                <ButtonContainer>
                  <FunctionalButton
                    buttonTitle="View Status Reports"
                    width="80%"
                    marginRight="0px"
                    isReversedColor={true}
                    hasBoxShadow={true}
                    disabled={true}
                  />
                </ButtonContainer>
              </>
            )}
            {!comingSoon && (
              <>
                <IntroArea>
                  <StatusReportIcon />
                  <StyledTitle>
                    <SmallText>Coming Soon</SmallText>
                  </StyledTitle>
                </IntroArea>
                <ButtonContainer>
                  <FunctionalButton
                    buttonTitle="View Status Reports"
                    handleButton={handleAddRef}
                    width="80%"
                    marginRight="0px"
                    isReversedColor={true}
                    hasBoxShadow={true}
                  />
                </ButtonContainer>
              </>
            )}
          </StyledBox>
        </>
      )}
    </FunctionArea>
  );
};
