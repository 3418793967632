import React, { useState, useEffect } from "react";
import { FunctionalButton } from 'components/functionalButton';
import { Popup } from 'components/popup';
import { CloseButton } from "components/closeButton";
import PledgxLogo from "assets/common/pledgxLogo";
import { referenceRedirectTo } from "api/reference.js";
import Input from 'components/inputField';
import PhoneInput from "react-phone-input-2";

import {
  TitleDiv,
  TitleText,
  PopupContainer,
  ButtonDiv,
  FormArea,
  FormTitle,
  StyledAddressContainer
} from "./styles.js";


export const RedirectToReference = ({
  openPopup,
  setOpenPopup,
  isMobile,
  data,
  setData
}) => {
  const [redirectEmail, setRedirectEmail] = useState(data?.referee?.email || "");
  const [firstName, setFirstName] = useState(data?.referee?.first_name || "");
  const [lastName, setLastName] = useState(data?.referee?.last_name || "");
  const [phoneNumber, setPhoneNumber] = useState(data?.referee?.phone || "");
  const [companyName, setCompanyName] = useState(data?.referee?.company || "");
  const [institutionName, setInstitutionName] = useState(data?.company_name || "");
  const [relationship, setRelationship] = useState(data?.referee?.relationship || "");

  const handleDone = () => {
    setOpenPopup(false);
  };


  const saveReferenceRedirectTo = () => {
    const newData = { ...data, company_name: institutionName, redirect_email: redirectEmail, other_first_name: firstName, other_last_name: lastName, other_company_name: companyName, other_relationship: relationship, other_phone_number: phoneNumber }
    referenceRedirectTo(newData, () => {
      setOpenPopup(false);
      window.location.replace("https://pledgx.com");
    }, () => { });
  };



  useEffect(() => {
    const updatedData = { ...data };
    updatedData["first_name"] = firstName;
    updatedData["last_name"] = lastName;
    updatedData["company_name"] = companyName;
    updatedData["redirect_email"] = redirectEmail;
    updatedData["relationship"] = relationship;
    setData(updatedData);
  }, [
    firstName,
    lastName,
    companyName,
    redirectEmail,
    relationship
  ]);



  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width={isMobile ? "100%" : "650px"}
      height={isMobile ? "100vh" : "550px"}
      dialogContent={(
        <>
          <CloseButton handleClose={handleDone} />
          <PopupContainer>
            <TitleDiv>
              <PledgxLogo width={isMobile ? 75 : ""} height={isMobile ? 74 : ""} />
            </TitleDiv>
            <FormTitle style={{ marginTop: 10 }}>
              {"Reference Redirect To"}
            </FormTitle>

            <FormArea isMobile={isMobile}>
              <StyledAddressContainer>
                <Input
                  id="inputWrap"
                  label="First Name"
                  type="text"
                  placeholder=""
                  labelSize="12px"
                  fontSize="18px"
                  value={firstName}
                  setValue={setFirstName}
                  disabled={false}
                />


                <Input
                  id="inputWrap last"
                  label="Last Name"
                  type="text"
                  placeholder=""
                  labelSize="12px"
                  fontSize="18px"
                  value={lastName}
                  setValue={setLastName}
                  disabled={false}
                />
              </StyledAddressContainer>

              <StyledAddressContainer>
                <Input
                  id="inputWrap"
                  label="Email"
                  type="text"
                  placeholder=""
                  labelSize="12px"
                  fontSize="18px"
                  value={redirectEmail}
                  setValue={setRedirectEmail}
                  disabled={false}
                />

                <Input
                  id="inputWrap last"
                  label="Company Name"
                  type="text"
                  placeholder=""
                  labelSize="12px"
                  fontSize="18px"
                  value={companyName}
                  setValue={setCompanyName}
                  disabled={false}
                />

              </StyledAddressContainer>

              <StyledAddressContainer>


                <div style={{ marginTop: "24px", width: "100%", marginLeft: '0px', marginRight: '-10px' }}>
                  <label htmlFor="phone" style={{ fontSize: "12px" }}>
                    Phone Number
                  </label>
                  <div style={{ height: "48px", width: "100%", marginTop: "-10px" }}>
                    <PhoneInput
                      id="phone"
                      country={"ca"}
                      value={phoneNumber}
                      onChange={(val) => setPhoneNumber(val)}
                      inputStyle={{
                        fontSize: "18px",
                        padding: "8px",
                        lineHeight: "24px",
                        marginLeft: "20px",
                        paddingLeft: "28px",
                        height: "100%",
                        width: "90%",
                      }}
                      dropdownStyle={{ fontSize: "18px", lineHeight: "24px" }}
                      className="custom-phone-input"
                    />
                  </div>
                </div>


                <Input
                  id="inputWrap last"
                  label="Ralationship"
                  type="text"
                  placeholder=""
                  labelSize="12px"
                  fontSize="18px"
                  value={relationship}
                  setValue={setRelationship}
                  disabled={false}
                />
              </StyledAddressContainer>
            </FormArea>

            <ButtonDiv isMobile={isMobile} style={{ justifyContent: "center" }}>
              <FunctionalButton
                buttonTitle="GO BACK"
                handleButton={handleDone}
                isReversedColor={true}
                height="48px"
                width="180px"
                marginTop="0px"
                marginRight="16px"
              />
              <FunctionalButton
                buttonTitle={"CONFIRM"}
                height="48px"
                width="180px"
                marginTop="0px"
                marginRight="16px"
                handleButton={() => saveReferenceRedirectTo()}
              />
            </ButtonDiv>
          </PopupContainer>

        </>
      )}
    />

  )
}
