import React, { useEffect, useRef, useState } from "react";
import { FooterArea, HeaderArea } from "./styles";
import { FunctionalButton } from "../../components/functionalButton";

const Footer = ({ isMobile, multiple = false, setFile, attachFile }) => {
  const fileInputRef = useRef(null);

  return (
    <>
      {!isMobile && (
        <>
          <FooterArea>
            <FunctionalButton
              buttonTitle="ATTACH"
              handleButton={() => {
                attachFile();
              }}
              height="45px"
              width="25%"
            />
            <FunctionalButton
              buttonTitle="UPLOAD"
              height="45px"
              handleButton={() => fileInputRef.current.click()}
              width="25%"
            />
            <input
              type="file"
              //multiple={multiple}
              style={{ display: "none" }}
              ref={fileInputRef}
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png, application/pdf, application/msword,image/jpeg,image/png"
              onClick={(e) => {
                e.target.value = null;
                fileInputRef.current.click();
              }}
              onChange={(e) => {
                if (
                  e.target.files[0].type === "application/pdf" ||
                  e.target.files[0].type === "application/msword" ||
                  e.target.files[0].type === "image/jpeg" ||
                  e.target.files[0].type === "image/png" ||
                  e.target.files[0].type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                ) {
                  if (multiple) {
                    setFile(e.target.files);
                  } else {
                    setFile(e.target.files[0]);
                  }
                }
              }}
              // onChange={handleChange}
            />
          </FooterArea>
        </>
      )}
    </>
  );
};

export default Footer;
