import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { COLORS } from "../../constants";

export const StyledTable = styled(Table)`
  && {
    border-spacing: ${(props) => props.borderSpacing || "0 16px !important"};
    margin-top: ${(props) => (props.concise ? "0" : "16px")};
    border-collapse: separate;

    th {
      border: none;
      height: 10px;
      border-spacing: 0 0 !important;
    }

    tbody {
      transform: translate(0, -13px);
    }

    tr {
      border-top: 1px #ebe9f1 solid;
      border-bottom: 1px #ebe9f1 solid;
    }

    ticon {
      border-top: 1px #ebe9f1 solid;
      border-bottom: 1px #ebe9f1 solid;
      border-left: 1px #ebe9f1 solid;
    }

    taction {
      border-top: 1px #ebe9f1 solid;
      border-bottom: 1px #ebe9f1 solid;
      border-right: 1px #ebe9f1 solid;
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
  && {
    ${(props) => (props.header ? "" : "height: 56px;")}
    background-color: ${(props) =>
      props.header ? "transparent" : `${props.backgroundColor || "white"}`};
    border-radius: ${(props) => props.borderRadius || "0.5rem"};
    ${(props) => (props.boxShadow ? `box-shadow: ${props.boxShadow};` : "")}

    &&.MuiTableRow-root.MuiTableRow-hover {
      &:hover {
        cursor: pointer;
        background-color: white;
        && tr {
          border-top: 2px solid ${COLORS.primaryOrange};
          border-bottom: 2px solid ${COLORS.primaryOrange};
          :first-child {
            border-left: 2px solid ${COLORS.primaryOrange};
          }
          :last-child {
            border-right: 2px solid ${COLORS.primaryOrange};
          }
        }
      }
    }

    &&.MuiTableRow-root.Mui-selected {
      background-color: white;
      && tr {
        border-top: 2px solid ${COLORS.primaryOrange};
        border-bottom: 2px solid ${COLORS.primaryOrange};
        :first-child {
          border-left: 2px solid ${COLORS.primaryOrange};
        }
        :last-child {
          border-right: 2px solid ${COLORS.primaryOrange};
        }
      }
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  && {
    font-family: SF Pro Text;
    font-weight: ${(props) => (props.header ? "500" : "400")};
    color: ${(props) => (props.header ? "#B9B9C3" : "#6E6B7B")};
    font-size: ${(props) =>
      props.header ? (props.largeFont ? "18px" : "14.5px") : "13px"};
    ${(props) => (props.width ? `width: ${props.width};` : "")}
    &.MuiTableCell-root {
      padding: 0 16px;

      &:first-child {
        border-top-left-radius: ${(props) => props.borderRadius || "0.5rem"};
        border-bottom-left-radius: ${(props) => props.borderRadius || "0.5rem"};
        border-left: ${(props) =>
          props.header ? "" : `1px ${COLORS.greyBorder} solid`};
      }

      &:last-child {
        border-top-right-radius: ${(props) => props.borderRadius || "0.5rem"};
        border-bottom-right-radius: ${(props) =>
          props.borderRadius || "0.5rem"};
        border-right: ${(props) =>
          props.header ? "" : `1px ${COLORS.greyBorder} solid`};
      }
    }
  }
`;

export const StyledTableCellIndex = styled(StyledTableCell)`
  width: 7%;
`;

export const StyledConciseTableContainer = styled.div`
  && {
    position: relative;
    max-height: ${(props) =>
      props.length
        ? `${props.length * 70}px`
        : `${window.innerHeight - 240}px`};
    //overflow-y: auto;
    overflow: auto;
    padding-right: ${(props) => props.paddingRight || "16px"};
    width: ${(props) => (props.width ? `${props.width}` : "")};
    height: ${(props) => (props.height ? `${props.height}` : "auto")};
  }

  &&::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &&::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: ${COLORS.scrollbarTrack};
    ${(props) => (props.show ? "" : "visibility: hidden;")};
  }

  &&::-webkit-scrollbar-thumb {
    background-color: ${COLORS.primaryOrange};
  }
`;

// Borderless concise table Styles

export const StyledBorderLessTableContainer = styled.div`
  && {
    max-height: ${(props) =>
      props.length ? `${props.length * 70}px` : "210px"};
    overflow-y: auto;
    padding-right: 16px;
  }

  &&::-webkit-scrollbar {
    width: 4px;
    height: 1px;
  }

  &&::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: ${COLORS.scrollbarTrack};
    ${(props) => (props.show ? "" : "visibility: hidden;")};
  }

  &&::-webkit-scrollbar-thumb {
    background-color: ${COLORS.primaryOrange};
  }
`;

export const StyledBorderlessTable = styled(Table)`
  && {
    th {
      border: none;
      height: 10px;
    }
  }
`;

export const StyledBorderlessTableRow = styled(TableRow)`
  && {
    ${(props) => (props.header ? "" : "height: 56px;")}
    border: none;
    box-shadow: none;
    padding-top: 16px;
  }
`;

export const StyledHeaderCheckBoxCell = styled(StyledTableCell)`
  && {
    font-size: 14px;
    text-align: center;

    &&.MuiTableCell-root {
      padding: 0px;
    }

    &&.MuiTableCell-head {
      // width: 20px;
    }
  }
`;

export const StyledActionHeaderCell = styled(StyledHeaderCheckBoxCell)`
  && {
    color: ${COLORS.primaryOrange};
    &&.MuiTableCell-head {
      padding-left: 20px;
      gay: 20px;
    }
    &&.MuiTableCell-root:first-child {
      border-left: none;
    }
  }
`;

export const StyledActionHeaderBorderCell = styled(StyledTableCell)`
  && {
    color: ${COLORS.primaryOrange};
`;

export const StyledBorderLessConciseTableContainer = styled(
  StyledConciseTableContainer
)`
  && {
    height: ${(props) => (props.height ? `${props.height}` : "")};
    border: transparent;
    border-radius: 0px;
    width: ${(props) => (props.width ? `${props.width}` : "1300px")};
    padding-right: 16px;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const StyledHeaderContainer = styled(
  StyledBorderLessConciseTableContainer
)`
  display: flex;
  justify-content: center;
`;

export const StyledActionContainer = styled.div`
  gap: 30px;
`;
