import styled from 'styled-components';
import background from 'assets/tutorialBubble/background.svg';
import { Typography } from '@material-ui/core';
import { TYPOGRAPHY } from '../../constants';

export const BubbleDiv = styled.div`
  background-image: url(${background});
  height: 98px;
  width: 180px;
  position: absolute;
  top: 42px;
  right: -6px;
`;

export const BubbleText = styled(Typography)`
  &&{
    font-family:${TYPOGRAPHY.primaryFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
    padding-top: ${props => props.paddingTop || "16px"};
    margin: ${props => props.margin || "0 8px 0"};
  }
`;

export const ChoiceArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px 0;
`;

export const ImgContainer = styled.img`
  padding-top: 8px;
`;

export const ButtonText = styled(Typography)`
  &&{
    font-family:${TYPOGRAPHY.primaryFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: white;
    padding-top: ${props => props.paddingTop || "16px"};
    margin: ${props => props.margin || "0 8px 0"};
    cursor: pointer;
  }
`;