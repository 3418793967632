import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { conciergeSelector } from '../../../data/selectors/user';
import { activateConciergeService, generateConciergeCode, getConciergeServiceStatus, invalidateConciergeCode } from '../../../data/actions/user';

import classes from './styles.module.css';
import { COLORS, FONT_SIZES } from '../../../constants';
import Spinner from '../../../components/spinner';
import { FunctionalButton } from '../../../components/functionalButton';

export const Concierge = () => {
  const initialState = {
    error: null,
    loading: false,
    generateCode: false,
    invalidateCode: false,
    staff: {
      activateService: false,
      login: false,
      targetEmail: null
    }
  };
  const [state, setState] = useState(initialState);
  const dispatch = useDispatch();
  let history = useHistory();
  const conciergeService = useSelector(conciergeSelector);
  const targetEmailRef = useRef();

  useEffect(() => {
    dispatch(getConciergeServiceStatus(null, (e) => handleResponse(e)));
  }, []);

  function handleResponse(res) {
    if (res?.response?.data?.message) {
      setState({ ...state, error: res.response.data.message });
    };
  };

  function handleInteraction({ action, payload }) {
    switch (action) {
      case 'ACTIVATE_CONCIERGE_SERVICE':
        setState({ ...state, staff: { ...state.staff, activateService: true, targetEmail: null } });
        break;
      case 'ACTIVATE_CONCIERGE_SERVICE_CANCEL':
        setState({ ...state, staff: { ...state.staff, activateService: false } });
        break;
      case 'ACTIVATE_CONCIERGE_SERVICE_CONFIRM':
        if (!state.staff.targetEmail) {
          setState({
            ...state,
            staff: {
              ...state.staff,
              targetEmail: targetEmailRef.current
            }
          });
          targetEmailRef.current = null;
        } else {
          setState({ ...state, loading: true });
          dispatch(
            activateConciergeService(
              { customer_email: state.staff.targetEmail },
              setState({
                ...state,
                loading: false,
                staff: {
                  ...state.staff,
                  activateService: false,
                  targetEmail: null
                }
              }),
              (e) => handleResponse(e)
            )
          );
          targetEmailRef.current = null;
        };
        break;
      case 'CLEAR_ERROR':
        setState(initialState);
        break;
      case 'CONCIERGE_LOGIN':
        setState({ ...state, staff: { ...state.staff, login: true } });
        break;
      case 'CONCIERGE_LOGIN_CANCEL':
        setState({ ...state, staff: { ...state.staff, login: false } });
        break;
      case 'CONCIERGE_LOGIN_CONFIRM':
        // history.push('/staff/account/concierge_login');
        window.open('/staff/account/concierge_login', '_blank');
        break;
      case 'GENERATE_CODE':
        if (conciergeService && conciergeService.code_active) {
          setState({ ...state, generateCode: true });
        } else {
          handleInteraction({ action: 'GENERATE_CODE_CONFIRM' });
        };
        break;
      case 'GENERATE_CODE_CANCEL':
        setState({ ...state, generateCode: false });
        break;
      case 'GENERATE_CODE_CONFIRM':
        setState({ ...state, loading: true });
        dispatch(
          generateConciergeCode(
            setState({
              ...state,
              loading: false,
              generateCode: false
            }),
            (e) => handleResponse(e)
          )
        );
        break;
      case 'INVALIDATE_CODE':
        setState({ ...state, invalidateCode: true });
        break;
      case 'INVALIDATE_CODE_CANCEL':
        setState({ ...state, invalidateCode: false });
        break;
      case 'INVALIDATE_CODE_CONFIRM':
        setState({ ...state, loading: true });
        dispatch(
          invalidateConciergeCode(
            setState({
              ...state,
              loading: false,
              invalidateCode: false
            }),
            (e) => handleResponse(e)
          )
        );
        break;
    };
  };

  if ((!state.loading && !state.error) && conciergeService) {
    if (conciergeService.service_type === 'ADMIN') {
      return (
        <>
          <div className={classes['major-element-title']} style={{ fontSize: FONT_SIZES.body_2 }}>Concierge Service</div>
          <div className={classes['element-content']}>
            <div className={classes['major-element-description']} style={{ fontSize: FONT_SIZES.body_3 }}>
              {
                conciergeService.service_active ?
                  conciergeService.code_active ?
                    <>
                    </>
                    :
                    <>
                      <span style={{ textDecoration: 'underline' }}>Your concierge service is active.</span>&nbsp;
                      <span>Generate a code, and share it with our customer success staff to allow us to make changes to your account on your behalf.</span>
                    </>
                  :
                  conciergeService.code_active ?
                    <>
                      <span>Your concierge service is expired. Your current concierge code is still active and may be used until it expires, but you will not be able to generate new concierge codes.</span>&nbsp;
                      <span className={classes['email-link']} onClick={() => window.location.href = 'mailto:support@pledgx.com?subject=Concierge%20Service&body=Please%20reactivate%20my%20concierge%20service.'}>Click here to contact us to reactivate your concierge service.</span>
                    </>
                    :
                    <>
                      <span>Our concierge service lets you set up your account without any hassles. We'll handle adding your projects, and other tasks.</span>&nbsp;
                      <span className={classes['email-link']} onClick={() => window.location.href = 'mailto:support@pledgx.com?subject=Concierge%20Service&body=Please%20activate%20my%20concierge%20service.'}>Click here to contact us for more information.</span>
                    </>
              }
            </div>
          </div>
          {
            conciergeService.code_active &&
            <div className={classes['element-content']}>
              <div className={classes['major-element-description']} style={{ fontSize: FONT_SIZES.body_2 }}>
                <span>Your concierge code is:</span>&nbsp;
                <span style={{ fontWeight: '700', color: COLORS.primaryOrange }}>{conciergeService.code}</span>
                <span>.</span>&nbsp;
                <span className={classes['email-link']} style={{ fontSize: FONT_SIZES.body_4 }} onClick={() => window.location.href = `mailto:support@pledgx.com?subject=Concierge%20Code%20Generated&body=Code:%20${conciergeService.code}%0D%0A`}>Click here to contact a customer success representative to make use of your concierge service.</span>
              </div>
            </div>
          }
          <div className={classes['element-content']}>
            {
              (state.generateCode || state.invalidateCode) &&
              <div className={classes['major-element-description']} style={{ fontWeight: '700', fontSize: FONT_SIZES.body_3, color: COLORS.primaryRed }}>
                <span>Are you sure you want to</span>&nbsp;
                {
                  state.generateCode ?
                    <span>generate a new concierge code?</span>
                    :
                    <span>invalidate your concierge code?</span>
                }
              </div>
            }
            {
              (state.generateCode || state.invalidateCode) ?
                <div className={classes['major-element-interaction']}>
                  <FunctionalButton
                    buttonTitle={state.generateCode ? 'Generate New Code' : 'Invalidate Code'}
                    handleButton={() => handleInteraction({ action: state.generateCode ? 'GENERATE_CODE_CONFIRM' : 'INVALIDATE_CODE_CONFIRM' })}
                    width='250px'
                    height='50px'
                    marginTop='20px'
                    marginRight='20px'
                    isReversedColor={true}
                  />
                  <FunctionalButton
                    buttonTitle='Cancel'
                    handleButton={() => handleInteraction({ action: state.generateCode ? 'GENERATE_CODE_CANCEL' : 'INVALIDATE_CODE_CANCEL' })}
                    width='250px'
                    height='50px'
                    marginTop='20px'
                    marginRight='20px'
                    isReversedColor={false}
                  />
                </div>
                :
                <div className={classes['major-element-interaction']}>
                  {
                    conciergeService.service_active &&
                    <FunctionalButton
                      buttonTitle={conciergeService.code_active ? 'Generate New Code' : 'Generate Code'}
                      handleButton={() => handleInteraction({ action: 'GENERATE_CODE' })}
                      width='250px'
                      height='50px'
                      marginTop='20px'
                      marginRight='20px'
                      isReversedColor={conciergeService.code_active ? true : false}
                    />
                  }
                  {
                    conciergeService.code_active &&
                    <FunctionalButton
                      buttonTitle='Invalidate Code'
                      handleButton={() => handleInteraction({ action: 'INVALIDATE_CODE' })}
                      width='250px'
                      height='50px'
                      marginTop='20px'
                      marginRight='20px'
                      isReversedColor={true}
                    />
                  }
                </div>
            }
            {
              conciergeService.code_active &&
              <div className={classes['major-element-description']} style={{ fontSize: FONT_SIZES.body_5 }}>
                <span style={{ fontStyle: 'italic' }}>Generating/Invalidating your concierge code disables concierge access to your account</span>
              </div>
            }
          </div>
        </>
      );
    } else if (conciergeService.service_type === 'STAFF') {
      return (
        <>
          <div className={classes['major-element-title']} style={{ fontSize: FONT_SIZES.body_2 }}>Concierge Service</div>
          {
            conciergeService.lastActivatedEmail &&
            <div className={classes['element-content']}>
              <div className={classes['major-element-description']} style={{ fontSize: FONT_SIZES.body_3, color: COLORS.primaryGreen }}>
                <span>Successfully activated concierge service for {conciergeService.lastActivatedEmail}</span> &nbsp;
                <br />
                <span>The activated concierge code is:</span>&nbsp;
                <span style={{ fontWeight: '700', color: COLORS.primaryOrange }}>{conciergeService.code}</span>
              </div>
            </div>
          }
          <div className={classes['element-content']}>
            {
              (state.staff.activateService) ?
                state.staff.targetEmail ?
                  <div className={classes['major-element-description']} style={{ fontWeight: '700', fontSize: FONT_SIZES.body_3, color: COLORS.primaryRed }}>
                    <span>Are you sure you want to activate concierge service for {state.staff.targetEmail}?</span>
                  </div>
                  :
                  <div className={classes['major-element-description']}>
                    <input className={classes['concierge-id-input']} type='text' placeholder='customer_email@mail.com' onChange={(e) => targetEmailRef.current = e.target.value} />
                  </div>
                :
                <></>
            }
            {
              (state.staff.login) &&
              <div className={classes['major-element-description']} style={{ fontWeight: '700', fontSize: FONT_SIZES.body_3, color: COLORS.primaryRed }}>
                <span>Are you sure you want to go to the concierge login screen?</span>
              </div>
            }
            {
              (state.staff.activateService || state.staff.login) ?
                <div className={classes['major-element-interaction']}>
                  <FunctionalButton
                    buttonTitle={state.staff.activateService ? 'Activate Concierge Service' : 'Go To Login'}
                    handleButton={() => handleInteraction({ action: state.staff.activateService ? 'ACTIVATE_CONCIERGE_SERVICE_CONFIRM' : 'CONCIERGE_LOGIN_CONFIRM' })}
                    width='300px'
                    height='50px'
                    marginTop='20px'
                    marginRight='20px'
                    isReversedColor={true}
                  />
                  <FunctionalButton
                    buttonTitle='Cancel'
                    handleButton={() => handleInteraction({ action: state.staff.activateService ? 'ACTIVATE_CONCIERGE_SERVICE_CANCEL' : 'CONCIERGE_LOGIN_CANCEL' })}
                    width='200px'
                    height='50px'
                    marginTop='20px'
                    marginRight='20px'
                    isReversedColor={false}
                  />
                </div>
                :
                <div className={classes['major-element-interaction']}>
                  <FunctionalButton
                    buttonTitle='Activate Concierge Service'
                    handleButton={() => handleInteraction({ action: 'ACTIVATE_CONCIERGE_SERVICE' })}
                    width='300px'
                    height='50px'
                    marginTop='20px'
                    marginRight='20px'
                    isReversedColor={false}
                  />
                  <FunctionalButton
                    buttonTitle='Concierge Login'
                    handleButton={() => handleInteraction({ action: 'CONCIERGE_LOGIN' })}
                    width='200px'
                    height='50px'
                    marginTop='20px'
                    marginRight='20px'
                    isReversedColor={true}
                  />
                </div>
            }
            {
              conciergeService.code_active &&
              <div className={classes['major-element-description']} style={{ fontSize: FONT_SIZES.body_5 }}>
                <span style={{ fontStyle: 'italic' }}>Generating/Invalidating your concierge code disables concierge access to your account</span>
              </div>
            }
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={classes['major-element-title']} style={{ fontSize: FONT_SIZES.body_2 }}>Concierge Service</div>
          <div className={classes['loading-content']}>
            <ErrorOutlineIcon />
            <span style={{ lineHeight: 'normal', marginLeft: '5px' }}>Concierge Service Unavailable</span>
          </div>
        </>
      );
    }
  } else if (!state.loading && state.error) {
    return (
      <>
        <div className={classes['major-element-title']} style={{ fontSize: FONT_SIZES.body_2 }}>Concierge Service</div>
        <div className={classes['error-content']}>
          <ErrorOutlineIcon sx={{ fontSize: '17px', color: COLORS.primaryRed }} />
          <span style={{ lineHeight: 'normal', marginLeft: '5px', fontSize: FONT_SIZES.body_3, color: COLORS.primaryRed }} >{state.error}</span>
        </div>
        <div className={classes['major-element-interaction']}>
          <FunctionalButton
            buttonTitle='Confirm'
            handleButton={() => handleInteraction({ action: 'CLEAR_ERROR' })}
            width='300px'
            height='50px'
            marginTop='20px'
            marginRight='20px'
            isReversedColor={false}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={classes['major-element-title']} style={{ fontSize: FONT_SIZES.body_2 }}>Concierge Service</div>
        <div className={classes['loading-content']}>
          <Spinner />
        </div>
      </>
    );
  };
};
