import React from "react";
import Input from "@material-ui/core/Input";

import { FileButton, InputLabel, FileLabel } from "./styles";

export default function FileInputButton({
  label,
  style,
  current,
  onChange,
  onRemove,
  fileError,
  inverse,
  withoutFileName,
  name = "Upload",
}) {
  return (
    <div style={{ ...style, width: "100%" }}>
      {label && <InputLabel>{label}</InputLabel>}
      <div style={{ display: "flex" }}>
        {!current && (
          <FileButton inverse={inverse}>
            <Input
              type="file"
              style={{ display: "none" }}
              onChange={onChange}
            ></Input>
            {name}
          </FileButton>
        )}
        {current && (
          <FileButton inverse={inverse} onClick={onRemove}>
            Remove
          </FileButton>
        )}
        {!withoutFileName && (
          <FileLabel>
            {fileError
              ? "This format is not compatible!"
              : current
              ? current.name
              : "No CV selected..."}
          </FileLabel>
        )}
      </div>
    </div>
  );
}
