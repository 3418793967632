import SelectDropdown from 'components/selectDropdown';
import React, { useEffect, useState } from 'react';
import PickList from '../../../components/pickList';
import { Row } from '../styles';
import { StyledCategoriesContainer } from './styles';

export const CategoriesSetup = ({ data, setData, setHasData }) => {
  const setInitialOption = () => {
    if (main.length > 0) {
        setHasData(true);
      switch(main) {
        case 'Construction':
          return construction;
        case 'Water and Sewer':
          return waterSewer;
        case 'Aggregates':
          return aggregates;
        case 'Building Operation - Supplies and Equipment':
          return buildingOperation;
        case 'Building Services':
          return buildingService;
        case 'Environmental Goods & Services':
          return environment;
        case 'Parks and Trails':
          return parks;
        case 'Recreation/Education':
          return recreationEducation;
        case 'Consulting Services':
          return consulting;
        case 'Road - Maintenance, Services and Traffic':
          return road;
        default:
          return []
      }
    }
    return [];
  }

  const mainOptions = [
    { key: 1, text: 'Aggregates', value: 'Aggregates' },
    { key: 2, text: 'Bridge Matls,Tools & Equipment', value: 'Bridge Matls,Tools & Equipment' },
    { key: 3, text: 'Building Operation - Supplies and Equipment', value: 'Building Operation - Supplies and Equipment' },
    { key: 4, text: 'Building Services', value: 'Building Services' },
    { key: 5, text: 'Construction', value: 'Construction' },
    { key: 6, text: 'Consulting Services', value: 'Consulting Services' },
    { key: 7, text: 'Environmental Goods & Services', value: 'Environmental Goods & Services' },
    { key: 8, text: 'Parking', value: 'Parking' },
    { key: 9, text: 'Parks and Trails', value: 'Parks and Trails' },
    { key: 10, text: 'Recreation/Education', value: 'Recreation/Education' },
    { key: 11, text: 'Road - Maintenance, Services and Traffic', value: 'Road - Maintenance, Services and Traffic' },
    { key: 12, text: 'Water and Sewer', value: 'Water and Sewer' },
  ];

  const aggregates = [
    { key: 1, text: 'Asphalt  ', value: 'Asphalt' },
  ];

  const buildingService = [
    { key: 1, text: 'Locksmith', value: 'Locksmith' },
    { key: 2, text: 'Overhead Door', value: 'Overhead Door' },
    { key: 3, text: 'Security & Monitoring', value: 'Security & Monitoring' },
  ];

  const buildingOperation = [
    { key: 1, text: 'Doors/Windows/Locks', value: 'Doors/Windows/Locks' },
    { key: 2, text: 'Window Coverings', value: 'Window Coverings' },
    { key: 3, text: 'Building Operation Supplies and Equipment', value: 'Building Operation Supplies and Equipment' },
    { key: 4, text: 'Construction', value: 'Construction' },
    { key: 5, text: 'Consulting Services', value: 'Consulting Services' },
    { key: 6, text: 'Electrical', value: 'Electrical' },
  ];

  const environment = [
    { key: 1, text: 'Green Power', value: 'Green Power' },
  ];

  const parks = [
    { key: 1, text: 'Engineered Wood Fiber Surface ', value: 'Engineered Wood Fiber Surface ' },
    { key: 2, text: 'Equipment & Supplies ', value: 'Equipment & Supplies Equipment & Supplies ' },
    { key: 3, text: 'Facility Management', value: 'Facility Management' },
  ];

  const recreationEducation = [
    { key: 1, text: 'Bleachers/Stands ', value: 'Bleachers/Stands' },
    { key: 2, text: 'Court Surface Specialists', value: 'Court Surface Specialists'},
    { key: 3, text: ' Paint (Field marking, ice paint, etc.)', value: ' Paint (Field marking, ice paint, etc.)' },
    { key: 4, text: 'Park Development', value: 'Park Development' },
    { key: 5, text: 'Playground Equipment', value: 'Playground Equipment' },
    { key: 6, text: 'Pool Services', value: 'Pool Services' },
    { key: 7, text: 'Pool Supplies', value: 'Pool Supplies' },
    { key: 8, text: 'Sports Field Lighting', value: 'Sports Field Lighting' },
    { key: 9, text: 'Welding Equipment & Supplies', value: 'Welding Equipment & Supplies'},
    { key: 10, text: 'Arena Supplies', value: 'Arena Supplies' },
    { key: 11, text: 'Fitness Equipment', value: 'Fitness Equipment' },
    { key: 12, text: 'Ice Resurfacer', value: 'Ice Resurfacer' },
    { key: 13, text: 'Recreation & Sporting Good Supplies (Toys, Games, etc.)',value: 'Recreation & Sporting Good Supplies (Toys, Games, etc.)' },
    { key: 14, text: 'Recreation Flooring / Restoration (Gym Floor, Rubberized, etc.)  ', value: 'Recreation Flooring / Restoration (Gym Floor, Rubberized, etc.) ' },
  ];

  const construction = [
    { key: 1, text: 'Demolition ', value: 'Demolition' },
    { key: 2, text: 'Drainage', value: 'Drainage'},
    { key: 3, text: 'Infrastructure (Wastewater)', value: 'Infrastructure (Wastewater)' },
    { key: 4, text: 'Municipal Drains', value: 'Municipal Drains' },
    { key: 5, text: 'Power Lines', value: 'Power Lines' },
    { key: 6, text: 'Utility', value: 'Utility' },
    { key: 7, text: 'Affordable Housing', value: 'Affordable Housing' },
    { key: 8, text: 'Infrastructure (Traffic Signalization)', value: 'Infrastructure (Traffic Signalization)' },
    { key: 9, text: 'Parks, Trails & Landscape', value: 'Parks, Trails & Landscape'},
    { key: 10, text: 'Oil & Gas Pipe', value: 'Oil & Gas Pipe' },
    { key: 11, text: 'Remediation Services', value: 'Remediation Services' },
    { key: 12, text: 'Restoration Services (fire, flood, etc.)', value: 'Restoration Services (fire, flood, etc.)' },
    { key: 13, text: 'Roads, Bridge, Sidewalks',value: 'Roads, Bridge, Sidewalks' },
    { key: 14, text: 'Building & Renovation',value: 'Building & Renovation' },
  ];

  const consulting = [
    { key: 1, text: 'Asbestos removal', value: 'Asbestos removal' },
    { key: 2, text: 'Roofing', value: 'Roofing'},
  ];

  const road = [
    { key: 1, text: 'Roadside Safety (Plyons, Baracades, etc.) ', value: 'Roadside Safety (Plyons, Baracades, etc.)' },
    { key: 2, text: 'Services (crack sealing, pavement markings, etc.)', value: 'Services (crack sealing, pavement markings, etc.)'},
    { key: 3, text: 'Streetlight supply & maintenance', value: 'Streetlight supply & maintenance' },
  ];

  const waterSewer =[
    { key: 1, text: 'Catch Basin Cleaning', value: 'Catch Basin Cleaning' },
    { key: 2, text: 'Cathodic Protection', value: 'Cathodic Protection' },
    { key: 3, text: 'CCTV Inspection', value: 'CCTV Inspection' },
    { key: 4, text: 'Emergency Watermain', value: 'Emergency Watermain' },
    { key: 5, text: 'Flow Testing', value: 'Flow Testing' },
    { key: 6, text: 'Hydrant Inspections ', value: 'Hydrant Inspections' },
    { key: 7, text: 'Hydrant Painting'  , value: 'Hydrant Painting' },
    { key: 8, text: 'New or Replacement Water/Sewer Lines'  , value: 'New or Replacement Water/Sewer Lines' },
    { key: 9, text: 'Wastewater Treatment & Services', value: 'Wastewater Treatment & Services' },
    { key: 10, text: 'Water Meters'  , value: 'Water Meters' },
    { key: 11, text: 'Watermain Swabbing'  , value: 'Watermain Swabbing' },
    { key: 12, text: 'Well & Water Related Services' , value: 'Well & Water Related Services' },
  ];

  const [main, setMain] = useState(data?.project_info?.categories?.length > 0 ? data.project_info.categories[0].main_categories : '');
  const [sub, setSub] = useState(data?.project_info?.categories?.length > 0 ? data.project_info.categories[0].sub_categories : '');
  const [options, setOptions] = useState(setInitialOption());

  const categories = data?.project_info?.categories?.length > 0 ? { ...data?.project_info?.categories[0] } : {};

  const handleMain = (props) => {
    if(props.value.length > 0) { //disable multiple selection for now
      if(props.value === 'Construction') {
        setOptions(construction);
      } else if(props.value === 'Water and Sewer') {
        setOptions(waterSewer);
      } else if(props.value === 'Aggregates'){
        setOptions(aggregates);
      } else if(props.value === 'Building Operation - Supplies and Equipment'){
        setOptions(buildingOperation);
      } else if(props.value === 'Building Services'){
        setOptions(buildingService);
      } else if(props.value === 'Environmental Goods & Services'){
        setOptions(environment);
      } else if(props.value === 'Parks and Trails'){
        setOptions(parks);
      } else if(props.value === 'Recreation/Education'){
        setOptions(recreationEducation);
      } else if(props.value === 'Consulting Services'){
        setOptions(consulting);
      } else if(props.value === 'Road - Maintenance, Services and Traffic'){
        setOptions(road);
      } else {
        setOptions([]);
      }
    }else{
      setOptions([]);
      categories['sub_categories'] = []
      setSub('');
      setMain('');
    }
      setMain(props.value);
      categories['main_categories'] = props.value;

      if(categories['main_categories'].length == 0) {
        categories['sub_categories'] = [];
        setHasData(false);
        setSub('');
        setMain('');
      } else {
        setHasData(true);
      }
      setData('project_info.categories', [categories]);

  }

  const handleSub = (props) => {
      setSub(props.value);
      categories['sub_categories'] = props.value;
      setData('project_info.categories', [categories]);
  }

  return (
    <Row marginTop="16px" marginBottom="16px">
      <StyledCategoriesContainer>
        {/* <PickList
          placeholder={'Categories'}
          value={main}
          label='Categories'
          options={mainOptions}
          handleSelect={handleMain}
          search={true}
          multiple={false}
          width="100%"
          height="50px"
          textDropdownMarginTop="12px"
        /> */}
        <SelectDropdown
          placeholder={'Select one'}
          value={main}
          label='Categories'
          options={mainOptions}
          handleSelect={handleMain}
          search={true}
          multiple={false}
          width="100%"
          height="50px"
          hasLabel="Category"
          hasData={main?.length > 0}
          clearable={true}
        />
      </StyledCategoriesContainer>
        <StyledCategoriesContainer>
          {/* <PickList
            placeholder={'Select multiple'}
            value={sub}
            label='Subcategories'
            options={options}
            handleSelect={handleSub}
            search={true}
            multiple={true}
            width="100%"
            height="50px"
            textDropdownMarginTop="12px"
          /> */}
          <SelectDropdown
            placeholder={'Select multiple'}
            value={sub}
            label='Subcategories'
            options={options}
            handleSelect={handleSub}
            search={true}
            multiple={true}
            width="100%"
            height="50px"
            hasLabel="Sub-category"
            hasData={sub?.length > 0}
            disabled={!(main && options.length > 0)}
          />
        </StyledCategoriesContainer>
    </Row>
  );
};
