import React, { useEffect, useRef, useState } from "react";
import {
  DetailedFormContainer,
  HeaderArea,
  LargeText,
  ScreenRoot,
  StyledBox,
  StyledHeaderDiv,
  StyledHeading,
  StyledProjectInfo,
  StyledTypography,
} from "./styles";
import { FunctionalButton } from "../../components/functionalButton";
import { useSelector } from "react-redux";
import { contactSelector } from "data/selectors/contact";

const Header = ({
  isMobile,
  uploadedFileCount,
  multiple = false,
  setFile,
  uploadedFileTotalSize,
  fileLimitCount,
  fileLimitSizeMB,
  docPoolPopUp,
}) => {
  const fileInputRef = useRef(null);

  return (
    <>
      <HeaderArea>
        <LargeText isMobile={isMobile}>
          {docPoolPopUp ? "Add Document" : "Document Pool"}
        </LargeText>
        {/* {isMobile && (
        <IconButton>
          <AddNewButtonIcon />
        </IconButton>
      )} */}
        {!isMobile && (
          <>
            {!docPoolPopUp && (
              <HeaderArea width="30%">
                {/* <FunctionalButton
                buttonTitle="DELETE"
                //handleButton={handleAdd}
                height="45px"
                width="70%"
              /> */}
                <FunctionalButton
                  buttonTitle="UPLOAD"
                  height="45px"
                  handleButton={() => fileInputRef.current.click()}
                  width="50%"
                />
                <input
                  type="file"
                  //multiple={multiple}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png, application/pdf, application/msword,image/jpeg,image/png"
                  onClick={(e) => {
                    e.target.value = null;
                    fileInputRef.current.click();
                  }}
                  onChange={(e) => {
                    if (
                      e.target.files[0].type === "application/pdf" ||
                      e.target.files[0].type === "application/msword" ||
                      e.target.files[0].type === "image/jpeg" ||
                      e.target.files[0].type === "image/png" ||
                      e.target.files[0].type ===
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    ) {
                      if (multiple) {
                        setFile(e.target.files);
                      } else {
                        setFile(e.target.files[0]);
                      }
                    }
                  }}
                  // onChange={handleChange}
                />

                {/* <SearchBar
              filteredData={filteredData}
              setInternalSearchResults={setInternalSearchResults}
              query={query}
              setQuery={setQuery}
              internalSearch={true}
            /> */}
              </HeaderArea>
            )}
          </>
        )}
      </HeaderArea>
      <StyledHeaderDiv>
        <StyledBox
          isMobile={isMobile}
          boxHeight="80px"
          boxWidth={docPoolPopUp ? " 32%" : "27%"}
          borderRadius="5px"
          margin="15px 30px 0 0"
        >
          <StyledHeading>Size</StyledHeading>
          <StyledProjectInfo>
            {`${uploadedFileTotalSize} / ${fileLimitSizeMB || "0.0"} MB used`}
          </StyledProjectInfo>
        </StyledBox>
        <StyledBox
          isMobile={isMobile}
          boxHeight="80px"
          boxWidth={docPoolPopUp ? "30%" : "15%"}
          borderRadius="5px"
          margin="15px 30px 0 0"
        >
          <StyledHeading>Number</StyledHeading>
          <StyledProjectInfo>{`${
            uploadedFileCount || 0
          } files`}</StyledProjectInfo>
        </StyledBox>
      </StyledHeaderDiv>
    </>
  );
};

export default Header;
