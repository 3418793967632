import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";

import "./style.css";
import home from "../../assets/sidebar/home.svg";
import win from "../../assets/sidebar/win.svg";
import secure from "../../assets/sidebar/secure.svg";
import perform from "../../assets/sidebar/perform.svg";
import about from "../../assets/sidebar/about.svg";

const drawerWidth = 82;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,

    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: 64,
  },
  drawerContainer: {
    overflow: "hidden",
  },
  drawerContainerBottom: {
    alignSelf: "center",
    position: "absolute",
    bottom: 100,
  },
  list: {
    display: "flex",
    margin: 0,
    padding: 0,
    flexDirection: "column",
  },
}));

const SideButton = withStyles((theme) => ({
  root: {
    width: "80px",
    height: "78px",
    margin: "4px 0px",
    backgroundColor: "white",
    borderRadius: "0px 8px 8px 0px",
    "&:hover": {
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      "&::before": {
        content: "''",
        backgroundColor: "#26303D",
        width: "5px",
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        borderRadius: "0px 200px 200px 0px",
      },
    },
  },
}))(Button);

function SideBar({ isMobile, executeScroll }) {
  const classes = useStyles();

  if (isMobile) return <></>;

  return (
    <Drawer
      className="sidebar"
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerContainer}>
        <List className={classes.list}>
          <SideButton onClick={() => executeScroll("home")}>
            <img className="sidebutton_logo" src={home} alt="home" />
            <div className="sidebutton_label">Home</div>
          </SideButton>
        </List>
        <Divider />
        <List className={classes.list}>
          <SideButton onClick={() => executeScroll("win")}>
            <img className="sidebutton_logo" src={win} alt="win" />
            <div className="sidebutton_label">Qualify</div>
          </SideButton>
          <SideButton onClick={() => executeScroll("perform")}>
            <img className="sidebutton_logo" src={perform} alt="perform" />
            <div className="sidebutton_label">Perform</div>
          </SideButton>
          <SideButton onClick={() => executeScroll("secure")}>
            <img className="sidebutton_logo" src={secure} alt="secure" />
            <div className="sidebutton_label">Secure</div>
          </SideButton>
          <SideButton onClick={() => executeScroll("about")}>
            <img className="sidebutton_logo" src={about} alt="about" />
            <div className="sidebutton_label">About</div>
          </SideButton>
        </List>
      </div>
      <div className={classes.drawerContainerBottom}>
        <List className={classes.list}>
          <a
            href="https://www.linkedin.com/company/pledgx"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon className="connect_logo" />
          </a>
          <a
            href="https://www.twitter.com/pledgx"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon className="connect_logo" />
          </a>
          <a
            href="https://www.facebook.com/pledgx"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon className="connect_logo" />
          </a>
        </List>
      </div>
    </Drawer>
  );
}

export default SideBar;
