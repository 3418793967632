const ConvertToDate = (str) => {
  let date = new Date(new Date(str).getTime() + 60 * 60 * 24 * 1000);

  let mnth = date.getMonth() + 1;
  if (mnth < 10) {
    mnth = "0" + String(mnth);
  }

  let day = date.getDate();
  if (day < 10) {
    day = "0" + String(day);
  }

  return [mnth, day, date.getFullYear()].join("/");
};

export default ConvertToDate;
