import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { FunctionalButton } from "../../../components/functionalButton";
import { CommonBusinessType } from "components/commonBusinessType/index.js";
import RadioGroupButtonForProjectCategory from "components/radioGroupButtonForProjectCategory";

import {
  StyledSubtext,
  StyledQuestion,
  ButtonAtBottom
} from './styles';


export const Categories = ({
  setPanel,
  onboardingData,
  setOnboardingData,
  hasData,
  setIsLastPanel,
  setPreviousStepPanel,
  setIsBranchPanel
}) => {

  setIsLastPanel(false);
  setPreviousStepPanel(2);

  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [containsUNSPC, setContainsUNSPC] = useState(onboardingData?.company?.is_unspc_category_type || false);

  const confirmRadioChange = (val) => {
    if (val === 1) {
      setContainsUNSPC(false);
      setOnboardingData("company.is_unspc_category_type", false);
    } else {
      setContainsUNSPC(true);
      setOnboardingData("company.is_unspc_category_type", true);
    }
  };


  return (
    <>
      <StyledQuestion isMobile={isMobile} width="70%">What category is your business in?</StyledQuestion>
      <StyledSubtext isMobile={isMobile} width="70%">
        <RadioGroupButtonForProjectCategory
          containsUNSPC={containsUNSPC}
          confirmRadioChange={confirmRadioChange}
        />
      </StyledSubtext>
      <CommonBusinessType
        width="70%"
        isMobile={isMobile}
        data={onboardingData?.company}
        setData={setOnboardingData}
        notPopup={true}
        containsUNSPC={containsUNSPC}
        attribute="company.categories"
        usePlusIcon={false}
        marginTop="8px"
      />
      <ButtonAtBottom>
        <FunctionalButton
          buttonTitle="Next"
          handleButton={hasData ? () => { setPanel(2); setIsLastPanel(true) } : () => { setPanel(3); setIsBranchPanel(true) }}
          width={isMobile ? "90%" : "200px"}
          marginRight="0px"
          marginTop={isMobile ? "72px" : "48px"}
          disabled={!(onboardingData?.company?.categories?.length > 0)}
        />
      </ButtonAtBottom>
    </>
  );
}


