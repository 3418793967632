import React from 'react';
import { Text, View, StyleSheet, Image, TextSpan } from '@react-pdf/renderer';
import HealthUnseleted from "./images/healthUnseleted.png"
import HealthSeleted from "./images/healthSeleted.png"
import SquareSelected from "./images/squareSelected.png"
import SquareUnselected from "./images/squareUnselected.png"

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 0,
        justifyContent: 'flex-start',
        width: '95%',
        paddingLeft: 30,
        paddingRight: 0,
    },
    headerContainer2: {
        marginTop: 30,
        width: '40%',
        flex: 1,
        paddingLeft: 10,
        paddingRight: 30,
    },
    healthSafety: {
        marginTop: -10,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
    },
    healthSafetyWithoutLogo: {
        marginTop: 23,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
        position: 'relative',
    },
    remarks: {
        paddingBottom: 6,
        fontSize: 10,
        color: '#888888',
        position: 'relative',
        marginTop: 10,
    },
    remarksTab: {
        paddingBottom: 6,
        fontSize: 10,
        color: '#888888',
        position: 'relative',
        marginTop: 10,
        marginLeft: 20
    },
    spanText: {
        color: '#000000',
        marginTop: 0,
    },
    remarksTabText: {
        color: '#000000',
        marginTop: 0,
        marginLeft: 20
    },
});


const PageFourHealthSafetyUSA = ({ prequalifyData, companyLogo }) => (
    <View style={{ display: 'flex', flexDirection: 'row' }}>
        <View style={styles.headerContainer}>
            <Text style={styles.remarks}>Do you have a formal Health & Safety Program?</Text>
            <Text>
                {prequalifyData?.data?.healthSafetyProgram === true ?
                    <Image src={HealthSeleted} />
                    :
                    <Image src={HealthUnseleted} />

                }
                <span style={styles.spanText}>  Yes</span>
            </Text>

            <Text>
                {prequalifyData?.data?.noHealthSafetyProgram === true ?
                    <Image src={HealthSeleted} />
                    :
                    <Image src={HealthUnseleted} />

                }
                <span style={styles.spanText}>  No</span>
            </Text>

            {prequalifyData?.data?.healthSafetyProgram === true ?
                <Text style={styles.remarksTab}>Select procedures covered by the program:</Text>
                :
                <Text style={styles.remarksTab}>Explain how your company manages Health & Safety:</Text>
            }

            {prequalifyData?.data?.healthSafetyProgram === true ? (
                <Text style={styles.remarksTabText}>
                    {prequalifyData?.data?.healthSafetyProgramList.map((item, index) => (
                        <React.Fragment key={item.value}>
                            {item.value}
                            {index !== prequalifyData?.data?.healthSafetyProgramList.length - 1 && ','}
                        </React.Fragment>
                    ))}
                </Text>
            ) : (
                <Text style={styles.remarksTabText}>
                    {prequalifyData?.data?.noHealthSafetyProgramRemark}
                </Text>
            )}


            <Text style={styles.remarks}>Do you have a formal project Health and Safety Orientation program?</Text>
            <Text>
                {prequalifyData?.data?.healthSafetyEmployeeOrientationProgram === true ?
                    <Image src={HealthSeleted} />
                    :
                    <Image src={HealthUnseleted} />

                }
                <span style={styles.spanText}>  Yes</span>
            </Text>

            <Text>
                {prequalifyData?.data?.noHealthSafetyEmployeeOrientationProgram === true ?
                    <Image src={HealthSeleted} />
                    :
                    <Image src={HealthUnseleted} />

                }
                <span style={styles.spanText}>  No</span>
            </Text>


            {prequalifyData?.data?.healthSafetyEmployeeOrientationProgram === true ?
                <Text style={styles.remarksTab}>Select procedures covered by the orientation program:</Text>
                :
                <Text style={styles.remarksTab}>Explain how your company manages Health & Safety:</Text>
            }

            {prequalifyData?.data?.healthSafetyEmployeeOrientationProgram === true ? (
                <Text style={styles.remarksTabText}>
                    {prequalifyData?.data?.healthSafetyEmployeeOrientationProgramList.map((item, index) => (
                        <React.Fragment key={item.value}>
                            {item.value}
                            {index !== prequalifyData?.data?.healthSafetyEmployeeOrientationProgramList.length - 1 && ','}
                        </React.Fragment>
                    ))}
                </Text>
            ) : (
                <Text style={styles.remarksTabText}>{prequalifyData?.data?.noHealthSafetyEmployeeOrientationProgramRemark}</Text>
            )}


        </View>

    </View>
);

export default PageFourHealthSafetyUSA;
