import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from "../../../../constants";


export const PersonnelCardRoot = styled.div`
  position: relative;
  margin-right: 10px;

  width: 240px;
  min-height: 128px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  border: 1px solid ${COLORS.darkGrey3};
  border-radius: 3px;
`;

export const AddCard = styled(PersonnelCardRoot)`
 && {
  border: 2px dashed ${COLORS.darkGrey3};
  align-items: center;
  justify-content: center;
  cursor: pointer;
 }
`;
export const PersonnelCardName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  font-family: ${TYPOGRAPHY.primaryFont};
  color: #000000;
`;

export const PersonnelCardPosition = styled.div`
  margin-top: 10px;
  font-family: ${TYPOGRAPHY.primaryFont};
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 17px;

  color: rgba(0, 0, 0, 0.87);
`;

export const ControlButtons = styled.div`
  margin-left: 20px;
  font-family: ${TYPOGRAPHY.primaryFont};
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  text-align: center;

  cursor: pointer;

  color: #ff6d1d;
`;

export const CornerDot = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  width: 15px;
  height: 15px;

  border-radius: 100%;
  background: #ffffff;
  border: 1px solid #ffaa7b;
`;

export const AddText = styled.div`
  margin-top: 12px;
  font-family: ${TYPOGRAPHY.primaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;

  text-align: center;

  color: #000000;
`;

export const PlusIcon = styled.img`
  width: 28px;
  height: 28px;
`;
