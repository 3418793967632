import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  ScreenRoot,
  HeaderArea,
  LargeText,
  DetailedFormContainer,
} from "./styles";

import AddNewButtonIcon from "assets/dashboardIcons/addNewButtonIcon";
import { IconButton } from "@material-ui/core";
import { FunctionalButton } from "components/functionalButton/index";
import { filterReference } from "api/reference";
import { LeftContainer } from "./LeftContainer";
import { RightContainer } from "./RightContainer";
import { AddRefPopup } from "./addRefPopup";
import { CommonSuccessPopup } from "components/commonSuccessPopup";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";

function ReferenceModule() {
  const [query, setQuery] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [filteredData, setFilteredData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [internalSearchResults, setInternalSearchResults] =
    useState(filteredData);

  const handleFilter = async (filterQuery) => {
    await filterReference(filterQuery)
      .then((data) => {
        if (data) {
          setFilteredData(data);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    handleFilter(query);
  }, []);

  const handleAdd = () => {
    setOpenPopup(true);
  };

  const handleShare= () => {
    // setOpenPopup(true);
  };

  return (
    <ScreenRoot>
      <DetailedFormContainer isMobile={isMobile}>
        <HeaderArea>
          <LargeText isMobile={isMobile}>Performance Reviews</LargeText>
          {isMobile && (
            <IconButton onClick={handleAdd}>
              <AddNewButtonIcon />
            </IconButton>
          )}
          {!isMobile && (
            <HeaderArea width="50%" justifyContent="flex-end">
              {/* <FunctionalButton
                  buttonTitle="Share Reference"
                  handleButton={handleShare}
                  width="40%"
                  isReversedColor={true}
                /> */}
               <SearchBar
                filterApi={filterReference}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                setInternalSearchResults={setInternalSearchResults}
                query={query}
                setQuery={setQuery}
                searchWidth="60%"
                internalSearch={true}
                searchPlaceHolder="Project Title, Location, Referee Name..."
                marginRight="0px"
              />
            </HeaderArea>
          )}
        </HeaderArea>
      </DetailedFormContainer>
      <DetailedFormContainer isMobile={isMobile}>
        <LeftContainer isMobile={isMobile} />
        <RightContainer
          isMobile={isMobile}
          data={query && query !== "" ? internalSearchResults : filteredData}
          setFilteredData={setFilteredData}
          setOpenPopup={setOpenPopup}
        />
      </DetailedFormContainer>
      <AddRefPopup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        setOpenSuccessPopup={setOpenSuccessPopup}
        isMobile={isMobile}
      />
      <CommonSuccessPopup
        openPopup={openSuccessPopup}
        setOpenPopup={setOpenSuccessPopup}
        isMobile={isMobile}
        boldText="Reference request sent!"
        text="Check Your Email for Verification."
      />
    </ScreenRoot>
  );
}

export default ReferenceModule;
