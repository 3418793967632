import React from 'react';

const MobileProjectsIcon = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.375 5.56302L7.5 0L5.625 5.56302H0L4.58437 9.2704L2.7825 15L7.5 11.4592L12.2175 15L10.4156 9.2704L15 5.56302H9.375V5.56302Z" fill="#888888"/>
    </svg>
  )
};

export default MobileProjectsIcon;


