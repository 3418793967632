import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { COLORS } from 'constants.js';


export const StyledSpinner = styled(CircularProgress)`
  &&&& {
    margin-top: 2px;
    &.MuiCircularProgress-root {
      color: ${props => props.color ? props.color : `${COLORS.primaryOrange}`}
    }
  }
`;
