import React, { useState } from "react";
import classes from "./style/Test.module.css";
import { DASHBOARDHEADER_HEIGHT } from "../../constants";
import { AlertPopup } from "components/alertPopup/alertPopup";
import { FormBuilder } from "../../components/formBuilder";

import formDetails from "./forms/formDetails.json";
import formFieldsOriginal from "./forms/formFields.json";
import formSetup from "./forms/formSetup.json";
import icons from "./style/icons.js";

function Test() {
  const [state, setState] = useState({
    showInteractionPopup: { visible: false, description: null },
  });
  const [formFields, setFormFields] = useState(formFieldsOriginal);
  const [formState, setFormState] = useState({
    formItemStates: {},
  });
  const [supplementaryData, setSupplementaryData] = useState({
    requirements: {
      data: [
        {
          id: "1",
          requirementTitle: "AODA Contractor Compliance Statement Form",
          requirementDescription:
            "Certificate of compliance with the Integrated Accessibility Standards Regulation (Ontario Regulation 191/11) under the Accessibility for Ontarians with Disabilities Act, 2005 (AODA)",
        },
        {
          id: "2",
          requirementTitle: "Background Information",
          requirementDescription:
            "A brief history (maximum 2 pages) of the company structure and ownership from conception, including growth profile, customer base and value proposition. Include whether the company has operated under another name in the past and note any mergers or acquisitions which impact current service provision or resources. Describe the ownership structure of the company and list any parent and/or subsidiary companies",
        },
        {
          id: "3",
          requirementTitle: "Contractor Qualification Statement (CCDC-11)",
          requirementDescription:
            "CCDC 11 – Contractor’s Qualification Statement is a standard format for contractors to provide information about their company, capacity, skill, and experience.",
        },
        {
          id: "4",
          requirementTitle: "COR Safety Program – Evidence of Certification",
          requirementDescription:
            "The Certificate of Recognition (COR™) that verifies full implementation of an employer’s Occupational Health and Safety Management System.",
        },
        {
          id: "5",
          requirementTitle: "Health & Safety Policy and Procedures",
          requirementDescription:
            "The company's standard health and safety policies and procedures",
        },
        {
          id: "6",
          requirementTitle: "Letter of Bondability (Agreement to Bond)",
          requirementDescription:
            "A letter issued by a Surety Issuer confirming to the project owner that should the contractor be awarded the job and execute the contract, the surety will provide the performance and/or payment bonds as per the terms outlined in the consent of surety.",
        },
        {
          id: "7",
          requirementTitle: "Organization Chart",
          requirementDescription:
            "A chart showing overall management structure of the company and that of the relevant project, including key site and office personnel (specific to the project being selected for)",
        },
        {
          id: "8",
          requirementTitle:
            "Quality Assurance / Quality Control (QA/QC) Procedures",
          requirementDescription:
            "The company's standard Quality Assurance / Quality Control (QA/QC) policies and procedures",
        },
        {
          id: "9",
          requirementTitle: "Statement of Insurability",
          requirementDescription:
            "A certificate indicating that the contractor is insured or insurable up to the required limits under various types of insurance required as per the RFPQ",
        },
        {
          id: "10",
          requirementTitle: "Submission Form",
          requirementDescription:
            "A form with details of documents being submitted under the RFPQ",
        },
        {
          id: "11",
          requirementTitle: "Surety's Letter of Pre-Qualification",
          requirementDescription:
            "A letter issued by a Surety Issuer to the owner that confirms the “bondability” of the contractor, acknowledging its business relationship and familiarity with the contractor.",
        },
        {
          id: "12",
          requirementTitle: "WSIB Certificate or Equivalent",
          requirementDescription:
            "Workplace Safety and Insurance Board certificate of coverage",
        },
      ],
    },
    states: {
      data: [
        { value: "AB", label: "Alberta" },
        { value: "BC", label: "British Columbia" },
        { value: "MB", label: "Manitoba" },
        { value: "NB", label: "New Brunswick" },
        { value: "NL", label: "Newfoundland and Labrador" },
        { value: "NS", label: "Nova Scotia" },
        { value: "NT", label: "Northwest Territories" },
        { value: "NU", label: "Nunavut" },
        { value: "ON", label: "Ontario" },
        { value: "PE", label: "Prince Edward Island" },
        { value: "QC", label: "Quebec" },
        { value: "SK", label: "Saskatchewan" },
        { value: "YT", label: "Yukon" },
      ],
    },
  });

  const [data, setData] = useState({
    constructionRecords: {
      2022: 9999,
      2020: 5000,
      2019: 100,
    },
    keyProjectsList: [
      {
        id: 1,
        projectTitle: "Project 1",
        projectNumber: "001",
        projectLocation: "Toronto",
      },
      {
        id: 2,
        projectTitle: "Project 2",
        projectNumber: "002",
        projectLocation: "Mississauga",
      },
      {
        id: 3,
        projectTitle: "Project 3",
        projectNumber: "003",
        projectLocation: "Markham",
      },
    ],
    keyProjectsListTwo: [
      {
        id: 4,
        prequalified: true,
        projectEmail: "project_4_email@mail.com",
        projectTitle: "Project 4",
        projectNumber: "004",
        projectLocation: "Vancouver",
        selected: true,
      },
      {
        id: 5,
        prequalified: false,
        projectEmail: "project_5_email@mail.com",
        projectTitle: "Project 5",
        projectNumber: "005",
        projectLocation: "Burnaby",
        selected: false,
      },
      {
        id: 6,
        prequalified: true,
        projectEmail: "project_6_email@mail.com",
        projectTitle: "Project 6",
        projectNumber: "006",
        projectLocation: "Richmond",
        selected: false,
      },
    ],
    safetyRecords: {
      2021: {
        baseRate: 24245,
        classCode: "232",
        expRate: 23,
        fatalities: 23,
        lostRate: 2,
      },
      2020: {
        baseRate: 24245,
        classCode: "232",
        expRate: 23,
        fatalities: 23,
        lostRate: 2,
      },
      2019: {
        baseRate: 24245,
        classCode: "232",
        expRate: 23,
        fatalities: 23,
        lostRate: 2,
      },
    },
  });

  function setRequirementsFormLoading(e) {
    setFormState({
      ...formState,
      formItemStates: {
        ...(formState.formItemStates || {}),
        inviteContractors: {
          ...(formState.formItemStates?.inviteContractors || {}),
          popupForm: {
            ...(formState.formItemStates?.inviteContractors?.popupForm || {}),
            loading: true,
          },
        },
      },
    });
  }

  function handleChangeData({ action, payload }) {
    switch (action) {
      case "CHANGE_DROPDOWN_VALUE":
        setData({ ...data, [payload.id]: { selectedData: payload.value } });
        break;
      case "CHANGE_LIST_SELECT_VALUE":
        var newList = data[payload.id];
        newList[payload.index][payload.selectedId] = payload.value;
        setData({
          ...data,
          [payload.id]: newList,
        });
        break;
      case "CHANGE_VALUE":
        const mutuallyExclusiveRadios = [
          "radioOneOfOne",
          "radioOneOfTwo",
          "radioOneOfThree",
        ];
        const mutuallyExclusiveRadiosTwo = [
          "radioOneOfFour",
          "radioOneOfFive",
          "radioOneOfSix",
        ];
        if (mutuallyExclusiveRadios.includes(payload.id)) {
          var newRadios = {};
          mutuallyExclusiveRadios.map(
            (radio) => (newRadios[radio] = radio === payload.id ? true : false)
          );
          setData({ ...data, ...newRadios });
        } else if (mutuallyExclusiveRadiosTwo.includes(payload.id)) {
          var newRadios = {};
          mutuallyExclusiveRadiosTwo.map(
            (radio) => (newRadios[radio] = radio === payload.id ? true : false)
          );
          setData({ ...data, ...newRadios });
        } else if (
          payload.id === "conditionalFieldFour" &&
          payload.value === false
        ) {
          setData({ ...data, conditionalFieldThree: "" });
        } else {
          setData({ ...data, [payload.id]: payload.value });
        }
        break;
      case "TABLE_CHANGE_VALUE":
        if (payload && payload.id) {
          setData({
            ...data,
            [payload.id]: data[payload.id]
              ? { ...data[payload.id], data: payload.value }
              : { data: payload.value },
          });
        }
        break;
      case "TABLE_SELECTED_CHANGE_VALUE":
        if (payload && payload.id) {
          setData({
            ...data,
            [payload.id]: data[payload.id]
              ? { ...data[payload.id], selectedData: payload.value }
              : { selectedData: payload.value },
          });
        }
        break;
      case "YEAR_CHANGE_VALUE":
        setData({
          ...data,
          [payload.id]: data[payload.id]
            ? {
                ...data[payload.id],
                [Object.keys(payload.value)[0]]:
                  payload.value[Object.keys(payload.value)[0]],
              }
            : {
                [Object.keys(payload.value)[0]]:
                  payload.value[Object.keys(payload.value)[0]],
              },
        });
        break;
    }
  }

  function handleConditionalStateChanged(e) {
    if (e.conditionalFieldFour === true) {
      setData({
        ...data,
        conditionalFieldFour: true,
      });
    }
  }

  function handleItemClicked(e) {
    if (["keyProjectsList", "keyProjectsListTwo"].includes(e.id)) {
      setState({
        ...state,
        showInteractionPopup: {
          ...state.showInteractionPopup,
          visible: true,
          description: `Handle the interaction when clicking the icon for project ID=${e.rowId}`,
        },
      });
    } else if (e.id) {
      switch (e.id) {
        case "testButtonLeft":
          setFormFields({
            ...formFields,
            testButtonLeft: {
              ...formFields.testButtonLeft,
              disabled: true,
            },
            testButtonRight: {
              ...formFields.testButtonRight,
              disabled: false,
            },
          });
          break;
        case "testButtonRight":
          setFormFields({
            ...formFields,
            testButtonLeft: {
              ...formFields.testButtonLeft,
              disabled: false,
            },
            testButtonRight: {
              ...formFields.testButtonRight,
              disabled: true,
            },
          });
          break;
      }
    }
  }

  function handleSubmit(formData) {
    // console.log(formData)
  }

  // Get rows/columns
  // If you know them beforehand, set them in formFields. If you don't know them, set them in the calling component. In this case, the columns are set in formFields, while the rows are set below:
  var safetyRecordRows = [];
  Object.keys(data.safetyRecords).map((safetyRecordRow) =>
    safetyRecordRows.push({ id: safetyRecordRow, title: safetyRecordRow })
  );
  safetyRecordRows.reverse();

  return (
    <div
      className={classes.container}
      style={{ padding: `${DASHBOARDHEADER_HEIGHT}px 70px 0 70px` }}
    >
      <AlertPopup
        openPopup={state.showInteractionPopup.visible}
        setOpenPopup={(e) =>
          setState({
            ...state,
            showInteractionPopup: {
              ...AlertPopup.showInteractionPopup,
              visible: e,
            },
          })
        }
        headerText={"Interaction Item Clicked."}
        subHeaderText={state.showInteractionPopup.description}
      />
      <div className={classes["form-container"]}>
        <FormBuilder
          //alert={{ visible: false, headerText: 'Sample Alert', subHeaderText: 'This is a test alert' }}
          changeData={(e) => handleChangeData(e)}
          changeItemState={formState.formItemStates}
          data={data}
          formDetails={formDetails}
          formFields={{
            ...formFields,
            safetyRecords: {
              ...formFields.safetyRecords,
              rows: safetyRecordRows,
            },
          }}
          formSetup={formSetup}
          icons={icons}
          legacyNavigationUi={true}
          //navigationType='TABS'
          onConditionalStateChanged={(e) => handleConditionalStateChanged(e)}
          onInteractionItemClicked={(e) => handleItemClicked(e)}
          //onNavigate={ (e) => console.log(e) }
          onSubmit={(formData) => handleSubmit(formData)}
          onSubmitItem={(e) => setRequirementsFormLoading(e)}
          //readOnly={ true }
          supplementaryData={supplementaryData}
        />
      </div>
    </div>
  );
}

export default Test;
