import React from 'react';
import { Image } from '@react-pdf/renderer';

const ClickableImage = ({ src, href }) => {
  return (
    <>
      <a href={href} target="_blank" rel="noopener noreferrer">
        <Image src={src} alt="Yes" style={{ position: 'absolute', height: 12, width: 12 }} />
      </a>
    </>
  );
};

export default ClickableImage;



