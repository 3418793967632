import React, { useState, useEffect, useRef } from 'react';

import {
  FormContainer,
  StyledLargeTitle,
  CloseButton,
  Row,
  FormTitle,
} from "../styles";

import { InputLabel } from './styles';

import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import CloseIcon from "@material-ui/icons/Close";
import Input from "../../../components/inputField";
import logoModal from "../../../assets/preQualifyIcons/logo_small.svg";
import { LooksGoodButton } from "../components/looksGoodButton";

import SelectedPersonnels from './components/selectedPersonnels';
import { SelectingPersonnels } from './components/selectingPersonnels';

import { FunctionalButton } from '../../../components/functionalButton';


export const KeyOfficePersonnelForm = ({
  qualificationData,
  setQualificationData,
  setIsLastPanel,
  setPreviousStepPanel,
}) => {
  setIsLastPanel(true);
  setPreviousStepPanel(1);

  const [openDialog, setOpenDialog] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const [dialogFN, setDialogFN] = useState("");
  const [dialogLN, setDialogLN] = useState("");
  const [dialogPos, setDialogPos] = useState("");
  const [dialogRes, setDialogRes] = useState(null);
  const [validSubmit, setValidSubmit] = useState(true);

  const fileInput = useRef();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (qualificationData?.key_office?.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  });

  useEffect(() => {
    if (dialogFN.length > 0 && dialogLN.length > 0 && dialogPos.length > 0) {
      setValidSubmit(false);
    } else {
      setValidSubmit(true);
    }
  }, [dialogFN, dialogLN, dialogPos]);

  const handleRemovePersonnel = (qualificationData, firstName, lastName, position) => {
    const updatedQualificationData = qualificationData.filter(
      (item) =>
        !(
          item.firstName === firstName &&
          item.lastName === lastName &&
          item.position === position
        )
    );

    setQualificationData('key_office', updatedQualificationData);
  };

  const handleAddPersonnel = () => {
    let updatedPersonnelData = [];

    if (qualificationData?.key_personnel) {
       updatedPersonnelData = [...qualificationData?.key_personnel];
    }

    updatedPersonnelData.unshift({
      firstName: dialogFN,
      lastName: dialogLN,
      position: dialogPos,
      resume: dialogRes,
      type: ['key_site']
    });

    setQualificationData('key_personnel', updatedPersonnelData);
  };

  const handleEditPersonnel = () => {
    let updatedPersonnelData = [];

    if (qualificationData?.key_office) {
       updatedPersonnelData = [...qualificationData.key_office];
    }

    updatedPersonnelData[editIndex] = {
      id: updatedPersonnelData[editIndex].id,
      firstName: dialogFN,
      lastName: dialogLN,
      position: dialogPos,
      resume: dialogRes,
    };

    setQualificationData('key_personnel', updatedPersonnelData);
  }

  const handleFileSelect = (e) => {
    fileInput.current.click();
  };

  const handleOnChangeFile = () => {
    const file = document.getElementById('resume-selector');
    setDialogRes(file.files[0]);
  };

  return (
    <FormContainer>
      <StyledLargeTitle>Personnel Selection</StyledLargeTitle>
      <SelectedPersonnels
        qualificationData={qualificationData}
        setQualificationData={setQualificationData}
      />
      <SelectingPersonnels
        qualificationData={qualificationData}
        setQualificationData={setQualificationData}
        add={() => setOpenDialog(true)}
      />
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setDialogFN("");
          setDialogLN("");
          setDialogPos("");
          setEditIndex(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <Paper
          elevation={0}
          style={{
            padding: 25,
            width: 400,
            alignSelf: "center",
            overflow: "hidden",
            position: "relative",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CloseButton
            aria-label="close"
            onClick={() => {
              setOpenDialog(false);
              setDialogFN("");
              setDialogLN("");
              setDialogPos("");
              setDialogRes(null);
              setEditIndex(null);
            }}
          >
            <CloseIcon />
          </CloseButton>
          <img src={logoModal} style={{ width: 65, height: 65 }}></img>
          <FormTitle style={{ marginTop: 10 }}>Add Your Personnel</FormTitle>
          <Row style={{ margin: "0px -10px" }}>
            <Input
              style={{ margin: "0px 10px" }}
              label="First Name*"
              type="text"
              placeholder="First Name"
              value={dialogFN}
              setValue={setDialogFN}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noErrorBorder={true}
            />
            <Input
              style={{ margin: "0px 10px" }}
              label="Last Name*"
              type="text"
              placeholder="Last Name"
              value={dialogLN}
              setValue={setDialogLN}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noErrorBorder={true}
            />
          </Row>
          <Input
            label="Position*"
            type="text"
            placeholder="Position"
            value={dialogPos}
            setValue={setDialogPos}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noErrorBorder={true}
          />
          <InputLabel>Resume</InputLabel>
          <FunctionalButton
            buttonTitle={'Resume'}
            id="resume"
            handleButton={(e) => handleFileSelect(e)}
            width="368px"
            isReversedColor={true}
            marginRight="0px"
          />
          <input
            ref={fileInput}
            id="resume-selector"
            onChange={handleOnChangeFile}
            type="file"
            style={{ display: 'none' }}
          />
          <LooksGoodButton
            disabled={validSubmit}
            onClick={() => {
              setOpenDialog(false);
              if (editIndex !== null) {
                handleEditPersonnel();
                setEditIndex(null);
              } else {
                handleAddPersonnel();
              };
              setDialogFN("");
              setDialogLN("");
              setDialogPos("");
              setDialogRes(null);
            }}
          >
            SUBMIT
          </LooksGoodButton>
        </Paper>
      </Dialog>
    </FormContainer>
  );
};
