import get from "lodash/get";
import { createSelector } from "reselect";

export const baseUserSelector = (state) => get(state, "contact", {});

export const contactSelector = createSelector(baseUserSelector, (contact) =>
  get(contact, "contactInfo", {})
);

export const viewSelector = createSelector(baseUserSelector, (contact) =>
  get(contact, "view", {})
);



