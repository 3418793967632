import { useState, useEffect, useRef } from 'react';
import calculatePosition from './calculatePosition';

export function useInteractionStateBuilder({ currentLocation, formFields, formSetup, userData }) {
  const initialState = {
    currentPage: null,
    fields: {},
    navigation: {},
    pages: {},
    requiredFields: {
      ids: [],
      titles: []
    },
    requiredPages: {
      ids: [],
      locations: []
    }
  };
  const [state, setState] = useState(initialState);
  const stateRef = useRef(initialState);
  stateRef.current = state;

  useEffect(() => {
    setCurrentPosition();
  }, [currentLocation, formSetup, userData]);

  function checkPageRequirements({ currentPosition, checkPages=false }) {
    /*
    var requiredFields = {
      ids: new Set(stateRef.current.requiredFields.ids),
      titles: new Set(stateRef.current.requiredFields.titles)
    };
    var requiredPages = {
      ids: new Set(stateRef.current.requiredPages.ids),
      locations: new Set(stateRef.current.requiredPages.locations)
    };
    */
    var requiredFields = {
      ids: new Set(),
      titles: new Set()
    };
    var requiredPages = {
      ids: new Set(),
      locations: new Set()
    };
    var requirementsMet = true;
    if (currentPosition.pageFields && (currentPosition.currentPage?.itemType === 'page')) {
      Object.keys(formFields).map(formField => {
        if (formFields[formField]?.required && (!userData[formField] && (userData[formField] !== false) && (userData[formField] !== 0))) {
          requiredFields.ids.add(formField);
          requiredFields.titles.add(formFields?.[formField]?.label);
          if (stateRef.current.fields[formField]?.location && (formSetup?.[stateRef.current.fields[formField].location?.[0]]?.itemType === 'page')) {
            requiredPages.ids.add(formSetup?.[stateRef.current.fields[formField].location?.[0]]?.id);
            requiredPages.locations.add(stateRef.current.fields[formField].location?.[0]);
          };
        };
      });
      if (currentPosition.pageFields && !currentPosition.currentPage.navigation.skipRequired) {
        currentPosition.pageFields.map(pageField => {
          if (formFields?.[pageField]?.required && (!userData[pageField] && (userData[pageField] !== false) && (userData[pageField] !== 0))) {
            requirementsMet = false;
          };
        });
      };
    };
    requiredFields = {
      ids: [...requiredFields.ids],
      titles: [...requiredFields.titles]
    };
    requiredPages = {
      ids: [...requiredPages.ids],
      locations: [...requiredPages.locations]
    };
    return { requiredFields, requiredPages, requirementsMet };
  };

  function setCurrentPosition() {
    var currentPosition = calculatePosition({ buildFieldHash: true, currentLocation, formSetup });
    var requirements;
    var requirementsMet = true;
    if (currentPosition?.currentPage?.navigation && currentPosition.currentPage.itemType === 'page') {
      requirements = checkPageRequirements({ currentPosition, checkPages: currentPosition.currentPage.navigation.submit ? true : false });
      requirementsMet = requirements.requirementsMet;
    };
    setState({
      ...stateRef.current,
      currentPage: currentPosition.currentPage,
      fields: {
        ...stateRef.current.fields,
        ...currentPosition?.pageHash
      },
      navigation: currentPosition?.currentPage?.navigation ? {
        ...currentPosition.currentPage.navigation,
        current: currentPosition.currentPage.navigation.current,
        edit: { ...currentPosition.currentPage.navigation.edit, action: 'EDIT', disabled: currentPosition.currentPage.navigation.edit?.disabled || false, display: (currentPosition.currentPage.itemType === 'reviewPage') ? true : false },
        navigationMessage: currentPosition.currentPage.navigation.navigationMessage,
        next: { ...currentPosition.currentPage.navigation.next, action: 'CHANGE_LOCATION', disabled: currentPosition.currentPage.navigation.next?.disabled || !requirementsMet, display: currentPosition.currentPage.navigation.next?.location ? true : false },
        previous: { ...currentPosition.currentPage.navigation.previous, action: 'CHANGE_LOCATION', disabled: currentPosition.currentPage.navigation.previous?.disabled || false, display: currentPosition.currentPage.navigation.previous?.location ? true : false },
        requirementsMet,
        setup: currentPosition.currentPage.navigation.setup,
        submit: { ...currentPosition.currentPage.navigation.submit, disabled: currentPosition.currentPage.navigation.submit?.disabled || !requirementsMet, action: 'SUBMIT', display: currentPosition.currentPage.navigation.submit ? true : false }
      } : {},
      pages: {
        ...stateRef.current.pages,
        [currentPosition?.currentPage?.navigation?.current?.location || currentLocation]: {
          fields: currentPosition?.pageFields,
          requirementsMet: requirementsMet
        }
      },
      requiredFields: requirements?.requiredFields || stateRef.current.requiredFields,
      requiredPages: requirements?.requiredPages || stateRef.current.requiredPages
    });
  };

  // To get int page locations from pages obj keys:
  //console.log('0,1'.split(',').map(Number))

  return stateRef.current;
};
