import React from 'react';

const DeleteIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.2675 0.732514C15.6239 0.0891621 14.5808 0.0891621 13.9372 0.732514L8.51032 6.1594L3.08344 0.732514C2.4368 0.107965 1.40892 0.116897 0.773233 0.752589C0.137541 1.38828 0.128609 2.41615 0.753158 3.06279L6.18004 8.48968L0.753158 13.9166C0.324748 14.3303 0.152933 14.9431 0.303751 15.5193C0.454569 16.0955 0.904549 16.5454 1.48074 16.6962C2.05693 16.8471 2.66967 16.6753 3.08344 16.2468L8.51032 10.82L13.9372 16.2468C14.5838 16.8714 15.6117 16.8625 16.2474 16.2268C16.8831 15.5911 16.892 14.5632 16.2675 13.9166L10.8406 8.48968L16.2675 3.06279C16.9108 2.41925 16.9108 1.37606 16.2675 0.732514Z" fill="#FF6D1D"/>
    </svg>
  )
};

export default DeleteIcon;


