import React from 'react';
import efficent from './efficient.svg';
import { StyledImg } from './styles';

const EfficientIcon = ({ width, height, noGreyFilter }) => {
  return (
    <StyledImg width={width} heigh={height} src={efficent} noGreyFilter={noGreyFilter} />
  )
};

export default EfficientIcon;
