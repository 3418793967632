import { FunctionalButton } from "components/functionalButton";
import React, { useState, useEffect } from "react";

import { SelectedProjects } from "./components/selectedProjects";

export const EmptyPanel = ({
  object,
  setOpenPopup,
}) => {

  return (
    <>
      <FunctionalButton
        buttonTitle={"Add New"}
        width="200px"
        isReversedColor={true}
        marginRight="0px"
        marginLeft="0px"
        marginTop="48px"
        marginBottom="48px"
        handleButton={() => setOpenPopup(true)}
        disabled={object}
      />
    </>

  )
}