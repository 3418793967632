import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { FunctionalButton } from "../../../components/functionalButton";
import {
  StyleList,
  StyledQuestion,
  InfoArea,
  StyledSkipText
 } from './styles';

 import { Categories } from './Categories';
 import { AddressConfirmation, AddressFields } from './Address';

export const Company = ({
  handleNext,
  onboardingData,
  setOnboardingData,
  panel,
  setPanel,
  setIsLastPanel,
  setPreviousStepPanel,
  isBranchPanel,
  setIsBranchPanel,
  handleExit
}) => {
  const hasData = (onboardingData?.company?.address &&
                    onboardingData?.company?.postal &&
                    onboardingData?.company?.city &&
                    onboardingData?.company?.province)
                   ? true : false;

  return (
    <>
    {panel == 1 && (
      <Categories 
        setPanel={setPanel}
        onboardingData={onboardingData}
        setOnboardingData={setOnboardingData}
        hasData={hasData}
        setIsLastPanel={setIsLastPanel}
        setPreviousStepPanel={setPreviousStepPanel}
        setIsBranchPanel={setIsBranchPanel}
      />
    )}
    {panel == 2 && (
      <AddressConfirmation 
        setPanel={setPanel}
        handleNext={handleNext}
        onboardingData={onboardingData}
        setOnboardingData={setOnboardingData}
        hasData={hasData}
        setIsLastPanel={setIsLastPanel}
        handleExit={handleExit}
      />
    )}
    {panel == 3 && (
      <AddressFields
        setPanel={setPanel}
        handleNext={handleNext}
        onboardingData={onboardingData}
        setOnboardingData={setOnboardingData}
        setIsLastPanel={setIsLastPanel}
        setIsBranchPanel={setIsBranchPanel}
        handleExit={handleExit}
      />
    )}
    </>
  );
}

