import React from "react";
import { useHistory } from "react-router-dom";
import classes from "./style/ReleaseNotes.module.css";
import { COLORS, DASHBOARDHEADER_HEIGHT, FONT_SIZES } from "../../constants";
import { ReleaseNotesBuilder } from "../../components/releaseNotesBuilder";
import { FunctionalButton } from "../../components/functionalButton";
import release_notes_public from "../../assets/system_messages/release_notes_public.json";

function ReleaseNotes() {
  const history = useHistory();
  return (
    <div
      className={classes.container}
      style={{ paddingTop: `${DASHBOARDHEADER_HEIGHT}px` }}
    >
      <div
        className={classes["page-title-container"]}
        style={{ color: "white", backgroundColor: COLORS.primaryOrange }}
      >
        <div
          className={classes["page-title-text"]}
          style={{ fontSize: FONT_SIZES.heading_5 }}
        >
          PledgXQualify - Release Notes
        </div>
        <FunctionalButton
          buttonTitle="Back to dashboard"
          handleButton={() => history.push("/")}
          width="200px"
          height="50px"
          isReversedColor={true}
        />
      </div>
      <div className={classes["release-notes-inner-container"]}>
        <ReleaseNotesBuilder releaseNotes={release_notes_public} />
      </div>
    </div>
  );
}

export default ReleaseNotes;
