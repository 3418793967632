
import React from 'react';


const TinyThreeDots = () => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.99647 11.1178C6.61745 11.1178 7.12086 11.6406 7.12086 12.2855C7.12086 12.9304 6.61745 13.4531 5.99647 13.4531C5.37548 13.4531 4.87207 12.9304 4.87207 12.2855C4.87207 11.6406 5.37548 11.1178 5.99647 11.1178ZM5.99647 6.05839C6.61745 6.05839 7.12086 6.58116 7.12086 7.22603C7.12086 7.8709 6.61745 8.39367 5.99647 8.39367C5.37548 8.39367 4.87207 7.8709 4.87207 7.22603C4.87207 6.58116 5.37548 6.05839 5.99647 6.05839ZM7.12086 2.16542C7.12086 1.52055 6.61745 0.997779 5.99647 0.997779C5.37548 0.997779 4.87207 1.52055 4.87207 2.16542C4.87207 2.81029 5.37548 3.33306 5.99647 3.33306C6.61745 3.33306 7.12086 2.81029 7.12086 2.16542Z" fill="#D0C9D6"/>
    </svg>
  )
};

export default TinyThreeDots;
