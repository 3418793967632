import get from "lodash/get";

const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  const payload = get(action, "payload", []);
  switch (action.type) {
    case "GET_BUYERS_LIST":
      return {
        ...state,
        service_provider_buyers_list: payload,
      };
    case "GET_AGENT_CONTRACTORS_LIST":
      return {
        ...state,
        service_provider_contractors_list: payload,
      };
    case "GET_BUYER_RFPQS_LIST":
      return {
        ...state,
        buyer_rfpqs_list: payload,
      };
    case "CLEAR_CONTRACTOR_CCDC_LIST":
      return {
        ...state,
        contractor_ccdcs_list: null,
      };
    case "GET_INSTITUTION_PROFILE_INFO":
      return {
        ...state,
        institution_profile_info: payload,
      };
    case "GET_CONTRACTOR_CCDCS_LIST":
      return {
        ...state,
        contractor_ccdcs_list: payload,
      };
    case "GET_AGENT_PPC_LIST":
      return {
        ...state,
        service_provider_ppc_list: payload,
      };
    case "GET_AGENT_CO_LIST":
      return {
        ...state,
        service_provider_co_list: payload,
      };
    case "GET_AGENT_CQS_LIST":
      return {
        ...state,
        service_provider_cqs_list: payload,
      };
    case "GET_CONTRACTOR_ALERTS":
      return {
        ...state,
        contractor_alerts: payload,
      };
    default:
      return state;
  }
};
