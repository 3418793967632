import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Input from '../../../components/inputField';
import { FunctionalButton } from "../../../components/functionalButton";
import { getCountryName } from "common/countryName";
import {
  StyledQuestion,
  InfoArea,
  StyledSkipText,
  TextContainer,
  LargeText,
  StyledInput,
  StyledInputLabel,
  StyledAddressContainer,
  StyledInputLabelContainer,
  StyledRow,
  ButtonAtBottom,
  SubRow
} from './styles';


export const AddressConfirmation = ({
  setPanel,
  handleNext,
  onboardingData,
  setOnboardingData,
  hasData,
  setIsLastPanel,
  handleExit
}) => {
  setIsLastPanel(true);
  const isMobile = useMediaQuery({ maxWidth: 480 });
  let fullAdress = "";

  if (hasData) {
    fullAdress = `${onboardingData?.company?.address || ""}${(onboardingData?.company?.address) ? ", " : ""}\
                ${onboardingData?.company?.suite ? `suite ${onboardingData?.company?.suite}` : ""}${(onboardingData?.company?.suite && onboardingData?.company?.postal) ? ", " : ""}\
                ${onboardingData?.company?.postal || ""}${(onboardingData?.company?.postal && onboardingData?.company?.city) ? ", " : ""}\
                ${onboardingData?.company?.city || ""}${(onboardingData?.company?.city && onboardingData?.company?.province) ? ", " : ""}\
                ${onboardingData?.company?.province || ""}`
  }

  const handleChoice = (confirmed) => {
    if (confirmed) {
      setOnboardingData('company.addressConfirmed', true);
      setIsLastPanel(true);
    } else {
      setOnboardingData('company.addressConfirmed', false);
      setIsLastPanel(false);
    }
  }

  return (
    <>
      <StyledQuestion isMobile={isMobile} width="650px">Is this your company address?</StyledQuestion>
      <TextContainer isMobile={isMobile} width="700px">{fullAdress}</TextContainer>
      <InfoArea isMobile={isMobile} width="650px" isColumn={true}>
        <FunctionalButton
          buttonTitle="Yes"
          width={isMobile ? "90%" : "420px"}
          height="48px"
          marginRight={isMobile ? "0px" : "16px"}
          marginTop={isMobile ? "16px" : "48px"}
          isReversedColor={(typeof onboardingData?.company?.addressConfirmed == "boolean") ? !onboardingData?.company?.addressConfirmed : true}
          handleButton={() => handleChoice(true)}
        />
        <FunctionalButton
          buttonTitle="No"
          width={isMobile ? "90%" : "420px"}
          height="48px"
          marginRight={isMobile ? "0px" : "16px"}
          marginTop="24px"
          isReversedColor={(typeof onboardingData?.company?.addressConfirmed == "boolean") ? onboardingData?.company?.addressConfirmed : true}
          handleButton={() => handleChoice(false)}
        />
      </InfoArea>
      {/* <ButtonAtBottom isMobile={isMobile}> */}
      <FunctionalButton
        buttonTitle={(typeof onboardingData?.company?.addressConfirmed == "boolean" && onboardingData?.company?.addressConfirmed) ?
          "Done" : "Next"}
        handleButton={(typeof onboardingData?.company?.addressConfirmed == "boolean" && onboardingData?.company?.addressConfirmed) ?
          () => handleExit() : () => setPanel(3)}
        width={isMobile ? "80%" : "200px"}
        marginRight="16px"
        marginTop={isMobile ? "72px" : "48px"}
        disabled={!(typeof onboardingData?.company?.addressConfirmed == "boolean")}
      />
      {/* </ButtonAtBottom> */}
    </>
  );
}

export const AddressFields = ({
  setPanel,
  handleNext,
  onboardingData,
  setOnboardingData,
  setIsLastPanel,
  setIsBranchPanel,
  handleExit
}) => {

  let autocomplete;
  let address1Field;
  let address2Field;
  let postalField;
  let cityField;
  let provinceField;
  let countryField;

  setIsLastPanel(true);

  function initAutocomplete() {
    address1Field = document.querySelector("#ship-address");
    address2Field = document.querySelector("#address2");
    postalField = document.querySelector("#postcode");
    cityField = document.querySelector("#locality");
    provinceField = document.querySelector("#state");
    countryField = document.querySelector("#country");
    const country = getCountryName(onboardingData?.country) == "Canada" ? "ca" : "us";
    autocomplete = new window.google.maps.places.Autocomplete(address1Field, {
      componentRestrictions: { country: [`${country}`] },
      fields: ["address_components", "geometry"],
      types: ["address"],
    });
    // address1Field.focus();
    autocomplete.addListener("place_changed", fillInAddress);
  }

  function fillInAddress() {
    const place = autocomplete.getPlace();
    let address1 = "";
    let postcode = "";
    let city = "";
    let province = "";
    let country = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case "route": {
          address1 += component.short_name;
          break;
        }

        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }
        case "locality":
          city = component.long_name;
          break;
        case "administrative_area_level_1": {
          province = component.short_name;
          break;
        }
        case "country": {
          country = component.short_name;
          break;
        }
      }
    }

    address1Field.value = address1;
    setOnboardingData('company.address', address1Field.value);
    postalField.value = postcode;
    setOnboardingData('company.postal', postalField.value);
    cityField.value = city;
    setOnboardingData('company.city', cityField.value);
    provinceField.value = province;
    setOnboardingData('company.province', provinceField.value);
    countryField.value = country;
    setOnboardingData('company.country', countryField.value);

    address2Field.focus();
  }

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [disabled, setDisabled] = useState('');
  const postalCode = onboardingData?.company?.postal;
  const canadianPostalCodePattern = /^[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d$/;
  const usaZipCodePattern = /^\d{5}(?:[-\s]\d{4})?$/;


  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyCwLidsAgErglXnD9dT6qnaQF7u_DYU3Pc&libraries=places`,
      () => initAutocomplete()
    );
  })

  useEffect(() => {
    if (onboardingData?.company?.address?.length > 0 &&
      onboardingData?.company?.postal?.length > 0 &&
      onboardingData?.company?.city?.length > 0 &&
      onboardingData?.company?.province?.length > 0 &&
      onboardingData?.company?.country?.length > 0
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  })

  useEffect(() => {
    if (disabled) {
      setOnboardingData('company.addressConfirmed', false);
    } else {
      setOnboardingData('company.addressConfirmed', true);
    }
  }, [disabled])

  return (
    <>
      <StyledQuestion isMobile={isMobile} width="650px">What is your company address?</StyledQuestion>
      <InfoArea isMobile={isMobile} width="650px" isColumn={true}>
        <StyledRow isMobile={isMobile}>
          <Input
            id="inputWrap"
            inputId="ship-address"
            required
            autocomplete="off"
            label="Company Location"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={onboardingData?.company?.address}
            setValue={(val) => { setOnboardingData('company.address', val) }}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noCancelIcon={true}
            noErrorBorder={true}
          />
          <SubRow className="inputWrap">
            <Input
              id="oneFifthMobile"
              inputId="address2"
              required
              autocomplete="off"
              label="Suite"
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={onboardingData?.company?.suite}
              setValue={(val) => { setOnboardingData('company.suite', val) }}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noCancelIcon={true}
              noErrorBorder={true}
            />
            <Input
              id="thirtyMobile"
              inputId="postcode"
              required
              autocomplete="off"
              // label="Postal/Zip Code"
              label={canadianPostalCodePattern.test(postalCode) ? "Postal Code" : usaZipCodePattern.test(postalCode) ? "Zip Code" : "Postal/Zip Code"}
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={onboardingData?.company?.postal}
              setValue={(val) => { setOnboardingData('company.postal', val) }}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noCancelIcon={true}
              noErrorBorder={true}
            />
          </SubRow>
        </StyledRow>
        <StyledRow isMobile={isMobile} >
          <Input
            id="inputWrap"
            inputId="locality"
            required
            autocomplete="off"
            label="City"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={onboardingData?.company?.city}
            setValue={(val) => { setOnboardingData('company.city', val) }}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noCancelIcon={true}
            noErrorBorder={true}
          />
          <SubRow className="inputWrap">
            <Input
              id="oneFifthMobile"
              inputId="state"
              required
              autocomplete="off"
              // label="State/Province"
              label={getCountryName(onboardingData?.company?.country) === "Canada" ? "Province" : getCountryName(onboardingData?.company?.country) === "United States" ? "State" : "State/Province"}
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={onboardingData?.company?.province}
              setValue={(val) => { setOnboardingData('company.province', val) }}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noCancelIcon={true}
              noErrorBorder={true}
            />

            <Input
              id="thirtyMobile"
              inputId="country"
              required
              autocomplete="off"
              label="Country"
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              paddingRight="0px"
              value={getCountryName(onboardingData?.company?.country)}
              setValue={(val) => { setOnboardingData('company.country', val) }}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noCancelIcon={true}
              noErrorBorder={true}
            />
          </SubRow>

        </StyledRow>
      </InfoArea>
      <FunctionalButton
        buttonTitle="Done"
        handleButton={() => {
          setIsBranchPanel(false);
          handleExit();
        }}
        width={isMobile ? "90%" : "200px"}
        marginRight="16px"
        marginTop={"48px"}
        disabled={disabled}
      />
      {/* </ButtonAtBottom> */}
    </>
  )
}
