import React, { useState, useEffect } from "react";

import { StepperProgress } from '../../components/stepperProgress';
import { BackButton } from "../../components/backButton";
import { StyledStepper } from './styles';
import { FunctionalButton } from '../../components/functionalButton';
import { StepContainer } from './styles';
import { ExitPopup } from "./components/ExitPopup";
import { BackIconButton } from "../../components/backIcon";
import { ExitIconButton } from "../../components/exitIcon";
import { CongratsPopup } from "./components/CongratsPopup";


export const Stepper = ({
  step,
  setStep,
  iconList,
  stepLabels,
  list,
  setList,
  labels,
  setLabels,
  panel,
  setPanel,
  isBranchPanel,
  setIsBranchPanel,  
  previousStepPanel,
  onboardingData,
  setOnboardingData,
  handleExit,
  openPopup,
  setOpenPopup,
  isMobile,
  openCongratsPopup,
  setOpenCongratsPopup,
  progress
 }) => {

  const handleBackStep = () => {
    if(panel === 1){
      if (step > 0) {
        if(isMobile){
          const tempList = [iconList[step - 1]];
          setList(tempList)
          const tempLabels = [`${stepLabels[step -1]}`];
          setLabels(tempLabels);
        } else {
          const tempList = iconList.splice(0, step );
          setList(tempList)
          const tempLabels = stepLabels.splice(0, step );
          setLabels(tempLabels);
        }
        
        setOnboardingData('step', step - 1)
        setStep(step - 1);
        setPanel(previousStepPanel);
      }
    } else {
      if(isBranchPanel){
        setPanel(panel - 2);
        setIsBranchPanel(false)
      } else {
        setPanel(panel - 1);
      }
      
    }
  }

  const handleClickIcon = (index) => {
    if(!isMobile) {
      const tempList = iconList.splice(0, index+1);
      setList(tempList)
      const tempLabels = stepLabels.splice(0, index+1);
      setLabels(tempLabels);
    }
    setIsBranchPanel(false);
    setOnboardingData('step', index);
  }


  return(
    <StyledStepper>
      {(isMobile && step > 0) && (
        <BackIconButton handleClick={handleBackStep}/>
      )}
      {(!isMobile && (step > 0)) && (
        <div style={{width:"149px"}}>
          <BackButton margin="0px 0px 0px 0px" handleClick={handleBackStep} />
        </div>
      )}
      
      <StepContainer>
        <StepperProgress
          activeStep={step}
          setActiveStep={setStep}
          iconList={list}
          stepLabels={labels}
          connectorWidth="35px"
          noConnectorColor={true}
          handleClickIcon={handleClickIcon}
          isMobile={isMobile}
        />
      </StepContainer>
      {isMobile && (
        <ExitIconButton handleClick={handleExit}/>
      )}

      {!isMobile && (
        <FunctionalButton
          buttonTitle={"SAVE & EXIT"}
          width="148px"
          height="38px"
          marginLeft="0px"
          marginRight="0px"
          fontSize="12px"
          handleButton={handleExit}
        />
      )}
      
      <ExitPopup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        onboardingData={onboardingData}
        isMobile={isMobile}
        progress={progress}
      />

      <CongratsPopup 
        openPopup={openCongratsPopup}
        setOpenPopup={setOpenCongratsPopup}
        isMobile={isMobile}
      />
  </StyledStepper>

  )
 }
