import styled from "styled-components";
import Checkbox from '@material-ui/core/Checkbox';
import { COLORS } from "../../constants";

export const StyledCheckBox = styled(Checkbox)`
  && {
    &&.MuiCheckbox-root {
      color: ${(props) => props.readOnly ? COLORS.textGrey : COLORS.primaryOrange };
    }

    &&.custom-checkbox-root .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }

    &&.MuiTableCell-root {
      padding: 0px;
    }
    &&:hover {
      ${(props) => (props.readOnly ? `background-color: transparent;` : '')}
      ${(props) => (props.readOnly ? `cursor: default;` : '')}
    }
  }
`;


export const StyledUncheckBox = styled(Checkbox)`
  && {
    &&.MuiCheckbox-root {
      color: ${COLORS.darkGrey1};
      label: disabled;
    }

    &&.custom-checkbox-root .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }

    &&.MuiTableCell-root {
      padding: 0px;
    }
  }
`;