import styled from "styled-components";
import { StyledTableCell } from "components/filesListTable/styles";
import { TYPOGRAPHY, COLORS } from '../../../constants';


export const StyledTableCellFirst = styled(StyledTableCell)`
  width: 150px;

  &&.MuiTableCell-root:first-child  {
    border: none;
  }

  &&.MuiTableCell-root {
    border: none;
  }
`;

export const StyledIndexCell = styled(StyledTableCellFirst)`
  width: 50px;
`;

export const StyledTableCellSecond = styled(StyledTableCell)`
  width: 200px;

  &&.MuiTableCell-root:first-child {
    border: none;
  }

  &&.MuiTableCell-root {
    border: none;
  }
`;

export const StyledTableCheckBoxCell= styled(StyledTableCell)`
  width: 10px;

  &&.MuiTableCell-root:last-child {
    border: none;
  }

  &&.MuiTableCell-root{
    border: none;
  }
`;

export const StyledTableCenteringContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledMinusImg = styled.img`
  width: 5px;
  cursor: pointer;
`;

export const StyledImgIcon = styled.img`
  margin-top: 5px;
  cursor: pointer;
`;

export const CardRoot = styled.div`
  position: relative;
  margin-right: 0px;

  width: 200px;
  min-height: 145px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${(props) => (props.readOnly ? COLORS.greyBorder : COLORS.primaryLightOrange )};
  background-color: white;
  ${(props) => (props.readOnly ? `color: ${ COLORS.darkGrey1 };` : '')}
`;

export const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  min-height: 60px;
  flex-grow: 1px;
  width: 100%;
  padding: 16px 20px 0 20px;
`;

export const StyledName = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledProfileImage = styled.div`
  display: flex;
  flex-direction: center;
`;

export const StyledDescription = styled.div`
  font-family: SF Pro Text;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
`;

export const StyledThreeDotMargin = styled.div`
  margin: auto 0;
  margin-left: 96px;
`;

export const StyledXMargin = styled.div`
  margin: auto 0;
  margin-left: 96px;
  cursor: pointer;
`;

export const StyledCheckboxText = styled.div`
  display: flex;
  align-items: center;
`;