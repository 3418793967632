import React from 'react';
import legal from './Legal.svg'

const Legal = ({ isMobile }) => {
  return (
    <img width={isMobile ? "36" : "56"} height={isMobile ? "36" : "56"} src={legal} />
  )
};

export default Legal;

