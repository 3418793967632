import React, { useEffect } from "react";
import {
  CloseButton,
  InfoArea,
  StyledPaper,
  StyledMenuItem,
  StyledButton,
  StyledButtonReview,
  StyledButtonContainer,
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import PledgxLogo from "../../assets/common/pledgxLogo";

export const ReferenceUpdatePopUp = ({ setOpenDialog, openDialog, handleButton }) => {
  useEffect(() => {
    if (!openDialog) {
      setOpenDialog(false);
    }
  }, [openDialog]);
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleGoBack = () => {
    handleClose();
  };

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper elevation={0}>
        <CloseButton aria-label="close" onClick={() => handleClose()}>
          <CloseIcon />
        </CloseButton>
        <div>
          <PledgxLogo />
        </div>

        <InfoArea>
        You have already given a reference for this project executed by this contractor. <br/> <br/>
        Would you like to update your reference?
        </InfoArea>
        <StyledButtonContainer>
          <StyledMenuItem>
            <StyledButton title="Go Back" width="230px" onClick={handleGoBack}>
              Go Back
            </StyledButton>
          </StyledMenuItem>

          <StyledMenuItem>
            <StyledButtonReview
              title="Confirm"
              width="230px"
              onClick={handleButton}
            >
              Confirm
            </StyledButtonReview>
          </StyledMenuItem>
        </StyledButtonContainer>
      </StyledPaper>
    </Dialog>
  );
};
