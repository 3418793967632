import { useMediaQuery } from "react-responsive";
import * as React from 'react';
import { SetupUserManagementContainer, LargeText } from './styles';
import { FunctionalButton } from '../../../components/functionalButton/index';
import { Link as RouterLink, useHistory } from "react-router-dom";

export const Subscription = () => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const history = useHistory();

  const handleAdd = () => {
    history.push("/subscribe/packages");
  };


  return (

    <SetupUserManagementContainer isMobile={isMobile} leftBorder={false}>

      {/* <LargeText> Subscription Management</LargeText> */}
      

      <FunctionalButton
        buttonTitle="Subscription"
        handleButton={handleAdd}
        width="30%"
      />

    </SetupUserManagementContainer>


  );

};


