import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { preQualifyUser, getCCDC, getFillableCCDC } from "../../../../data/actions/user";

import {
  StyledQuestion,
  InfoArea,
 } from './styles';

import { FunctionalButton } from "components/functionalButton";
import { AlertPopup } from 'components/alertPopup/alertPopup';

import { FormContainer } from "pages/newPreQualify/styles";
import { UploadArea } from "pages/preQualifyForm/components/uploadArea";
import { UploadedFileDisplay } from "pages/preQualifyForm/components/uploadedFileDisplay";
import { FileType } from "pages/newPreQualify/components/additionalDocuments/fileType";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


export const AdditionalDocuments = ({
  handleNext,
  setIsLastPanel,
  setPreviousStepPanel,
  data,
  setData,
  action,
  setAction,
  fileType,
  setFileType,
  panel,
  setPanel,
  updateCachedData,
  setPdfFileSequence,
}) => {
  setPreviousStepPanel(2);
  const inputFile = useRef(null);
  const [uploadSize, setUploadSize] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    if(data?.additional_document?.length === 0) {
      setUploadSize(0);

      return;
    }

    let fileSizes = 0;

    data?.additional_document?.forEach((file) => {
      fileSizes = fileSizes + file.size;
    });

    setUploadSize(fileSizes/1000000);
  }, [data?.additional_document]);

  const addUploadedFile = (files) => {
    let temp = [...data.additional_document];

    if((temp.length + files.length) > 10) {
      setOpenPopup(true);

      return;
    }

    let fileSizes = 0;

    for (let i = 0; i < files.length; i++)  {
      temp.push(files[i]);
      fileSizes = fileSizes + files[i].size;
    }

    if((uploadSize + fileSizes/1000000) > 50) {
      setOpenPopup(true);

      return;
    }

    setData('additional_document', temp);
  };

  const deleteFile = (index) => {
    let temp = [...data.additional_document];

    temp.splice(index, 1);
    setData('additional_document', temp);
  }

  const handleOnDragEnd = (resultEvent) => {
    if(!resultEvent.destination) {
      return;
    }

    const temp = [...data.additional_document];
    const [reorderedItem] = temp.splice(resultEvent.source.index, 1);
    temp.splice(resultEvent.destination.index, 0, reorderedItem);
    setData('additional_document', temp);
  };
// console.log(data);
  return (
    <>
      <FormContainer>
        <StyledQuestion marginBottom='164px' weight="500">Please Upload additional documents.</StyledQuestion>
        <UploadArea
          setFile={addUploadedFile}
          width="700px"
          height="186px"
          inputFile={inputFile}
          multiple={true}
        />
        <h5>Uploaded Files ({Math.round((uploadSize + Number.EPSILON) * 100) / 100} / 50 mb)</h5>
        { data?.additional_document?.length > 0 &&
          (<DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="additionalFile">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  { data?.additional_document?.map((item, i) =>  (
                      <Draggable key={`file-${i}`} draggableId={`file-${i}`} index={i}>
                        {(provided) => (
                          <div key={i} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                            <  InfoArea>
                              <UploadedFileDisplay
                                key={`file-${i}`}
                                file={item}
                                displayPercentage="700px"
                                deleteFile={deleteFile}
                                index={i}
                              />
                            </  InfoArea>
                          </div>
                        )}
                      </Draggable>
                    ))
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>)
        }
        <FunctionalButton
          buttonTitle="Next"
          handleButton={() => handleNext()}
          width="200px"
          marginRight="0px"
          marginTop="48px"
        />

        <AlertPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          headerText={'You have exceeded the file limit.'}
          subHeaderText={'You can upload a maximum of 10 documents or 50mb.'}
        />
      </FormContainer>

  </>
  );
};

export default AdditionalDocuments;
