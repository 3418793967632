 
import  countries from "./countries.json";

const countriesInArray = [];
Object.entries(countries).map(([key,value])=> countriesInArray.push({...value, countryCode:key}));


export default countriesInArray;


