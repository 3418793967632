import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
import { Container, Item } from "./styles";

const Sidebar = ({ location }) => {
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    const path = get(location, "pathname", "").split("/")[1];
    if (path) {
      setCurrent(path);
    } else {
      setCurrent("");
    }
  }, [location]);

  return (
    <Container>
      <Item active={current === ""} to="/">
        Overview
      </Item>
      <Item active={current === "qualification"} to="/qualification">
        Select
      </Item>
      <Item active={current === "bid"} to="/bid">
        Tenders
      </Item>
      <Item active={current === "news"} to="/news">
        News
      </Item>
    </Container>
  );
};

export default withRouter(Sidebar);
