import React from 'react';
import category from './category_color.svg'

const Category_color = ({ isMobile }) => {
  return (
    <img width={isMobile ? "36" : "56"} height={isMobile ? "36" : "56"} src={category} />
  )
};

export default Category_color;
