import React from 'react';

const LegalPersonnelPortrait = () => {
  return (
    <svg width="70" height="70" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="70" cy="70" r="70" fill="#C4C4C4"/>
    </svg>
  )
};

export default LegalPersonnelPortrait;

