
import React from 'react';

const Step4 = ({ active, completed }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2222:16262)">
    <g filter="url(#filter0_d_2222:16262)">
    <circle cx="16" cy="16" r="16" fill="white"/>
    </g>
    <circle cx="16" cy="16" r="13" fill={active || completed ? "#FF6D1D" : "white"} stroke="#ECEBED"/>
    <path d="M16.2734 20H17.4336V18.4585H18.5024V17.438H17.4336V12.2495H15.7202C14.6514 13.8555 13.5127 15.687 12.4922 17.438V18.4585H16.2734V20ZM13.647 17.3843C14.4419 16.0254 15.3872 14.5161 16.2197 13.2646H16.2896V17.4595H13.647V17.3843Z" fill={active || completed ? "white" : "#D0C9D6"}/>
    </g>
    <path d="M16.3955 21H18.084V19.1064H19.417V17.6504H18.084V11.1357H15.5957C14.2559 13.1729 12.8545 15.4424 11.5762 17.6641V19.1064H16.3955V21ZM13.21 17.5889C14.1738 15.9141 15.3154 14.0889 16.3271 12.5439H16.4229V17.6914H13.21V17.5889Z" fill={active || completed ? "white" : "#D0C9D6"}/>
    <defs>
    <filter id="filter0_d_2222:16262" x="-64" y="-57" width="160" height="160" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="7"/>
    <feGaussianBlur stdDeviation="32"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2222:16262"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2222:16262" result="shape"/>
    </filter>
    <clipPath id="clip0_2222:16262">
    <rect width="32" height="32" fill="white"/>
    </clipPath>
    </defs>
    </svg>


  )
};

export default Step4;

