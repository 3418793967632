import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import {
  OnBoardRoot,
  FormContainer,
  Content,
} from "../styles";
import { InfoArea } from '../components/styles';

import { LargeText, SmallText } from './styles';
import { ErrorText, ErrorDiv } from '../resetPasswordPage/styles';

import { FunctionalButton } from "../../../components/functionalButton";
import { confirmUserAEmail, resendConfirmationEmail } from '../../../api/onboarding';
import Input from '../../../components/inputField';
import { emailRE } from '../../../common/regex';

function VerificationPage(props) {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();
  const [panel, setPanel] = useState('');

  useEffect(() => {
    confirmEmail(token);
  }, []);

  const [errorText, setErrorText] = useState('');
  const [errorEmail, setErrorEmail] = useState('');

  const [email, setEmail] = useState('');

  const handleError = (error) => {
    if (error.status == 410) {
      setPanel(2);
      setErrorText(error.data.error);
    }
    if (error.status == 409) {
      setPanel(3);
      setErrorText(error.data.error);
    }
  }
  const confirmEmail = async (token) => {
    await confirmUserAEmail(token)
          .then(res => setPanel(1))
          .catch(err => handleError(err.response));
  };

  const handleResend = () => {
    resendConfirmationEmail({email}, onSuccess, onFailure);
  }

  const onSuccess = () => {
    setPanel(4);
  }

  const onFailure = (error) => {
    setErrorEmail(error.data.message);
  }

  useEffect(() => {
    setErrorEmail('');
  }, [email])

  return (
    <OnBoardRoot isMobile={isMobile}>
      <FormContainer isMobile={isMobile}>
        {panel == 1 && (
          <Content isMobile={isMobile}>
           <LargeText isMobile={isMobile}>Thanks! Your email has been confirmed!</LargeText>
           <SmallText isMobile={isMobile}>Log in to use PledgXQualify</SmallText>
           <FunctionalButton
             buttonTitle="Login"
             handleButton={() => history.push("/account/login")}
             width="200px"
             marginRight="0px"
             marginTop="40px"
             isReversedColor={true}
           />
           <SmallText isMobile={isMobile}>You can change your password after you have logged in.</SmallText>
          </Content>
        )}
        {panel == 2 && (
          <Content isMobile={isMobile}>
           <LargeText isMobile={isMobile} width="650px">{errorText}</LargeText>
           <InfoArea isMobile={isMobile} width="650px" isColumn={true}>
            <SmallText isMobile={isMobile}>Enter your email to resend a confirmation</SmallText>
              <Input
              type="text"
              placeholder="name@company.com"
              labelSize="12px"
              textAreaHeight="64px"
              fontSize="18px"
              value={email}
              setValue={(val) => setEmail(val)}
              validateFunc={(item) => emailRE.test(item)}
              hasError={email.length > 0 && !emailRE.test(email)}
              noCheckIcon={true}
              // noErrorBorder={true}
            />
            <ErrorDiv marginTop="4px">
              {email.length > 0 && !emailRE.test(email) && (
                <ErrorText>Email should be in format: name@company.com</ErrorText>
              )}
              {errorEmail && (
                <ErrorText>{errorEmail}</ErrorText>
              )}
            </ErrorDiv>
            <FunctionalButton
             buttonTitle="Resend"
             handleButton={handleResend}
             width="200px"
             marginRight="0px"
             marginTop="40px"
             disabled={!emailRE.test(email) || errorEmail}
           />
          </InfoArea>
          </Content>
        )}
        {panel == 3 && (
          <Content isMobile={isMobile}>
           <LargeText isMobile={isMobile} width="650px">{errorText}</LargeText>
           <FunctionalButton
             buttonTitle="Login"
             handleButton={() => history.push("/account/login")}
             width="200px"
             marginRight="0px"
             marginTop="40px"
             isReversedColor={true}
           />
          </Content>
        )}
         {panel == 4 && (
          <Content isMobile={isMobile}>
            <LargeText isMobile={isMobile} width="60%">A confirmation email has been sent to {email}.</LargeText>
            <SmallText isMobile={isMobile} width="70%">If you haven’t received anything within 5 minutes, check your
          Junk/Spam folder and add us to your contacts!</SmallText>
          </Content>
        )}
      </FormContainer>
    </OnBoardRoot>
  )
}

export default VerificationPage;