import { Avatar, Typography } from "@material-ui/core";
import styled from "styled-components";
import { TYPOGRAPHY, COLORS, DASHBOARDHEADER_HEIGHT } from "../../constants";
import IconButton from "@material-ui/core/IconButton";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export const IconArea = styled(IconButton)`
  &&{
    width: fit-content;
    color: black;
    border-radius: 0%;
    ${props => props.isMobile ? "margin-left:16px;" : ""}
    // margin:  ${props => props.margin ? props.margin : '0px'};

    &:hover{
      background-color: white;
    }
  }
`;

export const NameArea = styled(IconArea)`
  &&{
    padding: 0px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  position: fixed;
  width: 100%;
  ${props => props.isMobile ? "justify-content: space-between;" :""}
  background: white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 3px 3px rgba(0, 0, 0, 0.24);

  height: ${ DASHBOARDHEADER_HEIGHT }px;
  z-index: 1000;
  top: 0;
`;

export const Logo = styled.img`
  width: 133px;
  height: 55px;
`;

export const CustomIcons = styled.img`
  width: 24px;
  height: 24px;
`;

export const AvatarePicture = styled(Avatar)`
  &&{
    width: 42px;
    height: 42px;
    ${props => props.isMobile ? "margin-right: 8px;" : ""}
  }
`;

export const VerticalLine = styled.div`
  border-left: 1px solid #D0C9D6;
  height: 38.5px;
`;

export const ProfileInfo = styled.div`
  ${props => props.isMobile ? "" : "min-width: 150px; max-width: 280px;"}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  margin-left: 8px;
  position: relative;
`;

export const UserName = styled(Typography)`
 &&{
  font-family: ${TYPOGRAPHY.primaryFont};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-right: 8px;
  margin-left: 8px;
 }
`;

export const StyledMenu = styled(Menu)`
  &&&& {
    margin-top: 40px;
    margin-left: 0px;

  }

  .MuiMenu-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  // &:before{
  //   overflow: visible;
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   top: 0;
  //   right: 38px;
  //   width: 10px;
  //   height: 10px;
  //   margin-top: 16px;
  //   background-color: white;
  //   transform: translateY(-50%) rotate(45deg);
  //   z-index: 0;
  //   filter: drop-shadow(0px 2px 8px rgba(0,0,0,0.32));
  // }

`;

export const StyledMenuItem = styled(MenuItem)`
  &&.MuiListItem-gutters {
    padding-left: 8px;
    // padding-right: 4px;
  }
`;

export const StyledIcon = styled.div`
  margin-right: 12px;
`;


export const StyledIconPassword = styled.div`
  margin-right: 12px;
  margin-top: 10px;
`;

export const ToggleDiv = styled.div`
  display: flex; 
  flex-direction: row;
  align-items: center;
  margin-right: 24px;
`;

export const ToggleText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: ${COLORS.textGrey};
  }
`;  