import React, { useState, useEffect } from "react";

import { filterInsuranceGi } from "api/prequalify";
import AddIcon from "@mui/icons-material/Add";
import { StyledQuestion } from "../styles";
import { isEmpty } from "lodash";

import { FunctionalButton } from "components/functionalButton";
import { AddFinancialPopup } from "../../../../components/addFinancialPopup";

import { getInsuranceTypes } from "api/external_data";
import InsuranceTypeAndNamePick from "./insuranceTypeAndNamePick";

import { saveSingleFinancial } from "api/profile";

export const InsurancePickList = ({
  prequalifyData,
  setPrequalifyData,
  setIsLastPanel,
  setPreviousStepPanel,
  handleNext,
  popupTitle,
  setRerender,
  insuranceType,
  institutionId,
}) => {
  setIsLastPanel(true);
  setPreviousStepPanel(1);

  const [list, setList] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  // const [disabled, setDisable] = useState(true);
  const [object, setObject] = useState(null);
  useEffect(() => {
    handleFilter();

    if (isEmpty(prequalifyData?.insuranceList?.order)) {
      setObject(getInitialInsurance());
    } else {
      setObject(prequalifyData?.insuranceList);
    }
  }, []);
  const handleFilter = async () => {
    await filterInsuranceGi("", { institution_id: institutionId })
      .then((data) => {
        setList(data);
        setFilteredData(data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    setPrequalifyData("insuranceList", object);

    // if (object?.order?.length === insuranceType?.length) {
    //   object?.order?.map((value) => {
    //     if (
    //       object.state[value].type !== null &&
    //       Object.keys(object.state[value].data).length !== 0
    //     ) {
    //       setDisable(false);
    //     } else {
    //       setDisable(true);
    //     }
    //   });
    // }
  }, [object]);

  const getInitialInsurance = () => {
    return {
      order: [0],
      state: {
        0: {
          type: null,
          data: {},
        },
      },
    };
  };

  const addOtherInsuranceType = () => {
    try {
      let max = Math.max(...object?.order);
      let nextElementToAdd = [...object?.order, max + 1];

      object.order = nextElementToAdd;
      setObject({ ...object });
      object.state[max + 1] = getInitialInsurance().state[0];
      setObject({ ...object });
    } catch (e) {
      console.log("Not able to add the array");
    }
  };

  const handleAdd = () => {
    setOpenPopup(true);
  };
  const addFinancial = (props) => {
    let newProps = { ...props };
    if (institutionId) {
      newProps = { ...newProps, institution_id: institutionId };
    }
    saveSingleFinancial(
      newProps,
      () => {
        handleFilter();
      },
      () => {}
    );
  };

  const removeInsuranceType = (index) => {
    const temp = { ...object };
    temp?.order?.splice(index, 1);
    delete temp?.state[index];
    if (isEmpty(temp?.order)) {
      setObject(getInitialInsurance());
    } else {
      setObject(temp);
    }
    //setPrequalifyData("insuranceList", temp);
  };
  return (
    <>
      <StyledQuestion marginBottom="32px">
        Which Insurance Companies do you currently use?
      </StyledQuestion>
      {object?.order?.map((index) => (
        <InsuranceTypeAndNamePick
          insuranceType={insuranceType}
          key={index}
          index={index}
          object={object}
          setObject={setObject}
          filteredData={filteredData}
          removeInsuranceType={removeInsuranceType}
        />
      ))}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          //position: "relative",
        }}
      >
        {object?.order?.length < insuranceType.length && (
          <div
            style={{
              // width: "100%",
              height: "50px",
              display: "flex",
              alignItems: "center",
              flex: 1,
              justifyContent: "flex-start",
            }}
          >
            <div
              onClick={addOtherInsuranceType}
              // onMouseOver=()
              style={{
                display: "flex",
                alignItems: "center",
                color: "#FF6D1D",
                cursor: "pointer",
              }}
            >
              <AddIcon />
              Insurance Type
            </div>
          </div>
        )}
        <div
          style={{
            // width: "100%",
            height: "50px",
            display: "flex",
            alignItems: "center",
            flex: 1,
            justifyContent: "flex-end",
          }}
        >
          <div
            onClick={handleAdd}
            // onMouseOver=()
            style={{
              display: "flex",
              alignItems: "center",
              color: "#FF6D1D",
              cursor: "pointer",
            }}
          >
            <AddIcon />
            New Insurance Company
          </div>
          {/* <FunctionalButton
          buttonTitle="Add New"
          handleButton={() => handleAdd()}
          width="200px"
          marginRight="auto"
          marginLeft="auto"
          marginTop="16px"
        /> */}
        </div>
      </div>
      <FunctionalButton
        buttonTitle="Next"
        handleButton={() => handleNext()}
        width="200px"
        marginRight="auto"
        marginLeft="auto"
        marginTop="16px"
        // disabled={disabled}
      />

      <AddFinancialPopup
        setOpenPopup={setOpenPopup}
        openPopup={openPopup}
        handleButtonFunc={addFinancial}
        data={{ type: "insurance" }}
        popupTitle={popupTitle}
      />
    </>
  );
};

export default InsurancePickList;
