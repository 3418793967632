import React, { useState, useEffect } from "react";
import { YearsNumber } from "./YearsNumber";
import {
  StyledHealthSafetyPersonnelNone,
  StyledCenter,
  SafetyText,
  StyledSafetyRecordsContainer,
  StyledRow,
  SecondRadioContainer,
  SectionText,
  StyledYear,
  FieldColumn,
  FieldWrap,
  LabelText,
  MobileYearText,
  StyledTextField,
  StyledEditRecordButton,
} from "./styles";
import { MobileYearsNumber } from "./MobileYearsNumber";
import { FunctionalButton } from "components/functionalButton";
import { FieldArea } from "pages/contractorDashboard/profileSetup/styles";
import { RadioButton } from "components/radioButton";
import CheckedRadioButton from "assets/dashboardIcons/checkedRadioButton";
import UncheckedRadioButton from "assets/dashboardIcons/uncheckedRadioButton";
import { ResultPanel } from "pages/contractorDashboard/profileSetup/resultPanel.js";
import {
  COLORS,
  healthSafetyProgramList,
  healthSafetyEmployeeOrientationProgramList,
} from "../../../../constants";
import updateObjectByPath from "common/updateObjectByPath";
import { StyledSubtext } from "pages/loginPage/styles";
import Select from "react-select";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";
import DocumentPoolPopUp from "components/documentPoolPopUp";
import { WrapperForUploadedHealthSafetyFileDisplay } from "pages/preQualifyForm/components/uploadedHealthSafetyFileDisplay";
import ConciseTable from "components/filesListTable/conciseTable";
import { ScrollableDiv } from "pages/documentPool/styles";
import { AlertPopup } from "components/alertPopup/alertPopup";
import { useSelector } from "react-redux";
import { contactSelector } from "data/selectors/contact";
import { institutionProfileInfoSelector } from "data/selectors/serviceProvider";

export const SafetyRecordsUSA = ({
  data,
  setData,
  isMobile,
  handleNext,
  institutionId,
  currentDashboardUrl,
}) => {
  const currentYear = new Date().getFullYear();
  const [safetyRemarkUSA, setSafetyRemarkUSA] = useState(
    data?.annual?.remark_records?.annual_safety_records_remark_usa || ""
  );
  const [filteredData, setFilteredData] = useState(
    data?.annual?.health_safety_personnel || []
  );
  const [filteredSafetyDataUSA, setFilteredSafetyDataUSA] = useState(
    data?.annual?.safety_records_usa
  );
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupPersonnel, setOpenPopupPersonnel] = useState(false);
  const dataListOne = data?.annual?.remark_records?.health_safety_program_list;
  const [selectedListOneOptions, setSelectedListOneOptions] =
    useState(dataListOne);
  const dataListTwo =
    data?.annual?.remark_records
      ?.health_safety_employee_orientation_program_list;
  const [selectedListTwoOptions, setSelectedListTwoOptions] =
    useState(dataListTwo);
  const [internalSearchResults, setInternalSearchResults] = useState(
    data?.annual?.health_safety_documents_usa
  );
  const [query, setQuery] = useState("");
  const [openDocumentPool, setOpenDocumentPool] = useState(false);
  const headers = [];
  const [selectedFiles, setSelectedFiles] = useState([]);

  let contactData = useSelector(contactSelector);
  const institutionContactData = useSelector(institutionProfileInfoSelector);

  if (currentDashboardUrl === "/service-provider-dashboard") {
    contactData = institutionContactData;
  }
  const [healthSafetyPersonnelNone, setHealthSafetyPersonnelNone] = useState(
    data?.annual?.remark_records?.health_safety_personnel_none
  );
  console.log("contactData", contactData);
  const [isFocused, setIsFocused] = useState(false);
  const [isFocusedTwo, setIsFocusedTwo] = useState(false);

  const handleDocumentPoolPopUp = () => {
    setOpenDocumentPool(true);
  };

  const listOneOptions = healthSafetyProgramList.map((option) => ({
    value: option,
    label: option,
  }));

  const listTwoOptions = healthSafetyEmployeeOrientationProgramList.map(
    (option) => ({
      value: option,
      label: option,
    })
  );

  const handleSelectListOneChange = (selectedListOneOptions) => {
    setSelectedListOneOptions(selectedListOneOptions);
  };

  const handleSelectListTwoChange = (selectedListTwoOptions) => {
    setSelectedListTwoOptions(selectedListTwoOptions);
  };

  const [healthSafetyProgram, setHealthSafetyProgram] = useState(
    data?.annual?.remark_records?.health_safety_program || false
  );

  const [notHealthSafetyProgram, setNotHealthSafetyProgram] = useState(
    data?.annual?.remark_records?.no_health_safety_program || false
  );
  const [
    healthSafetyEmployeeOrientationProgram,
    setHealthSafetyEmployeeOrientationProgram,
  ] = useState(
    data?.annual?.remark_records?.health_safety_employee_orientation_program ||
      false
  );
  const [
    notHealthSafetyEmployeeOrientationProgram,
    setNotHealthSafetyEmployeeOrientationProgram,
  ] = useState(
    data?.annual?.remark_records
      ?.no_health_safety_employee_orientation_program || false
  );

  const [notHealthSafetyProgramRemark, setNotHealthSafetyProgramRemark] =
    useState(
      data?.annual?.remark_records?.no_health_safety_program_remark || ""
    );

  const [
    notHealthSafetyEmployeeOrientationProgramRemark,
    setNotHealthSafetyEmployeeOrientationProgramRemark,
  ] = useState(
    data?.annual?.remark_records
      ?.no_health_safety_employee_orientation_program_remark || ""
  );

  const handleSafetyRemarkUSAChange = (event) => {
    setSafetyRemarkUSA(event.target.value);
  };

  const handlesNotHealthSafetyProgramRemarkChange = (event) => {
    setNotHealthSafetyProgramRemark(event.target.value);
  };

  const handlesNotHealthSafetyEmployeeOrientationProgramRemarkChange = (
    event
  ) => {
    setNotHealthSafetyEmployeeOrientationProgramRemark(event.target.value);
  };

  useEffect(() => {
    setData(
      `annual.remark_records.annual_safety_records_remark_usa`,
      safetyRemarkUSA
    );
  }, [safetyRemarkUSA]);

  useEffect(() => {
    setData(
      `annual.remark_records.health_safety_program_list`,
      selectedListOneOptions
    );
  }, [selectedListOneOptions]);

  useEffect(() => {
    setData(
      `annual.remark_records.health_safety_employee_orientation_program_list`,
      selectedListTwoOptions
    );
  }, [selectedListTwoOptions]);

  useEffect(() => {
    setData(`annual.remark_records.health_safety_program`, healthSafetyProgram);
  }, [healthSafetyProgram]);

  useEffect(() => {
    setData(
      `annual.remark_records.health_safety_employee_orientation_program`,
      healthSafetyEmployeeOrientationProgram
    );
  }, [healthSafetyEmployeeOrientationProgram]);

  useEffect(() => {
    setData(
      `annual.remark_records.no_health_safety_program`,
      notHealthSafetyProgram
    );
  }, [notHealthSafetyProgram]);

  useEffect(() => {
    setData(
      `annual.remark_records.no_health_safety_program_remark`,
      notHealthSafetyProgramRemark
    );
  }, [notHealthSafetyProgramRemark]);

  useEffect(() => {
    setData(
      `annual.remark_records.no_health_safety_employee_orientation_program`,
      notHealthSafetyEmployeeOrientationProgram
    );
  }, [notHealthSafetyEmployeeOrientationProgram]);

  useEffect(() => {
    setData(
      `annual.remark_records.no_health_safety_employee_orientation_program_remark`,
      notHealthSafetyEmployeeOrientationProgramRemark
    );
  }, [notHealthSafetyEmployeeOrientationProgramRemark]);

  useEffect(() => {
    setData(
      `annual.remark_records.health_safety_personnel_none`,
      healthSafetyPersonnelNone
    );
  }, [healthSafetyPersonnelNone]);

  useEffect(() => {
    setData("annual.health_safety_personnel", filteredData);
  }, [filteredData]);

  useEffect(() => {
    setData("annual.safety_records_usa", filteredSafetyDataUSA);
  }, [filteredSafetyDataUSA]);

  const confirmRadioHealthSafetyChange = (val) => {
    if (val === 1) {
      setHealthSafetyProgram(true);
      setNotHealthSafetyProgram(false);
    } else {
      setNotHealthSafetyProgram(true);
      setHealthSafetyProgram(false);
    }
  };

  const confirmSecondRadio = (val) => {
    if (val === 1) {
      setHealthSafetyEmployeeOrientationProgram(true);
      setNotHealthSafetyEmployeeOrientationProgram(false);
    } else {
      setNotHealthSafetyEmployeeOrientationProgram(true);
      setHealthSafetyEmployeeOrientationProgram(false);
    }
  };

  const deleteHealthSafetyFile = (index) => {
    let temp = [...data?.annual?.health_safety_documents_usa];
    temp.splice(index, 1);

    setData("annual.health_safety_documents_usa", temp);
  };

  const addUploadedFile = () => {
    let temp = [...(data?.annual?.health_safety_documents_usa || [])];

    for (let key in selectedFiles) {
      if (!isNaN(key)) {
        temp.push(selectedFiles[key]);
      }
    }

    setData("annual.health_safety_documents_usa", temp);
    setOpenDocumentPool(false);
  };

  const handleHealthSafetyPersonnelNoneChange = () => {
    setHealthSafetyPersonnelNone(!healthSafetyPersonnelNone);
  };

  const fileLimitCount = contactData?.user_access_control?.file_limit_count;
  const fileLimitSize = contactData?.user_access_control?.file_limit_size;
  const fileLimitSizeMB = (fileLimitSize / (1024 * 1024)).toFixed(2);
  const customPaddingStyle = {
    paddingLeft: "5px", // Change this value to your desired padding left
    paddingRight: "5px", // Change this value to your desired padding right
  };

  return (
    <>
      <FieldWrap isMobile={isMobile}>
        {!isMobile && (
          <>
            <SectionText style={{ marginTop: "-10px" }}>
              Health and Safety
            </SectionText>

            <StyledRow style={{ marginBottom: "-10px" }}>
              <StyledSubtext style={{ width: "90%" }}>
                {" "}
                Select Personnel responsible for Health & Safety:{" "}
              </StyledSubtext>

              <StyledRow justifyContent="right" style={{ marginTop: "-10px" }}>
                <StyledHealthSafetyPersonnelNone
                  control={
                    <RadioButton
                      isChecked={healthSafetyPersonnelNone}
                      handleChange={handleHealthSafetyPersonnelNoneChange}
                    />
                  }
                  label="None"
                  labelPlacement="end"
                />
              </StyledRow>
            </StyledRow>

            {healthSafetyPersonnelNone === false && (
              <ResultPanel
                projects={filteredData}
                setProjects={setFilteredData}
                setOpenPopup={setOpenPopupPersonnel}
                openPopup={openPopupPersonnel}
                paddingRight="0px"
                height="210px"
                label={false}
                institution_id={institutionId}
                currentDashboardUrl={currentDashboardUrl}
              />
            )}

            <StyledSubtext style={{ marginTop: "28px" }}>
              {" "}
              Do you have a formal Health & Safety Program?{" "}
            </StyledSubtext>
            <StyledEditRecordButton
              style={{ marginLeft: "-10px", justifyContent: "left" }}
            >
              <RadioButton
                isChecked={
                  healthSafetyProgram === true &&
                  notHealthSafetyProgram === false
                    ? true
                    : false
                }
                value={healthSafetyProgram}
                checkedIcon={<CheckedRadioButton />}
                unCheckedIcon={<UncheckedRadioButton />}
                handleChange={() => confirmRadioHealthSafetyChange(1)}
              />
              <StyledSubtext style={{ marginTop: "0px" }}> Yes</StyledSubtext>
              <RadioButton
                isChecked={
                  notHealthSafetyProgram === true &&
                  healthSafetyProgram === false
                    ? true
                    : false
                }
                value={notHealthSafetyProgram}
                checkedIcon={<CheckedRadioButton />}
                unCheckedIcon={<UncheckedRadioButton />}
                handleChange={() => confirmRadioHealthSafetyChange(0)}
              />
              <StyledSubtext style={{ marginTop: "0px" }}> No</StyledSubtext>
            </StyledEditRecordButton>

            {healthSafetyProgram && (
              <SecondRadioContainer style={{ marginLeft: "30px" }}>
                <StyledSubtext style={{ marginTop: "15px" }}>
                  {" "}
                  Select procedures covered by the program:{" "}
                </StyledSubtext>
                <Select
                  options={listOneOptions}
                  isMulti
                  value={selectedListOneOptions}
                  onChange={handleSelectListOneChange}
                  menuIsOpen={isFocused}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "830px",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? "#ECEBED" : "white",
                      ":hover": {
                        backgroundColor: "#ECEBED",
                        border: "1px solid #ECEBED",
                      },
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: "#FFD9C5",
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: "#FF6D1D",
                    }),
                    multiValueRemove: (provided) => ({
                      ...provided,
                      color: "#FFAA7B",
                      ":hover": {
                        backgroundColor: "#FFD9C5",
                        color: "#FF6D1D",
                      },
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: state.isFocused
                        ? "#FF6D1D"
                        : provided.borderColor,
                      boxShadow: state.isFocused
                        ? "0 0 0 1px #FF6D1D"
                        : provided.boxShadow,
                      "&:hover": {
                        borderColor: "#FF6D1D",
                      },
                    }),
                  }}
                />
              </SecondRadioContainer>
            )}

            {notHealthSafetyProgram && (
              <>
                <FieldArea
                  width="96%"
                  noJustifyContent={true}
                  style={{ marginLeft: "30px" }}
                >
                  <StyledSubtext style={{ marginTop: "-10px" }}>
                    {" "}
                    Explain how your company manages Helath & Safety:{" "}
                  </StyledSubtext>
                  <StyledTextField
                    multiline
                    rows={5}
                    placeholder={"Enter your explanation here"}
                    variant="outlined"
                    value={notHealthSafetyProgramRemark}
                    onChange={handlesNotHealthSafetyProgramRemarkChange}
                  />
                </FieldArea>
              </>
            )}

            <StyledRow>
              <StyledSubtext style={{ marginTop: "28px" }}>
                {" "}
                Do you have a formal project Health & Safety Orientation
                program?{" "}
              </StyledSubtext>
            </StyledRow>

            <StyledEditRecordButton
              style={{ marginLeft: "-10px", justifyContent: "left" }}
            >
              <RadioButton
                isChecked={
                  healthSafetyEmployeeOrientationProgram === true &&
                  notHealthSafetyEmployeeOrientationProgram === false
                    ? true
                    : false
                }
                value={healthSafetyEmployeeOrientationProgram}
                checkedIcon={<CheckedRadioButton />}
                unCheckedIcon={<UncheckedRadioButton />}
                handleChange={() => confirmSecondRadio(1)}
              />
              <StyledSubtext style={{ marginTop: "0px" }}> Yes</StyledSubtext>
              <RadioButton
                isChecked={
                  notHealthSafetyEmployeeOrientationProgram === true &&
                  healthSafetyEmployeeOrientationProgram === false
                    ? true
                    : false
                }
                value={notHealthSafetyEmployeeOrientationProgram}
                checkedIcon={<CheckedRadioButton />}
                unCheckedIcon={<UncheckedRadioButton />}
                handleChange={() => confirmSecondRadio(0)}
              />
              <StyledSubtext style={{ marginTop: "0px" }}> No</StyledSubtext>
            </StyledEditRecordButton>

            {healthSafetyEmployeeOrientationProgram && (
              <SecondRadioContainer style={{ marginLeft: "30px" }}>
                <StyledSubtext style={{ marginTop: "15px" }}>
                  {" "}
                  Select procedures covered by the orientation program:{" "}
                </StyledSubtext>
                <Select
                  options={listTwoOptions}
                  isMulti
                  value={selectedListTwoOptions}
                  onChange={handleSelectListTwoChange}
                  menuIsOpen={isFocusedTwo}
                  onFocus={() => setIsFocusedTwo(true)}
                  onBlur={() => setIsFocusedTwo(false)}
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "830px",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? "#ECEBED" : "white",
                      ":hover": {
                        backgroundColor: "#ECEBED",
                        border: "1px solid #ECEBED",
                      },
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: "#FFD9C5",
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: "#FF6D1D",
                    }),
                    multiValueRemove: (provided) => ({
                      ...provided,
                      color: "#FFAA7B",
                      ":hover": {
                        backgroundColor: "#FFD9C5",
                        color: "#FF6D1D",
                      },
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: state.isFocused
                        ? "#FF6D1D"
                        : provided.borderColor,
                      boxShadow: state.isFocused
                        ? "0 0 0 1px #FF6D1D"
                        : provided.boxShadow,
                      "&:hover": {
                        borderColor: "#FF6D1D",
                      },
                    }),
                  }}
                />
              </SecondRadioContainer>
            )}

            {notHealthSafetyEmployeeOrientationProgram && (
              <>
                <FieldArea
                  width="96%"
                  noJustifyContent={true}
                  style={{ marginLeft: "30px" }}
                >
                  <StyledSubtext style={{ marginTop: "-10px" }}>
                    {" "}
                    Explain how your company manages Helath & Safety
                    Orientation:{" "}
                  </StyledSubtext>
                  <StyledTextField
                    multiline
                    rows={5}
                    placeholder={"Enter your explanation here"}
                    variant="outlined"
                    value={notHealthSafetyEmployeeOrientationProgramRemark}
                    onChange={
                      handlesNotHealthSafetyEmployeeOrientationProgramRemarkChange
                    }
                  />
                </FieldArea>
              </>
            )}

            <StyledSubtext style={{ marginTop: "28px" }}>
              {" "}
              Attach all relevant Health & Safety documents:{" "}
            </StyledSubtext>

            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  marginLeft: "0px",
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "70%",
                }}
              >
                <SearchBar
                  filteredData={data?.annual?.health_safety_documents_usa}
                  setInternalSearchResults={setInternalSearchResults}
                  internalSearch={true}
                  query={query}
                  setQuery={setQuery}
                  searchWidth="100%"
                  searchPlaceHolder="File Name..."
                />
              </div>
              <FunctionalButton
                buttonTitle="Document Pool"
                marginLeft="20px"
                handleButton={() => handleDocumentPoolPopUp()}
                width="250px"
                isReversedColor={true}
              />
            </div>

            {data?.annual?.health_safety_documents_usa?.length < 1 && (
              <div
                style={{
                  alignItems: "center",
                  backgroundColor: "white",
                  border: `dashed 2px ${COLORS.lightGrey1}`,
                  display: "flex",
                  height: "186px",
                  color: `${COLORS.textGrey}`,
                  justifyContent: "center",
                  width: "98%",
                  marginTop: "20px",
                }}
              >
                Selected Documents will appear here
              </div>
            )}
            {data?.annual?.health_safety_documents_usa?.length > 0 && (
              <div style={{ marginTop: "20px", width: "98%" }}>
                <ConciseTable headers={headers} />

                <ScrollableDiv style={{ maxHeight: "190px" }}>
                  <div style={{ marginRight: "15px" }}>
                    {data?.annual?.health_safety_documents_usa?.length > 0 &&
                      (query && query !== ""
                        ? internalSearchResults
                        : data?.annual?.health_safety_documents_usa
                      )?.map((item, i) => (
                        <StyledCenter style={{ marginTop: 0 }}>
                          <WrapperForUploadedHealthSafetyFileDisplay
                            key={`file-${i}`}
                            file={item}
                            displayPercentage="100%"
                            deleteFile={deleteHealthSafetyFile}
                            index={i}
                            setPrequalifyData={setData}
                            prequalifyData={data}
                            setOpenPopup={setOpenPopup}
                            openPopup={openPopup}
                          />
                        </StyledCenter>
                      ))}
                  </div>
                </ScrollableDiv>
              </div>
            )}

            <AlertPopup
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
              headerText={"You have exceeded the file limit."}
              subHeaderText={`You can upload a maximum of ${fileLimitCount} documents or ${fileLimitSizeMB}MB.`}
            />
            <DocumentPoolPopUp
              openPopup={openDocumentPool}
              setOpenPopup={setOpenDocumentPool}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              attachFile={addUploadedFile}
              institutionId={institutionId}
            />

            <SectionText style={{ marginTop: "48px" }}>
              Annual Safety Records
            </SectionText>
            <StyledSafetyRecordsContainer>
              <div>
                <SafetyText
                  style={{
                    marginLeft: "60px",
                    marginTop: "20px",
                    color: "#888888",
                    fontSize: "15px",
                    fontWeight: "600px",
                  }}
                >
                  {" "}
                  From EMR Worksheet
                </SafetyText>
                <hr
                  style={{
                    color: "#888888",
                    backgroundColor: "#888888",
                    height: 0.25,
                    opacity: 0.25,
                    borderColor: "#888888",
                    marginLeft: "60px",
                    marginRight: "-95px",
                    marginBottom: "-10px",
                  }}
                />
              </div>

              <div>
                <SafetyText
                  style={{
                    marginLeft: "105px",
                    marginTop: "20px",
                    color: "#888888",
                    fontSize: "15px",
                    fontWeight: "600px",
                  }}
                >
                  {" "}
                  From OSHA 300A
                </SafetyText>
                <hr
                  style={{
                    color: "#888888",
                    backgroundColor: "#888888",
                    height: 0.25,
                    opacity: 0.25,
                    borderColor: "#888888",
                    marginLeft: "105px",
                    marginRight: "-383px",
                    marginBottom: "-10px",
                  }}
                />
              </div>
            </StyledSafetyRecordsContainer>

            <div style={{ marginBottom: "60px" }}></div>

            <FieldColumn className="firstColumn">
              <StyledYear>{currentYear - 1}</StyledYear>
              <StyledYear>{currentYear - 2}</StyledYear>
              <StyledYear>{currentYear - 3}</StyledYear>
            </FieldColumn>
            <FieldColumn
              className="recordColumn"
              style={{ marginRight: "-30px" }}
            >
              <LabelText style={{ width: "110px", lineHeight: "10px" }}>
                Independent Classification Code
              </LabelText>
              <YearsNumber
                data={data?.annual?.safety_records_usa ?? {}}
                setData={setData}
                type="emr_worksheet_independent_classification_code"
                year={currentYear - 1}
              />
            </FieldColumn>
            <FieldColumn
              className="recordColumn"
              style={{ marginRight: "-15px" }}
            >
              <LabelText style={{ width: "110px", lineHeight: "10px" }}>
                Experience Modification Rating
              </LabelText>
              <YearsNumber
                data={data?.annual?.safety_records_usa ?? {}}
                setData={setData}
                type="emr_worksheet_electronic_medical_record"
                year={currentYear - 1}
              />
            </FieldColumn>
            <FieldColumn
              className="recordColumn"
              style={{ marginRight: "-30px" }}
            >
              <LabelText style={{ width: "110px", lineHeight: "10px" }}>
                Total Hours for All Employees
              </LabelText>
              <YearsNumber
                data={data?.annual?.safety_records_usa ?? {}}
                setData={setData}
                type="osha_300a_total_hours_for_all_employees"
                year={currentYear - 1}
                paddingLeft="5px"
                paddingRight="5px"
              />
            </FieldColumn>
            <FieldColumn
              className="recordColumn"
              style={{ marginRight: "-30px" }}
            >
              <LabelText style={{ width: "110px", lineHeight: "10px" }}>
                Total Number of Deaths
              </LabelText>
              <YearsNumber
                data={data?.annual?.safety_records_usa ?? {}}
                setData={setData}
                type="osha_300a_total_number_of_deaths"
                year={currentYear - 1}
              />
            </FieldColumn>
            <FieldColumn
              className="recordColumn"
              style={{ marginRight: "-30px" }}
            >
              <LabelText style={{ width: "110px", lineHeight: "10px" }}>
                Total Number of Other Cases
              </LabelText>
              <YearsNumber
                data={data?.annual?.safety_records_usa ?? {}}
                setData={setData}
                type="osha_300a_total_number_of_other_cases"
                year={currentYear - 1}
              />
            </FieldColumn>

            <FieldColumn
              className="recordColumn"
              style={{ marginRight: "-30px" }}
            >
              <LabelText style={{ width: "110px", lineHeight: "10px" }}>
                Total Number of Absent Days
              </LabelText>
              <YearsNumber
                data={data?.annual?.safety_records_usa ?? {}}
                setData={setData}
                type="osha_300a_total_number_of_absent_days"
                year={currentYear - 1}
              />
            </FieldColumn>

            <FieldArea width="98%" noJustifyContent={true}>
              <LabelText> Remarks </LabelText>
              <StyledTextField
                multiline
                rows={5}
                placeholder={"Remark"}
                variant="outlined"
                value={safetyRemarkUSA}
                onChange={handleSafetyRemarkUSAChange}
              />
            </FieldArea>
          </>
        )}

        {isMobile && (
          <>
            <MobileYearText margin="0px 0px 16px">
              {currentYear - 1}
            </MobileYearText>
            <MobileYearsNumber
              data={data?.annual?.safety_records_usa ?? {}}
              setData={setData}
              year={currentYear - 1}
            />
            <MobileYearText margin="32px 0px 16px">
              {currentYear - 2}
            </MobileYearText>
            <MobileYearsNumber
              data={data?.annual?.safety_records_usa ?? {}}
              setData={setData}
              year={currentYear - 2}
            />
            <MobileYearText margin="32px 0px 16px">
              {currentYear - 3}
            </MobileYearText>
            <MobileYearsNumber
              data={data?.annual?.safety_records_usa ?? {}}
              setData={setData}
              year={currentYear - 3}
            />
          </>
        )}
        <FunctionalButton
          buttonTitle="Next"
          handleButton={() => handleNext()}
          width="200px"
          marginRight="auto"
          marginLeft="auto"
          marginTop="16px"
        />
      </FieldWrap>
    </>
  );
};

export default SafetyRecordsUSA;
