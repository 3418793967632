import React from 'react';
import service from './service.svg';
import { StyledImg } from './styles';

const ServiceIcon = ({ width, height, noGreyFilter }) => {
  return (
    <StyledImg width={width} heigh={height} src={service} noGreyFilter={noGreyFilter} />
  )
};


export default ServiceIcon;
