import React, { useState } from "react";
import { ScrollableDiv, StyledDocumentContainer } from "./styles";

import ConciseTable from "components/filesListTable/conciseTable";
import { UploadedFiles } from "./uploadedFiles";
import { deleteFile, setShowcasePermission } from "api/file_managment";

import { AlertPopup } from "../../components/alertPopup/alertPopup";

export const DocumentList = ({
  filteredData,
  setFilteredData,
  fileLimitCount,
  fileLimitSizeMB,
  isMobile,
  docPoolPopUp,
  selectedFiles,
  setSelectedFiles,
  resumeFile,
  institutionId,
  fileTypes,
  openPopup,
  setOpenPopup,
  currentDashboardUrl,
}) => {
  const headers = [];
  !docPoolPopUp
    ? headers.push(
        { title: "Showcase", width: "10%" },
        { title: "Title", width: "30%" },
        { title: "Document Type", width: "28%" },
        { title: "Size(MB)", width: "13%" },
        { title: "Upload Date", width: "25%" },
        { title: "" }
      )
    : headers.push(
        { title: "", width: "10%" },
        { title: "Title", width: "30%" },
        { title: "Document Type", width: "27%" },
        { title: "Size(MB)", width: "12%" },
        { title: "Upload Date", width: "25%" },
        { title: "" }
      );

  const deleteAdditionalFile = (index) => {
    let temp = [...filteredData];
    let initialState = [...temp];
    let fileId = temp[index].file_id;
    let data = {
      file_id: fileId,
    };

    temp.splice(index, 1);
    setFilteredData(temp);
    deleteFile(
      data,
      () => {},
      () => {
        setFilteredData(initialState);
      }
    );
  };
  const handleSingleRadioButtonClick = (file) => {
    setSelectedFiles(file);
  };

  const handleRadioButtonClick = (index) => {
    const fileId = filteredData[index].file_id;
    const temp = [...filteredData];
    const data = { file_id: fileId };
    setShowcasePermission(
      data,
      () => {
        temp[index].private === true || temp[index].private === null
          ? (temp[index].private = false)
          : (temp[index].private = true);
        setFilteredData(temp);
      },
      () => {}
    );
  };

  const handleSelectButtonClick = (index, file_id, selectButtonValue) => {
    const temp = [...selectedFiles];
    const file = filteredData?.find((value) => value.file_id === file_id);
    if (selectButtonValue) {
      temp.push(file);
    } else {
      const index = temp?.findIndex((obj) => obj.file_id === file_id);
      temp?.splice(index, 1);
    }
    setSelectedFiles(temp);
  };

  return (
    <StyledDocumentContainer isMobile={isMobile}>
      <ConciseTable headers={isMobile ? "" : headers} />
      <ScrollableDiv style={{ maxHeight: docPoolPopUp ? "300px" : "" }}>
        {filteredData?.map((file, i) => (
          <UploadedFiles
            key={`file-${i}`}
            index={i}
            file={file}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
            displayPercentage="99%"
            handleDelete={deleteAdditionalFile}
            handleRadioButtonClick={handleRadioButtonClick}
            handleSelectButtonClick={handleSelectButtonClick}
            isMobile={isMobile}
            setOpenPopup={setOpenPopup}
            docPoolPopUp={docPoolPopUp}
            resumeFile={resumeFile}
            selectRadioButtonValue={selectedFiles?.file_id === file.file_id}
            handleSingleRadioButtonClick={handleSingleRadioButtonClick}
            fileTypes={fileTypes}
            institutionId={institutionId}
            currentDashboardUrl={currentDashboardUrl}
          />
        ))}
      </ScrollableDiv>
    </StyledDocumentContainer>
  );
};
