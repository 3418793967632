import React from 'react';
import info from './GeneralInfo.svg'

const GeneralInfo = ({ isMobile }) => {
  return (
    <img width={isMobile ? "36" : "56"} height={isMobile ? "36" : "56"} src={info} />
  )
};

export default GeneralInfo;

