import React, { useState } from "react";

import { MediumText, RegularText, StyledBox, SmallText } from "../styles";

import { FunctionalButton } from "../../../components/functionalButton";
import {
  FunctionArea,
  IntroArea,
  StyledTitle,
  ButtonContainer,
  ComingSoonImg,
  ImgDiv,
} from "./styles";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import COIcon from "assets/serviceProvider/COIcon";

export const ChangeOrder = ({ isMobile, selectedTab }) => {
  const [comingSoon, setComingSoon] = useState(true);
  const handleRec = () => {};
  const history = useHistory();
  const seeAllPendingCo = () => {
    history.push("/service-provider-dashboard/allChangeOrders");
  };
  return (
    <FunctionArea isMobile={isMobile}>
      {isMobile && (
        <StyledBox
          boxHeight="110px"
          borderRadius="15px"
          isMobile={isMobile}
          onClick={handleRec}
          style={{ cursor: "pointer" }}
        >
          {comingSoon && (
            <>
              <COIcon />
              <StyledTitle>
                <SmallText>Coming Soon</SmallText>
              </StyledTitle>
            </>
          )}
          {!comingSoon && (
            <>
              <COIcon />
              <StyledTitle>
                <SmallText>Coming Soon</SmallText>
              </StyledTitle>
            </>
          )}
        </StyledBox>
      )}
      {!isMobile && (
        <>
          <MediumText>Change Orders</MediumText>
          <StyledBox boxHeight="216px">
            {comingSoon && (
              <>
                {/* <ImgDiv style={{ width: "100%" }}>
                  <ComingSoonImg src={criteriaComingSoon} />
                </ImgDiv> */}
                <IntroArea>
                  <COIcon />
                  {/* <StyledTitle>
                    <SmallText>Coming Soon</SmallText>
                  </StyledTitle> */}
                </IntroArea>
                <ButtonContainer>
                  <FunctionalButton
                    buttonTitle="View Change Orders"
                    width="80%"
                    marginRight="0px"
                    isReversedColor={true}
                    hasBoxShadow={true}
                    handleButton={seeAllPendingCo}
                    disabled={selectedTab === "2" ? false : true}
                  />
                </ButtonContainer>
              </>
            )}
            {!comingSoon && (
              <>
                <IntroArea>
                  {/* <InsightsIcon /> */}
                  <StyledTitle>
                    <SmallText>Coming Soon</SmallText>
                  </StyledTitle>
                </IntroArea>
                <ButtonContainer>
                  <FunctionalButton
                    buttonTitle="Add"
                    handleButton={handleRec}
                    width="80%"
                    marginRight="0px"
                    isReversedColor={true}
                    hasBoxShadow={true}
                  />
                </ButtonContainer>
              </>
            )}
          </StyledBox>
        </>
      )}
    </FunctionArea>
  );
};
