import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { StyledMenu, StyledMenuItem, StyledMoreHorizIcon } from "./styles";
import { StatusButton } from "./StatusButton";
import { modifyProject } from "../../../../api/buyers";
import VerticalThreeDots from "assets/dashboardIcons/verticalThreeDots";

export const ActionsMenu = ({
  contract_id,
  status,
  setData,
  handleRfpqCancel,
  handleRfpqDelete,
  institutionId,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickCancel = (rfpq_id) => {
    handleClose();
    if (handleRfpqCancel) {
      handleRfpqCancel(rfpq_id);
    }
  };

  const clickDelete = async () => {
    handleClose();
    await modifyProject(contract_id, "delete")
      .then((data) => {
        setData(data);
      })
      .catch((err) => {});
  };

  const clickModify = async (action) => {
    handleClose();
    if (handleRfpqDelete) {
      handleRfpqDelete(contract_id);
    }
    // await modifyProject(contract_id, action)
    //   .then((data) => {
    //     setData(data);
    //   })
    //   .catch((err) => {});
  };

  if (["deleted"].includes(status)) {
    return <></>;
  } else {
    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <VerticalThreeDots />
        </IconButton>
        {status === "awarded" && (
          <>
            <StyledMenu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <StyledMenuItem>
                <StatusButton
                  title="submissions"
                  width="115px"
                  contract_id={contract_id}
                  institutionId={institutionId}
                />
              </StyledMenuItem>
              <StyledMenuItem>
                <StatusButton
                  title="edit"
                  width="58px"
                  contract_id={contract_id}
                  institutionId={institutionId}
                />
              </StyledMenuItem>
            </StyledMenu>
          </>
        )}
        {status == "draft" && (
          <>
            <StyledMenu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <StyledMenuItem>
                <StatusButton
                  title="review"
                  width="58px"
                  contract_id={contract_id}
                />
              </StyledMenuItem>
              <StyledMenuItem>
                <StatusButton
                  title="edit"
                  width="58px"
                  contract_id={contract_id}
                />
              </StyledMenuItem>
              <StyledMenuItem>
                <StatusButton
                  title="delete"
                  width="58px"
                  contract_id={contract_id}
                  handleClick={clickCancel}
                />
              </StyledMenuItem>
            </StyledMenu>
          </>
        )}
        {(status === "open" || status === "invited") && (
          <>
            <StyledMenu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <StyledMenuItem>
                <StatusButton
                  title="submissions"
                  width="115px"
                  contract_id={contract_id}
                  institutionId={institutionId}
                />
              </StyledMenuItem>
              <StyledMenuItem>
                <StatusButton
                  title="amend"
                  width="70px"
                  contract_id={contract_id}
                  disabled={true}
                  institutionId={institutionId}
                />
              </StyledMenuItem>
              <StyledMenuItem>
                <StatusButton
                  title="cancel"
                  width="70px"
                  contract_id={contract_id}
                  handleClick={clickModify}
                  institutionId={institutionId}
                />
              </StyledMenuItem>
            </StyledMenu>
          </>
        )}
        {status === "reviewed" && (
          <>
            <StyledMenu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <StyledMenuItem>
                <StatusButton
                  title="invite/publish"
                  width="125px"
                  contract_id={contract_id}
                />
              </StyledMenuItem>
              <StyledMenuItem>
                <StatusButton
                  title="edit"
                  width="58px"
                  contract_id={contract_id}
                />
              </StyledMenuItem>
              <StyledMenuItem>
                <StatusButton
                  title="delete"
                  width="58px"
                  contract_id={contract_id}
                  handleClick={clickCancel}
                />
              </StyledMenuItem>
            </StyledMenu>
          </>
        )}
        {status == "cancelled" && (
          <>
            <StyledMenu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <StyledMenuItem>
                <StatusButton
                  title="edit"
                  width="58px"
                  contract_id={contract_id}
                />
              </StyledMenuItem>
            </StyledMenu>
          </>
        )}
        {status == "closed" && (
          <>
            <StyledMenu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <StyledMenuItem>
                <StatusButton
                  title="submissions"
                  width="115px"
                  contract_id={contract_id}
                />
              </StyledMenuItem>
              <StyledMenuItem>
                <StatusButton
                  title="edit"
                  width="58px"
                  contract_id={contract_id}
                />
              </StyledMenuItem>
              <StyledMenuItem>
                <StatusButton
                  title="cancel"
                  width="70px"
                  contract_id={contract_id}
                  handleClick={clickModify}
                />
              </StyledMenuItem>
            </StyledMenu>
          </>
        )}
      </>
    );
  }
};
