import React, { useState, useEffect } from "react";
import { FunctionalButton } from 'components/functionalButton';
import PledgxLogo from "assets/common/pledgxLogo";
import { useHistory } from "react-router-dom";
import Input from "components/inputField";
import { ReactComponent as RedRectanglurLine } from "pages/underwriters/components/contractStatusReportSending/assets/redRectanglurLine.svg";
import Select from "components/selectField";
import { formatPhoneNumber } from "common/formatPhoneNumber";
import { handleCommaFormat, numberWithCommas, removeComma } from "common/numberFormat";
import { SubmitStatusReportIssuing } from "./submitStatusReportIssuing"
import { RedirectStatusReportIssuing } from "./redirectStatusReportIssuing"
import { SubmitErrorMessage } from "./submitErrorMessage"
import { sendContractStatusReport } from 'api/external_data';
import { useParams } from "react-router";
import PylonIcon from "assets/common/pylonIcon.png";
import { RadioButton } from "components/radioButton";
import CheckedRadioButton from "assets/dashboardIcons/checkedRadioButton";
import UncheckedRadioButton from "assets/dashboardIcons/uncheckedRadioButton";
import { NumberFormatOnly, NumberFormatWithCurrency } from 'components/locale/numberFormatOnly'
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import './styles.css';

import {
  ErrorDiv,
  StyledPylon,
  ErrorText,
  FormTitle,
  TitleDiv,
  ButtonDiv,
  StyledMainCSR,
  StyledLeftCSR,
  StyledRightCSR,
  TextTitle,
  LabelText,
  InputText,
  InputSelect,
  ScreenRoot,
  FormContainer,
  FieldArea,
  StyledLeftCSRLeft,
  StyledRightCSRRight,
  StyledLeftCSRLeftLeft,
  StyledRightCSRRightRight,
  StyledMainCSRRight,
  StyledMainCSRLeft,
  StyledTextField,
  StyledLeftCSRLeftCompletedDate,
  StyledEditRecordButton,
  StyledIsProgressSatisfactory,
  StyledRightCSRRemarks,

} from "./styles";


function formatDate(bondDate) {
  let formattedEffectiveDate = "";

  if (bondDate !== "") {
    const dateString = bondDate;
    const dateObject = new Date(dateString);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear().toString();
    formattedEffectiveDate = `${month}/${day}/${year}`;
  }

  return formattedEffectiveDate;
}


function ContractStatusReportIssuing({
  isMobile
}) {
  const [data, setData] = useState({});
  let history = useHistory();
  const { token } = useParams();
  const [valid, setValid] = useState(false);
  const [error, setError] = useState("");
  const [addData, setAddData] = useState({});
  const [issuer, setIssuer] = useState("");
  const [issuerRole, setIssuerRole] = useState("");
  const [issuerEmail, setIssuerEmail] = useState("");
  const [issuerOldEmail, setIssuerOldEmail] = useState("");
  const [status, setStatus] = useState("");
  const [issuerPhoneNumber, setIssuerPhoneNumber] = useState("");
  const [currentContractPrice, setCurrentContractPrice] = useState("");
  const [currentContractPricePercent, setCurrentContractPricePercent] = useState("");
  const [orginalProjectPrice, setOrginalProjectPrice] = useState("");
  const [holdbackAmount, setHoldbackAmount] = useState("");
  const [holdbackAmountPercent, setHoldbackAmountPercent] = useState("");
  const [isProgressSatisfactory, setIsProgressSatisfactory] = useState("");
  const [paidToContractorAmount, setPaidToContractorAmount] = useState("");
  const [paidToContractorAmountPercent, setPaidToContractorAmountPercent] = useState("");
  const [workApprovedAmount, setWorkApprovedAmount] = useState("");
  const [workApprovedAmountPercent, setWorkApprovedAmountPercent] = useState("");
  const [anticipatedCompletionDate, setAnticipatedCompletionDate] = useState("");
  const [reportingDate, setReportingDate] = useState("");
  const [openSubmitStatusReportPopup, setOpenSubmitStatusReportPopup] = useState(false);
  const [openRedirectStatusReportPopup, setOpenRedirectStatusReportPopup] = useState(false);
  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [projectStatus, setProjectStatus] = useState("");
  const [containProgessSatisfactory, setContainProgessSatisfactory] = useState(false);
  const [notContainProgessSatisfactory, setNotContainProgessSatisfactory] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [errors, setErrors] = useState([]);


  const projectStatusOptions = [
    "Underway",
    "Completed",
  ];

  const handleProjectStatus = (val) => {
    setProjectStatus(val);
  };


  const confirmProgessSatisfactoryChange = (val) => {
    if (val === 1) {
      setContainProgessSatisfactory(true);
      setNotContainProgessSatisfactory(false);
      setIsProgressSatisfactory(true);

    } else {
      setNotContainProgessSatisfactory(true);
      setContainProgessSatisfactory(false);
      setIsProgressSatisfactory(false);
    }
  };


  useEffect(() => {
    sendContractStatusReport(
      token,
      (res) => {
        setData(res);
        setValid(true);
        setStatus(res[0]?.status);
        setIssuer(res[0]?.issuer_name);
        setOrginalProjectPrice(res[0]?.project_actual_value);
        setIssuerRole(res[0]?.issuer_role);
        setIssuerEmail(res[0]?.issuer_email);
        setIssuerOldEmail(res[0]?.issuer_email);
        setIssuerPhoneNumber(res[0]?.issuer_phone_number);
        setCurrentContractPrice(numberWithCommas(Math.trunc(res[0]?.current_contract_price)));
        setCurrentContractPricePercent(res[0]?.current_contract_price_percent);
        setHoldbackAmount(numberWithCommas(Math.trunc(res[0]?.holdback_amount)));
        setHoldbackAmountPercent(res[0]?.holdback_amount_percent);
        setIsProgressSatisfactory(res[0]?.is_progress_satisfactory);
        setPaidToContractorAmount(numberWithCommas(Math.trunc(res[0]?.paid_to_contractor_amount)));
        setPaidToContractorAmountPercent(res[0]?.paid_to_contractor_amount_percent);
        setWorkApprovedAmount(numberWithCommas(Math.trunc(res[0]?.work_approved_amount)));
        setWorkApprovedAmountPercent(res[0]?.work_approved_amount_percent);
        setAnticipatedCompletionDate("");
        setReportingDate(formatDate(new Date().toLocaleDateString('en-US')));
        setProjectStatus(res[0]?.project_status);
        setRemarks(res[0]?.remarks);
      },
      (err) => { setValid(false); setError(err.data.message); }
    )
  }, [])


  useEffect(() => {
    const updatedData = { ...addData };
    updatedData["issuer"] = issuer;
    updatedData["issuer_role"] = issuerRole;
    updatedData["issuer_email"] = issuerEmail;
    updatedData["issuer_phone"] = issuerPhoneNumber;
    updatedData["current_contract_price"] = currentContractPrice ? removeComma(currentContractPrice) : 0;
    updatedData["current_contract_price_percent"] = currentContractPricePercent ? currentContractPricePercent : 0;
    updatedData["holdback_amount"] = holdbackAmount ? removeComma(holdbackAmount) : 0;
    updatedData["holdback_amount_percent"] = holdbackAmountPercent ? holdbackAmountPercent : 0;
    updatedData["is_progress_satisfactory"] = isProgressSatisfactory;
    updatedData["paid_to_contractor_amount"] = paidToContractorAmount ? removeComma(paidToContractorAmount) : 0;
    updatedData["paid_to_contractor_amount_percent"] = paidToContractorAmountPercent ? paidToContractorAmountPercent : 0;
    updatedData["work_approved_amount"] = workApprovedAmount ? removeComma(workApprovedAmount) : 0;
    updatedData["work_approved_amount_percent"] = workApprovedAmountPercent ? workApprovedAmountPercent : 0;
    updatedData["anticipated_completion_date"] = anticipatedCompletionDate;
    updatedData["reporting_date"] = reportingDate;
    updatedData["remarks"] = remarks ? remarks : "";
    updatedData["project_status"] = projectStatus ? projectStatus : "";
    setAddData(updatedData);
  }, [
    issuer,
    issuerRole,
    issuerEmail,
    issuerPhoneNumber,
    currentContractPrice,
    currentContractPricePercent,
    holdbackAmount,
    holdbackAmountPercent,
    isProgressSatisfactory,
    paidToContractorAmount,
    paidToContractorAmountPercent,
    workApprovedAmount,
    workApprovedAmountPercent,
    anticipatedCompletionDate,
    reportingDate,
    remarks
  ]);


  const handleSetRemarks = (event) => {
    setRemarks(event.target.value);
  };


  const handleGoBack = () => {
    history.goBack();
  };

  const handleRedirectStatus = () => {

    const contractor_name = data[0]?.contractor_name ? data[0]?.contractor_name : ""
    const project_title = data[0]?.project_title ? data[0]?.project_title : ""
    const requseter_name = data[0]?.requseter_name ? data[0]?.requseter_name : ""

    const newData = { ...addData, redirect_status: true, old_issuer_email: issuerOldEmail, token_no: token, contractor_name: contractor_name, project_name: project_title, underwiter_name: requseter_name };
    const errors = []

    if (issuerOldEmail === issuerEmail) {
      errors.push("Your email and the redirect's email are same, please change the email address.");
    }

    if (errors.length > 0) {
      setErrors(errors);
      setOpenErrorPopup(true);
    } else {
      setAddData(newData);
      setOpenRedirectStatusReportPopup(true);
    }

  };

  const handleSendingStatus = () => {
    const company_name = data[0]?.company_name ? data[0]?.company_name : ""
    const company_address = data[0]?.company_address ? data[0]?.company_address : ""
    const contractor_name = data[0]?.contractor_name ? data[0]?.contractor_name : ""
    const project_title = data[0]?.project_title ? data[0]?.project_title : ""
    const project_actual_value = data[0]?.project_actual_value ? data[0]?.project_actual_value : ""
    const project_id = data[0]?.project_id ? data[0]?.project_id : ""
    const requseter_name = data[0]?.requseter_name ? data[0]?.requseter_name : ""
    const requseter_role = data[0]?.requseter_role ? data[0]?.requseter_role : ""
    const requseter_email = data[0]?.requseter_email ? data[0]?.requseter_email : ""
    const requseter_phone_number = data[0]?.requseter_phone_number ? data[0]?.requseter_phone_number : ""
    const account_id = data[0]?.account_id ? data[0]?.account_id : ""
    const bond_id = data[1]?.bond_id ? data[1]?.bond_id : ""
    const effective_date = data[1]?.effective_date ? data[1]?.effective_date : ""
    const obligee = data[1]?.obligee ? data[1]?.obligee : ""
    const newData = { ...addData, account_id: account_id, redirect_status: false, requseter_name: requseter_name, requseter_role: requseter_role, requseter_email: requseter_email, requseter_phone_number: requseter_phone_number, bond_id: bond_id, effective_date: effective_date, obligee: obligee, project_id: project_id, project_title: project_title, contractor_name: contractor_name, company_address: company_address, company_name: company_name, project_actual_value: project_actual_value };

    const checkData = addData
    const errors = []

    if (projectStatus === "Completed") {

      if (
        checkData.current_contract_price === 0 &&
        checkData.current_contract_price_percent === 0
      ) {
        errors.push("Final Contract Price($) or (%) is empty");
      }

      if (checkData.anticipated_completion_date === "") {
        errors.push("Completion date field is empty");
      }

    } else {
      if (checkData.anticipated_completion_date === "") {
        errors.push("Anticipated completion date field is empty");
      }

      if (
        checkData.current_contract_price === 0 &&
        checkData.current_contract_price_percent === 0
      ) {
        errors.push("Current Contract Price($) or % field is empty");
      }

      if (
        checkData.holdback_amount === 0 &&
        checkData.holdback_amount_percent === 0 &&
        checkData.paid_to_contractor_amount === 0 &&
        checkData.paid_to_contractor_amount_percent === 0
      ) {
        errors.push("Holdback and Paid to Contractor Amounts($) or Percents(%) fields are empty");
      }

      if (checkData.work_approved_amount === 0 && checkData.work_approved_amount_percent === 0) {
        errors.push("Work approved amount($) or % field is empty");
      }

    }

    // Check issuer
    if (checkData.issuer === "") {
      errors.push("Issuer field is empty");
    }

    // Check issuer_role
    if (checkData.issuer_role === "") {
      errors.push("Issuer role field is empty");
    }

    // Check issuer_email
    if (checkData.issuer_email === "") {
      errors.push("Issuer email field is empty");
    }

    // Check issuer_phone
    if (checkData.issuer_phone === "") {
      errors.push("Issuer phone field is empty");
    }

    // Check is_progress_satisfactory
    if (checkData.is_progress_satisfactory === null) {
      errors.push("Progress satisfactory field is empty");
    }

    // Check remarks if progress is unsatisfactory
    if (checkData.is_progress_satisfactory === false && checkData.remarks === "") {
      errors.push("Remarks field is empty for unsatisfactory progress");
    }

    if (errors.length > 0) {
      setErrors(errors);
      setOpenErrorPopup(true);
    } else {
      setAddData(newData);
      setOpenSubmitStatusReportPopup(true);
    }

  };


  return (
    <ScreenRoot isMobile={isMobile}>
      {valid && (
        <>
          <FormContainer isMobile={isMobile}>
            <TitleDiv>
              <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
              <FormTitle style={{ marginTop: 10 }}>
                {"Contract Status Report"}
              </FormTitle>
            </TitleDiv>

            <FieldArea width={isMobile ? "100%" : "100%"}>
              <StyledMainCSR>
                <StyledLeftCSR>
                  <TextTitle>Bonding Company Details</TextTitle>
                  <Input
                    label="Company Name"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="0px"
                    backgroundColor="#EBE9F1"
                    value={data[0]?.company_name || ""}
                    disabled
                  />
                  <Input
                    label="Company Address"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    backgroundColor="#EBE9F1"
                    value={data[0]?.company_address || ""}
                    disabled
                  />

                  <Input
                    label="Contractor Name"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    backgroundColor="#EBE9F1"
                    value={data[0]?.contractor_name || ""}
                    disabled
                  />
                  <div style={{ marginTop: "18px" }}></div>
                  <TextTitle>Bond Details</TextTitle>

                  <Input
                    label="Bond Id"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="0px"
                    backgroundColor="#EBE9F1"
                    value={data[0]?.bond_id || ""}
                    disabled
                  />

                  <div style={{ marginTop: "18px" }}></div>
                  <StyledMainCSR>
                    <StyledLeftCSRLeft>
                      <Input
                        label="Effective Date"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="0px"
                        backgroundColor="#EBE9F1"
                        value={formatDate(data[1]?.effective_date) || ""}
                        disabled
                      />
                    </StyledLeftCSRLeft>

                    <StyledRightCSRRight>
                      <Input
                        label="Obligee"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="0px"
                        backgroundColor="#EBE9F1"
                        value={data[1]?.obligee || ""}
                        disabled
                      />
                    </StyledRightCSRRight>
                  </StyledMainCSR>
                </StyledLeftCSR>

                <StyledRightCSR>
                  <TextTitle>Project Details</TextTitle>
                  <Input
                    label="Project Title"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="0px"
                    backgroundColor="#EBE9F1"
                    value={data[0]?.project_title || ""}
                    disabled
                  />

                  <Input
                    label="Orginal Contract Price($)"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    backgroundColor="#EBE9F1"
                    value={NumberFormatOnly(data[0]?.project_actual_value) || ""}
                    disabled
                  />

                  <div style={{ marginTop: '-10px' }}>
                    <Select
                      label="Project Status"
                      options={projectStatusOptions.map((item) => ({
                        value: item,
                        label: item,
                      }))}
                      value={projectStatus}
                      setValue={(val) => handleProjectStatus(val)}
                      validateFunc={(item) => Boolean(item)}
                      noCheckIcon={true}
                      showDropdownIndicator={true}

                    />

                  </div>
                  <div style={{ marginTop: "18px" }}></div>
                  <TextTitle>Report Details</TextTitle>
                  {projectStatus === "Underway" && (
                    <>
                      <StyledMainCSR>
                        <StyledLeftCSRLeft>
                          <Input
                            label="Anticipated Completion Date"
                            type="text"
                            labelSize="12px"
                            fontSize="18px"
                            marginTop="0px"
                            paddingRight="5px"
                            paddingLeft="5px"
                            value={anticipatedCompletionDate}
                            setValue={(value) => setAnticipatedCompletionDate(value)}
                          />
                        </StyledLeftCSRLeft>

                        <StyledRightCSRRight>
                          <Input
                            label="Reporting Date"
                            type="text"
                            labelSize="12px"
                            fontSize="18px"
                            marginTop="0px"
                            paddingRight="5px"
                            paddingLeft="5px"
                            value={reportingDate}
                            setValue={(value) => setReportingDate(value)}
                          />
                        </StyledRightCSRRight>
                      </StyledMainCSR>

                      <StyledMainCSR>
                        <StyledMainCSRLeft>
                          <StyledLeftCSRLeftLeft>
                            <Input
                              label="Current Contract Price($)"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              value={currentContractPrice}
                              setValue={(value) => {
                                const currentContractValuePercent = (value * 100) / removeComma(orginalProjectPrice);
                                setCurrentContractPrice(value.toLocaleString());
                                setCurrentContractPricePercent(currentContractValuePercent);
                              }}
                              onBlur={(e) => setCurrentContractPrice(handleCommaFormat(e.target.value))}
                            />
                          </StyledLeftCSRLeftLeft>
                          <StyledRightCSRRightRight>
                            <Input
                              label="%"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              value={currentContractPricePercent}
                              setValue={(value) => {
                                const currentContractValue = (value * removeComma(orginalProjectPrice)) / 100;
                                setCurrentContractPricePercent(value);
                                setCurrentContractPrice(numberWithCommas(currentContractValue));
                              }}
                            />
                          </StyledRightCSRRightRight>
                        </StyledMainCSRLeft>

                        <StyledMainCSRRight>
                          <StyledLeftCSRLeftLeft>
                            <Input
                              label="Work Approved Amount($)"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              value={workApprovedAmount}
                              setValue={(value) => {
                                const currentAprrovedValuePercent = (value * 100) / removeComma(currentContractPrice);
                                setWorkApprovedAmount(value.toLocaleString());
                                setWorkApprovedAmountPercent(currentAprrovedValuePercent);
                              }}
                              onBlur={(e) => setWorkApprovedAmount(handleCommaFormat(e.target.value))}
                            />
                          </StyledLeftCSRLeftLeft>
                          <StyledRightCSRRightRight>
                            <Input
                              label="%"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              value={workApprovedAmountPercent}
                              setValue={(value) => {
                                const currentAprrovedValue = (value * removeComma(currentContractPrice)) / 100;
                                setWorkApprovedAmountPercent(value);
                                setWorkApprovedAmount(numberWithCommas(currentAprrovedValue));
                              }}
                            />
                          </StyledRightCSRRightRight>
                        </StyledMainCSRRight>
                      </StyledMainCSR>


                      <StyledMainCSR>
                        <StyledMainCSRLeft>
                          <StyledLeftCSRLeftLeft>
                            <Input
                              label="Paid to Contractor Amount($)"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              value={paidToContractorAmount}
                              setValue={(value) => {
                                const currentPaidValuePercent = (value * 100) / removeComma(workApprovedAmount);
                                setPaidToContractorAmount(value.toLocaleString());
                                setPaidToContractorAmountPercent(currentPaidValuePercent);
                                const restAmount = removeComma(workApprovedAmount) - value;
                                const restPercent = 100 - currentPaidValuePercent;
                                setHoldbackAmount(restAmount.toLocaleString());
                                setHoldbackAmountPercent(restPercent);
                              }}
                              onBlur={(e) => setPaidToContractorAmount(handleCommaFormat(e.target.value))}
                            />
                          </StyledLeftCSRLeftLeft>
                          <StyledRightCSRRightRight>
                            <Input
                              label="%"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              value={paidToContractorAmountPercent}
                              setValue={(value) => {
                                const currentPaidValue = (value * removeComma(workApprovedAmount)) / 100;
                                setPaidToContractorAmountPercent(value);
                                setPaidToContractorAmount(numberWithCommas(currentPaidValue));
                                const restAmount = removeComma(workApprovedAmount) - currentPaidValue;
                                const restPercent = 100 - value;
                                setHoldbackAmount(restAmount.toLocaleString());
                                setHoldbackAmountPercent(restPercent);
                              }}
                            />
                          </StyledRightCSRRightRight>
                        </StyledMainCSRLeft>

                        <StyledMainCSRRight>
                          <StyledLeftCSRLeftLeft>
                            <Input
                              label="Holdback Amount($)"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              value={holdbackAmount}
                              setValue={(value) => {
                                const currentHoldBackValuePercent = (value * 100) / removeComma(workApprovedAmount);
                                setHoldbackAmount(value.toLocaleString());
                                setHoldbackAmountPercent(currentHoldBackValuePercent);
                                const restAmount = removeComma(workApprovedAmount) - value;
                                const restPercent = 100 - currentHoldBackValuePercent;
                                setPaidToContractorAmount(restAmount.toLocaleString());
                                setPaidToContractorAmountPercent(restPercent);
                              }}
                              onBlur={(e) => setHoldbackAmount(handleCommaFormat(e.target.value))}
                            />
                          </StyledLeftCSRLeftLeft>
                          <StyledRightCSRRightRight>
                            <Input
                              label="%"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              value={holdbackAmountPercent}
                              setValue={(value) => {
                                const currentHoldBackValue = (value * removeComma(workApprovedAmount)) / 100;
                                setHoldbackAmountPercent(value);
                                setHoldbackAmount(numberWithCommas(currentHoldBackValue));
                                const restAmount = removeComma(workApprovedAmount) - removeComma(currentHoldBackValue);
                                const restPercent = 100 - value;
                                setPaidToContractorAmount(restAmount.toLocaleString());
                                setPaidToContractorAmountPercent(restPercent);
                              }}
                            />
                          </StyledRightCSRRightRight>
                        </StyledMainCSRRight>
                      </StyledMainCSR>
                    </>
                  )}

                  {projectStatus === "Completed" && (
                    <>
                      <StyledMainCSR>
                        <StyledMainCSRLeft>
                          <StyledLeftCSRLeftCompletedDate>
                            <Input
                              label="Completion Date"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="0px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              value={anticipatedCompletionDate}
                              setValue={(value) => setAnticipatedCompletionDate(value)}
                            />
                          </StyledLeftCSRLeftCompletedDate>
                        </StyledMainCSRLeft>

                        <StyledMainCSRRight>
                          <StyledLeftCSRLeftLeft>
                            <Input
                              label="Final Contract Price($)"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="0px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              value={currentContractPrice}
                              setValue={(value) => {
                                const currentContractValuePercent = (value * 100) / removeComma(orginalProjectPrice);
                                setCurrentContractPrice(value.toLocaleString());
                                setCurrentContractPricePercent(currentContractValuePercent);
                              }}
                              onBlur={(e) => setCurrentContractPrice(handleCommaFormat(e.target.value))}
                            />

                          </StyledLeftCSRLeftLeft>
                          <StyledRightCSRRightRight>
                            <Input
                              label="%"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="0px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              value={currentContractPricePercent}
                              setValue={(value) => {
                                const currentContractValue = (value * removeComma(orginalProjectPrice)) / 100;
                                setCurrentContractPricePercent(value);
                                setCurrentContractPrice(numberWithCommas(currentContractValue));
                              }}
                            />
                          </StyledRightCSRRightRight>
                        </StyledMainCSRRight>
                      </StyledMainCSR>



                    </>
                  )}

                  <StyledMainCSR>
                    <StyledIsProgressSatisfactory>
                      <LabelText style={{ marginTop: "16px" }}>Is Progress Satisfactory?</LabelText>
                      <StyledEditRecordButton style={{ display: 'flex', flexDirection: 'column', marginLeft: '-160px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-60px', marginTop: '30px' }}>
                          <RadioButton
                            isChecked={
                              containProgessSatisfactory === true && notContainProgessSatisfactory === false
                                ? true
                                : false
                            }
                            value={containProgessSatisfactory}
                            checkedIcon={<CheckedRadioButton />}
                            unCheckedIcon={<UncheckedRadioButton />}
                            handleChange={() => confirmProgessSatisfactoryChange(1)}
                          />
                          <span style={{ marginLeft: '5px' }}>Yes</span>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-62px' }}>
                          <RadioButton
                            isChecked={
                              notContainProgessSatisfactory === true && containProgessSatisfactory === false
                                ? true
                                : false
                            }
                            value={notContainProgessSatisfactory}
                            checkedIcon={<CheckedRadioButton />}
                            unCheckedIcon={<UncheckedRadioButton />}
                            handleChange={() => confirmProgessSatisfactoryChange(0)}
                          />
                          <span style={{ marginLeft: '5px' }}>No</span>
                        </div>
                      </StyledEditRecordButton>
                    </StyledIsProgressSatisfactory>

                    <StyledRightCSRRemarks>
                      <LabelText style={{ marginTop: "15px" }}> Remarks </LabelText>
                      <StyledTextField
                        multiline
                        rows={5}
                        placeholder={"Remark"}
                        variant="outlined"
                        value={remarks}
                        onChange={handleSetRemarks}
                      />
                    </StyledRightCSRRemarks>
                  </StyledMainCSR>

                </StyledRightCSR>
              </StyledMainCSR>

              <StyledMainCSR>
                <StyledLeftCSR>
                  <div style={{ marginTop: '48px' }}> </div>
                  <RedRectanglurLine />
                </StyledLeftCSR>

                <StyledRightCSR>
                  <div style={{ marginTop: '48px' }}> </div>
                  <RedRectanglurLine />
                </StyledRightCSR>
              </StyledMainCSR>

              <StyledMainCSR>
                <StyledLeftCSR>
                  <TextTitle>Report Requested by</TextTitle>

                  <StyledMainCSR>
                    <StyledLeftCSRLeft>
                      <Input
                        label="Name"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        paddingRight="5px"
                        paddingLeft="5px"
                        backgroundColor="#EBE9F1"
                        value={data[0]?.requseter_name || ""}
                        disabled
                      />
                    </StyledLeftCSRLeft>

                    <StyledRightCSRRight>
                      <Input
                        label="Role"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        paddingRight="5px"
                        paddingLeft="5px"
                        backgroundColor="#EBE9F1"
                        value={data[0]?.requseter_role || ""}
                        disabled
                      />
                    </StyledRightCSRRight>
                  </StyledMainCSR>

                  <StyledMainCSR>
                    <StyledLeftCSRLeft>
                      <Input
                        label="Email"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        paddingRight="5px"
                        paddingLeft="5px"
                        backgroundColor="#EBE9F1"
                        value={data[0]?.requseter_email || ""}
                        disabled
                      />
                    </StyledLeftCSRLeft>

                    <StyledRightCSRRight>
                      <div style={{ marginTop: '16px', width: "100%", marginLeft: '0px', marginRight: '40px' }}>
                        <label htmlFor="phone" style={{ fontSize: '12px', marginLeft: '0px' }}>Phone Number</label>
                        <div style={{ height: '47px', width: "100%", marginTop: '-10px', marginLeft: '0px' }}>
                          <PhoneInput
                            id="phone"
                            country={'ca'}
                            value={data[0]?.requseter_phone_number || ""}
                            inputStyle={{ fontSize: '18px', padding: '8px', lineHeight: '24px', marginLeft: '20px', paddingLeft: '28px', height: '100%', width: '91%', backgroundColor: '#EBE9F1' }}
                            dropdownStyle={{ fontSize: '18px', lineHeight: '24px', backgroundColor: '#EBE9F1' }}
                            className="custom-phone-input"
                            disabled
                          />
                        </div>
                      </div>
                    </StyledRightCSRRight>
                  </StyledMainCSR>
                </StyledLeftCSR>

                <StyledRightCSR>
                  <TextTitle>Report Issued by</TextTitle>
                  <StyledMainCSR>
                    <StyledLeftCSRLeft>
                      <Input
                        label="Name"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        paddingRight="5px"
                        paddingLeft="5px"
                        value={issuer}
                        setValue={(value) => setIssuer(value)}
                      />
                    </StyledLeftCSRLeft>

                    <StyledRightCSRRight>
                      <Input
                        label="Role"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        paddingRight="5px"
                        paddingLeft="5px"
                        value={issuerRole}
                        setValue={(value) => setIssuerRole(value)}
                      />
                    </StyledRightCSRRight>
                  </StyledMainCSR>

                  <StyledMainCSR>
                    <StyledLeftCSRLeft>
                      <Input
                        label="Email"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        paddingRight="5px"
                        paddingLeft="5px"
                        value={issuerEmail}
                        setValue={(value) => setIssuerEmail(value)}
                      />
                    </StyledLeftCSRLeft>

                    <StyledRightCSRRight>

                      <div style={{ marginTop: '16px', width: "100%", marginLeft: '0px', marginRight: '40px' }}>
                        <label htmlFor="phone" style={{ fontSize: '12px', marginLeft: '0px' }}>Phone Number</label>
                        <div style={{ height: '47px', width: "100%", marginTop: '-10px', marginLeft: '0px' }}>
                          <PhoneInput
                            id="phone"
                            country={'ca'}
                            value={issuerPhoneNumber}
                            onChange={(value) => setIssuerPhoneNumber(value)}
                            inputStyle={{ fontSize: '18px', padding: '8px', lineHeight: '24px', marginLeft: '20px', paddingLeft: '28px', height: '100%', width: '91%' }}
                            dropdownStyle={{ fontSize: '18px', lineHeight: '24px' }}
                            className="custom-phone-input"
                          />
                        </div>
                      </div>
                    </StyledRightCSRRight>
                  </StyledMainCSR>

                </StyledRightCSR>
              </StyledMainCSR>
            </FieldArea>

            <ButtonDiv isMobile={isMobile}>
              <FunctionalButton
                buttonTitle="GO BACK"
                handleButton={handleGoBack}
                isReversedColor={true}
                height="48px"
                width="199px"
                marginTop="64px"
                marginRight="16px"
              />

              {/* {status && status !== 'redirected' && ( */}
                <FunctionalButton
                  buttonTitle={"REDIRECT TO"}
                  height="48px"
                  width="199px"
                  marginTop="64px"
                  marginRight="16px"
                  isReversedColor={true}
                  handleButton={() => handleRedirectStatus()}
                />
              {/* )} */}


              <FunctionalButton
                buttonTitle={"SUBMIT"}
                height="48px"
                width="199px"
                marginTop="64px"
                marginRight="0px"
                handleButton={() => handleSendingStatus()}
              />
            </ButtonDiv>
          </FormContainer>

          <SubmitStatusReportIssuing
            isMobile={isMobile}
            data={addData}
            openPopup={openSubmitStatusReportPopup}
            setOpenPopup={setOpenSubmitStatusReportPopup}
          />

          <RedirectStatusReportIssuing
            isMobile={isMobile}
            data={addData}
            openPopup={openRedirectStatusReportPopup}
            setOpenPopup={setOpenRedirectStatusReportPopup}
          />

          <SubmitErrorMessage
            isMobile={isMobile}
            errors={errors}
            openPopup={openErrorPopup}
            setOpenPopup={setOpenErrorPopup}
          />
        </>
      )}
      {!valid && (
        <ErrorDiv isMobile={isMobile}>
          <StyledPylon src={PylonIcon} isMobile={isMobile} />
          <ErrorText isMobile={isMobile}>{error}</ErrorText>
        </ErrorDiv>
      )}
    </ScreenRoot >

  )
}

export default ContractStatusReportIssuing;
