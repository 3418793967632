import React, { useEffect, useState } from "react";
import {
  StyledLargeTitle,
  StyledMediumTitle,
  ProjectCardContainer,
} from "../../../preQualifyForm/styles";

import { SelectingProjects } from "./components/selectingProjects";
import { SelectedProjects } from "./components/selectedProjects";
import { AddProjectPopup } from "components/addProjectPopup";
import { filterProjects } from "api/prequalify";

export const ResultPanel = ({
  projects,
  setPanel,
  setAddData,
  setProjects,
  setEditIndex,
  setOpenEdit,
  setOpenPopup,
  openPopup,
  institutionId,
}) => {
  const [individual, setIndividual] = useState("");
  const [pickedArray, setPickedArray] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const addProject = (data) => {
    const individualTemp = data;
    let tempData = individualTemp;
    if (tempData?.project_status === "Underway") {
      tempData = { type: ["underway"], ...tempData };
    } else if (tempData?.project_status === "Completed") {
      tempData = { type: ["key"], ...tempData };
    }

    const temp = [tempData, ...projects];
    setProjects(temp);
  };

  const handleFilter = async () => {
    await filterProjects("", { institution_id: institutionId })
      .then((data) => {
        setFilteredData(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    handleFilter();
  }, []);
  return (
    <>
      <StyledLargeTitle>
        Which Projects do you want to showcase?
      </StyledLargeTitle>
      <SelectedProjects
        projects={projects}
        setProjects={setProjects}
        pickedArray={pickedArray}
        setPickedArray={setPickedArray}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
      />
      <SelectingProjects
        add={() => setPanel("add")}
        projects={projects}
        setProjects={setProjects}
        setOpenPopup={setOpenPopup}
        pickedArray={pickedArray}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
      />
      <AddProjectPopup
        setOpenPopup={setOpenPopup}
        openPopup={openPopup}
        data={individual}
        setData={setIndividual}
        handleButtonFunc={addProject}
        institution_id={institutionId}
      />
    </>
  );
};
