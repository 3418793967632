import React from "react";
import Input from "../../../../components/inputField";

export const YearsNumber = ({ data, setData, type, year }) => {
  return (
    <>
      <Input
        label=""
        type="text"
        value={
          data[`${year}`] &&
          (data[`${year}`][`${type}`] || data[`${year}`][`${type}`] === 0)
            ? data[`${year}`][`${type}`]
            : ""
        }
        marginTop="10px"
        labelSize="10px"
        setValue={(val) => setData(`safety_records.${year}.${type}`, val)}
        validateFunc={(item) => String(item).length > 0}
        noCheckIcon={true}
        noCancelIcon={true}
        noErrorBorder={true}
      />
      <Input
        label=""
        type="text"
        value={
          data[`${year - 1}`] &&
          (data[`${year - 1}`][`${type}`] ||
            data[`${year - 1}`][`${type}`] === 0)
            ? data[`${year - 1}`][`${type}`]
            : ""
        }
        marginTop="10px"
        labelSize="10px"
        setValue={(val) => setData(`safety_records.${year - 1}.${type}`, val)}
        validateFunc={(item) => String(item).length > 0}
        noCheckIcon={true}
        noCancelIcon={true}
        noErrorBorder={true}
      />
      <Input
        label=""
        type="text"
        value={
          data[`${year - 2}`] &&
          (data[`${year - 2}`][`${type}`] ||
            data[`${year - 2}`][`${type}`] === 0)
            ? data[`${year - 2}`][`${type}`]
            : ""
        }
        marginTop="10px"
        labelSize="10px"
        setValue={(val) => setData(`safety_records.${year - 2}.${type}`, val)}
        validateFunc={(item) => String(item).length > 0}
        noCheckIcon={true}
        noCancelIcon={true}
        noErrorBorder={true}
      />
    </>
  );
};
