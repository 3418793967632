import React, { useState, useEffect } from "react";
import { Popup } from 'components/popup';
import { Steps } from './steps';

export const AddProjectPopupT = ({
  setOpenPopup,
  openPopup,
  data,
  setData,
  isMobile,
  handleButtonFunc,
  handleNext
 }) => {
  return(
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width="716px"
      height="661px"
      dialogContent={(
        <Steps
          isMobile={isMobile}
          setOpenPopup={setOpenPopup}
          onboardingData={data}
          setOnboardingData={setData}
          handleButtonFunc={handleButtonFunc}
          handleNext={handleNext}
        />
      )}
    />
  )
 }
