import React from 'react';


const DownloadIcon = ({ fill, height }) => {
  return (
    <svg width={height || '14'} height={height || '14'} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.4615 7.83984C13.1641 7.83984 12.9231 8.08092 12.9231 8.37831V11.6091C12.9231 11.9065 12.682 12.1476 12.3846 12.1476H1.61541C1.31802 12.1476 1.07694 11.9065 1.07694 11.6091V8.37831C1.07694 8.08092 0.835866 7.83984 0.538471 7.83984C0.241077 7.83984 0 8.08092 0 8.37831V11.6091C0 12.5012 0.72323 13.2245 1.61538 13.2245H12.3846C13.2768 13.2245 14 12.5012 14 11.6091V8.37831C14 8.08092 13.7589 7.83984 13.4615 7.83984Z" fill={fill || "#FF6D1D"}/>
      <path d="M10.0604 7.45827C9.85171 7.25675 9.52091 7.25675 9.31227 7.45827L7.53803 9.2314V1.32363C7.53803 1.02623 7.29695 0.785156 6.99956 0.785156C6.70216 0.785156 6.46109 1.02623 6.46109 1.32363V9.23143L4.68792 7.45827C4.47401 7.25168 4.13314 7.25761 3.92655 7.47149C3.725 7.68016 3.725 8.01097 3.92655 8.21964L6.61884 10.9119C6.82887 11.1225 7.1698 11.1229 7.38034 10.9129L7.38132 10.9119L10.0736 8.21964C10.2802 8.00573 10.2743 7.66486 10.0604 7.45827Z" fill={fill || "#FF6D1D"}/>
    </svg>
  );
};

export default DownloadIcon;
