import React from "react";
import { FormBuilder } from "../../components/formBuilder";

import formDetails from "./forms/formDetails.json";
import formFields from "./forms/formFields.json";
import formSetup from "./forms/formSetup.json";

function SubmissionsTableForm({
  contractorChange,
  formLoading,
  interactionClicked,
  popup,
  popupClicked,
  prequalifiedContractorCount = 0,
  readOnly,
  successPopup,
  submissions,
  submitForm,
  disabled,
}) {
  return (
    <FormBuilder
      changeData={(e) =>
        contractorChange({
          contractorId: e.payload.rowId,
          index: e.payload.index,
          selected: e.payload.value,
          selectedId: e.payload.selectedId,
        })
      }
      data={{
        submissionsList: submissions,
      }}
      formDetails={{
        ...formDetails,
        successPopup: {
          ...formDetails.successPopup,
          titleText:
            prequalifiedContractorCount > 0
              ? `The Contractor${
                  prequalifiedContractorCount > 1 ? "s are" : " is"
                } now Selected`
              : "You have removed all Selected contractors",
        },
      }}
      formFields={formFields}
      formSetup={formSetup}
      interactionLoading={formLoading}
      navigationType={null}
      onInteractionItemClicked={interactionClicked}
      onPopupInteraction={popupClicked}
      onSubmit={submitForm}
      popup={popup}
      readOnly={readOnly}
      disabled={disabled}
      successPopup={successPopup}
    />
  );
}

export default SubmissionsTableForm;

/*
institution_name
institution_email   union ? true : false   requirements    match_rating   Select    View
*/
