import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { logoutUser } from "../../data/actions/user";
import DropdownArrow from "../../assets/dashboardIcons/DropdownArrow";
import { LetterAvatar } from "../../components/letterAvatar";
import { useHistory } from "react-router-dom";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as password } from "../../assets/preQualifyIcons/password.svg";
import { userSelector } from "data/selectors/user";

import {
  UserName,
  AvatarePicture,
  NameArea,
  StyledMenu,
  StyledMenuItem,
  StyledIcon,
  StyledIconPassword,
  ProfileInfo,
  IconArea,
  VerticalLine,
} from "./styles";

import settings from "../../assets/dashboardIcons/settings.svg";
import toolIcon from "../../assets/dashboardIcons/tools.svg";
import report from "../../assets/dashboardIcons/report.svg";
import { TutorialBubble } from "components/tutorialBubble";
import { getContact } from "data/actions/contact";
import { contactSelector } from "data/selectors/contact";
import Tooltip from "@mui/material/Tooltip";
import { COLORS } from "../../constants";
import { ReportBugPopup } from "../reportBugPopup";
import { CommonSuccessPopup } from "components/commonSuccessPopup";
import { getConciergeServiceStatus } from "../../data/actions/user";

export const ProfileMenu = ({ isMobile }) => {
  const data = useSelector(contactSelector);
  const [profileData, setProfileData] = useState({ ...data });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const [firstTimePopup, setFirstTimePopup] = useState(false);
  const history = useHistory();
  const [openReportPopup, setOpenReportPopup] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const current_user = useSelector(userSelector);
  const currentUrl = localStorage.getItem("current_dashboard_url");
  const redirectUrl =
    current_user?.userType === "Agent/Broker" && !current_user?.staff
      ? "/service-provider-dashboard"
      : "/";

  useEffect(() => {
    dispatch(getContact());
  }, []);

  useEffect(() => {
    dispatch(getConciergeServiceStatus());
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setFirstTimePopup(data?.login_time && data?.login_time == 1);
    setProfileData({ ...data });
  }, [data]);

  return (
    <>
      {profileData?.status === "active" && (
        <>
          {!isMobile && (
            <>
              {(current_user?.userType === "Contractor & Buyer" ||
                (current_user?.userType === "Agent/Broker" &&
                  localStorage.getItem("current_agent_dashboard")) ||
                current_user?.staff) && (
                <Tooltip
                  title="Go to Toolbar"
                  placement="bottom-end"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontSize: 11,
                        bgcolor: `${COLORS.primaryOrange}`,
                        "& .MuiTooltip-arrow": {
                          color: `${COLORS.primaryOrange}`,
                        },
                      },
                    },
                  }}
                >
                  <IconArea>
                    <img
                      src={toolIcon}
                      onClick={() => history.push(redirectUrl || "/")}
                      alt=""
                    />
                  </IconArea>
                </Tooltip>
              )}

              <div style={{ position: "relative" }}>
                <Tooltip
                  title="Report a problem"
                  placement="bottom-end"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontSize: 11,
                        bgcolor: `${COLORS.primaryOrange}`,
                        "& .MuiTooltip-arrow": {
                          color: `${COLORS.primaryOrange}`,
                        },
                      },
                    },
                  }}
                >
                  <IconArea>
                    <img
                      src={report}
                      onClick={() => {
                        setOpenReportPopup(true);
                      }}
                      alt=""
                    />
                  </IconArea>
                </Tooltip>
                {firstTimePopup &&
                  !localStorage.getItem("SeenFirstTimeBubble") && (
                    <TutorialBubble
                      text={
                        "Run into an issue? Click here & fill out the form so we can fix it!"
                      }
                      handleButton={() => {
                        setFirstTimePopup(false);
                        localStorage.setItem("SeenFirstTimeBubble", true);
                      }}
                    />
                  )}
              </div>

              {currentUrl === "/contractor-dashboard" && (
                <Tooltip
                  title="Edit profile"
                  placement="bottom-end"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontSize: 11,
                        bgcolor: `${COLORS.primaryOrange}`,
                        "& .MuiTooltip-arrow": {
                          color: `${COLORS.primaryOrange}`,
                        },
                      },
                    },
                  }}
                >
                  <IconArea>
                    <img
                      src={settings}
                      onClick={() => history.push("/profile")}
                      alt=""
                    />
                  </IconArea>
                </Tooltip>
              )}

              <VerticalLine />
            </>
          )}
          <ProfileInfo isMobile={isMobile}>
            <NameArea onClick={handleClick}>
              {profileData?.avatar && (
                <AvatarePicture
                  src={`data:image/png;base64,${profileData?.avatar}`}
                  isMobile={isMobile}
                />
              )}
              {!profileData?.avatar && (
                <LetterAvatar
                  name={`${profileData?.first_name}${
                    profileData?.first_name && profileData?.last_name ? " " : ""
                  }${profileData?.last_name}`}
                />
              )}
              {!isMobile && (
                <>
                  <UserName>
                    {`${profileData?.first_name || ""}${
                      profileData?.first_name && profileData?.last_name
                        ? `, ${profileData?.last_name.charAt(0)}.`
                        : profileData?.last_name
                    }`}
                  </UserName>
                  <DropdownArrow />
                </>
              )}
            </NameArea>
            <StyledMenu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <StyledMenuItem onClick={() => history.push("/change-password")}>
                <StyledIconPassword>
                  <SvgIcon component={password} />
                </StyledIconPassword>
                {"Change Password"}
              </StyledMenuItem>

              <StyledMenuItem onClick={() => dispatch(logoutUser())}>
                <StyledIcon>
                  <ExitToAppOutlinedIcon />
                </StyledIcon>
                {"Logout"}
              </StyledMenuItem>
            </StyledMenu>
          </ProfileInfo>
        </>
      )}
      {profileData?.status !== "active" && (
        <div style={{ position: "relative", marginRight: "16px" }}>
          <Tooltip
            title="Report a problem"
            placement="bottom-end"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: 11,
                  bgcolor: `${COLORS.primaryOrange}`,
                  "& .MuiTooltip-arrow": {
                    color: `${COLORS.primaryOrange}`,
                  },
                },
              },
            }}
          >
            <IconArea>
              <img
                src={report}
                onClick={() => {
                  setOpenReportPopup(true);
                }}
                alt=""
              />
            </IconArea>
          </Tooltip>
          {firstTimePopup && !localStorage.getItem("SeenFirstTimeBubble") && (
            <TutorialBubble
              text={
                "Run into an issue? Click here & fill out the form so we can fix it!"
              }
              handleButton={() => {
                setFirstTimePopup(false);
                localStorage.setItem("SeenFirstTimeBubble", true);
              }}
            />
          )}
        </div>
      )}
      <ReportBugPopup
        openPopup={openReportPopup}
        setOpenPopup={setOpenReportPopup}
        setOpenSuccessPopup={setOpenSuccessPopup}
        isMobile={isMobile}
      />
      <CommonSuccessPopup
        openPopup={openSuccessPopup}
        setOpenPopup={setOpenSuccessPopup}
        isMobile={isMobile}
        text={
          "Your report has been submitted! You will receive an email with a resolution in your mailbox, shortly."
        }
      />
    </>
  );
};
