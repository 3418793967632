import styled from "styled-components";
import { COLORS } from "../../../constants";

export const PreQualifyRoot = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => (props.isMobile ? "position: relative;" : "")}
  width: 100%;
  max-width: 860px;
  ${(props) => (props.isMobile ? "height: 100vh;" : "")}
  ${(props) => (props.isMobile ? "" : "align-content: center;" )}
  ${(props) => (props.isMobile ? "" : "justify-content: center;")}
  margin: 0px;
  background-color: ${COLORS.backgroundGrey};
  padding-top: 72px;
`;


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;


export const FormContainer = styled.div`
  width: 100%;
  ${(props) => (props.isMobile ? "align-self: center;" : "")}
  padding-bottom: 20px;
  margin-top: ${(props) => (props.isMobile ? "16px" : "20px")};
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
`;

export const StepContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const StyledStepper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-bottom: 16px;
  margin-top: 64px;
`;

export const StyledTempMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledPylonTemp = styled.img`
  width: 150px;
  margin-top: 167px;
  margin-bottom: auto;
`;

export const StyledMobileTempText = styled.div`
  font-family: SF Pro Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
`;
