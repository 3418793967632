import React, { useEffect, useState } from "react";
import {
  StyledPosition,
  StyledTableCellFirst,
  StyledTableCheckBoxCell,
  StyledTableCenteringContainer,
  StyledTableCellSecond,
  StyledMinusImg,
  StyledIndexCell,
  PersonnelCardRoot,
  StyledCardContent,
  StyledName,
  StyledProfileImage,
  StyledThreeDotMargin,
  StyledCheckboxText,
} from "./styles";
import { StyledXMargin } from "../../personnelSelection/components/styles";

import threeVerticalDots from "assets/preQualifyIcons/threeVerticalDots.svg";
import BorderLessConciseTable from "components/filesListTable/borderLessConciseTable";
import RoundedCheckbox from "components/roundedCheckbox";
import { ThreeDotsPopupMenu } from "components/threeDotsPopupMenu";
import TinyThreeDots from "assets/dashboardIcons/tinyThreeDots";
import LegalPersonnelPortrait from "assets/preQualifyIcons/legalPersonnel";
import { AddPersonnelPopup } from "components/addPersonnelPopup";
import { PlaceholderText, StyledBox, StyledRow } from "../../styles";
import { getSinglePersonnel } from "api/prequalify";
import { LetterAvatar } from "components/letterAvatar";
import XIcon from "assets/buyers/xIcon";

export const SelectedProjects = ({
  individual,
  setIndividual,
  projects,
  setProjects,
  openPopup,
  setOpenPopup,
  prequalifyData,
  setPrequalifyData,
  pickedArray,
  setPickedArray,
  filteredData,
  setFilteredData,
}) => {
  const removeProject = (e, index, bodyData) => {
    const temp = [...projects];
    temp.splice(index, 1);

    setProjects(temp);
    
    var pickedArrayIndex = pickedArray.indexOf(bodyData?.id);
    pickedArray.splice(pickedArrayIndex, 1);
    setPickedArray(pickedArray);
    if (bodyData?.id) {
      let allData = [...filteredData];
      if (!allData.find(({ id }) => id === bodyData.id)) {
        allData.push({
          id: bodyData.id,
          firstName: bodyData.firstName,
          lastName: bodyData.lastName,
          position: bodyData.position,
        });
      }

      setFilteredData(allData);
    }
  };

  const changePersonnelType = (e, index) => {
    const temp = [...projects];
    const type = e.target.value;

    if (!temp[index].type) {
      temp[index].type = [];
    }

    const personnelType = temp[index].type;

    const typeExists = personnelType.indexOf(type);

    if (typeExists >= 0) {
      personnelType.splice(typeExists, 1);
    } else {
      personnelType.push(type);
    }

    setProjects(temp);
  };

  const editPersonnel = (id) => {
    getSinglePersonnel(id, onSuccessEdit, () => {});
  };

  const onSuccessEdit = (res) => {
    setIndividual(res);
    setOpenPopup(true);
  };

  const body = (bodyData) => {
    if (pickedArray && !pickedArray.includes(bodyData.id)) {
      setPickedArray((pickedArray) => [...pickedArray, bodyData.id]);
    }

    return (
      <PersonnelCardRoot>
        <StyledCardContent>
          <StyledProfileImage>
            {/* <LegalPersonnelPortrait /> */}
            <LetterAvatar name={bodyData.firstName} />
            <StyledXMargin
              onClick={() => removeProject(null, bodyData.index, bodyData)}
            >
              <XIcon />
            </StyledXMargin>
          </StyledProfileImage>
          <StyledName>
            {bodyData.firstName} {bodyData.lastName}
          </StyledName>
          <StyledPosition>{bodyData.position}</StyledPosition>
          <StyledRow>
            <StyledCheckboxText>Site</StyledCheckboxText>
            <RoundedCheckbox
              value="key_site"
              attributeTypes={bodyData.type}
              onChange={(e) => changePersonnelType(e, bodyData.index)}
            />
            <StyledCheckboxText>Office</StyledCheckboxText>
            <RoundedCheckbox
              value="key_office"
              attributeTypes={bodyData.type}
              onChange={(e) => changePersonnelType(e, bodyData.index)}
            />
          </StyledRow>
        </StyledCardContent>
      </PersonnelCardRoot>
    );
  };

  // const headers = [
  //   { title: '' },
  //   { title: 'Project #' },
  //   { title: 'Project Title' },
  //   { title: 'Project Location' },
  // ];

  // const checkBoxHeaders = [
  //   { title: 'Key' },
  //   { title: 'Comparable' },
  //   { title: 'Underway' },
  // ]

  // const actionsHeader = [
  //   { title: 'Remove' }
  // ]

  return (
    <>
      {projects.length === 0 && (
        <StyledBox boxHeight="150px">
          <PlaceholderText>Selected Personnel will appear here</PlaceholderText>
        </StyledBox>
      )}
      {projects.length > 0 && (
        <BorderLessConciseTable
          length={10}
          body={body}
          data={projects}
          horizontal={true}
          height={"180px"}
          width={"860px"}
          backgroundColor={"transparent"}
        />
      )}
    </>
  );
};

export default SelectedProjects;
