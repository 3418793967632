import { AlertPopup } from "../../components/alertPopup/alertPopup";
import { SearchBar } from "../../pages/contractorDashboard/detailScreens/SearchBar";

import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  DetailArea,
  DetailedFormContainer,
  ScreenRoot,
  StyledTypography,
} from "./styles";
import Header from "./header";
import { getAllFiles } from "api/file_managment";
import { DocumentList } from "./documentList";
import bytesToMegaBytes from "common/convertFileSize";
import { COLORS } from "../../constants";
import { FONT_SIZES } from "../../constants";
import { useSelector } from "react-redux";
import { contactSelector } from "data/selectors/contact";
import Footer from "./footer";
import { getDocumentTypes } from "api/external_data";
import { institutionProfileInfoSelector } from "data/selectors/serviceProvider";

export const MainDocPool = ({
  docPoolPopUp = false,
  setSelectedFiles,
  selectedFiles,
  attachFile,
  resumeFile,
  institutionId,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const [filteredData, setFilteredData] = useState([]);
  const [totalFilesSize, setTotalFilesSize] = useState(null);
  const [internalSearchResults, setInternalSearchResults] =
    useState(filteredData);
  const [query, setQuery] = useState("");
  const [fileTypes, setFileTypes] = useState([]);
  const currentDashboardUrl = localStorage.getItem("current_dashboard_url");
  let contactData = useSelector(contactSelector);
  const dataRef = useRef(null);

  let institutionContactData = useSelector(institutionProfileInfoSelector);

  if (currentDashboardUrl === "/service-provider-dashboard") {
    contactData = institutionContactData;
  }

  const fileLimitCount = contactData?.user_access_control?.file_limit_count;
  const fileLimitSize =
    contactData?.user_access_control?.document_pool_size &&
    contactData?.user_access_control?.document_pool_size > 0
      ? contactData?.user_access_control?.document_pool_size
      : contactData?.user_access_control?.file_limit_size;
  const [openPopup, setOpenPopup] = useState(false);
  const fileLimitSizeMB = Math.round(fileLimitSize / (1024 * 1024));

  const getInstitutionFiles = async () => {
    if (
      currentDashboardUrl === "/service-provider-dashboard" &&
      institutionId
    ) {
      dataRef.current = { institution_id: institutionId };
    }

    await getAllFiles(dataRef.current)
      .then((data) => setFilteredData(data.files))
      .catch((err) => {
        console.log("Something went wrong");
      });
  };

  const getFileTypes = async () => {
    await getDocumentTypes()
      .then((data) => setFileTypes(data))
      .catch((err) => {
        console.log("Something went wrong");
      });
  };
  useEffect(() => {
    getInstitutionFiles();
    if (setSelectedFiles) {
      setSelectedFiles([]);
    }
    getFileTypes();
  }, []);

  useEffect(() => {
    let totalSize = 0;
    filteredData?.map((item) => (totalSize += item.size));
    setTotalFilesSize(bytesToMegaBytes(totalSize, 1));
  }, [filteredData]);

  const addUploadedFile = (uploadedFiles) => {
    let temp = [...filteredData];

    for (let key in uploadedFiles) {
      if (!isNaN(key)) {
        temp.push(uploadedFiles[key]);
      }
    }
    setFilteredData(temp);
  };

  return (
    <DetailedFormContainer isMobile={isMobile}>
      <Header
        isMobile={isMobile}
        uploadedFileCount={filteredData?.length}
        setFile={addUploadedFile}
        uploadedFileTotalSize={totalFilesSize}
        multiple={true}
        fileLimitCount={fileLimitCount}
        fileLimitSizeMB={fileLimitSizeMB}
        docPoolPopUp={docPoolPopUp}
      />

      <DetailArea style={{ width: "100%" }}>
        <StyledTypography>My Files</StyledTypography>
        {!isMobile && (
          <div
            style={{
              width: "70%",
              marginTop: "30px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SearchBar
              filteredData={filteredData}
              setInternalSearchResults={setInternalSearchResults}
              query={query}
              setQuery={setQuery}
              searchWidth={docPoolPopUp ? "60%" : "50%"}
              internalSearch={true}
              searchPlaceHolder="File Name..."
            />
          </div>
        )}
      </DetailArea>
      {filteredData?.length <= 0 && (
        <div
          style={{
            marginTop: "70px",
            color: "black",
            fontSize: FONT_SIZES.body_4,
            fontWeight: "400",
          }}
        >
          <div
            style={{
              color: COLORS.textGrey,
              fontSize: FONT_SIZES.body_5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No files uploaded
          </div>
        </div>
      )}
      {filteredData?.length > 0 && (
        <DocumentList
          filteredData={
            query && query !== "" ? internalSearchResults : filteredData
          }
          setFilteredData={setFilteredData}
          isMobile={isMobile}
          fileLimitCount={fileLimitCount}
          fileLimitSizeMB={fileLimitSizeMB}
          docPoolPopUp={docPoolPopUp}
          resumeFile={resumeFile}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          fileTypes={fileTypes}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          institutionId={institutionId}
          currentDashboardUrl={currentDashboardUrl}
        />
      )}

      {docPoolPopUp && (
        <Footer
          isMobile={isMobile}
          multiple={true}
          setFile={addUploadedFile}
          attachFile={attachFile}
        />
      )}
      <AlertPopup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        headerText={"You have exceeded the file size."}
        subHeaderText={`You can only upload a maximum of ${fileLimitSizeMB}MB documents.`}
        showButton={true}
      />
    </DetailedFormContainer>
  );
};
