import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";

import { GoBackButton } from "pages/buyers/components/goBackButton";
import { StyledContainer } from "./styles";
import { AllClientHeader } from "./components/AllClientHeader";
import { AllPendingCoTable } from "./components/allPendingCoTable";
import { serviceProviderCoListSelector } from "data/selectors/serviceProvider";
import { getServiceProviderCoListOfAllClient } from "data/actions/serviceProvider";

const ViewAllCoPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  let history = useHistory();
  const location = useLocation();
  const section = location.state?.section || "";

  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [internalSearchResults, setInternalSearchResults] = useState(data);
  const allContractorCoData = useSelector(serviceProviderCoListSelector);
  console.log("allContractorCoData", allContractorCoData);
  useEffect(() => {
    if (allContractorCoData?.length < 1) {
      dispatch(getServiceProviderCoListOfAllClient());
    }
  }, []);

  useEffect(() => {
    if (allContractorCoData?.length > 0) {
      let separatedCOs = [];

      allContractorCoData.forEach((institution) => {
        const { institutionId, institutionName, projects } = institution;

        projects.forEach((project) => {
          const { projectId, projectName, changeOrder } = project;

          changeOrder.forEach((co) => {
            separatedCOs.push({
              ...co,
              projectId,
              projectName,
              institutionId,
              institutionName,
            });
          });
        });
      });
      setData(separatedCOs);
    }
  }, [allContractorCoData]);
  const handleGoBack = () => {
    history.push("/");
  };

  return (
    <StyledContainer isMobile={isMobile}>
      <GoBackButton />
      <AllClientHeader
        title="Change Orders"
        hasButton={false}
        subTitle="CO created in last 90 days"
        marginBottom="0"
        marginTop="0"
        // handleButton={handleStartNew}
        setInternalSearchResults={setInternalSearchResults}
        query={query}
        setQuery={setQuery}
        data={data}
      />
      {data && data.length > 0 && (
        <AllPendingCoTable
          data={query && query !== "" ? internalSearchResults : data}
          setData={setData}
          selectTab={section}
        />
      )}
      {data.length <= 0 && (
        <div
          style={{
            height: "50%",
            display: "flex",
            fontSize: "18px",
            // textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>No Change Orders found</p>
        </div>
      )}
    </StyledContainer>
  );
};

export default ViewAllCoPage;
