import React from 'react';

const CheckedIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="#FF6D1D"/>
      <path d="M10.3285 5.83831C10.5582 5.60859 10.9307 5.60859 11.1604 5.83831C11.3901 6.06803 11.3901 6.44048 11.1604 6.6702L7.63098 10.1996C7.40126 10.4293 7.02881 10.4293 6.79909 10.1996L4.83831 8.23882C4.60859 8.0091 4.60859 7.63665 4.83831 7.40693C5.06803 7.17721 5.44048 7.17721 5.6702 7.40693L7.21504 8.95177L10.3285 5.83831Z" fill="white"/>
    </svg>
  )
};

export default CheckedIcon;


