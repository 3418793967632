import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import { COLORS, TYPOGRAPHY } from "../../constants";
import Paper from "@material-ui/core/Paper";


export const StyledAddressContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  gap: 16px;
`;



export const StyledPaper = styled(Paper)`
  && {
    padding: 25px;
    width: ${(props) => (props.isMobile ? "100%" : "650px")};
    align-self: center;
    overflow: hidden;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`;

export const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 25px;
  margin-left: 0;
  margin-right: auto;
  font-style: normal;
  font-weight: ${(props) => props.labelWeight || "normal"};
  font-size: ${(props) => props.labelSize || "12px"};
  line-height: 20px;
  color: #000000;
`;

export const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => props.marginTop || "0"};
  margin-bottom: ${(props) => props.marginBottom || "0"};
`;

export const FormTitle = styled.div`
  font-family: ${TYPOGRAPHY.primaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-top: 24px;
  color: ${COLORS.black};
`;

export const StyledFileName = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: #b8b8b8;
  margin-top: 1px;
  margin-bottom: 5px;
  background: #ffffff;
  border: 1px solid #ebe9f1;
  border-radius: 6px;
  margin-left: 12px;
`;

export const StyledUploadIcon = styled.label`
  margin: ${(props) => props.margin || 0};
  cursor: pointer;
`;

export const InfoArea = styled.div`
  ${(props) =>
    props.marginLeft && !props.isMobile
      ? `margin-left: ${props.marginLeft};`
      : ""}
  margin-top: ${(props) => props.marginTop || "24px"};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  ${(props) => (props.noJustifyContent ? "" : "justify-content: center;")}
  ${(props) =>
    props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}
  
  .last {
    padding-right: -5px !important;
  }

  .inputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "50%")};
  }

  .fullWidthInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: 100%;
  }

  .oneFifthInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "20%")};
  }

  .thirtyInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "30%")};
  }

  .oneThirdInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "33.33%")};
  }

  .twoThirdInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "66.66%")};
  }

  .oneFifthMobile {
    padding-right: 16px;
    width: ${(props) => (props.isMobile ? "50%" : "20%")};
  }

  .thirtyMobile {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "50%" : "30%")};
  }
`;
export const InfoLabel = styled.div`
  margin-bottom: 5px;
  margin-left: 0;
  margin-right: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;

  line-height: 15px;
  color: #938d98;
  padding-right: 16px;
`;
