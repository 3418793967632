import * as React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { StyledTab, SetupContainer } from '../styles'
import DataMaintenanceAssociation from './DataMaintenanceAssociation'
import DataMaintenanceUnion from './DataMaintenanceUnion'
import DataMaintenanceBank from './DataMaintenanceBank'
import DataMaintenanceInsurance from './DataMaintenanceInsurance'
import DataMaintenanceUnderwriter from './DataMaintenanceUnderwriter'
import DataMaintenanceInsuranceType from './DataMaintenanceInsuranceType'
import DataMaintenancePackage from './DataMaintenancePackage'
import DataMaintenanceDocumentType from './DataMaintenanceDocumentType'

export const DataMaintenance = () => {
  const [selectTab, setSelectTab] = React.useState('1');

  const handleChange = (event, newValue) => {
    setSelectTab(newValue);
  };

  return (
    <SetupContainer >
      <TabContext value={selectTab}>

        <TabList onChange={handleChange} variant="fullWidth" centered TabIndicatorProps={{hidden:true}}>
          <StyledTab label="Association" value="1" />
          <StyledTab label="Union" value="2" />
          <StyledTab label="Bank" value="3" />
          <StyledTab label="Insurance" value="4" />
          <StyledTab label="Underwriter" value="5" />
          <StyledTab label="Insurance Type" value="6" />
          <StyledTab label="Plans" value="7" />
          <StyledTab label="Document Type" value="8" />
        </TabList>

        <TabPanel value="1">
          <DataMaintenanceAssociation />
        </TabPanel>
        <TabPanel value="2">
          <DataMaintenanceUnion />
        </TabPanel>
        <TabPanel value="3">
          <DataMaintenanceBank />
        </TabPanel>
        <TabPanel value="4">
          <DataMaintenanceInsurance />
        </TabPanel>
        <TabPanel value="5">
          <DataMaintenanceUnderwriter />
        </TabPanel>
        <TabPanel value="6">
          <DataMaintenanceInsuranceType />
        </TabPanel>
        <TabPanel value="7">
          <DataMaintenancePackage />
        </TabPanel>
        <TabPanel value="8">
          <DataMaintenanceDocumentType />
        </TabPanel>
        

      </TabContext>
    </SetupContainer>

  );
}
