import React, { useEffect } from "react";

import { StyledTableCell } from "../../../components/filesListTable/styles";
import ConciseTable from "../../../components/filesListTable/conciseTable";
import {
  StyledProjectSelectionContainer,
  MobileColumnContent,
  MobileColumnText,
  StyledUnderwayContainer,
  StyledCompletedContainer,
} from "./styles";
import { ThreeDotsPopupMenu } from "../../../components/threeDotsPopupMenu";
import { truncateString } from "../../../common/truncateString";
import { useDispatch, useSelector } from "react-redux";
import { clientProjectsWipSelector } from "data/selectors/underwriters";
import { getClientProjectsInfoForWip } from "data/actions/underwriters";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const ProjectsList = ({
  filteredData,
  editFunc,
  deleteFunc,
  isMobile,
  companyId,
}) => {
  const clientProjects = useSelector(clientProjectsWipSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(
      getClientProjectsInfoForWip({ account_id: companyId }, "completed")
    );
  }, []);
  const headers = [
    { title: "Project Title", width: "38%" },
    { title: "Number", width: "22%" },
    { title: "Location", width: "22%" },
    { title: "Status", width: "13%", align: "center" },
    { title: "", width: "10%" },
  ];

  const goToProjectWipPage = (project_id) => {
    history.push("/client/wip/project", {
      projectId: project_id,
      companyId: companyId,
    });
  };

  const body = (bodyData) => {
    return (
      <>
        {isMobile && (
          <StyledTableCell component={"tr"} width="90%">
            <MobileColumnContent direction="column">
              <MobileColumnText>
                {truncateString(bodyData.project_name, 28)}
              </MobileColumnText>
              <MobileColumnText fontSize="12px" fontWeight="400">
                {truncateString(bodyData.project_location, 30)}
              </MobileColumnText>
            </MobileColumnContent>
          </StyledTableCell>
        )}
        {!isMobile && (
          <>
            <StyledTableCell component={"tr"} width="40%">
              {bodyData.project_name}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="25%">
              {bodyData.project_number}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="25%">
              {bodyData.project_location}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="10%">
              {bodyData.project_status === "Underway" ? (
                <StyledUnderwayContainer>Underway</StyledUnderwayContainer>
              ) : bodyData.project_status === "Completed" ? (
                <StyledCompletedContainer>Completed</StyledCompletedContainer>
              ) : (
                ""
              )}
            </StyledTableCell>
          </>
        )}
        <StyledTableCell component={"tr"} align="right" width="10%">
          <ThreeDotsPopupMenu
            item={bodyData}
            handleModify={editFunc}
            handleDelete={deleteFunc}
            wipButton={true}
            goToProjectWipPage={goToProjectWipPage}
          />
        </StyledTableCell>
      </>
    );
  };

  return (
    <StyledProjectSelectionContainer isMobile={isMobile}>
      <ConciseTable headers={isMobile ? "" : headers} />
      <ConciseTable
        // headers={isMobile ? "" : headers}
        body={body}
        data={filteredData}
      />
    </StyledProjectSelectionContainer>
  );
};
