import React from "react";
import {
  StyledContainerTitle,
  StyledHeaderContent,
  StyledButtonArea,
} from "./styles";
import { FunctionalButton } from "../../../components/functionalButton";

export const BuyerHeader = ({
  title,
  hasButton,
  handleButton,
  marginBottom,
}) => {
  return (
    <StyledContainerTitle>
      <StyledHeaderContent marginBottom={marginBottom}>
        {title}
      </StyledHeaderContent>
      {hasButton && (
        <StyledButtonArea>
          <FunctionalButton
            buttonTitle="Start a New Request"
            handleButton={handleButton}
            width="200px"
            height="48px"
            isReversedColor={true}
          />
        </StyledButtonArea>
      )}
    </StyledContainerTitle>
  );
};
