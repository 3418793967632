import React, { useState, useEffect } from "react";
import Input from "../inputField";
import { InfoArea, StyledRow, SectionText } from "./styles";
import Select from "../selectField";
import { getCountryName } from "../../common/countryName";
import countriesInArray from "../../assets/locale/countriesInArray";
import fetchStatesProvinces from "../../assets/locale/stateProvinceInArray.js";
import { getAssociations, getUnions } from "../../api/external_data";

export const Step6 = ({
  setDisable,
  addData,
  setAddData,
  isMobile
}) => {
  const currentYear = new Date().getFullYear();
  const [statesProvinces, setStatesProvinces] = useState([]);
  const ventureOptions = [
    "Joint Venture",
    "Corporation",
    "Partnership",
    "Registered",
    "Sole Proprietor",
    "Other",
  ];


  return (
    <InfoArea isMobile={isMobile} marginTop="0px" marginLeft="8px" isColumn={true} >
      {addData && (addData?.user_type === "Contractor & Buyer" || addData?.user_type === "Contractor") && (
        <>
          <SectionText>Operational Region</SectionText>
          <StyledRow isMobile={isMobile}>
            <div className="inputWrap">
              <Select
                style={{ marginRight: "16px" }}
                label="Country"
                options={countriesInArray.map((country) => ({
                  value: country.countryCode,
                  label: country.countryName,
                }))}
                value={addData?.operational_region?.country ? getCountryName(addData?.operational_region?.country) : "United States"}
                setValue={(value) => {
                  setAddData("operational_region.country", value);
                  const fetchedStatesProvinces = fetchStatesProvinces(getCountryName(value));
                  setStatesProvinces(fetchedStatesProvinces);
                  setAddData("operational_region.province", fetchedStatesProvinces[0]);
                }}
                validateFunc={(item) => Boolean(item)}
                noCheckIcon={true}
                showDropdownIndicator={true}
              />
            </div>

            {statesProvinces && statesProvinces.length > 0 && (
              <div className="inputWrap">
                <Select
                  style={{ marginRight: "16px" }}
                  label={getCountryName(addData?.operational_region?.country) === "Canada" ? "Province" : getCountryName(addData?.operational_region?.country) === "United States" ? "State" : "State/Province"}
                  options={statesProvinces.map((stateProvince) => ({
                    value: stateProvince,
                    label: stateProvince,
                  }))}
                  value={addData?.operational_region?.province ? addData?.operational_region?.province : "AL"}
                  setValue={(value) => setAddData("operational_region.province", value)}
                  validateFunc={(item) => Boolean(item)}
                  noCheckIcon={true}
                  showDropdownIndicator={true}
                />
              </div>
            )}

            {statesProvinces && statesProvinces.length === 0 && (
              <Input
                id="inputWrap"
                label={getCountryName(addData?.operational_region?.country) === "Canada" ? "Province" : getCountryName(addData?.operational_region?.country) === "United States" ? "State" : "State/Province"}
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={addData?.operational_region?.province ? addData?.operational_region?.province : ""}
                setValue={(val) => setAddData("operational_region.province", val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                marginRight="18px"
              />
            )}


            <Input
              id="inputWrap"
              label="Region"
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={addData?.operational_region?.region}
              setValue={(val) => setAddData('operational_region.region', val)}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
            />
          </StyledRow>

          <SectionText>General</SectionText>
          <StyledRow isMobile={isMobile}>

            <div className="fullWidthInputWrap">
              <Select
                style={{ marginRight: "16px" }}
                label="Year Established"
                options={Array.from(
                  { length: currentYear - 1899 },
                  (_, i) => currentYear - i
                ).map((item) => ({ value: item, label: item }))}
                value={addData?.establishment_year}
                setValue={(val) => setAddData("establishment_year", val)}
                validateFunc={(item) => Boolean(item)}
                noCheckIcon={true}
                showDropdownIndicator={true}
              />
            </div>
            <div className="fullWidthInputWrap">
              <Select
                style={{ marginRight: "16px" }}
                label="Venture Type"
                options={ventureOptions.map((item) => ({
                  value: item,
                  label: item,
                }))}
                value={addData?.venture_type}
                setValue={(val) => setAddData("venture_type", val)}
                validateFunc={(item) => Boolean(item)}
                noCheckIcon={true}
                showDropdownIndicator={true}
              />
            </div>
          </StyledRow>

          <SectionText>Additional Information</SectionText>
          <StyledRow isMobile={isMobile}>
            <div className="fullWidthInputWrap">
              <AssociationDropdown data={addData} onChangeData={setAddData} />
            </div>
            <div className="fullWidthInputWrap">
              <UnionDropdown data={addData} onChangeData={setAddData} />
            </div>
          </StyledRow>

        </>
      )}


      {addData && (addData?.user_type === "Buyer" || addData?.user_type === "Underwriter" || addData?.user_type === "Agent/Broker") && (
        <>
          <SectionText>General</SectionText>
          <StyledRow isMobile={isMobile}>
            <div className="fullWidthInputWrap">
              <Select
                style={{ marginRight: "16px" }}
                label="Year Established"
                options={Array.from(
                  { length: currentYear - 1899 },
                  (_, i) => currentYear - i
                ).map((item) => ({ value: item, label: item }))}
                value={addData?.establishment_year}
                setValue={(val) => setAddData("establishment_year", val)}
                validateFunc={(item) => Boolean(item)}
                noCheckIcon={true}
                showDropdownIndicator={true}
              />
            </div>
            <div className="fullWidthInputWrap">
              <Select
                style={{ marginRight: "16px" }}
                label="Venture Type"
                options={ventureOptions.map((item) => ({
                  value: item,
                  label: item,
                }))}
                value={addData?.venture_type}
                setValue={(val) => setAddData("venture_type", val)}
                validateFunc={(item) => Boolean(item)}
                noCheckIcon={true}
                showDropdownIndicator={true}
              />
            </div>
          </StyledRow>

          <SectionText>Additional Information</SectionText>
          <StyledRow isMobile={isMobile}>
            <div className="fullWidthInputWrap">
              <AssociationDropdown data={addData} onChangeData={setAddData} />
            </div>
            <div className="fullWidthInputWrap">
              <UnionDropdown data={addData} onChangeData={setAddData} />
            </div>
          </StyledRow>
        </>
      )}

    </InfoArea>

  )
}



const AssociationDropdown = ({ data, onChangeData }) => {
  const [associations, setAssociations] = useState([]);

  useEffect(() => {
    getAssociations()
      .then((res) => {
        setAssociations(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);


  return (
    <Select
      style={{ marginRight: "16px" }}
      label="Construction Association"
      options={associations.map((item) => ({
        value: item.name,
        label: item.name,
      }))}
      value={data?.association}
      setValue={(val) => onChangeData("association", val)}
      validateFunc={(item) => Boolean(item)}
      noCheckIcon={true}
      showDropdownIndicator={true}
    />


  );
}


const UnionDropdown = ({ data, onChangeData }) => {
  const [unions, setUnions] = useState([]);

  useEffect(() => {
    getUnions()
      .then((res) => {
        setUnions(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  return (
    <Select
      style={{ marginRight: "16px" }}
      label="Construction Union"
      options={unions.map((item) => ({
        value: item.name,
        label: item.name,
      }))}
      value={data?.union}
      setValue={(val) => onChangeData("union", val)}
      validateFunc={(item) => Boolean(item)}
      noCheckIcon={true}
      showDropdownIndicator={true}
    />
  );
};


