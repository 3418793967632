import styled from "styled-components";

export const InputWrap = styled.div`
  position: relative;
  width: 100%;
  margin-top: 12px;
`;

export const InputLabel = styled.div`
  margin-bottom: 5px;
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
`;

export const InputLabelHint = styled.span`
  margin-left: 15px;
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 20px;

  color: #777777;
`;

export const InputField = styled.textarea`
  width:  ${props => props.width ? props.width : '100%'};
  height: 40px;
  padding-left: 15px;
  padding-right: 40px;
  padding-top: 10px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 6px;
  color: #1a051d;

  font-style: normal;
  font-weight: normal;
  font-size: 15px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    // padding-top: 10px;
    color: #d0c9d6;
  }

  ${(props) =>
    props.validation === -1
      ? "border: 1px solid #FF647C; color: #FF647C; background-color: white;"
      : ""}

  ${(props) =>
    props.validation === 1
      ? "border: 1px solid #FF6D1D; background-color: white;"
      : ""}
`;
