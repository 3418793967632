import React, { useState, useEffect, useRef } from "react";

import {
  InputLabel,
  CloseButton,
  Row,
  FormTitle,
  StyledFileName,
  StyledUploadIcon,
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import CloseIcon from "@material-ui/icons/Close";
import Input from "components/inputField";
import PledgxLogo from "assets/common/pledgxLogo";
import AddResumeButton from "assets/onBoarding/AddResumeButton";
import { emailRE } from "common/regex";
import { FunctionalButton } from "components/functionalButton";

export const AddPersonnelPopupCache = ({
  setOpenDialog,
  openDialog,
  data,
  setData,
  handleButtonFunc,
}) => {
  const [editIndex, setEditIndex] = useState(null);

  const [dialogFN, setDialogFN] = useState("");
  const [dialogLN, setDialogLN] = useState("");
  const [dialogEmail, setDialogEmail] = useState("");
  const [dialogPos, setDialogPos] = useState("");
  const [dialogRes, setDialogRes] = useState("");
  const [validSubmit, setValidSubmit] = useState(false);
  const fileInput = useRef();

  useEffect(() => {
    setDialogFN(data?.firstName);
    setDialogLN(data?.lastName);
    setDialogEmail(data?.email);
    setDialogPos(data?.position);
    setDialogRes(data?.resume);
  }, [data]);

  useEffect(() => {
    if (
      dialogFN?.length > 0 &&
      dialogLN?.length > 0 &&
      emailRE.test(dialogEmail) &&
      dialogPos?.length > 0
    ) {
      setValidSubmit(false);
    } else {
      setValidSubmit(true);
    }
  }, [dialogFN, dialogLN, dialogEmail, dialogPos]);

  const handleAddPersonnel = () => {
    let updatedPersonnelData = [];

    if (data?.personnel) {
      updatedPersonnelData = [...data?.personnel];
    }

    updatedPersonnelData.unshift({
      id: data?.id,
      firstName: dialogFN,
      lastName: dialogLN,
      email: dialogEmail,
      position: dialogPos,
      resume: dialogRes,
    });
    if (setData) {
      setData(updatedPersonnelData);
    }
    if (handleButtonFunc) {
      handleButtonFunc({
        id: data?.id,
        firstName: dialogFN,
        lastName: dialogLN,
        email: dialogEmail,
        position: dialogPos,
        resume: dialogRes,
      });
    }
  };

  const handleEditPersonnel = () => {
    let updatedPersonnelData = [];

    if (data?.personnel) {
      updatedPersonnelData = [...data?.personnel];
    }

    updatedPersonnelData[editIndex] = {
      id: updatedPersonnelData[editIndex].id,
      firstName: dialogFN,
      lastName: dialogLN,
      email: dialogEmail,
      position: dialogPos,
      resume: dialogRes,
    };
    if (setData) {
      setData(updatedPersonnelData);
    }
    if (handleButtonFunc) {
      handleButtonFunc({
        id: updatedPersonnelData[editIndex].id,
        firstName: dialogFN,
        lastName: dialogLN,
        email: dialogEmail,
        position: dialogPos,
        resume: dialogRes,
      });
    }
  };

  const handleFileSelect = () => {
    fileInput.current.click();
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDialogFN("");
    setDialogLN("");
    setDialogEmail("");
    setDialogPos("");
    setDialogRes(null);
    setEditIndex(null);
  };

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <Paper
        elevation={0}
        style={{
          padding: 25,
          width: 400,
          alignSelf: "center",
          overflow: "hidden",
          position: "relative",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CloseButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <PledgxLogo />
        <FormTitle style={{ marginTop: 10 }}>Add Your Personnel</FormTitle>
        <Row style={{ margin: "0px -10px" }}>
          <Input
            style={{ margin: "0px 10px" }}
            label="First Name*"
            type="text"
            placeholder="First Name"
            value={dialogFN}
            setValue={setDialogFN}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noErrorBorder={true}
          />
          <Input
            style={{ margin: "0px 10px" }}
            label="Last Name*"
            type="text"
            placeholder="Last Name"
            value={dialogLN}
            setValue={setDialogLN}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noErrorBorder={true}
          />
        </Row>
        <Input
          label="Email*"
          type="text"
          placeholder="Email"
          value={dialogEmail}
          setValue={setDialogEmail}
          validateFunc={(item) => emailRE.test(item)}
          noCheckIcon={true}
          noErrorBorder={true}
        />
        <Input
          label="Position*"
          type="text"
          placeholder="Position"
          value={dialogPos}
          setValue={setDialogPos}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
        <InputLabel>Resume (Optional)</InputLabel>
        <div style={{ width: "100%" }}>
          <StyledUploadIcon>
            <input
              ref={fileInput}
              id="resume-selector"
              onClick={(e) => {
                e.target.value = null;
                handleFileSelect();
              }}
              onChange={(e) => {
                setDialogRes(e.target.files[0]);
              }}
              type="file"
              style={{ display: "none" }}
            />
            <AddResumeButton />
          </StyledUploadIcon>
          {dialogRes && (
            <StyledFileName>
              <span>{dialogRes.name}</span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => setDialogRes(null)}
              >
                <CloseIcon />{" "}
              </span>
            </StyledFileName>
          )}
        </div>
        <FunctionalButton
          disabled={validSubmit}
          width="100%"
          marginTop="16px"
          marginRight="0px"
          handleButton={() => {
            if (editIndex !== null) {
              handleEditPersonnel();
              setEditIndex(null);
            } else {
              handleAddPersonnel();
            }
            setOpenDialog(false);
            setDialogFN("");
            setDialogLN("");
            setDialogEmail("");
            setDialogPos("");
            setDialogRes(null);
          }}
          buttonTitle="SAVE"
        />
      </Paper>
    </Dialog>
  );
};
