import { Typography } from "@mui/material";
import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from '../../constants';
import IconButton from "@material-ui/core/IconButton";


export const FieldArea = styled.div`
  ${(props) =>
    props.marginLeft && !props.isMobile
      ? `margin-left: ${props.marginLeft};`
      : ""}
  margin-top: ${(props) => props.marginTop || "24px"};
  display: flex;
  flex-direction: ${(props) => (props.isColumn ? "column" : "row")};
  align-items: center;
  flex-wrap: wrap;
  ${(props) => (props.noJustifyContent ? "" : "justify-content: center;")}
  ${(props) =>
    props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}

  .last {
    padding-right: 0px !important;
  }

  .inputWrap {
    padding-right: ${(props) => (props.isMobile ? "0px" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "50%")};
  }

  .fullWidthInputWrap {
    width: 100%;
  }

  .oneFifthInputWrap {
   padding-right: ${(props) => (props.isMobile ? "0px" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "20%")};
  }

  .thirtyInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "30%")};
  }

  .oneThirdInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "33.33%")};
  }

  .twoThirdInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "66.66%")};
  }

  .oneFifthMobile {
    padding-right: 16px;
    width: ${(props) => (props.isMobile ? "50%" : "20%")};
  }

  .thirtyMobile {
    width: ${(props) => (props.isMobile ? "50%" : "30%")};
  }
`;



export const SectionText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    width: 100%;
    margin-top: ${(props) => props.marginTop || "16px"};
    color: ${COLORS.darkGrey1};
  }
`;

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PopupTitle = styled(Typography)`
&&{
  font-family: ${TYPOGRAPHY.primaryFont};
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 16px;
}
`;

export const PopupFields = styled.div`
  display: flex;
  flex-direction: column;
  // margin-top: 16px;
  // width: ${props => props.isMobile ? "100%" : "95%"};
  width: 100%;
  min-height: 330px;
`;

export const ButtonWrap = styled.div`
  min-height: 80px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const InfoArea = styled.div`
  ${props => props.marginLeft && !props.isMobile ? `margin-left: ${props.marginLeft};` : ''}
  margin-top: ${(props) => props.marginTop || "24px"};
  display: flex;
  flex-direction: ${props => props.isColumn || props.isMobile ? "column" : "row"};
  align-items: center;
  ${props => props.noJustifyContent ? "" : "justify-content: center;"}
  ${props => props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}
  .last {
    padding-right: 0px !important;
  }

  .inputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '50%'};
  }

  .fullWidthInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: 100%;
  }

  .oneFifthInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '20%'};
  }

  .thirtyInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '30%'};
  }

  .oneThirdInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '33.33%'};
  }

  .twoThirdInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '66.66%'};
  }

  .oneFifthMobile {
    padding-right: 16px;
    width: ${props => props.isMobile ? '50%' : '40%'};
  }

  .thirtyMobile {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '50%' : '60%'};
  }
`;

export const StyledRow = styled.div`
  display: flex;
  width: 100%;
  ${(props) => (props.margin ? `margin: ${props.margin};` : "")}
  ${(props) =>
    props.justifyContent ? `justify-content: ${props.justifyContent};` : ""}
  flex-direction: ${(props) =>
    props.isMobile || props.isColumn ? "column" : "row"};
`;


export const StyledSmallAddText = styled.div`
  color: ${COLORS.primaryOrange};
  cursor: pointer;
  font-family: ${TYPOGRAPHY.primaryFont} ;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin-top: 18px;
  margin-right: 16px;
`;

export const CloseButton = styled(IconButton)`
  &&{
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

export const StepperContainer = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: center;
  width: 100%;
  ${props => props.isMobile ? "margin-top: 8px;" : "margin-top: 12px;"}
`;