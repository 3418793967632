import React from 'react';

const Structure = ({ active, completed }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1545:3208)">
        <g filter="url(#filter0_d_1545:3208)">
          <circle cx="14" cy="14" r="14" fill="white"/>
          <circle cx="14" cy="14" r="13.5" fill={active || completed ? "#FF6D1D" : "white"} stroke="#ECEBED"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9588 8.45833V9.42083C15.5629 9.55833 16.8463 10.6583 17.3504 12.125H9.60461C10.1546 10.6583 11.4379 9.55833 13.0421 9.42083V8.45833C13.0421 8.18333 13.2254 8 13.5004 8C13.7754 8 13.9588 8.18333 13.9588 8.45833ZM18.5417 13.9583V18.0833H19V19H8V18.0833H8.45833V13.9583H8V13.0417H19V13.9583H18.5417ZM16.25 18.0834H17.625V13.9584H16.25V18.0834ZM13.957 13.9584H15.332V18.0834H13.957V13.9584ZM11.668 18.0834H13.043V13.9584H11.668V18.0834ZM9.375 13.9584H10.75V18.0834H9.375V13.9584Z" fill={active || completed ? "white" : "#D0C9D6"} />
      </g>
      <defs>
        <filter id="filter0_d_1545:3208" x="-64" y="-57" width="156" height="156" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="7"/>
          <feGaussianBlur stdDeviation="32"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1545:3208"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1545:3208" result="shape"/>
        </filter>
        <clipPath id="clip0_1545:3208">
          <rect width="28" height="28" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default Structure;
