import styled from "styled-components";
import { Checkbox } from "@material-ui/core";
import { COLORS } from "../../constants";

export const StyledRoundCheckbox = styled(Checkbox)`
  &&{
    .MuiIconButton-label{
      margin-left: 0px;
      margin-right: 0px;
    }
    &&:hover {
      ${(props) => (props.readOnly ? `background-color: transparent;` : '')}
      ${(props) => (props.readOnly ? `cursor: default;` : '')}
    }
  }
  
`;
