import React, { useEffect } from "react";
import FilesListTable from "../../../../components/filesListTable/index";
import { useDispatch, useSelector } from "react-redux";
import { getInstitutionRfpqs } from "../../../../data/actions/buyers";
import { institutionRfpqsSelector } from "../../../../data/selectors/buyers";

import {
  StyledCellTitle,
  StyledCellCommon,
  StyledCellAction,
  StyledStatus,
} from "./styles";

import { ActionsMenu } from "./ActionsMenu";
import ConciseTable from "components/filesListTable/conciseTable";
import moment from "moment";

export const AllProjectTable = ({
  data,
  setData,
  isMobile,
  borderRadius,
  boxShadow,
  isShortened,
  handleRfpqCancel,
  handleRfpqDelete,
  institutionId,
}) => {
  const dispatch = useDispatch();
  const institutionRfpqs = useSelector(institutionRfpqsSelector);

  useEffect(() => {
    if (institutionRfpqs.length < 1) {
      dispatch(getInstitutionRfpqs());
    }
  }, []);

  const pickTextColor = (status) => {
    switch (status.toLowerCase()) {
      case "awarded":
        return "#2F9B51";
      case "cancelled":
        return "#F00101";
      case "closed":
        return "#2F9B51";
      case "deleted":
        return "white";
      case "draft":
        return "#C48A1A";
      case "invited":
        return "#2F9B51";
      case "open":
        return "#2F9B51";
      case "reviewed":
        return "#435EAB";
      default:
        return "black";
    }
  };

  const pickBackgroundColor = (status) => {
    switch (status.toLowerCase()) {
      case "awarded":
        return "#C0FAD2";
      case "cancelled":
        return "#FFCACA";
      case "closed":
        return "#C0FAD2";
      case "deleted":
        return "grey";
      case "draft":
        return "#FFEBC6";
      case "invited":
        return " #C0FAD2";
      case "open":
        return "#C0FAD2";
      case "reviewed":
        return "#C1D1FF";
      default:
        return "white";
    }
  };
  const body = (bodyData) => {
    let status = bodyData?.status;

    if (moment.utc(bodyData.expiry).format() < moment().format()) {
      if (status === "invited" || status === "open") {
        status = "closed";
      }
    }
    return (
      <>
        <StyledCellTitle component={"tr"} borderRadius={borderRadius}>
          {bodyData.project.title}
        </StyledCellTitle>
        {!isShortened && (
          <StyledCellCommon
            component={"tr"}
            scope="row"
            borderRadius={borderRadius}
          >
            {bodyData.project.number}
          </StyledCellCommon>
        )}
        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
        >
          {bodyData.project.location}
        </StyledCellCommon>
        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
        >
          <StyledStatus
            textColor={pickTextColor(status)}
            backgroundColor={pickBackgroundColor(status)}
          >
            {status}
          </StyledStatus>
        </StyledCellCommon>
      </>
    );
  };

  const actions = (props) => {
    return (
      <>
        <StyledCellAction
          component={"tr"}
          scope="row"
          align="center"
          borderRadius={borderRadius}
        >
          <ActionsMenu
            contract_id={props.id}
            status={props.status}
            setData={setData}
            handleRfpqCancel={handleRfpqCancel}
            handleRfpqDelete={handleRfpqDelete}
            institutionId={institutionId}
          />
        </StyledCellAction>
      </>
    );
  };

  const createHeader = (title, width, align) => {
    return {
      title,
      width: width || "",
      align: align || "left",
    };
  };

  const headers = [
    createHeader("Project Title", "38%"),
    createHeader("Project Number", "20%"),
    createHeader("Project Location", "20%"),
    createHeader("Status", "22%"),
    createHeader(""),
  ];
  const shortenedHeaders = [
    createHeader("Project Title"),
    createHeader("Location"),
    createHeader(""),
    createHeader(""),
  ];

  return (
    <div>
      <ConciseTable
        headers={isMobile ? "" : isShortened ? shortenedHeaders : headers}
      />
      <ConciseTable
        actions={actions}
        actionType="project"
        data={data}
        //height="1200px"
        body={body}
        boxShadow={boxShadow}
        borderRadius={borderRadius}
        // headers={isMobile ? "" : isShortened ? shortenedHeaders : headers}
      />
    </div>
  );
};
