import React from "react";
import insights from "./insightsIcon.svg";
import styled from "styled-components";

const InsightsIcon = ({ width, height, isMobile }) => {
  return (
    <StyledImg
      width={width || "106"}
      height={height || "112"}
      src={insights}
      isMobile={isMobile}
    />
  );
};

export default InsightsIcon;

const StyledImg = styled.img`
  ${(props) => (props.isMobile ? "margin-bottom: 4px; margin-top: 8px;" : "")}
`;
