import React from "react";
import { FunctionalButton } from 'components/functionalButton';

import {
  ImagePopup,
  LargeText,
  SmallText,
  ButtonArea,
  PopupContainer,
} from './styles';
import { Popup } from 'components/popup';
import Success from "assets/dashboardIcons/Success";
import { CloseButton } from "components/closeButton";

export const CommonSuccessPopup = ({
  openPopup,
  setOpenPopup,
  isMobile,
  boldText,
  text,
  handleButton
}) => {

  const handleDone = () => {
    setOpenPopup(false);
  };

  return (
    <Popup
        open={openPopup}
        setOpen={setOpenPopup}
        width={isMobile ? "100%" : "550px"}
        height={isMobile ? "390px" : "480px"}
        dialogContent={(
          <>
            <PopupContainer>
              <CloseButton handleClose={handleDone} />
              <ImagePopup>
                <Success isMobile={isMobile} />
              </ImagePopup>
              <LargeText isMobile={isMobile}>{boldText || "Success!"}</LargeText>
              <SmallText>
                {text || "Your change has been saved."}
              </SmallText>
            </PopupContainer>
            <ButtonArea isMobile={isMobile}>
              <FunctionalButton
                buttonTitle="DONE"
                width="90%"
                height={isMobile ? "48px" : "53px"}
                marginRight="0px"
                handleButton={handleButton || handleDone}
              />
            </ButtonArea>
        </>
        )}
      />

  )
}
