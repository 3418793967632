import {
  getBuyerRfpqsList,
  getInstitutionProfileInfo,
} from "data/actions/serviceProvider";
import { BuyerRfpqsListSelector } from "data/selectors/serviceProvider";
import { AllProjectTable } from "pages/buyers/components/allProjects";
import { GoBackButton } from "pages/buyers/components/goBackButton";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";
import { AllClientHeader } from "./components/AllClientHeader";
import { StyledContainer } from "./styles";

const AllRfpqsPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  let history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [internalSearchResults, setInternalSearchResults] = useState([]);
  const [query, setQuery] = useState("");
  const institution_id = location.state?.institution_id || "";
  const [data, setData] = useState([]);
  const institutionRfpqs = useSelector(BuyerRfpqsListSelector);

  useEffect(() => {
    dispatch(
      getBuyerRfpqsList(
        { institution_id: institution_id },
        () => {},
        () => {}
      )
    );
    dispatch(
      getInstitutionProfileInfo(
        { institution_id: institution_id },
        () => {},
        () => {}
      )
    );
  }, []);

  useEffect(() => {
    if (institutionRfpqs) {
      setData(institutionRfpqs);
    }
  }, [institutionRfpqs]);

  const handleStartNew = () => {
    history.push("/rfpq", {
      institution_id: institution_id,
    });
  };

  const handleCancel = (rfpq_id) => {
    setData(data?.filter((rfpq) => rfpq.id !== rfpq_id));
  };

  const handleDelete = (rfpq_id) => {
    setData(
      data?.filter((rfpq) => {
        if (rfpq.id === rfpq_id) {
          return (rfpq.status = "cancelled");
        } else {
          return rfpq;
        }
      })
    );
  };

  const handleGoBack = () => {
    history.push("/");
  };

  return (
    <StyledContainer isMobile={isMobile}>
      <GoBackButton />
      <AllClientHeader
        title="RFP/Qs"
        hasButton={true}
        handleButton={handleStartNew}
        rfpqPage={true}
        setInternalSearchResults={setInternalSearchResults}
        query={query}
        setQuery={setQuery}
        data={data}
      />
      {data.length > 0 && (
        <AllProjectTable
          data={query && query !== "" ? internalSearchResults : data}
          setData={setData}
          handleRfpqCancel={handleCancel}
          handleRfpqDelete={handleDelete}
          institutionId={institution_id}
        />
      )}
    </StyledContainer>
  );
};

export default AllRfpqsPage;
