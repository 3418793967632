import get from "lodash/get";
import { createSelector } from "reselect";

export const baseTendersSelector = (state) => get(state, "tenders", {});

export const recommendedSelector = createSelector(
  baseTendersSelector,
  (tenders) => get(tenders, "recommended", [])
);
export const savedSelector = createSelector(baseTendersSelector, (tenders) =>
  get(tenders, "saved", [])
);
export const resultSelector = createSelector(baseTendersSelector, (tenders) =>
  get(tenders, "result", [])
);

export const contractorsSelector = createSelector(
  baseTendersSelector,
  (tenders) => get(tenders, "contractors", {})
);
