import React from "react";

import Company from "../../../../assets/preQualifyIcons/company.js";
import Structure from "../../../../assets/preQualifyIcons/structure.js";
import Financial from "../../../../assets/preQualifyIcons/financial.js";
import HealthSafety from "../../../../assets/preQualifyIcons/healthsafety.js";
import Projects from "../../../../assets/preQualifyIcons/projects.js";
import Office from "../../../../assets/preQualifyIcons/office.js";
import Years from "../../../../assets/preQualifyIcons/years.js";
import Additional from "../../../../assets/preQualifyIcons/additional.js";
import Submit from "../../../../assets/preQualifyIcons/submit.js";
import { useHistory } from "react-router-dom";
import { checkAuth } from "../../../../data/actions/user";
import { useDispatch } from "react-redux";

import {
  StyledStepLabel,
  StyledStep,
  StyledStepConnector,
  StyledStepper,
 } from './styles';

function ColorlibStepIcon(props) {
  const { active, completed } = props;
  const icons = {
    1: <Company active={active} completed={completed} />,
    2: <Structure active={active} completed={completed} />,
    3: <Financial active={active} completed={completed} />,
    4: <HealthSafety active={active} completed={completed} />,
    5: <Projects active={active} completed={completed} />,
    6: <Office active={active} completed={completed} />,
    7: <Years active={active} completed={completed} />,
    8: <Additional active={active} completed={completed} />,
    9: <Submit active={active} completed={completed} />,
  };

  return (
    <>
      {icons[String(props.icon)]}
    </>
  );
}

export default function CustomizedStepper({
  activeStep,
  setActiveStep,
  downloading,
  setListPanel,
  setSaveCCDC,
  saveCCDC,
}) {
  const steps = [
    "Project Info",
    "Legal Structure",
    "Financial Reference",
    "Health And Safety",
    "Key Projects",
    "Key Personnels",
    "Annual Records",
    "Additional Documents",
    "Review And Download",
  ];

  const dispatch = useDispatch();
  let history = useHistory();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleOnClick = (index) => {
    dispatch(
      checkAuth(
        () => {},
        () => {
          history.push("/account/login");
        }
      )
    );
    if (saveCCDC) {
      setSaveCCDC(false);
    }
    if(!downloading) {
      setActiveStep(index);
      setListPanel(1);
    }
  }
  return (
    <>
    <StyledStepper
      activeStep={activeStep}
      connector={<StyledStepConnector />}
    >
      {steps.map((label, index) => (
        <StyledStep key={label}>
          <StyledStepLabel
            StepIconComponent={ColorlibStepIcon}
            onClick={() => {handleOnClick(index)}}
          >
          </StyledStepLabel>
        </StyledStep>
      ))}
    </StyledStepper >
    </>
  );
}
