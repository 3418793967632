import React, { useState } from "react";
import Input from "../../../../components/inputField";
import { NumberFormatOnly } from 'components/locale/numberFormatOnly.js';

export const AnnualValues = ({ isMobile, data, setData }) => {
  const currentYear = new Date().getFullYear();

  const [valueCurrent, setValueCurrent] = useState(
    NumberFormatOnly(data["annual_values"]?.[currentYear])
  );
  const [valuePrev, setValuePrev] = useState(
    NumberFormatOnly(data["annual_values"]?.[currentYear - 1])
  );
  const [valuePrevv, setValuePrevv] = useState(
    NumberFormatOnly(data["annual_values"]?.[currentYear - 2])
  );
  const [valuePrevvv, setValuePrevvv] = useState(
    NumberFormatOnly(data["annual_values"]?.[currentYear - 3])
  );
  const [valuePrevvvv, setValuePrevvvv] = useState(
    NumberFormatOnly(data["annual_values"]?.[currentYear - 4])
  );

  const valueFormat = (e) => {
    setValueCurrent(NumberFormatOnly(e));
    setData(`annual_values.${currentYear}`, e);
  };

  return (
    <>
      <Input
        label="Current Year(forecast)"
        type="text"
        id="inputWrap"
        value={valueCurrent}
        marginTop="0px"
        labelSize="10px"
        setValue={(e) => valueFormat(e)}
        onBlur={(e) => setValueCurrent(NumberFormatOnly(e.target.value))}
        noErrorBorder={true}
        noCancelIcon={true}
        noCheckIcon={true}
      />
      <Input
        label={currentYear - 1}
        type="text"
        id="inputWrap"
        marginTop="0px"
        labelSize="10px"
        value={valuePrev}
        setValue={(e) => {
          setValuePrev(e);
          setData(`annual_values.${currentYear - 1}`, e);
        }}
        onBlur={(e) => setValuePrev(NumberFormatOnly(e.target.value))}
        validateFunc={(item) => String(item).length > 0}
        noErrorBorder={true}
        noCancelIcon={true}
        noCheckIcon={true}
      />
      <Input
        label={currentYear - 2}
        type="text"
        id="inputWrap"
        marginTop="0px"
        labelSize="10px"
        value={valuePrevv}
        setValue={(e) => {
          setValuePrevv(e);
          setData(`annual_values.${currentYear - 2}`, e);
        }}
        onBlur={(e) => setValuePrevv(NumberFormatOnly(e.target.value))}
        noErrorBorder={true}
        noCancelIcon={true}
        noCheckIcon={true}
      />
      <Input
        label={currentYear - 3}
        type="text"
        id="inputWrap"
        marginTop="0px"
        labelSize="10px"
        value={valuePrevvv}
        setValue={(e) => {
          setValuePrevvv(e);
          setData(`annual_values.${currentYear - 3}`, e);
        }}
        onBlur={(e) => setValuePrevvv(NumberFormatOnly(e.target.value))}
        validateFunc={(item) => String(item).length > 0}
        noErrorBorder={true}
        noCancelIcon={true}
        noCheckIcon={true}
      />
      <Input
        label={currentYear - 4}
        type="text"
        id="inputWrap"
        marginTop="0px"
        labelSize="10px"
        value={valuePrevvvv}
        setValue={(e) => {
          setValuePrevvvv(e);
          setData(`annual_values.${currentYear - 4}`, e);
        }}
        onBlur={(e) => setValuePrevvvv(NumberFormatOnly(e.target.value))}
        validateFunc={(item) => String(item).length > 0}
        noCancelIcon={true}
        noErrorBorder={true}
        noCheckIcon={true}
      />
    </>
  );
};
