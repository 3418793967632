import React, { useEffect, useState } from "react";
import { StyledPosition, StyledTableCellFirst, StyledTableCheckBoxCell, StyledTableCenteringContainer, StyledTableCellSecond, StyledMinusImg, StyledIndexCell, PersonnelCardRoot, StyledCardContent, StyledName, StyledProfileImage } from "./styles";

import threeVerticalDots from "assets/preQualifyIcons/threeVerticalDots.svg";
import BorderLessConciseTable from "components/filesListTable/borderLessConciseTable";
import RoundedCheckbox from "components/roundedCheckbox";
import { ThreeDotsPopupMenu } from "components/threeDotsPopupMenu";
import LegalPersonnelPortrait from '../../../../../assets/preQualifyIcons/legalPersonnel';
import { AddPersonnelPopup } from "components/addPersonnelPopup";
import { LetterAvatar } from "components/letterAvatar";
import { StyledThreeDotMargin, StyledXMargin } from "../../personnelSelection/components/styles";
import { PlaceholderText, StyledBox } from "../../styles";
import XIcon from "assets/buyers/xIcon";

export const SelectedProjects = ({ 
  projects, 
  setProjects, 
  pickedArray, 
  setPickedArray,
  rerender,
  setRerender
}) => {

  const removeProject = (e, index) => {
    let id = projects[index].id;
    setPickedArray(pickedArray.filter(items => items !== id));

    const temp = [...projects];
    temp.splice(index, 1);

    setProjects(temp);
    setRerender(true);
  };
 
  const changeProjectType = (e, index) => {
    const temp = [...projects];
    const type = e.target.value;

    const projectType = temp[index].type;

    const typeExists = projectType.indexOf(type);

    if(typeExists >= 0) {
      projectType.splice(typeExists, 1);
    } else {
      projectType.push(type);
    }

    setProjects(temp);
  };

  const body = (bodyData) => { 
    // setPickedArray(pickedArray => [...pickedArray, bodyData.id]);
    if(bodyData.id && !pickedArray.includes(bodyData.id)){
      setPickedArray(pickedArray => [...pickedArray, bodyData.id]);
    }

    return (
        <PersonnelCardRoot>
          <StyledCardContent>
            <StyledProfileImage>
              {/* <LegalPersonnelPortrait /> */}
              <LetterAvatar name={bodyData.firstName} />
              <StyledXMargin onClick={ () => removeProject(null, bodyData.index) }>
                <XIcon/>
              </StyledXMargin>
            </StyledProfileImage>
            <StyledName>
              {bodyData.firstName} {bodyData.lastName}
            </StyledName>
            <StyledPosition>
              {bodyData.position}
            </StyledPosition>
          </StyledCardContent>
        </PersonnelCardRoot>
  )};
    
  // const headers = [
  //   { title: '' },
  //   { title: 'Project #' },
  //   { title: 'Project Title' },
  //   { title: 'Project Location' },
  // ];

  // const checkBoxHeaders = [
  //   { title: 'Key' },
  //   { title: 'Comparable' },
  //   { title: 'Underway' },
  // ]

  // const actionsHeader = [
  //   { title: 'Remove' }
  // ]
  
  return (
    <>
      {projects.length === 0 && (
        <StyledBox boxHeight='150px'>
          <PlaceholderText>Selected Personnel will appear here</PlaceholderText>
        </StyledBox>
      )}
      {projects.length > 0 && (
      <BorderLessConciseTable
        body={body}
        data={projects}
        horizontal={true}
        height={'150px'}
        width="860px"
        backgroundColor={'transparent'}
        setPickedArray={setPickedArray}
      />
      )}
  </>
  )
}

export default SelectedProjects;
