import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";

import { AllClientsTable } from "./components/allClientsTable";
import { GoBackButton } from "pages/buyers/components/goBackButton";
import { StyledContainer } from "./styles";
import { AllClientHeader } from "./components/AllClientHeader";
import {
  contractorAlertsSelector,
  serviceProviderBuyerListSelector,
  serviceProviderContractorsListSelector,
} from "data/selectors/serviceProvider";
import {
  getServiceProviderBuyersList,
  getServiceProviderContractorAlerts,
  getServiceProviderContractorsList,
} from "data/actions/serviceProvider";

const AllServiceProviderClientPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  let history = useHistory();
  const location = useLocation();
  const section = location.state?.section || "";
  const [internalSearchResults, setInternalSearchResults] = useState([]);
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [contractorAltersData, setContractorAltersData] = useState([]);
  const serviceProviderBuyerData = useSelector(
    serviceProviderBuyerListSelector
  );
  const serviceProviderContractorData = useSelector(
    serviceProviderContractorsListSelector
  );
  const contractorAlerts = useSelector(contractorAlertsSelector);
  const actualServiceProviderBuyerData = serviceProviderBuyerData?.data;
  const actualServiceProviderContratcorsData =
    serviceProviderContractorData?.data;
  useEffect(() => {
    if (section === "1") {
      if (serviceProviderBuyerData?.length < 1) {
        dispatch(getServiceProviderBuyersList());
      }
      if (contractorAlerts?.length < 1) {
        dispatch(getServiceProviderContractorAlerts());
      }
    }
    if (section === "2") {
      if (serviceProviderContractorData?.length < 1) {
        dispatch(getServiceProviderContractorsList());
      }
    }
  }, []);
  useEffect(() => {
    if (section === "1") {
      if (actualServiceProviderBuyerData) {
        setData(actualServiceProviderBuyerData);
      }
      if (contractorAlerts) {
        setContractorAltersData(contractorAlerts);
      }
    }
    if (section === "2") {
      if (actualServiceProviderContratcorsData) {
        setData(actualServiceProviderContratcorsData);
      }
    }
  }, [
    actualServiceProviderBuyerData,
    actualServiceProviderContratcorsData,
    section,
  ]);

  useEffect(() => {
    if (section === "2") {
      if (contractorAlerts) {
        setContractorAltersData(contractorAlerts);
      }
    }
  }, [contractorAlerts, section]);

  const handleGoBack = () => {
    history.push("/");
  };

  return (
    <StyledContainer isMobile={isMobile}>
      <GoBackButton />
      <AllClientHeader
        title={section === "1" ? "All Buyers" : "All Contractors"}
        hasButton={false}
        // handleButton={handleStartNew}
        setInternalSearchResults={setInternalSearchResults}
        query={query}
        setQuery={setQuery}
        data={data}
      />
      {data && (
        <AllClientsTable
          data={query && query !== "" ? internalSearchResults : data}
          setData={setData}
          selectedTab={section}
          alertsData={contractorAltersData}
        />
      )}
    </StyledContainer>
  );
};

export default AllServiceProviderClientPage;
