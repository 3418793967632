import React from 'react';
import Input from '../../../components/inputField';


export const YearsNumber = ({
  label,
  classificationCode,
  baseRate,
  experienceRate,
  facilities,
  incidentRate,
  setCollectedData,
  year,
}) => {
  return (
    <>
      <Input
        label={label? label[0] : ''}
        id='oneFifthInputWrapNarrow'
        type="text"
        value={classificationCode}
        marginTop={16}
        labelSize="10px"
        setValue={(val) => setCollectedData(`years.classCode${year}`, val)}
        validateFunc={(item) => String(item).length > 0}
      />
      <Input
        label={label? label[1] : ''}
        id='oneFifthInputWrapNarrow'
        type="text"
        value={baseRate}
        marginTop={16}
        labelSize="10px"
        setValue={(val) => setCollectedData(`years.baseRate${year}`, val)}
        validateFunc={(item) => String(item).length > 0}
      />
      <Input
        label={label? label[2] : ''}
        id='oneFifthInputWrapNarrow'
        type="text"
        value={experienceRate}
        marginTop={16}
        labelSize="10px"
        setValue={(val) => setCollectedData(`years.expRate${year}`, val)}
        validateFunc={(item) => String(item).length > 0}
      />
      <Input
        label={label? label[3] : ''}
        id='oneFifthInputWrapNarrow'
        type="text"
        value={facilities}
        marginTop={16}
        labelSize="10px"
        setValue={(val) => setCollectedData(`years.fatalities${year}`, val)}
        validateFunc={(item) => String(item).length > 0}
      />
      <Input
        label={label? label[4] : ''}
        id='oneFifthInputWrapNarrow'
        type="text"
        value={incidentRate}
        marginTop={16}
        labelSize="10px"
        setValue={(val) => setCollectedData(`years.lostRate${year}`, val)}
        validateFunc={(item) => String(item).length > 0}
      />
    </>
  )
};
