
import React from 'react';

const ExitIcon = () => {
  return (
    <svg width="56" height="24" viewBox="0 0 56 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M52.1893 12L44.7197 19.4697C44.4268 19.7626 44.4268 20.2374 44.7197 20.5303C45.0126 20.8232 45.4874 20.8232 45.7803 20.5303L53.7803 12.5303C54.0732 12.2374 54.0732 11.7626 53.7803 11.4697L45.7803 3.46967C45.4874 3.17678 45.0126 3.17678 44.7197 3.46967C44.4268 3.76256 44.4268 4.23744 44.7197 4.53033L52.1893 12Z" fill="#1A051D" stroke="black"/>
      <path d="M9.23047 16.1987H3.61914V12.7705H8.92334V11.0688H3.61914V7.82324H9.23047V6.02197H1.47754V18H9.23047V16.1987ZM15.1987 14.8623L17.0581 18.0166H19.3823L16.4189 13.4263L19.3491 8.94385H17.083L15.29 12.0898H15.1489L13.3477 8.94385H10.957L13.9204 13.5176L10.9819 18.0166H13.2065L15.0576 14.8623H15.1987ZM22.2461 7.4082C22.9268 7.4082 23.4829 6.86865 23.4829 6.18799C23.4829 5.51562 22.9268 4.96777 22.2461 4.96777C21.5737 4.96777 21.0176 5.51562 21.0176 6.18799C21.0176 6.86865 21.5737 7.4082 22.2461 7.4082ZM21.2251 18H23.2754V8.92725H21.2251V18ZM26.4878 6.70264V8.98535H25.0518V10.5874H26.4878V15.5928C26.4878 17.3442 27.3179 18.0415 29.4014 18.0415C29.7998 18.0415 30.1816 18.0083 30.4805 17.9502V16.373C30.2314 16.3979 30.0737 16.4146 29.7832 16.4146C28.9199 16.4146 28.5381 16.0078 28.5381 15.103V10.5874H30.4805V8.98535H28.5381V6.70264H26.4878Z" fill="black"/>
    </svg>
  )
};

export default ExitIcon;
