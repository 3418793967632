import React from 'react';
import { StyledHeadingTitle, StyledContainerTitle } from './styles';

export const SectionTitle = ({ title, color }) => {
  return (
    <StyledContainerTitle>
      <StyledHeadingTitle color={color}>{title}</StyledHeadingTitle>
    </StyledContainerTitle>
  );
};
