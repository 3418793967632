import React from 'react';
import { StyledSpinner } from './styles';


const Spinner = ({ color, size }) => {
  return (
    <StyledSpinner
      size={size}
      color={color}
    />
  );
};

export default Spinner;
