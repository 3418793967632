import React, { useState } from "react";

import {
  MediumText,
  RegularText,
  StyledBox,
  SmallText
} from '../styles';

import { FunctionalButton } from "../../../components/functionalButton";
import {
  FunctionArea,
  IntroArea,
  StyledTitle,
  ButtonContainer,
  ComingSoonImg,
  ImgDiv
} from './styles';

import ReferencesIcon from "../../../assets/dashboardIcons/referencesIcon";
import Rating from "../../../assets/dashboardIcons/rating";
import refComingSoon from 'assets/dashboardIcons/refComingSoon.svg';
import { useHistory } from "react-router";

export const References = ({
  isMobile
}) => {
  const history = useHistory()

  const handleAddRef = () => {
    history.push('/reference')
  }
  const [comingSoon, setComingSoon] = useState(false);

  return (
    <FunctionArea isMobile={isMobile}>
      {isMobile && (
        <StyledBox 
          boxHeight="110px" 
          borderRadius="15px" 
          isMobile={isMobile} 
          onClick={handleAddRef} 
          style={{cursor: "pointer"}}
        >
          {comingSoon &&(
            <ImgDiv>
              <ComingSoonImg src={refComingSoon} />
            </ImgDiv>
          )}
          {!comingSoon && (
            <>
              <ReferencesIcon width={55} height={59} isMobile={isMobile} />
              <Rating width={130} height={19} />
            </>
          )}
        </StyledBox>

      )}
      {!isMobile && (
        <>
          <MediumText>References</MediumText>
          <StyledBox boxHeight="216px">
            {comingSoon && (
              <ImgDiv style={{width: "100%"}}>
                <ComingSoonImg src={refComingSoon} />
              </ImgDiv>
            )}
            {!comingSoon && (
              <>
                <IntroArea>
                  <ReferencesIcon />
                  {/* <StyledTitle>
                    <SmallText>No Rating Yet</SmallText>
                    <Rating />
                  </StyledTitle> */}
                </IntroArea>
                <ButtonContainer>
                  <FunctionalButton
                      buttonTitle="Add Reference"
                      handleButton={handleAddRef}
                      width="80%"
                      marginRight="0px"
                      isReversedColor={true}
                      hasBoxShadow={true}
                    />
                </ButtonContainer>
              </>
            )}
          </StyledBox>
        </>
      )}
    
  </FunctionArea>
  );
}
