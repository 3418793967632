// export const registrateUser = (data, onSuccess) => (dispatch) => {
//   dispatch({
//     type: "REGISTRATE_USER",
//     payload: {
//       endpoint: "/auth/register",
//       method: "POST",
//       body: data,
//     },
//     onSuccess,
//   });
// };

export const activateConciergeService =
  (data, onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "ACTIVATE_CONCIERGE_SERVICE",
      payload: {
        endpoint: "/concierge/activate_service",
        method: "POST",
        body: data,
      },
      onSuccess,
      onFailed,
    });
  };

export const checkAuth = (onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "CHECK_AUTH",
    payload: {
      endpoint: "/auth/check",
      method: "GET",
    },
    onSuccess,
    onFailed,
  });
};

// export const loginUser = (email, password, onSuccess) => (dispatch) => {
//   dispatch({
//     type: "LOGIN_USER",
//     payload: {
//       endpoint: "/auth/login",
//       method: "POST",
//       body: {
//         email,
//         password,
//       },
//     },
//     onSuccess,
//   });
// };

export const getCurrentUser = () => (dispatch) => {
  dispatch({
    type: "GET_CURRENT_USER",
    disableApiMiddleWare: true,
  });
};

export const setCurrentUser = (user) => (dispatch) => {
  setTimeout(() => {
    dispatch({
      type: "SET_CURRENT_USER",
      payload: user,
      disableApiMiddleWare: true,
    });
  }, 1);
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("User");
  localStorage.removeItem("First_Name");
  localStorage.removeItem("Last_Name");
  localStorage.removeItem("Avatar");
  localStorage.removeItem("user_status");
  localStorage.removeItem("user_subscribed");
  localStorage.removeItem("current_dashboard_url");
  dispatchEvent(new Event("storage"));

  dispatch({
    type: "LOGOUT_USER",
    payload: {
      endpoint: "/auth/logout",
      method: "POST",
    },
  });
  localStorage.removeItem("Authorization");
};

export const preQualifyUser =
  (body, action, onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "PREQUALIFY_USER",
      payload: {
        endpoint: `/qualification/save?action=${action}`,
        method: "POST",
        body: body,
        file: true,
      },
      onSuccess,
      onFailed,
    });
  };

export const getPreQualificationInfo =
  (data, onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "GET_PREQUALIFICATION_INFO",
      payload: {
        endpoint: `/qualification`,
        method: "POST",
        body: data,
      },
      onSuccess,
      onFailed,
    });
  };

export const getStandardCategoriesInfo = (onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "GET_STANDARD_CATEGORIES_INFO",
      payload: {
        endpoint: "/categories/standard/get",
        method: "GET",
      },
      onSuccess,
      onFailed,
    });
  };
  
  export const getStandardCategoriesSubCategoriesInfo = (onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "GET_STANDARD_SUB_CATEGORIES_INFO",
      payload: {
        endpoint: "/categories-sub-categories/get",
        method: "GET",
      },
      onSuccess,
      onFailed,
    });
  };
  

export const getUNSPCClassInfo = (onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "GET_UNSPC_FAMILY_INFO",
    payload: {
      endpoint: "/categories/unspc/get",
      method: "GET",
    },
    onSuccess,
    onFailed,
  });
};

export const getUNSPCFamilyClassInfo = (onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "GET_UNSPC_FAMILY_CLASS_INFO",
    payload: {
      endpoint: "/unspc-family-class/get",
      method: "GET",
    },
    onSuccess,
    onFailed,
  });
};


export const getSelectedCCDCInfo = (id, action, data) => (dispatch) => {
  dispatch({
    type: "GET_SELECTED_CCDC_INFO",
    payload: {
      endpoint: `/submissions/details/${id}?action=${action}`,
      method: "POST",
      body: data,
    },
  });
};

export const sendCCDC = (file, onSuccess, onFailed) => (dispatch) => {
  var formData = new FormData();
  formData.append("file", file);

  dispatch({
    type: "WAIT_UPLOAD_CCDC",
    disableApiMiddleWare: true,
  });

  dispatch({
    type: "SEND_CCDC",
    payload: {
      endpoint: `/pdf_to_database`,
      method: "POST",
      body: formData,
      file: true,
    },
  });
};

export const getCCDC = (ccdcId, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "GET_CCDC",
    payload: {
      endpoint: `/database_to_pdf/${ccdcId}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    },
    onSuccess,
    onFailed,
  });
};

export const generateConciergeCode = (onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "GENERATE_CONCIERGE_CODE",
    payload: {
      endpoint: "/concierge/generate_code",
      method: "GET",
    },
    onSuccess,
    onFailed,
  });
};

export const getConciergeServiceStatus =
  (onSuccess, onFailed) => (dispatch) => {
    dispatch({
      type: "GET_CONCIERGE_SERVICE_STATUS",
      payload: {
        endpoint: "/concierge/get_status",
        method: "GET",
      },
      onSuccess,
      onFailed,
    });
  };

export const invalidateConciergeCode = (onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "INVALIDATE_CONCIERGE_CODE",
    payload: {
      endpoint: "/concierge/invalidate_code",
      method: "GET",
    },
    onSuccess,
    onFailed,
  });
};

export const getFillableCCDC = (onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "GET_FILLABLE_CCDC",
    payload: {
      endpoint: `/database_to_pdf/fillable`,
      method: "GET",
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    },
    onSuccess,
    onFailed,
  });
};
