import React, { useState } from 'react';

import { StyledTableCell } from '../../../components/filesListTable/styles';
import ConciseTable from '../../../components/filesListTable/conciseTable';
import {
  StyledProjectSelectionContainer, 
  MobileColumnContent,
  MobileColumnText  
} from './styles';
import { ThreeDotsPopupMenu } from '../../../components/threeDotsPopupMenu';
import { truncateString } from '../../../common/truncateString';

export const ContractorsList = ({
  filteredData,
  editFunc,
  deleteFunc,
  isMobile
}) => {
  const headers = [
    { title: 'Contractor Name' },
    { title: 'Location' },
    { title: 'More Info' },
    { title: '' }
  ];

  const body = (bodyData) => {
    return (
      <>
        {isMobile && (
          <StyledTableCell component={'tr'} width="90%">
            <MobileColumnContent direction="column">
              <MobileColumnText>{truncateString(bodyData.name, 28)}</MobileColumnText>
              <MobileColumnText fontSize="12px" fontWeight="400">{truncateString(bodyData.location, 30)}</MobileColumnText>
            </MobileColumnContent>
          </StyledTableCell>
        )}
        {!isMobile && (
          <>
           <StyledTableCell component={'tr'} width="40%">
              {bodyData.name}
            </StyledTableCell>
            <StyledTableCell component={'tr'} width="25%">
              {bodyData.location}
            </StyledTableCell>
            <StyledTableCell component={'tr'} width="25%">
            </StyledTableCell>
          </>
        )}
        <StyledTableCell component={'tr'} align="right" width="10%">
          <ThreeDotsPopupMenu deleteText='Remove Contractor' item={bodyData} noEditButton={true} handleModify={editFunc} handleDelete={ () => deleteFunc(bodyData.contractor_id) }/>
        </StyledTableCell>
      </>
    );
  };

  return (
    <StyledProjectSelectionContainer isMobile={isMobile}>
        <ConciseTable
          headers={isMobile ? "" : headers}
          length={isMobile ? 5 : 5.5}
          body={body}
          data={filteredData}
        />
      </StyledProjectSelectionContainer>
  );
};
