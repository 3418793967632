import React from 'react';

const PlusSign = ({ enableAdd, onAdd }) => {
  return (
    <svg style={{cursor: "pointer"}} onClick={enableAdd? onAdd : () => {}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C10.7574 0 9.75 0.918628 9.75 2.05181V9.75H2.05181C0.918628 9.75 0 10.7574 0 12C0 13.2426 0.918628 14.25 2.05181 14.25H9.75V21.9482C9.75 23.0814 10.7574 24 12 24C13.2426 24 14.25 23.0814 14.25 21.9482V14.25H21.9482C23.0814 14.25 24 13.2426 24 12C24 10.7574 23.0814 9.75 21.9482 9.75H14.25V2.05181C14.25 0.918628 13.2426 0 12 0Z" fill= {enableAdd ? "#FF6D1D" : "#ECEBED"}/>
    </svg>
  );
};

export default PlusSign;
