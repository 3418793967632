import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FunctionalButton } from "components/functionalButton";
import PledgxLogo from "assets/common/pledgxLogo";
import { useHistory } from "react-router-dom";
import Input from "components/inputField";
import { ReactComponent as RedRectanglurLine } from "pages/underwriters/components/contractStatusReportSending/assets/redRectanglurLine.svg";
import { formatPhoneNumber } from "common/formatPhoneNumber";
import { SubmitStatusReportDownload } from "./submitStatusReportDownload"
import { contactSelector } from "data/selectors/contact";
import { convertPhoneNumberCanadaUSA } from "common/regex";
import { NumberFormatOnly, NumberFormatWithCurrency } from 'components/locale/numberFormatOnly'

import {
  FormTitle,
  TitleDiv,
  ButtonDiv,
  StyledMainCSR,
  StyledLeftCSR,
  StyledRightCSR,
  TextTitle,
  LabelText,
  ScreenRoot,
  FormContainer,
  FieldArea,
  StyledLeftCSRLeft,
  StyledRightCSRRight,
  StyledLeftCSRLeftLeft,
  StyledRightCSRRightRight,
  StyledMainCSRRight,
  StyledMainCSRLeft,
  StyledEditRecordButton,
  StyledRightCSRRemarks,
  StyledIsProgressSatisfactory,
} from "./styles.js";
import {
  getClientBondsInfo,
  latestStatusReportDetails,
  financialDataAnalysis
} from "data/actions/underwriters.js";
import {
  clientBondsSelector,
  latestStatusReportDetailSelector,
  financialDataAnalysisSelector
} from "data/selectors/underwriters.js";
import ConvertToDate from "common/convertToDate.js";
import { numberWithCommas } from "common/numberFormat.js";
import { COLORS, FONT_SIZES } from "constants.js";
import { RadioButton } from "components/radioButton/index.js";
import CheckedRadioButton from "assets/dashboardIcons/checkedRadioButton.js";
import UncheckedRadioButton from "assets/dashboardIcons/uncheckedRadioButton.js";
import InputArea from "components/inputAreaBG/index.js";


function formatDate(bondDate) {
  let formattedEffectiveDate = "";

  if (bondDate !== "") {
    const dateString = bondDate;
    const dateObject = new Date(dateString);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear().toString();
    formattedEffectiveDate = `${month}/${day}/${year}`;
  }

  return formattedEffectiveDate;
}


function ContractStatusReportViewing({ isMobile }) {
  let history = useHistory();
  const locationState = history.location.state;
  const dispatch = useDispatch();
  const { statusReportData } = locationState;
  const projectData = statusReportData;
  const [bondData, setBondData] = useState([]);
  const clientBonds = useSelector(clientBondsSelector);
  const latestreportDetails = useSelector(latestStatusReportDetailSelector);
  const financialDataAnalysisDetails = useSelector(financialDataAnalysisSelector);
  const lastStatusReportDetails = latestreportDetails[0];
  const [openDownloadPopup, setOpenDownloadPopup] = useState(false);
  const [addData, setAddData] = useState({});
  const contactData = useSelector(contactSelector);
  const companyLogo = contactData?.company_logo?.file;


  useEffect(() => {
    dispatch(
      getClientBondsInfo({
        account_id: projectData?.account_id,
        bond_id: projectData?.bond_id,
      })
    );
    dispatch(
      latestStatusReportDetails({
        account_id: projectData?.account_id,
        project_number: projectData?.project_number,
      })
    );
    dispatch(
      financialDataAnalysis({
        account_id: projectData?.account_id,
        project_number: projectData?.project_number,
      })
    );
  }, []);
  useEffect(() => {
    if (clientBonds) {
      setBondData(clientBonds);
    }
  }, [clientBonds]);


  const handleGoBack = () => {
    history.goBack();
  };

  const downloadStatusReport = () => {
    const updatedData = { ...lastStatusReportDetails };
    updatedData["effective_date"] = formatDate(bondData[0]?.effective_date);
    updatedData["obligee"] = bondData[0]?.obligee;
    updatedData["anticipated_completion_date"] = formatDate(lastStatusReportDetails?.anticipated_completion_date);
    updatedData["reporting_date"] = formatDate(lastStatusReportDetails?.reporting_date);
    updatedData["current_contract_price"] = NumberFormatOnly(Math.trunc(lastStatusReportDetails?.current_contract_price));
    updatedData["paid_to_contractor_amount"] = NumberFormatOnly(Math.trunc(lastStatusReportDetails?.paid_to_contractor_amount));
    updatedData["holdback_amount"] = NumberFormatOnly(Math.trunc(lastStatusReportDetails?.holdback_amount));
    updatedData["work_approved_amount"] = NumberFormatOnly(Math.trunc(lastStatusReportDetails?.work_approved_amount));
    updatedData["project_actual_value"] = NumberFormatOnly(Math.trunc(lastStatusReportDetails?.project_actual_value));
    if (companyLogo === null) {
      updatedData["companyLogo"] = "";
    } else {
      updatedData["companyLogo"] = companyLogo;
    }
    setAddData(updatedData);
    setOpenDownloadPopup(true);
  };

  return (
    <>
      {!lastStatusReportDetails && (
        <div
          style={{
            marginTop: "150px",
            color: "black",
            fontSize: FONT_SIZES.body_4,
            fontWeight: "400",
          }}
        >
          <div
            style={{
              color: COLORS.textGrey,
              fontSize: FONT_SIZES.body_4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No Status Report To View
          </div>
        </div>
      )}
      {lastStatusReportDetails && (
        <ScreenRoot isMobile={isMobile}>
          <FormContainer isMobile={isMobile}>
            <TitleDiv>
              <PledgxLogo
                width={isMobile ? 45 : ""}
                height={isMobile ? 45 : ""}
              />
              <FormTitle style={{ marginTop: 10 }}>
                {"Contract Status Report"}
              </FormTitle>
            </TitleDiv>

            <FieldArea width={isMobile ? "100%" : "100%"}>
              <StyledMainCSR>
                <StyledLeftCSR>
                  <TextTitle>Bonding Company Details</TextTitle>
                  <Input
                    label="Company Name"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="0px"
                    backgroundColor="#EBE9F1"
                    value={lastStatusReportDetails?.company_name || ""}
                    disabled
                  />

                  <Input
                    label="Company Address"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    backgroundColor="#EBE9F1"
                    value={lastStatusReportDetails?.company_address || ""}
                    disabled
                  />

                  <Input
                    label="Contractor Name"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    backgroundColor="#EBE9F1"
                    value={lastStatusReportDetails?.contractor_name || ""}
                    disabled
                  />
                  <div style={{ marginTop: "18px" }}></div>
                  <TextTitle>Bond Details</TextTitle>
                  <Input
                    label="Bond Id"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="0px"
                    backgroundColor="#EBE9F1"
                    value={lastStatusReportDetails?.bond_id || ""}
                    disabled
                  />

                  <div style={{ marginTop: "18px" }}></div>
                  <StyledMainCSR>
                    <StyledLeftCSRLeft>
                      <Input
                        label="Effective Date"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="0px"
                        backgroundColor="#EBE9F1"
                        value={ConvertToDate(bondData[0]?.effective_date) || ""}
                        disabled
                      />
                    </StyledLeftCSRLeft>

                    <StyledRightCSRRight>
                      <Input
                        label="Obligee"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="0px"
                        backgroundColor="#EBE9F1"
                        value={
                          bondData[0]?.Obligee !== "NULL"
                            ? bondData[0]?.obligee
                            : ""
                        }
                        disabled
                      />
                    </StyledRightCSRRight>
                  </StyledMainCSR>
                </StyledLeftCSR>

                <StyledRightCSR>
                  <TextTitle>Project Details</TextTitle>
                  <Input
                    label="Project Title"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="0px"
                    backgroundColor="#EBE9F1"
                    value={lastStatusReportDetails?.project_title || ""}
                    disabled
                  />
                  <Input
                    label="Project Status"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    backgroundColor="#EBE9F1"
                    value={lastStatusReportDetails?.project_status || ""}
                    disabled
                  />

                  <Input
                    label="Orginal Contract Price($)"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    backgroundColor="#EBE9F1"
                    value={
                      NumberFormatOnly(
                        lastStatusReportDetails?.project_actual_value
                      ) || ""
                    }
                    disabled
                  />
                  <div style={{ marginTop: "18px" }}></div>
                  <TextTitle>Report Details</TextTitle>
                  {projectData?.project_status === "Underway" && (
                    <>
                      <StyledMainCSR>
                        <StyledLeftCSRLeft>
                          <Input
                            label="Anticipated Completion Date"
                            type="text"
                            labelSize="12px"
                            fontSize="18px"
                            marginTop="0px"
                            backgroundColor="#EBE9F1"
                            value={
                              ConvertToDate(
                                lastStatusReportDetails?.anticipated_completion_date
                              ) || ""
                            }
                            disabled
                          />
                        </StyledLeftCSRLeft>

                        <StyledRightCSRRight>
                          <Input
                            label="Reporting Date"
                            type="text"
                            labelSize="12px"
                            fontSize="18px"
                            marginTop="0px"
                            backgroundColor="#EBE9F1"
                            value={ConvertToDate(
                              lastStatusReportDetails?.reporting_date
                            )}
                            disabled
                          />
                        </StyledRightCSRRight>
                      </StyledMainCSR>

                      <StyledMainCSR>
                        <StyledMainCSRLeft>
                          <StyledLeftCSRLeftLeft>
                            <Input
                              label="Current Contract Price($)"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              backgroundColor="#EBE9F1"
                              value={
                                NumberFormatOnly(
                                  Math.trunc(
                                    lastStatusReportDetails?.current_contract_price
                                  )
                                ) || ""
                              }
                              disabled
                            />
                          </StyledLeftCSRLeftLeft>
                          <StyledRightCSRRightRight>
                            <Input
                              label="%"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              backgroundColor="#EBE9F1"
                              value={
                                lastStatusReportDetails?.current_contract_price_percent ||
                                ""
                              }
                              disabled
                            />
                          </StyledRightCSRRightRight>
                        </StyledMainCSRLeft>

                        <StyledMainCSRRight>
                          <StyledLeftCSRLeftLeft>
                            <Input
                              label="Work Approved Amount($)"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              backgroundColor="#EBE9F1"
                              value={
                                NumberFormatOnly(
                                  Math.trunc(
                                    lastStatusReportDetails?.work_approved_amount
                                  )
                                ) || ""
                              }
                              disabled
                            />
                          </StyledLeftCSRLeftLeft>
                          <StyledRightCSRRightRight>
                            <Input
                              label="%"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              backgroundColor="#EBE9F1"
                              value={
                                lastStatusReportDetails?.work_approved_amount_percent ||
                                ""
                              }
                              disabled
                            />
                          </StyledRightCSRRightRight>
                        </StyledMainCSRRight>
                      </StyledMainCSR>

                      <StyledMainCSR>
                        <StyledMainCSRLeft>
                          <StyledLeftCSRLeftLeft>
                            <Input
                              label="Paid to Contractor Amount($)"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              backgroundColor="#EBE9F1"
                              value={
                                NumberFormatOnly(
                                  Math.trunc(
                                    lastStatusReportDetails?.paid_to_contractor_amount
                                  )
                                ) || ""
                              }
                              disabled
                            />
                          </StyledLeftCSRLeftLeft>
                          <StyledRightCSRRightRight>
                            <Input
                              label="%"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              backgroundColor="#EBE9F1"
                              value={
                                lastStatusReportDetails?.work_approved_amount_percent ||
                                ""
                              }
                              disabled
                            />
                          </StyledRightCSRRightRight>
                        </StyledMainCSRLeft>

                        <StyledMainCSRRight>
                          <StyledLeftCSRLeftLeft>
                            <Input
                              label="Holdback Amount($)"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              backgroundColor="#EBE9F1"
                              value={
                                NumberFormatOnly(
                                  Math.trunc(
                                    lastStatusReportDetails?.holdback_amount
                                  )
                                ) || ""
                              }
                              disabled
                            />
                          </StyledLeftCSRLeftLeft>
                          <StyledRightCSRRightRight>
                            <Input
                              label="%"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              backgroundColor="#EBE9F1"
                              value={
                                lastStatusReportDetails?.holdback_amount_percent ||
                                ""
                              }
                              disabled
                            />
                          </StyledRightCSRRightRight>
                        </StyledMainCSRRight>
                      </StyledMainCSR>
                    </>
                  )}
                  {projectData?.project_status === "Completed" && (
                    <>
                      <StyledMainCSR>
                        <StyledMainCSRLeft>
                          <Input
                            label="Completion Date"
                            type="text"
                            labelSize="12px"
                            fontSize="18px"
                            marginTop="16px"
                            backgroundColor="#EBE9F1"
                            value={
                              ConvertToDate(
                                lastStatusReportDetails?.anticipated_completion_date
                              ) || ""
                            }
                            disabled
                          />
                        </StyledMainCSRLeft>

                        <StyledMainCSRRight>
                          <StyledLeftCSRLeftLeft>
                            <Input
                              label="Final Contract Price($)"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              backgroundColor="#EBE9F1"
                              value={
                                NumberFormatOnly(
                                  Math.trunc(
                                    lastStatusReportDetails?.current_contract_price
                                  )
                                ) || ""
                              }
                              disabled
                            />
                          </StyledLeftCSRLeftLeft>
                          <StyledRightCSRRightRight>
                            <Input
                              label="%"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              backgroundColor="#EBE9F1"
                              value={
                                lastStatusReportDetails?.current_contract_price_percent ||
                                ""
                              }
                              disabled
                            />
                          </StyledRightCSRRightRight>
                        </StyledMainCSRRight>
                      </StyledMainCSR>
                    </>
                  )}
                  <StyledMainCSR>
                    <StyledIsProgressSatisfactory>
                      <LabelText style={{ marginTop: "16px" }}>Is Progress Satisfactory?</LabelText>
                      <StyledEditRecordButton style={{ display: 'flex', flexDirection: 'column', marginLeft: '-160px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-60px', marginTop: '0px' }}>
                          <RadioButton
                            isChecked={
                              lastStatusReportDetails?.is_progress_satisfactory ===
                                1
                                ? true
                                : false
                            }
                            value={
                              lastStatusReportDetails?.is_progress_satisfactory
                            }
                            checkedIcon={<CheckedRadioButton color="#D0C9D6" />}
                            unCheckedIcon={
                              <UncheckedRadioButton color="#D0C9D6" />
                            }
                            disabled
                          />
                          <span style={{ marginLeft: '5px' }}>Yes</span>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-62px' }}>
                          <RadioButton
                            isChecked={
                              lastStatusReportDetails?.is_progress_satisfactory ===
                                0
                                ? true
                                : false
                            }
                            value={
                              lastStatusReportDetails?.is_progress_satisfactory
                            }
                            checkedIcon={<CheckedRadioButton color="#D0C9D6" />}
                            unCheckedIcon={
                              <UncheckedRadioButton color="#D0C9D6" />
                            }
                            disabled
                          />
                          <span style={{ marginLeft: '5px' }}>No</span>
                        </div>
                      </StyledEditRecordButton>
                    </StyledIsProgressSatisfactory>


                    <StyledRightCSRRemarks>
                      <LabelText style={{ marginTop: "16px", marginBottom: "16px" }}> Remarks </LabelText>
                      <InputArea
                        backgroundColor="#EBE9F1"
                        placeholder={"No Remarks"}
                        value={lastStatusReportDetails?.remarks || ""}
                        readOnly={true}
                      />
                    </StyledRightCSRRemarks>
                  </StyledMainCSR>

                </StyledRightCSR>
              </StyledMainCSR>

              <StyledMainCSR>
                <StyledLeftCSR>
                  <div style={{ marginTop: "48px" }}> </div>
                  <RedRectanglurLine />
                </StyledLeftCSR>

                <StyledRightCSR>
                  <div style={{ marginTop: "48px" }}> </div>
                  <RedRectanglurLine />
                </StyledRightCSR>
              </StyledMainCSR>

              <StyledMainCSR>
                <StyledLeftCSR>
                  <TextTitle>Report Requested by</TextTitle>

                  <StyledMainCSR>
                    <StyledLeftCSRLeft>
                      <Input
                        label="Name"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="0px"
                        backgroundColor="#EBE9F1"
                        value={lastStatusReportDetails?.requseter_name || ""}
                        disabled
                      />
                    </StyledLeftCSRLeft>

                    <StyledRightCSRRight>
                      <Input
                        label="Role"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="0px"
                        backgroundColor="#EBE9F1"
                        value={lastStatusReportDetails?.requseter_role || ""}
                        disabled
                      />
                    </StyledRightCSRRight>
                  </StyledMainCSR>

                  <StyledMainCSR>
                    <StyledLeftCSRLeft>
                      <Input
                        label="Email"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        backgroundColor="#EBE9F1"
                        value={lastStatusReportDetails?.requseter_email || ""}
                        disabled
                      />
                    </StyledLeftCSRLeft>

                    <StyledRightCSRRight>
                      <Input
                        label="Phone Number"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        backgroundColor="#EBE9F1"
                        value={
                          convertPhoneNumberCanadaUSA(lastStatusReportDetails?.requseter_phone_number) || ""
                        }
                        disabled
                      />
                    </StyledRightCSRRight>
                  </StyledMainCSR>
                </StyledLeftCSR>

                <StyledRightCSR>
                  <TextTitle>Report Issued by</TextTitle>
                  <StyledMainCSR>
                    <StyledLeftCSRLeft>
                      <Input
                        label="Name"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="0px"
                        backgroundColor="#EBE9F1"
                        value={lastStatusReportDetails?.issuer_name || ""}
                        disabled
                      />
                    </StyledLeftCSRLeft>

                    <StyledRightCSRRight>
                      <Input
                        label="Role"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="0px"
                        backgroundColor="#EBE9F1"
                        value={lastStatusReportDetails?.issuer_role || ""}
                        disabled
                      />
                    </StyledRightCSRRight>
                  </StyledMainCSR>

                  <StyledMainCSR>
                    <StyledLeftCSRLeft>
                      <Input
                        label="Email"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        backgroundColor="#EBE9F1"
                        value={lastStatusReportDetails?.issuer_email || ""}
                        disabled
                      />
                    </StyledLeftCSRLeft>

                    <StyledRightCSRRight>
                      <Input
                        label="Phone Number"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        backgroundColor="#EBE9F1"
                        value={
                          convertPhoneNumberCanadaUSA(lastStatusReportDetails?.issuer_phone_number) || ""
                        }
                        disabled
                      />
                    </StyledRightCSRRight>
                  </StyledMainCSR>
                </StyledRightCSR>
              </StyledMainCSR>
            </FieldArea>

            <ButtonDiv isMobile={isMobile}>
              <FunctionalButton
                buttonTitle="GO BACK"
                handleButton={handleGoBack}
                isReversedColor={true}
                height="48px"
                width="199px"
                marginTop="64px"
                marginRight="16px"
              />
              <FunctionalButton
                buttonTitle={"DOWNLOAD"}
                height="48px"
                width="199px"
                marginTop="64px"
                marginRight="0px"
                handleButton={() => downloadStatusReport()}
              />
            </ButtonDiv>
          </FormContainer>

          <SubmitStatusReportDownload
            isMobile={isMobile}
            data={addData}
            openPopup={openDownloadPopup}
            setOpenPopup={setOpenDownloadPopup}
          />

        </ScreenRoot>


      )}
    </>
  );
}

export default ContractStatusReportViewing;