import React from 'react';

const DeleteIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4167 3.33333H10.9167V1.58333C10.9167 1.26117 10.6555 1 10.3333 1H5.66667C5.3445 1 5.08333 1.26117 5.08333 1.58333V3.33333H1.58333C1.26117 3.33333 1 3.5945 1 3.91667C1 4.23883 1.26117 4.5 1.58333 4.5H14.4167C14.7388 4.5 15 4.23883 15 3.91667C15 3.5945 14.7388 3.33333 14.4167 3.33333ZM6.25 2.16667H9.75V3.33333H6.25V2.16667ZM2.75 5.66667V13.25C2.75 14.2165 3.5335 15 4.5 15H11.5C12.4665 15 13.25 14.2165 13.25 13.25V5.66667H2.75ZM6.25 8V12.0833H5.08333V8H6.25ZM7.41667 12.0833H8.58333V8H7.41667V12.0833ZM10.9167 12.0833H9.75V8H10.9167V12.0833Z" fill="#1A051D"/>
    </svg>
  );
};

export default DeleteIcon;
