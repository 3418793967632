import React from 'react';
import { KeyOfficePersonnelForm }  from '../keyOfficePersonnelForm/index';
import { KeySitePersonnelForm }  from '../keySitePersonnelForm/index';


export const KeyPersonnelForm = ({
  setActiveStep,
  listPanel,
  qualificationData,
  setQualificationData,
  setIsLastPanel,
  setPreviousStepPanel,
}) => {

  return (
    <>
      { listPanel == 1 && (
        <KeyOfficePersonnelForm
          setActiveStep={setActiveStep}
          qualificationData={qualificationData}
          setQualificationData={setQualificationData}
          setIsLastPanel={setIsLastPanel}
          setPreviousStepPanel={setPreviousStepPanel}
        />
      )}
      {/* { listPanel == 2 && (
        <KeySitePersonnelForm
          setActiveStep={setActiveStep}
          qualificationData={qualificationData}
          setQualificationData={setQualificationData}
          setIsLastPanel={setIsLastPanel}
        />
      )} */}
    </>
  );
}
