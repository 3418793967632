import * as React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Concierge } from "../../contractorDashboard/profileSetup/Concierge.js"
import { StyledTab, StyledText } from '../styles'
import ConciergePaid from './ConciergePaid'
import Upload from './Upload'

export const Tools = () => {
  const [selectTab, setSelectTab] = React.useState('1');

  const handleChange = (event, newValue) => {
    setSelectTab(newValue);
  };

  return (
    <TabContext value={selectTab}>

      <TabList onChange={handleChange} variant="fullWidth" centered TabIndicatorProps={{hidden:true}}>
        <StyledTab label="Concierge Service" value="1" />
        <StyledTab label="Concierge Paid" value="2" />
        <StyledTab label="Upload" value="3" />
      </TabList>

      <TabPanel value="1">
        <Concierge />
      </TabPanel>
      <TabPanel value="2">
        <ConciergePaid />
      </TabPanel>
      <TabPanel value="3">
        <Upload />
      </TabPanel>

    </TabContext>

  );
}
