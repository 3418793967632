import React, { useState, useRef, useEffect } from "react";

import { StyledQuestion, StyledCenter } from "../styles";

import { FunctionalButton } from "components/functionalButton";
import { AlertPopup } from "components/alertPopup/alertPopup";

import { FormContainer } from "pages/newPreQualify/styles";

import { WrapperForUploadedFileDisplay } from "pages/preQualifyForm/components/uploadedFileDisplay";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { useSelector } from "react-redux";
import { contactSelector } from "../../../../data/selectors/contact";

import {
  PopupBodyText,
  PopupTitle,
} from "../../../../components/alertPopup/styles";
import {
  StyledButton,
  StyledButtonReview,
  StyledMenuItem,
} from "components/addFinalizePopUp/styles";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";
import DocumentPoolPopUp from "components/documentPoolPopUp";
import { ScrollableDiv } from "pages/documentPool/styles";
import ConciseTable from "components/filesListTable/conciseTable";
import { COLORS } from "../../../../constants";
import { institutionProfileInfoSelector } from "data/selectors/serviceProvider";
//import { SubmissionPreview } from "./submissionPreview";

export const AdditionalDocuments = ({
  handleNext,
  setIsLastPanel,
  setPreviousStepPanel,
  prequalifyData,
  setPrequalifyData,
  action,
  setAction,
  fileType,
  setFileType,
  panel,
  setPanel,
  updateCachedData,
  setPdfFileSequence,
  rfpq,
  institutionId,
}) => {
  setPreviousStepPanel(2);
  const currentAdditionalRequirementArray = [
    ...prequalifyData?.additional_document_requirement,
  ];
  const headers = [];
  !rfpq
    ? headers.push(
        { title: "Title", width: "48%" },
        { title: "Size(MB)", width: "18%" },
        { title: "" }
      )
    : headers.push(
        { title: "Title", width: "38%" },
        { title: "Requirement Match", width: "40%" },
        { title: "Size(MB)", width: "18%" },
        { title: "" }
      );
  const [missingRequirementPopUp, setMissingRequirementPopUp] = useState(false);
  const [internalSearchResults, setInternalSearchResults] = useState(
    prequalifyData?.additional_document
  );
  const [query, setQuery] = useState("");
  const currentDashboardUrl = localStorage.getItem("current_dashboard_url");
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [openDocumentPool, setOpenDocumentPool] = useState(false);
  let contactData = useSelector(contactSelector);
  const institutionContactData = useSelector(institutionProfileInfoSelector);

  if (currentDashboardUrl === "/service-provider-dashboard") {
    contactData = institutionContactData;
  }
  const fileLimitCount = contactData?.user_access_control?.file_limit_count;
  const fileLimitSize = contactData?.user_access_control?.file_limit_size;

  const fileLimitSizeMB = (fileLimitSize / (1024 * 1024)).toFixed(2);
  let requirements = [];
  if (rfpq) {
    if (rfpq?.rfpq?.requirements?.length > 0)
      requirements = [{ title: "None" }, ...rfpq?.rfpq?.requirements];
  }

  useEffect(() => {
    let temp = [...prequalifyData?.additional_document];

    for (let requirement in currentAdditionalRequirementArray) {
      const missingFileId = temp.some(
        (document) =>
          document.file_id ===
          currentAdditionalRequirementArray[requirement].file_id
      );

      if (!missingFileId) {
        currentAdditionalRequirementArray.splice(requirement, 1);
        setPrequalifyData(
          "additional_document_requirement",
          currentAdditionalRequirementArray
        );
      }
    }
  }, [prequalifyData?.additional_document]);

  const addUploadedFile = () => {
    let temp = [...prequalifyData?.additional_document];

    for (let key in selectedFiles) {
      if (!isNaN(key)) {
        temp.push(selectedFiles[key]);
      }
    }

    setPrequalifyData("additional_document", temp);
    setOpenDocumentPool(false);
  };

  const deleteAdditionalFile = (index) => {
    let temp = [...prequalifyData?.additional_document];

    temp.splice(index, 1);

    setPrequalifyData("additional_document", temp);
  };

  // const handleOnDragEnd = (resultEvent) => {
  //   if (!resultEvent.destination) {
  //     return;
  //   }

  //   const temp = [...prequalifyData?.additional_document];
  //   const [reorderedItem] = temp.splice(resultEvent.source.index, 1);
  //   temp.splice(resultEvent.destination.index, 0, reorderedItem);
  //   setPrequalifyData("additional_document", temp);
  // };

  const handleDocumentPoolPopUp = () => {
    setOpenDocumentPool(true);
  };
  const handleGoBackClick = () => {
    setMissingRequirementPopUp(false);
  };
  const handlerequirementCheck = () => {
    if (prequalifyData?.project_info?.rfpq_id && rfpq?.rfpq?.requirements) {
      const mandatoryRequirement = rfpq?.rfpq?.requirements?.filter(
        (item) => item.mandatory
      );
      const requirementMatch = mandatoryRequirement?.every((obj1) => {
        return currentAdditionalRequirementArray?.some(
          (obj2) => obj2.requirement_id === obj1.id
        );
      });

      if (!requirementMatch) {
        setMissingRequirementPopUp(true);
      } else {
        handleNext();
      }
    } else {
      handleNext();
    }
  };
  const bodyContent = (
    <>
      <PopupTitle>You are missing document(s)</PopupTitle>
      <PopupBodyText style={{ width: "65%" }}>
        You have not uploaded all the mandatory documents. Would you like to
        proceed?
      </PopupBodyText>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItem: "center",
          marginTop: "37px",
        }}
      >
        <StyledMenuItem>
          <StyledButton
            title="Go Back"
            width="200px"
            height="48px"
            onClick={handleGoBackClick}
          >
            GO BACK
          </StyledButton>
        </StyledMenuItem>

        <StyledMenuItem>
          <StyledButtonReview
            title="Review"
            width="200px"
            height="48px"
            onClick={() => handleNext()}
          >
            PROCEED
          </StyledButtonReview>
        </StyledMenuItem>
      </div>
    </>
  );
  return (
    <Switch>
      <Route exact path="/prequalify/additional/documents">
        <FormContainer>
          <StyledQuestion marginBottom="148" weight="500">
            What Documents do you want to showcase?
          </StyledQuestion>
          <div style={{ display: "flex", width: "100%" }}>
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                justifyContent: "flex-start",
                width: "70%",
              }}
            >
              <SearchBar
                filteredData={prequalifyData?.additional_document}
                setInternalSearchResults={setInternalSearchResults}
                internalSearch={true}
                query={query}
                setQuery={setQuery}
                searchWidth="100%"
                searchPlaceHolder="File Name..."
              />
            </div>
            <FunctionalButton
              buttonTitle="Document Pool"
              marginLeft="20px"
              handleButton={() => handleDocumentPoolPopUp()}
              width="250px"
              isReversedColor={true}
            />
          </div>
          {prequalifyData?.additional_document?.length < 1 && (
            <div
              style={{
                alignItems: "center",
                backgroundColor: "white",
                border: `dashed 2px ${COLORS.lightGrey1}`,
                display: "flex",
                height: "186px",
                color: `${COLORS.textGrey}`,
                justifyContent: "center",
                width: "90%",
                marginTop: "20px",
              }}
            >
              Selected Documents will appear here
            </div>
          )}
          {prequalifyData?.additional_document?.length > 0 && (
            <div style={{ marginTop: "20px", width: "90%" }}>
              <ConciseTable headers={headers} />

              <ScrollableDiv style={{ maxHeight: "400px" }}>
                <div style={{ marginRight: "15px" }}>
                  {prequalifyData?.additional_document?.length > 0 &&
                    (query && query !== ""
                      ? internalSearchResults
                      : prequalifyData?.additional_document
                    )?.map((item, i) => (
                      <StyledCenter style={{ marginTop: 0 }}>
                        <WrapperForUploadedFileDisplay
                          key={`file-${i}`}
                          file={item}
                          displayPercentage="100%"
                          deleteFile={deleteAdditionalFile}
                          index={i}
                          ccdcId={prequalifyData?.ccdcId}
                          setPrequalifyData={setPrequalifyData}
                          prequalifyData={prequalifyData}
                          setOpenPopup={setOpenPopup}
                          openPopup={openPopup}
                          requirements={requirements}
                          currentAdditionalRequirementArray={
                            currentAdditionalRequirementArray
                          }
                        />
                      </StyledCenter>
                    ))}
                </div>
              </ScrollableDiv>
            </div>
          )}

          <FunctionalButton
            buttonTitle="Next"
            handleButton={() => handlerequirementCheck()}
            width="200px"
            marginRight="0px"
            marginTop="48px"
          />

          <AlertPopup
            openPopup={missingRequirementPopUp}
            setOpenPopup={setMissingRequirementPopUp}
            bodyContent={bodyContent}
            height="488px"
          />
          <AlertPopup
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            headerText={"You have exceeded the file limit."}
            subHeaderText={`You can upload a maximum of ${fileLimitCount} documents or ${fileLimitSizeMB}MB.`}
          />
          <DocumentPoolPopUp
            openPopup={openDocumentPool}
            setOpenPopup={setOpenDocumentPool}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            attachFile={addUploadedFile}
            institutionId={institutionId}
          />
        </FormContainer>
      </Route>
    </Switch>
  );
};

export default AdditionalDocuments;
