
import React from 'react';

const MobileSaveButtonIcon = () => {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="31" height="31" rx="6" fill="#FF6D1D"/>
    <path d="M15.9978 23.9996C13.6172 23.9996 11.2367 23.9996 8.85641 23.9996C7.89495 23.9996 7.17135 23.3837 7.01911 22.4413C7.00416 22.33 6.99796 22.2176 7.00059 22.1053C7.00059 17.3693 7.00059 12.6331 7.00059 7.89675C7.00259 6.77009 7.77076 6.00295 8.89698 6.00295C12.833 6.00295 16.769 6.00212 20.705 6.00045C20.8138 5.99674 20.9222 6.01611 21.023 6.05728C21.1238 6.09844 21.2147 6.16048 21.2899 6.2393C22.4446 7.39935 23.6017 8.55657 24.7612 9.71094C24.84 9.78604 24.902 9.877 24.943 9.97782C24.9841 10.0786 25.0034 10.187 24.9995 10.2958C24.9965 14.2326 24.9965 18.1691 24.9995 22.1053C24.9995 23.232 24.2318 23.9986 23.1051 23.9991C20.7372 24.0001 18.3681 24.0003 15.9978 23.9996ZM12.4008 7.20173H8.93704C8.41374 7.20173 8.20242 7.41504 8.20242 7.94282V22.1138C8.20242 22.5615 8.43778 22.7993 8.88045 22.8003C9.19894 22.8003 9.51742 22.8003 9.83591 22.8003C9.88949 22.8003 9.94257 22.7938 10.0022 22.7893V22.5665C10.0022 21.2792 10.0022 19.9922 10.0022 18.7053C10.0022 17.5666 10.7673 16.8024 11.9051 16.8024H20.096C21.2348 16.8024 21.9989 17.5671 21.9989 18.7053C21.9989 19.9985 21.9989 21.2919 21.9989 22.5855V22.7998C22.3935 22.7998 22.7671 22.7998 23.1407 22.7998C23.5513 22.7998 23.7977 22.5565 23.7982 22.1489C23.7982 18.2942 23.797 14.4393 23.7947 10.5842C23.7928 10.5005 23.761 10.4202 23.705 10.3579C22.6885 9.33338 21.6684 8.31237 20.6449 7.29486C20.6056 7.24838 20.5518 7.2166 20.4921 7.20473C20.2007 7.19572 19.9088 7.20022 19.5993 7.20022V7.41955C19.5993 8.51317 19.5993 9.60662 19.5993 10.6999C19.5993 11.5011 19.103 11.9988 18.3038 12.0018H13.7469C12.8785 12.0018 12.3983 11.5246 12.3983 10.6613L12.4008 7.20173ZM20.7991 22.7908V22.6115C20.7991 21.3056 20.7991 19.9998 20.7991 18.6942C20.7991 18.2341 20.5668 18.0002 20.1096 18.0002H11.9006C11.4278 18.0002 11.1995 18.2276 11.1995 18.7013C11.1995 20.0012 11.1995 21.3008 11.1995 22.6V22.7903L20.7991 22.7908ZM13.6142 7.20974V10.786H18.3894V7.20974H13.6142Z" fill="white"/>
    </svg>
  )
};

export default MobileSaveButtonIcon;


