import React from "react";

import {
  StyledCellTitle,
  StyledCellCommon,
  StyledCellAction,
  StyledAlertCircle,
  StyledAlertText,
} from "./styles";

import { ActionsMenu } from "./ActionsMenu";
import ConciseTable from "components/filesListTable/conciseTable";
import { useHistory, useLocation } from "react-router-dom";

export const AllClientsTable = ({
  data,
  isMobile,
  borderRadius,
  boxShadow,
  isShortened,
  selectedTab,
  alertsData,
}) => {
  let history = useHistory();

  const seeAllAlertsPage = (id) => {
    const institutionAlertData = alertsData?.find(
      (item) => item.institutionId === id
    );
    history.push(`/client/alerts/${id}/`, { alertsData: institutionAlertData });
  };
  const body = (bodyData) => {
    let alertsCount = 0;
    if (selectedTab === "2") {
      const institutionAlertData = alertsData?.find(
        (item) => item.institutionId === bodyData?.id
      );
      alertsCount =
        institutionAlertData?.insurance?.length +
        institutionAlertData?.projects?.length +
        (institutionAlertData?.wip_updated === false ? 1 : 0);
    }
    return (
      <>
        <StyledCellTitle component={"tr"} borderRadius={borderRadius}>
          {bodyData?.agent_account_id}
        </StyledCellTitle>
        {!isShortened && (
          <StyledCellCommon
            component={"tr"}
            scope="row"
            borderRadius={borderRadius}
            width="27%"
          >
            {bodyData?.institution_name}
          </StyledCellCommon>
        )}
        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
          width="29%"
        >
          {(bodyData?.address ? bodyData?.address : "") +
            (bodyData?.city ? ", " + bodyData?.city : "") +
            (bodyData?.province ? ", " + bodyData?.province : "")}
        </StyledCellCommon>

        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
          width="15%"
        >
          {selectedTab === "2" && (
            <StyledAlertCircle onClick={() => seeAllAlertsPage(bodyData?.id)}>
              <StyledAlertText>{alertsCount}</StyledAlertText>
            </StyledAlertCircle>
          )}
          {/* {bodyData?.count} */}
        </StyledCellCommon>
      </>
    );
  };

  const actions = (props) => {
    return (
      <>
        <StyledCellAction
          component={"tr"}
          scope="row"
          align="center"
          borderRadius={borderRadius}
        >
          <ActionsMenu
            client_id={props.id}
            selectedTab={selectedTab}
            companyName={props.institution_name}
          />
        </StyledCellAction>
      </>
    );
  };

  const createHeader = (title, width, align) => {
    return {
      title,
      width: width || "",
      align: align || "left",
    };
  };

  const headers = [
    createHeader("Account ID", "21%"),
    createHeader("Company Name", "27%"),
    createHeader("Address", "29%"),
    createHeader(selectedTab === "2" ? "Alerts" : "", "15%"),
    createHeader(""),
  ];
  const shortenedHeaders = [
    createHeader("Account ID"),
    createHeader("Company Name"),
    createHeader(""),
    createHeader(""),
  ];

  return (
    <div>
      <ConciseTable
        headers={isMobile ? "" : isShortened ? shortenedHeaders : headers}
        largefont={false}
        borderSpacing="0 5px !important"
      />
      <ConciseTable
        actions={actions}
        actionType="client"
        data={data}
        //height="1200px"
        body={body}
        boxShadow={boxShadow}
        borderRadius={borderRadius}
        // headers={isMobile ? "" : isShortened ? shortenedHeaders : headers}
      />
    </div>
  );
};
