import moment from "moment";

export const formatDate = (date) => {
  const locale = navigator.language;
  const localeDateFormat = Intl.DateTimeFormat(locale)
    .formatToParts()
    .map(({ type }) => {
      switch (type) {
        case "day":
          return "DD";
        case "month":
          return "MM";
        case "year":
          return "yyyy";
        case "literal":
          return "-";
        default:
          return type;
      }
    })
    .join("");
  if (date) {
    const newDate = new Date(date + "T00:00:00Z");
    if (isNaN(newDate)) {
      return " "; // Parsing failed
    }
    const timezoneOffset = newDate.getTimezoneOffset();
    const adjustedDate = new Date(newDate.getTime() + timezoneOffset * 60000);

    return moment(adjustedDate).format(localeDateFormat); // 'LL' represents the long date format
  }
  return " ";
};

export const formatDateInMonthDay = (dateString) => {
  try {
    if (dateString) {
      const [year, month, day] = dateString.split("-").map(Number);

      // Remember that the month index in JavaScript starts at 0 (January = 0, February = 1, ..., December = 11)
      const date = new Date(year, month - 1, day);

      return new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
      }).format(date);
    }
    return " "; // Return a default space if dateString is empty
  } catch (error) {
    console.error("Error parsing or formatting date:", error);
    return " "; // Return a default space on error
  }
};
