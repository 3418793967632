import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { StyledButton, StyledMenu, StyledMenuItem } from "pages/underwriters/components/allClientsTable/styles.js";

import VerticalThreeDots from "assets/dashboardIcons/verticalThreeDots";
import { useHistory } from "react-router";

export const ActionsMenuForPGA = ({ client_id, companyName, status, setData, viewPeerGroupAnalysis }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const viewPeerGroupAnalysisClientData = () => {
    history.push("/underwriter/client/peergroupanalysis", {
      company_name: companyName,
      account_id: client_id
    });
  };
  


  if (["deleted"].includes(status)) {
    return <></>;
  } else {
    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ padding: 0 }}
        >
          <VerticalThreeDots />
        </IconButton>
        <StyledMenu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >

          <StyledMenuItem>
            <StyledButton width="180px" onClick={viewPeerGroupAnalysisClientData}>
              Peer Group Analysis
            </StyledButton>
          </StyledMenuItem>

        </StyledMenu>
      </>
    );
  }
};
