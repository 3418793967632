import React from "react";

import {
  FormContainer,
} from "../styles";

import { SearchProject } from '../components/searchProject';
import { Panel1 } from './panel1';


export const HelpForm = ({
  setActiveStep,
  setDownloading,
  listPanel,
  setListPanel,
  setIsLastPanel,
  setQualificationData,
  qualificationData,
  setAction
 }) => {

  return (
    <FormContainer>
      {listPanel === 1 &&  (
        <Panel1
          setDownloading={setDownloading}
          listPanel={listPanel}
          setListPanel={setListPanel}
          setIsLastPanel={setIsLastPanel}
          setAction={setAction}
        />
      )}
        {listPanel === 2 && (
          <SearchProject
            setActiveStep={setActiveStep}
            setListPanel={setListPanel}
            setIsLastPanel={setIsLastPanel}
            qualificationData={qualificationData}
            setQualificationData={setQualificationData}
          />
        )}
    </FormContainer>
  );
};
