
import React from 'react';

const BackIcon = () => {
  return (
    <svg width="66" height="24" viewBox="0 0 66 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.81066 12L11.2803 19.4697C11.5732 19.7626 11.5732 20.2374 11.2803 20.5303C10.9874 20.8232 10.5126 20.8232 10.2197 20.5303L2.21967 12.5303C1.92678 12.2374 1.92678 11.7626 2.21967 11.4697L10.2197 3.46967C10.5126 3.17678 10.9874 3.17678 11.2803 3.46967C11.5732 3.76256 11.5732 4.23744 11.2803 4.53033L3.81066 12Z" fill="#FF6D1D" stroke="#FF6D1D"/>
      <path d="M30.5991 18C33.1558 18 34.7163 16.7051 34.7163 14.5967C34.7163 13.0361 33.604 11.874 31.9937 11.7246V11.5752C33.1973 11.3843 34.127 10.272 34.127 9.01025C34.127 7.16748 32.7573 6.02197 30.4829 6.02197H25.4775V18H30.5991ZM27.6191 7.69043H29.96C31.2549 7.69043 32.0103 8.31299 32.0103 9.38379C32.0103 10.4795 31.2051 11.0688 29.686 11.0688H27.6191V7.69043ZM27.6191 16.3315V12.5879H30.0264C31.6616 12.5879 32.5249 13.2271 32.5249 14.4473C32.5249 15.6758 31.6865 16.3315 30.1094 16.3315H27.6191ZM39.3315 18.1494C40.5186 18.1494 41.5146 17.6348 42.021 16.7549H42.1621V18H44.146V11.7993C44.146 9.88184 42.8345 8.75293 40.502 8.75293C38.3438 8.75293 36.8413 9.76562 36.6753 11.3511H38.6177C38.8086 10.7285 39.4561 10.3882 40.4023 10.3882C41.5146 10.3882 42.1123 10.8945 42.1123 11.7993V12.5796L39.7715 12.7207C37.5635 12.8452 36.3267 13.7998 36.3267 15.4268C36.3267 17.0869 37.5801 18.1494 39.3315 18.1494ZM39.9375 16.564C39.0244 16.564 38.3687 16.1074 38.3687 15.3271C38.3687 14.5718 38.9082 14.1484 40.062 14.0737L42.1123 13.9326V14.6714C42.1123 15.7505 41.1826 16.564 39.9375 16.564ZM54.4722 11.957C54.2563 10.0811 52.8452 8.75293 50.5127 8.75293C47.7817 8.75293 46.1797 10.5044 46.1797 13.4429C46.1797 16.4146 47.79 18.1826 50.521 18.1826C52.8203 18.1826 54.248 16.9043 54.4722 15.0283H52.5132C52.2974 15.9829 51.5835 16.4893 50.5127 16.4893C49.1099 16.4893 48.2632 15.3687 48.2632 13.4429C48.2632 11.5503 49.1016 10.438 50.5127 10.438C51.6416 10.438 52.3223 11.0771 52.5132 11.957H54.4722ZM58.7222 12.646H58.5811V5.41602H56.5225V18H58.5811V14.8125L59.3115 14.082L62.2251 18H64.7153L60.8472 12.8203L64.4663 8.92725H62.0757L58.7222 12.646Z" fill="#FF6D1D"/>
    </svg>
  )
};

export default BackIcon;
