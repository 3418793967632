import React from "react";
import {
  StyledTableCellFirst,
  StyledTableCheckBoxCell,
  StyledTableCenteringContainer,
  StyledTableCellSecond,
  StyledTableCell,
  StyledTableCellThird,
  StyledMinusImg,
  StyledIndexCell,
  StyledUnderwayContainer,
  StyledCompletedContainer,
} from "./styles";
import { ThreeDotsPopupMenu } from "components/threeDotsPopupMenu";
import BorderLessConciseTable from "components/filesListTable/borderLessConciseTable";
import {
  RoundedUnCheckedBox,
  RoundedCheckbox,
} from "components/roundedCheckbox";

export const SelectedProjects = ({
  projects,
  setProjects,
  pickedArray,
  setPickedArray,
  filteredData,
  setFilteredData,
}) => {
  const removeProject = (e, index, bodyData) => {
    const temp = [...projects];
    temp.splice(index, 1);
    setProjects(temp);

    var pickedArrayIndex = pickedArray.indexOf(bodyData?.id);
    pickedArray.splice(pickedArrayIndex, 1);
    setPickedArray(pickedArray);

    if (bodyData?.id) {
      let allData = [...filteredData];
      if (!allData.find(({ id }) => id === bodyData.id)) {
        allData.push({
          id: bodyData.id,
          project_name: bodyData.project_name,
          project_number: bodyData.project_number,
          project_location: bodyData.project_location,
          project_status: bodyData.project_status,
          type: ["key"],
        });
      }

      setFilteredData(allData);
    }
  };

  const changeProjectType = (e, index) => {
    const temp = [...projects];
    const type = e.target.value;
    const projectType = temp[index].type || [];
    const typeExists = projectType.indexOf(type);

    if (typeExists >= 0) {
      projectType.splice(typeExists, 1);
    } else {
      projectType.push(type);
    }

    temp[index]["type"] = projectType;
    setProjects(temp);
  };

  const body = (bodyData) => {
    if (pickedArray && !pickedArray.includes(bodyData.id)) {
      setPickedArray((pickedArray) => [...pickedArray, bodyData.id]);
    }

    return (
      <>
        <StyledIndexCell>{bodyData.index + 1}</StyledIndexCell>
        <StyledTableCellFirst>{bodyData.project_number}</StyledTableCellFirst>
        <StyledTableCellSecond>{bodyData.project_name}</StyledTableCellSecond>
        <StyledTableCellFirst>{bodyData.project_location}</StyledTableCellFirst>
        <StyledTableCellThird>
          {bodyData.project_status === "Underway" ? (
            <StyledUnderwayContainer>Underway</StyledUnderwayContainer>
          ) : bodyData.project_status === "Completed" ? (
            <StyledCompletedContainer>Completed</StyledCompletedContainer>
          ) : (
            ""
          )}
        </StyledTableCellThird>

        {bodyData && bodyData.project_status != "Underway" && (
          <>
            <StyledTableCheckBoxCell>
              <StyledTableCenteringContainer>
                <RoundedCheckbox
                  attributeTypes={bodyData.type}
                  value="key"
                  onChange={(e) => changeProjectType(e, bodyData.index)}
                />
              </StyledTableCenteringContainer>
            </StyledTableCheckBoxCell>
            <StyledTableCheckBoxCell>
              <StyledTableCenteringContainer>
                <RoundedCheckbox
                  attributeTypes={bodyData.type}
                  value="comparable"
                  onChange={(e) => changeProjectType(e, bodyData.index)}
                />
              </StyledTableCenteringContainer>
            </StyledTableCheckBoxCell>
          </>
        )}
        {bodyData && bodyData.project_status == "Underway" && (
          <>
            <StyledTableCheckBoxCell>
              <StyledTableCenteringContainer>
                <RoundedUnCheckedBox />
              </StyledTableCenteringContainer>
            </StyledTableCheckBoxCell>
            <StyledTableCheckBoxCell>
              <StyledTableCenteringContainer>
                <RoundedUnCheckedBox />
              </StyledTableCenteringContainer>
            </StyledTableCheckBoxCell>
          </>
        )}

        <StyledTableCheckBoxCell>
          <StyledTableCenteringContainer>
            <ThreeDotsPopupMenu
              removeEdit={"na"}
              addProjectRemark={true}
              item={bodyData}
              projects={projects}
              setProjects={setProjects}
              handleDelete={removeProject}
            />
          </StyledTableCenteringContainer>
        </StyledTableCheckBoxCell>
      </>
    );
  };

  const headers = [
    { title: "" },
    { title: "Project #" },
    { title: "Project Title" },
    { title: "Project Location" },
    { title: "Status" },
  ];

  const checkBoxHeaders = [{ title: "Key" }, { title: "Comparable" }];

  const actionsHeader = [{ title: "" }];

  return (
    <>
      <BorderLessConciseTable
        headers={headers}
        checkBoxHeaders={checkBoxHeaders}
        length={0}
        actionsHeaders={actionsHeader}
        height={"30px"}
        width="860px"
      />
      <BorderLessConciseTable
        length={3.5}
        body={body}
        data={projects}
        height={"300px"}
        width="860px"
      />
    </>
  );
};

export default SelectedProjects;
