import React, { useEffect, useState } from "react";
import { StyledTableCell } from '../../../components/filesListTable/styles';
import ConciseTable from '../../../components/filesListTable/conciseTable';
import { FunctionalButton } from '../../../components/functionalButton/index';
import { AddUnionPopup } from "../../../components/addUnionPopup";
import { getUnions } from "../../../api/external_data";
import { StyledProjectSelectionContainer, HeaderArea } from '../styles';
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";


export default function DataMaintenanceUnion(editFunc,
  deleteFunc,
  isMobile) {

  const headers = [
    { title: 'Id' },
    { title: 'Name' },
    { title: 'Address' },
    { title: 'Suite Number' },
    { title: 'City' },
    { title: 'State/Province' },
    { title: 'Postal/Zip Code' },
    { title: 'Country' },
    { title: 'Phone' },
    { title: 'Website' },
    { title: 'Union Type' },

  ];

  const [openPopup, setOpenPopup] = useState(false);
  const [unions, setUnions] = useState([]);
  const [internalSearchResults, setInternalSearchResults] = useState(unions);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getUnions()
      .then((res) => {
        setUnions(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  const handleAdd = () => {
    setOpenPopup(true);
  };

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCell component={'tr'} width="10%">
          {bodyData.id}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="25%">
          {bodyData.name}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="10%">
          {bodyData.address}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="10%">
          {bodyData.suite_number}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="10%">
          {bodyData.city}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="10%">
          {bodyData.province}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="10%">
          {bodyData.postal_zip_code}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="10%">
          {bodyData.country}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="10%">
          {bodyData.phone}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="10%">
          {bodyData.website}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="10%">
          {bodyData.union_type}
        </StyledTableCell>
      </>

    );
  };


  return (
    <StyledProjectSelectionContainer isMobile={isMobile}>

      <HeaderArea width="100%">
        <SearchBar
          filteredData={unions}
          setInternalSearchResults={setInternalSearchResults}
          query={query}
          setQuery={setQuery}
          internalSearch={true}
        />
        <FunctionalButton
          buttonTitle="Add Union"
          handleButton={handleAdd}
          width="60%"
        />
      </HeaderArea>

      <ConciseTable
        headers={isMobile ? "" : headers}
        length={isMobile ? 8 : 8.5}
        body={body}
        data={
          query && query !== "" ? internalSearchResults : unions
        }
      />

      <AddUnionPopup
        setOpenDialog={setOpenPopup}
        openDialog={openPopup}
      />

    </StyledProjectSelectionContainer>
  )
}


