import React, { useState } from 'react';

import { filterPersonnels } from '../../../../api/prequalify';

import IncrementalSearchPickList from '../../../../components/incrementalSearchPickList';
import { StyledTableCell } from '../../../../components/filesListTable/styles';

import { StyledImgIcon } from './styles';
import plusIcon from '../../../../assets/common/plusIcon.svg';

export const SelectingPersonnels = ({
  add,
  qualificationData,
  setQualificationData,
}) => {
  const [filteredData, setFilteredData] = useState([]);

  const headers = [
    { title: '' },
    { title: 'Personnel Name' },
    { title: 'Position' },
  ];

  const handlePersonnelSelection = (bodyData) => {
    const filteredTemp = [...filteredData];
    const personnelsTemp = [...qualificationData?.key_personnel];

    personnelsTemp.unshift({
      id: bodyData.id,
      firstName: bodyData.firstName,
      lastName: bodyData.lastName,
      position: bodyData.position,
      type: ['key_site'],
    });

    setQualificationData('key_personnel', personnelsTemp);
    filteredTemp.splice(bodyData.index, 1);
    setFilteredData(filteredTemp);
  };

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCell component={'tr'}>
          <StyledImgIcon src={plusIcon} onClick={() => handlePersonnelSelection(bodyData)} />
        </StyledTableCell>
        <StyledTableCell component={'tr'}>
          {`${bodyData.firstName} ${bodyData.lastName}`}
        </StyledTableCell>
        <StyledTableCell component={'tr'}>
          {bodyData.position}
        </StyledTableCell>
      </>
    );
  };

  return (
    <IncrementalSearchPickList
      add={add}
      headers={headers}
      body={body}
      conciseLength={5.5}
      filterApi={filterPersonnels}
      filteredData={filteredData}
      setFilteredData={setFilteredData}
      addButtonTitle="Add Personnel"
    />
  );
};
