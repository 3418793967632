import React, { useState, useEffect } from "react";
import {
  StyledLargeTitle,
  StyledMediumTitle,
  ProjectCardContainer,
} from "../../../preQualifyForm/styles";

import { SelectingProjects } from "./components/selectingProjects";

import ProjectCard from "../../../preQualifyForm/components/projectCard";
import FilledAccordion from '../../../../components/filledAccordion';
import { KeyProjects } from './KeyProjects';
import { NoProjects } from './styles';
import BorderLessConciseTable from "../../../../components/filesListTable/borderLessConciseTable";
import { StyledTableCell } from "../../../../components/filesListTable/styles";
import { StyledTableCellFirst, StyledUploadedFilesContainer } from "../../../buyers/components/additionalFilesUpload/styles";
import { SelectedProjects } from "./components/selectedProjects";
import { AddProjectPopup } from "./addProjectPopup";
import { AddPersonnelPopup } from "components/addPersonnelPopup";
import { AddPersonnelPopupCache } from "./addPersonnelPopup";

export const ResultPanel = ({
  projects,
  setPanel,
  setAddData,
  setProjects,
  setEditIndex,
  setOpenEdit,
  setOpenPopup,
  openPopup,
  setPrequalifyData,
  prequalifyData
}) => {
  const addProject = () => {
    setPanel('add');
  };

  const [individual, setIndividual] = useState(null);

  const addPersonnel = (data) => {

  };
  
  useEffect(() => {
    if(individual) {
      const individualTemp = individual[0];
      const temp = [individualTemp, ...projects];
      setProjects(temp);
    }
  }, [individual]);
 
  return (
    <>
      
      {/* <SelectedProjects
        projects={projects}
        setProjects={setProjects}
        setOpenPopup={setOpenPopup}
        openPopup={openPopup}
        individual={individual}
        setPrequalifyData={setPrequalifyData}
        prequalifyData={prequalifyData}
      /> */}
      <SelectingProjects
        add={() => setPanel("add")}
        projects={projects}
        setProjects={setProjects}
        setOpenPopup={setOpenPopup}
        setPrequalifyData={setPrequalifyData}
        prequalifyData={prequalifyData}
      />
       <AddPersonnelPopupCache
        setOpenDialog={setOpenPopup}
        openDialog={openPopup}
        data={individual}
        setData={setIndividual}
        handleButtonFunc={addPersonnel}
      />
      {/* <AddProjectPopup
        openPopup={openPopupp}
        setOpenPopup={setOpenPopup}
      /> */}
    </>
  )
}
