import React, { useState } from "react";
import { StyledTableCell } from "../../../components/filesListTable/styles";
import ConciseTable from "../../../components/filesListTable/conciseTable";
import { ThreeDotsPopupMenu } from "../../../components/threeDotsPopupMenu";
import { ResumeButton } from "../../../components/resumeButton";
import {
  StyledProjectSelectionContainer,
  ProfilePic,
  MobileColumnContent,
  MobileColumnText,
} from "./styles";
import { LetterAvatar } from "../../../components/letterAvatar";
import { truncateString } from "../../../common/truncateString";

export const PersonnelList = ({
  filteredData,
  editFunc,
  deleteFunc,
  isMobile,
}) => {
  const headers = [
    { title: "", width: "7%" },
    { title: "Name", width: "25%" },
    { title: "Email", width: "28%" },
    { title: "Position", width: "25%" },
    { title: "Resume", width: "10%" },
    { title: "" },
  ];

  const body = (bodyData) => {
    const name = `${bodyData.firstName}${
      bodyData.firstName && bodyData.lastName ? " " : ""
    }${bodyData.lastName}`;
    return (
      <>
        <StyledTableCell component={"tr"} width="7%" align="center">
          <ProfilePic>
            <LetterAvatar name={name} />
          </ProfilePic>
        </StyledTableCell>
        {isMobile && (
          <StyledTableCell component={"tr"} width="90%">
            <MobileColumnContent direction="column">
              <MobileColumnText>{name}</MobileColumnText>
              <MobileColumnText fontSize="12px" fontWeight="400">
                {truncateString(bodyData.position, 20)}
              </MobileColumnText>
            </MobileColumnContent>
          </StyledTableCell>
        )}
        {!isMobile && (
          <>
            <StyledTableCell component={"tr"} width="25%">
              {name}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="25%">
              {bodyData.email}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="25%">
              {bodyData.position}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="10%">
              <ResumeButton
                width="70px"
                height="29px"
                fontSize="11px"
                handleButton={() => {}}
                buttonTitle="Resume"
                exists={
                  bodyData.resume && bodyData.resume !== "" ? true : false
                }
              />
            </StyledTableCell>
          </>
        )}
        <StyledTableCell component={"tr"} align="right" width="3%">
          <ThreeDotsPopupMenu
            item={bodyData}
            handleModify={editFunc}
            handleDelete={deleteFunc}
          />
        </StyledTableCell>
      </>
    );
  };

  return (
    <StyledProjectSelectionContainer isMobile={isMobile}>
      <ConciseTable headers={isMobile ? "" : headers} />
      <ConciseTable
        // headers={isMobile ? "" : headers}
        body={body}
        data={filteredData}
      />
    </StyledProjectSelectionContainer>
  );
};
