import React, { useState } from "react";
import { useHistory } from "react-router";
import { FunctionalButton } from "../../../../components/functionalButton";
import { AnalyzePopup } from "./AnalyzePopup";
import {
  StyledTypography,
  StyledTypographyPagination,
  StyledButtonContainer,
  StyledContainer,
} from "./styles";

export const AdditionalBox = ({ submissionNumber, data, buttonTitle }) => {
  let history = useHistory();
  const [analyze, setAnalyze] = useState(true);
  const [openAnalyze, setOpenAnalyze] = useState(false);

  const handleButtonAnalyze = () => {
    if (analyze) {
      setOpenAnalyze(true);
    }
  };

  const handleButtonDecision = () => {};

  const handleCloseDialog = () => {
    setOpenAnalyze(false);
    setAnalyze(!analyze);
    history.push("analyzed", { data: data });
  };

  return (
    <StyledContainer>
      <StyledTypography>Submissions</StyledTypography>
      {/* <StyledTypographyPagination>Showing {submissionNumber} out of {submissionNumber} results</StyledTypographyPagination> */}
      {/* <StyledButtonContainer>
              <FunctionalButton
                buttonTitle={buttonTitle}
                handleButton={(buttonTitle == "Analyze")? handleButtonAnalyze : handleButtonDecision}
                marginRight="0px"
              />
          </StyledButtonContainer>
          <AnalyzePopup openAnalyze={openAnalyze} handleClose={handleCloseDialog} /> */}
    </StyledContainer>
  );
};
