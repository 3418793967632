import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '../../constants';
import Slider from '@material-ui/core/Slider';


export const HeaderArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${props => props.width || "100%"};
`;


export const GradientText = styled.b`
background: linear-gradient(90deg, #43B868 0%, #B7FE5C 23.44%, #FEC65C 50%, #FF6D1D 77.6%, #FF0000 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`;

const getThumbColor = (rating) => {
  if (rating > 18 && rating <= 20) {
    return "linear-gradient(330.26deg, #1E7D32 21.73%, #388E3C 86.36%)"; // Dark Green
  }
  if (rating > 16 && rating <= 18) {
    return "linear-gradient(330.26deg, #43B868 21.73%, #297942 86.36%)"; // Green
  }
  if (rating > 14 && rating <= 16) {
    return "linear-gradient(330.26deg, #7CB342 21.73%, #558B2F 86.36%)"; // Light Green
  }
  if (rating > 12 && rating <= 14) {
    return "linear-gradient(330.26deg, #d9e35c 21.73%, #a9f65d 86.36%)"; // Yellow-Green
  }
  if (rating > 10 && rating <= 12) {
    return "linear-gradient(330.26deg, #FEC65C 21.73%, #FA9C52 86.36%)"; // Yellow
  }
  if (rating > 8 && rating <= 10) {
    return "linear-gradient(330.26deg, #FFB74D 21.73%, #FFA726 86.36%)"; // Orange
  }
  if (rating > 6 && rating <= 8) {
    return "linear-gradient(330.26deg, #FF8A65 21.73%, #FF7043 86.36%)"; // Light Orange
  }
  if (rating > 4 && rating <= 6) {
    return "linear-gradient(330.26deg, #FF7043 21.73%, #F4511E 86.36%)"; // Deep Orange
  }
  if (rating > 2 && rating <= 4) {
    return "linear-gradient(330.26deg, #FEC65C 21.73%, #FF6D1D 86.36%)"; // Yellow to Orange
  }
  if (rating > 0 && rating <= 2) {
    return "linear-gradient(330.26deg, #FF6D1D 21.73%, #FF0000 86.36%)"; // Orange to Red
  }
  return "linear-gradient(330.26deg, #FEC65C 21.73%, #FA9C52 86.36%)"; // Default (Yellow)
};

export const StyledSlider = styled(Slider)`
  && {
    color: red;
  }

  .MuiSlider-rail {
    background: linear-gradient(90deg, #FF0000 0%, #FF6D1D 23.44%, #FEC65C 50%, #B7FE5C 77.6%, #43B868 100%);
    background-color: ${COLORS.scrollbarTrack};    
    height: 4px;
    border-radius: 2px;
    opacity: 1;
    padding-right: 12px;
  }

  .MuiSlider-track {
    color:transparent;
    height: 4px;
    border-radius: 2px;
  }

  .MuiSlider-mark {
    display: none;
  }

  .MuiSlider-thumb {
    margin-top: -8px;
    margin-left: -8px;
    width: 20px;
    height: 20px;
    background: ${props => getThumbColor(props.value)};
  }

  .MuiSlider-thumb:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-markLabel {
    top: 40px;
    font-size: 13px;
    font-family: ${TYPOGRAPHY.primaryFont};
  }
`;


const getThumbColorReverse = (rating) => {
  if (rating >= 18 && rating < 21) {
    return "linear-gradient(330.26deg, #FF6D1D 21.73%, #FF0000 86.36%)"; // Orange to Red
  }
  if (rating >= 16 && rating < 18) {
    return "linear-gradient(330.26deg, #FF8A65 21.73%, #FF7043 86.36%)"; // Light Orange
  }
  if (rating >= 14 && rating < 16) {
    return "linear-gradient(330.26deg, #FFB74D 21.73%, #FFA726 86.36%)"; // Orange
  }
  if (rating >= 12 && rating < 14) {
    return "linear-gradient(330.26deg, #FEC65C 21.73%, #FA9C52 86.36%)"; // Yellow to Orange
  }
  if (rating >= 10 && rating < 12) {
    return "linear-gradient(330.26deg, #FEC65C 21.73%, #FF6D1D 86.36%)"; // Yellow
  }
  if (rating >= 8 && rating < 10) {
    return "linear-gradient(330.26deg, #d9e35c 21.73%, #a9f65d 86.36%)"; // Yellow-Green
  }
  if (rating >= 6 && rating < 8) {
    return "linear-gradient(330.26deg, #7CB342 21.73%, #558B2F 86.36%)"; // Light Green
  }
  if (rating >= 4 && rating < 6) {
    return "linear-gradient(330.26deg, #43B868 21.73%, #297942 86.36%)"; // Green
  }
  if (rating >= 2 && rating < 4) {
    return "linear-gradient(330.26deg, #1E7D32 21.73%, #388E3C 86.36%)"; // Dark Green
  }
  if (rating > 0 && rating < 2) {
    return "linear-gradient(330.26deg, #004D40 21.73%, #00796B 86.36%)"; // Deep Green
  }
  return "linear-gradient(330.26deg, #FEC65C 21.73%, #FA9C52 86.36%)"; // Default (Yellow)
};



export const StyledSliderReverse = styled(Slider)`
  && {
    color: red;
  }

  .MuiSlider-rail {
    background: linear-gradient(90deg, #43B868 0%, #B7FE5C 23.44%, #FEC65C 50%, #FF6D1D 77.6%, #FF0000 100%);
    background-color: ${COLORS.scrollbarTrack};    
    height: 4px;
    border-radius: 2px;
    opacity: 1;
    padding-right: 12px;
  }

  .MuiSlider-track {
    color:transparent;
    height: 4px;
    border-radius: 2px;
  }

  .MuiSlider-mark {
    display: none;
  }

  .MuiSlider-thumb {
    margin-top: -8px;
    margin-left: -8px;
    width: 20px;
    height: 20px;
    background: ${props => getThumbColorReverse(props.value)};
  }

  .MuiSlider-thumb:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-markLabel {
    top: 40px;
    font-size: 13px;
    font-family: ${TYPOGRAPHY.primaryFont};
  }
`;

export const StyledSliderRiskScore = styled(Slider)`
  && {
    color: red;
  }

  .MuiSlider-rail {
    background: linear-gradient(90deg, #43B868 0%, #B7FE5C 23.44%, #FEC65C 50%, #FF6D1D 77.6%, #FF0000 100%);
    background-color: ${COLORS.scrollbarTrack};    
    height: 12px;
    border-radius: 6px;
    opacity: 1;
    padding-right: 12px;
  }

  .MuiSlider-track {
    color:transparent;
    height: 12px;
    border-radius: 6px;
  }

  .MuiSlider-mark {
    display: none;
  }

  .MuiSlider-thumb {
    margin-top: -8px;
    margin-left: -8px;
    width: 28px;
    height: 28px;
    background: ${props => getThumbColorReverse(props.value)};
  }

  .MuiSlider-thumb:hover {
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-markLabel {
    top: 30px;
    margin-left: 8px;
    font-size: 14px;
    font-family: ${TYPOGRAPHY.primaryFont};
  }
  .MuiSlider-markLabel[data-index="1"] {
    margin-top: -45px; 
    transform: translateX(-50%);
  }
`;


