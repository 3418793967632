import React from "react";
import Input from "../inputField";
import { InfoArea } from "./styles";
import { StyledRow } from "./styles";
import TextField from "../textField";

export const Step4 = ({
  setDisable,
  addData,
  setAddData,
  isMobile
}) => {

  return (
    <InfoArea isMobile={isMobile} marginTop="0px" marginLeft="8px" isColumn={true} >
      <StyledRow isMobile={isMobile} marginTop="-8px">
        <Input
          id="fullWidthInputWrap"
          label="Contract Type"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.contract_type || ''}
          setValue={(val) => setAddData('contract_type', val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
      </StyledRow>
      <StyledRow isMobile={isMobile} className="fullWidthInputWrap" marginTop="-8px">
        <TextField
          width="100%"
          label="Description of Project and Scope of Services"
          value={addData?.description || ''}
          setValue={(val) => setAddData('description', val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
      </StyledRow>
    </InfoArea>

  )
}