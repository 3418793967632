import React, { useEffect, useState, useRef } from "react";
import { FloatingButton } from "../../../components/floatingButton";
import updateObjectByPath from "../../../common/updateObjectByPath";
import { SafetyRecords } from "./safetyRecords";
import { IconButton } from "@material-ui/core";
import MobileSaveButtonIcon from "../../../assets/dashboardIcons/mobileSaveButtonIcon";
import FilledAccordion from "../../../components/filledAccordion";
import { ResultPanel } from "./resultPanel.js";
import { FunctionalButton } from "components/functionalButton";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";
import DocumentPoolPopUp from "components/documentPoolPopUp";
import { WrapperForUploadedHealthSafetyFileDisplay } from "pages/preQualifyForm/components/uploadedHealthSafetyFileDisplay";
import ConciseTable from "components/filesListTable/conciseTable";
import { COLORS } from "../../../constants";
import { ScrollableDiv } from "pages/documentPool/styles";

import {
  FieldArea,
  StyledRow,
  SectionText,
  SetupContainer,
  StyledEditRecordButton,
  SecondRadioContainer,
  SecondInnerRadioContainer,
  StyledTextField,
  StyledCenter,
  StyledHealthSafetyPersonnelNone,
} from "./styles";
import { StyledSubtext, OptionArea } from "pages/loginPage/styles";
import { RadioButton } from "components/radioButton";
import CheckedRadioButton from "assets/dashboardIcons/checkedRadioButton";
import UncheckedRadioButton from "assets/dashboardIcons/uncheckedRadioButton";
import { LabelText } from "./safetyRecords/styles";

export const HealthSafetyCanada = ({
  isMobile,
  setOpenSuccessPopup,
  getAnnualInfo,
  saveAnnualInfo,
  data,
  setData,
  institutionData,
  currentDashboardUrl,
}) => {
  const [filteredData, setFilteredData] = useState(
    data?.health_safety_personnel_canada || []
  );
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupPersonnel, setOpenPopupPersonnel] = useState(false);
  const [internalSearchResults, setInternalSearchResults] = useState(
    data?.health_safety_documents_canada || []
  );
  const [query, setQuery] = useState("");
  const [openDocumentPool, setOpenDocumentPool] = useState(false);
  const headers = [];
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [healthSafetyPersonnelNone, setHealthSafetyPersonnelNone] = useState(
    data?.annual_record_remarks_and_cor?.health_safety_personnel_canada_none
  );

  const [containsCor, setContainsCor] = useState(
    data?.annual_record_remarks_and_cor?.certificate_of_recognition || false
  );
  const [notContainsCor, setNotContainsCor] = useState(
    data?.annual_record_remarks_and_cor?.no_certificate_of_recognition || false
  );
  const [equivalentCor, setEquivalentCor] = useState(
    data?.annual_record_remarks_and_cor
      ?.certificate_of_recognition_equivalent || false
  );
  const [coveredCor, setCoveredCor] = useState(
    data?.annual_record_remarks_and_cor
      ?.no_certificate_of_recognition_equivalent || false
  );
  const [safetyRemark, setSafetyRemark] = useState(
    data?.annual_record_remarks_and_cor?.annual_safety_records_remark || ""
  );

  const [corsRemark, setCorsRemark] = useState(
    data?.annual_record_remarks_and_cor?.cors_remark || ""
  );

  const handleHealthSafetyPersonnelNoneChange = () => {
    setHealthSafetyPersonnelNone(!healthSafetyPersonnelNone);
  };

  const deleteHealthSafetyFile = (index) => {
    let temp = [...data?.health_safety_documents_canada];
    temp.splice(index, 1);
    setData({
      ...data,
      health_safety_documents_canada: temp,
    });
  };

  const addUploadedFile = () => {
    let temp = [...data?.health_safety_documents_canada];

    for (let key in selectedFiles) {
      if (!isNaN(key)) {
        temp.push(selectedFiles[key]);
      }
    }

    setData({
      ...data,
      health_safety_documents_canada: temp,
    });

    setOpenDocumentPool(false);
  };

  const handleDocumentPoolPopUp = () => {
    setOpenDocumentPool(true);
  };

  const handleSafetyRemarkChange = (event) => {
    setSafetyRemark(event.target.value);
  };

  const handleCorsRemarkChange = (event) => {
    setCorsRemark(event.target.value);
  };

  if (data && !data.annual_record_remarks_and_cor) {
    data["annual_record_remarks_and_cor"] = {};
  }

  useEffect(() => {
    const updatedData = { ...data };
    updatedData["annual_record_remarks_and_cor"][
      "annual_safety_records_remark"
    ] = safetyRemark;
    updatedData["annual_record_remarks_and_cor"]["cors_remark"] = corsRemark;
    updatedData["annual_record_remarks_and_cor"]["certificate_of_recognition"] =
      containsCor;
    updatedData["annual_record_remarks_and_cor"][
      "certificate_of_recognition_equivalent"
    ] = equivalentCor;
    updatedData["annual_record_remarks_and_cor"][
      "no_certificate_of_recognition"
    ] = notContainsCor;
    updatedData["annual_record_remarks_and_cor"][
      "no_certificate_of_recognition_equivalent"
    ] = coveredCor;
    updatedData["annual_record_remarks_and_cor"][
      "health_safety_personnel_canada_none"
    ] = healthSafetyPersonnelNone;
    updatedData["health_safety_personnel_canada"] = filteredData;
    updatedData["annual_record_remarks_and_cor"]["country"] = "Canada";
    setData(updatedData);
  }, [
    safetyRemark,
    corsRemark,
    containsCor,
    equivalentCor,
    notContainsCor,
    coveredCor,
    healthSafetyPersonnelNone,
    filteredData,
  ]);

  const handleSave = () => {
    const newdata = { ...data, ...institutionData };

    saveAnnualInfo(
      newdata,
      () => {
        setOpenSuccessPopup(true);
      },
      () => {}
    );
  };

  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };

  const confirmRadioChange = (val) => {
    if (val === 1) {
      setContainsCor(true);
      setCorsRemark("");
      setNotContainsCor(false);
      setEquivalentCor(false);
      setCoveredCor(false);
    } else {
      setNotContainsCor(true);
      setContainsCor(false);
    }
  };

  const confirmSecondRadio = (val) => {
    if (val === 1) {
      setEquivalentCor(true);
      setCoveredCor(false);
    } else {
      setCoveredCor(true);
      setEquivalentCor(false);
    }
  };

  const saveButtonRef = useRef();

  return (
    <SetupContainer isMobile={isMobile}>
      {!isMobile && (
        <FloatingButton
          buttonTitle="Save"
          handleButton={handleSave}
          width="150px"
          height="38px"
          marginRight="0px"
          marginTop="-280px"
        />
      )}
      <StyledRow justifyContent="space-between" ref={saveButtonRef}>
        {isMobile && (
          <IconButton onClick={handleSave}>
            <MobileSaveButtonIcon />
          </IconButton>
        )}
      </StyledRow>
      <FieldArea noJustifyContent={true} isMobile={isMobile}>
        {!isMobile && (
          <>
            <SectionText style={{ marginTop: "-20px" }}>
              Health and Safety
            </SectionText>

            <StyledRow style={{ marginBottom: "-10px" }}>
              <StyledSubtext style={{ width: "90%" }}>
                {" "}
                Select Personnel responsible for Health & Safety:{" "}
              </StyledSubtext>

              <StyledRow justifyContent="right" style={{ marginTop: "-10px" }}>
                <StyledHealthSafetyPersonnelNone
                  control={
                    <RadioButton
                      isChecked={healthSafetyPersonnelNone}
                      handleChange={handleHealthSafetyPersonnelNoneChange}
                    />
                  }
                  label="None"
                  labelPlacement="end"
                />
              </StyledRow>
            </StyledRow>

            {healthSafetyPersonnelNone === false && (
              <ResultPanel
                projects={filteredData}
                setProjects={setFilteredData}
                setOpenPopup={setOpenPopupPersonnel}
                openPopup={openPopupPersonnel}
                institutionId={institutionData?.institution_id || ""}
                currentDashboardUrl={currentDashboardUrl}
                paddingRight="0px"
                height="210px"
                label={false}
              />
            )}

            <StyledSubtext>
              {" "}
              Certificate of Recognition program (COR<sup>TM</sup>)?{" "}
            </StyledSubtext>
            <StyledEditRecordButton>
              <RadioButton
                isChecked={
                  containsCor === true && notContainsCor === false
                    ? true
                    : false
                }
                value={containsCor}
                checkedIcon={<CheckedRadioButton />}
                unCheckedIcon={<UncheckedRadioButton />}
                handleChange={() => confirmRadioChange(1)}
              />
              <StyledSubtext style={{ marginTop: "0px" }}> Yes</StyledSubtext>
              <RadioButton
                isChecked={
                  notContainsCor === true && containsCor === false
                    ? true
                    : false
                }
                value={notContainsCor}
                checkedIcon={<CheckedRadioButton />}
                unCheckedIcon={<UncheckedRadioButton />}
                handleChange={() => confirmRadioChange(0)}
              />
              <StyledSubtext style={{ marginTop: "0px" }}> No</StyledSubtext>
            </StyledEditRecordButton>
            {notContainsCor && (
              <SecondRadioContainer>
                <SecondInnerRadioContainer>
                  <RadioButton
                    isChecked={
                      equivalentCor === true && coveredCor === false
                        ? true
                        : false
                    }
                    value={equivalentCor}
                    checkedIcon={<CheckedRadioButton />}
                    unCheckedIcon={<UncheckedRadioButton />}
                    handleChange={() => confirmSecondRadio(1)}
                  />
                  <StyledSubtext
                    style={{ fontSize: "12px", marginTop: "10px" }}
                  >
                    {" "}
                    Equivalent to COR<sup>TM</sup>{" "}
                  </StyledSubtext>
                </SecondInnerRadioContainer>
                <SecondInnerRadioContainer>
                  <RadioButton
                    isChecked={
                      coveredCor === true && equivalentCor === false
                        ? true
                        : false
                    }
                    value={coveredCor}
                    checkedIcon={<CheckedRadioButton />}
                    unCheckedIcon={<UncheckedRadioButton />}
                    handleChange={() => confirmSecondRadio(0)}
                  />
                  <StyledSubtext
                    style={{ fontSize: "12px", marginTop: "10px" }}
                  >
                    {" "}
                    Covered by Health and Safety plan{" "}
                  </StyledSubtext>
                </SecondInnerRadioContainer>
                <FieldArea width="780px" noJustifyContent={true}>
                  <LabelText> Remarks </LabelText>
                  <StyledTextField
                    multiline
                    rows={5}
                    placeholder={"Remark"}
                    variant="outlined"
                    value={corsRemark}
                    onChange={handleCorsRemarkChange}
                  />
                </FieldArea>
              </SecondRadioContainer>
            )}

            <StyledSubtext style={{ marginTop: "28px" }}>
              {" "}
              Attach all relevant Health & Safety documents:{" "}
            </StyledSubtext>

            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  marginLeft: "0px",
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "70%",
                }}
              >
                <SearchBar
                  filteredData={data?.health_safety_documents_canada}
                  setInternalSearchResults={setInternalSearchResults}
                  internalSearch={true}
                  query={query}
                  setQuery={setQuery}
                  searchWidth="100%"
                  searchPlaceHolder="File Name..."
                />
              </div>
              <FunctionalButton
                buttonTitle="Document Pool"
                marginLeft="20px"
                handleButton={() => handleDocumentPoolPopUp()}
                width="250px"
                isReversedColor={true}
              />
            </div>

            {data?.health_safety_documents_canada?.length < 1 && (
              <div
                style={{
                  alignItems: "center",
                  backgroundColor: "white",
                  border: `dashed 2px ${COLORS.lightGrey1}`,
                  display: "flex",
                  height: "186px",
                  color: `${COLORS.textGrey}`,
                  justifyContent: "center",
                  width: "99%",
                  marginTop: "20px",
                }}
              >
                Selected Documents will appear here
              </div>
            )}
            {data?.health_safety_documents_canada?.length > 0 && (
              <div style={{ marginTop: "20px", width: "100%" }}>
                <ConciseTable headers={headers} />

                <ScrollableDiv style={{ maxHeight: "190px" }}>
                  <div style={{ marginRight: "15px" }}>
                    {data?.health_safety_documents_canada?.length > 0 &&
                      (query && query !== ""
                        ? internalSearchResults
                        : data?.health_safety_documents_canada
                      )?.map((item, i) => (
                        <StyledCenter style={{ marginTop: 0 }}>
                          <WrapperForUploadedHealthSafetyFileDisplay
                            key={`file-${i}`}
                            file={item}
                            displayPercentage="100%"
                            deleteFile={deleteHealthSafetyFile}
                            index={i}
                            setPrequalifyData={setData}
                            prequalifyData={data}
                            setOpenPopup={setOpenPopup}
                            openPopup={openPopup}
                          />
                        </StyledCenter>
                      ))}
                  </div>
                </ScrollableDiv>
              </div>
            )}

            <DocumentPoolPopUp
              openPopup={openDocumentPool}
              setOpenPopup={setOpenDocumentPool}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              attachFile={addUploadedFile}
              institutionId={institutionData?.institution_id || ""}
            />

            <SectionText style={{ marginTop: "48px" }}>
              Annual Safety Records
            </SectionText>
            <SafetyRecords
              isMobile={isMobile}
              data={data}
              setData={onChangeData}
            />
            <FieldArea width="98%" noJustifyContent={true}>
              <LabelText> Remarks </LabelText>
              <StyledTextField
                multiline
                rows={5}
                placeholder={"Remark"}
                variant="outlined"
                value={safetyRemark}
                onChange={handleSafetyRemarkChange}
              />
            </FieldArea>
          </>
        )}
        {isMobile && (
          <>
            <FilledAccordion
              header="Annual Safety Records"
              details={
                <SafetyRecords
                  isMobile={isMobile}
                  data={data}
                  setData={onChangeData}
                />
              }
            />
          </>
        )}
      </FieldArea>
    </SetupContainer>
  );
};
