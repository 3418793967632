import React, { useState, useEffect, useRef } from 'react';

import {
  FormContainer,
  Row,
  FormTitle,
  PerconnelCardContainer,
  CloseButton,
  StyledLargeTitle,
  StyledMediumTitle,
} from "../styles";

import { InputLabel, StyledLooksGoodButton } from './styles';

import Dialog from "@material-ui/core/Dialog";
import Paper from "@material-ui/core/Paper";
import CloseIcon from "@material-ui/icons/Close";
import Input from "../../../components/inputField";
import Select from "../../../components/selectField";
import logoModal from "../../../assets/preQualifyIcons/logo_small.svg";
import { LooksGoodButton } from "../components/looksGoodButton";
import { FunctionalButton } from '../../../components/functionalButton';

import { LegalPersonnel } from "./LegalPersonnel";


export const LegalStructureForm = ({
  qualificationData,
  setQualificationData,
  setIsLastPanel,
  setPreviousStepPanel,
}) => {
  setIsLastPanel(true);
  setPreviousStepPanel(1);

  const currentYear = new Date().getFullYear();

  const [openDialog, setOpenDialog] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const [dialogFN, setDialogFN] = useState("");
  const [dialogLN, setDialogLN] = useState("");
  const [dialogPos, setDialogPos] = useState("");
  const [dialogRes, setDialogRes] = useState(null);
  const [validSubmit, setValidSubmit] = useState(true);

  const [companyName, setCompanyName] = useState(qualificationData?.project_info ? qualificationData?.project_info.gc_name : "");
  const [disabled, setDisabled] = useState(true);

  const fileInput = useRef();

  const ventureOptions = [
    "Joint Venture",
    "Corporation",
    "Partnership",
    "Registered",
    "Sole Proprietor",
    "Other",
  ];

  useEffect(() => {
    setCompanyName(qualificationData?.project_info ? qualificationData?.project_info.gc_name : "")
  });

  useEffect(() => {
    const data = qualificationData?.legal_structure;

    if (Boolean(data?.establishment_year) &&
        Boolean(data?.venture_type) &&
        data?.personnel?.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  });

  useEffect(() => {
    if (dialogFN?.length > 0 && dialogLN?.length > 0 && dialogPos?.length > 0) {
      setValidSubmit(false);
    } else {
      setValidSubmit(true);
    }
  }, [dialogFN, dialogLN, dialogPos]);

  const handleRemovePersonnel = (qualificationData, firstName, lastName, position) => {
    const updatedQualificationData = qualificationData?.filter(
      (item) =>
        !(
          item.firstName === firstName &&
          item.lastName === lastName &&
          item.position === position
        )
    );

    setQualificationData('legal_structure.personnel', updatedQualificationData);
  };

  const handleAddPersonnel = () => {
    let updatedPersonnelData = [];

    if (qualificationData?.legal_structure?.personnel) {
       updatedPersonnelData = [...qualificationData.legal_structure.personnel];
    }
    updatedPersonnelData.push({
      firstName: dialogFN,
      lastName: dialogLN,
      position: dialogPos,
      resume: dialogRes,
    });

    setQualificationData('legal_structure.personnel', updatedPersonnelData);
  };

  const handleEditPersonnel = () => {
    let updatedPersonnelData = [];

    if (qualificationData?.legal_structure?.personnel) {
       updatedPersonnelData = [...qualificationData.legal_structure.personnel];
    }

    updatedPersonnelData[editIndex] = {
      id: updatedPersonnelData[editIndex].id,
      firstName: dialogFN,
      lastName: dialogLN,
      position: dialogPos,
      resume: dialogRes,
    };

    setQualificationData('legal_structure.personnel', updatedPersonnelData);
  }

  const handleFileSelect = (e) => {
    fileInput.current.click();
  };

  const handleOnChangeFile = () => {
    const file = document.getElementById('resume-selector');
    setDialogRes(file.files[0]);
  };

  return (
    <FormContainer>
      <StyledLargeTitle>Legal Structure</StyledLargeTitle>
      <StyledMediumTitle marginBottom="-8px">Company Details</StyledMediumTitle>
      <Row marginBottom="16px">
        <Select
          style={{ marginRight: "16px" }}
          label="Which year was your firm established?*"
          options={Array.from(
            { length: currentYear - 1899 },
            (_, i) => currentYear - i
          ).map((item) => ({ value: item, label: item }))}
          value={qualificationData?.legal_structure?.establishment_year}
          setValue={(val) => setQualificationData('legal_structure.establishment_year', val)}
          validateFunc={(item) => Boolean(item)}
        />
        <Select
          style={{ marginRight: "16px" }}
          label="What type of venture are you?*"
          options={ventureOptions.map((item) => ({ value: item, label: item }))}
          value={qualificationData?.legal_structure?.venture_type}
          setValue={(val) => setQualificationData('legal_structure.venture_type', val)}
          validateFunc={(item) => Boolean(item)}
        />
      </Row>
      <StyledMediumTitle marginBottom="16px">
        Who are the Officers, Partners, or Principals at {qualificationData?.project_info ?
                                             qualificationData?.project_info?.gc_name :
                                             "your company"}?
      </StyledMediumTitle>
      <PerconnelCardContainer>
        {qualificationData?.legal_structure &&
         qualificationData?.legal_structure?.personnel &&
         qualificationData?.legal_structure?.personnel.map((item, i) => { if(item.firstName) return (
          <LegalPersonnel
            {...item}
            key={i}
            qualificationData={qualificationData?.legal_structure ?
                               qualificationData?.legal_structure?.personnel :
                               []}
            setPersonnel={handleRemovePersonnel}
            setDialogFN={setDialogFN}
            setDialogLN={setDialogLN}
            setDialogPos={setDialogPos}
            setOpenDialog={setOpenDialog}
            index={i}
            setEditIndex={setEditIndex}
          />
        )})}
        { (!qualificationData?.legal_structure?.personnel||
          qualificationData?.legal_structure?.personnel?.length < 4) && (
            <LegalPersonnel
              variant="add"
              onClick={() => setOpenDialog(true)}
            />
          )
        }
      </PerconnelCardContainer>

      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setDialogFN("");
          setDialogLN("");
          setDialogPos("");
          setEditIndex(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <Paper
          elevation={0}
          style={{
            padding: 25,
            width: 400,
            alignSelf: "center",
            overflow: "hidden",
            position: "relative",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CloseButton
            aria-label="close"
            onClick={() => {
              setOpenDialog(false);
              setDialogFN("");
              setDialogLN("");
              setDialogPos("");
              setDialogRes(null);
              setEditIndex(null);
            }}
          >
            <CloseIcon />
          </CloseButton>
          <img src={logoModal} style={{ width: 65, height: 65 }}></img>
          <FormTitle style={{ marginTop: 10 }}>Add Your Personnel</FormTitle>
          <Row style={{ margin: "0px -10px" }}>
            <Input
              style={{ margin: "0px 10px" }}
              label="First Name*"
              type="text"
              placeholder="First Name"
              value={dialogFN}
              setValue={setDialogFN}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noErrorBorder={true}
            />
            <Input
              style={{ margin: "0px 10px" }}
              label="Last Name*"
              type="text"
              placeholder="Last Name"
              value={dialogLN}
              setValue={setDialogLN}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noErrorBorder={true}
            />
          </Row>
          <Input
            label="Position*"
            type="text"
            placeholder="Position"
            value={dialogPos}
            setValue={setDialogPos}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noErrorBorder={true}
          />
          <InputLabel>Resume</InputLabel>
          <FunctionalButton
            buttonTitle={'Resume'}
            id="resume"
            handleButton={(e) => handleFileSelect(e)}
            width="368px"
            isReversedColor={true}
            marginRight="0px"
          />
          <input
            ref={fileInput}
            id="resume-selector"
            onChange={handleOnChangeFile}
            type="file"
            style={{ display: 'none' }}
          />
          <LooksGoodButton
            disabled={validSubmit}
            onClick={() => {
              setOpenDialog(false);
              if (editIndex !== null) {
                handleEditPersonnel();
                setEditIndex(null);
              } else {
                handleAddPersonnel();
              };
              setDialogFN("");
              setDialogLN("");
              setDialogPos("");
              setDialogRes(null);
            }}
          >
            SUBMIT
          </LooksGoodButton>
        </Paper>
      </Dialog>
    </FormContainer>
  );
};
