import React from "react";

import {
  StyledHeading,
  StyledProjectInfo,
  StyledBox,
  StyledHeaderDiv,
  StyledProjectTitleText,
  StyledNumberText,
} from "./styles";

export const HeaderText = ({
  isMobile,
  projectTitle,
  projectNumber,
  projectLocation,
  projectClosingDate,
  projectStatus,
  projectSubmissions,
  projectInvitationCount,
  projectOriginalStatus,
}) => {
  return (
    <StyledHeaderDiv flexDirection="column">
      <StyledProjectTitleText>{projectTitle}</StyledProjectTitleText>
      <StyledNumberText>{projectNumber}</StyledNumberText>
      <StyledHeaderDiv>
        <StyledBox
          isMobile={isMobile}
          boxHeight="80px"
          boxWidth="23%"
          borderRadius="5px"
        >
          <StyledHeading>Project Location</StyledHeading>
          <StyledProjectInfo>{projectLocation}</StyledProjectInfo>
        </StyledBox>
        <StyledBox
          isMobile={isMobile}
          boxHeight="80px"
          boxWidth="25%"
          borderRadius="5px"
        >
          <StyledHeading>Closing Date and Time</StyledHeading>
          <StyledProjectInfo>{projectClosingDate}</StyledProjectInfo>
        </StyledBox>
        <StyledBox
          isMobile={isMobile}
          boxHeight="80px"
          boxWidth="23%"
          borderRadius="5px"
        >
          <StyledHeading>Status</StyledHeading>
          <StyledProjectInfo>{projectStatus}</StyledProjectInfo>
        </StyledBox>
        <StyledBox
          isMobile={isMobile}
          boxHeight="80px"
          boxWidth="23%"
          borderRadius="5px"
        >
          <StyledHeading>Submissions</StyledHeading>

          <StyledProjectInfo>
            {projectOriginalStatus === "open"
              ? projectSubmissions
              : `${projectSubmissions} out of ${projectInvitationCount}`}
          </StyledProjectInfo>
        </StyledBox>
      </StyledHeaderDiv>
    </StyledHeaderDiv>
  );
};
