import styled from "styled-components";
import { TYPOGRAPHY, COLORS } from "../../constants";
import { Typography } from "@material-ui/core";
 
export const LargeText = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.isMobile ? "22px" : "28px"};
    font-style: normal;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0px;
    margin-left: 8px;
  }
`;

export const PopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.isMobile ? "100%" : "90%")};
    margin-top: 0px;
    ${props => props.isMobile ? "" : "margin-left: 24px; margin-right: 24px;"}
`;

export const TitleArea = styled.div`
  display: flex; 
  flex-direction: row;
  margin-top: ${props => props.isMobile ? "4px" : "36px"};
  width: 100%;
`;

export const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: ${props => props.marginTop || "25px"};
  margin-left: 0;
  margin-right: auto;
  font-style: normal;
  font-weight:  ${props => props.labelWeight || 'normal'};
  font-size: ${props => props.labelSize || '12px'};
  line-height: 20px;
  color: #000000;
`;

export const StyledUploadIcon = styled.label`
  margin: ${props => props.margin || 0};
  cursor: pointer;
`;

export const StyledFileName = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
`;