import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import { COLORS, TYPOGRAPHY } from "../../constants";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

export const StyledPaper = styled(Paper)`
  && {
    padding: 25px;
    width: ${(props) => (props.isMobile ? "100%" : "400px")};
    align-self: center;
    overflow: hidden;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`;

export const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

export const FormTitle = styled.div`
  font-family: ${TYPOGRAPHY.primaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-top: 24px;
  color: #000000;
  text-align: center;
`;

export const HighLightText = styled.span`
  font-weight: 600;
`;

export const InfoArea = styled.div`
  ${(props) =>
    props.marginLeft && !props.isMobile
      ? `margin-left: ${props.marginLeft};`
      : ""}
  margin-top: ${(props) => props.marginTop || "24px"};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 41px;
  text-align: center;
  color: #1a051d;
  ${(props) => (props.noJustifyContent ? "" : "justify-content: center;")}
  ${(props) =>
    props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}
  
  .last {
    padding-right: 0px !important;
  }

  .inputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "50%")};
  }

  .fullWidthInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: 100%;
  }

  .oneFifthInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "20%")};
  }

  .thirtyInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "30%")};
  }

  .oneThirdInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "33.33%")};
  }

  .twoThirdInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "66.66%")};
  }

  .oneFifthMobile {
    padding-right: 16px;
    width: ${(props) => (props.isMobile ? "50%" : "20%")};
  }

  .thirtyMobile {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "50%" : "30%")};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &&.MuiListItem-gutters {
    padding-left: 4px;
    padding-right: 4px;
  }
`;

export const StyledButton = styled(Button)`
  && {
    width: ${(props) => (props.width ? props.width : "100px")};
    height: ${(props) => (props.height ? props.height : "36px")};
    background-color: white;
    border: 1px solid ${(props) =>
      props.title === "Go Back"
        ? COLORS.primaryOrange
        : props.title === "Review"
        ? COLORS.primaryOrange
        : props.title === "view"
        ? COLORS.primaryOrange
        : COLORS.primaryOrange};
    box-sizing: border-box;
    border-radius: 6px;
    color: ${(props) =>
      props.title === "Go Back"
        ? COLORS.primaryOrange
        : props.title === "Review"
        ? COLORS.primaryOrange
        : props.title === "view"
        ? COLORS.primaryOrange
        : COLORS.primaryOrange};
    font-family: SF Pro Text;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.5s ease;
    text-transform: uppercase;

    &:hover {
      background-color: ${(props) =>
        props.title === "Go Back"
          ? COLORS.primaryOrange
          : props.title === "Review"
          ? COLORS.primaryOrange
          : props.title === "view"
          ? COLORS.primaryOrange
          : COLORS.primaryOrange};
      color: white;
    }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledButtonReview = styled(Button)`
  && {
    width: ${(props) => (props.width ? props.width : "100px")};
    height: ${(props) => (props.height ? props.height : "36px")};
    background-color: #FF6D1D;
    border: 1px solid ${(props) =>
      props.title === "Go Back"
        ? COLORS.primaryOrange
        : props.title === "Review"
        ? COLORS.primaryOrange
        : props.title === "view"
        ? COLORS.primaryOrange
        : COLORS.primaryOrange};
    box-sizing: border-box;
    border-radius: 6px;
    color: white;
    font-family: SF Pro Text;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.5s ease;
    text-transform: uppercase;

    &:hover {
      background-color: ${(props) =>
        props.title === "Go Back"
          ? COLORS.primaryOrange
          : props.title === "Review"
          ? COLORS.primaryOrange
          : props.title === "view"
          ? COLORS.primaryOrange
          : COLORS.primaryOrange};
      color: white;
    }
`;
