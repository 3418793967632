import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const DonutChartComponent = ({ percentage1, percentage2, size = "140%" }) => {
  const chartRef = useRef(null);
  const textElement = useRef(null);
  useEffect(() => {
    const chart = chartRef.current.chart;
    const centerX = chart.plotWidth / 2 + 13;
    const centerY = chart.plotHeight / 2 + 13;

    if (textElement.current) {
      textElement.current.destroy();
    }
    textElement.current = chart.renderer
      .text(percentage1 + "%", centerX, centerY)
      .attr({
        align: "center",
        zIndex: 10,
      })
      .css({
        fontSize: "18px",
        color: "black",
        fontWeight: "600",
      })
      .add();
  }, [percentage1]);
  const options = {
    chart: {
      type: "pie",
      backgroundColor: null,
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        size: size,
        dataLabels: {
          enabled: false,
        },
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            halo: {
              size: 0,
            },
            borderWidth: 0,
          },
        },
      },
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },

    series: [
      {
        name: "Percentage",
        data: [
          {
            name: "Estimated Revenue",
            y: parseInt(percentage1),
            color: {
              linearGradient: { x1: 1, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "#FFAA7B"],
                [1, "#FF6D1D"],
              ],
            },
          },
          {
            name: "Earned Revenue",
            y: parseInt(percentage2),
            color: "#EBE9F1",
          },
        ],
        innerSize: "60%",
      },
    ],
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
        containerProps={{ style: { height: "140px" } }}
      />
    </div>
  );
};

export default DonutChartComponent;
