import React, { useState, useEffect } from "react";
import { Stepper } from "./Stepper";
import {
  Content,
  PreQualifyRoot,
  FormContainer,
  StyledTempMobileContainer,
  StyledPylonTemp,
  StyledMobileTempText
} from "./styles";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import updateObjectByPath from "../../../common/updateObjectByPath";
import { qualificationSelector } from "data/selectors/user";
import { useLocation } from "react-router-dom";
import { getProjectInfo } from '../../../api/buyers';
import { useDispatch, useSelector } from "react-redux";
import { getPreQualificationInfo, preQualifyUser } from "data/actions/user";
import { checkAuth } from "data/actions/user";
import { useHistory } from "react-router-dom";


//logos
import PylonIcon from "assets/common/pylonIcon.png";
import { ProjectDetails } from "./components/projectDetails";
import { ProjectLocation } from "./components/projectLocation";
import { Categories } from "./components/categories";
import AdditionalDocuments from "./components/additionalDocuments";
import { MultipleSelectCheckmarks } from "./components/requirements";
import { Criteria } from "./components/criteria";

// function SwitchComponents({ active, children }) {
//   return children.filter((child) => child.props.step === active);
// };

function ProjectSetup() {

  const isMobile = useMediaQuery({ maxWidth: 960 });

  let history = useHistory();
  const dispatch = useDispatch();
  const qualification = useSelector(qualificationSelector);
  const [data, setData] = useState({});
  const location = useLocation();
  const ccdcId =  location.state?.ccdcId || '';
  const [action, setAction] =  useState(location.state?.action || "new");
  const [step, setStep] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [fileType, setFileType] = useState('');
  const [pdfFileSequence, setPdfFileSequence] = useState([]);

  // for buyers 
  const contractId = location.state && location.state.contractId? location.state.contractId : '';
  const [hasProjectDetails, setHasProjectDetails] = useState(false);
  const [currNavLocation, setCurrNavLocation] = useState({ step: 0, panel: 0 });

  useEffect(() => {
    // console.log(contractId);
    if (contractId) {
      getInfo(contractId);
    }
  }, []);

  const getInfo = async (contractId) => {
    await getProjectInfo(contractId)
          .then(data => setData(data))
          .catch(err => {});
  };
  
  useEffect(() => {
    // console.log(data);
  },[data])
  useEffect(() => {
    setData(data);
    // if(hasProjectDetails) {
    //       setDisabled(false);
    //     } else {
    //       setDisabled(true);
    //     }
  }, [data, hasProjectDetails,]);
  // const [navigationSequence, setNavigationSequence] = useState({
  //   0: { path: ['/qualifying'], visited: true, name:'Project Selection' },
  //   1: { path: ['/info/owner', '/info/legal'], visited: false, name:'Company Info' },
  //   2: { path: [
  //           '/financial/bank',
  //           '/financial/bond',
  //           '/financial/general',
  //           '/financial/automobile',
  //           '/financial/equipment'
  //         ], visited: false, name: 'Financial Institutions' },
  //   3: { path: ['/safety'], visited: false, name : 'Safety Records' },
 
  const [navigationSequence, setNavigationSequence] = useState({
    0: { path: ['/projectsetup'], visited: true, name:'Project Selection' },
    1: { path: ['/projectlocation'], visited: false, name:'Project Location' },
    2: { path: ['/categories'], visited: false, name:'Common Categories' },
    3: { path: ['/additional'], visited: false, name:'Additional Documents' },
    4: { path: ['/requirements'], visited: false, name:'Requirements' },
    5: { path: ['/criteria'], visited: false, name:'Criteria' },
  });


  // useEffect(() => {
  //   if (action == "edit") {
  //     dispatch(getSelectedCCDCInfo(ccdcId, action));
  //   }
  //   else {
  //     dispatch(getPreQualificationInfo());
  //   }
  // }, [dispatch]);

  // useEffect(() => {
  //   setPrequalifyData({ ...qualification });
  // }, [qualification]);

  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };

  const iconList = [

  ];

  const stepLabels = [
    "Step 1",
    "Step 2",
    "Step 3",
    "Step 4",
    "Step 5",
    "Step 6",
    // "Step 7",
    // "Step 8",
    // "Step 9"
  ];

  const [list, setList] = useState([iconList[0]])
  const [labels, setLabels] = useState([stepLabels[0]])
  const [panel, setPanel] = useState(1);
  const [isBranchPanel, setIsBranchPanel] = useState(false);
  const [isLastPanel, setIsLastPanel] = useState(false)
  const [previousStepPanel, setPreviousStepPanel] = useState("");
  const [disabledSelect, setDisabledSelect] = useState(true)
  const [popupSkip, setPopupSkip] = useState(false);


  const handleNext = (handleGenericGenerate, getPDF) => {
    dispatch(
      checkAuth(
        () => {},
        () => {
          history.push("/account/login");
        }
      )
    );

    // if(isLastPanel){
    //   if (step < 8) {
    //     const tempList = iconList.splice(0, step + 2);
    //     setList(tempList)
    //     const tempLabels = stepLabels.splice(0, step + 2);
    //     setLabels(tempLabels);
    //     onChangeData("step", step + 1);
    //     setStep(step + 1);
    //     setPanel(1);
    //     setIsLastPanel(false);
    //     setIsBranchPanel(false);
    //   }
    // } else {
    //   setPanel(panel + 1);
    // };

   // let data = handleCCDCGenerate();


    if(action === 'new') {
      setAction('edit');
    }

    let newCurrLocation = {};

    if(currNavLocation.panel < navigationSequence?.[currNavLocation.step]?.path?.length - 1) {
      newCurrLocation = { ...currNavLocation, panel: currNavLocation.panel + 1 };
    } else {
      newCurrLocation = { step: currNavLocation.step + 1, panel: 0 };
      let visitedSequence = { ...navigationSequence };
      // visitedSequence[currNavLocation.step].visited = true;
      visitedSequence[newCurrLocation.step].visited = true;
      setNavigationSequence(visitedSequence);
    }

    const newPath = navigationSequence?.[newCurrLocation.step]?.path?.[newCurrLocation.panel];
    history.push('/buyers' + newPath);

    setCurrNavLocation(newCurrLocation);
  };

  return (
    <>
    { !isMobile && (
    <PreQualifyRoot>
      <Stepper
          step={step}
          setStep={setStep}
          iconList={iconList}
          list={list}
          setList={setList}
          setLabels={setLabels}
          panel={panel}
          setPanel={setPanel}
          isLastPanel={isLastPanel}
          setIsLastPanel={setIsLastPanel}
          isBranchPanel={isBranchPanel}
          setIsBranchPanel={setIsBranchPanel}
          previousStepPanel={previousStepPanel}
          onboardingData={data}
          setOnboardingData={setData}
          setAction={setAction}
          setNavigationSequence={setNavigationSequence}
          navigationSequence={navigationSequence}
          currNavLocation={currNavLocation}
          setCurrNavLocation={setCurrNavLocation}
          history={history}
      />
      <FormContainer isMobile={isMobile}>
          <Content>
            <Switch>
              <Route exact path="/buyers/projectsetup">
                <ProjectDetails
                  step={0}
                  handleNext={handleNext}
                  panel={panel}
                  setPanel={setPanel}
                  setIsLastPanel={setIsLastPanel}
                  setPreviousStepPanel={setPreviousStepPanel}
                  data={data}
                  setData={setData}
                  setDownloading={setDownloading}
                  setPopupSkip={setPopupSkip}
                  setIsBranchPanel={setIsBranchPanel}
                />
              </Route>
              <Route exact path="/buyers/projectlocation">
                <ProjectLocation
                  step={1}
                  handleNext={handleNext}
                  panel={panel}
                  setPanel={setPanel}
                  setIsLastPanel={setIsLastPanel}
                  setPreviousStepPanel={setPreviousStepPanel}
                  data={data}
                  setData={onChangeData}
                  setDownloading={setDownloading}
                  setPopupSkip={setPopupSkip}
                  setIsBranchPanel={setIsBranchPanel}
                />
              </Route>
              <Route exact path="/buyers/categories">
                <Categories
                  step={2}
                  handleNext={handleNext}
                  panel={panel}
                  setPanel={setPanel}
                  setIsLastPanel={setIsLastPanel}
                  setPreviousStepPanel={setPreviousStepPanel}
                  data={data}
                  setData={onChangeData}
                  setDownloading={setDownloading}
                  setPopupSkip={setPopupSkip}
                  setIsBranchPanel={setIsBranchPanel}
                />
              </Route>
              <Route exact path="/buyers/additional">
                <AdditionalDocuments
                  step={3}
                  handleNext={handleNext}
                  panel={panel}
                  setPanel={setPanel}
                  setIsLastPanel={setIsLastPanel}
                  setPreviousStepPanel={setPreviousStepPanel}
                  data={data}
                  setData={onChangeData}
                  setDownloading={setDownloading}
                  setPopupSkip={setPopupSkip}
                  setIsBranchPanel={setIsBranchPanel}
                />
              </Route>
              <Route exact path="/buyers/requirements">
                <MultipleSelectCheckmarks
                  step={4}
                  handleNext={handleNext}
                  panel={panel}
                  setPanel={setPanel}
                  setIsLastPanel={setIsLastPanel}
                  setPreviousStepPanel={setPreviousStepPanel}
                  data={data}
                  setData={onChangeData}
                  setDownloading={setDownloading}
                  setPopupSkip={setPopupSkip}
                  setIsBranchPanel={setIsBranchPanel}
                />
              </Route>
              <Route exact path="/buyers/criteria">
                <Criteria
                  step={4}
                  handleNext={handleNext}
                  panel={panel}
                  setPanel={setPanel}
                  setIsLastPanel={setIsLastPanel}
                  setPreviousStepPanel={setPreviousStepPanel}
                  data={data}
                  setData={onChangeData}
                  setDownloading={setDownloading}
                  setPopupSkip={setPopupSkip}
                  setIsBranchPanel={setIsBranchPanel}
                />
              </Route>

              {/* <Route exact path="/prequalify/projectsetup">
                <TemplateSelection
                  step={0}
                  handleNext={handleNext}
                  panel={panel}
                  setPanel={setPanel}
                  setIsLastPanel={setIsLastPanel}
                  setPreviousStepPanel={setPreviousStepPanel}
                  data={data}
                  setPrequalifyData={onChangeData}
                  setDownloading={setDownloading}
                  setPopupSkip={setPopupSkip}
                  setIsBranchPanel={setIsBranchPanel}
                />
              </Route>
              <Route path="/prequalify/info">
                <OwnerPartners
                  step={1}
                  handleNext={handleNext}
                  panel={panel}
                  setPanel={setPanel}
                  setIsLastPanel={setIsLastPanel}
                  setPreviousStepPanel={setPreviousStepPanel}
                  data={data}
                  setPrequalifyData={onChangeData}
                  popupSkip={popupSkip}
                  setIsBranchPanel={setIsBranchPanel}
                />
              </Route>
           */}
            {/* </SwitchComponents> */}
            </Switch>
          </Content>
      </FormContainer>
  </PreQualifyRoot>
  )}
  {isMobile && (
    <StyledTempMobileContainer>
      <StyledPylonTemp src={PylonIcon} />
      <StyledMobileTempText>
      Please switch to <br/>desktop for best experience
      </StyledMobileTempText>
    </StyledTempMobileContainer>
  )}
  </>
  );
};


export default ProjectSetup;
