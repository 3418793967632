import React from 'react';

const Office = ({ active, completed }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1545:3177)">
        <g filter="url(#filter0_d_1545:3177)">
          <circle cx="14" cy="14" r="14" fill="white"/>
          <circle cx="14" cy="14" r="13.5" fill={active || completed ? "#FF6D1D" : "white"} stroke="#ECEBED"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4553 14.0917C9.97062 14.0917 7 14.8344 7 16.319V17.9094H15.91V16.319C15.91 14.8344 12.9406 14.0917 11.4553 14.0917ZM16.5461 14.0917C16.3609 14.0917 16.1535 14.1032 15.9314 14.1261C16.6683 14.6594 17.1819 15.3753 17.1819 16.319V17.9094H20.9995V16.319C20.9995 14.8344 18.0308 14.0917 16.5461 14.0917ZM11.4571 9C10.4032 9 9.54791 9.85593 9.54791 10.9098C9.54791 11.9636 10.4032 12.8189 11.4571 12.8189C12.5115 12.8189 13.3605 11.9636 13.3605 10.9098C13.3605 9.85593 12.5115 9 11.4571 9ZM16.5485 9C15.4934 9 14.6388 9.85593 14.6388 10.9098C14.6388 11.9636 15.4934 12.8189 16.5485 12.8189C17.6024 12.8189 18.4507 11.9636 18.4507 10.9098C18.4507 9.85593 17.6024 9 16.5485 9Z" fill={active || completed ? "white" : "#D0C9D6"}/>
      </g>
      <defs>
        <filter id="filter0_d_1545:3177" x="-64" y="-57" width="156" height="156" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="7"/>
          <feGaussianBlur stdDeviation="32"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1545:3177"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1545:3177" result="shape"/>
        </filter>
        <clipPath id="clip0_1545:3177">
          <rect width="28" height="28" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default Office;
