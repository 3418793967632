import styled from "styled-components";

export const BackButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 20px;
`;

export const BackButton = styled.button`
  background-color: transparent;
  color: #ff6d1d;
  font-size: 16px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-family: "SF Pro Text";

  &:hover {
    opacity: 0.8;
  }
`;
