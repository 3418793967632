import React, { useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import { FileRow } from "./tableRows";
import {
  StyledTable,
  StyledTableRow,
  StyledTableCell,
  StyledActionHeaderBorderCell,
} from "./styles";
import { MultiSelectedRow } from "./multiSelectedTableRows";

const FilesListTable = ({
  headers,
  data,
  isQualified,
  actions,
  actionType,
  icons,
  body,
  concise,
  handleSelected,
  handleClickRow,
  hover,
  borderSpacing,
  actionsHeaders,
  borderRadius,
  boxShadow,
  noActions,
  multiSelect,
  pickedArray,
  largefont = true,
}) => {
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndexList, setSelectedIndexList] = useState([]);
  return (
    <StyledTable
      concise={concise}
      style={{ borderCollapse: "separate" }}
      borderSpacing={borderSpacing}
    >
      {headers && headers.length > 0 && (
        <TableHead>
          <StyledTableRow header={true}>
            {headers.map((header) => (
              <StyledTableCell
                align={header.align}
                width={header.width}
                header={true}
                largeFont={largefont}
              >
                {header.title}
              </StyledTableCell>
            ))}
            {actionsHeaders && actionsHeaders.length > 0 && (
              <>
                {actionsHeaders.map((actionsHeader) => (
                  <StyledActionHeaderBorderCell
                    align={actionsHeader.align}
                    header={true}
                  >
                    {actionsHeader.title}
                  </StyledActionHeaderBorderCell>
                ))}
              </>
            )}
          </StyledTableRow>
        </TableHead>
      )}
      {data && (
        <TableBody>
          {data &&
            data.map((row, index) => {
              return (
                <>
                  {multiSelect && (
                    <MultiSelectedRow
                      borderRadius={borderRadius}
                      boxShadow={boxShadow}
                      {...row}
                      index={index}
                      isQualified={isQualified}
                      actions={actions}
                      actionType={actionType}
                      icons={icons}
                      body={body}
                      selectedIndexList={selectedIndexList}
                      setSelectedIndexList={setSelectedIndexList}
                      handleSelected={handleSelected}
                      handleClickRow={handleClickRow}
                      hover={hover}
                      noActions={noActions}
                      pickedArray={pickedArray}
                    />
                  )}
                  {!multiSelect && (
                    <FileRow
                      borderRadius={borderRadius}
                      boxShadow={boxShadow}
                      {...row}
                      index={index}
                      isQualified={isQualified}
                      actions={actions}
                      actionType={actionType}
                      icons={icons}
                      body={body}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                      handleSelected={handleSelected}
                      handleClickRow={handleClickRow}
                      hover={hover}
                      noActions={noActions}
                      pickedArray={pickedArray}
                    />
                  )}
                </>
              );
            })}
        </TableBody>
      )}
    </StyledTable>
  );
};

export default FilesListTable;
