import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { numberWithCommas } from "common/numberFormat";

const borderColor = '#EBE9F1'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#EBE9F1',
        backgroundColor: '#F8F8F8',
        alignItems: 'center',
        height: 24,
        fontStyle: 'normal',
        paddingLeft: 15,
        color: '#000000',
        fontWeight: 400,
        fontSize: 10,
    },
    name: {
        width: '40%',
        textAlign: 'left',
        borderRightColor: borderColor,
        color: '#888888',
    },
    year: {
        width: '20%',
        borderRightColor: borderColor,
        textAlign: 'left',
        color: '#000000',
    },
});

const PageFourConstructionValueTableRow = ({ items }) => {
    const rows = items?.map(item =>
        <View style={styles.row}>
            <Text style={styles.year}>{numberWithCommas(item?.current_year)}</Text>
            <Text style={styles.year}>{numberWithCommas(item?.second_past_year)}</Text>
            <Text style={styles.year}>{numberWithCommas(item?.third_past_year)}</Text>
            <Text style={styles.year}>{numberWithCommas(item?.fourth_past_year)}</Text>
            <Text style={styles.year}>{numberWithCommas(item?.fifth_past_year)}</Text>
        </View>
    );
    return (<Fragment>{rows}</Fragment>)
};

export default PageFourConstructionValueTableRow;
