import React, { useRef, useState, useEffect } from "react";
import { CloseButton } from "components/closeButton";
import { FunctionalButton } from 'components/functionalButton';
import report from './report.svg';
import Input from 'components/inputField';
import { 
  PopupContainer, 
  LargeText, 
  TitleArea, 
  StyledUploadIcon,
  StyledFileName,
  InputLabel,
} from "./styles";
import TextField from "components/textField";
import updateObjectByPath from "common/updateObjectByPath";
import AddResumeButton from 'assets/onBoarding/AddResumeButton';
import CloseIcon from "@material-ui/icons/Close";
import { Popup } from 'components/popup';

import { reportIssue } from "api/dashboard";


export const ReportBugPopup = ({
  openPopup,
  setOpenPopup,
  setOpenSuccessPopup,
  isMobile
}) => {
  const handleDone = () => {
    setData({});
    setOpenPopup(false);
  };

  const fileInput = useRef();
  const handleFileSelect = () => {
    fileInput.current.click();
  };

  const [data, setData] = useState({})
  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };

  const [disabled, setDisabled] = useState(true)
  useEffect(() => {
    if (data?.title && data?.description){
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [data])

  const handleReport = () => {
    let formData = new FormData();
    if(data?.screenshot) {
      formData.append('screenshot', data?.screenshot);
    }
    formData.append('data', JSON.stringify(data));
    reportIssue(formData, () => {handleDone(); setOpenSuccessPopup(true)}, () => {});
  }

  return (
    <Popup
        open={openPopup}
        setOpen={setOpenPopup}
        width={isMobile ? "100%" : "550px"}
        height={isMobile ? "580px" : "650px"}
        maxWidth={false}
        dialogContent={(
          <>
            <PopupContainer isMobile={isMobile}>
              <CloseButton handleClose={handleDone} />
              <TitleArea isMobile={isMobile}>
                <img src = {report} />
                <LargeText isMobile={isMobile}>Report a problem</LargeText>
              </TitleArea>
              <Input
                label="Bug"
                type="text"
                placeholder="Short Description of bug"
                labelSize="12px"
                fontSize="18px"
                value={data?.title}
                setValue={(val) => onChangeData('title', val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
              />
              <TextField
                label="Detailed Description"
                placeholder="Description of the steps in recieving the bug"
                value={data?.description}
                setValue={(val) => onChangeData('description', val)}
                validateFunc={(item) => item.length > 0}
              />
              <InputLabel>File Upload</InputLabel>
              <div>
              {!data?.screenshot && (
                <>
                <StyledUploadIcon>
                  <input
                    ref={fileInput}
                    id="resume-selector"
                    accept= ".jpg,.jpeg,.png, image/jpeg,image/png"
                    onClick={(e) => {
                      e.target.value = null;
                      handleFileSelect();
                    }}
                    onChange={(e) => {
                        onChangeData('screenshot', e.target.files[0]);
                    }}
                    type="file"
                    style={{ display: 'none' }}
                  />
                  <FunctionalButton
                    buttonTitle="+ ADD A PHOTO"
                    width={isMobile ? "100%" : "271px"}
                    height={isMobile ? "48px" : "53px"}
                    marginRight="0px"
                    handleButton={handleFileSelect}
                    isReversedColor={true}
                  />
                </StyledUploadIcon>
                <InputLabel marginTop="8px">{"Maximum 1 photo with a total size of up to 5mb"}</InputLabel>
                </>
              )}
                
                {data?.screenshot && (
                  <StyledFileName>
                    <span>{data?.screenshot?.name}</span>
                    <span style={{ cursor: "pointer" }} onClick={() => onChangeData('screenshot', null)}><CloseIcon /> </span>
                  </StyledFileName>
                )}
              </div>
              <FunctionalButton
                buttonTitle="SUBMIT REPORT"
                width={isMobile ? "100%" : "271px"}
                height={isMobile ? "48px" : "53px"}
                marginRight="0px"
                marginTop="40px"
                handleButton={handleReport}
                disabled={disabled}
              />
            </PopupContainer>
        </>
        )}
      />
  )
}