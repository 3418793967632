import React from 'react';

const Step1 = ({ active, completed }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2222:16210)">
      <g filter="url(#filter0_d_2222:16210)">
      <circle cx="16" cy="16" r="16" fill="white"/>
      </g>
      <circle cx="16" cy="16" r="13" fill={active || completed ? "#FF6D1D" : "white"} stroke="#ECEBED"/>
      </g>
      <path d="M15.4385 21H17.2021V11.1357H15.4453L12.8682 12.9473V14.6084L15.3223 12.8721H15.4385V21Z" fill={active || completed ? "white" : "#D0C9D6"}/>
      <defs>
      <filter id="filter0_d_2222:16210" x="-64" y="-57" width="160" height="160" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="7"/>
      <feGaussianBlur stdDeviation="32"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2222:16210"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2222:16210" result="shape"/>
      </filter>
      <clipPath id="clip0_2222:16210">
      <rect width="32" height="32" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
};

export default Step1;

