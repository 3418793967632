import React from 'react';
import documents from './documentsIcon.svg'
import styled from "styled-components";

const DocumentsIcon = ({width, height, isMobile}) => {
  return (
    <StyledImg width={width || "106"} height={height || "112"} src={documents} isMobile={isMobile}/>
  )
};

export default DocumentsIcon;

const StyledImg = styled.img`
  ${props => props.isMobile ? "margin-bottom: 4px; margin-top: 8px;" : ""}
`;