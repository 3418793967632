import React from 'react';
import { Text, View, StyleSheet, Link } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 10,
    justifyContent: 'flex-end',
    width: '50%',
    flex: 1,
    paddingLeft: 30,
    paddingRight: 30,
  },
  remarksText: {
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: 400,
  },
});

const PageProgressIsSatisfactoryNoRemarks = ({ csrData }) => (
  <View style={styles.headerContainer}>
    <Text style={styles.remarksText}>
      {csrData?.data?.remarks || ""}
    </Text>
  </View>
);

export default PageProgressIsSatisfactoryNoRemarks;
