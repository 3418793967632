import styled from 'styled-components';
import { TYPOGRAPHY, COLORS } from '../../../constants';
import { FormTitle } from "../styles";


export const StyledBottomButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: ${props => props.marginTop?  props.marginTop : '0px'};
  justify-content: ${props => props.justifyContent?  props.justifyContent : 'flex-end'};
  margin-left: 24px;
`;

export const StyledPopupTitle = styled(FormTitle)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 18px;
    font-weight: 600;
    margin-top: 12px;
    line-height: 1.3;
    color: ${COLORS.primaryRed};
  }
`;

export const StyledPopupText = styled(FormTitle)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 12px;
    font-weight: 500;
    margin-top: 12px;
  }
`;

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const StyledIconContainer = styled.div`
  margin-top: 54px;
  margin-right: 16px;
`;
