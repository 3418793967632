import styled from 'styled-components';
import { FormTitle, Row, StyledInputContainer } from "../styles";
import { TYPOGRAPHY, COLORS } from "../../../constants";
import { StyledTableCell } from '../../../components/filesListTable/styles';
import { Typography } from '@material-ui/core';


export const StyledButtonRow = styled(Row)`
  && {
    margin-top: 24px;
  }
`;

export const StyledUploadText = styled(FormTitle)`
  && {
    font-size: 18px;
    font-family: ${TYPOGRAPHY.primaryFont};
    margin-bottom: 8px;
    color: ${COLORS.darkGrey2};
  }
`;

export const StyledUploadArea = styled.div`
  margin-top: 32px;
`;

export const StyledLookGoodButton = styled(Row)`
  && {
    margin-top: 16px;
  }
`;

export const StyledTableCellFirst = styled(StyledTableCell)`
  width: 80%;
`;

export const StyledFileDisplay = styled.div`
  width: 90%;
`;

export const StyledSpan = styled.span`
  display: block;
  cursor: pointer;
  color: ${COLORS.primaryOrange};
  text-transform: uppercase;
  margin-top: 16px;
`;

export const StyledTitle = styled(FormTitle)`
  && {
    margin-top: 56px;
    margin-bottom: 16px;
    color: ${COLORS.darkGrey2};
    font-size: 24px;
  }
`;

export const StyledYear = styled(Typography)`
  justify-content: center;
  display: flex;
  flex-direction: column;

  && {
    font-size: 15px;
    margin-top: 40px;

    &.MuiTypography-root{
      font-weight: 600;
    }
  }
`;

export const StyledSafetyRecords = styled(StyledInputContainer)`
  && {
    margin-bottom: 48px;
  }
`;
