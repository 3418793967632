import React, { useState, useRef, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { COLORS } from "../../constants";

import { StyledCenter, StyledQuestion } from "./styles";

import { AlertPopup } from "components/alertPopup/alertPopup";
import { FormContainer } from "pages/newPreQualify/styles";
import { UploadArea } from "pages/preQualifyForm/components/uploadArea";
import { WrapperForUploadedFileDisplay } from "./uploadedFileDisplay";
import { deleteFile } from "api/file_managment";
import { useSelector } from "react-redux";
import { contactSelector } from "../../data/selectors/contact";
import { FunctionalButton } from "components/functionalButton";
import DocumentPoolPopUp from "components/documentPoolPopUp";
import ConciseTable from "components/filesListTable/conciseTable";
import { ScrollableDiv } from "pages/documentPool/styles";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";

export const AdditionalDocuments = ({
  ccdcId,
  files = [],
  readOnly,
  rfpqId,
  setFiles,
  institutionId,
}) => {
  const inputFile = useRef(null);
  const [uploadSize, setUploadSize] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);
  const contactData = useSelector(contactSelector);
  const [openDocumentPool, setOpenDocumentPool] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [internalSearchResults, setInternalSearchResults] = useState(files);
  const [query, setQuery] = useState("");
  const fileLimitCount = contactData?.user_access_control?.file_limit_count;
  const fileLimitSize = contactData?.user_access_control?.file_limit_size;

  const fileLimitSizeMB = (fileLimitSize / (1024 * 1024)).toFixed(2);
  const headers = [
    { title: "Title", width: "78%" },
    { title: "Size(MB)", width: "20%" },
    { title: "" },
  ];
  useEffect(() => {
    if (files.length === 0) {
      setUploadSize(0);

      return;
    }

    let fileSizes = 0;

    files?.forEach((file) => {
      fileSizes = fileSizes + file.size;
    });

    setUploadSize(fileSizes / 1000000);
  }, [files]);

  const addUploadedFile = () => {
    let temp = [...files];

    for (let key in selectedFiles) {
      if (!isNaN(key)) {
        temp.push(selectedFiles[key]);
      }
    }
    setFiles(temp);
    setOpenDocumentPool(false);
  };

  const deleteAdditionalFile = (index) => {
    let temp = [...files];
    temp.splice(index, 1);
    setFiles(temp);
  };

  const handleOnDragEnd = (resultEvent) => {
    if (!resultEvent.destination) {
      return;
    }

    const temp = [...files];
    const [reorderedItem] = temp.splice(resultEvent.source.index, 1);
    temp.splice(resultEvent.destination.index, 0, reorderedItem);
    setFiles(temp);
  };
  const handleDocumentPoolPopUp = () => {
    setOpenDocumentPool(true);
  };

  return (
    <FormContainer style={{ marginTop: readOnly ? 0 : "20px" }}>
      {!readOnly && (
        <>
          <div style={{ display: "flex", width: "100%" }}>
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                justifyContent: "flex-start",
                width: "70%",
              }}
            >
              <SearchBar
                filteredData={files}
                setInternalSearchResults={setInternalSearchResults}
                internalSearch={true}
                query={query}
                setQuery={setQuery}
                searchWidth="100%"
                searchPlaceHolder="File Name..."
              />
            </div>
            <FunctionalButton
              buttonTitle="Document Pool"
              marginLeft="20px"
              handleButton={() => handleDocumentPoolPopUp()}
              width="250px"
              isReversedColor={true}
            />
          </div>
        </>
      )}
      {readOnly && files.length < 1 && (
        <div
          style={{
            alignItems: "center",
            backgroundColor: "white",
            border: `dashed 2px ${COLORS.lightGrey1}`,
            display: "flex",
            height: "186px",
            justifyContent: "center",
            width: "100%",
          }}
        >
          No Files Selected
        </div>
      )}
      {files.length > 0 && (
        <div style={{ marginTop: readOnly ? 0 : "20px", width: "90%" }}>
          <ConciseTable headers={headers} />
          <ScrollableDiv style={{ maxHeight: "400px" }}>
            <div style={{ marginRight: "15px" }}>
              {files.length > 0 &&
                (query && query !== "" ? internalSearchResults : files)?.map(
                  (item, i) => (
                    <StyledCenter style={{ marginTop: 0 }}>
                      <WrapperForUploadedFileDisplay
                        key={`file-${i}`}
                        file={item}
                        displayPercentage="700px"
                        deleteFile={deleteAdditionalFile}
                        index={i}
                        ccdcId={ccdcId}
                        readOnly={readOnly}
                        rfpqId={rfpqId}
                        setFiles={setFiles}
                        files={files}
                        setOpenPopup={setOpenPopup}
                        openPopup={openPopup}
                      />
                    </StyledCenter>
                  )
                )}
            </div>
          </ScrollableDiv>
        </div>
      )}

      <AlertPopup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        headerText={"You have exceeded the file limit."}
        subHeaderText={`You can upload a maximum of ${fileLimitCount} documents or ${fileLimitSizeMB}MB.`}
      />

      <DocumentPoolPopUp
        openPopup={openDocumentPool}
        institutionId={institutionId}
        setOpenPopup={setOpenDocumentPool}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        attachFile={addUploadedFile}
      />
    </FormContainer>
  );
};

export default AdditionalDocuments;
