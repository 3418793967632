import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';


const styles = StyleSheet.create({
    headerContainer1: {
        marginTop: 10,
        justifyContent: 'flex-start',
        width: '60%',
        // flex: 1,
        paddingLeft: 30,
        paddingRight: 0,
        paddingTop: 30,   
    },
    headerContainer2: {
        marginTop: 10,
        justifyContent: 'flex-end',
        width: '40%',
        flex: 1,
        paddingLeft: 10,
        paddingRight: 30,
    },
    banking: {
        marginTop: -40,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
    },
    bankingWithoutLogo: {
        marginTop: -7,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
    },
    spanText: {
        color: '#888888',
    },
});

const PageThreeBanking = ({ prequalifyData, companyLogo }) => (
    <View style={{ display: 'flex', flexDirection: 'row' }}>
        <View style={styles.headerContainer1}>
        {companyLogo !== ""  ? <Text style={styles.banking}>Banking</Text> : <Text style={styles.bankingWithoutLogo}>Banking</Text> }
            <Text>
                <span style={styles.spanText}>Institution Name: </span>
                {prequalifyData?.data?.bankName}
            </Text>
            <Text>
                <span style={styles.spanText}>Address: </span>
                {prequalifyData?.data?.bankAddress}
            </Text>
        </View>

        <View style={styles.headerContainer2}>
            <Text style={styles.banking}></Text>
            <Text>
                <span style={styles.spanText}>Contact Name: </span>
                {prequalifyData?.data?.bankContactName}
            </Text>
            <Text>
                <span style={styles.spanText}>Email: </span>
                {prequalifyData?.data?.bankContactEmail}
            </Text>
        </View>
    </View>
);

export default PageThreeBanking;
