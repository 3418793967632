import styled from 'styled-components';
import { Dropdown, Menu } from 'semantic-ui-react';
import { TYPOGRAPHY, COLORS } from '../../constants';
export const StyledMenu = styled(Menu)`
  &&.ui.menu {
    width:  ${props => props.width ? props.width : '204px'};
    ${props => props.height ? `height: ${props.height}` : ''};
    box-shadow: none;
    ${props => props.hasData ? `border: 1px solid ${COLORS.primaryOrange};` : ""}

    .item>i.dropdown.icon{
      margin-top: 8px;
      color: #3F3356;
    }

    .item>.label{
      font-size: 14px;
      font-family: ${TYPOGRAPHY.primaryFont};
      background: ${COLORS.secondaryLightOrange};
      color: ${COLORS.primaryLightOrange};
      margin-right: 8px;
      font-weight: 500;

    }
  }

  &&.ui.compact.menu {
    .item:last-child{
      border-radius: 0.285714rem .28571429rem .28571429rem 0.285714rem;
    }
  }
`;


export const StyledDropdown = styled(Dropdown)`
  &&&& {
    width:  ${props => props.width ? `${props.width}` : '204px'};
    ${props => props.height ? `min-height: ${props.height}` : 'min-height: 35px'};
    ${props => props.readOnly ? '' : props.hasData ? `border: 1px solid ${COLORS.darkGrey3};` : `border: 1px solid ${COLORS.darkGrey3};`}
    border-radius: 6px;
    position: ${props => props.position ? props.position : ''};
    margin-top: ${props => props.position ? '-18px' : ''};
    min-width: ${props => props.minWidth ? `${props.minWidth}` : ''}
    position: fixed;
    ${props => props.readOnly ? `background-color: ${ COLORS.backgroundGrey };` : ''}


    &.ui.multiple.dropdown>.label{
      font-size: 14px;
      font-family: ${TYPOGRAPHY.primaryFont};
      background: ${COLORS.secondaryLightOrange};
      color: ${COLORS.primaryOrange};
      margin-right: 8px;
      font-weight: 500;
      margin-top: 8px;
      box-shadow: none;
    }

    &.ui.dropdown>.text{
      font-size: 14px;
      font-family: ${TYPOGRAPHY.primaryFont};
      color:  ${props => props.hasData && !props.multiple?  `${COLORS.primaryOrange}` : `${COLORS.neutralGrey}`};
      ${props => !props.hasData ? (props.multiple ? 'margin-top: 12px' : props.position ? 'margin-top: 1px' : 'margin-top: 8px') : ''};
      ${props => props.hasData && !props.multiple ? `background: ${COLORS.secondaryLightOrange};
                                  border: 0 solid transparent;
                                  border-radius: 0.28571429rem;
                                  transition: background .1s ease;
                                  padding: 0.35714286em 0.78571429em;
                                  margin-left: -10px;`: ''}
    }

    &.ui.selection.active.dropdown {
      .menu {
        border-color: ${COLORS.primaryOrange};
      }
    }

    &.ui.selection.active.dropdown:hover {
      border-color: ${COLORS.primaryOrange};
    }

    &.ui.selection.dropdown>.dropdown.icon{
      ${props => props.readOnly ? 'display: none;' : ''}
      color: ${COLORS.neutralGrey};
      margin-top: ${props => props.value ? '-9px' : ''};
    }

    &.ui.selection.dropdown>.dropdown.icon::before{
      height: 200px;
      width: 200px;
    }
  }
  .divider{
    border-bottom:0px;
  }
`;

export const StyledLabel = styled.p`
  && {
    margin-bottom: 4px;
    font-family:  ${TYPOGRAPHY.primaryFont};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
`;
