import React from "react";
import { InfoArea, StyledRow, SectionText } from "./styles";
import { AddressAutoFields } from "./AddressAutoFields";

export const Step5 = ({
  setDisable,
  addData,
  setAddData,
  isMobile
}) => {

  return (
    <InfoArea isMobile={isMobile} marginTop="0px" marginLeft="8px" isColumn={true} >
          <SectionText>Company Address</SectionText>
          <AddressAutoFields data={addData} setData={setAddData} />
    </InfoArea>

  )
}