import React, { useState } from "react";
import {
  StyledContainerTitle,
  StyledHeaderContent,
  StyledButtonArea,
  StyledContainerInside,
  StyledAlertsOuterContainer,
  StyledAlertsInnerContainer,
  StyledAlertsCircle,
  StyledAlertsText,
} from "./styles";
import { FunctionalButton } from "../../../components/functionalButton";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";

export const AllClientHeader = ({
  title,
  hasButton,
  handleButton,
  marginBottom,
  subTitle,
  rfpqPage,
  setInternalSearchResults,
  query,
  setQuery,
  data,
  marginTop,
  alerts = false,
}) => {
  return (
    <StyledContainerTitle marginTop={marginTop}>
      <StyledContainerInside>
        <StyledHeaderContent marginBottom={marginBottom}>
          {title}
        </StyledHeaderContent>
        {subTitle && (
          <div style={{ marginBottom: "16px", fontSize: "16px" }}>
            {subTitle}
          </div>
        )}
      </StyledContainerInside>
      <StyledButtonArea>
        {hasButton && (
          <FunctionalButton
            buttonTitle="Start New"
            handleButton={handleButton}
            width="180px"
            height="48px"
            isReversedColor={true}
            disabled={rfpqPage ? false : true}
          />
        )}
        {!alerts && (
          <SearchBar
            searchWidth="60%"
            internalSearch={true}
            query={query}
            setQuery={setQuery}
            setInternalSearchResults={setInternalSearchResults}
            filteredData={data}
          />
        )}
        {alerts && (
          <>
            <StyledAlertsOuterContainer>
              <StyledAlertsInnerContainer>
                <StyledAlertsCircle>
                  <StyledAlertsText>
                    {data?.projects?.length || 0}
                  </StyledAlertsText>
                </StyledAlertsCircle>
                <StyledAlertsText
                  style={{
                    color: "#888888",
                    fontSize: 12,
                    lineHeight: 2,
                    fontWeight: "400",
                  }}
                >
                  Project
                </StyledAlertsText>
              </StyledAlertsInnerContainer>
            </StyledAlertsOuterContainer>
            <StyledAlertsOuterContainer>
              <StyledAlertsInnerContainer>
                <StyledAlertsCircle style={{ background: "#FFEBC6" }}>
                  <StyledAlertsText style={{ color: "#C48A1A" }}>
                    {data?.insurance?.length || 0}
                  </StyledAlertsText>
                </StyledAlertsCircle>
                <StyledAlertsText
                  style={{
                    color: "#888888",
                    fontSize: 12,
                    lineHeight: 2,
                    fontWeight: "400",
                  }}
                >
                  Insurance
                </StyledAlertsText>
              </StyledAlertsInnerContainer>
            </StyledAlertsOuterContainer>
            <StyledAlertsOuterContainer>
              <StyledAlertsInnerContainer>
                <StyledAlertsCircle style={{ background: "#C0FAD2" }}>
                  <StyledAlertsText style={{ color: "#2F9B51" }}>
                    {data?.wip_updated === false ? 1 : 0 || 0}
                  </StyledAlertsText>
                </StyledAlertsCircle>
                <StyledAlertsText
                  style={{
                    color: "#888888",
                    fontSize: 12,
                    lineHeight: 2,
                    fontWeight: "400",
                  }}
                >
                  WIP
                </StyledAlertsText>
              </StyledAlertsInnerContainer>
            </StyledAlertsOuterContainer>
          </>
        )}
      </StyledButtonArea>
    </StyledContainerTitle>
  );
};
