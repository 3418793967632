import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from "../../../constants";
import { StyledTableCell } from "../../../components/filesListTable/styles";
import Tab from "@mui/material/Tab";

export const StyledTableCellFirst = styled(StyledTableCell)`
  width: ${(props) => (props.isMobile ? "70%" : "50%")};
`;

export const StyledTableCellSecond = styled(StyledTableCell)`
  width: 30%;
`;

export const StyledTableCellStatus = styled(StyledTableCell)`
  ${(props) => (props.isMobile ? "width: 20%;" : "")}
`;

export const ListContainer = styled.div`
  width: 100%;
  margin-top: -24px;
  //height: 300px;
  max-height: 600px;
  overflow: auto;
  &&::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &&::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: ${COLORS.scrollbarTrack};
    ${(props) => (props.show ? "" : "visibility: hidden;")};
  }

  &&::-webkit-scrollbar-thumb {
    background-color: ${COLORS.primaryOrange};
  }
`;

export const ProgressColumn = styled.div`
  width: 100%;
  text-align: center;
`;
export const StyledTab = styled(Tab)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 16px;
    font-weight: 500;
    text-transform: none;
    color: ${COLORS.blurOutText};
    padding-right: 8px;
    padding-left: 8px;
    border: 1px solid #dae0e7;
    background: #f9f9f9;
    border-radius: 10px 10px 0px 0px;
    transition: all 0.1s;
    cursor: pointer;
    user-select: none;
  }
  &:hover {
    color: ${COLORS.primaryOrange};
  }
  &&.Mui-selected {
    color: ${COLORS.primaryOrange};
    font-size: 20px;
    background: #f9fbfc;
    border-bottom-color: transparent;
  }
`;
export const SetupContainer = styled.div`
  // ${(props) => (!props.isMobile ? "margin-left: 110px" : "")};
  // padding-bottom: 32px;
  position: relative;
  // z-index: 0;
  width: 100%;
`;
