export const getestimatedRevenue = (obj) => {
  let estimatedRevenue = 0;

  if (obj?.wip_data?.length > 0) {
    estimatedRevenue =
      parseFloat(
        obj?.wip_data[obj?.wip_data?.length - 1]
          ?.project_value_or_estimated_revenue
      ) || 0;
  } else {
    estimatedRevenue = parseFloat(obj?.value) || 0;
  }
  return estimatedRevenue;
};

export const getestimatedCostOfRevenue = (obj) => {
  let EstimatedCostOfRevenue = 0;
  if (obj?.wip_data?.length > 0) {
    EstimatedCostOfRevenue = parseFloat(
      obj?.wip_data[obj?.wip_data?.length - 1]?.estimated_cost_of_revenue
    );
  } else {
    EstimatedCostOfRevenue = parseFloat(
      obj?.original_estimated_cost_of_revenue
    );
  }
  return EstimatedCostOfRevenue;
};

export const getFirstPpcObject = (obj) => {
  const tempData = [...obj?.wip_data];
  const sorted = tempData?.sort((a, b) => b.id - a.id);

  const firstPPCDataObject = sorted?.find(
    (data) => data.ppc_data && Object.keys(data.ppc_data).length !== 0
  );
  return firstPPCDataObject;
};
