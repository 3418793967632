import { getInstitutionProfileInfo } from "data/actions/serviceProvider";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

import { MainDocPool } from "./mainDocPool";
import { ScreenRoot } from "./styles";

export default function DocumentPool() {
  const location = useLocation();
  const dispatch = useDispatch();
  const institution_id = location.state?.institution_id || "";
  const currentDashboardUrl = localStorage.getItem("current_dashboard_url");
  useEffect(() => {
    if (currentDashboardUrl === "/service-provider-dashboard") {
      dispatch(
        getInstitutionProfileInfo(
          { institution_id: institution_id },
          () => {},
          () => {}
        )
      );
    }
  }, []);
  return (
    <ScreenRoot>
      <MainDocPool institutionId={institution_id} />
    </ScreenRoot>
  );
}
