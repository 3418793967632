import styled from "styled-components";
import { TYPOGRAPHY, COLORS } from "../../constants";
import Typography from '@material-ui/core/Typography';

export const ImagePopup = styled.div`
  margin-top: 16px;
`;

export const PopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.isMobile ? "90%" : "100%")};
    margin-top: 0px;
    justify-content: center;
    align-items: center;
`;

export const ButtonArea = styled.div`
    margin-top: 24px;
    justify-content: center;
    display: flex;
    flex-direction: row
`;

export const LargeText = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.isMobile ? "22px" : "28px"};
    font-style: normal;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 24px;
  }
`;

export const SmallText = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.secondaryFont};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 12px;
    width: 90%;
  }
`;