import React from "react";
import { LetterAvatar } from 'components/letterAvatar'
import { AvatarPicture } from './styles';

const UserAvatar = ({ avatar, firstName, lastName }) => {
  return (
    <>
      { avatar && (
        <AvatarPicture src={`data:image/png;base64,${avatar}`} />
      )}
      {!avatar && (
        <LetterAvatar name={`${firstName}${firstName && lastName ? " " : ''}${lastName}`} />
      )}
    </>
  )
};

export default UserAvatar;
