import React, { useEffect, useState } from "react";
import { StyledTableCell } from '../../../components/filesListTable/styles';
import ConciseTable from '../../../components/filesListTable/conciseTable';
import { FunctionalButton } from '../../../components/functionalButton/index';
import { AddDocumentTypePopup } from "../../../components/addDocumentTypePopup";
import { getDocumentTypes } from "../../../api/external_data";
import { StyledProjectSelectionContainer, HeaderArea } from '../styles';
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";


export default function DataMaintenanceDocumentType(editFunc,
  deleteFunc,
  isMobile) {

  const headers = [
    { title: 'Id',width:"10%"},
    { title: 'Document Type',width:"35%"},
    { title: 'is additional info?',width:"28%"},
    { title: 'is currency amount?',width:"27%"},
  ];

  const [openPopup, setOpenPopup] = useState(false);
  const [documentType, setDocumentType] = useState([]);
  const [internalSearchResults, setInternalSearchResults] = useState(documentType);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getDocumentTypes()
      .then((res) => {
        setDocumentType(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  const handleAdd = () => {
    setOpenPopup(true);
  };

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCell component={'tr'} width="10%">
          {bodyData.id}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="40%">
          {bodyData.doc_type}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="25%">
          {bodyData.is_additional_info === true ? (
            "Yes"
          ) : (
            "No"
          )}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="25%">
          {bodyData.is_currency_amount === true ? (
            "Yes"
          ) : (
            "No"
          )}
        </StyledTableCell>
      </>

    );
  };


  return (
    <StyledProjectSelectionContainer isMobile={isMobile}>

      <HeaderArea width="100%">
        <SearchBar
          filteredData={documentType}
          setInternalSearchResults={setInternalSearchResults}
          query={query}
          setQuery={setQuery}
          internalSearch={true}
        />
        <FunctionalButton
          buttonTitle="Add Document Type"
          handleButton={handleAdd}
          width="60%"
        />
      </HeaderArea>

      <ConciseTable headers={isMobile ? "" : headers} />
      <ConciseTable
        body={body}
        data={
          query && query !== "" ? internalSearchResults : documentType
        }
      />

      <AddDocumentTypePopup
        setOpenDialog={setOpenPopup}
        openDialog={openPopup}
      />

    </StyledProjectSelectionContainer>
  )
}


