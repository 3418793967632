import React, { useState, useEffect } from "react";
import {
  handleCommaFormat,
  numberWithCommas,
  removeComma,
} from "common/numberFormat";
import { useLocation } from "react-router-dom"
import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper,
  SmallTitleText,
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Input from "components/inputField";
import PledgxLogo from "assets/common/pledgxLogo";
import { FunctionalButton } from "components/functionalButton";
import DateTimePickerField from "components/dateTimePickerField";
import { editProjectDataForWIP, editUnderwriterClientProjectDataForWIP } from "api/work_in_progress";
import { useDispatch } from "react-redux";
import { getClientProjectsInfoForWip , getClientProjectsInfoForWipUnderwriter} from "data/actions/underwriters";

export const EditProjectPopup = ({
  setOpenDialog,
  openDialog,
  editProjectData,
  isMobile,
  companyId,
}) => {
  const [validSubmit, setValidSubmit] = useState(false);
  const [data, setData] = useState({});
  const location = useLocation();
  const [actualStartDate, setActualStartDate] = useState(
    editProjectData?.actual_start_date || ""
  );
  const [plannedStartDate, setPlannedStartDate] = useState(
    editProjectData?.planned_start_date || ""
  );
  const [estimatedCostOfRevenue, setEstimatedCostOfRevenue] = useState(
    editProjectData?.original_estimated_cost_of_revenue || ""
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      actualStartDate?.length > 0 &&
      plannedStartDate?.length > 0 &&
      estimatedCostOfRevenue?.length > 0
    ) {
      setValidSubmit(false);
    } else {
      setValidSubmit(true);
    }
  }, [actualStartDate, plannedStartDate, estimatedCostOfRevenue]);

  useEffect(() => {
    const updatedData = { ...data };
    updatedData["actual_start_date"] = actualStartDate;
    if (editProjectData?.planned_start_date === "") {
      setPlannedStartDate(actualStartDate);
    }
    setData(updatedData);
  }, [actualStartDate]);

  useEffect(() => {
    const updatedData = { ...data };
    updatedData["estimated_cost_of_revenue"] = estimatedCostOfRevenue
      ? removeComma(estimatedCostOfRevenue)
      : 0;
    setData(updatedData);
  }, [estimatedCostOfRevenue]);

  useEffect(() => {
    const updatedData = { ...data };
    updatedData["planned_start_date"] = plannedStartDate;
    setData(updatedData);
  }, [plannedStartDate]);

  const handleEditProjectDataForWIP = () => {
    const project_id = editProjectData?.id;
    const actual_start_date = actualStartDate;
    const estimated_cost_of_revenue = estimatedCostOfRevenue
      ? removeComma(estimatedCostOfRevenue)
      : 0;
    const planned_start_date = plannedStartDate;
    const newData = {
      ...data,
      project_id: project_id,
      actual_start_date: actual_start_date,
      estimated_cost_of_revenue: estimated_cost_of_revenue,
      planned_start_date: planned_start_date,
    };
    const isUnderwriterWip = location.pathname.includes("underwriter/client/wip");

    if (isUnderwriterWip) {
      editUnderwriterClientProjectDataForWIP(
        newData,
        () => {
          setActualStartDate("");
          setPlannedStartDate("");
          setEstimatedCostOfRevenue("");
          setOpenDialog(false);
          dispatch(
            getClientProjectsInfoForWipUnderwriter({ account_id: companyId }, "underway")
          );
        },
        () => {}
      );
    } else {
      editProjectDataForWIP(
      newData,
      () => {
        setActualStartDate("");
        setPlannedStartDate("");
        setEstimatedCostOfRevenue("");
        setOpenDialog(false);
        dispatch(
          getClientProjectsInfoForWip({ account_id: companyId }, "underway")
        );
      },
        () => {}
      );
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <StyledPaper
          elevation={0}
          isMobile={isMobile}
          style={{ height: "500px" }}
        >
          <CloseButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </CloseButton>

          <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />

          <FormTitle
            style={{
              marginTop: "25px",
              marginBottom: "10px",
              color: "#1A051D",
              fontSize: "22px",
              fontWeight: "600",
              fontStyle: "normal",
              lineHeight: "24px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {editProjectData?.project_name}
          </FormTitle>

          <InfoArea isMobile={isMobile} marginTop="0px">
            {editProjectData?.original_estimated_cost_of_revenue == undefined &&
              editProjectData?.actual_start_date === "" && (
                <>
                  <div
                    style={{
                      margin: "0px",
                      marginLeft: "12px",
                      marginBottom: "-15px",
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Input
                      id="fullWidthInputWrap"
                      label="Estimated Cost of Revenue*"
                      type="text"
                      placeholder="$"
                      labelSize="12px"
                      fontSize="18px"
                      value={estimatedCostOfRevenue}
                      setValue={(value) => {
                        setEstimatedCostOfRevenue(value.toLocaleString());
                      }}
                      onBlur={(e) =>
                        setEstimatedCostOfRevenue(
                          handleCommaFormat(e.target.value)
                        )
                      }
                      noCheckIcon={true}
                      noErrorBorder={true}
                    />
                  </div>

                  <div
                    style={{
                      margin: "0px",
                      marginLeft: "12px",
                      marginBottom: "16px",
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <DateTimePickerField
                      showDateField={true}
                      dateWidth="50%"
                      paddingRight="16px"
                      dateLabel="Actual Start Date*"
                      dateValue={actualStartDate}
                      setDateValue={setActualStartDate}
                    />

                    <DateTimePickerField
                      showDateField={true}
                      dateWidth="50%"
                      paddingRight="16px"
                      dateLabel="Planned Start Date*"
                      dateValue={plannedStartDate}
                      setDateValue={setPlannedStartDate}
                    />
                  </div>
                </>
              )}

            {editProjectData?.original_estimated_cost_of_revenue &&
              editProjectData?.actual_start_date !== "" && (
                <>
                  <div
                    style={{
                      margin: "0px",
                      marginLeft: "12px",
                      marginBottom: "-15px",
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Input
                      id="fullWidthInputWrap"
                      label="Estimated Cost of Revenue*"
                      type="text"
                      placeholder="$"
                      labelSize="12px"
                      fontSize="18px"
                      value={handleCommaFormat(
                        editProjectData?.original_estimated_cost_of_revenue
                      )}
                      noCheckIcon={true}
                      noErrorBorder={true}
                      disabled
                    />
                  </div>

                  <div
                    style={{
                      margin: "0px",
                      marginLeft: "12px",
                      marginBottom: "16px",
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <DateTimePickerField
                      showDateField={true}
                      dateWidth="50%"
                      paddingRight="16px"
                      dateLabel="Actual Start Date*"
                      dateValue={editProjectData?.actual_start_date}
                      setDateValue={setActualStartDate}
                      disabled={true}
                    />

                    <DateTimePickerField
                      showDateField={true}
                      dateWidth="50%"
                      paddingRight="16px"
                      dateLabel="Planned Start Date*"
                      dateValue={editProjectData?.planned_start_date}
                      setDateValue={setPlannedStartDate}
                      disabled={true}
                    />
                  </div>
                </>
              )}
          </InfoArea>

          <div
            style={{
              marginLeft: "420px",
              marginBottom: "32px",
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <FunctionalButton
              disabled={validSubmit}
              width="30%"
              marginTop="32px"
              marginBottom="64x"
              marginRight="0px"
              handleButton={() => {
                handleEditProjectDataForWIP();
                setOpenDialog(false);
              }}
              buttonTitle={"SAVE CHANGES"}
            />
          </div>
        </StyledPaper>
      </Dialog>
    </>
  );
};
