import get from "lodash/get";
import { createSelector } from "reselect";

export const baseNewsSelector = (state) => get(state, "news", {});

export const newsSelector = createSelector(baseNewsSelector, (news) =>
  get(news, "news", [])
);

export const newsSavedSelector = createSelector(baseNewsSelector, (news) =>
  get(news, "savedNews", [])
);
