import React, { useState, useEffect } from 'react';
import { useMediaQuery } from "react-responsive";
import { YearsNumber } from './YearsNumber';
import { StyledYear, FieldColumn, FieldWrap, LabelText, MobileYearText } from './styles';
import { MobileYearsNumber } from './MobileYearsNumber';

export const SafetyRecords = ({
  data,
  setData,
  isMobile
 }) => {

  const currentYear = new Date().getFullYear();

  return (
    <FieldWrap isMobile={isMobile}>
      {!isMobile && (
        <>
          <FieldColumn className='firstColumn'>
            <LabelText className='yearLabel'>Year</LabelText>
            <StyledYear>{currentYear-1}</StyledYear>
            <StyledYear>{currentYear-2}</StyledYear>
            <StyledYear>{currentYear-3}</StyledYear>
          </FieldColumn>
          <FieldColumn className='recordColumn'>
            <LabelText>Industry Classification Code</LabelText>
            <YearsNumber 
              data={data?.safety_records ? data?.safety_records : {}}
              setData={setData}
              type="classCode"
              year={currentYear-1}
            />
          </FieldColumn>
          <FieldColumn className='recordColumn'>
            <LabelText>Industry Base Rate</LabelText>
            <YearsNumber 
              data={data?.safety_records ? data?.safety_records : {}}
              setData={setData}
              type="baseRate"
              year={currentYear-1}
            />
          </FieldColumn>
          <FieldColumn className='recordColumn'>
            <LabelText>Company’s Experience Rate</LabelText>
            <YearsNumber 
              data={data?.safety_records ? data?.safety_records : {}}
              setData={setData}
              type="expRate"
              year={currentYear-1}
            />
          </FieldColumn>
          <FieldColumn className='recordColumn'>
            <LabelText>Fatalities (Actual Number)</LabelText>
            <YearsNumber 
              data={data?.safety_records ? data?.safety_records : {}}
              setData={setData}
              type="fatalities"
              year={currentYear-1}
            />
          </FieldColumn>
          <FieldColumn className='recordColumn'>
            <LabelText>Lost Time Incident Frequency</LabelText>
            <YearsNumber 
              data={data?.safety_records ? data?.safety_records : {}}
              setData={setData}
              type="lostRate"
              year={currentYear-1}
            />
          </FieldColumn>
        </>
      )}
      {isMobile && (
        <>
          <MobileYearText margin="0px 0px 16px">{currentYear-1}</MobileYearText>
          <MobileYearsNumber 
             data={data?.safety_records ? data?.safety_records : {}}
             setData={setData}
             year={currentYear-1}
          />
          <MobileYearText margin="32px 0px 16px">{currentYear-2}</MobileYearText>
          <MobileYearsNumber 
             data={data?.safety_records ? data?.safety_records : {}}
             setData={setData}
             year={currentYear-2}
          />
          <MobileYearText margin="32px 0px 16px">{currentYear-3}</MobileYearText>
          <MobileYearsNumber 
             data={data?.safety_records ? data?.safety_records : {}}
             setData={setData}
             year={currentYear-3}
          />
        </>
      )}
      
    </FieldWrap>
  );
};
