
import { MediumText, HeadingText, DataTable } from "../../../underwriterDashboard/styles";
import React, { useEffect, useState } from "react";
import { StyledContainer } from "pages/underwriters/components/peerGroupAnalysis/styles.js";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { GoBackButton } from "pages/buyers/components/goBackButton";
import { singlePeerGroupAnalysis } from 'api/external_data.js'
import { ReactComponent as UpArrow } from "assets/upArrow.svg";
import { ReactComponent as DownArrow } from "assets/downArrow.svg";
import { ReactComponent as FlatIcon } from "assets/flatIcon.svg";
import { RatingSlider, RatingSliderTrend, RatingSliderReverse, RatingSliderForTotalDebtToEquity, RatingSliderRiskScore } from "components/ratingSliderForPeerGroupAnalysis";
import { GradientText } from "components/ratingSliderForPeerGroupAnalysis/styles.js"
import { numberWithCommas, numberWithCommasInt } from "common/numberFormat.js";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { StyledTab } from "pages/underwriterDashboard/clientSection/styles.js";
import Insights from 'pages/underwriters/components/peerGroupAnalysis/insights.js'
import Recommendations from 'pages/underwriters/components/peerGroupAnalysis/recommendations.js'

const PeerGroupAnalysis = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  const location = useLocation();
  const companyName = location.state?.company_name || "";
  const accountId = location.state?.account_id;
  const [pgaData, setPgaData] = useState([]);
  const [selectTab, setSelectTab] = React.useState('1');
  const [amountFormat, setAmountFormat] = useState('Actual Value');


  const handleAmountFormatChange = (event) => {
    setAmountFormat(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setSelectTab(newValue);
  };

  const accId = {
    account_id: location.state?.account_id
  }

  useEffect(() => {
    singlePeerGroupAnalysis(accId,
      onSuccessSinglePeerGroupAnalysis,
      () => { })
  }, []);

  const onSuccessSinglePeerGroupAnalysis = (res) => {
    setPgaData(res);
  };


  return (
    <StyledContainer isMobile={isMobile}>
      <GoBackButton />
      <br />
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '0 0 60%', marginRight: '20px' }}>
          <MediumText isMobile={isMobile} style={{ fontSize: '28px', lineHeight: '20px', fontFamily: 'SF Pro Text', color: '#1A051D', marginBottom: '20px', fontWeight: '600' }}>Peer Group Analysis</MediumText>
          <HeadingText>Account Id : <b>{accountId}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Name : &nbsp;<b>{companyName}</b></HeadingText>
          <HeadingText>
            Show Amounts in{' '}
            <select value={amountFormat} onChange={handleAmountFormatChange} style={{ fontWeight: 'bold', appearance: 'none', WebkitAppearance: 'none', MozAppearance: 'none', backgroundColor: 'transparent', border: 'none', outline: 'none', padding: 0, paddingRight: '20px', fontSize: 'inherit', cursor: 'pointer', backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='6'><path fill='%23333' d='M5 6L0 1h10z'/></svg>")`, backgroundRepeat: 'no-repeat', backgroundPosition: 'right', }}>
              <option value="Actual Value">Actual Value</option>
              <option value="Thousands">Thousands</option>
              <option value="Million">Million</option>
            </select>
          </HeadingText>
        </div>

        <div style={{ flex: '0 0 40%' }}>
          {pgaData && pgaData.length > 0 && pgaData[0].EP_riskscore > 0 && (
            <>
              <HeadingText ><GradientText>Risk Score</GradientText></HeadingText>
              <br />
              <div style={{ marginRight: '5px', marginLeft: '5px' }}>
                <RatingSliderRiskScore
                  value={pgaData[0].Score2}
                  startValue={pgaData[0].SP_riskscore}
                  endValue={pgaData[0].EP_riskscore}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <br />
      <div style={{ display: 'flex' }}>
        <div style={{ flex: '0 0 60%', marginRight: '20px' }}>
          <div>
            <div>
              {pgaData && pgaData.length > 0 && amountFormat === 'Actual Value' && (
                <DataTable>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: '#EBE9F1', opacity: "75%" }}>Metric</th>
                      <th style={{ backgroundColor: '#EBE9F1', opacity: "75%" }}>3Y Average</th>
                      <th style={{ backgroundColor: '#EBE9F1', opacity: "75%" }}>3Y Average Peer Comparison</th>
                      <th style={{ backgroundColor: '#EBE9F1', opacity: "75%" }}>3Y Trend</th>
                      <th style={{ backgroundColor: '#EBE9F1', opacity: "75%" }}>3Y Trend Peer Comparison</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Annual Gross Contract Income</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommasInt(pgaData[0].Value_AnnualGrossContractIncome)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_AnnualGrossContractIncome}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualGrossContractIncome === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualGrossContractIncome === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualGrossContractIncome === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualGrossContractIncome}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Annual Total Operating Revenue</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommasInt(pgaData[0].Value_AnnualTotalOperatingRevenue)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_AnnualTotalOperatingRevenue}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualTotalOperatingRevenue === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualTotalOperatingRevenue === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualTotalOperatingRevenue === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualTotalOperatingRevenue}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Annual Total Direct Expense</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommasInt(pgaData[0].Value_AnnualTotalDirectExpense)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderReverse
                          value={pgaData[0].MetricQuantile_AnnualTotalDirectExpense}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualTotalDirectExpense === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualTotalDirectExpense === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualTotalDirectExpense === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualTotalDirectExpense}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Annual Gross Profit</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommasInt(pgaData[0].Value_AnnualGrossProfit)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_AnnualGrossProfit}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualGrossProfit === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualGrossProfit === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualGrossProfit === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualGrossProfit}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Gross Profit Margin</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{Math.round(pgaData[0].Value_GrossProfitMargin * 100)}%</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_GrossProfitMargin}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_GrossProfitMargin === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_GrossProfitMargin === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_GrossProfitMargin === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_GrossProfitMargin}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>


                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Annual Operating Profit</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommasInt(pgaData[0].Value_AnnualOperatingProfit)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_AnnualOperatingProfit}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualOperatingProfit === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualOperatingProfit === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualOperatingProfit === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualOperatingProfit}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Operating Profit Margin</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{Math.round(pgaData[0].Value_OperatingProfitMargin * 100)}%</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_OperatingProfitMargin}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_OperatingProfitMargin === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_OperatingProfitMargin === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_OperatingProfitMargin === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_OperatingProfitMargin}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>


                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Annual Total Overhead Expense</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommasInt(pgaData[0].Value_AnnualTotalOverheadExpense)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderReverse
                          value={pgaData[0].MetricQuantile_AnnualTotalOverheadExpense}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualTotalOverheadExpense === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualTotalOverheadExpense === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualTotalOverheadExpense === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualTotalOverheadExpense}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Working Capital</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommasInt(pgaData[0].Value_WorkingCapital)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_WorkingCapital}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_WorkingCapital === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_WorkingCapital === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_WorkingCapital === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_WorkingCapital}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Working Capital Ratio</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommas(pgaData[0].Value_WorkingCapitalRatio)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_WorkingCapitalRatio}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_WorkingCapitalRatio === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_WorkingCapitalRatio === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_WorkingCapitalRatio === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_WorkingCapitalRatio}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Acid Test</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommasInt(pgaData[0].Value_AcidTest)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_AcidTest}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AcidTest === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AcidTest === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AcidTest === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AcidTest}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Net Quick</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommasInt(pgaData[0].Value_NetQuick)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_NetQuick}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_NetQuick === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_NetQuick === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_NetQuick === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_NetQuick}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Working Capital Vs Overhead</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommas(pgaData[0].Value_WorkingCapitalVsOverhead)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_WorkingCapitalVsOverhead}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_WorkingCapitalVsOverhead === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_WorkingCapitalVsOverhead === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_WorkingCapitalVsOverhead === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_WorkingCapitalVsOverhead}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Tangible Net Worth</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommasInt(pgaData[0].Value_TangibleNetWorth)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_TangibleNetWorth}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_TangibleNetWorth === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_TangibleNetWorth === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_TangibleNetWorth === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_TangibleNetWorth}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Total Debt To Equity</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommas(pgaData[0].Value_TotalDebtToEquity)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_TotalDebtToEquity}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_TotalDebtToEquity === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_TotalDebtToEquity === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_TotalDebtToEquity === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_TotalDebtToEquity}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                  </tbody>
                </DataTable>
              )}


              {pgaData && pgaData.length > 0 && amountFormat === 'Thousands' && (
                <DataTable>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: '#EBE9F1', opacity: "75%" }}>Metric</th>
                      <th style={{ backgroundColor: '#EBE9F1', opacity: "75%" }}>3Y Average</th>
                      <th style={{ backgroundColor: '#EBE9F1', opacity: "75%" }}>3Y Average Peer Comparison</th>
                      <th style={{ backgroundColor: '#EBE9F1', opacity: "75%" }}>3Y Trend</th>
                      <th style={{ backgroundColor: '#EBE9F1', opacity: "75%" }}>3Y Trend Peer Comparison</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Annual Gross Contract Income</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_AnnualGrossContractIncome) > 99 ? pgaData[0].Value_AnnualGrossContractIncome / 1000 : pgaData[0].Value_AnnualGrossContractIncome).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_AnnualGrossContractIncome}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualGrossContractIncome === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualGrossContractIncome === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualGrossContractIncome === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualGrossContractIncome}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Annual Total Operating Revenue</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_AnnualTotalOperatingRevenue) > 99 ? pgaData[0].Value_AnnualTotalOperatingRevenue / 1000 : pgaData[0].Value_AnnualTotalOperatingRevenue).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_AnnualTotalOperatingRevenue}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualTotalOperatingRevenue === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualTotalOperatingRevenue === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualTotalOperatingRevenue === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualTotalOperatingRevenue}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Annual Total Direct Expense</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_AnnualTotalDirectExpense) > 99 ? pgaData[0].Value_AnnualTotalDirectExpense / 1000 : pgaData[0].Value_AnnualTotalDirectExpense).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderReverse
                          value={pgaData[0].MetricQuantile_AnnualTotalDirectExpense}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualTotalDirectExpense === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualTotalDirectExpense === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualTotalDirectExpense === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualTotalDirectExpense}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Annual Gross Profit</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_AnnualGrossProfit) > 99 ? pgaData[0].Value_AnnualGrossProfit / 1000 : pgaData[0].Value_AnnualGrossProfit).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_AnnualGrossProfit}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualGrossProfit === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualGrossProfit === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualGrossProfit === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualGrossProfit}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Gross Profit Margin</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{Math.round(pgaData[0].Value_GrossProfitMargin * 100)}%</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_GrossProfitMargin}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_GrossProfitMargin === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_GrossProfitMargin === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_GrossProfitMargin === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_GrossProfitMargin}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Annual Operating Profit</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_AnnualOperatingProfit) > 99 ? pgaData[0].Value_AnnualOperatingProfit / 1000 : pgaData[0].Value_AnnualOperatingProfit).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_AnnualOperatingProfit}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualOperatingProfit === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualOperatingProfit === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualOperatingProfit === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualOperatingProfit}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Operating Profit Margin</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{Math.round(pgaData[0].Value_OperatingProfitMargin * 100)}%</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_OperatingProfitMargin}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_OperatingProfitMargin === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_OperatingProfitMargin === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_OperatingProfitMargin === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_OperatingProfitMargin}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Annual Total Overhead Expense</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_AnnualTotalOverheadExpense) > 99 ? pgaData[0].Value_AnnualTotalOverheadExpense / 1000 : pgaData[0].Value_AnnualTotalOverheadExpense).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderReverse
                          value={pgaData[0].MetricQuantile_AnnualTotalOverheadExpense}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualTotalOverheadExpense === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualTotalOverheadExpense === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualTotalOverheadExpense === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualTotalOverheadExpense}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Working Capital</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_WorkingCapital) > 99 ? pgaData[0].Value_WorkingCapital / 1000 : pgaData[0].Value_WorkingCapital).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_WorkingCapital}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_WorkingCapital === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_WorkingCapital === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_WorkingCapital === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_WorkingCapital}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>


                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Working Capital Ratio</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommas(pgaData[0].Value_WorkingCapitalRatio)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_WorkingCapitalRatio}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_WorkingCapitalRatio === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_WorkingCapitalRatio === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_WorkingCapitalRatio === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_WorkingCapitalRatio}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Acid Test</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_AcidTest) > 99 ? pgaData[0].Value_AcidTest / 1000 : pgaData[0].Value_AcidTest).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_AcidTest}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AcidTest === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AcidTest === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AcidTest === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AcidTest}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Net Quick</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_NetQuick) > 99 ? pgaData[0].Value_NetQuick / 1000 : pgaData[0].Value_NetQuick).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_NetQuick}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_NetQuick === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_NetQuick === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_NetQuick === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_NetQuick}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Working Capital Vs Overhead</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommas(pgaData[0].Value_WorkingCapitalVsOverhead)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_WorkingCapitalVsOverhead}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_WorkingCapitalVsOverhead === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_WorkingCapitalVsOverhead === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_WorkingCapitalVsOverhead === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_WorkingCapitalVsOverhead}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Tangible Net Worth</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_TangibleNetWorth) > 99 ? pgaData[0].Value_TangibleNetWorth / 1000 : pgaData[0].Value_TangibleNetWorth).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_TangibleNetWorth}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_TangibleNetWorth === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_TangibleNetWorth === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_TangibleNetWorth === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_TangibleNetWorth}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Total Debt To Equity</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommas(pgaData[0].Value_TotalDebtToEquity)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_TotalDebtToEquity}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_TotalDebtToEquity === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_TotalDebtToEquity === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_TotalDebtToEquity === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_TotalDebtToEquity}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                  </tbody>
                </DataTable>
              )}

              {pgaData && pgaData.length > 0 && amountFormat === 'Million' && (
                <DataTable>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: '#EBE9F1', opacity: "75%" }}>Metric</th>
                      <th style={{ backgroundColor: '#EBE9F1', opacity: "75%" }}>3Y Average</th>
                      <th style={{ backgroundColor: '#EBE9F1', opacity: "75%" }}>3Y Average Peer Comparison</th>
                      <th style={{ backgroundColor: '#EBE9F1', opacity: "75%" }}>3Y Trend</th>
                      <th style={{ backgroundColor: '#EBE9F1', opacity: "75%" }}>3Y Trend Peer Comparison</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Annual Gross Contract Income</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_AnnualGrossContractIncome) > 99 ? pgaData[0].Value_AnnualGrossContractIncome / 1000000 : pgaData[0].Value_AnnualGrossContractIncome).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_AnnualGrossContractIncome}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualGrossContractIncome === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualGrossContractIncome === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualGrossContractIncome === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualGrossContractIncome}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Annual Total Operating Revenue</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_AnnualTotalOperatingRevenue) > 99 ? pgaData[0].Value_AnnualTotalOperatingRevenue / 1000000 : pgaData[0].Value_AnnualTotalOperatingRevenue).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_AnnualTotalOperatingRevenue}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualTotalOperatingRevenue === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualTotalOperatingRevenue === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualTotalOperatingRevenue === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualTotalOperatingRevenue}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Annual Total Direct Expense</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_AnnualTotalDirectExpense) > 99 ? pgaData[0].Value_AnnualTotalDirectExpense / 1000000 : pgaData[0].Value_AnnualTotalDirectExpense).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderReverse
                          value={pgaData[0].MetricQuantile_AnnualTotalDirectExpense}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualTotalDirectExpense === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualTotalDirectExpense === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualTotalDirectExpense === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualTotalDirectExpense}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Annual Gross Profit</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_AnnualGrossProfit) > 99 ? pgaData[0].Value_AnnualGrossProfit / 1000000 : pgaData[0].Value_AnnualGrossProfit).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_AnnualGrossProfit}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualGrossProfit === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualGrossProfit === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualGrossProfit === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualGrossProfit}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Gross Profit Margin</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{Math.round(pgaData[0].Value_GrossProfitMargin * 100)}%</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_GrossProfitMargin}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_GrossProfitMargin === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_GrossProfitMargin === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_GrossProfitMargin === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_GrossProfitMargin}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Annual Operating Profit</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_AnnualOperatingProfit) > 99 ? pgaData[0].Value_AnnualOperatingProfit / 1000000 : pgaData[0].Value_AnnualOperatingProfit).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_AnnualOperatingProfit}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualOperatingProfit === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualOperatingProfit === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualOperatingProfit === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualOperatingProfit}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Operating Profit Margin</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{Math.round(pgaData[0].Value_OperatingProfitMargin * 100)}%</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_OperatingProfitMargin}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_OperatingProfitMargin === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_OperatingProfitMargin === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_OperatingProfitMargin === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_OperatingProfitMargin}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Annual Total Overhead Expense</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_AnnualTotalOverheadExpense) > 99 ? pgaData[0].Value_AnnualTotalOverheadExpense / 1000000 : pgaData[0].Value_AnnualTotalOverheadExpense).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderReverse
                          value={pgaData[0].MetricQuantile_AnnualTotalOverheadExpense}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AnnualTotalOverheadExpense === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AnnualTotalOverheadExpense === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AnnualTotalOverheadExpense === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AnnualTotalOverheadExpense}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Working Capital</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_WorkingCapital) > 99 ? pgaData[0].Value_WorkingCapital / 1000000 : pgaData[0].Value_WorkingCapital).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_WorkingCapital}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_WorkingCapital === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_WorkingCapital === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_WorkingCapital === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_WorkingCapital}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>


                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Working Capital Ratio</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommas(pgaData[0].Value_WorkingCapitalRatio)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_WorkingCapitalRatio}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_WorkingCapitalRatio === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_WorkingCapitalRatio === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_WorkingCapitalRatio === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_WorkingCapitalRatio}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Acid Test</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_AcidTest) > 99 ? pgaData[0].Value_AcidTest / 1000000 : pgaData[0].Value_AcidTest).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_AcidTest}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_AcidTest === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_AcidTest === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_AcidTest === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_AcidTest}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Net Quick</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_NetQuick) > 99 ? pgaData[0].Value_NetQuick / 1000000 : pgaData[0].Value_NetQuick).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_NetQuick}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_NetQuick === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_NetQuick === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_NetQuick === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_NetQuick}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Working Capital Vs Overhead</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommas(pgaData[0].Value_WorkingCapitalVsOverhead)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_WorkingCapitalVsOverhead}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_WorkingCapitalVsOverhead === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_WorkingCapitalVsOverhead === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_WorkingCapitalVsOverhead === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_WorkingCapitalVsOverhead}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)` }}>Tangible Net Worth</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'right' }}>{numberWithCommas((Math.abs(pgaData[0].Value_TangibleNetWorth) > 99 ? pgaData[0].Value_TangibleNetWorth / 1000000 : pgaData[0].Value_TangibleNetWorth).toFixed(2))}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_TangibleNetWorth}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.50)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_TangibleNetWorth === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_TangibleNetWorth === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_TangibleNetWorth === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.50)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_TangibleNetWorth}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)` }}>Total Debt To Equity</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'right' }}>{numberWithCommas(pgaData[0].Value_TotalDebtToEquity)}</td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                      {/* <RatingSliderForTotalDebtToEquity */}
                        <RatingSlider
                          value={pgaData[0].MetricQuantile_TotalDebtToEquity}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241, 0.25)`, textAlign: 'center' }}>
                        {pgaData[0].Trend_TotalDebtToEquity === 0 && (<FlatIcon />)}
                        {pgaData[0].Trend_TotalDebtToEquity === 1 && (<UpArrow />)}
                        {pgaData[0].Trend_TotalDebtToEquity === -1 && (<DownArrow />)}
                      </td>
                      <td style={{ backgroundColor: `rgba(235, 233, 241,0.25)`, textAlign: 'center', paddingRight: "18px" }}>
                        <RatingSliderTrend
                          value={pgaData[0].TrendQuantile_TotalDebtToEquity}
                          startValue={0}
                          endValue={100}
                        />
                      </td>
                    </tr>

                  </tbody>
                </DataTable>
              )}
            </div>
          </div>
        </div>


        {pgaData && pgaData.length > 0 && (
          <div style={{ flex: '0 0 40%' }}>
            <div style={{ backgroundColor: '#FFFFFF' }}>
              <TabContext value={selectTab}>

                <TabList onChange={handleChange} variant="fullWidth" centered TabIndicatorProps={{ hidden: true }}>
                  <StyledTab label="Insights" value="1" />
                  <StyledTab label="Recommendations" value="2" />
                </TabList>

                <TabPanel value="1">
                  {pgaData &&
                    <Insights data={pgaData} />
                  }
                </TabPanel>
                <TabPanel value="2">
                  {pgaData &&
                    <Recommendations data={pgaData} />
                  }
                </TabPanel>

              </TabContext>
            </div>
          </div>
        )}
      </div>

      {pgaData.length <= 0 && (
        <p style={{ textAlign: 'center', fontSize: "16px" }}>No Peer Group Analysis Data is in the database for {accountId}</p>
      )}
    </StyledContainer>
  );
};

export default PeerGroupAnalysis;
