import React from "react";
import { Tools } from "../conciergeTools/Tools";
import { SetupContainer } from "./styles";

export const Dashboard = ({ isMobile }) => {

  return (
    <SetupContainer isMobile={isMobile}>
      <Tools />
    </SetupContainer>
  );
};
