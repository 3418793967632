import React, { useEffect } from 'react';
import { StyledResumeButton } from "./styles";

export const ResumeButton =({
    buttonTitle,
    handleButton,
    width,
    height,
    margin,
    fontSize,
    disabled,
    hasBoxShadow,
    exists
}) => {

    return (
        <StyledResumeButton
            onClick={ handleButton }
            width={width}
            height={height}
            margin={margin}
            fontSize={fontSize}
            hasBoxShadow={hasBoxShadow}
            exists={ exists }
        >
            {buttonTitle || "RESUME"}
        </StyledResumeButton>
    )
}

