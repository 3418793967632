export const statusReportTransaction =
(data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "STATUS_REPORT_TRANSACTION",
    payload: {
      endpoint: "/underwriter/status/report/transaction",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};


export const statusReportGivingFromIssuer =
(data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "STATUS_REPORT_GIVING_ISSUER",
    payload: {
      endpoint: "/underwriter/contract/status/report/giving/issuer",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};

export const getFilterUsersList =
(data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "GET_FILTER_USERS_LIST",
    payload: {
      endpoint: "/admin/reports/get_filter_users_list",
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed,
  });
};


export const addAssociation = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: 'ADD_ASSOCIATION',
    payload: {
      endpoint: '/associations/add_association',
      method: 'POST',
      body: data
    },
    onSuccess,
    onFailed
  });
};


export const addUnion = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: 'ADD_UNION',
    payload: {
      endpoint: '/unions/add_union',
      method: 'POST',
      body: data
    },
    onSuccess,
    onFailed
  });
};


export const addBank = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: 'ADD_BANK',
    payload: {
      endpoint: '/banks/add_bank',
      method: 'POST',
      body: data
    },
    onSuccess,
    onFailed
  });
};


export const addInsurance = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: 'ADD_INSURANCE',
    payload: {
      endpoint: '/insurances/add_insurance',
      method: 'POST',
      body: data
    },
    onSuccess,
    onFailed
  });
};


export const addUnderwriter = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: 'ADD_UNDERWRITER',
    payload: {
      endpoint: '/underwriter/add_underwriter',
      method: 'POST',
      body: data
    },
    onSuccess,
    onFailed
  });
};

export const addInsuranceType = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: 'ADD_INSURANCE_TYPE',
    payload: {
      endpoint: '/insurance_type/add_insurance_type',
      method: 'POST',
      body: data
    },
    onSuccess,
    onFailed
  });
};

export const addDocumentType = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: 'ADD_DOCUMENT_TYPE',
    payload: {
      endpoint: '/document_type/add_document_type',
      method: 'POST',
      body: data
    },
    onSuccess,
    onFailed
  });
};



export const addPackage = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: 'ADD_PACKAGE',
    payload: {
      endpoint: '/packages/add_package',
      method: 'POST',
      body: data
    },
    onSuccess,
    onFailed
  });
};



export const getAssociations = (onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "GET_ASSOCIATIONS",
    payload: {
      endpoint: "/associations/get_association_all",
      method: "GET",
    },
    onSuccess,
    onFailed
  });
};


export const getUnions = (onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "GET_UNIONS",
    payload: {
      endpoint: "/unions/get_union_all",
      method: "GET",
    },
    onSuccess,
    onFailed
  });
};





