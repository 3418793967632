import React from "react";

import { 
  ColumnContainer
} from './styles';

import { ReferenceSummary } from './rightComponents/ReferenceSummary';
import { ReferenceCard } from "./rightComponents/ReferenceCard";


export const RightContainer = ({
  isMobile,
  data,
  setFilteredData,
  setOpenPopup
}) => { 
  return (
    <ColumnContainer isMobile={isMobile} width="60%">
      <ReferenceSummary isMobile={isMobile} data={data} setOpenPopup={setOpenPopup} />
      {data?.length > 0 && 
       data.map((item,i) => {return (
         <ReferenceCard data={item} setFilteredData={setFilteredData} isMobile={isMobile}/>
       )})}
    </ColumnContainer>
  )
};