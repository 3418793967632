import get from "lodash/get";
import { createSelector } from "reselect";

export const baseServiceProviderSelector = (state) =>
  get(state, "serviceProvider", {});

export const serviceProviderBuyerListSelector = createSelector(
  baseServiceProviderSelector,
  (serviceProvider) => get(serviceProvider, "service_provider_buyers_list", [])
);
export const serviceProviderContractorsListSelector = createSelector(
  baseServiceProviderSelector,
  (serviceProvider) =>
    get(serviceProvider, "service_provider_contractors_list", [])
);
export const BuyerRfpqsListSelector = createSelector(
  baseServiceProviderSelector,
  (serviceProvider) => get(serviceProvider, "buyer_rfpqs_list", [])
);

export const institutionProfileInfoSelector = createSelector(
  baseServiceProviderSelector,
  (serviceProvider) => get(serviceProvider, "institution_profile_info", [])
);
export const ContractorCcdcListSelector = createSelector(
  baseServiceProviderSelector,
  (serviceProvider) => get(serviceProvider, "contractor_ccdcs_list", [])
);
export const serviceProviderPpcListSelector = createSelector(
  baseServiceProviderSelector,
  (serviceProvider) => get(serviceProvider, "service_provider_ppc_list", [])
);
export const serviceProviderCoListSelector = createSelector(
  baseServiceProviderSelector,
  (serviceProvider) => get(serviceProvider, "service_provider_co_list", [])
);
export const serviceProviderCqsListSelector = createSelector(
  baseServiceProviderSelector,
  (serviceProvider) => get(serviceProvider, "service_provider_cqs_list", [])
);
export const contractorAlertsSelector = createSelector(
  baseServiceProviderSelector,
  (serviceProvider) => get(serviceProvider, "contractor_alerts", [])
);
