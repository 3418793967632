import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { userSelector } from "../../data/selectors/user";
import {
  currenciesSelector,
  newCustomerSelector,
  permissionsSelector,
  pricesSelector,
  stripeErrorSelector,
  subscriptionSelector,
} from "../../data/selectors/subscriptions";
import {
  cancelSubscription,
  clearCreateSubscription,
  createSubscription,
  getPrices,
  modifySubscriptionAutorenewal,
} from "../../data/actions/subscriptions";

import { DASHBOARDHEADER_HEIGHT } from "../../constants";
import displayDate from "../../utilities/displayDate";
import { useWindowSize } from "../../utilities/useWindowSize";

import { CloseButton } from "../../components/closeButton";
import { CommonToggle } from "../../components/commonToggle";
import { FeaturesTable } from "../../components/featuresTable";
import { FunctionalButton } from "../../components/functionalButton";
import { PackageItem } from "../../components/packageItem";
import { Popup } from "../../components/popup";
import PledgxLogo from "../../assets/common/pledgxLogo";

import {
  BillingPromoText,
  ButtonArea,
  CenterRow,
  ContentArea,
  ErrorContainer,
  ErrorText,
  FeaturesContainer,
  ImagePopup,
  InteractionsContainer,
  LargeText,
  PackageCarouselContainer,
  PackagesContainer,
  PackageItemBoxDescription,
  PopupContainer,
  RowBreak,
  SmallText,
  TextArea,
  ToggleDiv,
  ToggleText,
} from "./styles";

import contractor_packages from "../../assets/payment/contractor_packages.json";
import currencies from "../../assets/payment/currencies.json";

export const Packages = (props) => {
  const initialState = {
    error: null,
    loading: false,
    popup: {
      display: false,
      type: "cancel_subscription_autorenewal",
    },
    pricesLoading: true,
    billing: {
      currency: "CAD",
      frequency: "YR",
    },
    user: {
      loggedIn: false,
      subscribed: false,
    },
    subscribedPackage: {
      id: null,
      title: null,
    },
  };

  const [state, setState] = useState(initialState);
  const stateRef = useRef(initialState);
  const pricesRequested = useRef(false);

  const current_user = useSelector(userSelector);
  const activeSubscription = useSelector(subscriptionSelector);
  const newCustomer = useSelector(newCustomerSelector);
  const currencyOptions = useSelector(currenciesSelector);
  const packagePrices = useSelector(pricesSelector);
  const stripeError = useSelector(stripeErrorSelector);
  const subscriptionPermissions = useSelector(permissionsSelector);
  const windowSize = useWindowSize();
  const history = useHistory();
  const dispatch = useDispatch();

  stateRef.current = state;
  const popupOptions = {
    cancel_subscription_autorenewal: {
      height: "500px",
      width: "550px",
      button_cancel: "Keep auto-renewal",
      button_confirm: "Cancel auto-renewal",
      text_title:
        "Are you sure you want to cancel auto-renewal of your subscription?",
      text_details: [
        "Your subscription will no longer auto-renew, and you will lose access to PledgX services",
        activeSubscription && activeSubscription.subscription_expiry
          ? displayDate({
              date: activeSubscription.subscription_expiry,
              prepend: { future: "on", past: "on" },
            })
          : "at the end of your current billing period",
      ].join(" "),
    },
    stripe_error: {
      height: "650px",
      width: "550px",
      button_confirm: "Confirm",
      text_title: "There was an error in your last transaction",
      text_details: null,
    },
    stripe_payment_failed: {
      height: "650px",
      width: "550px",
      button_confirm: "Confirm",
      text_title: "There was an error in your last transaction",
      text_details:
        "Unfortunately, your last payment did not complete successfully. Please try again",
    },
  };

  useEffect(() => {
    dispatch(clearCreateSubscription());
    if (!pricesRequested.current) {
      pricesRequested.current = true;
      dispatch(getPrices());
    }
  }, []);

  useEffect(() => {
    handleUserData();
  }, [current_user]);

  useEffect(() => {
    if (
      (!packagePrices || Object.keys(packagePrices).length < 1) &&
      !pricesRequested.current
    ) {
      pricesRequested.current = true;
      dispatch(getPrices());
      handlePriceData();
    } else {
      pricesRequested.current = false;
      handlePriceData();
    }
  }, [packagePrices]);

  useEffect(() => {
    handleUserData();
  }, [activeSubscription]);

  function handleUserData() {
    var selectedCurrency = stateRef.current.billing.currency;
    if (currencyOptions) {
      if (currencyOptions && currencyOptions.selected_currency) {
        selectedCurrency = currencyOptions.selected_currency;
      } else if (
        currencyOptions.currencies_available &&
        currencyOptions.currencies_available.length === 1
      ) {
        selectedCurrency = currencyOptions.currencies_available[0];
      }
    }
    if (current_user && Object.keys(current_user).length > 0) {
      var newUserData = stateRef.current.user;
      newUserData.loggedIn = true;
      var newSubscriptionData = stateRef.current.subscribedPackage;
      if (activeSubscription && activeSubscription.id) {
        newUserData.subscribed = true;
        newSubscriptionData = activeSubscription;
      } else {
        newUserData.subscribed = false;
        newSubscriptionData = initialState.subscribedPackage;
      }
      var newPopup = stateRef.current.popup;
      if (stripeError) {
        newPopup = {
          ...stateRef.current.popup,
          display: true,
          type:
            stripeError === "PAYMENT_FAILED"
              ? "stripe_payment_failed"
              : "stripe_payment_failed",
        };
      }
      setState({
        ...stateRef.current,
        user: newUserData,
        subscribedPackage: newSubscriptionData,
        billing: {
          ...stateRef.current.billing,
          currency: selectedCurrency,
          frequency:
            activeSubscription && activeSubscription.billing_cycle
              ? activeSubscription.billing_cycle
              : stateRef.current.billing.frequency,
        },
        popup: newPopup,
      });
    } else if (!current_user || Object.keys(current_user).length < 1) {
      setState({
        ...stateRef.current,
        user: {
          ...stateRef.current.user,
          loggedIn: false,
          subscribed: false,
          billing: {
            ...stateRef.current.billing,
            currency: selectedCurrency,
          },
        },
      });
    }
  }

  function handlePriceData() {
    for (var contractorPackage of contractor_packages.packages) {
      if (
        packagePrices[contractorPackage.title] &&
        packagePrices[contractorPackage.title].id === contractorPackage.id
      ) {
        contractorPackage.price = packagePrices[contractorPackage.title].price;
        if (packagePrices[contractorPackage.title].free_trial_days) {
          contractorPackage.free_trial_days =
            packagePrices[contractorPackage.title].free_trial_days;
        }
      }
    }
    if (stripeError) {
      setState({
        ...stateRef.current,
        popup: {
          ...stateRef.current.popup,
          display: true,
          type:
            stripeError === "PAYMENT_FAILED"
              ? "stripe_payment_failed"
              : "stripe_payment_failed",
        },
        pricesLoading: false,
      });
    } else {
      setState({
        ...stateRef.current,
        pricesLoading: false,
      });
    }
  }

  function handleInteraction({ action, payload }) {
    switch (action) {
      case "CANCEL_SUBSCRIPTION":
        setState({
          ...stateRef.current,
          loading: true,
          error: null,
        });
        dispatch(
          cancelSubscription(
            {},
            () => history.push("/subscribe/unsubscribed"),
            (e) => handleError(e)
          )
        );
        break;
      case "CANCEL_SUBSCRIPTION_AUTORENEWAL":
        setState({
          ...stateRef.current,
          popup: {
            display: true,
            type: "cancel_subscription_autorenewal",
          },
        });
        break;
      case "CHANGE_BILLING_FREQUENCY":
        var new_billing_frequency = "MO";
        if (payload) {
          new_billing_frequency = "YR";
        }
        setState({
          ...stateRef.current,
          billing: {
            ...stateRef.current.billing,
            frequency: new_billing_frequency,
          },
        });
        break;
      case "CHANGE_PACKAGE":
        break;
      case "CONFIRM_POPUP":
        switch (payload) {
          case "cancel_subscription_autorenewal":
            setState({
              ...stateRef.current,
              loading: true,
              error: null,
              popup: {
                ...stateRef.current.popup,
                display: false,
              },
            });
            dispatch(
              modifySubscriptionAutorenewal(
                { autorenewal_status: false },
                () =>
                  setState({
                    ...state,
                    loading: false,
                    popup: { ...stateRef.current.popup, display: false },
                  }),
                (e) => handleError(e)
              )
            );
            break;
          case "stripe_error":
            setState({
              ...stateRef.current,
              loading: false,
              error: null,
              popup: {
                ...stateRef.current.popup,
                display: false,
              },
            });
            break;
          case "stripe_payment_failed":
            setState({
              ...stateRef.current,
              loading: false,
              error: null,
              popup: {
                ...stateRef.current.popup,
                display: false,
              },
            });
            break;
        }
        break;
      case "CONTACT_US":
        window.location.href =
          "mailto:support@pledgx.com?subject=Subscriptions";
        break;
      case "MORE_INFO":
        break;
      case "RENEW_SUBSCRIPTION":
        setState({
          ...stateRef.current,
          loading: true,
          error: null,
        });
        dispatch(
          modifySubscriptionAutorenewal(
            { autorenewal_status: true },
            () => setState({ ...state, loading: false }),
            (e) => handleError(e)
          )
        );
        break;
      case "SIGN_UP":
        history.push("/account/register");
        break;
      case "SUBSCRIBE":
        handleCreateSubscription({
          packageId: payload.packageId,
          priceId: payload.priceId,
          redirectType: "SUBSCRIBE",
        });
        break;
      case "SWITCH_CURRENCY":
        setState({
          ...stateRef.current,
          billing: { ...stateRef.current.billing, currency: payload },
        });
        break;
      case "UPGRADE_PACKAGE":
        handleCreateSubscription({
          packageId: payload.packageId,
          priceId: payload.priceId,
          redirectType: "UPGRADE",
        });
        break;
      case "VIEW_FEATURES":
        document.getElementById("features").scrollIntoView();
        window.scrollBy(0, DASHBOARDHEADER_HEIGHT * -1);
        break;
    }
  }

  function handleCreateSubscription({ packageId, priceId, redirectType }) {
    dispatch(clearCreateSubscription());
    setState({
      ...stateRef.current,
      loading: true,
      error: null,
    });
    dispatch(
      createSubscription(
        {
          package_id: packageId,
          price_id: priceId,
        },
        () => handleRedirect(redirectType),
        (e) => handleError(e)
      )
    );
  }

  function handleError(e) {
    // Handle API Erorrs
    if (
      e.response &&
      e.response.status === 401 &&
      e.response.data &&
      e.response.data.message === "SESSION_ID_INVALID"
    ) {
      history.push("/account");
    } else if (e.response && e.response.data) {
      var errorMessage = "Unknown error";
      try {
        errorMessage = e.response.data.message || "Unknown error";
      } catch {}
      if (
        errorMessage.includes(
          "You cannot combine currencies on a single customer"
        )
      ) {
        errorMessage =
          "You have selected a currency different from the currency which you have used for a previous subscription. If you have not had a previous subscription with us, please try subscribing again in 24 hours.";
      }
      setState({
        ...stateRef.current,
        error: errorMessage,
        loading: false,
      });
    } else {
      setState({
        ...stateRef.current,
        error:
          "We apologize. An unknown error occurred while processing your request",
        loading: false,
      });
    }
  }

  function handleRedirect(redirectType) {
    // Handle Redirect to payment page when client secret, subscription id, etc. are received
    history.push(`/subscribe/pay?${redirectType}`);
  }

  var currencyIndex = currencies.currencies_list.indexOf(
    stateRef.current.billing.currency
  );
  var remainingCurrencies = [...currencies.currencies_list];
  if (currencyIndex !== -1) {
    remainingCurrencies.splice(currencyIndex, 1);
  }

  var pageHorizontalMargins = 200;
  if (windowSize && windowSize.width) {
    if (windowSize.width < 600) {
      pageHorizontalMargins = 10;
    } else if (windowSize.width < 700) {
      pageHorizontalMargins = 20;
    } else if (windowSize.width < 1000) {
      pageHorizontalMargins = 30;
    } else if (windowSize.width < 1200) {
      pageHorizontalMargins = 100;
    }
  }

  return (
    <PackagesContainer horizontalMargins={pageHorizontalMargins}>
      <CenterRow>
        <ToggleDiv>
          <ToggleText bold={stateRef.current.billing.frequency === "MO"}>
            Billed Monthly
          </ToggleText>
          <CommonToggle
            handleSwitch={(e) =>
              handleInteraction({
                action: "CHANGE_BILLING_FREQUENCY",
                payload: e.target.checked,
              })
            }
            checked={stateRef.current.billing.frequency === "YR"}
          />
          <ToggleText bold={stateRef.current.billing.frequency === "YR"}>
            Billed Yearly
          </ToggleText>
        </ToggleDiv>
      </CenterRow>
      {contractor_packages?.promos?.billing_frequency && (
        <CenterRow>
          <BillingPromoText>
            {contractor_packages.promos.billing_frequency}
          </BillingPromoText>
        </CenterRow>
      )}
      <RowBreak />
      {stateRef.current.error && (
        <>
          <CenterRow>
            <ErrorContainer>
              <ErrorOutlineIcon sx={{ fontSize: 22, color: "white" }} />
              <ErrorText>{stateRef.current.error}</ErrorText>
            </ErrorContainer>
          </CenterRow>
        </>
      )}
      <PackageCarouselContainer>
        {contractor_packages.packages.map((packageOption) => (
          <PackageItem
            billing={stateRef.current.billing}
            handleInteraction={(e) => handleInteraction(e)}
            loading={stateRef.current.loading}
            packageOption={packageOption}
            permissions={subscriptionPermissions}
            pricesLoading={
              !packagePrices || Object.keys(packagePrices).length < 1
            }
            subscribedPackage={stateRef.current.subscribedPackage}
            newCustomer={newCustomer}
            userStatus={{
              loggedIn:
                props.authStatus &&
                props.authStatus.loggedIn &&
                stateRef.current.user &&
                stateRef.current.user.loggedIn,
              subscribed: props.authStatus.subscribed,
            }}
          />
        ))}
      </PackageCarouselContainer>
      <RowBreak />
      <InteractionsContainer>
        <PackageItemBoxDescription>
          All prices are in {stateRef.current.billing.currency}
        </PackageItemBoxDescription>
        {(!currencyOptions ||
          !currencyOptions.currencies_available ||
          currencyOptions.currencies_available.length > 1) &&
          remainingCurrencies &&
          remainingCurrencies.length > 0 && (
            <PackageItemBoxDescription
              clickable={true}
              onClick={() =>
                handleInteraction({
                  action: "SWITCH_CURRENCY",
                  payload: remainingCurrencies[0],
                })
              }
            >
              Switch to {remainingCurrencies[0]}
            </PackageItemBoxDescription>
          )}
        <FunctionalButton
          buttonTitle="View All Plan Features"
          handleButton={() => handleInteraction({ action: "VIEW_FEATURES" })}
          width="250px"
          height="50px"
          marginTop="20px"
          marginRight="0px"
          isReversedColor={true}
        />
      </InteractionsContainer>
      <RowBreak />
      <FeaturesContainer id="features">
        <FeaturesTable
          columns={contractor_packages.features.columns}
          rows={contractor_packages.features.rows}
          options={contractor_packages.features.options}
          displayVariant={stateRef.current.billing.frequency}
        />
      </FeaturesContainer>
      <Popup
        open={stateRef.current.popup.display}
        height={popupOptions[stateRef.current.popup.type].height}
        innerHeight={popupOptions[stateRef.current.popup.type].height}
        innerPadding={props.isMobile ? "0" : "60px 50px 40px 50px"}
        width={
          props.isMobile
            ? "100%"
            : popupOptions[stateRef.current.popup.type].width
        }
        dialogContent={
          <>
            <PopupContainer>
              <CloseButton
                handleClose={() =>
                  setState({
                    ...stateRef.current,
                    popup: { ...stateRef.current.popup, display: false },
                  })
                }
              />
              <ContentArea>
                <ImagePopup>
                  <PledgxLogo isMobile={props.isMobile} />
                </ImagePopup>
                <TextArea>
                  <LargeText isMobile={props.isMobile}>
                    {popupOptions[stateRef.current.popup.type].text_title}
                  </LargeText>
                  <SmallText>
                    {popupOptions[stateRef.current.popup.type].text_details}
                  </SmallText>
                </TextArea>
              </ContentArea>
              <ButtonArea isMobile={props.isMobile}>
                {popupOptions[stateRef.current.popup.type].button_cancel && (
                  <FunctionalButton
                    buttonTitle={
                      popupOptions[stateRef.current.popup.type].button_cancel
                    }
                    height={props.isMobile ? "48px" : "53px"}
                    marginRight="20px"
                    handleButton={() =>
                      setState({
                        ...stateRef.current,
                        popup: { ...stateRef.current.popup, display: false },
                      })
                    }
                    isReversedColor={true}
                  />
                )}
                <FunctionalButton
                  buttonTitle={
                    popupOptions[stateRef.current.popup.type].button_confirm
                  }
                  height={props.isMobile ? "48px" : "53px"}
                  marginRight="0px"
                  handleButton={() =>
                    handleInteraction({
                      action: "CONFIRM_POPUP",
                      payload: stateRef.current.popup.type,
                    })
                  }
                />
              </ButtonArea>
            </PopupContainer>
          </>
        }
      />
    </PackagesContainer>
  );
};
