import styled from "styled-components";
import Card from "@material-ui/core/Card";
import { Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TYPOGRAPHY } from "../../../constants";

export const StyledCard = styled(Card)`
  margin-top: 24px;
  min-height: 96px;
  min-width: 207px;
  ${(props) => (props.isMobile ? "text-align:center" : "")};

  && {
    border-radius: 6px;
    border-color: #ebe9f1;
  }
`;

export const StyledHeading = styled.p`
  display: inline;
  color: #c4c4c4;
  font-size: 10px;
  margin-left: 16px;
`;

export const StyledProjectInfo = styled.p`
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  margin-left: 16px;

  &:first-letter {
    text-transform: uppercase;
  }
`;

export const StyledContainer = styled.div`
  display: inline-block;
  margin-top: 16px;
  vertical-align: top;
`;

export const StyledTitle = styled(StyledContainer)`
  margin-left: ${(props) => (props.isMobile ? "" : "16px")};
  width: ${(props) => (props.isMobile ? "" : "32%")};
  min-width: ${(props) => (props.isMobile ? "" : "350px")};
`;

export const StyledProjectNumber = styled(StyledContainer)`
  width: ${(props) => (props.isMobile ? "" : "16%")};
  min-width: ${(props) => (props.isMobile ? "" : "203px")};
`;

export const StyledProjectOtherInfo = styled(StyledContainer)`
  width: ${(props) => (props.isMobile ? "" : "20%")};
  min-width: ${(props) => (props.isMobile ? "" : "254px")};
`;

export const StyledProjectStatus = styled(StyledContainer)`
  min-width: ${(props) => (props.isMobile ? "" : "106px")};
`;

export const StyledCompanyInfo = styled.p`
  font-size: 18px;
  font-weight: 550;
  margin: 0;
  color: #5e5873;
`;

export const StyledCompanyAddress = styled(StyledContainer)`
  margin-top: 20px;
  min-width: ${(props) => (props.isMobile ? "" : "254px")};
`;

export const StyledCompanyName = styled(StyledTitle)`
  margin-top: 20px;
`;

export const StyledDate = styled(StyledProjectNumber)`
  margin-top: 20px;
`;

export const StyledDetailsContainer = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "64%")};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.isMobile ? "center" : "")};

  .inputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    min-width: ${(props) => (props.isMobile ? "100%" : "285px")};
    width: 50%;
  }

  .fullWidthInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: 100%;
  }

  .oneThirdInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    min-width: ${(props) => (props.isMobile ? "100%" : "285px")};
    width: 33.33%;
  }

  .twoThirdInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: 66.67%;
  }

  .oneFifthInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    min-width: ${(props) => (props.isMobile ? "100%" : "200px")};
    width: 16%;
  }

  .narrowInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    min-width: ${(props) => (props.isMobile ? "100%" : "50px")};
    width: 10%;
  }

  .oneForthInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    min-width: ${(props) => (props.isMobile ? "100%" : "285px")};
    width: 25%;
  }
`;

export const StyledContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 16px;
`;
export const StyledContainerInside = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const StyledHeadingTitle = styled(Typography)`
  && {
    font-size: 24px;
    font-family: SF Pro Text;
    font-weight: 600;
    margin-top: 48px;
    color: ${(props) => (props.color ? props.color : "black")};
  }
`;

export const StyledQuestion = styled(Typography)`
  && {
    font-size: 15px;
    margin-top: 24px;

    &.MuiTypography-root {
      font-weight: 500;
    }
  }
`;

export const StyledRadioLabel = styled(FormControlLabel)`
  && {
    margin-top: 16px;
    margin-left: 0px;
    margin-right: 14px;

    .MuiTypography-body1 {
      font-size: 12px;
      font-weight: 500;
    }

    .MuiIconButton-label {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
`;

export const StyledSubContainer = styled(StyledDetailsContainer)`
  width: 100%;
`;

export const StyledHeaderContent = styled(StyledHeadingTitle)`
  && {
    font-size: 28px;
    font-weight: 600;
    width: 50%;
    margin-top: 24px;
    margin-bottom: ${(props) =>
      props.marginBottom ? props.marginBottom : "16px"};
  }
`;

export const StyledButtonArea = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const StyledSetupContainer = styled(StyledSubContainer)`
  margin-top: 48px;
`;

export const StyledBox = styled.div`
  width: ${(props) => props.boxWidth || "100%"};
  ${(props) =>
    props.hasData
      ? ""
      : `border-radius: ${props.borderRadius || `10px`}; margin-bottom: 12px;`}
  ${(props) =>
    props.hasData ? "" : "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;"}
  height: ${(props) => props.boxHeight || "248px"};
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "column"};
  ${(props) => (props.isMobile ? "align-items: center;" : "")}
  ${(props) => (props.margin ? `margin: ${props.margin};` : "")}
  ${(props) => (props.hasData ? "" : "justify-content: center;")}
  ${(props) => (props.noBackground ? "" : "background-color: white;")}
`;

export const StyledHeaderDiv = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "row"};
  justify-content: space-between;
  margin-top: 16px;
`;

export const StyledProjectTitleText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 32px;
  }
`;

export const StyledNumberText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    margin: 16px 4px;
  }
`;
