import React from "react";
import { FunctionalButton } from 'components/functionalButton';
import { Popup } from 'components/popup';
import { CloseButton } from "components/closeButton";
import { filterReference, deleteComment } from "api/reference";
import PledgxLogo from "assets/common/pledgxLogo";

import {
  TitleDiv,
  TitleText,
  PopupContainer,
  ButtonDiv
} from "./styles";


export const DeletePost = ({
  openPopup,
  setOpenPopup,
  isMobile,
  data,
  setComment,
  setHasComment,
  setFilteredData
}) => {

  const handleFilter = async (filterQuery) => {
    await filterReference(filterQuery)
      .then((data) => {
        if (data) {
          setFilteredData(data);
          setOpenPopup(false);
        }
      })
      .catch((err) => { });
  };

  const handleDone = () => {
    setOpenPopup(false);
  };

  const deleteContestPost = () => {
    deleteComment(data?.id, () => {
      setComment(null);
      setHasComment(false);
      setOpenPopup(false);
      // handleFilter("");
      window.location.reload();
    }, () => { })
  }


  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width={isMobile ? "100%" : "500px"}
      height={isMobile ? "100vh" : "300px"}
      dialogContent={(
        <>
          <CloseButton handleClose={handleDone} />
          <PopupContainer>
            <TitleDiv>
              <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
            </TitleDiv>
            
            <TitleText fontSize="18px" fontWeight="600" color="black" margin="30px 0px 6px">Would you like to delete the comment?</TitleText>
            <ButtonDiv isMobile={isMobile}>
              <FunctionalButton
                buttonTitle="GO BACK"
                handleButton={handleDone}
                isReversedColor={true}
                height="48px"
                width="150px"
                marginTop="0px"
                marginRight="0px"
              />
              <FunctionalButton
                buttonTitle={"CONFIRM"}
                height="48px"
                width="150px"
                marginTop="0px"
                marginRight="0px"
                handleButton={() => deleteContestPost()}
              />
            </ButtonDiv>
          </PopupContainer>

        </>
      )}
    />

  )
}
