import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { GraphicContainer } from "./GraphicContainer";
import { Login } from "./Login";
import { Register } from "./Register";
import { EmailSent } from "./EmailSent";
import { ForgotPassword } from "./ForgotPassword";
import { CheckEmail } from "./CheckEmail";
import { ResendEmail } from "./ResendEmail";
import { hotjar } from "react-hotjar";
import { Helmet } from "react-helmet";

import { Switch, Redirect, Route } from "react-router-dom";

import { LoginRoot } from "./styles";

function LoginPage() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isMedium = useMediaQuery({ maxWidth: 720 });
  const [email, setEmail] = useState("");
  const [isPasswordChange, setIsPasswordChange] = useState(true);

  useEffect(() => {
    hotjar.initialize(2877094, 6);
  }, []);

  return (
    <LoginRoot isMobile={isMedium}>
      {/* {isMobile && (
          <Header isMobile={isMobile} useLinks redirectToMain></Header>
        )} */}
      <Switch>
        <Route
          exact
          path="/account"
          render={() => <Redirect to="/account/login" />}
        />
        <Route path="/account/login">
          <Login isMobile={isMobile} />
        </Route>
        <Route path="/account/register/:token">
          <Register email={email} setEmail={setEmail} isMobile={isMobile} />
        </Route>
        <Route path="/account/register">
          <Register email={email} setEmail={setEmail} isMobile={isMobile} />
        </Route>
        <Route path="/account/emailed">
          <EmailSent email={email} isMobile={isMobile} />
        </Route>
        <Route path="/account/forgot">
          <ForgotPassword
            setIsPasswordChange={setIsPasswordChange}
            isMobile={isMobile}
          />
        </Route>
        <Route path="/account/check">
          <CheckEmail isPasswordChange={isPasswordChange} isMobile={isMobile} />
        </Route>
        <Route path="/account/resend">
          <ResendEmail
            setIsPasswordChange={setIsPasswordChange}
            isMobile={isMobile}
          />
        </Route>
      </Switch>
      {!isMedium && <GraphicContainer />}
    </LoginRoot>
  );
}

export default LoginPage;
