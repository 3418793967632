import React, { Fragment } from "react";
import Tooltip from '@mui/material/Tooltip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

import classes from './Table.module.css';
import { COLORS, FONT_SIZES } from '../../constants';

export const FeaturesTable = ({ columns, displayVariant, rows, options }) => {

  function cellBuilder(cellData) {
    var cellContent = '';
    switch(typeof cellData) {
      case 'boolean':
        if (cellData) {
          cellContent = <CheckIcon />;
        } else {
          cellContent = <HorizontalRuleIcon />;
        };
        break;
      case 'number':
        cellContent = cellData.toString();
        break;
      case 'string':
        cellContent = cellData;
        break;
      case 'object':
        if (displayVariant && (cellData !== null) && (cellData[displayVariant])) {
          return cellBuilder(cellData[displayVariant]);
        };
        break;
    };
    return(
      <div className={ [classes['cell-wrap'], classes['cell-content']].join(' ') }>
        { cellContent }
      </div>
    );
  };

  function rowBuilder() {
    try {
      return(
        rows.map((row, index) => (
          <tr
            key={ row.id }
            id={ row.id }
            className={ [row.priority ? `priority-${ row.priority }` : 'priority-0', (index % 2 === 0) ? classes['even-row'] : classes['odd-row'], (row.disabled) && classes['row-disabled']].join(' ') }
            style={{
              backgroundColor: (index % 2 === 0) ? COLORS.greyBorder : '',
              color: (row.disabled) ? COLORS.disabledGrey : ''
            }}
          >
            {
              columns.map(column => (
                column.title_column ?
                  <td key={ column.id } className={ [classes[column.id], classes.cell].join(' ') }>
                    <div className={ [classes.truncate, classes['row-title']].join(' ') }>
                      <span className={ [classes['row-title-span'], classes['cell-wrap']].join(' ') }>{ row.title }</span>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {
                          row.unreleased &&
                            <Tooltip
                              title='This feature is coming soon!'
                              placement='top'
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    fontSize: FONT_SIZES.body_5,
                                    color: 'white',
                                    bgcolor: COLORS.darkGrey1,
                                    border: `1.5px solid ${ COLORS.darkGrey1 }`,
                                    maxWidth: '250px',
                                    '& .MuiTooltip-arrow': {
                                      color: COLORS.darkGrey1
                                    },
                                  },
                                },
                              }}
                              >
                                <AccessTimeIcon
                                  sx={{ fontSize: 14, color: COLORS.disabledGrey, cursor: 'pointer', marginRight: row.tooltip ? '5px' : '0' }}
                                />
                            </Tooltip>
                        }
                        {
                          row.tooltip &&
                            <Tooltip
                              title={ row.tooltip }
                              placement='top'
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    fontSize: FONT_SIZES.body_5,
                                    color: 'white',
                                    bgcolor: row.disabled ? COLORS.darkGrey1 : COLORS.primaryOrange,
                                    border: `1.5px solid ${ row.disabled ? COLORS.darkGrey1 : COLORS.primaryOrange }`,
                                    maxWidth: '250px',
                                    '& .MuiTooltip-arrow': {
                                      color: row.disabled ? COLORS.darkGrey1 : COLORS.primaryOrange
                                    },
                                  },
                                },
                              }}
                              >
                                <HelpOutlineIcon
                                  sx={{ fontSize: 14, color: row.disabled ? COLORS.disabledGrey : COLORS.primaryOrange, cursor: 'pointer' }}
                                />
                            </Tooltip>
                        }
                      </div>
                    </div>
                  </td>
                :
                  (options[column.id] && ((options[column.id].features[row.id]) || (options[column.id].features[row.id] === false) || (options[column.id].features[row.id] === 0))) ?
                    <td key={ column.id } className={ [classes[column.id], classes.cell].join(' ') }>
                      { cellBuilder(options[column.id].features[row.id]) }
                    </td>
                  :
                    <td key={ column.id } className={ [classes['empty-cell'], classes.cell].join(' ') }>
                    </td>
              ))
            }
          </tr>
        ))
      );
    } catch {
    };
  };
  
  return (
    <div className={ classes['table-container'] } style={{ color: 'black', fontSize: FONT_SIZES.body_5, fontWeight: '400' }}>
       <table className={ classes.table } cellPadding={ 0 } cellSpacing={ 0 }>
        <colgroup>
          {
            columns.map(column => (
              <col className={ classes['data-row'] } key={ column.id } />
            ))
          }
        </colgroup>
        <thead
          className={ classes['table-head'] }
          style={{ borderBottom: `.5px solid ${ COLORS.lightGrey1 }` }}
        >
          <tr>
            {
              columns.map(column => (
                <th
                  key={ column.id }
                  className={ [classes['table-column'], column.subtle && classes.subtle, column.title_column ? classes['title-column'] : classes['data-column']].join(' ') }
                  style={ column.subtle && { color: COLORS.lightGrey2 }}
                >
                  { column.title }
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          { rowBuilder() }
        </tbody>
      </table>
    </div>
  );
};
