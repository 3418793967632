import { AutoFilledAddress } from "components/autoFilledAddress";
import { InfoArea, SectionText } from "./styles";
import Input from "../inputField";
import React, { useEffect, useState } from "react";
import { getBanks, getInsurances } from "api/external_data";
import Select from "../../components/selectField";

export const Step1 = ({ setDisable, addData, setAddData, isMobile }) => {
  const [dropdownList, setDropdownList] = useState([]);

  useEffect(() => {
    if (
      addData?.company_name?.length > 0 &&
      addData?.company?.address?.length > 0 &&
      addData?.company?.city?.length > 0 &&
      addData?.company?.postal?.length > 0 &&
      addData?.company?.province?.length > 0
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [addData]);

  useEffect(() => {
    if (addData?.type === "bank") {
      getBanks()
        .then((res) => {
          let bankList = [...res];
          setDropdownList(bankList);
        })
        .catch(() => {
          console.log("TODO: HANDLE ERROR PROPERLY");
        });
    }
    if (addData?.type === "insurance" || addData?.type === "bonding") {
      getInsurances()
        .then((res) => {
          if (addData?.type === "insurance") {
            let insuranceList = [...res];
            setDropdownList(insuranceList);
          }
          if (addData?.type === "bonding") {
            let bondinglist = [
              ...res.filter((insurance) => insurance.is_bonding === "Yes"),
            ];
            setDropdownList(bondinglist);
          }
        })
        .catch(() => {
          console.log("TODO: HANDLE ERROR PROPERLY");
        });
    }
  }, []);

  return (
    <InfoArea isMobile={isMobile} marginTop="0px">
      <SectionText>Add Company Information</SectionText>
      {(addData?.type === "bank" ||
        addData?.type === "insurance" ||
        addData?.type === "bonding") && (
        <Select
          // style={{ marginRight: "16px" }}
          label="Choose Entity Name"
          options={dropdownList?.map((item) => ({
            value: item.name,
            label: item.name,
          }))}
          value={addData?.company_name ? addData?.company_name : ""}
          setValue={(val) => setAddData("company_name", val)}
          validateFunc={(item) => Boolean(item)}
          noCheckIcon={true}
          showDropdownIndicator={true}
        />
      )}
      {/* {addData?.type === "bonding" && (
        <Input
          // id="fullWidthInputWrap"
          label="Entity Name*"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.company_name ? addData?.company_name : ""}
          setValue={(val) => setAddData("company_name", val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
      )} */}
      <AutoFilledAddress
        data={addData}
        setData={setAddData}
        requiredFields={true}
      />
    </InfoArea>
  );
};
