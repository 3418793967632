import React, { useEffect } from "react";

import { FormBuilder } from "../formBuilder";

import { CloseButton, FormTitle } from "./styles";

import Dialog from "@material-ui/core/Dialog";
import Error from "@material-ui/icons/Error";
import Paper from "@material-ui/core/Paper";
import CloseIcon from "@material-ui/icons/Close";
import PledgxLogo from "assets/common/pledgxLogo";

export const AddItemPopup = ({
  data,
  errorIcon,
  form,
  inheritState,
  message,
  onSubmit,
  openDialog,
  options,
  setData,
  setOpenDialog,
  setEmptyItem,
}) => {
  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <Paper
        elevation={0}
        style={{
          padding: 25,
          width: 400,
          alignSelf: "center",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <CloseButton
          aria-label="close"
          onClick={() => {
            setEmptyItem({});
            setOpenDialog(false);
          }}
        >
          <CloseIcon />
        </CloseButton>
        {!inheritState?.loading && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {errorIcon ? (
              <Error
                style={{
                  color: "#FF647C",
                  marginLeft: "5px",
                  width: 60,
                  height: 60,
                }}
              />
            ) : (
              <PledgxLogo />
            )}
            <FormTitle
              style={{
                marginTop: "10px",
                marginBottom: form.navigationType ? "20px" : "0",
              }}
            >
              {options?.labels?.popupTitle || "Add Item"}
            </FormTitle>
          </div>
        )}
        {form && (
          <FormBuilder
            changeData={(e) => setData(e.payload)}
            data={data}
            formDetails={{
              ...form.details,
              style: form.details?.style
                ? form.details.style
                : { padding: "0 10px 0 10px" },
            }}
            formFields={form.fields}
            formSetup={form.setup}
            loading={inheritState?.loading}
            navigationType={form.navigationType || null}
            onSubmit={onSubmit}
          />
        )}
      </Paper>
    </Dialog>
  );
};
