import axios from "axios";
import { API_HOST } from "../constants";

let headers = {};

const getAuth = () => {
  const authData = localStorage.getItem("Authorization");
  if (authData) {
    headers = { Authorization: authData };
  }
};

export const getCompanyInfo = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/profile/company/get`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};


export const getSubCategories = async (data, onSuccess, onFailure) => {
  getAuth();  
  try {
    const response = await axios.post(`${API_HOST}/sub-categories/get`, data, { headers });
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem('Authorization');
      dispatchEvent(new Event('storage'));
    } else {
      if (onFailure && typeof onFailure === 'function') {
        onFailure(err);
      } else {
        console.error('Error occurred:', err);
      }
    }
  }
};

// export const getClassUNSPC = async (data, onSuccess, onFailure) => {
//   getAuth();
//   return await axios.post(`${API_HOST}/unspc-class/get`, data, { headers })
//     .then((res) => onSuccess(res.data))
//     .catch((err) => {
//       if (err.response.status === 401) {
//         localStorage.removeItem("Authorization");
//         dispatchEvent(new Event("storage"));
//       }
//       onFailure(err.response)
//     });
// }

export const getClassUNSPC = async (data, onSuccess, onFailure) => {
  getAuth();  
  try {
    const response = await axios.post(`${API_HOST}/unspc-class/get`, data, { headers });
    onSuccess(response.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      localStorage.removeItem('Authorization');
      dispatchEvent(new Event('storage'));
    } else {
      if (onFailure && typeof onFailure === 'function') {
        onFailure(err);
      } else {
        console.error('Error occurred:', err);
      }
    }
  }
};


export const saveCompanyInfo = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/profile/company/save`, data, { headers })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const savePassword = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/profile/password/change`, data, { headers })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const getFinancialInfo = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/profile/financial/get`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const getSingleFinancial = async (id, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .get(`${API_HOST}/profile/singlefinancial/get/${id}`, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const saveSingleFinancial = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/profile/singlefinancial/save`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const deleteSingleFinancial = async (id, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/profile/singlefinancial/delete/${id}`, {}, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const setDefaultFinancial = async (
  id,
  type,
  data,
  onSuccess,
  onFailure
) => {
  getAuth();
  return await axios
    .post(
      `${API_HOST}/profile/singlefinancial/default/${id}?type=${type}`,
      data,
      { headers }
    )
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};
export const getAnnualInfo = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/profile/annual/get`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const saveAnnualInfo = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/profile/annual/save`, data, { headers })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const saveCompanyLogo = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/profile/logo`, data, { headers })
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};
