import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Input from '../../../../components/inputField';
import { FunctionalButton } from "../../../../components/functionalButton";
import {
  StyledQuestion,
  InfoArea,
  ButtonAtBottom
 } from '../styles';

export const Panel2 = ({
  handleNext,
  onboardingData,
  setOnboardingData,
  setIsLastPanel,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  setIsLastPanel(true);
  
  return (
    <>
      <StyledQuestion isMobile={isMobile} width="650px">What is your job title?</StyledQuestion>
      <InfoArea isMobile={isMobile} width="50%">
        <Input
          label="Job Title"
          type="text"
          placeholder="i.e: Project Manager"
          labelSize="12px"
          fontSize="18px"
          value={onboardingData?.user_title || ''}
          setValue={(val) => setOnboardingData('user_title', val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noCancelIcon={true}
          noErrorBorder={true}
        />
      </InfoArea>
      {/* <ButtonAtBottom isMobile={isMobile}> */}
      <FunctionalButton
          buttonTitle="Next"
          handleButton={handleNext}
          width={isMobile ? "90%" : "200px"}
          marginRight="0px"
          marginTop={isMobile ? "72px" : "48px"}
          disabled={!(onboardingData?.user_title?.length > 0)}
        />
      {/* </ButtonAtBottom> */}
    </>
  );
}

