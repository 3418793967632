import React, { useEffect, useState } from "react";
import { FunctionalButton } from "components/functionalButton";
import PledgxLogo from "assets/common/pledgxLogo";
import { useHistory } from "react-router-dom";
import Input from "components/inputField";
import { viewPaymentProgressCertificateApproval } from 'api/work_in_progress.js';
import { useParams } from "react-router";
import { handleCommaFormat, numberWithCommas, removeComma } from "common/numberFormat";
import { progressPaymentCertificateApprovalBuyer } from "api/work_in_progress";
import DateTimePickerField from "components/dateTimePickerField";
import { RedirectToApproval } from "./redirectToApproval"

import {
  FormTitle,
  TitleDiv,
  ButtonDiv,
  ScreenRoot,
  FormContainer,
  FieldArea,
  SmallTitleText
} from "./styles.js";

import { COLORS, FONT_SIZES } from "constants.js";


function PaymentProgressCertificateForApproval({
  isMobile
}) {
  let history = useHistory();
  const [data, setData] = useState({});
  const { token1, token2 } = useParams();
  const [valid, setValid] = useState(false);
  const [error, setError] = useState("");
  const [openRedirectStatusReportPopup, setOpenRedirectStatusReportPopup] = useState(false);

  const [validSubmit, setValidSubmit] = useState(false);
  const [progressPaymentNo, setProgressPaymentNo] = useState(0);
  const [progressPaymentDate, setProgressPaymentDate] = useState("");
  const [dateApproved, setDateApproved] = useState("");
  const [billingsToDate, setBillingsToDate] = useState(data?.billings_to_date || 0);
  const [holdbackRetainageToDate, setHoldbackRetainageToDate] = useState(0);
  const [currentPaymentDue, setCurrentPaymentDue] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [previousProgressPayments, setPreviousProgressPayments] = useState(0);
  const lastPreviousProgressPayments = data?.last_previous_progress_payments ? removeComma(data?.last_previous_progress_payments) : 0;
  const [showWarningBillingsToDate, setShowWarningBillingsToDate] = useState(false);


  useEffect(() => {
    if (removeComma(billingsToDate) >= removeComma(data?.old_billings_to_date)) {
      setValidSubmit(false);
    } else {
      setValidSubmit(true);
    }
  }, [billingsToDate]);



  useEffect(() => {
    const updatedData = { ...data };
    updatedData["progress_payment_no"] = progressPaymentNo;
    setData(updatedData);
  }, [progressPaymentNo]);



  useEffect(() => {
    const updatedData = { ...data };
    updatedData["billings_to_date"] = billingsToDate ? removeComma(billingsToDate) : 0;
    const diffPreviousProgressPayments = removeComma(billingsToDate) - removeComma(holdbackRetainageToDate);
    setPreviousProgressPayments(diffPreviousProgressPayments);
    const diffCurrentPaymentDue = diffPreviousProgressPayments - lastPreviousProgressPayments
    setCurrentPaymentDue(numberWithCommas(diffCurrentPaymentDue));

    if (removeComma(billingsToDate) < removeComma(data?.old_billings_to_date)) {
      setShowWarningBillingsToDate(true);
    } else {
      setShowWarningBillingsToDate(false);
    }

    setData(updatedData);
  }, [billingsToDate]);



  useEffect(() => {
    const updatedData = { ...data };
    updatedData["holdback_retainage_to_date"] = holdbackRetainageToDate ? removeComma(holdbackRetainageToDate) : 0;
    const diffPreviousProgressPayments = removeComma(billingsToDate) - removeComma(holdbackRetainageToDate);
    setPreviousProgressPayments(diffPreviousProgressPayments);
    const diffCurrentPaymentDue = diffPreviousProgressPayments - lastPreviousProgressPayments
    setCurrentPaymentDue(numberWithCommas(diffCurrentPaymentDue));

    setData(updatedData);
  }, [holdbackRetainageToDate]);


  useEffect(() => {
    const updatedData = { ...data };
    updatedData["progress_payment_date"] = progressPaymentDate;
    setData(updatedData);
  }, [progressPaymentDate]);


  useEffect(() => {
    const updatedData = { ...data };
    updatedData["date_approved"] = dateApproved;
    setData(updatedData);
  }, [dateApproved]);

  useEffect(() => {
    const updatedData = { ...data };
    updatedData["remarks"] = remarks ? remarks : "";
    setData(updatedData);
  }, [remarks]);

  const handleProgressPaymentCertificateApprovalBuyer = () => {
    const ppc_status = "Approved"
    const newData = { ...data, ppc_status: ppc_status };
    setData(newData);
    progressPaymentCertificateApprovalBuyer(newData, () => {
      window.location.replace("https://pledgx.com")
    }, () => { });
  };


  useEffect(() => {
    viewPaymentProgressCertificateApproval(
      token1,
      token2,
      (res) => {
        setData(res);
        setBillingsToDate(res?.billings_to_date);
        setHoldbackRetainageToDate(res?.holdback_retainage_to_date);
        setValid(true);
      },
      (err) => { setValid(false); setError(err.data.message); }
    )
  }, [])


  const handleRedirectStatus = () => {
    setOpenRedirectStatusReportPopup(true);
  };

  return (
    <>
      {!data && (
        <div
          style={{
            marginTop: "150px",
            color: "black",
            fontSize: FONT_SIZES.body_4,
            fontWeight: "400",
          }}
        >
          <div
            style={{
              color: COLORS.textGrey,
              fontSize: FONT_SIZES.body_4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No Progress Payment  Certificate for Approval To View
          </div>
        </div>
      )}
      {data && (
        <ScreenRoot isMobile={isMobile}>
          <FormContainer isMobile={isMobile}>
            <TitleDiv>
              <PledgxLogo
                width={isMobile ? 45 : ""}
                height={isMobile ? 45 : ""}
              />
              <FormTitle style={{ marginTop: 10 }}>
                {"Progress Payment Certificate"}
              </FormTitle>
              
              <SmallTitleText style={{
                marginBottom: 10,
                marginTop: -30,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              }}>
                {data?.project_name}
              </SmallTitleText>
            </TitleDiv>

            <FieldArea width={isMobile ? "100%" : "100%"}>

              <div style={{ margin: "0px", marginBottom: "-15px", display: "flex", flexDirection: "row", width: "100%" }}>
                {data?.ppc_status === "Approved" && (
                  <>
                    <Input
                      id="inputWrap"
                      label="Progress Payment #"
                      type="text"
                      placeholder=""
                      labelSize="12px"
                      fontSize="18px"
                      value={data?.progress_payment_no}
                      noCheckIcon={true}
                      noErrorBorder={true}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />
                    <DateTimePickerField
                      showDateField={true}
                      dateWidth="50%"
                      paddingRight="0px"
                      dateLabel="Progress Payment Date"
                      dateValue={data?.progress_payment_date ? data?.progress_payment_date : ""}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />
                  </>
                )}


                {data?.ppc_status !== "Approved" && (
                  <>
                    <Input
                      id="inputWrap"
                      label="Progress Payment #"
                      type="text"
                      placeholder=""
                      labelSize="12px"
                      fontSize="18px"
                      value={
                        data?.progress_payment_no ? data?.progress_payment_no : ""
                      }
                      setValue={setProgressPaymentNo}
                      validateFunc={(item) => item.length > 0}
                      noCheckIcon={true}
                      noErrorBorder={true}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />
                    <DateTimePickerField
                      showDateField={true}
                      dateWidth="50%"
                      paddingRight="0px"
                      dateLabel="Progress Payment Date*"
                      dateValue={
                        data?.progress_payment_date ? data?.progress_payment_date : ""
                      }
                      setDateValue={setProgressPaymentDate}
                    />
                  </>
                )}

              </div>

              <div style={{ margin: "0px", marginBottom: "-15px", display: "flex", flexDirection: "row", width: "100%" }}>
                {data?.ppc_status === "Approved" && (
                  <>
                    <Input
                      id="inputWrap"
                      label="Billings to Date"
                      type="text"
                      placeholder="$"
                      labelSize="12px"
                      fontSize="18px"
                      value={handleCommaFormat(data?.billings_to_date)}
                      noCheckIcon={true}
                      noErrorBorder={true}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />

                    <Input
                      id="inputWrap last"
                      label="Holdback/Retainage to Date"
                      type="text"
                      placeholder="$"
                      labelSize="12px"
                      fontSize="18px"
                      value={handleCommaFormat(data?.holdback_retainage_to_date)}
                      noCheckIcon={true}
                      noErrorBorder={true}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />
                  </>
                )}


                {data?.ppc_status !== "Approved" && (
                  <>
                    <Input
                      id="inputWrap"
                      label="Billings to Date*"
                      type="text"
                      placeholder="$"
                      labelSize="12px"
                      fontSize="18px"
                      value={handleCommaFormat(billingsToDate)}
                      setValue={(value) => {
                        setBillingsToDate(value.toLocaleString());
                        const diffCurrentPaymentDue = removeComma(value) - removeComma(holdbackRetainageToDate) - lastPreviousProgressPayments
                        setCurrentPaymentDue(numberWithCommas(diffCurrentPaymentDue));
                      }}
                      onBlur={(e) => setBillingsToDate(handleCommaFormat(e.target.value))}
                      noCheckIcon={true}
                      noErrorBorder={true}
                    />

                    <Input
                      id="inputWrap last"
                      label="Holdback/Retainage to Date*"
                      type="text"
                      placeholder="$"
                      labelSize="12px"
                      fontSize="18px"
                      value={handleCommaFormat(holdbackRetainageToDate)}
                      setValue={(value) => {
                        setHoldbackRetainageToDate(value.toLocaleString());
                        const diffCurrentPaymentDue = removeComma(billingsToDate) - removeComma(value) - lastPreviousProgressPayments
                        setCurrentPaymentDue(numberWithCommas(diffCurrentPaymentDue));
                      }}
                      onBlur={(e) => setHoldbackRetainageToDate(handleCommaFormat(e.target.value))}
                      noCheckIcon={true}
                      noErrorBorder={true}
                    />
                  </>
                )}
              </div>

              <div style={{ margin: "0px", marginBottom: "-15px", display: "flex", flexDirection: "row", width: "100%" }}>

                {data?.ppc_status === "Approved" && (
                  <>
                    <Input
                      id="inputWrap"
                      label="Previous Progress Payments"
                      type="text"
                      placeholder="$"
                      labelSize="12px"
                      fontSize="18px"
                      value={handleCommaFormat(data?.last_previous_progress_payments)}
                      noCheckIcon={true}
                      noErrorBorder={true}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />
                    <Input
                      id="inputWrap last"
                      label="Current Payment Due"
                      type="text"
                      placeholder="$"
                      labelSize="12px"
                      fontSize="18px"
                      value={handleCommaFormat(data?.current_payment_due)}
                      noCheckIcon={true}
                      noCancelIcon={true}
                      noErrorBorder={true}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />
                  </>
                )}


                {data?.ppc_status !== "Approved" && (
                  <>
                    <Input
                      id="inputWrap"
                      label="Previous Progress Payments"
                      type="text"
                      placeholder="$"
                      labelSize="12px"
                      fontSize="18px"
                      value={handleCommaFormat(lastPreviousProgressPayments)}
                      noCheckIcon={true}
                      noErrorBorder={true}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />

                    <Input
                      id="inputWrap last"
                      label="Current Payment Due"
                      type="text"
                      placeholder="$"
                      labelSize="12px"
                      fontSize="18px"
                      value={handleCommaFormat(currentPaymentDue)}
                      noCheckIcon={true}
                      noCancelIcon={true}
                      noErrorBorder={true}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />
                  </>
                )}

              </div>

              <div style={{ margin: "0px", display: "flex", flexDirection: "row", width: "100%" }}>

                {data?.ppc_status !== "Approved" && (
                  <>
                    <DateTimePickerField
                      showDateField={true}
                      dateWidth="50%"
                      paddingRight="16px"
                      dateLabel="Date Approved"
                      dateValue={data?.date_approved ? data?.date_approved : ""}
                      setDateValue={setDateApproved}
                    />

                    <Input
                      id="inputWrap last"
                      label="Remarks"
                      type="text"
                      placeholder=""
                      labelSize="12px"
                      fontSize="18px"
                      value={data?.remarks ? data?.remarks : ""}
                      setValue={setRemarks}
                      noCheckIcon={true}
                      noCancelIcon={true}
                      noErrorBorder={true}
                    />

                  </>
                )}


                {data?.ppc_status === "Approved" && (
                  <>
                    <DateTimePickerField
                      showDateField={true}
                      dateWidth="50%"
                      paddingRight="16px"
                      dateLabel="Date Approved"
                      dateValue={data?.date_approved ? data?.date_approved : ""}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                    />

                    <Input
                      id="inputWrap last"
                      label="Remarks"
                      type="text"
                      placeholder=""
                      labelSize="12px"
                      fontSize="18px"
                      value={data?.remarks ? data?.remarks : ""}
                      backgroundColor="rgba(235, 233, 241, 0.75)"
                      disabled
                      noCheckIcon={true}
                      noCancelIcon={true}
                      noErrorBorder={true}
                    />

                  </>
                )}
              </div>


              {showWarningBillingsToDate && (
                <div style={{ color: 'red', fontSize: '12px', marginTop: '0px' }}>
                  Billings to Date* value should not be less than {numberWithCommas(removeComma(data?.old_billings_to_date))}
                </div>
              )}



            </FieldArea>

            <ButtonDiv isMobile={isMobile}>
              {data?.ppc_status === "Approved" && (
                <>
                  <FunctionalButton
                    disabled
                    width="30%"
                    marginTop="32px"
                    marginBottom="34x"
                    marginRight="0px"
                    buttonTitle={"APPROVED"}
                  />
                </>
              )}

              {data?.ppc_status !== "Approved" && (
                <>
                  <FunctionalButton
                    disabled={validSubmit}
                    width="30%"
                    marginTop="32px"
                    marginBottom="34x"
                    marginRight="16px"
                    handleButton={() => {
                      handleProgressPaymentCertificateApprovalBuyer();
                    }}
                    buttonTitle={"APPROVE"}
                  />

                  {data?.redirect_email === "" && (
                    <>
                      <FunctionalButton
                        buttonTitle={"REDIRECT TO"}
                        width="30%"
                        marginTop="32px"
                        marginBottom="34x"
                        marginRight="0px"
                        isReversedColor={true}
                        handleButton={() => handleRedirectStatus()}
                      />
                    </>
                  )}
                </>
              )}
            </ButtonDiv>

            <RedirectToApproval
              isMobile={isMobile}
              data={data}
              openPopup={openRedirectStatusReportPopup}
              setOpenPopup={setOpenRedirectStatusReportPopup}
            />


          </FormContainer>
        </ScreenRoot>
      )}
    </>
  );
}

export default PaymentProgressCertificateForApproval;
