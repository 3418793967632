import { FunctionalButton } from "components/functionalButton";
import React, { useState } from "react";
import AwfulFace from "assets/reference/awfulFace";
import { AlertPopup } from "components/alertPopup/alertPopup";
import { useSelector } from "react-redux";
import { contactSelector } from "../../../data/selectors/contact";
import {
  StyledBox,
  StyledBoxContent,
  SmallTitle,
  DataText,
  InfoDiv,
  LargeTitle,
} from "../styles";

import { RowContent, RateBox, RateBoxText, StyledSpan } from "./styles";
import PoorFace from "assets/reference/poorFace";
import AverageFace from "assets/reference/averageFace";
import NiceFace from "assets/reference/niceFace";
import AmazingFace from "assets/reference/amazingFace";

export const ReferenceSummary = ({ isMobile, data, setOpenPopup }) => {
  const total = data?.length || 1;
  let awful = 0;
  let poor = 0;
  let average = 0;
  let great = 0;
  let amazing = 0;

  const [openExceedPopup, setOpenExceedPopup] = useState(false);
  const contactData = useSelector(contactSelector);
  const referenceLimit = contactData?.user_access_control?.number_of_references;

  if (total > 0) {
    data.map((item, i) => {
      const rating = item?.rating;
      if (rating > 8 && rating < 11) {
        amazing++;
      }
      if (rating > 6 && rating < 9) {
        great++;
      }
      if (rating > 4 && rating < 7) {
        average++;
      }
      if (rating > 2 && rating < 5) {
        poor++;
      }
      if (rating > 0 && rating < 3) {
        awful++;
      }
    });
  }

  const handleAdd = () => {
    if (data && data?.length >= referenceLimit) {
      setOpenExceedPopup(true);
    } else {
      setOpenPopup(true);
    }
  };

  return (
    <StyledBox boxHeight="170px" hasData={true}>
      <StyledBoxContent margin="24px 32px" flexDirection="column">
        <RowContent justifyContent="space-between" margin="4px 0 8px 0">
          <LargeTitle fontWeight="600">
          References received <StyledSpan>({data?.length})</StyledSpan>
          </LargeTitle>
          <FunctionalButton
            buttonTitle={"Get a Reference"}
            isReversedColor={true}
            height="32px"
            width="199px"
            marginTop="0px"
            marginRight="0px"
            handleButton={handleAdd}
          />
        </RowContent>
        <RowContent justifyContent="space-between" margin="24px 0 0 0">
          <RateBox color="#FF0000" justifyContent="space-between">
            <RateBoxText>
              <AwfulFace />
            </RateBoxText>
            <RateBoxText fontWeight="700" fontSize="15px">
              {Math.round((awful / total) * 100)}%
            </RateBoxText>
          </RateBox>
          <RateBox color="#FF6D1D" justifyContent="space-between">
            <RateBoxText>
              <PoorFace />
            </RateBoxText>
            <RateBoxText fontWeight="700" fontSize="15px">
              {Math.round((poor / total) * 100)}%
            </RateBoxText>
          </RateBox>
          <RateBox color="#FEC65C" justifyContent="space-between">
            <RateBoxText>
              <AverageFace />
            </RateBoxText>
            <RateBoxText fontWeight="700" fontSize="15px">
              {Math.round((average / total) * 100)}%
            </RateBoxText>
          </RateBox>
          <RateBox color="#8DD655" justifyContent="space-between">
            <RateBoxText>
              <NiceFace />
            </RateBoxText>
            <RateBoxText fontWeight="700" fontSize="15px">
              {Math.round((great / total) * 100)}%
            </RateBoxText>
          </RateBox>
          <RateBox color="#43B868" justifyContent="space-between">
            <RateBoxText>
              <AmazingFace />
            </RateBoxText>
            <RateBoxText fontWeight="700" fontSize="15px">
              {Math.round((amazing / total) * 100)}%
            </RateBoxText>
          </RateBox>
        </RowContent>
        {/* {data?.length > 0 && (
            <SmallTitle margin="24px 0 0 0">Looks like you only have {data?.length} references... Let’s get some more!</SmallTitle>
          )}
          {!(data?.length > 0) && (
            <SmallTitle margin="24px 0 0 0">Looks like you don't have any reference... Let’s get some!</SmallTitle>
          )} */}
      </StyledBoxContent>
      <AlertPopup
        openPopup={openExceedPopup}
        setOpenPopup={setOpenExceedPopup}
        headerText="You have exceeded the reference limit."
        subHeaderText={`You can add a maximum of ${referenceLimit} references.`}
        showButton={true}
      />
    </StyledBox>
  );
};
