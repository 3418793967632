import React from 'react';
import { Text, View, StyleSheet, Image, Link } from '@react-pdf/renderer';
import Active from "pages/underwriters/components/downloadCSR/images/active.png"
import Inactive from "pages/underwriters/components/downloadCSR/images/inactive.png"


const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 10,
    justifyContent: 'flex-end',
    width: '50%',
    flex: 1,
    paddingLeft: 30,
    paddingRight: 30,
  },
  spanText: {
    color: '#2F9B51',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
  },
});

const PageProgressIsSatisfactory = ({ csrData }) => (
  <View style={styles.headerContainer}>
   
      {csrData && csrData?.data?.is_progress_satisfactory === 0 && (
        <>
          <View>
            <Image src={Inactive} style={{ height: 20, width: 150 }} />
          </View>

        </>
      )}

      {csrData && csrData?.data?.is_progress_satisfactory === 1 && (
        <>
          <View>
            <Image src={Active} style={{ height: 20, width: 150 }} />
          </View>

        </>
      )}


  </View>
);

export default PageProgressIsSatisfactory;
