import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { filterProjects } from "../../../api/prequalify";
import { StyledBox, PlaceholderText } from "../styles";
import { IconContainer, SeeAllText } from "./styles";
import { ProjectCard } from "./ProjectCard";
import { FunctionalButton } from "../../../components/functionalButton";
import { AddProjectPopup } from "../../../components/addProjectPopup";
import PlusSign from "../../../assets/onBoarding/plusSign";
import { saveProject, checkProjectsLimit } from "../../../api/dashboard";
import { AlertPopup } from "components/alertPopup/alertPopup";
import { useSelector } from "react-redux";
import { contactSelector } from "../../../data/selectors/contact";

export const TabPanel2 = ({ isMobile }) => {
  const [projectList, setProjectList] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [project, setProject] = useState("");
  const history = useHistory();
  const [openExceedPopup, setOpenExceedPopup] = useState(false);
  const contactData = useSelector(contactSelector);
  const projectsLimit = contactData?.user_access_control?.number_of_projects;

  useEffect(() => {
    handleFilterProject();
  }, []);

  const handleFilterProject = async () => {
    await filterProjects("")
      .then((data) => {
        setProjectList(data);
      })
      .catch((err) => {});
  };

  const handleAdd = () => {
    if (projectList && projectList.length >= projectsLimit) {
      setOpenExceedPopup(true);
    } else {
      setOpenPopup(true);
    }
    // checkProjectsLimit(() => setOpenPopup(true), () => setOpenExceedPopup(true))
  };

  const addProject = (data) => {
    saveProject(data, onSuccess, () => {});
  };

  const onSuccess = () => {
    setProject("");
    handleFilterProject();
  };

  useEffect(() => {
    if (!openPopup) {
      setProject("");
    }
  }, [openPopup]);

  const handleSeeProject = () => {
    history.push("/all/projects");
  };

  return (
    <>
      {!(projectList?.length > 0) && (
        <StyledBox boxHeight="80px" borderRadius="10px">
          <PlaceholderText>Your Projects will appear here</PlaceholderText>
        </StyledBox>
      )}
      {projectList?.length > 0 &&
        projectList.slice(0, 6).map((item, i) => {
          return <ProjectCard {...item} key={i} />;
        })}
      <FunctionalButton
        buttonTitle="Add Project"
        handleButton={handleAdd}
        width="100%"
        marginRight="0px"
        marginTop="8px"
        isReversedColor={true}
        frontIcon={true}
        hasBoxShadow={true}
      />
      {projectList?.length > 0 && (
        <SeeAllText onClick={handleSeeProject}>See All Projects</SeeAllText>
      )}
      <AddProjectPopup
        setOpenPopup={setOpenPopup}
        openPopup={openPopup}
        data={project}
        setData={setProject}
        handleButtonFunc={addProject}
        isMobile={isMobile}
      />
      <AlertPopup
        openPopup={openExceedPopup}
        setOpenPopup={setOpenExceedPopup}
        isMobile={isMobile}
        headerText="You have exceeded the projects limit."
        subHeaderText={`You can add a maximum of ${projectsLimit} projects.`}
        showButton={true}
      />
    </>
  );
};
