import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from '../../constants';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import { StyledTableCell } from '../filesListTable/styles';


export const StyleButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NoItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  font-size: 15px;
  width: 100%;
  margin-top: 16px;
`;

export const OnboardContainer = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isMobile ? "90%" : "100%")};
`;

export const PopupContainer = styled(OnboardContainer)`
  &&{
    margin-top: 0px;
    justify-content: center;
    align-items: center;
  }
`;

export const InfoArea = styled.div`
  margin-top: ${(props) => props.marginTop || "24px"};
  margin-bottom: ${(props) => props.marginBottom || ""};
  display: flex;
  flex-direction: ${props => props.isMobile || props.isColumn ? "column" : "row"};
  justify-content: center;
  align-items: center;
  ${props => props.width && !props.isMobile ? `width: ${props.width};` : "width: 90%"}

  .inputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '50%'};
  }

  .fullWidthInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: 100%;
  }

  .oneFifthInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '20%'};
  }

  .thirtyInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '30%'};
  }
`;

export const ButtonArea = styled(InfoArea)`
  && {
    margin-top: 40px;
    justify-content: center;
  }
`;

export const ImagePopup = styled.div`
  margin-top: 16px;
`;

export const LargeText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.displayFont};
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 16px;
  }
`;

export const MediumText = styled(Typography)`
  && {
    font-family:  ${TYPOGRAPHY.primaryFont};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 16px;
    color: ${COLORS.lightGrey2};
  }
`;

export const SmallText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.secondaryFont};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 16px;
  }
`;

export const StyledQuestion = styled(Typography)`
  
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-style: normal;
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
    color: black;
    margin-bottom: 32px;
    text-align: center;
  }
`;

export const TextContainer = styled(StyledQuestion)`
  && {
    margin-top: 36px;
    width: ${props => props.isMobile? "100%" : "80%"};
  }
`;

export const StyledCardTypography = styled(Typography)`
  && {
    font-size: 18px;
    font-family: ${TYPOGRAPHY.primaryFont};
    font-weight: 500;
    margin-top: 24px;
    color: #888888;

  }
`;

export const StyledCard = styled(Card)`
  && {
      width: 308px;
      height: 248px;
      margin-left: 12px;
      margin-right: 12px;
      box-shadow: none;
      background: ${props => props.isChecked ? COLORS.lighOrange : "white"};
      border: 1px solid ${props => props.isChecked ? COLORS.primaryOrange : "#ECEBED"};
      box-sizing: border-box;
      border-radius: 10px;
  }
`;

export const StyledCardContent = styled(CardContent)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 16px;
  }
`;

export const StyledCardActionArea = styled(CardActionArea)`
  && {
    // height: 64px;
  }
`;

export const StyledCardContainer = styled.div`
  margin-top: 16px;
`;

export const StyledFlag = styled.img`

`;

export const StyleList = styled.div`
  width: 100%;
  margin-top: 24px;
  position: fixed;
`;

export const StyledSkipText = styled.div`
  color: ${COLORS.primaryOrange};
  cursor: pointer;
  font-family: ${TYPOGRAPHY.primaryFont} ;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin-top: 18px;
`;

export const StyledFileDiplay = styled.div`
  width: 100%;
  margin-top: 40px;
`;


export const StyledInput = styled.input`
  && {
    display: flex;
    justify-content: right;
    width: ${props => props.width || '250'}px;
    height: 48px;
    font-size: 18px;
    box-sizing: border-box;
    border-radius: 4px;
    color: #1a051d;
    border: 1px solid ${props => props.borderColor || '#cccccc'};
    text-indent: 14px;
  }
  &&.form input[type="text"] {
    box-sizing: border-box;
    padding-left: 50px;
  }
  :focus {
    outline: none !important;
    border: 1px solid #FF6D1D;
  }

`;

export const StyledInputLabel = styled.label`
  font-size: 12px;
  text-align: left;
  margin-top: 16px;
`;

export const StyledAddressContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  gap: 16px;
`;

export const StyledInputLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${props => props.marginRight || ''}px;
`;



////// new redesign

export const StyledConciseContainer = styled.div`
  && {
    margin: 16px 0;
    width: 100%;
  }
`;

export const StyledTableCellFirst = styled(StyledTableCell)`
  width: 50%;
`;

export const StyledRow = styled.div`
  width: 100%;
  text-align: center;
  // margin-right: 16px;
  ${props => props.marginTop ? `margin-top: ${props.marginTop};` : ''}
  display: flex;
  flex-direction: ${props => props.isMobile || props.isColumn ? "column" : "row"};
`;

export const StyledCenterRow = styled(StyledRow)`
  display: flex;
  justify-content: center;
`;

export const StyledOrangeHighlight = styled.span`
  font-family: ${TYPOGRAPHY.displayFont};
  font-weight: ${props => props.weight? props.weight : "600"};
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  color: ${COLORS.primaryOrange};
`;

export const StyledCenter = styled.div`
  display: flex;
  justify-content:center;
  margin-top: 16px;
`;

export const StyledPicklistSmallHeaderContainer = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  text-align: left;
`;
export const StyledPicklistSmallHeader = styled.div`
  font-family: SF Pro Text;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 8px;
  letter-spacing: 0px;
  text-align: left;
`;

export const StyledBox = styled.div`
  width: ${props => props.boxWidth || "100%"};
  border-radius: ${props => props.borderRadius || `10px`}; 
  align-self: flex-start;
  border: 1px solid #EBE9F1 ;
  height: ${props => props.boxHeight || "60px"};
  
  display: flex;
  flex-direction: ${props => props.flexDirection || "column"};
  align-items: center;
  ${props => props.margin ?  `margin: ${props.margin};` : ""}
  justify-content: center;
  background-color: ${props => props.readOnly ? COLORS.backgroundGrey : 'white' };
`;

export const PlaceholderText = styled(Typography)`
  &&{
    font-family:${TYPOGRAPHY.primaryFont};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: ${COLORS.textGrey};
  }
`;

export const ProjectCardContainer = styled.div`
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
`;