import React, { useState, useEffect } from "react";
import Papa from "papaparse";



import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";


import { ProjectsInputBlock } from "pages/preQualifyForm/styles";
import { FileButton } from "pages/preQualifyForm/components/fileInputButton/styles";




export const MainPanel = ({
  steps,
  setPanel,
  setParsed,
  setFields,
}) => {
  const [csvFile, setCsvFile] = useState(null);

  useEffect(() => {
    if (csvFile) {
      Papa.parse(csvFile, {
        header: true,
        complete: (res) => {
          res.errors.map((item) => res.data.splice(item.row, 1));
          setParsed(res.data);
          setFields(res.meta.fields);
        },
      });
    };
  }, [csvFile]);

  return (
    <>
      {/* <FormTi>Your Key Projects</FormTitle> */}
      <Stepper activeStep={0} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <ProjectsInputBlock>
        <FileButton
          name="Upload from file"
          inverse
          withoutFileName
          onChange={(e) => {
            if (e.target.files[0].type === "text/plain") {
              setCsvFile(e.target.files[0]);
              setPanel("match");
            }
          }}
        />
        <span
          style={{
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "12px",
            lineHeight: "20px",
            color: "#000000",
          }}
        >
          You can upload any .csv file as long as there is 1 record per row.
          The next steps will help you match your spreadsheet columns to the
          right data points. Edit or clean up any errors before finalizing
          your import.
        </span>
      </ProjectsInputBlock>

      <span
        style={{
          display: "block",
          cursor: "pointer",
          color: "#ff6d1d",
          textTransform: "uppercase",
          marginTop: 50,
        }}
        onClick={() => setPanel("add")}
      >
        {'OR manually fill in your data >'}
      </span>
    </>
  );
};
