import styled from "styled-components";

export const CardRoot = styled.div`
  width: 370px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

export const Categories = styled.div`
  margin-top: 5px;
  display: flex;
`;

export const Category = styled.div`
  height: 18px;
  border-radius: 5px;
  padding: 0 5px;
  margin-right: 10px;

  font-weight: normal;
  font-size: 11px;
  line-height: 17px;
  text-transform: capitalize;

  ${(props) =>
    props.color === "green" &&
    "color: #02814e; background: rgba(2, 129, 78, 0.25);"}
  ${(props) =>
    props.color === "yellow" &&
    "color: #FF6D1D; background: rgba(255, 109, 29, 0.25);"}
  ${(props) =>
    props.color === "purple" &&
    "color: #435EAB; background: rgba(67, 94, 171, 0.25);"}
`;

export const CardTitle = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  width: ${(props) => (props.small ? "200px" : "350px")};

  margin-top: 20px;

  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: ${(props) => (props.small ? "17px" : "21px")};

  color: #000000;
`;

export const LinkContainer = styled.div`
  margin-top: 17px;

  display: flex;
  flex-direction: row;

  align-items: center;
`;

export const Link = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;

  color: #b5b5b5;
`;

export const Minutes = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;

  color: #b5b5b5;
`;

export const Image = styled.img`
  margin-top: 15px;

  width: ${(props) => (props.small ? "130px" : "350px")};
  height: ${(props) => (props.small ? "88px" : "230px")};
`;
