import React, { useState, useEffect } from "react";
import { downloadZip } from "client-zip/index.js";
import FileSaver from "file-saver";
import {
  getCCDCs,
  ccdcFormatRemaining,
  pledgxFormatRemaining,
} from "../../../../api/prequalify";
import SystemUpdateAltRoundedIcon from "@material-ui/icons/SystemUpdateAltRounded";
import { AddFinalizePopUp } from "../../../../components/addFinalizePopUp";
import { AddDownloadPopUp } from "../../../../components/addDownloadPopUp";
import { StyledQuestion } from "../styles";
import { Router, Switch, Route } from "react-router-dom";

import {
  StyledFilePreviewContainer,
  StyledTitle,
  StyledTitleBold,
  FormContainer,
  StyledPdfViewerContainer,
  StyledButtonContainer,
  StyledDownloadSelection,
  StyledTitle2,
} from "./styles";

import { PdfViewer } from "components/pdfViewer";
import { UploadedFileDisplay } from "pages/preQualifyForm/components/uploadedFileDisplay";
import { AlertPopup } from "components/alertPopup/alertPopup";
import { useDispatch, useSelector } from "react-redux";
import { contactSelector } from "../../../../data/selectors/contact";
import { FunctionalButton } from "components/functionalButton";
import SubmissionPreview from "../../../../pages/submissionPreview";
import FileType from "../additionalDocuments/fileType";
import {
  ContractorCcdcListSelector,
  institutionProfileInfoSelector,
} from "data/selectors/serviceProvider";
import { getContractorCcdcsList } from "data/actions/serviceProvider";

export const ReviewForm = ({
  setIsLastPanel,
  setPreviousStepPanel,
  pdfFileSequence,
  setPdfFileSequence,
  fileType,
  action,
  setAction,
  setFileType,
  setPrequalifyData,
  updateCachedData,
  handleNext,
  prequalifyData,
  insuranceTypeList,
  institutionId,
}) => {
  const [selectedFile, setSelectedFile] = useState(0);
  const [openExceedPopup, setOpenExceedPopup] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDowloadPopup, setOpenDownloadPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const currentDashboardUrl = localStorage.getItem("current_dashboard_url");
  let contactData = useSelector(contactSelector);
  const institutionContactData = useSelector(institutionProfileInfoSelector);

  if (currentDashboardUrl === "/service-provider-dashboard") {
    contactData = institutionContactData;
  }

  const [selectedFileTitle, setSelectedFileTitle] = useState(
    "Your Qualifications Showcase"
  );
  const ccdcLimit =
    contactData?.user_access_control?.format_remaining?.ccdc_format;
  const pledgxLimit =
    contactData?.user_access_control?.format_remaining?.pledgx_format;
  const [data, setData] = useState([]);
  const checkCcdcId = (obj) =>
    (obj.status === "Reviewed" ||
      obj.status === "Downloaded" ||
      obj.status === "Submitted") &&
    prequalifyData?.ccdcId === obj.ccdcId;
  const checkCcdcIdValue = data ? data.some(checkCcdcId) : "";
  const pdfDownloadCounter = { pledgxLimit: pledgxLimit, ccdcLimit: ccdcLimit };
  const contractorCcdcList = useSelector(ContractorCcdcListSelector);

  const submitStatus = (obj) =>
    obj.status === "Submitted" && prequalifyData?.ccdcId === obj.ccdcId;
  const checkSubmitStatus = data ? data.some(submitStatus) : "";
  const [downloadButtonClickedCounter, setDownloadButtonClickedCounter] =
    useState(0);

  setIsLastPanel(true);
  setPreviousStepPanel(1);

  const ccdcList = async () => {
    if (
      currentDashboardUrl === "/service-provider-dashboard" &&
      institutionId
    ) {
      dispatch(
        getContractorCcdcsList(
          { institution_id: institutionId },
          () => {},
          () => {}
        )
      );
    } else {
      await getCCDCs()
        .then((data) => {
          setData(data);
        })
        .catch((err) => {});
    }
  };
  useEffect(() => {
    if (contractorCcdcList?.length > 0) {
      setData(contractorCcdcList);
    }
  }, [contractorCcdcList]);

  useEffect(() => {
    ccdcList();
  }, []);

  const downloadReport = async () => {
    if (checkCcdcIdValue === true) {
      const content = await downloadZip(pdfFileSequence).blob();
      FileSaver.saveAs(content, `qualify_${Date.now()}.zip`);
    }
  };

  const onSuccessDownload = (res) => {
    if (res?.status === "success") {
      handleDownloaded();
      handleClose();
    }
  };

  const handleDownloadCCDC = (id) => {
    if (fileType === "ccdc-11") {
      ccdcFormatRemaining(
        id,
        { institution_id: institutionId },
        onSuccessDownload,
        () => {}
      );
    } else if (fileType === "generic") {
      pledgxFormatRemaining(
        id,
        { institution_id: institutionId },
        onSuccessDownload,
        () => {}
      );
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloaded = () => {
    var newData = data;
    for (var projectData of newData) {
      if (projectData.ccdcId === prequalifyData.ccdcId) {
        projectData.status = "Downloaded";
      }
    }
    setData(newData);
    setOpenPopup(false);
  };

  // add handle Finalize Button
  const handleFinalizeButtonClick = () => {
    if (fileType === "ccdc-11" && ccdcLimit > 0) {
      setOpenPopup(true);
      handleClose();
    } else if (fileType === "generic" && pledgxLimit > 0) {
      setOpenPopup(true);
      handleClose();
    }
  };

  // add handle Download Button
  const handleDownloadButtonClick = () => {
    if (pledgxLimit > 0 && fileType === "generic") {
      if (downloadButtonClickedCounter === 0) {
        handleDownloadCCDC(prequalifyData?.ccdcId);
        setDownloadButtonClickedCounter(downloadButtonClickedCounter + 1);
      }
      setOpenDownloadPopup(true);
      handleClose();
    } else if (ccdcLimit > 0 && fileType === "ccdc-11") {
      if (downloadButtonClickedCounter === 0) {
        handleDownloadCCDC(prequalifyData?.ccdcId);
        setDownloadButtonClickedCounter(downloadButtonClickedCounter + 1);
      }
      setOpenDownloadPopup(true);
      handleClose();
    } else {
      setOpenExceedPopup(true);
    }
  };

  const handleClickFile = (i) => {
    setSelectedFile(i);
  };

  const icon = <SystemUpdateAltRoundedIcon style={{ marginLeft: 15 }} />;

  return (
    <Switch>
      <Route exact path="/prequalify/preview/ecqs">
        <SubmissionPreview
          setIsLastPanel={setIsLastPanel}
          handleNext={handleNext}
          prequalifyData={prequalifyData}
          setPrequalifyData={setPrequalifyData}
          checkCcdcIdValue={checkCcdcIdValue}
          checkSubmitStatus={checkSubmitStatus}
          ccdcData={data}
          setCcdcData={setData}
          insuranceTypeList={insuranceTypeList}
          institutionId={institutionId}
          currentDashboardUrl={currentDashboardUrl}
        />
      </Route>
      <Route exact path="/prequalify/preview/format">
        <FileType
          action={action}
          setAction={setAction}
          prequalifyData={prequalifyData}
          handleNext={handleNext}
          setIsLastPanel={setIsLastPanel}
          fileType={fileType}
          setFileType={setFileType}
          setPrequalifyData={updateCachedData}
          setPdfFileSequence={setPdfFileSequence}
          handleFinalizeButtonClick={handleFinalizeButtonClick}
          pdfDownloadCounter={pdfDownloadCounter}
        />
      </Route>

      <Route exact path="/prequalify/preview/download">
        <FormContainer>
          <StyledQuestion margin-bottom="16px">
            Congrats - Here are your Documents!
          </StyledQuestion>

          <StyledFilePreviewContainer>
            <StyledDownloadSelection>
              <StyledTitleBold>Click</StyledTitleBold>
              <StyledTitle> a file to preview(Only .pdf files)</StyledTitle>
              {pdfFileSequence.length > 0 &&
                pdfFileSequence.map((item, i) => {
                  return (
                    <UploadedFileDisplay
                      key={`file-${i}`}
                      file={item}
                      displayPercentage="90%"
                      marginTop={"8px"}
                      noActions={true}
                      index={i}
                      handleClickRow={() => handleClickFile(i)}
                    />
                  );
                })}
            </StyledDownloadSelection>

            <StyledPdfViewerContainer>
              <rowContainer>
                <StyledButtonContainer>
                  <StyledTitle2>{selectedFileTitle}</StyledTitle2>

                  <FunctionalButton
                    buttonTitle="Download"
                    handleButton={handleDownloadButtonClick}
                    width="150px"
                    marginRight="0px"
                    marginTop="0px"
                    marginBottom="5px"
                  />
                  <AddDownloadPopUp
                    setOpenDialog={(e) => setOpenDownloadPopup(e)}
                    openDialog={openDowloadPopup}
                    prequalifyData={prequalifyData}
                    downloadFile={downloadReport}
                    data={data}
                  />
                </StyledButtonContainer>
              </rowContainer>

              <PdfViewer pdfFile={pdfFileSequence[selectedFile]} />
            </StyledPdfViewerContainer>
          </StyledFilePreviewContainer>

          <AlertPopup
            openPopup={openExceedPopup}
            setOpenPopup={setOpenExceedPopup}
            headerText={`You have exceeded the limit.`}
            subHeaderText={`You have only 0 CQS. Please upgrade your package to increase your limit.`}
          />
        </FormContainer>
      </Route>
    </Switch>
  );
};

export default ReviewForm;
