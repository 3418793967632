import React, { useState, useEffect } from "react";
import {
  Row,
  StyledLargeTitle,
  StyledMediumTitle,
  StyledOrangeText,
} from "../../styles";
import Input from "../../../../components/inputField";
import { FunctionalButton } from '../../../../components/functionalButton';
import ConciseTable from "../../../../components/filesListTable/conciseTable";
import { StyledTableCell } from '../../../../components/filesListTable/styles';
import { NextArrowButton } from '../nextArrowButton';
import { searchProject } from '../../../../api/prequalify';
import { Popup } from '../../../../components/popup';
import { SearchPopupContent } from './SearchPopup';

import {
  StyledButtonContainer,
  StyledListContainer,
  StyledTableCellFirst,
} from './styles';
import { TimeoutPopup } from "components/timeoutPopup";

export const SearchProject = ({
  setActiveStep,
  setListPanel,
  setIsLastPanel,
  setQualificationData,
  qualificationData
}) => {
  const [title, setTitle] = useState('')
  const [number, setNumber] = useState('')
  const [location, setLocation] = useState('')
  const [data, setData] = useState('')
  const [openPopup, setOpenPopup] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [disabledSelect, setDisabledSelect] = useState(true)
  const [openTimeoutPopup, setOpenTimeoutPopup] = useState(false);

  setIsLastPanel(true)

  const createHeader = (title, align) => {
    return {
      title,
      align: align || "left",
    };
  };

  const allHeaders = [
    createHeader("Project Title"),
    createHeader("Number"),
    createHeader("Location"),
    createHeader(""),
  ];

  const handleSearch = () => {
    if(title || number || location ) {
    searchProject(title, number, location, onSuccess, onFailure);
  }
};

  const onSuccess = (data) => {
    setData(data);
    data.search_result.length == 0? setOpenPopup(true) : setOpenPopup(false);
  }
  const onFailure = (error) => {
    if (error.status == 401) {
      setOpenTimeoutPopup(true);
    }
  }

  const handlePickProject = (props) => {
    const project_info = {
      ... qualificationData.project_info,
      project_title: props?.project_title,
      project_number: props?.project_number,
      project_city: props?.project_location,
      owner_name: props?.owner_name,
      owner_email: props?.owner_email,
      owner_phone: props?.owner_phone,
      owner_suite: props?.owner_suite,
      owner_postal_code: props?.owner_postal_code,
      owner_province: props?.owner_province,
      owner_city: props?.owner_city,
      categories:  props?.categories
    }
    setQualificationData('project_info', project_info);
    setDisabledSelect(false);
  };

  const actions = (props) => {
    return (
      <>
      <StyledTableCell component={'tr'}  align="center">
        <NextArrowButton />
      </StyledTableCell>
    </>
    )
  }

  const body = (bodyData) => { return (
      <>
        <StyledTableCellFirst component={'tr'}>
          {bodyData.project_title}
        </StyledTableCellFirst>
        <StyledTableCell component={'tr'} scope="row">
          {bodyData.project_number}
        </StyledTableCell>
        <StyledTableCell component={'tr'} scope="row">
          {bodyData.project_location}
        </StyledTableCell>
      </>
    );
  };

  const handleCreateProject = () => {
    setQualificationData('project_info.project_title', '');
    setQualificationData('project_info.project_number', '');
    setQualificationData('project_info.project_city', '');
    // setQualificationData('project_info.owner_name', '');
    // setQualificationData('project_info.owner_email', '');
    // setQualificationData('project_info.owner_city', '');
    setQualificationData('project_info.categories.main_categories', '');
    setQualificationData('project_info.categories.main_categories', '');
    setActiveStep(0);
    setListPanel(1);
  };


  useEffect(() => {
    if(title?.length > 0 || number?.length > 0 || location?.length > 0)
      setDisabled(false);
    else if (title?.length == 0 && number?.length == 0 && location?.length == 0)
      setDisabled(true);
  }, [title, number, location]);

  return (
    <>
      <StyledLargeTitle>Project Selection</StyledLargeTitle>
      <StyledMediumTitle>Which project are you qualifying for?</StyledMediumTitle>
      <Row>
        <Input
          style={{ paddingRight: "16px", width: "60%" }}
          label="Project title"
          type="text"
          placeholder="Title"
          value={title}
          setValue={setTitle}
          validateFunc={(item) => true}
        />
        <Input
          style={{ paddingRight: "16px", width: "40%" }}
          label="Project number"
          type="text"
          placeholder="Number"
          value={number}
          setValue={setNumber}
          validateFunc={(item) => true}
        ></Input>
      </Row>
      <Row alignItems="flex-end">
        <Input
          style={{ paddingRight: "16px", width: "60%" }}
          label="City"
          type="text"
          placeholder="City name"
          value={location}
          setValue={setLocation}
          validateFunc={(item) => true}
        ></Input>
        <StyledButtonContainer>
          <FunctionalButton
            buttonTitle="Search"
            width="450px"
            height="50px"
            handleButton={handleSearch}
            disabled={disabled}
          />
        </StyledButtonContainer>
      </Row>
      { data && (
        <>
        <Popup
          open={openPopup}
          setOpen={setOpenPopup}
          width="432px"
          height="343px"
          dialogContent={(<SearchPopupContent handleYes={handleCreateProject} setOpenPopup={setOpenPopup}/>)}
        />
        { data.search_result.length > 0 && (
          <>
            <StyledListContainer>
            <ConciseTable
                length={7}
                headers={allHeaders}
                data={data.search_result}
                body={body}
                actionType="project"
                actions={actions}
                handleSelected={handlePickProject}
                hover={true}
            />
          </StyledListContainer>
         </>
        )}
       </>
      )}
      <Row>
        <StyledOrangeText
          onClick={handleCreateProject}
        >
          manually fill in your Project
        </StyledOrangeText>
      </Row>
      <TimeoutPopup openPopup={openTimeoutPopup} setOpenPopup={setOpenTimeoutPopup}/>
    </>
  )
};
