// import React, { useState } from "react";
// import { useDispatch } from "react-redux";
// import { useMediaQuery } from "react-responsive";
// import { FunctionalButton } from "components/functionalButton";
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import ListItemText from '@mui/material/ListItemText';
// import Select from '@mui/material/Select';
// import Checkbox from '@mui/material/Checkbox';

// import {
//   StyledQuestion,
//   InfoArea,
//   StyledAddressContainer,
//   // StyledSkipText,
//   // TextContainer
//  } from './styles';
 
//  import Input from '../../../../components/inputField';
// export const Requirements = ({
//   handleNext,
//   data,
//   setData,
//   panel,
//   setPanel,
//   setIsLastPanel,
//   setPreviousStepPanel
// }) => {
//   const isMobile = useMediaQuery({ maxWidth: 960 });
//   setIsLastPanel(true);

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// const names = [
//   'Oliver Hansen',
//   'Van Henry',
//   'April Tucker',
//   'Ralph Hubbard',
//   'Omar Alexander',
//   'Carlos Abbott',
//   'Miriam Wagner',
//   'Bradley Wilkerson',
//   'Virginia Andrews',
//   'Kelly Snyder',
// ];

//   const [personName, setPersonName] = React.useState([]);

//   const handleChange = (event) => {
//     const {
//       target: { value },
//     } = event;
//     setPersonName(
//       // On autofill we get a stringified value.
//       typeof value === 'string' ? value.split(',') : value,
//     );
 

//   return (
//     <>
//       <StyledQuestion isMobile={isMobile} width="650px">What are the mandetory requirements?</StyledQuestion>
//       <InfoArea isMobile={isMobile} width="650px" isColumn={true} marginTop="0px">
//         <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
//         <Select
//           labelId="demo-multiple-checkbox-label"
//           id="demo-multiple-checkbox"
//           multiple
//           value={personName}
//           onChange={handleChange}
//           input={<OutlinedInput label="Tag" />}
//           renderValue={(selected) => selected.join(', ')}
//           MenuProps={MenuProps}
//         >
//           {names.map((name) => (
//             <MenuItem key={name} value={name}>
//               <Checkbox checked={personName.indexOf(name) > -1} />
//               <ListItemText primary={name} />
//             </MenuItem>
//           ))}
//         </Select>
//       </InfoArea>
//       <FunctionalButton
//           buttonTitle="Next"
//           handleButton={() => handleNext()}
//           width="200px"
//           marginRight="0px"
//           marginTop="48px"
//         />
//     </>
//   );
// }
// }
import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { FunctionalButton } from "components/functionalButton";
import { useMediaQuery } from "react-responsive";


import {
  StyledQuestion,
  InfoArea,
  StyledAddressContainer,
 } from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const names = [
  "Submission Form",
  "WSIB Certificate or Equivalent",
  "Statement of Insurability",
  "Surety’s Letter of Pre-Qualification",
  "Letter of Bondability",
  "COR Safety Program Certificate",
  "Contractor Qualification Statement",
  "Health & Safety Policy and Procedure",
  "Acknowledgement of Reciept"
];

export const MultipleSelectCheckmarks = ({
    handleNext,
    data,
    setData,
    panel,
    setPanel,
    setIsLastPanel,
    setPreviousStepPanel
  }) => {
  const [personName, setPersonName] = React.useState([]);
  const isMobile = useMediaQuery({ maxWidth: 960 });

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
     <StyledQuestion isMobile={isMobile} width="650px">Select your mandatory requirements</StyledQuestion>
      <InfoArea>
        <FormControl sx={{ m: 1, width: 420, bgcolor: '#FFFFFF', borderColor: '#FF6D1D' }}>
          <InputLabel id="demo-multiple-checkbox-label">Mandatory Requirements</InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={personName}
            onChange={handleChange}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {names.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={personName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
            
          </Select>
        </FormControl>
      </InfoArea>
        <FunctionalButton
          buttonTitle="Next"
          handleButton={() => handleNext()}
          width="200px"
          marginRight="0px"
          marginTop="48px"
        />
    </>
  );
}
