import React, { useState, useEffect, useRef } from 'react';

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper,
  StyledMenuItem,
  StyledButton,
  StyledButtonDownload,
  StyledButtonContainer
} from './styles';

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import PledgxLogo from '../../assets/common/pledgxLogo';


export const AddDocsPendingIDPPopUp = ({
  setOpenDialog,
  openDialog,
  isMobile
}) => {

  useEffect(() => {
    if (!openDialog) {
      setOpenDialog(false);
    }
  }, [openDialog]);

  

  const handleClose = () => {
    setOpenDialog(false);
  };

  // add handle Go Back Button
  const handleGoBackClick = () => {
    handleClose();
  };


  // add handle Docs Pending IDP Button
  const handleDocsPendingIDPClick = () => {

    // IDP link goes here
    
       
  };

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper
        elevation={0}
        isMobile={isMobile}
      >
        <CloseButton
          aria-label="close"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </CloseButton>

        <PledgxLogo width={isMobile ? 45 : ''} height={isMobile ? 45 : ''} />
        <InfoArea isMobile={isMobile} marginTop="0px">
          <FormTitle style={{ marginTop: 20, marginBottom: 20 }}>{'Would you like to go to the IDP?'}</FormTitle>
        </InfoArea>

        <StyledButtonContainer>
          <StyledMenuItem>
            <StyledButton title="Go Back" width="150px" onClick={handleGoBackClick}
            > Go Back
            </StyledButton>
          </StyledMenuItem>

          <StyledMenuItem>
            <StyledButton title="Go to the IDP" width="150px" onClick={handleDocsPendingIDPClick} disabled
            > Go to the IDP
            </StyledButton>
          </StyledMenuItem>

        </StyledButtonContainer>
      </StyledPaper>
    </Dialog>
  )
}