import styled from 'styled-components';
import { StyledSubContainer } from '../styles';


export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const StyledButtonArea = styled(StyledSubContainer)`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;
