import React, { useState } from "react";

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper,
  StyledAddressContainer
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Input from "../inputField";
import PledgxLogo from "../../assets/common/pledgxLogo";
import { FunctionalButton } from "../functionalButton";
import updateObjectByPath from "../../common/updateObjectByPath";
import { AddressAutoFields } from "../../pages/contractorDashboard/profileSetup/AddressAutoFields";
import { addUnion } from "../../data/actions/dataMaintenance";
import { useDispatch } from "react-redux";
import countriesInArray from "../../assets/locale/countriesInArray";
import Select from "../../components/selectField";
import { formatPhoneNumber } from "../../common/formatPhoneNumber";
import { phoneRegex } from "../../common/regex";
import { SuccessPopup } from "../../pages/adminToolsDashboard/SuccessPopup";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

export const AddUnionPopup = ({
  setOpenDialog,
  openDialog,
  isMobile,
  label,
  buttonLabel
}) => {

  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState(data?.phone);

  const handlePhoneNumberChange = (value) => {
    setData({ ...data, phone: value });
  };



  const handleSave = () => {
    dispatch(
      addUnion(
        data,
        () => {
          setOpenSuccessPopup(true);
          setData({});
        },
        () => { }
      )
    );
  };

  const handleClose = () => {
    setOpenDialog(false);
  };


  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };



  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper elevation={0} isMobile={isMobile}>
        <CloseButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
        <FormTitle style={{ marginTop: 10 }}>
          {label || "Add Union"}
        </FormTitle>
        <InfoArea isMobile={isMobile} marginTop="0px">
          <Input
            id="inputWrap"
            label="Name"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.name}
            setValue={(val) => onChangeData("name", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
          />

          <Input
            id="inputWrap"
            label="Union Type"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.union_type}
            setValue={(val) => onChangeData("union_type", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
          />

          <AddressAutoFields data={data} setData={onChangeData} />

          <StyledAddressContainer>
            <Input
              id="fullWidthInputWrap"
              label="Website"
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={data?.website}
              setValue={(val) => onChangeData("website", val)}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              style={{marginRight: "-265px"}}
            />

            <div style={{ marginTop: "24px", width: "100%", marginLeft: '250px', marginRight: '16px' }}>
              <label htmlFor="phone" style={{ fontSize: "12px" }}>
                Phone Number
              </label>
              <div style={{ height: "48px", width: "100%", marginTop: "-10px" }}>
                <PhoneInput
                  id="phone"
                  country={"ca"}
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  style={{ marginTop: "16px", width: "100%", height: "100%" }}
                  inputStyle={{
                    fontSize: "18px",
                    padding: "8px",
                    lineHeight: "24px",
                    marginLeft: "20px",
                    paddingLeft: "28px",
                    height: "100%",
                    width: "98%",
                    marginRight: "20px",
                  }}
                  dropdownStyle={{ fontSize: "18px", lineHeight: "24px" }}
                />
              </div>
            </div>

            <div className="oneThirdInputWrap">
              <Select
                style={{ marginRight: "0px", width: "150px"}}
                label="Country"
                options={countriesInArray.map((country) => ({
                  value: country.countryName,
                  label: country.countryName,
                }))}
                value={data?.country}
                setValue={(val) => onChangeData("country", val)}
                validateFunc={(item) => Boolean(item)}
                noCheckIcon={true}
                showDropdownIndicator={true}
              />
            </div>

          </StyledAddressContainer>

        </InfoArea>

        <FunctionalButton
          width="30%"
          marginTop="16px"
          marginRight="10px"
          handleButton={() => {
            handleSave();
          }}
          buttonTitle={buttonLabel || "SAVE"}
        />
        <SuccessPopup
          setOpenPopup={setOpenSuccessPopup}
          openPopup={openSuccessPopup}
          isMobile={isMobile}
        />
      </StyledPaper>
    </Dialog>
  );
};
