import React from 'react';
import { useState } from 'react';
import { RoundedCheckbox } from "./roundedCheckbox";
import FormControlLabel from '@mui/material/FormControlLabel';

import {
  StyledSlider,
  StyledHeader,
  StyledHeader2,
  StyledContainer,
  HeaderArea,
  StyledSliderDisabled
} from './styles';

export const RatingSlider = ({ value, header, setValue }) => {
  let marks = [];
  let i = 1;
  const [isSliderEnabled, setIsSliderEnabled] = useState(true);

  while (i <= 10) {
    marks.push({
      value: i,
      label: String(i),
    });
    i += 1
  }

  const handleOnChange = (e, val) => {
    setValue(val);
  };

  const handleOnChangeCheckBox = (e, val) => {
    if (val === true) {
      setIsSliderEnabled(false);
      handleOnChange(e,val);
    } else {
      setIsSliderEnabled(true);
    }       
  }

  return (
    <StyledContainer>
      <StyledHeader>{header}</StyledHeader>
      <StyledHeader2>N/A</StyledHeader2>
      <HeaderArea>
        {(isSliderEnabled === true) && (
          <>
            <StyledSlider
              defaultValue={value}
              value={value}
              onChange={handleOnChange}
              marks={marks}
              step={1}
              min={1}
              max={10}
            />
          </>
        )}

        {(isSliderEnabled === false) && (
          <>
            <StyledSliderDisabled
              defaultValue={value}
              value={value}
              onChange={handleOnChange}
              marks={marks}
              step={1}
              min={1}
              max={10}
              disabled={!isSliderEnabled}
            />
          </>
        )}
        <FormControlLabel
          control={<RoundedCheckbox
            value={1}
            onChange={handleOnChangeCheckBox}
          />}
        />
      </HeaderArea>
    </StyledContainer>
  );
};

