import React, { useState } from "react";
import { InfoArea } from "./styles";
import { StyledRow } from "./styles";
import { CommonBusinessType } from "components/commonBusinessType/index.js";
import RadioGroupButtonForProjectCategory from "components/radioGroupButtonForProjectCategory";

export const Step5 = ({
  setDisable,
  addData,
  setAddData,
  isMobile
}) => {
  const [containsUNSPC, setContainsUNSPC] = useState(addData?.is_unspc_category_type || false);

  const confirmRadioChange = (val) => {
    if (val === 1) {
      setContainsUNSPC(false);
      setAddData("is_unspc_category_type", false);
    } else {
      setContainsUNSPC(true);
      setAddData("is_unspc_category_type", true);
    }
  };


  return (
    <InfoArea isMobile={isMobile} marginTop="0px" marginLeft="8px" isColumn={true} >
      <RadioGroupButtonForProjectCategory
        containsUNSPC={containsUNSPC}
        confirmRadioChange={confirmRadioChange}
      />
      <StyledRow isMobile={isMobile}>
        <CommonBusinessType
          data={addData}
          setData={setAddData}
          isMobile={isMobile}
          notPopup={true}
          containsUNSPC={containsUNSPC}
        />
      </StyledRow>
    </InfoArea>

  )
}