import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import { COLORS, TYPOGRAPHY } from "../../constants";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

export const StyledPaper = styled(Paper)`
  && {
    padding: 15px;
    width: ${(props) => (props.isMobile ? "100%" : "680px")};
    height: 320px;
    align-self: center;
    overflow: hidden;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`;

export const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

export const InfoArea = styled.div`
  font-family: ${TYPOGRAPHY.secondaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin: 40px;
  color: ${COLORS.black};
  text-align: center;
  overflow: hidden;
`;

export const StyledMenuItem = styled(MenuItem)`
  &&.MuiListItem-gutters {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const StyledButton = styled(Button)`
  && {
    width: ${(props) => (props.width ? props.width : "100px")};
    height: 48px;
    background-color: white;
    border: 1px solid ${COLORS.primaryOrange};
    box-sizing: border-box;
    border-radius: 6px;
    color: ${COLORS.primaryOrange};
    font-family:${TYPOGRAPHY.primaryFont};
    font-size: 17px;
    font-weight: 600;
    transition: all 0.5s ease;
    text-transform: uppercase;

    &:hover {
      background-color: ${COLORS.primaryOrange};
      color: white;
    }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledButtonReview = styled(Button)`
  && {
    width: ${(props) => (props.width ? props.width : "100px")};
    height: 48px;
    background-color: #FF6D1D;
    border: 1px solid ${COLORS.primaryOrange};
    box-sizing: border-box;
    border-radius: 6px;
    color: white;
    font-family:${TYPOGRAPHY.primaryFont};
    font-size: 17px;
    font-weight: 600;
    transition: all 0.5s ease;
    text-transform: uppercase;

    &:hover {
      background-color: ${COLORS.primaryOrange};
      color: white;
    }
`;
