import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { FunctionalButton } from "components/functionalButton";

import {
  StyledQuestion,
  InfoArea,
  StyledAddressContainer,
  // StyledSkipText,
  // TextContainer
 } from './styles';
 
import { CommonCategories } from 'components/commonCategories';

export const Categories = ({
  handleNext,
  data,
  setData,
  panel,
  setPanel,
  setIsLastPanel,
  setPreviousStepPanel
}) => {
  const isMobile = useMediaQuery({ maxWidth: 960 });
  setIsLastPanel(true);
  // console.log(data);
  return (
    <>
      <StyledQuestion isMobile={isMobile} width="650px">What category is your project in?</StyledQuestion>
      <InfoArea isMobile={isMobile} width="650px" isColumn={true} marginTop="0px">
        <CommonCategories data={data}  setData={setData}/>
      </InfoArea>
      <FunctionalButton
          buttonTitle="Next"
          handleButton={() => handleNext()}
          width="200px"
          marginRight="0px"
          marginTop="48px"
        />
    </>
  );
}
