import axios from "axios";
import { API_HOST } from "../constants";

let headers = {};

const getAuth = () => {
  const authData = localStorage.getItem("Authorization");
  if (authData) {
    headers = { Authorization: authData };
  }
};

export const conciergeLogin = async (data, onSuccess, onFailure) => {
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/concierge/login`, data, { headers })
    .then((res) => {
      localStorage.setItem("Authorization", res.data.session_id);
      localStorage.setItem("User", JSON.stringify(res.data.user));
      localStorage.setItem("user_status", JSON.stringify(res.data.status));
      if (res.data.user_subscribed && res.data.user_subscribed === true) {
        localStorage.setItem("user_subscribed", "true");
      } else {
        localStorage.setItem("user_subscribed", "false");
      }
      // localStorage.setItem("First_Name",res.data.first_name);
      // localStorage.setItem("Last_Name",res.data.last_name);
      // localStorage.setItem("Avatar",res.data.avatar);
      onSuccess(res.data);
    })
    .catch((err) => onFailure(err.response));
};

export const saveOnboarding = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/onboarding/save`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const getOnboarding = async (onSuccess, onFailure) => {
  getAuth();
  return await axios
    .get(`${API_HOST}/onboarding/get`, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      console.log("TODO Proper error handling");
      localStorage.removeItem("Authorization");
      dispatchEvent(new Event("storage"));

      onFailure(err.response);
    });
};

export const login = async (data, onSuccess, onFailure) => {
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/auth/login`, data, { headers })
    .then((res) => {
      localStorage.setItem("Authorization", res.data.session_id);
      localStorage.setItem("User", JSON.stringify(res.data.user));
      localStorage.setItem("user_status", JSON.stringify(res.data.status));
      if (res.data.user_subscribed && res.data.user_subscribed === true) {
        localStorage.setItem("user_subscribed", "true");
      } else {
        localStorage.setItem("user_subscribed", "false");
      }
      // localStorage.setItem("First_Name",res.data.first_name);
      // localStorage.setItem("Last_Name",res.data.last_name);
      // localStorage.setItem("Avatar",res.data.avatar);
      onSuccess(res.data);
    })
    .catch((err) => onFailure(err.response));
};

export const register = async (data, onSuccess, onFailure) => {
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/auth/register`, data, { headers })
    .then((res) => onSuccess())
    .catch((err) => onFailure(err.response));
};

export const onboardingRegister = async (data, onSuccess, onFailure) => {
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/auth/onboarding/register`, data, { headers })
    .then((res) => onSuccess())
    .catch((err) => onFailure(err.response));
};

export const onboardingUpload = async (data, onSuccess, onFailure) => {
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/auth/onboarding/upload`, data, { headers })
    .then((res) => onSuccess())
    .catch((err) => onFailure(err.response));
};

export const confirmUserAEmail = async (token) => {
  return await axios.post(`${API_HOST}/auth/verification/${token}`, {
    headers,
  });
  // .then((res) => res.data)
  // .catch((err) => console.log(err));
};

export const getUserEmailFromToken = async (token) => {
  headers["Content-Type"] = "application/json";
  return await axios.post(`${API_HOST}/auth/signup/${token}`, {
    headers,
  });
  // .then((res) => res.data)
  // .catch((err) => console.log(err));
};

export const resendConfirmationEmail = async (data, onSuccess, onFailure) => {
  return await axios
    .post(`${API_HOST}/auth/verification/resend`, data, { headers })
    .then((res) => onSuccess())
    .catch((err) => onFailure(err.response));
};

export const forgotPassword = async (data, onSuccess, onFailure) => {
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/auth/forgot`, data, { headers })
    .then((res) => onSuccess())
    .catch((err) => onFailure(err.response));
};

export const checkResetToken = async (token, onSuccess, onFailure) => {
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/auth/checktoken/${token}`, { headers })
    .then((res) => onSuccess())
    .catch((err) => onFailure(err.response));
};

export const resetPassword = async (token, data, onSuccess, onFailure) => {
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/auth/reset/${token}`, data, { headers })
    .then((res) => onSuccess())
    .catch((err) => onFailure(err.response));
};
