import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { numberWithCommas } from "common/numberFormat";

const styles = StyleSheet.create({
    headerContainer1: {
        marginTop: 10,
        justifyContent: 'flex-start',
        width: '60%',
        // flex: 1,
        paddingLeft: 30,
        paddingRight: 0,
    },
    headerContainer2: {
        marginTop: 10,
        justifyContent: 'flex-end',
        width: '40%',
        flex: 1,
        paddingLeft: 10,
        paddingRight: 30,
    },
    bonding: {
        marginTop: 5,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
    },
    spanText: {
        color: '#888888',
    },
});

const PageThreeBonding = ({ prequalifyData }) => (
    <View style={{ display: 'flex', flexDirection: 'row' }}>
        <View style={styles.headerContainer1}>
            <Text style={styles.bonding}>Bonding</Text>
            <Text>
                <span style={styles.spanText}>Institution Name: </span>
                {prequalifyData?.data?.bondingCompanyName}
            </Text>
            <Text>
                <span style={styles.spanText}>Address: </span>
                {prequalifyData?.data?.bondingCompanyAddress}
            </Text>
            <Text>
                <span style={styles.spanText}>Aggregate Limit: </span>
                ${numberWithCommas(prequalifyData?.data?.bondAggregateLimit)}                
            </Text>
        </View>

        <View style={styles.headerContainer2}>
            <Text style={styles.bonding}></Text>
            <Text>
                <span style={styles.spanText}>Contact Name: </span>
                {prequalifyData?.data?.bondingCompanyContactName}
            </Text>
            <Text>
                <span style={styles.spanText}>Email: </span>
                {prequalifyData?.data?.bondingCompanyContactEmail}
            </Text>
            <Text>
                <span style={styles.spanText}>Single Project Limit: </span>
                ${numberWithCommas(prequalifyData?.data?.bondSingleProjectLimit)}                
            </Text>
        </View>
    </View>
);

export default PageThreeBonding;
