import React from "react";

import { Container, Logo } from "./styles";
import logo_dark from "../../assets/common/full-logo.svg";

function Splash() {
  return (
    <Container><Logo src={logo_dark} /></Container>
  );
};

export default Splash;
