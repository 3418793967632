import Input from "components/inputField";
import React, { useEffect, useState } from "react";
import { CurrencyType, FacilityType } from "../../constants";
import Select from "components/selectField";
import { numberWithCommas, removeComma } from "../../common/numberFormat";
import { SelectingOptions } from "./remainingTypes";

export const BankAdditionalfields = ({ setAddData, index, addData }) => {
  const currentState = addData?.facility?.state[index];
  const [facilityType, setFacilitytype] = useState([]);
  useEffect(() => {
    setFacilitytype(SelectingOptions(addData?.facility, FacilityType));
  }, [addData]);
  return (
    <>
      <div className="fullWidthInputWrap">
        <Select
          style={{ marginRight: "16px" }}
          label="Type"
          options={facilityType.map((item) => ({
            value: item,
            label: item,
          }))}
          value={currentState?.type}
          setValue={(val) => setAddData(`facility.state.${index}.type`, val)}
          validateFunc={(item) => Boolean(item)}
          noCheckIcon={true}
          showDropdownIndicator={true}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="oneThirdInputWrap">
          <Select
            style={{ marginRight: "16px" }}
            label="Currency"
            options={CurrencyType.map((item) => ({
              value: item.value,
              label: item.text,
            }))}
            value={currentState?.currency}
            setValue={(val) =>
              setAddData(`facility.state.${index}.currency`, val)
            }
            validateFunc={(item) => Boolean(item)}
            noCheckIcon={true}
            showDropdownIndicator={true}
          />
        </div>

        <Input
          type="text"
          id="inputWrap"
          label="Amount"
          placeholder="1,234,678"
          labelSize="12px"
          fontSize="18px"
          paddingRight="12px"
          value={numberWithCommas(currentState?.amount)}
          setValue={(val) =>
            setAddData(`facility.state.${index}.amount`, removeComma(val))
          }
        />
        <Input
          type="text"
          id="inputWrap"
          placeholder="1,234,678"
          label="Outstanding"
          labelSize="12px"
          fontSize="18px"
          value={numberWithCommas(currentState?.outstanding_balance)}
          setValue={(val) =>
            setAddData(
              `facility.state.${index}.outstanding_balance`,
              removeComma(val)
            )
          }
          paddingRight="12px"
        />
      </div>
    </>
  );
};
