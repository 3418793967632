import React, { useState, useEffect } from "react";
import { emailRE } from "../../../common/regex";
import { ButtonYesNo } from "../components/buttonYesNo";
import {
  Row,
  FormTitle,
  StyledMediumTitle,
} from "../styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "../../../components/inputField";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../components/accordion";

import { AccordionContainer } from './styles';

export const Panel2 = ({
  setActiveStep,
  qualificationData,
  setQualificationData,
  disabled,
  disabledBC,
  sameAI,
  setSameAI,
  sameCI,
  setSameCI,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [disabledMiddleBC, setDisabledMiddleBC] = useState(true);

  const insurance = qualificationData?.insurance;
  const bonding = qualificationData.bonding;

  useEffect(() => {
    if (
      bonding?.bc &&
      bonding?.addressBC?.length > 0 &&
      bonding?.postalCodeBC?.length > 0 &&
      bonding?.cityBC?.length > 0 &&
      bonding?.provinceBC
    ) {
      setDisabledMiddleBC(false);
    } else {
      setDisabledMiddleBC(true);
    }
  });

  return (
    <>
      <StyledMediumTitle>
        Insurance details
      </StyledMediumTitle>
      <AccordionContainer>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={(event, newExpanded) =>
            setExpanded(newExpanded ? "panel1" : false)
          }
        >
          <AccordionSummary
            expanded={expanded === "panel1"}
            error={
              !(insurance?.GICompany?.length > 0 &&
                insurance?.GIAddress?.length > 0 &&
                insurance?.GIFirstName?.length > 0 &&
                insurance?.GILastName?.length > 0 &&
                insurance?.GIPhone?.length > 0 &&
                emailRE.test(insurance?.GIEmail))
            }
            title="General Liability Insurance"
          />
          <AccordionDetails>
            <Row style={{  paddingRight: "16px", width: "50%" }}>
              <Input
                label="Insurance Company Name"
                type="text"
                placeholder="Insurance Company"
                value={insurance?.GICompany}
                setValue={(val) => setQualificationData('insurance.GICompany', val)}
                validateFunc={(item) => item.length > 0}
              />
            </Row>
            <Row style={{  paddingRight: "16px", width: "50%" }}>
              <Input
                  label="Insurance Company Address"
                  type="text"
                  placeholder="Address"
                  value={insurance?.GIAddress}
                  setValue={(val) => setQualificationData('insurance.GIAddress', val)}
                  validateFunc={(item) => item.length > 0}
                />
            </Row>
            <Row>
              <Input
                style={{  marginRight: "16px", width: "50%" }}
                label="Contact First Name"
                type="text"
                placeholder="First Name"
                value={insurance?.GIFirstName}
                setValue={(val) => setQualificationData('insurance.GIFirstName', val)}
                validateFunc={(item) => item.length > 0}
              />
              <Input
                style={{  marginRight: "16px", width: "50%" }}
                label="Contact Last Name*"
                type="text"
                placeholder="Last Name"
                value={insurance?.GILastName}
                setValue={(val) => setQualificationData('insurance.GILastName', val)}
                validateFunc={(item) => item.length > 0}
              />
            </Row>
            <Row marginBottom="16px">
              <Input
                style={{  marginRight: "16px", width: "50%" }}
                label="ContactPhone*"
                type="text"
                placeholder="Phone"
                value={insurance?.GIPhone}
                setValue={(val) => setQualificationData('insurance.GIPhone', val)}
                validateFunc={(item) => item.length > 0}
              />
              <Input
                style={{  marginRight: "16px", width: "50%" }}
                label="Contact Email*"
                type="text"
                placeholder="Email"
                value={insurance?.GIEmail}
                setValue={(val) => setQualificationData('insurance.GIEmail', val)}
                validateFunc={(item) => emailRE.test(item)}
              />
            </Row>
            <StyledMediumTitle>
              Does your General Liability Insurance have a limit of $5 million or more?
            </StyledMediumTitle>
             <ButtonYesNo
                value={insurance?.GIHasIns}
                setQualificationData={setQualificationData}
                attribute='insurance.GIHasIns'
              />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel2"}
          onChange={(event, newExpanded) =>
            setExpanded(newExpanded ? "panel2" : false)
          }
        >
          <AccordionSummary
            expanded={expanded === "panel2"}
            title="Automobile Liability Insurance Limit"
            error={
              !(insurance?.AICompany?.length > 0 &&
                insurance?.AIAddress?.length > 0 &&
                insurance?.AIFirstName?.length > 0 &&
                insurance?.AILastName?.length > 0 &&
                insurance?.AIPhone?.length > 0 &&
                emailRE.test(insurance?.AIEmail))
            }
          />
          <AccordionDetails>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#FF6D1D" }}
                  checked={sameAI}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setSameAI(true);
                      setQualificationData('insurance.AICompany', insurance?.GICompany);
                      setQualificationData('insurance.AIAddress', insurance?.GIAddress);
                      setQualificationData('insurance.AIFirstName', insurance?.GIFirstName);
                      setQualificationData('insurance.AIFirstName', insurance?.GILastName);
                      setQualificationData('insurance.AIPhone', insurance?.GIPhone);
                      setQualificationData('insurance.AIEmail', insurance?.GIEmail);
                    } else {
                      setSameAI(false);
                      setQualificationData('insurance.AICompany', '');
                      setQualificationData('insurance.AIAddress', '');
                      setQualificationData('insurance.AIFirstName', '');
                      setQualificationData('insurance.AIFirstName', '');
                      setQualificationData('insurance.AIPhone', '');
                      setQualificationData('insurance.AIEmail', '');
                    }
                  }}
                  name="checkedG"
                />
              }
              label="Same as above"
            />
            <Row style={{  paddingRight: "16px", width: "50%" }}>
              <Input
                type="text"
                placeholder="Insurance Company Name"
                value={insurance?.AICompany}
                setValue={(val) => setQualificationData('insurance.AICompany', val)}
                validateFunc={(item) => item.length > 0}
              />
            </Row>
            <Row style={{  paddingRight: "16px", width: "50%" }}>
              <Input
                label="Insurance Company Address"
                type="text"
                placeholder="Address"
                value={insurance?.AIAddress}
                setValue={(val) => setQualificationData('insurance.AIAddress', val)}
                validateFunc={(item) => item.length > 0}
              />
            </Row>
            <Row style={{ width: "100%" }}>
              <Input
                style={{ marginRight: "16px", width: "50%" }}
                label="Contact First Name*"
                type="text"
                placeholder="First Name"
                value={insurance?.AIFirstName}
                setValue={(val) => setQualificationData('insurance.AIFirstName', val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noErrorBorder={true}
              />
              <Input
                style={{ marginRight: "16px", width: "50%" }}
                label="Contact Last Name*"
                type="text"
                placeholder="Last name"
                value={insurance?.AILastName}
                setValue={(val) => setQualificationData('insurance.AILastName', val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noErrorBorder={true}
              />
            </Row>
            <Row marginBottom="16px">
              <Input
                style={{ marginRight: "16px", width: "50%" }}
                label="Contact Phone"
                type="text"
                placeholder="Phone"
                value={insurance?.AIPhone}
                setValue={(val) => setQualificationData('insurance.AIPhone', val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noErrorBorder={true}
              />
              <Input
                style={{ marginRight: "16px", width: "50%" }}
                label="ContactEmail"
                type="text"
                placeholder="Email"
                value={insurance?.AIEmail}
                setValue={(val) => setQualificationData('insurance.AIEmail', val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noErrorBorder={true}
              />
            </Row>
            <StyledMediumTitle>
              Does your Automobile Liability Insurance have a limit of $5 million or more?
            </StyledMediumTitle>
            <ButtonYesNo
                value={insurance?.AIHasIns}
                setQualificationData={setQualificationData}
                attribute='insurance.AIHasIns'
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel3"}
          onChange={(event, newExpanded) =>
            setExpanded(newExpanded ? "panel3" : false)
          }
        >
          <AccordionSummary
            expanded={expanded === "panel3"}
            title="Broad Form Contractors’ Equipment Insurance"
            error={
              !(insurance?.CICompany?.length > 0 &&
                insurance?.CIAddress?.length > 0 &&
                insurance?.CIFirstName?.length > 0 &&
                insurance?.CILastName?.length > 0 &&
                insurance?.CIPhone?.length > 0 &&
                emailRE.test(insurance?.CIEmail))
            }
          />
          <AccordionDetails>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#FF6D1D" }}
                  checked={sameCI}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setSameCI(true);
                      setQualificationData('insurance.CICompany', insurance?.GICompany)
                      setQualificationData('insurance.CIAddress', insurance?.GIAddress)
                      setQualificationData('insurance.CIFirstName', insurance?.GIFirstName)
                      setQualificationData('insurance.CILastName', insurance?.GILastName)
                      setQualificationData('insurance.CIPhone', insurance?.GIPhone)
                      setQualificationData('insurance.CIEmail', insurance?.GIEmail)
                    } else {
                      setSameCI(false);
                      setQualificationData('insurance.CICompany', '')
                      setQualificationData('insurance.CIAddress', '')
                      setQualificationData('insurance.CIFirstName', '')
                      setQualificationData('insurance.CILastName', '')
                      setQualificationData('insurance.CIPhone', '')
                      setQualificationData('insurance.CIEmail', '')
                    }
                  }}
                  name="checkedG"
                />
              }
              label="Same as above"
            />
            <Row style={{  paddingRight: "16px", width: "50%" }}>
              <Input
                type="text"
                placeholder="Insurance Company Name"
                value={insurance?.CICompany}
                setValue={(val) => setQualificationData('insurance.CICompany', val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noErrorBorder={true}
              ></Input>
            </Row>
            <Row style={{  paddingRight: "16px", width: "50%" }}>
              <Input
                label="Insurance Company Address"
                type="text"
                placeholder="Address"
                value={insurance?.CIAddress}
                setValue={(val) => setQualificationData('insurance.CIAddress', val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noErrorBorder={true}
              />
            </Row>
            <Row>
              <Input
                style={{ marginRight: "16px", width: "50%" }}
                label="Contact First Name"
                type="text"
                placeholder="First Name"
                value={insurance?.CIFirstName}
                setValue={(val) => setQualificationData('insurance.CIFirstName', val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noErrorBorder={true}
              />
              <Input
                style={{ marginRight: "16px", width: "50%" }}
                label="Contact Last Name"
                type="text"
                placeholder="Last Name"
                value={insurance?.CILastName}
                setValue={(val) => setQualificationData('insurance.CILastName', val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noErrorBorder={true}
              />
            </Row>
            <Row marginBottom="16px">
              <Input
                style={{ marginRight: "16px", width: "50%" }}
                label="Contact Phone"
                type="text"
                placeholder="Phone"
                value={insurance?.CIPhone}
                setValue={(val) => setQualificationData('insurance.CIPhone', val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noErrorBorder={true}
              />
              <Input
                style={{ marginRight: "16px", width: "50%" }}
                label="Contact Email"
                type="text"
                placeholder="Email"
                value={insurance?.CIEmail}
                setValue={(val) => setQualificationData('insurance.CIEmail', val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noErrorBorder={true}
              />
            </Row>
            <StyledMediumTitle>
              Do you have Broad Form Contractors’ Equipment Insurance?
            </StyledMediumTitle>
            <ButtonYesNo
              value={insurance?.CIHasIns}
              setQualificationData={setQualificationData}
              attribute='insurance.CIHasIns'
            />
          </AccordionDetails>
        </Accordion>
      </AccordionContainer>
    </>
  );
};
