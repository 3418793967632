import Input from "components/inputField";
import React, { useState, useEffect } from "react";
import { CurrencyType } from "../../constants";
import Select from "components/selectField";
import { numberWithCommas, removeComma } from "../../common/numberFormat";
import { SelectingOptions } from "./remainingTypes";
import { getInsuranceTypes } from "api/external_data";
import DateTimePickerField from "components/dateTimePickerField";

export const InsuranceTypeAndLimit = ({
  setAddData,
  index,
  addData,
  insuranceType,
  setInsuranceType,
  allInsuraceType,
}) => {
  const currentState = addData?.insuranceDetails?.state[index];

  useEffect(() => {
    setInsuranceType(
      SelectingOptions(addData?.insuranceDetails, allInsuraceType)
    );
  }, [addData]);

  return (
    <>
      <div className="fullWidthInputWrap last">
        <Select
          style={{ marginRight: "16px" }}
          label="Type"
          options={insuranceType.map((item) => ({
            value: item.type,
            label: item.type,
          }))}
          value={currentState?.type}
          setValue={(val) =>
            setAddData(`insuranceDetails.state.${index}.type`, val)
          }
          validateFunc={(item) => Boolean(item)}
          noCheckIcon={true}
          showDropdownIndicator={true}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div className="oneThirdInputWrap">
          <Select
            style={{ marginRight: "16px" }}
            label="Currency"
            options={CurrencyType.map((item) => ({
              value: item.value,
              label: item.text,
            }))}
            value={currentState?.currency}
            setValue={(val) =>
              setAddData(`insuranceDetails.state.${index}.currency`, val)
            }
            validateFunc={(item) => Boolean(item)}
            noCheckIcon={true}
            showDropdownIndicator={true}
          />
        </div>

        <Input
          type="text"
          id="inputWrap"
          label="Aggregate Limit"
          placeholder="1,234,678"
          labelSize="12px"
          fontSize="18px"
          paddingRight="12px"
          value={numberWithCommas(currentState?.aggregate_limit)}
          setValue={(val) =>
            setAddData(
              `insuranceDetails.state.${index}.aggregate_limit`,
              removeComma(val)
            )
          }
        />
        <Input
          type="text"
          id="inputWrap last"
          placeholder="1,234,678"
          label="Per Occurrence"
          labelSize="12px"
          fontSize="18px"
          value={numberWithCommas(currentState?.per_occurence)}
          setValue={(val) =>
            setAddData(
              `insuranceDetails.state.${index}.per_occurence`,
              removeComma(val)
            )
          }
          paddingRight="12px"
        />
      </div>
      {/* <Input
        id="inputWrap"
        label="Effective Date"
        type="date"
        //placeholder="abcs"
        labelSize="12px"
        fontSize="18px"
        paddingRight="10px"
        value={currentState?.effective_date}
        setValue={(val) => {
          setAddData(`insuranceDetails.state.${index}.effective_date`, val);
        }}
        noCheckIcon={true}
        noErrorBorder={true}
      />
      <Input
        id="inputWrap last"
        label="Expiry Date"
        type="date"
        //placeholder="yyyy/mm/dd"
        labelSize="12px"
        fontSize="18px"
        paddingRight="10px"
        value={currentState?.expiry_date}
        setValue={(val) =>
          setAddData(`insuranceDetails.state.${index}.expiry_date`, val)
        }
        noCheckIcon={true}
        noErrorBorder={true}
      /> */}
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <DateTimePickerField
          showDateField={true}
          dateWidth="100%"
          paddingRight="16px"
          dateLabel="Effective Date"
          dateValue={
            currentState?.effective_date ? currentState?.effective_date : ""
          }
          setDateValue={(val) => {
            setAddData(`insuranceDetails.state.${index}.effective_date`, val);
          }}
        />
        <DateTimePickerField
          showDateField={true}
          dateWidth="100%"
          dateLabel="Expiry Date"
          dateValue={currentState?.expiry_date ? currentState?.expiry_date : ""}
          setDateValue={(val) => {
            setAddData(`insuranceDetails.state.${index}.expiry_date`, val);
          }}
        />
      </div>
    </>
  );
};
