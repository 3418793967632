import React, { useEffect, useRef, useState } from "react";

import classes from "./style/FormBuilder.module.css";
import { COLORS, FONT_SIZES } from "../../constants";

import { AlertPopup } from "components/alertPopup/alertPopup";
import { FunctionalButton } from "../../components/functionalButton";
import PledgxLogo from "../../assets/common/pledgxLogo";
import Spinner from "../spinner";
import { SuccessPopup } from "../../pages/contractorDashboard/profileSetup/SuccessPopup";

import { InteractionBuilder } from "./InteractionBuilder";
import { PageBuilder } from "./PageBuilder";
import { NavigationBuilder } from "./NavigationBuilder";
import { useInteractionStateBuilder } from "./utils/useInteractionStateBuilder";

export const FormBuilder = ({
  alert,
  changeData,
  changeItemState,
  data,
  formDetails,
  formFields,
  formSetup,
  goToLocation,
  icons,
  id,
  ignoreRequired,
  interactionLoading,
  legacyNavigationUi = false,
  loading,
  navigationType = "BUBBLES",
  onAlertStateChanged,
  onConditionalStateChanged,
  onDataChange,
  onInteractionItemClicked,
  onNavigate,
  onNavigationItemClicked,
  onPopupInteraction,
  onResumeButtonClicked,
  onSubmit,
  onSubmitItem,
  popup,
  successPopup,
  readOnly,
  disabled,
  supplementaryData,
  fontSize
}) => {
  const [state, setState] = useState({
    alert: {
      headerText: null,
      subHeaderText: null,
      visible: false,
    },
    currentLocation: [0],
    popup: {
      visible: false,
    },
    successPopup: {
      visible: false,
    },
  });
  const [conditionalState, setConditionalState] = useState({});
  const [internalData, setInternalData] = useState({});
  const [itemStates, setItemStates] = useState({});
  const [interactedFields, setInteractedFields] = useState([]);
  const [visitedLocations, setVisitedLocations] = useState([]);
  const interactionState = useInteractionStateBuilder({
    currentLocation: state.currentLocation,
    formFields,
    formSetup,
    userData: data || internalData,
  });

  const alertShown = useRef(false);
  const formSubmitted = useRef(false);
  const changesSinceNavigate = useRef(false); // This keeps track of whether any changes have been made since the last navigate to send to onNavigate. Ex. if no changes have been made, the parent component doesnt need to save

  useEffect(() => {
    var dialogueStates = {
      alert: {},
      popup: {},
      successPopup: {},
    };
    if (alert) {
      dialogueStates.alert = alert;
    }
    if (popup) {
      dialogueStates.popup = popup;
    }
    if (successPopup) {
      dialogueStates.successPopup = successPopup;
    }
    setState({
      ...state,
      ...dialogueStates,
    });
  }, [alert, popup, successPopup]);

  useEffect(() => {
    if (changeItemState) {
      setItemStates({ ...itemStates, ...changeItemState });
    }
  }, [changeItemState]);

  useEffect(() => {
    if (onConditionalStateChanged) {
      onConditionalStateChanged(conditionalState);
    }
  }, [conditionalState]);

  useEffect(() => {
    handleInteraction({
      action: "CHANGE_LOCATION",
      payload: goToLocation || [0],
    });
  }, [goToLocation, id]);

  useEffect(() => {
    if (!data && onDataChange) {
      onDataChange(internalData);
    }
  }, [internalData]);

  useEffect(() => {
    if (state.alert?.visible) {
      alertShown.current = true;
      if (onAlertStateChanged) {
        onAlertStateChanged(true);
      }
    } else if (alertShown.current) {
      if (onAlertStateChanged) {
        onAlertStateChanged(false);
      }
    }
  }, [state.alert]);

  useEffect(() => {
    if (onNavigate) {
      onNavigate({
        currentLocation: state.currentLocation,
        dataChanged: changesSinceNavigate.current,
      });
    }
    changesSinceNavigate.current = false;
  }, [state.currentLocation]);

  function handleInteraction({ action, payload }) {
    switch (action) {
      case "BUTTON_CLICKED":
        if (onInteractionItemClicked) {
          onInteractionItemClicked(payload);
          changesSinceNavigate.current = true;
        }
        break;
      case "CHANGE_DROPDOWN_VALUE":
        var newInteractedElements = new Set(interactedFields).add(payload.id);
        setInteractedFields([...newInteractedElements]);
        changesSinceNavigate.current = true;
        if (data && changeData) {
          changeData({ action, payload });
        } else {
          setInternalData({
            ...internalData,
            [payload.id]: { selectedData: payload.value },
          });
        }
        break;
      case "CHANGE_FILES":
        var newInteractedElements = new Set(interactedFields).add(payload.id);
        setInteractedFields([...newInteractedElements]);
        changesSinceNavigate.current = true;
        if (data && changeData) {
          changeData({ action, payload });
        } else {
          setInternalData({
            ...internalData,
            [payload.id]: { selectedFiles: payload.value },
          });
        }
        break;
      case "CHANGE_LIST_SELECT_VALUE":
        var newInteractedElements = new Set(interactedFields).add(payload.id);
        setInteractedFields([...newInteractedElements]);
        changesSinceNavigate.current = true;
        if (data && changeData) {
          changeData({ action, payload });
        } else {
          var newList = internalData[payload.id] || [];
          if (newList[payload.index]?.[payload.selectedId]) {
            newList[payload.index][payload.selectedId] = payload.value;
          }
          setInternalData({
            ...internalData,
            [payload.id]: newList,
          });
        }
        break;
      case "CHANGE_LOCATION_DIRECT":
        if (formSetup[payload[0]]?.navigation?.disableDirectEntry) {
          // If disableDirectEntry is true, do not navigate directly to this location
          break;
        }
      case "CHANGE_LOCATION":
        if (payload === "SUBMIT") {
          // SUBMIT FORM
        } else {
          if (payload?.[0] > state.currentLocation?.[0]) {
            if (!readOnly && !interactionState?.navigation?.requirementsMet) {
            } else {
              setState({ ...state, currentLocation: payload });
              handleLegacyNavigationUi(payload);
            }
          } else {
            setState({ ...state, currentLocation: payload });
            handleLegacyNavigationUi(payload);
          }
        }
        // TODO: Add All required input boxes from the current page to required IDs
        break;
      case "CHANGE_VALUE":
        var newInteractedElements = new Set(interactedFields).add(payload.id);
        setInteractedFields([...newInteractedElements]);
        changesSinceNavigate.current = true;
        if (data && changeData) {
          changeData({ action, payload });
        } else {
          setInternalData({ ...internalData, [payload.id]: payload.value });
        }
        break;
      case "CHECK_REQUIRED":
        return handleSubmit({ checkRequired: true, submitForm: false });
      case "INTERACTION_ITEM_CLICKED":
        if (onInteractionItemClicked) {
          onInteractionItemClicked(payload);
          changesSinceNavigate.current = true;
        }
        break;
      case "RESUME_BUTTON_CLICKED":
        if (onResumeButtonClicked) {
          onResumeButtonClicked(payload);
          changesSinceNavigate.current = true;
        }
      case "POPUP_INTERACTION":
        switch (payload.interaction) {
          case "CANCEL_POPUP":
          case "CLOSE_POPUP":
            setState({ ...state, popup: { ...state.popup, visible: false } });
            break;
          default:
            break;
        }
        if (onPopupInteraction) {
          onPopupInteraction({ action: payload.interaction, id: payload.id });
        }
        break;
      case "SUBMIT_FORM":
        handleSubmit();
        break;
      case "SUBMIT_ITEM":
        if (onSubmitItem) {
          onSubmitItem(payload);
          changesSinceNavigate.current = true;
        }
        break;
      case "TABLE_ADD_SUPPLEMENTARY_VALUE":
        // Add supplementary data to the given element
        break;
      case "TABLE_CHANGE_VALUE":
        if (payload?.id) {
          changesSinceNavigate.current = true;
          if (data && changeData) {
            changeData({ action, payload });
          } else {
            setInternalData({
              ...internalData,
              [payload.id]: internalData[payload.id]
                ? { ...internalData[payload.id], data: payload.value }
                : { data: payload.value },
            });
          }
        }
        break;
      case "TABLE_SELECTED_CHANGE_VALUE":
        changesSinceNavigate.current = true;
        if (data && changeData) {
          changeData({ action, payload });
        } else {
          setInternalData({
            ...internalData,
            [payload.id]: internalData[payload.id]
              ? { ...internalData[payload.id], selectedData: payload.value }
              : { selectedData: payload.value },
          });
        }
        break;
      case "YEAR_CHANGE_VALUE":
        changesSinceNavigate.current = true;
        if (data && changeData) {
          changeData({ action, payload });
        } else {
          setInternalData({
            ...internalData,
            [payload.id]: internalData[payload.id]
              ? {
                  ...internalData[payload.id],
                  [Object.keys(payload.value)[0]]:
                    payload.value[Object.keys(payload.value)[0]],
                }
              : {
                  [Object.keys(payload.value)[0]]:
                    payload.value[Object.keys(payload.value)[0]],
                },
          });
        }
        break;
      default:
        break;
    }
  }

  function handleLegacyNavigationUi(payload) {
    if (legacyNavigationUi) {
      var newVisitedLocations = [...visitedLocations];
      try {
        newVisitedLocations = [
          ...visitedLocations,
          ...Array.from(Array(payload?.[0] + 1).keys()),
        ];
      } catch {}
      setVisitedLocations([...new Set(newVisitedLocations)]);
    }
  }

  function handleSubmit(props) {
    formSubmitted.current = true;
    var formComplete = false;
    if (interactionState?.requiredFields?.ids?.length > 0) {
      var goToLocation;
      var alertParams = {
        headerText: "Please fill all required fields",
        subHeaderText:
          interactionState.requiredFields.titles?.length > 0 &&
          `The following fields are required: ${interactionState.requiredFields.titles.join(
            ", "
          )}`,
        visible: true,
      };
      if (
        interactionState.requiredPages?.locations?.[0] ||
        interactionState.requiredPages.locations[0] === 0
      ) {
        goToLocation = interactionState.requiredPages.locations[0];
      }
      setState({
        ...state,
        alert: alertParams,
        currentLocation:
          goToLocation || goToLocation === 0
            ? [goToLocation]
            : state.currentLocation,
      });
    } else {
      formComplete = true;
      if (onSubmit && props?.submitForm !== false) {
        onSubmit(data || internalData);
      }
    }
    if (props?.checkRequired) {
      return formComplete;
    }
  }

  if (loading || !formFields || !formSetup) {
    return (
      <div
        className={[
          classes["loading-container"],
          "pledgxform-loading-container",
        ].join(" ")}
      >
        <Spinner />
      </div>
    );
  } else {
    var parsedPopupHeight;
    if (state.popup?.height) {
      try {
        parsedPopupHeight = parseInt(state.popup.height.split("p")[0]);
      } catch {}
    }
    return (
      <>
        {state.alert && (
          <AlertPopup
            openPopup={state.alert.visible}
            setOpenPopup={(e) =>
              setState({ ...state, alert: { ...state.alert, visible: e } })
            }
            headerText={state.alert?.headerText || "Item limit reached"}
            subHeaderText={
              state.alert?.subHeaderText ||
              "Please remove items before adding new ones."
            }
          />
        )}
        {state.popup && (
          <AlertPopup
            openPopup={
              state.popup.visible &&
              !state.alert?.visible &&
              !state.successPopup?.visible
            }
            height={state.popup.height || "434px"}
            width={state.popup.width || "640px"}
            setOpenPopup={() =>
              handleInteraction({
                action: "POPUP_INTERACTION",
                payload: { id: state.popup.id, interaction: "CLOSE_POPUP" },
              })
            }
            showIcon={false}
            bodyContent={
              <div
                className={classes["popup-content-container"]}
                style={
                  parsedPopupHeight
                    ? { height: `${parsedPopupHeight - 52 - 24}px` }
                    : {}
                }
              >
                <div className={classes["popup-text-container"]}>
                  {state.popup.showLogo && (
                    <div className={classes["popup-logo-area"]}>
                      <PledgxLogo isMobile={false} />
                    </div>
                  )}
                  {state.popup.title && (
                    <div
                      className={classes["popup-title"]}
                      style={{ fontSize: FONT_SIZES.heading_6 }}
                    >
                      {state.popup.title}
                    </div>
                  )}
                  {state.popup.content && (
                    <div
                      style={{
                        fontSize: FONT_SIZES.body_2,
                        lineHeight: "normal",
                        marginTop: "30px",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      {state.popup.content}
                    </div>
                  )}
                </div>
                {state.popup.interaction && (
                  <div className={classes["popup-interaction-container"]}>
                    {state.popup.interaction.cancelButton && (
                      <div
                        style={{
                          width:
                            state.popup.interaction.cancelButton.width ||
                            "200px",
                        }}
                      >
                        <FunctionalButton
                          buttonTitle={
                            state.popup.interaction.cancelButton.title ||
                            "Cancel"
                          }
                          handleButton={() =>
                            handleInteraction({
                              action: "POPUP_INTERACTION",
                              payload: {
                                id: state.popup.id,
                                interaction: "CANCEL_POPUP",
                              },
                            })
                          }
                          isReversedColor={true}
                        />
                      </div>
                    )}
                    {state.popup.interaction.confirmButton && (
                      <div
                        style={{
                          width:
                            state.popup.interaction.confirmButton.width ||
                            "200px",
                        }}
                      >
                        <FunctionalButton
                          buttonTitle={
                            state.popup.interaction.confirmButton.title ||
                            "Confirm"
                          }
                          handleButton={() =>
                            handleInteraction({
                              action: "POPUP_INTERACTION",
                              payload: {
                                id: state.popup.id,
                                interaction: "CONFIRM_POPUP",
                              },
                            })
                          }
                          isReversedColor={false}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            }
          />
        )}
        {state.successPopup && (
          <SuccessPopup
            buttonText={formDetails?.successPopup?.buttonText || "DONE"}
            descriptionText={formDetails?.successPopup?.descriptionText || ""}
            isMobile={false}
            openPopup={state.successPopup?.visible && !state.alert?.visible}
            setOpenPopup={(e) =>
              handleInteraction({
                action: "POPUP_INTERACTION",
                payload: { id: "successPopup", interaction: "CONFIRM_POPUP" },
              })
            }
            titleText={state.successPopup.content || "Success!"}
          />
        )}
        <div className={classes.container} style={formDetails?.style || {}}>
          {formDetails?.title && (
            <div
              className={[
                classes["title-container"],
                "pledgxform-title-container",
              ].join(" ")}
            >
              <span
                className={[
                  classes["title-text"],
                  "pledgxform-title-text",
                ].join(" ")}
                style={{ fontSize: FONT_SIZES.heading_3 }}
              >
                {formDetails.title}
              </span>
            </div>
          )}
          {navigationType && formSetup && formSetup.length > 1 && (
            <div
              className={[
                classes["navigation-container"],
                "pledgxform-navigation-container",
              ].join(" ")}
            >
              <NavigationBuilder
                changeLocationDirect={(newLocation) =>
                  handleInteraction({
                    action: "CHANGE_LOCATION_DIRECT",
                    payload: newLocation,
                  })
                }
                formSetup={formSetup}
                icons={icons}
                id={id}
                ignoreRequired={ignoreRequired}
                interactionState={interactionState}
                navigationType={navigationType}
                onNavigationItemClicked={onNavigationItemClicked}
                readOnly={readOnly}
                visitedLocations={visitedLocations}
              />
            </div>
          )}
          <div
            className={[
              classes["page-container"],
              "pledgxform-page-container",
              classes["page-scroll-container"],
            ].join(" ")}
            style={formDetails?.pageStyle || {}}
          >
            <PageBuilder
              conditionalState={conditionalState}
              currentLocation={state.currentLocation}
              data={data || internalData}
              formFields={formFields}
              formSetup={formSetup}
              formSubmitted={formSubmitted.current}
              handleInteraction={handleInteraction}
              id={id}
              interactedFields={interactedFields}
              itemStates={itemStates}
              readOnly={readOnly}
              fontSize={fontSize || "18px"}
              disabled={disabled}
              setConditionalState={setConditionalState}
              onSubmitItem={(item) =>
                handleInteraction({ action: "SUBMIT_ITEM", payload: item })
              }
              supplementaryData={supplementaryData}
            />
          </div>
          {interactionLoading ? (
            <div
              className={[
                classes["interaction-container"],
                classes["interaction-loading"],
                "pledgxform-interaction-container",
              ].join(" ")}
            >
              <div className={classes["interaction-loading-container"]}>
                <Spinner />
              </div>
              {formDetails?.interactionLoadingMessage && (
                <div
                  className={classes["interaction-loading-message"]}
                  style={{
                    color: COLORS.textGrey,
                    fontSize: FONT_SIZES.body_5,
                  }}
                >
                  {formDetails.interactionLoadingMessage}
                </div>
              )}
            </div>
          ) : !readOnly ? (
            <div
              className={[
                classes["interaction-container"],
                "pledgxform-interaction-container",
              ].join(" ")}
            >
              <InteractionBuilder
                changeLocation={(newLocation) =>
                  handleInteraction({
                    action: "CHANGE_LOCATION",
                    payload: newLocation,
                  })
                }
                checkRequired={() =>
                  handleInteraction({ action: "CHECK_REQUIRED" })
                }
                id={id}
                interactionState={interactionState}
                labels={formDetails?.labels || {}}
                onNavigationItemClicked={onNavigationItemClicked}
                submit={() => handleInteraction({ action: "SUBMIT_FORM" })}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
};
