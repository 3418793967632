import React from "react";

export const ArrowsIcon = ({ backArrow = false }) => {
  return (
    <>
      {backArrow && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="32"
          viewBox="0 0 20 32"
          fill="none"
        >
          <path
            d="M16 4L4.35355 15.6464C4.15829 15.8417 4.15829 16.1583 4.35355 16.3536L16 28"
            stroke="white"
            stroke-width="7"
            stroke-linecap="round"
          />
        </svg>
      )}
      {!backArrow && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="32"
          viewBox="0 0 20 32"
          fill="none"
        >
          <path
            d="M4 28L15.6464 16.3536C15.8417 16.1583 15.8417 15.8417 15.6464 15.6464L4 4"
            stroke="white"
            stroke-width="7"
            stroke-linecap="round"
          />
        </svg>
      )}
    </>
  );
};
