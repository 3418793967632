import { HealthSafetyCanada } from "./HealthSafetyCanada";
import { HealthSafetyUSA } from "./HealthSafetyUSA";
import { useMediaQuery } from "react-responsive";
import React, { useState } from "react";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import {
  StyledTab,
  StyledTabListHealthSafety,
  SetupUserManagementContainer,
} from "./styles";
import { SuccessPopup } from "./SuccessPopup";
import { LargeText } from "../styles";
import { getCountryName } from "common/countryName.js";

export const HealthSafety = ({
  getAnnualInfo,
  saveAnnualInfo,
  data,
  setData,
  institution_id,
  currentDashboardUrl,
}) => {
  const [selectTab, setSelectTab] = React.useState("1");
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);

  const handleChange = (event, newValue) => {
    setSelectTab(newValue);
  };

  let institution_data = {};

  if (currentDashboardUrl === "/service-provider-dashboard" && institution_id) {
    institution_data = { institution_id: institution_id };
  }

  return (
    <SetupUserManagementContainer isMobile={isMobile} leftBorder={false}>
      <LargeText
        isMobile={isMobile}
        style={{ textAlign: "center", marginBottom: "48px" }}
      >
        {isMobile ? "Edit your Records" : "Edit your Health & Safety Records"}
      </LargeText>
      <TabContext value={selectTab}>
        {getCountryName(data?.annual_record_remarks_and_cor?.company_country) === "Canada" ? (
          <StyledTabListHealthSafety onChange={handleChange} variant="fullWidth" centered TabIndicatorProps={{ hidden: true }}>
            <StyledTab label="Canada" value="1" style={{ minWidth: "180px" }} />
            <StyledTab
              label="United States"
              value="2"
              style={{ minWidth: "180px" }}
            />
          </StyledTabListHealthSafety>
        ) : (
          <StyledTabListHealthSafety
            onChange={handleChange}
            variant="fullWidth"
            centered
            TabIndicatorProps={{ hidden: true }}
          >
            <StyledTab label="Canada" value="2" style={{ minWidth: "180px" }} />
            <StyledTab
              label="United States"
              value="1"
              style={{ minWidth: "180px" }}
            />
          </StyledTabListHealthSafety>
        )}

        {getCountryName(data?.annual_record_remarks_and_cor?.company_country) === "Canada" ? (
          <>
            <TabPanel value="1">
              <HealthSafetyCanada
                isMobile={isMobile}
                setOpenSuccessPopup={setOpenSuccessPopup}
                getAnnualInfo={getAnnualInfo}
                saveAnnualInfo={saveAnnualInfo}
                data={data}
                setData={setData}
                institutionData={institution_data}
                currentDashboardUrl={currentDashboardUrl}
              />
            </TabPanel>

            <TabPanel value="2">
              <HealthSafetyUSA
                isMobile={isMobile}
                setOpenSuccessPopup={setOpenSuccessPopup}
                getAnnualInfo={getAnnualInfo}
                saveAnnualInfo={saveAnnualInfo}
                data={data}
                setData={setData}
                institutionData={institution_data}
                currentDashboardUrl={currentDashboardUrl}
              />
            </TabPanel>
          </>
        ) : (
          <>
            <TabPanel value="2">
              <HealthSafetyCanada
                isMobile={isMobile}
                setOpenSuccessPopup={setOpenSuccessPopup}
                getAnnualInfo={getAnnualInfo}
                saveAnnualInfo={saveAnnualInfo}
                data={data}
                setData={setData}
                institutionData={institution_data}
                currentDashboardUrl={currentDashboardUrl}
              />
            </TabPanel>

            <TabPanel value="1">
              <HealthSafetyUSA
                isMobile={isMobile}
                setOpenSuccessPopup={setOpenSuccessPopup}
                getAnnualInfo={getAnnualInfo}
                saveAnnualInfo={saveAnnualInfo}
                data={data}
                setData={setData}
                institutionData={institution_data}
                currentDashboardUrl={currentDashboardUrl}
              />
            </TabPanel>
          </>
        )}
      </TabContext>

      <SuccessPopup
        setOpenPopup={setOpenSuccessPopup}
        openPopup={openSuccessPopup}
        isMobile={isMobile}
      />
    </SetupUserManagementContainer>
  );
};
