import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { StyledButton, StyledMenu, StyledMenuItem } from "./styles";

import VerticalThreeDots from "assets/dashboardIcons/verticalThreeDots";
import { useHistory } from "react-router";

export const ActionsMenu = ({
  client_id,
  companyName,
  status,
  selectedTab,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const seeAllProjects = () => {
    history.push("/service_provider/projects", {
      institution_id: client_id,
    });
  };
  const handleStartNew = () => {
    history.push("/rfpq", {
      institution_id: client_id,
    });
  };

  const startNewCqs = () => {
    history.push("/prequalify/qualifying", {
      institution_id: client_id,
    });
  };

  const seeDocumentPool = () => {
    history.push("/documents", {
      institution_id: client_id,
    });
  };

  const seeAllCqs = () => {
    history.push("/all/CQS", {
      institution_id: client_id,
    });
  };
  const handleEdit = () => {
    history.push("/profile", {
      institution_id: client_id,
    });
  };
  const handleSeeContractors = () => {
    history.push("/buyers/prequalified/contractors", {
      institution_id: client_id,
    });
  };

  const seeProjectsPage = () => {
    history.push("/all/projects", {
      institution_id: client_id,
    });
  };
  const seeAllPersonnel = () => {
    history.push("/all/personnel", {
      institution_id: client_id,
    });
  };
  const seeWorkInProgress = () => {
    history.push("/client/wip", {
      company_name: companyName,
      account_id: client_id,
    });
  };

  if (["deleted"].includes(status)) {
    return <></>;
  } else {
    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ padding: 0 }}
        >
          <VerticalThreeDots />
        </IconButton>
        <StyledMenu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          {selectedTab === "1" && (
            <>
              <StyledMenuItem>
                <StyledButton width="180px" onClick={seeAllProjects}>
                  View RFP/Qs
                </StyledButton>
              </StyledMenuItem>
              <StyledMenuItem>
                <StyledButton width="180px" onClick={handleStartNew}>
                  Start a New Request
                </StyledButton>
              </StyledMenuItem>
              <StyledMenuItem>
                <StyledButton width="180px" onClick={seeDocumentPool}>
                  Document Pool
                </StyledButton>
              </StyledMenuItem>
              <StyledMenuItem>
                <StyledButton width="180px" onClick={handleSeeContractors}>
                  Prequalified Contractors
                </StyledButton>
              </StyledMenuItem>
            </>
          )}
          {selectedTab === "2" && (
            <>
              <StyledMenuItem>
                <StyledButton width="180px" onClick={seeAllCqs}>
                  View All CQS
                </StyledButton>
              </StyledMenuItem>
              <StyledMenuItem>
                <StyledButton width="180px" onClick={startNewCqs}>
                  Start New CQS
                </StyledButton>
              </StyledMenuItem>
              <StyledMenuItem>
                <StyledButton width="180px" onClick={handleEdit}>
                  Edit Profile
                </StyledButton>
              </StyledMenuItem>
              <StyledMenuItem>
                <StyledButton width="180px" disabled>
                  Add Reference
                </StyledButton>
              </StyledMenuItem>
              <StyledMenuItem>
                <StyledButton width="180px" onClick={seeDocumentPool}>
                  DocumentPool
                </StyledButton>
              </StyledMenuItem>
              <StyledMenuItem>
                <StyledButton width="180px" onClick={seeWorkInProgress}>
                  WIP
                </StyledButton>
              </StyledMenuItem>
              {/* <StyledMenuItem>
                <StyledButton width="180px" onClick={seeAllPersonnel}>
                  See All Personnel
                </StyledButton>
              </StyledMenuItem> */}
              <StyledMenuItem>
                <StyledButton width="180px" onClick={seeProjectsPage}>
                  See All Projects
                </StyledButton>
              </StyledMenuItem>
            </>
          )}

          {/* <StyledMenuItem>
            <StyledButton
              width="180px"
              //  onClick={clickDelete}
              disabled
            >
              Download eCQS
            </StyledButton>
          </StyledMenuItem> */}
          {/* <StyledMenuItem>
            <StyledButton
              width="180px"
              onClick={() => console.log("future implementation")}
              disabled
            >
              Edit Financial Statement
            </StyledButton>
          </StyledMenuItem>
          <StyledMenuItem>
            <StyledButton
              width="180px"
              onClick={() => console.log("future implementation")}
              disabled
            >
              Attach Financial Statement
            </StyledButton>
          </StyledMenuItem> */}
          {/* <StyledMenuItem>
            <StyledButton width="180px" onClick={seeAllProjects}>
              Projects
            </StyledButton>
          </StyledMenuItem> */}
        </StyledMenu>
      </>
    );
  }
};
