import React from 'react';


const ErrorAlertIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16C0 7.2 7.2 0 16 0C24.8 0 32 7.2 32 16C32 24.8 24.8 32 16 32C7.2 32 0 24.8 0 16ZM20.2 23L23 20.2L18.8 16L23 11.8L20.2 9L16 13.2L11.8 9L9 11.8L13.2 16L9 20.2L11.8 23L16 18.8L20.2 23Z" fill="#FF0000"/>
    </svg>
  )
};

export default ErrorAlertIcon;
