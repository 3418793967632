import React, { useEffect } from "react";
import { MediumText, Content, StyledArea } from "../styles";
import MobilePersonnelIcon from "../../../assets/dashboardIcons/mobilePersonnelIcon";
import MobileProjectsIcon from "../../../assets/dashboardIcons/mobileProjectsIcon";
import { useHistory } from "react-router";
import { ProfileSetup } from "./ProfileSetup";
import { References } from "./References";
import { Documents } from "./Documents";
import { FunctionArea, MobileBoxText } from "./styles";
import { StyledBox } from "../styles";
import { Wip } from "./Wip";

export const FunctionSection = ({ isMobile, userData }) => {
  const history = useHistory();

  return (
    <Content isMobile={isMobile}>
      <StyledArea marginTop="24px" isMobile={isMobile}>
        <ProfileSetup isMobile={isMobile} userData={userData} />
        <References isMobile={isMobile} />
        <Documents isMobile={isMobile} />
        <Wip isMobile={isMobile} />
        {isMobile && (
          <FunctionArea isMobile={isMobile}>
            <StyledBox
              boxHeight="45px"
              borderRadius="12px"
              isMobile={isMobile}
              onClick={() => {
                history.push("/all/projects");
              }}
              style={{ cursor: "pointer" }}
              margin="0 0 16px 0"
              flexDirection="row"
            >
              <MobileProjectsIcon />
              <MobileBoxText>Projects</MobileBoxText>
            </StyledBox>
            <StyledBox
              boxHeight="45px"
              borderRadius="12px"
              isMobile={isMobile}
              onClick={() => {
                history.push("/all/personnel");
              }}
              style={{ cursor: "pointer" }}
              flexDirection="row"
            >
              <MobilePersonnelIcon />
              <MobileBoxText>Personnel</MobileBoxText>
            </StyledBox>
          </FunctionArea>
        )}
      </StyledArea>
    </Content>
  );
};
