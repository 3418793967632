import React, { useEffect } from "react";

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper,
  StyledMenuItem,
  StyledButton,
  StyledButtonReview,
  StyledButtonContainer,
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import PledgxLogo from "../../assets/common/pledgxLogo";
import { reviewSingleCCDC } from "../../api/prequalify";

export const AddFinalizePopUp = ({
  setOpenDialog,
  openDialog,
  prequalifyData,
  isMobile,
  onSuccess,
}) => {
  const reviewCCDC = (id) => {
    reviewSingleCCDC(id, onSuccessReview, () => {});
  };

  const onSuccessReview = (res) => {
    onSuccess();
  };

  useEffect(() => {
    if (!openDialog) {
      setOpenDialog(false);
    }
  }, [openDialog]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  // add handle Go Back Button
  const handleGoBackClick = () => {
    handleClose();
  };

  // add handle Finalize Button
  const handleFinalizeClick = () => {
    reviewCCDC(prequalifyData.ccdcId);
  };

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper elevation={0} isMobile={isMobile}>
        <CloseButton aria-label="close" onClick={() => handleClose()}>
          <CloseIcon />
        </CloseButton>

        <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
        <InfoArea isMobile={isMobile} marginTop="0px">
          <FormTitle style={{ marginTop: 20, marginBottom: 20 }}>
            {"Please confirm that you want to proceed to Review"}
          </FormTitle>
        </InfoArea>

        <StyledButtonContainer>
          <StyledMenuItem>
            <StyledButton
              title="Go Back"
              width="150px"
              onClick={handleGoBackClick}
            >
              GO BACK
            </StyledButton>
          </StyledMenuItem>

          <StyledMenuItem>
            <StyledButtonReview
              title="Review"
              width="150px"
              onClick={handleFinalizeClick}
            >
              CONFIRM
            </StyledButtonReview>
          </StyledMenuItem>
        </StyledButtonContainer>
      </StyledPaper>
    </Dialog>
  );
};
