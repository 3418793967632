import React from 'react';
import { StyledTableRow } from "./styles";


export const BorderLessTableRows = (props) => {
  const {
    ccdcId,
    actions,
    actionType,
    icons,
    body,
    backgroundColor,
    pickedArray,
    setPickedArray,
    selectedNotSkip
  } = props;

  const bodyProps = { ...props };
  delete bodyProps['actions'];
  delete bodyProps['icons'];
  delete bodyProps['body'];

  if(bodyProps.id && pickedArray && !pickedArray.includes(bodyProps.id)){
    setPickedArray(pickedArray => [...pickedArray, bodyProps.id]);

  } else if (bodyProps.institutional_id && pickedArray && bodyProps.institutional_id) {
    setPickedArray(pickedArray => [...pickedArray, bodyProps.institutional_id]);

  } else if (bodyProps.rfpq_id && pickedArray && !pickedArray.includes(bodyProps.rfpq_id)) {
    setPickedArray(pickedArray => [...pickedArray, bodyProps.rfpq_id]);
  };
  
 
  return (
    <>
      <StyledTableRow backgroundColor={backgroundColor}>
        {icons && icons()}
        {body && body(bodyProps)}
        {actions && !actionType && actions(ccdcId)}
        {actions && actionType && actions(bodyProps)}
      </StyledTableRow>
    </>
  );
};
