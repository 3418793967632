import { LetterAvatar } from "components/letterAvatar";
import React, { useEffect, useState } from "react";
import ShowDetailsPopup from "pages/referenceModule/showDetailsPopup/index";
import { ReactComponent as ViewIcon } from 'assets/common/ViewIcon.svg'

import {
  StyledBox,
  StyledBoxContent,
  SmallTitle,
  DataText
} from '../styles';
import { RatingBar } from "./RatingBar";
import {
  RowContent,
  AvatarePicture,
  ColumnContent,
  ColumnContentStatus,
  StyledDivider,
  StyledProvidedContainer,
  StyledContestedContainer
} from './styles';


export const ReferenceCard = ({
  isMobile,
  data
}) => {

  const [comment, setComment] = useState(data?.institution_response || "");
  const [hasComment, setHasComment] = useState(null);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [detailsData, setDetailsData] = useState([]);

  useEffect(() => {
    setHasComment(data?.institution_response ? true : false);
  }, [data])

  const showDetails = (data) => {
    setShowDetailsPopup(true);
    setDetailsData(data);
  };


  return (
    <StyledBox boxHeight="200px" hasData={true}>
      <StyledBoxContent margin="24px 32px" flexDirection="column">
        <RowContent margin="0px 0 16px" justifyContent="space-between">
          <RowContent width="60%">
            <RatingBar rating={data?.rating} />
          </RowContent>

          <SmallTitle>
            {data?.reference_received_at}
          </SmallTitle>
        </RowContent>
        <RowContent margin="0px 0 5px" justifyContent="space-between">
          <DataText fontSize="15px" fontWeight="600">{data?.project_title}</DataText>
          <ViewIcon
            onClick={() => showDetails(data)}
            style={{ cursor: 'pointer' }}
          />
        </RowContent>
        <SmallTitle fontSize="14px">{data?.project_location}</SmallTitle>

        <DataText margin="12px 0 0 0">{data?.comment}</DataText>
        <RowContent margin="12px 0 0 0" justifyContent="flex-start">
          {data?.referee_avatar && (
            <AvatarePicture src={`data:image/png;base64,${data?.referee_avatar}`} isMobile={isMobile} />
          )}
          {!data?.referee_avatar && (
            <LetterAvatar name={`${data?.referee_first_name}${data?.referee_first_name && data?.referee_last_name ? " " : ''}${data?.referee_last_name}`} />
          )}
          <ColumnContent>
            <DataText fontSize="15px" fontWeight="600">{`${data?.referee_first_name}${data?.referee_first_name && data?.referee_last_name ? " " : ''}${data?.referee_last_name}`}</DataText>
            <DataText fontSize="15px">{data?.referee_company || "No company information"}</DataText>
          </ColumnContent>

          <RowContent justifyContent="flex-end">
            <ColumnContentStatus justifyContent='flex-end'>
              {data?.status === "contested" && (
                <StyledContestedContainer>Contested</StyledContestedContainer>
              )}

              {(data?.status === "provided" || data?.status === "updating") && (
                <StyledProvidedContainer>Provided</StyledProvidedContainer>
              )}

            </ColumnContentStatus>
          </RowContent>
        </RowContent>

        <ShowDetailsPopup
            openPopup={showDetailsPopup}
            setOpenPopup={setShowDetailsPopup}
            data={detailsData}
          />

        {hasComment && (
          <>
            <StyledDivider />
            <RowContent margin="12px 0 0 0" justifyContent="flex-start">
              {data?.company_avatar && (
                <AvatarePicture src={`data:image/png;base64,${data?.company_avatar}`} isMobile={isMobile} />
              )}
              {!data?.company_avatar && (
                <LetterAvatar name={data?.company_name} />
              )}
              <ColumnContent>
                <DataText fontSize="15px" fontWeight="600">{data?.company_name}</DataText>
              </ColumnContent>
            </RowContent>
            <RowContent margin="8px 0 0 0" justifyContent="flex-start">
              <DataText>{comment}</DataText>
            </RowContent>
          </>
        )}
      </StyledBoxContent>
    </StyledBox>
  )
};

