import React from "react";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";

import IconButton from "@material-ui/core/IconButton";
import BookmarksOutlinedIcon from "@material-ui/icons/BookmarksOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import BookmarksIcon from "@material-ui/icons/Bookmarks";

import {
  CardRoot,
  Categories,
  Category,
  CardTitle,
  LinkContainer,
  Link,
  Minutes,
  Image,
} from "./styles";
import { CategoryColor } from "../../data/consts/index";

function NewsCard({
  reverse,
  link,
  category,
  title,
  image,
  minutes,
  small,
  style,
  saved,
  onSave,
  onDelete,
}) {
  const url = new URL(link);

  return (
    <CardRoot style={style}>
      <Paper elevation={0} style={{ padding: 10 }}>
        <Categories>
          <Category color={CategoryColor[category]}>{category}</Category>
        </Categories>
        {!small && (
          <>
            {reverse && image && <Image src={image}></Image>}
            <CardTitle>{title}</CardTitle>
            <LinkContainer>
              {!saved ? (
                <IconButton
                  style={{ maxWidth: 50, marginRight: 5 }}
                  size="small"
                  onClick={onSave}
                >
                  <BookmarksOutlinedIcon style={{ width: 17, height: 17 }} />
                </IconButton>
              ) : (
                <IconButton
                  style={{ maxWidth: 50, marginRight: 5 }}
                  size="small"
                  onClick={onDelete}
                >
                  <BookmarksIcon style={{ width: 17, height: 17 }} />
                </IconButton>
              )}
              <Link href={link} target="_blank" rel="noopener noreferrer">
                {url.hostname}
              </Link>
              <Divider
                orientation="vertical"
                flexItem
                variant="middle"
              ></Divider>
              <Minutes>{minutes} min read</Minutes>
            </LinkContainer>
            {!reverse && image && <Image src={image}></Image>}
          </>
        )}
        {small && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <CardTitle small>{title}</CardTitle>
                <LinkContainer>
                  {!saved ? (
                    <IconButton
                      style={{ maxWidth: 50, marginRight: 5 }}
                      size="small"
                      onClick={onSave}
                    >
                      <BookmarksOutlinedIcon
                        style={{ width: 15, height: 15 }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      style={{ maxWidth: 50, marginRight: 5 }}
                      size="small"
                      onClick={onDelete}
                    >
                      <BookmarksIcon style={{ width: 15, height: 15 }} />
                    </IconButton>
                  )}
                  <Link href={link} target="_blank" rel="noopener noreferrer">
                    {url.hostname}
                  </Link>
                  <Divider
                    orientation="vertical"
                    flexItem
                    variant="middle"
                  ></Divider>
                  <Minutes>{minutes} min read</Minutes>
                </LinkContainer>
              </div>
              {image && <Image small src={image}></Image>}
            </div>
          </>
        )}
      </Paper>
    </CardRoot>
  );
}

export default NewsCard;
