import { AlertPopup } from "../../../../components/alertPopup/alertPopup";
import Spinner from "../../../../components/spinner";
import RfpqPreview from "../../../../pages/rfpqPreview";
import React from "react";
import { StyledCloseButton } from "../../../../components/closeButton/styles";
import CloseIcon from "@material-ui/icons/Close";
import { PopupContent } from "components/alertPopup/styles";
import { Popup } from "components/popup";
import { padding } from "@mui/system";
import PledgxLogo from "assets/common/pledgxLogo";

const RfpqReviewPopUp = ({ openPopup, setOpenPopup, rfpqId, setRfpqId }) => {
  const closeButton = (
    <StyledCloseButton
      aria-label="close"
      onClick={() => {
        setOpenPopup(false);
        setRfpqId(null);
      }}
    >
      <CloseIcon />
    </StyledCloseButton>
  );

  const bodyContent = (
    <div style={{ height: "100%" }}>
      {rfpqId && <RfpqPreview rfpqId={rfpqId} />}
    </div>
  );

  const icon = <PledgxLogo />;
  const content = () => {
    return (
      <>
        {closeButton}
        <PopupContent>
          {icon}
          {bodyContent}
        </PopupContent>
      </>
    );
  };
  return (
    <Popup
      open={openPopup}
      dialogContent={content()}
      width={"800px"}
      height={"600px"}
    />
  );
};

export default RfpqReviewPopUp;
