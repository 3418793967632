import React from "react";
import { useHistory } from "react-router-dom";
import { FunctionalButton } from '../../../../components/functionalButton';
import { StyledButtonContainer, StyledButtonArea } from './styles';
import { postProjectInfo } from '../../../../api/buyers';


export const ProjectButtons = ({ data, setData, feature, disabled }) => {
  let history = useHistory();
  const project_number = data? data.project_details?.project_number : '';

  const handleSave = async () => {
    await postProjectInfo("save", data)
          .then(data => {
            history.push(`/buyers/projects/`);
          })
          .catch(err => {});
  };

  const handlePublish = async () => {
    await postProjectInfo("publish", data)
          .then(data => {
            history.push(`/buyers/projects/`);
          })
          .catch(err => {});
  };

  const handleDiscard = () => {
    history.push('/buyers/projects/');
  };

  const handleViewSubmissions = () => {
    history.push(`/buyers/submissions/${project_number}`);
  };

  let buttonText = 'Publish';
  if(feature === 'amend') {
    buttonText = 'Save & Publish';
  }

  let discardText = 'Discard Changes';
  if(feature === 'view') {
    discardText = ' Return To List';
  }

  return (
    <StyledButtonArea>
         <StyledButtonContainer className="oneThirdInputWrap">
         <FunctionalButton
             buttonTitle={discardText}
             handleButton={handleDiscard}
             width="100%"
             isReversedColor={true}
             marginRight="0px"
           />
       </StyledButtonContainer>
      { (feature === 'edit' || feature === 'new') && (
         <StyledButtonContainer className="oneThirdInputWrap">
         <FunctionalButton
             buttonTitle="Save"
             handleButton={handleSave}
             width="100%"
             marginRight="0px"
             disabled={disabled}
           />
       </StyledButtonContainer>
      )}
      { feature !== 'view' && (
        <StyledButtonContainer className="oneThirdInputWrap">
        <FunctionalButton
          buttonTitle={buttonText}
          handleButton={handlePublish}
          width="100%"
          marginRight="0px"
          disabled={disabled}
        />
      </StyledButtonContainer>
      )}
      { feature === 'view' && (
        <StyledButtonContainer className="oneThirdInputWrap">
        <FunctionalButton
          buttonTitle="View Submissions"
          handleButton={handleViewSubmissions}
          width="100%"
          marginRight="0px"
        />
      </StyledButtonContainer>
      )}
  </StyledButtonArea>
  )
}
