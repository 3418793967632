
import React, { useState, useEffect } from 'react';

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper,
  StyledTextField,
  LabelText
} from './styles';

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import PledgxLogo from '../../assets/common/pledgxLogo';
import { FunctionalButton } from '../functionalButton';

export const AddProjectRemarkPopup = ({
  setOpenDialog,
  openDialog,
  data,
  projects,
  setProjects,
  handleButtonFunc,
  isMobile,
  notShowResume,
  label,
  buttonLabel,
  noEmailEdit
}) => {
  const [editIndex, setEditIndex] = useState(null);
  const [validSubmit, setValidSubmit] = useState(false);
  const [projectRemark, setProjectRemark] = useState(data?.remark || '');


  useEffect(() => {
    if (!openDialog) {
      setOpenDialog(false);
    }
  }, [openDialog]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleprojectRemarkChange = (event) => {
    setProjectRemark(event.target.value);
  };

  const addProjectRemark = (projectRemark) => {
    const temp = [...projects];
    temp[data.index]["remark"] = projectRemark;
    setProjects(temp);
  };



  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper
        elevation={0}
        isMobile={isMobile}
      >
        <CloseButton
          aria-label="close"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </CloseButton>

        <PledgxLogo width={isMobile ? 45 : ''} height={isMobile ? 45 : ''} />
        <FormTitle style={{ marginTop: 10 }}>{label || 'Add Your Project Remark'}</FormTitle>
        <InfoArea isMobile={isMobile} marginTop="0px">

          <StyledTextField
            multiline
            rows={5}
            placeholder={"Project Remark"}
            variant="outlined"
            value={projectRemark}
            onChange={handleprojectRemarkChange}
            noCheckIcon={true}
            noErrorBorder={true}
          />

        </InfoArea>

        <FunctionalButton
          disabled={validSubmit}
          width="100%"
          marginTop="16px"
          marginRight="0px"
          handleButton={() => {
            addProjectRemark(projectRemark);
            setOpenDialog(false);
          }}
          buttonTitle={buttonLabel || "ADD REMARK"}
        />
      </StyledPaper>
    </Dialog>
  )

}