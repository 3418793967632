import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { userSelector } from "data/selectors/user";
import { contactSelector } from "data/selectors/contact";
import {
  filterUsers,
  updateRole,
  updateClassification,
  addNewPledgxUser,
  editNewPledgxUser,
} from "api/users";
import { Roles, Classification } from "constants.js";

import { Logo } from "components/logo";
import UserAvatar from "components/userAvatar";
import IncrementalSearchPickList from "components/incrementalSearchPickList";
import SelectDropdown from "components/selectDropdown";
import { AddPersonnelPopup } from "components/addPersonnelPopup";
import { StyledTableCell } from "components/filesListTable/styles";
import { ThreeDotsPopupMenu } from "components/threeDotsPopupMenu";
import { AlertPopup } from "components/alertPopup/alertPopup";
import {
  StyledPageContainer,
  StyledTitle,
  StyledCompanyContainer,
  LogoDiv,
  StyledCompanyInfo,
  StyledTitle2,
  StyledUserManagementSelectionContainer,
} from "pages/rbacPages/styles.js";

export const UserManagement = () => {
  const current_user = useSelector(userSelector);
  const [filteredData, setFilteredData] = useState([]);
  const [isOpenAddUser, setIsOpenAddUser] = useState(false);
  const [isOpenEditUser, setIsOpenEditUser] = useState(false);
  const [openExceedPopup, setOpenExceedPopup] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [data, setData] = useState({});
  const [filteredUser, setFilteredUser] = useState([]);
  const contactData = useSelector(contactSelector);
  const usersLimit = contactData?.user_access_control?.number_of_users;
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const headers = [
    { title: "" },
    { title: "Employee" },
    { title: "Position" },
    { title: "Role" },
    { title: "Class" },
    { title: "" },
  ];

  const updateFilteredData = (updatedData, index) => {
    let updatedFilter = [...filteredData];
    updatedFilter[index] = updatedData;
    setFilteredData(updatedFilter);
  };

  const handleRoleChange = (index, id, newRole) => {
    updateRole(id, newRole, updateFilteredData, index);
  };

  const handleClassChange = (index, id, newClass) => {
    updateClassification(id, newClass, updateFilteredData, index);
  };

  const addUser = (formData) => {
    addNewPledgxUser(formData, () => {
      setRerender(true);
      handleFilterUsers();
    }, onFailureAddNewUser);    
  };


  const onFailureAddNewUser = (error) => {
    if (error?.status == 409) {
      setIsErrorMessage(true);
      setErrorMessage(error?.data?.message);
    }
  };

  const openEditUser = (firstName, lastName, email, position) => {
    const data = {
      firstName,
      lastName,
      email,
      position,
    };

    setData(data);
    setIsOpenEditUser(true);
  };

  const handleEditUser = (formData) => {
    editNewPledgxUser(formData, () => {
      setRerender(true);
      setData({});
    });
  };

  // User Access Control (lines 92-113)

  useEffect(() => {
    handleFilterUsers();
  }, []);

  const handleFilterUsers = async () => {
    await filterUsers("")
      .then((data) => {
        setFilteredUser(data);
        setFilteredData(
          data?.filter(
            (obj) =>
              obj?.email !== contactData?.email &&
              obj?.first_name !== contactData?.first_name &&
              obj?.last_name !== contactData?.last_name
          )
        );
      })
      .catch((err) => { });
  };

  const handleAdd = () => {
    if (filteredUser && filteredUser.length >= usersLimit) {
      setOpenExceedPopup(true);
    } else {
      setIsOpenAddUser(true);
    }
  };

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCell component={"tr"} width="125px">
          <UserAvatar
            avatar={bodyData?.avatar}
            firstName={bodyData?.first_name}
            lastName={bodyData?.last_name}
          />
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="150px">
          {bodyData.first_name} {bodyData.last_name}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="150px">
          {bodyData.position}
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="100px">
          <SelectDropdown
            options={Roles}
            value={bodyData?.roles?.[0]}
            handleSelect={(e) =>
              handleRoleChange(bodyData.index, bodyData.id, e.value)
            }
          />
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="100px">
          <SelectDropdown
            options={Classification}
            value={bodyData.class}
            handleSelect={(e) =>
              handleClassChange(bodyData.index, bodyData.id, e.value)
            }
          />
        </StyledTableCell>
        <StyledTableCell component={"tr"} width="50" align="left">
          <ThreeDotsPopupMenu
            handleModify={() =>
              openEditUser(
                bodyData.first_name,
                bodyData.last_name,
                bodyData.email,
                bodyData.position
              )
            }
          />
        </StyledTableCell>
      </>
    );
  };

  return (
    <>
      {current_user?.roles?.[0] === "admin" && (
        <StyledUserManagementSelectionContainer>
          {/* <StyledTitle>
            User Management
          </StyledTitle>
          <StyledCompanyContainer>
            <LogoDiv>
              <Logo logo={current_user?.institution?.logo} variant="rounded" />
            </LogoDiv>
            <StyledCompanyInfo>
              <StyledTitle>{current_user?.institution?.name}</StyledTitle>
              <StyledTitle2>{current_user?.institution?.address}</StyledTitle2>
            </StyledCompanyInfo>
          </StyledCompanyContainer> */}

          <IncrementalSearchPickList
            filterApi={filterUsers}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
            body={body}
            headers={headers}
            conciseLength={5.5}
            addButtonTitle="Add New User"
            add={handleAdd}
            marginTop={"10px"}
            placeholder={"Name, email, role, etc. ..."}
            rerender={rerender}
            setRerender={setRerender}
            height="700px"
            tableWidth="900px"
            internalSearch={true}
          />

          <AddPersonnelPopup
            setOpenDialog={setIsOpenAddUser}
            openDialog={isOpenAddUser}
            handleButtonFunc={addUser}
            label="Add Your Users"
            buttonLabel="ADD USER"
            notShowResume
          />
          <AddPersonnelPopup
            setOpenDialog={setIsOpenEditUser}
            openDialog={isOpenEditUser}
            setData={setData}
            data={data}
            handleButtonFunc={handleEditUser}
            label="Add Your Users"
            buttonLabel="ADD USER"
            noEmailEdit
            notShowResume
          />

          <AlertPopup
            openPopup={openExceedPopup}
            setOpenPopup={setOpenExceedPopup}
            headerText="You have exceeded the user limit."
            subHeaderText={`You can add a maximum of ${usersLimit} users.`}
            showButton={true}
          />


          <AlertPopup
            openPopup={isErrorMessage}
            setOpenPopup={setIsErrorMessage}
            headerText="User Add Alert"
            subHeaderText={`${errorMessage}`}
            showButton={false}
          />

        </StyledUserManagementSelectionContainer>
      )}
    </>
  );
};

// export default UserManagement;
