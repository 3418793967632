import React, {useState} from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FunctionalButton } from 'components/functionalButton';

import {
  Title,
  PopupContainer
} from './styles';
import { Popup } from 'components/popup';
import { CloseButton } from "components/closeButton";
import { Panel1 } from "./Panel1";
import { Panel2 } from "./Panel2";
import { Panel3 } from "./Panel3";
import { Panel4 } from './Panel4';
import { Panel5 } from "./Panel5";

export const AddRefPopup = ({
  openPopup,
  setOpenPopup,
  setOpenSuccessPopup,
  isMobile
}) => {

  const handleDone = () => {
    setPanel(1);
    setOpenPopup(false);
  };

  const [panel, setPanel] = useState(1)
  const [projectId, setProjectId] = useState(0)
  return (
    <Popup
        open={openPopup}
        setOpen={setOpenPopup}
        width={isMobile ? "100%" : "1162px"}
        height={isMobile ? "100vh" : "700px"}
        dialogContent={(
          <>
          <CloseButton handleClose={handleDone} />
            <PopupContainer>
              <Title margin="0 0 24px">Get a Reference</Title>
              {panel == 1 && (
                <Panel1 setPanel={setPanel} isMobile={isMobile}/>
              )}
              {panel == 2 && (
                <Panel2 
                  setPanel={setPanel} 
                  projectId={projectId}
                  setProjectId={setProjectId}
                  isMobile={isMobile}/>
              )}
              {panel == 3 && (
                <Panel3 
                  setPanel={setPanel} 
                  projectId={projectId}
                  setOpen={setOpenPopup}
                  setOpenSuccessPopup={setOpenSuccessPopup}
                  isMobile={isMobile}/>
              )}
              {panel == 4 && (
                <Panel4
                  setPanel={setPanel} 
                  setOpen={setOpenPopup}
                  setOpenSuccessPopup={setOpenSuccessPopup}
                  isMobile={isMobile}/>
              )}
              {panel == 5 && (
                <Panel5
                  setPanel={setPanel} 
                  setOpen={setOpenPopup}
                  setOpenSuccessPopup={setOpenSuccessPopup}
                  isMobile={isMobile}/>
              )}
            </PopupContainer>
        </>
        )}
      />

  )
}
