import React, { useState, useEffect } from 'react';
import { useMediaQuery } from "react-responsive";
import { SectionTitle } from '../SectionTitle';
import { COLORS } from '../../../../constants';
import { StyledWeightsContainer, StyledInputContainer, StyledSectionContainer } from './styles';
import Slider from '../../../../components/slider';
import { InputWithLabel } from '../../../../components/inputWithLabel';


export const WeightAttributes = ({ data, setData }) => {
  const isMobile = useMediaQuery({ maxWidth: 748 });

  const [financialWeight, setFinancialWeight] = useState(data.attribute_weights?.financial || 1);
  const [generalExpWeight, setGeneralExpWeight] = useState(data.attribute_weights?.generalExp || 1);
  const [reputationWeight, setReputationWeight] = useState(data.attribute_weights?.reputation || 1);
  const [healthWeight, setHealthWeight] = useState(data.attribute_weights?.health || 1);
  const [projectWeight, setProjectWeight] = useState(data.attribute_weights?.project || 1);
  const [personnelWeight, setPersonnelWeight] = useState(data.attribute_weights?.personnel || 1);
  const [recentExpWeight, setRecentExpWeight] = useState(data.attribute_weights?.recentExp || 1);
  const [ageWeight, setAgeWeight] = useState(data.attribute_weights?.age || 1);
  const [businessWeight, setBusinessWeight] = useState(data.attribute_weights?.business || 1);
  const [locationWeight, setLocationWeight] = useState(data.attribute_weights?.location || 1);
  const [imageWeight, setImageWeight] = useState(data.attribute_weights?.image || 1);

  if (data && !data.attribute_weights) {
    data['attribute_weights'] = {}
  }
  useEffect(() => {
      const updatedData = { ...data };

      updatedData['attribute_weights']['financial'] = financialWeight;
      updatedData['attribute_weights']['generalExp'] = generalExpWeight;
      updatedData['attribute_weights']['reputation'] = reputationWeight;
      updatedData['attribute_weights']['health'] = healthWeight;
      updatedData['attribute_weights']['project'] = projectWeight;
      updatedData['attribute_weights']['personnel'] = personnelWeight;
      updatedData['attribute_weights']['recentExp'] = recentExpWeight;
      updatedData['attribute_weights']['age'] = ageWeight;
      updatedData['attribute_weights']['business'] = businessWeight;
      updatedData['attribute_weights']['location'] = locationWeight;
      updatedData['attribute_weights']['image'] = imageWeight;

      setData(updatedData);
  }, [financialWeight,
      generalExpWeight,
      reputationWeight,
      healthWeight,
      projectWeight,
      personnelWeight,
      recentExpWeight,
      ageWeight,
      businessWeight,
      locationWeight,
      imageWeight]);

  return (
    <>
      <SectionTitle title="Weigh the Attributes" color={COLORS.darkGrey2} />
      <StyledWeightsContainer>
        <StyledSectionContainer>
          <Slider
            value={financialWeight}
            setValue={setFinancialWeight}
            header={'Financial Standing and Record'}
          />
          <StyledInputContainer isMobile={isMobile}>
            <InputWithLabel
              value={financialWeight}
              setValue={setFinancialWeight}
              label='%'
            />
          </StyledInputContainer>
        </StyledSectionContainer>

        <StyledSectionContainer>
          <Slider
            value={generalExpWeight}
            setValue={setGeneralExpWeight}
            header={'General Experience'}
          />
          <StyledInputContainer isMobile={isMobile}>
            <InputWithLabel
              value={generalExpWeight}
              setValue={setGeneralExpWeight}
              label='%'
            />
          </StyledInputContainer>
        </StyledSectionContainer>

        <StyledSectionContainer>
          <Slider
            value={reputationWeight}
            setValue={setReputationWeight}
            header={'Reputation for Completion on Time'}
          />
          <StyledInputContainer isMobile={isMobile}>
            <InputWithLabel
              value={reputationWeight}
              setValue={setReputationWeight}
              label='%'
            />
          </StyledInputContainer>
        </StyledSectionContainer>

        <StyledSectionContainer>
          <Slider
            value={healthWeight}
            setValue={setHealthWeight}
            header={'Health & Safety Record'}
          />
          <StyledInputContainer isMobile={isMobile}>
            <InputWithLabel
              value={healthWeight}
              setValue={setHealthWeight}
              label='%'
            />
          </StyledInputContainer>
        </StyledSectionContainer>

        <StyledSectionContainer>
          <Slider
            value={projectWeight}
            setValue={setProjectWeight}
            header={'Project Value'}
          />
          <StyledInputContainer isMobile={isMobile}>
            <InputWithLabel
              value={projectWeight}
              setValue={setProjectWeight}
              label='%'
            />
          </StyledInputContainer>
        </StyledSectionContainer>

        <StyledSectionContainer>
          <Slider
            value={personnelWeight}
            setValue={setPersonnelWeight}
            header={'Personnel/Team Expertise'}
          />
          <StyledInputContainer isMobile={isMobile}>
            <InputWithLabel
              value={personnelWeight}
              setValue={setPersonnelWeight}
              label='%'
            />
          </StyledInputContainer>
        </StyledSectionContainer>

        <StyledSectionContainer>
          <Slider
            value={recentExpWeight}
            setValue={setRecentExpWeight}
            header={'Recent Experience in a Similar Project'}
          />
          <StyledInputContainer isMobile={isMobile}>
            <InputWithLabel
              value={recentExpWeight}
              setValue={setRecentExpWeight}
              label='%'
            />
          </StyledInputContainer>
        </StyledSectionContainer>

        <StyledSectionContainer>
          <Slider
            value={ageWeight}
            setValue={setAgeWeight}
            header={'Age of Organization'}
          />
          <StyledInputContainer isMobile={isMobile}>
            <InputWithLabel
              value={ageWeight}
              setValue={setAgeWeight}
              label='%'
            />
          </StyledInputContainer>
        </StyledSectionContainer>

        <StyledSectionContainer>
          <Slider
            value={businessWeight}
            setValue={setBusinessWeight}
            header={'Previous Business Relationship'}
          />
          <StyledInputContainer isMobile={isMobile}>
            <InputWithLabel
              value={businessWeight}
              setValue={setBusinessWeight}
              label='%'
            />
          </StyledInputContainer>
        </StyledSectionContainer>

        <StyledSectionContainer>
          <Slider
            value={locationWeight}
            setValue={setLocationWeight}
            header={'Location of Firm'}
          />
          <StyledInputContainer isMobile={isMobile}>
            <InputWithLabel
              value={locationWeight}
              setValue={setLocationWeight}
              label='%'
            />
          </StyledInputContainer>
        </StyledSectionContainer>

        <Slider
          value={imageWeight}
          setValue={setImageWeight}
          header={'Image of Organization'}
        />
        <StyledInputContainer isMobile={isMobile}>
          <InputWithLabel
            value={imageWeight}
            setValue={setImageWeight}
            label='%'
          />
        </StyledInputContainer>
      </StyledWeightsContainer>
    </>
  );
};
