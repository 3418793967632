import React, { useState } from "react";
import Input from "components/inputField";
import { numberWithCommas, removeComma } from "../../common/numberFormat";
import DateTimePickerField from "components/dateTimePickerField";

export const BondingLimits = ({ addData, setAddData }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Input
        type="text"
        id="inputWrap"
        label="Aggregate Limit"
        placeholder="1,234,678"
        labelSize="12px"
        fontSize="18px"
        paddingRight="12px"
        value={numberWithCommas(addData?.bond_aggregate_limit)}
        setValue={(val) => setAddData("bond_aggregate_limit", removeComma(val))}
      />
      <Input
        type="text"
        id="inputWrap"
        label="Single Project Limit"
        placeholder="1,234,678"
        labelSize="12px"
        fontSize="18px"
        paddingRight="12px"
        value={numberWithCommas(addData?.bond_single_project_limit)}
        setValue={(val) =>
          setAddData("bond_single_project_limit", removeComma(val))
        }
      />
      {/* <Input
        id="inputWrap"
        label="Next Review Date"
        type="date"
        //placeholder="abcs"
        labelSize="12px"
        fontSize="18px"
        paddingRight="10px"
        value={addData?.bond_next_review_date || ""}
        setValue={(val) => {
          setAddData("bond_next_review_date", val);
        }}
      /> */}
      <DateTimePickerField
        showDateField={true}
        dateWidth="60%"
        paddingRight="16px"
        dateLabel="Next Review Date"
        dateValue={
          addData?.bond_next_review_date ? addData?.bond_next_review_date : ""
        }
        setDateValue={(val) => {
          setAddData("bond_next_review_date", val);
        }}
      />
    </div>
  );
};
