import styled from 'styled-components';

import PlusSignIcon from '../../assets/buyers/plusSignIcon.js';
import MinusSignIcon from '../../assets/buyers/minusSignIcon.js';

import { Accordion } from '@material-ui/core';
import { COLORS, TYPOGRAPHY } from '../../constants';


export const StyledFilledAccordion = styled(Accordion)`
  && {
    box-shadow: none;
    width: 100%;
    background-color: transparent;
  }

  &&:before {
    top: 0;
    height: 0;
  }

  &&.MuiAccordion-root {
    margin-top: 16px;
  }

  &&.MuiAccordion-root.Mui-expanded {
    margin: 16px 0 0 0;
    padding: 0;
  }
  
  .MuiAccordionSummary-root.Mui-expanded{
    min-height: 64px;
  }
  
  .MuiIconButton-root {
    color: #eeeee;
  }

  .MuiTypography-root {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-weight: 600;
  }

  .MuiAccordionSummary-root {
    padding: 0;
    background-color: white;
    border-radius: 6px;
    border: 1px solid #EBE9F1;
    min-height: 64px;
  }

  .MuiAccordionSummary-expandIcon {
    order: -1;
    margin:0;
    padding: 0;
  }

  .MuiTouchRipple-root:last-child {
    color: ${COLORS.accordionFill};
  }

  .MuiIconButton-label {
    margin-left: 12px;
    margin-right: 12px;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }
`;
