import React from "react";
import { StyledButtonText, StyledIconButton } from './styles';


export const BackButton = ({ handleClick, margin, buttonText }) => {

  return (
    <>
      <StyledIconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        margin={margin}
      >
        <StyledButtonText>{buttonText? `< ${buttonText}` : "< BACK"}</StyledButtonText>
      </StyledIconButton>
    </>
  )
}
