import React from "react";

const ComplianceIcon = () => {
  return (
    <svg
      width="102"
      height="102"
      viewBox="0 0 102 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2903_8659)">
        <rect
          x="8"
          y="4"
          width="86"
          height="86"
          rx="16"
          fill="#FFDB98"
          shape-rendering="crispEdges"
        />
        <path
          d="M69.5497 55.2716C68.7896 55.2716 68.1711 54.6473 68.1711 53.88V44.0502C68.1711 36.2895 63.0913 29.7039 56.1116 27.5027C56.3214 26.9129 56.4323 26.2867 56.4323 25.6496C56.4323 22.6263 53.9955 20.1666 51.0001 20.1666C48.0046 20.1666 45.5678 22.6263 45.5678 25.6497C45.5678 26.2918 45.6765 26.917 45.8849 27.5039C38.9071 29.7062 33.829 36.2909 33.829 44.0503V53.8801C33.829 54.6474 33.2105 55.2717 32.4504 55.2717C29.4105 55.2717 26.9375 57.768 26.9375 60.8362C26.9375 63.9045 29.4105 66.4007 32.4504 66.4007H45.3111C45.1869 66.8845 45.1219 67.3871 45.1219 67.9001C45.1219 71.1717 47.7589 73.8333 51.0001 73.8333C54.2412 73.8333 56.8782 71.1717 56.8782 67.9001C56.8782 67.3872 56.8132 66.8846 56.6889 66.4007H69.5496C72.5895 66.4007 75.0625 63.9045 75.0625 60.8362C75.0627 57.7679 72.5896 55.2716 69.5497 55.2716ZM48.6993 25.6497C48.6993 24.3692 49.7314 23.3274 51.0001 23.3274C52.2687 23.3274 53.3008 24.3692 53.3008 25.6497C53.3008 26.0696 53.1883 26.4783 52.979 26.8344C52.3295 26.7588 51.6693 26.7186 51.0001 26.7186C50.3315 26.7186 49.6721 26.7587 49.0232 26.8342C48.8136 26.4791 48.6993 26.0737 48.6993 25.6497ZM53.7469 67.9C53.7469 69.4288 52.5146 70.6724 51.0002 70.6724C49.4857 70.6724 48.2535 69.4288 48.2535 67.9C48.2535 67.3599 48.4061 66.8438 48.6895 66.4006H53.3108C53.5943 66.8438 53.7469 67.3599 53.7469 67.9ZM69.5497 63.2399H32.4504C31.1373 63.2399 30.069 62.1615 30.069 60.8361C30.069 59.5107 31.1373 58.4323 32.4504 58.4323C34.9372 58.4323 36.9605 56.3902 36.9605 53.88V44.0502C36.9605 36.2363 43.2586 29.8792 51.0001 29.8792C58.7415 29.8792 65.0396 36.2363 65.0396 44.0502V53.88C65.0396 56.3902 67.0629 58.4323 69.5497 58.4323C70.8628 58.4323 71.9311 59.5107 71.9311 60.8361C71.9313 62.1615 70.8628 63.2399 69.5497 63.2399Z"
          fill="white"
          stroke="white"
          stroke-width="0.75"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2903_8659"
          x="0"
          y="0"
          width="102"
          height="102"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2903_8659"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2903_8659"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ComplianceIcon;
