import React, { useState, useEffect } from "react";
import SelectDropdown from '../selectDropdown';
import { getSubCategories, getClassUNSPC } from "api/profile.js";

import {
  StyleList,
  InfoArea,
} from './styles';


export const CategoriesRow = ({
  marginTop,
  data,
  setData,
  index,
  categories,
  mainSelected,
  marginTopSecondary,
  attribute,
  isMobile,
  readOnly,
  initialSubCategory,
  containsUNSPC,
  categoriesStandard,
  categoriesUNSPC
}) => {
  let listOneOptions = [];
  const [categoriesStandardData, setCategoriesStandardData] = useState(data?.categories_list || categoriesStandard);
  const [categoriesUNSPCData, setCategoriesUNSPCData] = useState(data?.family_list || categoriesUNSPC);


  useEffect(() => {
    if (containsUNSPC !== data?.is_unspc_category_type) {
      setMain('');
      setSub('');
      setOptions([]);
    }
  }, [containsUNSPC, data?.is_unspc_category_type]);

  
  
  const fetchSubcategories = (selectedMainCategory) => {
    const qData = { qData: selectedMainCategory };
    if (containsUNSPC === true) {
      getClassUNSPC(qData, (res) => {
        setOptions(res);
        listOneOptions = options.map((option) => ({
          value: option,
          label: option,
        }));

      }, () => {
        console.error("Error fetching sub-categories");
      });
    } else {
      getSubCategories(qData, (res) => {
        setOptions(res);
        listOneOptions = options.map((option) => ({
          value: option,
          label: option,
        }));

      }, () => {
        console.error("Error fetching sub-categories");
      });
    }
  };


  const setInitialOption = () => {
    let result = [];
    if (main?.length > 0) {
      if (initialSubCategory?.length > 0) {
        result = initialSubCategory.find(item => item?.main_categories === main);
      }

      if (result) {
        const subCategories = result?.sub_categories;
        return subCategories;
      } else {
        return [];
      }
    }
    return [];
  }

  const setInitialMainOptions = (props) => {
    let temp = props;
    mainSelected?.map((item) => {
      temp = temp.filter(x => x.value !== item || x.value == main);
    })
    return temp;
  }
  const [main, setMain] = useState(categories ? categories.main_categories : '');
  const [sub, setSub] = useState(categories ? categories.sub_categories : '');
  const [options, setOptions] = useState(setInitialOption());
  const categoriesInfo = containsUNSPC === true ? categoriesUNSPCData : categoriesStandardData;
  const [mainOptions, setMainOptions] = useState(setInitialMainOptions(categoriesInfo));
  const [selectedCategories, setSelectedCategories] = useState({ ...categories });

  if (!selectedCategories.index) {
    selectedCategories["index"] = index;
  }

  useEffect(() => {
    let tmp = data?.categories ? data?.categories : [];
    const indexInCategories = tmp.findIndex(x => x.index == index);
    if (indexInCategories != -1) {
      if (selectedCategories['main_categories']?.length == 0) {
        tmp = tmp.filter(x => x.index !== index)
      } else {
        tmp[indexInCategories] = selectedCategories;
      }
    } else {
      tmp.push(selectedCategories);
    }

    setData(attribute || 'categories', tmp);
  }, [selectedCategories])

  const [disabled, setdisabled] = useState(options?.length > 0 ? false : true)

  const handleMain = (props) => {
    setMain(props.value);

    if (props.value.length > 0) {
      setSub('');
      setdisabled(false);
      if (props.value) {
        fetchSubcategories(props.value);
      } else {
        setOptions([]);
        setdisabled(true);
        setSub('');
        const tmp = { ...selectedCategories, main_categories: props.value, sub_categories: [] }
        setSelectedCategories(tmp)
        return;
      }
    } else {
      setdisabled(true);
      setOptions([]);
      setSub('');
      setMain('');
      const tmp = { ...selectedCategories, main_categories: props.value, sub_categories: [] }
      setSelectedCategories(tmp);
      return;
    }

    const tmp = { ...selectedCategories, main_categories: props.value }
    setSelectedCategories(tmp)
  }

  const handleSub = (props) => {
    setSub(props.value);
    const tmp = { ...selectedCategories, sub_categories: props.value }
    setSelectedCategories(tmp);
  }

  return (
    <InfoArea isMobile={isMobile} marginTop={marginTop || "-16px"} noJustifyContent={true}>
      <StyleList marginTop={marginTopSecondary} className={"inputWrap"}>
        <SelectDropdown
          placeholder={readOnly ? '' : 'Select one'}
          value={main}
          label={containsUNSPC === false ? "Categories/Family" : "Categories/Family"}
          options={mainOptions}
          handleSelect={handleMain}
          search={true}
          multiple={false}
          width="100%"
          height="50px"
          hasLabel="Category"
          hasData={main?.length > 0}
          clearable={true}
          readOnly={readOnly}
        />
      </StyleList>

      <StyleList marginTop={marginTopSecondary} className={"inputWrap last"}>
        <SelectDropdown
          placeholder={readOnly ? '' : 'Select multiple'}
          value={sub}
          label={containsUNSPC === false ? "Subcategories/Class" : "Subcategories/Class"}
          options={options}
          handleSelect={handleSub}
          search={true}
          multiple={true}
          width="100%"
          height="50px"
          hasLabel="Sub-category"
          hasData={sub?.length > 0}
          disabled={disabled}
          readOnly={readOnly}
        />
      </StyleList>
    </InfoArea>
  )
}
