import React, { useState } from "react";

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Input from "../inputField";
import PledgxLogo from "../../assets/common/pledgxLogo";
import { FunctionalButton } from "../functionalButton";
import { addInsuranceType } from "../../data/actions/dataMaintenance";
import { useDispatch } from "react-redux";
import { SuccessPopup } from "../../pages/adminToolsDashboard/SuccessPopup";

export const AddInsuranceTypePopup = ({
  setOpenDialog,
  openDialog,
  isMobile,
  label,
  buttonLabel
}) => {
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);


  const handleSave = () => {
    dispatch(
      addInsuranceType(
        data,
        () => {
          setOpenSuccessPopup(true);
          setData({});
        },
        () => { }
      )
    );
  };


  // useEffect(() => {
  //   setData({ reportView: filteredData });
  // }, [filteredData]);


  const handleClose = () => {
    setOpenDialog(false);
  };


  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper elevation={0} isMobile={isMobile}>
        <CloseButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
        <FormTitle style={{ marginTop: 10 }}>
          {label || "Add Insurance Type"}
        </FormTitle>
        <InfoArea isMobile={isMobile} marginTop="0px">
          <Input
            id="inputWrap"
            label="Insurance Type"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.insurance_type}
            setValue={(val) => setData({ ...data, insurance_type: val })}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
          />

          <Input
            id="inputWrap"
            label="Short Form"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.short_form}
            setValue={(val) => {
              setData({ ...data, short_form: val });
            }}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
          />


        </InfoArea>

        <FunctionalButton
          width="90%"
          marginTop="16px"
          marginRight="10px"
          handleButton={() => {
            handleSave();
          }}
          buttonTitle={buttonLabel || "ADD"}
        />
        <SuccessPopup
          setOpenPopup={setOpenSuccessPopup}
          openPopup={openSuccessPopup}
          isMobile={isMobile}
        />
      </StyledPaper>
    </Dialog>
  );
};
