import React, { useState } from "react";
import PledgxLogo from '../../../../assets/common/pledgxLogo';
import Step1 from "../../../../assets/common/addProjectIcons/step1";
import Step2 from "../../../../assets/common/addProjectIcons/step2";
import Step3 from "../../../../assets/common/addProjectIcons/step3";
import Step4 from "../../../../assets/common/addProjectIcons/step4";
import { StepperProgress } from '../../../../components/stepperProgress';

import { PopupContent } from './styles'

export const Steps = ({

 }) => {
   const [step, setStep] = useState(0);
   const iconList = [Step1, Step2, Step3, Step4];
   const stepLabels = [
     "Step 1",
     "Step 2",
     "Step 3",
     "Step 4",
   ];

  return(
    <>
    <PopupContent>
      <PledgxLogo/>
      <StepperProgress
        activeStep={step}
        setActiveStep={setStep}
        iconList={iconList}
        stepLabels={stepLabels}
        connectorWidth="60px"
      />
    </PopupContent>
  </>
  )
 }
