import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { checkAuth, setCurrentUser } from '../data/actions/user';
import { userSelector } from '../data/selectors/user';

import Error from '../pages/error';
import Splash from '../pages/splash';

const REQUIRE_ONBOARDING = true;

function PrivateRoute({ children, component: Component, prerequisiteLoading=false, type='SUBSCRIBED', ...rest }) {
  const initialState = {
    currentAuthStatus: {
      loggedIn: false,
      subscribed: false
    },
    error: null,
    proceed: false,
    redirect: null,
    requiresUser: false,
    requiresSubscription: false
  }
  const [state, setState] = useState(initialState);

  const current_user = useSelector(userSelector);
  const dispatch = useDispatch();

  const stateRef = useRef(initialState);
  stateRef.current = state;
  const eligibilityCheckRef = useRef(false);

  useEffect(() => {
    window.addEventListener('storage', () => checkEligibility());
    return () => {
      window.removeEventListener('storage', () => checkEligibility());
    };
  }, []);

  useEffect(() => {
    startRouting();
  }, [rest.path])

  useEffect(() => {
    // TODO: PERF CONSIDERATION: Is this necessary?
    checkEligibility();
  }, [current_user]);

  function _get_auth_status() {
    const path = rest.path;
    const authData = localStorage.getItem('Authorization');
    const userData = JSON.parse(localStorage.getItem('User'));
    const userStatus = JSON.parse(localStorage.getItem('user_status'));
    const token = path.substring(path.lastIndexOf('/') + 1, path.length);
    
    var user_subscribed = localStorage.getItem('user_subscribed');
    var userSubscribed = false;
    if (user_subscribed && (user_subscribed === 'true')) {
      userSubscribed = true;
    };

    var currentAuthStatus = {
      authData: authData,
      loggedIn: false,
      subscribed: false,
      token: token,
      userData: userData,
      userActive: (userStatus === 'active') ? true : false
    };
    if (authData && userData) {
      currentAuthStatus = {
        ...currentAuthStatus,
        loggedIn: true
      };
      if ((!current_user || (Object.keys(current_user).length < 1)) && type !== 'VERIFY') {
        // The 'VERIFY' type handles setCurrentUser in startRouting()
        dispatch(setCurrentUser(userData));
      };
    } else if (current_user && (Object.keys(current_user).length > 0)){
      dispatch(setCurrentUser({}));
    };
    if (currentAuthStatus.loggedIn && userSubscribed) {
      currentAuthStatus = {
        ...currentAuthStatus,
        subscribed: true
      };
    };
    return currentAuthStatus;
  };

  function checkEligibility() {
    if (!eligibilityCheckRef.current && stateRef.current.proceed) {
      eligibilityCheckRef.current = true; // Since checkEligibility is called async through useEffect, and EventListener, this ensures that it is not called again before finishing
      var currentAuthStatus = _get_auth_status();
      if ((stateRef.current.requiresUser || stateRef.current.requiresSubscription) && (!currentAuthStatus.loggedIn)) {
        setState({
          ...stateRef.current,
          currentAuthStatus: currentAuthStatus,
          proceed: false,
          error: null,
          redirect: '/account'
        });
      } else if (stateRef.current.requiresSubscription && !currentAuthStatus.subscribed) {
        var redirectLink = '/account'
        if (currentAuthStatus.loggedIn) {
          // User is not logged in. Redirect to login
          redirectLink = '/subscribe';
        };
        setState({
          ...stateRef.current,
          currentAuthStatus: currentAuthStatus,
          proceed: false,
          error: null,
          redirect: redirectLink
        });
      };
      eligibilityCheckRef.current = false;
    };
  };

   function clearError() {
    if (stateRef.current.redirect) {
      setState({
        ...stateRef.current,
        error: null
      });
    } else {
      startRouting();
    };
   };

  function startRouting() {
    // TO DO: CHECK REDUX TO SEE IF USER IS LOGGED IN BEFORE RESORTING TO LOCALSTORAGE!!!!
    var redirectLink = '/account';
    var currentAuthStatus = _get_auth_status();
    if (currentAuthStatus.loggedIn && !currentAuthStatus.userActive && REQUIRE_ONBOARDING) {
      // User requires onboarding...
      setState({
        ...stateRef.current,
        currentAuthStatus: currentAuthStatus,
        proceed: false,
        error: null,
        redirect: '/onboarding'
      });
    };
    switch(type) {
      case 'ALL_USERS':
        // This allows all users to access the page, but will attempt to load the user, if they are logged in
        if (!currentAuthStatus.loggedIn) {
          // User is not logged in. Redirect to login page
          setState({
            ...stateRef.current,
            currentAuthStatus: currentAuthStatus,
            error: null,
            proceed: true
          });
        } else {
          // User is logged in. Display route
          setState({
            ...stateRef.current,
            currentAuthStatus: currentAuthStatus,
            error: null,
            proceed: true
          });
        };
        break;
      case 'LOGGED_IN':
        if (!currentAuthStatus.loggedIn) {
          // User is not logged in. Redirect to login page
          setState({
            ...stateRef.current,
            currentAuthStatus: currentAuthStatus,
            proceed: false,
            error: null,
            redirect: '/account'
          });
        } else {
          // User is logged in. Display route
          setState({
            ...stateRef.current,
            currentAuthStatus: currentAuthStatus,
            error: null,
            proceed: true,
            requiresUser: true
          });
        };
        break;
      case 'SUBSCRIBED':
        if (!currentAuthStatus.subscribed) {
          if (!currentAuthStatus.loggedIn) {
            // User is not logged in. Redirect to login
            redirectLink = '/account';
          } else {
            // User is logged in, but not subscribed. Redirect to packages
            redirectLink = '/subscribe';
          };
          setState({
            ...stateRef.current,
            currentAuthStatus: currentAuthStatus,
            proceed: false,
            error: null,
            redirect: redirectLink
          });
        } else {
          setState({
            ...stateRef.current,
            currentAuthStatus: currentAuthStatus,
            error: null,
            proceed: true,
            requiresUser: true
          });
        };
        break;
      case 'VERIFY':
          if (currentAuthStatus.authData && currentAuthStatus.userData) {
            dispatch(
              checkAuth(
                () => {
                  dispatch(setCurrentUser(currentAuthStatus.userData));
                  setState({
                    ...stateRef.current,
                    currentAuthStatus: currentAuthStatus,
                    error: null,
                    proceed: true,
                    requiresUser: true
                  });
                },
                () => {
                  setState({
                    ...stateRef.current,
                    currentAuthStatus: currentAuthStatus,
                    proceed: false,
                    error: null,
                    redirect: '/'
                  });
                }
              )
            );
          } else {
            setState({
              ...stateRef.current,
              currentAuthStatus: currentAuthStatus,
              proceed: true,
              error: null
            });
          }
        break;
      case 'REFERENCE':
          setState({
            ...stateRef.current,
            currentAuthStatus: currentAuthStatus,
            error: null,
            proceed: true,
            requiresUser: true
          });
        break;
      default:
        if (!currentAuthStatus.loggedIn) {
          // User is not logged in. Redirect to login page
          setState({
            ...stateRef.current,
            currentAuthStatus: currentAuthStatus,
            proceed: false,
            error: null,
            redirect: '/account'
          });
        } else {
          // User is logged in. Display route
          setState({
            ...stateRef.current,
            currentAuthStatus: currentAuthStatus,
            error: null,
            proceed: true,
            requiresUser: true
          });
        };
      };
  };

  if (Component) {
    return(
      <Route
        { ...rest }
        render={ props =>
          (stateRef.current.proceed && !prerequisiteLoading) ?
            <Component { ...props } { ...rest.componentProps } authStatus={{ loggedIn: stateRef.current.currentAuthStatus.loggedIn, subscribed: stateRef.current.currentAuthStatus.subscribed }} />
          :
            (stateRef.current.error) ?
              <Error error_message={ stateRef.current.error } retry={ () => clearError() } retry_button_message={ (stateRef.current.redirect ? 'Confirm' : 'Retry') } />
            :
              (stateRef.current.redirect) ?
                <Redirect to={ stateRef.current.redirect } />
              :
                <Splash />
        }
      />
    );
  } else if (children && !prerequisiteLoading) {
    if (stateRef.current.proceed) {
      return <Route { ...rest } render={ () => children } />;
    } else if (stateRef.current.error) {
      return <Error error_message={ stateRef.current.error } retry={ () => clearError() } retry_button_message={ (stateRef.current.redirect ? 'Confirm' : 'Retry') } />;
    } else if (stateRef.current.redirect) {
      return <Redirect to={ stateRef.current.redirect } />;
    };
  };
  return <Splash />;
};

export default PrivateRoute;
