import React from 'react';
import { Text, View, StyleSheet, Line } from '@react-pdf/renderer';


const borderColor = '#EBE9F1'
const styles = StyleSheet.create({
    container: {
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        position: 'fixed',
    },
    lineHeader: {
        marginTop: 0,
        marginLeft: 0,
        marginRight: 0,
        height: 79,
        backgroundColor: borderColor,
    },
    companyName: {
        marginTop: 20,
        marginLeft: 30,
        fontSize: 16,
    },
    spanText: {
        marginLeft: 30,
        fontSize: 12,
    },
    lineBottom: {
        marginTop: 0,
        marginLeft: 0,
        marginRight: 0,
        height: 2,
        backgroundColor: "#F66616",
    },
});

const PdfHeaderWithoutLogo = ({ prequalifyData }) => (
    <View style={styles.container}>
        <Line style={styles.lineHeader} vertical={false}>
            <Text style={styles.companyName}>
                <span>{prequalifyData?.data?.companyName}</span>
            </Text>
            <Text style={styles.spanText}>
                <span>Contractor's Qualifications Showcase </span>
            </Text>
        </Line>
        <Line style={styles.lineBottom} vertical={false}></Line>      
    </View>

);

export default PdfHeaderWithoutLogo;
