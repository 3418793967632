import styled from 'styled-components';
import { Avatar } from "@material-ui/core";


export const AvatarPicture = styled(Avatar)`
  &&{
    width: 42px;
    height: 42px;
  }
`;
