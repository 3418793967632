import React, { useState, useEffect } from "react";
import Input from '../../components/inputField';
import { StyledRow } from "./styles";
import { getCountryName } from "common/countryName";

export const AddressAutoFields = ({
  data,
  setData,
  isMobile
}) => {

  let autocomplete;
  let address1Field;
  let address2Field;
  let postalField;
  let cityField;
  let provinceField;
  let countryField;
  const [address, setAddress] = useState({});
  const postalCode = data?.company?.postal;
  const canadianPostalCodePattern = /^[A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d$/;
  const usaZipCodePattern = /^\d{5}(?:[-\s]\d{4})?$/;

  function initAutocomplete() {
    address1Field = document.querySelector("#ship-address");
    address2Field = document.querySelector("#address2");
    postalField = document.querySelector("#postcode");
    cityField = document.querySelector("#locality");
    provinceField = document.querySelector("#state");
    countryField = document.querySelector("#country");
    const country = data?.country ? data?.country : "CA";
    autocomplete = new window.google.maps.places.Autocomplete(address1Field, {
      componentRestrictions: { country: [data?.company?.country] },
      fields: ["address_components", "geometry"],
      types: ["address"],
    });
    autocomplete.addListener("place_changed", fillInAddress);
  }

  function fillInAddress() {
    const place = autocomplete.getPlace();
    let address1 = "";
    let postcode = "";
    let city = "";
    let province = "";
    let country = "";

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case "route": {
          address1 += component.short_name;
          break;
        }

        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }
        case "locality":
          city = component.long_name;
          break;
        case "administrative_area_level_1": {
          province = component.short_name;
          break;
        }
        case "country": {
          country = component.short_name;
          break;
        }
      }
    }

    address1Field.value = address1;
    postalField.value = postcode;
    cityField.value = city;
    provinceField.value = province;
    countryField.value = country;
   
    const temp = {
      address: address1,
      postal: postcode,
      suite: address2Field.value,
      city: city,
      province: province,
      country: country
    }

    setAddress(temp)
    address2Field.focus();
  }

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  useEffect(() => {
    setData('company', address)
  }, [address])

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyCwLidsAgErglXnD9dT6qnaQF7u_DYU3Pc&libraries=places`,
      () => initAutocomplete()
    );
  }, [])

  
  return (
    <>
      <StyledRow isMobile={isMobile}>
        <Input
          id="twoThirdInputWrap"
          inputId="ship-address"
          required
          autocomplete="off"
          label="Address"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          paddingRight="0px"
          value={data?.company?.address}
          setValue={(val) => { setData('company.address', val) }}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
        <Input
          id="oneThirdInputWrap"
          inputId="address2"
          required
          autocomplete="off"
          label="Suite"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={data?.company?.suite}
          setValue={(val) => { setData('company.suite', val) }}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />

        <Input
          id="twoThirdInputWrap"
          inputId="postcode"
          required
          autocomplete="off"
          label={canadianPostalCodePattern.test(postalCode) ? "Postal Code" : usaZipCodePattern.test(postalCode) ? "Zip Code" : "Postal/Zip Code"}
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={data?.company?.postal}
          setValue={(val) => { setData('company.postal', val) }}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />


      </StyledRow>
      <StyledRow isMobile={isMobile}>

        <Input
          id="twoThirdInputWrap"
          inputId="locality"
          required
          autocomplete="off"
          label="City"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={data?.company?.city}
          setValue={(val) => { setData('company.city', val) }}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
        <Input
          id="oneThirdInputWrap"
          inputId="state"
          required
          autocomplete="off"
          label={canadianPostalCodePattern.test(postalCode) ? "Province" : usaZipCodePattern.test(postalCode) ? "State" : "State/Province"}
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={data?.company?.province}
          setValue={(val) => { setData('company.province', val) }}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />

        <Input
          id="twoThirdInputWrap"
          inputId="country"
          required
          autocomplete="off"
          label="Country"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={data?.company?.country}
          setValue={(val) => { setData('company.country', val) }}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />

      </StyledRow>
    </>
  )
}
