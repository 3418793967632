import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { filterPersonnels } from "../../../api/prequalify";
import { StyledBox, PlaceholderText } from "../styles";
import { IconContainer, SeeAllText } from "./styles";
import { PersonnelCard } from "./PersonnelCard";
import { FunctionalButton } from "../../../components/functionalButton";
import { AddPersonnelPopup } from "../../../components/addPersonnelPopup";
import PlusSign from "../../../assets/onBoarding/plusSign";
import { savePersonnel, checkPersonnelLimit } from "../../../api/dashboard";
import { AlertPopup } from "components/alertPopup/alertPopup";
import { useSelector } from "react-redux";
import { contactSelector } from "../../../data/selectors/contact";

export const TabPanel1 = ({ isMobile }) => {
  const [personnelList, setPersonnelList] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [individual, setIndividual] = useState("");
  const history = useHistory();
  const [openExceedPopup, setOpenExceedPopup] = useState(false);
  const contactData = useSelector(contactSelector);
  const personnelLimit = contactData?.user_access_control?.number_of_personnel;
  const fileLimitCount = contactData?.user_access_control?.file_limit_count;
  const fileLimitSize = contactData?.user_access_control?.file_limit_size;
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const fileLimitSizeMB = (fileLimitSize / (1024 * 1024)).toFixed(2);

  useEffect(() => {
    handleFilterPersonnel();
  }, []);

  const handleFilterPersonnel = async () => {
    await filterPersonnels("")
      .then((data) => {
        setPersonnelList(data);
      })
      .catch((err) => { });
  };

  const handleAdd = () => {
    if (personnelList && personnelList.length >= personnelLimit) {
      setOpenExceedPopup(true);
    } else {
      setOpenPopup(true);
    }
    // checkPersonnelLimit(() => setOpenPopup(true), () => setOpenExceedPopup(true))
  };

  const addPersonnel = (data) => {
    savePersonnel(data, onSuccess, onFailure);
  };

  const onSuccess = (res) => {
    setIndividual("");
    handleFilterPersonnel();
  };

  const onFailure = (error) => {
    if (error?.status == 409) {
      setHasError(true);
      setErrorMessage(error?.data?.message);
    }
  };

  useEffect(() => {
    if (!openPopup) {
      setIndividual("");
    }
  }, [openPopup]);

  const handleSeePersonnel = () => {
    history.push("/all/personnel");
  };

  return (
    <>
      {!(personnelList?.length > 0) && (
        <StyledBox boxHeight="80px" borderRadius="10px">
          <PlaceholderText>Your Personnel will appear here</PlaceholderText>
        </StyledBox>
      )}
      {personnelList?.length > 0 &&
        personnelList.slice(0, 6).map((item, i) => {
          if (item.firstName || item.lastName)
            return <PersonnelCard {...item} key={i} />;
        })}
      <FunctionalButton
        buttonTitle="Add Personnel"
        handleButton={handleAdd}
        width="100%"
        marginRight="0px"
        marginTop="8px"
        isReversedColor={true}
        frontIcon={true}
        hasBoxShadow={true}
      />
      {personnelList?.length > 0 && (
        <SeeAllText onClick={handleSeePersonnel}>See All Personnel</SeeAllText>
      )}
      <AddPersonnelPopup
        setOpenDialog={setOpenPopup}
        openDialog={openPopup}
        data={individual}
        setData={setIndividual}
        handleButtonFunc={addPersonnel}
        fileLimitCount={fileLimitCount}
        fileLimitSizeMB={fileLimitSizeMB}
      />
      <AlertPopup
        openPopup={openExceedPopup}
        setOpenPopup={setOpenExceedPopup}
        isMobile={isMobile}
        headerText="You have exceeded the personnel limit."
        subHeaderText={`You can add a maximum of ${personnelLimit} personnel.`}
        showButton={true}
      />
      <AlertPopup
        openPopup={hasError}
        setOpenPopup={setHasError}
        isMobile={isMobile}
        headerText="Personnel Add/Edit Alert"
        subHeaderText={`${errorMessage}`}
        showButton={false}
      />
    </>
  );
};
