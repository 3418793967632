export const UploadIcon = ({ color }) => (
  <svg
    style={{ verticalAlign: "text-top" }}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 6.33527C16.3125 6.34527 17.2942 6.4261 17.9342 7.0661C18.6667 7.7986 18.6667 8.97693 18.6667 11.3336V12.1669C18.6667 14.5244 18.6667 15.7028 17.9342 16.4353C17.2025 17.1669 16.0233 17.1669 13.6667 17.1669H7C4.64333 17.1669 3.46417 17.1669 2.7325 16.4353C2 15.7019 2 14.5244 2 12.1669V11.3336C2 8.97693 2 7.7986 2.7325 7.0661C3.3725 6.4261 4.35417 6.34527 6.16667 6.33527"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M10.3333 2.33398L10.3333 13.334M10.3333 13.334L7.83325 10.3724M10.3333 13.334L12.8333 10.3724"
      stroke={color}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
