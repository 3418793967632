import styled from "styled-components";
import { TYPOGRAPHY, COLORS } from "../../../../constants";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";


export const SecondInnerRadioContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledCenter = styled.div`
  display: flex;
  justify-content:center;
  margin-top: 16px;
`;


export const SafetyText = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 4px;
  }
`;



export const StyledSafetyRecordsContainer = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: row;
  width: 100%;
  gap: 16px;
`;



export const StyledRow = styled.div`
  display: flex;
  width: 100%;
  ${(props) => (props.margin ? `margin: ${props.margin};` : "")}
  ${(props) =>
    props.justifyContent ? `justify-content: ${props.justifyContent};` : ""}
  flex-direction: ${(props) =>
    props.isMobile || props.isColumn ? "column" : "row"};
`;



export const SecondRadioContainer = styled.div`
  display: flex;
  flex-direction: column;
`;



export const SectionText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    width: 100%;
    margin-top: ${(props) => props.marginTop || "16px"};
    color: ${COLORS.darkGrey1};
  }
`;



export const FieldArea = styled.div`
  ${(props) =>
    props.marginLeft && !props.isMobile
      ? `margin-left: ${props.marginLeft};`
      : ""}
  margin-top: ${(props) => props.marginTop || "24px"};
  display: flex;
  flex-direction: ${(props) => (props.isColumn ? "column" : "row")};
  align-items: center;
  flex-wrap: wrap;
  ${(props) => (props.noJustifyContent ? "" : "justify-content: center;")}
  ${(props) =>
    props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}

  .last {
    padding-right: 0px !important;
  }

  .inputWrap {
    padding-right: ${(props) => (props.isMobile ? "0px" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "50%")};
  }

  .fullWidthInputWrap {
    width: 100%;
  }

  .oneFifthInputWrap {
   padding-right: ${(props) => (props.isMobile ? "0px" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "20%")};
  }

  .thirtyInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "30%")};
  }

  .oneThirdInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "33.33%")};
  }

  .twoThirdInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "66.66%")};
  }

  .oneFifthMobile {
    padding-right: 16px;
    width: ${(props) => (props.isMobile ? "50%" : "20%")};
  }

  .thirtyMobile {
    width: ${(props) => (props.isMobile ? "50%" : "30%")};
  }
`;


export const StyledEditRecordButton = styled(FieldArea)`
  margin-top: 15px;
  margin-left: 0px;
`;


export const StyledYear = styled(Typography)`
  justify-content: center;
  display: flex;
  flex-direction: column;

  && {
    font-size: 15px;
    margin-top: 39px;

    &.MuiTypography-root {
      font-weight: 600;
    }
  }
`;

export const FieldColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin-right: -32px;
  }
`;

export const FieldWrap = styled.div`
  ${(props) =>
    props.marginLeft && !props.isMobile
      ? `margin-left: ${props.marginLeft};`
      : ""}
  margin-top: ${(props) => props.marginTop || "24px"};
  display: flex;
  flex-direction: ${(props) =>
    props.isColumn || props.isMobile ? "column" : "row"};
  flex-wrap: wrap;
  ${(props) =>
    props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}

  .last {
    padding-right: 0px !important;
  }

  .firstColumn {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "7%")};
  }

  .recordColumn {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: 18.6%;
  }

  .fullWidthInputWrap {
    padding-right: 16px;
    width: 100%;
  }
`;

export const MobileYearText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: ${COLORS.textGrey};
    ${(props) => (props.margin ? `margin: ${props.margin};` : "")}
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    width: ${(props) => (props.width ? props.width : "100%")};
    border-color: ${(props) => (props.noBorderColor ? "" : COLORS.lightGrey1)};
    background-color: white;

    .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) =>
        props.noBorderColor ? "" : COLORS.lightGrey1};
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${COLORS.primaryOrange};
      border-width: thin;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${COLORS.primaryOrange};
    }
  }
`;
