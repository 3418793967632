import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { COLORS, TYPOGRAPHY } from '../../../../constants';


export const StyledInputWrap = styled.div`
  margin-top: 8px;
`;

export const StyledUnderText = styled(Typography)`
  && {
    width: 200px;
    font-size: 10px;
    font-family: ${TYPOGRAPHY.primaryFont};
    margin-top: 8px;
    color: ${COLORS.darkGrey2};
  }
`;
