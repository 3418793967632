import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link as RouterLink } from "react-router-dom";
import PledgxLogo from "../../assets/common/pledgxLogo";

import {
  FormContainer,
  Content,
  StyledEmailSentCard,
  EmailSentText,
  EmailSentSubText
} from "./styles";

export const EmailSent = ({ email, isMobile }) => {

  return (
    <FormContainer isMobile={isMobile}>
      <Content isMobile={isMobile} marginTop="50px">
      <StyledEmailSentCard>
        <RouterLink to="/">
          <PledgxLogo/>
        </RouterLink>
        <EmailSentText>
          Thanks! You're almost there.
        </EmailSentText>
        <EmailSentSubText>
          We’ve sent a confirmation email to{" "}
          <span style={{ fontWeight: "bold", color: "black" }}>
            {email}
          </span>
          .
        </EmailSentSubText>
        <EmailSentSubText>
          If you haven’t received anything within 5 minutes, check your
          Junk/Spam folder and add us to your contacts!
        </EmailSentSubText>
      </StyledEmailSentCard>
      </Content>
    </FormContainer>
  );
}

