import { CircularProgress } from "@mui/material";
import {
  confirmFileUpload,
  createFile,
  updateCCDC_AdditionalFileBlob,
  uploadFileToGCP,
} from "api/file_managment";

import { COLORS } from "../../../../constants";
import React, { useState } from "react";
import { useEffect } from "react";
import FilesListTable from "../../../../components/filesListTable";
import { StyledTableCell } from "../../../../components/filesListTable/styles";
import { DeleteButton } from "../deleteButton";
import {
  StyledTableCellFirst,
  StyleDisplay,
  StyledTableCellSecond,
} from "./styles";
import Select from "../../../../components/selectField";

export const WrapperForUploadedFileDisplay = ({
  file,
  deleteFile,
  displayPercentage,
  index,
  marginTop,
  noActions,
  handleClickRow,
  ccdcId,
  setPrequalifyData,
  setOpenPopup,
  openPopup,
  prequalifyData,
  requirements,
  currentAdditionalRequirementArray,
}) => {
  let currentAdditionalDocArray = [...prequalifyData?.additional_document];

  const [openProgress, setOpenProgress] = useState(false);

  let uploadFileName = file.name;
  let extension = uploadFileName.split(".").pop();
  let fileName = uploadFileName.replace("." + extension, "");

  let dataForFirstApi = {
    file_type: "Institution_file",
    file_extension: extension,
    file_name: fileName,
  };

  useEffect(() => {
    if (
      !currentAdditionalRequirementArray?.some(
        (obj) => obj.file_id === file.file_id
      )
    ) {
      currentAdditionalRequirementArray.push({ file_id: file.file_id });
      setPrequalifyData(
        "additional_document_requirement",
        currentAdditionalRequirementArray
      );
    }

    // if (isNaN(file.file_id)) {
    //   setOpenProgress(true);
    //   //get upload url and file id
    //   createFile(
    //     dataForFirstApi,
    //     async (data) => {
    //       //#region upload file using upload url
    //       try {
    //         await uploadFileToGCP(data.upload_url, file, {
    //           headers: {
    //             "Content-Type": file.content_type,
    //             "Access-Control-Allow-Origin": "*",
    //           },
    //         });
    //       } catch (e) {
    //         console.log("CORS ERROR");
    //       }
    //       //#endregion

    //       //#region confirm file is uploaded or not
    //       await confirmFileUpload(
    //         { file_id: data.id },
    //         async () => {
    //           //#region save file id to ccdc table in additional_file column
    //           await updateCCDC_AdditionalFileBlob({
    //             ccdcId,
    //             file_id: data.id,
    //           });
    //           currentAdditionalDocArray[index].file_id = data.id;
    //           currentAdditionalRequirementArray.push({ file_id: data.id });
    //           setPrequalifyData(
    //             "additional_document",
    //             currentAdditionalDocArray
    //           );
    //           setPrequalifyData(
    //             "additional_document_requirement",
    //             currentAdditionalRequirementArray
    //           );
    //           //#endregion
    //           //TODO: Toast a message
    //           console.log(
    //             "File uploaded succesfully verified by calling confirm api"
    //           );

    //           setOpenProgress(false);
    //         },
    //         () => {
    //           //Removing file from the state
    //           currentAdditionalDocArray.splice(index, 1);
    //           setPrequalifyData(
    //             "additional_document",
    //             currentAdditionalDocArray
    //           );
    //           //set Alert popup if file size limit reached
    //           setOpenPopup(true);
    //           console.log("Failed uploaded api");
    //         }
    //       );

    //       //#endregion
    //     },
    //     () => {
    //       //Removing file from the state
    //       currentAdditionalDocArray.splice(index, 1);
    //       setPrequalifyData("additional_document", currentAdditionalDocArray);
    //       //set Alert popup if file size limit reached
    //       setOpenPopup(true);
    //     }
    //   );
    // }
  }, []);

  return (
    <UploadedFileDisplay
      file={file}
      deleteFile={deleteFile}
      displayPercentage={displayPercentage}
      index={index}
      marginTop={marginTop}
      noActions={noActions}
      handleClickRow={handleClickRow}
      openProgress={openProgress}
      requirements={requirements}
      setPrequalifyData={setPrequalifyData}
      prequalifyData={prequalifyData}
      currentAdditionalRequirementArray={currentAdditionalRequirementArray}
    ></UploadedFileDisplay>
  );
};

export const UploadedFileDisplay = ({
  file,
  deleteFile,
  displayPercentage,
  index,
  marginTop,
  noActions,
  handleClickRow,
  openProgress,
  requirements,
  setPrequalifyData,
  prequalifyData,

  currentAdditionalRequirementArray,
}) => {
  const requirementIndex = currentAdditionalRequirementArray
    ?.map((value) => value.file_id)
    .indexOf(file.file_id);

  const handleRequirement = (value) => {
    const id = requirements.find(
      (requirement) => requirement.title === value
    ).id;

    currentAdditionalRequirementArray[requirementIndex].requirement_title =
      value;
    currentAdditionalRequirementArray[requirementIndex].requirement_id = id
      ? id
      : "";
    setPrequalifyData(
      "additional_document_requirement",
      currentAdditionalRequirementArray
    );
  };
  const actions = (props) => {
    return (
      <>
        <StyledTableCell component={"tr"} align="right">
          {openProgress && (
            <CircularProgress
              style={{
                height: "20px",
                width: "20px",
                color: COLORS.primaryOrange,
              }}
              disableShrink
            />
          )}
          {!openProgress && (
            <DeleteButton
              handleClick={() => {
                deleteFile(index);
              }}
            />
          )}
        </StyledTableCell>
      </>
    );
  };

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCellFirst component={"tr"}>
          {bodyData.file_name}
        </StyledTableCellFirst>
        {prequalifyData?.project_info?.rfpq_id && (
          <StyledTableCellSecond component={"tr"}>
            <Select
              dropdownHeight="30px"
              options={requirements.map((requirement) => ({
                value: requirement.title,
                label: requirement.title,
              }))}
              value={
                currentAdditionalRequirementArray[requirementIndex]
                  ?.requirement_title
              }
              setValue={(val) => {
                handleRequirement(val);
              }}
              validateFunc={(item) => Boolean(item)}
              noCheckIcon={true}
              showDropdownIndicator={true}
              withoutMargin={true}
            />
          </StyledTableCellSecond>
        )}
        <StyledTableCell component={"tr"} scope="row" align="right" width="20%">
          {bodyData.file_size}
        </StyledTableCell>
      </>
    );
  };

  const bytesToMegaBytes = (bytes, roundTo) => {
    const sizeMB = roundTo
      ? (bytes / (1024 * 1024)).toFixed(roundTo)
      : bytes / (1024 * 1024);
    return sizeMB + " MB";
  };

  const uploadedFileDetails = [
    {
      file_name: file?.name,
      file_size: file?.size ? bytesToMegaBytes(file?.size, 1) : "",
    },
  ];

  return (
    <StyleDisplay
      displayPercentage={displayPercentage}
      marginTop={index === 0 ? "10px" : 0}
    >
      <FilesListTable
        data={uploadedFileDetails}
        body={body}
        actions={actions}
        noActions={noActions}
        actionType="file"
        concise
        borderSpacing="0 4px !important"
        handleClickRow={handleClickRow}
        position="relative"
      />
    </StyleDisplay>
  );
};
