import React from 'react';
import {
  StyledSlider, 
  StyledHeader,
  StyledContainer,
} from './styles';


const Slider = ({ 
  value, 
  header, 
  setValue, 
  maxTen, 
  width, 
  margin,
  headerMarginBottom,
  headerFontSize,
  noThumb,
  icon,
  disabled
}) => {
  let marks = [];
  let i = 1;
  if (maxTen) {
    while(i <= 10) {
      marks.push({
        value: i,
        label: String(i),
      });
      i += 1
    }
  } else {
    while(i <= 100) {
      marks.push({
        value: i,
        label: String(i),
      });
  
      if(i == 1) {
        i += 9;
      } else {
        i += 10;
      }
    }
  }

  const handleOnChange = (e, val) => {
    setValue(val);
  };

  return (
    <StyledContainer width={width} margin={margin}>
      <StyledHeader headerMarginBottom={headerMarginBottom} headerFontSize={headerFontSize}>
        {icon}
        {header}
      </StyledHeader>
      <StyledSlider
        defaultValue={value}
        value={value}
        onChange={handleOnChange}
        marks={marks}
        aria-labelledby="discrete-slider-restrict"
        step={maxTen ? 1 : 0}
        min={1}
        max={maxTen ? 10 : 100}
        noThumb={noThumb}
        disabled={disabled}
      />
    </StyledContainer>
  );
};

export default Slider;
