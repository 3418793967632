import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { institutionRfpqsSelector } from "../../../data/selectors/buyers";
import Spinner from "../../../components/spinner";

import {
  MediumText,
  Content,
  StyledArea,
  StyledBox,
  PlaceholderText,
  StyledTooltipButton,
  ButtonPlacement,
} from "../styles";

import { ListContainer } from "./styles";

import { useHistory } from "react-router-dom";
import { FunctionalButton } from "../../../components/functionalButton";
import { ArrowIcon } from "./asset/ArrowIcon";
import { getAllProjects } from "api/buyers";
import { AllProjectTable } from "pages/buyers/components/allProjects";

export const RequestSection = ({ isMobile, userData }) => {
  let history = useHistory();
  const [state, setState] = useState({
    loading: false,
  });
  const [data, setData] = useState([]);
  const institutionRfpqs = useSelector(institutionRfpqsSelector);

  useEffect(() => {
    if (institutionRfpqs) {
      setData(institutionRfpqs);
    }
  }, [institutionRfpqs]);

  const createHeader = (title, align) => {
    return {
      title,
      align: align || "left",
    };
  };

  const handleSeeAll = () => {
    history.push("/buyers/projects");
  };

  const handleNew = () => {
    history.push("/rfpq");
  };

  return (
    <Content isMobile={isMobile}>
      <MediumText isMobile={isMobile}>Your Requests for Proposal</MediumText>
      <StyledBox
        hasData={data?.length > 0}
        boxHeight="270px"
        noBackground={data?.length > 0 ? true : false}
      >
        {!state.loading && data?.length > 0 && (
          <ListContainer>
            <AllProjectTable
              data={data?.slice(0, 3)}
              setData={setData}
              isMobile={isMobile}
              borderRadius="20px"
              boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px;"
              isShortened={true}
            />
          </ListContainer>
        )}
        {!state.loading && !(data?.length > 0) && (
          <PlaceholderText>
            Your Requests for Proposal will appear here
          </PlaceholderText>
        )}
        {state.loading && (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
            <span style={{ marginTop: "20px" }}>Loading RFPQ...</span>
          </div>
        )}
      </StyledBox>
      <StyledArea
        marginTop={isMobile && data?.length > 0 ? "-38px" : "12px"}
        isMobile={isMobile}
      >
        <ButtonPlacement isMobile={isMobile} marginRight="20px">
          {data?.length > 0 && (
            <FunctionalButton
              buttonTitle={isMobile ? "See All" : "See All RFP/Qs"}
              handleButton={handleSeeAll}
              width="400px"
              // marginRight="10px"
              isReversedColor={true}
              icon={<ArrowIcon fill="#FF6D1D" />}
              hasBoxShadow={true}
              disabled={state.loading}
            />
          )}
        </ButtonPlacement>
        {userData?.status == "active" && (
          <StyledTooltipButton>
            <FunctionalButton
              buttonTitle={isMobile ? "Start New" : "Start a New Request"}
              handleButton={userData?.status == "active" ? handleNew : () => {}}
              width="500px"
              marginRight="0px"
              icon={<ArrowIcon fill="white" />}
              hasBoxShadow={true}
              disabled={
                userData?.status == "active"
                  ? state.loading
                    ? true
                    : false
                  : true
              }
            />
          </StyledTooltipButton>
        )}
      </StyledArea>
    </Content>
  );
};
