import React from 'react';
import additional from './openProject_color.svg'

const OpenProject_color = ({ isMobile }) => {
  return (
    <img width={isMobile ? "36" : "56"} height={isMobile ? "36" : "56"} src={additional} />
  )
};

export default OpenProject_color;
