import React, { useState, useEffect } from "react";

import Input from "../inputField";
import { InfoArea, StyledSmallAddText } from "./styles";
import { StyledRow } from "./styles";
import { dateRegex, emailRE } from "../../common/regex";
import { formatPhoneNumber } from "common/formatPhoneNumber";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

export const Step3 = ({
  setDisable,
  addData,
  setAddData,
  isMobile
}) => {
  const [hasOther, setHasOther] = useState(addData?.other?.length > 0);
  const [phoneNumber, setPhoneNumber] = React.useState(addData?.consultant_phone || '');
  const [otherPhoneNumber, setOtherPhoneNumber] = React.useState(addData?.other_phone || '');
  const handlePhoneNumberChange = (value) => {
    setAddData('consultant_phone', value);
  };

  const handleOtherPhoneNumberChange = (value) => {
    setAddData('other_phone', value);
  };

  return (
    <InfoArea isMobile={isMobile} marginTop="0px" marginLeft="8px" isColumn={true} >
      <StyledRow isMobile={isMobile}>
        <Input
          id="inputWrap"
          label="Project Consultant"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.consultant || ''}
          setValue={(val) => setAddData('consultant', val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
        <Input
          id="inputWrap"
          label="Consultant Contact Person"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.consultant_contact || ''}
          setValue={(val) => setAddData('consultant_contact', val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
      </StyledRow>
      <StyledRow isMobile={isMobile}>
        {/* <Input
          id="inputWrap"
          label="Phone Number"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={formatPhoneNumber(addData?.consultant_phone) || ''}
          setValue={(val) => setAddData('consultant_phone', val)}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        /> */}

        <div style={{ marginTop: '24px', width: "100%" }}>
          <label htmlFor="phone" style={{ fontSize: '12px' }}>Phone Number</label>
          <div style={{ height: '47px', width: "100%", marginTop: '-10px' }}>
            <PhoneInput
              id="phone"
              country={'ca'}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              style={{ marginTop: '16px', width: '100%', height: '100%' }}
              inputStyle={{ fontSize: '18px', padding: '8px', lineHeight: '24px', marginLeft: '20px', paddingLeft: '28px', height: '100%', width: "45%" }}
              dropdownStyle={{ fontSize: '18px', lineHeight: '24px' }}
            />
          </div>
        </div>

        <Input
          id="inputWrap"
          label="Email"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.consultant_email || ''}
          setValue={(val) => setAddData('consultant_email', val)}
          validateFunc={(item) => emailRE.test(item)}
          noCheckIcon={true}
          noErrorBorder={true}
          style={{ marginLeft: '-290px' }}
        />
      </StyledRow>
      {!hasOther && (
        <StyledRow>
          <StyledSmallAddText onClick={() => setHasOther(true)}>
            + Add Other Party
          </StyledSmallAddText>
        </StyledRow>
      )}
      {hasOther && (
        <>
          <StyledRow isMobile={isMobile}>
            <Input
              id="inputWrap"
              label="Other Party"
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={addData?.other || ''}
              setValue={(val) => setAddData('other', val)}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noErrorBorder={true}
            />
            <Input
              id="inputWrap"
              label="Other Party Contact Person"
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={addData?.other_contact || ''}
              setValue={(val) => setAddData('other_contact', val)}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noErrorBorder={true}
            />
          </StyledRow>
          <StyledRow isMobile={isMobile}>
            {/* <Input
              id="inputWrap"
              label="Phone Number"
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={addData?.other_phone || ''}
              setValue={(val) => setAddData('other_phone', val)}
              validateFunc={(item) => item.length > 0}
              noCheckIcon={true}
              noErrorBorder={true}
            /> */}

            <div style={{ marginTop: '24px', width: "100%" }}>
              <label htmlFor="phone" style={{ fontSize: '12px' }}>Phone Number</label>
              <div style={{ height: '47px', width: "100%", marginTop: '-10px' }}>
                <PhoneInput
                  id="phone"
                  country={'ca'}
                  value={otherPhoneNumber}
                  onChange={handleOtherPhoneNumberChange}
                  style={{ marginTop: '16px', width: '100%', height: '100%' }}
                  inputStyle={{ fontSize: '18px', padding: '8px', lineHeight: '24px', marginLeft: '20px', paddingLeft: '28px', height: '100%', width: "45%" }}
                  dropdownStyle={{ fontSize: '18px', lineHeight: '24px' }}
                />
              </div>
            </div>
            <Input
              id="inputWrap"
              label="Email"
              type="text"
              placeholder=""
              labelSize="12px"
              fontSize="18px"
              value={addData?.other_email || ''}
              setValue={(val) => setAddData('other_email', val)}
              validateFunc={(item) => emailRE.test(item)}
              noCheckIcon={true}
              noErrorBorder={true}
              style={{ marginLeft: '-290px' }}
            />
          </StyledRow>
        </>
      )}
    </InfoArea>

  )
}