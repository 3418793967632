import React, { useState } from "react";

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper,
  SectionLabelText
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Input from "../inputField";
import PledgxLogo from "../../assets/common/pledgxLogo";
import { FunctionalButton } from "../functionalButton";
import { addDocumentType } from "../../data/actions/dataMaintenance";
import { useDispatch } from "react-redux";
import { SuccessPopup } from "../../pages/adminToolsDashboard/SuccessPopup";
import { StyledSubtext } from "pages/loginPage/styles";
import { StyledEditRecordButton } from "pages/contractorDashboard/profileSetup/styles.js";
import { RadioButton } from "components/radioButton";
import CheckedRadioButton from "assets/dashboardIcons/checkedRadioButton";
import UncheckedRadioButton from "assets/dashboardIcons/uncheckedRadioButton";

export const AddDocumentTypePopup = ({
  setOpenDialog,
  openDialog,
  isMobile,
  label,
  buttonLabel
}) => {
  const [data, setData] = useState({
    document_type: "",
    is_additional_info: false,
    is_currency_amount: false
  });
  const dispatch = useDispatch();
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [isCurrencyAmount, setIsCurrencyAmount] = useState(data?.is_currency_amount);
  const [isAdditionalInfo, setIsAdditionalInfo] = useState(data?.is_additional_info);


  const isAdditionalInfoRadioChange = (val) => {
    if (val === 1) {
      setIsAdditionalInfo(false);
      setData({ ...data, is_additional_info: false });
    } else {
      setIsAdditionalInfo(true);
      setData({ ...data, is_additional_info: true });
    }
  };


  const isCurrencyAmountRadioChange = (val) => {
    if (val === 1) {
      setIsCurrencyAmount(false);
      setData({ ...data, is_currency_amount: false });
    } else {
      setIsCurrencyAmount(true);
      setData({ ...data, is_currency_amount: true });
    }
  };



  const handleSave = () => {
    dispatch(
      addDocumentType(
        data,
        () => {
          setOpenSuccessPopup(true);
          setData({});
        },
        () => { }
      )
    );
  };


  const handleClose = () => {
    setOpenDialog(false);
  };


  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper elevation={0} isMobile={isMobile}>
        <CloseButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
        <FormTitle style={{ marginTop: 10 }}>
          {label || "Add Document Type"}
        </FormTitle>
        <InfoArea isMobile={isMobile} marginTop="0px">
          <Input
            id="fullWidthInputWrap"
            label="Document Type"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.document_type}
            setValue={(val) => setData({ ...data, document_type: val })}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
          />

          <SectionLabelText>Is additional info?</SectionLabelText>
          <StyledEditRecordButton>
            <RadioButton
              isChecked={
                isAdditionalInfo === false
                  ? true
                  : false
              }
              value={isAdditionalInfo}
              checkedIcon={<CheckedRadioButton />}
              unCheckedIcon={<UncheckedRadioButton />}
              handleChange={() => isAdditionalInfoRadioChange(1)}
            />
            <StyledSubtext style={{ marginTop: "0px" }}> No</StyledSubtext>
            <RadioButton
              isChecked={
                isAdditionalInfo === true
                  ? true
                  : false
              }
              value={isAdditionalInfo}
              checkedIcon={<CheckedRadioButton />}
              unCheckedIcon={<UncheckedRadioButton />}
              handleChange={() => isAdditionalInfoRadioChange(0)}
            />
            <StyledSubtext style={{ marginTop: "0px" }}> Yes</StyledSubtext>
          </StyledEditRecordButton>

          <SectionLabelText>Is currency & amount?</SectionLabelText>
          <StyledEditRecordButton>
            <RadioButton
              isChecked={
                isCurrencyAmount === false
                  ? true
                  : false
              }
              value={isCurrencyAmount}
              checkedIcon={<CheckedRadioButton />}
              unCheckedIcon={<UncheckedRadioButton />}
              handleChange={() => isCurrencyAmountRadioChange(1)}
            />
            <StyledSubtext style={{ marginTop: "0px" }}> No</StyledSubtext>
            <RadioButton
              isChecked={
                isCurrencyAmount === true
                  ? true
                  : false
              }
              value={isCurrencyAmount}
              checkedIcon={<CheckedRadioButton />}
              unCheckedIcon={<UncheckedRadioButton />}
              handleChange={() => isCurrencyAmountRadioChange(0)}
            />
            <StyledSubtext style={{ marginTop: "0px" }}> Yes</StyledSubtext>
          </StyledEditRecordButton>
        </InfoArea>

        <FunctionalButton
          width="30%"
          marginTop="16px"
          marginRight="10px"
          handleButton={() => {
            handleSave();
          }}
          buttonTitle={buttonLabel || "ADD"}
        />
        <SuccessPopup
          setOpenPopup={setOpenSuccessPopup}
          openPopup={openSuccessPopup}
          isMobile={isMobile}
        />
      </StyledPaper>
    </Dialog>
  );
};
