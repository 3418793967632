import React from 'react';

const Projects = ({ active, completed }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1545:3187)">
        <g filter="url(#filter0_d_1545:3187)">
          <circle cx="14" cy="14" r="14" fill="white"/>
          <circle cx="14" cy="14" r="13.5" fill={active || completed ? "#FF6D1D" : "white"} stroke="#ECEBED"/>
        </g>
        <path d="M15.75 11.9189L14 7L12.25 11.9189H7L11.2787 15.197L9.597 20.2632L14 17.1323L18.403 20.2632L16.7212 15.197L21 11.9189H15.75Z" fill={active || completed ? "white" : "#D0C9D6"}/>
      </g>
      <defs>
      <filter id="filter0_d_1545:3187" x="-64" y="-57" width="156" height="156" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="7"/>
        <feGaussianBlur stdDeviation="32"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1545:3187"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1545:3187" result="shape"/>
      </filter>
      <clipPath id="clip0_1545:3187">
        <rect width="28" height="28" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
};

export default Projects;
