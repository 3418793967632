import React, { useState, useEffect } from 'react';
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import PledgxLogo from 'assets/common/pledgxLogo';
import { FunctionalButton } from 'components/functionalButton/index.js';
import { StyledTableCell } from "components/filesListTable/styles";
import { StyledTableRow, StyledConciseTableContainer } from 'components/filesListTable/styles';
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper,
  StyledTextField,
  LabelText
} from 'components/showUserListOfOneClientPopup/styles.js';

export const ShowUserListOfOneClientPopup = ({
  setOpenPopup,
  openPopup,
  data,
  isMobile
}) => {


  useEffect(() => {
    if (!openPopup) {
      setOpenPopup(false);
    }
  }, [openPopup]);

  const handleClose = () => {
    setOpenPopup(false);
  };





  return (
    <Dialog
      open={openPopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper
        elevation={0}
        isMobile={isMobile}
      >
        <CloseButton
          aria-label="close"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </CloseButton>

        <PledgxLogo width={isMobile ? 45 : ''} height={isMobile ? 45 : ''} />
        <FormTitle style={{ marginTop: 10 }}>{'List of Users'}</FormTitle>
        <InfoArea isMobile={isMobile} marginTop="10px">

          {data && Array.isArray(data) && data.length > 0 ? (
            <StyledConciseTableContainer>
              <TableHead>
                <StyledTableRow borderRadius={0}>
                  <StyledTableCell width="35%" header={true}>Name</StyledTableCell>
                  <StyledTableCell width="35%" header={true}>Email</StyledTableCell>
                  <StyledTableCell width="20%" header={true}>Position</StyledTableCell>
                  <StyledTableCell width="10%" header={true}>Role</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component={"tr"} width="35%">
                      {item.first_name} {item.last_name}
                    </StyledTableCell>
                    <StyledTableCell component={"tr"} width="35%">{item.email}</StyledTableCell>
                    <StyledTableCell component={"tr"} width="20%">{item.position}</StyledTableCell>
                    <StyledTableCell component={"tr"} width="10%">{item.roles[0]}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </StyledConciseTableContainer>
          ) : (
            <p>No user data available.</p>
          )}

        </InfoArea>

        <FunctionalButton
          width="30%"
          marginTop="16px"
          marginRight="0px"
          handleButton={() => {
            setOpenPopup(false);
          }}
          buttonTitle={"Go Back"}
        />
      </StyledPaper>
    </Dialog>

  )
}