import React, { useState, useEffect } from "react";
import { FunctionalButton } from 'components/functionalButton';
import { StyledTableCell } from 'components/filesListTable/styles';
import {
  Title,
  TableDiv,
  InfoText,
  InfoDiv
} from './styles';
import ConciseTable from "components/filesListTable/conciseTable";
import { getProjectReferees, sendProjectReferenceRequests } from "api/reference";
import updateObjectByPath from "common/updateObjectByPath";
import { AddPendingReferencePopup } from "../../../components/addPendingReferencePopup";
import { PendingAlert } from "./PendingAlert";

export const Panel3 = ({
  setPanel,
  isMobile,
  projectId,
  setOpen,
  setOpenSuccessPopup
}) => {
  const [data, setData] = useState({});
  const [pickedReferess, setPickReferees] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [pendingData, setPendingData] = useState({});

  useEffect(() => {
    getProjectReferees(projectId, (res) => { setData(res); }, () => { });
  }, []);

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCell component={'tr'} width="30%">
          {`${bodyData.first_name}${bodyData.first_name && bodyData.last_name ? " " : ''}${bodyData.last_name}`}
        </StyledTableCell>
        <StyledTableCell component={'tr'} scope="row" width="30%">
          {bodyData.email}
        </StyledTableCell>
        <StyledTableCell component={'tr'} scope="row" width="30%">
          {bodyData.relationship}
        </StyledTableCell>
        <StyledTableCell component={'tr'} scope="row" width="10%">

        </StyledTableCell>
      </>
    );
  };

  const handleSelected = (selectedIndexList) => {
    const temp = []
    selectedIndexList.forEach(element => temp.push(data?.referees[element]));
    setPickReferees(temp);
    const updatingObject = temp.find(item => item.project_status === 'updating' || item.project_status === 'pending');

    if (updatingObject !== undefined && (updatingObject?.project_status === 'updating' || updatingObject?.project_status === 'pending')) {
      setIsPending(true);
      setPendingData(updatingObject);
    }
    
  }

  const handleSendRequest = () => {
    const pickedData = { ...data, referees: pickedReferess }
    sendProjectReferenceRequests(pickedData, () => { setOpen(false); setOpenSuccessPopup(true); setPanel(1) }, () => { })
  }

  return (
    <>
      <Title fontWeight={500} margin="0px 0 0">Who would you like a reference from?</Title>
      <InfoDiv>
        <InfoText>{data?.project_name}</InfoText>
        <InfoText fontSize="15px" fontWeight="500">{data?.project_number}</InfoText>
        <InfoText fontSize="15px" fontWeight="500">{data?.project_location}</InfoText>
      </InfoDiv>
      <TableDiv>
        <ConciseTable
          length={4}
          body={body}
          data={data?.referees}
          hover={true}
          show={true}
          width="80%"
          paddingRight="0px"
          handleSelected={handleSelected}
          multiSelect={true}
        />
      </TableDiv>

      <FunctionalButton
        buttonTitle={"ASK FOR REFERENCE"}
        handleButton={handleSendRequest}
        width={isMobile ? "90%" : "368px"}
        marginRight="0px"
        marginTop="24px"
        disabled={!(pickedReferess.length > 0)}
      />
      
      <AddPendingReferencePopup
        setOpenDialog={setOpenPopup}
        openDialog={openPopup}
        objectData={pendingData}
        setObjectData={setPendingData}
        data={data}
        setData={setData}
        setPickReferees={setPickReferees}
      />

      <PendingAlert 
           setOpenDialog={setIsPending}
           openDialog={isPending}
           setOpenPopup={setOpenPopup}      
      />
    </>

  )
}
