import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { SideNav } from "./SideNav";
import { Personnel } from "../detailScreens/SeeAllPersonnel";
import { Project } from "../detailScreens/SeeAllProjects";
import { AnnualRecords } from "./AnnualRecords";
import { getAnnualInfo, saveAnnualInfo } from "../../../api/profile";
import { ScreenRoot, FormContainer } from "../styles";

import { SideBarContainer, StyledMainPanel } from "./styles";
import { Admin } from "./Admin";
import { ContactInfo } from "./ContactInfo";
import { CompanyInfo } from "./CompanyInfo";
import { FinancialInfo } from "./FinancialInfo";
import DrawerSideNav from "./DrawerSideNav";
import { SuccessPopup } from "./SuccessPopup";
import { UserAccessManagement } from "./UserAccessManagement";
import { HealthSafety } from "./HealthSafety";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { getInstitutionProfileInfo } from "data/actions/serviceProvider";

function SwitchComponents({ active, children }) {
  return children.filter((child) => child.props.step === active);
}

export default function ProfileSetup() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [activeStep, setActiveStep] = useState(0);

  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const institution_id = location?.state?.institution_id || "";
  const currentDashboardUrl = localStorage.getItem("current_dashboard_url");

  useEffect(() => {
    var inputData = {};
    if (
      currentDashboardUrl === "/service-provider-dashboard" &&
      institution_id
    ) {
      inputData = { institution_id: institution_id };
      dispatch(
        getInstitutionProfileInfo(
          { institution_id: institution_id },
          () => {},
          () => {}
        )
      );
    }
    getAnnualInfo(
      inputData,
      (res) => setData({ ...res }),
      () => {}
    );
  }, []);

  return (
    <ScreenRoot isMobile={isMobile}>
      {isMobile && (
        <DrawerSideNav
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          isMobile={isMobile}
        />
      )}
      {!isMobile && (
        <SideBarContainer isMobile={isMobile}>
          <SideNav
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isMobile={isMobile}
            currentDashboardUrl={currentDashboardUrl}
          />
        </SideBarContainer>
      )}

      <FormContainer isMobile={isMobile} leftBorder={true}>
        {currentDashboardUrl !== "/service-provider-dashboard" && (
          <SwitchComponents active={activeStep}>
            <ContactInfo
              step={0}
              isMobile={isMobile}
              setOpenSuccessPopup={setOpenSuccessPopup}
            />
            <CompanyInfo
              step={1}
              isMobile={isMobile}
              setOpenSuccessPopup={setOpenSuccessPopup}
            />
            <FinancialInfo
              step={2}
              isMobile={isMobile}
              setOpenSuccessPopup={setOpenSuccessPopup}
            />
            <HealthSafety
              step={3}
              getAnnualInfo={getAnnualInfo}
              saveAnnualInfo={saveAnnualInfo}
              data={data}
              setData={setData}
            />
            <AnnualRecords
              step={4}
              isMobile={isMobile}
              setOpenSuccessPopup={setOpenSuccessPopup}
              getAnnualInfo={getAnnualInfo}
              saveAnnualInfo={saveAnnualInfo}
              data={data}
              setData={setData}
            />
            <StyledMainPanel step={5} isMobile={isMobile}>
              <Personnel narrowCard={true} noWidthChange={true} />
            </StyledMainPanel>
            <StyledMainPanel step={6} isMobile={isMobile}>
              <Project noWidthChange={true} />
            </StyledMainPanel>
            <UserAccessManagement
              step={999}
              isMobile={isMobile}
              setOpenSuccessPopup={setOpenSuccessPopup}
            />
            <Admin
              step={7}
              isMobile={isMobile}
              setOpenSuccessPopup={setOpenSuccessPopup}
            />
          </SwitchComponents>
        )}

        {currentDashboardUrl === "/service-provider-dashboard" && (
          <SwitchComponents active={activeStep}>
            <CompanyInfo
              step={0}
              isMobile={isMobile}
              setOpenSuccessPopup={setOpenSuccessPopup}
              institution_id={institution_id}
              currentDashboardUrl={currentDashboardUrl}
            />
            <FinancialInfo
              step={1}
              isMobile={isMobile}
              setOpenSuccessPopup={setOpenSuccessPopup}
              institution_id={institution_id}
              currentDashboardUrl={currentDashboardUrl}
            />
            <HealthSafety
              step={2}
              getAnnualInfo={getAnnualInfo}
              saveAnnualInfo={saveAnnualInfo}
              data={data}
              setData={setData}
              institution_id={institution_id}
              currentDashboardUrl={currentDashboardUrl}
            />
            <AnnualRecords
              step={3}
              isMobile={isMobile}
              setOpenSuccessPopup={setOpenSuccessPopup}
              getAnnualInfo={getAnnualInfo}
              saveAnnualInfo={saveAnnualInfo}
              data={data}
              setData={setData}
              institution_id={institution_id}
              currentDashboardUrl={currentDashboardUrl}
            />
            <StyledMainPanel step={4} isMobile={isMobile}>
              <Personnel
                narrowCard={true}
                noWidthChange={true}
                institution_id={institution_id}
                currentDashboardUrl={currentDashboardUrl}
              />
            </StyledMainPanel>
            <StyledMainPanel step={5} isMobile={isMobile}>
              <Project
                noWidthChange={true}
                institution_id={institution_id}
                currentDashboardUrl={currentDashboardUrl}
              />
            </StyledMainPanel>
            {/* <UserAccessManagement
              step={999}
              isMobile={isMobile}
              setOpenSuccessPopup={setOpenSuccessPopup}
            /> */}
          </SwitchComponents>
        )}
        <SuccessPopup
          setOpenPopup={setOpenSuccessPopup}
          openPopup={openSuccessPopup}
          isMobile={isMobile}
        />
      </FormContainer>
    </ScreenRoot>
  );
}
