import styled from 'styled-components';
import { StyledTableCell } from '../filesListTable/styles';


export const StyledTableCellFirst = styled(StyledTableCell)`
  width: 80%;
`;

export const StyleDisplay = styled.div`
  width: ${props => props.displayPercentage || "75%"};
`;
