import React, { useEffect, useState, useRef } from "react";
import { FloatingButton } from "../../../components/floatingButton";
import updateObjectByPath from "../../../common/updateObjectByPath";
import { SafetyRecordsUSA } from "./safetyRecordsUSA";
import { IconButton } from "@material-ui/core";
import MobileSaveButtonIcon from "../../../assets/dashboardIcons/mobileSaveButtonIcon";
import FilledAccordion from "../../../components/filledAccordion";
import { ResultPanel } from "./resultPanel.js";
import Select from "react-select";
import { FunctionalButton } from "components/functionalButton";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";
import DocumentPoolPopUp from "components/documentPoolPopUp";
import { WrapperForUploadedHealthSafetyFileDisplay } from "pages/preQualifyForm/components/uploadedHealthSafetyFileDisplay";
import ConciseTable from "components/filesListTable/conciseTable";
import {
  COLORS,
  healthSafetyProgramList,
  healthSafetyEmployeeOrientationProgramList,
} from "../../../constants";
import { ScrollableDiv } from "pages/documentPool/styles";
import { AlertPopup } from "components/alertPopup/alertPopup";

import {
  FieldArea,
  StyledRow,
  SectionText,
  SetupContainer,
  StyledEditRecordButton,
  SecondRadioContainer,
  StyledSafetyRecordsContainer,
  StyledTextField,
  SafetyText,
  StyledCenter,
  StyledHealthSafetyPersonnelNone,
} from "./styles";
import { StyledSubtext } from "pages/loginPage/styles";
import { RadioButton } from "components/radioButton";
import CheckedRadioButton from "assets/dashboardIcons/checkedRadioButton";
import UncheckedRadioButton from "assets/dashboardIcons/uncheckedRadioButton";
import { LabelText } from "./safetyRecords/styles";
import { useSelector } from "react-redux";
import { contactSelector } from "data/selectors/contact";

export const HealthSafetyUSA = ({
  isMobile,
  setOpenSuccessPopup,
  getAnnualInfo,
  saveAnnualInfo,
  data,
  setData,
  institutionData,
  currentDashboardUrl,
}) => {
  const [filteredData, setFilteredData] = useState(
    data?.health_safety_personnel || []
  );
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupPersonnel, setOpenPopupPersonnel] = useState(false);
  const dataListOne =
    data?.annual_record_remarks_and_cor?.health_safety_program_list;
  const [selectedListOneOptions, setSelectedListOneOptions] =
    useState(dataListOne);
  const dataListTwo =
    data?.annual_record_remarks_and_cor
      ?.health_safety_employee_orientation_program_list;
  const [selectedListTwoOptions, setSelectedListTwoOptions] =
    useState(dataListTwo);
  const [internalSearchResults, setInternalSearchResults] = useState(
    data?.health_safety_documents_usa || []
  );
  const [query, setQuery] = useState("");
  const [openDocumentPool, setOpenDocumentPool] = useState(false);
  let requirements = [];
  const headers = [];
  const [selectedFiles, setSelectedFiles] = useState([]);
  const contactData = useSelector(contactSelector);
  const personnelLimitCount =
    contactData?.user_access_control?.number_of_personnel;
  const fileLimitCount = contactData?.user_access_control?.file_limit_count;
  const fileLimitSize = contactData?.user_access_control?.file_limit_size;
  const fileLimitSizeMB = (fileLimitSize / (1024 * 1024)).toFixed(2);
  const [healthSafetyPersonnelNone, setHealthSafetyPersonnelNone] = useState(
    data?.annual_record_remarks_and_cor?.health_safety_personnel_none
  );
  const [isFocused, setIsFocused] = useState(false);
  const [isFocusedTwo, setIsFocusedTwo] = useState(false);
  const deleteHealthSafetyFile = (index) => {
    let temp = [...data?.health_safety_documents_usa];
    temp.splice(index, 1);
    setData({
      ...data,
      health_safety_documents_usa: temp,
    });
  };

  const addUploadedFile = () => {
    let temp = [...data?.health_safety_documents_usa];

    for (let key in selectedFiles) {
      if (!isNaN(key)) {
        temp.push(selectedFiles[key]);
      }
    }

    setData({
      ...data,
      health_safety_documents_usa: temp,
    });

    setOpenDocumentPool(false);
  };

  const handleDocumentPoolPopUp = () => {
    setOpenDocumentPool(true);
  };

  const listOneOptions = healthSafetyProgramList.map((option) => ({
    value: option,
    label: option,
  }));

  const listTwoOptions = healthSafetyEmployeeOrientationProgramList.map(
    (option) => ({
      value: option,
      label: option,
    })
  );

  const handleSelectChange = (selectedListOneOptions) => {
    setSelectedListOneOptions(selectedListOneOptions);
  };

  const handleSelectListTwoChange = (selectedListTwoOptions) => {
    setSelectedListTwoOptions(selectedListTwoOptions);
  };

  const [healthSafetyProgram, setHealthSafetyProgram] = useState(
    data?.annual_record_remarks_and_cor?.health_safety_program || false
  );
  const [notHealthSafetyProgram, setNotHealthSafetyProgram] = useState(
    data?.annual_record_remarks_and_cor?.no_health_safety_program || false
  );
  const [
    healthSafetyEmployeeOrientationProgram,
    setHealthSafetyEmployeeOrientationProgram,
  ] = useState(
    data?.annual_record_remarks_and_cor
      ?.health_safety_employee_orientation_program || false
  );
  const [
    notHealthSafetyEmployeeOrientationProgram,
    setNotHealthSafetyEmployeeOrientationProgram,
  ] = useState(
    data?.annual_record_remarks_and_cor
      ?.no_health_safety_employee_orientation_program || false
  );
  const [safetyRemark, setSafetyRemark] = useState(
    data?.annual_record_remarks_and_cor?.annual_safety_records_remark_usa || ""
  );

  const [notHealthSafetyProgramRemark, setNotHealthSafetyProgramRemark] =
    useState(
      data?.annual_record_remarks_and_cor?.no_health_safety_program_remark || ""
    );

  const [
    notHealthSafetyEmployeeOrientationProgramRemark,
    setNotHealthSafetyEmployeeOrientationProgramRemark,
  ] = useState(
    data?.annual_record_remarks_and_cor
      ?.no_health_safety_employee_orientation_program_remark || ""
  );

  const handleSafetyRemarkChange = (event) => {
    setSafetyRemark(event.target.value);
  };

  const handlesNotHealthSafetyProgramRemarkChange = (event) => {
    setNotHealthSafetyProgramRemark(event.target.value);
  };

  const handlesNotHealthSafetyEmployeeOrientationProgramRemarkChange = (
    event
  ) => {
    setNotHealthSafetyEmployeeOrientationProgramRemark(event.target.value);
  };

  if (data && !data.annual_record_remarks_and_cor) {
    data["annual_record_remarks_and_cor"] = {};
  }

  useEffect(() => {
    const updatedData = { ...data };
    updatedData["annual_record_remarks_and_cor"]["health_safety_program_list"] =
      selectedListOneOptions;
    updatedData["annual_record_remarks_and_cor"][
      "health_safety_employee_orientation_program_list"
    ] = selectedListTwoOptions;
    updatedData["annual_record_remarks_and_cor"][
      "annual_safety_records_remark_usa"
    ] = safetyRemark;
    updatedData["annual_record_remarks_and_cor"]["health_safety_program"] =
      healthSafetyProgram;
    updatedData["annual_record_remarks_and_cor"][
      "health_safety_employee_orientation_program"
    ] = healthSafetyEmployeeOrientationProgram;
    updatedData["annual_record_remarks_and_cor"]["no_health_safety_program"] =
      notHealthSafetyProgram;
    updatedData["annual_record_remarks_and_cor"][
      "no_health_safety_program_remark"
    ] = notHealthSafetyProgramRemark;
    updatedData["annual_record_remarks_and_cor"][
      "no_health_safety_employee_orientation_program"
    ] = notHealthSafetyEmployeeOrientationProgram;
    updatedData["annual_record_remarks_and_cor"][
      "no_health_safety_employee_orientation_program_remark"
    ] = notHealthSafetyEmployeeOrientationProgramRemark;
    updatedData["annual_record_remarks_and_cor"][
      "health_safety_personnel_none"
    ] = healthSafetyPersonnelNone;
    updatedData["annual_record_remarks_and_cor"]["country"] = "United States";
    setData(updatedData);
  }, [
    safetyRemark,
    selectedListOneOptions,
    selectedListTwoOptions,
    healthSafetyProgram,
    healthSafetyEmployeeOrientationProgram,
    notHealthSafetyProgram,
    notHealthSafetyProgramRemark,
    notHealthSafetyEmployeeOrientationProgram,
    notHealthSafetyEmployeeOrientationProgramRemark,
    healthSafetyPersonnelNone,
  ]);

  const handleSave = () => {
    const newdata = { ...data, ...institutionData };

    saveAnnualInfo(
      newdata,
      () => {
        setOpenSuccessPopup(true);
      },
      () => {}
    );
  };

  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };

  useEffect(() => {
    onChangeData("health_safety_personnel", filteredData);
  }, [filteredData]);

  const confirmRadioChange = (val) => {
    if (val === 1) {
      setHealthSafetyProgram(true);
      setNotHealthSafetyProgram(false);
    } else {
      setNotHealthSafetyProgram(true);
      setHealthSafetyProgram(false);
    }
  };

  const confirmSecondRadio = (val) => {
    if (val === 1) {
      setHealthSafetyEmployeeOrientationProgram(true);
      setNotHealthSafetyEmployeeOrientationProgram(false);
    } else {
      setNotHealthSafetyEmployeeOrientationProgram(true);
      setHealthSafetyEmployeeOrientationProgram(false);
    }
  };

  const handleHealthSafetyPersonnelNoneChange = () => {
    setHealthSafetyPersonnelNone(!healthSafetyPersonnelNone);
  };

  const saveButtonRef = useRef();

  return (
    <SetupContainer isMobile={isMobile}>
      {!isMobile && (
        <FloatingButton
          buttonTitle="Save"
          handleButton={handleSave}
          width="150px"
          height="38px"
          marginRight="0px"
          marginTop="-280px"
        />
      )}
      <StyledRow justifyContent="space-between" ref={saveButtonRef}>
        {isMobile && (
          <IconButton onClick={handleSave}>
            <MobileSaveButtonIcon />
          </IconButton>
        )}
      </StyledRow>
      <FieldArea noJustifyContent={true} isMobile={isMobile}>
        {!isMobile && (
          <>
            <SectionText style={{ marginTop: "-20px" }}>
              Health and Safety
            </SectionText>
            <StyledRow style={{ marginBottom: "-10px" }}>
              <StyledSubtext style={{ width: "90%" }}>
                {" "}
                Select Personnel responsible for Health & Safety:{" "}
              </StyledSubtext>

              <StyledRow justifyContent="right" style={{ marginTop: "-10px" }}>
                <StyledHealthSafetyPersonnelNone
                  control={
                    <RadioButton
                      isChecked={healthSafetyPersonnelNone}
                      handleChange={handleHealthSafetyPersonnelNoneChange}
                    />
                  }
                  label="None"
                  labelPlacement="end"
                />
              </StyledRow>
            </StyledRow>

            {healthSafetyPersonnelNone === false && (
              <ResultPanel
                projects={filteredData}
                setProjects={setFilteredData}
                setOpenPopup={setOpenPopupPersonnel}
                openPopup={openPopupPersonnel}
                paddingRight="0px"
                height="150px"
                label={false}
                institutionId={institutionData?.institution_id || ""}
                currentDashboardUrl={currentDashboardUrl}
              />
            )}

            <StyledSubtext style={{ marginTop: "28px" }}>
              {" "}
              Do you have a formal Health & Safety Program?{" "}
            </StyledSubtext>
            <StyledEditRecordButton>
              <RadioButton
                isChecked={
                  healthSafetyProgram === true &&
                  notHealthSafetyProgram === false
                    ? true
                    : false
                }
                value={healthSafetyProgram}
                checkedIcon={<CheckedRadioButton />}
                unCheckedIcon={<UncheckedRadioButton />}
                handleChange={() => confirmRadioChange(1)}
              />
              <StyledSubtext style={{ marginTop: "0px" }}> Yes</StyledSubtext>
              <RadioButton
                isChecked={
                  notHealthSafetyProgram === true &&
                  healthSafetyProgram === false
                    ? true
                    : false
                }
                value={notHealthSafetyProgram}
                checkedIcon={<CheckedRadioButton />}
                unCheckedIcon={<UncheckedRadioButton />}
                handleChange={() => confirmRadioChange(0)}
              />
              <StyledSubtext style={{ marginTop: "0px" }}> No</StyledSubtext>
            </StyledEditRecordButton>
            {healthSafetyProgram && (
              <SecondRadioContainer style={{ marginLeft: "30px" }}>
                <StyledSubtext style={{ marginTop: "15px" }}>
                  {" "}
                  Select procedures covered by the program:{" "}
                </StyledSubtext>
                <Select
                  options={listOneOptions}
                  isMulti
                  value={selectedListOneOptions}
                  onChange={handleSelectChange}
                  menuIsOpen={isFocused}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "765px",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? "#ECEBED" : "white",
                      ":hover": {
                        backgroundColor: "#ECEBED",
                        border: "1px solid #ECEBED",
                      },
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: "#FFD9C5",
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: "#FF6D1D",
                    }),
                    multiValueRemove: (provided) => ({
                      ...provided,
                      color: "#FFAA7B",
                      ":hover": {
                        backgroundColor: "#FFD9C5",
                        color: "#FF6D1D",
                      },
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: state.isFocused
                        ? "#FF6D1D"
                        : provided.borderColor,
                      boxShadow: state.isFocused
                        ? "0 0 0 1px #FF6D1D"
                        : provided.boxShadow,
                      "&:hover": {
                        borderColor: "#FF6D1D",
                      },
                    }),
                  }}
                />
              </SecondRadioContainer>
            )}

            {notHealthSafetyProgram && (
              <>
                <FieldArea
                  width="96%"
                  noJustifyContent={true}
                  style={{ marginLeft: "30px" }}
                >
                  <StyledSubtext style={{ marginTop: "-10px" }}>
                    {" "}
                    Explain how your company manages Health & Safety:{" "}
                  </StyledSubtext>
                  <StyledTextField
                    multiline
                    rows={5}
                    placeholder={"Enter your explanation here"}
                    variant="outlined"
                    value={notHealthSafetyProgramRemark}
                    onChange={handlesNotHealthSafetyProgramRemarkChange}
                  />
                </FieldArea>
              </>
            )}

            <StyledRow>
              <StyledSubtext style={{ marginTop: "15px" }}>
                {" "}
                Do you have a formal project Health & Safety Orientation
                program?{" "}
              </StyledSubtext>
            </StyledRow>

            <StyledEditRecordButton>
              <RadioButton
                isChecked={
                  healthSafetyEmployeeOrientationProgram === true &&
                  notHealthSafetyEmployeeOrientationProgram === false
                    ? true
                    : false
                }
                value={healthSafetyEmployeeOrientationProgram}
                checkedIcon={<CheckedRadioButton />}
                unCheckedIcon={<UncheckedRadioButton />}
                handleChange={() => confirmSecondRadio(1)}
              />
              <StyledSubtext style={{ marginTop: "0px" }}> Yes</StyledSubtext>
              <RadioButton
                isChecked={
                  notHealthSafetyEmployeeOrientationProgram === true &&
                  healthSafetyEmployeeOrientationProgram === false
                    ? true
                    : false
                }
                value={notHealthSafetyEmployeeOrientationProgram}
                checkedIcon={<CheckedRadioButton />}
                unCheckedIcon={<UncheckedRadioButton />}
                handleChange={() => confirmSecondRadio(0)}
              />
              <StyledSubtext style={{ marginTop: "0px" }}> No</StyledSubtext>
            </StyledEditRecordButton>

            {healthSafetyEmployeeOrientationProgram && (
              <SecondRadioContainer style={{ marginLeft: "30px" }}>
                <StyledSubtext style={{ marginTop: "15px" }}>
                  {" "}
                  Select procedures covered by the orientation program:{" "}
                </StyledSubtext>
                <Select
                  options={listTwoOptions}
                  isMulti
                  value={selectedListTwoOptions}
                  onChange={handleSelectListTwoChange}
                  menuIsOpen={isFocusedTwo}
                  onFocus={() => setIsFocusedTwo(true)}
                  onBlur={() => setIsFocusedTwo(false)}
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "765px",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? "#ECEBED" : "white",
                      ":hover": {
                        backgroundColor: "#ECEBED",
                        border: "1px solid #ECEBED",
                      },
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: "#FFD9C5",
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: "#FF6D1D",
                    }),
                    multiValueRemove: (provided) => ({
                      ...provided,
                      color: "#FFAA7B",
                      ":hover": {
                        backgroundColor: "#FFD9C5",
                        color: "#FF6D1D",
                      },
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: state.isFocused
                        ? "#FF6D1D"
                        : provided.borderColor,
                      boxShadow: state.isFocused
                        ? "0 0 0 1px #FF6D1D"
                        : provided.boxShadow,
                      "&:hover": {
                        borderColor: "#FF6D1D",
                      },
                    }),
                  }}
                />
              </SecondRadioContainer>
            )}

            {notHealthSafetyEmployeeOrientationProgram && (
              <>
                <FieldArea
                  width="96%"
                  noJustifyContent={true}
                  style={{ marginLeft: "30px" }}
                >
                  <StyledSubtext style={{ marginTop: "-10px" }}>
                    {" "}
                    Explain how your company manages Health & Safety
                    Orientation:{" "}
                  </StyledSubtext>
                  <StyledTextField
                    multiline
                    rows={5}
                    placeholder={"Enter your explanation here"}
                    variant="outlined"
                    value={notHealthSafetyEmployeeOrientationProgramRemark}
                    onChange={
                      handlesNotHealthSafetyEmployeeOrientationProgramRemarkChange
                    }
                  />
                </FieldArea>
              </>
            )}

            <StyledSubtext style={{ marginTop: "28px" }}>
              {" "}
              Attach all relevant Health & Safety documents:{" "}
            </StyledSubtext>

            <div style={{ display: "flex", width: "100%" }}>
              <div
                style={{
                  marginLeft: "0px",
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "70%",
                }}
              >
                <SearchBar
                  filteredData={data?.health_safety_documents_usa}
                  setInternalSearchResults={setInternalSearchResults}
                  internalSearch={true}
                  query={query}
                  setQuery={setQuery}
                  searchWidth="100%"
                  searchPlaceHolder="File Name..."
                />
              </div>
              <FunctionalButton
                buttonTitle="Document Pool"
                marginLeft="20px"
                handleButton={() => handleDocumentPoolPopUp()}
                width="250px"
                isReversedColor={true}
              />
            </div>

            {data?.health_safety_documents_usa?.length < 1 && (
              <div
                style={{
                  alignItems: "center",
                  backgroundColor: "white",
                  border: `dashed 2px ${COLORS.lightGrey1}`,
                  display: "flex",
                  height: "186px",
                  color: `${COLORS.textGrey}`,
                  justifyContent: "center",
                  width: "99%",
                  marginTop: "20px",
                }}
              >
                Selected Documents will appear here
              </div>
            )}
            {data?.health_safety_documents_usa?.length > 0 && (
              <div style={{ marginTop: "20px", width: "100%" }}>
                <ConciseTable headers={headers} />

                <ScrollableDiv style={{ maxHeight: "190px" }}>
                  <div style={{ marginRight: "15px" }}>
                    {data?.health_safety_documents_usa?.length > 0 &&
                      (query && query !== ""
                        ? internalSearchResults
                        : data?.health_safety_documents_usa
                      )?.map((item, i) => (
                        <StyledCenter style={{ marginTop: 0 }}>
                          <WrapperForUploadedHealthSafetyFileDisplay
                            key={`file-${i}`}
                            file={item}
                            displayPercentage="100%"
                            deleteFile={deleteHealthSafetyFile}
                            index={i}
                            setPrequalifyData={setData}
                            prequalifyData={data}
                            setOpenPopup={setOpenPopup}
                            openPopup={openPopup}
                          />
                        </StyledCenter>
                      ))}
                  </div>
                </ScrollableDiv>
              </div>
            )}

            {/* <AlertPopup
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
              headerText={"You have exceeded the file limit."}
              subHeaderText={`You can upload a maximum of ${fileLimitCount} documents or ${fileLimitSizeMB}MB.`}
            /> */}
            <DocumentPoolPopUp
              openPopup={openDocumentPool}
              setOpenPopup={setOpenDocumentPool}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              attachFile={addUploadedFile}
              institutionId={institutionData?.institution_id || ""}
            />

            <SectionText style={{ marginTop: "48px" }}>
              Annual Safety Records
            </SectionText>
            <StyledSafetyRecordsContainer>
              <div>
                <SafetyText
                  style={{
                    marginLeft: "55px",
                    marginTop: "20px",
                    color: "#888888",
                    fontSize: "15px",
                    fontWeight: "600px",
                    whiteSpace: "wrap",
                  }}
                >
                  {" "}
                  From EMR Worksheet
                </SafetyText>
                <hr
                  style={{
                    color: "#888888",
                    backgroundColor: "#888888",
                    height: 0.25,
                    opacity: 0.25,
                    borderColor: "#888888",
                    marginLeft: "55px",
                    marginRight: "-80px",
                    marginBottom: "-10px",
                    whiteSpace: "wrap",
                  }}
                />
              </div>

              <div>
                <SafetyText
                  style={{
                    marginLeft: "75px",
                    marginTop: "20px",
                    color: "#888888",
                    fontSize: "15px",
                    fontWeight: "600px",
                    whiteSpace: "wrap",
                  }}
                >
                  {" "}
                  From OSHA 300A
                </SafetyText>
                <hr
                  style={{
                    color: "#888888",
                    backgroundColor: "#888888",
                    height: 0.25,
                    opacity: 0.25,
                    borderColor: "#888888",
                    marginLeft: "75px",
                    marginRight: "-355px",
                    marginBottom: "-10px",
                    whiteSpace: "wrap",
                  }}
                />
              </div>
            </StyledSafetyRecordsContainer>

            <SafetyRecordsUSA
              isMobile={isMobile}
              data={data}
              setData={onChangeData}
            />
            <FieldArea width="98%" noJustifyContent={true}>
              <LabelText> Remarks </LabelText>
              <StyledTextField
                multiline
                rows={5}
                placeholder={"Remark"}
                variant="outlined"
                value={safetyRemark}
                onChange={handleSafetyRemarkChange}
              />
            </FieldArea>
          </>
        )}
        {isMobile && (
          <>
            <FilledAccordion
              header="Annual Safety Records"
              details={
                <SafetyRecordsUSA
                  isMobile={isMobile}
                  data={data}
                  setData={onChangeData}
                />
              }
            />
          </>
        )}
      </FieldArea>
    </SetupContainer>
  );
};
