import React, { useState, useEffect } from "react";

import {
  FormContainer,
} from "../styles";

import { MainPanel } from "./mainPanel";
import { MatchPanel } from "./matchPanel";
import { AddPanel } from "./addPanel";
import { ResultPanel } from './resultPanel';


export const PrincipalProjectsForm = ({
  setActiveStep,
  qualificationData,
  setQualificationData,
  setIsLastPanel,
  setPreviousStepPanel,
}) => {
  setIsLastPanel(true);
  setPreviousStepPanel(1);

  const [panel, setPanel] = useState("result" );

  const [projects, setProjects] = useState(
    qualificationData?.projects ? qualificationData.projects : []
  );

  const [addData, setAddData] = useState({ type: ['key'] });
  const [parsed, setParsed] = useState([]);
  const [fields, setFields] = useState([]);
  const [viewer, setViewer] = useState({});
  const [editIndex, setEditIndex] = useState(-1);
  const [openEdit, setOpenEdit] = useState(false);

  const steps = ["Upload a file",
                 "Match the column titles",
                 "Review and edit"];

  useEffect(() => {
      setQualificationData('projects', projects);
  }, [projects]);

  return (
    <FormContainer>
      {panel === "main" && (
        <MainPanel
          steps={steps}
          setPanel={setPanel}
          setParsed={setParsed}
          setFields={setFields}
        />
      )}
      {(openEdit || panel === "add") && (
        <AddPanel
          addData={addData}
          setAddData={setAddData}
          setPanel={setPanel}
          setProjects={setProjects}
          editIndex={editIndex}
          setEditIndex={setEditIndex}
          setOpenEdit={setOpenEdit}
        />
      )}
      {(!openEdit && panel === "match") && (
        <MatchPanel
          steps={steps}
          fields={fields}
          parsed={parsed}
          viewer={viewer}
          setViewer={setViewer}
          setProjects={setProjects}
          setPanel={setPanel}
        />
      )}
      {(!openEdit && panel === "result") && (
        <ResultPanel
          projects={projects}
          setPanel={setPanel}
          setAddData={setAddData}
          setProjects={setProjects}
          setEditIndex={setEditIndex}
          setOpenEdit={setOpenEdit}
          setActiveStep={setActiveStep}
        />
      )}
    </FormContainer>
  );
};
