import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { logoutUser } from "../../data/actions/user";
import {
  getLatestSubscriptionReceipt,
  getSubscriptionStatus,
} from "../../data/actions/subscriptions";
import {
  receiptSelector,
  subscriptionStatusSelector,
} from "../../data/selectors/subscriptions";

import classes from "./style/Confirmation.module.css";

import { FunctionalButton } from "../../components/functionalButton";
import { Popup } from "../../components/popup";
import PledgxLogo from "../../assets/common/pledgxLogo";
import Spinner from "../../components/spinner";
import Success from "../../assets/dashboardIcons/Success";

import {
  ButtonAreaVertical,
  ContentArea,
  ImagePopup,
  LargeText,
  LoadingImagePopup,
  PopupContainer,
  PopupLoadingContainer,
  PopupLoadingText,
  TextArea,
} from "./styles";

export const Confirmation = (props) => {
  // WARNING: UNSUBSCRIBE IS NOT IN USE, AND NOT FULLY IMPLEMENTED!

  const REFRESH_TIMEOUT = 3000;

  const history = useHistory();
  const dispatch = useDispatch();
  const receipt_data = useSelector(receiptSelector);
  const subscription_status = useSelector(subscriptionStatusSelector);

  const [state, setState] = useState({
    loading: true,
    pageType: "SUBSCRIBED",
    popup: {
      display: false,
      largeText: null,
      type: null,
    },
    receiptData: null,
  });
  const refreshTimeout = useRef();
  const refreshLimit = useRef(5);

  useEffect(() => {
    if (
      props &&
      props.subscriptionStatus &&
      props.subscriptionStatus === "SUBSCRIBED"
    ) {
      if (props && props.authStatus && props.authStatus.loggedIn) {
        handleRequests();
      } else {
        history.push("/account/login");
      }
    } else if (
      props &&
      props.subscriptionStatus &&
      props.subscriptionStatus === "UNSUBSCRIBED"
    ) {
      setState({
        ...state,
        loading: false,
        pageType: "UNSUBSCRIBED",
        popup: {
          ...state.popup,
          display: true,
          type: "PROCEED",
        },
      });
      dispatch(logoutUser());
    } else {
      setState({
        ...state,
        loading: false,
        pageType: null,
        popup: {
          ...state.popup,
          display: true,
          type: "ERROR",
        },
      });
    }

    return () => {
      clearTimeout(refreshTimeout.current);
    };
  }, []);

  useEffect(() => {
    if (receipt_data && Object.keys(receipt_data).length > 0) {
      handleReceiptRequest(true);
    }
  }, [receipt_data]);

  useEffect(() => {
    if (subscription_status) {
      if (subscription_status.error) {
        clearTimeout(refreshTimeout.current);
        setState({
          ...state,
          loading: false,
          pageType: null,
          popup: {
            ...state.popup,
            display: true,
            type: "ERROR",
          },
        });
      } else if (
        subscription_status.active ||
        subscription_status.active === false
      ) {
        if (state.pageType === "SUBSCRIBED") {
          if (
            subscription_status.active &&
            subscription_status.package &&
            subscription_status.package.title !== "Free"
          ) {
            localStorage.setItem("user_subscribed", "true");
            var receiptData = {};
            if (
              subscription_status.package &&
              subscription_status.package.title
            ) {
              receiptData.package = {
                title: subscription_status.package.title,
              };
            }
            clearTimeout(refreshTimeout.current);
            setState({
              ...state,
              loading: false,
              popup: {
                ...state.popup,
                display: true,
                type: "PROCEED",
              },
              receiptData: receiptData,
            });
          }
        } else if (state.pageType === "UNSUBSCRIBED") {
          if (!subscription_status.active) {
            clearTimeout(refreshTimeout.current);
            setState({
              ...state,
              loading: false,
              popup: {
                ...state.popup,
                display: true,
                type: "PROCEED",
              },
            });
            dispatch(logoutUser());
          }
        } else {
          clearTimeout(refreshTimeout.current);
          setState({
            ...state,
            loading: false,
            pageType: null,
            popup: {
              ...state.popup,
              display: true,
              type: "ERROR",
            },
          });
        }
      } else {
        clearTimeout(refreshTimeout.current);
        setState({
          ...state,
          loading: false,
          pageType: null,
          popup: {
            ...state.popup,
            display: true,
            type: "ERROR",
          },
        });
      }
    }
  }, [subscription_status]);

  function handleReceiptRequest(requestResult) {
    var receiptData;
    if (requestResult && receipt_data) {
      receiptData = receipt_data;
    }
    setState({
      ...state,
      loading: false,
      //pageType: 'SUBSCRIBED',
      popup: {
        ...state.popup,
        display: true,
      },
      receiptData: receiptData,
    });
    dispatch(logoutUser());
  }

  function handleRequests() {
    clearTimeout(refreshTimeout.current);
    if (refreshLimit.current > 0) {
      refreshLimit.current = refreshLimit.current - 1;
      if (state.loading || !state.popup.display || state.popup.type) {
        setState({
          ...state,
          loading: false,
          popup: {
            ...state.popup,
            display: true,
            type: null,
          },
        });
      }
      refreshTimeout.current = setTimeout(() => {
        dispatch(
          getSubscriptionStatus(
            null,
            () => null,
            () => handleStatusResult(false)
          )
        );
        handleRequests();
      }, REFRESH_TIMEOUT);
    } else {
      // DISPLAY ERROR, REDIRECT, ETC.
      handleStatusResult(false);
    }
  }

  function handleStatusResult(statusResult) {
    if (!statusResult) {
      setState({
        ...state,
        loading: false,
        popup: {
          ...state.popup,
          display: true,
          type: "ERROR",
        },
      });
    }
  }

  if (state.loading) {
    return (
      <div className={classes["loading-container"]}>
        <Spinner />
      </div>
    );
  } else {
    var popupText = "Thank you! You are subscribed";
    var billingText = "";
    var packageText = "";
    if (state.receiptData) {
      if (state.receiptData.billing_cycle) {
        if (state.receiptData.billing_cycle === "YR") {
          billingText = "on a yearly basis";
        } else if (state.receiptData.billing_cycle === "MO") {
          billingText = "on a monthly basis";
        }
      }
      if (state.receiptData.package && state.receiptData.package.title) {
        packageText = `to our ${state.receiptData.package.title} plan`;
      }
      popupText = [
        "Thank you! You are subscribed",
        packageText,
        billingText,
      ].join(" ");
    }
    return (
      <div className={classes.container}>
        <Popup
          open={state.popup.display}
          height="550px"
          innerHeight="550px"
          innerPadding={props.isMobile ? "0" : "60px 50px 40px 50px"}
          width={props.isMobile ? "100%" : "550px"}
          dialogContent={
            state.popup.type === "ERROR" ? (
              <PopupContainer>
                <ContentArea>
                  <ImagePopup>
                    <PledgxLogo isMobile={props.isMobile} />
                  </ImagePopup>
                  <TextArea>
                    <LargeText isMobile={props.isMobile}>
                      There was an error with your request
                    </LargeText>
                  </TextArea>
                </ContentArea>
                <ButtonAreaVertical isMobile={props.isMobile}>
                  <FunctionalButton
                    buttonTitle="TRY AGAIN"
                    height={props.isMobile ? "48px" : "53px"}
                    handleButton={() => history.push("/subscribe")}
                    width="100%"
                  />
                </ButtonAreaVertical>
              </PopupContainer>
            ) : state.popup.type === "PROCEED" ? (
              <PopupContainer>
                <ContentArea>
                  <ImagePopup>
                    <Success isMobile={props.isMobile} />
                  </ImagePopup>
                  <TextArea>
                    <LargeText isMobile={props.isMobile}>
                      {state.pageType === "SUBSCRIBED"
                        ? popupText
                        : "You have unsubscribed. You will continue to have access until your subscription expires. We hope to see you back soon!"}
                    </LargeText>
                  </TextArea>
                </ContentArea>
                <ButtonAreaVertical isMobile={props.isMobile}>
                  {state.receiptData && state.receiptData.receipt_url && (
                    <FunctionalButton
                      buttonTitle="VIEW YOUR RECEIPT"
                      height={props.isMobile ? "48px" : "53px"}
                      handleButton={() =>
                        window.open(
                          state.receiptData.receipt_url,
                          "_blank",
                          "noopener,noreferrer"
                        )
                      }
                      isReversedColor={true}
                      width="100%"
                      marginBottom="20px"
                    />
                  )}
                  <FunctionalButton
                    buttonTitle="DONE"
                    height={props.isMobile ? "48px" : "53px"}
                    handleButton={() => history.push("/")}
                    width="100%"
                  />
                </ButtonAreaVertical>
              </PopupContainer>
            ) : (
              <PopupContainer>
                <PopupLoadingContainer>
                  <LoadingImagePopup>
                    <PledgxLogo isMobile={props.isMobile} />
                  </LoadingImagePopup>
                  <Spinner />
                  <PopupLoadingText>
                    Thank you for subscribing to PledgXQualify. Just give us a
                    few moments to process your payment
                  </PopupLoadingText>
                </PopupLoadingContainer>
              </PopupContainer>
            )
          }
        />
      </div>
    );
  }
};
