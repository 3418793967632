import React, { useState } from "react";
import Select from "react-select";

import { InputLabel, InputWrap, ReadOnlyValue } from "./styles";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

function CustomSelect({
  options,
  value,
  setValue,
  isMulti,
  label,
  validateFunc,
  style,
  onChange,
  withoutMargin,
  iconMarginRight,
  paddingRight,
  noCheckIcon,
  showDropdownIndicator,
  showError,
  readOnly,
  useValueLabel,
  dropdownHeight,
  disabled,
}) {
  const [validate, setValidate] = useState(
    value ? (validateFunc(value) ? 1 : -1) : 0
  );

  const [valueLabel, setValueLabel] = useState();

  var innerStyle = {
    dropdownIndicator: (styles) => ({
      display: "none",
    }),
    indicatorSeparator: (styles) => ({
      display: "none",
    }),
    clearIndicator: (styles) => ({
      color: "#FF6D1D",
      paddingTop: 4,
      marginRight: 5,
      ":hover": {
        cursor: "pointer",
      },
    }),
    control: (styles) => ({
      ...styles,
      height: dropdownHeight ? dropdownHeight : "50px",
      fontSize: "15px",
      backgroundColor: validate === 1 || validate === -1 ? "white" : "white",

      border:
        validate === 1
          ? "1px solid #cccccc"
          : validate === -1
          ? "1px solid #FF647C"
          : "1px solid #cccccc",
      boxShadow: "none",
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        background: "#FFF3EC",
        borderRadius: "4px",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#F26934",
    }),
    menuList: (styles) => ({
      ...styles,
      maxHeight: 220,
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: "14px",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      fontSize: "12px",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#F26934",
      ":hover": {
        cursor: "pointer",
        background: "#F26934",
        opacity: 0.7,
        color: "whitesmoke",
      },
    }),
  };
  if (showDropdownIndicator) {
    delete innerStyle.dropdownIndicator;
    /*
    innerStyle.dropdownIndicator = (styles) => ({
      color: 'hsl(0, 0%, 80%)',
      display: 'flex',
      marginRight: '15px',
      transition: 'color 150ms'
    });
    */
  }

  return (
    <InputWrap style={{ ...style, marginTop: withoutMargin ? 0 : 25 }}>
      <InputLabel>{label}</InputLabel>
      {readOnly ? (
        <ReadOnlyValue
          fontSize="18px"
          paddingRight={paddingRight}
          value={value}
          width="100%"
        >
          {value}
        </ReadOnlyValue>
      ) : (
        <Select
          //arrowRenderer={ () => <div>T</div> }
          className={isMulti ? "basic-multi-select" : "basic-single"}
          classNamePrefix="select"
          isMulti={isMulti}
          isSearchable={true}
          name={label}
          onChange={(e) => {
            if (e.label && useValueLabel) {
              setValueLabel(e.label);
            }
            isMulti ? setValue(e.map((item) => item.value)) : setValue(e.value);
            validateFunc(e.value) ? setValidate(1) : setValidate(-1);
            if (onChange) onChange();
          }}
          options={options}
          menuPortalTarget={document.body}
          styles={innerStyle}
          value={
            value
              ? {
                  value: value,
                  label: useValueLabel && valueLabel ? valueLabel : value,
                }
              : null
          }
          isDisabled={disabled}
        />
      )}
      {validate === 1 && !noCheckIcon && (
        <CheckRoundedIcon
          style={{
            color: "#F26934",
            position: "absolute",
            right: isMulti ? 32 : 12,
            bottom: 12,
            marginRight: iconMarginRight ? iconMarginRight : 0,
            width: 16,
            height: 16,
          }}
        ></CheckRoundedIcon>
      )}
      {validate === -1 && (
        <CancelRoundedIcon
          style={{
            color: "#FF647C",
            position: "absolute",
            right: isMulti ? 32 : 12,
            bottom: 12,
            marginRight: iconMarginRight ? iconMarginRight : 0,
            width: 16,
            height: 16,
          }}
        ></CancelRoundedIcon>
      )}
    </InputWrap>
  );
}

export default CustomSelect;
