import get from "lodash/get";
import { createSelector } from "reselect";

export const baseUserSelector = (state) => get(state, "user", {});

export const conciergeSelector = createSelector(baseUserSelector, (user) =>
  get(user, "concierge", {})
);

export const qualificationSelector = createSelector(baseUserSelector, (user) =>
  get(user, "qualification", {})
);

export const standardCategoriesListSelector = createSelector(baseUserSelector, (user) =>
  get(user, "standardCategoriesList", [])
);

export const standardCategoriesSubCategoriesListSelector = createSelector(baseUserSelector, (user) =>
  get(user, "standardCategoriesSubCategoriesList", [])
);

export const unspcFamilyListSelector = createSelector(baseUserSelector, (user) =>
  get(user, "unspcFamilyList", [])
);

export const unspcFamilyClassListSelector = createSelector(baseUserSelector, (user) =>
  get(user, "unspcFamilyClassList", [])
);


export const userSelector = createSelector(baseUserSelector, (user) =>
  get(user, "user", {})
);