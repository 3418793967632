import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 10,
        justifyContent: 'flex-start',
        width: '50%',
        flex: 1,
        paddingLeft: 30,
        paddingRight: 30,
    },
    submittedTo: {
        marginTop: 10,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
    },
    spanText: {
        color: '#888888',
    },
});

const PageOneSubmittedTo = ({ prequalifyData }) => (
    <View style={styles.headerContainer}>
        <Text style={styles.submittedTo}>Submitted to</Text>
        <Text>
            <span style={styles.spanText}>Name: </span>
            {prequalifyData?.data?.projectOwner}
        </Text>
        <Text>
            <span style={styles.spanText}>Website: </span>
        </Text>
        <Text><span style={styles.spanText}>Address: </span>
            {prequalifyData?.data?.projectOwnerAddress}
        </Text>
        <Text>
            <span style={styles.spanText}>Email: </span>
            {prequalifyData?.data?.projectOwnerEmail}
        </Text>
        <Text><span style={styles.spanText}>Phone: </span>
            {prequalifyData?.data?.projectOwnerPhone}
        </Text>
    </View>
);

export default PageOneSubmittedTo;
