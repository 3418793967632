import get from "lodash/get";
import { createSelector } from "reselect";

export const baseBuyersSelector = (state) => get(state, "buyers", {});

export const buyersSelector = createSelector(baseBuyersSelector, (buyers) =>
  get(buyers, "buyers", [])
);

export const contractorsSelector = createSelector(baseBuyersSelector, (buyers) =>
  get(buyers, 'contractors_list', [])
);

export const institutionRfpqSelector = createSelector(baseBuyersSelector, (buyers) =>
  get(buyers, 'institution_rfpq', {})
);

export const institutionRfpqsSelector = createSelector(baseBuyersSelector, (buyers) =>
  get(buyers, 'institution_rfpqs', [])
);

export const requirementsSelector = createSelector(baseBuyersSelector, (buyers) =>
  get(buyers, 'requirements', [])
);

export const rfpqSelector = createSelector(baseBuyersSelector, (buyers) =>
  get(buyers, 'rfpq', {})
);