import get from "lodash/get";

const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  const payload = get(action, 'payload');
  switch (action.type) {
    case 'CLEAR_MESSAGE':
      return {
        ...state,
        [payload]: null
      };
    case 'GET_SYSTEM_MESSAGES':
      return {
        ...state,
        release_notes: payload?.release_notes
      };
    default:
      return state;
  };
};