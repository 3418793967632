import { FunctionalButton } from "components/functionalButton";
import { LetterAvatar } from "components/letterAvatar";
import React, { useEffect, useState } from "react";
import displayDate from "utilities/displayDate";
import { ReactComponent as ViewIcon } from "assets/common/ViewIcon.svg";
import { StyledBox, StyledBoxContent, SmallTitle, DataText } from "../styles";
import { RatingBar } from "./RatingBar";
import {
  RowContent,
  AvatarePicture,
  ColumnContent,
  ColumnContentStatus,
  StyledDivider,
  StyledProvidedContainer,
  StyledContestedContainer,
} from "./styles";
import { ThreeDotButtonDiv } from "../leftComponents/styles";
import { ThreeDotsPopupMenu } from "components/threeDotsPopupMenu";
import ShowDetailsPopup from "pages/referenceModule/showDetailsPopup/index";
import { ContestPost } from "./contestPost";
import { DeletePost } from "./deletePost";
import DateTimePickerField from "components/dateTimePickerField";

export const ReferenceCard = ({ isMobile, data, setFilteredData }) => {
  const [comment, setComment] = useState(data?.institution_response || "");
  const [hasComment, setHasComment] = useState(null);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDeletePostPopup, setOpenDeletePostPopup] = useState(false);

  useEffect(() => {
    setHasComment(data?.institution_response ? true : false);
  }, [data]);

  const handlePost = () => {
    setOpenPopup(true);
  };

  const showDetails = (data) => {
    setShowDetailsPopup(true);
    setDetailsData(data);
  };

  return (
    <StyledBox boxHeight="200px" hasData={true}>
      <StyledBoxContent margin="24px 32px" flexDirection="column">
        <RowContent margin="0px 0 16px" justifyContent="space-between">
          <RowContent width="60%">
            <RatingBar rating={data?.rating} />
          </RowContent>

          <SmallTitle>
            <DateTimePickerField
              showDateField={true}
              withoutMargin={true}
              paddingRight="0px"
              dateValue={
                data?.reference_received_at ? data?.reference_received_at : ""
              }
              readOnly={true}
              fontSize="15px"
              textAlign="right"
              fontColor="#D0C9D6"
              dateWidth="100%"
            />
            {/* {data?.reference_received_at} */}
            {/* {data?.reference_received_at && displayDate({date: data?.reference_received_at})} */}
          </SmallTitle>
        </RowContent>
        <RowContent margin="0px 0 5px" justifyContent="space-between">
          <DataText fontSize="15px" fontWeight="600">
            {data?.project_title}
          </DataText>
          <ViewIcon
            onClick={() => showDetails(data)}
            style={{ cursor: "pointer" }}
          />
        </RowContent>
        <SmallTitle fontSize="14px">{data?.project_location}</SmallTitle>

        <DataText margin="12px 0 0 0">{data?.comment}</DataText>
        <RowContent margin="12px 0 0 0" justifyContent="flex-start">
          {data?.referee_avatar && (
            <AvatarePicture
              src={`data:image/png;base64,${data?.referee_avatar}`}
              isMobile={isMobile}
            />
          )}
          {!data?.referee_avatar && (
            <LetterAvatar
              name={`${data?.referee_first_name}${
                data?.referee_first_name && data?.referee_last_name ? " " : ""
              }${data?.referee_last_name}`}
            />
          )}
          <ColumnContent>
            <DataText fontSize="15px" fontWeight="600">{`${
              data?.referee_first_name
            }${data?.referee_first_name && data?.referee_last_name ? " " : ""}${
              data?.referee_last_name
            }`}</DataText>
            <DataText fontSize="15px">
              {data?.referee_company || "No company information"}
            </DataText>
          </ColumnContent>

          <RowContent justifyContent="flex-end">
            <ColumnContentStatus justifyContent="flex-end">
              {data?.status === "contested" && (
                <StyledContestedContainer>Contested</StyledContestedContainer>
              )}

              {(data?.status === "provided" || data?.status === "updating") && (
                <StyledProvidedContainer>Provided</StyledProvidedContainer>
              )}
            </ColumnContentStatus>
          </RowContent>
        </RowContent>
        <StyledDivider />
        <RowContent margin="8px 0 0 0" justifyContent="flex-start">
          {(data?.status === "provided" || data?.status === "updating") &&
            hasComment === false && (
              <FunctionalButton
                buttonTitle={"Contest"}
                isReversedColor={true}
                height="30px"
                width="199px"
                marginTop="12px"
                marginRight="0px"
                handleButton={() => handlePost()}
                borderColor="#FF0000"
              />
            )}

          <ShowDetailsPopup
            openPopup={showDetailsPopup}
            setOpenPopup={setShowDetailsPopup}
            data={detailsData}
          />
        </RowContent>

        {hasComment && (
          <>
            <RowContent margin="12px 0 0 0" justifyContent="flex-start">
              <ThreeDotButtonDiv>
                <ThreeDotsPopupMenu
                  handleModify={() => setOpenPopup(true)}
                  handleDelete={() => setOpenDeletePostPopup(true)}
                />
              </ThreeDotButtonDiv>
              {data?.company_avatar && (
                <AvatarePicture
                  src={`data:image/png;base64,${data?.company_avatar}`}
                  isMobile={isMobile}
                />
              )}
              {!data?.company_avatar && (
                <LetterAvatar name={data?.company_name} />
              )}
              <ColumnContent>
                <DataText fontSize="15px" fontWeight="600">
                  {data?.company_name}
                </DataText>
              </ColumnContent>
            </RowContent>
            <RowContent margin="8px 0 0 0" justifyContent="flex-start">
              <DataText>{comment}</DataText>
            </RowContent>
          </>
        )}

        <ContestPost
          isMobile={isMobile}
          data={data}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          comment={comment}
          setComment={setComment}
          setHasComment={setHasComment}
          setFilteredData={setFilteredData}
        />

        <DeletePost
          isMobile={isMobile}
          data={data}
          openPopup={openDeletePostPopup}
          setOpenPopup={setOpenDeletePostPopup}
          setComment={setComment}
          setHasComment={setHasComment}
          setFilteredData={setFilteredData}
        />
      </StyledBoxContent>
    </StyledBox>
  );
};
