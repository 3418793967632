import React, { useState, useEffect } from "react";

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper,
  StyledSmallAddText,
  StyledRow
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Input from "../inputField";
import PledgxLogo from "../../assets/common/pledgxLogo";
import { FunctionalButton } from "../functionalButton";
import { addUnderwriter } from "../../data/actions/dataMaintenance";
import { useDispatch } from "react-redux";
import { SuccessPopup } from "../../pages/adminToolsDashboard/SuccessPopup";
import updateObjectByPath from "../../common/updateObjectByPath";
import { getPackages } from "../../api/external_data";
import Select from "../selectField";


export const AddUnderwriterPopup = ({
  setOpenDialog,
  openDialog,
  isMobile,
  label,
  buttonLabel
}) => {

  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [hasOther, setHasOther] = useState(false);


  const handleSave = () => {
    dispatch(
      addUnderwriter(
        data,
        () => {
          setOpenSuccessPopup(true);
          setData({});
        },
        () => { }
      )
    );
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };





  return (
    <Dialog
      open={openDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper elevation={0} isMobile={isMobile}>
        <CloseButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
        <FormTitle style={{ marginTop: 10 }}>
          {label || "Add Underwriter"}
        </FormTitle>
        <InfoArea isMobile={isMobile} marginTop="0px">

          <Input
            id="fullWidthInputWrap"
            label="Underwriter Name"
            type="text"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.corporate_name}
            setValue={(val) => onChangeData("corporate_name", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
          />
          <div className="inputWrap">
            <PackageDropdown1 data={data} onChangeData={onChangeData} />
          </div>

          <Input
            id="inputWrap"
            label="Number of Packages"
            type="number"
            placeholder=""
            labelSize="12px"
            fontSize="18px"
            value={data?.number_of_packages_1}
            setValue={(val) => onChangeData("number_of_packages_1", val)}
            validateFunc={(item) => item.length > 0}
            noCheckIcon={true}
            noCancelIcon={true}
            noErrorBorder={true}
          />



          {!hasOther && (
            <StyledRow>
              <StyledSmallAddText onClick={() => setHasOther(true)}>
                + Add Package
              </StyledSmallAddText>
            </StyledRow>
          )}
          {hasOther && (
            <>
              <div className="inputWrap">
                <PackageDropdown2 data={data} onChangeData={onChangeData} />
              </div>

              <Input
                id="inputWrap"
                label="Number of Packages"
                type="number"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={data?.number_of_packages_2}
                setValue={(val) => onChangeData("number_of_packages_2", val)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noCancelIcon={true}
                noErrorBorder={true}
              />
            </>
          )}
        </InfoArea>

        <FunctionalButton
          width="90%"
          marginTop="16px"
          marginRight="10px"
          handleButton={() => {
            handleSave();
          }}
          buttonTitle={buttonLabel || "ADD UNDERWRITER"}
        />
        <SuccessPopup
          setOpenPopup={setOpenSuccessPopup}
          openPopup={openSuccessPopup}
          isMobile={isMobile}
        />
      </StyledPaper>
    </Dialog>
  );
};



const PackageDropdown1 = ({ data, onChangeData }) => {
  const [packages, setPackages] = useState([]);


  useEffect(() => {
    getPackages()
      .then((res) => {
        setPackages(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);


  return (
    <Select
      style={{ marginRight: "16px" }}
      label="Package Type"
      options={packages.map((item) => ({
        value: item.package_name,
        label: item.package_name,
      }))}
      value={data?.package_name_1}
      setValue={(val) => onChangeData("package_name_1", val)}
      validateFunc={(item) => Boolean(item)}
      noCheckIcon={true}
      showDropdownIndicator={true}
    />
  );
};



const PackageDropdown2 = ({ data, onChangeData }) => {
  const [packages, setPackages] = useState([]);


  useEffect(() => {
    getPackages()
      .then((res) => {
        setPackages(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);


  return (
    <Select
      style={{ marginRight: "16px" }}
      label="Package Type"
      options={packages.map((item) => ({
        value: item.package_name,
        label: item.package_name,
      }))}
      value={data?.package_name_2}
      setValue={(val) => onChangeData("package_name_2", val)}
      validateFunc={(item) => Boolean(item)}
      noCheckIcon={true}
      showDropdownIndicator={true}
    />
  );
};



