import React, { useEffect, useState } from "react";

import { 
  StyledBox,
  SmallText,
  TinyText,
  MediumTinyText
} from '../styles';
import { CardDiv } from './styles';

import { 
  PersonnelContent, 
  TitleDiv, 
  StyledDivider,
  ProgressInCard 
} from './styles'
import { LetterAvatar } from "../../../components/letterAvatar";
import { ResumeButton } from "../../../components/resumeButton";
import { truncateString } from "../../../common/truncateString";
import { ThreeDotsPopupMenu } from '../../../components/threeDotsPopupMenu';
import CompletedProgress from "../../../assets/dashboardIcons/completedProgress";
import TinyThreeDots from '../../../assets/dashboardIcons/tinyThreeDots';


export const ContractorRecentCard = ({
  isMobile,
  item, 
  key,
  editFunc,
  deleteFunc,
}) => {
  return (
    <CardDiv isMobile={isMobile} width={isMobile ? "224px" : "25%"}>
      <StyledBox boxHeight={isMobile ? "152px " : "186px"} margin="0 0 20px 0">
        <PersonnelContent height="50px" margin={isMobile ? "28px 24px 8px" : "28px 28px 14px"}>
            <MediumTinyText fontSize="15px">{isMobile ? truncateString(item.name, 35) : truncateString(item.name, 65)}</MediumTinyText>     
        </PersonnelContent>
        <StyledDivider/>
        <PersonnelContent height={isMobile ? "30px" : "50px"} direction="row" margin="28px 16px 22px 28px" justifyContent="space-between">
          <PersonnelContent direction="column" margin="0px 0px 8px">
            <TinyText style={{height: "20px"}}>{item.email}</TinyText>
            <MediumTinyText fontSize="14px">{isMobile ? truncateString(item.location, 35) : truncateString(item.location, 65)}</MediumTinyText>
          </PersonnelContent>
          <ThreeDotsPopupMenu deleteText='Remove Contractor' item={item} noEditButton={true} handleModify={editFunc} handleDelete={ () => deleteFunc(item.contractor_id) } icon={isMobile ? <TinyThreeDots/> : ""} />
        </PersonnelContent>
      </StyledBox>
    </CardDiv>
  );
}
