import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  getNews,
  getSavedNews,
  saveArticle,
  deleteArticle,
} from "../../data/actions/news";
import { getUserCategories } from "../../data/actions/overview";
import { newsSelector, newsSavedSelector } from "../../data/selectors/news";
import { categoriesSelector } from "../../data/selectors/overview";
import Grid from "@material-ui/core/Grid";
import DashHeader from "../../components/dashboardHeader";
import Sidebar from "../../components/sidebar";
import { Container } from "../../components/common";
import Controls from "../../components/controlPanel";

import NewsCard from "../../components/newsCard";

import { Content, ChangeMode, ChangeModeItem } from "./styles";

function GridNews({ news }) {
  const dispatch = useDispatch();

  return (
    <Grid
      container
      spacing={2}
      style={{ flexWrap: "nowrap", justifyContent: "center" }}
    >
      <Grid item>
        {news.slice(0, Math.ceil(news.length * 0.25)).map((item, i) => (
          <NewsCard
            {...item}
            reverse={false}
            key={i}
            onSave={() => dispatch(saveArticle(item))}
            onDelete={() => dispatch(deleteArticle(item))}
          ></NewsCard>
        ))}
      </Grid>
      <Grid item>
        {news
          .slice(Math.ceil(news.length * 0.25), Math.ceil(news.length * 0.5))
          .map((item, i) => (
            <NewsCard
              {...item}
              reverse={true}
              key={i}
              onSave={() => dispatch(saveArticle(item))}
              onDelete={() => dispatch(deleteArticle(item))}
            ></NewsCard>
          ))}
      </Grid>
      <Grid item>
        {news
          .slice(Math.ceil(news.length * 0.5), news.length)
          .map((item, i) => (
            <NewsCard
              {...item}
              small
              key={i}
              onSave={() => dispatch(saveArticle(item))}
              onDelete={() => dispatch(deleteArticle(item))}
            ></NewsCard>
          ))}
      </Grid>
    </Grid>
  );
}

function News(props) {
  const dispatch = useDispatch();
  const news = useSelector(newsSelector);
  const savedNews = useSelector(newsSavedSelector);
  const categories = useSelector(categoriesSelector);

  const [mode, setMode] = useState("newsfeed");
  const [chosenCategories, setChosenCategories] = useState([]);

  useEffect(() => {
    dispatch(getUserCategories());
    dispatch(getNews());
    dispatch(getSavedNews());
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getNews(chosenCategories.join()));
  // }, [dispatch, chosenCategories]);

  return (
    <>
      <DashHeader></DashHeader>
      <Sidebar></Sidebar>
      <Container>
        <Controls />
        <Content>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 20,
            }}
          >
            {/* <Select
              isMulti
              openMenuOnFocus
              styles={{
                container: (styles) => ({
                  ...styles,
                  // flexGrow: 1,
                  minWidth: 400,
                }),
                indicatorSeparator: (styles) => ({
                  display: "none",
                }),
                control: (styles) => ({
                  ...styles,
                  boxShadow: "none",
                  height: 42,
                  marginRight: 20,
                  background: "#E9E9E9",
                  border: "none !important",
                  borderRadius: "6px",
                  fontSize: "12px",
                  color: "#3F3356",
                  outline: "none",
                }),
                menu: (styles) => ({
                  ...styles,
                  fontSize: "12px",
                  color: "#444444",
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    backgroundColor: isSelected ? "#e9e9e9" : "white",
                    color: "#444444",
                    cursor: "pointer",
                  };
                },
              }}
              className="basic-single"
              classNamePrefix="select"
              name="sort-by"
              options={categories.map((cat) => {
                return { value: cat, label: cat };
              })}
              onChange={(e) => setChosenCategories(e.map((item) => item.value))}
            /> */}
            <ChangeMode>
              <ChangeModeItem
                active={mode === "newsfeed"}
                onClick={() => setMode("newsfeed")}
              >
                Newsfeed
              </ChangeModeItem>
              <ChangeModeItem
                active={mode === "saved"}
                onClick={() => setMode("saved")}
              >
                Saved News
              </ChangeModeItem>
            </ChangeMode>
          </div>
          {mode === "newsfeed" && <GridNews news={news}></GridNews>}
          {mode === "saved" &&
            (savedNews.length ? (
              <GridNews news={savedNews}></GridNews>
            ) : (
              <h3>Your saved list is empty.</h3>
            ))}
        </Content>
      </Container>
    </>
  );
}

export default News;
