import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { qualificationSelector } from "../../data/selectors/user";
import updateObjectByPath from "../../common/updateObjectByPath";
import { getPreQualificationInfo, getSelectedCCDCInfo } from "../../data/actions/user";

import { StickyHeader, Content, StyledContainer } from "./styles";
import { NavBar } from './navBar/index';

import { HelpForm } from './helpForm/index';
import { ProjectInfoForm } from './projectInfoForm/index';
import { LegalStructureForm } from './legalStructureForm/index';
import { FinancialForm } from './financialForm/index';
import { HeathAndSafetyForm } from './healthAndSafetyForm/index';
import { PrincipalProjectsForm } from './principalProjectsForm/index';
import { KeyPersonnelForm } from './keyPersonnelsForm';
import { CalendarYearsForm } from './calendarYearsForm/index';
import { AdditionalDocuments } from './additionalDocuments/index';
import { ReviewForm } from './reviewForm/index';

function SwitchComponents({ active, children }) {
  // Switch all children and return the "active" one
  return children.filter((child) => child.props.step === active);
};

function PreQualifyForm(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const ccdcId =  location.state?.ccdcId || '';
  const [action, setAction] =  useState(location.state?.action || "new");

  const qualification = useSelector(qualificationSelector);
  const [qualificationData, setQualificationData] = useState({ ...qualification });

  const [activeStep, setActiveStep] = React.useState(action == "edit" ? 0 : -1);
  const [downloading, setDownloading] = React.useState(false);
  const [listPanel, setListPanel] = React.useState(1);

  const [sameAI, setSameAI] = useState(false);
  const [sameCI, setSameCI] = useState(false);
  const [isLastPanel, setIsLastPanel] = useState(false)
  const [previousStepPanel, setPreviousStepPanel] = useState('');
  const [saveCCDC, setSaveCCDC] = useState(false);

  useEffect(() => {
    if (action == "edit") {
      dispatch(getSelectedCCDCInfo(ccdcId, action));
    } else {
      dispatch(getPreQualificationInfo());
    }
  }, [dispatch]);

  useEffect(() => {
    setQualificationData({ ...qualification });
  }, [qualification]);

  const onChangeQualificationData = (attribute, val) => {
    const updatedQual = { ...qualificationData };
    updateObjectByPath(updatedQual, attribute, val);
    setQualificationData({ ...updatedQual });
  };

  return (
    <StyledContainer>
      <StickyHeader>
        <NavBar
          downloading={downloading}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          listPanel={listPanel}
          setListPanel={setListPanel}
          isLastPanel={isLastPanel}
          setIsLastPanel={setIsLastPanel}
          previousStepPanel={previousStepPanel}
          saveCCDC={saveCCDC}
          setSaveCCDC={setSaveCCDC}
          qualificationData={qualificationData}
          action={action}
          setAction={setAction}
        />
      </StickyHeader>
      <Content>
        <SwitchComponents active={activeStep}>
          <HelpForm
            step={-1}
            setActiveStep={setActiveStep}
            setDownloading={setDownloading}
            listPanel={listPanel}
            setListPanel={setListPanel}
            setIsLastPanel={setIsLastPanel}
            qualificationData={qualificationData}
            setQualificationData={onChangeQualificationData}
            setAction={setAction}
          />
          <ProjectInfoForm
            step={0}
            setActiveStep={setActiveStep}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
            qualificationData={qualificationData}
            setQualificationData={onChangeQualificationData}
          />
          <LegalStructureForm
            step={1}
            qualificationData={qualificationData}
            setQualificationData={onChangeQualificationData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
          />
          <FinancialForm
            step={2}
            setActiveStep={setActiveStep}
            qualificationData={qualificationData}
            setQualificationData={onChangeQualificationData}
            sameAI={sameAI}
            setSameAI={setSameAI}
            sameCI={sameCI}
            setSameCI={setSameCI}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
          />
          <HeathAndSafetyForm
            step={3}
            setActiveStep={setActiveStep}
            qualificationData={qualificationData}
            setQualificationData={onChangeQualificationData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
          />
          <PrincipalProjectsForm
            step={4}
            setActiveStep={setActiveStep}
            qualificationData={qualificationData}
            setQualificationData={onChangeQualificationData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
          />
          <KeyPersonnelForm
            step={5}
            setActiveStep={setActiveStep}
            listPanel={listPanel}
            qualificationData={qualificationData}
            setQualificationData={onChangeQualificationData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
          />
          <CalendarYearsForm
            step={6}
            setActiveStep={setActiveStep}
            qualificationData={qualificationData}
            setQualificationData={onChangeQualificationData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}

          />
          <AdditionalDocuments
            step={7}
            qualificationData={qualificationData}
            setQualificationData={onChangeQualificationData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
            setSaveCCDC={setSaveCCDC}
          />
          <ReviewForm
            step={8}
            qualificationData={qualificationData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
          />
        </SwitchComponents>
      </Content>
    </StyledContainer>
  );
};

export default PreQualifyForm;
