import React from "react";
import { FunctionalButton } from '../../../components/functionalButton';

import {
  ImagePopup,
  LargeTextSuccess,
  SmallText,
  ButtonArea,
  PopupContainer,
} from '../styles';
import { Popup } from '../../../components/popup';
import Success from "assets/dashboardIcons/Success";
import { CloseButton } from "components/closeButton";

export const SuccessPopup = ({
  openPopup,
  setOpenPopup,
  buttonText='DONE',
  descriptionText='The User information has been saved.',
  titleText='Success!',
  isMobile
}) => {

  const handleDone = () => {
    setOpenPopup(false);
  };

  return (
    <Popup
        open={openPopup}
        setOpen={setOpenPopup}
        width={isMobile ? "100%" : "550px"}
        height={isMobile ? "390px" : "480px"}
        dialogContent={(
          <>
            <PopupContainer>
              <CloseButton handleClose={handleDone} />
              <ImagePopup>
                <Success isMobile={isMobile} />
              </ImagePopup>
              <LargeTextSuccess isMobile={isMobile}>{ titleText }</LargeTextSuccess>
              <SmallText>
                { descriptionText }
              </SmallText>
            </PopupContainer>
            <ButtonArea isMobile={isMobile}>
              <FunctionalButton
                buttonTitle={ buttonText }
                width="60%"
                height={isMobile ? "48px" : "53px"}
                marginRight="0px"
                handleButton={handleDone}
              />
            </ButtonArea>
        </>
        )}
      />

  )
}
