import React, { useEffect, useState } from 'react';
import {
  StyledCardTypography,
  StyledCard,
  StyledCardContent,
  StyledCardActionArea,
 } from './styles';
import { RadioButton } from '../../../../components/radioButton';


export const CheckCard = ({text, pickedData, setPickedData }) => {
  const [isChecked, setIsChecked] = useState(pickedData.includes(text)? true : false);

  const clickCard = () => {
    setIsChecked(!isChecked);
  }

  useEffect(() => {
    if(isChecked && !pickedData.includes(text)) {
      pickedData.push(text);
    } else if(!isChecked && pickedData.includes(text)) {
      pickedData = pickedData.filter(item => item !== text);
    }
    // if(pickedData.length > 0) {
    //   setHasData(true);
    // } else {
    //   setHasData(false);
    // }
    setPickedData(pickedData);
  }, [isChecked]);

  return (
    <StyledCard>
      <StyledCardActionArea onClick={clickCard}>
        <StyledCardContent>
          <RadioButton isChecked={isChecked} />
          <StyledCardTypography>{text}</StyledCardTypography>
        </StyledCardContent>
      </StyledCardActionArea>
    </StyledCard>
  )
}
