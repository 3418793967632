import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '../../../../constants';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';


export const StyledCardTypography = styled(Typography)`
  && {
    font-size: 18px;
    font-family: ${TYPOGRAPHY.primaryFont};
    font-weight: 500;
    margin-left: 8px;
  }
`;

export const StyledCard = styled(Card)`
  && {
      width: 100%;
      height: 64px;
      box-shadow: none;
      background: ${COLORS.darkGrey3};
  }
`;

export const StyledCardContent = styled(CardContent)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;
  }
`;

export const StyledCardActionArea = styled(CardActionArea)`
  && {
    height: 64px;
  }
`;

export const StyledCardContainer = styled.div`
  margin-top: 16px;
`;
