import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import HeaderImageLogo from "pages/underwriters/components/downloadCSR/images/headerImage.png"
import LogoImage from "pages/underwriters/components/downloadCSR/images/logo.png"

const styles = StyleSheet.create({
    view: {
        width: 612,
        height: 121,
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        position: "fixed",
    },
    companyName: {
        marginTop: 20,
        marginLeft: 30,
        fontSize: 16,
        position: 'absolute',
    },
    spanText: {
        marginLeft: 30,
        marginTop: 45,
        position: 'absolute',
        fontSize: 12,
    },
});

const PdfHeaderWithLogo = ({ csrData }) => (
    <View style={styles.view}>
        <Image src={HeaderImageLogo} />
        <Text style={styles.companyName}>
            <span>{csrData?.data?.company_name}</span>
        </Text>
        <Text style={styles.spanText}>
            <span>Contract Status Report </span>
        </Text>
        {csrData?.data?.companyLogo && (
            <Image src={csrData?.data?.companyLogo} style={{ border: 0, borderRadius: 48, marginTop: 35, height: 84, position: 'absolute', left: 467, width: 84, marginRight: 61 }} />
        )}
    </View>
);

export default PdfHeaderWithLogo;
