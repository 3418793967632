import React from "react";
import { Route, Switch } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';

//import MobileContainer from "./containers/MobileContainer"
import WebContainer from "./containers/WebContainer";

function App() {
  return (
    <Switch>
      <Route path='/' component={ WebContainer } />
      { /* <Route path='/mobile' component={ MobileContainer } /> */ }
    </Switch>
  );
}

export default App;
