
import React from "react";
import { numberWithCommas, removeComma } from "common/numberFormat.js";

const Recommendations = ({ data }) => {

  return (
    <div style={{ backgroundColor: '#FFFFFF' }}>
      <div style={{ backgroundColor: '#FFFFFF', padding: '5px' }}>
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <React.Fragment key={index}>
              {item.Recommendation1 && (
                <p> 1. {item.Recommendation1}</p>
              )}
              {item.Recommendation2 && (
                <p> 2. {item.Recommendation2}</p>
              )}
              {item.Recommendation3 && (
                <p> 3. {item.Recommendation3}</p>
              )}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default Recommendations;
