import React, { useState, useEffect } from "react";
import InputMaterial from "@material-ui/core/Input";
import dragfield from "../../assets/preQualifyIcons/temp_card.svg";

export const UploadFile = () => {
  const [file, setFile] = React.useState(null);
  return (
    <label>
      <InputMaterial
        type="file"
        style={{ display: "none" }}
        inputProps={{ accept: ".pdf,.doc,.docx" }}
        onClick={(e) => { e.target.value = null }}
        onChange={(e) => {
          if (
            e.target.files[0].type === "application/pdf" ||
            e.target.files[0].type === "application/msword" ||
            e.target.files[0].type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            setFile(e.target.files[0]);
          }
        }}
      />
      <img
        src={dragfield}
        style={{
          width: 367,
          height: 186,
          cursor: "pointer",
        }}
      />
        </label>
  )
}