import React, { useState, useEffect } from "react";
import { filterPersonnels } from "api/prequalify";
import IncrementalSearchPickList from "components/incrementalSearchPickList";
import { StyledImgIcon } from "./styles";
import { StyledTableCell } from "components/filesListTable/styles";
import PlusSignIcon from "assets/buyers/plusSignIcon";
import { checkPersonnelLimit } from "api/dashboard";
import { AlertPopup } from "components/alertPopup/alertPopup";
import { useSelector } from "react-redux";
import { contactSelector } from "../../../../../data/selectors/contact";
import { institutionProfileInfoSelector } from "data/selectors/serviceProvider";

export const SelectingProjects = ({
  projects,
  setProjects,
  setOpenPopup,
  setPickedArray,
  pickedArray,
  rerender,
  setRerender,
  paddingRight,
  height,
  institution_id,
  currentDashboardUrl,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [personnelList, setPersonnelList] = useState("");
  const [selected, setSelected] = useState(false);
  const [openExceedPopup, setOpenExceedPopup] = useState(false);
  const [filterChange, setFilterChange] = useState(true);
  let contactData = useSelector(contactSelector);
  const institutionContactData = useSelector(institutionProfileInfoSelector);

  if (currentDashboardUrl === "/service-provider-dashboard") {
    contactData = institutionContactData;
  }
  const personnelLimit = contactData?.user_access_control?.number_of_personnel;
  const projectsLimit = contactData?.user_access_control?.number_of_projects;

  // const headers = [
  //   { title: '' },
  //   { title: 'Project #' },
  //   { title: 'Project Title' },
  //   { title: 'Project Location' },
  // ];

  const handleProjectSelection = (bodyData) => {
    // if (projects && projects.length >= projectsLimit) {
    //   setOpenExceedPopup(true);
    // } else {
    setRerender(true);
    setPickedArray((pickedArray) => [...pickedArray, bodyData.id]);
    const filteredTemp = [...filteredData];
    const projectsTemp = [...projects];
    projectsTemp.unshift({
      id: bodyData.id,
      firstName: bodyData.firstName,
      lastName: bodyData.lastName,
      position: bodyData.position,
      email: bodyData.email,
      resume: bodyData.resume,
      type: ["key"],
    });

    setProjects(projectsTemp);
    filteredTemp.splice(bodyData.index, 1);
    setFilteredData(filteredTemp);
    setRerender(false);
    // }
  };

  useEffect(() => {
    handleFilterPersonnel();
  }, []);

  const handleFilterPersonnel = async () => {
    let data = {};

    if (
      currentDashboardUrl === "/service-provider-dashboard" &&
      institution_id
    ) {
      data = { institution_id: institution_id };
    }

    await filterPersonnels("", data)
      .then((data) => {
        setPersonnelList(data);
        setFilteredData(data);
      })
      .catch((err) => {});
  };

  const handleAdd = () => {
    if (personnelList && personnelList.length >= personnelLimit) {
      setOpenExceedPopup(true);
    } else {
      setOpenPopup(true);
    }
    // checkPersonnelLimit(() => setOpenPopup(true), () => setOpenExceedPopup(true))
  };

  // useEffect(() => {
  //   if(filterChange) {
  //     setFilterChange(false);

  //     for (var i = 0; i < filteredData.length; i++) {
  //       let id = filteredData[i].id;
  //       let filteredTemp = [...filteredData];
  //       if(pickedArray.includes(id)) {
  //         console.log(id);
  //         filteredTemp.splice(i, 1);
  //         setFilteredData(filteredTemp);
  //       }
  //     }
  //   } else {
  //     setFilterChange(true);
  //   }

  // },[filteredData]);

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCell
          component={"tr"}
          onClick={() => handleProjectSelection(bodyData)}
        >
          <StyledImgIcon src={PlusSignIcon} />
        </StyledTableCell>
        <StyledTableCell
          component={"tr"}
          onClick={() => handleProjectSelection(bodyData)}
        >
          {bodyData.id}
        </StyledTableCell>
        <StyledTableCell
          component={"tr"}
          onClick={() => handleProjectSelection(bodyData)}
        >
          {bodyData.firstName} {bodyData.lastName}
        </StyledTableCell>
        <StyledTableCell
          component={"tr"}
          onClick={() => handleProjectSelection(bodyData)}
        >
          {bodyData.position}
        </StyledTableCell>
      </>
    );
  };

  return (
    <>
      <IncrementalSearchPickList
        add={handleAdd}
        body={body}
        conciseLength={3.5}
        // filterApi={filterPersonnels}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        addButtonTitle="Add Personnel"
        handleSelected={() => setSelected(true)}
        placeholder={"ID, Name, Position..."}
        marginTop={"24px"}
        hover={true}
        setRerender={setRerender}
        rerender={rerender}
        width="100%"
        pickedArray={pickedArray}
        paddingRight={paddingRight}
        height={height}
      />
      <AlertPopup
        openPopup={openExceedPopup}
        setOpenPopup={setOpenExceedPopup}
        headerText="You have exceeded the personnel limit."
        subHeaderText={`You can add a maximum of ${personnelLimit} personnel.`}
        showButton={true}
      />
    </>
  );
};
