import React from "react";
import agent from "./agentIcon.svg";
import styled from "styled-components";

const AgentIcon = ({ width, height, isMobile }) => {
  return (
    <StyledImg
      width={width || "400"}
      height={height || "250"}
      src={agent}
      isMobile={isMobile}
    />
  );
};

export default AgentIcon;

const StyledImg = styled.img`
  ${(props) => (props.isMobile ? "margin-bottom: 8px; margin-top: 12px;" : "")}
`;
