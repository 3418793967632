import React, { useState, useEffect } from "react";

import { ResultPanel } from './resultPanel';

export const InteractiveTable = ({
  data,
  id,
  inheritState,
  internalSearch,
  options={},
  readOnly,
  selectedData,
  setData,
  setPopupData,
  setSelectedData,
  submitItem,
  onInteraction
}) => {
  const [items, setItems] = useState();
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    if (setData && !data) {
      setData(items);
    };
  }, [items]);

  return (
    <ResultPanel
      id={ id }
      inheritState={ inheritState }
      internalSearch={ internalSearch }
      items={ data || items || [] }
      openPopup={ openPopup }
      options={ options }
      readOnly={ readOnly }
      selectedData={ selectedData || [] }
      setItems={ (setData && data) ? setData : setItems }
      setOpenPopup={ setOpenPopup }
      setPopupData={ setPopupData }
      setSelectedData={ setSelectedData }
      submitItem={ submitItem }
      onInteraction={onInteraction}
    />
  );
};

export default InteractiveTable;
