import React from "react";
import { COLORS, FONT_SIZES } from "../../constants";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import Spinner from "../spinner";
import classes from "./ReleaseNotes.module.css";

export const ReleaseNote = ({ index, releaseNote }) => {
  return (
    <div
      className={classes["release-note-container"]}
      style={{ marginTop: index > 0 ? "50px" : "0px" }}
    >
      {releaseNote.title && (
        <div
          className={classes["release-note-title"]}
          style={{ fontSize: FONT_SIZES.body_1 }}
        >
          {releaseNote.title}
        </div>
      )}
      {releaseNote.date && (
        <div
          className={classes["release-note-date"]}
          style={{ fontSize: FONT_SIZES.body_3 }}
        >
          {releaseNote.date}
        </div>
      )}
      {releaseNote.content && (
        <div className={classes["release-note-content-container"]}>
          {releaseNote.content.map((releaseNoteContent, index) => (
            <>
              <div className={classes["release-note-content-title"]}>
                {releaseNoteContent.title}
              </div>
              <div className={classes["release-note-content-description"]}>
                {releaseNoteContent?.description}
              </div>
              {releaseNoteContent.content ? (
                <ul className={classes["release-note-inner-content-container"]}>
                  {releaseNoteContent.content.map(
                    (releaseNoteInnerContent, index) => (
                      <li>
                        {releaseNoteInnerContent.title && (
                          <span
                            className={
                              classes["release-note-inner-content-title"]
                            }
                          >
                            {releaseNoteInnerContent.title}
                          </span>
                        )}
                        {releaseNoteInnerContent.title &&
                          releaseNoteInnerContent.description && <>&nbsp;</>}
                        {releaseNoteInnerContent.description && (
                          <span
                            className={
                              classes["release-note-inner-content-text"]
                            }
                          >
                            {releaseNoteInnerContent.description}
                          </span>
                        )}
                        {releaseNoteInnerContent.tag && (
                          <>
                            &nbsp;
                            <span
                              className={classes.new}
                              style={{
                                backgroundColor: COLORS.primaryOrange,
                                fontSize: FONT_SIZES.body_6,
                              }}
                            >
                              {releaseNoteInnerContent.tag}
                            </span>
                          </>
                        )}
                      </li>
                    )
                  )}
                </ul>
              ) : (
                <ul
                  className={classes["release-note-inner-content-container"]}
                ></ul>
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
};
