import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import IconButton from "@material-ui/core/IconButton";
import BookmarksOutlinedIcon from "@material-ui/icons/BookmarksOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import BookmarksIcon from "@material-ui/icons/Bookmarks";

import {
  BuyerBody,
  BuyerRow,
  BuyerColumn,
  BuyerLogo,
  BuyerName,
} from "./styles";

import construction from "../../assets/buyers/constructionLogo.svg";

const CustomButton = withStyles((theme) => ({
  root: {
    width: 108,
    height: 32,
    backgroundColor: "#FF6D1D",
    border: "1.5px solid #FF6D1D",
    boxSizing: "border-box",
    borderRadius: "6px",
    color: "white",

    fontSize: "9px",
    fontWeight: 600,
    transition: "all 0.5s ease",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "white",
      color: "#FF6D1D",
    },
  },
  disabled: {
    backgroundColor: "#FFF3EC",
    color: "#FFDECC !important",
    border: "1.5px solid #FFF3EC",
  },
}))(Button);

function Buyer({ buyer, style }) {
  return (
    <BuyerBody style={style}>
      <BuyerRow style={{ alignItems: "center" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <BuyerLogo src={construction}></BuyerLogo>
        </div>
        <BuyerColumn style={{ flexGrow: 1 }}>
          <BuyerName>
            <span>Buyer Name</span>
          </BuyerName>
        </BuyerColumn>
        <BuyerColumn style={{ marginRight: 15 }}>
          <CustomButton>Select</CustomButton>
        </BuyerColumn>
        <IconButton style={{ maxWidth: 50, marginRight: 25 }}>
          <MoreVertOutlinedIcon />
        </IconButton>
      </BuyerRow>

      <BuyerRow style={{ marginTop: 10 }}></BuyerRow>
    </BuyerBody>
  );
}

export default Buyer;
