import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PageSixDocumentsTableRow from './pageSixDocumentsTableRow';
import PageSixDocumentsTableHeader from './pageSixDocumentsTableHeader';
import PageSixDocumentsTableRowForResume from './pageSixDocumentsTableRowForResume'

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 0,
        justifyContent: 'flex-start',
        width: '80%',
        paddingLeft: 30,
        paddingRight: 30,
    },
    viewContainer: {
        borderRadius: 6,
        backgroundColor: '#F8F8F8',
        width: 537,
        paddingTop: 2,
        paddingBottom: 3,
    },
    documents: {
        marginTop: -10,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
    },
    documentsWithoutLogo: {
        marginTop: 23,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
        position: 'relative',
    },
});

const PageSixDocuments = ({ prequalifyData, companyLogo }) => (
    <View style={styles.headerContainer}>
        {companyLogo !== "" ? <Text style={styles.documents}>Attached Documents</Text> : <Text style={styles.documentsWithoutLogo}>Attached Documents</Text>}
        <View style={styles.viewContainer}>
            <PageSixDocumentsTableHeader />
            <PageSixDocumentsTableRow items={prequalifyData?.data?.additionalDocument} />

            {prequalifyData?.data?.keyPersonnels?.selectedData?.map(PersonnelResume => (
                (PersonnelResume?.resume !== null) || (PersonnelResume?.resume !== "") && (
                    <PageSixDocumentsTableRowForResume item={PersonnelResume?.resume} />
                )
            ))}
        </View>

    </View>
);

export default PageSixDocuments;
