import React from "react";
import { FunctionalButton } from 'components/functionalButton';
import { Popup } from 'components/popup';
import { CloseButton } from "components/closeButton";
import PledgxLogo from "assets/common/pledgxLogo";

import {
  TitleDiv,
  TitleText,
  PopupContainer,
  ButtonDiv
} from "pages/referenceModule/rightComponents/styles.js";


export const DeleteRow = ({
  openPopup,
  setOpenPopup,
  isMobile,
  data,
  setData,
  selectedRow
}) => {



  const handleDone = () => {
    setOpenPopup(false);
  };

  const deleteTableRow = () => {
    setData(data.filter((_, index) => index !== selectedRow));
    setOpenPopup(false);
  }


  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width={isMobile ? "100%" : "420px"}
      height={isMobile ? "100vh" : "340px"}
      dialogContent={(
        <>
          <CloseButton handleClose={handleDone} />
          <PopupContainer>
            <TitleDiv>
              <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
            </TitleDiv>

            <TitleText fontSize="18px" fontWeight="600" color="black" margin="30px 0px 6px 20px">Would you like to delete this row?</TitleText>
            <br />
            <TitleText fontSize="14px" fontWeight="400">Class: <b>{data[selectedRow]['Class']}</b>, Item Description: <b>{data[selectedRow]['Item Description']}</b></TitleText>
            <ButtonDiv isMobile={isMobile}>
              <FunctionalButton
                buttonTitle="GO BACK"
                handleButton={handleDone}
                isReversedColor={true}
                height="48px"
                width="150px"
                marginTop="0px"
                marginRight="0px"
              />
              <FunctionalButton
                buttonTitle={"CONFIRM"}
                height="48px"
                width="150px"
                marginTop="0px"
                marginRight="0px"
                handleButton={() => deleteTableRow()}
              />
            </ButtonDiv>
          </PopupContainer>

        </>
      )}
    />

  )
}
