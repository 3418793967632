import React from 'react';

const Additional = ({ active, completed }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1545:3156)">
        <g filter="url(#filter0_d_1545:3156)">
          <circle cx="14" cy="14" r="14" fill="white"/>
          <circle cx="14" cy="14" r="13.5" fill={active || completed ? "#FF6D1D" : "white"} stroke="#ECEBED"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7312 9.20625L19.7937 11.2687C20.0688 11.5438 20.0688 11.9562 19.7937 12.2313L18.5562 13.4688L15.5312 10.4438L16.7688 9.20625C17.0437 8.93125 17.4563 8.93125 17.7312 9.20625ZM9.20625 16.7688L14.5687 11.4062L17.5938 14.4313L12.2313 19.7937C12.0938 19.9313 11.9562 20 11.75 20H9.6875C9.275 20 9 19.725 9 19.3125V17.25C9 17.0437 9.06875 16.9062 9.20625 16.7688Z" fill={active || completed ? "white" : "#D0C9D6"}/>
      </g>
      <defs>
        <filter id="filter0_d_1545:3156" x="-64" y="-57" width="156" height="156" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="7"/>
          <feGaussianBlur stdDeviation="32"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1545:3156"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1545:3156" result="shape"/>
        </filter>
        <clipPath id="clip0_1545:3156">
          <rect width="28" height="28" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default Additional;
