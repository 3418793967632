import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

export const StyledPdfViewerPaper = styled(Paper)`
  &&&& {
    background-color: #565656;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 637px;
    min-height: 750px;
    color: #eeeeee;

    border: 2px solid #565656;
    border-radius: 8px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    .react-pdf__Page__canvas {
      max-width: 550px;
      max-height: 750px;
    }
  }
`;


export const StyledPageScrollPaper = styled(Paper)`
  width: 100%;
  height: 32px;
  margin-bottom: 16px;
  min-width: 637px;
  text-align: center;

  && {
    background-color: #F5F5F5;
  }
`;


export const StyledPageInfo = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #444444;
  display: inline-block;
  margin: 9px 0;
`;


export const StyledIncrementUpButton = styled(Button)`
  height: 32px;

  && {
    visibility: ${props => ((props.pageNumber === props.numPages) ? 'hidden' : 'visible')};
    float: right;
    color: #1A051D;
    font-size: 20px;
    font-weight: 600;
  }
`;


export const StyledIncrementDownButton = styled(StyledIncrementUpButton)`
  && {
    visibility: ${props => ((props.pageNumber === 1) ? 'hidden' : 'visible')};
    float: left;
  }
`;
