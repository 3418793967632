import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '../../constants';

export const StyledCategoriesArea = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => !props.isMobile && props.width ? props.width : "100%"};
`;

export const InfoArea = styled.div`
  ${props => props.marginLeft && !props.isMobile ? `margin-left: ${props.marginLeft};` : ''}
  margin-top: ${(props) => props.marginTop || "24px"};
  display: flex;
  flex-direction: ${props => props.isColumn || props.isMobile ? "column" : "row"};
  align-items: center;
  flex-wrap: wrap;
  ${props => props.noJustifyContent ? "" : "justify-content: center;"}
  ${props => props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}

  .last {
    padding-right: 0px !important;
  }
  .inputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '50%'};
  }

  .fullWidthInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: 100%;
  }

  .oneFifthInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '20%'};
  }

  .thirtyInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '30%'};
  }

  .oneThirdInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '33.33%'};
  }

  .twoThirdInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '66.66%'};
  }

  .oneFifthMobile {
    padding-right: 16px;
    width: ${props => props.isMobile ? '50%' : '40%'};
  }

  .thirtyMobile {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '50%' : '60%'};
  }
`;

export const StyleList = styled.div`
  margin-top: ${props => props.marginTop ? props.marginTop : '24px'};
  // ${props => props.isMobile ? "" : "margin-right: 16px;"}
`;

export const PlusSignArea = styled.div`
  width: 100%;
  margin-top: ${props => props.usePlusIcon ? '20px' : "4px"};
  display: flex;
  flex-direction: row;
  
`;

export const StyledAddText = styled.div`
    color: ${COLORS.primaryOrange};
    cursor: pointer;
    font-family: ${TYPOGRAPHY.primaryFont} ;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    margin-top: 18px;
    margin-right: 16px;
    ${props => !props.enableAdd ? `color: ${COLORS.lightGrey2}` : ''}
`;

export const SmallAddText = styled(StyledAddText)`
  &&{
    font-size: 14px;
  }
`;  