import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 0,
        justifyContent: 'flex-start',
        width: '100%',
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30,
    },
    projectDetails: {
        marginTop: -40,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
    },
    projectDetailsWithoutLogo: {
        marginTop: -7,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
        position:'relative',
    },
    spanText: {
        color: '#888888',
    },
});

const PageOneProjectDetails = ({ prequalifyData, companyLogo }) => (
    <View style={styles.headerContainer}>
        {companyLogo !== ""  ? <Text style={styles.projectDetails}>Project Details</Text> : <Text style={styles.projectDetailsWithoutLogo}>Project Details</Text> }
        <Text>
            <span style={styles.spanText}>Project Title: </span>
            {prequalifyData?.data?.projectTitle}
        </Text>
        <Text>
            <span style={styles.spanText}>Project Number: </span>
            {prequalifyData?.data?.projectNumber}
        </Text>
        <Text><span style={styles.spanText}>Location: </span>
            {prequalifyData?.data?.ProjectLocation}
        </Text>
    </View>
);

export default PageOneProjectDetails;
