import React from 'react';
import { StyledCircularProgress } from './styles';


export const  CircularLoading = (props) => {
  return (
    <div>
      <StyledCircularProgress { ...props }/>
    </div>
  );
};
