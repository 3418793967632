import React from 'react';
// import { Radio } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import CheckedIcon from '../../assets/buyers/checkedIcon';
import UncheckedIcon from '../../assets/buyers/uncheckedIcon';
import { StyledRoundCheckbox } from './styles';

export const RadioButton = ({
  isChecked,
  value,
  handleChange,
  checkedIcon,
  readOnly,
  unCheckedIcon
}) => {

  return (
    <>
      <StyledRoundCheckbox
        classes={{ root: 'custom-radio-button-root' }}
        checked={isChecked === true}
        checkedIcon={checkedIcon || <CheckedIcon />}
        icon={unCheckedIcon || <UncheckedIcon/>}
        onChange={handleChange}
        readOnly={ readOnly }
        value={value}
      />
    </>
  )
}
