import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import { COLORS, TYPOGRAPHY } from "../../../../constants";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';

export const TitleText = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.fontSize || "22px"};
    font-style: normal;
    font-weight: ${props => props.fontWeight || "500"};
    line-height: 21px;
    letter-spacing: 0px;
    text-align: ${props => props.textAlign || "left"};
    color: ${props => props.color || `${COLORS.darkGrey2}`};
    margin: ${props => props.margin || "0 0 16px"};
  }
`;


export const StyledTextField = styled(TextField)`

    width: ${(props) => (props.width ? props.width : "100%")};
    font-size: 18px;
    height: 48px;
    font-family: 'SF Pro Text';
    font-weight: 700;
    border-color: ${(props) => (props.noBorderColor ? "" : COLORS.lightGrey1)};
    background-color: ${(props) => (props.backgroundColor ? COLORS.lightGrey1 : "white")};

    .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) =>
    props.noBorderColor ? "" : COLORS.lightGrey1};
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${COLORS.primaryOrange};
      border-width: thin;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${COLORS.primaryOrange};
    }
  
`;



export const ScreenRoot = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  ${(props) => (props.isMobile ? "position: relative;" : "")}
  width: 100%;
  max-width: 1162px;
  ${(props) => (props.isMobile ? "" : "align-content: center;")}
  ${(props) => (props.isMobile ? "" : "justify-content: center;")}
  ${(props) => (props.isMobile ? "padding: 55px 0 0 0;" : "padding: 55px 55px 0px 55px;")}
  background-color: white;
`;


export const FieldArea = styled.div`
  ${(props) =>
    props.marginLeft && !props.isMobile
      ? `margin-left: ${props.marginLeft};`
      : ""}
  margin-top: ${(props) => props.marginTop || "0px"};
  display: flex;
  flex-direction: ${(props) => (props.isColumn ? "column" : "row")};
  align-items: center;
  flex-wrap: wrap;
  ${(props) => (props.noJustifyContent ? "" : "justify-content: center;")}
  ${(props) =>
    props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}

  .last {
    padding-right: 0px !important;
  }

  .inputWrap {
    padding-right: ${(props) => (props.isMobile ? "0px" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "50%")};
  }

  .fullWidthInputWrap {
    width: 100%;
  }

  .oneFifthInputWrap {
   padding-right: ${(props) => (props.isMobile ? "0px" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "20%")};
  }

  .thirtyInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "30%")};
  }

  .oneThirdInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "33.33%")};
  }

  .twoThirdInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "66.66%")};
  }

  .oneFifthMobile {
    padding-right: 16px;
    width: ${(props) => (props.isMobile ? "50%" : "20%")};
  }

  .thirtyMobile {
    width: ${(props) => (props.isMobile ? "50%" : "30%")};
  }
`;


export const FormContainer = styled.div`
  width: ${(props) => (props.isMobile ? "85%" : "100%")};
  ${(props) => (props.isMobile ? "align-self: center;" : "")}
  padding-bottom: 20px;
  margin-top: ${(props) => (props.isMobile ? "16px" : "24px")};
  display: flex;
  flex-direction: column;
  // align-items: center;
  // ${(props) => (props.leftBorder ? "margin-top: 50px;" : "")};
  border-left: ${(props) => (props.leftBorder ? "3px solid #EBE9F1" : "none")};
`;


export const StyledPaper = styled(Paper)`
  && {
    padding: 15px;
    width: ${(props) => (props.isMobile ? "100%" : "1142px")};
    align-self: center;
    overflow: hidden;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
`;


export const StyledMainCSR = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    // margin-bottom: 32px;
`;


export const StyledLeftCSR = styled.div`
    width: 50%;
    margin-right: 75px;
`;


export const StyledRightCSR = styled.div`
    width: 50%;
    margin-left: auto;
`;


export const StyledMainCSRLeft = styled.div`
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin-right: 12px;
`;

export const StyledMainCSRRight = styled.div`
    display: flex;
    justify-content: space-between;
    width: 50%;
`;

export const StyledLeftCSRLeft = styled.div`
    width: 50%;
    margin-right: 12px;
`;


export const StyledRightCSRRight = styled.div`
    width: 50%;
    margin-left: auto;
`;

export const StyledRightCSRRemarks = styled.div`
    width: 70%;
    margin-left: 0px;
`;



export const StyledIsProgressSatisfactory = styled.div`
    display: flex;
    justify-content: space-between;
    width: 30%;
    margin-right: 12px;
`;

export const StyledLeftCSRLeftLeft = styled.div`
    width: 75%;
    margin-right: 6px;
`;


export const StyledRightCSRRightRight = styled.div`
    width: 25%;
    margin-left: auto;
`;



export const TextTitle = styled.div`
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 41px;
    color: #B9B9B9;
    margin-bottom: 4px;
`;

export const LabelText = styled.div`
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    margin-bottom: 8px;
    margin-top: 8px;
    color: #000000;
`;

export const InputText = styled.div`
    box-sizing: border-box;
    background: rgba(235, 233, 241, 0.75);
    border: 1px solid #EBE9F1;
    border-radius: 6px;
    margin-bottom: 16px;
    padding-left: 15px;
    padding-top: 14px;
    padding-bottom: 14px;
    margin-top: 4px;
    height: 48px;
    font-size: 15px;
    position: relative;
`;

export const InputSelect = styled.select`
    box-sizing: border-box;
    background: rgba(235, 233, 241, 0.75);
    border: 1px solid #EBE9F1;
    border-radius: 6px;
    margin-bottom: 16px;
    padding-left: 15px;
    padding-top: 14px;
    padding-bottom: 14px;
    margin-top: 4px;
    height: 48px;
    font-size: 15px;
`;

export const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};;
  justify-content: center;
  margin: 24px 0px;
`;

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isMobile ? "90%" : "100%")};
  justify-content: center;
  ${props => props.isMobile ? "" : "padding: 0px 16px;"}
`;

export const TitleDiv = styled.div`
  display: flex; 
  flex-direction: column;
  align-items: center; 
  width: 100%;
  margin: 16px 0 0;
`;




export const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 25px;
  margin-left: 0;
  margin-right: auto;
  font-style: normal;
  font-weight: ${(props) => props.labelWeight || "normal"};
  font-size: ${(props) => props.labelSize || "12px"};
  line-height: 20px;
  color: #000000;
`;

export const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => props.marginTop || "0"};
  margin-bottom: ${(props) => props.marginBottom || "0"};
`;

export const FormTitle = styled.div`
  font-family: ${TYPOGRAPHY.primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 41px;
  margin-top: 24px;
  margin-bottom: 32px;
  text-align: center;
  color: #1A051D;
`;

export const StyledFileName = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: #b8b8b8;
  margin-top: 1px;
  margin-bottom: 5px;
  background: #ffffff;
  border: 1px solid #ebe9f1;
  border-radius: 6px;
  margin-left: 12px;
`;

export const StyledUploadIcon = styled.label`
  margin: ${(props) => props.margin || 0};
  cursor: pointer;
`;

export const InfoArea = styled.div`
  ${(props) =>
    props.marginLeft && !props.isMobile
      ? `margin-left: ${props.marginLeft};`
      : ""}
  margin-top: ${(props) => props.marginTop || "24px"};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  ${(props) => (props.noJustifyContent ? "" : "justify-content: center;")}
  ${(props) =>
    props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}
  
  .last {
    padding-right: -5px !important;
  }

  .inputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "50%")};
  }

  .fullWidthInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: 100%;
  }

  .oneFifthInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "20%")};
  }

  .thirtyInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "30%")};
  }

  .oneThirdInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "33.33%")};
  }

  .twoThirdInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "66.66%")};
  }

  .oneFifthMobile {
    padding-right: 16px;
    width: ${(props) => (props.isMobile ? "50%" : "20%")};
  }

  .thirtyMobile {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "50%" : "30%")};
  }
`;
export const InfoLabel = styled.div`
  margin-bottom: 5px;
  margin-left: 0;
  margin-right: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;

  line-height: 15px;
  color: #938d98;
  padding-right: 16px;
`;

export const StyledEditRecordButton = styled(FieldArea)`
  align-items: left;
  margin-top: 15px;
`;

