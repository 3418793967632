import React from 'react';
import FilesListTable from './index';
import { StyledConciseTableContainer } from './styles';


const ConciseTable = (props) => {
  const { data, length, width, height, paddingRight} = props;
  const show = data && (data.length > length);

  return (
    <StyledConciseTableContainer show={show} length={length} width={width} height={height} paddingRight={paddingRight}>
      <FilesListTable concise {...props} />
    </StyledConciseTableContainer>
  );
};

export default ConciseTable;
