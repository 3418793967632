import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import { StyledMenu, StyledMenuItem, StyledButton } from "./styles";
import DeleteIcon from "../../assets/buyers/deleteIcon";
import VerticalThreeDots from "../../assets/dashboardIcons/verticalThreeDots";
import { useMediaQuery } from "react-responsive";
import { AddProjectRemarkPopup } from "../addProjectRemarkPopup";
import { AddCcdcTemplatePopUp } from "../addCcdcTemplatePopUp";
import { downloadZip } from "client-zip/index.js";
import FileSaver from "file-saver";
import { AlertPopup } from "components/alertPopup/alertPopup";
import handleFileDownload from "common/downloadFile";

export const ThreeDotsPopupMenuNew = ({
  item,
  index,
  data,
  handleSeeAll,
  projects,
  setProjects,
  icon,
  handleModify,
  handleDelete,
  removeEdit,
  addProjectRemark,
  noEditButton,
  deleteText,
  handleDisable,
  handleEnable,
  documentPool,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openClonePopup, setOpenClonePopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const checkCcdcId = (obj) =>
    (obj.status === "Downloaded" ||
      obj.status === "Submitted" ||
      obj.status === "Awarded") &&
    item.id === obj.ccdcId;
  const checkCcdcIdValue = data ? data.some(checkCcdcId) : "";
  const [pdfFileSequence, setPdfFileSequence] = useState([]);
  const ckeckEnableDisable = item?.status;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickDownloadedDelete = async () => {
    if (checkCcdcIdValue == true) {
      setOpenDeletePopup(true);
    } else {
      setOpenDeletePopup(false);
    }
    handleClose();
  };

  const clickDelete = async () => {
    handleDelete(index);
    handleClose();
  };
  const clickDownload = async () => {
    handleFileDownload(item?.file_id, item?.file_name);
    handleClose();
  };

  const clickEnable = async () => {
    handleEnable(item ? item?.id : "");
    handleClose();
  };

  const clickDisable = async () => {
    handleDisable(item ? item?.id : "");
    handleClose();
  };

  const clickDeleteModern = (e) => {
    handleDelete(e, item.index, item);
    handleClose();
  };

  const clickModify = async (action) => {
    handleModify(item ? item?.id : "");
    handleClose();
  };

  // add button
  const handleRemarkButtonClick = () => {
    if (item) {
      setOpenPopup(true);
      handleClose();
    }
  };

  const handleCloneCcdcButtonClick = () => {
    if (item) {
      setOpenClonePopup(true);
      handleClose();
    }
  };

  const handleDownloadButtonClick = async () => {
    const content = await downloadZip(pdfFileSequence).blob();
    FileSaver.saveAs(content, `qualify_${Date.now()}.zip`);
    handleClose();
  };

  return (
    <>
      {documentPool && (
        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ padding: 0 }}
          >
            {icon || <VerticalThreeDots />}
          </IconButton>
          <StyledMenu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <StyledMenuItem>
              <StyledButton width="180px" onClick={clickDownload}>
                Download
              </StyledButton>
            </StyledMenuItem>

            <StyledMenuItem>
              <StyledButton width="180px" onClick={clickDelete} disabled>
                Delete
              </StyledButton>
            </StyledMenuItem>
            <StyledMenuItem>
              <StyledButton
                width="180px"
                onClick={() => console.log("future implementation")}
                disabled
              >
                Certify
              </StyledButton>
            </StyledMenuItem>
          </StyledMenu>
        </>
      )}
    </>
  );
};
