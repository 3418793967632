import { Typography } from "@mui/material";
import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from '../../../../constants';
import IconButton from "@material-ui/core/IconButton";
import { constant } from "lodash";


export const CloseButton = styled(IconButton)`
  &&{
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PopupTitle = styled(Typography)`
&&{
  font-family: ${TYPOGRAPHY.primaryFont};
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 16px;
}
`;

export const PopupFields = styled.div`
  display: flex;
  flex-direction: column;
  // margin-top: 16px;
  // width: ${props => props.isMobile ? "100%" : "95%"};
  width: 100%;
  min-height: 330px;
`;

export const ButtonWrap = styled.div`
  min-height: 80px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledQuestionHeader = styled.div`
  font-family: SF Pro Text;
  font-size: 18px;
  color: ${COLORS.primaryOrange};
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  margin: 48px 0 8px 0;
`;

export const StyledYesNo = styled.div`
  font-family: SF Pro Text;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  margin: auto 0;
  margin-left: 32px;
`;