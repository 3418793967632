import get from "lodash/get";

const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  const payload = get(action, "payload", []);
  switch (action.type) {
    case "CANCEL_RFPQ":
      var newRfpqs = state.institution_rfpqs.filter((institution_rfpq) => {
        if (institution_rfpq.id === payload.rfpq_id) {
          return (institution_rfpq.status = "cancelled");
        } else {
          return institution_rfpq;
        }
      });
      return {
        ...state,
        institution_rfpqs: newRfpqs,
      };
    case "CLEAR_INSTITUTION_RFPQ":
      return {
        ...state,
        institution_rfpq: null,
      };
    case "CREATE_RFPQ":
      return {
        ...state,
        institution_rfpq: payload.rfpq,
      };
    case "DELETE_RFPQ":
      var newRfpqs = state.institution_rfpqs.filter(
        (institution_rfpq) => institution_rfpq.id !== payload.rfpq_id
      );
      return {
        ...state,
        institution_rfpqs: newRfpqs,
      };
    case "GET_BUYERS_LIST":
      return {
        ...state,
        buyers: payload,
      };
    case "GET_CONTRACTORS_LIST":
      return {
        ...state,
        contractors_list: payload,
      };
    case "GET_INSTITUTION_RFPQ":
      return {
        ...state,
        institution_rfpq: payload.rfpq,
      };
    case "GET_INSTITUTION_RFPQS":
      return {
        ...state,
        institution_rfpqs: payload,
      };
    case "GET_REQUIREMENTS":
      var publicRequirements = payload.public || [];
      var privateRequirements = payload.private || [];
      return {
        ...state,
        requirements: [...publicRequirements, ...privateRequirements],
      };
    case "GET_RFPQ":
      return {
        ...state,
        rfpq: payload.rfpq,
      };
    case "PREQUALIFY_CONTRACTORS":
      if (payload && payload.length > 0) {
        var prequalified_submissions = [];
        payload.map((prequalified_result) => {
          prequalified_submissions.push(prequalified_result.id);
        });
        var newSubmissions = state.institution_rfpq.submissions || [];
        newSubmissions.map((submission) => {
          if (prequalified_submissions.includes(submission.id)) {
            submission.prequalified = true;
          }
        });
      }
      return {
        ...state,
      };
    case "REVIEW_RFPQ":
      return {
        ...state,
        institution_rfpq: {
          ...state.institution_rfpq,
          status: "reviewed",
        },
      };
    case "GET_ASSOCIATIONS":
      return {
        ...state,
        associations: payload,
      };
    case "GET_UNIONS":
      return {
        ...state,
        unions: payload,
      };
    default:
      return state;
  }
};
