import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  background: #f5f5f5;
  min-height: calc(100vh - 172px);

  padding: 20px 35px;
`;

export const Title = styled.div`
  flex-grow: 2;

  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 41px;

  color: #1a051d;
`;

export const ContentControls = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: row;
`;

export const SelectLabel = styled.div`
  margin-right: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 41px;

  color: #1a051d;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

//OptionPick component
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 200px);
  padding: 100px 80px;
  
`;

export const FormTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  color: #000000;
`;
export const PictureButton = styled.div`
  width: 260px;
  height: 260px;

  background: #ffffff;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.08);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const PictureButtonLabel = styled.div`
  margin-top: 30px;
  width: 65%;
  font-weight: 550;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #000000;
`;

export const PictureButtonImage = styled.img`
  width: 118px;
  height: 118px;
  border-radius: 100%;
`;
export const BottomSentence = styled.div`
  margin-top: 50px;
  text-align: center;
  font-weight: 550;
  font-size: 16px;
  line-height: 18px;
  color: #FF6D1D;
`;
