import React, { useState, useRef } from "react";
import { savePassword } from "../../../api/profile";
import { LargeText, } from "../styles";
import { FunctionalButton } from '../../../components/functionalButton/index';
import { ConfirmationMessage } from "../../../pages/referencesVieweCQS/ConfirmationMessage";

import {
  ErrorText,
  FieldArea,
  StyledRow,
  SetupContainerPassword,
} from "./styles";


import Input from "../../../components/inputField";
import { FloatingButton } from "../../../components/floatingButton";
import { IconButton } from "@material-ui/core";
import MobileSaveButtonIcon from "../../../assets/dashboardIcons/mobileSaveButtonIcon";
import updateObjectByPath from "../../../common/updateObjectByPath";

const uppercaseRE = /(?=.*?[A-Z])/;
const lowercaseRE = /(?=.*?[a-z])/;
const numberRE = /(?=.*?[0-9])/;
const eightMoreRE = /^[\s\S]{8,}$/;
const passwordRE = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/

export default function ChangePassword({ isMobile }) {

  const [data, setData] = useState({});
  const saveButtonRef = useRef();
  const [password, setPassword] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [openSuccessPopup, setOpenSuccessPopup] = React.useState(false);


  const handleSave = () => {
    savePassword(
      data,
      () => {
        setConfirmPassword("");
        setPassword("");
        setOpenSuccessPopup(true);
      },
      () => {
        setOldPassword(true);
      }
    );
  };

  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };




  return (
    <SetupContainerPassword isMobile={isMobile}>
      <br /> <br /> <br /> <br /> <br /> <br />
      <StyledRow justifyContent="space-between" ref={saveButtonRef}>
        <LargeText isMobile={isMobile}>
          {isMobile
            ? "Change Password"
            : "Change Password"}
        </LargeText>
        {isMobile && (
          <IconButton onClick={handleSave}>
            <MobileSaveButtonIcon />
          </IconButton>
        )}
      </StyledRow>
      <FieldArea width={isMobile ? "100%" : "66.67%"}>

        <Input
          autoComplete="new-password"
          label="Current Password"
          type="password"
          placeholder="Current Password"
          labelSize="12px"
          textAreaHeight="64px"
          fontSize="18px"
          value={data?.currentPassword}
          setValue={(val) => {
            onChangeData("currentPassword", val);
          }}
          noCheckIcon={true}
        />

        {oldPassword === true && (
          <ErrorText>Current Password is not macthing.....</ErrorText>
        )}

        <Input
          autoComplete="new-password"
          label="New Password"
          type="password"
          placeholder="New Password"
          labelSize="12px"
          textAreaHeight="64px"
          fontSize="18px"
          value={data?.password}
          setValue={(val) => {
            onChangeData("password", val);
            setPassword(val);
          }}
          validateFunc={(item) => passwordRE.test(item)}
          hasError={password.length > 0 && !passwordRE.test(password)}
          noCheckIcon={true}
        />
        {password.length > 0 && !uppercaseRE.test(password) && (
          <ErrorText>At least 1 uppercase letter should exists.</ErrorText>
        )}
        {password.length > 0 && !lowercaseRE.test(password) && (
          <ErrorText>At least 1 lowercase letter should exists.</ErrorText>
        )}
        {password.length > 0 && !numberRE.test(password) && (
          <ErrorText>At least 1 digit should exists.</ErrorText>
        )}
        {password.length > 0 && !eightMoreRE.test(password) && (
          <ErrorText>Should contain at least 8 characters.</ErrorText>
        )}

        <Input
          autoComplete="new-password"
          label="Confirm Password"
          type="password"
          placeholder="Confirm Password"
          labelSize="12px"
          textAreaHeight="64px"
          fontSize="18px"
          value={confirmPassword}
          setValue={(val) => {
            setConfirmPassword(val);
          }}
          validateFunc={(item) => passwordRE.test(item)}
          hasError={confirmPassword.length > 0 && !passwordRE.test(confirmPassword)}
          noCheckIcon={true}
        />

        {password != confirmPassword && (
          <ErrorText>Password is not macthing.....</ErrorText>
        )}


        <FunctionalButton
          buttonTitle="Save"
          handleButton={handleSave}
          width="150px"
          height="38px"
          marginTop="80px"
        />

        <ConfirmationMessage
          openPopup={openSuccessPopup}
          setOpenPopup={setOpenSuccessPopup}
          isMobile={isMobile}
        />

      </FieldArea>
    </SetupContainerPassword>
  );
};


