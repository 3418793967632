import React, { useEffect, useState } from "react";
import { FunctionalButton } from "components/functionalButton";
import PledgxLogo from "assets/common/pledgxLogo";
import { useHistory } from "react-router-dom";
import Input from "components/inputField";
import { ReactComponent as RedRectanglurLine } from "pages/underwriters/components/contractStatusReportSending/assets/redRectanglurLine.svg";
import { viewContractStatusReport } from 'api/external_data';
import { useParams } from "react-router";
import { formatPhoneNumber } from "common/formatPhoneNumber";
import { RadioButton } from "components/radioButton";
import CheckedRadioButton from "assets/dashboardIcons/checkedRadioButton";
import UncheckedRadioButton from "assets/dashboardIcons/uncheckedRadioButton";
import InputArea from "components/inputAreaBG/index.js";
import { convertPhoneNumberCanadaUSA } from "common/regex";
import { NumberFormatOnly, NumberFormatWithCurrency } from 'components/locale/numberFormatOnly'

import {
  FormTitle,
  TitleDiv,
  ButtonDiv,
  StyledMainCSR,
  StyledLeftCSR,
  StyledRightCSR,
  TextTitle,
  LabelText,
  ScreenRoot,
  FormContainer,
  FieldArea,
  StyledLeftCSRLeft,
  StyledRightCSRRight,
  StyledLeftCSRLeftLeft,
  StyledRightCSRRightRight,
  StyledMainCSRRight,
  StyledMainCSRLeft,
  StyledEditRecordButton,
  StyledRightCSRRemarks,
  StyledIsProgressSatisfactory,
} from "./styles.js";

import ConvertToDate from "common/convertToDate.js";
import { numberWithCommas } from "common/numberFormat.js";
import { COLORS, FONT_SIZES } from "constants.js";


function ContractStatusReportViewingWithToken({
  isMobile
}) {
  let history = useHistory();
  const [data, setData] = useState({});
  const { token } = useParams();
  const [valid, setValid] = useState(false);
  const [error, setError] = useState("")


  useEffect(() => {
    viewContractStatusReport(
      token,
      (res) => {
        setData(res);     
        setValid(true);
      },
      (err) => { setValid(false); setError(err.data.message); }
    )
  }, [])


  const handleGoBack = () => {
    window.location.replace("https://pledgx.com")
  };

  const downloadStatusReport = () => { };

  return (
    <>
      {!data[0] && (
        <div
          style={{
            marginTop: "150px",
            color: "black",
            fontSize: FONT_SIZES.body_4,
            fontWeight: "400",
          }}
        >
          <div
            style={{
              color: COLORS.textGrey,
              fontSize: FONT_SIZES.body_4,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No Status Report To View
          </div>
        </div>
      )}
      {data[0] && (
        <ScreenRoot isMobile={isMobile}>
          <FormContainer isMobile={isMobile}>
            <TitleDiv>
              <PledgxLogo
                width={isMobile ? 45 : ""}
                height={isMobile ? 45 : ""}
              />
              <FormTitle style={{ marginTop: 10 }}>
                {"Contract Status Report"}
              </FormTitle>
            </TitleDiv>

            <FieldArea width={isMobile ? "100%" : "100%"}>
              <StyledMainCSR>
                <StyledLeftCSR>
                  <TextTitle>Bonding Company Details</TextTitle>
                  <Input
                    label="Company Name"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="0px"
                    backgroundColor="#EBE9F1"
                    value={data[0]?.company_name || ""}
                    disabled
                  />

                  <Input
                    label="Company Address"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    backgroundColor="#EBE9F1"
                    value={data[0]?.company_address || ""}
                    disabled
                  />

                  <Input
                    label="Contractor Name"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    backgroundColor="#EBE9F1"
                    value={data[0]?.contractor_name || ""}
                    disabled
                  />
                  <div style={{ marginTop: "18px" }}></div>
                  <TextTitle>Bond Details</TextTitle>
                  <Input
                    label="Bond Id"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="0px"
                    backgroundColor="#EBE9F1"
                    value={data[0]?.bond_id || ""}
                    disabled
                  />

                  <div style={{ marginTop: "18px" }}></div>
                  <StyledMainCSR>
                    <StyledLeftCSRLeft>
                      <Input
                        label="Effective Date"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="0px"
                        backgroundColor="#EBE9F1"
                        value={ConvertToDate(data[1]?.effective_date) || ""}
                        disabled
                      />
                    </StyledLeftCSRLeft>

                    <StyledRightCSRRight>
                      <Input
                        label="Obligee"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="0px"
                        backgroundColor="#EBE9F1"
                        value={
                          data[1]?.Obligee !== "NULL"
                            ? data[1]?.obligee
                            : ""
                        }
                        disabled
                      />
                    </StyledRightCSRRight>
                  </StyledMainCSR>
                </StyledLeftCSR>

                <StyledRightCSR>
                  <TextTitle>Project Details</TextTitle>
                  <Input
                    label="Project Title"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="0px"
                    backgroundColor="#EBE9F1"
                    value={data[0]?.project_title || ""}
                    disabled
                  />
                  <Input
                    label="Project Status"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    backgroundColor="#EBE9F1"
                    value={data[0]?.project_status || ""}
                    disabled
                  />

                  <Input
                    label="Orginal Contract Price($)"
                    type="text"
                    labelSize="12px"
                    fontSize="18px"
                    marginTop="16px"
                    backgroundColor="#EBE9F1"
                    value={
                      NumberFormatOnly(
                        data[0]?.project_actual_value
                      ) || ""
                    }
                    disabled
                  />
                  <div style={{ marginTop: "18px" }}></div>
                  <TextTitle>Report Details</TextTitle>
                  {data[0]?.project_status === "Underway" && (
                    <>
                      <StyledMainCSR>
                        <StyledLeftCSRLeft>
                          <Input
                            label="Anticipated Completion Date"
                            type="text"
                            labelSize="12px"
                            fontSize="18px"
                            marginTop="0px"
                            backgroundColor="#EBE9F1"
                            value={
                              ConvertToDate(
                                data[0]?.anticipated_completion_date
                              ) || ""
                            }
                            disabled
                          />
                        </StyledLeftCSRLeft>

                        <StyledRightCSRRight>
                          <Input
                            label="Reporting Date"
                            type="text"
                            labelSize="12px"
                            fontSize="18px"
                            marginTop="0px"
                            backgroundColor="#EBE9F1"
                            value={ConvertToDate(
                              data[0]?.reporting_date
                            )}
                            disabled
                          />
                        </StyledRightCSRRight>
                      </StyledMainCSR>

                      <StyledMainCSR>
                        <StyledMainCSRLeft>
                          <StyledLeftCSRLeftLeft>
                            <Input
                              label="Current Contract Price($)"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              backgroundColor="#EBE9F1"
                              value={
                                NumberFormatOnly(
                                  Math.trunc(
                                    data[0]?.current_contract_price
                                  )
                                ) || ""
                              }
                              disabled
                            />
                          </StyledLeftCSRLeftLeft>
                          <StyledRightCSRRightRight>
                            <Input
                              label="%"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              backgroundColor="#EBE9F1"
                              value={
                                data[0]?.current_contract_price_percent ||
                                ""
                              }
                              disabled
                            />
                          </StyledRightCSRRightRight>
                        </StyledMainCSRLeft>

                        <StyledMainCSRRight>
                          <StyledLeftCSRLeftLeft>
                            <Input
                              label="Work Approved Amount($)"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              backgroundColor="#EBE9F1"
                              value={
                                NumberFormatOnly(
                                  Math.trunc(
                                    data[0]?.work_approved_amount
                                  )
                                ) || ""
                              }
                              disabled
                            />
                          </StyledLeftCSRLeftLeft>
                          <StyledRightCSRRightRight>
                            <Input
                              label="%"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              backgroundColor="#EBE9F1"
                              value={
                                data[0]?.work_approved_amount_percent ||
                                ""
                              }
                              disabled
                            />
                          </StyledRightCSRRightRight>
                        </StyledMainCSRRight>
                      </StyledMainCSR>

                      <StyledMainCSR>
                        <StyledMainCSRLeft>
                          <StyledLeftCSRLeftLeft>
                            <Input
                              label="Paid to Contractor Amount($)"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              backgroundColor="#EBE9F1"
                              value={
                                NumberFormatOnly(
                                  Math.trunc(
                                    data[0]?.paid_to_contractor_amount
                                  )
                                ) || ""
                              }
                              disabled
                            />
                          </StyledLeftCSRLeftLeft>
                          <StyledRightCSRRightRight>
                            <Input
                              label="%"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              backgroundColor="#EBE9F1"
                              value={
                                data[0]?.paid_to_contractor_amount_percent ||
                                ""
                              }
                              disabled
                            />
                          </StyledRightCSRRightRight>
                        </StyledMainCSRLeft>

                        <StyledMainCSRRight>
                          <StyledLeftCSRLeftLeft>
                            <Input
                              label="Holdback Amount($)"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              backgroundColor="#EBE9F1"
                              value={
                                NumberFormatOnly(
                                  Math.trunc(
                                    data[0]?.holdback_amount
                                  )
                                ) || ""
                              }
                              disabled
                            />
                          </StyledLeftCSRLeftLeft>
                          <StyledRightCSRRightRight>
                            <Input
                              label="%"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              backgroundColor="#EBE9F1"
                              value={
                                data[0]?.holdback_amount_percent ||
                                ""
                              }
                              disabled
                            />
                          </StyledRightCSRRightRight>
                        </StyledMainCSRRight>
                      </StyledMainCSR>
                    </>
                  )}
                  {data[0]?.project_status === "Completed" && (
                    <>
                      <StyledMainCSR>
                        <StyledMainCSRLeft>
                          <Input
                            label="Completion Date"
                            type="text"
                            labelSize="12px"
                            fontSize="18px"
                            marginTop="16px"
                            backgroundColor="#EBE9F1"
                            value={
                              ConvertToDate(
                                data[0]?.anticipated_completion_date
                              ) || ""
                            }
                            disabled
                          />
                        </StyledMainCSRLeft>

                        <StyledMainCSRRight>
                          <StyledLeftCSRLeftLeft>
                            <Input
                              label="Final Contract Price($)"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              backgroundColor="#EBE9F1"
                              value={
                                NumberFormatOnly(
                                  Math.trunc(
                                    data[0]?.current_contract_price
                                  )
                                ) || ""
                              }
                              disabled
                            />
                          </StyledLeftCSRLeftLeft>
                          <StyledRightCSRRightRight>
                            <Input
                              label="%"
                              type="text"
                              labelSize="12px"
                              fontSize="18px"
                              marginTop="16px"
                              paddingRight="5px"
                              paddingLeft="5px"
                              backgroundColor="#EBE9F1"
                              value={
                                data[0]?.current_contract_price_percent ||
                                ""
                              }
                              disabled
                            />
                          </StyledRightCSRRightRight>
                        </StyledMainCSRRight>
                      </StyledMainCSR>
                    </>
                  )}

                  <StyledMainCSR>
                    <StyledIsProgressSatisfactory>
                      <LabelText style={{ marginTop: "16px" }}>Is Progress Satisfactory?</LabelText>
                      <StyledEditRecordButton style={{ display: 'flex', flexDirection: 'column', marginLeft: '-160px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-60px', marginTop: '10px' }}>

                          <RadioButton
                            isChecked={
                              data[0]?.is_progress_satisfactory ===
                                1
                                ? true
                                : false
                            }
                            value={
                              data[0]?.is_progress_satisfactory
                            }
                            checkedIcon={<CheckedRadioButton color="#D0C9D6" />}
                            unCheckedIcon={
                              <UncheckedRadioButton color="#D0C9D6" />
                            }
                            disabled
                          />
                          <span style={{ marginLeft: '5px' }}>Yes</span>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-62px' }}>
                          <RadioButton
                            isChecked={
                              data[0]?.is_progress_satisfactory ===
                                0
                                ? true
                                : false
                            }
                            value={
                              data[0]?.is_progress_satisfactory
                            }
                            checkedIcon={<CheckedRadioButton color="#D0C9D6" />}
                            unCheckedIcon={
                              <UncheckedRadioButton color="#D0C9D6" />
                            }
                            disabled
                          />

                          <span style={{ marginLeft: '5px' }}>No</span>
                        </div>
                      </StyledEditRecordButton>
                    </StyledIsProgressSatisfactory>

                    <StyledRightCSRRemarks>
                      <LabelText style={{ marginTop: "16px", marginBottom: "10px" }}> Remarks </LabelText>
                      <InputArea
                        backgroundColor="#EBE9F1"
                        placeholder={"No Remarks"}
                        value={data[0]?.remarks || ""}
                        readOnly={true}
                      />
                    </StyledRightCSRRemarks>
                  </StyledMainCSR>

                </StyledRightCSR>
              </StyledMainCSR>

              <StyledMainCSR>
                <StyledLeftCSR>
                  <div style={{ marginTop: "48px" }}> </div>
                  <RedRectanglurLine />
                </StyledLeftCSR>

                <StyledRightCSR>
                  <div style={{ marginTop: "48px" }}> </div>
                  <RedRectanglurLine />
                </StyledRightCSR>
              </StyledMainCSR>

              <StyledMainCSR>
                <StyledLeftCSR>
                  <TextTitle>Report Requested by</TextTitle>

                  <StyledMainCSR>
                    <StyledLeftCSRLeft>
                      <Input
                        label="Name"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="0px"
                        backgroundColor="#EBE9F1"
                        value={data[0]?.requseter_name || ""}
                        disabled
                      />
                    </StyledLeftCSRLeft>

                    <StyledRightCSRRight>
                      <Input
                        label="Role"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="0px"
                        backgroundColor="#EBE9F1"
                        value={data[0]?.requseter_role || ""}
                        disabled
                      />
                    </StyledRightCSRRight>
                  </StyledMainCSR>

                  <StyledMainCSR>
                    <StyledLeftCSRLeft>
                      <Input
                        label="Email"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        backgroundColor="#EBE9F1"
                        value={data[0]?.requseter_email || ""}
                        disabled
                      />
                    </StyledLeftCSRLeft>

                    <StyledRightCSRRight>
                      <Input
                        label="Phone Number"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        backgroundColor="#EBE9F1"
                        value={
                          convertPhoneNumberCanadaUSA(data[0]?.requseter_phone_number) || ""
                        }
                        disabled
                      />
                    </StyledRightCSRRight>
                  </StyledMainCSR>
                </StyledLeftCSR>

                <StyledRightCSR>
                  <TextTitle>Report Issued by</TextTitle>
                  <StyledMainCSR>
                    <StyledLeftCSRLeft>
                      <Input
                        label="Name"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="0px"
                        backgroundColor="#EBE9F1"
                        value={data[0]?.issuer_name || ""}
                        disabled
                      />
                    </StyledLeftCSRLeft>

                    <StyledRightCSRRight>
                      <Input
                        label="Role"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="0px"
                        backgroundColor="#EBE9F1"
                        value={data[0]?.issuer_role || ""}
                        disabled
                      />
                    </StyledRightCSRRight>
                  </StyledMainCSR>

                  <StyledMainCSR>
                    <StyledLeftCSRLeft>
                      <Input
                        label="Email"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        backgroundColor="#EBE9F1"
                        value={data[0]?.issuer_email || ""}
                        disabled
                      />
                    </StyledLeftCSRLeft>

                    <StyledRightCSRRight>
                      <Input
                        label="Phone Number"
                        type="text"
                        labelSize="12px"
                        fontSize="18px"
                        marginTop="16px"
                        backgroundColor="#EBE9F1"
                        value={
                          convertPhoneNumberCanadaUSA(data[0]?.issuer_phone_number) || ""
                        }
                        disabled
                      />
                    </StyledRightCSRRight>
                  </StyledMainCSR>
                </StyledRightCSR>
              </StyledMainCSR>
            </FieldArea>

            <ButtonDiv isMobile={isMobile}>
              <FunctionalButton
                buttonTitle="Go To PledgX Website"
                handleButton={handleGoBack}
                isReversedColor={true}
                height="48px"
                width="250px"
                marginTop="64px"
                marginRight="16px"
              />
            </ButtonDiv>
          </FormContainer>
        </ScreenRoot>
      )}
    </>
  );
}

export default ContractStatusReportViewingWithToken;
