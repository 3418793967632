import React, { useState, useEffect } from 'react';
import { useMediaQuery } from "react-responsive";
import { YearsNumber } from './YearsNumber';
import { GapBetweenWords, StyledYear, FieldColumn, FieldWrap, LabelText, MobileYearText } from './styles';
import { MobileYearsNumber } from './MobileYearsNumber';

export const SafetyRecordsUSA = ({
  data,
  setData,
  isMobile
 }) => {

  const currentYear = new Date().getFullYear();

  
  return (
    <FieldWrap isMobile={isMobile}>
      {!isMobile && (
        <>
          <FieldColumn className='firstColumn'>
            <LabelText className='yearLabel'>Year</LabelText>
            <StyledYear>{currentYear-1}</StyledYear>
            <StyledYear>{currentYear-2}</StyledYear>
            <StyledYear>{currentYear-3}</StyledYear>
          </FieldColumn>
          <FieldColumn className='recordColumn'>
            <LabelText>Independent Classification Code</LabelText>
            <YearsNumber 
              data={data?.safety_records_usa ? data?.safety_records_usa : {}}
              setData={setData}
              type="emr_worksheet_independent_classification_code"
              year={currentYear-1}
            />
          </FieldColumn>
          <FieldColumn className='recordColumn'>
            <LabelText>Experience Modification Rating</LabelText>
            <YearsNumber 
              data={data?.safety_records_usa ? data?.safety_records_usa : {}}
              setData={setData}
              type="emr_worksheet_electronic_medical_record"
              year={currentYear-1}
            />
          </FieldColumn>
          <FieldColumn className='recordColumn'>
            <LabelText>Total Hours for All Employees</LabelText>
            <YearsNumber 
              data={data?.safety_records_usa ? data?.safety_records_usa : {}}
              setData={setData}
              type="osha_300a_total_hours_for_all_employees"
              year={currentYear-1}
              paddingLeft="5px"
              paddingRight="5px"
            />
          </FieldColumn>
          <FieldColumn className='recordColumn'>
            <LabelText>Total  Number  of   <GapBetweenWords /> Deaths</LabelText> 
            <YearsNumber 
              data={data?.safety_records_usa ? data?.safety_records_usa : {}}
              setData={setData}
              type="osha_300a_total_number_of_deaths"
              year={currentYear-1}
            />
          </FieldColumn>
          <FieldColumn className='recordColumn'>
            <LabelText>Total Number of Other Cases</LabelText>
            <YearsNumber 
              data={data?.safety_records_usa ? data?.safety_records_usa : {}}
              setData={setData}
              type="osha_300a_total_number_of_other_cases"
              year={currentYear-1}
            />
          </FieldColumn>
          <FieldColumn className='recordColumn'>
            <LabelText>Total Number of Absent Days</LabelText>
            <YearsNumber 
              data={data?.safety_records_usa ? data?.safety_records_usa : {}}
              setData={setData}
              type="osha_300a_total_number_of_absent_days"
              year={currentYear-1}
            />
          </FieldColumn>
        </>
      )}
      {isMobile && (
        <>
          <MobileYearText margin="0px 0px 16px">{currentYear-1}</MobileYearText>
          <MobileYearsNumber 
             data={data?.safety_records_usa ? data?.safety_records_usa : {}}
             setData={setData}
             year={currentYear-1}
          />
          <MobileYearText margin="32px 0px 16px">{currentYear-2}</MobileYearText>
          <MobileYearsNumber 
             data={data?.safety_records_usa ? data?.safety_records_usa : {}}
             setData={setData}
             year={currentYear-2}
          />
          <MobileYearText margin="32px 0px 16px">{currentYear-3}</MobileYearText>
          <MobileYearsNumber 
             data={data?.safety_records_usa ? data?.safety_records_usa : {}}
             setData={setData}
             year={currentYear-3}
          />
        </>
      )}
      
    </FieldWrap>
  );
};
