import React from "react";

export const ArrowIcon = ({
  fill,
  width = "10",
  height = "16",
  marginLeft = "16px",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: marginLeft }}
    >
      <path
        d="M6.34962 8L0.424264 2.35463C-0.141106 1.81598 -0.141106 0.942645 0.424264 0.403991C0.989634 -0.134663 1.90628 -0.134663 2.47165 0.403991L9.4207 7.02468C9.98607 7.56333 9.98607 8.43667 9.4207 8.97532L2.47165 15.596C1.90628 16.1347 0.989634 16.1347 0.424264 15.596C-0.141106 15.0574 -0.141106 14.184 0.424264 13.6454L6.34962 8Z"
        fill={fill}
      />
    </svg>
  );
};
