import styled from "styled-components";
import { COLORS } from "../../constants";

export const InputWrap = styled.div`
  position: relative;
  width: 100%;
  margin-top: 12px;
`;

export const InputLabel = styled.div`
  margin-bottom: 5px;
  display: flex;
  font-style: normal;
  font-weight: ${(props) => props.labelWeight || "normal"};
  font-size: ${(props) => props.labelSize || "12px"};
  line-height: 20px;
  color: #000000;
`;

export const InputLabelHint = styled.span`
  margin-left: 15px;
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 20px;

  color: #777777;
`;

export const InputField = styled.input`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => props.textAreaHeight || "48px"};

  padding-left: ${(props) => props.paddingLeft || "15px"};
  padding-right: ${(props) => props.paddingRight || "40px"};

  ${(props) =>
    props?.backgroundColor || props?.readOnly
      ? `background-color: ${
          props.readOnly ? COLORS.backgroundGrey : props.backgroundColor
        };`
      : ""}
  border: 1px solid ${(props) =>
    (props.readOnly && COLORS.greyBorder) || props.borderColor || "#cccccc"};
  box-sizing: border-box;
  border-radius: 4px;
  color: ${(props) => (props.color ? props.color : "#1a051d")};

  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => props.fontSize || "15px"};

  &:focus {
    outline: none;
    border-color: ${(props) =>
      props.readOnly ? COLORS.greyBorder : "#ff6d1d"};
    ${(props) => (props.readOnly ? `cursor: default;` : "")}
  }
  &:hover {
    ${(props) => (props.readOnly ? `cursor: default;` : "")}
  }

  &::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #d0c9d6;
  }
`;

export const ReadOnlyValue = styled.span`
  width: ${(props) => (props.width ? props.width : "100%")};
  min-height: ${(props) => props.textAreaHeight || "50px"};

  padding-left: ${(props) => props.paddingLeft || "15px"};
  padding-right: ${(props) => props.paddingRight || "40px"};
  margin-right: ${(props) => props.marginRight || ""};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : COLORS.backgroundGrey};
  border: 1px solid ${COLORS.greyBorder};
  box-sizing: border-box;
  border-radius: 4px;
  color: ${COLORS.darkGrey1};

  font-style: normal;
  font-weight: normal;
  font-size: ${(props) => props.fontSize || "15px"};
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
`;
