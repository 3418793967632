import React, { useEffect, useState} from 'react';
import { StyledContainer } from "./styles";
import { useParams } from 'react-router-dom';
import { getSubmissionDetails } from '../../api/buyers';
import { GoBackButton } from './components/goBackButton';
import SubmissionPreview from '../submissionPreview';


const SubmissionDetails = () => {
  const { submissionId } = useParams();
  const [data, setData] = useState('');

  useEffect(() => {
    getDetailsData(submissionId);
  }, []);

  const getDetailsData = async (id) => {
    await getSubmissionDetails(id, 'edit')
          .then(data => {setData(data);})
          .catch(err => {});
  };
  return(
    <StyledContainer>
      <GoBackButton buttonText="Back To Submissions" />
      { data && (
          <SubmissionPreview
            setIsLastPanel={ true }
            handleNext={ () => console.log('NEXT') }
            prequalifyData={ data }
            readOnly={ true }
            submitCqs={ false }
          />
      )}
    </StyledContainer>
  );
};

export default SubmissionDetails;
