export const getRecommendedTenders = (offset = 0, categories = "") => (
  dispatch
) => {
  dispatch({
    type: "GET_RECOMMENDED_TENDERS",
    payload: {
      endpoint: `/tenders/recommended?offset=${offset}&categories=${categories}`,
      method: "GET",
    },
  });
};

export const loadMoreTenders = (
  offset = 0,
  categories = "",
  onSuccess,
  onFailed
) => (dispatch) => {
  dispatch({
    type: "LOAD_MORE_TENDERS",
    payload: {
      endpoint: `/tenders/recommended?offset=${offset}&categories=${categories}`,
      method: "GET",
    },
    onSuccess,
    onFailed,
  });
};

export const saveTender = (tender) => (dispatch) => {
  dispatch({
    type: "SAVE_TENDER",
    payload: {
      endpoint: "/tenders/saved",
      method: "POST",
      body: { ...tender },
    },
    tender,
  });
};

export const deleteTender = (tender) => (dispatch) => {
  dispatch({
    type: "DELETE_TENDER",
    payload: {
      endpoint: "/tenders/saved",
      method: "DELETE",
      body: {
        tender_id: tender.Id,
      },
    },
    tender,
  });
};

export const getSavedTenders = () => (dispatch) => {
  dispatch({
    type: "GET_SAVED_TENDERS",
    payload: {
      endpoint: "/tenders/saved",
      method: "GET",
    },
  });
};

export const getResultTenders = () => (dispatch) => {
  dispatch({
    type: "GET_RESULT_TENDERS",
    payload: {
      endpoint: "/tenders/submitted",
      method: "GET",
    },
  });
};

export const getResultTendersUsers = (bidId) => (dispatch) => {
  dispatch({
    type: "GET_RESULT_TENDERS_USERS",
    payload: {
      endpoint: `/tenders/submitted/users?id=${bidId}`,
      method: "GET",
    },
    bidId,
  });
};
