import get from "lodash/get";

const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_NOTIFICATIONS": {
      const payload = get(action, "payload", []);
      return {
        ...state,
        notifications: payload,
      };
    }
    case "EDIT_NOTIFICATION": {
      const notification = get(action, "notification", {});
      const updNotifications = [...state.notifications];

      const updNotificationIndex = updNotifications.findIndex(
        (item) => item.id === notification.id
      );
      if (updNotificationIndex !== -1)
        updNotifications[updNotificationIndex] = notification;

      return {
        ...state,
        notifications: updNotifications,
      };
    }
    case "DELETE_NOTIFICATION": {
      const notification = get(action, "notification", {});
      const updNotifications = [...state.notifications];

      const updNotificationIndex = updNotifications.findIndex(
        (item) => item.id === notification.id
      );
      if (updNotificationIndex !== -1)
        updNotifications.splice(updNotificationIndex, 1);

      return {
        ...state,
        notifications: updNotifications,
      };
    }
    default:
      return state;
  }
};
