import React, { useState, useEffect } from "react";
import { filterProjects } from "api/prequalify";
import IncrementalSearchPickList from "components/incrementalSearchPickList";
import { StyledImgIcon } from "./styles";
import { StyledTableCell } from "components/filesListTable/styles";
import PlusSignIcon from "assets/buyers/plusSignIcon";
import { AlertPopup } from "components/alertPopup/alertPopup";
import { useSelector } from "react-redux";
import { contactSelector } from "../../../../../data/selectors/contact";

import {
  StyledProjectSelectionContainer,
  MobileColumnContent,
  MobileColumnText,
  StyledUnderwayContainer,
  StyledCompletedContainer,
} from "./styles";
import { institutionProfileInfoSelector } from "data/selectors/serviceProvider";

export const SelectingProjects = ({
  add,
  projects,
  setProjects,
  setOpenPopup,
  pickedArray,
  filteredData,
  setFilteredData,
}) => {
  const [selected, setSelected] = useState(false);
  const [openExceedPopup, setOpenExceedPopup] = useState(false);
  const currentDashboardUrl = localStorage.getItem("current_dashboard_url");
  let contactData = useSelector(contactSelector);
  const institutionContactData = useSelector(institutionProfileInfoSelector);

  if (currentDashboardUrl === "/service-provider-dashboard") {
    contactData = institutionContactData;
  }
  const projectsLimit = contactData?.user_access_control?.number_of_projects;

  const headers = [
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
    { title: "" },
  ];

  const handleProjectSelection = (bodyData) => {
    const filteredTemp = [...filteredData];
    const projectsTemp = [...projects];

    projectsTemp.unshift({
      id: bodyData.id,
      project_name: bodyData.project_name,
      project_number: bodyData.project_number,
      project_location: bodyData.project_location,
      project_status: bodyData.project_status,
      type: ["key"],
    });

    setProjects(projectsTemp);
    filteredTemp.splice(bodyData.index, 1);
    setFilteredData(filteredTemp);
  };

  const handleUnderwayProjectSelection = (bodyData) => {
    const filteredTemp = [...filteredData];
    const projectsTemp = [...projects];

    projectsTemp.unshift({
      id: bodyData.id,
      project_name: bodyData.project_name,
      project_number: bodyData.project_number,
      project_location: bodyData.project_location,
      project_status: bodyData.project_status,
      type: ["underway"],
    });

    setProjects(projectsTemp);
    filteredTemp.splice(bodyData.index, 1);
    setFilteredData(filteredTemp);
  };

  // add button

  const handleAdd = (data) => {
    if (filteredData && filteredData.length >= projectsLimit) {
      setOpenExceedPopup(true);
    } else {
      setOpenPopup(true);
    }
  };

  const body = (bodyData) => {
    return (
      <>
        {bodyData && bodyData.project_status != "Underway" && (
          <>
            <StyledTableCell
              component={"tr"}
              onClick={() => handleProjectSelection(bodyData)}
            >
              <StyledImgIcon src={PlusSignIcon} />
            </StyledTableCell>
            <StyledTableCell
              component={"tr"}
              onClick={() => handleProjectSelection(bodyData)}
            >
              {bodyData.project_number}
            </StyledTableCell>
            <StyledTableCell
              component={"tr"}
              onClick={() => handleProjectSelection(bodyData)}
            >
              {bodyData.project_name}
            </StyledTableCell>
            <StyledTableCell
              component={"tr"}
              onClick={() => handleProjectSelection(bodyData)}
            >
              {bodyData.project_location}
            </StyledTableCell>
            <StyledTableCell
              component={"tr"}
              width="10%"
              align="left"
              onClick={() => handleProjectSelection(bodyData)}
            >
              {bodyData.project_status === "Underway" ? (
                <StyledUnderwayContainer>Underway</StyledUnderwayContainer>
              ) : bodyData.project_status === "Completed" ? (
                <StyledCompletedContainer>Completed</StyledCompletedContainer>
              ) : (
                ""
              )}
            </StyledTableCell>
          </>
        )}

        {bodyData && bodyData.project_status == "Underway" && (
          <>
            <StyledTableCell
              component={"tr"}
              onClick={() => handleUnderwayProjectSelection(bodyData)}
            >
              <StyledImgIcon src={PlusSignIcon} />
            </StyledTableCell>
            <StyledTableCell
              component={"tr"}
              onClick={() => handleUnderwayProjectSelection(bodyData)}
            >
              {bodyData.project_number}
            </StyledTableCell>
            <StyledTableCell
              component={"tr"}
              onClick={() => handleUnderwayProjectSelection(bodyData)}
            >
              {bodyData.project_name}
            </StyledTableCell>
            <StyledTableCell
              component={"tr"}
              onClick={() => handleUnderwayProjectSelection(bodyData)}
            >
              {bodyData.project_location}
            </StyledTableCell>
            <StyledTableCell
              component={"tr"}
              width="10%"
              align="left"
              onClick={() => handleUnderwayProjectSelection(bodyData)}
            >
              {bodyData.project_status === "Underway" ? (
                <StyledUnderwayContainer>Underway</StyledUnderwayContainer>
              ) : bodyData.project_status === "Completed" ? (
                <StyledCompletedContainer>Completed</StyledCompletedContainer>
              ) : (
                ""
              )}
            </StyledTableCell>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <IncrementalSearchPickList
        add={handleAdd}
        headers={headers}
        body={body}
        conciseLength={3.5}
        filterApi={filterProjects}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        addButtonTitle="Add Project"
        handleSelected={() => setSelected(true)}
        pickedArray={pickedArray}
        hover={true}
        height={"500px"}
        marginTop={"24px"}
        placeholder={"Project Title, Number, Location..."}
        width="100%"
        internalSearch={true}
      />
      <AlertPopup
        openPopup={openExceedPopup}
        setOpenPopup={setOpenExceedPopup}
        headerText="You have exceeded the projects limit."
        subHeaderText={`You can add a maximum of ${projectsLimit} projects.`}
        showButton={true}
      />
    </>
  );
};
