import React from 'react';
import buyer from './buyerIcon.svg'
import styled from "styled-components";

const BuyerIcon = ({width, height, isMobile}) => {
  return (
    <StyledImg width={width || "400"} height={height || "250"} src={buyer} isMobile={isMobile}/>
  )
};

export default BuyerIcon;

const StyledImg = styled.img`
  ${props => props.isMobile ? "margin-bottom: 8px; margin-top: 12px;" : ""}
`;