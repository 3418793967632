import React from "react";
import { FunctionalButton } from 'components/functionalButton';
import { Popup } from 'components/popup';
import { CloseButton } from "components/closeButton";
import PledgxLogo from "assets/common/pledgxLogo";

import {
  TitleDiv,
  TitleText,
  PopupContainer,
  ButtonDiv
} from "./styles";


export const ErrorDateApprovedMessage = ({
  openPopup,
  setOpenPopup,
  isMobile,
  errorMessage
}) => {

  const handleDone = () => {
    setOpenPopup(false);
  };


  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width={isMobile ? "100%" : "600px"}
      height={isMobile ? "100vh" : "250px"}
      dialogContent={(
        <>
          <CloseButton handleClose={handleDone} />
          <PopupContainer>
            <TitleDiv>
              <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
            </TitleDiv>
            
            <TitleText fontSize="18px" fontWeight="400" color="black" margin="20px 0px 0px" textAlign="center">{errorMessage}</TitleText>
            <ButtonDiv isMobile={isMobile}>
              <FunctionalButton
                buttonTitle="GO BACK"
                handleButton={handleDone}
                isReversedColor={true}
                height="48px"
                width="150px"
                marginTop="0px"
                marginRight="0px"
              />
            </ButtonDiv>
          </PopupContainer>

        </>
      )}
    />

  )
}
