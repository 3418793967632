import React, { useState, useEffect } from "react";
import { FunctionalButton } from '../../../../components/functionalButton';
import { StyledPopupTitle,StyledPopupText, StyledBottomButtonContainer } from './styles';

export const SearchPopupContent = ({ handleYes, setOpenPopup }) => {
  const handleNo = () => {
    setOpenPopup(false)
  }

  return (
    <>
      <StyledPopupTitle>Uh oh! The project you are looking for does not exist!</StyledPopupTitle>
      <StyledPopupText>Do you want to create new project?</StyledPopupText>
      <StyledBottomButtonContainer justifyContent="center" marginTop="36px">
      <FunctionalButton
          buttonTitle="Yes"
          width="138px"
          height="40px"
          marginRight="16px"
          handleButton={handleYes}
        />
        <FunctionalButton
          buttonTitle="No"
          width="138px"
          height="40px"
          isReversedColor={true}
          handleButton={handleNo}
        />
      </StyledBottomButtonContainer>
    </>
  )
}