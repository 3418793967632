import get from "lodash/get";
import { createSelector } from "reselect";

export const baseSubscriptionsSelector = (state) =>
  get(state, "subscriptions", {});

export const currenciesSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => get(subscriptions, "currency_options", {})
);

export const receiptSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => get(subscriptions, "receipt_data", {})
);

export const permissionsSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => get(subscriptions, "permissions", {})
);

export const pricesSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => get(subscriptions, "prices", {})
);

export const stripeDataSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => get(subscriptions, "stripeData", {})
);

export const stripeErrorSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => get(subscriptions, "stripe_error")
);

export const subscriptionSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => get(subscriptions, "subscription", {})
);
export const newCustomerSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => get(subscriptions, "new_customer", {})
);

export const subscriptionStatusSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => get(subscriptions, "subscription_status")
);

export const promoCodeSelector = createSelector(
  baseSubscriptionsSelector,
  (subscriptions) => get(subscriptions, "stripeData")
);
