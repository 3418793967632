import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { openProjects } from "../../../../api/prequalify";
import { AddProjectPopupT } from "../projectPopupT";

import { StyledQuestion, StyledBox, PlaceholderText } from "../styles";

import { ResultPanel } from "../bankPicklist/resultPanel";
import { FunctionalButton } from "components/functionalButton";
import { AddFinancialPopup } from "../bankPicklist/addPersonnelPopup";
import { EmptyPanel } from "../bankPicklist/emptyPanel";
import SelectedProjects from "../bankPicklist/components/selectedProjects";
import { getRfpq, getRfpqs } from "api/rfpq";

export const PickProject = ({
  setActiveStep,
  prequalifyData,
  setPrequalifyData,
  setIsLastPanel,
  setPreviousStepPanel,
  setPanel,
  handleNext,
  rfpq,
  setRfpq,
  institutionId,
}) => {
  setIsLastPanel(true);
  setPreviousStepPanel(1);

  const isMobile = useMediaQuery({ maxWidth: 960 });

  const [object, setObject] = useState(
    prequalifyData?.project_info?.project_title
      ? prequalifyData?.project_info
      : ""
  );

  const [list, setList] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [pickedArray, setPickedArray] = useState([]);
  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    setObject(
      prequalifyData?.project_info?.project_title
        ? prequalifyData?.project_info
        : ""
    );
  }, [prequalifyData]);

  useEffect(() => {
    setPrequalifyData("project_info", object);
    setPickedArray([]);
  }, [object]);

  const handleGetSingleRfpq = async (rfpq_id) => {
    if (rfpq_id) {
      await getRfpq({ rfpq_id: rfpq_id, institution_id: institutionId })
        .then((data) => {
          setRfpq(data);
        })
        .catch((err) => {
          console.log("Something Wrong", err);
        });
    }
  };

  useEffect(() => {
    handleRfpqProject();
  }, []);

  const handleRfpqProject = async () => {
    await getRfpqs({ institution_id: institutionId })
      .then((data) => {
        let temp = [...data.invited, ...data.public];
        temp.sort((value1, value2) =>
          value1.created_on > value2.created_on ? 1 : -1
        );

        setFilteredData(temp);
      })
      .catch((err) => {
        // TODO better error handling
        console.log("Something wrong", err);
      });
  };


  const handlePickProject = (props) => {
    const project_info = {
      ...prequalifyData.project_info,
      project_title: props?.project_title,
      project_number: props?.project_number,
      project_city: props?.project_location,
      owner_name: props?.owner_name,
      owner_email: props?.owner_email,
      owner_phone: props?.owner_phone,
      owner_suite: props?.owner_suite,
      owner_postal_code: props?.owner_postal_code,
      owner_province: props?.owner_province,
      owner_city: props?.owner_city,
      categories: props?.categories,
      initialSubCategory: []
    };
    setPrequalifyData("project_info", project_info);
  };

  return (
    <>
      <StyledQuestion marginBottom="32px">
        {"Which Project are you Qualifying for?"}
      </StyledQuestion>
      {!object && (
        <StyledBox boxWidth="844px">
          <PlaceholderText>Selected Projects will appear here</PlaceholderText>
        </StyledBox>
      )}
      {object && (
        <SelectedProjects
          project={true}
          object={object}
          setObject={setObject}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          pickedArray={pickedArray}
          setPickedArray={setPickedArray}
          setRerender={setRerender}
          handleGetSingleRfpq={handleGetSingleRfpq}
          setRfpq={setRfpq}
        />
      )}
      {filteredData?.length < 1 && (
        <EmptyPanel
          object={object}
          setObject={setObject}
          setOpenPopup={setOpenPopup}
        />
      )}
      {filteredData?.length > 0 && (
        <ResultPanel
          project={true}
          object={object}
          setObject={setObject}
          setOpenPopup={setOpenPopup}
          openPopup={openPopup}
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          filterApi={getRfpqs}
          pickedArray={pickedArray}
          rerender={rerender}
          handleGetSingleRfpq={handleGetSingleRfpq}
          disabled={object}
        />
      )}
      <FunctionalButton
        buttonTitle="Next"
        handleButton={() => handleNext()}
        width="200px"
        marginRight="auto"
        marginLeft="auto"
        marginTop="16px"
        disabled={!object}
      />
      <AddProjectPopupT
        // isMobile={isMobile}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        setData={setPrequalifyData}
        data={prequalifyData}
        handleButtonFunc={handlePickProject}
        handleNext={() => {
          //  setPopupSkip(true)
          handleNext();
        }}
      />
    </>
  );
};

export default PickProject;
