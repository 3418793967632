import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "../../assets/preQualifyIcons/deleteIcon";
import { ReactComponent as DownloadIcon } from "../../assets/common/downloadIcon.svg";
import {
  confirmFileUpload,
  createFile,
  updateCCDC_AdditionalFileBlob,
  uploadFileToGCP,
} from "api/file_managment";
import { attachRfpqFile } from "../../data/actions/buyers";
import { COLORS } from "../../constants";
import React, { useState } from "react";
import { useEffect } from "react";
import FilesListTable from "../filesListTable";
import { StyledTableCell } from "../filesListTable/styles";
import { StyledTableCellFirstUploadedFile, StyleDisplay } from "./styles";
import handleFileDownload from "common/downloadFile";

export const WrapperForUploadedFileDisplay = ({
  file,
  deleteFile,
  displayPercentage,
  index,
  marginTop,
  noActions,
  handleClickRow,
  ccdcId,
  readOnly,
  rfpqId,
  setFiles,
  setOpenPopup,
  openPopup,
  files,
}) => {
  const dispatch = useDispatch();
  let currentAdditionalDocArray = [...files];
  const [openProgress, setOpenProgress] = useState(false);

  let uploadFileName = file.name;
  let extension = uploadFileName.split(".").pop();
  let fileName = uploadFileName.replace("." + extension, "");

  let dataForFirstApi = {
    file_type: "Institution_file",
    file_extension: extension,
    file_name: fileName,
  };

  useEffect(() => {
    // if (isNaN(file.file_id)) {
    //   setOpenProgress(true);
    //   createFile(
    //     dataForFirstApi,
    //     async (data) => {
    //       //#region upload file using upload url
    //       try {
    //         await uploadFileToGCP(data.upload_url, file, {
    //           headers: {
    //             "Content-Type": file.content_type,
    //             "Access-Control-Allow-Origin": "*",
    //           },
    //         });
    //       } catch (e) {
    //         console.log("CORS ERROR");
    //       }
    //       //#endregion
    //       //#region confirm file is uploaded or not
    //       await confirmFileUpload(
    //         { file_id: data.id },
    //         async () => {
    //           //#region save file id to ccdc table in additional_file column
    //           if (ccdcId) {
    //             await updateCCDC_AdditionalFileBlob({
    //               ccdcId,
    //               file_id: data.id,
    //             });
    //           } else if (rfpqId) {
    //             await dispatch(
    //               attachRfpqFile({ rfpq_id: rfpqId, file_id: data.id })
    //             );
    //           }
    //           currentAdditionalDocArray[index].id = data.id;
    //           setFiles(currentAdditionalDocArray);
    //           //#endregion
    //           //TODO: Toast a message
    //           setOpenProgress(false);
    //         },
    //         () => {
    //           //Removing file from the state
    //           currentAdditionalDocArray.splice(index, 1);
    //           setFiles(currentAdditionalDocArray);
    //           //set Alert popup if file size limit reached
    //           setOpenPopup(true);
    //         }
    //       );
    //       //#endregion
    //     },
    //     () => {
    //       //Removing file from the state
    //       currentAdditionalDocArray.splice(index, 1);
    //       setFiles(currentAdditionalDocArray);
    //       //set Alert popup if file size limit reached
    //       setOpenPopup(true);
    //     }
    //   );
    // }
  }, []);

  return (
    <UploadedFileDisplay
      file={file}
      deleteFile={deleteFile}
      displayPercentage={displayPercentage}
      index={index}
      marginTop={marginTop}
      noActions={noActions}
      handleClickRow={handleClickRow}
      openProgress={openProgress}
      readOnly={readOnly}
    ></UploadedFileDisplay>
  );
};

export const UploadedFileDisplay = ({
  file,
  deleteFile,
  displayPercentage,
  index,
  marginTop,
  noActions,
  handleClickRow,
  openProgress,
  readOnly,
}) => {
  const actions = (props) => {
    return (
      <>
        <StyledTableCell component={"tr"} align="right">
          {openProgress && (
            <CircularProgress
              style={{
                height: "20px",
                width: "20px",
                color: COLORS.primaryOrange,
              }}
              disableShrink
            />
          )}
          {!openProgress && !readOnly && (
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={() => deleteFile(index)}
            >
              <DeleteIcon />
            </IconButton>
          )}
          {readOnly && (
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={() =>
                handleFileDownload(file?.file_id, props?.file_name)
              }
            >
              <DownloadIcon />
            </IconButton>
          )}
        </StyledTableCell>
      </>
    );
  };

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCellFirstUploadedFile component={"tr"}>
          {bodyData.file_name}
        </StyledTableCellFirstUploadedFile>
        <StyledTableCell component={"tr"} scope="row" align="right" width="20%">
          {bodyData.file_size}
        </StyledTableCell>
      </>
    );
  };

  const bytesToMegaBytes = (bytes, roundTo) => {
    const sizeMB = roundTo
      ? (bytes / (1024 * 1024)).toFixed(roundTo)
      : bytes / (1024 * 1024);
    return sizeMB + " mb";
  };

  const uploadedFileDetails = [
    {
      file_name: file?.name + "." + file?.extension,
      file_size: file?.size ? bytesToMegaBytes(file?.size, 1) : "",
    },
  ];

  return (
    <StyleDisplay
      displayPercentage={displayPercentage}
      marginTop={index === 0 ? "10px" : 0}
    >
      <FilesListTable
        data={uploadedFileDetails}
        body={body}
        actions={actions}
        noActions={noActions}
        actionType="file"
        concise
        borderSpacing="0 4px !important"
        handleClickRow={handleClickRow}
      />
    </StyleDisplay>
  );
};
