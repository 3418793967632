import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FunctionalButton } from 'components/functionalButton';

import {
  ImagePopup,
  LargeText,
  SmallText,
  ButtonArea,
  PopupContainer,
} from './styles';
import { Popup } from 'components/popup';
import Cone from "assets/onBoarding/Cone";
import { CloseButton } from "components/closeButton";

export const TimeoutPopup = ({
  openPopup,
  setOpenPopup,
  isMobile
}) => {
  const history = useHistory();

  const handleDone = () => {
    setOpenPopup(false);
  };

  const handleLogin = () => {
    history.push("/account/login");
  }
  return (
    <Popup
        open={openPopup}
        setOpen={setOpenPopup}
        width={isMobile ? "100%" : "550px"}
        height={isMobile ? "390px" : "480px"}
        dialogContent={(
          <>
            <PopupContainer>
              {/* <CloseButton handleClose={handleDone} /> */}
              <ImagePopup>
                <Cone isMobile={isMobile} />
              </ImagePopup>
              <LargeText isMobile={isMobile}>Your session has timed out!</LargeText>
              <SmallText>
                You must log in to continue using application.
              </SmallText>
            </PopupContainer>
            <ButtonArea isMobile={isMobile}>
              <FunctionalButton
                buttonTitle="LOGIN"
                width="90%"
                height={isMobile ? "48px" : "53px"}
                marginRight="0px"
                handleButton={handleLogin}
              />
            </ButtonArea>
        </>
        )}
      />

  )
}
