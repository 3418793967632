import React, { useState, useEffect } from "react";
import menuIcon from "../../assets/dashboardIcons/sidebar-icon-2.svg";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../data/selectors/user";

import {
  HeaderContainer,
  Logo,
  IconArea,
  ToggleText,
  ToggleDiv,
} from "./styles";
import logo_dark from "../../assets/common/full-logo.svg";

import { ProfileMenu } from "./ProfileMenu";
import { useHistory } from "react-router-dom";
import { CommonToggle } from "components/commonToggle";
import { viewSelector } from "data/selectors/contact";
import { getView } from "data/actions/contact";

function DashboardHeader() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const history = useHistory();
  const dispatch = useDispatch();
  let location = useLocation();
  const path = location.pathname;
  const view = useSelector(viewSelector);
  const currentUrl = localStorage.getItem("current_agent_dashboard")
    ? localStorage.getItem("current_agent_dashboard")
    : localStorage.getItem("current_dashboard_url");
  const [state, setState] = useState({ logged_in: false });
  const current_user = useSelector(userSelector);

  useEffect(() => {
    handleUserData();
  }, [current_user]);
  function handleUserData() {
    if (
      current_user &&
      Object.keys(current_user).length > 0 &&
      !state.logged_in
    ) {
      var newUserData = state;
      newUserData.logged_in = true;
      // handle user subscribed logic
      setState({
        ...state,
        newUserData,
      });
    } else {
      setState({
        ...state,
        logged_in: false,
      });
    }
  }

  const handleSwitch = (checked) => {
    dispatch(getView(checked ? "buyer" : "contractor"));
  };

  return (
    <HeaderContainer isMobile={isMobile}>
      {isMobile && (
        <>
          <IconArea
            isMobile={isMobile}
            onClick={() => {
              history.push(currentUrl || "/");
            }}
          >
            <img src={menuIcon} />
          </IconArea>
          <Link to="/">
            <Logo src={logo_dark} />
          </Link>
        </>
      )}
      {!isMobile && (
        <>
          <IconArea
            style={{ color: "#fffff", flexGrow: 5, maxWidth: 100 }}
            onClick={() => {
              history.push(currentUrl || "/");
            }}
          >
            <img src={menuIcon} />
          </IconArea>
          <Link
            to={currentUrl || "/"}
            style={{ flexGrow: 10, maxWidth: 200, marginLeft: "-10px" }}
          >
            <Logo src={logo_dark} />
          </Link>
          <div style={{ flexGrow: 10 }}></div>
        </>
      )}
      {/* {path === "/dashboard" && (
        <ToggleDiv>
          <ToggleText>Contractor</ToggleText>
          <CommonToggle 
            handleSwitch = {(e) => handleSwitch(e.target.checked)}
            checked={view == "buyer"}
          />
          <ToggleText>Buyer</ToggleText>
        </ToggleDiv>
      )} */}

      {localStorage.getItem("Authorization") && (
        <>
          <ProfileMenu isMobile={isMobile} />
        </>
      )}
    </HeaderContainer>
  );
}

export default DashboardHeader;
