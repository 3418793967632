import React, { Fragment } from "react";
import { Text, View, StyleSheet, Line } from "@react-pdf/renderer";
import { numberWithCommas } from "common/numberFormat";
import { convertPhoneNumberCanadaUSA, convertNumberToRoman } from "common/regex";
import { formatDate } from "common/dateFormat";

const borderColor = "#EBE9F1";
const styles = StyleSheet.create({
  titleTextContainer: {
    marginTop: 10,
    justifyContent: "flex-start",
    width: "100%",
  },
  headerContainer1: {
    marginTop: 0,
    justifyContent: "flex-start",
    width: "60%",
  },
  headerContainer2: {
    marginTop: -15,
    justifyContent: "flex-end",
    width: "40%",
    flex: 1,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: "#F8F8F8",
    borderBottomWidth: 0,
    alignItems: "left",
    fontStyle: "normal",
    width: 537,
    borderRadius: 0,
    paddingLeft: 10,
    color: "#000000",
    fontWeight: 400,
    fontSize: 10,
    lineHeight: 1.5,
    paddingTop: 5,
  },
  headerContainer3: {
    marginTop: 5,
    justifyContent: "flex-start",
    width: "59%",
    paddingRight: 10,
  },
  headerContainer4: {
    marginTop: 5,
    width: "41%",
    flex: 1,
    paddingLeft: 0,
  },

  headerContainer5: {
    width: "60%",
  },
  headerContainer6: {
    width: "40%",
  },
  spanText: {
    color: "#888888",
  },
  spanText2: {
    color: "#888888",
    marginLeft: 5,
  },
  remarks: {
    color: "#888888",
    position: "fixed",
  },
  lineDivider: {
    marginTop: 0,
    height: 1,
    backgroundColor: "#EBE9F1",
    marginLeft: 10,
    marginRight: 10,
  },
  viewContainer: {
    borderRadius: 6,
    backgroundColor: "#F8F8F8",
    width: 537,
    paddingTop: 3,
    paddingBottom: 3,
  },
});

const getTruncatedContent = (content, maxLength) => {
  return content.length > maxLength
    ? content.substring(0, maxLength) + "..."
    : content;
};

const PageFiveKeyProjectsDetails = ({ items }) => {
  const rows = items?.map((item) => (
    <View>
      <Text style={styles.titleTextContainer}>
        <span style={styles.spanText}>Title: </span>
        {item?.project_name || ""}
      </Text>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View style={styles.headerContainer1}>
          <Text>
            <span style={styles.spanText}>Number: </span>
            {item?.project_number || ""}
          </Text>
          <Text>
            <span style={styles.spanText}>Location: </span>
            {item?.project_location || ""}
          </Text>
        </View>
        <View style={styles.headerContainer2}>
          <Text>
            <span style={styles.spanText}> </span>
          </Text>
          <Text>
            <span style={styles.spanText}>Manager: </span>
            {item?.manager || ""}
          </Text>
          <Text>
            <span style={styles.spanText}>Superintendent: </span>
            {item?.superintendent || ""}
          </Text>
        </View>
      </View>

      <View style={styles.viewContainer}>
        <View style={styles.row} key={item?.id.toString()}>
          <View style={styles.headerContainer3}>
            <Text>
              <span style={styles.spanText}>Project Owner: </span>
              {item?.owner || ""}
            </Text>
            <Text>
              <span style={styles.spanText}>Contact Person: </span>
              {item?.owner_contact || ""}
            </Text>
          </View>

          <View style={styles.headerContainer4}>
            <Text>
              <span style={styles.spanText}>Email: </span>
              {item?.owner_email || ""}
            </Text>
            <Text>
              <span style={styles.spanText}>Phone Number: </span>
              {convertPhoneNumberCanadaUSA(item?.owner_phone) || ""}
            </Text>
          </View>
        </View>

        <Line style={styles.lineDivider} vertical={false} />

        {(item?.consultant?.trim() !== "" ||
          item?.consultant_contact?.trim() !== "" ||
          item?.consultant_email?.trim() !== "" ||
          item?.consultant_phone?.trim() !== "") && (
            <View style={styles.row} key={item?.id.toString()}>
              <View style={styles.headerContainer3}>
                <Text>
                  <span style={styles.spanText}>Project Consultant: </span>
                  {item?.consultant || ""}
                </Text>
                <Text>
                  <span style={styles.spanText}>Contact Person: </span>
                  {item?.consultant_contact || ""}
                </Text>
              </View>

              <View style={styles.headerContainer4}>
                <Text>
                  <span style={styles.spanText}>Email: </span>
                  {item?.consultant_email || ""}
                </Text>
                <Text>
                  <span style={styles.spanText}>Phone Number: </span>
                  {convertPhoneNumberCanadaUSA(item?.consultant_phone) || ""}
                </Text>
              </View>
            </View>
          )}

        {(item?.consultant?.trim() !== "" ||
          item?.consultant_contact?.trim() !== "" ||
          item?.consultant_email?.trim() !== "" ||
          item?.consultant_phone?.trim() !== "") && (
            <Line style={styles.lineDivider} vertical={false} />
          )}

        {(item?.other?.trim() !== "" ||
          item?.other_contact?.trim() !== "" ||
          item?.other_email?.trim() !== "" ||
          item?.other_phone?.trim() !== "") && (
            <View style={styles.row} key={item?.id.toString()}>
              <View style={styles.headerContainer3}>
                <Text>
                  <span style={styles.spanText}>Other Party: </span>
                  {item?.other || ""}
                </Text>
                <Text>
                  <span style={styles.spanText}>Contact Person: </span>
                  {item?.other_contact || ""}
                </Text>
              </View>

              <View style={styles.headerContainer4}>
                <Text>
                  <span style={styles.spanText}>Email: </span>
                  {item?.other_email || ""}
                </Text>
                <Text>
                  <span style={styles.spanText}>Phone Number: </span>
                  {convertPhoneNumberCanadaUSA(item?.other_phone) || ""}
                </Text>
              </View>
            </View>
          )}

        {(item?.other?.trim() !== "" ||
          item?.other_contact?.trim() !== "" ||
          item?.other_email?.trim() !== "" ||
          item?.other_phone?.trim() !== "") && (
            <Line style={styles.lineDivider} vertical={false} />
          )}

        <View style={styles.row}>
          <View style={styles.headerContainer3}>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={styles.headerContainer5}>
                <Text>
                  <span style={styles.spanText}>
                    Date Substantially Completed:{" "}
                  </span>
                </Text>
                <Text>
                  {formatDate(item?.substantial_date)}
                  {/* {item?.substantial_date ? item?.substantial_date : " "} */}
                </Text>
              </View>

              <View style={styles.headerContainer6}>
                <Text>
                  <span style={styles.spanText}>Date Completed: </span>
                </Text>
                <Text>
                  {formatDate(item?.completed_date)}
                  {/* {item?.completed_date ? item?.completed_date : " "} */}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.headerContainer4}>
            <Text>
              <span style={styles.spanText}>Value at Completion: </span>
            </Text>
            <Text>${item?.value ? numberWithCommas(item?.value) : " "}</Text>
          </View>
        </View>

        <Line style={styles.lineDivider} vertical={false} />

        <View style={styles.row}>
          <View style={styles.headerContainer3}>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View style={styles.headerContainer5}>
                <Text>
                  <span style={styles.spanText}>Contract Type: </span>
                </Text>
                <Text>{item?.contract_type ? item?.contract_type : " "}</Text>
              </View>

              <View style={styles.headerContainer6}>
                <Text>
                  <span style={styles.spanText}>Category/Family: </span>
                </Text>
                <Text>
                  {item?.categories?.map((item, index) => (
                    <Text key={index}>
                      {convertNumberToRoman(index + 1)}. {item?.main_categories ? `${item?.main_categories}\n` : " \n"}
                    </Text>
                  ))}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.headerContainer4}>
            <Text>
              <span style={styles.spanText}>Sub Categories/Class: </span>
            </Text>
            <Text>
              {item?.categories?.map((item, index) => (
                <Text key={index}>
                 {convertNumberToRoman(index + 1)}. {item?.sub_categories ? `${item?.sub_categories}\n` : " \n"}
                </Text>
              ))}
            </Text>
          </View>
        </View>

        <Line style={styles.lineDivider} vertical={false} />

        <View style={styles.row}>
          <View style={styles.headerContainer3}>
            <Text>
              <span style={styles.spanText}>Description: </span>
            </Text>
            <Text style={{ lineHeight: 1.1 }}>
              {item?.description ? item?.description : " "}
            </Text>
          </View>

          <View style={styles.headerContainer4}>
            <Text>
              <span style={styles.remarks}>Remarks: </span>
            </Text>
            <Text style={{ paddingRight: 2, lineHeight: 1.1 }}>
              {item?.remark ? item?.remark : " "}
            </Text>
          </View>
        </View>
      </View>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default PageFiveKeyProjectsDetails;
