import styled from "styled-components";
import { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';
import { COLORS } from "../../constants";

const grey = {
  400: '#BFC7CF',
  500: '#AAB4BE',
  600: '#6F7E8C',
  };
  
export const Root = styled('span')(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin: 10px;
  cursor: pointer;
  
  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }
  
  & .${switchUnstyledClasses.track} {
    background: #FFDECC;
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  
  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 18px;
    height: 18px;
    top: 1px;
    left: 3px;
    border-radius: 16px;
    background: linear-gradient(225deg, #FFAE82 0%, #FF6D1D 100%);
    position: relative;
    transition: all 200ms ease;
  }
  
  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: ${grey[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }
  
  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
    left: 22px;
    top: 1px;
    background: linear-gradient(225deg, #FFAE82 0%, #FF6D1D 100%);
    }
  
    .${switchUnstyledClasses.track} {
    background: #FBFAFF;
    box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.1);
    }
  }
  
  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
  `,
);


  //flex-direction: ${(props) => (props.isMobile ? "column" : "row")};