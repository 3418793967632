import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { FunctionalButton } from "../../../components/functionalButton";

import {
  StyledQuestion,
  InfoArea,
  StyledSkipText,
  TextContainer,
} from "./styles";

import { PanelUploadStatement } from "./templatePanel/PanelUploadStatement";
import { PanelPrevStatement } from "./templatePanel/PanelPrevStatement";
import { ValidateOwner } from "./ownerPartners/validateOwner";
import { FillOwner } from "./ownerPartners/fillOwner";
import { ValidateAddress } from "./ownerPartners/validateAddress";
import { FillAddress } from "./ownerPartners/fillAddress";
import { CompanyDetails } from "./ownerPartners/companyDetails";
import { OfficerPartners } from "./officerPartners";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { isEmpty } from "lodash";

export const OwnerPartners = ({
  handleNext,
  prequalifyData,
  setPrequalifyData,
  panel,
  setPanel,
  setIsLastPanel,
  setPreviousStepPanel,
  popupSkip,
  setIsBranchPanel,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 960 });

  const [selected, setSelected] = useState(false);
  const [hasCategories, setHasCategories] = useState("");
  const [checkFill, setCheckFill] = useState(false);
  const [manualOwner, setManualOwner] = useState(prequalifyData?.project_info);
  const [empty, setEmpty] = useState(true);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    const projectInfo = prequalifyData?.project_info;
    setHasCategories(
      projectInfo && projectInfo?.categories?.main_categories?.length > 0
        ? true
        : false
    );

    if (!isEmpty(prequalifyData?.project_info?.rfpq_id?.toString())) {
      setReadOnly(true);
    }
  });

  // useEffect(() => {
  //   if(empty){
  //     setEmpty(false);
  //     setPrequalifyData('legal_structure.personnel', '');
  //   }
  //   if( !popupSkip && panel === 1 && prequalifyData?.project_info?.owner_name === '') {
  //     setPanel(2);
  //   } else if(!popupSkip && panel === 3 && (!prequalifyData?.project_info?.owner_address ||
  //     !prequalifyData?.project_info?.owner_city ||
  //     !prequalifyData?.project_info?.owner_postal_code ||
  //     !prequalifyData?.project_info?.owner_province )) {
  //       setPanel(4);
  //   }

  //   if(panel === 2 && !checkFill){
  //     setPrequalifyData('project_info.owner_name', '');
  //     setPrequalifyData('project_info.owner_email', '');
  //     setCheckFill(true);
  //   } else if(popupSkip && panel === 1 && !checkFill){
  //     setPrequalifyData('project_info.owner_name', '');
  //     setPrequalifyData('project_info.owner_email', '');
  //     setCheckFill(true);
  //   }
  // },[panel]);

  return (
    <>
      {!popupSkip && (
        <Switch>
          {/* { panel == 1 && (
        <>
          <ValidateOwner
            prequalifyData={prequalifyData}
            setPrequalifyData={setPrequalifyData}
            handleNext={handleNext}
            setIsLastPanel={setIsLastPanel}
            setPanel={setPanel}
            panel={panel}
            setPreviousStepPanel={setPreviousStepPanel}
            setIsBranchPanel={setIsBranchPanel}
          />
        </>
      )}
      { panel == 2  && (
        <>
          <FillOwner
            prequalifyData={prequalifyData}
            setPrequalifyData={setPrequalifyData}
            handleNext={handleNext}
            setIsLastPanel={setIsLastPanel}
            setPanel={setPanel}
            panel={panel}
            setPreviousStepPanel={setPreviousStepPanel}
            setSelected={setSelected}
            setIsBranchPanel={setIsBranchPanel}
          />
        </>
      )}
      { panel == 3 && (
        <>
          <ValidateAddress
            prequalifyData={prequalifyData}
            setPrequalifyData={setPrequalifyData}
            handleNext={handleNext}
            setIsLastPanel={setIsLastPanel}
            setPanel={setPanel}
            panel={panel}
            setPreviousStepPanel={setPreviousStepPanel}
            setSelected={setSelected}
            setIsBranchPanel={setIsBranchPanel}
          />
        </>
      )} */}
          <Route exact path="/prequalify/info/owner">
            <FillAddress
              prequalifyData={prequalifyData}
              setPrequalifyData={setPrequalifyData}
              handleNext={handleNext}
              setIsLastPanel={setIsLastPanel}
              setPanel={setPanel}
              panel={panel}
              setPreviousStepPanel={setPreviousStepPanel}
              setSelected={setSelected}
              readOnly={readOnly}
            />
          </Route>
          {/* { panel == 5 && (
        <>
          <CompanyDetails
            prequalifyData={prequalifyData}
            setPrequalifyData={setPrequalifyData}
            handleNext={handleNext}
            setIsLastPanel={setIsLastPanel}
            setPanel={setPanel}
            panel={panel}
            setPreviousStepPanel={setPreviousStepPanel}
            setSelected={setSelected}
          />
        </>
      )} */}
          {/* <Route exact path="/prequalify/info/legal">
        <OfficerPartners
          prequalifyData={prequalifyData}
          setPrequalifyData={setPrequalifyData}
          handleNext={handleNext}
          setIsLastPanel={setIsLastPanel}
          setPanel={setPanel}
          panel={panel}
          setPreviousStepPanel={setPreviousStepPanel}
          setSelected={setSelected}
        />
      </Route> */}
        </Switch>
      )}
      {popupSkip && (
        <>
          {/* { panel == 1  && (
          <FillOwner
            prequalifyData={prequalifyData}
            setPrequalifyData={setPrequalifyData}
            handleNext={handleNext}
            setIsLastPanel={setIsLastPanel}
            setPanel={setPanel}
            panel={panel}
            setPreviousStepPanel={setPreviousStepPanel}
            setSelected={setSelected}
            setIsBranchPanel={setIsBranchPanel}
          />
      )} */}
          {panel == 1 && (
            <>
              <FillAddress
                prequalifyData={prequalifyData}
                setPrequalifyData={setPrequalifyData}
                handleNext={handleNext}
                setIsLastPanel={setIsLastPanel}
                setPanel={setPanel}
                panel={panel}
                setPreviousStepPanel={setPreviousStepPanel}
                setSelected={setSelected}
                readOnly={readOnly}
              />
            </>
          )}
          {/* { panel == 5 && (
        <>
          <CompanyDetails
            prequalifyData={prequalifyData}
            setPrequalifyData={setPrequalifyData}
            handleNext={handleNext}
            setIsLastPanel={setIsLastPanel}
            setPanel={setPanel}
            panel={panel}
            setPreviousStepPanel={setPreviousStepPanel}
            setSelected={setSelected}
            setIsBranchPanel={setIsBranchPanel}
          />
        </>
      )} */}
          {/* { panel == 5 && (
        <>
          <OfficerPartners
            prequalifyData={prequalifyData}
            setPrequalifyData={setPrequalifyData}
            handleNext={handleNext}
            setIsLastPanel={setIsLastPanel}
            setPanel={setPanel}
            panel={panel}
            setPreviousStepPanel={setPreviousStepPanel}
            setSelected={setSelected}
          />
        </>
      )} */}
        </>
      )}
    </>
  );
};
