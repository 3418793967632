import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from "../../constants";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabPanel from "@material-ui/lab/TabPanel";
import Typography from "@material-ui/core/Typography";
import TabList from "@mui/lab/TabList";
import Divider from "@material-ui/core/Divider";
import { StyledTableCell } from "../../components/filesListTable/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import { Avatar } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";

export const ProfilePicture = styled(VisibilityIcon)`
  && {
    width: ${(props) => (props.isMobile ? "120px" : "100px")};
    height: ${(props) => (props.isMobile ? "120px" : "40px")};
  }
`;

export const StyledFileName = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: #b8b8b8;
  margin-top: 1px;
  margin-bottom: 5px;
  background: #ffffff;
  border: 1px solid #ebe9f1;
  border-radius: 6px;
  margin-left: 12px;
`;

export const StyledUploadIcon = styled.label`
  margin: ${(props) => props.margin || 0};
  cursor: pointer;
`;

export const InputLabel = styled.div`
  margin-bottom: 5px;
  margin-top: 25px;
  margin-left: 0;
  margin-right: auto;
  font-style: normal;
  font-weight: ${(props) => props.labelWeight || "normal"};
  font-size: ${(props) => props.labelSize || "12px"};
  line-height: 20px;
  color: #000000;
`;

export const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

export const ErrorText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: ${COLORS.primaryRed};
    margin-top: 4px;
  }
`;

export const LargeTextSuccess = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${(props) => (props.isMobile ? "22px" : "28px")};
    font-style: normal;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 24px;
  }
`;

export const SmallText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.secondaryFont};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 12px;
  }
`;

export const ImagePopup = styled.div`
  margin-top: 16px;
`;

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isMobile ? "90%" : "100%")};
  margin-top: 0px;
  justify-content: center;
  align-items: center;
`;

export const ButtonArea = styled.div`
  margin-top: 40px;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;

export const FieldArea = styled.div`
  ${(props) =>
    props.marginLeft && !props.isMobile
      ? `margin-left: ${props.marginLeft};`
      : ""}
  margin-top: ${(props) => props.marginTop || "24px"};
  display: flex;
  flex-direction: ${(props) => (props.isColumn ? "column" : "row")};
  align-items: center;
  flex-wrap: wrap;
  ${(props) => (props.noJustifyContent ? "" : "justify-content: center;")}
  ${(props) =>
    props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}

  .last {
    padding-right: 0px !important;
  }

  .inputWrap {
    padding-right: ${(props) => (props.isMobile ? "0px" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "50%")};
  }

  .fullWidthInputWrap {
    width: 100%;
  }

  .inputWraphalf {
    width: 50%;
  }

  .oneFifthInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0px" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "20%")};
  }

  .thirtyInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "30%")};
  }

  .oneThirdInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "33.33%")};
  }

  .twoThirdInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "66.66%")};
  }

  .oneFifthMobile {
    padding-right: 16px;
    width: ${(props) => (props.isMobile ? "50%" : "20%")};
  }

  .thirtyMobile {
    width: ${(props) => (props.isMobile ? "50%" : "30%")};
  }
`;

export const SectionText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    width: 100%;
    margin-top: ${(props) => props.marginTop || "16px"};
    color: ${COLORS.darkGrey1};
  }
`;

export const StyledRow = styled.div`
  display: flex;
  width: 100%;
  ${(props) => (props.margin ? `margin: ${props.margin};` : "")}
  ${(props) =>
    props.justifyContent ? `justify-content: ${props.justifyContent};` : ""}
  flex-direction: ${(props) =>
    props.isMobile || props.isColumn ? "column" : "row"};
`;

export const StyledTableCellFirst = styled(StyledTableCell)`
  width: ${(props) => (props.isMobile ? "70%" : "50%")};
`;

export const StyledTableCellSecond = styled(StyledTableCell)`
  width: 30%;
`;

export const ListContainer = styled.div`
  width: 100%;
  margin-top: -24px;
`;

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}));

export const StyledRadioLabel = styled(FormControlLabel)`
  && {
    margin-top: 16px;
    margin-left: 0px;
    margin-right: 14px;

    .MuiTypography-body1 {
      font-size: 12px;
      font-weight: 500;
    }

    .MuiIconButton-label {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
`;

export const SetupContainer = styled.div`
  ${(props) => (!props.isMobile ? "margin-left: 110px" : "")};
  padding-bottom: 32px;
  position: relative;
  z-index=0;
`;

export const TabBox = styled(Box)`
  && {
    width: 100%;
    margin-top: 36px;
    display: flex;
    padding-left: 16px;
  }
`;

export const StyledText = styled.div`
  && {
    margin: 0 0 0 16px;
    padding-bottom: 16px;
    font-size: 18px;
    font-weight: 700;
    color: ${COLORS.primaryOrange};
    text-align: center;
    font-family: ${TYPOGRAPHY.primaryFont};
  }
`;

export const StyledTab = styled(Tab)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 18px;
    font-weight: 500;
    text-transform: none;
    color: ${COLORS.blurOutText};
    padding-right: 8px;
    padding-left: 8px;
    border: 1px solid #DAE0E7;
	  background: #F9F9F9;
    border-radius:  10px 10px 0px 0px;
    transition: all .1s;
    cursor: pointer;
    user-select: none;
  }
  &:hover{
    color: ${COLORS.primaryOrange};
  }
  & .Mui-selected: {
    color: "red",
    fontSize: "20px"
   }
`;

export const StyledTabList = styled(TabList)`
  && {
    color: "red";
    variant: "fullWidth";
  }
`;

export const StyledTabsContainer = styled(Tabs)``;

export const DividerLine = styled.div`
  width: 0.5px;
  height: 16px;
  left: 1243px;
  top: 158px;
  border: 1px solid ${COLORS.secondaryOrange};
  margin-top: 14px;
`;

export const StyledTabPanel = styled(TabPanel)`
  && {
    padding-top: 10px;
    padding-right: 10px;
  }
`;

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-left: 16px;
`;

export const IconContainer = styled.span`
  display: flex;
  align-items: center;
  margin-right: 9px;
`;

export const SeeAllText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: ${COLORS.blurOutText};
    cursor: pointer;
    margin-top: 16px;

    &:hover {
      color: ${COLORS.primaryOrange};
    }
  }
`;

export const StyledDivider = styled(Divider)`
  && {
    margin-right: 24px;
    margin-left: 24px;
  }
`;

export const StyledProjectSelectionContainer = styled.div`
  ${(props) => (props.isMobile ? "margin-top: 16px;" : "")}
`;

export const DetailContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

export const CardDiv = styled.div`
  width: ${(props) => props.width || "25%"};
  margin-right: 20px;
  ${(props) => (props.isMobile ? "flex: 0 0 auto;" : "")}
`;

export const PersonnelContent = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  margin: ${(props) => props.margin || "28px"};
  ${(props) =>
    !props.direction || props.direction == "row" ? "align-items: center" : ""};
  ${(props) => (props.height ? `height: ${props.height};` : "")}
  ${(props) =>
    props.justifyContent ? `justify-content: ${props.justifyContent};` : ""}
`;

export const DetailArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
`;

export const StyledSearchContainer = styled.div`
  display: inline-block;
  width: ${(props) => props.width || "60%"};
  margin-right: 20px;
`;

export const StyledSearch = styled.input`
  && {
    height: 48px;
    width: ${(props) => props.searchWidth || "860px"};
    font-family: Times New Roman;

    .prompt {
      border-radius: 5px;
      border-color: ${COLORS.darkGrey3};
    }

    &&:focus {
      border-radius: 5px;
      border-color: ${COLORS.darkGrey3};
    }
  }
`;

export const StyledSearchIcon = styled.img`
  position: absolute;
  margin-top: 12px;
  margin-left: 8px;
  height: 24px;
`;

export const StyledCancelIcon = styled.img`
  position: absolute;
  margin-top: 17px;
  margin-left: 830px;
  height: 16px;
`;

export const HeaderArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${(props) => props.width || "100%"};
`;

export const ProfilePic = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ProgressInCard = styled.div`
  margin-top: 16px;
  margin-right: -48px;
`;

export const TouchScrollDiv = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  margin-top: 20px;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const MobileColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MobileColumnText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${(props) => props.fontSize || "13px"};
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || "500"} !important;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: ${COLORS.columnGreyText};
    width: 100%;
  }
`;

export const SubHeading = styled(Typography)`
  && {
    font-family: SF Pro Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    color: ${COLORS.lightGrey2};
    margin-top: ${(props) => (props.isMobile ? "16px" : "-4px")};
    ${(props) => (props.isMobile ? "" : "margin-bottom: 8px;")}
  }
`;

export const StyledTableCellStatus = styled(StyledTableCell)`
  ${(props) => (props.isMobile ? "width: 20%;" : "")}
`;

export const StyledDownloadedContainer = styled.div`
  padding: 0 10px;
  background-color: ${COLORS.lightGreen};
  border-radius: 20px;
  color: ${COLORS.primaryGreen};
  text-align: center;
`;

export const StyledDraftContainer = styled.div`
  padding: 0 10px;
  background-color: ${COLORS.lightYellow};
  border-radius: 20px;
  color: ${COLORS.primaryYellow};
  text-align: center;
`;

export const StyledReviewedContainer = styled.div`
  padding: 0 10px;
  background-color: ${COLORS.lightBlue};
  border-radius: 20px;
  color: ${COLORS.primaryBlue};
  text-align: center;
`;

export const StyledUnderwayContainer = styled.div`
  padding: 0 10px;
  background-color: ${COLORS.lightYellow};
  border-radius: 20px;
  color: ${COLORS.primaryYellow};
  text-align: center;
`;

export const StyledCompletedContainer = styled.div`
  padding: 0 10px;
  background-color: ${COLORS.lightGreen};
  border-radius: 20px;
  color: ${COLORS.primaryGreen};
  text-align: center;
`;

export const FormContainer = styled.div`
  width: ${(props) => (props.isMobile ? "85%" : "85%")};
  ${(props) => (props.isMobile ? "align-self: center;" : "")}
  padding-bottom: 20px;
  margin-top: ${(props) => (props.isMobile ? "16px" : "24px")};
  display: flex;
  flex-direction: column;
  // align-items: center;
  // ${(props) => (props.leftBorder ? "margin-top: 50px;" : "")};
  border-left: ${(props) => (props.leftBorder ? "3px solid #EBE9F1" : "none")};
`;

export const DetailedFormContainer = styled(FormContainer)`
  && {
    width: ${(props) =>
      props.isMobile && !props.noWidthChange ? "90%" : "100%"};
  }
`;

export const LargeText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${(props) => (props.isMobile ? "18px" : "28px")};
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    margin-top: 16px;
    margin-bottom: 16px;
    color: #1a051d;
    text-align: center;
  }
`;

export const LargeReportText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${(props) => (props.isMobile ? "14px" : "20px")};
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    margin-top: 16px;
    margin-bottom: 16px;
    color: ${COLORS.lightGrey1};
    text-align: center;
    display: flex;
    flex-direction: row;
  }
`;

export const ReportText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${(props) => (props.isMobile ? "14px" : "24px")};
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    margin-top: 16px;
    margin-bottom: 16px;
    color: ${COLORS.primaryOrange};
    text-align: center;
  }
`;

export const ScreenRoot = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  ${(props) => (props.isMobile ? "position: relative;" : "")}
  width: 100%;
  max-width: 1440px;
  ${(props) => (props.isMobile ? "" : "align-content: center;")}
  ${(props) => (props.isMobile ? "" : "justify-content: center;")}
  ${(props) =>
    props.isMobile ? "padding: 70px 0 0 0;" : "padding: 70px 70px 0px 70px;"}
  background-color: ${COLORS.backgroundGrey};
`;

export const StyledArea = styled.div`
  width: 100%;
  display: flex;
  ${(props) => (props.isMobile ? "flex-wrap: wrap;" : "")}
  justify-content: ${(props) =>
    props.isMobile ? "space-around" : "space-between"};
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop};` : "")}
`;

export const StyledBox = styled.div`
  width: ${(props) => props.boxWidth || "100%"};
  ${(props) =>
    props.hasData
      ? ""
      : `border-radius: ${props.borderRadius || `20px`}; margin-bottom: 12px;`}
  ${(props) =>
    props.hasData ? "" : "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;"}
  height: ${(props) => props.boxHeight || "248px"};
  display: flex;
  flex-direction: ${(props) => props.flexDirection || "column"};
  ${(props) => (props.isMobile ? "align-items: center;" : "")}
  ${(props) => (props.margin ? `margin: ${props.margin};` : "")}
  ${(props) => (props.hasData ? "" : "justify-content: center;")}
  ${(props) => (props.noBackground ? "" : "background-color: white;")}
`;
