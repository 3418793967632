import React from "react";
import { FunctionalButton } from 'components/functionalButton';
import { sendReference } from 'api/reference';
import { Popup } from 'components/popup';
import { CloseButton } from "components/closeButton";
import QualityIcon from "assets/reference/quality";
import EfficientIcon from "assets/reference/efficient";
import OrganizedIcon from "assets/reference/organized";
import ServiceIcon from "assets/reference/service";
import AdministrationIcon from "assets/reference/administration";
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import Tooltip from "@mui/material/Tooltip";

import {
  DarkTitle,
  TitleDiv,
  TitleText,
  PopupContainer,
  PointText,
  ButtonDiv,
  StyledAwardsArea,
  StyledBoxContent
} from "./styles";
import { COLORS } from "../../../constants";

export const ConfirmPopup = ({
  openPopup,
  setOpenPopup,
  setOpenSuccessPopup,
  isMobile,
  data,
  companyName,
  token
}) => {

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      border: "1px solid black",
      background: "white"
    },
    '&:hover': {
      cursor: 'pointer',
    },
    marginRight: 12
  }));



  const handleDone = () => {
    setOpenPopup(false);
  };

  const handleSubmit = () => {
    sendReference(token, data,
      () => {
        setOpenPopup(false);
        setOpenSuccessPopup(true);
      },
      () => { }
    )
  }

  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width={isMobile ? "100%" : "640px"}
      height={isMobile ? "100vh" : "1000px"}
      dialogContent={(
        <>
          <CloseButton handleClose={handleDone} />
          <PopupContainer>
            <TitleDiv>
              <DarkTitle>Confirm your reference to</DarkTitle>
              <DarkTitle color={COLORS.primaryOrange}>{companyName}</DarkTitle>
            </TitleDiv>
            <TitleText fontSize="18px" fontWeight="600" color="black" margin="0px 0px 6px">{data?.project_name}</TitleText>
            <TitleText fontSize="18px">{data?.project_location}</TitleText>

            <TitleText fontSize="24px" fontWeight="500" color={COLORS.blurOutText} margin="8px 0px 16px">Rating</TitleText>
            {data?.assessment?.scale?.length > 0 &&
              data?.assessment?.scale.map((each) => {
                return (
                  <>
                    <TitleText fontSize="18px" fontWeight="400" color="black">{each.question}</TitleText>
                    {each.point === true &&
                      <TitleText fontSize="18px" fontWeight="500" color={COLORS.blurOutText}>N/A</TitleText>
                    }

                    {each.point !== true &&
                      <TitleText fontSize="24px" fontWeight="500" color={COLORS.blurOutText}><PointText>{each.point}</PointText> /10</TitleText>
                    }

                  </>

                )
              })
            }


            <TitleText fontSize="24px" fontWeight="500" color={COLORS.blurOutText} margin="8px 0px 16px">Award</TitleText>
            <StyledAwardsArea>
              <StyledBoxContent margin="16px 32px 24px">
                {data?.awards?.quality === 1 && (
                  <Tooltip
                    title="Quality"
                    placement="bottom-end"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: 11,
                          bgcolor: `${COLORS.primaryOrange}`,
                          "& .MuiTooltip-arrow": {
                            color: `${COLORS.primaryOrange}`,
                          },
                        },
                      },
                    }}
                  >
                    <StyledBadge overlap="circular">
                      <QualityIcon width={65} height={65} noGreyFilter={true} />
                    </StyledBadge>
                  </Tooltip>
                )}
                {data?.awards?.efficiency === 1 && (
                  <Tooltip
                    title="Efficiency"
                    placement="bottom-end"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: 11,
                          bgcolor: `${COLORS.primaryOrange}`,
                          "& .MuiTooltip-arrow": {
                            color: `${COLORS.primaryOrange}`,
                          },
                        },
                      },
                    }}
                  >
                    <StyledBadge overlap="circular">
                      <EfficientIcon width={65} height={65} noGreyFilter={true} />
                    </StyledBadge>
                  </Tooltip>
                )}
                {data?.awards?.organization === 1 && (
                  <Tooltip
                    title="Organization"
                    placement="bottom-end"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: 11,
                          bgcolor: `${COLORS.primaryOrange}`,
                          "& .MuiTooltip-arrow": {
                            color: `${COLORS.primaryOrange}`,
                          },
                        },
                      },
                    }}
                  >
                    <StyledBadge overlap="circular">
                      <OrganizedIcon width={65} height={65} noGreyFilter={true} />
                    </StyledBadge>
                  </Tooltip>
                )}
                {data?.awards?.service === 1 && (
                  <Tooltip
                    title="Service"
                    placement="bottom-end"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: 11,
                          bgcolor: `${COLORS.primaryOrange}`,
                          "& .MuiTooltip-arrow": {
                            color: `${COLORS.primaryOrange}`,
                          },
                        },
                      },
                    }}
                  >
                    <StyledBadge overlap="circular">
                      <ServiceIcon width={65} height={65} noGreyFilter={true} />
                    </StyledBadge>
                  </Tooltip>
                )}
                {data?.awards?.administration === 1 && (
                  <Tooltip
                    title="Administration"
                    placement="bottom-end"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: 11,
                          bgcolor: `${COLORS.primaryOrange}`,
                          "& .MuiTooltip-arrow": {
                            color: `${COLORS.primaryOrange}`,
                          },
                        },
                      },
                    }}
                  >
                    <StyledBadge overlap="circular">
                      <AdministrationIcon width={65} height={65} noGreyFilter={true} />
                    </StyledBadge>
                  </Tooltip>
                )}
              </StyledBoxContent>
            </StyledAwardsArea>



            <TitleText fontSize="24px" fontWeight="500" color={COLORS.blurOutText} margin="16px 0px">Comment</TitleText>
            <TitleText fontSize="14px" color="black" fontWeight="400">{data?.assessment?.comment}</TitleText>

            <ButtonDiv isMobile={isMobile}>
              <FunctionalButton
                buttonTitle="BACK TO FORM"
                handleButton={handleDone}
                isReversedColor={true}
              />
              <FunctionalButton
                buttonTitle={"CONFIRM & SUBMIT"}
                handleButton={handleSubmit}
              />
            </ButtonDiv>
          </PopupContainer>

        </>
      )}
    />

  )
}
