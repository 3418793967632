
import React from 'react';

const DropdownArrow = () => {
  return (
    <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.5 4.04702L8.34155 0.115616C8.49218 -0.0385385 8.7364 -0.0385385 8.88703 0.115616C9.03766 0.26977 9.03766 0.519703 8.88703 0.673858L4.77274 4.88438C4.62211 5.03854 4.37789 5.03854 4.22726 4.88438L0.112973 0.673858C-0.0376577 0.519703 -0.0376577 0.26977 0.112973 0.115616C0.263604 -0.0385385 0.507825 -0.0385385 0.658455 0.115616L4.5 4.04702Z" fill="black"/>
    </svg>
  )
};

export default DropdownArrow;


