import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PageTwoPersonnelTableRow from './pageTwoPersonnelTableRow';
import PageTwoPersonnelTableHeader from './pageTwoPersonnelTableHeader';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 10,
        justifyContent: 'flex-start',
        width: '80%',
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30,
    },
    personnel: {
        marginTop: -20,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
    },
    spanText: {
        color: '#888888',
    },
    spanTable: {
        marginTop: 5,
        paddingTop: 10,
        color: '#888888',
    },
    viewContainer: {
        borderRadius: 6,
        backgroundColor: '#F8F8F8',
        width: 537,
        paddingTop: 2,
        paddingBottom: 3,
    },
});

const PageTwoPersonnel = ({ prequalifyData }) => (
    <View style={styles.headerContainer}>
        <Text style={styles.personnel}>Personnel</Text>
        <View style={styles.viewContainer}>
            <PageTwoPersonnelTableHeader />
            <PageTwoPersonnelTableRow items={prequalifyData?.data?.keyPersonnels?.selectedData} />
        </View>
    </View>
);

export default PageTwoPersonnel;
