import React from 'react';
import additional from './financial_color.svg'

const Financial_color = ({ isMobile }) => {
  return (
    <img width={isMobile ? "36" : "56"} height={isMobile ? "36" : "56"} src={additional} />
  )
};

export default Financial_color;
