import React, { useState, useEffect } from "react";
import { YearsNumber } from "./YearsNumber";
import {
  StyledRow,
  StyledYear,
  FieldColumn,
  FieldWrap,
  LabelText,
  MobileYearText,
  StyledTextField,
} from "./styles";
import { MobileYearsNumber } from "./MobileYearsNumber";
import { FunctionalButton } from "components/functionalButton";
import { FieldArea } from "pages/contractorDashboard/profileSetup/styles";
import { RadioButton } from "components/radioButton";
import CheckedRadioButton from "assets/dashboardIcons/checkedRadioButton";
import UncheckedRadioButton from "assets/dashboardIcons/uncheckedRadioButton";
import {
  StyledCenter,
  StyledHealthSafetyPersonnelNone,
  SecondInnerRadioContainer,
  SecondRadioContainer,
  SectionText,
  StyledEditRecordButton,
} from "pages/newPreQualify/components/safetyRecordsUSA/styles.js";
import { StyledSubtext } from "pages/loginPage/styles";
import { ResultPanel } from "pages/contractorDashboard/profileSetup/resultPanel.js";
import Select from "react-select";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";
import DocumentPoolPopUp from "components/documentPoolPopUp";
import { WrapperForUploadedHealthSafetyFileDisplay } from "pages/preQualifyForm/components/uploadedHealthSafetyFileDisplay";
import ConciseTable from "components/filesListTable/conciseTable";
import { ScrollableDiv } from "pages/documentPool/styles";
import { AlertPopup } from "components/alertPopup/alertPopup";
import { useSelector } from "react-redux";
import { contactSelector } from "data/selectors/contact";
import { COLORS } from "../../../../constants";
import { institutionProfileInfoSelector } from "data/selectors/serviceProvider";

export const SafetyRecords = ({
  data,
  setData,
  isMobile,
  handleNext,
  institutionId,
  currentDashboardUrl,
}) => {
  const currentYear = new Date().getFullYear();
  const [safetyRemark, setSafetyRemark] = useState(
    data?.annual?.remark_records?.annual_safety_records_remark || ""
  );
  const [healthSafetyPersonnelNone, setHealthSafetyPersonnelNone] = useState(
    data?.annual?.remark_records?.health_safety_personnel_canada_none
  );
  const [filteredData, setFilteredData] = useState(
    data?.annual?.health_safety_personnel_canada || []
  );
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupPersonnel, setOpenPopupPersonnel] = useState(false);
  const [internalSearchResults, setInternalSearchResults] = useState(
    data?.annual?.health_safety_documents_canada
  );
  const [query, setQuery] = useState("");
  const [openDocumentPool, setOpenDocumentPool] = useState(false);
  const headers = [];
  const [selectedFiles, setSelectedFiles] = useState([]);

  let contactData = useSelector(contactSelector);
  const institutionContactData = useSelector(institutionProfileInfoSelector);

  if (currentDashboardUrl === "/service-provider-dashboard") {
    contactData = institutionContactData;
  }
  const fileLimitCount = contactData?.user_access_control?.file_limit_count;
  const fileLimitSize = contactData?.user_access_control?.file_limit_size;
  const fileLimitSizeMB = (fileLimitSize / (1024 * 1024)).toFixed(2);

  const [containsCor, setContainsCor] = useState(
    data?.annual?.remark_records?.certificate_of_recognition || false
  );
  const [notContainsCor, setNotContainsCor] = useState(
    data?.annual?.remark_records?.no_certificate_of_recognition || false
  );
  const [equivalentCor, setEquivalentCor] = useState(
    data?.annual?.remark_records?.certificate_of_recognition_equivalent || false
  );
  const [coveredCor, setCoveredCor] = useState(
    data?.annual?.remark_records?.no_certificate_of_recognition_equivalent ||
      false
  );

  const [corsRemark, setCorsRemark] = useState(
    data?.annual?.remark_records?.cors_remark || ""
  );

  const handleCorsRemarkChange = (event) => {
    setCorsRemark(event.target.value);
  };

  const handleDocumentPoolPopUp = () => {
    setOpenDocumentPool(true);
  };

  const deleteHealthSafetyFile = (index) => {
    let temp = [...data?.annual?.health_safety_documents_canada];
    temp.splice(index, 1);

    setData("annual.health_safety_documents_canada", temp);
  };

  const addUploadedFile = () => {
    let temp = [...(data?.annual?.health_safety_documents_canada || [])];

    for (let key in selectedFiles) {
      if (!isNaN(key)) {
        temp.push(selectedFiles[key]);
      }
    }

    setData("annual.health_safety_documents_canada", temp);
    setOpenDocumentPool(false);
  };

  const confirmCORRadioChange = (val) => {
    if (val === 1) {
      setContainsCor(true);
      setCorsRemark("");
      setNotContainsCor(false);
      setEquivalentCor(false);
      setCoveredCor(false);
    } else {
      setNotContainsCor(true);
      setContainsCor(false);
    }
  };

  const confirmCORSecondRadio = (val) => {
    if (val === 1) {
      setEquivalentCor(true);
      setCoveredCor(false);
    } else {
      setCoveredCor(true);
      setEquivalentCor(false);
    }
  };

  const handleSafetyRemarkChange = (event) => {
    setSafetyRemark(event.target.value);
  };

  useEffect(() => {
    setData(`annual.remark_records.certificate_of_recognition`, containsCor);
  }, [containsCor]);

  useEffect(() => {
    setData(
      `annual.remark_records.no_certificate_of_recognition`,
      notContainsCor
    );
  }, [notContainsCor]);

  useEffect(() => {
    setData(
      `annual.remark_records.certificate_of_recognition_equivalent`,
      equivalentCor
    );
  }, [equivalentCor]);

  useEffect(() => {
    setData(
      `annual.remark_records.no_certificate_of_recognition_equivalent`,
      coveredCor
    );
  }, [coveredCor]);

  useEffect(() => {
    setData(`annual.remark_records.cors_remark`, corsRemark);
  }, [corsRemark]);

  useEffect(() => {
    setData(`annual.remark_records.annual_safety_records_remark`, safetyRemark);
  }, [safetyRemark]);

  useEffect(() => {
    setData(
      `annual.remark_records.health_safety_personnel_canada_none`,
      healthSafetyPersonnelNone
    );
  }, [healthSafetyPersonnelNone]);

  useEffect(() => {
    setData(`annual.health_safety_personnel_canada`, filteredData);
  }, [filteredData]);

  const handleHealthSafetyPersonnelNoneChange = () => {
    setHealthSafetyPersonnelNone(!healthSafetyPersonnelNone);
  };

  return (
    <>
      <SectionText style={{ marginTop: "10px" }}>Health and Safety</SectionText>
      <StyledRow style={{ marginBottom: "-10px" }}>
        <StyledSubtext style={{ width: "90%" }}>
          {" "}
          Select Personnel responsible for Health & Safety:{" "}
        </StyledSubtext>
        <StyledRow justifyContent="right" style={{ marginTop: "-10px" }}>
          <StyledHealthSafetyPersonnelNone
            control={
              <RadioButton
                isChecked={healthSafetyPersonnelNone}
                handleChange={handleHealthSafetyPersonnelNoneChange}
              />
            }
            label="None"
            labelPlacement="end"
          />
        </StyledRow>
      </StyledRow>

      {healthSafetyPersonnelNone === false && (
        <ResultPanel
          projects={filteredData}
          setProjects={setFilteredData}
          setOpenPopup={setOpenPopupPersonnel}
          openPopup={openPopupPersonnel}
          paddingRight="0px"
          height="210px"
          label={false}
          institution_id={institutionId}
          currentDashboardUrl={currentDashboardUrl}
        />
      )}

      <StyledSubtext style={{ marginLeft: "-560px" }}>
        {" "}
        Certificate of Recognition program (COR<sup>TM</sup>)?{" "}
      </StyledSubtext>

      <StyledEditRecordButton
        style={{ marginLeft: "-10px", justifyContent: "left" }}
      >
        <RadioButton
          isChecked={
            containsCor === true && notContainsCor === false ? true : false
          }
          value={containsCor}
          checkedIcon={<CheckedRadioButton />}
          unCheckedIcon={<UncheckedRadioButton />}
          handleChange={() => confirmCORRadioChange(1)}
        />
        <StyledSubtext style={{ marginTop: "0px" }}> Yes</StyledSubtext>
        <RadioButton
          isChecked={
            notContainsCor === true && containsCor === false ? true : false
          }
          value={notContainsCor}
          checkedIcon={<CheckedRadioButton />}
          unCheckedIcon={<UncheckedRadioButton />}
          handleChange={() => confirmCORRadioChange(0)}
        />
        <StyledSubtext style={{ marginTop: "0px" }}> No</StyledSubtext>
      </StyledEditRecordButton>
      {notContainsCor && (
        <SecondRadioContainer>
          <SecondInnerRadioContainer
            style={{ marginLeft: "85px", justifyContent: "left" }}
          >
            <RadioButton
              isChecked={
                equivalentCor === true && coveredCor === false ? true : false
              }
              value={equivalentCor}
              checkedIcon={<CheckedRadioButton />}
              unCheckedIcon={<UncheckedRadioButton />}
              handleChange={() => confirmCORSecondRadio(1)}
            />
            <StyledSubtext style={{ fontSize: "12px", marginTop: "10px" }}>
              {" "}
              Equivalent to COR<sup>TM</sup>{" "}
            </StyledSubtext>
          </SecondInnerRadioContainer>
          <SecondInnerRadioContainer
            style={{ marginLeft: "85px", justifyContent: "left" }}
          >
            <RadioButton
              isChecked={
                coveredCor === true && equivalentCor === false ? true : false
              }
              value={coveredCor}
              checkedIcon={<CheckedRadioButton />}
              unCheckedIcon={<UncheckedRadioButton />}
              handleChange={() => confirmCORSecondRadio(0)}
            />
            <StyledSubtext style={{ fontSize: "12px", marginTop: "10px" }}>
              {" "}
              Covered by Health and Safety plan{" "}
            </StyledSubtext>
          </SecondInnerRadioContainer>
          <FieldArea width="835px" noJustifyContent={true}>
            <LabelText> Remarks </LabelText>
            <StyledTextField
              multiline
              rows={5}
              placeholder={"Remark"}
              variant="outlined"
              value={corsRemark}
              onChange={handleCorsRemarkChange}
            />
          </FieldArea>
        </SecondRadioContainer>
      )}

      <StyledSubtext style={{ marginTop: "28px", marginLeft: "-530px" }}>
        {" "}
        Attach all relevant Health & Safety documents:{" "}
      </StyledSubtext>

      <div style={{ display: "flex", width: "100%" }}>
        <div
          style={{
            marginLeft: "10px",
            display: "flex",
            justifyContent: "flex-start",
            width: "70%",
          }}
        >
          <SearchBar
            filteredData={data?.annual?.health_safety_documents_canada}
            setInternalSearchResults={setInternalSearchResults}
            internalSearch={true}
            query={query}
            setQuery={setQuery}
            searchWidth="100%"
            searchPlaceHolder="File Name..."
          />
        </div>
        <FunctionalButton
          buttonTitle="Document Pool"
          marginLeft="20px"
          handleButton={() => handleDocumentPoolPopUp()}
          width="250px"
          isReversedColor={true}
        />
      </div>

      {data?.annual?.health_safety_documents_canada?.length < 1 && (
        <div
          style={{
            alignItems: "center",
            backgroundColor: "white",
            border: `dashed 2px ${COLORS.lightGrey1}`,
            display: "flex",
            height: "186px",
            color: `${COLORS.textGrey}`,
            justifyContent: "center",
            width: "98%",
            marginTop: "20px",
          }}
        >
          Selected Documents will appear here
        </div>
      )}
      {data?.annual?.health_safety_documents_canada?.length > 0 && (
        <div style={{ marginTop: "20px", width: "98%" }}>
          <ConciseTable headers={headers} />

          <ScrollableDiv style={{ maxHeight: "190px" }}>
            <div style={{ marginRight: "15px" }}>
              {data?.annual?.health_safety_documents_canada?.length > 0 &&
                (query && query !== ""
                  ? internalSearchResults
                  : data?.annual?.health_safety_documents_canada
                )?.map((item, i) => (
                  <StyledCenter style={{ marginTop: 0 }}>
                    <WrapperForUploadedHealthSafetyFileDisplay
                      key={`file-${i}`}
                      file={item}
                      displayPercentage="100%"
                      deleteFile={deleteHealthSafetyFile}
                      index={i}
                      setPrequalifyData={setData}
                      prequalifyData={data}
                      setOpenPopup={setOpenPopup}
                      openPopup={openPopup}
                    />
                  </StyledCenter>
                ))}
            </div>
          </ScrollableDiv>
        </div>
      )}

      <AlertPopup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        headerText={"You have exceeded the file limit."}
        subHeaderText={`You can upload a maximum of ${fileLimitCount} documents or ${fileLimitSizeMB}MB.`}
      />
      <DocumentPoolPopUp
        openPopup={openDocumentPool}
        setOpenPopup={setOpenDocumentPool}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        attachFile={addUploadedFile}
        institutionId={institutionId}
      />

      <SectionText style={{ marginTop: "48px" }}>
        Annual Safety Records
      </SectionText>

      <FieldWrap isMobile={isMobile}>
        {!isMobile && (
          <>
            <FieldColumn className="firstColumn">
              <StyledYear>{currentYear - 1}</StyledYear>
              <StyledYear>{currentYear - 2}</StyledYear>
              <StyledYear>{currentYear - 3}</StyledYear>
            </FieldColumn>
            <FieldColumn className="recordColumn">
              <LabelText>Industry Classification Code</LabelText>
              <YearsNumber
                data={data?.annual?.safety_records ?? {}}
                setData={setData}
                type="classCode"
                year={currentYear - 1}
              />
            </FieldColumn>
            <FieldColumn className="recordColumn">
              <LabelText>Industry Base Rate</LabelText>
              <YearsNumber
                data={data?.annual?.safety_records ?? {}}
                setData={setData}
                type="baseRate"
                year={currentYear - 1}
              />
            </FieldColumn>
            <FieldColumn className="recordColumn">
              <LabelText>Company’s Experience Rate</LabelText>
              <YearsNumber
                data={data?.annual?.safety_records ?? {}}
                setData={setData}
                type="expRate"
                year={currentYear - 1}
              />
            </FieldColumn>
            <FieldColumn className="recordColumn">
              <LabelText>Fatalities (Actual Number)</LabelText>
              <YearsNumber
                data={data?.annual?.safety_records ?? {}}
                setData={setData}
                type="fatalities"
                year={currentYear - 1}
              />
            </FieldColumn>
            <FieldColumn className="recordColumn">
              <LabelText>Lost Time Incident Frequency</LabelText>
              <YearsNumber
                data={data?.annual?.safety_records ?? {}}
                setData={setData}
                type="lostRate"
                year={currentYear - 1}
              />
            </FieldColumn>
            <FieldArea width="98%" noJustifyContent={true}>
              <LabelText> Remarks </LabelText>
              <StyledTextField
                multiline
                rows={5}
                placeholder={"Remark"}
                variant="outlined"
                value={safetyRemark}
                onChange={handleSafetyRemarkChange}
              />
            </FieldArea>
          </>
        )}
        {isMobile && (
          <>
            <MobileYearText margin="0px 0px 16px">
              {currentYear - 1}
            </MobileYearText>
            <MobileYearsNumber
              data={data?.annual?.safety_records ?? {}}
              setData={setData}
              year={currentYear - 1}
            />
            <MobileYearText margin="32px 0px 16px">
              {currentYear - 2}
            </MobileYearText>
            <MobileYearsNumber
              data={data?.annual?.safety_records ?? {}}
              setData={setData}
              year={currentYear - 2}
            />
            <MobileYearText margin="32px 0px 16px">
              {currentYear - 3}
            </MobileYearText>
            <MobileYearsNumber
              data={data?.annual?.safety_records ?? {}}
              setData={setData}
              year={currentYear - 3}
            />
          </>
        )}
        <FunctionalButton
          buttonTitle="Next"
          handleButton={() => handleNext()}
          width="200px"
          marginRight="auto"
          marginLeft="auto"
          marginTop="16px"
        />
      </FieldWrap>
    </>
  );
};

export default SafetyRecords;
