import React from 'react';

const ScoreOrganization = () => {
  return (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight: 6}}>
    <path d="M19.3986 16.6787C19.3024 16.9597 19.245 17.2622 19.1024 17.5174C18.6409 18.3451 17.9058 18.7245 16.9671 18.7245H5.12782C4.43062 18.7245 3.73289 18.7245 3.03568 18.7245C1.59603 18.7214 0.602051 17.7248 0.602051 16.2893C0.602051 12.606 0.602051 8.92262 0.602051 5.23928C0.602051 4.87095 0.648882 4.51787 0.808318 4.1869C1.24453 3.28448 1.97541 2.82459 2.97096 2.81407C4.28644 2.80039 5.60192 2.8088 6.9174 2.81407C7.26711 2.80813 7.61398 2.87778 7.93429 3.01827C8.25459 3.15877 8.54078 3.36678 8.77327 3.62808C9.30841 4.20689 9.86196 4.7715 10.3992 5.34926C10.5042 5.46794 10.6341 5.56204 10.7796 5.62488C10.9251 5.68772 11.0827 5.71775 11.2411 5.71285C13.1561 5.70654 15.0709 5.70654 16.9855 5.71285C18.0658 5.71285 18.9324 6.31271 19.2708 7.28617C19.3234 7.44087 19.3555 7.60188 19.3971 7.75974L19.3986 16.6787ZM10.0014 17.3038H16.9571C17.6238 17.3038 17.9785 16.9534 17.979 16.2888C17.979 13.5726 17.979 10.8566 17.979 8.1407C17.9823 8.02471 17.9711 7.90876 17.9458 7.79552C17.8374 7.37457 17.4896 7.13147 17.0087 7.13094C15.0758 7.13094 13.1428 7.13094 11.2095 7.13094C10.8691 7.13711 10.5313 7.07033 10.2188 6.9351C9.90632 6.79986 9.6264 6.59931 9.39786 6.34692C8.84483 5.75811 8.28286 5.17824 7.73299 4.58681C7.62978 4.47006 7.50209 4.37752 7.35903 4.31576C7.21596 4.25401 7.06104 4.22456 6.90529 4.22952C5.61455 4.23584 4.3238 4.22952 3.03305 4.23215C2.38163 4.23215 2.02382 4.58838 2.02382 5.24191C2.02382 8.92525 2.02382 12.6086 2.02382 16.2919C2.02037 16.4079 2.03133 16.5238 2.05644 16.6371C2.16484 17.0607 2.51739 17.3049 3.0099 17.3054L10.0014 17.3038Z" fill="#B9B9C3"/>
    </svg>

  )
};

export default ScoreOrganization;
