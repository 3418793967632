import React, { useState, useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import PledgxLogo from "../../assets/common/pledgxLogo";
import { emailRE } from "../../common/regex";

import Input from "../../components/inputField";
import { FunctionalButton } from "../../components/functionalButton";
import { conciergeLogin } from "../../api/onboarding";

import {
  FormContainer,
  Content,
  FormCard,
  FormTitle,
  ErrorText,
} from "../loginPage/styles";

export const ConciergeLogin = ({ isMobile }) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState("");
  const [conciergeCode, setConciergeCode] = useState("");
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [hasErrorVerify, setHasErrorVerify] = useState(false);

  const handleLogin = async () => {
    await conciergeLogin(
      { email, password, concierge_code: conciergeCode },
      onSuccess,
      onFailure
    );
  };

  const onSuccess = (data) => {
    history.push("/contractor-dashboard");
  };

  const onFailure = (error) => {
    if (error?.status == 401) {
      setHasError(true);
      setErrorMessage(error?.data?.message);
    }
    if (error?.status == 403) {
      setHasErrorVerify(true);
      setErrorMessage(error?.data?.message);
    }
  };

  useEffect(() => {
    setHasError(false);
    setHasErrorVerify(false);
    setErrorMessage("");
  }, [email, password]);

  return (
    <FormContainer isMobile={isMobile}>
      <Content isMobile={isMobile} marginTop="50px">
        <div
          onClick={() => history.push("/staff/account/concierge_login")}
          style={{ width: "100%", cursor: "pointer" }}
        >
          <PledgxLogo />
        </div>
        <FormCard>
          <FormTitle>Concierge Login</FormTitle>
          <Input
            label="Your Staff Email"
            type="text"
            placeholder="name@company.com"
            labelSize="12px"
            textAreaHeight="64px"
            fontSize="18px"
            value={email}
            setValue={(val) => setEmail(val)}
            validateFunc={(item) => emailRE.test(item)}
            hasError={hasError ? hasError : hasErrorVerify}
            noCheckIcon={true}
          />
          <Input
            label="Your Staff Password"
            type="password"
            placeholder="Password"
            labelSize="12px"
            textAreaHeight="64px"
            fontSize="18px"
            value={password}
            setValue={(val) => setPassword(val)}
            validateFunc={(item) => item.length > 0}
            hasError={hasError}
            noCheckIcon={true}
          />
          <Input
            label="Customer Concierge Code"
            type="text"
            placeholder="A1B2C3D"
            labelSize="12px"
            textAreaHeight="64px"
            fontSize="18px"
            value={conciergeCode}
            setValue={(val) => setConciergeCode(val)}
            //validateFunc={(item) => emailRE.test(item)}
            hasError={hasError ? hasError : hasErrorVerify}
            noCheckIcon={true}
          />

          {hasError && <ErrorText>{errorMessage}</ErrorText>}
          <FunctionalButton
            buttonTitle="Login"
            handleButton={handleLogin}
            width="100%"
            marginRight="0px"
            marginTop="30px"
          />
        </FormCard>
      </Content>
    </FormContainer>
  );
};
