import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import {
  ScreenRoot,
  FormContainer,
  SideContainer,
  LargeText,
  StyledSpan,
  StyledBox,
  StyledArea,
} from "./styles";
import { getOnboarding } from "../../api/onboarding";
import AdminIcon from "../../assets/landingToolsBarIcons/adminIcon";
import ContractorIcon from "../../assets/landingToolsBarIcons/contractorIcon";
import BuyerIcon from "../../assets/landingToolsBarIcons/buyerIcon";
import UnderwriterIcon from "../../assets/landingToolsBarIcons/underwriterIcon";
import AgentIcon from "assets/landingToolsBarIcons/agentIcon";

function Dashboard() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [userData, setUserData] = useState("");
  let history = useHistory();

  useEffect(() => {
    getOnboarding(
      (data) => {
        if (data.status != "active") {
          history.push("/onboarding");
        } else {
          setUserData(data);
        }
      },
      () => {}
    );
    localStorage.removeItem("current_dashboard_url");
    localStorage.removeItem("current_agent_dashboard");
  }, []);

  return (
    <ScreenRoot isMobile={isMobile}>
      <FormContainer isMobile={isMobile}>
        <LargeText isMobile={isMobile}>
          {isMobile ? "Select Module" : "Please Select Module"}
        </LargeText>
        <br /> <br />
        {userData &&
          userData?.userType === "Contractor" &&
          userData?.staff !== true &&
          history.push("/contractor-dashboard")}
        {userData &&
          userData?.userType === "Buyer" &&
          userData?.staff !== true &&
          history.push("/buyer-dashboard")}
        {userData &&
          userData?.userType === "Underwriter" &&
          userData?.staff !== true &&
          history.push("/underwriter-dashboard")}
        {userData &&
          userData?.userType === "Contractor & Buyer" &&
          userData?.staff !== true && (
            <>
              <StyledArea>
                <StyledBox
                  boxHeight="0px"
                  borderRadius="0px"
                  isMobile={isMobile}
                  onClick={() => {
                    history.push("/buyer-dashboard");
                    localStorage.setItem(
                      "current_dashboard_url",
                      "/buyer-dashboard"
                    );
                  }}
                  style={{ cursor: "pointer" }}
                  flexDirection="row"
                >
                  <BuyerIcon />
                </StyledBox>

                <StyledBox
                  boxHeight="0px"
                  borderRadius="0px"
                  isMobile={isMobile}
                  onClick={() => {
                    history.push("/contractor-dashboard");
                    localStorage.setItem(
                      "current_dashboard_url",
                      "/contractor-dashboard"
                    );
                  }}
                  style={{ cursor: "pointer" }}
                  flexDirection="row"
                >
                  <ContractorIcon />
                </StyledBox>
              </StyledArea>
            </>
          )}
        {userData && userData?.staff === true && (
          <>
            <StyledArea>
              <StyledBox
                boxHeight="0px"
                borderRadius="0px"
                isMobile={isMobile}
                onClick={() => {
                  history.push("/buyer-dashboard");
                  localStorage.setItem(
                    "current_dashboard_url",
                    "/buyer-dashboard"
                  );
                }}
                style={{ cursor: "pointer" }}
                flexDirection="row"
              >
                <BuyerIcon />
              </StyledBox>

              <StyledBox
                boxHeight="0px"
                borderRadius="0px"
                isMobile={isMobile}
                onClick={() => {
                  history.push("/contractor-dashboard");
                  localStorage.setItem(
                    "current_dashboard_url",
                    "/contractor-dashboard"
                  );
                }}
                style={{ cursor: "pointer" }}
                flexDirection="row"
              >
                <ContractorIcon />
              </StyledBox>
            </StyledArea>

            <div style={{ flex: "50%", padding: "0px", marginTop: "250px" }}>
              <StyledArea display="flex">
                <StyledBox
                  boxHeight="0px"
                  borderRadius="0px"
                  isMobile={isMobile}
                  onClick={() => {
                    history.push("/underwriter-dashboard");
                    localStorage.setItem(
                      "current_dashboard_url",
                      "/underwriter-dashboard"
                    );
                  }}
                  style={{ cursor: "pointer" }}
                  flexDirection="row"
                >
                  <UnderwriterIcon />
                </StyledBox>
                <StyledBox
                  boxHeight="0px"
                  borderRadius="0px"
                  isMobile={isMobile}
                  onClick={() => {
                    history.push("/service-provider-dashboard");
                    localStorage.setItem(
                      "current_dashboard_url",
                      "/service-provider-dashboard"
                    );
                  }}
                  style={{ cursor: "pointer" }}
                  flexDirection="row"
                >
                  <AgentIcon />
                </StyledBox>
              </StyledArea>
            </div>
            <div style={{ flex: "50%", padding: "0px", marginTop: "250px" }}>
              <StyledArea style={{ justifyContent: "center" }}>
                <StyledBox
                  // boxHeight="0px"
                  boxWidth="34%"
                  borderRadius="20px"
                  isMobile={isMobile}
                  onClick={() => {
                    history.push("/admin-tools");
                    localStorage.setItem(
                      "current_dashboard_url",
                      "/admin-tools"
                    );
                  }}
                  style={{ cursor: "pointer", justifyContent: "center" }}
                  flexDirection="row"
                >
                  <AdminIcon />
                  <div
                    style={{
                      fontSize: "50px",
                      fontWeight: "550",
                      color: "#FF6D1D",
                      fontFamily: "Gill Sans, sans-serif ",
                      marginTop: "60px",
                    }}
                  >
                    Admin
                  </div>
                </StyledBox>
              </StyledArea>
            </div>
          </>
        )}
      </FormContainer>
    </ScreenRoot>
  );
}

export default Dashboard;
