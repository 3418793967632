import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { getBuyersList } from "../../data/actions/buyers";
import { buyersSelector } from "../../data/selectors/buyers";
import DashHeader from "../../components/dashboardHeader";
import Sidebar from "../../components/sidebar";
import { Container } from "../../components/common";
import Controls from "../../components/controlPanel";

import Select from "react-select";
import BuyerCard from "../../components/buyerCard";
import Notices from "../../components/notice";

import {
  Content,
  Title,
  ContentControls,
  SelectLabel,
  Row,
  Column,
} from "./styles";

const LoadMoreButton = withStyles((theme) => ({
  root: {
    marginTop: 20,
    width: "100%",
    height: 48,
    backgroundColor: "#2d2d2d",
    border: "1.5px solid #2d2d2d",
    boxSizing: "border-box",
    borderRadius: "6px",
    color: "white",

    fontSize: "17px",
    fontWeight: 600,
    transition: "all 0.5s ease",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "white",
      color: "#2d2d2d",
    },
  },
}))(Button);

function Qualification(props) {
  const dispatch = useDispatch();
  const buyers = useSelector(buyersSelector);
  const [loadind, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getBuyersList());
  }, [dispatch]);

  return (
    <>
      <DashHeader></DashHeader>
      <Sidebar></Sidebar>
      <Container>
        <Controls />
        <Content>
          <ContentControls>
            <Title>List of Buyers</Title>
            <SelectLabel>Sort by:</SelectLabel>
            <Select
              openMenuOnFocus
              styles={{
                indicatorSeparator: (styles) => ({
                  display: "none",
                }),
                control: (styles) => ({
                  ...styles,
                  boxShadow: "none",
                  width: 150,
                  background: "#E9E9E9",
                  border: "none !important",
                  borderRadius: "6px",
                  fontSize: "12px",
                  color: "#3F3356",
                  outline: "none",
                }),
                menu: (styles) => ({
                  ...styles,
                  fontSize: "12px",
                  color: "#444444",
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    backgroundColor: isSelected ? "#e9e9e9" : "white",
                    color: "#444444",
                    cursor: "pointer",
                  };
                },
              }}
              className="basic-single"
              classNamePrefix="select"
              defaultValue={{ value: "bestMatch", label: "Best Match" }}
              name="sort-by"
              options={[
                { value: "bestMatch", label: "Best Match" },
                { value: "dateLatest", label: "Date - Latest" },
                { value: "dateEarliest", label: "Date - Earliest" },
                { value: "notViewed", label: "Not Viewed" },
              ]}
            />
          </ContentControls>
          <Row style={{ marginTop: 10 }}>
            <Column style={{ flexGrow: 1 }}>
              {buyers.map((buyer) => (
                <BuyerCard buyer={buyer}></BuyerCard>
              ))}
              <LoadMoreButton
                onClick={() => {
                  setLoading(true);
                  // dispatch(
                  //   loadMoreTenders(
                  //     recommended.length,
                  //     chosenCategories.join(),
                  //     () => setLoading(false),
                  //     () => setLoading(false)
                  //   )
                  // );
                }}
              >
                {loadind ? "Loading..." : "Load More"}
              </LoadMoreButton>
            </Column>
            <Column style={{ marginLeft: 20, marginTop: 5 }}>
              <Notices></Notices>
            </Column>
          </Row>
        </Content>
      </Container>
    </>
  );
}

export default Qualification;
