import React from "react";
import wip from "./wip.svg";
import styled from "styled-components";

const WipIcon = ({ width, height, isMobile }) => {
  return (
    <StyledImg
      width={width || "106"}
      height={height || "112"}
      src={wip}
      isMobile={isMobile}
    />
  );
};

export default WipIcon;

const StyledImg = styled.img`
  ${(props) => (props.isMobile ? "margin-bottom: 4px; margin-top: 8px;" : "")}
`;
