import React from 'react';

const UncheckedRadioButton = (props) => {
  return ( 
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11" fill='white' stroke={ props?.color || '#FFAA7B' } stroke-width="2"/>
    </svg>
  )    
};

export default UncheckedRadioButton;


