import React from 'react';
import underwriter from './underwriterIcon.svg'
import styled from "styled-components";

const UnderwriterIcon = ({width, height, isMobile}) => {
  return (
    <StyledImg width={width || "400"} height={height || "250"} src={underwriter} isMobile={isMobile}/>
  )
};

export default UnderwriterIcon;

const StyledImg = styled.img`
  ${props => props.isMobile ? "margin-bottom: 8px; margin-top: 12px;" : ""}
`;