import axios from "axios";
import { API_HOST } from "../constants";

let headers = {};

const getAuth = () => {
  const authData = localStorage.getItem("Authorization");
  if (authData) {
    headers = { Authorization: authData };
  }
};

export const getRfpqs = async (data) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/rfpq/get_rfpqs`, data, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log("Something wrong", err);
    });
};

export const getRfpq = async (data) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/rfpq/get_rfpq`, data, { headers })
    .then((res) => res.data)
    .catch((err) => {
      console.log("Something wrong", err);
    });
};

export const submitCqs = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/rfpq/submit_proposal`, data, { headers })
    .then(async (res) => {
      await onSuccess(res.data);
      headers["Content-Type"] = "application/json";
    })
    .catch((err) => {
      console.log("Something wrong", err);
      onFailure(err);
    });
};
