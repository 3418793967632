import styled from 'styled-components';
import { COLORS } from '../../constants';


export const StyledDragfieldArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width?  props.width : '367px'};
  height: ${props => props.height?  props.height : '186px'};
  border: dashed 2px ${COLORS.lightGrey1};
  cursor: pointer;
`;

export const StyledLabel = styled.label`
  margin: ${props => props.margin || 0};
  width: ${props => props.width || "100%"};
`;
