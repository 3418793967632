import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { FunctionalButton } from "../../../components/functionalButton";
import { getPreQualificationInfo } from "data/actions/user";
import {
  StyledQuestion,
  InfoArea,
  StyledSkipText,
  TextContainer,
} from "./styles";

import { PanelUploadStatement } from "./templatePanel/PanelUploadStatement";
import { Panel3 } from "./healthPanel/Panel3";
import { Panel4 } from "./healthPanel/Panel4";
import { PanelPrevStatement } from "./templatePanel/PanelPrevStatement";
import { PickProject } from "./templatePanel/PickProject";

export const TemplateSelection = ({
  handleNext,
  prequalifyData,
  setPrequalifyData,
  panel,
  setPanel,
  setIsLastPanel,
  setPreviousStepPanel,
  setAction,
  setDownloading,
  setPopupSkip,
  setIsBranchPanel,
  rfpq,
  setRfpq,
  institutionId,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 960 });
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);

  return (
    <>
      {/* { panel == 1 && (
        <>
          <StyledQuestion isMobile={isMobile} width="650px">
            What would you like to use as a template to complete the qualification statement?
          </StyledQuestion>
          <InfoArea isMobile={isMobile} width="650px" isColumn={true} marginTop="0px">
            <FunctionalButton
              buttonTitle="USE A PREVIOUS STATEMENT"
              width="420px"
              height="48px"
              marginRight="16px"
              marginTop="48px"
              weight='500'
              isReversedColor={selected === 1 ? false : true}
              selected={selected === 1 ? true : false}
              handleButton={() => {
                setSelected(1);
              }}
            />
            {/* <FunctionalButton
              buttonTitle="UPLOAD A QUALIFICATION STATEMENT"
              width="420px"
              height="48px"
              marginTop="24px"
              weight='500'
              isReversedColor={selected === 2 ? false : true}
              handleButton={() => setSelected(2)}
            />
            <FunctionalButton
              buttonTitle="FILL MANUALLY"
              width="420px"
              height="48px"
              marginTop="24px"
              weight='500'
              isReversedColor={selected === 3 ? false : true}
              selected={selected === 3 ? true : false}
              handleButton={() => { dispatch(getPreQualificationInfo(() => setSelected(3), () => setSelected(3)))
              }}
            />
          </InfoArea>
          <FunctionalButton
            buttonTitle="Next"
            handleButton={() => {selected === 3 ? setPanel(3) : setPanel(2)}}
            width="200px"
            marginRight="0px"
            marginTop="48px"
            disabled={selected === 0}
          />
        </>
      )} */}
      {/* { panel == 2 && (
        <>
        { selected === 1 && (
          <PanelPrevStatement
            prequalifyData={prequalifyData}
            setPrequalifyData={setPrequalifyData}
            handleNext={handleNext}
            setIsLastPanel={setIsLastPanel}
            setPanel={setPanel}
            panel={panel}
            setPreviousStepPanel={setPreviousStepPanel}
          />
        )}
        { selected === 2 && (
          <PanelUploadStatement
            prequalifyData={prequalifyData}
            setPrequalifyData={setPrequalifyData}
            handleNext={handleNext}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
            setAction={setAction}
            setDownloading={setDownloading}
         />
        )}
        </>
      )} */}
      {panel == 1 && (
        <>
          <PickProject
            handleNext={handleNext}
            prequalifyData={prequalifyData}
            setPrequalifyData={setPrequalifyData}
            setIsLastPanel={setIsLastPanel}
            setPreviousStepPanel={setPreviousStepPanel}
            setPopupSkip={setPopupSkip}
            setSelected={setSelected}
            rfpq={rfpq}
            setRfpq={setRfpq}
            institutionId={institutionId}
          />
        </>
      )}
    </>
  );
};
