import { FONT_SIZES } from "../../constants";
import React from "react";
import Input from "../inputField";
import { numberWithCommas } from "common/numberFormat";

export const InsuranceGroup = ({ readOnly, value }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {value?.order?.map((key) => (
        <div style={{ marginTop: "10px" }} key={key}>
          <div
            style={{
              display: "block",

              marginBottom: "5px",
            }}
          >
            <span
              style={{
                fontSize: FONT_SIZES.body_3,
                fontWeight: "bold",
              }}
            >
              {value?.state[key]?.type || ""}
            </span>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Input
              fontSize="18px"
              labelSize="12px"
              marginTop="10px"
              paddingRight="15px"
              label={"Company Name"}
              type="text"
              value={value?.state[key]?.data?.company_name || ""}
              readOnly={readOnly}
              marginRight="10px"
            />

            <Input
              fontSize="18px"
              labelSize="12px"
              marginTop="10px"
              paddingRight="15px"
              label={"Address"}
              type="text"
              value={
                (value?.state[key]?.data?.suite_number &&
                value?.state[key]?.data?.suite_number !== "None"
                  ? value?.state[key]?.data?.suite_number + "-"
                  : "") +
                  (value?.state[key]?.data?.address
                    ? value?.state[key]?.data?.address + ", "
                    : "") +
                  (value?.state[key]?.data?.city
                    ? value?.state[key]?.data?.city + ", "
                    : "") +
                  (value?.state[key]?.data?.province
                    ? value?.state[key]?.data?.province + ", "
                    : "") +
                  (value?.state[key]?.data?.postal
                    ? value?.state[key]?.data?.postal
                    : "") || ""
              }
              readOnly={readOnly}
            />
          </div>
          <div style={{ display: "flex" }}>
            <Input
              fontSize="18px"
              labelSize="12px"
              marginTop="10px"
              paddingRight="15px"
              label={"Contact Name"}
              type="text"
              value={
                value?.state[key]?.data?.contact_first_name +
                  " " +
                  value?.state[key]?.data?.contact_last_name || ""
              }
              readOnly={readOnly}
              marginRight="10px"
            />
            <Input
              fontSize="18px"
              labelSize="12px"
              marginTop="10px"
              paddingRight="15px"
              label={"Contact Email"}
              type="text"
              value={value?.state[key]?.data?.contact_email || ""}
              readOnly={readOnly}
            />
          </div>
          <div style={{ display: "flex" }}>
            <Input
              fontSize="18px"
              labelSize="12px"
              marginTop="10px"
              paddingRight="15px"
              label={"Liability Limit ($)"}
              type="text"
              value={
                numberWithCommas(value?.state[key]?.data?.limit_value) || ""
              }
              readOnly={readOnly}
              marginRight="10px"
            />
            <Input
              fontSize="18px"
              labelSize="12px"
              marginTop="10px"
              paddingRight="15px"
              label={"Per Occurrence Limit"}
              type="text"
              value={
                numberWithCommas(
                  value?.state[key]?.data?.per_occurance_limit
                ) || ""
              }
              readOnly={readOnly}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default InsuranceGroup;
