import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '../../../../constants';
import Slider from '@material-ui/core/Slider';
import { Input } from 'semantic-ui-react'


export const StyledWeightsContainer = styled.div`
  border: 1px solid ${COLORS.greyBorder};
  border-radius: 6px;
  width: 100%;
  margin-top: 16px;
  padding: 16px;
`;

export const StyledSlider = styled(Slider)`
  && {
    width: 60%;
  }

  .MuiSlider-rail {
    background-color: ${COLORS.scrollbarTrack};
    height: 4px;
    border-radius: 2px;
    opacity: 1;
    padding-right: 12px;
  }

  .MuiSlider-track {
    background: linear-gradient(225deg, #FFB167 0%, #FF7C2D 100%);
    height: 4px;
    border-radius: 2px;
  }

  .MuiSlider-mark {
    display: none;
  }

  .MuiSlider-thumb {
    width: 28px;
    height: 28px;
    border: 2px white solid;
    background: linear-gradient(225deg, #FFB167 0%, #FF7C2D 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    display: inline-block;
    margin-top: -13px;
    margin-left: -14px;
  }

  .MuiSlider-thumb:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-markLabel {
    top: 40px;
    font-size: 13px;
    font-family: ${TYPOGRAPHY.primaryFont};
  }
`;

export const StyledHeader = styled.div`
  && {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    font-family: ${TYPOGRAPHY.primaryFont};
    color: ${COLORS.lightGrey2};
  }
`;

export const StyledInputContainer = styled.div`
  && {
    display: inline;
    position: ${props => props.isMobile ? '' : 'relative'};
    margin-left: ${props => props.isMobile ? '' : '32px'};
    bottom: 30px;
  }
`;

export const StyledSectionContainer = styled.div`
  && {
    margin-bottom: 56px;
  }
`;
