import React, { useState } from "react";
import {
  InfoContainer,
  ImageContainer,
  Dashboard,
  Dots,
  SubTitle,
  Title,
  Text,
  CopyRights,
  Links,

} from "./styles";

import dashboard from "../../assets/dashboard.svg";
import dots from "../../assets/dots.svg";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import loginImage from "../../assets/common/loginImage.svg";
import onboardingImage from "../../assets/common/onboardingImage.png"

export const GraphicContainer = () => {

  return (
    <>
    <InfoContainer>
        <Dashboard src={onboardingImage}></Dashboard>
    </InfoContainer>
    </>
  );
}

