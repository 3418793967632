import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '../../constants';
import Slider from '@material-ui/core/Slider';


export const StyledContainer = styled.div`
  display: inline-block;
  width: ${props => props.width || "60%"};
  ${props => props.margin ? `margin: ${props.margin};` : ""}
`;

export const StyledSlider = styled(Slider)`
  && {
    color: red;
  }

  .MuiSlider-rail {
    background-color: ${COLORS.scrollbarTrack};
    height: 4px;
    border-radius: 2px;
    opacity: 1;
    padding-right: 12px;
  }

  .MuiSlider-track {
    background: linear-gradient(225deg, #FFB167 0%, #FF7C2D 100%);
    height: 4px;
    border-radius: 2px;
  }

  .MuiSlider-mark {
    display: none;
  }

  .MuiSlider-thumb {
    width: 28px;
    height: 28px;
    border: 2px white solid;
    background: linear-gradient(225deg, #FFB167 0%, #FF7C2D 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    display: ${props => props.noThumb ? "none" : "inline-block"};
    margin-top: -13px;
    margin-left: -14px;
  }

  .MuiSlider-thumb:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-markLabel {
    top: 28px;
    font-size: 13px;
    font-family: ${TYPOGRAPHY.primaryFont};
  }
`;

export const StyledHeader = styled.div`
  && {
    margin-bottom: ${props => props.headerMarginBottom || "20px"};
    font-size: ${props => props.headerFontSize || "18px"};
    font-weight: 500;
    line-height: 21px;
    font-family: ${TYPOGRAPHY.primaryFont};
    color: ${COLORS.lightGrey2};
    display: flex; 
    flex-direction: row;
    align-items: center;
  }
`;
