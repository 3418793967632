import React from "react";
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PdfDocument from './pdfDocument';
import { FunctionalButton } from 'components/functionalButton';
import { useHistory } from "react-router-dom";
import { ButtonDiv } from "pages/underwriters/components/contractStatusReportViewing/styles.js"

function DownloadCSR(data) {
  let history = useHistory();
  const currentDateTime = new Date().toLocaleString().replace(/[\s,:]+/g, '_');
  let fileName = `contract_status_report_${currentDateTime}.pdf`;

  const handleGoBack = () => {
    history.goBack();
  };


  return (
    <div>
      <PDFViewer width={740} height={380} showToolbar={false}>
        <PdfDocument csrData={data} />
      </PDFViewer>

      <div>
        <ButtonDiv>
          <FunctionalButton
            buttonTitle="GO BACK"
            handleButton={handleGoBack}
            isReversedColor={true}
            height="48px"
            width="199px"
            marginTop="5px"
            marginRight="16px"
            style={{ boxSizing: "border-box" }}
          />
          <div  style={{ height:'48px', width:'199px' }}> 
          <PDFDownloadLink
            document={<PdfDocument csrData={data} />}
            fileName={fileName}
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading..." : <FunctionalButton
                buttonTitle={"EXPORT"}
                height="48px"
                width="199px"
                marginTop="5px"
                marginRight="0px"
                style={{ boxSizing: "border-box" }}
              />
            }
          </PDFDownloadLink>
          </div>
        </ButtonDiv>
      </div>
    </div>
  );
}

export default DownloadCSR;
