import React from "react";
import AwfulFace from "assets/reference/awfulFace";
import { StyledBox, StyledBoxContent, LargeTitle } from "../styles";

import { RowContent, RateBox, RateBoxText, StyledSpan } from "./styles";
import PoorFace from "assets/reference/poorFace";
import AverageFace from "assets/reference/averageFace";
import NiceFace from "assets/reference/niceFace";
import AmazingFace from "assets/reference/amazingFace";

export const ReferenceSummary = ({ isMobile, data }) => {
  const total = data?.length || 1;
  let awful = 0;
  let poor = 0;
  let average = 0;
  let great = 0;
  let amazing = 0;

  if (total > 0) {
    data.map((item, i) => {
      const rating = item?.rating;
      if (rating > 8 && rating < 11) {
        amazing++;
      }
      if (rating > 6 && rating < 9) {
        great++;
      }
      if (rating > 4 && rating < 7) {
        average++;
      }
      if (rating > 2 && rating < 5) {
        poor++;
      }
      if (rating > 0 && rating < 3) {
        awful++;
      }
    });
  }

  return (
    <StyledBox boxHeight="170px" hasData={true}>
      <StyledBoxContent margin="24px 32px" flexDirection="column">
        <RowContent justifyContent="space-between" margin="4px 0 8px 0">
          <LargeTitle fontWeight="600">
          References received <StyledSpan>({data?.length})</StyledSpan>
          </LargeTitle>
        </RowContent>
        <RowContent justifyContent="space-between" margin="24px 0 0 0">
          <RateBox color="#FF0000">
            <RateBoxText>
              <AwfulFace />
            </RateBoxText>
            <RateBoxText fontWeight="700" fontSize="15px">
              {Math.round((awful / total) * 100)}%
            </RateBoxText>
          </RateBox>
          <RateBox color="#FF6D1D">
            <RateBoxText>
              <PoorFace />
            </RateBoxText>
            <RateBoxText fontWeight="700" fontSize="15px">
              {Math.round((poor / total) * 100)}%
            </RateBoxText>
          </RateBox>
          <RateBox color="#FEC65C">
            <RateBoxText>
              <AverageFace />
            </RateBoxText>
            <RateBoxText fontWeight="700" fontSize="15px">
              {Math.round((average / total) * 100)}%
            </RateBoxText>
          </RateBox>
          <RateBox color="#8DD655">
            <RateBoxText>
              <NiceFace />
            </RateBoxText>
            <RateBoxText fontWeight="700" fontSize="15px">
              {Math.round((great / total) * 100)}%
            </RateBoxText>
          </RateBox>
          <RateBox color="#43B868">
            <RateBoxText>
              <AmazingFace />
            </RateBoxText>
            <RateBoxText fontWeight="700" fontSize="15px">
              {Math.round((amazing / total) * 100)}%
            </RateBoxText>
          </RateBox>
        </RowContent>
      </StyledBoxContent>
    </StyledBox>
  );
};
