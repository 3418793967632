import React from 'react';
import requirements from './requirements_grey.svg'

const Requirements_grey = ({ isMobile }) => {
  return (
    <img width={isMobile ? "36" : "56"} height={isMobile ? "36" : "56"} src={requirements} />
  )
};

export default Requirements_grey;
