import React, { useState, useEffect } from "react";
import { FunctionalButton } from 'components/functionalButton';
import Input from 'components/inputField';
import {
  Title,
  StyledAddressContainer,
  FormArea
} from './styles';
import { addAndSendNewGeneric } from "api/reference";
import updateObjectByPath from "common/updateObjectByPath";
import { emailRE, phoneRegex } from "common/regex";
import { formatPhoneNumber } from "common/formatPhoneNumber";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import './styles.css';

export const Panel5 = ({
  isMobile,
  setOpen,
  setOpenSuccessPopup
}) => {
  const [data, setData] = useState({ type: "generic" })
  const [disabled, setDisabled] = useState(true);
  const [phoneNumber, setPhoneNumber] = React.useState(data?.phone);

  const handlePhoneNumberChange = (value) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, "phone", value);
    setData({ ...updatedData });
  };


  useEffect(() => {
    if (data?.first_name?.length > 0 &&
      data?.last_name?.length > 0 &&
      emailRE.test(data?.email) &&
      data?.company_name?.length > 0 &&
      data?.relationship?.length > 0) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [data])

  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };

  const handleButton = () => {
    addAndSendNewGeneric(data, () => { setOpen(false); setOpenSuccessPopup(true) }, () => { })
  }

  return (
    <>
      <Title fontWeight={500} margin="0px 0 16px">Who would you like a reference from?</Title>
      <FormArea width="70%" isMobile={isMobile}>
        <Input
          id="inputWrap"
          label="First Name"
          type="text"
          placeholder="First Name"
          labelSize="12px"
          fontSize="18px"
          value={data?.first_name}
          setValue={(val) => { onChangeData('first_name', val) }}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
        <Input
          id="inputWrap"
          label="Last Name"
          type="text"
          placeholder="Last Name"
          labelSize="12px"
          fontSize="18px"
          value={data?.last_name}
          setValue={(val) => { onChangeData('last_name', val) }}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />

        <StyledAddressContainer>

          {/* <Input
          id="inputWrap"
          label="Phone"
          type="text"
          placeholder="(123) 456-7890"
          labelSize="12px"
          fontSize="18px"
          value={data?.phone}
          setValue={(val) => { onChangeData('phone', formatPhoneNumber(val)) }}
          validateFunc={(item) => phoneRegex.test(item)}
          noCheckIcon={true}
          noErrorBorder={true}
        /> */}


          <div style={{ marginTop: '24px', width: "100%", marginLeft: '0px', marginRight: '0px' }}>
            <label htmlFor="phone" style={{ fontSize: '12px', marginLeft: '0px' }}>Phone Number</label>
            <div style={{ height: '48px', width: "100%", marginTop: '-10px' }}>
              <PhoneInput
                id="phone"
                country={'ca'}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                inputStyle={{ fontSize: '18px', padding: '8px', lineHeight: '24px', marginLeft: '20px', paddingLeft: '28px', height: '100%', width: '46%' }}
                dropdownStyle={{ fontSize: '18px', lineHeight: '24px' }}
                className="custom-phone-input"
              />
            </div>
          </div>


          <Input
            id="inputWrap"
            label="Email"
            type="text"
            placeholder="name@company.ca"
            labelSize="12px"
            fontSize="18px"
            value={data?.email}
            setValue={(val) => { onChangeData('email', val) }}
            validateFunc={(item) => emailRE.test(item)}
            noCheckIcon={true}
            noErrorBorder={true}
            style = {{marginLeft: '-400px'}}
          />

        </StyledAddressContainer>

        <Input
          id="inputWrap"
          label="Company Name"
          type="text"
          placeholder="Company Name"
          labelSize="12px"
          fontSize="18px"
          value={data?.company_name}
          setValue={(val) => { onChangeData('company_name', val) }}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
        <Input
          id="inputWrap"
          label="Relationship"
          type="text"
          placeholder="Relationship"
          labelSize="12px"
          fontSize="18px"
          value={data?.relationship}
          setValue={(val) => { onChangeData('relationship', val) }}
          validateFunc={(item) => item.length > 0}
          noCheckIcon={true}
          noErrorBorder={true}
        />
      </FormArea>
      <FunctionalButton
        buttonTitle={"ASK FOR REFERENCE"}
        handleButton={handleButton}
        width={isMobile ? "90%" : "368px"}
        marginRight="0px"
        marginTop="48px"
        disabled={disabled}
      />
    </>

  )
}
