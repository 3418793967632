import React, { useState, useEffect } from "react";
import PledgxLogo from "assets/common/pledgxLogo";
import Step1Icon from "assets/common/addProjectIcons/step1";
import { StepperProgress } from "components/stepperProgress";
import CloseIcon from "@material-ui/icons/Close";
import { CloseButton } from "./styles";
import updateObjectByPath from "common/updateObjectByPath";
import { PopupContent, PopupTitle, PopupFields, ButtonWrap } from "./styles";
import { Step1 } from "./Step1";
import { FunctionalButton } from "components/functionalButton";


function SwitchComponents({ active, children }) {
  return children.filter((child) => child.props.step === active);
}

export const Steps = ({
  setOpenPopup,
  onboardingData,
  setOnboardingData,
  handleButtonFunc,
  isMobile,
  handleNext,
}) => {
  const [step, setStep] = useState(0);
  const iconList = [Step1Icon];
  const [disabled, setDisable] = useState(true);

  const [addData, setAddData] = useState({ ...onboardingData?.project_info });
  const [editIndex, setEditIndex] = useState(null);

  const onChangeData = (attribute, val) => {
    const updatedData = { ...addData };
    updateObjectByPath(updatedData, attribute, val);
    setAddData({ ...updatedData });
  };

  const handleNextStep = () => {
    setOpenPopup(false);
    handleNext();
  };

  useEffect(() => {
    const project_info = {
      ...onboardingData.project_info,
      project_title: addData?.project_title,
      project_number: addData?.project_number,
      project_city: addData?.project_city,
      categories: addData?.categories,
      is_unspc_category_type: addData?.is_unspc_category_type,
      initialSubCategory: []
    };

    setOnboardingData("project_info", project_info);
  }, [addData]);

  return (
    <>
      <PopupContent>
        <CloseButton
          aria-label="close"
          onClick={() => {
            setOpenPopup(false);
            setEditIndex(null);
          }}
        >
          <CloseIcon />
        </CloseButton>
        <PledgxLogo />
        <PopupTitle>Add a Project</PopupTitle>
        <PopupFields isMobile={isMobile}>
          <Step1
            step={0}
            isMobile={isMobile}
            setDisable={setDisable}
            addData={addData}
            setAddData={onChangeData}
          />
        </PopupFields>
        <ButtonWrap>
          <FunctionalButton
            width="300px"
            marginTop="16px"
            marginRight="0px"
            handleButton={handleNextStep}
            buttonTitle={"SAVE"}
            disabled={false}
          />
        </ButtonWrap>
      </PopupContent>
    </>
  );
};
