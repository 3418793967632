import moment from "moment";

function displayDate(props) {
  /*
  props:
  - date (Date): Date to be used. If not provided, the current client date will be used
  - prepend (Obj): Object used to contextually prepend date. Ex. { future: 'expires on', past: 'expired on' }
  - style (string): Preset styles
  */
  var prependObj = props.prepend;
  if (props.style) {
    switch (props.style) {
      case "EXPIRY":
        prependObj = {
          future: "Expires",
          past: "Expired",
        };
    }
  }
  try {
    var dateString = props.prepend ? "unknown date" : "Unknown date";
    try {
      var date = props.date ? moment.utc(props.date) : moment();
      var dateYear = date.year();
      var dateMonth = date.month() + 1;
      var dateDate = date.date();
      var time = date.format("hh:mm a");
      dateString = `${dateMonth}-${dateDate}-${dateYear}, ${time}`;
    } catch {}

    return [
      prependObj
        ? date > new Date()
          ? `${prependObj.future} `
          : `${prependObj.past} `
        : "",
      dateString,
    ].join("");
  } catch {}

  return "Unknown date";
}

export default displayDate;
