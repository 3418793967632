import React from 'react';
import category from './category_grey.svg'

const Category_grey = ({ isMobile }) => {
  return (
    <img width={isMobile ? "36" : "56"} height={isMobile ? "36" : "56"} src={category} />
  )
};

export default Category_grey;
