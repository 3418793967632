import React from "react";

const VerticalLine = () => {
  return (
    <div
      style={{
        width: "0px",
        // margin: "70px 0",
        marginTop: "60px",
        height: "675px",
        backgroundColor: "#000",
        border: "1px solid #ECE9F1",
      }}
    ></div>
  );
};

export default VerticalLine;
