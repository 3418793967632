import React, { useEffect, useState } from "react";
import { RowContent, SmallRateBox, SmallRateText } from './styles';

const pickColor = (rating) => {
  if( rating > 8 && rating < 11){
    return "#43B868"
  }
  if( rating > 6 && rating < 9){
    return "#8DD655"
  }
  if( rating > 4 && rating < 7){
    return "#FEC65C"
  }
  if( rating > 2 && rating < 5){
    return "#FF6D1D"
  }
  if( rating > 0 && rating < 3){
    return "#FF0000"
  }
  return false;
}

export const RatingBar = ({rating}) => {
  return (
    <RowContent justifyContent="space-between">
       {[...Array(rating ? rating : 0)].map((x, i) => {return (
          <SmallRateBox color={pickColor(rating)}>
            <SmallRateText>{i+1}</SmallRateText>
          </SmallRateBox>
       )})}
       {[...Array(10-(rating ? rating : 0))].map((x, i) => {return (
          <SmallRateBox color={false}>
            <SmallRateText>{rating+i+1}</SmallRateText>
          </SmallRateBox>
       )})}
    </RowContent>
  )
}