import React, { useState } from "react";
import PledgxLogo from "../../assets/common/pledgxLogo";
import Step1Icon from "../../assets/common/addProjectIcons/step1";
import Step2Icon from "../../assets/common/addProjectIcons/step2";
import Step3Icon from "../../assets/common/addProjectIcons/step3";
import Step4Icon from "../../assets/common/addProjectIcons/step4";
import { StepperProgress } from "../stepperProgress";
import { FunctionalButton } from "../functionalButton";
import CloseIcon from "@material-ui/icons/Close";
import { CloseButton } from "./styles";
import updateObjectByPath from "../../common/updateObjectByPath";

import {
  PopupContent,
  PopupTitle,
  PopupFields,
  ButtonWrap,
  StepperContainer,
} from "./styles";

import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { Step4 } from "./Step4";
import { Step5 } from "./Step5";
import { Step6 } from "./Step6";

function SwitchComponents({ active, children }) {
  return children.filter((child) => child.props.step === active);
}

export const Steps = ({
  setOpenPopup,
  onboardingData,
  setOnboardingData,
  initialSubCategory,
  handleButtonFunc,
  isMobile,
}) => {
  const [step, setStep] = useState(0);
  const iconList = [Step1Icon, Step2Icon, Step3Icon, Step4Icon];
  const [disabled, setDisable] = useState(false);

  const stepLabels = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5", "Step 6"];

  const [addData, setAddData] = useState({ ...onboardingData });
  const [editIndex, setEditIndex] = useState(null);

  const onChangeData = (attribute, val) => {
    const updatedData = { ...addData };
    updateObjectByPath(updatedData, attribute, val);
    setAddData({ ...updatedData });
  };

  const handleNext = () => {
    if (step < 5 && (addData?.user_type === "Contractor" || addData?.user_type === "Contractor & Buyer")) {
      setStep(step + 1);
    } else if (step < 4 && (addData?.user_type !== "Contractor" && addData?.user_type !== "Contractor & Buyer")) {
      setStep(step + 1);
    } else {
      let updatedClientData = [];

      if (onboardingData) {
        updatedClientData = [onboardingData];
      }
      updatedClientData.unshift({ ...addData });
      setOnboardingData(updatedClientData);
      if (handleButtonFunc) {
        handleButtonFunc(addData);
      }
      setOpenPopup(false);
    }
  };

  return (
    <>
      <PopupContent>
        <CloseButton
          aria-label="close"
          onClick={() => {
            setOpenPopup(false);
            setEditIndex(null);
          }}
        >
          <CloseIcon />
        </CloseButton>
        <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
        <StepperContainer isMobile={isMobile}>
          <StepperProgress
            activeStep={step}
            setActiveStep={setStep}
            iconList={iconList}
            stepLabels={stepLabels}
            connectorWidth={isMobile ? "40px" : "60px"}
            isMobile={isMobile}
          />
        </StepperContainer>
        {(addData?.user_type === "Contractor" || addData?.user_type === "Contractor & Buyer") && (
          <>
            <PopupTitle>Edit Client</PopupTitle>
            <PopupFields isMobile={isMobile}>
              <SwitchComponents active={step}>
                <Step1
                  step={0}
                  isMobile={isMobile}
                  setDisable={setDisable}
                  addData={addData}
                  setAddData={onChangeData}
                />
                <Step2
                  step={1}
                  isMobile={isMobile}
                  setDisable={setDisable}
                  addData={addData}
                  setAddData={onChangeData}
                />
                <Step3
                  step={2}
                  isMobile={isMobile}
                  setDisable={setDisable}
                  addData={addData}
                  setAddData={onChangeData}
                />
                <Step4
                  step={3}
                  isMobile={isMobile}
                  setDisable={setDisable}
                  addData={addData}
                  setAddData={onChangeData}
                  initialSubCategory={initialSubCategory}
                />
                <Step5
                  step={4}
                  isMobile={isMobile}
                  setDisable={setDisable}
                  addData={addData}
                  setAddData={onChangeData}
                />
                <Step6
                  step={5}
                  isMobile={isMobile}
                  setDisable={setDisable}
                  addData={addData}
                  setAddData={onChangeData}
                />
              </SwitchComponents>

            </PopupFields>

            <ButtonWrap>
              <FunctionalButton
                width="300px"
                marginTop="16px"
                marginRight="0px"
                handleButton={handleNext}
                buttonTitle={step < 5 ? "NEXT" : "SAVE"}
                disabled={disabled}
              />
            </ButtonWrap>
          </>
        )}

        {(addData?.user_type !== "Contractor" && addData?.user_type !== "Contractor & Buyer") && (
          <>
            <PopupTitle>Edit Client</PopupTitle>
            <PopupFields isMobile={isMobile}>
              <SwitchComponents active={step}>
                <Step1
                  step={0}
                  isMobile={isMobile}
                  setDisable={setDisable}
                  addData={addData}
                  setAddData={onChangeData}
                />
                <Step2
                  step={1}
                  isMobile={isMobile}
                  setDisable={setDisable}
                  addData={addData}
                  setAddData={onChangeData}
                />
                <Step3
                  step={2}
                  isMobile={isMobile}
                  setDisable={setDisable}
                  addData={addData}
                  setAddData={onChangeData}
                />
                <Step5
                  step={3}
                  isMobile={isMobile}
                  setDisable={setDisable}
                  addData={addData}
                  setAddData={onChangeData}
                />
                <Step6
                  step={4}
                  isMobile={isMobile}
                  setDisable={setDisable}
                  addData={addData}
                  setAddData={onChangeData}
                />
              </SwitchComponents>

            </PopupFields>

            <ButtonWrap>
              <FunctionalButton
                width="300px"
                marginTop="16px"
                marginRight="0px"
                handleButton={handleNext}
                buttonTitle={step < 4 ? "NEXT" : "SAVE"}
                disabled={disabled}
              />
            </ButtonWrap>

          </>
        )}



      </PopupContent>
    </>
  );
};
