export const businessOptions = [
  { value: "Landscaping/Parks/Trails", label: "Landscaping/ Parks/ Trails" },
  { value: "Recreation", label: "Recreation" },
  { value: "Residential", label: "Residential" },

  { value: "Roads/Bridges/Sidewalks", label: "Roads/ Bridges/ Sidewalks" },
  { value: "Sewers/Watermains", label: "Sewers/ Watermains" },
  { value: "Infrastructure", label: "Infrastructure" },

  { value: "Renovation", label: "Renovation" },
  { value: "Restoration", label: "Restoration" },
  { value: "Demolition", label: "Demolition" },
  { value: "Doors/Windows/Locks", label: "Doors/ Windows/ Locks" },
  { value: "Roofing", label: "Roofing" },
];

export const CategoryColor = {
  "Landscaping/Parks/Trails": "green",
  Recreation: "green",
  Residential: "green",

  "Roads/Bridges/Sidewalks": "yellow",
  "Sewers/Watermains": "yellow",
  Infrastructure: "yellow",

  Renovation: "purple",
  Restoration: "purple",
  Demolition: "purple",
  "Doors/Windows/Locks": "purple",
  Roofing: "purple",
};
