import { combineReducers } from "redux";

import user from "./user";
import tenders from "./tenders";
import buyers from "./buyers";
import news from "./news";
import overview from "./overview";
import notifications from "./notifications";
import contact from "./contact";
import subscriptions from "./subscriptions";
import systemMessages from "./systemMessages";
import underwriters from "./underwriters";
import serviceProvider from "./serviceProvider";

const appReducer = combineReducers({
  user,
  tenders,
  buyers,
  news,
  overview,
  notifications,
  contact,
  subscriptions,
  systemMessages,
  underwriters,
  serviceProvider,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
