import styled from "styled-components";
import { Button } from "@material-ui/core";
import { COLORS } from "../../constants";

export const FloatingContainer = styled.div`
  position: fixed;
  //bottom: 70px;
  right: 110px;
  z-index: 1;
`;

export const StyledButton = styled(Button)`
  && {
    width: ${(props) => props.width || "350px"};
    height: ${(props) => props.height || "48px"};
    margin-right: ${(props) => props.marginRight || "20px"};
    margin-left: ${(props) => props.marginLeft || "0px"};
    margin-top: ${(props) => props.marginTop || "0px"};
    margin-bottom: ${(props) => props.marginBottom || "0px"};

    background-color: ${(props) =>
      props.isReversedColor ? "white" : `${props.borderColor || "#FF6D1D"}`};
    border: 1.5px solid ${(props) => props.borderColor || "#FF6D1D"};
    box-sizing: border-box;
    border-radius: 6px;

    ${(props) =>
      props.hasBoxShadow ? "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;" : ""}
    color: ${(props) =>
      props.isReversedColor ? `${props.borderColor || "#FF6D1D"}` : "white"};

    font-size: ${(props) => props.fontSize || "17px"};
    font-weight: ${(props) => props.weight || "600"};
    transition: all 0.5s ease;
    text-transform: capitalize;

    &:hover {
      background-color: ${(props) =>
        props.isReversedColor || props.selected
          ? `${props.borderColor || "#FF6D1D"}`
          : "white"};
      color: ${(props) =>
        props.isReversedColor || props.selected
          ? "white"
          : `${props.borderColor || "#FF6D1D"}`};
    }

    &.Mui-disabled {
      background-color: #fff3ec;
      color: ${COLORS.secondaryOrange} !important;
      border: 1.5px solid #fff3ec;
    }
  }
`;
