import styled from "styled-components";
import { StyledTableCell } from "components/filesListTable/styles";
import { TYPOGRAPHY, COLORS } from "../../../../../constants";


export const StyledTableCellFirst = styled(StyledTableCell)`
  width: 150px;

  &&.MuiTableCell-root:first-child  {
    border: none;
  }

  &&.MuiTableCell-root {
    border: none;
  }
`;

export const StyledIndexCell = styled(StyledTableCellFirst)`
  width: 50px;
`;

export const StyledTableCellSecond = styled(StyledTableCell)`
  width: 200px;

  &&.MuiTableCell-root:first-child {
    border: none;
  }

  &&.MuiTableCell-root {
    border: none;
  }
`;

export const StyledTableCheckBoxCell= styled(StyledTableCell)`
  width: 10px;

  &&.MuiTableCell-root:last-child {
    border: none;
  }

  &&.MuiTableCell-root{
    border: none;
  }
`;

export const StyledTableCenteringContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledMinusImg = styled.img`
  width: 5px;
  cursor: pointer;
`;

export const StyledImgIcon = styled.img`
  margin-top: 5px;
  cursor: pointer;
`;

export const PersonnelCardRoot = styled.div`
  position: relative;
  // margin-right: 8px;

  width: 200px;
  min-height: 130px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${COLORS.primaryLightOrange};
  background-color: white;
`;

export const StyledCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  margin-top: 16px;
  min-height: 60px;
  flex-grow: 1px;
  width: 100%;
`;

export const StyledName = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledProfileImage = styled.div`
  display: flex;
  flex-direction: center;
`;

export const StyledPosition = styled.div`
  font-family: SF Pro Text;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
`;