import React from 'react';
import requirements from './requirements_color.svg'

const Requirements_color = ({ isMobile }) => {
  return (
    <img width={isMobile ? "36" : "56"} height={isMobile ? "36" : "56"} src={requirements} />
  )
};

export default Requirements_color;
