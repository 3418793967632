import React from "react";
import { StyledCheckBox, StyledUncheckBox } from "./styles";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

export default function RoundedCheckbox({
  attributeTypes,
  onChange,
  readOnly,
  value,
  disabled,
}) {
  return (
    <React.Fragment>
      <StyledCheckBox
        classes={{ root: "custom-checkbox-root" }}
        icon={<CircleUnchecked />}
        checkedIcon={<CircleCheckedFilled />}
        value={value}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        checked={attributeTypes && attributeTypes.includes(value)}
      />
    </React.Fragment>
  );
}

const RoundedUnCheckedBox = ({ value, onChange, attributeTypes }) => {
  return (
    <React.Fragment>
      <StyledUncheckBox
        classes={{ root: "custom-checkbox-root" }}
        icon={<CircleUnchecked />}
        disabled
        checkedIcon={<CircleCheckedFilled />}
        value={value}
        onChange={onChange}
        checked={attributeTypes && attributeTypes.includes(value)}
      />
    </React.Fragment>
  );
};

export { RoundedUnCheckedBox, RoundedCheckbox };
