import React from 'react';
import organized from './organized.svg';
import { StyledImg } from './styles';
const OrganizedIcon = ({ width, height, noGreyFilter }) => {
  return (
    <StyledImg width={width} heigh={height} src={organized} noGreyFilter={noGreyFilter} />
  )
};

export default OrganizedIcon;
