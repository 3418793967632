import React, { useState, useEffect, useRef } from "react";

import {
  Error,
  InputLabel,
  StyledDatePicker,
  StyledInnerDiv,
  StyledInput,
  StyledMainDiv,
  StyledReadonlyInput,
  StyledSelect,
} from "./styles";
import { FaCalendarAlt } from "react-icons/fa";
import { FaClock } from "react-icons/fa";

const DateTimePickerField = ({
  width,
  dateWidth,
  showDateField = false,
  showTimeField = false,
  paddingRight,
  dateLabel,
  labelSize,
  labelWeight,
  withoutMargin,
  marginTop,
  timeLabel,
  dateValue,
  setDateValue,
  readOnly,
  showBorder,
  backgroundColor,
  height,
  fontSize,
  paddingLeft,
  fontColor,
  textAlign,
  timeValue,
  setTimeValue,
  disabled = false,
}) => {
  const parseDateString = (dateString) => {
    const date = new Date(dateString + "T00:00:00Z"); // Append time and 'Z' to indicate UTC

    if (isNaN(date)) {
      return null; // Parsing failed
    }

    const timezoneOffset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() + timezoneOffset * 60000);

    return adjustedDate;
  };

  const parseTimeString = (timeString) => {
    if (timeString) {
      const time_string = timeString || "";
      const delimiter = ":"; // Custom delimiter character
      const timeArray = time_string?.split(delimiter);
      if (timeArray) {
        const hours = parseInt(timeArray[0], 10);
        const minutes = parseInt(timeArray[1], 10);
        const convertedDate = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          hours,
          minutes
        );
        return convertedDate;
      }
    }
  };
  const [date, setDate] = useState(
    dateValue ? parseDateString(dateValue) : new Date()
  );

  const locale = navigator.language;

  const [errorMessage, setErrorMessage] = useState(null);

  const [time, setTime] = useState(timeValue ? parseTimeString(timeValue) : "");

  const handleDateFormat = (val) => {
    let formattedDate = null;
    if (val) {
      const year = val.getFullYear();
      const month = val.getMonth();
      const Day = val.getDate();
      formattedDate = `${year}-${month + 1 < 10 ? "0" : ""}${month + 1}-${
        Day < 10 ? "0" : ""
      }${Day}`;
    }
    return formattedDate;
  };
  const localeDateFormat = Intl.DateTimeFormat(locale)
    .formatToParts()
    .map(({ type }) => {
      switch (type) {
        case "day":
          return "dd";
        case "month":
          return "MM";
        case "year":
          return "yyyy";
        case "literal":
          return "-";
        default:
          return type;
      }
    })
    .join("");

  const localePlaceholderDateFormat = Intl.DateTimeFormat(locale)
    .formatToParts()
    .map(({ type }) => {
      switch (type) {
        case "day":
          return "DD";
        case "month":
          return "MM";
        case "year":
          return "YYYY";
        case "literal":
          return "-";
        default:
          return type;
      }
    })
    .join("");

  const placeholderTimeFormat = Intl.DateTimeFormat(locale, {
    hour: "numeric",
    minute: "numeric",
    hour12: true, // or false for 24-hour format
  })
    .formatToParts()
    .map(({ type, value }) => {
      switch (type) {
        case "hour":
          return "HH";
        case "minute":
          return "MM";
        case "literal":
          return value;
        case "dayPeriod":
          return "";
        default:
          return type;
      }
    })
    .join("");

  const CustomInput = ({ value, onClick, onChange }) => (
    <>
      {readOnly ? (
        <StyledReadonlyInput
          value={value}
          showBorder={showBorder}
          backgroundColor={backgroundColor}
          height={height}
          width={dateWidth}
          fontSize={fontSize}
          textAlign={textAlign}
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
          fontColor={fontColor}
          readOnly
        />
      ) : (
        <StyledMainDiv onClick={onClick} width="100%" disabled={disabled}>
          <StyledInput
            type="text"
            value={value}
            onChange={onChange}
            placeholder={localePlaceholderDateFormat}
            disabled={disabled}
          />

          <FaCalendarAlt
            style={{
              marginRight: "1rem",
              fontSize: "1.25rem",
              color: "black",
            }}
            className="icon"
          />

          {/* {errorMessage && <Error>{errorMessage}</Error>} */}
        </StyledMainDiv>
      )}
    </>
  );

  const CustomTimeInput = ({ value, onClick, onChange }) => (
    <>
      {readOnly ? (
        <StyledReadonlyInput
          value={value}
          showBorder={showBorder}
          backgroundColor={backgroundColor}
          height={height}
          width={width}
          fontSize={fontSize}
          textAlign={textAlign}
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
          fontColor={fontColor}
          readOnly
        />
      ) : (
        <StyledMainDiv onClick={onClick} width={width}>
          <StyledInput
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholderTimeFormat}
          />
          <FaClock
            style={{
              marginRight: "1rem",
              fontSize: "1.25rem",
              color: "black",
            }}
            className="icon"
          />
        </StyledMainDiv>
      )}
    </>
  );

  function isValidDate(dateString) {
    const newDate = new Date(dateString);
    return newDate instanceof Date && !isNaN(newDate);
  }

  const handleTimeChange = (value) => {
    if (!value) {
      setTime(null);
      return;
    }

    const selectedDateTime = new Date(date.getTime());

    selectedDateTime.setHours(value.getHours());
    selectedDateTime.setMinutes(value.getMinutes());

    let formattedTime = null;
    if (value) {
      const hours = value.getHours();
      const minutes = value.getMinutes();

      formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
    }

    setTime(selectedDateTime);

    setTimeValue(formattedTime);
  };
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const CustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
    let year = [];
    Array.from({ length: 100 }).map((_, offset) => {
      return year.push(new Date().getFullYear() - 50 + offset);
    });

    return (
      <div>
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          {"<"}
        </button>

        <StyledSelect
          value={date.getFullYear()}
          onChange={({ target: { value } }) => {
            changeYear(value);
          }}
        >
          {year.map((year) => {
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </StyledSelect>
        <StyledSelect
          value={months[date.getMonth()]}
          onChange={({ target: { value } }) =>
            changeMonth(months.indexOf(value))
          }
        >
          {months.map((month, index) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </StyledSelect>
        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          {">"}
        </button>
      </div>
    );
  };
  return (
    <div
      style={{
        width: dateWidth ? dateWidth : "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      {showDateField && (
        <StyledInnerDiv
          paddingRight={paddingRight}
          style={{
            marginTop: withoutMargin ? 0 : marginTop ? marginTop : "25px",
          }}
        >
          {dateLabel && (
            <InputLabel labelSize={labelSize} labelWeight={labelWeight}>
              {dateLabel}
            </InputLabel>
          )}

          <StyledDatePicker
            //showIcon={true}
            strictParsing={true}
            dateFormat={localeDateFormat}
            selected={dateValue ? parseDateString(dateValue) : "" || ""}
            minDate={new Date(100, 0, 1)}
            onChange={(date) => {
              const formattedValue = handleDateFormat(date);
              setDateValue(formattedValue);
            }}
            // showMonthDropdown
            // showYearDropdown
            // dropdownMode="select"
            customInput={<CustomInput />}
            readOnly={readOnly}
            disabled={disabled}
            renderCustomHeader={CustomHeader}
          />
        </StyledInnerDiv>
      )}
      {showTimeField && (
        <StyledInnerDiv
          paddingRight={paddingRight}
          style={{
            marginTop: withoutMargin ? 0 : marginTop ? marginTop : "25px",
          }}
        >
          {timeLabel && (
            <InputLabel labelSize={labelSize} labelWeight={labelWeight}>
              {timeLabel}
            </InputLabel>
          )}
          <StyledDatePicker
            //showIcon={true}
            strictParsing={true}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeFormat="HH:mm"
            dateFormat={Intl.DateTimeFormat(locale, {
              hour: "numeric",
              minute: "numeric",
              hour12: true, // or false for 24-hour format
            })
              .formatToParts()
              .map(({ type, value }) => {
                switch (type) {
                  case "hour":
                    return "HH";
                  case "minute":
                    return "mm";
                  case "literal":
                    return value;
                  case "dayPeriod":
                    return "";
                  default:
                    return type;
                }
              })
              .join("")}
            selected={timeValue ? parseTimeString(timeValue) : "" || ""}
            onChange={handleTimeChange}
            customInput={<CustomTimeInput />}
            // timeCaption="Time"
          />
        </StyledInnerDiv>
      )}
    </div>
  );
};

export default DateTimePickerField;
