import React from 'react';

const CheckedRadioButton = (props) => {
  return ( 
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="11" stroke={ props?.color || '#FF6D1D' } stroke-width="2"/>
    <circle cx="12" cy="12" r="7" fill={ props?.color || '#FF6D1D' }/>
    </svg>
  )
};

export default CheckedRadioButton;


