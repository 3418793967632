import styled from "styled-components";
import { Avatar } from "@material-ui/core";


export const CompanyLogo = styled(Avatar)`
  && {
    width: ${props => props.isMobile ? "121px" : "182px"};
    height: ${props => props.isMobile ? "114px" : "182px"};+

    border-radius: 5px;
  }
`;

export const LogoPlacementContainer = styled.img`
  width: ${props => props.isMobile ? "121px" : "182px"};
  height: ${props => props.isMobile ? "114px" : "171px"};
`;
