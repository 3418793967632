import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { StyledTextField } from "../../../../pages/newPreQualify/components/safetyRecords/styles";
import { NumberFormatOnly } from "components/locale/numberFormatOnly.js";
import Input from "components/inputField";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";
import DocumentPoolPopUp from "components/documentPoolPopUp";
import { WrapperForUploadedHealthSafetyFileDisplay } from "pages/preQualifyForm/components/uploadedHealthSafetyFileDisplay";
import ConciseTable from "components/filesListTable/conciseTable";
import { COLORS } from "../../../../constants";
import { ScrollableDiv } from "pages/documentPool/styles";
import { StyledSubtext } from "pages/loginPage/styles";

import {
  StyledQuestion,
  StyledAddressContainer,
  StyledInputLabelContainer,
  StyledCenter,
} from "../styles";

import { FunctionalButton } from "../../../../components/functionalButton";
import { FieldArea } from "pages/contractorDashboard/profileSetup/styles";
import { LabelText } from "../safetyRecords/styles";

export const FillValueConstruction = ({
  handleNext,
  setIsLastPanel,
  prequalifyData,
  setPrequalifyData,
  institutionId,
}) => {
  setIsLastPanel(true);

  const isMobile = useMediaQuery({ maxWidth: 960 });
  const currentYear = new Date().getFullYear();
  const [internalSearchResults, setInternalSearchResults] = useState(
    prequalifyData?.annual?.financial_records_documents || []
  );
  const [query, setQuery] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [openDocumentPool, setOpenDocumentPool] = useState(false);
  const headers = [];
  const [selectedFiles, setSelectedFiles] = useState([]);

  const deleteFinancialRecordsFile = (index) => {
    let temp = [...prequalifyData?.annual?.financial_records_documents];
    temp.splice(index, 1);
    setPrequalifyData("annual.financial_records_documents", temp);
  };

  const addUploadedFile = () => {
    let temp = [...prequalifyData?.annual?.financial_records_documents];

    for (let key in selectedFiles) {
      if (!isNaN(key)) {
        temp.push(selectedFiles[key]);
      }
    }

    setPrequalifyData("annual.financial_records_documents", temp);
    setOpenDocumentPool(false);
  };

  const handleDocumentPoolPopUp = () => {
    setOpenDocumentPool(true);
  };

  const [valueCurrent, setValueCurrent] = useState(
    prequalifyData?.annual["annual_values"]?.[currentYear]
      ? NumberFormatOnly(prequalifyData?.annual["annual_values"]?.[currentYear])
      : 0
  );
  const [valuePrev, setValuePrev] = useState(
    prequalifyData?.annual["annual_values"]?.[currentYear - 1]
      ? NumberFormatOnly(
          prequalifyData?.annual["annual_values"]?.[currentYear - 1]
        )
      : 0
  );
  const [valuePrevv, setValuePrevv] = useState(
    prequalifyData?.annual["annual_values"]?.[currentYear - 2]
      ? NumberFormatOnly(
          prequalifyData?.annual["annual_values"]?.[currentYear - 2]
        )
      : 0
  );
  const [valuePrevvv, setValuePrevvv] = useState(
    prequalifyData?.annual["annual_values"]?.[currentYear - 3]
      ? NumberFormatOnly(
          prequalifyData?.annual["annual_values"]?.[currentYear - 3]
        )
      : 0
  );
  const [valuePrevvvv, setValuePrevvvv] = useState(
    prequalifyData?.annual["annual_values"]?.[currentYear - 4]
      ? NumberFormatOnly(
          prequalifyData?.annual["annual_values"]?.[currentYear - 4]
        )
      : 0
  );
  const [constructionRemark, setConstructionRemark] = useState(
    prequalifyData?.annual?.remark_records?.annual_construction_value_remark ||
      ""
  );

  const handleConstructionRemarkChange = (event) => {
    setConstructionRemark(event.target.value);
  };

  useEffect(() => {
    setPrequalifyData(
      `annual.remark_records.annual_construction_value_remark`,
      constructionRemark
    );
  }, [constructionRemark]);

  const valueFormat = (e) => {
    setValueCurrent(e);
    setPrequalifyData(`annual.annual_values.${currentYear}`, e);
  };

  return (
    <>
      <StyledQuestion isMobile={isMobile} width="650px">
        Annual Value of Construction work projected for current year and past
        four years
      </StyledQuestion>
      <StyledInputLabelContainer marginRight="325">
        <Input
          label="Current Year(forecast)"
          type="text"
          id="inputWrap"
          value={valueCurrent}
          marginTop="0px"
          labelSize="12px"
          setValue={(e) => valueFormat(e)}
          onBlur={(e) => setValueCurrent(NumberFormatOnly(e.target.value))}
          noErrorBorder={true}
          noCancelIcon={true}
          noCheckIcon={true}
          width={"310px"}
          fontSize={"18px"}
        />
      </StyledInputLabelContainer>
      <StyledAddressContainer>
        <StyledInputLabelContainer>
          <Input
            label={currentYear - 1}
            type="text"
            id="inputWrap"
            marginTop="0px"
            labelSize="12px"
            value={valuePrev}
            setValue={(e) => {
              setValuePrev(e);
              setPrequalifyData(`annual.annual_values.${currentYear - 1}`, e);
            }}
            onBlur={(e) => setValuePrev(NumberFormatOnly(e.target.value))}
            validateFunc={(item) => String(item).length > 0}
            noErrorBorder={true}
            noCancelIcon={true}
            noCheckIcon={true}
            width={"310px"}
            fontSize={"18px"}
          />
        </StyledInputLabelContainer>
        <StyledInputLabelContainer>
          <Input
            label={currentYear - 2}
            type="text"
            id="inputWrap"
            marginTop="0px"
            labelSize="12px"
            value={valuePrevv}
            setValue={(e) => {
              setValuePrevv(e);
              setPrequalifyData(`annual.annual_values.${currentYear - 2}`, e);
            }}
            onBlur={(e) => setValuePrevv(NumberFormatOnly(e.target.value))}
            noErrorBorder={true}
            noCancelIcon={true}
            noCheckIcon={true}
            width={"310px"}
            fontSize={"18px"}
          />
        </StyledInputLabelContainer>
      </StyledAddressContainer>
      <StyledAddressContainer>
        <StyledInputLabelContainer>
          <Input
            label={currentYear - 3}
            type="text"
            id="inputWrap"
            marginTop="0px"
            labelSize="12px"
            value={valuePrevvv}
            setValue={(e) => {
              setValuePrevvv(e);
              setPrequalifyData(`annual.annual_values.${currentYear - 3}`, e);
            }}
            onBlur={(e) => setValuePrevvv(NumberFormatOnly(e.target.value))}
            validateFunc={(item) => String(item).length > 0}
            noErrorBorder={true}
            noCancelIcon={true}
            noCheckIcon={true}
            width={"310px"}
            fontSize={"18px"}
          />
        </StyledInputLabelContainer>
        <StyledInputLabelContainer>
          <Input
            label={currentYear - 4}
            type="text"
            id="inputWrap"
            marginTop="0px"
            labelSize="12px"
            value={valuePrevvvv}
            setValue={(e) => {
              setValuePrevvvv(e);
              setPrequalifyData(`annual.annual_values.${currentYear - 4}`, e);
            }}
            onBlur={(e) => setValuePrevvvv(NumberFormatOnly(e.target.value))}
            validateFunc={(item) => String(item).length > 0}
            noCancelIcon={true}
            noErrorBorder={true}
            noCheckIcon={true}
            width={"310px"}
            fontSize={"18px"}
          />
        </StyledInputLabelContainer>
      </StyledAddressContainer>
      <FieldArea width="74%" noJustifyContent={true}>
        <LabelText style={{ fontSize: "12px" }}> Remarks </LabelText>
        <StyledTextField
          multiline
          rows={5}
          placeholder={"Remark"}
          variant="outlined"
          value={constructionRemark}
          onChange={handleConstructionRemarkChange}
        />
      </FieldArea>

      <StyledSubtext style={{ marginTop: "28px", marginLeft: "-370px" }}>
        {" "}
        Attach all relevant Financial documents:{" "}
      </StyledSubtext>

      <div style={{ display: "flex", width: "74%" }}>
        <div
          style={{
            marginLeft: "0px",
            display: "flex",
            justifyContent: "flex-start",
            width: "70%",
          }}
        >
          <SearchBar
            filteredData={prequalifyData?.annual?.financial_records_documents}
            setInternalSearchResults={setInternalSearchResults}
            internalSearch={true}
            query={query}
            setQuery={setQuery}
            searchWidth="100%"
            searchPlaceHolder="File Name..."
          />
        </div>
        <FunctionalButton
          buttonTitle="Document Pool"
          marginLeft="20px"
          handleButton={() => handleDocumentPoolPopUp()}
          width="250px"
          isReversedColor={true}
        />
      </div>

      {prequalifyData?.annual?.financial_records_documents?.length < 1 && (
        <div
          style={{
            alignItems: "center",
            backgroundColor: "white",
            border: `dashed 2px ${COLORS.lightGrey1}`,
            display: "flex",
            height: "186px",
            color: `${COLORS.textGrey}`,
            justifyContent: "center",
            width: "74%",
            marginTop: "20px",
          }}
        >
          Selected Documents will appear here
        </div>
      )}
      {prequalifyData?.annual?.financial_records_documents?.length > 0 && (
        <div style={{ marginTop: "20px", width: "74%" }}>
          <ConciseTable headers={headers} />

          <ScrollableDiv style={{ maxHeight: "190px" }}>
            <div style={{ marginRight: "15px" }}>
              {prequalifyData?.annual?.financial_records_documents?.length >
                0 &&
                (query && query !== ""
                  ? internalSearchResults
                  : prequalifyData?.annual?.financial_records_documents
                )?.map((item, i) => (
                  <StyledCenter style={{ marginTop: 0 }}>
                    <WrapperForUploadedHealthSafetyFileDisplay
                      key={`file-${i}`}
                      file={item}
                      displayPercentage="100%"
                      deleteFile={deleteFinancialRecordsFile}
                      index={i}
                      setPrequalifyData={setPrequalifyData}
                      prequalifyData={prequalifyData}
                      setOpenPopup={setOpenPopup}
                      openPopup={openPopup}
                    />
                  </StyledCenter>
                ))}
            </div>
          </ScrollableDiv>
        </div>
      )}

      <DocumentPoolPopUp
        openPopup={openDocumentPool}
        setOpenPopup={setOpenDocumentPool}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        attachFile={addUploadedFile}
        institutionId={institutionId}
      />

      <FunctionalButton
        buttonTitle="Next"
        handleButton={() => handleNext()}
        width="200px"
        marginRight="0px"
        marginTop="48px"
      />
    </>
  );
};

export default FillValueConstruction;
