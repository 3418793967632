import React, { useEffect, useState } from "react";

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper,
  StyledMenuItem,
  StyledButton,
  StyledButtonReview,
  StyledButtonContainer,
} from "../addFinalizePopUp/styles";
import { COLORS } from "../../constants";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import PledgxLogo from "../../assets/common/pledgxLogo";
import { submitCqs } from "api/rfpq";
import { SuccessPopup } from "../../pages/contractorDashboard/profileSetup/SuccessPopup";
import { useSelector } from "react-redux";
import { contactSelector } from "data/selectors/contact";

export const AddSubmitPopUp = ({
  setOpenDialog,
  openDialog,
  isMobile,
  handleNext,
  data,
  ccdcData,
  submitButton,
  setPrequalifyData,
  setSubmitButton,
}) => {
  const [failMessage, setFailMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const contactData = useSelector(contactSelector);
  const [confirmMsg, setConfirmMsg] = useState("");
  const onlineSubmitLimit =
    contactData?.user_access_control?.action_counter_remaining?.online_submit;

  const handleClose = () => {
    setOpenDialog(false);
    setFailMessage(false);
    setSuccessMessage(false);
  };

  // add handle Go Back Button
  const handleGoBackClick = () => {
    handleClose();
  };

  //check if user already submitted cqs for this rfpq
  useEffect(() => {
    const submittedWithSameRfpqId = ccdcData?.filter(
      (item, index) =>
        item.status === "Submitted" &&
        item.rfpq_id !== null &&
        item.rfpq_id === data?.rfpq_id
    );

    if (submittedWithSameRfpqId?.length > 0) {
      setConfirmMsg(
        "you have already submitted a CQS for the selected RFP/Q. Do you want to resubmit a CQS now, replacing your previous submission?"
      );
    } else {
      setConfirmMsg("Do you want to submit CQS for selected RFP/Q?");
    }
  }, [ccdcData]);

  //Handle submit button to submit cqs for selected rfpq
  const handleSubmitClick = async () => {
    await submitCqs(
      data,
      () => {
        setSubmitButton(true);
        setOpenDialog(false);
        setSuccessMessage(true);
        setPrequalifyData("ccdc_status", "Submitted");
      },
      (err) => {
        setOpenDialog(false);
        setFailMessage(true);
        setErrorMessage(err.response.data.message);
      }
    );
  };

  return (
    <>
      {openDialog && (
        <Dialog
          open={openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
        >
          <StyledPaper elevation={0} isMobile={isMobile}>
            <CloseButton aria-label="close" onClick={() => handleClose()}>
              <CloseIcon />
            </CloseButton>

            <PledgxLogo
              width={isMobile ? 45 : ""}
              height={isMobile ? 45 : ""}
            />
            <InfoArea isMobile={isMobile} marginTop="0px">
              <FormTitle style={{ marginTop: 20, marginBottom: 20 }}>
                {confirmMsg}
              </FormTitle>
            </InfoArea>

            <StyledButtonContainer>
              <StyledMenuItem>
                <StyledButton
                  title="Go Back"
                  width="150px"
                  onClick={handleGoBackClick}
                >
                  Go Back
                </StyledButton>
              </StyledMenuItem>

              <StyledMenuItem>
                <StyledButton
                  title="Submit"
                  width="150px"
                  onClick={handleSubmitClick}
                >
                  CONFIRM
                </StyledButton>
              </StyledMenuItem>
            </StyledButtonContainer>
          </StyledPaper>
        </Dialog>
      )}
      {successMessage && (
        <SuccessPopup
          openPopup={successMessage}
          setOpenPopup={setSuccessMessage}
          descriptionText="Your Qualification Statement Has Been Submitted"
        />
      )}
      {failMessage && (
        <SuccessPopup
          openPopup={failMessage}
          setOpenPopup={setFailMessage}
          descriptionText={
            errorMessage ||
            "Your Qualification Statement Has not Been Submitted. Try Again!!!"
          }
          titleText="Error!!!"
          buttonText="Go Back"
        />
      )}
    </>
  );
};
