import get from "lodash/get";

const initialState = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_RECOMMENDED_TENDERS": {
      const payload = get(action, "payload", []);
      return {
        ...state,
        recommended: payload,
      };
    }
    case "LOAD_MORE_TENDERS": {
      const payload = get(action, "payload", []);
      const updRecommended = [...state.recommended];

      return {
        ...state,
        recommended: [...updRecommended, ...payload],
      };
    }
    case "GET_SAVED_TENDERS": {
      const payload = get(action, "payload", []);
      return {
        ...state,
        saved: payload,
      };
    }
    case "SAVE_TENDER": {
      const tender = get(action, "tender", {});
      const updSaved = [...state.saved];
      const updRecommended = [...state.recommended];

      updSaved.push(tender);

      const updIndex = updRecommended.findIndex(
        (item) => item.Id === tender.Id
      );
      updRecommended[updIndex].saved = true;

      return {
        ...state,
        saved: updSaved,
        recommended: updRecommended,
      };
    }
    case "DELETE_TENDER": {
      const tender = get(action, "tender", {});
      const updSaved = [...state.saved];
      const updRecommended = [...state.recommended];

      const updSavedTenderIndex = updSaved.findIndex(
        (item) => item.Id === tender.Id
      );
      updSaved.splice(updSavedTenderIndex, 1);

      const updIndex = updRecommended.findIndex(
        (item) => item.Id === tender.Id
      );
      if (updIndex !== -1) updRecommended[updIndex].saved = false;

      return {
        ...state,
        saved: updSaved,
        recommended: updRecommended,
      };
    }
    case "GET_RESULT_TENDERS": {
      const payload = get(action, "payload", []);
      return {
        ...state,
        result: payload,
      };
    }
    case "GET_RESULT_TENDERS_USERS": {
      const payload = get(action, "payload", []);
      const bidId = get(action, "bidId");
      let updContractors = { ...state.contractors };

      updContractors[bidId] = payload;

      return {
        ...state,
        contractors: updContractors,
      };
    }
    default:
      return state;
  }
};
