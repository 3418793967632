import axios from "axios";
import { API_HOST } from "../constants";

let headers = {};
const getAuth = () => {
  const authData = localStorage.getItem("Authorization");
  if (authData) {
    headers = { Authorization: authData };
  }
};

export const getWorkInProgress = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/workinprogress/singleproject`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      // if (err.response.status === 401) {
      //   localStorage.removeItem("Authorization");
      //   dispatchEvent(new Event("storage"));
      // }
      onFailure(err.response);
    });
};
export const getUnderwriterWorkInProgress = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/underwriter/workinprogress/singleproject`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      // if (err.response.status === 401) {
      //   localStorage.removeItem("Authorization");
      //   dispatchEvent(new Event("storage"));
      // }
      onFailure(err.response);
    });
};


export const viewPaymentProgressCertificateApproval = async (
  token1,
  token2,
  onSuccess,
  onFailure
) => {
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/wip/ppc/approval/${token1}/${token2}`, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => onFailure(err.response));
};

export const viewChangeOrderApproval = async (
  token1,
  token2,
  onSuccess,
  onFailure
) => {
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/wip/changeorder/approval/${token1}/${token2}`, {
      headers,
    })
    .then((res) => onSuccess(res.data))
    .catch((err) => onFailure(err.response));
};

export const changeOrderApprovalRedirectTo = async (
  data,
  onSuccess,
  onFailure
) => {
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/wip/changeorder/approval/redirect`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => onFailure(err.response));
};

export const paymentProgressCertificateApprovalRedirectTo = async (
  data,
  onSuccess,
  onFailure
) => {
  headers["Content-Type"] = "application/json";
  return await axios
    .post(`${API_HOST}/wip/ppc/approval/redirect`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => onFailure(err.response));
};

export const addProgressPaymentContractor = async (
  data,
  onSuccess,
  onFailure
) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/workinprogress/contractor/addWipPPC`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};
export const addProgressPaymentContractorUnderwriter = async (
  data,
  onSuccess,
  onFailure
) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/underwriter/workinprogress/contractor/addWipPPC`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const editProjectDataForWIP = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/workinprogress/project/edit`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};
export const editUnderwriterClientProjectDataForWIP = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/underwriter/wip/project/edit`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const progressPaymentCertificateApprovalBuyer = async (
  data,
  onSuccess,
  onFailure
) => {
  return await axios
    .post(`${API_HOST}/workinprogress/buyer/ppc/approval`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const changeOrderApprovalBuyer = async (data, onSuccess, onFailure) => {
  return await axios
    .post(`${API_HOST}/workinprogress/buyer/changeorder/approval`, data, {
      headers,
    })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const addProgressPaymentBuyer = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/workinprogress/buyer/addppc`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const addChangeOrderContractor = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/workinprogress/contractor/addWipCO`, data, {
      headers,
    })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const addChangeOrderContractorUnderwriter = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/underwriter/workinprogress/contractor/addWipCO`, data, {
      headers,
    })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const addChangeOrderBuyer = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/workinprogress/buyer/addchangeorder`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};

export const getLimitValues = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/workinprogress/getValues`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      onFailure(err.response);
    });
};

export const getLimitValuesUnderwriter = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/underwriter/wip/getlimitValues`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      onFailure(err.response);
    });
};



export const saveLimitValues = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/workinprogress/setValues`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};
export const saveLimitValuesUnderwriter = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios
    .post(`${API_HOST}/underwriter/wip/setlimitValues`, data, { headers })
    .then((res) => onSuccess(res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("Authorization");
        dispatchEvent(new Event("storage"));
      }
      onFailure(err.response);
    });
};