import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

import {
  StyledInputContainer,
  StyledLeftContainer,
  StyledTitle,
} from "../styles";
import Input from "../../../components/inputField";
import { numberWithCommas, removeComma } from "common/numberFormat";
import { saveLimitValues, saveLimitValuesUnderwriter } from "api/work_in_progress";

const LeftComponent = ({
  data,
  accountId,
  totalCostIncurredToDate,
  totalEstimatedCostOfRevenue,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  const location = useLocation();
  const dispatch = useDispatch();

  const [aggregateLimit, setAggregateLimit] = useState(null);
  const [outStandingBids, setOutStandingBids] = useState(null);
  const [totalCostToComplete, setTotalCostToComplete] = useState(null);
  const [capacityUsed, setCapacityUsed] = useState(null);
  const [capacityAvailable, setCapacityAvailable] = useState(null);

  useEffect(() => {
    setTotalCostToComplete(
      totalEstimatedCostOfRevenue - totalCostIncurredToDate
    );
  }, [totalCostIncurredToDate, totalEstimatedCostOfRevenue]);
  useEffect(() => {
    setCapacityUsed(parseInt(outStandingBids) + parseInt(totalCostToComplete));
    setCapacityAvailable(parseInt(aggregateLimit) - parseInt(capacityUsed));
  }, [aggregateLimit, outStandingBids, totalCostToComplete, capacityUsed]);

  useEffect(() => {
    setAggregateLimit(data?.aggregate_project_limit);
    setOutStandingBids(data?.value_of_outstanding_bids);
  }, [data]);
  const saveValues = () => {
    const isUnderwriterWip = location.pathname.includes("underwriter/client/wip");
    if (isUnderwriterWip) {
      saveLimitValuesUnderwriter(
        {
          account_id: accountId,
          aggregate_project_limit: aggregateLimit,
          value_of_outstanding_bids: outStandingBids,
        },
        () => {},
        () => {}
      );
    } else {
      saveLimitValues(
        {
        account_id: accountId,
        aggregate_project_limit: aggregateLimit,
          value_of_outstanding_bids: outStandingBids,
        },  
        () => {},
        () => {}
    );
    }
  };

  return (
    <StyledLeftContainer>
      <StyledInputContainer>
        <StyledTitle>Aggregate Project Limit</StyledTitle>
        <div style={{ width: "70%" }}>
          <Input
            fontSize="18px"
            labelSize="12px"
            marginTop="0px"
            type="text"
            placeholder="$"
            value={numberWithCommas(aggregateLimit)}
            setValue={(val) => setAggregateLimit(removeComma(val))}
            onBlur={saveValues}
          />
        </div>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledTitle>Total Value of Outstanding Bids</StyledTitle>
        <div style={{ width: "70%" }}>
          <Input
            fontSize="18px"
            labelSize="12px"
            marginTop="0px"
            type="text"
            placeholder="$"
            value={numberWithCommas(outStandingBids)}
            setValue={(val) => setOutStandingBids(removeComma(val))}
            onBlur={saveValues}
          />
        </div>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledTitle>Total Cost to Complete</StyledTitle>
        <div style={{ width: "70%" }}>
          <Input
            fontSize="18px"
            labelSize="12px"
            marginTop="0px"
            type="text"
            placeholder="$"
            disabled={true}
            value={numberWithCommas(totalCostToComplete)}
            backgroundColor="rgba(235, 233, 241, 0.75)"
          />
        </div>
      </StyledInputContainer>
      <StyledInputContainer>
        <StyledTitle>Capacity Used</StyledTitle>
        <div style={{ width: "70%" }}>
          <Input
            fontSize="18px"
            labelSize="12px"
            marginTop="0px"
            type="text"
            placeholder="$"
            disabled={true}
            value={numberWithCommas(capacityUsed)}
            backgroundColor="rgba(235, 233, 241, 0.75)"
          />
        </div>
      </StyledInputContainer>
      <StyledInputContainer style={{ marginBottom: "0" }}>
        <StyledTitle>Capacity Available</StyledTitle>
        <div style={{ width: "70%" }}>
          <Input
            fontSize="18px"
            labelSize="12px"
            marginTop="0px"
            type="text"
            placeholder="$"
            disabled={true}
            value={numberWithCommas(capacityAvailable)}
            backgroundColor="rgba(235, 233, 241, 0.75)"
          />
        </div>
      </StyledInputContainer>
    </StyledLeftContainer>
  );
};

export default LeftComponent;
