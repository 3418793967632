import React from "react";
import { Page, Document, StyleSheet, Image, View, Line, Text } from "@react-pdf/renderer";
import PageOneProjectDetails from "./pageOneProjectDetails";
import PageOneSubmittedTo from "./pageOneSubmittedTo";
import PageOneSubmittedBy from "./pageOneSubmittedBy";
import PageTwoCompanyInformation from "./pageTwoCompanyInformation";
import PageTwoPersonnel from "./pageTwoPersonnel";
import PageFourHealthSafetyPersonnel from "./pageFourHealthSafetyPersonnel"
import PageThreeBanking from "./pageThreeBanking";
import PageThreeBonding from "./pageThreeBonding";
import PageThreeInsurance from "./pageThreeInsurance"
import PageFourHealthSafety from "./pageFourHealthSafety"
import PageFourHealthSafetyUSA from "./pageFourHealthSafetyUSA"
import PageFourSafetyRecords from "./pageFourSafetyRecords"
import PageFourSafetyRecordsUSA from "./pageFourSafetyRecordsUSA"
import PageFourConstructionValue from "./pageFourConstructionValue"
import PageFiveKeyProjects from "./pageFiveKeyProjects"
import PageFiveComparableProjects from "./pageFiveComparableProjects"
import PageFiveUnderwayProjects from "./pageFiveUnderwayProjects"
import PageSixDocuments from "./pageSixDocuments"
import PdfHeaderWithoutLogo from "./pdfHeaderWithoutLogo";
import PdfHeaderWithLogo from "./pdfHeaderWithLogo";
import PdfFooter from "./pdfFooter";
import PdfFooterHomePage from "./pdfFooterHomePage"
import DefaultImage from "pages/pledgxOutputPDF/images/defaultImage.jpg";


const styles = StyleSheet.create({
    page: {
        backgroundColor: '#FFFFFF',
        fontFamily: 'Helvetica',
        fontSize: 10,
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        lineHeight: 1.5,
        flexDirection: 'column',
        size: 'letter',
    },
    logo: {
        width: 84,
        height: 70,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    lineDivider: {
        marginTop: 10,
        height: 1,
        backgroundColor: '#EBE9F1',
        marginLeft: 30,
        marginRight: 30,
    },
    lineHeader: {
        marginTop: 0,
        marginLeft: 0,
        marginRight: 0,
        height: 79,
        backgroundColor: '#EBE9F1',
    },
    pageNumbers: {
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: 'center',
        paddingTop: 10,
        marginTop: 0,
    },
    spanText: {
        color: '#888888',
        marginTop: 5,
        marginBottom: 5,
        marginLeft: 30,
        marginRight: 30,
    },
    remarks: {
        color: '#888888',
        marginTop: 6,
        marginBottom: 5,
        marginLeft: 30,
        marginRight: 30,
    },
    spanText2: {
        color: '#000000',
        marginTop: 5,
        marginLeft: 30,
        marginRight: 30,
    },
    remarksText: {
        color: '#000000',
        marginTop: 0,
        marginLeft: 30,
        marginRight: 30,
    },
    errorMessage: {
        color: 'red',
        marginTop: 60,
        marginLeft: 30,
        marginRight: 30,
        fontSize: 22,
    },
    lineDividerBeforeFooter: {
        height: 1,
        width: 512,
        backgroundColor: '#F66616',
        marginLeft: 30,
        marginRight: 30,
        bottom: 55,
    },
    imageContainer1: {
        justifyContent: 'flex-start',
        width: '100%',
        flex: 1,
    },
    imageContainer2: {
        justifyContent: 'flex-start',
        width: '100%',
        flex: 1,
    },

});


const PdfDocument = ({ prequalifyData }) => {

    const chunk = (array, size) =>
        array.reduce((acc, _, i) => {
            if (i % size === 0) acc.push(array.slice(i, i + size))
            return acc
        }, [])

    const chunkSize = 2;
    const companyLogo = prequalifyData?.data?.companyLogoImage ? prequalifyData?.data?.companyLogoImage : "";
    const chunkedKeyProjectsList = chunk(prequalifyData?.data?.keyProjectsList, chunkSize);
    const chunkedComparableProjectsList = chunk(prequalifyData?.data?.comparableProjectsList, chunkSize);
    const chunkedUnderwayProjectsList = chunk(prequalifyData?.data?.underwayProjectsList, chunkSize);
    const projectImageData = prequalifyData?.data?.projectImageData;


    let image1 = "";
    let image2 = "";
    let image3 = "";
    let image4 = "";

    if (projectImageData !== undefined) {

        if (projectImageData.length >= 4) {
            image1 = projectImageData[0]?.image;
            image2 = projectImageData[1]?.image;
            image3 = projectImageData[2]?.image;
            image4 = projectImageData[3]?.image;
        }

        if (projectImageData.length === 3) {
            image1 = projectImageData[0]?.image;
            image2 = projectImageData[1]?.image;
            image3 = projectImageData[2]?.image;
        }

        if (projectImageData.length === 2) {
            image1 = projectImageData[0]?.image;
            image2 = projectImageData[1]?.image;
        }

        if (projectImageData.length === 1) {
            image1 = projectImageData[0]?.image;
        }

    }
    return (
        <Document>

            <Page style={styles.page} >
                {companyLogo !== "" ? <PdfHeaderWithLogo prequalifyData={prequalifyData} /> : <PdfHeaderWithoutLogo prequalifyData={prequalifyData} />}
                <PageOneProjectDetails prequalifyData={prequalifyData} companyLogo={companyLogo} />
                <Line style={styles.lineDivider} vertical={false} />
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <PageOneSubmittedBy prequalifyData={prequalifyData} />
                    <PageOneSubmittedTo prequalifyData={prequalifyData} />
                </View>

                {projectImageData && projectImageData.length >= 4 && (
                    <>
                        <View style={{ display: 'flex', flexDirection: 'row', marginTop: 40, height: 345, position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                            <View style={styles.imageContainer1}>
                                <Image src={image1} alt="Image 1" style={{ height: 172, width: 306, bottom: 172, position: 'absolute', objectFit: 'cover' }} />
                                <Image src={image2} alt="Image 2" style={{ height: 172, width: 306, bottom: 0, position: 'absolute', objectFit: 'cover' }} />
                            </View>
                            <View style={styles.imageContainer2}>
                                <Image src={image3} alt="Image 3" style={{ height: 172, width: 306, bottom: 172, position: 'absolute', objectFit: 'cover' }} />
                                <Image src={image4} alt="Image 4" style={{ height: 172, width: 306, bottom: 0, position: 'absolute', objectFit: 'cover' }} />
                            </View>
                        </View>

                    </>
                )}

                {projectImageData && projectImageData.length === 3 && (
                    <>
                        <View style={{ display: 'flex', flexDirection: 'row', marginTop: 40, height: 345, position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                            <View style={styles.imageContainer1}>
                                <Image src={image1} alt="Image 1" style={{ height: 344, width: 306, bottom: 0, position: 'absolute', objectFit: 'cover' }} />
                            </View>
                            <View style={styles.imageContainer2}>
                                <Image src={image2} alt="Image 2" style={{ height: 172, width: 306, bottom: 172, position: 'absolute', objectFit: 'cover' }} />
                                <Image src={image3} alt="Image 3" style={{ height: 172, width: 306, bottom: 0, position: 'absolute', objectFit: 'cover' }} />
                            </View>
                        </View>

                    </>
                )}

                {projectImageData && projectImageData.length === 2 && (
                    <>
                        <View style={{ display: 'flex', flexDirection: 'row', marginTop: 40, height: 345, position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                            <View style={styles.imageContainer1}>
                                <Image src={image1} alt="Image 1" style={{ height: 344, width: 306, bottom: 0, position: 'absolute', objectFit: 'cover' }} />
                            </View>
                            <View style={styles.imageContainer2}>
                                <Image src={image2} alt="Image 2" style={{ height: 344, width: 306, bottom: 0, position: 'absolute', objectFit: 'cover' }} />
                            </View>
                        </View>

                    </>
                )}

                {projectImageData && projectImageData.length === 1 && (
                    <Image src={image1} alt="Image 1" style={{ marginTop: 40, height: 344, position: 'absolute', bottom: 0, left: 0, width: '100%', objectFit: 'cover' }} />
                )}

                {(projectImageData === undefined || projectImageData.length === 0) && (
                    <Image src={DefaultImage} alt="Default Image" style={{ marginTop: 40, height: 344, position: 'absolute', bottom: 0, left: 0, width: '100%' }} />
                )}
                <PdfFooterHomePage />
            </Page>

            <Page style={styles.page} >
                {companyLogo !== "" ? <PdfHeaderWithLogo prequalifyData={prequalifyData} /> : <PdfHeaderWithoutLogo prequalifyData={prequalifyData} />}
                <PageTwoCompanyInformation prequalifyData={prequalifyData} companyLogo={companyLogo} />
                <Line style={styles.lineDivider} vertical={false} />
                <PageTwoPersonnel prequalifyData={prequalifyData} />
                <PdfFooter />
            </Page>

            <Page style={styles.page} >
                {companyLogo !== "" ? <PdfHeaderWithLogo prequalifyData={prequalifyData} /> : <PdfHeaderWithoutLogo prequalifyData={prequalifyData} />}
                <PageThreeBanking prequalifyData={prequalifyData} companyLogo={companyLogo} />
                <Line style={styles.lineDivider} vertical={false} />
                <PageThreeBonding prequalifyData={prequalifyData} />
                <Line style={styles.lineDivider} vertical={false} />
                <PageThreeInsurance prequalifyData={prequalifyData} />
                <PdfFooter />
            </Page>


            {prequalifyData?.data?.selectedCountryForHealthSafety === "Canada" ? (
                <>
                    <Page style={styles.page} >
                        {companyLogo !== "" ? <PdfHeaderWithLogo prequalifyData={prequalifyData} /> : <PdfHeaderWithoutLogo prequalifyData={prequalifyData} />}
                        <PageFourHealthSafety prequalifyData={prequalifyData} companyLogo={companyLogo} />
                        <Line style={styles.lineDivider} vertical={false} />
                        <PageFourSafetyRecords prequalifyData={prequalifyData} />
                        <Text style={styles.remarks}>Remarks:</Text>
                        <Text style={styles.remarksText}>{prequalifyData?.data?.safetyRecordsRemark}</Text>
                        <Line style={styles.lineDivider} vertical={false} />
                        <PageFourConstructionValue prequalifyData={prequalifyData} />
                        <Text style={styles.remarks}>Remarks:</Text>
                        <Text style={styles.remarksText}>{prequalifyData?.data?.constructionRecordsRemark}</Text>
                        <PdfFooter />
                    </Page>
                </>

            ) : (
                <>
                    <Page style={styles.page} >
                        {companyLogo !== "" ? <PdfHeaderWithLogo prequalifyData={prequalifyData} /> : <PdfHeaderWithoutLogo prequalifyData={prequalifyData} />}
                        <PageFourHealthSafetyPersonnel prequalifyData={prequalifyData} />
                        <PageFourHealthSafetyUSA prequalifyData={prequalifyData} companyLogo={companyLogo} />
                        <Line style={styles.lineDivider} vertical={false} />
                        <PageFourSafetyRecordsUSA prequalifyData={prequalifyData} />
                        <Text style={styles.remarks}>Remarks:</Text>
                        <Text style={styles.remarksText}>{prequalifyData?.data?.safetyRecordsRemarkUSA}</Text>
                        <PdfFooter />
                    </Page>

                    <Page style={styles.page} >
                        {companyLogo !== "" ? <PdfHeaderWithLogo prequalifyData={prequalifyData} /> : <PdfHeaderWithoutLogo prequalifyData={prequalifyData} />}
                        <PageFourConstructionValue prequalifyData={prequalifyData} />
                        <Text style={styles.remarks}>Remarks:</Text>
                        <Text style={styles.remarksText}>{prequalifyData?.data?.constructionRecordsRemark}</Text>
                        <PdfFooter />
                    </Page>
                </>
            )}

            {prequalifyData?.data?.keyProjectsList && prequalifyData?.data?.keyProjectsList.length !== 0 && (
                <>
                    {chunkedKeyProjectsList?.map(project => (
                        <Page style={styles.page} >
                            {companyLogo !== "" ? <PdfHeaderWithLogo prequalifyData={prequalifyData} /> : <PdfHeaderWithoutLogo prequalifyData={prequalifyData} />}
                            <PageFiveKeyProjects prequalifyData={project} companyLogo={companyLogo} />
                            <PdfFooter />
                        </Page>
                    ))}
                </>
            )}


            {prequalifyData?.data?.comparableProjectsList && prequalifyData?.data?.comparableProjectsList.length !== 0 && (
                <>
                    {chunkedComparableProjectsList?.map(project => (
                        <Page style={styles.page} >
                            {companyLogo !== "" ? <PdfHeaderWithLogo prequalifyData={prequalifyData} /> : <PdfHeaderWithoutLogo prequalifyData={prequalifyData} />}
                            <PageFiveComparableProjects prequalifyData={project} companyLogo={companyLogo} />
                            <PdfFooter />
                        </Page>
                    ))}
                </>

            )}

            {prequalifyData?.data?.underwayProjectsList && prequalifyData?.data?.underwayProjectsList.length !== 0 && (
                <>
                    {chunkedUnderwayProjectsList?.map(project => (
                        <Page style={styles.page} >
                            {companyLogo !== "" ? <PdfHeaderWithLogo prequalifyData={prequalifyData} /> : <PdfHeaderWithoutLogo prequalifyData={prequalifyData} />}
                            <PageFiveUnderwayProjects prequalifyData={project} companyLogo={companyLogo} />
                            <PdfFooter />
                        </Page>
                    ))}
                </>
            )}

            {prequalifyData?.data?.additionalDocument && prequalifyData?.data?.additionalDocument.length !== 0 && (
                <Page style={styles.page} >
                    {companyLogo !== "" ? <PdfHeaderWithLogo prequalifyData={prequalifyData} /> : <PdfHeaderWithoutLogo prequalifyData={prequalifyData} />}
                    <PageSixDocuments prequalifyData={prequalifyData} companyLogo={companyLogo} />
                    <PdfFooter />
                </Page>
            )}



        </Document>
    );
}

export default PdfDocument;
