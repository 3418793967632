import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendCCDC } from "../../../data/actions/user";
import { FunctionalButton } from "../../../components/functionalButton";
import { CircularLoading } from '../../../components/circularLoading';
import { Popup } from '../../../components/popup';
import { UploadFailurePopup } from './UploadFailurePopup';

export const UploadButton = ({
  setDownloading,
  setListPanel,
  CCDCFile,
  setCCDCFile,
  clickUploadFile,
  setAction,
  marginTop
 }) => {

  const uploadStatus = useSelector(state => state.user.status);
  const dispatch = useDispatch();
  const [waitReadingFile, setWaitReadingFile] = useState(null)
  const [buttonTitle, setButtonTitle] = useState("Upload");
  const [icon, setIcon] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    if (uploadStatus == "succeeded") {
      setDownloading(false);
      setAction("new");
      setListPanel(2);
    }

    if(uploadStatus == "failed") {
      setOpenPopup(true);
      setIcon(null);
      dispatch({ type: "RESET_CCDC", disableApiMiddleWare: true })
    }
  }, [uploadStatus]);

  const handleButton = () => {
    dispatch(sendCCDC(CCDCFile));
    setWaitReadingFile(true);
    setDownloading(true);
    setButtonTitle("Reading File...");
    setIcon(<CircularLoading margin="8px 0px 0px 32px" />);
  };

  return (
    <>
      <FunctionalButton
        disabled={!!waitReadingFile}
        buttonTitle={buttonTitle}
        handleButton={handleButton}
        icon={icon}
        marginRight="24px"
        marginLeft="20px"
        marginTop={marginTop || "-6px"}
        width="200px"
      />
      <Popup
        open={openPopup}
        setOpen={setOpenPopup}
        width="418px"
        height="210px"
        dialogContent={(
          <UploadFailurePopup
            clickUploadFile={clickUploadFile}
            setCCDCFile={setCCDCFile}
            setOpenPopup={setOpenPopup}
          />
        )}
      />
    </>
  );
};
