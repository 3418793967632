import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PageFourHealthSafetyPersonnelTableRow from './pageFourHealthSafetyPersonnelTableRow';
import PageFourHealthSafetyPersonnelTableHeader from './pageFourHealthSafetyPersonnelTableHeader';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 10,
        justifyContent: 'flex-start',
        width: '80%',
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30,
    },
    personnel: {
        marginTop: -40,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
    },
    spanText: {
        color: '#888888',
    },
    spanTable: {
        marginTop: 5,
        paddingTop: 10,
        color: '#888888',
    },
    viewContainer: {
        borderRadius: 6,
        backgroundColor: '#F8F8F8',
        width: 537,
        paddingTop: 2,
        paddingBottom: 3,
    },
});

const PageFourHealthSafetyPersonnel = ({ prequalifyData }) => (
    <View style={styles.headerContainer}>
        <Text style={styles.personnel}>Health & Safety</Text>
        <Text style={styles.spanText}>Personnel responsible for Health & Safety:</Text>
        <View style={styles.viewContainer}>
            <PageFourHealthSafetyPersonnelTableHeader />
            <PageFourHealthSafetyPersonnelTableRow items={prequalifyData?.data?.health_safety_personnel} />
        </View>
    </View>
);

export default PageFourHealthSafetyPersonnel;
