import React from "react";
import CheckIcon from '@mui/icons-material/Check';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import Input from '../inputField';

import classes from './Table.module.css';
import { COLORS, FONT_SIZES } from '../../constants';

export const SimpleTable = ({
  columns,
  data,
  displayVariant,
  readOnly,
  paddingRight,
  paddingLeft,
  rows,
  setData,
  singleType,
  style={}
}) => {
  function cellBuilder({ cellData, column, row }) {
    var cellContent = '';
    switch(singleType) {
      case 'boolean':
        if (cellData) {
          cellContent = <CheckIcon />;
        } else {
          cellContent = <HorizontalRuleIcon />;
        };
        break;
      case 'inputArea':
        cellContent = <Input
          color='black'
          labelSize='12px'
          marginTop='0px'
          paddingLeft={ paddingLeft }
          paddingRight={ paddingRight }
          readOnly={ readOnly }
          setValue={ (e) => setData(
            {
              ...data || {},
              [row]: {
                ...data?.[row] || {},
                [column]: e
              }
            }
          )}
          value={ cellData || '' }
        />
        break;
      case 'number':
        cellContent = cellData.toString();
        break;
      case 'object':
        if (displayVariant && (cellData !== null) && (cellData[displayVariant])) {
          return cellBuilder(cellData[displayVariant]);
        };
        break;
      case 'string':
        cellContent = cellData;
        break;
    };
    return(
      <div className={ [classes['cell-wrap'], classes['cell-content']].join(' ') }>
        { cellContent }
      </div>
    );
  };

  function rowBuilder() {
    try {
      return(
        rows.map(row => (
          <tr key={ row.id } id={ row.id }>
            {
              columns.map(column => (
                column.title_column ?
                  <td key={ column.id } className={ [classes[column.id], classes.cell, classes['title-cell']].join(' ') }>
                    <div className={ [classes.truncate, classes['row-title']].join(' ') }>
                      <span className={ [classes['row-title-span'], classes['cell-wrap']].join(' ') }>{ row.title }</span>
                    </div>
                  </td>
                :
                  <td key={ column.id } className={ [classes[column.id], classes.cell].join(' ') }>
                    { cellBuilder({ cellData: data[row.id]?.[column.id], column: column.id, row: row.id }) }
                  </td>
              ))
            }
          </tr>
        ))
      );
    } catch {
    };
  };
  
  return (
    <div className={ classes['table-container'] } style={{ color: 'black', fontSize: FONT_SIZES.body_5, fontWeight: '400' }}>
       <table className={ classes.table } cellPadding={ 0 } cellSpacing={ 0 } style={{ width: style.width || 'auto' }}>
        <colgroup>
          {
            columns.map(column => (
              <col className={ classes['data-row'] } key={ column.id } />
            ))
          }
        </colgroup>
        <thead
          className={ classes['table-head'] }
          style={{ borderBottom: `.5px solid ${ COLORS.lightGrey1 }` }}
        >
          <tr>
            {
              columns.map((column, index) => (
                <th
                  key={ column.id }
                  className={ [classes['table-column'], column.subtle && classes.subtle, column.title_column ? classes['title-column'] : classes['data-column']].join(' ') }
                  style={{ width: (index === 0) ? '0%' : `${ 100/columns.length }%`, fontSize: style.headerFontSize || FONT_SIZES.body_6 }}
                >
                  { column.title }
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          { rowBuilder() }
        </tbody>
      </table>
    </div>
  );
};
