function buildHash({ currentLocation, currentPage }) {
  var return_obj = {};
  if (currentPage?.content && Array.isArray(currentPage.content)) {
    currentPage.content.map(pageContent => {
      if (pageContent.itemType === 'field') {
        return_obj[pageContent.id] = { location: currentLocation };
      } else if (pageContent.itemType === 'group') {
        return_obj = { ...return_obj, [pageContent.id]: { location: currentLocation }, ...buildHash({ currentLocation, currentPage: pageContent })};
      };
    });
    return return_obj;
  } else { return {}};
};

function findNextLocations({ currentLocation }) {
  var nextLocation = [...currentLocation];
  var previousLocation = [...currentLocation];
  if (previousLocation[previousLocation.length - 1] > 0) {
    previousLocation[previousLocation.length - 1] = previousLocation[previousLocation.length - 1] - 1;
  } else {
    // 0 end index. Try previous page(s)
  }
  return {
    next: null,
    previous: null
  }
};

function calculatePosition({ buildFieldHash, currentLocation, formSetup }) {
  var locationIndex = 0;
  var currentPage;
  while (locationIndex < currentLocation.length) {
    if (locationIndex === 0) {
      currentPage = formSetup[currentLocation[0]];
    } else {
      currentPage = currentPage.content[currentLocation[locationIndex]];
    };
    locationIndex++;
  };
  var defaultContent = false;
  while (!defaultContent) {
    if (currentPage && currentPage.content) {
      if (currentPage.content[0] && (currentPage.content[0].itemType !== 'page')) {
        defaultContent = true;
      } else {
        currentPage = currentPage.content[0];
      };
    } else {
      // Empty
      defaultContent = true;
    };
  };
  var returnObj = { currentPage };
  if (buildFieldHash) {
    returnObj.pageHash = buildHash({ currentLocation, currentPage });
    returnObj.pageFields = Object.keys(returnObj.pageHash);
  };
  //var nextLocations = findNextLocations({ currentLocation, formSetup });
  return returnObj;
};

export default calculatePosition;
