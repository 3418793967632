import React from 'react';
import invite from './invite_grey.svg'

const Invite_grey = ({ isMobile }) => {
  return (
    <img width={isMobile ? "36" : "56"} height={isMobile ? "36" : "56"} src={invite} />
  )
};

export default Invite_grey;
