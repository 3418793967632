import styled from "styled-components";
import { COLORS } from "constants.js";


export const StyledProjectSelectionContainer = styled.div`
  ${props => props.isMobile ? "margin-top: 16px;" : ""};
`;

export const StyledUserManagementSelectionContainer = styled.div`
  margin-top: ${props => props.marginTop || "32px"};
  width: ${props => props.width || "600px"};
  position: relative;
`;


export const HeaderArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${props => props.width || "100%"};
`;



export const FormContainer = styled.div`
  width: ${(props) => (props.isMobile ? "85%" : "85%")};
  ${(props) => (props.isMobile ? "align-self: center;" : "")}
  padding-bottom: 20px;
  margin-left: 20px;
  margin-top: ${(props) => (props.isMobile ? "16px" : "24px")};
  display: flex;
  flex-direction: column;
  // align-items: center;
  // ${(props) => (props.leftBorder ? "margin-top: 50px;" : "")};
  border-left: ${(props) => (props.leftBorder ? "3px solid #EBE9F1" : "none")};
`;



export const FieldArea = styled.div`
  ${(props) =>
    props.marginLeft && !props.isMobile
      ? `margin-left: ${props.marginLeft};`
      : ""}
  margin-top: ${(props) => props.marginTop || "24px"};
  display: flex;
  flex-direction: ${(props) => (props.isColumn ? "column" : "row")};
  align-items: center;
  flex-wrap: wrap;
  ${(props) => (props.noJustifyContent ? "" : "justify-content: center;")}
  ${(props) =>
    props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}

  .last {
    padding-right: 0px !important;
  }

  .inputWrap {
    padding-right: ${(props) => (props.isMobile ? "0px" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "50%")};
  }

  .fullWidthInputWrap {
    width: 100%;
  }

  .oneFifthInputWrap {
   padding-right: ${(props) => (props.isMobile ? "0px" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "20%")};
  }

  .thirtyInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "30%")};
  }

  .oneThirdInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "33.33%")};
  }

  .twoThirdInputWrap {
    width: ${(props) => (props.isMobile ? "100%" : "66.66%")};
  }

  .oneFifthMobile {
    padding-right: 16px;
    width: ${(props) => (props.isMobile ? "50%" : "20%")};
  }

  .thirtyMobile {
    width: ${(props) => (props.isMobile ? "50%" : "30%")};
  }
`;

export const StyledEditRecordButton = styled(FieldArea)`
  align-items: left;
  margin-top: 15px;
`;




export const StyledPageContainer = styled.div`
  margin: 72px 72px 72px 72px;
  max-width: 1440px;
  height: 100vh;
`;

export const StyledTitle = styled.div`
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  color: ${COLORS.primaryDarkPurple};

  margin-top: 56px;
`;

export const StyledCompanyContainer = styled.div`
  display: flex;
  margin-top: 48px;
  margin-bottom: 48px;
`;

export const LogoDiv = styled.div`
  text-align: left;
`;

export const StyledTitle2 = styled(StyledTitle)`
  font-size: 28px;
  font-weight: 400;
`;

export const StyledCompanyInfo = styled.div`
  display: inline-block;
  margin-left: 32px;
`;
