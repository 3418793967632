import React from "react";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import Error from "@material-ui/icons/Error";
import { InputField, InputWrap, InputLabel, ReadOnlyValue } from "./styles";

function CustomInputField({
  backgroundColor,
  color,
  type,
  label,
  placeholder,
  value,
  setValue,
  validateFunc,
  style,
  withoutMargin,
  marginTop,
  id,
  labelSize,
  labelWeight,
  textAreaHeight,
  borderColor,
  fontSize,
  autoComplete,
  noCheckIcon,
  cancelIcon,
  noErrorBorder,
  ref,
  hasError,
  inputId,
  disabled,
  width,
  paddingRight,
  paddingLeft,
  onBlur,
  showError,
  readOnly,
  marginRight,
}) {
  const [validate, setValidate] = React.useState(
    validateFunc && value
      ? validateFunc(value)
        ? 1
        : 0
      : String(value).length > 0
      ? 1
      : 0
    // validateFunc && value ? (validateFunc(value) ? 1 : -1) : 0
  );

  const handleOnChange = (e) => {
    if (setValue && validateFunc) {
      setValue(e.target.value);
      validateFunc(e.target.value) ? setValidate(1) : setValidate(-1);
    } else if (setValue) {
      setValue(e.target.value);
    }
  };

  return (
    <InputWrap
      style={{
        ...style,
        marginTop: withoutMargin ? 0 : marginTop ? marginTop : 25,
      }}
      className={id || ""}
    >
      {label && (
        <InputLabel labelSize={labelSize} labelWeight={labelWeight}>
          {label}
        </InputLabel>
      )}
      <div
        style={{ display: "flex", alignItems: "center", position: "relative" }}
      >
        {readOnly ? (
          <ReadOnlyValue
            backgroundColor={backgroundColor}
            fontSize={fontSize}
            paddingRight={paddingRight}
            paddingLeft={paddingLeft}
            textAreaHeight={textAreaHeight}
            value={value}
            width={width}
            marginRight={marginRight}
          >
            {value}
          </ReadOnlyValue>
        ) : (
          <InputField
            id={inputId}
            inputRef={ref}
            autoComplete={autoComplete}
            backgroundColor={backgroundColor}
            color={color}
            textAreaHeight={textAreaHeight}
            type={type}
            validation={validate}
            item={value}
            placeholder={placeholder}
            value={value}
            onChange={handleOnChange}
            borderColor={showError ? "#FF647C" : borderColor}
            fontSize={fontSize}
            noErrorBorder={noErrorBorder}
            hasError={hasError}
            disabled={disabled}
            width={width}
            paddingRight={paddingRight}
            paddingLeft={paddingLeft}
            onBlur={onBlur}
            readOnly={readOnly}
          />
        )}
        {validateFunc && validate === 1 && !noCheckIcon && (
          <CheckRoundedIcon
            style={{
              color: "#FF6D1D",
              position: "absolute",
              right: 12,
              // bottom: 14,

              width: 16,
              height: 16,
            }}
          ></CheckRoundedIcon>
        )}
        {(showError ||
          (((validateFunc && validate === -1) || hasError) && cancelIcon)) && (
          <Error
            style={{
              color: "#FF647C",
              position: "absolute",
              right: 12,
              // bottom: 14,

              width: 16,
              height: 16,
            }}
          ></Error>
        )}
      </div>
    </InputWrap>
  );
}

export default CustomInputField;
