import React, { useEffect, useState } from 'react';
import classes from './style/FormBuilder.module.css';
import { FunctionalButton } from '../../components/functionalButton';
import { COLORS, FONT_SIZES } from '../../constants';

export const InteractionBuilder =({ changeLocation, checkRequired, interactionState, onNavigationItemClicked, submit }) => {
  const recognizedButtonOrder = ['edit', 'previous', 'next', 'submit'];
  const recognizedButtons = {
    edit: {
      label: 'Edit'
    },
    previous: {
      label: 'Previous'
    },
    next: {
      label: 'Next'
    },
    submit: {
      label: 'Submit'
    }
  };
  function handleClick({ action, id, payload }) {
    var notifyOnNavigationItemClicked = true;
    switch (action) {
      case 'CHANGE_LOCATION':
        if (changeLocation) {
          changeLocation(payload);
        };
        break;
      case 'CHECK_REQUIRED':
        checkRequired();
        break;
      case 'EDIT':
        if (changeLocation) {
          changeLocation([0]);
        };
        break;
      case 'NO_ACTION':
        break;
      case 'NOTIFY_IF_COMPLETE':
        if (!checkRequired()) {
          notifyOnNavigationItemClicked = false;
        };
        break;
      case 'SUBMIT':
        if (submit) {
          submit();
        };
    };
    if (onNavigationItemClicked && notifyOnNavigationItemClicked) {
      onNavigationItemClicked({ id, currentLocation: interactionState?.currentPage?.navigation?.current?.location });
    };
  };
  
  if (interactionState?.navigation) {
    if (interactionState.navigation.setup) {
      return(
        <>
          <div className={ [classes['interaction-inner-container-split'], 'pledgxform-interaction-inner-container-split'].join(' ') }>
            {
              ['left', 'center', 'right'].map(placement => (
                  <div className={ [classes[`interaction-inner-container-${ [placement] }`], `pledgxform-interaction-inner-container-${ [placement] }`].join(' ') }>               
                    {
                      Object.keys(interactionState.navigation.setup).includes(placement) &&
                        interactionState.navigation.setup[placement].map(recognizedButton => (
                          (interactionState.navigation[recognizedButton]?.display !== false) &&
                            <FunctionalButton
                              buttonTitle={ interactionState.navigation[recognizedButton].label || recognizedButtons[recognizedButton]?.label || interactionState.navigation[recognizedButton].id || 'Button' }
                              disabled={ interactionState.navigation[recognizedButton].disabled }
                              handleButton={ () => handleClick({ action: interactionState.navigation[recognizedButton].action || 'NO_ACTION', id: interactionState.navigation[recognizedButton].id || recognizedButton, payload: interactionState.navigation[recognizedButton].location })}
                              isReversedColor={ interactionState.navigation[recognizedButton].isReversedColor }
                              width={ interactionState.navigation[recognizedButton].width || '200px' }
                              marginRight='0px'
                              marginTop='0px'
                            />
                        ))
                    }
                  </div>
              ))
            }
          </div>
          {
            interactionState.navigation.navigationMessage &&
              <div
                className={ classes['navigation-message'] }
                style={{ color: COLORS.textGrey, fontSize: FONT_SIZES.body_5 }}
              >
                { interactionState.navigation.navigationMessage }
              </div>
          }
        </>
      );
    } else {
      return(
        <>
          <div className={ [classes['interaction-inner-container'], 'pledgxform-interaction-inner-container'].join(' ') }>
            {
              recognizedButtonOrder.map(recognizedButton => (
                interactionState.navigation[recognizedButton]?.display &&
                  <FunctionalButton
                    buttonTitle={ interactionState.navigation[recognizedButton].label || recognizedButtons[recognizedButton]?.label || interactionState.navigation[recognizedButton].id || 'Button' }
                    disabled={ interactionState.navigation[recognizedButton].disabled }
                    handleButton={ () => handleClick({ action: interactionState.navigation[recognizedButton].action || 'NO_ACTION', id: interactionState.navigation[recognizedButton].id || recognizedButton, payload: interactionState.navigation[recognizedButton].location })}
                    isReversedColor={ interactionState.navigation[recognizedButton].isReversedColor }
                    width={ interactionState.navigation[recognizedButton].width || '200px' }
                    marginRight='0px'
                    marginTop='0px'
                  />
              ))
            }
          </div>
          {
            interactionState.navigation.navigationMessage &&
              <div
                className={ classes['navigation-message'] }
                style={{ color: COLORS.textGrey, fontSize: FONT_SIZES.body_5 }}
              >
                { interactionState.navigation.navigationMessage }
              </div>
          }
        </>
      );
    };
  } else {
    return(
      <div className={ [classes['interaction-inner-container'], 'pledgxform-interaction-inner-container'].join(' ') }>
      </div>
    );
  }
};