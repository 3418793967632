import styled from "styled-components";
import { StyledTableCell } from "../../../../components/filesListTable/styles";

export const StyledTableCellFirst = styled(StyledTableCell)`
  width: 40%;
`;
export const StyledTableCellSecond = styled(StyledTableCell)`
  width: 40%;
`;

export const StyleDisplay = styled.div`
  width: ${(props) => props.displayPercentage || "75%"};
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop};` : "")}
`;
