import React, { useEffect, useRef, useState } from "react";
import { COLORS, FONT_SIZES } from "../../constants";
import Tooltip from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { FunctionalButton } from "../functionalButton";
import Spinner from "../spinner";

import displayDate from "../../utilities/displayDate";
import displayPrice from "../../utilities/displayPrice";

import {
  PackageItemBox,
  PackageItemBoxCancelContainer,
  PackageItemBoxCancelText,
  PackageItemBoxDescription,
  PackageItemBoxFeature,
  PackageItemBoxFreeTrialText,
  PackageItemBoxInteractionContainer,
  PackageItemBoxPrice,
  PackageItemBoxPriceContainer,
  PackageItemBoxPriceTotal,
  PackageItemBoxRow,
  PackageItemBoxTitle,
  PackageItemContainer,
  PackageItemTopDecoration,
} from "./styles";

import contractor_packages from "../../assets/payment/contractor_packages.json";
import moment from "moment";

export const PackageItem = ({
  billing,
  handleInteraction,
  packageOption,
  permissions,
  loading,
  pricesLoading,
  subscribedPackage,
  userStatus,
  newCustomer,
}) => {
  const [state, setState] = useState({
    persistentProps: {
      allowDecoration: false,
      allowHighlighted: false,
      allowOutlined: false,
    },
  });
  const stateRef = useRef({
    persistentProps: {
      allowDecoration: false,
      allowHighlighted: false,
      allowOutlined: false,
    },
  });
  stateRef.current = state;

  useEffect(() => {
    if (userStatus.loggedIn) {
      // User Logged in
      if (permissions && permissions["VIEW_SUBSCRIPTION_STATUS"] === true) {
        // User has permission to view subscription status
        if (userStatus.subscribed) {
          // User is subscribed
          if (!subscribedPackage.public_package) {
            setState({
              ...stateRef.current,
              persistentProps: {
                allowDecoration: false,
                allowHighlighted: false,
                allowOutlined: false,
              },
            });
          } else {
            setState({
              ...stateRef.current,
              persistentProps: {
                allowDecoration: true,
                allowHighlighted: false,
                allowOutlined: true,
              },
            });
          }
        } else {
          // User is not subscribed
          setState({
            ...stateRef.current,
            persistentProps: {
              allowDecoration: true,
              allowHighlighted: true,
              allowOutlined: true,
            },
          });
        }
      } else {
        // User does not have permission to view subscription status
        setState({
          ...stateRef.current,
          persistentProps: {
            allowDecoration: false,
            allowHighlighted: false,
            allowOutlined: false,
          },
        });
      }
    } else {
      // User Logged out
      setState({
        ...stateRef.current,
        persistentProps: {
          allowDecoration: true,
          allowHighlighted: true,
          allowOutlined: true,
        },
      });
    }
  }, [userStatus, subscribedPackage]);

  function handleButtons({ packageType, packageIndex }) {
    var expiryDate;
    var subscriptionDetails = {
      buttonAction: "",
      buttonDisabled: false,
      buttonStyle: "REVERSED",
      buttonText: "",
      //decorationText: '',
      expires: false,
      //expiresText: '',
      outlined: false,
      selected: false,
      subscribed: false,
      firstTimeCustomer: true,
    };
    var newPersistentProps = stateRef.current.persistentProps;
    if (loading) {
      return subscriptionDetails;
    }
    var packageIndex = contractor_packages.packages_hash?.[packageType]?.index;
    var subscribedPackageIndex =
      contractor_packages.packages_hash?.[subscribedPackage.title]?.index;
    var packageDetails = null;
    if (packageIndex !== null) {
      packageDetails = contractor_packages.packages[packageIndex];
    }
    var packagePricingAvailable = true;
    if (!packageDetails?.price[billing.frequency]?.[billing.currency]) {
      packagePricingAvailable = false;
    }
    if (userStatus?.loggedIn) {
      subscriptionDetails = {
        ...subscriptionDetails,
        firstTimeCustomer: newCustomer,
      };
      if (permissions && permissions["VIEW_SUBSCRIPTION_STATUS"] === false) {
        // Not permitted to view packages
        subscriptionDetails = {
          ...subscriptionDetails,
          buttonDisabled: true,
          buttonStyle: "NORMAL",
          buttonText: "Subscribe",
          decorationText: null,
          expires: true,
          expiresText: "Requires an admin user",
        };
      } else if (
        subscribedPackage?.id !== null &&
        (packageDetails?.id === subscribedPackage.id ||
          (!subscribedPackage.public_package && !packageDetails?.id))
      ) {
        // Current package matches the subscribed package
        if (subscribedPackage.subscription_active) {
          // Active subscription
          subscriptionDetails.subscribed = true;
          if (subscribedPackage.subscription_renews) {
            // Subscription will renew. Do not show expiry info
            if (
              subscribedPackage.billing_cycle &&
              subscribedPackage.billing_cycle === billing.frequency
            ) {
              subscriptionDetails = {
                ...subscriptionDetails,
                buttonDisabled: true,
                buttonText: "Subscribed",
                selected: true,
              };
            } else {
              subscriptionDetails = {
                ...subscriptionDetails,
                buttonDisabled: false,
                subscribed: false,
                buttonText: "Subscribe",
                buttonAction: "UPGRADE_PACKAGE",
                expires: true,
                expiresText: [
                  "Switch to",
                  billing.frequency === "YR" ? "yearly" : "monthly",
                  "billing",
                ].join(" "),
                outlined: true,
              };
            }
          } else {
            // Subscription will expire. Show expiry info
            if (
              !subscribedPackage.billing_cycle ||
              subscribedPackage.billing_cycle === billing.frequency
            ) {
              var textOptions = {
                expiry: {
                  no_renew: {
                    future: "Subscription ends",
                    past: "Subscription ended",
                  },
                  renew: {
                    future: "Expires",
                    past: "Expired",
                  },
                },
              };
              if (packageDetails.id) {
                subscriptionDetails = {
                  ...subscriptionDetails,
                  buttonAction:
                    subscribedPackage &&
                    subscribedPackage.current_stripe_subscription
                      ? "RENEW_SUBSCRIPTION"
                      : "SUBSCRIBE",
                  buttonStyle: "NORMAL",
                  buttonText:
                    subscribedPackage &&
                    subscribedPackage.current_stripe_subscription
                      ? "Renew"
                      : "Subscribe",
                  expires: true,
                  expiresText: subscribedPackage.subscription_expiry
                    ? displayDate({
                        date: subscribedPackage.subscription_expiry,
                        prepend:
                          subscribedPackage &&
                          subscribedPackage.current_stripe_subscription
                            ? textOptions.expiry.renew
                            : textOptions.expiry.no_renew,
                      })
                    : subscribedPackage &&
                      subscribedPackage.current_stripe_subscription
                    ? "Auto renew is off"
                    : "Trial ended",
                  outlined: true,
                };
              } else {
                if (packageOption?.title === "Enterprise") {
                  subscriptionDetails = {
                    ...subscriptionDetails,
                    buttonAction: "CONTACT_US",
                    buttonStyle: "REVERSED",
                    buttonText: "Contact Us",
                    expires: true,
                    expiresText:
                      subscribedPackage?.title !== "Free" &&
                      packageDetails?.title !== "Free"
                        ? subscribedPackage.subscription_expiry
                          ? displayDate({
                              date: subscribedPackage.subscription_expiry,
                              prepend:
                                subscribedPackage &&
                                subscribedPackage.current_stripe_subscription
                                  ? textOptions.expiry.renew
                                  : textOptions.expiry.no_renew,
                            })
                          : subscribedPackage &&
                            subscribedPackage.current_stripe_subscription
                          ? "Auto renew is off"
                          : "Trial ended"
                        : " ",
                    //outlined: true
                    selected:
                      subscribedPackage?.title !== "Free" &&
                      packageOption?.title === "Enterprise"
                        ? true
                        : false,
                  };
                } else {
                  subscriptionDetails = {
                    ...subscriptionDetails,
                    buttonAction: "CONTACT_US",
                    buttonStyle: "REVERSED",
                    buttonDisabled: true,
                    buttonText: "Free Trial",
                    expires: true,
                    expiresText: " ",
                    // outlined: true,
                    selected:
                      subscribedPackage?.title === "Free" &&
                      packageOption?.title === "Free"
                        ? true
                        : false,
                  };
                }
              }
            } else {
              subscriptionDetails = {
                ...subscriptionDetails,
                buttonDisabled: false,
                subscribed: false,
                buttonText: "Subscribe",
                buttonAction: "UPGRADE_PACKAGE",
                expires: true,
                expiresText: [
                  "Switch to",
                  billing.frequency === "YR" ? "yearly" : "monthly",
                  "billing",
                ].join(" "),
                outlined: true,
              };
            }
          }
        } else {
          // Inactive subscription
          subscriptionDetails = {
            ...subscriptionDetails,
            buttonAction: "SUBSCRIBE",
            buttonStyle: "NORMAL",
            buttonText: "Subscribe",
            expires: true,
            expiresText: subscribedPackage.subscription_expiry
              ? displayDate({
                  date: subscribedPackage.subscription_expiry,
                  style: "EXPIRY",
                })
              : "Package expired",
            outlined: true,
          };
        }
      } else if (subscribedPackage?.id !== null) {
        // Current package is not the subscribed package, but has an ID
        if (
          subscribedPackageIndex === null ||
          subscribedPackageIndex === undefined ||
          packageIndex < subscribedPackageIndex
        ) {
          // subscribedPackage index is missing or is higher than the current package
          subscriptionDetails = {
            ...subscriptionDetails,
            buttonAction: "CONTACT_US",
            buttonText: packagePricingAvailable
              ? "Subscribe"
              : packageOption?.title === "Free"
              ? "Free Trial"
              : "Contact Us",
            buttonDisabled:
              packagePricingAvailable || packageDetails?.title === "Free"
                ? true
                : false,
            buttonStyle: packagePricingAvailable ? "NORMAL" : "REVERSED",
            //expires: packagePricingAvailable ? true : false,
            expires: false,
            expiresText: packagePricingAvailable
              ? "Contact us to change package"
              : null,
          };
        } else {
          // subscribedPackage index is available and is higher than packageIndex
          subscriptionDetails = {
            ...subscriptionDetails,
            buttonAction: packagePricingAvailable
              ? "UPGRADE_PACKAGE"
              : "CONTACT_US",
            buttonText: packagePricingAvailable ? "Upgrade" : "Contact Us",
          };
        }
      } else {
        // Current package is not the subscribed package, and has no ID/data
        subscriptionDetails = {
          ...subscriptionDetails,
          buttonAction: packagePricingAvailable ? "SUBSCRIBE" : "CONTACT_US",
          buttonText: packagePricingAvailable ? "Subscribe" : "Contact Us",
        };
      }
    } else {
      subscriptionDetails = {
        ...subscriptionDetails,
        buttonAction: packagePricingAvailable ? "SIGN_UP" : "CONTACT_US",
        buttonText: packagePricingAvailable
          ? "SIGN UP"
          : packageOption?.title === "Free"
          ? "SIGN UP"
          : "Contact Us",
      };
    }
    return subscriptionDetails;
  }

  var displayedPackageDetails;
  if (
    subscribedPackage?.title === "Enterprise" &&
    !packageOption.id &&
    subscribedPackage?.id &&
    !subscribedPackage.public_package &&
    subscribedPackage.details &&
    Array.isArray(subscribedPackage.details) &&
    subscribedPackage.details.length > 0
  ) {
    displayedPackageDetails = subscribedPackage.details;
  } else if (
    packageOption.details &&
    Array.isArray(packageOption.details) &&
    packageOption.details.length > 0
  ) {
    displayedPackageDetails = packageOption.details;
  }

  return (
    <PackageItemContainer>
      <PackageItemTopDecoration>
        {!loading &&
        packageOption.recommended &&
        packageOption.recommended[billing.frequency] &&
        stateRef.current.persistentProps.allowDecoration &&
        !handleButtons({ packageType: packageOption.title }).subscribed
          ? "Recommended"
          : !loading &&
            handleButtons({ packageType: packageOption.title }).decorationText
          ? handleButtons({ packageType: packageOption.title }).decorationText
          : ""}
      </PackageItemTopDecoration>
      <PackageItemBox
        recommended={
          !loading &&
          (handleButtons({ packageType: packageOption.title }).outlined ||
            (packageOption.recommended &&
              packageOption.recommended[billing.frequency] &&
              stateRef.current.persistentProps.allowOutlined))
        }
        loading={loading}
        pricesLoading={pricesLoading}
        selected={
          !loading &&
          (handleButtons({ packageType: packageOption.title }).selected ||
            (packageOption.recommended &&
              packageOption.recommended[billing.frequency] &&
              stateRef.current.persistentProps.allowHighlighted))
        }
      >
        <PackageItemBoxRow noTopMargin={true}>
          <PackageItemBoxTitle>{packageOption.title}</PackageItemBoxTitle>
        </PackageItemBoxRow>
        <PackageItemBoxRow>
          {loading || pricesLoading ? (
            <>
              <PackageItemBoxPriceContainer>
                <PackageItemBoxPrice>
                  <Spinner />
                </PackageItemBoxPrice>
              </PackageItemBoxPriceContainer>
              <PackageItemBoxPriceTotal></PackageItemBoxPriceTotal>
            </>
          ) : (
            <>
              {packageOption.price[billing.frequency]?.[billing.currency] ? (
                <PackageItemBoxPriceContainer>
                  <PackageItemBoxPrice>$</PackageItemBoxPrice>
                  <PackageItemBoxPrice>
                    {displayPrice({
                      amount:
                        packageOption.price[billing.frequency]?.[
                          billing.currency
                        ].amount,
                      options: { addDecimal: true },
                    })}
                  </PackageItemBoxPrice>
                  &nbsp;
                  <PackageItemBoxPrice secondary={true}>
                    /mo
                  </PackageItemBoxPrice>
                </PackageItemBoxPriceContainer>
              ) : packageOption.title === "Free" ? (
                <PackageItemBoxPriceContainer>
                  <PackageItemBoxPrice>$0</PackageItemBoxPrice>
                </PackageItemBoxPriceContainer>
              ) : (
                <PackageItemBoxPriceContainer>
                  <PackageItemBoxPrice secondary={true}>
                    Custom Pricing
                  </PackageItemBoxPrice>
                </PackageItemBoxPriceContainer>
              )}
              <PackageItemBoxPriceTotal>
                {packageOption.price?.total?.[billing.frequency]?.[
                  billing.currency
                ]
                  ? `Pay $${displayPrice({
                      amount:
                        packageOption.price?.total?.[billing.frequency]?.[
                          billing.currency
                        ].amount,
                      options: { addDecimal: true },
                    })} now`
                  : ""}
              </PackageItemBoxPriceTotal>
            </>
          )}
        </PackageItemBoxRow>
        <PackageItemBoxRow>
          <PackageItemBoxDescription minHeight={60}>
            {packageOption.description}
          </PackageItemBoxDescription>
        </PackageItemBoxRow>
        {displayedPackageDetails && (
          <PackageItemBoxRow>
            {displayedPackageDetails.map((packageDetail) => (
              <PackageItemBoxFeature>
                <span style={{ marginRight: "5px" }}>
                  {packageDetail.title}
                </span>
                {packageDetail.tooltip && (
                  <Tooltip
                    title={packageDetail.tooltip}
                    placement="top"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: FONT_SIZES.body_5,
                          color:
                            packageOption.title === subscribedPackage
                              ? COLORS.primaryOrange
                              : "white",
                          bgcolor:
                            packageOption.title === subscribedPackage
                              ? "white"
                              : COLORS.primaryOrange,
                          border: `1.5px solid ${COLORS.borderOrange}`,
                          maxWidth: "250px",
                          "& .MuiTooltip-arrow": {
                            color:
                              packageOption.title === subscribedPackage
                                ? "white"
                                : COLORS.primaryOrange,
                          },
                        },
                      },
                    }}
                  >
                    <HelpOutlineIcon
                      sx={{
                        fontSize: 14,
                        color:
                          packageOption.title === subscribedPackage
                            ? "white"
                            : COLORS.primaryOrange,
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleInteraction({
                          action: "MORE_INFO",
                          payload: {
                            package: packageOption.title,
                            detail: packageDetail.id,
                          },
                        })
                      }
                    />
                  </Tooltip>
                )}
              </PackageItemBoxFeature>
            ))}
          </PackageItemBoxRow>
        )}
        <PackageItemBoxInteractionContainer
          style={
            userStatus?.loggedIn &&
            // handleButtons({ packageType: packageOption.title }).buttonText !==
            //   "Contact Us" &&
            !subscribedPackage?.subscription_active &&
            handleButtons({ packageType: packageOption.title })
              .firstTimeCustomer
              ? { height: "130px" }
              : {}
          }
        >
          <FunctionalButton
            buttonTitle={
              pricesLoading
                ? ""
                : handleButtons({ packageType: packageOption.title }).buttonText
            }
            handleButton={() =>
              handleInteraction({
                action: handleButtons({ packageType: packageOption.title })
                  .buttonAction,
                payload: {
                  packageId: packageOption.id,
                  priceId:
                    packageOption.price[billing.frequency]?.[billing.currency]
                      ?.stripe_id,
                },
              })
            }
            width="100%"
            height="50px"
            isReversedColor={
              handleButtons({ packageType: packageOption.title })
                .buttonStyle === "REVERSED"
                ? true
                : false
            }
            disabled={
              loading ||
              pricesLoading ||
              handleButtons({ packageType: packageOption.title }).buttonDisabled
            }
          />
          <PackageItemBoxCancelContainer>
            {!loading && !pricesLoading ? (
              handleButtons({ packageType: packageOption.title }).expires ? (
                <PackageItemBoxCancelText
                  expired={true}
                  selected={
                    handleButtons({ packageType: packageOption.title }).selected
                  }
                  onClick={() => handleInteraction({ action: "" })}
                >
                  {handleButtons({ packageType: packageOption.title })
                    .expiresText
                    ? handleButtons({ packageType: packageOption.title })
                        .expiresText
                    : "Expired"}
                </PackageItemBoxCancelText>
              ) : handleButtons({ packageType: packageOption.title })
                  .subscribed ? (
                <PackageItemBoxCancelText
                  onClick={() =>
                    handleInteraction({
                      action: "CANCEL_SUBSCRIPTION_AUTORENEWAL",
                    })
                  }
                >
                  Cancel auto-renewal
                </PackageItemBoxCancelText>
              ) : userStatus?.loggedIn &&
                !subscribedPackage?.subscription_active &&
                handleButtons({ packageType: packageOption.title })
                  .buttonText !== "Contact Us" &&
                packageOption?.free_trial_days > 0 &&
                handleButtons({ packageType: packageOption.title })
                  .firstTimeCustomer ? (
                <PackageItemBoxFreeTrialText>
                  Your free trial starts now. Your card will be charged on{" "}
                  {moment()
                    .add(packageOption?.free_trial_days, "days")
                    .format("MM-DD-YYYY")}
                  , unless you cancel. You can cancel anytime during your trial.
                </PackageItemBoxFreeTrialText>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </PackageItemBoxCancelContainer>
        </PackageItemBoxInteractionContainer>
      </PackageItemBox>
    </PackageItemContainer>
  );
};
