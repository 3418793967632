import React from 'react';
import Input from '../../../../components/inputField';


export const MobileYearsNumber = ({
  data,
  setData,
  year,
}) => {

  return (
    <>
      <Input
        id="fullWidthInputWrap"
        label='Industry Classification Code, or Equivalent'
        type="text"
        value={(data[`${year}`] && data[`${year}`]["classCode"])? data[`${year}`]["classCode"] : ''}
        marginTop="0px"
        labelSize="10px"
        setValue={(val) => setData(`safety_records.${year}.classCode`, val)}
        validateFunc={(item) => String(item).length > 0}
        noCheckIcon={true}
        noErrorBorder={true}
      />
      <Input
        id="fullWidthInputWrap"
        label='Industry Base Rate'
        type="text"
        value={(data[`${year}`] && data[`${year}`]["baseRate"])? data[`${year}`][`baseRate`] : ''}
        marginTop="0px"
        labelSize="10px"
        setValue={(val) => setData(`safety_records.${year}.baseRate`, val)}
        validateFunc={(item) => item > 0}
        noCheckIcon={true}
        noErrorBorder={true}
      />
      <Input
        id="fullWidthInputWrap"
        label={'Company’s Experience Rate'}
        type="text"
        value={(data[`${year}`] && data[`${year}`][`expRate`])? data[`${year}`][`expRate`] : ''}
        marginTop="0px"
        labelSize="10px"
        setValue={(val) => setData(`safety_records.${year}.expRate`, val)}
        validateFunc={(item) => item > 0}
        noCheckIcon={true}
        noErrorBorder={true}
      />
      <Input
        id="fullWidthInputWrap"
        label='Fatalities (Actual Number)'
        type="text"
        value={(data[`${year}`] && data[`${year}`][`fatalities`])? data[`${year}`][`fatalities`] : ''}
        marginTop="0px"
        labelSize="10px"
        setValue={(val) => setData(`safety_records.${year}.fatalities`, val)}
        validateFunc={(item) => item > 0}
        noCheckIcon={true}
        noErrorBorder={true}
      />
      <Input
        id="fullWidthInputWrap"
        label='Lost Time Incident Frequency (LTIF) Rate'
        type="text"
        value={(data[`${year}`] && data[`${year}`][`lostRate`])? data[`${year}`][`lostRate`] : ''}
        marginTop="0px"
        labelSize="10px"
        setValue={(val) => setData(`safety_records.${year}.lostRate`, val)}
        validateFunc={(item) => item > 0}
        noCheckIcon={true}
        noErrorBorder={true}
      />
    </>
  )
};
