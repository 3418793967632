import styled from 'styled-components';
import { FormTitle } from "../../styles";
import { StyledTableCell } from '../../../../components/filesListTable/styles';
import { TYPOGRAPHY } from '../../../../constants';

export const StyledButtonContainer = styled.div`
  width: 40%;
  margin-top: 49px;
`;

export const StyledListContainer = styled.div`
  && {
    margin-top: 48px;
    margin-bottom: 8px;
  }
`;

export const StyledBottomButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: ${props => props.marginTop?  props.marginTop : '0px'};
  justify-content: ${props => props.justifyContent?  props.justifyContent : 'flex-end'};
`;

export const StyledPopupTitle = styled(FormTitle)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 24px;
    font-weight: 600;
    margin-top: 56px;
    text-align: center;
    line-height: 1.3;
  }
`;
export const StyledPopupText = styled(FormTitle)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 20px;
    font-weight: 500;
    margin-top: 24px;
    text-align: center;
    line-height: 1.3;
  }
`;

export const StyledTableCellFirst = styled(StyledTableCell)`
  width: 50%;
`;
