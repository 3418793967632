import React, { useState, useEffect } from "react";

import {
  FormContainer,
} from "pages/preQualifyForm/styles";

import { MainPanel } from "./mainPanel";
import { MatchPanel } from "./matchPanel";
import { AddPanel } from "./addPanel";
import { ResultPanel } from './resultPanel';
import { FunctionalButton } from "../../../../components/functionalButton";
import { AddProjectPopup } from "components/addProjectPopup";


export const PersonnelSelection = ({
  setActiveStep,
  prequalifyData,
  setPrequalifyData,
  setIsLastPanel,
  setPreviousStepPanel,
  handleNext
}) => {

  const [panel, setPanel] = useState("result" );

  const [projects, setProjects] = useState(
    prequalifyData?.key_personnel ? prequalifyData.key_personnel : []
  );

  // useEffect(() => {
  //   const temp = [...projects]
  //   setPrequalifyData('key_personnel', temp)
  // }, [projects])

  const [addData, setAddData] = useState({ type: ['key'] });
  const [parsed, setParsed] = useState([]);
  const [fields, setFields] = useState([]);
  const [viewer, setViewer] = useState({});
  const [editIndex, setEditIndex] = useState(-1);
  const [openEdit, setOpenEdit] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [project, setProject] = useState('');

  const steps = ["Upload a file",
                 "Match the column titles",
                 "Review and edit"];

  // useEffect(() => {
  //     setPrequalifyData('key_personnel', projects);
  // }, [projects]);

  useEffect(() => {
    if (!openPopup) {
      setProject('');
    }
  }, [openPopup])

  return (
    <FormContainer>
      {panel === "main" && (
        <MainPanel
          steps={steps}
          setPanel={setPanel}
          setParsed={setParsed}
          setFields={setFields}
        />
      )}
      {(openEdit || panel === "add") && (
        <AddPanel
          addData={addData}
          setAddData={setAddData}
          setPanel={setPanel}
          setProjects={setProjects}
          editIndex={editIndex}
          setEditIndex={setEditIndex}
          setOpenEdit={setOpenEdit}
          setOpenPopup={setOpenPopup}
        />
      )}
      {(!openEdit && panel === "match") && (
        <MatchPanel
          steps={steps}
          fields={fields}
          parsed={parsed}
          viewer={viewer}
          setViewer={setViewer}
          setProjects={setProjects}
          setPanel={setPanel}
        />
      )}
      {(!openEdit && panel === "result") && (
        <ResultPanel
          projects={projects}
          setPanel={setPanel}
          setAddData={setAddData}
          setProjects={setProjects}
          setEditIndex={setEditIndex}
          setOpenEdit={setOpenEdit}
          setActiveStep={setActiveStep}
          setOpenPopup={setOpenPopup}
          openPopup={openPopup}
          prequalifyData={prequalifyData}
          setPrequalifyData={setPrequalifyData}
        />
      )}
       <FunctionalButton
        buttonTitle="Next"
        handleButton={() => handleNext()}
        width="200px"
        marginRight="auto"
        marginLeft="auto"
        marginTop="48px"
        disabled={false}
      />
      {/* <AddProjectPopup 
          setOpenPopup={setOpenPopup}
          openPopup={openPopup}
          data={project}
          setData={setProject}
        //  handleButtonFunc={addProject}
        //  isMobile={isMobile}
        /> */}
    </FormContainer>
  );
};

export default PersonnelSelection;
