import React, { useState, useEffect } from "react";

import { SelectingItems } from "./components/selectingItems";

import { SelectedItems } from "./components/selectedItems";
import { AddItemPopup } from "../addItemPopup";
import {
  StyledPicklistSmallHeader,
  StyledPicklistSmallHeaderContainer,
} from "./styles";
import { checkUserAvailable } from "api/buyers";
import { useSelector } from "react-redux";
import { contactSelector } from "data/selectors/contact";
import { ConfirmInvitationPopUp } from "components/confirmInvitationPopUp";

export const ResultPanel = ({
  id,
  inheritState,
  internalSearch,
  items,
  openPopup,
  options,
  readOnly,
  selectedData,
  setItems,
  setOpenPopup,
  setSelectedData,
  submitItem,
  onInteraction,
}) => {
  const [item, setItem] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedIds, setSelectedIds] = useState({});
  const [rerender, setRerender] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [addItem, setAddItem] = useState({});
  const contactData = useSelector(contactSelector);
  const institutionId = contactData?.user_access_control?.institution_id;

  useEffect(() => {
    populateSelectedIds();
  }, [selectedData.length, selectedData, selectedItems]);

  useEffect(() => {
    setOpenPopup(false);
    setItem({});
  }, [items]);

  function populateSelectedIds() {
    var relevantData = [];
    var relevantIds = {};
    if (selectedData) {
      relevantData = selectedData;
    } else if (selectedItems) {
      relevantData = selectedItems;
    }
    relevantData.map((relevantItem) => (relevantIds[relevantItem.id] = true));
    setSelectedIds(relevantIds);
  }

  async function submitPopup() {
    if (options?.addItemsIndividually) {
      if (id === "inviteContractors") {
        const selectedTemp = [...selectedData];
        await checkUserAvailable({ email: item.email })
          .then((data) => {
            if (data?.message === "User not available") {
              var newItem = { id: null, ...item };
              selectedTemp.unshift(newItem);
              setItem({});
            }
            if (data?.length > 0) {
              if (data[0].id === institutionId) {
                //console.log("belongs to your institution");
                setItem({});
              } else {
                var addedItem = items?.find((obj) => obj.id === data[0].id);
                setAddItem(addedItem);
                setOpenDialog(true);
              }
            }
          })
          .catch((err) => {
            console.log("error", err);
          });

        setSelectedData(selectedTemp);
      } else {
        submitItem({ id: id, value: item });
        setItem({});
      }

      setOpenPopup(false);
    } else {
      var newItems = [...items];
      newItems.unshift(
        !item.id && options?.list?.idField
          ? { ...item, id: item[options.list.idField] }
          : item
      );
      setItems(newItems);
      setItem({});
      setOpenPopup(false);
    }
  }

  const handleFinalizeClick = (addItem) => {
    const selectedTemp = [...selectedData];
    const idExists = selectedTemp.some((obj) => obj.id === addItem.id);
    if (!idExists) {
      selectedTemp.unshift(addItem);
      setSelectedData(selectedTemp);
    }
    setAddItem({});
    setItem({});
    setOpenDialog(false);
  };
  // If there are no errors, no loading, save the last item submitted id, and remove loading, remove popup, remove item
  return (
    <>
      <StyledPicklistSmallHeaderContainer>
        <StyledPicklistSmallHeader>
          {options?.labels?.keyItems}
        </StyledPicklistSmallHeader>
      </StyledPicklistSmallHeaderContainer>
      {(!options || !options.disableSelectedItems) && (
        <SelectedItems
          options={options}
          readOnly={readOnly}
          rerender={rerender}
          selectedItems={selectedData || selectedItems}
          setRerender={setRerender}
          setSelectedItems={setSelectedData || setSelectedItems}
          onInteraction={onInteraction}
        />
      )}
      {!readOnly && (
        <>
          <SelectingItems
            internalSearch={internalSearch}
            itemLimit={options?.addLimit}
            items={items}
            selectLimit={options?.selectLimit}
            setItems={setItems}
            setOpenPopup={setOpenPopup}
            options={options}
            rerender={rerender}
            selectedIds={selectedIds}
            selectedItems={selectedData || selectedItems}
            setRerender={setRerender}
            setSelectedItems={setSelectedData || setSelectedItems}
          />
          <AddItemPopup
            data={item}
            form={options?.popupForm}
            inheritState={inheritState?.popupForm}
            openDialog={openPopup}
            options={options}
            setEmptyItem={setItem}
            setData={(e) => setItem({ ...item, [e.id]: e.value })}
            setOpenDialog={setOpenPopup}
            onSubmit={submitPopup}
          />
          <ConfirmInvitationPopUp
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            handleFinalizeClick={handleFinalizeClick}
            item={item}
            addedItem={addItem}
          />
        </>
      )}
    </>
  );
};
