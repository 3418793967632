import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#EBE9F1'
const currentYear = new Date().getFullYear();
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#EBE9F1',
        backgroundColor: '#F8F8F8',
        color: '#888888',
        alignItems: 'center',
        height: 20,
        textAlign: 'left',
        fontStyle: 'bold',
        paddingLeft: 15,
        marginTop: 5,
    },
    name: {
        width: '40%',
        borderRightColor: borderColor,
    },
    year: {
        width: '20%',
        borderRightColor: borderColor,
    },
});

const PageFourSafetyRecordsUSATableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.name}></Text>
        <Text style={styles.year}>{currentYear - 1}</Text>
        <Text style={styles.year}>{currentYear - 2}</Text>
        <Text style={styles.year}>{currentYear - 3}</Text>
    </View>
);

export default PageFourSafetyRecordsUSATableHeader;
