import React, { useEffect, useState } from "react";
import { MediumText } from "./styles.js";
import { useDispatch, useSelector } from "react-redux";
import { StyledContainer } from "./styles";
import { useMediaQuery } from "react-responsive";
import { AllClientsTable } from "pages/underwriters/components/allClientsTable";
import { getUnderwriterClientInfo } from "data/actions/underwriters";
import { underwriterClientSelector } from "data/selectors/underwriters";
import { GoBackButton } from "pages/buyers/components/goBackButton";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar.js";

const PeerGroupAnalysis = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const underwriterClientData = useSelector(underwriterClientSelector);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState(data);


  useEffect(() => {
    if (underwriterClientData.length < 1) {
      dispatch(getUnderwriterClientInfo());
    }
  }, []);
  useEffect(() => {
    if (underwriterClientData) {
      setData(underwriterClientData);
    }
  }, [underwriterClientData]);

  useEffect(() => {
    const filteredData = searchQuery ? data.filter(item =>
      item.AccountId.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.CompanyName.toLowerCase().includes(searchQuery.toLowerCase())
    ) : data;
    setSearchResults(filteredData);
  }, [searchQuery, data]);

  return (
    <StyledContainer isMobile={isMobile}>
      <GoBackButton />
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: "200px"}}>
        <MediumText isMobile={isMobile} style={{ fontSize: '28px', lineHeight: '20px', fontFamily: 'SF Pro Text', color: '#1A051D', marginBottom: '20px', fontWeight: '600' }}>Select your Client</MediumText>

        <SearchBar
          filteredData={data}
          setInternalSearchResults={setSearchResults}
          query={searchQuery}
          setQuery={setSearchQuery}
          internalSearch={true}
          searchWidth={"40%"}
          searchPlaceHolder={['Account ID ', ' Company Name']}
        />

      </div>

      {data && <AllClientsTable
        data={
          searchQuery && searchQuery !== ""
            ? searchResults
            : data
        }
        setData={setData}
        isPGA={true}
      />
      }
    </StyledContainer>
  );
};

export default PeerGroupAnalysis;
