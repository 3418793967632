import React, { useState, useEffect } from "react";
import {
  handleCommaFormat,
  numberWithCommas,
  removeComma,
} from "common/numberFormat";

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper,
  SmallTitleText,
  ButtonDiv,
} from "./styles.js";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Input from "../inputField";
import PledgxLogo from "../../assets/common/pledgxLogo";
import { FunctionalButton } from "../functionalButton";
import DateTimePickerField from "components/dateTimePickerField";
import { addProgressPaymentContractor, addProgressPaymentContractorUnderwriter } from "api/work_in_progress";
import { SubmitSendForApproval } from "./submitSendForApproval";
import { ErrorDateApprovedMessage } from "./errorDateApprovedMessage.js";
import { SubmitErrorMessage } from "./submitErrorMessage";
import { useDispatch } from "react-redux";
import { getClientProjectsInfoForWip, getClientProjectsInfoForWipUnderwriter } from "data/actions/underwriters.js";
import { useLocation } from "react-router-dom";

export const AddPaymentProgressContractorPopup = ({
  setOpenDialog,
  openDialog,
  data,
  setData,
  projectData,
  ppcId,
  isMobile,
  label,
  buttonLabel,
  companyId,
}) => {
  const dispatch = useDispatch();
  const [progressPaymentNo, setProgressPaymentNo] = useState(0);
  const [progressPaymentDate, setProgressPaymentDate] = useState("");
  const [dateApproved, setDateApproved] = useState("");
  const [billingsToDate, setBillingsToDate] = useState("");
  const [costIncurredToDate, setCostIncurredToDate] = useState("");
  const [holdbackRetainageToDate, setHoldbackRetainageToDate] = useState("");
  const lastPreviousProgressPayments = data?.last_previous_progress_payments
    ? removeComma(data?.last_previous_progress_payments)
    : 0;
  const estimatedCostOfRevenue = data?.estimated_cost_of_revenue
    ? removeComma(data?.estimated_cost_of_revenue)
    : 0;
  const [previousProgressPayments, setPreviousProgressPayments] = useState(0);
  const [currentPaymentDue, setCurrentPaymentDue] = useState("");
  const [costToComplete, setCostToComplete] = useState("");
  const [validSubmit, setValidSubmit] = useState(false);
  const [remarks, setRemarks] = useState("");
  const userData = JSON.parse(localStorage.getItem("User"));
  const userEmail = userData?.email;
  const [openSendForApprovalPopup, setOpenSendForApprovalPopup] =
    useState(false);
  const [projectInfo, setProjectInfo] = useState({});
  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [showWarningBillingsToDate, setShowWarningBillingsToDate] =
    useState(false);
  const [showWarningCostIncurredToDate, setShowWarningCostIncurredToDate] =
    useState(false);

  const [openDateApprovedErrorPopup, setOpenDateApprovedErrorPopup] =
    useState(false);
  const [errorMessageForDateAprroved, setErrorMessageForDateAprroved] =
    useState("");
  const location = useLocation();
  const isUnderwriterWip = location.pathname.includes("underwriter/client/wip");
  useEffect(() => {
    setProgressPaymentNo(ppcId);
  }, [ppcId]);

  useEffect(() => {
    if (
      progressPaymentDate?.length > 0 &&
      costIncurredToDate?.length > 0 &&
      billingsToDate?.length > 0
      // removeComma(costIncurredToDate) >=
      // removeComma(data?.old_cost_incurred_to_date) &&
      // removeComma(billingsToDate) >= removeComma(data?.old_billings_to_date)
    ) {
      setValidSubmit(false);
    } else {
      setValidSubmit(true);
    }
  }, [progressPaymentDate, costIncurredToDate, billingsToDate]);

  useEffect(() => {
    const updatedData = { ...data };
    updatedData["billings_to_date"] = billingsToDate
      ? removeComma(billingsToDate)
      : 0;
    const diffPreviousProgressPayments =
      removeComma(billingsToDate) - removeComma(holdbackRetainageToDate);
    setPreviousProgressPayments(diffPreviousProgressPayments);
    const diffCurrentPaymentDue =
      diffPreviousProgressPayments - lastPreviousProgressPayments;
    setCurrentPaymentDue(numberWithCommas(diffCurrentPaymentDue));

    if (removeComma(billingsToDate) < removeComma(data?.old_billings_to_date)) {
      setShowWarningBillingsToDate(true);
    } else {
      setShowWarningBillingsToDate(false);
    }

    setData(updatedData);
  }, [billingsToDate]);

  useEffect(() => {
    const updatedData = { ...data };
    updatedData["holdback_retainage_to_date"] = holdbackRetainageToDate
      ? removeComma(holdbackRetainageToDate)
      : 0;
    const diffPreviousProgressPayments =
      removeComma(billingsToDate) - removeComma(holdbackRetainageToDate);
    setPreviousProgressPayments(diffPreviousProgressPayments);
    const diffCurrentPaymentDue =
      diffPreviousProgressPayments - lastPreviousProgressPayments;
    setCurrentPaymentDue(numberWithCommas(diffCurrentPaymentDue));

    setData(updatedData);
  }, [holdbackRetainageToDate]);

  useEffect(() => {
    const updatedData = { ...data };
    updatedData["cost_incurred_to_date"] = costIncurredToDate
      ? removeComma(costIncurredToDate)
      : 0;
    const diffCostToComplete =
      estimatedCostOfRevenue - removeComma(costIncurredToDate);
    setCostToComplete(numberWithCommas(diffCostToComplete));

    if (
      removeComma(costIncurredToDate) <
      removeComma(data?.old_cost_incurred_to_date)
    ) {
      setShowWarningCostIncurredToDate(true);
    } else {
      setShowWarningCostIncurredToDate(false);
    }

    setData(updatedData);
  }, [costIncurredToDate]);

  useEffect(() => {
    const updatedData = { ...data };
    updatedData["progress_payment_no"] = ppcId;
    updatedData["current_payment_due"] = currentPaymentDue
      ? currentPaymentDue
      : 0;
    updatedData["progress_payment_date"] = progressPaymentDate;
    updatedData["cost_to_complete"] = costToComplete ? costToComplete : 0;
    updatedData["date_approved"] = dateApproved;
    updatedData["remarks"] = remarks ? remarks : "";
    setData(updatedData);
  }, [
    progressPaymentNo,
    currentPaymentDue,
    progressPaymentDate,
    costToComplete,
    dateApproved,
    remarks,
  ]);

  const handleAddProgressPaymentContractor = () => {
    const isUnderwriterWip = location.pathname.includes("underwriter/client/wip"); 
    const billings_to_date = billingsToDate ? removeComma(billingsToDate) : 0;
    const progress_payment_no = progressPaymentNo;
    const cost_incurred_to_date = costIncurredToDate
      ? removeComma(costIncurredToDate)
      : 0;
    const holdback_retainage_to_date = holdbackRetainageToDate
      ? removeComma(holdbackRetainageToDate)
      : 0;
    const current_payment_due = currentPaymentDue
      ? removeComma(currentPaymentDue)
      : 0;
    const progress_payment_date = progressPaymentDate;
    const cost_to_complete = costToComplete ? removeComma(costToComplete) : 0;
    const date_approved = dateApproved;
    const send_for_approval = false;
    const newData = {
      ...data,
      progress_payment_no: progress_payment_no,
      billings_to_date: billings_to_date,
      cost_incurred_to_date: cost_incurred_to_date,
      holdback_retainage_to_date: holdback_retainage_to_date,
      current_payment_due: current_payment_due,
      progress_payment_date: progress_payment_date,
      cost_to_complete: cost_to_complete,
      date_approved: date_approved,
      remarks: remarks,
      user_email: userEmail,
      send_for_approval: send_for_approval,
      id: projectData?.id,
    };

    if (date_approved !== "" && date_approved !== null) {
      if(isUnderwriterWip){ 
        addProgressPaymentContractorUnderwriter(
          newData,
        () => {
          setOpenDialog(false);
          setProgressPaymentNo("");
          setProgressPaymentDate("");
          setDateApproved("");
          setBillingsToDate("");
          setCostIncurredToDate("");
          setHoldbackRetainageToDate("");
          setPreviousProgressPayments("");
          setCurrentPaymentDue("");
          setCostToComplete("");
          setRemarks("");

          dispatch(
            getClientProjectsInfoForWipUnderwriter({ account_id: companyId }, "underway")
          );
        })
      }else{
        addProgressPaymentContractor(
          newData,
        () => {
          setOpenDialog(false);
          setProgressPaymentNo("");
          setProgressPaymentDate("");
          setDateApproved("");
          setBillingsToDate("");
          setCostIncurredToDate("");
          setHoldbackRetainageToDate("");
          setPreviousProgressPayments("");
          setCurrentPaymentDue("");
          setCostToComplete("");
          setRemarks("");

          dispatch(
            getClientProjectsInfoForWip({ account_id: companyId }, "underway")
          );
        },
        () => {}
      );
      }
    } else {
      setErrorMessageForDateAprroved(
        "Date Approved field must be filled up before saving."
      );
      setOpenDateApprovedErrorPopup(true);
    }
  };

  const handleAddProgressPaymentContractorForApproval = () => {
    const progress_payment_no = progressPaymentNo;
    const billings_to_date = billingsToDate ? removeComma(billingsToDate) : 0;
    const cost_incurred_to_date = costIncurredToDate
      ? removeComma(costIncurredToDate)
      : 0;
    const holdback_retainage_to_date = holdbackRetainageToDate
      ? removeComma(holdbackRetainageToDate)
      : 0;
    const current_payment_due = currentPaymentDue
      ? removeComma(currentPaymentDue)
      : 0;
    const progress_payment_date = progressPaymentDate;
    const cost_to_complete = costToComplete ? removeComma(costToComplete) : 0;
    const date_approved = dateApproved;
    const send_for_approval = true;
    const buyer_email = projectData?.owner_email
      ? projectData?.owner_email
      : projectData?.consultant_email
      ? projectData?.consultant_email
      : "";
    const buyer_name = projectData?.owner_contact
      ? projectData?.owner_contact
      : projectData?.consultant_contact
      ? projectData?.consultant_contact
      : "";
    const general_contractor = userData?.institution?.name
      ? userData?.institution?.name
      : "";
    const project_name = projectData?.project_name
      ? projectData?.project_name
      : "";
    const newData = {
      ...data,
      progress_payment_no: progress_payment_no,
      billings_to_date: billings_to_date,
      cost_incurred_to_date: cost_incurred_to_date,
      holdback_retainage_to_date: holdback_retainage_to_date,
      current_payment_due: current_payment_due,
      progress_payment_date: progress_payment_date,
      cost_to_complete: cost_to_complete,
      date_approved: date_approved,
      remarks: remarks,
      user_email: userEmail,
      send_for_approval: send_for_approval,
      buyer_email: buyer_email,
      buyer_name: buyer_name,
      general_contractor: general_contractor,
      project_name: project_name,
    };

    if (buyer_email === "") {
      setOpenErrorPopup(true);
    } else {
      if (date_approved !== "" && date_approved !== null) {
        setErrorMessageForDateAprroved(
          "Date Approved field must be empty before sending approval."
        );
        setOpenDateApprovedErrorPopup(true);
      } else {
        setProjectInfo(newData);
        setOpenSendForApprovalPopup(true);
      }
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <StyledPaper elevation={0} isMobile={isMobile}>
          <CloseButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
          <FormTitle
            style={{
              marginTop: "10px",
              color: "#1A051D",
              fontSize: "22px",
              fontWeight: "600",
              fontStyle: "normal",
              lineHeight: "24px",
            }}
          >
            {label || "Progress Payment"}
          </FormTitle>

          <SmallTitleText
            style={{
              marginBottom: 0,
              paddingBottom: "15px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {data?.project_name}
          </SmallTitleText>

          <InfoArea isMobile={isMobile} marginTop="0px">
            <div
              style={{
                margin: "0px",
                marginBottom: "-15px",
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Input
                id="oneThirdInputWrap"
                label="Progress Payment #"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={progressPaymentNo}
                setValue={setProgressPaymentNo}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noErrorBorder={true}
              />
              <DateTimePickerField
                showDateField={true}
                dateWidth="33%"
                paddingRight="16px"
                dateLabel="Progress Payment Date*"
                dateValue={progressPaymentDate}
                setDateValue={setProgressPaymentDate}
              />

              <DateTimePickerField
                showDateField={true}
                dateWidth="34%"
                paddingRight="0px"
                dateLabel="Date Approved"
                dateValue={dateApproved}
                setDateValue={setDateApproved}
              />
            </div>

            <div
              style={{
                margin: "0px",
                marginBottom: "-15px",
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Input
                id="oneThirdInputWrap"
                label="Cost Incurred to Date*"
                type="text"
                placeholder="$"
                labelSize="12px"
                fontSize="18px"
                value={costIncurredToDate}
                setValue={(value) => {
                  setCostIncurredToDate(value.toLocaleString());
                }}
                onBlur={(e) =>
                  setCostIncurredToDate(handleCommaFormat(e.target.value))
                }
                noCheckIcon={true}
                noErrorBorder={true}
              />

              <Input
                id="oneThirdInputWrap"
                label="Billings to Date*"
                type="text"
                placeholder="$"
                labelSize="12px"
                fontSize="18px"
                value={billingsToDate}
                setValue={(value) => {
                  setBillingsToDate(value.toLocaleString());
                }}
                onBlur={(e) =>
                  setBillingsToDate(handleCommaFormat(e.target.value))
                }
                noCheckIcon={true}
                noErrorBorder={true}
              />

              <Input
                id="oneThirdInputWrap last"
                label="Holdback/Retainage to Date*"
                type="text"
                placeholder="$"
                labelSize="12px"
                fontSize="18px"
                value={holdbackRetainageToDate}
                setValue={(value) => {
                  setHoldbackRetainageToDate(value.toLocaleString());
                }}
                onBlur={(e) =>
                  setHoldbackRetainageToDate(handleCommaFormat(e.target.value))
                }
                noCheckIcon={true}
                noErrorBorder={true}
              />
            </div>

            <div
              style={{
                margin: "0px",
                marginBottom: "-15px",
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Input
                id="oneThirdInputWrap"
                label="Previous Progress Payments"
                type="text"
                placeholder="$"
                labelSize="12px"
                fontSize="18px"
                value={handleCommaFormat(data?.previous_progress_payments)}
                noCheckIcon={true}
                noErrorBorder={true}
                backgroundColor="rgba(235, 233, 241, 0.75)"
                disabled
              />
              <Input
                id="oneThirdInputWrap"
                label="Current Payment Due"
                type="text"
                placeholder="$"
                labelSize="12px"
                fontSize="18px"
                value={handleCommaFormat(currentPaymentDue)}
                noCheckIcon={true}
                noCancelIcon={true}
                noErrorBorder={true}
                backgroundColor="rgba(235, 233, 241, 0.75)"
                disabled
              />

              <Input
                id="oneThirdInputWrap last"
                label="Cost to Complete"
                type="text"
                placeholder="$"
                labelSize="12px"
                fontSize="18px"
                value={handleCommaFormat(costToComplete)}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noCancelIcon={true}
                noErrorBorder={true}
                backgroundColor="rgba(235, 233, 241, 0.75)"
                disabled
              />
            </div>

            <div
              style={{
                margin: "0px",
                display: "flex",
                flexDirection: "row",
                width: "104%",
              }}
            >
              <Input
                id="fullWidthInputWrap last"
                label="Remarks"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={remarks}
                setValue={setRemarks}
                validateFunc={(item) => item.length > 0}
                noCheckIcon={true}
                noCancelIcon={true}
                noErrorBorder={true}
              />
            </div>

            {showWarningBillingsToDate && (
              <div style={{ color: "red", fontSize: "12px", marginTop: "0px" }}>
                Billings to Date* value should not be less than{" "}
                {numberWithCommas(removeComma(data?.old_billings_to_date))}
              </div>
            )}

            {showWarningCostIncurredToDate && (
              <div style={{ color: "red", fontSize: "12px", marginTop: "0px" }}>
                Cost Incurred to Date* value should not be less than{" "}
                {numberWithCommas(removeComma(data?.old_cost_incurred_to_date))}
              </div>
            )}
          </InfoArea>

          <SubmitSendForApproval
            isMobile={isMobile}
            data={projectInfo}
            openPopup={openSendForApprovalPopup}
            setOpenPopup={setOpenSendForApprovalPopup}
            setOpenDialog={setOpenDialog}
          />

          <SubmitErrorMessage
            isMobile={isMobile}
            openPopup={openErrorPopup}
            setOpenPopup={setOpenErrorPopup}
          />

          <ErrorDateApprovedMessage
            isMobile={isMobile}
            openPopup={openDateApprovedErrorPopup}
            setOpenPopup={setOpenDateApprovedErrorPopup}
            errorMessage={errorMessageForDateAprroved}
          />

          <ButtonDiv isMobile={isMobile}>
            <FunctionalButton
              disabled={validSubmit}
              width="35%"
              marginTop="10px"
              marginBottom="34x"
              marginRight="16px"
              handleButton={() => {
                handleAddProgressPaymentContractor();
              }}
              buttonTitle={buttonLabel || "SAVE AS APPROVED"}
            />

            <FunctionalButton
              disabled={isUnderwriterWip ? true :validSubmit}
              width="35%"
              marginTop="10px"
              marginBottom="34x"
              marginRight="0px"
              handleButton={() => {
                handleAddProgressPaymentContractorForApproval();
              }}
              buttonTitle={buttonLabel || "SEND FOR APPROVAL"}
            />
          </ButtonDiv>
        </StyledPaper>
      </Dialog>
    </>
  );
};
