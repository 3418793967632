import React, { useEffect, useState } from "react";

import { 
  ColumnContainer,
  StyledBox,
} from './styles';

import { CompanySummary } from "./leftComponents/CompanySummary";
import { GeneralInfo } from './leftComponents/GeneralInfo';
import { TopProjects } from './leftComponents/TopProjects';
import { TopPersonnel } from './leftComponents/TopPersonnel';
import { getAwardsPoint, getCompanyReferenceInfo, getScores } from '../../api/reference';
import { COMPANY_DATA } from './MockData';
import { Awards } from './leftComponents/Awards';
import { Scores } from './leftComponents/Scores';
export const LeftContainer = ({
  isMobile
}) => { 
  const [companyData, setCompanyData] = useState({})
  const [logo, setLogo] = useState('')
  const [points, setPoints] = useState('')
  const [scores, setScores] = useState('')

  useEffect(() => {
    getCompanyReferenceInfo(
      (res) => {
        setCompanyData(res); 
        setLogo(res.logo);
        getAwardsPoint(res?.id, (data) => setPoints(data), () => {});
        getScores(res?.id, (data) => setScores(data), () => {});
      },
      () => {}
    )
  },[])


  return (
    <ColumnContainer isMobile={isMobile} margin="0 16px 0 0px" width="40%">
      <CompanySummary isMobile={isMobile} data={companyData} logo={logo} setLogo={setLogo}/>
      {points && (
        <Awards isMobile={isMobile} points={points} />
      )}
      {scores && (
        <Scores isMobile={isMobile} scores={scores} />
      )}
      {/* <GeneralInfo isMobile={isMobile} data={companyData} /> */}
      {/* <TopProjects isMobile={isMobile} />
      <TopPersonnel isMobile={isMobile} /> */}
    </ColumnContainer>
  )
};