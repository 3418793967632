import styled from "styled-components";
import { Container } from "../../components/common";

export const StyledContainer = styled(Container)`
  && {
    // padding-bottom: 100px;
    margin: 0 ${(props) => (props.isMobile ? "0px" : "70px")};
    width: 100%;
    height: auto;
  }
`;

export const StyledMainContainer = styled.div`
  width: 100%;
  height: 300px;
  margin-top: 18px;
  display: flex;
`;

export const StyledLeftContainer = styled.div`
  width: 35%;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledRightContainer = styled.div`
  width: 65%;
  display: flex;
  align-items: center;
`;

export const StyledGraphContainer = styled.div`
  width: 23%;
  height: 100%;
  border-radius: 6px;
  border: 1px solid #ebe9f1;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "18px")};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 23px 18px 23px;
`;

export const StyledOutsideContainer = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8;
  display: inline-flex;
`;

export const StyledInnerContainer = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
`;
export const StyledTitleContainer = styled.div`
  color: ${(props) => (props.color ? props.color : " #6e6b7b")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-family: SF Pro Text;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "600")};
  // word-wrap: break-word;
`;
export const StyledValueContainer = styled.span`
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  font-family: SF Pro Text;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
  word-wrap: break-word;
`;
export const StyledChartContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: inline-flex;
`;
export const StyledTitle = styled.div`
  color: #6e6b7b;
  font-family: SF Pro Text;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  width: 28%;
  display: flex;
  align-items: center;
  //   justify-content: center;
`;

export const StyledInputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  gap: 28px;
  justifycontent: center;
`;

export const StyledTextContainer = styled.div`
  color: #6e6b7b;
  fontsize: 14;
  fontfamily: SF Pro Text;
  fontweight: 600;
  lineheight: 20;
  wordwrap: break-word;
`;
