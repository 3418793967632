import { CircularProgress } from "@mui/material";
import {
  confirmFileUpload,
  createFile,
  updateCCDC_AdditionalFileBlob,
  uploadFileToGCP,
} from "api/file_managment";

import { COLORS } from "../../../../constants";
import React, { useState } from "react";
import { useEffect } from "react";
import FilesListTable from "../../../../components/filesListTable";
import { StyledTableCell } from "../../../../components/filesListTable/styles";
import { DeleteButton } from "../deleteButton";
import {
  StyledTableCellFirst,
  StyleDisplay,
  StyledTableCellSecond,
} from "./styles";
import Select from "../../../../components/selectField";

export const WrapperForUploadedHealthSafetyFileDisplay = ({
  file,
  deleteFile,
  displayPercentage,
  index,
  marginTop,
  noActions,
  handleClickRow,
  setPrequalifyData,
  prequalifyData,
  requirements
}) => {
  const [openProgress, setOpenProgress] = useState(false);

  let uploadFileName = file.name;
  let extension = uploadFileName.split(".").pop();
  let fileName = uploadFileName.replace("." + extension, "");

  let dataForFirstApi = {
    file_type: "Institution_file",
    file_extension: extension,
    file_name: fileName,
  };


  return (
    <UploadedFileDisplay
      file={file}
      deleteFile={deleteFile}
      displayPercentage={displayPercentage}
      index={index}
      marginTop={marginTop}
      noActions={noActions}
      handleClickRow={handleClickRow}
      openProgress={openProgress}
      requirements={requirements}
      setPrequalifyData={setPrequalifyData}
      prequalifyData={prequalifyData}
    ></UploadedFileDisplay>
  );
};

export const UploadedFileDisplay = ({
  file,
  deleteFile,
  displayPercentage,
  index,
  marginTop,
  noActions,
  handleClickRow,
  openProgress,
  requirements,
  setPrequalifyData,
  prequalifyData
}) => {

  const actions = (props) => {
    return (
      <>
        <StyledTableCell component={"tr"} align="right">
          {openProgress && (
            <CircularProgress
              style={{
                height: "20px",
                width: "20px",
                color: COLORS.primaryOrange,
              }}
              disableShrink
            />
          )}
          {!openProgress && (
            <DeleteButton
              handleClick={() => {
                deleteFile(index);
              }}
            />
          )}
        </StyledTableCell>
      </>
    );
  };

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCellFirst component={"tr"}>
          {bodyData.file_name}
        </StyledTableCellFirst>
        <StyledTableCell component={"tr"} scope="row" align="right" width="20%">
          {bodyData.file_size}
        </StyledTableCell>
      </>
    );
  };

  const bytesToMegaBytes = (bytes, roundTo) => {
    const sizeMB = roundTo
      ? (bytes / (1024 * 1024)).toFixed(roundTo)
      : bytes / (1024 * 1024);
    return sizeMB + " MB";
  };

  const uploadedFileDetails = [
    {
      file_name: file?.name,
      file_size: file?.size ? bytesToMegaBytes(file?.size, 1) : "",
    },
  ];

  return (
    <StyleDisplay
      displayPercentage={displayPercentage}
      marginTop={index === 0 ? "10px" : 0}
    >
      <FilesListTable
        data={uploadedFileDetails}
        body={body}
        actions={actions}
        noActions={noActions}
        actionType="file"
        concise
        borderSpacing="0 4px !important"
        handleClickRow={handleClickRow}
        position="relative"
      />
    </StyleDisplay>
  );
};
