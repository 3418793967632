import React, { useState, useEffect } from "react";

import ConvertToDate from '../../../common/convertToDate';

import { FormTitle } from "../styles";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import Select from "../../../components/selectField";

import { LooksGoodButton } from "../components/looksGoodButton";


export const MatchPanel = ({
  steps,
  fields,
  parsed,
  viewer,
  setViewer,
  setProjects,
  setPanel,
}) => {
  const [mapper, setMapper] = useState({});

  const matchOptions = [{ value: "Not Applicable", label: "Not Applicable" },
                        { value: "Project Title", label: "Project Title" },
                        { value: "Contract Type", label: "Contract Type" },
                        { value: "Date Completed", label: "Date Completed" },
                        { value: "Owner", label: "Owner" },
                        { value: "Consultant", label: "Consultant" },
                        { value: "Project Value", label: "Project Value" }];

  return (
    <>
      <FormTitle>Your Key Projects</FormTitle>
      <Stepper activeStep={1} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <TableContainer
        component={Paper}
        elevation={0}
        style={{ marginTop: 15 }}
      >
        <Table size="small">
          <TableHead style={{ backgroundColor: "rgba(242, 105, 52, 0.1)" }}>
            <TableRow>
              {fields.map((item, i) => (
                <TableCell
                  key={i}
                  style={{
                    padding: "1px 6px",
                    fontSize: "11px",
                    whiteSpace: "nowrap",
                    borderLeft: "1px solid rgba(242, 105, 52, 0.1)",
                    borderRight: "1px solid rgba(242, 105, 52, 0.1)",
                    borderTop: "1px solid rgba(242, 105, 52, 0.1)",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {parsed.map((row, i) => (
              <TableRow key={i}>
                {fields.map((field, i) => (
                  <TableCell
                    key={i}
                    style={{
                      fontSize: "11px",
                      whiteSpace: "nowrap",
                      padding: "1px 6px",
                      borderLeft: "1px solid rgba(224, 224, 224, 1)",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    {row[field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Table size="small" style={{ marginTop: 40 }}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                fontSize: "15px",
                fontWeight: "bold",
                background: "#F9F7F7",
                border: "1px solid #F9F7F7",
                width: "50%",
              }}
            >
              Your uploaded column header
            </TableCell>
            <TableCell
              style={{
                fontSize: "15px",
                color: "white",
                fontWeight: "bold",
                background: "#FF6D1D",
                border: "1px solid #FF6D1D",
                width: "50%",
              }}
            >
              Pledgx column header
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((field, i) => (
            <TableRow key={i}>
              <TableCell style={{ border: "none" }}>{field}</TableCell>
              <TableCell style={{ padding: "5px", border: "none" }}>
                <Select
                  withoutMargin
                  options={matchOptions}
                  value={viewer[field] ? viewer[field] : ""}
                  setValue={(item) => {
                    setMapper((prev) => {
                      return { ...prev, [item]: field };
                    });
                    setViewer((prev) => {
                      return { ...prev, [field]: item };
                    });
                  }}
                  validateFunc={(item) => Boolean(item)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <LooksGoodButton
        style={{ maxWidth: 370 }}
        onClick={() => {
          let result = [];
          parsed.map((item) => {
            result.push({
              name: item[mapper["Project Title"]],
              completed: ConvertToDate(item[mapper["Date Completed"]]),
              buyer: item[mapper["Owner"]],
              consultant: item[mapper["Consultant"]],
              price: item[mapper["Project Value"]],
              contractType: item[mapper["Contract Type"]],
            });
          });
          setProjects(result);
          setPanel("result");
        }}
      >
        {'Looks good >'}
      </LooksGoodButton>
    </>
  );
};
