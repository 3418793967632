import React from "react";
import BorderLessConciseTable from "../../../../components/filesListTable/borderLessConciseTable";
import { StyledTableCellFirst, StyledTableCheckBoxCell, StyledTableCenteringContainer, StyledTableCellSecond, StyledMinusImg, StyledIndexCell } from "./styles";
import RoundedCheckbox from "../../../../components/roundedCheckbox";
import minusSignIcon from "../../../../assets/common/minusSignIcon.svg";
import { COLORS } from "../../../../constants";


export const SelectedPersonnels = ({ qualificationData, setQualificationData }) => {
  const removePersonnel = (e, index) => {
    const temp = [...qualificationData?.key_personnel];
    temp.splice(index, 1);

    setQualificationData('key_personnel', temp);
  };

  const changePersonnelType = (e, index) => {
    const temp = [...qualificationData?.key_personnel];
    const type = e.target.value;

    const personnelType = temp[index].type;

    const typeExists = personnelType.indexOf(type);

    if(typeExists >= 0) {
      personnelType.splice(typeExists, 1);
    } else {
      personnelType.push(type);
    }

    setQualificationData('key_personnel', temp);
  };

  const body = (bodyData) => { return (
    <>
      <StyledIndexCell>
        {bodyData.index + 1}
      </StyledIndexCell>
      <StyledTableCellFirst>
        {`${bodyData.firstName} ${bodyData.lastName}`}
      </StyledTableCellFirst>
      <StyledTableCellSecond>
      </StyledTableCellSecond>
      <StyledTableCellFirst>
        {bodyData.position}
      </StyledTableCellFirst>
      <StyledTableCheckBoxCell>
        <StyledTableCenteringContainer>
          <RoundedCheckbox value='key_site' attributeTypes={bodyData.type} onChange={(e) => changePersonnelType(e, bodyData.index)} />
        </StyledTableCenteringContainer>
      </StyledTableCheckBoxCell>
      <StyledTableCheckBoxCell>
        <StyledTableCenteringContainer>
          <RoundedCheckbox value='key_office' attributeTypes={bodyData.type} onChange={(e) => changePersonnelType(e, bodyData.index)} />
        </StyledTableCenteringContainer>
      </StyledTableCheckBoxCell>
      <StyledTableCheckBoxCell>
        <StyledTableCenteringContainer>
          <StyledMinusImg src={minusSignIcon} onClick={(e) => removePersonnel(e, bodyData.index)} />
        </StyledTableCenteringContainer>
      </StyledTableCheckBoxCell>
    </>
  )};

  const headers = [
    { title: '' },
    { title: 'Personnel Name' },
    { title: '' },
    { title: 'Position' },
  ];

  const checkBoxHeaders = [
    { title: 'Site' },
    { title: 'Office' },
  ];

  const actionsHeader = [
    { title: 'Remove' }
  ];

  return (
    <>
      <BorderLessConciseTable
        headers={headers}
        checkBoxHeaders={checkBoxHeaders}
        length={3.5}
        body={body}
        data={qualificationData?.key_personnel}
        actionsHeaders={actionsHeader}
      />
    </>
  );
};

export default SelectedPersonnels;
