import { Concierge } from './Concierge'
import { useMediaQuery } from "react-responsive";
import * as React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { StyledTab, StyledTabList, SetupUserManagementContainer } from './styles';
import { UserManagement } from '../../../pages/rbacPages/userManagement/index';
import { Subscription } from './Subscription'

export const Admin = () => {
  const [selectTab, setSelectTab] = React.useState('1');
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const handleChange = (event, newValue) => {
    setSelectTab(newValue);
  };

  return (

      <SetupUserManagementContainer isMobile={isMobile} leftBorder={false}>
        <TabContext value={selectTab}>

          <StyledTabList onChange={handleChange} variant="fullWidth" centered TabIndicatorProps={{hidden:true}}>
            <StyledTab label="Concierge Tools" value="1" />
            <StyledTab label="User Management" value="2" />
            <StyledTab label="Subscription Management" value="3" />
          </StyledTabList>

          <TabPanel value="1">
            <Concierge />
          </TabPanel>
          <TabPanel value="2">
            <UserManagement />
          </TabPanel>
          <TabPanel value="3">
            <Subscription />
          </TabPanel>

        </TabContext>
      </SetupUserManagementContainer>


  );
};
