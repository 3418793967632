import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { GraphicContainer } from '../loginPage/GraphicContainer';
import { ConciergeLogin } from './ConciergeLogin';

import { Switch, Redirect, Route } from 'react-router-dom';

import {
  LoginRoot,
} from '../loginPage/styles';


function StaffLoginPage() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isMedium = useMediaQuery({ maxWidth: 720 });

  return (
      <LoginRoot isMobile={isMedium}>
        {/* {isMobile && (
          <Header isMobile={isMobile} useLinks redirectToMain></Header>
        )} */}
        <Switch>
          <Route exact path='/staff/account' render={() => <Redirect to='/staff/account/concierge_login' />} />
          <Route path='/staff/account/concierge_login'>
            <ConciergeLogin isMobile={ isMobile }/>
          </Route>
        </Switch>
        {!isMedium && (
          <GraphicContainer />
        )}
      </LoginRoot>
  );
}

export default StaffLoginPage;
