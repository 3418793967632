import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from '../../constants';
import Typography from '@material-ui/core/Typography';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@material-ui/core/IconButton';



export const DataTable = styled.table`
  font-family: ${TYPOGRAPHY.primaryFont};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 6px;
  border-collapse: collapse;
  border-radius: 6px; 
  overflow: hidden;
  th{
    text-align: center;
    border-right: 2px solid white;
    border-left: 2px solid white;
    border-bottom: 6px solid white;
  }
  td {
    padding: 8px;
    font-size: 14px;
    font-weight: 400;
    font-family:'SF Pro Text';
    border: 2px solid white;
  }
`;

export const StyledTooltip = styled(Tooltip)`
`;

export const StyledTooltipButton = styled(IconButton)`
  &&{
    background-color: none;
    width: ${props => props.isMobile ? "46%" : "49%"};
    margin: 0px;
    padding: 0px;
    &:hover{
      background-color: none;
    }
  }
`;

export const ButtonPlacement = styled.div`
  width: ${props => props.isMobile ? "46%" : "49%"};
`;

export const ScreenRoot = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  ${(props) => (props.isMobile ? "position: relative;" : "")}
  width: 100%;
  max-width: 1440px;
  ${(props) => (props.isMobile ? "" : "align-content: center;" )}
  ${(props) => (props.isMobile ? "" : "justify-content: center;")}
  ${(props) => (props.isMobile ? "padding: 70px 0 0 0;" : "padding: 70px 70px 0px 70px;" )}
  background-color: ${COLORS.backgroundGrey};
`;

export const FormContainer = styled.div`
  width: ${(props) => (props.isMobile ? "85%" : "75%")};
  ${(props) => (props.isMobile ? "align-self: center;" : "")}
  padding-bottom: 20px;
  margin-top: ${(props) => (props.isMobile ? "16px" : "24px")};
  display: flex;
  flex-direction: column;
  // align-items: center;
  // ${(props) => (props.leftBorder ? "margin-top: 50px;" : "")};
  border-left: ${(props) => (props.leftBorder ? "3px solid #EBE9F1" : "none")};
`;

export const DetailedFormContainer = styled(FormContainer)`
  &&{
    width: ${(props) => (props.isMobile && !props.noWidthChange ? "90%" : "100%")};
  }
`;
export const SideContainer = styled.div`
  width: ${(props) => (props.isMobile ? "90%" : "25%")};
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
`;

export const LargeText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.isMobile ? "18px" : "28px"};
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const StyledSpan = styled.span`
    color: ${COLORS.primaryOrange};
`;

export const MediumText = styled(Typography)`
  && {
    font-family:  ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.isMobile ? "14px" : "18px"};
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
    color:  ${props => props.isMobile ? `${COLORS.textGrey}` : "black"};
  }
`;

export const HeadingText = styled(Typography)`
  && {
    font-family:  ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.isMobile ? "14px" : "18px"};
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    width: 100%;
    text-align: left;
    margin-bottom: 8px;
    color:  ${props => props.isMobile ? `${COLORS.textGrey}` : "black"};
  }
`;


export const SmallText = styled(Typography)`
  && {
    font-family:  ${TYPOGRAPHY.primaryFont};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 6px;
  }
`;

export const GreySmallText = styled(SmallText)`
  &&{
    color: ${COLORS.textGrey};
    font-weight: 400;
  }
`;

export const TinyText = styled(SmallText)`
  &&{
    font-size: 12px;
    font-weight: 400;
    color: black;
  }
`;

export const MediumTinyText = styled(SmallText)`
&&{
  font-size: ${props => props.fontSize || "13px"};
  color: black;
}
`;

export const PlaceholderText = styled(SmallText)`
  &&{
    color: ${COLORS.textGrey};
    font-weight: 400;
    text-align: center;
    ${props => props.hasBorderTop ? "border-top: 2px solid #EBE9F1; padding-top: 8px;" : ""}
  }
`;

export const TooltipText = styled(SmallText)`
  &&{
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0px;
  }
`;

export const RegularText = styled(SmallText)`
  &&{
    font-size: 12px;
    font-weight: 500;
    color: black;
    margin-bottom: 0px;
  }
`;


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${props => props.isMobile ? "14px" : "32px"};
  margin-right: ${props => props.isMobile ? "" : "70px"};
  // ${(props) => (props.isMobile && props.marginTop ? `margin-top: ${props.marginTop};` : "")}
`;

export const StyledArea = styled.div`
  width: 100%;
  display: flex;
  ${props => props.isMobile ? "flex-wrap: wrap;" : ""}
  justify-content: ${props => props.isMobile ? "space-around" : "space-between"};
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop};` : "")}
`;

export const StyledBox = styled.div`
  width: ${props => props.boxWidth || "100%"};
  ${props => props.hasData ? "" : `border-radius: ${props.borderRadius || `20px`}; margin-bottom: 12px;`}
  ${props => props.hasData ? "" : "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;"}
  height: ${props => props.boxHeight || "248px"};
  display: flex;
  flex-direction: ${props => props.flexDirection || "column"};
  ${props => props.isMobile ? "align-items: center;" : ""}
  ${props => props.margin ?  `margin: ${props.margin};` : ""}
  ${props => props.hasData ? "" : "justify-content: center;"}
  ${props => props.noBackground ? "" : "background-color: white;"}
`;