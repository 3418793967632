import React from 'react';
import contractor from './contractorIcon.svg'
import styled from "styled-components";

const ContractorIcon = ({width, height, isMobile}) => {
  return (
    <StyledImg width={width || "400"} height={height || "250"} src={contractor} isMobile={isMobile}/>
  )
};

export default ContractorIcon;

const StyledImg = styled.img`
  ${props => props.isMobile ? "margin-bottom: 8px; margin-top: 12px;" : ""}
`;