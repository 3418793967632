import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper{
    width: ${(props) => (props.width || "800px")};
    height:  ${(props) => (props.height || "325px")};;
  }
  .MuiDialog-paperWidthSm{
    max-width: 1200px;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  ${(props) => (props.height ? `height: ${ props.height }` : '')};
  ${(props) => (props.padding ? `padding: ${ props.padding } !important` : '')};
  &&.MuiDialogContent-root{
    flex: none;
    margin: ${(props) => (props.margin || "0")};
  }
`;
