import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";

import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as contact } from "../../../assets/preQualifyIcons/contact.svg";
import { ReactComponent as financial } from "../../../assets/preQualifyIcons/financial.svg";
import { ReactComponent as office } from "../../../assets/preQualifyIcons/office.svg";
import { ReactComponent as projects } from "../../../assets/preQualifyIcons/projects.svg";
import { ReactComponent as years } from "../../../assets/preQualifyIcons/years.svg";
import { ReactComponent as structure } from "../../../assets/preQualifyIcons/structure.svg";
import { ReactComponent as admin } from "../../../assets/preQualifyIcons/review.svg";

import { StyledStepConnector, StyledStepLabel, StyledStepper } from './styles';

const useColorlibStepIconStyles = makeStyles({
  root: {
    cursor: "pointer",
    zIndex: 1,
    color: "#b8b8b8",
    width: 30,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: { color: "#888888" },
  completed: { color: '#B9B9C3' },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SvgIcon component={admin} />,
    2: <SvgIcon component={structure} />,
    3: <SvgIcon component={office} />,
    4: <SvgIcon component={years} />,
    5: <SvgIcon component={office} />,
    6: <SvgIcon component={projects} />,
    7: <SvgIcon component={admin} />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

export const SideNav = ({ 
  activeStep, 
  setActiveStep,
  isMobile
 }) => {
  const steps = [
    "Concierge Tools",
    "Data Maintenance",
    "Client Onboarding",
    "Reports"
  ];

  return (
    <StyledStepper
      isMobile={isMobile}
      activeStep={activeStep}
      orientation="vertical"
      connector={<StyledStepConnector />}
    >
      {steps?.map((label, index) => (
        <Step key={label}>
          <StyledStepLabel
            StepIconComponent={ColorlibStepIcon}
            onClick={() => {
              setActiveStep(index);
            }}
          >
            {label}
          </StyledStepLabel>
        </Step>
      ))}
    </StyledStepper>
  );
}