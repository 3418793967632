import React from 'react';


const SideBarOpenArrow = () => {
  return (
    <svg width="29" height="57" viewBox="0 0 29 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 56C15.1878 56 28.5 43.6878 28.5 28.5C28.5 13.3122 15.1878 1 0 1V56Z" fill="white"/>
    <path d="M0 56C15.1878 56 28.5 43.6878 28.5 28.5C28.5 13.3122 15.1878 1 0 1" stroke="#FF6D1D"/>
    <path d="M14.8496 28L8.92426 22.3546C8.35889 21.816 8.35889 20.9426 8.92426 20.404C9.48963 19.8653 10.4063 19.8653 10.9716 20.404L17.9207 27.0247C18.4861 27.5633 18.4861 28.4367 17.9207 28.9753L10.9716 35.596C10.4063 36.1347 9.48963 36.1347 8.92426 35.596C8.35889 35.0574 8.35889 34.184 8.92426 33.6454L14.8496 28Z" fill="#FF6D1D"/>
    </svg>
  )
};

export default SideBarOpenArrow;
