import React from "react";
import { FunctionalButton } from 'components/functionalButton';
import { Popup } from 'components/popup';
import { CloseButton } from "components/closeButton";
import PylonIcon from "assets/common/pylonIcon.png";

import {
  TitleDiv,
  TitleText,
  PopupContainer
} from "pages/referenceModule/rightComponents/styles.js";


export const AlertMessage = ({
  openPopup,
  setOpenPopup,
  isMobile
}) => {


  const handleDone = () => {
    setOpenPopup(false);
  };

  const icon = <img src={PylonIcon} />;

  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width={isMobile ? "100%" : "640px"}
      height={isMobile ? "100vh" : "434px"}
      dialogContent={(
        <>
          <CloseButton handleClose={handleDone} />
          <PopupContainer>
            <TitleDiv>
              {icon}
            </TitleDiv>

            <TitleText fontSize="18px" fontWeight="600" color="black" margin="30px 20px 16px">Total Assets and Total Libilities & Networth are not equal.</TitleText>
            <FunctionalButton
              buttonTitle="GO BACK"
              handleButton={handleDone}
              isReversedColor={true}
              height="48px"
              width="150px"
              marginTop="40px"
              marginRight="10px"
              marginLeft="200px"
            />
          </PopupContainer>

        </>
      )}
    />

  )
}
