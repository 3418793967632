import React from 'react';
import references from './referencesIcon.svg'
import styled from "styled-components";

const ReferencesIcon = ({width, height, isMobile}) => {
  return (
    <StyledImg width={width || "106"} height={height || "112"} src={references} isMobile={isMobile} />
  )
};

export default ReferencesIcon;

const StyledImg = styled.img`
  ${props => props.isMobile ? "margin-bottom: 4px; margin-top: -8px;" : ""}
`;