import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import worker from 'pdfjs-dist/build/pdf.worker.entry';

import { PageScroll } from './pageScroll';
import { StyledPdfViewerPaper } from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = worker;


export const PdfViewer = ({ pdfFile }) => {
    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [isPdf, setIsPdf] = useState(true);
    const [imageSrc, setImageSrc] = useState("");


    useEffect(() => {
        if (pdfFile && typeof pdfFile === 'string') {
            const extension = pdfFile.split('.').pop().toLowerCase();
            if (extension === 'pdf') {
                setIsPdf(true);
                setImageSrc("");
            } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
                setIsPdf(false);
                setImageSrc(pdfFile);
            }
        }
    }, [pdfFile]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setPageNumber(1);
        setNumPages(numPages);
    };

    const nextPage = () => {
        if ((pageNumber + 1) <= numPages) {
            setPageNumber((prev) => prev + 1);
        }
    };

    const prevPage = () => {
        if ((pageNumber - 1) > 0) {
            setPageNumber((prev) => prev - 1);
        }
    };

    return (
        <div>

            {isPdf ? (
                <>
                    <PageScroll
                        pageNumber={pageNumber}
                        numPages={numPages}
                        nextPage={nextPage}
                        prevPage={prevPage}
                        setPageNumber={setPageNumber}
                    />
                    <StyledPdfViewerPaper elevation={0} >
                        <Document
                            file={pdfFile}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={err => console.log(err)}
                        >
                            <Page pageNumber={pageNumber} />
                        </Document>
                    </StyledPdfViewerPaper>
                </>
            ) : (
                <img src={imageSrc} alt="Selected image file" />
            )}
        </div>
    );
};
