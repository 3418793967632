import React, { useEffect, useState } from "react";
import { StyledTableCell } from '../../../components/filesListTable/styles';
import ConciseTable from '../../../components/filesListTable/conciseTable';
import { FunctionalButton } from '../../../components/functionalButton/index';
import { AddPackagePopup } from "../../../components/addPackagePopup";
import { getPackages } from "../../../api/external_data";
import { StyledProjectSelectionContainer, HeaderArea } from '../styles';
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";


export default function DataMaintanancePackage(editFunc,
  deleteFunc,
  isMobile) {

  const headers = [
    { title: 'Id' , width: "7%"},
    { title: 'Plan Name', width: "25%"},
    { title: '# of Users', width: "25%" },
    { title: '# of References', width: "25%" },
    { title: '# of Projects', width: "25%" },
    { title: '# of Personnel', width: "25%" },
    { title: 'File Limit Count', width: "25%" },
    { title: 'File Limit Size', width: "25%" },
    { title: '# of CQS-CCDC(Monthly)', width: "25%" },
    { title: '# of CQS-PledgX(Monthly)', width: "30%" },
    { title: '# of CQS-CCDC(Yearly)', width: "30%" },
    { title: '# of CQS-PledgX(Yearly)', width: "30%" },
  ];

  const [openPopup, setOpenPopup] = useState(false);
  const [packages, setPackages] = useState([]);
  const [internalSearchResults, setInternalSearchResults] = useState(packages);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getPackages()
      .then((res) => {
        setPackages(res);
      })
      .catch(() => {
        console.log("TODO: HANDLE ERROR PROPERLY");
      });
  }, []);

  const handleAdd = () => {
    setOpenPopup(true);
  };

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCell component={'tr'} width="15%">
          {bodyData.id}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="25%">
          {bodyData.package_name}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="25%">
          {bodyData.number_of_users}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="25%">
          {bodyData.number_of_references}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="25%">
          {bodyData.number_of_projects}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="25%">
          {bodyData.number_of_personnel}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="25%">
          {bodyData.file_limit_count}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="25%">
          {bodyData.file_limit_size}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="25%">
          {bodyData.number_of_cqs_ccdc_monthly}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="30%">
          {bodyData.number_of_cqs_pledgx_monthly}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="30%">
          {bodyData.number_of_cqs_ccdc_yearly}
        </StyledTableCell>
        <StyledTableCell component={'tr'} width="30%">
          {bodyData.number_of_cqs_pledgx_yearly}
        </StyledTableCell>
      </>
    );
  };


  return (
    <StyledProjectSelectionContainer isMobile={isMobile}>

      <HeaderArea width="100%">
        <SearchBar
          filteredData={packages}
          setInternalSearchResults={setInternalSearchResults}
          query={query}
          setQuery={setQuery}
          internalSearch={true}
        />
        <FunctionalButton
          buttonTitle="Add Plan"
          handleButton={handleAdd}
          width="60%"
        />
      </HeaderArea>
  
      <ConciseTable
        headers={isMobile ? "" : headers}
        length={isMobile ? 8 : 8.5}
        body={body}
        data={
          query && query !== "" ? internalSearchResults : packages
        }
      />

      <AddPackagePopup
        setOpenDialog={setOpenPopup}
        openDialog={openPopup}
      />

    </StyledProjectSelectionContainer>
  )
}


