import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { COLORS, TYPOGRAPHY } from '../../constants';
import IconButton from '@material-ui/core/IconButton';


export const StyledButtonText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 13px;
    font-weight: 550;
    color: ${COLORS.primaryOrange};
    text-transform: uppercase;
  }
`;

export const StyledIconButton = styled(IconButton)`
  &&{
    width: fit-content;
    color: white;
    padding: 0px;
    border-radius: 0%;
    cursor: pointer;
    margin:  ${props => props.margin ? props.margin : '0px'};

    &:hover{
      background-color: white;
    }
  }
`;
