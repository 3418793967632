import React from 'react';


const SideBarCloseArrow = () => {
  return (
    <svg width="30" height="57" viewBox="0 0 30 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.5 56C14.3122 56 1 43.6878 1 28.5C1 13.3122 14.3122 1 29.5 1V56Z" fill="white"/>
    <path d="M29.5 56C14.3122 56 1 43.6878 1 28.5C1 13.3122 14.3122 1 29.5 1" stroke="#FF6D1D"/>
    <path d="M14.6504 28L20.5757 22.3546C21.1411 21.816 21.1411 20.9426 20.5757 20.404C20.0104 19.8653 19.0937 19.8653 18.5284 20.404L11.5793 27.0247C11.0139 27.5633 11.0139 28.4367 11.5793 28.9753L18.5284 35.596C19.0937 36.1347 20.0104 36.1347 20.5757 35.596C21.1411 35.0574 21.1411 34.184 20.5757 33.6454L14.6504 28Z" fill="#FF6D1D"/>
    </svg>    
  )
};

export default SideBarCloseArrow;
