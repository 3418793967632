import axios from 'axios';
import { API_HOST } from '../constants';

let headers = {};

const getAuth = () => {
  const authData = localStorage.getItem("Authorization");
  if (authData) {
    headers = { Authorization: authData };}
};

export const savePersonnel = async (data, onSuccess, onFailure) => {
  getAuth();
  headers['Content-Type']= 'multipart/form-data';
  return await axios.post(`${API_HOST}/dashboard/savepersonnel`, data, {headers})
                          .then((res) => {
                            onSuccess(res.data);
                            headers['Content-Type']="application/json";
                          })
                          .catch((err) =>  {
                            if (err.response.status === 401) {
                              localStorage.removeItem("Authorization");
                              dispatchEvent(new Event("storage"));
                            }
                            onFailure(err.response)
                          });
}

export const saveProject = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios.post(`${API_HOST}/dashboard/saveproject`, data, {headers})
                          .then((res) => onSuccess(res.data))
                          .catch((err) =>  {
                            if (err.response.status === 401) {
                              localStorage.removeItem("Authorization");
                              dispatchEvent(new Event("storage"));
                            }
                            onFailure(err.response)
                          });
}

export const reportIssue = async (data, onSuccess, onFailure) => {
  getAuth();
  return await axios.post(`${API_HOST}/issue/report`, data, {headers})
                    .then((res) => onSuccess(res.data))
                    .catch((err) => {
                      if (err.response.status === 401) {
                        localStorage.removeItem("Authorization");
                        dispatchEvent(new Event("storage"));
                      }
                      onFailure(err.response)
                    });
}

export const checkPersonnelLimit = async (onSuccess, onFailure) => {
  getAuth();
  return await axios.get(`${API_HOST}/dashboard/check/personnel`, {headers})
                      .then((res) => onSuccess(res.data))
                      .catch((err) =>{
                        if (err.response.status === 401) {
                          localStorage.removeItem("Authorization");
                          dispatchEvent(new Event("storage"));
                        }
                        onFailure(err.response);
                      })
};

export const checkProjectsLimit = async (onSuccess, onFailure) => {
  getAuth();
  return await axios.get(`${API_HOST}/dashboard/check/projects`, {headers})
                      .then((res) => onSuccess(res.data))
                      .catch((err) => {
                        if (err.response.status === 401) {
                          localStorage.removeItem("Authorization");
                          dispatchEvent(new Event("storage"));
                        }
                        onFailure(err.response);
                      });
};