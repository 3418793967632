import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  background: #f5f5f5;
  min-height: calc(100vh - 172px);

  padding: 20px 35px;
`;

export const Title = styled.div`
  flex-grow: 2;

  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 41px;

  color: #1a051d;
`;

export const ContentControls = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: row;
`;

export const SelectLabel = styled.div`
  margin-right: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 41px;

  color: #1a051d;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
