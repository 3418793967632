import { Tooltip } from "@material-ui/core";
import { COLORS } from "../../constants";
import React from "react";

import {
  StyledStepLabel,
  StyledStep,
  StyledStepConnector,
  StyledStepper,
 } from './styles';


export const StepperProgress = ({
  activeStep,
  setActiveStep,
  navigationSequence,
  stepLabels,
  connectorWidth,
  noConnectorColor,
  handleClickIcon,
  isMobile,
}) =>  {

  const ColorlibStepIcon = (props) => {
    const { active, completed } = props;
    let icons={};

    for(let step in navigationSequence) {
      let Component = navigationSequence[step].colouredIcon;

      if(!navigationSequence[step].visited) {
        Component = navigationSequence[step].unColouredIcon;
      }

      if(Component) {
        let pair = {[`${step}`]: <Component isMobile={isMobile} active={active} completed={completed} />}
        icons = {...icons, ...pair};
      }
    }

    return (
      <>
        {icons[String(props.icon - 1)]}
      </>
    );
  }

  const handleOnClick = (index) => {
    if (handleClickIcon){
      handleClickIcon(index);
      if(!isMobile) {setActiveStep(index);}
    } else {
      setActiveStep(index);
    }
  }

  return (
    <>
    <StyledStepper
      activeStep={activeStep}
      connector={<StyledStepConnector connectorWidth={connectorWidth} noConnectorColor={noConnectorColor} />}
    >
      {stepLabels.map((label, index) => (
        <StyledStep key={label}>
          <Tooltip
            title={navigationSequence?.[index]?.name}
            placement="bottom-end"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: 11,
                  bgcolor: `${COLORS.primaryOrange}`,
                  '& .MuiTooltip-arrow': {
                    color: `${COLORS.primaryOrange}`,
                  },
                },
              },
            }}
          >

            <StyledStepLabel
              StepIconComponent={ColorlibStepIcon}
              onClick={ () => handleOnClick(index)}
            />
          </Tooltip>
        </StyledStep>
      ))}
    </StyledStepper>
    </>
  );
}
