import styled from 'styled-components';
import { Search } from 'semantic-ui-react'

import CheckedIcon from '../../assets/buyers/checkedIcon';
import { COLORS } from '../../constants';

export const StyledSearch = styled.input`
  && {
    height: 48px;
    width: ${props => props.searchWidth || "400px"};
    font-family: Times New Roman;

    .prompt {
      border-radius: 5px;
      border-color: ${COLORS.darkGrey3}
    }

    &&:focus {
      border-radius: 5px;
      border-color: ${COLORS.darkGrey3}
    }
  }
`;

export const StyledSearchContainer = styled.div`
  display: inline-block;
  // margin-right: 48px;
  width: ${props => props.width || "100%"};
`;

export const StyledPicklistContainer = styled.div`
  margin-top: 32px;
  padding-right: ${props => props.paddingRight || "16px" };
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${props => props.width || "100%"};
`;

export const StyledSearchIcon = styled.img`
  position: absolute;
  margin-top: 12px;
  margin-left: 8px;
  height: 24px;
`;

export const StyledCancelIcon = styled.img`
  position: absolute;
  margin-top: 17px;
  margin-left: 830px;
  height: 16px;
`;

export const StyledProjectSelectionContainer = styled.div`
  margin-top: ${props => props.marginTop || "32px"};
  width: ${props => props.width || "1300px"};
  position: relative;
`;


export const StyledUserManagementSelectionContainer = styled.div`
  margin-top: ${props => props.marginTop || "32px"};
  width: ${props => props.width || "600px"};
  position: relative;
`;
