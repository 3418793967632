import React, { useEffect, useState} from 'react';
import { Container } from "../../components/common";
import { ProjectHeader } from './components/ProjectHeader';
import { SubmissionTable } from "./components/submissionTable"
import { AdditionalBox } from './components/additionalBox';
import { useParams } from 'react-router-dom';
import { GoBackButton } from './components/goBackButton';
import { useLocation } from "react-router-dom";


const AnalyzedSubmissions = () => {
  let location = useLocation();
  const [data, setData] = useState('');

  useEffect(() => {
    setData(location.state && location.state.data? location.state.data : '');
  }, []);

  const  getRandom = (arr, n) => {
    qualifiedSubmission = new Array(n);
    disqualifiedSubmission = arr.slice();
    let len = arr.length,
        taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      const x = Math.floor(Math.random() * len);
      const index = (x in taken) ? taken[x] : x;
      qualifiedSubmission[n] = arr[index];
      disqualifiedSubmission = disqualifiedSubmission.filter(item => item !== qualifiedSubmission[n])
      taken[x] = --len in taken ? taken[len] : len;
    }
  }

  let qualifiedSubmission = []
  let disqualifiedSubmission = []

  if (data) {
    const qualifiedPercentage = Math.round(0.25*(data.all_submisions.length));
    getRandom(data.all_submisions, qualifiedPercentage);
  }

  return (
    <Container>
      { data && (
        <>
          <GoBackButton />
          <ProjectHeader data={data} />
          <AdditionalBox
            submissionNumber={data.all_submisions.length}
            buttonTitle="Decision Support Queries"
            />
          <SubmissionTable headers={'Qualified'} data={qualifiedSubmission} isQualified={true} />
          <SubmissionTable headers={'Disqualified'} data={disqualifiedSubmission} isQualified={false} />
        </>
      )}
    </Container>
  );
};

export default AnalyzedSubmissions;
