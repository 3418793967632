import Additional_color from "assets/newPreQualify/Additional_color";
import Additional_grey from "assets/newPreQualify/Additional_grey";
import Category_color from "assets/buyers/Category_color";
import Category_grey from "assets/buyers/Category_grey";
import Invite_color from "assets/buyers/Invite_color";
import Invite_grey from "assets/buyers/Invite_grey";
import Location_color from "assets/buyers/Location_color";
import Location_grey from "assets/buyers/Location_grey";
import OpenProject_color from "assets/newPreQualify/OpenProject_color";
import OpenProject_grey from "assets/newPreQualify/OpenProject_grey";
import Requirements_color from "assets/buyers/Requirements_color";
import Requirements_grey from "assets/buyers/Requirements_grey";
import Review_color from "assets/newPreQualify/Review_color";
import Review_grey from "assets/newPreQualify/Review_grey";

const icons = {
  categoryPage: {
    color: <Category_color />,
    grey: <Category_grey />
  },
  detailsPage: {
    color: <OpenProject_color />,
    grey: <OpenProject_grey />
  },
  documentsPage: {
    color: <Additional_color />,
    grey: <Additional_grey />
  },
  invitePage: {
    color: <Invite_color />,
    grey: <Invite_grey />
  },
  locationPage: {
    color: <Location_color />,
    grey: <Location_grey />
  },
  requirementsPage: {
    color: <Requirements_color />,
    grey: <Requirements_grey />
  },
  reviewPage: {
    color: <Review_color />,
    grey: <Review_grey />
  }
}

export default icons;