import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 10,
        justifyContent: 'flex-start',
        width: '50%',
        flex: 1,
        paddingLeft: 10,
        paddingRight: 30,
    },
    projectDetails: {
        marginTop: 10,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 14,
        color: '#B9B9B9',
        fontWeight: 600,
    },
    spanText: {
        color: '#B9B9B9',
    },
});

const PageProjectDetails = ({ csrData }) => (
    <View style={styles.headerContainer}>
        <Text style={styles.projectDetails}>Project Details</Text>
        <Text>
            <span style={styles.spanText}>Project Title: </span>
            {csrData?.data?.project_title}
        </Text>
        <Text><span style={styles.spanText}>Project Status: </span>
            {csrData?.data?.project_status}
        </Text>
        <Text>
            <span style={styles.spanText}>Original Contract Price: </span>
            ${csrData?.data?.project_actual_value}
        </Text>
    </View>
);

export default PageProjectDetails;
