import { getOnboarding } from "api/onboarding";
import VerticalLine from "../../components/verticalLine";
import { contactSelector } from "data/selectors/contact";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import { ClientSection } from "./clientSection";
import { FunctionSection } from "./functionSection";
import { SideSection } from "./sideSection";

import {
  ScreenRoot,
  FormContainer,
  LargeText,
  StyledSpan,
  SideContainer,
} from "./styles";

function UnderwriterDashboard() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [userData, setUserData] = useState("");
  const contactData = useSelector(contactSelector);
  let history = useHistory();

  useEffect(() => {
    getOnboarding(
      (data) => {
        if (data.status !== "active") {
          history.push("/onboarding");
        } else {
          setUserData(data);
        }
      },
      () => {}
    );
  }, []);

  return (
    <ScreenRoot isMobile={isMobile}>
      <FormContainer isMobile={isMobile}>
        <LargeText isMobile={isMobile}>
          Welcome Back,
          <StyledSpan>
            {contactData?.first_name
              ? contactData?.first_name
              : contactData?.last_name}
          </StyledSpan>
          !
        </LargeText>
        <ClientSection isMobile={isMobile} userData={userData} />
        <FunctionSection isMobile={isMobile} userData={userData} />
      </FormContainer>
      {!isMobile && (
        <>
          <VerticalLine />
          <SideContainer isMobile={isMobile}>
            <SideSection isMobile={isMobile} />
          </SideContainer>
        </>
      )}
    </ScreenRoot>
  );
}

export default UnderwriterDashboard;
