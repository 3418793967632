import React, { useEffect, useState } from "react";

import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";

import { GoBackButton } from "pages/buyers/components/goBackButton";
import { StyledContainer } from "./styles";
import { AllClientHeader } from "./components/AllClientHeader";

import { AllAlertsTable } from "./components/allAlertsTable";

const ClientAlertsPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });

  const location = useLocation();
  const alertsData = location.state?.alertsData || "";

  const [data, setData] = useState({});
  const [combinedData, setCombinedData] = useState({});

  useEffect(() => {
    if (alertsData) {
      setData(alertsData);

      const newData = [
        ...alertsData.insurance.map((item) => ({
          ...item,
          institutionId: alertsData.institutionId,
          institutionName: alertsData.institutionName,
        })),
        ...alertsData.projects.map((item) => ({
          ...item,
          institutionId: alertsData.institutionId,
          institutionName: alertsData.institutionName,
        })),
        !alertsData?.wip_updated
          ? {
              wip_updated: alertsData.wip_updated,
              type: "wip",
              institutionId: alertsData.institutionId,
              institutionName: alertsData.institutionName,
            }
          : {},
      ];

      setCombinedData(newData);
    }
  }, [alertsData]);

  //   useEffect(() => {
  //     if (allContractorCoData?.length > 0) {
  //       let separatedCOs = [];

  //       allContractorCoData.forEach((institution) => {
  //         const { institutionId, institutionName, projects } = institution;

  //         projects.forEach((project) => {
  //           const { projectId, projectName, changeOrder } = project;

  //           changeOrder.forEach((co) => {
  //             separatedCOs.push({
  //               ...co,
  //               projectId,
  //               projectName,
  //               institutionId,
  //               institutionName,
  //             });
  //           });
  //         });
  //       });
  //       setData(separatedCOs);
  //     }
  //   }, [allContractorCoData]);

  return (
    <StyledContainer isMobile={isMobile}>
      <GoBackButton />
      <AllClientHeader
        title={data?.institutionName || " "}
        hasButton={false}
        subTitle={data?.institutionId || " "}
        marginBottom="0"
        marginTop="0"
        // handleButton={handleStartNew}
        //setInternalSearchResults={setInternalSearchResults}
        // query={query}
        //setQuery={setQuery}
        data={data}
        alerts={true}
      />
      {combinedData && combinedData.length > 0 && (
        <AllAlertsTable data={combinedData} />
      )}
      {combinedData.length <= 0 && (
        <div
          style={{
            height: "50%",
            display: "flex",
            fontSize: "18px",
            // textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>No Alerts Found</p>
        </div>
      )}
    </StyledContainer>
  );
};

export default ClientAlertsPage;
