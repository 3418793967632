import styled from "styled-components";
import { TYPOGRAPHY, COLORS } from "../../constants";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Container } from "../../components/common";

export const StyledContainer = styled(Container)`
  && {
    max-width: 1440px;
    min-width: 1280px;
    height: 100vh;
    background-color: ${COLORS.backgroundGrey};
  }
`;

export const Root = styled.div`
  display: flex;
  flex-direction: row;

  background: #f5f5f5;
  min-height: 100vh;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 256px;
  width: 25%;

  background: rgba(242, 105, 52, 0.1);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  // width: 1400px;
  margin-top: 80px;
`;

export const Logo = styled.img`
  align-self: center;
  margin-top: 50px;
  width: 135px;
  height: 35px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => props.marginTop || "0"};
  margin-bottom: ${(props) => props.marginBottom || "0"};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

// Steps components

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 200px);
  margin-top: 0px;
`;

export const FormTitle = styled.div`
  font-family: ${TYPOGRAPHY.primaryFont};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  // margin-top: 24px;
  color: ${COLORS.lightGrey2};
`;

export const StyledLargeTitle = styled(FormTitle)`
  && {
    font-size: 32px;
    font-weight: 600;
    color: black;
    margin-bottom: 34px;
  }
`;

export const StyledMediumTitle = styled(FormTitle)`
  && {
    font-size: 24px;
    font-weight: 500;
    color: ${COLORS.darkGrey2};
    margin-top: 40px;
    margin-bottom: ${(props) => props.marginBottom || "0px"};
  }
`;

export const StyledOrangeText = styled(FormTitle)`
  && {
    font-size: 13px;
    font-weight: 600;
    color: ${COLORS.primaryOrange};
    margin-top: 24px;
    display: block;
    cursor: pointer;
    text-transform: uppercase;
  }
`;

export const FormSubTitle = styled.div`
  margin-top: 11px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;

  color: #000000;
`;

export const PictureButton = styled.div`
  width: 240px;
  height: 240px;

  background: #ffffff;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.08);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const PictureButtonLabel = styled.div`
  margin-top: 30px;

  width: 65%;

  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: center;

  color: #000000;
`;

export const PictureButtonImage = styled.img`
  width: 118px;
  height: 118px;
  border-radius: 100%;
`;

export const PerconnelCardContainer = styled.div`
  margin-top: 20px;

  display: flex;
  flex-wrap: wrap;
`;

export const ProjectCardContainer = styled.div`
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
`;

export const ProjectsInputBlock = styled.div`
  margin-top: 40px;

  padding: 40px;

  border: 1px dashed #888888;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledPdfViewerContainer = styled.div`
  margin-top: 24px;
  width: 75%;
`;

export const StyledConciseContainer = styled.div`
  && {
    margin: 16px 0;
    width: 75%;
  }
`;

export const StyledOrText = styled(FormTitle)`
  && {
    font-size: 18px;
    font-family: ${TYPOGRAPHY.primaryFont};
    // margin-bottom: 16px;
    color: black;
  }
`;

export const NextButton = withStyles((theme) => ({
  root: {
    height: "40px",
    width: "40px",
    backgroundColor: "#FF6D1D",
    border: "1.5px solid #FF6D1D",
    boxSizing: "border-box",
    borderRadius: "10px",
    color: "white",

    fontSize: "17px",
    fontWeight: 600,
    transition: "all 0.5s ease",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "white",
      color: "#FF6D1D",
    },
  },
  disabled: {
    backgroundColor: "#FFF3EC",
    color: "#FFDECC !important",
    border: "1.5px solid #FFF3EC",
  },
}))(Button);

export const CloseButton = withStyles({
  root: {
    position: "absolute",
    right: 10,
    top: 10,
  },
})(IconButton);
export const StyledInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.isMobile ? "center" : "")};

  .inputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    min-width: ${(props) => (props.isMobile ? "100%" : "285px")};
    width: 50%;
  }

  .fullWidthInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: 100%;
  }

  .oneThirdInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    min-width: ${(props) => (props.isMobile ? "100%" : "285px")};
    width: 33.33%;
  }

  .twoThirdInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: 66.67%;
  }

  .oneFifthInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    min-width: ${(props) => (props.isMobile ? "100%" : "200px")};
    width: 20%;
  }

  .oneFifthInputWrapNarrow {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    min-width: ${(props) => (props.isMobile ? "100%" : "200px")};
    width: 18%;
  }

  .narrowInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    min-width: ${(props) => (props.isMobile ? "100%" : "50px")};
    width: 8%;
  }

  .oneForthInputWrap {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    min-width: ${(props) => (props.isMobile ? "100%" : "285px")};
    width: 25%;
  }
`;

export const StickyHeader = styled.div`
  height: 80px;
  max-width: 1440px;
  min-width: 1280px;
  position: fixed;
  position: -webkit-sticky;
  -webkit-position: sticky;
  z-index: 1;
  background: ${COLORS.backgroundGrey};
  text-align: center;
  -webkit-backface-visibility: hidden;

  &:before,
  &:after {
    content: "";
    display: block;
    height: 16px;
    position: sticky;
    -webkit-position: sticky;
    position: -webkit-sticky;
    box-sizing: border-box;
  }

  &:before {
    top: 128px;
    // box-shadow: 0px 10px 6px -6px black;
  }

  &:after {
    // background: linear-gradient(${COLORS.backgroundGrey} 10%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0.4) 70%, transparent);
    top: 0;
    z-index: 2;
  }
`;

export const ShadowCover = styled.div`
  position: sticky;
  top: 0px;
  height: 16px;
  background-color: white;
`;
