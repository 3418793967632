import React from 'react';

const Years = ({ active, completed }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1545:3164)">
        <g filter="url(#filter0_d_1545:3164)">
          <circle cx="14" cy="14" r="14" fill="white"/>
          <circle cx="14" cy="14" r="13.5" fill={active || completed ? "#FF6D1D" : "white"} stroke="#ECEBED"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9375 9.375H18.3125C18.692 9.375 19 9.683 19 10.0625V18.3125C19 18.692 18.692 19 18.3125 19H8.6875C8.308 19 8 18.692 8 18.3125V10.0625C8 9.683 8.308 9.375 8.6875 9.375H10.0625V8H12.125V9.375H14.875V8H16.9375V9.375ZM9.375 17.625H17.625V11.4375H9.375V17.625ZM10.75 12.8125H12.125V14.1875H10.75V12.8125ZM14.1875 12.8125H12.8125V14.1875H14.1875V12.8125Z" fill={active || completed ? "white" : "#D0C9D6"}/>
      </g>
      <defs>
        <filter id="filter0_d_1545:3164" x="-64" y="-57" width="156" height="156" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="7"/>
          <feGaussianBlur stdDeviation="32"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1545:3164"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1545:3164" result="shape"/>
        </filter>
        <clipPath id="clip0_1545:3164">
          <rect width="28" height="28" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default Years;
