
import React from "react";


const NegativeAttributes = ({ data }) => {

  const getNegativeAttributeValue = (id) => {
    const values = {
      1: "This company has been in business for less than 10 years",
      2: "They have been frequently seeking change orders on their projects",
      3: "Their operations are not profitable",
      4: "This company has liquidity problems, and it appears to be worsening",
      5: "Their liabilities exceed their assets",
      6: "This company does not provide their financial statements on time (later than 90 days)",
      7: "This company has not been reporting regularly on the progress of their projects",
      8: "This company works on many smaller projects, some of which are non-profitable",
      9: "They are a general contractor, operating in a high competition area"
    };
    return values[id] || '';
  };

  const getNegativeValues = () => {
    const dataString = data?.NegativeAttributes;
    if (dataString !== undefined && dataString !== null) {
      try {
        const dataArray = JSON.parse(dataString);
        if (Array.isArray(dataArray)) {
          return dataArray.map((id) => {
            return { key: id, value: getNegativeAttributeValue(id) };
          });
        } else {
          return [];
        }
      } catch (error) {
        return [];
      }
    } else {
      return [];
    }
  };

  const negativeValues = getNegativeValues();

  return (
    <div style={{ backgroundColor: '#FFFFFF', margin: '0px' }}>
      {data && negativeValues.length > 0 && (
        <React.Fragment>
          <ol style={{ fontSize: '15px' }}>
            {negativeValues.map((item) => (
              <li key={item.key} style={{ marginBottom: '10px' }}>{item.value}.</li>
            ))}
          </ol>
        </React.Fragment>
      )}
    </div>
  );
};

export default NegativeAttributes;
