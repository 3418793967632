import React from "react";

import IconButton from "@material-ui/core/IconButton";
import BookmarksOutlinedIcon from "@material-ui/icons/BookmarksOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import BookmarksIcon from "@material-ui/icons/Bookmarks";

import {
  TenderBody,
  TenderRow,
  TenderColumn,
  TenderLogo,
  TenderName,
  TenderCompany,
  TenderDescText,
  TenderDate,
  TenderBusinessCategory,
  NameTag,
} from "./styles";

import { CategoryColor } from "../../data/consts/index";

function Tender({ tender, closed, won, onSave, onDelete, style }) {
  return (
    <TenderBody style={style}>
      <TenderRow style={{ alignItems: "center" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <TenderLogo></TenderLogo>
        </div>
        <TenderColumn style={{ flexGrow: 1 }}>
          <TenderName>
            <span>{tender.bid_name}</span>
            {closed && <NameTag red>Closed</NameTag>}
            {won && <NameTag green>Winning Bid</NameTag>}
          </TenderName>
          <TenderCompany>{tender.organization}</TenderCompany>
        </TenderColumn>
        <TenderColumn style={{ marginRight: 35 }}>
          <TenderRow>
            <TenderDescText style={{ marginRight: 10 }}>
              Location:{" "}
            </TenderDescText>
            <TenderDescText>{tender.location}</TenderDescText>
          </TenderRow>
          <TenderRow>
            <TenderDescText style={{ marginRight: 10 }}>
              Closing date:{" "}
            </TenderDescText>
            <TenderDescText>{tender.closing_date}</TenderDescText>
          </TenderRow>
          {/* <TenderRow>
            <TenderDescText style={{ marginRight: 10 }}>
              Budget:{" "}
            </TenderDescText>
            <TenderDescText>{budget}</TenderDescText>
          </TenderRow> */}
        </TenderColumn>
        {!(closed || won) && (
          <>
            {!tender.saved ? (
              <IconButton style={{ maxWidth: 50 }} onClick={onSave}>
                <BookmarksOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton style={{ maxWidth: 50 }} onClick={onDelete}>
                <BookmarksIcon />
              </IconButton>
            )}
          </>
        )}
        <IconButton
          style={{ maxWidth: 50, marginRight: 25 }}
          onClick={(e) => {
            if (closed || won) e.stopPropagation();
          }}
          onFocus={(e) => {
            if (closed || won) e.stopPropagation();
          }}
        >
          <MoreVertOutlinedIcon />
        </IconButton>
      </TenderRow>

      <TenderRow style={{ marginTop: 10 }}>
        <TenderDate>{tender.publish_date}</TenderDate>
        <TenderRow style={{ flexGrow: 1 }}>
          {typeof tender.frontend_categories === "string" && (
            <TenderBusinessCategory
              color={CategoryColor[tender.frontend_categories]}
            >
              {tender.frontend_categories}
            </TenderBusinessCategory>
          )}
          {typeof tender.frontend_categories === "object" &&
            tender.frontend_categories.map((category) => (
              <TenderBusinessCategory color={CategoryColor[category]}>
                {category}
              </TenderBusinessCategory>
            ))}
        </TenderRow>
      </TenderRow>
    </TenderBody>
  );
}

export default Tender;
