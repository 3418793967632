import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { DetailedFormContainer } from "pages/contractorDashboard/styles.js";
import { LargeText, SetupContainer } from "../styles.js";
import { ClientList } from "./ClientList.js";
import { DetailArea } from "pages/contractorDashboard/detailScreens/styles.js";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";
import { getUsers } from "../../../api/external_data";
import { getSingleClient, disableSingleClient, enableSingleClient, saveSingleClient } from "../../../api/onboarding_client";
import { AddClientPopup } from "../../../components/addClientPopup";


export default function UserOnboardingEdit({ noWidthChange }) {
  const [query, setQuery] = useState("");
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [filteredData, setFilteredData] = useState([]);
  const [internalSearchResults, setInternalSearchResults] = useState(filteredData);
  const [openPopup, setOpenPopup] = useState(false);
  const [client, setClient] = useState("");
  const [initialSubCategory, setInitialSubCategory] = useState("");
  let subCategoryInfo = [];



  const handleFilter = async () => {
    await getUsers()
      .then((data) => {
        setFilteredData(data);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    handleFilter(query);
  }, []);

  const addClient = (data) => {
    saveSingleClient(data, onSuccess, () => { });
  }

  const onSuccess = () => {
    setClient("");
    handleFilter("");
  }

  //three dots button


  const editClient = (id) => {
    getSingleClient(id, onSuccessEdit, () => { });
  };

  const onSuccessEdit = (res) => {
    setClient(res);
    setOpenPopup(true);
    const categoriesData = res?.categories;

    const allSubCategories = [];
    let categoryList = [];

    categoriesData.forEach((category) => {
      subCategoryInfo = category.sub_categories.map((option, index) => ({
        key: index + 1,
        text: option,
        value: option,
      }));
      categoryList = {
        "main_categories": category?.main_categories,
        "sub_categories": subCategoryInfo
      }
      allSubCategories.push(categoryList);
    });

    setInitialSubCategory(allSubCategories);

  };


  const disableClient = (id) => {
    disableSingleClient(id, onSuccessDisable, () => { });
  };
  const onSuccessDisable = (res) => {
    setQuery("");
    handleFilter("");
  };


  const enableClient = (id) => {
    enableSingleClient(id, onSuccessEnable, () => { });
  };
  const onSuccessEnable = (res) => {
    setQuery("");
    handleFilter("");
  };

  return (
    <SetupContainer isMobile={isMobile}>
      <DetailedFormContainer isMobile={isMobile} noWidthChange={noWidthChange}>
        <LargeText isMobile={isMobile}>
          {isMobile
            ? "All Client"
            : "All Client's List"}
        </LargeText>

        <SearchBar
          filteredData={filteredData}
          setInternalSearchResults={setInternalSearchResults}
          query={query}
          setQuery={setQuery}
          searchWidth="100%"
          internalSearch={true}
        />

        <DetailArea>
          <ClientList
            filteredData={
              query && query !== "" ? internalSearchResults : filteredData
            }
            editFunc={editClient}
            disableFunc={disableClient}
            enableFunc={enableClient}
            isMobile={isMobile}
          />
        </DetailArea>

        <AddClientPopup
          setOpenPopup={setOpenPopup}
          openPopup={openPopup}
          data={client}
          setData={setClient}
          initialSubCategory={initialSubCategory}
          handleButtonFunc={addClient}
          isMobile={isMobile}
        />

      </DetailedFormContainer>
    </SetupContainer>
  );
}

