import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PlusSign from '../../assets/onBoarding/plusSign.js';
import { CategoriesRow } from "./CategoriesRow.js";
import {
  standardCategoriesListSelector,
  unspcFamilyListSelector,
} from "data/selectors/user";

import {
  getStandardCategoriesInfo,
  getUNSPCClassInfo,
} from "data/actions/user";

import {
  StyledCategoriesArea,
  SmallAddText,
  PlusSignArea,
  StyledAddText
} from './styles.js';

export const CommonBusinessType = ({
  data,
  setData,
  attribute,
  usePlusIcon,
  marginTopSecondary,
  isMobile,
  width,
  notPopup,
  readOnly,
  containsUNSPC
}) => {
  const allSubCategories = [];
  const standardCategoriesList = useSelector(
    standardCategoriesListSelector
  );

  const unspcFamilyList = useSelector(
    unspcFamilyListSelector
  );

  const dispatch = useDispatch();
  
  useEffect(() => {
    if (standardCategoriesList?.length < 1) {
      dispatch(getStandardCategoriesInfo());
    }    
  }, []);

  useEffect(() => {
    if (unspcFamilyList?.length < 1) {
      dispatch(getUNSPCClassInfo());
    }
  }, []);

  if (Array.isArray(data?.categories)) {
    data?.categories.forEach(category => {
      const subCategoryInfo = (category.sub_categories || []).map((option, index) => ({
        key: index + 1,
        text: option,
        value: option
      }));

      const categoryList = {
        main_categories: category.main_categories || [],
        sub_categories: subCategoryInfo
      };

      allSubCategories.push(categoryList);
    });

  }

  const [enableAdd, setEnableAdd] = useState(true);
  const [initialSubCategory, setInitialSubCategory] = useState(allSubCategories);

  useEffect(() => {
    if (allSubCategories?.length > 0) {
      setInitialSubCategory(allSubCategories);
    }
  }, [data]);

  const addInitialList = (data) => {
    const tmp = [];
    if (data?.categories?.length > 0 && initialSubCategory?.length > 0 && standardCategoriesList.length > 0 && unspcFamilyList.length > 0) {
      const list = data?.categories;
      const listOfMain = list.map((item) => item.main_categories);
      list.map((item) => {
        tmp.push(
          <CategoriesRow
            attribute={attribute}
            setEnableAdd={setEnableAdd}
            data={data}
            setData={setData}
            categoriesUNSPC={unspcFamilyList}
            categoriesStandard={standardCategoriesList}
            initialSubCategory={initialSubCategory}
            containsUNSPC={containsUNSPC}
            marginTop="0px"
            index={item.index}
            categories={item}
            mainSelected={listOfMain}
            isMobile={isMobile}
            marginTopSecondary={marginTopSecondary}
            readOnly={readOnly}
          />)
      });
    } else if (standardCategoriesList.length > 0 && unspcFamilyList.length > 0) {
      tmp.push(<CategoriesRow
        attribute={attribute}
        setEnableAdd={setEnableAdd}
        data={data}
        setData={setData}
        categoriesUNSPC={unspcFamilyList}
        categoriesStandard={standardCategoriesList}
        initialSubCategory={initialSubCategory}
        containsUNSPC={containsUNSPC}
        index={0}
        isMobile={isMobile}
        marginTopSecondary={marginTopSecondary}
        readOnly={readOnly}
      />)
    }
    return tmp;
  }

  const [addList, setAddList] = useState([]);

  useEffect(() => {
    setAddList([]);
    addInitialList([]);
  }, [containsUNSPC]);

  useEffect(() => {
    if (notPopup) {
      setAddList([])
    }
  }, [data?.categories])

  useEffect(() => {
    if (addList?.length == 0 && notPopup) {
      setAddList(addInitialList(data))
    }
  }, [addList])

  useEffect(() => {
    if (!notPopup) {
      setAddList(addInitialList(data))
    }
  }, [data])

  const onAdd = () => {
    const index = addList.length
    const list = data?.categories;
    const listOfMain = list?.length > 0 ? list.map((item) => item.main_categories) : [];
    setAddList(addList.concat(<CategoriesRow
      data={data}
      setData={setData}
      categoriesUNSPC={unspcFamilyList}
      categoriesStandard={standardCategoriesList}
      initialSubCategory={initialSubCategory}
      containsUNSPC={containsUNSPC}
      index={index}
      marginTop="0px"
      marginTopSecondary={marginTopSecondary}
      mainSelected={listOfMain}
      isMobile={isMobile}
      attribute={attribute}
      setEnableAdd={setEnableAdd}
      readOnly={readOnly}
    />))
  }

  return (
    <StyledCategoriesArea isMobile={isMobile} width={width}>
      {addList}
      {((!readOnly) && (addList.length < 12) && (data?.categories?.length > 0)) && (
        <PlusSignArea usePlusIcon={usePlusIcon} >
          {usePlusIcon && (
            <>
              <PlusSign enableAdd={enableAdd} onAdd={onAdd} />
              <StyledAddText onClick={enableAdd ? onAdd : () => { }} enableAdd={enableAdd} >Add Category/Family</StyledAddText>
            </>
          )}
          {!usePlusIcon && (
            <SmallAddText onClick={enableAdd ? onAdd : () => { }} enableAdd={enableAdd} >+ Add Category/Family</SmallAddText>
          )}
        </PlusSignArea>
      )}

    </StyledCategoriesArea>
  );
}
