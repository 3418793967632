import React, { useEffect } from 'react';
import { StyledButton } from "./styles";

export const FunctionalButton =({
    buttonTitle,
    handleButton,
    width,
    height,
    isReversedColor,
    marginRight,
    marginLeft,
    marginTop,
    marginBottom,
    fontSize,
    disabled,
    icon,
    hasBoxShadow,
    frontIcon,
    weight,
    borderColor,
    selected,
}) => {

    return (
        <StyledButton
            onClick={ handleButton }
            width={width}
            height={height}
            isReversedColor={isReversedColor}
            marginRight={marginRight}
            marginLeft={marginLeft}
            marginTop={marginTop}
            marginBottom={marginBottom}
            fontSize={fontSize}
            disabled={disabled}
            hasBoxShadow={hasBoxShadow}
            weight={weight}
            borderColor={borderColor}
            selected={selected}
        >
            {frontIcon && (
            <>{icon}</>
            )}
            {buttonTitle}
            {!frontIcon && (
            <>{icon}</>
            )}
        </StyledButton>
    )
}

