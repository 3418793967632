import React from 'react';
import { Text, View, StyleSheet, Link } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 10,
    justifyContent: 'flex-end',
    width: '50%',
    flex: 1,
    paddingLeft: 30,
    paddingRight: 30,
  },
  submittedBy: {
    marginTop: 10,
    paddingBottom: 3,
    fontFamily: 'Helvetica',
    fontSize: 16,
    color: '#F66616',

  },
  spanText: {
    color: '#888888',
  },
});

const PageOneSubmittedBy = ({ prequalifyData }) => (
  <View style={styles.headerContainer}>
    <Text style={styles.submittedBy}>Submitted by</Text>
    <Text>
      <span style={styles.spanText}>Name: </span>
      {prequalifyData?.data?.companyName}
    </Text>
    <Text>
      <span style={styles.spanText}>Website: </span>
      <Link href={prequalifyData?.data?.companyWebsite}>
        {prequalifyData?.data?.companyWebsite}
      </Link>
    </Text>
    <Text>
      <span style={styles.spanText}>Address: </span>
      {prequalifyData?.data?.companyAddress}
    </Text>
    <Text>
      <span style={styles.spanText}>Email: </span>
      {prequalifyData?.data?.companyEmail}
    </Text>
    <Text>
      <span style={styles.spanText}>Phone: </span>
      {prequalifyData?.data?.companyContactNumber}
    </Text>
  </View>
);

export default PageOneSubmittedBy;
