import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '../../constants';
import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';


export const HeaderArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${props => props.width || "100%"};
`;


export const StyledCheckBox = styled(Checkbox)`
  && {
    &&.MuiCheckbox-root {
      color: ${(props) => props.readOnly ? COLORS.textGrey : COLORS.primaryOrange };
      margin-left:30px;
      margin-top:-15px;
    }

    &&.custom-checkbox-root .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }

    &&.MuiTableCell-root {
      padding: 0px;
    }
    &&:hover {
      ${(props) => (props.readOnly ? `background-color: transparent;` : '')}
      ${(props) => (props.readOnly ? `cursor: default;` : '')}
    }
  }
`;


const getThumbColor = (rating) => {
  if( rating > 8 && rating < 11){
    return "linear-gradient(330.26deg, #43B868 21.73%, #297942 86.36%)"
  }
  if( rating > 6 && rating < 9){
    return "linear-gradient(330.26deg, #d9e35c 21.73%, #a9f65d 86.36%)"
  }
  if( rating > 4 && rating < 7){
    return "linear-gradient(330.26deg, #FEC65C 21.73%, #FA9C52 86.36%)"
  }
  if( rating > 2 && rating < 5){
    return "linear-gradient(330.26deg, #FEC65C 21.73%, #FF6D1D 86.36%)"
  }
  if( rating > 0 && rating < 3){
    return "linear-gradient(330.26deg, #FF6D1D 21.73%, #FF0000 86.36%)"
  }
  return "linear-gradient(330.26deg, #FEC65C 21.73%, #FA9C52 86.36%)"
}

export const StyledContainer = styled.div`
  display: inline-block;
  width: 100%;
  margin: ${props => props.margin || "0px 0 68px"};
`;

// background-color: ${COLORS.scrollbarTrack};

export const StyledSlider = styled(Slider)`
  && {
    color: red;
  }

  .MuiSlider-rail {
    background: linear-gradient(90deg, #FF0000 0%, #FF6D1D 23.44%, #FEC65C 50%, #B7FE5C 77.6%, #43B868 100%);
    background-color: ${COLORS.scrollbarTrack};    
    height: 4px;
    border-radius: 2px;
    opacity: 1;
    padding-right: 12px;
  }

  .MuiSlider-track {
    color:transparent;
    height: 4px;
    border-radius: 2px;
  }

  .MuiSlider-mark {
    display: none;
  }

  .MuiSlider-thumb {
    margin-top: -10px;
    margin-left: -10px;
    width: 25px;
    height: 25px;
    background: ${props => getThumbColor(props.value)};
  }

  .MuiSlider-thumb:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-markLabel {
    top: 40px;
    font-size: 13px;
    font-family: ${TYPOGRAPHY.primaryFont};
  }
`;


export const StyledSliderDisabled = styled(Slider)`
  && {
    color: ${COLORS.lightGrey2};
  }

  .MuiSlider-rail {
    background: ${COLORS.lightGrey2};
    background-color: ${COLORS.scrollbarTrack};    
    height: 4px;
    border-radius: 2px;
    opacity: 1;
    padding-right: 12px;
  }

  .MuiSlider-track {
    color:transparent;
    height: 4px;
    border-radius: 2px;
  }

  .MuiSlider-mark {
    display: none;
  }

  .MuiSlider-thumb {
    margin-top: -10px;
    margin-left: -10px;
    width: 25px;
    height: 25px;
    background: ${COLORS.lightGrey2};
  }

  .MuiSlider-thumb:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  .MuiSlider-markLabel {
    top: 40px;
    font-size: 13px;
    font-family: ${TYPOGRAPHY.primaryFont};
  }
`;



export const StyledHeader = styled.div`
  && {
    margin-bottom: 4px;
    margin-left: -8px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    font-family: ${TYPOGRAPHY.primaryFont};
    color: ${COLORS.lightGrey2};
  }
`;

export const StyledHeader2 = styled.div`
  && {
    margin-top: -15px;
    margin-right: 20px;
    margin-left: -2px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: right;
    font-family: ${TYPOGRAPHY.primaryFont};
    color: ${COLORS.lightGrey2};
  }
`;
