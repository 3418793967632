import React, { useState } from "react";
import PledgxLogo from '../../../../assets/common/pledgxLogo';
import Step1Icon from "../../../../assets/common/addProjectIcons/step1";
import { StepperProgress } from 'components/stepperProgress';
import{ emailRE } from '../../../../common/regex';
import { FunctionalButton } from '../../../../components/functionalButton';
import CloseIcon from "@material-ui/icons/Close";
import Input from "../../../../components/inputField";
import { CloseButton } from './styles';
import updateObjectByPath from "../../../../common/updateObjectByPath";

import {
  PopupContent,
  PopupTitle,
  PopupFields,
  ButtonWrap
 } from './styles'

import { Step1 } from './Step1';

function SwitchComponents({ active, children }) {
  return children.filter((child) => child.props.step === active);
};

export const Steps = ({
  setOpenPopup,
  isMobile,
  setEquipmentInsurance,
  setAutoInsurance,
  equipmentInsurance,
  autoInsurance,
  setInsuranceType,
  handleNext,
  setPanel,
  prequalifyData,
  setPrequalifyData
 }) => {
  const [step, setStep] = useState(0);
  const iconList = [Step1Icon];
  const [disabled, setDisable] = useState(true);

  const stepLabels = [
    "Step 1",
  ];

  const [addData, setAddData] = useState({})
  const [editIndex, setEditIndex] = useState(null);

  const onChangeData = (attribute, val) => {
    const updatedData = { ...addData };
    updateObjectByPath(updatedData, attribute, val);
    setAddData({ ...updatedData });
  };

  const handleNextPick = () => {
    if (autoInsurance){
      setInsuranceType('Automobile Insurance');
      setPanel(11);
    } else if (equipmentInsurance) {
      setInsuranceType(equipmentInsurance);
      setInsuranceType('Equipment Insurance');
      setPanel(11);
    } else {
      handleNext();
    }
  };

  return(
    <>
    <PopupContent>
      <CloseButton
        aria-label="close"
        onClick={() => {setOpenPopup(false); setEditIndex(null);}}
      >
        <CloseIcon />
      </CloseButton>
      <PledgxLogo/>
      <PopupTitle>Are you using the same company for...</PopupTitle>
      <PopupFields isMobile={isMobile}>
        <Step1
          isMobile={isMobile}
          setDisable={setDisable}
          addData={addData}
          setAddData={onChangeData}
          setAutoInsurance={setAutoInsurance}
          setEquipmentInsurance={setEquipmentInsurance}
          prequalifyData={prequalifyData}
        setPrequalifyData={setPrequalifyData}
        />
      </PopupFields>
      <ButtonWrap>
      <FunctionalButton
          width="400px"
          marginTop="16px"
          marginRight="0px"
          handleButton={handleNextPick}
          buttonTitle={"NEXT"}
          disabled={false}
        />
      </ButtonWrap>
    </PopupContent>
  </>
  )
 }
