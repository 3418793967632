import React from 'react';

const HealthSafety = ({ active, completed }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1545:3193)">
        <g filter="url(#filter0_d_1545:3193)">
          <circle cx="14" cy="14" r="14" fill="white"/>
          <circle cx="14" cy="14" r="13.5"  fill={active || completed ? "#FF6D1D" : "white"} stroke="#ECEBED"/>
        </g>
        <g clip-path="url(#clip1_1545:3193)">
          <path d="M13.3765 20.9993C13.2307 20.9366 13.0837 20.8764 12.9394 20.811C10.3558 19.6435 8.76246 17.627 8.15935 14.7614C8.05185 14.2459 8.01727 13.7046 8.00901 13.1769C7.98946 11.7318 8.00186 10.2862 8.00262 8.84105C8.00262 8.51734 8.16649 8.34668 8.47882 8.34081C10.2185 8.30714 11.7075 7.65346 12.9458 6.37974C13.021 6.30419 13.0909 6.22708 13.1649 6.15232C13.3698 5.94525 13.5994 5.95152 13.8129 6.14958C14.1831 6.49326 14.5383 6.86081 14.9404 7.16104C15.8658 7.85308 16.9114 8.22729 18.0491 8.31615C18.2126 8.32906 18.3768 8.33454 18.5407 8.3412C18.7978 8.35138 18.974 8.52948 18.974 8.79682C18.974 10.3543 19.0394 11.9161 18.9575 13.4689C18.7854 16.7331 17.2579 19.1196 14.462 20.6145C14.185 20.7628 13.8888 20.8721 13.6017 20.9993H13.3765ZM9.69319 13.4713C9.69363 14.2534 9.91669 15.0178 10.3342 15.6679C10.7517 16.318 11.3448 16.8246 12.0387 17.1237C12.7326 17.4228 13.496 17.5009 14.2325 17.3482C14.969 17.1956 15.6455 16.819 16.1766 16.266C16.7076 15.713 17.0693 15.0084 17.216 14.2414C17.3626 13.4744 17.2877 12.6793 17.0006 11.9566C16.7134 11.234 16.227 10.6162 15.6029 10.1813C14.9787 9.74645 14.2447 9.51407 13.4938 9.51353C12.4859 9.51426 11.5195 9.93139 10.8066 10.6734C10.0937 11.4154 9.6926 12.4216 9.69131 13.4713H9.69319Z" fill={active || completed ? "white" : "#D0C9D6"}/>
          <path d="M10.5352 13.4623C10.5366 12.8537 10.7114 12.2592 11.0374 11.7541C11.3634 11.2489 11.8259 10.8558 12.3665 10.6245C12.907 10.3931 13.5013 10.334 14.0741 10.4544C14.647 10.5749 15.1726 10.8697 15.5846 11.3013C15.9966 11.733 16.2764 12.2823 16.3886 12.8796C16.5008 13.4769 16.4403 14.0954 16.2149 14.657C15.9895 15.2185 15.6092 15.6978 15.1221 16.0342C14.6351 16.3706 14.0632 16.549 13.4788 16.5468C12.6962 16.5427 11.9471 16.2158 11.3954 15.6377C10.8437 15.0596 10.5344 14.2774 10.5352 13.4623ZM12.9271 14.3255C12.6493 14.0319 12.3919 13.7563 12.1303 13.4847C11.9348 13.2815 11.6792 13.2709 11.5049 13.451C11.3305 13.6311 11.338 13.9 11.5304 14.1019C11.8872 14.4762 12.2451 14.8488 12.6042 15.2199C12.8064 15.4285 13.0466 15.4297 13.2495 15.2199C13.9837 14.4595 14.7161 13.6969 15.4468 12.9324C15.6411 12.7292 15.649 12.4626 15.4746 12.2806C15.3002 12.0986 15.0439 12.11 14.8495 12.3112C14.3717 12.8067 13.8948 13.3036 13.4187 13.8017C13.2593 13.9673 13.1041 14.1364 12.9271 14.3255Z" fill={active || completed ? "white" : "#D0C9D6"}/>
        </g>
      </g>
      <defs>
        <filter id="filter0_d_1545:3193" x="-64" y="-57" width="156" height="156" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="7"/>
          <feGaussianBlur stdDeviation="32"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1545:3193"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1545:3193" result="shape"/>
        </filter>
        <clipPath id="clip0_1545:3193">
          <rect width="28" height="28" fill="white"/>
        </clipPath>
        <clipPath id="clip1_1545:3193">
          <rect width="11" height="15" fill="white" transform="translate(8 6)"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default HealthSafety;
