import React from "react";
import Input from "../inputField";
import { InfoArea } from "./styles";
import { StyledRow, SectionText } from "./styles";
import { emailRE } from "../../common/regex";
import { formatPhoneNumber } from "common/formatPhoneNumber";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

export const Step3 = ({
  setDisable,
  addData,
  setAddData,
  isMobile
}) => {


  return (
    <InfoArea isMobile={isMobile} marginTop="0px" marginLeft="8px" isColumn={true} >
      <SectionText>Business Information</SectionText>
      <StyledRow isMobile={isMobile}>
      <Input
        id="inputWrap"
        label="Company Name"
        type="text"
        placeholder=""
        labelSize="12px"
        fontSize="18px"
        value={addData?.company_name || ''}
        setValue={(val) => setAddData('company_name', val)}
        validateFunc={(item) => item.length > 0}
        noCheckIcon={true}
        noErrorBorder={true}
      />
      <Input
        id="inputWrap"
        label="Company Website"
        type="text"
        placeholder=""
        labelSize="12px"
        fontSize="18px"
        value={addData?.company_website  || ''}
        setValue={(val) => setAddData('company_website', val)}
        validateFunc={(item) => item.length > 0}
        noCheckIcon={true}
        noErrorBorder={true}
      />
      </StyledRow>
      <StyledRow isMobile={isMobile}>

      <div style={{ marginTop: "24px", width: "100%", marginLeft: '0px', marginRight: '0px' }}>
          <label htmlFor="phone" style={{ fontSize: "12px" }}>
            Phone Number
          </label>
          <div style={{ height: "48px", width: "100%", marginTop: "-10px" }}>
            <PhoneInput
              id="phone"
              country={"ca"}
              value={addData?.company_phone}
              onChange={(value) => setAddData("company_phone", value)}
              style={{ marginTop: "16px", width: "100%", height: "100%" }}
              inputStyle={{
                fontSize: "18px",
                padding: "8px",
                lineHeight: "24px",
                marginLeft: "20px",
                paddingLeft: "28px",
                height: "100%",
                width: "89%",
                marginRight: "20px",
              }}
              dropdownStyle={{ fontSize: "18px", lineHeight: "24px" }}
            />
          </div>
        </div>

      <Input
        id="fullWidthInputWrap"
        label="Company Email"
        type="text"
        placeholder=""
        labelSize="12px"
        fontSize="18px"
        value={addData?.company_email || ''}
        setValue={(val) => setAddData('company_email', val)}
        validateFunc={(item) => emailRE.test(item)}
        noCheckIcon={true}
        noErrorBorder={true}
        style={{ marginLeft: "5px" }}
      />
      </StyledRow>

      <SectionText>Company Identification</SectionText>

      <StyledRow isMobile={isMobile}>
      <Input
        id="fullWidthInputWrap"
        label="Business Number"
        type="text"
        placeholder=""
        labelSize="12px"
        fontSize="18px"
        value={addData?.company_business_number || ''}
        setValue={(val) => setAddData('company_business_number', val)}
        validateFunc={(item) => item.length > 0}
        noCheckIcon={true}
        noErrorBorder={true}
      />
      </StyledRow>
    
    </InfoArea>
    
  )
}