import React, { useState, useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import Stepper from "../components/stepper/stepper";
import { BackButton } from "../../../components/backButton";
import { StyledNavBar } from './styles';
import { FunctionalButton } from '../../../components/functionalButton';
import { preQualifyUser, getCCDC, getFillableCCDC } from "../../../data/actions/user";
import { qualificationSelector } from "../../../data/selectors/user";
import { checkAuth } from "../../../data/actions/user";
import { useHistory } from "react-router-dom";
export const NavBar = ({
  downloading,
  activeStep,
  setActiveStep,
  listPanel,
  setListPanel,
  isLastPanel,
  setIsLastPanel,
  previousStepPanel,
  saveCCDC,
  setSaveCCDC,
  qualificationData,
  action,
  setAction
}) => {
  const data = useSelector(qualificationSelector);
  const dispatch = useDispatch();
  let history = useHistory();

  const getPDF = (res) => {
    const ccdcId = res.ccdcId
    dispatch(getCCDC(ccdcId
      // () => dispatch(getFillableCCDC())
      ))
  }

  const handleBackStep = () => {
    const authData = localStorage.getItem("Authorization");
    dispatch(
      checkAuth(
        () => {},
        () => {
          history.push("/account/login");
        }
      )
    );
    if(listPanel === 1) {
      if (activeStep > -1) {
        setActiveStep(activeStep - 1);
        setListPanel(previousStepPanel);
      }
    } else {
      setListPanel(listPanel - 1);
    }
    if(saveCCDC) {
      setSaveCCDC(false);
    }
  };

  const handleNextStep = () => {
    dispatch(
      checkAuth(
        () => {},
        () => {
          history.push("/account/login");
        }
      )
    );
    
    if(isLastPanel) {
      if (activeStep < 8) {
        setActiveStep(activeStep + 1);
        setListPanel(1);
        setIsLastPanel(false);

        if(saveCCDC) {
          let formData = new FormData();

          // add additional documents
          let cacheDataCopy = { ...qualificationData };

          let data = cacheDataCopy?.additional_document;
          let arrayKey = `additionalFiles`;

          if (Array.isArray(data)) {
            arrayKey = `additionalFiles`;

            data.forEach(v => {
              formData.append(arrayKey, v);
            });
          }

          // add legal structure personnel resumes
          data = cacheDataCopy?.legal_structure?.personnel;

          if (Array.isArray(data)) {
            arrayKey = `personnel`;

            data.forEach((v, i) => {
              if(v.resume) {
                if(v.id) {
                  formData.append(`${arrayKey}[${v.id}]`, v.resume);
                } else {
                  formData.append(`${arrayKey}-new-[${i}]`, v.resume);
                }
              }

              delete cacheDataCopy['legal_structure']['personnel'][i]['resume'];
            });
          }

          // add personnel resumes
          data = cacheDataCopy?.key_personnel;

          if (Array.isArray(data)) {
            arrayKey = `key_personnel`;

            data.forEach((v, i) => {
              if(v.resume) {
                if(v.id) {
                  formData.append(`${arrayKey}[${v.id}]`, v.resume);
                } else {
                  formData.append(`${arrayKey}-new-[${i}]`, v.resume);
                }
              }

              delete cacheDataCopy['key_personnel'][i]['resume'];
            });
          }

          // add CORS file
          if(cacheDataCopy?.years?.CORfileOrEquivalent) {
            formData.append('CORfileOrEquivalent', cacheDataCopy?.years?.CORfileOrEquivalent);
          }

          delete cacheDataCopy['additional_document'];
          delete cacheDataCopy['years']['CORfileOrEquivalent'];

          formData.append('data', JSON.stringify(cacheDataCopy));

          dispatch(preQualifyUser(formData, action, (res) => {getPDF(res); setAction("edit");}));
          setSaveCCDC(false);
        }
      }
    } else {
      setListPanel(listPanel + 1);
    }
  };

  return (
    <>
    <StyledNavBar>
      <BackButton margin="0px 32px 0px 0px" handleClick={handleBackStep} />
      <Stepper
          downloading={downloading}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setListPanel={setListPanel}
          saveCCDC={saveCCDC}
          setSaveCCDC={setSaveCCDC}
      />
      <FunctionalButton
        buttonTitle={activeStep === 8 ? "DONE" : "NEXT STEP"}
        width="148px"
        height="38px"
        marginLeft="32px"
        marginRight="0px"
        fontSize="12px"
        handleButton={handleNextStep}
      />
    </StyledNavBar>
    </>
  )
};
