import React from 'react';

const Financial = ({ active, completed }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1545:3201)">
        <g filter="url(#filter0_d_1545:3201)">
          <circle cx="14" cy="14" r="14" fill="white"/>
          <circle cx="14" cy="14" r="13.5" fill={active || completed ? "#FF6D1D" : "white"} stroke="#ECEBED"/>
        </g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7778 8H9.21611C8.54144 8 8.00611 8.57912 8.00611 9.29412L8 18.3529C8 19.0673 8.54144 19.6471 9.21611 19.6471H17.7778C18.4531 19.6471 19 19.0673 19 18.3529V9.29412C19 8.57912 18.4531 8 17.7778 8ZM17.778 15.7647H15.3336C15.3336 16.8369 14.5129 17.7059 13.5002 17.7059C12.4876 17.7059 11.6669 16.8369 11.6669 15.7647H9.21636V9.29414H17.778V15.7647Z" fill={active || completed ? "white" : "#D0C9D6"}/>
      </g>
      <defs>
        <filter id="filter0_d_1545:3201" x="-64" y="-57" width="156" height="156" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="7"/>
          <feGaussianBlur stdDeviation="32"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1545:3201"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1545:3201" result="shape"/>
        </filter>
        <clipPath id="clip0_1545:3201">
          <rect width="28" height="28" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default Financial;
