import React from 'react';
import invite from './invite_color.svg'

const Invite_color = ({ isMobile }) => {
  return (
    <img width={isMobile ? "36" : "56"} height={isMobile ? "36" : "56"} src={invite} />
  )
};

export default Invite_color;
