import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from '../../../constants';
import { Typography } from "@mui/material";

export const ErrorText = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: ${COLORS.primaryRed};
    margin-top: 4px;
  }
`;

export const ErrorDiv = styled.div`
  margin-top: ${(props) => props.marginTop || "0px"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;
