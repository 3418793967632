import styled from 'styled-components';
import { COLORS } from '../../constants'
import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export const StyledMenu = styled(Menu)`
  &&&& {
    margin-top: 36px;
    margin-left: ${props => props.removeEdit ? "-30px" : "-50px"};
  }

  .MuiMenu-list {
    display: flex;
    flex-direction: row;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &&.MuiListItem-gutters {
    padding-left: 4px;
    padding-right: 4px;
  }
`;

export const StyledButton = styled(Button)`
  && {
    width: ${(props) => (props.width ? props.width : '100px')};
    height: 27px;
    background-color: white;
    border: 2px solid ${(props) => ((props.title == "edit")? COLORS.primaryGreen :
                                    (props.title == "remark")? COLORS.primaryGreen :
                                    (props.title == "finalize")? COLORS.primaryOrange :
                                    (props.title == "download")? COLORS.primaryOrange :
                                    (props.title == "upload")? COLORS.primaryOrange :
                                    (props.title == "amend")? COLORS.primaryBlue :
                                    (props.title == "cancel" || props.title == "archive")? COLORS.primaryRed :
                                    (props.title == "disable")? COLORS.primaryRed :
                                    (props.title == "enable")? COLORS.primaryGreen :
                                    (props.title == "Add User")? COLORS.primaryGreen :
                                    (props.title == "User List")? COLORS.primaryOrange :
                                    (props.title == "delete")? COLORS.primaryRed :
                                    (props.title == "submissions")? COLORS.primaryYellow :
                                    (props.title == "view")? COLORS.primaryOrange : COLORS.lightGrey1)};
    box-sizing: border-box;
    border-radius: 6px;
    color: ${(props) => ((props.title == "edit")? COLORS.primaryGreen :
                         (props.title == "remark")? COLORS.primaryGreen :
                         (props.title == "finalize")? COLORS.primaryOrange :
                         (props.title == "download")? COLORS.primaryOrange :
                         (props.title == "upload")? COLORS.primaryOrange :
                         (props.title == "amend")? COLORS.primaryBlue :
                         (props.title == "cancel" || props.title == "archive")? COLORS.primaryRed :
                         (props.title == "disable")? COLORS.primaryRed :
                         (props.title == "enable")? COLORS.primaryGreen :
                         (props.title == "Add User")? COLORS.primaryGreen :
                         (props.title == "User List")? COLORS.primaryOrange :
                         (props.title == "delete")? COLORS.primaryRed :
                         (props.title == "submissions")? COLORS.primaryYellow :
                         (props.title == "view")? COLORS.primaryOrange : COLORS.lightGrey1)};

    font-family: SF Pro Text;
    font-size: 12px;
    font-weight: 600;
    transition: all 0.5s ease;
    text-transform: uppercase;

    &:hover {
      background-color: ${(props) => ((props.title == "edit")? COLORS.primaryGreen :
                                      (props.title == "remark")? COLORS.primaryGreen :
                                      (props.title == "finalize")? COLORS.primaryOrange :
                                      (props.title == "download")? COLORS.primaryOrange :
                                      (props.title == "upload")? COLORS.primaryOrange :
                                      (props.title == "amend")? COLORS.primaryBlue :
                                      (props.title == "cancel" || props.title == "archive")? COLORS.primaryRed :
                                      (props.title == "disable")? COLORS.primaryRed :
                                      (props.title == "enable")? COLORS.primaryGreen :
                                      (props.title == "Add User")? COLORS.primaryGreen :
                                      (props.title == "User List")? COLORS.primaryOrange :
                                      (props.title == "delete")? COLORS.primaryRed :
                                      (props.title == "submissions")? COLORS.primaryYellow :
                                      (props.title == "view")? COLORS.primaryOrange : COLORS.lightGrey1)};
      color: white;
    }
`;