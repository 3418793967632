import React from "react";
import review from "./review_grey.svg";

const Review_grey = ({ isMobile }) => {
  return (
    <img
      width={isMobile ? "36" : "56"}
      height={isMobile ? "36" : "56"}
      src={review}
    />
  );
};

export default Review_grey;
