import React from "react";
import { useHistory } from "react-router-dom";

import {
  ImagePopup,
  LargeText,
  SmallText,
  ButtonArea,
  PopupContainer,
} from './styles';
import { Popup } from '../../../components/popup';
import LiftPerson from "../../../assets/onBoarding/LiftPerson";
import { FunctionalButton } from "components/functionalButton";

export const CongratsPopup = ({
  openPopup,
  setOpenPopup,
  isMobile
}) => {
  let history = useHistory();

  const handleGoToDashboard = () => {
    history.push("/");
  };

  const handleContinueProfile =  () => {
    history.push("/profile");
  };

  return (
    <Popup
        open={openPopup}
        setOpen={setOpenPopup}
        width="640px"
        height="575px"
        dialogContent={(
          <>
            <PopupContainer>
              <ImagePopup>
                <LiftPerson isMobile={isMobile} />
              </ImagePopup>
              <LargeText isMobile={isMobile}>Congrats! You're now on Your Way to Success!</LargeText>
              <SmallText>
              Would you like to complete your profile now or checkout your dashboard?
              </SmallText>
            </PopupContainer>
            <ButtonArea isMobile={isMobile} marginTop="24px">
              <FunctionalButton
                buttonTitle="GO TO DASHBOARD"
                width="250px"
                height="53px"
                marginRight={isMobile ? "0px" : "16px"}
                isReversedColor={true}
                handleButton={handleGoToDashboard}
              />
              <FunctionalButton
                buttonTitle="COMPLETE PROFILE"
                width="250px"
                height="53px"
                marginRight="0px"
                marginTop={isMobile ? "16px" : "0px"}
                handleButton={handleContinueProfile}
              />
            </ButtonArea>
        </>
        )}
      />
  )
}
