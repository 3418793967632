import { downloadFile } from "api/file_managment";
import FileSaver from "file-saver";
//Function to download file from google cloud bucket
const handleFileDownload = (fileId, fileName) => {
  downloadFile(
    { file_id: fileId },
    (data) => {
      fetch(data)
        .then((res) => res.blob())
        .then((blob) => FileSaver.saveAs(blob, fileName));
    },
    () => {
      console.log("can not download this file");
    }
  );
};

export default handleFileDownload;
