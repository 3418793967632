import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import LinkedInInactive from 'pages/pledgxOutputPDF/images/linkedInInactive.png'
import LinkedInActive from 'pages/pledgxOutputPDF/images/linkedInActive.png'
import ResumeActive from 'pages/pledgxOutputPDF/images/resumeActive.png'
import ResumeInactive from 'pages/pledgxOutputPDF/images/resumeInactive.png'
import ClickableImage from './clickableImage'

const borderColor = '#EBE9F1'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#EBE9F1',
        backgroundColor: '#F8F8F8',
        alignItems: 'center',
        height: 24,
        fontStyle: 'normal',
        paddingLeft: 15,
        color: '#000000',
        fontWeight: 400,
        fontSize: 10,
    },
    name: {
        width: '30%',
        textAlign: 'left',
        borderRightColor: borderColor,
    },
    title: {
        width: '25%',
        borderRightColor: borderColor,
        textAlign: 'left',
    },
    siteOffice: {
        width: '20%',
        borderRightColor: borderColor,
        textAlign: 'left',
    },
    linkedIn: {
        width: '15%',
        borderRightColor: borderColor,
        textAlign: 'left',
    },
    resume: {
        width: '10%',
        borderRightColor: borderColor,
        textAlign: 'left',
    },
});

const PageTwoPersonnelTableRow = ({ items }) => {
    const rows = items?.map(item =>
        <View style={styles.row} key={item?.id.toString()}>
            <Text style={styles.name}>{item?.firstName} {item?.lastName}</Text>
            <Text style={styles.title}>{item?.position}</Text>
            <Text style={styles.siteOffice}>
                {(item.type[0] === 'key_site' || item.type[0] === 'key_office') && (item.type[1] === 'key_site' || item.type[1] === 'key_office') ? "Site & Office" :
                    item.type[0] === 'key_site' ? "Site" : item.type[0] === 'key_office' ? "Office" : ""}
            </Text>
            <Text style={styles.linkedIn}>
                {item?.linkedInProfile !== "" ? <ClickableImage src={LinkedInActive} href={item?.linkedInProfile} /> : <Image src={LinkedInInactive} style={{ position: 'absolute', height: 12, width: 12 }} />}
            </Text>
            <Text style={styles.resume}>
                {item?.is_resume === true ? <Image src={ResumeActive} style={{ marginTop: 0, height: 16, width: 40 }} /> : <Image src={ResumeInactive} style={{ height: 16, width: 40 }} />}
            </Text>
        </View>
    );
    return (<Fragment>{rows}</Fragment>)
};

export default PageTwoPersonnelTableRow;
