import React, { useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { yAxisGradient, xAxisGradient } from './Gradients';
import { numberWithCommas } from "common/numberFormat.js";

const ScatterPlot = ({ data, totalAccounts, onDataPointClick }) => {

  const chartRef = useRef(null);
  const totalAccount = totalAccounts + " accounts";
  let score2 = 0;
  const [initialExtremes, setInitialExtremes] = useState(null);

  const handleZoomIn = () => {
    if (chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];
      const xAxisCurrentExtremes = xAxis.getExtremes();
      const yAxisCurrentExtremes = yAxis.getExtremes();
      const newXMin = xAxisCurrentExtremes.min + (xAxisCurrentExtremes.max - xAxisCurrentExtremes.min) * 0.25;
      const newXMax = xAxisCurrentExtremes.max - (xAxisCurrentExtremes.max - xAxisCurrentExtremes.min) * 0.25;
      const newYMin = yAxisCurrentExtremes.min + (yAxisCurrentExtremes.max - yAxisCurrentExtremes.min) * 0.25;
      const newYMax = yAxisCurrentExtremes.max - (yAxisCurrentExtremes.max - yAxisCurrentExtremes.min) * 0.25;
      xAxis.setExtremes(newXMin, newXMax);
      yAxis.setExtremes(newYMin, newYMax);
    }
  };

  const handleZoomOut = () => {
    if (chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];
      const xAxisCurrentExtremes = xAxis.getExtremes();
      const yAxisCurrentExtremes = yAxis.getExtremes();
      
      // Calculate new extremes
      const newXMin = xAxisCurrentExtremes.min - (xAxisCurrentExtremes.max - xAxisCurrentExtremes.min) * 0.25;
      const newXMax = xAxisCurrentExtremes.max + (xAxisCurrentExtremes.max - xAxisCurrentExtremes.min) * 0.25;
      const newYMin = yAxisCurrentExtremes.min - (yAxisCurrentExtremes.max - yAxisCurrentExtremes.min) * 0.25;
      const newYMax = yAxisCurrentExtremes.max + (yAxisCurrentExtremes.max - yAxisCurrentExtremes.min) * 0.25;
  
      // Ensure new minimum values are not less than 0
      const finalXMin = newXMin < 0 ? 0 : newXMin;
      const finalYMin = newYMin < 0 ? 0 : newYMin;
  
      // Set new extremes
      xAxis.setExtremes(finalXMin, newXMax);
      yAxis.setExtremes(finalYMin, newYMax);
    }
  };
  

  const handleReset = () => {
    if (chartRef.current && chartRef.current.chart && initialExtremes) {
      const chart = chartRef.current.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];
      xAxis.setExtremes(initialExtremes.xMin, initialExtremes.xMax);
      yAxis.setExtremes(initialExtremes.yMin, initialExtremes.yMax);
    }
  };

  const chartCallback = (chart) => {
    if (chartRef.current === null) {
      chartRef.current = chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];
      setInitialExtremes({
        xMin: xAxis.min,
        xMax: xAxis.max,
        yMin: yAxis.min,
        yMax: yAxis.max
      });
    }
  };


  // Define linear gradients for different risk score ranges
  const gradients = {
    '0.00': '#32CD32',
    '0.05': '#2AAC2A',
    '0.10': '#228B22',
    '0.15': '#2F9F49',
    '0.20': '#3CB371',
    '0.25': '#66D080',
    '0.30': '#90EE90',
    '0.35': '#C8F7BF',
    '0.40': '#FFFFED',
    '0.45': '#FFFF77',
    '0.50': '#FFFF00',
    '0.55': '#FFEB00',
    '0.60': '#FFD700',
    '0.65': '#FFBE00',
    '0.70': '#FFA500',
    '0.75': '#FF9900',
    '0.80': '#FF8C00',
    '0.85': '#FF7824',
    '0.90': '#FF6347',
    '0.95': '#FF3224',
    '1.0': '#FF0000'
  };


  // Function to generate marker color based on risk value
  const getMarkerColor = (score) => {
    if (score >= 0 && score < 0.05) {
      return gradients['0.00'];
    } else if (score >= 0.05 && score < 0.10) {
      return gradients['0.05'];
    } else if (score >= 0.1 && score < 0.15) {
      return gradients['0.10'];
    } else if (score >= 0.15 && score < 0.20) {
      return gradients['0.15'];
    } else if (score >= 0.20 && score < 0.25) {
      return gradients['0.20'];
    } else if (score >= 0.25 && score < 0.30) {
      return gradients['0.25'];
    }  else if (score >= 0.30 && score < 0.35) {
      return gradients['0.30'];
    } else if (score >= 0.35 && score < 0.40) {
      return gradients['0.35'];
    }  else if (score >= 0.40 && score < 0.45) {
      return gradients['0.40'];
    } else if (score >= 0.45 && score < 0.50) {
      return gradients['0.45'];
    } else if (score >= 0.50 && score < 0.55) {
      return gradients['0.50'];
    } else if (score >= 0.55 && score < 0.60) {
      return gradients['0.55'];
    } else if (score >= 0.60 && score < 0.65) {
      return gradients['0.60'];
    } else if (score >= 0.65 && score < 0.70) {
      return gradients['0.65'];
    } else if (score >= 0.70 && score < 0.75) {
      return gradients['0.70'];
    } else if (score >= 0.75 && score < 0.80) {
      return gradients['0.75'];
    } else if (score >= 0.80 && score < 0.85) {
      return gradients['0.80'];
    } else if (score >= 0.85 && score < 0.90) {
      return gradients['0.85'];
    } else if (score >= 0.90 && score < 0.95) {
      return gradients['0.90'];
    } else if (score >= 0.95 && score < 1.00) {
      return gradients['0.95'];
    } else if (score >= 1) {
      return gradients['1.00'];
    }
  };

  const options = {
    chart: {
      type: 'scatter',
      height: 600, // Set the desired height here
    },
    title: {
      text: totalAccount
    },
    defs: {
      gradient_yAxis: yAxisGradient,
      gradient_xAxis: xAxisGradient
    },
    yAxis: {
      title: {
        text: 'Size',
        style: {
          fontSize: '16px',
          color: '#FF6D1D',
          fontWeight: 'bold'
        }
      },
      lineColor: 'url(#gradient_yAxis)', // Reference the gradient pattern for yAxis
      lineWidth: 2,
      gridLineColor: 'transparent', // Hide grid lines
      labels: {
        enabled: true // Eable y-axis labels
      }
    },
    xAxis: {
      title: {
        text: 'Risk',
        style: {
          fontSize: '16px',
          color: '#FF6D1D',
          fontWeight: 'bold'
        }
      },
      lineColor: 'url(#gradient_xAxis)', // Reference the gradient pattern for xAxis
      lineWidth: 2,
      gridLineColor: 'transparent', // Hide grid lines
      labels: {
        enabled: true // Enable x-axis labels
      },
      tickLength: 0, // Hide x-axis ticks
      min: 0, // Set minimum value of x-axis
      max: 1, // Set maximum value of x-axis
      tickInterval: 0.1, // Optional: Add tick intervals for better readability
      labels: {
        formatter: function () {
          return (this.value * 100).toFixed(0) + '%'; // Format labels as percentages
        }
      }
    },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function () {
          return `Account Id: <b>${this.point.AccountId}</b><br>Company Name: <b>${this.point.CompanyName}</b><br>Risk Score(X-axis): <b>${(this.x * 100).toFixed(2)}%</b><br>Annual Gross Contract Income(Y-axis): <b>${numberWithCommas(this.y)}</b>`;
      },
      style: {
          color: '#FFFFFF' // Set text color
      },
      backgroundColor: 'rgba(255, 109, 29, 1)'
  },
    plotOptions: {
      scatter: {
        marker: {
          radius: 16, // Default point size
          states: {
            hover: {
              radiusPlus: 4, // Increase size on hover
              fillColor: '#FF6D1D' // Change color on hover rgba(255, 109, 29, 1)
            }
          }
        },
        dataLabels: {
          enabled: true,
          format: '{point.AccountId}', // Display AccountId
          style: {
            fontWeight: 'bold',
            fontSize: '7px',
            textAlign: 'center' // Center the text horizontally
          },
          align: 'center', // Center the data label horizontally
          verticalAlign: 'middle' // Center the data label vertically
        },
        events: {
          click: function (event) {
            score2 = event.point.x * 100;
            const AccountId = event.point.AccountId;
            const CompanyName = event.point.CompanyName;
            const eventData = { "AccountId": AccountId, "CompanyName": CompanyName, "score": score2 };
            onDataPointClick(eventData);
          }
        }
      }
    },
    navigation: {
      buttonOptions: {
        enabled: true
      }
    },
    series: [
      {
        name: '',
        data: data.map(point => ({
          x: point.Score,
          y: point.AnnualGrossContractIncome,
          AccountId: point.AccountId,
          CompanyName: point.CompanyName,
          marker: {
            fillColor: {
              linearGradient: [0, 0, 300, 0], // Apply linear gradient to marker fill
              stops: [
                [0, getMarkerColor(point.Score)], // Start color
                [1, getMarkerColor(point.Score)] // End color (same as start for solid fill)
              ]
            }
          }
        }))
      }
    ],
    credits: {
      enabled: false // Disable Highcharts.com link
    }
  };


  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} callback={chartCallback} ref={chartRef} />
      <button onClick={handleZoomOut} style={{ marginRight: '5px' }}>-</button>
      <button onClick={handleZoomIn} style={{ marginRight: '5px' }}>+</button>
      <button onClick={handleReset}>Reset</button>
    </div>
  );
};

export default ScatterPlot;
