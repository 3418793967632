export const getContact = (onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "GET_CONTACT",
    payload: {
      endpoint: "/profile/contact/get",
      method: "GET",
    },
    onSuccess,
    onFailed,
  });
};

export const saveAvatar = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "SAVE_AVATAR",
    payload: {
      endpoint: `/profile/avatar/save`,
      method: "POST",
      body: data,
      file: true
    },
    onSuccess,
    onFailed
  });
};


export const saveContact = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "SAVE_CONTACT",
    payload: {
      endpoint: `/profile/contact/save`,
      method: "POST",
      body: data,
    },
    onSuccess,
    onFailed
  });
};

export const getView = (data, onSuccess, onFailed) => (dispatch) => {
  dispatch({
    type: "GET_VIEW",
    data: data,
  });
};