import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { FunctionalButton } from "../../../components/functionalButton";
import {
  StyledCenter,
  SecondInnerRadioContainer,
  SafetyText,
  StyledSafetyRecordsContainer,
  StyledRow,
  SecondRadioContainer,
  SectionText,
  StyledYear,
  FieldColumn,
  FieldWrap,
  LabelText,
  MobileYearText,
  StyledTextField,
  StyledEditRecordButton,
} from "pages/newPreQualify/components/safetyRecords/styles.js";
import { StyledQuestion } from "pages/newPreQualify/components/styles.js";
import { FieldArea } from "pages/contractorDashboard/profileSetup/styles";
import { RadioButton } from "components/radioButton";
import CheckedRadioButton from "assets/dashboardIcons/checkedRadioButton";
import UncheckedRadioButton from "assets/dashboardIcons/uncheckedRadioButton";
import { StyledSubtext } from "pages/loginPage/styles";
import { PanelUploadStatement } from "./templatePanel/PanelUploadStatement";
import { Panel3 } from "./healthPanel/Panel3";
import { Panel4 } from "./healthPanel/Panel4";
import { PanelPrevStatement } from "./templatePanel/PanelPrevStatement";
import { PickProject } from "./templatePanel/PickProject";
import { CurrentYear } from "./annualRecords/currentYear";
import { PreviousPreviousYear } from "./annualRecords/PreviousPreviousYear";
import { PreviousYear } from "./annualRecords/PreviousYear";
import { SafetyRecords } from "./safetyRecords";
import { SafetyRecordsUSA } from "./safetyRecordsUSA";
import { getCountryName } from "common/countryName";
export const AnnualRecords = ({
  handleNext,
  prequalifyData,
  setPrequalifyData,
  panel,
  setPanel,
  setIsLastPanel,
  setPreviousStepPanel,
  institutionId,
}) => {
  const currentDashboardUrl = localStorage.getItem("current_dashboard_url");
  const isMobile = useMediaQuery({ maxWidth: 960 });
  setIsLastPanel(true);
  let countryForHS = "United States";

  if (
    getCountryName(prequalifyData?.project_info?.owner_country) === "Canada"
  ) {
    countryForHS = "Canada";
  } else {
    countryForHS = "United States";
  }

  const [selectedCountry, setSelectedCountry] = useState(countryForHS);
  const isCanada = selectedCountry === "Canada";
  const isUSA = selectedCountry === "United States";
  const [containCanada, setContainCanada] = useState(isCanada);
  const [notContainCanada, setNotContainCanada] = useState(isUSA);

  const confirmRadioChange = (val) => {
    if (val === 1) {
      setContainCanada(true);
      setSelectedCountry("Canada");
      setNotContainCanada(false);
    } else {
      setNotContainCanada(true);
      setSelectedCountry("United States");
      setContainCanada(false);
    }
  };

  useEffect(() => {
    setPrequalifyData(
      "annual.remark_records.selected_country_for_health_safety",
      selectedCountry
    );
  }, [selectedCountry]);

  return (
    <>
      <StyledQuestion marginBottom="32px">
        Please enter your Health & Safety Records
      </StyledQuestion>

      <StyledSubtext style={{ marginLeft: "-480px", justifyContent: "left" }}>
        {" "}
        Which country do you select for Health & Safety Records?{" "}
      </StyledSubtext>

      <StyledEditRecordButton
        style={{ marginLeft: "-20px", justifyContent: "left" }}
      >
        <RadioButton
          isChecked={
            containCanada === true && notContainCanada === false ? true : false
          }
          value={containCanada}
          checkedIcon={<CheckedRadioButton />}
          unCheckedIcon={<UncheckedRadioButton />}
          handleChange={() => confirmRadioChange(1)}
        />
        <StyledSubtext style={{ marginTop: "0px" }}> Canada</StyledSubtext>
        <RadioButton
          isChecked={
            notContainCanada === true && containCanada === false ? true : false
          }
          value={notContainCanada}
          checkedIcon={<CheckedRadioButton />}
          unCheckedIcon={<UncheckedRadioButton />}
          handleChange={() => confirmRadioChange(0)}
        />
        <StyledSubtext style={{ marginTop: "0px" }}>
          {" "}
          United States
        </StyledSubtext>
      </StyledEditRecordButton>

      {panel == 1 && containCanada === true && (
        <SafetyRecords
          data={prequalifyData}
          setData={setPrequalifyData}
          isMobile={isMobile}
          handleNext={handleNext}
          institutionId={institutionId}
          currentDashboardUrl={currentDashboardUrl}
        />
      )}

      {panel == 1 && containCanada === false && (
        <SafetyRecordsUSA
          data={prequalifyData}
          setData={setPrequalifyData}
          isMobile={isMobile}
          handleNext={handleNext}
          institutionId={institutionId}
          currentDashboardUrl={currentDashboardUrl}
        />
      )}
    </>
  );
};
