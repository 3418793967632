import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import IconButton from "@material-ui/core/IconButton";
import { StyledButton, StyledMenu, StyledMenuItem } from "./styles";
import VerticalThreeDots from "assets/dashboardIcons/verticalThreeDots";
import { useHistory } from "react-router";
import { AlertPopup } from "components/alertPopup/alertPopup";
import axios from "axios";
import { API_HOST } from "constants.js";
import { AddDocsPendingIDPPopUp } from "components/addDocsPendingIDPPopUp";

export const ActionsMenu = ({ client_id, companyName, status }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let history = useHistory();
  const [showPopup, setShowPopup] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 453 });
  const [openDocsPendingIDPPopup, setOpenDocsPendingIDPPopup] = useState(false);

  const handleUploadPdfFile = async (file) => {
    let headers = {};
    const getAuth = () => {
      const authData = localStorage.getItem("Authorization");
      if (authData) {
        headers = { Authorization: authData };
      }
    };
    setShowPopup(true);

    if (file) {
      const formData = new FormData();
      formData.append('account_id', client_id);
      formData.append('company_name', companyName);
      formData.append('pdfFile', file);

      try {
        getAuth();
        const response = await axios.post(`${API_HOST}/underwriter/financial/data/analysis`, formData, {
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
          },
        });
        setShowPopup(false);
      } catch (error) {
        setShowPopup(false);
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const seeAllProjects = () => {
    history.push("/underwriter/client/projects", {
      company_name: companyName,
      account_id: client_id,
    });
  };

  const viewFinancialAnalysis = () => {
    history.push("/underwriter/client/financialanalysis", {
      company_name: companyName,
      account_id: client_id
    });
  };


  const viewDocsPendingIDP = () => {
    setOpenDocsPendingIDPPopup(true);
  };


  const seeWorkInProgress = () => {
    history.push("/underwriter/client/wip", {
      company_name: companyName,
      account_id: client_id,
    });
  };

  if (["deleted"].includes(status)) {
    return <></>;
  } else {
    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ padding: 0 }}
        >
          <VerticalThreeDots />
        </IconButton>
        <StyledMenu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
          {/* <StyledMenuItem>
            <StyledButton width="180px">
              Upload Docs
              <input
                id="file-input"
                type="file"
                accept="application/pdf"
                style={{
                  position: "absolute",
                  opacity: 0,
                }}
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    handleUploadPdfFile(file);
                  }
                }}
              />
            </StyledButton>
          </StyledMenuItem> */}

          <StyledMenuItem>
            <StyledButton width="180px" onClick={viewFinancialAnalysis}>
              Financial Analysis
            </StyledButton>
          </StyledMenuItem>

          {/* <StyledMenuItem>
            <StyledButton width="180px" onClick={viewDocsPendingIDP}>
              Docs Pending IDP*
            </StyledButton>
          </StyledMenuItem> */}


          <StyledMenuItem>
            <StyledButton
              width="180px"
              onClick={seeWorkInProgress}
            // disabled
            >
              WIP Analysis
            </StyledButton>
          </StyledMenuItem>

          <StyledMenuItem>
            <StyledButton width="180px" onClick={seeAllProjects}>
              All Projects
            </StyledButton>
          </StyledMenuItem>

          <StyledMenuItem>
            <StyledButton width="180px" disabled>
              pledgXeCQS
            </StyledButton>
          </StyledMenuItem>

          <StyledMenuItem>
            <StyledButton
              width="180px"
              onClick={() => console.log("future implementation")}
              disabled
            >
              References
            </StyledButton>
          </StyledMenuItem>
          {/*<StyledMenuItem>
            <StyledButton width="180px" onClick={seeWorkInProgress}>
              WIP
            </StyledButton>
          </StyledMenuItem> */}

        </StyledMenu>

        <AddDocsPendingIDPPopUp
          setOpenDialog={setOpenDocsPendingIDPPopup}
          openDialog={openDocsPendingIDPPopup}
        />

        <AlertPopup
          openPopup={showPopup}
          setOpenPopup={setShowPopup}
          isMobile={isMobile}
          headerText="File Uploaded Successfully."
          subHeaderText={
            <>
              IDP In Progress...<span style={{ animation: 'spin 2s linear infinite' }}>⏳</span> <br />
              Approximate time for completion: 5-10 minutes{' '}
            </>
          }
          showButton={false}
        />

      </>
    );
  }
};
