import React from 'react';
import additional from './projectShow_grey.svg'

const ProjectShow_grey = ({ isMobile }) => {
  return (
    <img width={isMobile ? "36" : "56"} height={isMobile ? "36" : "56"} src={additional} />
  )
};

export default ProjectShow_grey;
