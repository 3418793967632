import React, { useState, useEffect } from "react";
import { Popup } from "../popup";
import { Steps } from "./steps";

export const AddProjectPopup = ({
  setOpenPopup,
  openPopup,
  data,
  setData,
  isMobile,
  handleButtonFunc,
  institution_id,
}) => {
  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width="650px"
      height="661px"
      dialogContent={
        <Steps
          isMobile={isMobile}
          setOpenPopup={setOpenPopup}
          onboardingData={data}
          setOnboardingData={setData}
          handleButtonFunc={handleButtonFunc}
          institution_id={institution_id}
        />
      }
    />
  );
};
