import React, { useState } from "react";
import Input from "components/inputField";
import { InfoArea } from "./styles";
import { StyledRow } from "./styles";
import { CommonBusinessType } from "components/commonBusinessType/index.js";
import RadioGroupButtonForProjectCategory from "components/radioGroupButtonForProjectCategory";

export const Step1 = ({
  setDisable,
  addData,
  setAddData,
  isMobile
}) => {

  const [containsUNSPC, setContainsUNSPC] = useState("");

  const confirmRadioChange = (val) => {
    const newValue = val === 1 ? false : true;
    setContainsUNSPC(newValue);
    setAddData('is_unspc_category_type', newValue);
  };

  const handleProjectTitleChange = (val) => {
    setAddData('project_title', val);
  };

  const handleProjectNumberChange = (val) => {
    setAddData('project_number', val);
  };

  const handleProjectCityChange = (val) => {
    setAddData('project_city', val);
  };

  return (
    <InfoArea isMobile={isMobile} marginTop="0px" marginLeft="8px" isColumn={true} >
      <StyledRow isMobile={isMobile}>
        <Input
          id="fullWidthInputWrap"
          label="Project Title"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.project_title || ''}
          // setValue={(val) => setAddData('project_title', val)}
          setValue={handleProjectTitleChange}
          validateFunc={(item) => item.length > 0}
        />
      </StyledRow>
      <StyledRow isMobile={isMobile}>
        <Input
          id="oneThirdInputWrap"
          label="Project Number"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.project_number || ''}
          // setValue={(val) => setAddData('project_number', val)}
          setValue={handleProjectNumberChange}
          validateFunc={(item) => item.length > 0}
        />
        <Input
          id="twoThirdInputWrap"
          label="Location"
          type="text"
          placeholder=""
          labelSize="12px"
          fontSize="18px"
          value={addData?.project_city || ''}
          // setValue={(val) => setAddData('project_city', val)}
          setValue={handleProjectCityChange}
          validateFunc={(item) => item.length > 0}
        />
      </StyledRow>

      <RadioGroupButtonForProjectCategory
        containsUNSPC={containsUNSPC}
        confirmRadioChange={confirmRadioChange}
      />
      <StyledRow>
        <CommonBusinessType
          data={addData}
          setData={setAddData}
          isMobile={isMobile}
          notPopup={true}
          containsUNSPC={containsUNSPC}
        />
      </StyledRow>
    </InfoArea>

  )
}