import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { filterPrequaliedContractors } from "../../../api/buyers";
import { StyledBox, PlaceholderText } from "../styles";
import { ReportsCard } from "./ReportsCard";
import { SeeAllText } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getServiceProviderCqsListOfAllClient } from "data/actions/serviceProvider";
import { serviceProviderCqsListSelector } from "data/selectors/serviceProvider";

export const TabPanel1 = ({ isMobile }) => {
  const [cqsList, setCqsList] = useState("");
  const history = useHistory();
  const allContractorCqsData = useSelector(serviceProviderCqsListSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (allContractorCqsData?.length < 1) {
      dispatch(getServiceProviderCqsListOfAllClient());
    }
  }, []);

  useEffect(() => {
    if (allContractorCqsData?.length > 0) {
      setCqsList(allContractorCqsData);
    }
  }, [allContractorCqsData]);

  const handleSeeAllCqs = () => {
    history.push("/service-provider-dashboard/clients/cqs");
  };

  return (
    <>
      {!(cqsList?.length > 0) && (
        <StyledBox boxHeight="80px" borderRadius="10px">
          <PlaceholderText>All CQS will appear here</PlaceholderText>
        </StyledBox>
      )}
      {cqsList?.length > 0 &&
        cqsList
          .slice(-6)
          .reverse()
          .map((item, i) => {
            if (item.institutionName) return <ReportsCard {...item} key={i} />;
          })}
      {cqsList?.length > 0 && (
        <SeeAllText onClick={handleSeeAllCqs}>See all CQS</SeeAllText>
      )}
    </>
  );
};
