import styled from "styled-components";
import { Button } from "@material-ui/core";
import { COLORS } from "../../constants";

export const StyledResumeButton = styled(Button)`
  && {
    max-width: ${(props) => props.width || "125px"};
    padding: 0 20px;
    height: ${(props) => props.height || "29px"};
    background-color: ${(props) =>
      props.exists ? COLORS.primaryGreen2 : COLORS.lightGrey4};
    margin: ${(props) => props.margin || "0px"};
    border-radius: 20px;
    font-size: ${(props) => props.fontSize || "11px"};
    color: ${(props) =>
      props.exists ? COLORS.lightGreen4 : COLORS.primaryGray};
    text-transform: none;
    pointer-events: none;
  }
  // &:hover {
  //   color: black;
  // }
`;
