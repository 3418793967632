import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  getRecommendedTenders,
  getResultTenders,
  getSavedTenders,
  saveTender,
  deleteTender,
  loadMoreTenders,
  getResultTendersUsers,
} from "../../data/actions/tenders";
import {
  recommendedSelector,
  resultSelector,
  savedSelector,
  contractorsSelector,
} from "../../data/selectors/tenders";
import { getUserCategories } from "../../data/actions/overview";
import { categoriesSelector } from "../../data/selectors/overview";
import DashHeader from "../../components/dashboardHeader";
import Sidebar from "../../components/sidebar";
import { Container } from "../../components/common";
import Controls from "../../components/controlPanel";

import Select from "react-select";

import TenderCard from "../../components/tenderCard";
import Notices from "../../components/notice";

import {
  Content,
  BaseInfo,
  InfoBox,
  Circle,
  InfoSubTitle,
  InfoTitle,
  Title,
  ContentControls,
  SelectLabel,
  ChangeMode,
  ChangeModeItem,
  Row,
  Column,
  ContractorRow,
  ContractorName,
  ContractorWinnerTag,
} from "./styles";

const LoadMoreButton = withStyles((theme) => ({
  root: {
    marginTop: 20,
    width: "100%",
    height: 48,
    backgroundColor: "#2d2d2d",
    border: "1.5px solid #2d2d2d",
    boxSizing: "border-box",
    borderRadius: "6px",
    color: "white",

    fontSize: "17px",
    fontWeight: 600,
    transition: "all 0.5s ease",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "white",
      color: "#2d2d2d",
    },
  },
}))(Button);

function Item({ color, contracts, title, sub_items, onClick, style }) {
  return (
    <InfoBox onClick={onClick} style={style}>
      <Circle color={color}></Circle>
      <div style={{ marginLeft: 12 }}>
        <InfoSubTitle>
          {contracts} {sub_items}
        </InfoSubTitle>
        <InfoTitle color={color}>{title}</InfoTitle>
      </div>
    </InfoBox>
  );
}

function ContentHeader({ current, setMode, categories, setChosenCategories }) {
  return (
    <ContentControls>
      <Title>{current}</Title>
      {/* <Select
        isMulti
        openMenuOnFocus
        styles={{
          container: (styles) => ({
            ...styles,
            // flexGrow: 1,
            minWidth: 200,
          }),
          indicatorSeparator: (styles) => ({
            display: "none",
          }),
          control: (styles) => ({
            ...styles,
            boxShadow: "none",
            height: 42,
            marginRight: 20,
            background: "#E9E9E9",
            border: "none !important",
            borderRadius: "6px",
            fontSize: "12px",
            color: "#3F3356",
            outline: "none",
          }),
          menu: (styles) => ({
            ...styles,
            fontSize: "12px",
            color: "#444444",
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected ? "#e9e9e9" : "white",
              color: "#444444",
              cursor: "pointer",
            };
          },
        }}
        className="basic-single"
        classNamePrefix="select"
        name="sort-by"
        options={categories.map((cat) => {
          return { value: cat, label: cat };
        })}
        onChange={(e) => setChosenCategories(e.map((item) => item.value))}
      /> */}
      <SelectLabel>Sort by:</SelectLabel>
      <Select
        openMenuOnFocus
        styles={{
          indicatorSeparator: (styles) => ({
            display: "none",
          }),
          control: (styles) => ({
            ...styles,
            boxShadow: "none",
            width: 150,
            marginRight: 20,
            background: "#E9E9E9",
            border: "none !important",
            borderRadius: "6px",
            fontSize: "12px",
            color: "#3F3356",
            outline: "none",
          }),
          menu: (styles) => ({
            ...styles,
            fontSize: "12px",
            color: "#444444",
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected ? "#e9e9e9" : "white",
              color: "#444444",
              cursor: "pointer",
            };
          },
        }}
        className="basic-single"
        classNamePrefix="select"
        defaultValue={{ value: "dateLatest", label: "Date - Latest" }}
        name="sort-by"
        options={[
          { value: "dateLatest", label: "Date - Latest" },
          { value: "dateEarliest", label: "Date - Earliest" },
        ]}
      />
      <ChangeMode>
        <ChangeModeItem active onClick={() => setMode("list")}>
          List
        </ChangeModeItem>
        <ChangeModeItem onClick={() => setMode("map")}>Map</ChangeModeItem>
      </ChangeMode>
    </ContentControls>
  );
}

function ContractorsList({ tenderId }) {
  const dispatch = useDispatch();
  const contractors = useSelector(contractorsSelector);

  useEffect(() => {
    if (!contractors[tenderId]) {
      dispatch(getResultTendersUsers(tenderId));
    }
  });

  return (
    <Column style={{ width: "100%" }}>
      {contractors[tenderId] &&
        contractors[tenderId].map((item) => (
          <ContractorRow>
            <ContractorName>{item.company_name}</ContractorName>
            {item.bid_won === "Yes" && (
              <ContractorWinnerTag>WINNING BID PRICE</ContractorWinnerTag>
            )}
          </ContractorRow>
        ))}
    </Column>
  );
}

function ListTenders({
  recommended,
  saved,
  results,
  setMode,
  categories,
  chosenCategories,
  setChosenCategories,
}) {
  const dispatch = useDispatch();
  const [current, setCurrent] = useState("Recommended");
  const [loadind, setLoading] = useState(false);

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Row style={{ marginTop: 10 }}>
        <Column style={{ flexGrow: 1 }}>
          <BaseInfo>
            <Item
              color="#FF6D1D"
              contracts={recommended.length}
              sub_items="Tenders"
              title="Recomended"
              onClick={() => setCurrent("Recommended")}
            ></Item>
            <Item
              color="#435EAB"
              contracts={saved.length}
              sub_items="Tenders"
              title="Saved"
              onClick={() => setCurrent("Saved")}
            ></Item>
            <Item
              color="#02814E"
              contracts={results.length}
              sub_items="Bids"
              title="Submitted/ Results"
              style={{ marginRight: 0 }}
              onClick={() => setCurrent("Submitted/ Results")}
            ></Item>
          </BaseInfo>
          <ContentHeader
            current={current}
            setMode={setMode}
            categories={categories}
            setChosenCategories={setChosenCategories}
          ></ContentHeader>

          {current === "Recommended" && (
            <>
              {recommended.map((tender, i) => (
                <TenderCard
                  key={i}
                  tender={tender}
                  onSave={() => dispatch(saveTender(tender))}
                  onDelete={() => dispatch(deleteTender(tender))}
                ></TenderCard>
              ))}
              <LoadMoreButton
                onClick={() => {
                  setLoading(true);
                  dispatch(
                    loadMoreTenders(
                      recommended.length,
                      chosenCategories.join(),
                      () => setLoading(false),
                      () => setLoading(false)
                    )
                  );
                }}
              >
                {loadind ? "Loading..." : "Load More"}
              </LoadMoreButton>
            </>
          )}
          {current === "Saved" && (
            <>
              {saved.length !== 0 ? (
                saved.map((tender, i) => (
                  <TenderCard
                    key={i}
                    tender={tender}
                    onSave={() => dispatch(saveTender(tender))}
                    onDelete={() => dispatch(deleteTender(tender))}
                  ></TenderCard>
                ))
              ) : (
                <h3>Your saved list is empty.</h3>
              )}
            </>
          )}
          {/* {current === "Submitted/ Results" &&
            results.map((tender, i) => (
              <TenderCard key={i} tender={tender}></TenderCard>
            ))} */}

          {current === "Submitted/ Results" && (
            <div style={{ marginTop: 10 }}>
              {results.map((item, i) => (
                <Accordion
                  key={i}
                  TransitionProps={{ unmountOnExit: true }}
                  expanded={expanded === i}
                  onChange={handleChange(i)}
                >
                  <AccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <TenderCard
                      closed
                      tender={item}
                      style={{ width: "100%" }}
                    ></TenderCard>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ContractorsList tenderId={item.Id}></ContractorsList>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          )}
        </Column>
        <Column style={{ marginLeft: 20, marginTop: 5 }}>
          <Notices></Notices>
        </Column>
      </Row>
    </>
  );
}

function BidMatching(props) {
  const dispatch = useDispatch();
  const recommended = useSelector(recommendedSelector);
  const saved = useSelector(savedSelector);
  const results = useSelector(resultSelector);
  const categories = useSelector(categoriesSelector);

  const [chosenCategories, setChosenCategories] = useState([]);

  const [mode, setMode] = useState("list");

  useEffect(() => {
    dispatch(getUserCategories());
    dispatch(getRecommendedTenders());
    dispatch(getSavedTenders());
    dispatch(getResultTenders());
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getRecommendedTenders(0, chosenCategories.join()));
  // }, [dispatch, chosenCategories]);

  return (
    <>
      <DashHeader></DashHeader>
      <Sidebar></Sidebar>
      <Container>
        <Controls />
        <Content>
          {mode === "list" && (
            <ListTenders
              recommended={recommended}
              saved={saved}
              results={results}
              setMode={setMode}
              categories={categories}
              chosenCategories={chosenCategories}
              setChosenCategories={setChosenCategories}
            ></ListTenders>
          )}
          {mode === "map" && <h1>MAP</h1>}
        </Content>
      </Container>
    </>
  );
}

export default BidMatching;
