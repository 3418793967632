import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from '../../constants';
import Typography from '@material-ui/core/Typography';
import { Avatar } from "@material-ui/core";

export const ScreenRoot = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => (props.isMobile ? "position: relative;" : "")}
  width: 100%;
  max-width: 1440px;
  height: 100vh;
  ${(props) => (props.isMobile ? "" : "align-content: center;" )}
  // ${(props) => (props.isMobile ? "" : "justify-content: center;")}
  ${(props) => (props.isMobile ? "padding: 70px 0 0 0;" : "padding: 70px 70px 0px 70px;" )}
  background-color: ${COLORS.backgroundGrey};
`;

export const HeaderArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justifyContent || "space-between"};
  width: ${props => props.width || "100%"};
`;

export const LargeText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.isMobile ? "18px" : "28px"};
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    margin-top: 16px;
    margin-bottom: 0px;
  }
`;

export const DetailedFormContainer = styled.div`
  width: ${(props) => (props.isMobile && !props.noWidthChange ? "90%" : "100%")};
  ${(props) => (props.isMobile ? "align-self: center;" : "")}
  padding-bottom: 20px;
  margin-top: ${(props) => (props.isMobile ? "16px" : "24px")};
  display: flex;
  flex-direction: ${props => props.flexDirection || "row"};
`;

export const ColumnContainer = styled.div`
  width: ${(props) => (props.isMobile ? "90%" : `${props.width}`)};
  display: flex;
  flex-direction: column;
  ${props => props.margin ? `margin: ${props.margin};` : ""};
`;

export const StyledBox = styled.div`
  width: ${props => props.boxWidth || "100%"};
  border-radius: ${props => props.borderRadius || `10px`}; 
  border: 1.5px solid #EBE9F1;
  margin-bottom: 12px;
  // height: ${props => props.boxHeight || "248px"};
  min-height: ${props => props.boxHeight || "248px"};
  display: flex;
  flex-direction: ${props => props.flexDirection || "column"};
  ${props => props.isMobile ? "align-items: center;" : ""}
  ${props => props.margin ?  `margin: ${props.margin};` : ""}
  ${props => props.hasData ? "" : "justify-content: center;"}
  ${props => props.noBackground ? "" : "background-color: white;"}
`;

export const CompanyLogo = styled(Avatar)`
  &&{
    width: 121px;
    height: 114px;
  }
`;

export const EditBadge = styled(Avatar)`
  &&{
    width: ${props => props.isMobile ? "18px" : "30px"};
    height: ${props => props.isMobile ? "18px" : "30px"};
    ${props => props.isMobile ? "border: 1px solid white;" : "border: 2px solid white;"}
    cursor: pointer;
  }
`;

export const StyledBoxContent = styled.div`
  margin: ${props => props.margin || "32px 32px"};
  display: flex;
  flex-direction: ${props => props.flexDirection || "row"};
  ${props => props.width ? `width: ${props.width};` : ""}\
  position: relative;
`;

export const LargeTitle = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 24px;
    font-style: normal;
    font-weight: ${props => props.fontWeight || "500"};
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
`;

export const SmallTitle = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.fontSize || "15px"};
    font-style: normal;
    font-weight: 500;
    text-align: left;
    color: ${COLORS.smallTitleGrey};
    ${props => props.margin ? `margin: ${props.margin};` : ""}
  }
`;

export const DataText = styled(Typography)`
  &&{
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${props => props.fontSize || "14px"};
    font-style: normal;
    font-weight: ${props => props.fontWeight || "400"};
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    ${props => props.margin ? `margin: ${props.margin};` : ""}
  }
`;

export const InfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.width ? `width: ${props.width};` : ""}
`;