import React from "react";
import BorderLessConciseTable from "../../../../components/filesListTable/borderLessConciseTable";
import { StyledTableCellFirst, StyledTableCheckBoxCell, StyledTableCenteringContainer, StyledTableCellSecond, StyledMinusImg, StyledIndexCell } from "./styles";
import RoundedCheckbox from "../../../../components/roundedCheckbox";
import minusSignIcon from "../../../../assets/common/minusSignIcon.svg";
import { COLORS } from "../../../../constants";


export const SelectedProjects = ({ projects, setProjects }) => {
  const removeProject = (e, index) => {
    const temp = [...projects];
    temp.splice(index, 1);

    setProjects(temp);
  };

  const changeProjectType = (e, index) => {
    const temp = [...projects];
    const type = e.target.value;

    const projectType = temp[index].type;

    const typeExists = projectType.indexOf(type);

    if(typeExists >= 0) {
      projectType.splice(typeExists, 1);
    } else {
      projectType.push(type);
    }

    setProjects(temp);
  };

  const body = (bodyData) => {
    return (
    <>
      <StyledIndexCell>
        {bodyData.index + 1}
      </StyledIndexCell>
      <StyledTableCellFirst>
        {bodyData.project_number}
      </StyledTableCellFirst>
      <StyledTableCellSecond>
        {bodyData.name}
      </StyledTableCellSecond>
      <StyledTableCellFirst>
        {bodyData.location}
      </StyledTableCellFirst>
      <StyledTableCheckBoxCell>
        <StyledTableCenteringContainer>
          <RoundedCheckbox attributeTypes={bodyData.type} value='key' onChange={(e) => changeProjectType(e, bodyData.index)} />
        </StyledTableCenteringContainer>
      </StyledTableCheckBoxCell>
      <StyledTableCheckBoxCell>
        <StyledTableCenteringContainer>
          <RoundedCheckbox attributeTypes={bodyData.type} value='comparable' onChange={(e) => changeProjectType(e, bodyData.index)} />
        </StyledTableCenteringContainer>
      </StyledTableCheckBoxCell>
      <StyledTableCheckBoxCell>
        <StyledTableCenteringContainer>
          <RoundedCheckbox attributeTypes={bodyData.type} value='underway' onChange={(e) => changeProjectType(e, bodyData.index)} />
        </StyledTableCenteringContainer>
      </StyledTableCheckBoxCell>
      <StyledTableCheckBoxCell>
        <StyledTableCenteringContainer>
          <StyledMinusImg src={minusSignIcon} onClick={(e) => removeProject(e, bodyData.index)} />
        </StyledTableCenteringContainer>
      </StyledTableCheckBoxCell>
    </>
  )};

  const headers = [
    { title: '' },
    { title: 'Project #' },
    { title: 'Project Title' },
    { title: 'Project Location' },
  ];

  const checkBoxHeaders = [
    { title: 'Key' },
    { title: 'Comparable' },
    { title: 'Underway' },
  ]

  const actionsHeader = [
    { title: 'Remove' }
  ]

  return (
    <>
      <BorderLessConciseTable
        headers={headers}
        checkBoxHeaders={checkBoxHeaders}
        length={4.75}
        body={body}
        data={projects}
        actionsHeaders={actionsHeader}
      />
    </>
  )
}

export default SelectedProjects;
