import React from "react";
import { FunctionalButton } from 'components/functionalButton';
import { Popup } from 'components/popup';
import { CloseButton } from "components/closeButton";
import PledgxLogo from "assets/common/pledgxLogo";
import { addProgressPaymentContractor } from "api/work_in_progress";

import {
  TitleDiv,
  TitleText,
  PopupContainer,
  ButtonDiv
} from "./styles.js";


export const SubmitSendForApproval = ({
  openPopup,
  setOpenPopup,
  isMobile,
  data,
  setOpenDialog
}) => {

  const handleDone = () => {
    setOpenPopup(false);
  };


  const sendForApproval = () => {
    addProgressPaymentContractor(data, () => {
      setOpenPopup(false);
      setOpenDialog(false);     
    }, () => { });
  };


  return (
    <Popup
      open={openPopup}
      setOpen={setOpenPopup}
      width={isMobile ? "100%" : "577px"}
      height={isMobile ? "100vh" : "338px"}
      dialogContent={(
        <>
          <CloseButton handleClose={handleDone} />
          <PopupContainer>
            <TitleDiv>
              <PledgxLogo width={isMobile ? 75 : ""} height={isMobile ? 74 : ""} />
            </TitleDiv>

            <TitleText fontSize="28px" fontWeight="700" color="#1A051D" margin="41px 60px 39px" lineHeight="41px">Please confirm requesting </TitleText>
            <TitleText fontSize="28px" fontWeight="700" color="#1A051D" margin="-20px 0px 30px" lineHeight="41px" textAlign="center"> Approval from <span style={{ fontWeight: "400" }}>{data?.buyer_email}</span></TitleText>
            <ButtonDiv isMobile={isMobile}>
              <FunctionalButton
                buttonTitle="GO BACK"
                handleButton={handleDone}
                isReversedColor={true}
                height="48px"
                width="233px"
                marginTop="0px"
                marginRight="16px"
              />
              <FunctionalButton
                buttonTitle={"CONFIRM"}
                height="48px"
                width="233px"
                marginTop="0px"
                marginRight="0px"
                handleButton={() => sendForApproval()}
              />
            </ButtonDiv>
          </PopupContainer>

        </>
      )}
    />

  )
}
