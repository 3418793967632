import styled, { keyframes } from "styled-components";

export const ClickablePageThumbnail = styled.div`
  box-sizing: border-box;
  height: 157px;
  cursor: pointer;
  border: ${(props) =>
    props.selected ? "1px solid #ff6d1d" : "1px solid transparent"};
  display: inline-block;
  box-shadow: 0 2px 6px #0000000f;
`;

export const SplitContainer = styled.div`
  position: relative;
  height: 194px;
  width: 2px;
`;

const skeletonLoading = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const SplitSkeletonContainer = styled.div`
  position: relative;
  height: 194px;
  width: 2px;
`;

export const LineSkeleton = styled.div`
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to right,
    #f0f0f0 0%,
    #ffffff 20%,
    #f0f0f0 40%,
    #f0f0f0 100%
  );
  background-size: 200px 100%;
  animation: ${skeletonLoading} 1.2s infinite;
`;

export const ToggleButtonSkeleton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-image: linear-gradient(
    to right,
    #f0f0f0 0%,
    #ffffff 20%,
    #f0f0f0 40%,
    #f0f0f0 100%
  );
  background-size: 200px 100%;
  animation: ${skeletonLoading} 1.2s infinite;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const ClickablePageThumbnailSkeleton = styled.div`
  height: 155px;
  width: 120px;
  background: #f0f0f0;
  background-image: linear-gradient(
    to right,
    #f0f0f0 0%,
    #ffffff 20%,
    #f0f0f0 40%,
    #f0f0f0 100%
  );
  background-repeat: no-repeat;
  background-size: 200px 100%;
  animation: ${shimmer} 1.2s infinite linear;
  border-radius: 4px;
`;

export const Line = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${(props) => (props.selected ? "#ff6d1d" : "#DCDCE1")};
`;

export const ToolbarButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const ToggleButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 12px;
  width: 12px;
  background-color: ${(props) => (props.selected ? "#ff6d1d" : "#DCDCE1")};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConfidenceLabel = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  padding: 2px 6px;
  background-color: #ff6d1d;
  transform: translateX(-50%);
  color: white;
  font-weight: 600;
  font-size: 14px;
`;
