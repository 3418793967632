import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from '../../constants';

export const StyledSearchContainer = styled.div`
  display: inline-block;
  width: ${props => props.width || "60%"};
`;

export const StyledSearch = styled.input`
  && {
    height: 48px;
    width: ${props => props.searchWidth || "860px"};
    font-family: Times New Roman;

    .prompt {
      border-radius: 5px;
      border-color: ${COLORS.darkGrey3}
    }

    &&:focus {
      border-radius: 5px;
      border-color: ${COLORS.darkGrey3}
    }
  }
`;

export const StyledSearchIcon = styled.img`
  position: absolute;
  margin-top: 12px;
  margin-left: 8px;
  height: 24px;
`;

export const StyledCancelIcon = styled.img`
  position: absolute;
  margin-top: 17px;
  margin-left: 830px;
  height: 16px;
`;