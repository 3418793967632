const updateObjectByPath = (obj, path, value) => {
  let schema = obj;
  let pList = path.split('.');
  const len = pList.length;
  
  for(let i = 0; i < len - 1; i++) {
      const elem = pList[i];
      
      if(!schema[elem]) schema[elem] = {};
      schema = schema[elem];
  }

  schema[pList[len-1]] = value;
};

export default updateObjectByPath;
