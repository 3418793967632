import styled from "styled-components";
import { TYPOGRAPHY, COLORS } from "../../../../constants";
import Typography from "@material-ui/core/Typography";

export const StyledYear = styled(Typography)`
  justify-content: center;
  display: flex;
  flex-direction: column;

  && {
    font-size: 15px;
    height: 60px;

    &.MuiTypography-root {
      font-weight: 600;
    }
  }
`;

export const FieldColumn = styled.div`
  display: flex;
  flex-direction: column;

  .yearLabel {
    visibility: hidden;
  }
`;

export const LabelText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin-right: -32px;
  }
`;

export const FieldWrap = styled.div`
  ${(props) =>
    props.marginLeft && !props.isMobile
      ? `margin-left: ${props.marginLeft};`
      : ""}
  margin-top: ${(props) => props.marginTop || "24px"};
  display: flex;
  flex-direction: ${(props) =>
    props.isColumn || props.isMobile ? "column" : "row"};
  flex-wrap: wrap;
  ${(props) =>
    props.width && !props.isMobile ? `width: ${props.width};` : "width: 100%;"}

  .last {
    padding-right: 0px !important;
  }

  .firstColumn {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: ${(props) => (props.isMobile ? "100%" : "7%")};
  }

  .recordColumn {
    padding-right: ${(props) => (props.isMobile ? "0" : "16px")};
    width: 18.6%;
  }

  .fullWidthInputWrap {
    padding-right: 16px;
    width: 100%;
  }
`;

export const MobileYearText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: ${COLORS.textGrey};
    ${(props) => (props.margin ? `margin: ${props.margin};` : "")}
  }
`;
