import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 10,
    justifyContent: 'flex-start',
    width: '50%',
    flex: 1,
    paddingLeft: 30,
    paddingRight: 10,
  },
  bondDetails: {
    marginTop: 10,
    paddingBottom: 3,
    fontFamily: 'Helvetica',
    fontSize: 14,
    color: '#B9B9B9',
    fontWeight: 600,

  },
  spanText: {
    color: '#B9B9B9',
  },
});

const PageBondDetails = ({ csrData }) => (
  <View style={styles.headerContainer}>
    <Text style={styles.bondDetails}>Bond Details</Text>
    <Text>
      <span style={styles.spanText}>Bond Id: </span>
      {csrData?.data?.bond_id}
    </Text>
    <Text>
      <span style={styles.spanText}>Effective Date: </span>
      {csrData?.data?.effective_date}
    </Text>
    <Text>
      <span style={styles.spanText}>Obligee: </span>
      {csrData?.data?.obligee}
    </Text>
  </View>
);

export default PageBondDetails;
