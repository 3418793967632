import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#EBE9F1'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: '#F8F8F8',
        color: '#888888',
        alignItems: 'center',
        height: 24,
        textAlign: 'left',
        fontStyle: 'bold',
        paddingLeft: 15,
        marginTop: 5,
    },
    name: {
        width: '80%',
    },
    type: {
        width: '20%',
    },
});

const PageSixDocumentsTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.name}>Name</Text>
        <Text style={styles.type}>Type</Text>
    </View>
);

export default PageSixDocumentsTableHeader;
