import { Popup } from "../../components/popup";
import { PopupContent, StyledCloseButton } from "./style";
import CloseIcon from "@material-ui/icons/Close";
import PledgxLogo from "assets/common/pledgxLogo";
import { MainDocPool } from "pages/documentPool/mainDocPool";

const DocumentPoolPopUp = ({
  openPopup,
  setOpenPopup,
  setSelectedFiles,
  selectedFiles,
  attachFile,
  resumeFile = false,
  institutionId,
}) => {
  const closeButton = (
    <StyledCloseButton aria-label="close" onClick={() => setOpenPopup(false)}>
      <CloseIcon />
    </StyledCloseButton>
  );

  const content = () => {
    return (
      <>
        {closeButton}
        <PopupContent>
          <MainDocPool
            docPoolPopUp={true}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            attachFile={attachFile}
            resumeFile={resumeFile}
            institutionId={institutionId}
          />
        </PopupContent>
      </>
    );
  };

  return (
    <Popup
      open={openPopup}
      dialogContent={content()}
      width={"950px"}
      height={"750px"}
      innerPadding={"0 24px 0 24px"}
    />
  );
};

export default DocumentPoolPopUp;
