import React, { useEffect } from "react";

import { PROVINCES } from "../../../constants";
import { emailRE } from "../../../common/regex";

import {
  Row,
  StyledMediumTitle,
} from "../styles";

import Input from "../../../components/inputField";
import Select from "../../../components/selectField";

import { LooksGoodButton } from "../components/looksGoodButton";


export const Panel0 = ({
  setPanel,
  qualificationData,
  setQualificationData,
  disabled,
  setDisabled,
}) => {
  const bankOptions = ["Bank of the Galactic Republic",
                       "Alterna Bank",
                       "B2b Bank",
                       "Bank Of Montreal",
                       "Bridgewater Bank",
                       "Canadian Imperial Bank Of Commerce (CIBC)",
                       "Cff Bank",
                       "Canadian Tire Bank",
                       "Citizens Bank Of Canada",
                       "Canadian Western Bank",
                       "Equitable Bank",
                       "First Nations Bank Of Canada",
                       "General Bank Of Canada",
                       "Hollis Canadian Bank",
                       "Homequity Bank",
                       "Laurentian Bank Of Canada",
                       "Manulife Bank Of Canada",
                       "National Bank Of Canada",
                       "President'S Choice Bank (PC Financial)",
                       "Rogers Bank",
                       "Royal Bank Of Canada",
                       "Scotiabank",
                       "Tangerine Bank",
                       "Toronto-Dominion Bank",
                       "UNI Coopération financière",
                       "Versabank",
                       "Zag Bank"];

  const bank = qualificationData.bank;

  useEffect(() => {
    if (
      bank?.name?.length > 0 &&
      bank?.address?.length > 0 &&
      bank?.zip_code?.length > 0 &&
      bank?.city?.length > 0 &&
      bank?.province?.length > 0 &&
      bank?.contact_person_firstname?.length > 0 &&
      bank?.contact_person_lastname?.length > 0 &&
      bank?.phone?.length > 0 &&
      emailRE.test(bank?.email)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  });

  return(
    <>
      <StyledMediumTitle>Which bank do you currently work with?</StyledMediumTitle>
      <Row>
        <Select
          label="Name"
          iconMarginRight="16px"
          style={{ paddingRight:"16px", width: "50%"}}
          options={bankOptions.map((item) => ({ value: item, label: item }))}
          value={bank?.name}
          setValue={(val) => setQualificationData('bank.name', val)}
          validateFunc={(item) => Boolean(item)}
        />
      </Row>
      <Row>
        <Input
          style={{  marginRight: "16px", width: "50%"}}
          label="Address*"
          type="text"
          placeholder="Address"
          value={bank?.address}
          setValue={(val) => setQualificationData('bank.address', val)}
          validateFunc={(item) => item.length > 0}
        />
        <Input
          style={{ marginRight: "16px", width: "25%"}}
          label="Suite number, etc."
          type="text"
          placeholder="Suite number, etc."
          value={bank?.suite_number}
          setValue={(val) => setQualificationData('bank.suite_number', val)}
          validateFunc={(item) => item.length > 0}
        />
        <Input
          style={{ marginRight: "16px", width: "25%"}}
          label="ZIP/ Postal Code*"
          type="text"
          placeholder="ZIP/ Postal Code"
          value={bank?.zip_code}
          setValue={(val) => setQualificationData('bank.zip_code', val)}
          validateFunc={(item) => item.length > 0}
        />
      </Row>
      <Row marginBottom="16px">
        <Input
          style={{  marginRight: "16px", width: "49%" }}
          label="City"
          type="text"
          placeholder="City"
          value={bank?.city}
          setValue={(val) => setQualificationData('bank.city', val)}
          validateFunc={(item) => item.length > 0}
        />
        <Select
          style={{ paddingRight: "16px", width: "51%" }}
          iconMarginRight="16px"
          label="Province"
          options={PROVINCES.map((item) => ({ value: item, label: item }))}
          value={bank?.province}
          setValue={(val) => setQualificationData('bank.province', val)}
          validateFunc={(item) => Boolean(item)}
        />
      </Row>
      <StyledMediumTitle> Who is the main contact?</StyledMediumTitle>
      <Row>
        <Input
          style={{  marginRight: "16px", width: "50%" }}
          label="First Name"
          type="text"
          placeholder="First Name"
          value={bank?.contact_person_firstname}
          setValue={(val) => setQualificationData('bank.contact_person_firstname', val)}
          validateFunc={(item) => item.length > 0}
        />
        <Input
          style={{  marginRight: "16px", width: "50%" }}
          label="Last Name"
          type="text"
          placeholder="Last Name"
          value={bank?.contact_person_lastname}
          setValue={(val) => setQualificationData('bank.contact_person_lastname', val)}
          validateFunc={(item) => item.length > 0}
        />
      </Row>
      <Row marginBottom="16px">
        <Input
          style={{  marginRight: "16px", width: "50%" }}
          label="Phone"
          type="text"
          placeholder="Company Phone"
          value={bank?.phone}
          setValue={(val) => setQualificationData('bank.phone', val)}
          validateFunc={(item) => item.length > 0}
        />
        <Input
          style={{  marginRight: "16px", width: "50%" }}
          label="Email"
          type="text"
          placeholder="Company Email"
          value={bank?.email}
          setValue={(val) => setQualificationData('bank.email', val)}
          validateFunc={(item) => {
            return emailRE.test(item);
          }}
        />
      </Row>
    </>
  );
};
