import moment from "moment";

function mapFields({ clientRfpq, serverRfpq }) {
  var mappedRfpq = {};

  if (serverRfpq.expiry) {
    var dateString1 = moment.utc(serverRfpq.expiry);
    // var dateString = new Date(serverRfpq.expiry);
    // const newDate = dateString.toLocaleString("en-US", { timeZone: "GMT" });
    // const expiryDate = new Date(Date.parse(newDate));

    mappedRfpq.closingDate = `${dateString1.format("YYYY-MM-DD")}`;
    mappedRfpq.closingTime = `${dateString1.format("HH:mm")}`;
    // mappedRfpq.closingTime = `${
    //   expiryDate.getHours() < 10 ? "0" : ""
    // }${expiryDate.getHours()}:${
    //   expiryDate.getMinutes() < 10 ? "0" : ""
    // }${expiryDate.getMinutes()}`;
  }
  if (serverRfpq.institution) {
    mappedRfpq.institutionId = serverRfpq?.institution?.institution_id;
    mappedRfpq.ownerName = serverRfpq?.institution?.name;
  }
  if (serverRfpq.files) {
    mappedRfpq.additionalDocuments = {
      selectedFiles: serverRfpq.files,
    };
  }
  if (serverRfpq.project) {
    if (serverRfpq.project.categories) {
      mappedRfpq.projectCategory = serverRfpq.project.categories;
    }
    if (serverRfpq.project.country) {
      mappedRfpq.projectCountry = { selectedData: serverRfpq.project.country };
    }
    if (serverRfpq.project.description) {
      mappedRfpq.projectDescription = serverRfpq.project.description;
    }
    if (serverRfpq.project.location) {
      mappedRfpq.projectCity = serverRfpq.project.location;
    }
    if (serverRfpq.project.location_description) {
      mappedRfpq.projectLocationDescription =
        serverRfpq.project.location_description;
    }
    if (serverRfpq.project.number) {
      mappedRfpq.projectNumber = serverRfpq.project.number;
    }
    if (serverRfpq.project.state) {
      mappedRfpq.projectState = serverRfpq.project.state;
    }
    if (serverRfpq.project.title) {
      mappedRfpq.projectTitle = serverRfpq.project.title;
    }
  }
  if (serverRfpq.requirements) {
    mappedRfpq.projectRequirements = {
      selectedData: serverRfpq.requirements,
    };
  }

  return mappedRfpq;
}

export default mapFields;
