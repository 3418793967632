import React from "react";
import { Popup } from "components/popup";
import CloseIcon from "@material-ui/icons/Close";

import PylonIcon from "assets/common/pylonIcon.png";

import {
  StyledCloseButton,
  PopupContent,
  PopupTitle,
  PopupBodyText,
  StyledButtonContainer,
  StyledMenuItem,
  StyledButton,
  StyledButtonReview,
} from "./styles";
import { useHistory } from "react-router";

export const AlertPopup = ({
  bodyContent,
  headerText,
  height = "434px",
  subHeaderText,
  openPopup,
  setOpenPopup,
  showIcon = true,
  width = "640px",
  showButton = false,
}) => {
  const closeButton = (
    <StyledCloseButton aria-label="close" onClick={() => setOpenPopup(false)}>
      <CloseIcon />
    </StyledCloseButton>
  );
  const history = useHistory();
  const handleGoToSubscribePage = () => {
    history.push("/subscribe");
  };
  const handleGoToDashboard = () => {
    history.push("/");
  };

  const icon = <img src={PylonIcon} />;

  const bodyText = (
    <>
      <PopupTitle>{headerText}</PopupTitle>
      <PopupBodyText>{subHeaderText}</PopupBodyText>
      {showButton && (
        <StyledButtonContainer>
          <StyledMenuItem>
            <StyledButton
              title="Go Back"
              width="230px"
              onClick={handleGoToDashboard}
            >
              Go Back
            </StyledButton>
          </StyledMenuItem>

          <StyledMenuItem>
            <StyledButtonReview
              title="Upgrade"
              width="230px"
              onClick={handleGoToSubscribePage}
            >
              UPGRADE
            </StyledButtonReview>
          </StyledMenuItem>
        </StyledButtonContainer>
      )}
    </>
  );

  const content = () => {
    return (
      <>
        {closeButton}
        <PopupContent>
          {showIcon && icon}
          {bodyContent || bodyText}
        </PopupContent>
      </>
    );
  };

  return (
    <Popup
      open={openPopup}
      dialogContent={content()}
      width={width}
      height={height}
    />
  );
};
