import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PageFourSafetyRecordsTableRow from './pageFourSafetyRecordsTableRow';
import PageFourSafetyRecordsTableHeader from './pageFourSafetyRecordsTableHeader';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 10,
        justifyContent: 'flex-start',
        width: '80%',
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30,
    },
    safetyRecords: {
        marginTop: -30,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
    },
    viewContainer: {
        borderRadius: 6,
        backgroundColor: '#F8F8F8',
        width: 537,
        paddingTop: 2,
        paddingBottom: 3,
    },
});

const PageFourSafetyRecords = ({ prequalifyData }) => (
    <View style={styles.headerContainer}>
        <Text style={styles.safetyRecords}>Safety Records</Text>
        <View style={styles.viewContainer}>
            <PageFourSafetyRecordsTableHeader />
            <PageFourSafetyRecordsTableRow items={prequalifyData?.data?.safetyRecordsForPdf} />
        </View>

    </View>
);

export default PageFourSafetyRecords;
