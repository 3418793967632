import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from "../../../constants";
import Divider from "@material-ui/core/Divider";
import { Typography } from "@material-ui/core";
import { StyledTableCell } from "../../../components/filesListTable/styles";

export const StyledDivider = styled(Divider)`
  && {
    margin-right: 24px;
    margin-left: 24px;
  }
`;

export const StyledProjectSelectionContainer = styled.div`
  ${(props) => (props.isMobile ? "margin-top: 16px;" : "")}
`;

export const DetailContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

export const CardDiv = styled.div`
  width: ${(props) => props.width || "25%"};
  margin-right: 20px;
  ${(props) => (props.isMobile ? "flex: 0 0 auto;" : "")}
`;

export const PersonnelContent = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  margin: ${(props) => props.margin || "28px"};
  ${(props) =>
    !props.direction || props.direction == "row" ? "align-items: center" : ""};
  ${(props) => (props.height ? `height: ${props.height};` : "")}
  ${(props) =>
    props.justifyContent ? `justify-content: ${props.justifyContent};` : ""}
`;

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-left: 16px;
`;

export const DetailArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const StyledSearchContainer = styled.div`
  display: inline-block;
  width: ${(props) => props.width || "60%"};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "20px")};
`;

export const StyledSearch = styled.input`
  && {
    height: 48px;
    width: ${(props) => props.searchWidth || "860px"};
    font-family: Times New Roman;

    .prompt {
      border-radius: 5px;
      border-color: ${COLORS.darkGrey3};
    }

    &&:focus {
      border-radius: 5px;
      border-color: ${COLORS.darkGrey3};
    }
  }
`;

export const StyledSearchIcon = styled.img`
  position: absolute;
  margin-top: 12px;
  margin-left: 8px;
  height: 24px;
`;

export const StyledCancelIcon = styled.img`
  position: absolute;
  margin-top: 17px;
  margin-left: 830px;
  height: 16px;
`;

export const HeaderArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: ${(props) => props.width || "100%"};
`;

export const ProfilePic = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ProgressInCard = styled.div`
  margin-top: 16px;
  margin-right: -48px;
`;

export const TouchScrollDiv = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  margin-top: 20px;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const MobileColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MobileColumnText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: ${(props) => props.fontSize || "13px"};
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || "500"} !important;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: ${COLORS.columnGreyText};
    width: 100%;
  }
`;

export const SubHeading = styled(Typography)`
  && {
    font-family: SF Pro Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    color: ${COLORS.lightGrey2};
    margin-top: ${(props) => (props.isMobile ? "16px" : "-4px")};
    ${(props) => (props.isMobile ? "" : "margin-bottom: 8px;")}
  }
`;

export const StyledTableCellStatus = styled(StyledTableCell)`
  ${(props) => (props.isMobile ? "width: 20%;" : "")}
`;

export const StyledDownloadedContainer = styled.div`
  padding: 0 10px;
  background-color: ${COLORS.lightGreen};
  border-radius: 20px;
  color: ${COLORS.primaryGreen};
  text-align: center;
`;

export const StyledDraftContainer = styled.div`
  padding: 0 10px;
  background-color: ${COLORS.lightYellow};
  border-radius: 20px;
  color: ${COLORS.primaryYellow};
  text-align: center;
`;

export const StyledReviewedContainer = styled.div`
  padding: 0 10px;
  background-color: ${COLORS.lightBlue};
  border-radius: 20px;
  color: ${COLORS.primaryBlue};
  text-align: center;
`;

export const StyledUnderwayContainer = styled.div`
  padding: 0 10px;
  background-color: ${COLORS.lightYellow};
  border-radius: 20px;
  color: ${COLORS.primaryYellow};
  text-align: center;
`;

export const StyledCompletedContainer = styled.div`
  padding: 0 10px;
  background-color: ${COLORS.lightGreen};
  border-radius: 20px;
  color: ${COLORS.primaryGreen};
  text-align: center;
`;
