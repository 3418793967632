import React from 'react';
import { COLORS, FONT_SIZES } from '../../constants';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ReleaseNote } from './ReleaseNote';

import Spinner from "../spinner";
import classes from './ReleaseNotes.module.css';

export const ReleaseNotesBuilder =({ loading, releaseNotes }) => {
  if (loading || !releaseNotes) {
    return(
      <div className={ classes['loading-container'] }>
        <Spinner />
      </div>
    );
  } else {
    return(
      <div className={ classes.container }>
        {
          releaseNotes.map((releaseNote, index) => (
            <ReleaseNote index={ index } releaseNote={ releaseNote } />
          ))
        }
      </div>
    );
  };
};
