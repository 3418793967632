import { StyledSubtext } from "pages/loginPage/styles";
import { StyledEditRecordButton } from "pages/contractorDashboard/profileSetup/styles.js";
import { RadioButton } from "components/radioButton";
import CheckedRadioButton from "assets/dashboardIcons/checkedRadioButton";
import UncheckedRadioButton from "assets/dashboardIcons/uncheckedRadioButton";
import { SectionLabelText } from "pages/adminToolsDashboard/styles.js";

const RadioGroupButtonForProjectCategory = ({
  containsUNSPC,
  confirmRadioChange,
}) => {
  return (
    <>
      <SectionLabelText>Pick the suitable business type:</SectionLabelText>
      <StyledEditRecordButton>
        {containsUNSPC === "" ?
          <RadioButton
            isChecked={""}
            value={containsUNSPC}
            checkedIcon={<CheckedRadioButton />}
            unCheckedIcon={<UncheckedRadioButton />}
            handleChange={() => confirmRadioChange(1)}
          />
          :
          <RadioButton
            isChecked={!containsUNSPC}
            value={containsUNSPC}
            checkedIcon={<CheckedRadioButton />}
            unCheckedIcon={<UncheckedRadioButton />}
            handleChange={() => confirmRadioChange(1)}
          />
        }
        <StyledSubtext style={{ marginTop: "0px" }}> Standard</StyledSubtext>
        <RadioButton
          isChecked={containsUNSPC}
          value={!containsUNSPC}
          checkedIcon={<CheckedRadioButton />}
          unCheckedIcon={<UncheckedRadioButton />}
          handleChange={() => confirmRadioChange(0)}
        />
        <StyledSubtext style={{ marginTop: "0px" }}> UNSPC</StyledSubtext>
      </StyledEditRecordButton>
    </>
  );
};

export default RadioGroupButtonForProjectCategory;
