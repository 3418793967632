import { TYPOGRAPHY } from "../../../constants";
import styled from "styled-components";


export const StyleButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NoProjects = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: ${TYPOGRAPHY.primaryFont};
  font-weight: 500;
  font-size: 15px;
  width: 100%;
  margin-top: 16px;
`;
