import React from "react";

import {
  CloseButton,
  FormTitle,
  InfoArea,
  StyledPaper
} from "./styles";

import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import PledgxLogo from "../../assets/common/pledgxLogo";


const ShowImagePopup = ({
  setOpenPopup,
  openPopup,
  isMobile,
  label,
  imageId
}) => {

  const handleClose = () => {
    setOpenPopup(false);
  };


  return (
    <Dialog
      open={openPopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <StyledPaper elevation={0} isMobile={isMobile}>
        <CloseButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <PledgxLogo width={isMobile ? 45 : ""} height={isMobile ? 45 : ""} />
        <FormTitle style={{ marginTop: 10 }}>
          {label || "Screen Shot"}
        </FormTitle>

        <InfoArea isMobile={isMobile} marginTop="10px">
          <img src={`data:image/png;base64,${imageId}`} height={300} width={550} />
        </InfoArea>

      </StyledPaper>
    </Dialog>
  );
};

export default ShowImagePopup;

