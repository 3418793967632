import React from "react";

import { Container, Logo } from "./styles";
import logo_dark from "../../assets/common/full-logo.svg";

function Error(props) {
  function retry() {
    if (props && props.retry) {
      props.retry();
    };
  };
  
  return (
    <Container>
      <Logo src={logo_dark} />
      <div>We're sorry. An error occurred.</div>
      <div onClick={ () => retry() }>
        { (props && props.retry_button_message) ? props.retry_button_message : 'Retry' }
      </div>
    </Container>
  );
};

export default Error;
