import React, { useState } from "react";

import { 
  ContractInfo,
  CommentCard,
  TitleText, 

} from './styles';

import { MultilineTextField } from 'components/multilineTextField';

export const Comment = ({
  data,
  onChangeAnswer
}) => {

  const commentList = [
    "Very Pleasant to work with!",
    "Work was done well and on time!",
    "Professional and easy to communicate with!"
  ]
  
  const [pickedCommentIndex, setPickedCommentIndex] = useState('')

  const handlePick = (val, i) => {
    
    if (i === pickedCommentIndex){
      setPickedCommentIndex('');
      onChangeAnswer('assessment.formatted_comment', '')
    } else {
      setPickedCommentIndex(i);
      onChangeAnswer('assessment.formatted_comment', val)
    }
  }

  return (
    <>
      {/* <ContractInfo flexDirection="row" justifyContent="space-between">
      {commentList.map((val,i) => {
        return(
          <CommentCard onClick={() => handlePick(val,i)} isPicked={i === pickedCommentIndex}>{val}</CommentCard>
        )
      })}
      </ContractInfo> */}
      <MultilineTextField
        placeholder="Comment"
        row="7"
        width="100%"
        margin="16px 0 0"
        value={data?.assessment?.comment}
        setValue={(e) => onChangeAnswer('assessment.comment', e.target.value)}
        noBorderColor={true}
      />
    </>
  
  )
}