import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";

import { GoBackButton } from "pages/buyers/components/goBackButton";
import { clientProjectsWipSelector } from "data/selectors/underwriters";
import { PageHeader } from "./components/pageHeader";
import { StyledContainer, StyledMainContainer } from "./styles";

import {
  StyledSubHeaderContent,
  StyledBox,
  PlaceholderText,
} from "./components/styles";
import { AllProjectsTable } from "./components/allProjectsTable";

import LeftComponent from "./components/leftComponent";
import RightComponent from "./components/rightComponent";
import { getLimitValues, getLimitValuesUnderwriter } from "api/work_in_progress";
import { getClientProjectsInfoForWip, getClientProjectsInfoForWipUnderwriter } from "data/actions/underwriters";
import { getFirstPpcObject } from "./components/commonfunction";
import { getSingleProject } from "api/prequalify";
import { AddProjectPopup } from "components/addProjectPopup";
import { saveProject } from "api/dashboard";

const WorkInProgress = () => {
  const isMobile = useMediaQuery({ maxWidth: 453 });
  const location = useLocation();
  const dispatch = useDispatch();
  const [estimatedCostOfRevenue, setEstimatedCostOfRevenue] = useState(0);

  const [totalcostIncurredToDate, setTotalCostIncurredToDate] = useState(0);
  const companyName = location.state?.company_name || "";
  const accountId = location.state?.account_id;
  const clientProjects = useSelector(clientProjectsWipSelector);
  let history = useHistory();
  const [data, setData] = useState([]);
  //asdf
  const [project, setProject] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  
  const [limitValues, setLimitValues] = useState({});

  console.log("accountId", accountId)
  useEffect(() => {
    const isUnderwriterWip = location.pathname.includes("underwriter/client/wip");

    if (isUnderwriterWip) {
      dispatch(
        getClientProjectsInfoForWipUnderwriter({ account_id: accountId }, "underway")
      );

      getLimitValuesUnderwriter(
        { account_id: accountId },
        (res) => {
          setLimitValues(res);
        },
        () => {}
      );
    } else {
      dispatch(
        getClientProjectsInfoForWip({ account_id: accountId }, "underway")
      );

      getLimitValues(
        { account_id: accountId },
        (res) => {
          setLimitValues(res);
        },
        () => {}
      );
    }
  }, []);


  
  useEffect(() => {
    if (clientProjects) {
      setData(clientProjects);
    }
  }, [clientProjects]);
  const handleGoBack = () => {
    history.push("/underwriter-dashboard");
  };

  useEffect(() => {
    const totalEstimatedCostOfRevenue = data?.reduce((total, obj) => {
      // add  original value of the main object
      let costOfRevenue = 0;
      if (obj?.wip_data?.length > 0) {
        // Assuming the last item in wip_data contains the most recent estimate
        const latestWipData = obj?.wip_data[obj?.wip_data?.length - 1];
        costOfRevenue = parseFloat(latestWipData?.estimated_cost_of_revenue);
      } else {
        costOfRevenue =
          parseFloat(obj?.original_estimated_cost_of_revenue) || 0;
      }
      // To minimize floating-point precision issues, keep track of totals in a higher precision
      total += costOfRevenue;

      return total;
    }, 0);

    const total_cost_incurred_to_date = data?.reduce((total, item) => {
      const firstPPCDataObject = getFirstPpcObject(item);

      const costForItem =
        parseFloat(firstPPCDataObject?.ppc_data?.cost_incurred_to_date) || 0;

      return total + costForItem;
    }, 0);

    setTotalCostIncurredToDate(total_cost_incurred_to_date);
    setEstimatedCostOfRevenue(Math.round(totalEstimatedCostOfRevenue));
  }, [data]);
  const editProject = (id) => {
    getSingleProject(id, onSuccessEdit, () => {});
  };
  const onSuccessEdit = (res) => {
    setProject(res);
    setOpenPopup(true);
  };
  const addProject = (data) => {
    let newProps = { ...data };
    if (accountId) {
      newProps = { ...newProps, institution_id: accountId };
    }
    saveProject(newProps, onSuccess, () => {});
  };
  const onSuccess = () => {
    const isUnderwriterWip = location.pathname.includes("underwriter/client/wip");

    if (isUnderwriterWip) {
      dispatch(
        getClientProjectsInfoForWipUnderwriter({ account_id: accountId }, "underway")
      );
    } else {
      dispatch(
        getClientProjectsInfoForWip({ account_id: accountId }, "underway")
      );
    }
    setProject("");
   
  };
  return (
    <StyledContainer isMobile={isMobile}>
      <GoBackButton />
      <PageHeader
        subTitle="Work in Progress Schedule"
        companyName={companyName}
        hasButton={true}
        companyId={accountId}
        // handleButton={handleStartNew}
      />

      <div
        style={{
          height: "10px",
          width: "80%",
          marginTop: "0px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <a
          href="/wip-template-upload.xlsx"
          download="wip-template-upload.xlsx"
          style={{
            textDecoration: "none",
            color: "#FF6D1D",
          }}
        >
          {"Download Template"}
        </a>
      </div>

      <StyledMainContainer>
        <LeftComponent
          data={limitValues}
          accountId={accountId}
          totalCostIncurredToDate={totalcostIncurredToDate}
          totalEstimatedCostOfRevenue={estimatedCostOfRevenue}
        />
        <RightComponent
          data={data}
          totalCostIncurredToDate={totalcostIncurredToDate}
          totalEstimatedCostOfRevenue={estimatedCostOfRevenue}
        />
      </StyledMainContainer>
      <div
        style={{
          height: "300px",
          width: "100%",
          marginTop: "30px",
        }}
      >
        <StyledSubHeaderContent>List of Projects</StyledSubHeaderContent>
        {!(data?.length > 0) && (
          <StyledBox boxHeight="80%" borderRadius="10px" margin="10px 0px">
            <PlaceholderText>
              Underway Projects will appear here
            </PlaceholderText>
          </StyledBox>
        )}
        {data?.length > 0 && (
          <AllProjectsTable
            data={data}
            setData={setData}
            clientCompanyName={companyName}
            companyId={accountId}
            editProject={editProject}
          />
        )}
      </div>
      <AddProjectPopup
        setOpenPopup={setOpenPopup}
        openPopup={openPopup}
        data={project}
        setData={setProject}
        handleButtonFunc={addProject}
        isMobile={isMobile}
        institution_id={accountId}
      />
    </StyledContainer>
  );
};

export default WorkInProgress;
