import React from 'react';

const Step2 = ({ active, completed }) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2222:16210)">
    <g filter="url(#filter0_d_2222:16210)">
    <circle cx="16" cy="16" r="16" fill="white"/>
    </g>
    <circle cx="16" cy="16" r="13" fill={active || completed ? "#FF6D1D" : "white"} stroke="#ECEBED"/>
    </g>
    <path d="M12.0273 14.0547H13.6816C13.6816 13.0156 14.3652 12.3047 15.4385 12.3047C16.4707 12.3047 17.0859 12.9951 17.0859 13.8906C17.0859 14.6426 16.7852 15.1143 15.541 16.3721L12.123 19.8105V21H18.9727V19.5371H14.5156V19.4209L16.6348 17.3359C18.3301 15.6748 18.8291 14.875 18.8291 13.7266C18.8291 12.0859 17.4961 10.8896 15.5342 10.8896C13.4766 10.8896 12.0273 12.1885 12.0273 14.0547Z" fill={active || completed ? "white" : "#D0C9D6"}/>
    <defs>
    <filter id="filter0_d_2222:16210" x="-64" y="-57" width="160" height="160" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="7"/>
    <feGaussianBlur stdDeviation="32"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2222:16210"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2222:16210" result="shape"/>
    </filter>
    <clipPath id="clip0_2222:16210">
    <rect width="32" height="32" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
};

export default Step2;

