import styled from "styled-components";
import { FONT_SIZES, COLORS } from "../../constants";

export const PackageItemContainer = styled.div`
  flex: 1;
  width: 250px;
  min-width: 250px;
  position: relative;

  display: flex;
  flex-direction: column;

  &&:not(:last-child) {
    margin-right: 30px;
  }
`;

export const PackageItemTopDecoration = styled.div`
  font-size: ${FONT_SIZES.body_4};
  color: ${COLORS.primaryOrange};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
`;

export const PackageItemBox = styled.div`
  flex: 1;
  padding: 50px 20px 50px 20px;
  min-height: 400px;
  background-color: ${(props) =>
    props.loading
      ? COLORS.darkGrey3
      : props.selected && !props.pricesLoading
      ? COLORS.primaryOrange
      : COLORS.lighOrange};
  ${(props) =>
    props.loading
      ? "color: transparent"
      : props.selected && !props.pricesLoading
      ? "color: white"
      : ""};
  border-radius: 10px;
  border: 1.5px solid
    ${(props) =>
      props.loading
        ? COLORS.darkGrey3
        : !props.pricesLoading && (props.selected || props.recommended)
        ? COLORS.borderOrange
        : COLORS.lighOrange};
  position: relative;

  display: flex;
  flex-direction: column;
`;

export const PackageItemBoxRow = styled.div`
  margin-top: ${(props) => (props.noTopMargin ? "0" : "50px")};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PackageItemBoxTitle = styled.span`
  font-size: ${FONT_SIZES.body_4};
  font-weight: 700;
`;

export const PackageItemBoxPriceContainer = styled.div`
  height: 50px;
  display: flex;
`;
export const PackageItemBoxPrice = styled.div`
  font-size: ${(props) =>
    props.secondary ? FONT_SIZES.body_2 : FONT_SIZES.heading_3};
  line-height: ${(props) => (props.secondary ? "40px" : "normal")};
  font-weight: 500;
  display: flex;
  align-items: ${(props) => (props.secondary ? "flex-end" : "center")};
`;

export const PackageItemBoxPriceTotal = styled.span`
  font-size: ${FONT_SIZES.body_5};
  line-height: normal;
  height: 15px;
`;

export const PackageItemBoxDescription = styled.span`
  text-align: center;
  font-size: ${FONT_SIZES.body_5};
  ${(props) => (props.clickable ? `color: ${COLORS.primaryOrange};` : "")}
  ${(props) => (props.clickable ? "cursor: pointer" : "")};
  ${(props) => (props.minHeight ? `min-height: ${props.minHeight}px;` : "")}
`;

export const PackageItemBoxFeature = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${FONT_SIZES.body_5};
  line-height: normal;
  padding: 4px 0;
  border-top: 0.25px solid;
  &&:last-child {
    border-bottom: 0.25px solid;
  }
`;

export const PackageItemBoxInteractionContainer = styled.div`
  margin-top: auto;
  padding-top: 50px;
  width: 100%;
  height: 100px;
`;

export const PackageItemBoxCancelContainer = styled.span`
  margin-top: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PackageItemBoxCancelText = styled.span`
  font-size: ${FONT_SIZES.body_5};
  cursor: ${(props) => (props.expired ? "default" : "pointer")};
  ${(props) =>
    props.expired
      ? props.selected
        ? `color: white;`
        : `color: ${COLORS.primaryOrange}`
      : ""}
`;
export const PackageItemBoxFreeTrialText = styled.span`
  font-size: ${FONT_SIZES.body_6};
  height: auto;
  text-align: center;
`;
