import styled from "styled-components";
import { COLORS, TYPOGRAPHY } from "../../../../constants";
import Typography from '@material-ui/core/Typography';


export const StyledQuestion = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.displayFont};
    font-weight: ${props => props.weight? props.weight : "500"};
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    margin-bottom: ${props => props.marginBottom? props.marginBottom : "16px"};
    width: ${props => props.isMobile? "100%" : props.width};
  }
`;


export const InfoArea = styled.div`
  margin-top: ${(props) => props.marginTop || "24px"};
  margin-bottom: ${(props) => props.marginBottom || ""};
  display: flex;
  flex-direction: ${props => props.isMobile || props.isColumn ? "column" : "row"};
  justify-content: center;
  align-items: center;
  ${props => props.width && !props.isMobile ? `width: ${props.width};` : "width: 90%"}

  .inputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '50%'};
  }

  .fullWidthInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: 100%;
  }

  .oneFifthInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '20%'};
  }

  .thirtyInputWrap {
    padding-right: ${props => props.isMobile ? '0' : '16px'};
    width: ${props => props.isMobile ? '100%' : '30%'};
  }
`;

export const StyledAddressContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  gap: 16px;
`;