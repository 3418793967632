import React from 'react';
import { Text, View, StyleSheet, Image, TextSpan } from '@react-pdf/renderer';
import HealthUnseleted from "./images/healthUnseleted.png"
import HealthSeleted from "./images/healthSeleted.png"
import SquareSelected from "./images/squareSelected.png"
import SquareUnselected from "./images/squareUnselected.png"

const styles = StyleSheet.create({
    headerContainer1: {
        marginTop: 0,
        justifyContent: 'flex-start',
        width: '60%',
        paddingLeft: 30,
        paddingRight: 0,
    },
    headerContainer2: {
        marginTop: 30,
        width: '40%',
        flex: 1,
        paddingLeft: 10,
        paddingRight: 30,
    },
    healthSafety: {
        marginTop: -10,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
    },
    healthSafetyWithoutLogo: {
        marginTop: 23,
        paddingBottom: 3,
        fontFamily: 'Helvetica',
        fontSize: 16,
        color: '#F66616',
        position:'relative',
    },
    remarks: {
        paddingBottom: 6,
        fontSize: 10,
        color: '#888888',
        position: 'relative',
    },
    spanText: {
        color: '#000000',
        marginTop: 0,
    },
    superscript: {
        fontSize: 6,
        top: -12,
        position: 'absolute',
        lineHeight: 1,
    },
});

const Superscript = ({ children }) => (
    <Text style={styles.superscript}>{children}</Text>
);


const PageFourHealthSafety = ({ prequalifyData, companyLogo }) => (
    <View style={{ display: 'flex', flexDirection: 'row' }}>
        <View style={styles.headerContainer1}>
        {companyLogo !== ""  ? <Text style={styles.healthSafety}>Health & Safety</Text> : <Text style={styles.healthSafetyWithoutLogo}>Health & Safety</Text> }
            <Text>Certificate of Recognition program (CORS<Superscript></Superscript>)?</Text>
            <Text>
                {prequalifyData?.data?.corsRadioButtonYes === true ?
                    <Image src={HealthSeleted} />
                    :
                    <Image src={HealthUnseleted} />

                }
                <span style={styles.spanText}>  Yes</span>
            </Text>

            <Text>
                {prequalifyData?.data?.corsRadioButtonNo === true ?
                    <Image src={HealthSeleted} />
                    :
                    <Image src={HealthUnseleted} />

                }
                <span style={styles.spanText}>  No</span>
            </Text>

            <Text style={{ marginLeft: 15 }}>
                {prequalifyData?.data?.corsRadioButtonNo === true && prequalifyData?.data?.corsRadioButtonEquivalentToCor === true ?
                    <Image src={SquareSelected} />
                    :
                    <Image src={SquareUnselected} />
                }
                <span style={styles.spanText}>  Equivalent to CORS</span>
            </Text>

            <Text style={{ marginLeft: 15 }}>
                {prequalifyData?.data?.corsRadioButtonNo === true && prequalifyData?.data?.corsRadioButtonCoveredByOther === true ?
                    < Image src={SquareSelected} />
                    :
                    <Image src={SquareUnselected} />
                }
                <span style={styles.spanText}>  Covered by Health & Safety plan</span>
            </Text>

        </View>

        {prequalifyData?.data?.corsRadioButtonNo === true &&
            <View style={styles.headerContainer2}>
                <Text style={styles.healthSafety}></Text>
                <Text style={styles.remarks}>Remarks:</Text>
                <Text>
                    {prequalifyData?.data?.corsRemark}
                </Text>
            </View>
        }
    </View>
);

export default PageFourHealthSafety;
