import React from 'react';
import location from './location_color.svg'

const Location_color = ({ isMobile }) => {
  return (
    <img width={isMobile ? "36" : "56"} height={isMobile ? "36" : "56"} src={location} />
  )
};

export default Location_color;
