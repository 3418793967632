import React from "react";
import { StyledIconButton } from './styles';
import BackIcon from "../../assets/common/backIcon"; 

export const BackIconButton = ({ handleClick, margin }) => {

  return (
    <>
      <StyledIconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        margin={margin}
      >
        <BackIcon />
      </StyledIconButton>
    </>
  )
}
