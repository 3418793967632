import React from 'react';
import { Text, View, Image, StyleSheet, Line, Link } from '@react-pdf/renderer';
import LogoImage from "pages/pledgxOutputPDF/images/pledgx_logo.png";

const styles = StyleSheet.create({
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#FFFFFF',
        padding: 10,
        fontSize: 12,
    },
    image: {
        height: 25,
        width: 76,
        marginTop: -5
    },
    lineDividerBeforeFooter: {
        height: 1,
        width: 537,
        backgroundColor: '#F66616',
        marginLeft: 20,
        marginRight: 20,
        bottom: 25,
    },
    pageNumbers: {
        position: 'absolute',
        bottom: 3,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: 8,
        color: '#1A051D',
        paddingTop: 20,
    },
});

const PdfFooter = () => {
    const href = "https://pledgx.com"
    return (
        <div style={styles.footer}>
            <Line style={styles.lineDividerBeforeFooter} vertical={false} />
            <View style={styles.footer}>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ marginLeft: 25, fontSize: 8 }}>{new Date().toLocaleDateString()}</Text>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <Text style={{ fontSize: 12, paddingTop: 3, paddingLeft: 350, color: '#1A051D' }}>Powered by </Text>
                        <Link href={href}>
                            <Image src={LogoImage} style={{ marginTop: -5, height: 25, width: 75, marginRight: 5}} />
                        </Link>
                    </View>
                    <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} />
                </View>
            </View>
        </div>
    );
};

export default PdfFooter;