import React, { useState } from 'react';
import { StyledFilledAccordion } from './styles';
import { COLORS } from '../../constants';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import PlusSignIcon from '../../assets/buyers/plusSignIcon.js';
import MinusSignIcon from '../../assets/buyers/minusSignIcon.js';

const FilledAccordion = ({ index, header, details }) => {
  const [ expanded, setExpanded ] = useState(false);

  const handleOnChange = () => {
    setExpanded(prevState => !prevState);
  };

  return (
    <StyledFilledAccordion 
      expanded={expanded} 
      onChange={handleOnChange}
      square={false}
    >
      <AccordionSummary
        expandIcon={expanded ? 
                    <MinusSignIcon fill={COLORS.primaryOrange} height={18} /> : 
                    <PlusSignIcon fill={COLORS.primaryOrange} height={18} />}
        aria-controls={`${index}-content`}
        id={index}
      >
        <Typography>{header}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {details}
      </AccordionDetails>
    </StyledFilledAccordion>
  );
};

export default FilledAccordion;
