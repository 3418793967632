import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";

import AddRoundedIcon from "@material-ui/icons/AddRounded";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";

export const Accordion = withStyles({
  root: {
    marginTop: 5,
    marginBottom: 5,
    borderRadius: "6px",

    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const __AccordionSummary = withStyles({
  root: {
    display: "flex",
    padding: "0px 10px",

    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 15,

    color: "#000000",
    background: "#F9F7F7",
    borderRadius: "6px",
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    alignItems: "center",
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export function AccordionSummary({ expanded, error, title }) {
  return (
    <__AccordionSummary>
      {expanded ? (
        <RemoveRoundedIcon
          style={{
            width: 30,
            height: 30,
            color: "#FF6D1D",
            marginRight: 5,
          }}
        ></RemoveRoundedIcon>
      ) : (
        <AddRoundedIcon
          style={{
            width: 30,
            height: 30,
            color: "#FF6D1D",
            marginRight: 5,
          }}
        ></AddRoundedIcon>
      )}
      <span style={{ flexGrow: 1 }}>{title}</span>
      {error ? (
        <ErrorOutlineRoundedIcon
          fontSize="small"
          style={{ color: "#FF692E" }}
        />
      ) : (
        <DoneRoundedIcon fontSize="small" style={{ color: "#02814E" }} />
      )}
    </__AccordionSummary>
  );
}

export const AccordionDetails = withStyles((theme) => ({
  root: {
    flexDirection: "column",
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);
