import React from "react";

export const FlagIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="72"
      viewBox="0 0 24 72"
      fill="none"
    >
      <g filter="url(#filter0_d_815_7209)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 6C6 4.89543 5.10457 4 4 4C2.89543 4 2 4.89543 2 6V8.125C2 9.22957 2.89543 10.125 4 10.125C5.10457 10.125 6 9.22957 6 8.125V6ZM6 20.875C6 19.7704 5.10457 18.875 4 18.875C2.89543 18.875 2 19.7704 2 20.875V25.125C2 26.2296 2.89543 27.125 4 27.125C5.10457 27.125 6 26.2296 6 25.125V20.875ZM6 37.875C6 36.7704 5.10457 35.875 4 35.875C2.89543 35.875 2 36.7704 2 37.875L2 42.125C2 43.2296 2.89543 44.125 4 44.125C5.10457 44.125 6 43.2296 6 42.125L6 37.875ZM6 54.875C6 53.7704 5.10457 52.875 4 52.875C2.89543 52.875 2 53.7704 2 54.875L2 57C2 57.2788 2.05707 57.5444 2.16016 57.7855V65.8723C2.16016 66.7106 2.83975 67.3902 3.67808 67.3902H14.2529C15.7304 67.3902 16.7322 67.0144 17.056 66.3326C17.39 65.6421 16.9953 64.7681 15.9429 63.8591L14.7285 62.8102C14.3946 62.5218 14.2125 62.1285 14.2327 61.7352C14.2344 61.5647 14.2776 61.3964 14.3596 61.2413C14.4415 61.0862 14.5603 60.9477 14.7083 60.8349L15.9429 59.7686C16.9751 58.8683 17.3596 58.003 17.0257 57.3125C16.6917 56.622 15.7101 56.2374 14.2529 56.2374H6L6 54.875Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_815_7209"
          x="0"
          y="0"
          width="23.1716"
          height="71.3902"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_815_7209"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_815_7209"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
