import styled from "styled-components";
import StepLabel from "@material-ui/core/StepLabel";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import Stepper from "@material-ui/core/Stepper";


export const StyledStepLabel = styled(StepLabel)`
  &&{
    .MuiStepLabel-iconContainer{
      padding: 0;
    }

    cursor: pointer;
  }

  &&.MuiStepLabel-root.Mui-disabled{
    cursor: pointer;
  }
`;

export const StyledStep = styled(Step)`
  &&{
    padding: 0;
  }
`;

export const StyledStepper = styled(Stepper)`
  &&{
    background: transparent;
    align-self: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const StyledStepConnector = styled(StepConnector)`
  &&{
    .MuiStepConnector-lineHorizontal{
      border-top-width: 4px;
      width: 90px;
    }

    .MuiStepConnector-line{
      border-color: #ECE9F1;
    }
  }

  &&.MuiStepConnector-active {
    .MuiStepConnector-line {
      border-color: #FFDECC;
    }
  }

  &&.MuiStepConnector-completed {
    .MuiStepConnector-line {
      border-color: #FFDECC;
    }
  }
`;
