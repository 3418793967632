import React, { useState, useEffect } from "react";
import { InfoArea } from "../styles";
import Select from "../../../../components/selectField";
import XIcon from "assets/buyers/xIcon";
import IconButton from "@material-ui/core/IconButton";
import { SelectingTypeOptions } from "components/addFinancialPopup/remainingTypes";

export const InsuranceTypeAndNamePick = ({
  insuranceType,
  index,
  object,
  setObject,
  filteredData,
  removeInsuranceType,
}) => {
  const [insuranceCompanyList, setInsuranceCompanyList] = useState([]);

  const [remainingType, setRemainingType] = useState([]);
  const [disabled, setDisable] = useState(true);
  const currentState = object?.state[index];
  //   useEffect(() => {
  //     setInsuranceCompanyList(filteredData);
  //   }, [filteredData]);
  useEffect(() => {
    const type = insuranceType?.find(
      (value) => value.short_form === currentState.type
    )?.type;
    const temp = filteredData.filter((data) => {
      if (data.insuranceDetails && data.insuranceDetails.state) {
        const stateKeys = Object.keys(data.insuranceDetails.state);
        for (const key of stateKeys) {
          if (data.insuranceDetails.state[key].type === type) {
            return true;
          }
        }
      }
      return false;
    });

    setInsuranceCompanyList(temp);

    if (currentState.type !== null) {
      setDisable(false);
    } else {
      setDisable(true);
    }

    setRemainingType(SelectingTypeOptions(object, insuranceType));
  }, [object, filteredData]);

  const handleTypeUpdate = (value, index) => {
    object.state[index].type = value;
    setObject({ ...object });
  };

  const handleDataUpdate = (value, index) => {
    const insuranceCompany = filteredData.find((item) => item.ref_id === value);
    object.state[index].data = {
      //institution_id: value,
      ...insuranceCompany,
    };
    setObject({ ...object });
  };
  return (
    <div style={{ width: "100%", display: "flex" }}>
      <InfoArea marginTop="0px" width="50%" style={{ marginRight: "20px" }}>
        <Select
          //style={{ marginRight: "16px" }}
          label="Insurance Type"
          options={remainingType.map((item) => ({
            value: item.short_form,
            label: item.type,
          }))}
          value={
            insuranceType.find(
              (value) => value.short_form === object?.state[index]?.type
            )?.type
          }
          setValue={(val) => handleTypeUpdate(val, index)}
          validateFunc={(item) => Boolean(item)}
          noCheckIcon={true}
          showDropdownIndicator={true}
        />
      </InfoArea>
      <InfoArea marginTop="0px" width="50%">
        <Select
          //style={{ marginRight: "16px" }}
          label=" Company Name"
          options={insuranceCompanyList.map((item) => ({
            value: item.ref_id,
            label: item.company_name,
          }))}
          value={object?.state[index]?.data?.company_name}
          setValue={(val) => handleDataUpdate(val, index)}
          validateFunc={(item) => Boolean(item)}
          noCheckIcon={true}
          showDropdownIndicator={true}
          disabled={disabled}
        />
      </InfoArea>
      <InfoArea marginTop="45px">
        <IconButton onClick={() => removeInsuranceType(index)}>
          <XIcon />
        </IconButton>
      </InfoArea>
    </div>
  );
};

export default InsuranceTypeAndNamePick;
