import styled from "styled-components";

import IconButton from "@material-ui/core/IconButton";
import { TYPOGRAPHY } from "constants.js";
import { Typography } from "@mui/material";

export const StyledCloseButton = styled(IconButton)`
  && {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // max-height: 406px;
  margin-top: 24px;
`;

export const PopupTitle = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 5px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 25px;
  }
`;

export const PopupBodyText = styled(Typography)`
  && {
    font-family: ${TYPOGRAPHY.primaryFont};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 8px;
  }
`;
