import VerticalLine from "components/verticalLine";
import { contactSelector } from "data/selectors/contact";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router";
import { ClientSection } from "./clientSection";
import { FunctionSection } from "./functionSection";
import { SideSection } from "./sideSection";
import ConeIcon from "../../assets/common/ConeIcon.js";
import {
  FormContainer,
  LargeText,
  ScreenRoot,
  SideContainer,
  StyledArea,
  StyledBox,
  StyledSpan,
} from "./styles";
import BuyerIcon from "assets/landingToolsBarIcons/buyerIcon";
import ContractorIcon from "assets/landingToolsBarIcons/contractorIcon";
import {
  serviceProviderBuyerListSelector,
  serviceProviderContractorsListSelector,
} from "data/selectors/serviceProvider";
import {
  getServiceProviderBuyersList,
  getServiceProviderContractorsList,
} from "data/actions/serviceProvider";
import ContractorAgentDashboard from "./contractorAgentDashboard";

function ServiceProviderDashboard() {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [userData, setUserData] = useState("");
  const dispatch = useDispatch();
  const contactData = useSelector(contactSelector);
  let history = useHistory();
  const serviceProviderBuyerData = useSelector(
    serviceProviderBuyerListSelector
  );
  const serviceProviderContractorData = useSelector(
    serviceProviderContractorsListSelector
  );

  useEffect(() => {
    if (serviceProviderBuyerData?.length < 1) {
      dispatch(getServiceProviderBuyersList());
    }

    if (serviceProviderContractorData?.length < 1) {
      dispatch(getServiceProviderContractorsList());
    }
    localStorage.removeItem("current_agent_dashboard");
  }, []);

  useEffect(() => {
    if (
      serviceProviderContractorData?.data?.length > 0 &&
      serviceProviderBuyerData?.data?.length < 1
    ) {
      history.push("/service-provider-dashboard/contractor-client");
    }
    if (
      serviceProviderContractorData?.data?.length < 1 &&
      serviceProviderBuyerData?.data?.length > 0
    ) {
      history.push("/service-provider-dashboard/buyer-client");
    }
  }, [serviceProviderBuyerData, serviceProviderContractorData]);
  return (
    <ScreenRoot isMobile={isMobile}>
      <FormContainer isMobile={isMobile}>
        {serviceProviderContractorData?.data?.length > 0 &&
          serviceProviderBuyerData?.data?.length > 0 && (
            <>
              <LargeText isMobile={isMobile} style={{ textAlign: "center" }}>
                {isMobile ? "Select Module" : "Please Select Client Type"}
              </LargeText>
              <br /> <br />
              <StyledArea>
                <StyledBox
                  boxHeight="0px"
                  borderRadius="0px"
                  isMobile={isMobile}
                  onClick={() => {
                    history.push("/service-provider-dashboard/buyer-client");
                    localStorage.setItem(
                      "current_agent_dashboard",
                      "/service-provider-dashboard/buyer-client"
                    );
                  }}
                  style={{ cursor: "pointer" }}
                  flexDirection="row"
                >
                  <BuyerIcon />
                </StyledBox>

                <StyledBox
                  boxHeight="0px"
                  borderRadius="0px"
                  isMobile={isMobile}
                  onClick={() => {
                    history.push(
                      "/service-provider-dashboard/contractor-client"
                    );
                    localStorage.setItem(
                      "current_agent_dashboard",
                      "/service-provider-dashboard/contractor-client"
                    );
                  }}
                  style={{ cursor: "pointer" }}
                  flexDirection="row"
                >
                  <ContractorIcon />
                </StyledBox>
              </StyledArea>
            </>
            // ) : (
            //   <>
            //     {serviceProviderContractorData?.data?.length > 0 &&
            //       serviceProviderBuyerData?.data?.length < 1 && (
            //         <ContractorAgentDashboard />
            //       )}
            //     {serviceProviderContractorData?.data?.length < 1 &&
            //       serviceProviderBuyerData?.data?.length > 0 && (
            //         <ContractorAgentDashboard />
            //       )}
            //   </>
          )}
        {!serviceProviderContractorData?.data?.length > 0 &&
          !serviceProviderBuyerData?.data?.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "15%",
              }}
            >
              <ConeIcon />
              <LargeText
                isMobile={isMobile}
                style={{ textAlign: "center", lineHeight: "40px" }}
              >
                {
                  "There are no clients assigned to you on PledgX, please contact customer success team at support@pledgx.com to add your clients."
                }
              </LargeText>
            </div>
          )}
      </FormContainer>
    </ScreenRoot>
  );
}

export default ServiceProviderDashboard;
