import React from 'react';
import countryImg from './Country.svg';

const Country = ({ isMobile }) => {
  return (
    <img width={isMobile ? "36" : "56"} height={isMobile ? "36" : "56"} src={countryImg} />
  )
};

export default Country;

