import React, { useEffect, useState } from "react";

import { 
  StyledBox,
  SmallText,
  GreySmallText
} from '../styles';

import { PersonnelContent, TitleDiv } from './styles'
import { LetterAvatar } from "../../../components/letterAvatar";
import { truncateString } from "../../../common/truncateString";
export const PersonnelCard = ({
  isMobile,
  firstName, 
  lastName,
  id,
  key,
  position,
}) => {
  const name = `${firstName}${firstName && lastName ? " " :''}${lastName}`;

  return (
    <>
      <StyledBox boxHeight="80px" borderRadius="10px" margin="0 0 20px 0">
        <PersonnelContent>
          <LetterAvatar name={name} />
          <TitleDiv>
            <SmallText>{name} </SmallText>
            <GreySmallText>{truncateString(position, 23)}</GreySmallText>
          </TitleDiv>        
        </PersonnelContent>
      </StyledBox>
    </>
   
  );
}



