import { CircularProgress } from "@mui/material";
import {
  confirmFileUpload,
  createFile,
  setFileType,
  uploadFileToGCP,
} from "api/file_managment";
import bytesToMegaBytes from "common/convertFileSize";
import FilesListTable from "components/filesListTable";
import { StyledTableCell } from "components/filesListTable/styles";
import { ThreeDotsPopupMenuNew } from "components/threeDotsPopupMenuNew";
import { COLORS } from "../../constants";
import moment from "moment";
import { StyleDisplay } from "pages/preQualifyForm/components/uploadedFileDisplay/styles";
import React, { useState } from "react";
import { useEffect } from "react";
import RoundedCheckbox from "components/roundedCheckbox";
import { MobileColumnContent, MobileColumnText } from "./styles";
import { RadioButton } from "components/radioButton";
import CheckedRadioButton from "assets/dashboardIcons/checkedRadioButton";
import UncheckedRadioButton from "assets/dashboardIcons/uncheckedRadioButton";
import Select from "../../components/selectField";
import DateTimePickerField from "components/dateTimePickerField";

export const UploadedFiles = ({
  file,
  filteredData,
  setFilteredData,
  index,
  displayPercentage,
  handleDelete,
  handleRadioButtonClick,
  handleSelectButtonClick,
  isMobile,
  setOpenPopup,
  docPoolPopUp,
  resumeFile,
  selectRadioButtonValue,
  handleSingleRadioButtonClick,
  fileTypes,
  institutionId,
  currentDashboardUrl,
}) => {
  let currentFileArray = [...filteredData];

  const [openProgress, setOpenProgress] = useState(false);
  const [selectButtonValue, setSelectButtonValue] = useState(false);
  const [docType, setDocType] = useState(file?.file_type);
  let uploadFileName = file.name;
  let extension = uploadFileName.split(".").pop();
  let fileName = uploadFileName.replace("." + extension, "");

  let dataForFirstApi = {
    file_type: "None",
    file_extension: extension,
    file_name: fileName,
  };
  if (currentDashboardUrl === "/service-provider-dashboard") {
    dataForFirstApi = { institution_id: institutionId, ...dataForFirstApi };
  }

  useEffect(() => {
    if (isNaN(file.file_id)) {
      setOpenProgress(true);
      //get upload url and file id
      createFile(
        dataForFirstApi,
        async (data) => {
          //#region upload file using upload url
          try {
            await uploadFileToGCP(data.upload_url, file, {
              headers: {
                "Content-Type": file.content_type,
                "Access-Control-Allow-Origin": "*",
              },
            });
          } catch (e) {
            console.log("CORS ERROR");
          }
          //#endregion

          //#region confirm file is uploaded or not
          await confirmFileUpload(
            { file_id: data.id },
            async () => {
              //TODO: Toast a message

              currentFileArray[index].file_id = data.id;
              currentFileArray[index].private = true;
              // currentFileArray[index].file_type = "None";
              setFilteredData(currentFileArray);

              setOpenProgress(false);
            },
            (err) => {
              //Removing file from the state

              currentFileArray.splice(index, 1);
              setFilteredData(currentFileArray);
              //set Alert popup if file size limit reached
              setOpenPopup(true);
              console.log("Failed uploaded api");
            }
          );

          //#endregion
        },
        () => {
          //Removing file from the state
          currentFileArray.splice(index, 1);
          setFilteredData(currentFileArray);
          //set Alert popup if file size limit reached
          setOpenPopup(true);
        }
      );
    }
  }, []);

  const handlefileType = async (value) => {
    const data = {
      file_id: file?.file_id,
      file_type: value,
    };
    if (value !== docType) {
      await setFileType(
        data,
        () => {
          setDocType(value);
        },
        () => {}
      );
    }
  };

  const body = (bodyData) => {
    return (
      <>
        {docPoolPopUp && (
          <StyledTableCell component={"tr"} width="10%" align="left">
            {resumeFile && (
              <RadioButton
                checkedIcon={<CheckedRadioButton />}
                isChecked={selectRadioButtonValue}
                handleChange={() => handleSingleRadioButtonClick(file)}
                unCheckedIcon={<UncheckedRadioButton />}
              />
            )}
            {!resumeFile && (
              <RoundedCheckbox
                value={selectButtonValue}
                attributeTypes={[true]}
                onChange={() => {
                  if (selectButtonValue) {
                    setSelectButtonValue(false);
                    handleSelectButtonClick(index, bodyData?.file_id, false);
                  } else {
                    setSelectButtonValue(true);
                    handleSelectButtonClick(index, bodyData?.file_id, true);
                  }
                }}
              />
            )}
          </StyledTableCell>
        )}
        {!docPoolPopUp && (
          <StyledTableCell component={"tr"} width="10%" align="left">
            <RoundedCheckbox
              value={bodyData?.showcase == null ? false : !bodyData?.showcase}
              attributeTypes={[true]}
              onChange={() => handleRadioButtonClick(index)}
            />
          </StyledTableCell>
        )}
        {isMobile && (
          <StyledTableCell component={"tr"} width="90%">
            <MobileColumnContent direction="column">
              <MobileColumnText>{bodyData.file_name}</MobileColumnText>
              <MobileColumnText fontSize="12px" fontWeight="400">
                {bytesToMegaBytes(bodyData.file_size, 1)}
              </MobileColumnText>
            </MobileColumnContent>
          </StyledTableCell>
        )}
        {!isMobile && (
          <>
            <StyledTableCell component={"tr"} width="29%">
              {bodyData.file_name}
            </StyledTableCell>
            <StyledTableCell component={"tr"} width="20%">
              {(!docPoolPopUp || !file["file_type"]) && (
                <Select
                  dropdownHeight="30px"
                  options={fileTypes?.map((fileType) => ({
                    value: fileType?.doc_type,
                    label: fileType?.doc_type,
                  }))}
                  value={docType}
                  setValue={(val) => {
                    handlefileType(val);
                  }}
                  validateFunc={(item) => Boolean(item)}
                  noCheckIcon={true}
                  showDropdownIndicator={true}
                  withoutMargin={true}
                />
              )}
              {docPoolPopUp && file["file_type"] && <>{docType}</>}
            </StyledTableCell>
            <StyledTableCell
              component={"tr"}
              scope="row"
              align="right"
              width="12%"
            >
              {bodyData.file_size}
            </StyledTableCell>
            <StyledTableCell
              component={"tr"}
              scope="row"
              align="right"
              width="18%"
            >
              <DateTimePickerField
                showDateField={true}
                withoutMargin={true}
                dateValue={bodyData.upload_date ? bodyData.upload_date : ""}
                readOnly={true}
                paddingRight="0px"
              />
            </StyledTableCell>
          </>
        )}
        <StyledTableCell component={"tr"} align="right" width="">
          {openProgress && (
            <CircularProgress
              style={{
                height: "20px",
                width: "20px",
                color: COLORS.primaryOrange,
              }}
              disableShrink
            />
          )}
          {!openProgress && (
            <ThreeDotsPopupMenuNew
              item={bodyData}
              index={index}
              removeEdit="DocPool"
              documentPool={true}
              handleDelete={handleDelete}
            />
          )}
        </StyledTableCell>
      </>
    );
  };
  var dateString1 = moment.utc(file?.updated);

  var uploadDate = `${dateString1.format("YYYY-MM-DD")}`;
  const uploadedFileDetails = [
    {
      file_id: file?.file_id,
      file_name: file?.name,
      file_size: file?.size ? bytesToMegaBytes(file?.size, 1) : "",
      upload_date: uploadDate,
      showcase: file?.private,
    },
  ];

  return (
    <StyleDisplay
      displayPercentage={displayPercentage}
      marginTop={index === 0 ? "10px" : 0}
    >
      <FilesListTable
        data={uploadedFileDetails}
        body={body}
        actionType="file"
        concise
        borderSpacing="0 4px !important"
        // handleClickRow={handleClickRow}
        position="relative"
      />
    </StyleDisplay>
  );
};
