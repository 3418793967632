import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import { FunctionalButton } from '../../../../components/functionalButton';
import { MultilineTextField } from '../../../../components/multilineTextField';
import {
  StyledDialogTitle,
  StyledDialog,
  StyledDialogContent,
} from './styles';


export const MessagePopup = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
     <StyledDialog open={open} onClose={handleClose}>
        <StyledDialogTitle>Send a comment to the contractor</StyledDialogTitle>
        <StyledDialogContent>
          <MultilineTextField label="Add your remarks" placeholder="Remark" row={4}/>
        </StyledDialogContent>
        <DialogActions>
          <FunctionalButton handleButton={handleClose} width="187px" buttonTitle={'Submit'} />
        </DialogActions>
      </StyledDialog>
    </>
  )
}