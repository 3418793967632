import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Step from "@material-ui/core/Step";
import { useSelector } from "react-redux";
import { userSelector } from "data/selectors/user";

import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as contact } from "../../../assets/preQualifyIcons/contact.svg";
import { ReactComponent as financial } from "../../../assets/preQualifyIcons/financial.svg";
import { ReactComponent as office } from "../../../assets/preQualifyIcons/office.svg";
import { ReactComponent as projects } from "../../../assets/preQualifyIcons/projects.svg";
import { ReactComponent as years } from "../../../assets/preQualifyIcons/years.svg";
import { ReactComponent as structure } from "../../../assets/preQualifyIcons/structure.svg";
import { ReactComponent as healthSafety } from "../../../assets/preQualifyIcons/healthsafety.svg";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { StyledStepConnector, StyledStepLabel, StyledStepper } from "./styles";

const useColorlibStepIconStyles = makeStyles({
  root: {
    cursor: "pointer",
    zIndex: 1,
    color: "#b8b8b8",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: { color: "#888888" },
  completed: { color: "#B9B9C3" },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SvgIcon component={contact} />,
    2: <SvgIcon component={structure} />,
    3: <SvgIcon component={financial} />,
    4: <SvgIcon component={healthSafety} />,
    5: <SvgIcon component={years} />,
    6: <SvgIcon component={office} />,
    7: <SvgIcon component={projects} />,
    8: <SettingsOutlinedIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

export const SideNav = ({
  activeStep,
  setActiveStep,
  isMobile,
  currentDashboardUrl,
}) => {
  const steps = [
    "Contact Information",
    "Company Information",
    "Financial Reference",
    "Health & Safety",
    "Financial Records",
    "Personnel",
    "Projects",
  ];

  const stepsAdmin = [
    "Contact Information",
    "Company Information",
    "Financial Reference",
    "Health & Safety",
    "Financial Records",
    "Personnel",
    "Projects",
    "Admin",
  ];

  const stepsAgentOnbehalfOfContractaor = [
    "Company Information",
    "Financial Reference",
    "Health & Safety",
    "Financial Records",
    "Personnel",
    "Projects",
  ];

  const current_user = useSelector(userSelector);
  const current_role = current_user?.roles?.[0];

  return (
    <StyledStepper
      isMobile={isMobile}
      activeStep={activeStep}
      orientation="vertical"
      connector={<StyledStepConnector />}
    >
      {current_role !== "admin" &&
        currentDashboardUrl !== "/service-provider-dashboard" &&
        steps.map((label, index) => (
          <Step key={label}>
            <StyledStepLabel
              StepIconComponent={ColorlibStepIcon}
              onClick={() => {
                setActiveStep(index);
              }}
            >
              {label}
            </StyledStepLabel>
          </Step>
        ))}

      {current_role === "admin" &&
        currentDashboardUrl !== "/service-provider-dashboard" &&
        stepsAdmin.map((label, index) => (
          <Step key={label}>
            <StyledStepLabel
              StepIconComponent={ColorlibStepIcon}
              onClick={() => {
                setActiveStep(index);
              }}
            >
              {label}
            </StyledStepLabel>
          </Step>
        ))}
      {currentDashboardUrl === "/service-provider-dashboard" &&
        stepsAgentOnbehalfOfContractaor.map((label, index) => (
          <Step key={label}>
            <StyledStepLabel
              StepIconComponent={ColorlibStepIcon}
              onClick={() => {
                setActiveStep(index);
              }}
            >
              {label}
            </StyledStepLabel>
          </Step>
        ))}
    </StyledStepper>
  );
};
