import React, { useEffect, useState } from "react";
import { StyledContainer } from "./styles";
import { useLocation } from "react-router-dom";
import { BuyerHeader } from "./components/BuyerHeader";
import { getProjectInfo } from "../../api/buyers";
import { ProjectSetupInfo } from "./components/projectSetupInfo";
import { ProjectButtons } from "./components/projectButtons";
import { CategoriesSetup } from "./components/categoriesSetup";
import { MandatoryRequirement } from "./components/mandatoryRequirements";
import { GoBackButton } from "./components/goBackButton";
import { WeightAttributes } from "./components/weightedAttributes/weightAttributes";
import { Calculator } from "./components/calculator";
import { AdditionalFilesUpload } from "./components/additionalFilesUpload";
import { CommonCategories } from "components/commonCategories";
import updateObjectByPath from "common/updateObjectByPath";

const CriteriaPage = () => {
  const location = useLocation();
  const [data, setData] = useState({});
  const contract_id =
    location.state && location.state.contract_id
      ? location.state.contract_id
      : "";
  const feature =
    location.state && location.state.feature ? location.state.feature : "";

  const [hasProjectDetails, setHasProjectDetails] = useState(false);
  const [hasMandatoryRequirements, setHasMandatoryRequirements] =
    useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    // console.log(contract_id);
    if (contract_id) {
      getInfo(contract_id);
    }
  }, []);

  useEffect(() => {
    setData(data);
    if (hasProjectDetails) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [data, hasProjectDetails]);

  const getInfo = async (contract_id) => {
    await getProjectInfo(contract_id)
      .then((data) => setData(data))
      .catch((err) => {});
  };

  let title = "";
  switch (feature) {
    case "new":
      title = "Setup a New RFP/Q";
      break;
    case "amend":
      title = "Amend Your RFP/Q";
      break;
    case "edit":
      title = "Edit Your RFP/Q";
      break;
    default:
      title = "View Your RFP/Q";
  }

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const onChangeData = (attribute, val) => {
    const updatedData = { ...data };
    updateObjectByPath(updatedData, attribute, val);
    setData({ ...updatedData });
  };

  return (
    <StyledContainer>
      <GoBackButton />
      <BuyerHeader title={title} hasButton={false} marginBottom="0px" />
      {(feature == "new" || !isEmpty(data)) && (
        <>
          <ProjectSetupInfo
            data={data}
            setData={setData}
            setHasData={setHasProjectDetails}
          />
          <CommonCategories data={data} setData={onChangeData} />
          <MandatoryRequirement data={data} setData={setData} />
          <WeightAttributes data={data} setData={setData} />
          <AdditionalFilesUpload />
          <ProjectButtons
            data={data}
            setData={setData}
            feature={feature}
            disabled={disabled}
          />
        </>
      )}
    </StyledContainer>
  );
};

export default CriteriaPage;
