import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Input from '../../../../components/inputField';
import { FunctionalButton } from "../../../../components/functionalButton";
import {
  StyledQuestion,
  InfoArea,
  ButtonAtBottom
} from '../styles';
import { formatPhoneNumber } from '../../../../common/formatPhoneNumber';
import { phoneRegex } from '../../../../common/regex';
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

export const Panel1 = ({
  handleNext,
  onboardingData,
  setOnboardingData,
  setIsLastPanel,
  setPreviousStepPanel
}) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  setIsLastPanel(false);
  setPreviousStepPanel(1);
  const [phoneNumber, setPhoneNumber] = React.useState(onboardingData?.user_phone || '');


  return (
    <>
      <StyledQuestion isMobile={isMobile} width="650px">What is your phone number?</StyledQuestion>
      <InfoArea isMobile={isMobile} width="50%">
        {/* <Input
          label="Phone Number"
          type="text"
          placeholder="(123) 456-7890"
          labelSize="12px"
          fontSize="18px"
          value={onboardingData?.user_phone || ''}
          setValue={(val) => setOnboardingData('user_phone', formatPhoneNumber(val))}
          validateFunc={(item) => phoneRegex.test(item)}
          noCheckIcon={true}
          noCancelIcon={true}
          noErrorBorder={true}
        /> */}

        <div style={{ marginTop: "16px", width: "100%" }}>
          <label htmlFor="phone" style={{ fontSize: "12px" }}>
            Phone Number
          </label>
          <div style={{ height: "47px", width: "100%", marginTop: "-10px" }}>
            <PhoneInput
              id="phone"
              country={"ca"}
              value={phoneNumber}
              onChange={(val) => setOnboardingData('user_phone', val)}
              style={{ marginTop: "16px", width: "100%", height: "100%" }}
              inputStyle={{
                fontSize: "18px",
                padding: "8px",
                lineHeight: "24px",
                marginLeft: "20px",
                paddingLeft: "28px",
                height: "100%",
                width: "97%",
                marginRight: "20px",
              }}
              dropdownStyle={{ fontSize: "18px", lineHeight: "24px" }}
            />
          </div>
        </div>

      </InfoArea>

      <FunctionalButton
        buttonTitle="Next"
        handleButton={handleNext}
        width={isMobile ? "90%" : "200px"}
        marginRight="0px"
        marginTop={isMobile ? "72px" : "48px"}
      // disabled={!(phoneRegex.test(onboardingData?.user_phone))}
      />
    </>
  );
}

