import React, { useEffect, useState } from "react";
import { StyledTableCell } from "components/filesListTable/styles";
import BorderLessConciseTable from "components/filesListTable/borderLessConciseTable";
import XIcon from "assets/buyers/xIcon";
import IconButton from "@material-ui/core/IconButton";

export const SelectedProjects = ({
  object,
  setObject,
  filteredData,
  setFilteredData,
  project,
  pickedArray,
  setPickedArray,
  setRerender,
  handleGetSingleRfpq,
  setRfpq,
}) => {
  useEffect(() => {
    if (project) {
      if (!object?.rfpq_id) {
        setRfpq("");
      }
      if (object?.rfpq_id) {
        handleGetSingleRfpq(object?.rfpq_id);
      }
    }
  }, [object]);

  const removeProject = (bodyData) => {
    if (project) {
      setRfpq("");
    }
    setObject("");
    setPickedArray([]);
    if (bodyData?.rfpq_id) {
      let tmp = [...filteredData];
      if (!tmp.find(({ id }) => id === bodyData?.rfpq_id)) {
        tmp.push({
          id: bodyData.rfpq_id,
          project: {
            title: bodyData?.project_title,
            number: bodyData?.project_number,
            location: bodyData?.project_city,
            closing_date: bodyData?.project_closing_date,
          },
        });
      }
      setFilteredData(tmp);
    }
  };
  const removeInstitution = (bodyData) => {
    setObject("");
    setPickedArray([]);

    if (filteredData?.length > 0 && bodyData.institution_id) {
      let tmp = [...filteredData];
      if (
        !tmp.find(
          ({ institution_id }) => institution_id === bodyData?.institution_id
        )
      ) {
        tmp.push({
          address: bodyData.address,
          city: bodyData.city,
          company_name: bodyData.company_name,
          contact_email: bodyData.contact_email,
          contact_first_name: bodyData.contact_first_name,
          contact_last_name: bodyData.contact_last_name,
          contact_phone: bodyData.contact_phone,
          default: bodyData.default,
          has_ins: bodyData.has_ins,
          institution_id: bodyData.institution_id,
          postal: bodyData.postal,
          province: bodyData.province,
          ref_id: bodyData.ref_id,
          suite_number: bodyData.suite_number,
          type: bodyData.type,
          has_ins: bodyData.has_ins,
        });
      }
      setFilteredData(tmp);
    }
  };

  const body = (bodyData) => {
    if (pickedArray && !pickedArray.includes(bodyData.institution_id)) {
      setPickedArray((pickedArray) => [
        ...pickedArray,
        bodyData.institution_id,
      ]);
    }

    const address = `${bodyData?.address || ""}${bodyData?.address ? ", " : ""}\
    ${bodyData?.suite_number ? `suite ${bodyData?.suite_number}` : ""}${
      bodyData?.suite_number && bodyData?.city ? ", " : ""
    }\
    ${bodyData?.city || ""}${bodyData?.city && bodyData?.province ? ", " : ""}\
    ${bodyData?.province || ""}${bodyData?.postal ? " " : ""}${
      bodyData?.postal || ""
    }`;
    return (
      <>
        <StyledTableCell width="40%">{bodyData.company_name}</StyledTableCell>
        <StyledTableCell width="50%">{address}</StyledTableCell>
        <StyledTableCell width="10%">
          <IconButton onClick={() => removeInstitution(bodyData)}>
            <XIcon />
          </IconButton>
        </StyledTableCell>
      </>
    );
  };

  const bodyProject = (bodyData) => {
    if (pickedArray && !pickedArray.includes(bodyData.rfpq_id)) {
      setPickedArray((pickedArray) => [...pickedArray, bodyData.rfpq_id]);
    }

    return (
      <>
        <StyledTableCell width="30%">{bodyData.project_title}</StyledTableCell>
        <StyledTableCell width="30%">{bodyData.project_number}</StyledTableCell>
        <StyledTableCell width="10%">{bodyData.project_city}</StyledTableCell>
        <StyledTableCell width="10%">
          <IconButton onClick={() => removeProject(bodyData)}>
            <XIcon />
          </IconButton>
        </StyledTableCell>
      </>
    );
  };

  return (
    <>
      {!project && (
        <BorderLessConciseTable
          body={body}
          // headers={headers}
          data={object}
          height={"60px"}
          singleRow={true}
          width="860px"
          pickedArray={pickedArray}
          selectedNotSkip={true}
          setPickedArray={setPickedArray}
        />
      )}
      {project && (
        <BorderLessConciseTable
          body={bodyProject}
          // headers={headers}
          data={object}
          height={"58px"}
          singleRow={true}
          width="860px"
          pickedArray={pickedArray}
          //selectedNotSkip={true}
          setPickedArray={setPickedArray}
        />
      )}
    </>
  );
};

export default SelectedProjects;
