import styled from "styled-components";
import { StyledTableCell } from '../../../../components/filesListTable/styles';


export const StyledTableCellFirst = styled(StyledTableCell)`
  width: 150px;

  &&.MuiTableCell-root:first-child  {
    border: none;
  }

  &&.MuiTableCell-root {
    border: none;
  }
`;

export const StyledIndexCell = styled(StyledTableCellFirst)`
  width: 50px;
`;

export const StyledTableCellSecond = styled(StyledTableCell)`
  width: 200px;

  &&.MuiTableCell-root:first-child {
    border: none;
  }

  &&.MuiTableCell-root {
    border: none;
  }
`;

export const StyledTableCheckBoxCell= styled(StyledTableCell)`
  width: 10px;

  &&.MuiTableCell-root:last-child {
    border: none;
  }

  &&.MuiTableCell-root{
    border: none;
  }
`;

export const StyledTableCenteringContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledMinusImg = styled.img`
  width: 21px;
  cursor: pointer;
`;

export const StyledImgIcon = styled.img`
  margin-top: 5px;
  cursor: pointer;
`;
