import React, { useState, useEffect } from "react";
import { FunctionalButton } from "components/functionalButton";
import { StyledTableCell } from "components/filesListTable/styles";
import { Title, TableDiv, ElementDiv, ImgButton } from "./styles";
import ConciseTable from "components/filesListTable/conciseTable";
import {
  getGenericReferees,
  sendGenericReferenceRequests,
} from "api/reference";
import { CommonSearchBar } from "components/commonSearchBar";
import addRef from "./addRef.svg";
import { SearchBar } from "pages/contractorDashboard/detailScreens/SearchBar";
import { AddGenericPendingReferencePopup } from "../../../components/addGenericPendingReferencePopup";
import { PendingAlert } from "./PendingAlert";

export const Panel4 = ({
  setPanel,
  isMobile,
  setOpen,
  setOpenSuccessPopup,
}) => {
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [pickedReferess, setPickReferees] = useState([]);
  const [internalSearchResults, setInternalSearchResults] =
    useState(filteredData);
  const [isPending, setIsPending] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [pendingData, setPendingData] = useState({});

  useEffect(() => {
    getGenericReferees(
      query,
      (res) => { setFilteredData(res); },
      () => { }
    );
  }, []);

  const body = (bodyData) => {
    return (
      <>
        <StyledTableCell component={"tr"} width="25%">
          {`${bodyData.first_name}${bodyData.first_name && bodyData.last_name ? " " : ""
            }${bodyData.last_name}`}
        </StyledTableCell>
        <StyledTableCell component={"tr"} scope="row" width="30%">
          {bodyData.email}
        </StyledTableCell>
        <StyledTableCell component={"tr"} scope="row" width="30%">
          {bodyData.institution_name}
        </StyledTableCell>
        <StyledTableCell
          component={"tr"}
          scope="row"
          width="15%"
        ></StyledTableCell>
      </>
    );
  };

  const handleSelected = (selectedIndexList) => {
    const temp = [];
    selectedIndexList.forEach((element) => temp.push(filteredData[element]));
    setPickReferees(temp);
    const updatingObject = temp.find(item => item.project_status === 'updating' || item.project_status === 'pending');

    if (updatingObject !== undefined && (updatingObject?.project_status === 'updating' || updatingObject?.project_status === 'pending')) {
      setIsPending(true);
      setPendingData(updatingObject);
    }    
  };

  const handleSendRequest = () => {
    const pickedData = [...pickedReferess];

    sendGenericReferenceRequests(
      pickedData,
      () => {
        setOpen(false);
        setOpenSuccessPopup(true);
        setPanel(1);
      },
      () => { }
    );
  };

  return (
    <>
      <Title fontWeight={500} margin="0px 0 16px">
        Who would you like a reference from?
      </Title>
      <TableDiv>
        {/* <CommonSearchBar
          filterApi={getGenericReferees}
          setFilteredData={setFilteredData}
          searchWidth="80%"
          searchPlaceHolder="Name, email, company name..."
        /> */}
        <SearchBar
          filteredData={filteredData}
          setFilteredData={setFilteredData}
          setInternalSearchResults={setInternalSearchResults}
          query={query}
          setQuery={setQuery}
          searchWidth="80%"
          internalSearch={true}
          searchPlaceHolder="Name, Email, Company..."
          marginRight="5px"
        />
        <ElementDiv>
          <ConciseTable
            length={4}
            body={body}
            data={query && query !== "" ? internalSearchResults : filteredData}
            hover={true}
            show={true}
            paddingRight="8px"
            handleSelected={handleSelected}
            multiSelect={true}
          />
        </ElementDiv>
        <ElementDiv marginTop="8px">
          <ImgButton src={addRef} onClick={() => setPanel(5)} />
        </ElementDiv>
      </TableDiv>
      <FunctionalButton
        buttonTitle={"ASK FOR REFERENCE"}
        handleButton={handleSendRequest}
        width={isMobile ? "90%" : "368px"}
        marginRight="0px"
        marginTop="24px"
        disabled={!(pickedReferess.length > 0)}
      />

      <AddGenericPendingReferencePopup
        setOpenDialog={setOpenPopup}
        openDialog={openPopup}
        objectData={pendingData}
        setObjectData={setPendingData}
        data={filteredData}
        setData={setFilteredData}
        pickedReferess={pickedReferess}
        setPickReferees={setPickReferees}
      />

      <PendingAlert
        setOpenDialog={setIsPending}
        openDialog={isPending}
        setOpenPopup={setOpenPopup}
      />
    </>
  );
};
