import React from "react";

import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";

import { InputField, InputWrap, InputLabel } from "./styles";

function CustomTextField({
  label,
  placeholder,
  value,
  setValue,
  validateFunc,
  style,
  withoutMargin,
  width,
}) {
  const [validate, setValidate] = React.useState(
    value ? (validateFunc(value) ? 1 : -1) : 0
  ); // 0-no validation, 1-valid, -1-invalid

  return (
    <InputWrap style={{ ...style, marginTop: withoutMargin ? 0 : 25 }}>
      <InputLabel>{label}</InputLabel>
      <div style={{ position: "relative" }}>
        <InputField
          style={{ minHeight: 100 }}
          validation={validate}
          placeholder={placeholder}
          width={width}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            validateFunc(e.target.value) ? setValidate(1) : setValidate(-1);
          }}
        ></InputField>
        {validate === 1 && (
          <CheckRoundedIcon
            style={{
              color: "#FF6D1D",
              position: "absolute",
              right: 12,
              bottom: 12,

              width: 16,
              height: 16,
            }}
          ></CheckRoundedIcon>
        )}
        {validate === -1 && (
          <CancelRoundedIcon
            style={{
              color: "#FF647C",
              position: "absolute",
              right: 12,
              bottom: 12,

              width: 16,
              height: 16,
            }}
          ></CancelRoundedIcon>
        )}
      </div>
    </InputWrap>
  );
}

export default CustomTextField;
