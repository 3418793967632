import React, { useState } from 'react';
import { StyledDialog,StyledDialogContent } from './styles';

export const Popup = ({ dialogContent, height, innerHeight, innerPadding, margin, open, setOpen, width }) => {
  
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
     <StyledDialog open={ open } width={ width } height={ height }>
       { dialogContent && (
          <StyledDialogContent margin={ margin } height={ innerHeight } padding={ innerPadding }>
            { dialogContent }
        </StyledDialogContent>
       )}
      </StyledDialog>
    </>
  )
}