import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Input from '../../../components/inputField';
import { FunctionalButton } from "../../../components/functionalButton";
import {
  StyledQuestion,
  InfoArea,
  StyledSkipText
 } from './styles';
import { formatPhoneNumber } from '../../../common/formatPhoneNumber';
import { Panel1 } from "./contactPanel/panel1";
import { Panel2 } from "./contactPanel/panel2";

export const Contact = ({
  handleNext,
  onboardingData,
  setOnboardingData,
  panel,
  setPanel,
  setIsLastPanel,
  setPreviousStepPanel
}) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  
  return (
    <>
    {panel == 1 &&
      <Panel1 
        handleNext={handleNext}
        onboardingData={onboardingData}
        setOnboardingData={setOnboardingData}
        setIsLastPanel={setIsLastPanel}
        setPreviousStepPanel={setPreviousStepPanel}
      />
    }
    {panel == 2 &&
      <Panel2
        handleNext={handleNext}
        onboardingData={onboardingData}
        setOnboardingData={setOnboardingData}
        setIsLastPanel={setIsLastPanel}
      />
    }
    </>
  );
}

