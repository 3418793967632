import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Link as RouterLink } from "react-router-dom";
import PledgxLogo from "../../assets/common/pledgxLogo";
import { emailRE } from "../../common/regex";
import { forgotPassword } from "../../api/onboarding";

import Input from '../../components/inputField';
import { FunctionalButton } from '../../components/functionalButton';

import { useHistory } from "react-router-dom";


import {
  FormContainer,
  Content,
  FormCard,
  FormTitle,
  StyledRememberMe,
  StyledSubtext,
  StyledSpan,
  ErrorText,
  StyledForgotPassword,
  OptionArea
} from "./styles";

export const ForgotPassword = ({ setIsPasswordChange, isMobile }) => {
  const [email, setEmail] = useState("");
  const [hasError, setHasError] = useState(false);

  const history = useHistory();

  const handleSentEmail = async () => {
    await forgotPassword({email}, onSuccess, onFailure);
  };

  const onSuccess = () => {
    setIsPasswordChange(true);
    history.push('/account/check');
  }

  const onFailure = () => {
    setHasError(true);
  }

  useEffect(() => {
    setHasError(false);
  },[email])


  return (
    <FormContainer isMobile={isMobile}>
      <Content isMobile={isMobile} marginTop="50px">
        <div onClick={() => history.push('/account/login')} style={{width: "100%", cursor:"pointer"}}>
          <PledgxLogo />
        </div>
        <FormCard>
          <FormTitle>Reset Password</FormTitle>
          <StyledSubtext>Enter the email associated with your account and we will send an email with instructions to reset your password</StyledSubtext>
            <Input
              label="Email Address"
              type="text"
              placeholder="name@company.com"
              labelSize="12px"
              textAreaHeight="64px"
              fontSize="18px"
              value={email}
              setValue={(val) => setEmail(val)}
              validateFunc={(item) => emailRE.test(item)}
              hasError={hasError}
              noCheckIcon={true}
            />
            {hasError && (
              <ErrorText>Hmm. We don’t recognize this email. Please enter a valid email or sign up for a new account.</ErrorText>
            )}
          <FunctionalButton
            buttonTitle="Send"
            handleButton={handleSentEmail}
            width="100%"
            marginRight="0px"
            marginTop="24px"
            disabled={(!emailRE.test(email))}
          />
        </FormCard>
      </Content>
    </FormContainer>
  );
}

