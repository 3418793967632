import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { convertPhoneNumberCanadaUSA } from "common/regex";
import {
  ScreenRoot,
  DetailedFormContainer
} from '../styles';
import Input from 'components/inputField';
import {
  ContractInfo,
  DarkTitle,
  TitleText,
  PageTitle,
  FormArea,
  ErrorText,
  StyledPylon,
  ErrorDiv,
  ButtonDiv
} from './styles'
import { checkReferenceToken } from 'api/reference';
import { useParams } from "react-router";
import updateObjectByPath from "common/updateObjectByPath";
import { Scale } from "./scale";
import { Comment } from './comment';
import { FunctionalButton } from "components/functionalButton";
import { ConfirmPopup } from './confirmPopup';
import { CommonSuccessPopup } from "components/commonSuccessPopup";
import PylonIcon from "assets/common/pylonIcon.png";
import { GiveAwards } from "./giveAwards";
import { ReferenceUpdatePopUp } from "components/referenceUpdatePopUp";
import { RedirectToReference } from "./redirectToReference"

function QuestionnaireScreen() {
  const { token } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [openPopup, setOpenPopup] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [openStatusPopup, setOpenStatusPopup] = useState(false);
  const [openRedirectToReferencePopup, setOpenRedirectToReferencePopup] = useState(false);
  const [data, setData] = useState({});
  const [answers, setAnswers] = useState({});
  const [companyName, setCompanyName] = useState("");
  const [valid, setValid] = useState(false)
  const [error, setError] = useState("")

  useEffect(() => {
    checkReferenceToken(
      token,
      (res) => { setData(res); setValid(true); setCompanyName(res?.company_name);},
      (err) => { setValid(false); setError(err.data.message); }
    )
  }, [])

  useEffect(() => {
    setAnswers({ ...data })
  }, [data])

  const onChangeAnswer = (attribute, val) => {
    const updatedData = { ...answers };
    updateObjectByPath(updatedData, attribute, val);
    setAnswers({ ...updatedData });
  };


  const changeUrl = () => {
    window.location.replace("https://pledgx.com")
  };

  const handleReferenceUpdate = () => {
    setOpenStatusPopup(false);
    setOpenPopup(true);
  };

  const checkReferenceGiven = () => {
    if (answers?.referee?.reference_status === false) {
      setOpenPopup(true);
    } else {
      setOpenStatusPopup(true);
    }
  };


  const handleRedirectStatus = () => {
    setOpenRedirectToReferencePopup(true);
  };


  return (
    <ScreenRoot>
      {valid && (
        <>
          <DetailedFormContainer isMobile={isMobile} >
            <PageTitle isMobile={isMobile}>Reference</PageTitle>
          </DetailedFormContainer>
          <ContractInfo marginTop="16px">
            <DarkTitle fontWeight="600">{companyName}</DarkTitle>
            {answers?.type != "generic" && (
              <>
                <TitleText>{answers?.project_name}</TitleText>
                <TitleText fontSize="18px">{answers?.project_location}</TitleText>
              </>
            )}
          </ContractInfo>
          <ContractInfo>
            <TitleText margin="0xp">Referee Information</TitleText>
            <FormArea isMobile={isMobile}>
              <Input
                id="thirdWrap"
                label="First Name"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={answers?.referee?.first_name}
                disabled={true}
              />
              <Input
                id="thirdWrap"
                label="Last Name"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={answers?.referee?.last_name}
                disabled={true}
              />
              <Input
                id="thirdWrap"
                label="Company Name"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={answers?.referee?.company}
                disabled={true}
              />
              <Input
                id="thirdWrap"
                label="Email"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={answers?.referee?.email}
                disabled={true}
              />
              <Input
                id="thirdWrap"
                label="Phone Number"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={convertPhoneNumberCanadaUSA(answers?.referee?.phone)}
                disabled={true}
              />


              <Input
                id="thirdWrap"
                label="Ralationship"
                type="text"
                placeholder=""
                labelSize="12px"
                fontSize="18px"
                value={answers?.referee?.relationship}
                disabled={true}
              />
            </FormArea>
          </ContractInfo>
          <ContractInfo>
            <TitleText margin="24px 0 0">Rating</TitleText>
            <Scale scaleData={answers?.assessment?.scale} onChangeAnswer={onChangeAnswer} />
          </ContractInfo>
          <ContractInfo>
            <TitleText margin="24px 0 0">Give an Awards!</TitleText>
            <GiveAwards onChangeAnswer={onChangeAnswer} data={answers} />
          </ContractInfo>
          <ContractInfo>
            <TitleText margin="24px 0 0">Leave a Comment!</TitleText>
            <Comment onChangeAnswer={onChangeAnswer} data={answers} />
          </ContractInfo>

          <ButtonDiv isMobile={isMobile} style={{ justifyContent: "center" }}>
            <FunctionalButton
              buttonTitle="SUBMIT"
              width="15%"
              marginTop="24px"
              marginBottom="48x"
              marginRight="16px"
              handleButton={checkReferenceGiven}
            />

            {data?.referee?.redirect_status === null && (
              <>
                <FunctionalButton
                  buttonTitle={"REDIRECT TO"}
                  width="15%"
                  marginTop="24px"
                  marginBottom="48x"
                  marginRight="0px"
                  isReversedColor={true}
                  handleButton={() => handleRedirectStatus()}
                />
              </>
            )}

          </ButtonDiv>

          <RedirectToReference
            isMobile={isMobile}
            data={data}
            setData={setData}
            openPopup={openRedirectToReferencePopup}
            setOpenPopup={setOpenRedirectToReferencePopup}
          />


          <ConfirmPopup
            isMobile={isMobile}
            data={answers}
            companyName={companyName}
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            setOpenSuccessPopup={setOpenSuccessPopup}
            token={token}
          />
          <CommonSuccessPopup
            setOpenPopup={setOpenSuccessPopup}
            openPopup={openSuccessPopup}
            isMobile={isMobile}
            boldText="Reference sent!"
            text="Check your email for verification"
            handleButton={changeUrl}
          />
          <ReferenceUpdatePopUp
            setOpenDialog={(e) => setOpenStatusPopup(e)}
            openDialog={openStatusPopup}
            handleButton={() => handleReferenceUpdate()}
          />
        </>
      )}
      {!valid && (
        <ErrorDiv isMobile={isMobile}>
          <StyledPylon src={PylonIcon} isMobile={isMobile} />
          <ErrorText isMobile={isMobile}>{error}</ErrorText>
        </ErrorDiv>
      )}

    </ScreenRoot>
  )
}

export default QuestionnaireScreen;