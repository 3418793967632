import React from "react";

import {
  StyledCellTitle,
  StyledCellCommon,
  StyledCellAction,
  StyledStatus,
} from "./styles";

import { ActionsMenu } from "./ActionsMenu";
import ConciseTable from "components/filesListTable/conciseTable";
import DateTimePickerField from "components/dateTimePickerField";

export const AllProjectsTable = ({
  data,
  setData,
  isMobile,
  borderRadius,
  boxShadow,
  isShortened,
  clientCompanyName,
}) => {
  const pickTextColor = (status) => {
    switch (status?.toLowerCase()) {
      case "underway":
        return "#BC7C02";
      case "completed":
        return "#2F9B51";
      default:
        return "black";
    }
  };

  const pickBackgroundColor = (status) => {
    switch (status?.toLowerCase()) {
      case "underway":
        return "#FFEBC6";
      case "completed":
        return "#C0FAD2";
      default:
        return "white";
    }
  };
 
  const body = (bodyData) => {
    return (
      <>
        <StyledCellTitle component={"tr"} borderRadius={borderRadius}>
          {bodyData?.project_name}
        </StyledCellTitle>
        {!isShortened && (
          <StyledCellCommon
            component={"tr"}
            scope="row"
            borderRadius={borderRadius}
          >
            <StyledStatus
              textColor={pickTextColor(bodyData?.project_status)}
              backgroundColor={pickBackgroundColor(bodyData?.project_status)}
            >
              {bodyData?.project_status}
            </StyledStatus>
          </StyledCellCommon>
        )}
        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
        >
          {bodyData?.created}
        </StyledCellCommon>
        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
        >
          <DateTimePickerField
            showDateField={true}
            withoutMargin={true}
            dateValue={bodyData?.completed_date ? bodyData?.completed_date : ""}
            readOnly={true}
            paddingRight="0px"
          />
        </StyledCellCommon>
        <StyledCellCommon
          component={"tr"}
          scope="row"
          borderRadius={borderRadius}
        >
          {bodyData?.last_status_report}
        </StyledCellCommon>
      </>
    );
  };

  const actions = (props) => {
    return (
      <>
        <StyledCellAction
          component={"tr"}
          scope="row"
          align="center"
          borderRadius={borderRadius}
        >
          <ActionsMenu
            clientCompanyName={clientCompanyName}
            data={data.find((obj) => obj.id === props.id)}
            wipButton={true}
            clientId={data.find((obj) => obj.id === props.id)?.account_id}
          />
        </StyledCellAction>
      </>
    );
  };

  const createHeader = (title, width, align) => {
    return {
      title,
      width: width || "",
      align: align || "left",
    };
  };

  const headers = [
    createHeader("Project Title", "35%"),
    createHeader("Status", "15%"),
    createHeader("Created", "15%"),
    createHeader("Completion", "15%"),
    createHeader("Last Status Report", "30%"),
    createHeader(""),
  ];
  const shortenedHeaders = [
    createHeader("Project Title"),
    createHeader("Status"),
    createHeader(""),
    createHeader(""),
  ];

  return (
    <div>
      <ConciseTable
        headers={isMobile ? "" : isShortened ? shortenedHeaders : headers}
      />
      <ConciseTable
        actions={actions}
        actionType="client"
        data={data}
        height="1200px"
        body={body}
        boxShadow={boxShadow}
        borderRadius={borderRadius}
        // headers={isMobile ? "" : isShortened ? shortenedHeaders : headers}
      />
    </div>
  );
};
